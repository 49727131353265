import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {BaseFormModelComponent} from "../../../core/shared/base/base.component";
import {Offer_Dto} from "../../../core/app-dto/offer.dto";
import {Subject} from "rxjs";
import {Store} from "@ngrx/store";
import * as store from "../../../core/app-store";
import {BsModalRef} from "ngx-bootstrap";
import {ActivatedRoute} from "@angular/router";
import {ResetSection} from "../../../core/app-store/events/base.events";
import {Car_Dto} from "../../../core/app-dto/car.dto";
import {ObjectValidators} from "../../../core/shared/object.validators";
import {UpdateOfferAttributesActionProxy} from "../../common/ap/update-offer-attributes.action-proxy";
import {AddOfferFirstStepComponent} from "../add-offer-wizard/add-offer-first-step/add-offer-first-step.component";

@Component({
    selector: 'app-update-offer-title',
    templateUrl: './update-offer-title.component.html',
    styleUrls: ['./update-offer-title.component.scss']
})
export class UpdateOfferTitleComponent extends BaseFormModelComponent<Offer_Dto> implements OnInit, OnDestroy {
    public editDataAP: UpdateOfferAttributesActionProxy;
    public onClose: Subject<boolean>;
    public offer: Offer_Dto;

    @ViewChild(AddOfferFirstStepComponent, {static: false})
    private attributesComponent: AddOfferFirstStepComponent;

    constructor(public appState: Store<store.State>,
                private activeModal: BsModalRef,
                public activatedRoute: ActivatedRoute) {
        super(null, ResetSection.CarService);
        this.editDataAP = new UpdateOfferAttributesActionProxy(this, appState);
    }

    public diInitialize(data: Offer_Dto) {
        this.offer = new Offer_Dto(data);
        this.dataLoaded = true;
    }

    ngOnInit() {
        this.onClose = new Subject();
    }

    ngOnDestroy() {
        this.destroy();
    }

    public decline() {
        this.onClose.next(false);
        this.activeModal.hide();
    }

    public accept() {
        this.formSubmitted = true;
        this.attributesComponent.accept();
        this.attributesComponent.markFormAsDirtyAndTouched();
    }

    offerAttributesFormSaved(data: Offer_Dto) {
        if (ObjectValidators.isValidObject(data)) {
            this.offer = data;
            if (this.editDataAP.canExecute()) {
                this.editDataAP.execute(this.offer);
                this.onClose.next(true);
                this.activeModal.hide();
            }
        }
    }
}
