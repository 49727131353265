import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ServicePlatform_Dto} from "../../../app-dto/service-platform.dto";
import {Mechanic_Dto} from "../../../app-dto/mechanic.dto";
import {CheckServicePlatformAvailabilityActionProxy} from "./check-service-platform-availability.action-proxy";
import {CheckMechanicAvailabilityActionProxy} from "./check-mechanic-availability.action-proxy";
import {Store} from "@ngrx/store";
import * as store from "../../../app-store";
import {DateTimeService} from "../../services/date-time.service";
import {CheckMechanicPlatformBusySandbox} from "./check-mechanic-platform-busy.sandbox";
import {BaseEmbeddedWebComponent, IWebComponent} from "../../../shared/base/base.component";
import {FileAttachment, LoggedUser_Dto} from "../../../app-dto/core.dto";
import {isValidObject} from "../../../shared/helpers/common.helpers";
import {CalendarEventRelatedEntityType} from "../../../app-dto/calendar-event.dto";

@Component({
  selector: 'app-check-mechanic-platform-busy',
  templateUrl: './check-mechanic-platform-busy.component.html',
  styleUrls: ['./check-mechanic-platform-busy.component.scss']
})
export class CheckMechanicPlatformBusyComponent extends BaseEmbeddedWebComponent implements IWebComponent, OnInit, OnDestroy {
  public servicePlatformUnavailable: boolean = false;
  public mechanicUnavailable: boolean = false;
  public checkServicePlatformAvailabilityAP: CheckServicePlatformAvailabilityActionProxy;
  public checkMechanicAvailabilityAP: CheckMechanicAvailabilityActionProxy;

  public servicePlatformValue: ServicePlatform_Dto;
  public mechanicValue: Mechanic_Dto;

  public startDateValue: Date;
  public endDateValue: Date;
  public eventIdValue: string;

  @Input("colClass") colClass:string;

  @Input("servicePlatform") set servicePlatform(data: ServicePlatform_Dto) {
    this.servicePlatformValue = data;
    this.checkServicePlatformAvailability();
  }
  @Input("mechanic") set mechanic(data: Mechanic_Dto) {
    this.mechanicValue = data;
    this.checkMechanicAvailability();
  }
  @Input("startDate") set startDate(data: Date) {
    this.startDateValue = data;
    this.checkMechanicAvailability();
    this.checkServicePlatformAvailability();
  }

  @Input("endDate") set endDate(data: Date) {
    this.endDateValue = data;
    this.checkMechanicAvailability();
    this.checkServicePlatformAvailability();
  }

  @Input("eventId") set eventId(data: string) {
    this.eventIdValue = data;
    this.checkMechanicAvailability();
    this.checkServicePlatformAvailability();
  }

  @Input("forAppointments") forAppointments:boolean=true;

  @Output() dismissUnavailableServicePlatformEvent = new EventEmitter<any>();
  @Output() dismissUnavailableMechanicEvent = new EventEmitter<any>();

  constructor(public appState: Store<store.State>,
              public sandbox: CheckMechanicPlatformBusySandbox,
              public dateTimeService: DateTimeService) {
    super();
    this.checkServicePlatformAvailabilityAP = new CheckServicePlatformAvailabilityActionProxy(this, sandbox.appState);
    this.checkMechanicAvailabilityAP = new CheckMechanicAvailabilityActionProxy(this, sandbox.appState);
  }

  ngOnInit() {
    this.sandbox.initialize((data: LoggedUser_Dto) => {
      if (isValidObject(data)) {
      }

    });
  }

  destroy() {
    if (this.sandbox != null) {
      this.sandbox.unsubscribe();
    }
  }


  checkServicePlatformAvailability() {
    if (isValidObject(this.servicePlatformValue) == false) return;
    if (!this.startDateValue) return;
    if (!this.endDateValue) return;
    this.checkServicePlatformAvailabilityAP.execute(this.startDateValue, this.endDateValue,
      this.servicePlatformValue.id, this.eventIdValue, this.forAppointments ? CalendarEventRelatedEntityType.Appointment : CalendarEventRelatedEntityType.CarServiceAction,  (result: boolean) => {
        this.servicePlatformUnavailable = result;
      });
  }

  checkMechanicAvailability() {
    if (isValidObject(this.mechanicValue) == false) return;
    if (!this.startDateValue) return;
    if (!this.endDateValue) return;
      this.checkMechanicAvailabilityAP.execute(this.startDateValue, this.endDateValue,
        this.mechanicValue.id, this.eventIdValue, this.forAppointments ? CalendarEventRelatedEntityType.Appointment : CalendarEventRelatedEntityType.CarServiceAction,(result: boolean) => {
          this.mechanicUnavailable = result;
        });
  }

  acceptUnavailableServicePlatform() {
    this.servicePlatformUnavailable = false;
  }

  dismissUnavailableServicePlatform() {
    //this.di.form.controls.servicePlatform.setValue(null);
    if(this.dismissUnavailableServicePlatformEvent) this.dismissUnavailableServicePlatformEvent.emit();
    this.servicePlatformUnavailable = false;
  }

  acceptUnavailableMechanic() {
    this.mechanicUnavailable = false;
  }

  dismissUnavailableMechanic() {
    //this.di.form.controls.mechanic.setValue(null);
    if(this.dismissUnavailableMechanicEvent) this.dismissUnavailableMechanicEvent.emit();
    this.mechanicUnavailable = false;
  }
}
