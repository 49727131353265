import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { FindSupplySandbox } from './find-supply.sandbox';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { Supply_Dto } from '../../../core/app-dto/supply.dto';
import { SupplyItemFormDi } from '../supply-form/supply-item-form.di';
import { GetSuppliesActionProxy } from './get-supplies.action-proxy';
import { Supplier_Dto } from '../../../core/app-dto/supplier.dto';
import { ConfirmationDialogService } from '../../../core/core/dialog/confirmation-dialog.service';


@Component({
  selector: 'app-find-supply',
  templateUrl: './find-supply.component.html',
  styleUrls: ['./find-supply.component.scss']
})
export class FindSupplyComponent extends BaseFormModelComponent<Supplier_Dto> implements OnInit, OnDestroy {
  public title: string;
  public onClose: Subject<any>;
  public data: Supplier_Dto = null;
  public di: SupplyItemFormDi;
  public getSuppliesAP: GetSuppliesActionProxy;
  public dtOptions: any;
  public selectedSupplies: Supply_Dto[] = [];

  constructor(public appState: Store<store.State>,
              public sandbox: FindSupplySandbox,
              private activeModal: BsModalRef,
              public activatedRoute: ActivatedRoute,
              public confirmationDialogService: ConfirmationDialogService) {
    super(sandbox, null);
    this.getSuppliesAP = new GetSuppliesActionProxy(this, sandbox.appState);
  }

  public diInitialize(data: Supplier_Dto) {
    this.data = data;
    this.title = 'Editare piesa receptie';
    this.sandbox.initialize();
    this.getSuppliesAP.execute(data ? data.id : '');
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.onClose = new Subject();
    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      pageLength: 10,
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      bInfo: false,
      ajax: () => {
      },
      columns: [
        { data: 'receptionSeries', width: '20%' },
        { data: 'supplier', width: '20%' },
        { data: 'invoiceNumber', width: '20%' },
        { data: 'supplyDate', width: '20%' },
        { data: 'totalValue', width: '20%' }
      ]
    };
    this.initialize();
    this.dataLoaded = true;
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    if (this.selectedSupplies.length === 0) {
      this.onClose.next([]);
      this.activeModal.hide();
    }

    let isUniqueSupplier = true;
    const supplier: Supplier_Dto = this.selectedSupplies[0].supplier;
    this.selectedSupplies.forEach((supply: Supply_Dto) => {
      if (supply.supplier.id !== supplier.id) {
        isUniqueSupplier = false;
      }
    });
    if (isUniqueSupplier) {
      this.onClose.next(this.selectedSupplies);
      this.activeModal.hide();
    } else {
      this.confirmationDialogService.info(
          'Stornare receptii',
          'Receptiile selectate trebuie sa aiba furnizor cu acelasi nume!',
          'Continua', 'sm', true).subscribe((confirmed) => {
      });
    }
  }

  updateSupplySelection(value: boolean, supply: Supply_Dto) {
    if (value) {
      this.selectedSupplies.push(supply);
    } else {
      this.selectedSupplies = this.selectedSupplies.filter(item => item.id !== supply.id);
    }
  }

}
