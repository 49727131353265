import {BaseFormDI} from "../../base/base.di";
import {InvoicePartialCharge_Dto} from "../../../app-dto/invoice-print-job.dto";
import {FormControl, FormGroup, Validators} from "@angular/forms";

export class InvoiceChargeDi extends BaseFormDI<InvoicePartialCharge_Dto> {
    constructor(public index: number, data) {
        super(new InvoicePartialCharge_Dto(data));
        this.initForm();
    }

    getModel(): InvoicePartialCharge_Dto {
        this.model.amount = this.form.value.amount;
        this.model.type = this.form.value.type;
        return this.model;
    }

    initForm() {
        // Validators.pattern("/^-?\\d*[.,]?\\d{0,2}$/")
        this.form = new FormGroup({
            amount: new FormControl(this.model.amount, Validators.compose([Validators.required, Validators.min(1)])),
            type: new FormControl(this.model.type, Validators.compose([Validators.required]))
        });
    }

    public isValid(): boolean {
        return this.form.valid;
    }
}
