import {Injectable} from '@angular/core';
import {BaseSandbox} from '../../base/base.sandbox';
import {Store} from '@ngrx/store';
import * as store from '../../../app-store';

@Injectable()
export class ReceiptSandbox extends BaseSandbox {

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }
    clearSandboxState() {
    }

    initialize(param1?: any, param2?: any, param3?: any, param4?: any, param5?: any): any {
    }
}
