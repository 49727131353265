import {Injectable} from '@angular/core';
import * as store from '../../../app-store/index';
import {select, Store} from '@ngrx/store';
import {BaseSandbox} from 'src/app/core/shared/base/base.sandbox';
import {BaseComponentState} from 'src/app/core/app-store/utils/base-component.state';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {Do_ResetStateForSection, ResetSection} from 'src/app/core/app-store/events/base.events';
import {LoggedUser_Dto} from 'src/app/core/app-dto/core.dto';
import {Router} from '@angular/router';
import {CachingUtils} from '../../../shared/caching.utils';

@Injectable()
export class LoginSandbox extends BaseSandbox {
  public login$ = this.appState$.pipe(select(store.getLogin));
  public loggedUser$ = this.appState$.pipe(select(store.getLoggedUser));

  private defaultRoute: string = 'dashboard';
  private organizationProfile = 'subscription/organization-profile';

  constructor(
    protected appState$: Store<store.State>,
    private router: Router,
  ) {
    super(appState$);
  }

  clearSandboxState() {
  }

  initialize(authMessage: (message: string) => {}) {
    this.clearSandboxState();
    this.appState$.dispatch(new Do_ResetStateForSection(ResetSection.Login));

    this.subscriptions.push(this.login$.subscribe((state: BaseComponentState) => {
      if (ObjectValidators.isValidObject(state)) {
        authMessage(state.stateMessage);
        const user = new LoggedUser_Dto(CachingUtils.loadData(LoggedUser_Dto.identifier));
        if (user != null && !user.isInitialSetupDone) {
          if (user.role && user.role.name === 'Organization_Admin')
            this.router.navigate([this.organizationProfile]);
          else if (state.loaded) {
            this.router.navigate([this.defaultRoute]);
          }
        } else if (state.loaded) {
          this.router.navigate([this.defaultRoute]);
        }
      }
    }));
  }
}
