import { PagedResult } from '../../shared/datatable/datatable.helpers';
import { Action } from '@ngrx/store';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from '../events/base.events';
import {
  GetServicePlatformsPagedResultEvent,
  GetServicePlatformEvent,
  RegisterServicePlatformEvent,
  EditServicePlatformEvent,
  ActivateServicePlatformEvent,
  DeactivateServicePlatformEvent,
  DeleteServicePlatformEvent,
  GetServicePlatformsListEvent
} from '../events/service-platform.events';
import { ResetStateActionProxy } from '../../shared/base/base.action-proxy';
import { ServicePlatform_Dto } from '../../app-dto/service-platform.dto';
import * as moment from 'moment';
import {ObjectValidators} from "../../shared/object.validators";

export interface ServicePlatformState {
  lastRefreshTime: number;
  servicePlatform: ServicePlatform_Dto;
  servicePlatformsPagedResult: PagedResult<ServicePlatform_Dto>;
  servicePlatformsList: Array<ServicePlatform_Dto>;
}

export const INITIAL_STATE: ServicePlatformState = Object.assign({}, {
  lastRefreshTime: 0,
  servicePlatform: null,
  servicePlatformsPagedResult: new PagedResult<ServicePlatform_Dto>([]),
  servicePlatformsList: []
});


export function reducer(state = INITIAL_STATE, action: Action): ServicePlatformState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {
    // Get Platform

    case Do_ResetStateForSection.identifier + ResetSection.Calendar: {
      return Object.assign({}, state, { servicePlatformsList: [] });
    }

    case Do_ResetStateForSection.identifier + ResetSection.ServicePlatformDetails: {
      return Object.assign({}, state, { servicePlatform: null });
    }

    case Do_GenericSuccessEvent.identifier + GetServicePlatformEvent.identifier: {
      const platformData = new ServicePlatform_Dto((action as Do_GenericSuccessEvent<ServicePlatform_Dto>).data);
      return Object.assign({}, state, { servicePlatform: platformData });
    }

    case Do_GenericErrorEvent.identifier + GetServicePlatformEvent.identifier: {
      return Object.assign({}, state, { servicePlatform: null });
    }

    case ResetStateActionProxy.name + GetServicePlatformEvent.identifier: {
      return Object.assign({}, state, { servicePlatform: null });
    }

    // Get Platform

    // Get Platforms

    case Do_ResetStateForSection.identifier + ResetSection.ServicePlatforms: {
      return Object.assign({}, state, { servicePlatformsPagedResult: new PagedResult<ServicePlatform_Dto>([]) });
    }

    case GetServicePlatformsPagedResultEvent.identifier: {
      if ((action as GetServicePlatformsPagedResultEvent).withReset) {
        state.servicePlatformsPagedResult = new PagedResult<ServicePlatform_Dto>([]);
        return Object.assign({}, state);
      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetServicePlatformsPagedResultEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<PagedResult<ServicePlatform_Dto>>).data;
      state.servicePlatformsPagedResult = new PagedResult<ServicePlatform_Dto>(data);
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetServicePlatformsPagedResultEvent.identifier: {
      state.servicePlatformsPagedResult = new PagedResult<ServicePlatform_Dto>([]);
      return Object.assign({}, state);
    }

    // Get Platforms

    // Get Platforms List
    case Do_GenericSuccessEvent.identifier + GetServicePlatformsListEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<Array<ServicePlatform_Dto>>).data;
      state.servicePlatformsList = data.map(r => new ServicePlatform_Dto(r));
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetServicePlatformsListEvent.identifier: {
      state.servicePlatformsList = [];
      return Object.assign({}, state);
    }
    // /Get Platforms List

    case Do_GenericSuccessEvent.identifier + RegisterServicePlatformEvent.identifier: {
      return Object.assign({}, state, { lastRefreshTime: moment().unix() });
    }
    case Do_GenericSuccessEvent.identifier + DeactivateServicePlatformEvent.identifier:
    case Do_GenericSuccessEvent.identifier + ActivateServicePlatformEvent.identifier:
    case Do_GenericSuccessEvent.identifier + EditServicePlatformEvent.identifier: {
      const data = new ServicePlatform_Dto((action as Do_GenericSuccessEvent<ServicePlatform_Dto>).data);
      if(ObjectValidators.isValidObject(state.servicePlatform) && state.servicePlatform.id == data.id) {
        return Object.assign({}, state, {lastRefreshTime: moment().unix(), servicePlatform: data});
      }
    }
    case Do_GenericSuccessEvent.identifier + DeleteServicePlatformEvent.identifier: {
      return Object.assign({}, state, { lastRefreshTime: moment().unix() });
    }

    default: {
      return state;
    }
  }
}

export const getTimestampCallback = (state: ServicePlatformState) => state.lastRefreshTime;
export const getServicePlatformCallback = (state: ServicePlatformState) => state.servicePlatform;
export const getServicePlatformsPagedResultCallback = (state: ServicePlatformState) => state.servicePlatformsPagedResult;
export const getServicePlatformsListCallback = (state: ServicePlatformState) => state.servicePlatformsList;
