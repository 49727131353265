import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store';
import { select, Store } from '@ngrx/store';
import {BaseSandbox, BaseSandboxWithCurrentUserLoaded} from 'src/app/core/shared/base/base.sandbox';
import {
  CarServiceListDataTableParameters,
  DataTableParameters,
  PagedResult
} from 'src/app/core/shared/datatable/datatable.helpers';
import { CarServiceListItemDi } from './car-service-list-item.di';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';

@Injectable()
export class CarServiceListSandbox extends BaseSandboxWithCurrentUserLoaded {
    public carServiceEntries$ = this.appState$.pipe(select(store.getCarServiceListing));
    public timestamp$ = this.appState$.pipe(select(store.getCarServiceTimestamp));

    public totalNumberOfResults: number = null;
    public callback: any;
    public dataTableParameters: CarServiceListDataTableParameters;
    public resetTableCallback: Function;
    public lastRefreshTime: number = 0;

    public totalPages: number = 0;
    public items: Array<CarServiceListItemDi> = [];
    private draw: number = 1;

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
        this.items = [];
        this.draw = 1;
        this.lastRefreshTime = 0;
        super.clearSandboxState();
    }

    public initialize(): void {
        this.clearSandboxState();
        this.loadUser(()=>{});
        this.subscriptions.push(this.carServiceEntries$.subscribe((data: PagedResult<CarService_Dto>) => {
            if (data != null && data.items != null) {
                
                this.items = data.items.map(r => new CarServiceListItemDi(r));
                this.totalNumberOfResults = data.totalItems;
                this.totalPages = data.totalPages;
                if (this.callback != null) {
                    this.callback({
                        draw: this.draw++,
                        recordsTotal: data.totalItems,
                        recordsFiltered: data.totalItems,
                        data: []
                    });
                }
            } else {
                this.items = [];
                this.draw = 1;
            }
        }));

        this.subscriptions.push(this.timestamp$.subscribe((result: number) => {
            if (result > this.lastRefreshTime) {
                this.lastRefreshTime = result;
                this.resetTable();
            }
        }));
    }

    public setResetTableCallback(callback: Function) {
        this.resetTableCallback = callback;
    }

    public resetTable() {
        if (this.resetTableCallback != null) {
            this.resetTableCallback();
        }
    }
}
