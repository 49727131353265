import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { CarServiceDataFormDI } from './car-service-data-form.di';
import { CarServiceDataFormSandbox } from './car-service-data-form.sandbox';
import { CarServiceEntryData_Dto } from 'src/app/core/app-dto/car-service.dto';
import { Options } from 'ng5-slider';
import {makeInputPositive} from '../../../core/shared/helpers/common.helpers';

@Component({
  selector: 'app-car-service-data-form',
  templateUrl: './car-service-data-form.component.html',
  styleUrls: ['./car-service-data-form.component.scss']
})
export class CarServiceDataFormComponent extends BaseFormModelComponent<CarServiceEntryData_Dto> implements OnInit, OnDestroy {
  public di:CarServiceDataFormDI;
  options: Options = {
    floor: 0,
    ceil: 100
  };

  @Output()
  formSaved = new EventEmitter<CarServiceEntryData_Dto>();

  constructor(
    public appState: Store<store.State>,
    public sandbox: CarServiceDataFormSandbox
  ) {
    super(sandbox);
  }

  public diInitialize(data: CarServiceEntryData_Dto) {

    this.di = new CarServiceDataFormDI(data);
    this.dataLoaded = true;

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();

    if (this.di.isValid()) {
      if (this.formSaved != null) {
        this.formSaved.emit(this.di.getModel());
      }
    }
  }

  isValid() {
    return this.di.isValid();
  }

  makeValuePositive(event: any) {
    makeInputPositive(event);
  }
}
