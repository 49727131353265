import {BaseFormDI} from 'src/app/core/shared/base/base.di';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Supplier_Dto} from '../../../core/app-dto/supplier.dto';
import {CarPart_Dto} from '../../../core/app-dto/car-part.dto';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {min} from '../../../core/shared/form/custom-form.validator';
import {YesNoComponent, YesNoComponentInput} from '../../../core/shared/components/yes-no/yes-no.component';
import {isValidString} from '../../../core/shared/helpers/common.helpers';
import {round} from 'lodash';

export class CarPartFormDi extends BaseFormDI<CarPart_Dto> {

    public acquisitionPriceVATInput: YesNoComponentInput;
    public sellingPriceVATInput: YesNoComponentInput;
    public isEditable: boolean;

    constructor(data: CarPart_Dto = new CarPart_Dto(null), isEditable: boolean = true, private withQuantityValidation: boolean) {
        super(new CarPart_Dto(data));
        this.isEditable = isEditable;
        this.initForm();
        if (isValidString(this.model.id) == false) {
            this.model.acquisitionPriceHasVat = true;
            this.model.sellingPriceHasVat = true;
        }
        this.acquisitionPriceVATInput = new YesNoComponentInput(this.model.acquisitionPriceHasVat, !this.model.acquisitionPriceHasVat, 'Pretul include TVA?', this.form,
            'acquisitionVAT', !this.isEditable);
        this.sellingPriceVATInput = new YesNoComponentInput(this.model.sellingPriceHasVat, !this.model.sellingPriceHasVat, 'Pretul include TVA?', this.form, 'sellingPriceVAT', false);
    }

    getModel(): CarPart_Dto {
        this.model.name = this.form.value.name;
        this.model.code = this.isEditable ? this.form.value.code : this.form.getRawValue().code;
        this.model.acquisitionPrice = this.form.value.acquisitionPrice;
        this.model.acquisitionPriceHasVat = this.form.get(this.acquisitionPriceVATInput.formGroupName).get(YesNoComponent.YES_CONTROL_NAME).value || false;
        this.model.acquisitionPriceCurrency = this.form.value.acquisitionPriceCurrency.name;
        this.model.acquisitionVatValue = this.form.value.acquisitionVatValue;
        this.model.sellingVatValue = this.form.value.sellingVatValue;
        if (this.model.acquisitionPriceHasVat) {
            this.model.acquisitionPriceWithVat = this.model.acquisitionPrice;
            this.model.acquisitionPrice = round(this.model.acquisitionPriceWithVat * 100 / (100 + this.form.value.acquisitionVatValue.id), 2);
        } else {
            this.model.acquisitionPriceWithVat = round(this.model.acquisitionPrice +
                (this.model.acquisitionPrice * this.form.value.acquisitionVatValue.id / 100), 2);
        }
        this.model.sellingPrice = this.form.value.sellingPrice;
        this.model.sellingPriceHasVat = this.form.get(this.sellingPriceVATInput.formGroupName).get(YesNoComponent.YES_CONTROL_NAME).value || false;
        this.model.sellingPriceCurrency = this.form.value.sellingPriceCurrency.name;
        if (this.model.sellingPriceHasVat) {
            this.model.sellingPriceWithVat = this.model.sellingPrice;
            this.model.sellingPrice = round(this.model.sellingPriceWithVat * 100 / (100 + this.form.value.sellingVatValue.id), 2);
        } else {
            this.model.sellingPriceWithVat = round(this.model.sellingPrice +
                (this.model.sellingPrice * this.form.value.sellingVatValue.id / 100), 2);
        }
        this.model.supplier = new Supplier_Dto(this.form.getRawValue().supplier);
        this.model.isSecondHand = this.isEditable ? this.form.value.isSecondHand : this.form.getRawValue().isSecondHand || false;
        this.model.quantity = this.form.value.quantity || 0;
        this.model.um = this.form.value.um;

        return this.model;
    }

    initForm() {
        let sellingPrice: number = this.model.sellingPrice;
        if (this.model.sellingPriceHasVat) {
            sellingPrice = this.model.sellingPriceWithVat;
        }
        let acquisitionPrice: number = this.model.acquisitionPrice;
        if (this.model.acquisitionPriceHasVat) {
            acquisitionPrice = this.model.acquisitionPriceWithVat;
        }
        if (!isValidString(this.model.um)) {
            this.model.um = 'buc';
        }
        this.form = new FormGroup({
            name: new FormControl({value: this.model.name, disabled: false}, Validators.compose([Validators.required])),
            code: new FormControl({value: this.model.code, disabled: false}, Validators.compose([Validators.required])),
            acquisitionPrice: new FormControl({value: acquisitionPrice, disabled: false},
                Validators.compose([Validators.required, min(0)])),
            sellingPrice: new FormControl({value: sellingPrice, disabled: false}, Validators.compose([Validators.required, min(0)])),
            supplier: new FormControl({
                value: ObjectValidators.isValidObject(this.model.supplier) ? {
                    id: this.model.supplier.id,
                    name: this.model.supplier.name
                } : null, disabled: false
            }, Validators.compose([Validators.required])),
            isSecondHand: new FormControl({value: this.model.isSecondHand, disabled: !this.isEditable}, Validators.compose([])),
            quantity: new FormControl({value: this.model.quantity || 1, disabled: false},
                this.withQuantityValidation ? Validators.compose([Validators.max(this.model.availableQuantity), Validators.min(0)]) :
                    Validators.compose([Validators.min(0)])),
            um: new FormControl({value: this.model.um, disabled: false}, Validators.compose([]))
        });
    }
}
