import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {InvoiceInput, InvoiceRequestType} from '../invoice/invoice.component';

@Component({
  selector: 'app-invoice-overview-organization',
  templateUrl: './invoice-overview-organization.component.html',
  styleUrls: ['./invoice-overview-organization.component.scss']
})
export class InvoiceOverviewOrganizationComponent implements OnInit {

  public invoiceInput: InvoiceInput = null;
  public dataLoaded: boolean = false;

  constructor(
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.organizationId !== undefined && params.invoiceId !== undefined) {
        let data = new InvoiceInput();
        data.type = InvoiceRequestType.Organization;
        data.invoiceId = params.invoiceId;
        data.organizationId = params.organizationId;
        this.invoiceInput = data;

        this.dataLoaded = true;
      }
    });
  }

}
