import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiErrorDto } from '../utils/api-error.dto';
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ActiveDataTableParameters, PagedResult } from '../../shared/datatable/datatable.helpers';
import { User_Dto } from '../../app-dto/core.dto';
import { ErrorHelpers } from '../../shared/error.helpers';

class UserApiRoutes {
    public static route_for_GET_USERS_PAGINATED() {
        return '/api/user/all';
    }
    public static route_for_GET_USER(userId: string) {
        return `/api/user/${userId}`;
    }
    public static route_for_REGISTER_USER() {
        return '/api/user/';
    }
    public static route_for_EDIT_USER() {
        return '/api/user/';
    }
    public static route_for_DELETE_USER(userId: string) {
        return `/api/user/${userId}`;
    }
    public static route_for_ACTIVATE_USER(userId: string) {
        return `/api/user/${userId}/activate`;
    }
    public static route_for_DEACTIVATE_USER(userId: string) {
        return `/api/user/${userId}/deactivate`;
    }
    public static route_for_SET_USER_INITIAL_SETUP(userId: string, value: boolean) {
        return `/api/user/${userId}/setInitialSetup/${value}`;
    }
}

@Injectable()
export class UserApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_registerUser(userData: User_Dto): Observable<User_Dto> {
        const url = this.configService.getAPIBaseUrl() + UserApiRoutes.route_for_REGISTER_USER();

        return this.http.post(url, userData)
            .map((res: any) => {
                return new User_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_editUser(userData: User_Dto, updatedHistory: boolean): Observable<User_Dto> {
        const url = this.configService.getAPIBaseUrl() + UserApiRoutes.route_for_EDIT_USER() + '?updatedHistory=' + updatedHistory;

        return this.http.put(url, userData)
            .map((res: any) => {
                return new User_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getFilteredUsersPaginated(data: ActiveDataTableParameters): Observable<PagedResult<User_Dto>> {
        const url = this.configService.getAPIBaseUrl() + UserApiRoutes.route_for_GET_USERS_PAGINATED() + '?' + data.toUrlParameters();

        return this.http.get(url)
            .map((res: PagedResult<User_Dto>) => {
                //@todo : add check to validate result ( response will be wrapped in a ApiResponse object
                return new PagedResult<User_Dto>(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getUser(userId: string): Observable<User_Dto> {
        const url = this.configService.getAPIBaseUrl() + UserApiRoutes.route_for_GET_USER(userId);

        return this.http.get(url)
            .map((res: any) => {
                //@todo : add check to validate result ( response will be wrapped in a ApiResponse object
                return new User_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deleteUser(userId: string): Observable<User_Dto> {
        const url = this.configService.getAPIBaseUrl() + UserApiRoutes.route_for_DELETE_USER(userId);

        return this.http.delete(url)
            .map((res: any) => {
                //@todo : add check to validate result ( response will be wrapped in a ApiResponse object
                return new User_Dto(null);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_activateUser(userId: string, startDate: Date): Observable<User_Dto> {
        const url = this.configService.getAPIBaseUrl() + UserApiRoutes.route_for_ACTIVATE_USER(userId) + '?startDate=' + startDate.toISOString();

        return this.http.put(url, {})
            .map((res: any) => {
                //@todo : add check to validate result ( response will be wrapped in a ApiResponse object
                return new User_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deactivateUser(userId: string, endDate: Date): Observable<User_Dto> {
        const url = this.configService.getAPIBaseUrl() + UserApiRoutes.route_for_DEACTIVATE_USER(userId) + '?endDate=' + endDate.toISOString();

        return this.http.put(url, {})
            .map((res: any) => {
                //@todo : add check to validate result ( response will be wrapped in a ApiResponse object
                return new User_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_setUserInitialSetup(userId: string, value: boolean): Observable<User_Dto> {
        const url = this.configService.getAPIBaseUrl() + UserApiRoutes.route_for_SET_USER_INITIAL_SETUP(userId, value);

        return this.http.put(url, {})
            .map((res: any) => {
                return new User_Dto(null);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
}
