import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {Observable} from 'rxjs';
import {IWebComponent} from 'src/app/core/shared/base/base.component';
import {BaseActionProxy} from 'src/app/core/shared/base/base.action-proxy';
import {ActiveDataTableParameters, DataTableParameters} from 'src/app/core/shared/datatable/datatable.helpers';
import {GetMechanicsPagedResultEvent} from 'src/app/core/app-store/events/mechanic.events';
import {DeleteOfferEvent, GetOffersPagedResultEvent} from '../../../core/app-store/events/offer.events';

export class DeleteOfferActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent, appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(id: string, callback: () => void): any {
        if (this.canExecute()) {
            this.appState.dispatch(new DeleteOfferEvent(id, callback));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

