import {Car_Dto} from "../../app-dto/car.dto";
import {PagedResult} from "../../shared/datatable/datatable.helpers";
import {Action} from "@ngrx/store";
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from "../events/base.events";
import {
  ActivateCarEvent,
  DeactivateCarEvent,
  DeleteCarEvent,
  EditCarEvent,
  GetCarEvent,
  GetCarsPagedResultEvent,
  RegisterCarEvent,
  SearchCarEvent
} from "../events/car.events";
import {ResetStateActionProxy} from "../../shared/base/base.action-proxy";
import * as moment from "moment";
import {ObjectValidators} from "../../shared/object.validators";
import {
  ReportingItem, ReportingListProfitItem,
  ReportingMechanicItem,
  ReportingProviderItem,
  ReportingSet
} from "../../app-dto/reporting.dto";
import {
  GetMechanicsReportingEvent, GetOrdersReportingEvent,
  GetProfitReportingEvent, GetProvidersListReportingEvent,
  GetProvidersReportingEvent
} from "../events/reporting.events";
import {CarService_Dto} from "../../app-dto/car-service.dto";
import {GetAllCarServiceEntriesForReportingEvent} from "../events/car-service.events";

export interface ReportingState {
  profitReport: ReportingSet<ReportingListProfitItem>;
  providerReport: ReportingSet<ReportingProviderItem>;
  providerListReport: ReportingSet<ReportingProviderItem>;
  mechanicsReport: ReportingSet<ReportingMechanicItem>;
  ordersReport: ReportingSet<ReportingItem>;
  allOrders:Array<CarService_Dto>;
}

export const INITIAL_STATE: ReportingState = Object.assign({}, {
  profitReport: null,
  providerReport: null,
  providerListReport:null,
  mechanicsReport: null,
  ordersReport: null,
  allOrders: null
});


export function reducer(state = INITIAL_STATE, action: Action): ReportingState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {
    // Get Car

    case Do_ResetStateForSection.identifier + ResetSection.Reporting: {
      return Object.assign({}, state, {profitReport: null, mechanicsReport : null, ordersReport : null , providerReport : null,allOrders:null});
    }

    case Do_GenericSuccessEvent.identifier + GetProvidersReportingEvent.identifier: {
      state.providerReport = (action as Do_GenericSuccessEvent<ReportingSet<ReportingProviderItem>>).data;
      return Object.assign({}, state);
    }
    case Do_GenericSuccessEvent.identifier + GetProvidersListReportingEvent.identifier: {
      state.providerListReport = (action as Do_GenericSuccessEvent<ReportingSet<ReportingProviderItem>>).data;
      return Object.assign({}, state);
    }
    case Do_GenericSuccessEvent.identifier + GetProfitReportingEvent.identifier: {
      state.profitReport = (action as Do_GenericSuccessEvent<ReportingSet<ReportingListProfitItem>>).data;
      return Object.assign({}, state);
    }
    case Do_GenericSuccessEvent.identifier + GetMechanicsReportingEvent.identifier: {
      state.mechanicsReport = (action as Do_GenericSuccessEvent<ReportingSet<ReportingMechanicItem>>).data;
      return Object.assign({}, state);
    }
    case Do_GenericSuccessEvent.identifier + GetOrdersReportingEvent.identifier: {
      state.ordersReport = (action as Do_GenericSuccessEvent<ReportingSet<ReportingItem>>).data;
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + GetAllCarServiceEntriesForReportingEvent.identifier: {
      state.allOrders = (action as Do_GenericSuccessEvent<Array<CarService_Dto>>).data;
      return Object.assign({}, state);
    }
    case Do_GenericErrorEvent.identifier + GetAllCarServiceEntriesForReportingEvent.identifier: {
      state.allOrders = null;
      return Object.assign({}, state);
    }

    default: {
      return state;
    }
  }
}

export const getProfitReportCallback = (state: ReportingState) => state.profitReport;
export const getProviderReportCallback = (state: ReportingState) => state.providerReport;
export const getProviderListReportCallback = (state: ReportingState) => state.providerListReport;
export const getMechanicReportCallback = (state: ReportingState) => state.mechanicsReport;
export const getOrderReportCallback = (state: ReportingState) => state.ordersReport;
export const getAllOrderReportCallback = (state: ReportingState) => state.allOrders;
