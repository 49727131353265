import { Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import { map, switchMap } from 'rxjs/operators';
import { Do_GenericSuccessEvent, Do_GenericErrorEvent, EventWithScreenLoading, SeamlessEvent } from 'src/app/core/app-store/events/base.events';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { ProfileApiService } from 'src/app/core/api-integration/services/profile-api.service';
import { GetProfileEvent, EditProfileEvent, ProfileImageChanged, DeleteUserImageProfileUrlEvent } from 'src/app/core/app-store/events/profile.events';
import { User_Dto, FileUploadTarget } from 'src/app/core/app-dto/core.dto';
import { UploadInputFinishedEvent } from '../../core/app-store';



@Injectable()
export class ProfileUIEventProcessor {

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private profileApiService: ProfileApiService
    ) {
    }

    @Effect()
    getProfile$: Observable<any> = this.actions$
        .pipe(
            ofType(GetProfileEvent.identifier, EventWithScreenLoading.identifier + GetProfileEvent.identifier),
            map((action: GetProfileEvent) => action),
            switchMap((action: GetProfileEvent) => {
                return new Observable((observer) => {
                    this.profileApiService.u_getProfile()
                        .subscribe((res: User_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<User_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    editProfile$: Observable<any> = this.actions$
        .pipe(
            ofType(EditProfileEvent.identifier, EventWithScreenLoading.identifier + EditProfileEvent.identifier),
            map((action: EditProfileEvent) => action),
            switchMap((action: EditProfileEvent) => {
                return new Observable((observer) => {
                    this.profileApiService.u_updateProfile(action.model)
                        .subscribe((res: User_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<User_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doDeleteUserImageProfileUrlEvent$
        :
        Observable<any> = this.actions$
            .pipe(
                ofType(DeleteUserImageProfileUrlEvent.identifier, SeamlessEvent.identifier + DeleteUserImageProfileUrlEvent.identifier),
                map((action: DeleteUserImageProfileUrlEvent) => action),
                switchMap((action: DeleteUserImageProfileUrlEvent) => {
                    return new Observable((observer) => {
                        this.profileApiService.u_deleteProfileImageUrl()
                            .subscribe((res: any) => {
                                if (ObjectValidators.isValidObject(res))
                                    observer.next(new Do_GenericSuccessEvent<string>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
                )
            );
}
