import { PagedResult } from '../../shared/datatable/datatable.helpers';
import { Action } from '@ngrx/store';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from '../events/base.events';
import { ResetStateActionProxy } from '../../shared/base/base.action-proxy';
import { Department_Dto } from '../../app-dto/department.dto';
import {
  ActivateDepartmentEvent,
  DeactivateDepartmentEvent, DeleteDepartmentEvent, EditDepartmentEvent,
  GetDepartmentEvent, GetDepartmentsEvent,
  GetDepartmentsPagedResultEvent,
  RegisterDepartmentEvent
} from '../events/department.events';
import * as moment from 'moment';
import {Mechanic_Dto} from "../../app-dto/mechanic.dto";
import {ObjectValidators} from "../../shared/object.validators";

export interface DepartmentState {
  lastRefreshTime: number;
  department: Department_Dto;
  departmentsPagedResult: PagedResult<Department_Dto>;
  departments: Department_Dto[];
}

export const INITIAL_STATE: DepartmentState = Object.assign({}, {
  lastRefreshTime: 0,
  department: null,
  departmentsPagedResult: new PagedResult<Department_Dto>([]),
  departments: null
});


export function reducer(state = INITIAL_STATE, action: Action): DepartmentState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {
    // Get Department

    case Do_ResetStateForSection.identifier + ResetSection.Department: {
      return Object.assign({}, state, { department: null });
    }

    case Do_GenericSuccessEvent.identifier + GetDepartmentEvent.identifier: {
      const departmentData = new Department_Dto((action as Do_GenericSuccessEvent<Department_Dto>).data);
      return Object.assign({}, state, { department: departmentData });
    }

    case Do_GenericErrorEvent.identifier + GetDepartmentEvent.identifier: {
      return Object.assign({}, state, { department: null });
    }

    case ResetStateActionProxy.name + GetDepartmentEvent.identifier: {
      return Object.assign({}, state, { department: null });
    }

    // Get Department

    // Get Departments

    case Do_ResetStateForSection.identifier + ResetSection.Departments: {
      return Object.assign({}, state, { departmentsPagedResult: new PagedResult<Department_Dto>([]) });
    }

    case GetDepartmentsPagedResultEvent.identifier: {
      if ((action as GetDepartmentsPagedResultEvent).withReset) {
        state.departmentsPagedResult = new PagedResult<Department_Dto>([]);
        return Object.assign({}, state);
      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetDepartmentsPagedResultEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<PagedResult<Department_Dto>>).data;
      state.departmentsPagedResult = new PagedResult<Department_Dto>(data);
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetDepartmentsPagedResultEvent.identifier: {
      state.departmentsPagedResult = new PagedResult<Department_Dto>([]);
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + GetDepartmentsEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<Department_Dto[]>).data;
      state.departments = data;
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetDepartmentsEvent.identifier: {
      state.departments = null;
      return Object.assign({}, state);
    }

    // Get Departments

    case Do_GenericSuccessEvent.identifier + RegisterDepartmentEvent.identifier: {
      state.lastRefreshTime =  moment().unix();
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + DeactivateDepartmentEvent.identifier:
    case Do_GenericSuccessEvent.identifier + ActivateDepartmentEvent.identifier:
    case Do_GenericSuccessEvent.identifier + EditDepartmentEvent.identifier: {
      const data = new Department_Dto((action as Do_GenericSuccessEvent<Department_Dto>).data);
      if(ObjectValidators.isValidObject(state.department) && state.department.id == data.id) {
        state.department = data;
      }
      state.lastRefreshTime =  moment().unix();
      return Object.assign({}, state);

    }

    case Do_GenericSuccessEvent.identifier + DeleteDepartmentEvent.identifier: {
      state.lastRefreshTime =  moment().unix();
      return Object.assign({}, state);
    }

    default: {
      return state;
    }
  }
}

export const getTimestampCallback = (state: DepartmentState) => state.lastRefreshTime;
export const getDepartmentCallback = (state: DepartmentState) => state.department;
export const getDepartmentsPagedResultCallback = (state: DepartmentState) => state.departmentsPagedResult;
export const getDepartmentsCallback = (state: DepartmentState) => state.departments;
