import {Component, Input, OnInit} from '@angular/core';
import {Offer_Dto} from '../../../core/app-dto/offer.dto';
import {EditCarServiceCarWorksComponent} from '../../../car-service/components/edit-car-service-car-works/edit-car-service-car-works.component';
import {BsModalService} from 'ngx-bootstrap';
import {UpdateOfferClientDataComponent} from '../update-offer-client-data/update-offer-client-data.component';

@Component({
    selector: 'app-offer-details-list-client-details',
    templateUrl: './offer-details-list-client-details.component.html',
    styleUrls: ['./offer-details-list-client-details.component.scss']
})
export class OfferDetailsListClientDetailsComponent implements OnInit {

    @Input() offer: Offer_Dto;
    @Input() isReadOnlyValue: boolean = false;

    constructor(private modalService: BsModalService) {
    }

    ngOnInit() {
    }

    editClient() {
        const modalRef = this.modalService.show(UpdateOfferClientDataComponent, {backdrop: 'static', keyboard: false, class: 'modal-xl'});
        modalRef.content.dataInput = this.offer;
    }

}
