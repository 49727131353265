import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { CarPart_Dto, CarPartAggregate_Dto } from 'src/app/core/app-dto/car-part.dto';
import { Supply_Dto } from '../../../core/app-dto/supply.dto';

@Injectable()
export class CarPartDetailsSandbox extends BaseSandbox {
    public getCarPart$ = this.appState$.pipe(select(store.getCarPartAggregate));
    public timestamp$ = this.appState$.pipe(select(store.getSupplyItemsTimestamp));
    private lastRefreshTime = 0;
    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    clearSandboxState() {
    }

    initialize(carPart: (data: CarPart_Dto, supplies: Supply_Dto[]) => {}, resetData:()=>void) {
        this.clearSandboxState();

        this.subscriptions.push(this.getCarPart$.subscribe((data: CarPartAggregate_Dto) => {
            if (ObjectValidators.isValidObject(data)) {
                carPart(data.carPart, data.supplies);
            }
        }));
        this.subscriptions.push(this.timestamp$.subscribe((result: number) => {
            if (result > this.lastRefreshTime) {
                this.lastRefreshTime = result;
                resetData();
            }
        }));
    }
}
