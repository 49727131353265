import { Component, OnInit } from '@angular/core';
import {StaticDataSandbox} from "./static-data.sandbox";

@Component({
  selector: 'app-static-data',
  templateUrl: './static-data.component.html',
  styleUrls: ['./static-data.component.scss']
})
export class StaticDataComponent implements OnInit {

  constructor(public sandbox: StaticDataSandbox) { }

  ngOnInit() {
    this.sandbox.initialize();
  }

  ngOnDestroy(){
    this.sandbox.clearSandboxState();
  }

}
