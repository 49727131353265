import {EventWithScreenLoading, SeamlessEvent, UIGeneratedEvent} from "./base.events";
import { DataTableParameters} from "../../shared/datatable/datatable.helpers";
import {SMSPackageType} from "../../app-dto/sms.dto";

export class GetSMSSentPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "GetSMSSentPagedResultEvent";
  constructor(public parameters: DataTableParameters, public withReset: boolean = false) {
    super();
    this.type += GetSMSSentPagedResultEvent.identifier;
  }
}
export class GetSMSBoughtPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "GetSMSBoughtPagedResultEvent";
  constructor(public parameters: DataTableParameters, public withReset: boolean = false) {
    super();
    this.type += GetSMSBoughtPagedResultEvent.identifier;
  }
}

export class GetAvailablePackagesForSMSEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier:string = "GetAvailablePackagesForSMSEvent";
  constructor(public callback: (data:Array<SMSPackageType>)=>void) {
    super();
    this.type += GetAvailablePackagesForSMSEvent.identifier;
  }
}
export class GetAvailablePackagesForSMSForPlatformAdminEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier:string = "GetAvailablePackagesForSMSForPlatformAdminEvent";
  constructor(public callback: (data:Array<SMSPackageType>)=>void) {
    super();
    this.type += GetAvailablePackagesForSMSForPlatformAdminEvent.identifier;
  }
}

export class BuySMSForOrganizationAsPlatformAdminEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "BuySMSForOrganizationAsPlatformAdminEvent";
  constructor(public organizationId:string,public packageId:string) {
    super();
    this.type += BuySMSForOrganizationAsPlatformAdminEvent.identifier;
  }
}
