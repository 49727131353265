import { Injectable } from '@angular/core';
import * as store from '../../../app-store/index';
import { select, Store } from '@ngrx/store';
import {BaseSandboxWithCurrentUserLoaded} from 'src/app/core/shared/base/base.sandbox';
import {LoggedUser_Dto} from "../../../app-dto/core.dto";

@Injectable()
export class CheckMechanicPlatformBusySandbox extends BaseSandboxWithCurrentUserLoaded {

  constructor(protected appState$: Store<store.State>) {
    super(appState$);
  }

  public clearSandboxState() {
  }

  public initialize(dataLoaded: (data: LoggedUser_Dto) => void): any {
    this.clearSandboxState();

    super.loadUser(()=>{
      if(this.currentUser != null && dataLoaded) dataLoaded(this.currentUser);
    });
  }
}
