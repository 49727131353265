import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseFormModelComponent} from '../../../core/shared/base/base.component';
import {CarPart_Dto, CarServiceCarPart_Dto} from '../../../core/app-dto/car-part.dto';
import {RegisterCarServiceCarPartActionProxy} from '../../../car-service/components/car-service-save-car-part/register-car-service-car-part.action-proxy';
import {EditCarServiceCarPartActionProxy} from '../../../car-service/components/car-service-save-car-part/edit-car-service-car-part.action-proxy';
import {GetCarPartActionProxy} from '../../../car-part/components/car-part-details/get-car-part.action-proxy';
import {GetSuppliersActionProxy} from '../../../car-part/components/save-car-part/get-suppliers.action-proxy';
import {Subject} from 'rxjs';
import {CarPartFormComponent} from '../../../car-part/components/car-part-form/car-part-form.component';
import {CarPartSearchComponent} from '../../../car-part/components/car-part-search/car-part-search.component';
import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {CarServiceSaveCarPartSandbox} from '../../../car-service/components/car-service-save-car-part/car-service-save-car-part.sandbox';
import {BsModalRef} from 'ngx-bootstrap';
import {NotifierService} from 'angular-notifier';
import {AppUserBusinessRuleProvider} from '../../../core/core/business-rule-provider/app-user.business-rule.provider';
import {ActivatedRoute} from '@angular/router';
import {ResetSection} from '../../../core/app-store/events/base.events';
import {isValidArrayAndHasElements, isValidObject} from '../../../core/shared/helpers/common.helpers';
import {UpdateOfferAddServiceCarPartActionProxy} from '../../common/ap/update-offer-add-service-car-part.action-proxy';
import {UpdateOfferUpdateServiceCarPartActionProxy} from '../../common/ap/update-offer-update-service-car-part.action-proxy';
import {Offer_Dto} from '../../../core/app-dto/offer.dto';

@Component({
    selector: 'app-update-offer-save-service-car-part',
    templateUrl: './update-offer-save-service-car-part.component.html',
    styleUrls: ['./update-offer-save-service-car-part.component.scss']
})
export class UpdateOfferSaveServiceCarPartComponent extends BaseFormModelComponent<UpdateOfferServiceCarPartInput> implements OnInit, OnDestroy {
    public addOfferCarPartAP: UpdateOfferAddServiceCarPartActionProxy;
    public editOfferCarPartAP: UpdateOfferUpdateServiceCarPartActionProxy;
    public getSupplierAP: GetSuppliersActionProxy;
    public title: string;
    public onClose: Subject<boolean>;

    public data: CarPart_Dto = null;
    public offer: Offer_Dto = null;
    public isAddginNewItem: boolean = false;
    public offerId: string;


    @ViewChild(CarPartFormComponent, {static: false})
    private carPartFormComponent: CarPartFormComponent;

    @ViewChild(CarPartSearchComponent, {static: false}) carPartSearchComponent: CarPartSearchComponent;


    constructor(public appState: Store<store.State>,
                public sandbox: CarServiceSaveCarPartSandbox,
                private activeModal: BsModalRef,
                private notifierService: NotifierService,
                public rulesProvider: AppUserBusinessRuleProvider,
                public activatedRoute: ActivatedRoute) {
        super(sandbox, ResetSection.CarPart);
        this.addOfferCarPartAP = new UpdateOfferAddServiceCarPartActionProxy(this, sandbox.appState);
        this.editOfferCarPartAP = new UpdateOfferUpdateServiceCarPartActionProxy(this, sandbox.appState);
        this.getSupplierAP = new GetSuppliersActionProxy(this, sandbox.appState);
        this.getSupplierAP.execute();
    }

    public diInitialize(data: UpdateOfferServiceCarPartInput) {
        this.data = data.carPart;
        this.offer = data.offer;
        if (isValidObject(this.data)) {
            this.title = 'Editare piesa service';
        } else {
            this.isAddginNewItem = true;
            this.title = 'Adauga piesa service';
        }
        this.dataLoaded = true;
    }

    ngOnInit() {
        this.onClose = new Subject();
    }

    ngOnDestroy() {
        this.destroy();
    }

    public decline() {
        this.onClose.next(false);
        this.activeModal.hide();
    }

    public accept() {
        this.carPartFormComponent.accept();
    }

    public formSaved(model: CarPart_Dto) {
        if (model.id) {
            model.isEdit = !this.isAddginNewItem;
            this.editOfferCarPartAP.execute(this.offer.id, model);
        } else {
            this.addOfferCarPartAP.execute(this.offer.id, model);
        }
        this.onClose.next(true);
        this.activeModal.hide();
    }

    carPartSelected(value: CarPart_Dto) {
        if (isValidObject(value)) {
            this.data = new CarPart_Dto(value);
        } else {
            this.data = null;
        }
    }
}

export class UpdateOfferServiceCarPartInput {
    constructor(public offer: Offer_Dto, public carPart: CarPart_Dto) {
    }
}
