import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';
import { FormGroup, FormControl, Validators } from '@angular/forms';

export class CarServiceSaveFinalInspectionDi extends BaseFormDI<CarService_Dto> {

    constructor(data: CarService_Dto = new CarService_Dto(null)) {
        super(new CarService_Dto(data));
        this.initForm();
    }

    getModel(): CarService_Dto {
        this.model.finalInspection.brakes = this.form.value.brakes;
        this.model.finalInspection.functional = this.form.value.functional;
        this.model.finalInspection.geometry = this.form.value.geometry;
        this.model.finalInspection.hazards = this.form.value.hazards;
        this.model.finalInspection.headlights = this.form.value.headlights;
        this.model.finalInspection.roadTest = this.form.value.roadTest;
        this.model.finalInspection.windscreen = this.form.value.windscreen;
        this.model.finalInspection.startUser = this.form.value.startUser;
        this.model.finalInspection.endUser = this.form.value.endUser;

        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
            brakes: new FormControl(this.model.finalInspection.brakes, Validators.compose([Validators.required])),
            functional: new FormControl(this.model.finalInspection.functional, Validators.compose([Validators.required])),
            geometry: new FormControl(this.model.finalInspection.geometry, Validators.compose([Validators.required])),
            hazards: new FormControl(this.model.finalInspection.hazards, Validators.compose([Validators.required])),
            headlights: new FormControl(this.model.finalInspection.headlights, Validators.compose([Validators.required])),
            roadTest: new FormControl(this.model.finalInspection.roadTest, Validators.compose([Validators.required])),
            windscreen: new FormControl(this.model.finalInspection.windscreen, Validators.compose([Validators.required])),
            startUser: new FormControl(this.model.finalInspection.startUser, Validators.compose([Validators.required])),
            endUser: new FormControl(this.model.finalInspection.endUser, Validators.compose([Validators.required])),
        });
    }
}
