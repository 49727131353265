import { Action } from '@ngrx/store';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from '../events/base.events';
import {Draft_Dto} from '../../app-dto/draft.dto';
import {DeleteDraftEvent, GetDraftsEvent, RegisterDraftEvent} from '../events/draft.events';
import {isValidArrayAndHasElements} from "../../shared/helpers/common.helpers";
import * as moment from "moment";

export interface DraftState {
  lastRefreshTime: number;
  drafts: Draft_Dto[];
}

export const INITIAL_STATE: DraftState = Object.assign({}, {
  lastRefreshTime: 0,
  drafts: []
});


export function reducer(state = INITIAL_STATE, action: Action): DraftState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {
    // Get Drafts

    case Do_ResetStateForSection.identifier + ResetSection.Drafts: {
      state.drafts = [];
      return Object.assign({}, state);
    }

    case GetDraftsEvent.identifier: {
      if ((action as GetDraftsEvent)) {
        state.drafts = [];
        return Object.assign({}, state);
      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetDraftsEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<Draft_Dto[]>).data;
      state.drafts = data;
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetDraftsEvent.identifier: {
      state.drafts = [];
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + DeleteDraftEvent.identifier:
    case Do_GenericSuccessEvent.identifier + RegisterDraftEvent.identifier: {
      
      state.lastRefreshTime= moment().unix();
      return Object.assign({}, state);
    }



    default: {
      return state;
    }
  }
}

export const getTimestampCallback = (state: DraftState) => state.lastRefreshTime;
export const getDraftsCallback = (state: DraftState) => state.drafts;
