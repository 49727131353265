import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  BaseFormComponent,
  BaseWebComponent,
  BaseWebComponentWithBreadcrumb
} from 'src/app/core/shared/base/base.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GetOrganizationClientActionProxy } from './get-organization-client.action-proxy';
import { OrganizationClientDetailsSandbox } from './organization-client-details.sandbox';
import { OrganizationClientDetailsDi } from './organization-client-details.di';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { BsModalService } from 'ngx-bootstrap';
import { OrganizationClient_Dto } from '../../../core/app-dto/organization-client.dto';
import { SaveOrganizationClientComponent } from '../save-organization-client/save-organization-client.component';
import { DeleteOrganizationClientActionProxy } from '../organization-clients-list/delete-organization-client.action-proxy';
import { ConfirmationDialogService } from "../../../core/core/dialog/confirmation-dialog.service";
import {Breadcrumb} from "../../../core/app-dto/breadcrumb.dto";

@Component({
  selector: 'app-organization-client-details',
  templateUrl: './organization-client-details.component.html',
  styleUrls: ['./organization-client-details.component.scss']
})
export class OrganizationClientDetailsComponent extends BaseWebComponentWithBreadcrumb implements OnInit, OnDestroy {
  public getOrganizationClientAP: GetOrganizationClientActionProxy;
  public deleteOrganizationClientAP: DeleteOrganizationClientActionProxy;
  public organizationClientId: string = null;

  constructor(public sandbox: OrganizationClientDetailsSandbox,
    public activatedRoute: ActivatedRoute,
    private router: Router, public confirmationDialogService: ConfirmationDialogService,
    private modalService: BsModalService
  ) {
    super(sandbox, ResetSection.OrganizationClient);
    this.getOrganizationClientAP = new GetOrganizationClientActionProxy(this, sandbox.appState);
    this.deleteOrganizationClientAP = new DeleteOrganizationClientActionProxy(this, sandbox.appState);
    this.di = new OrganizationClientDetailsDi();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id !== undefined) {
        this.organizationClientId = params.id;
        this.getOrganizationClientAP.execute(this.organizationClientId, ()=>{

        });
      }
    });

    this.initialize((data: OrganizationClient_Dto) => {
      this.di = new OrganizationClientDetailsDi(data);
      this.setBreadcrumbAP.execute([ new Breadcrumb({text: 'Acasa', url: '/dashboard'}),new Breadcrumb({text: 'Client', url:'/client/all'}),
        new Breadcrumb({text:data.name})]);
      this.dataLoaded = true;
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  deleteOrganizationClient(organizationClientId: string) {
    this.confirmationDialogService.confirm(
      "Stergere client",
      "Urmeaza sa stergeti clientul. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
        if (confirmed) {
          this.deleteOrganizationClientAP.execute(organizationClientId, () => {
            this.router.navigate(['/client/all']);
          });
        }
      });

  }

  updateOrganizationClient() {
    const modalRef = this.modalService.show(SaveOrganizationClientComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = this.di.model;
  }
}
