import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCalendarModule } from '../calendar/calendar.module';
import { CarPartModule } from '../car-part/car-part.module';
import { UploadModule } from '../core/upload/upload.module';
import { CoreModule } from '../core/core/core.module';
import { CalendarModule } from 'angular-calendar';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CarModule } from '../car/car.module';
import { ArchwizardModule } from 'angular-archwizard';
import { Ng5SliderModule } from 'ng5-slider';
import { DataTablesModule } from 'angular-datatables';
import { RouterModule } from '@angular/router';
import { DocumentsModule } from '../documents/documents.module';
import {BsDatepickerModule, ModalModule, TimepickerModule} from 'ngx-bootstrap';

import { AddCarInServiceComponent } from './components/add-car-in-service/add-car-in-service.component';
import { AddCarInServiceSandbox } from './components/add-car-in-service/add-car-in-service.sandbox';
import { OrganizationClientModule } from '../organization-client/organization-client.module';
import { CarServiceDataFormComponent } from './components/car-service-data-form/car-service-data-form.component';
import { CarServicePhotosComponent } from './components/car-service-photos/car-service-photos.component';
import { CarServiceCompleteComponent } from './components/car-service-complete/car-service-complete.component';
import { CarServiceDataFormSandbox } from './components/car-service-data-form/car-service-data-form.sandbox';
import { CarServiceListComponent } from './components/car-service-list/car-service-list.component';
import { CarServiceListSandbox } from './components/car-service-list/car-service-list.sandbox';
import { CarServicePhotosSandbox } from './components/car-service-photos/car-service-photos.sandbox';
import { CarServiceCompleteSandbox } from './components/car-service-complete/car-service-complete.sandbox';
import { CarServiceClientPartsComponent } from './components/car-service-client-parts/car-service-client-parts.component';
import { CarServiceClientPartsSandbox } from './components/car-service-client-parts/car-service-client-parts.sandbox';
import { CarServiceSearchComponent } from './components/car-service-search/car-service-search.component';
import { CarServiceSearchSandbox } from './components/car-service-search/car-service-search.sandbox';
import { CarServiceDetailsComponent } from './components/car-service-details/car-service-details.component';
import { CarServiceDetailsSandbox } from './components/car-service-details/car-service-details.sandbox';
import { EditCarServiceClientComponent } from './components/edit-car-service-client/edit-car-service-client.component';
import { EditCarServiceClientSandbox } from './components/edit-car-service-client/edit-car-service-client.sandbox';
import { EditCarServiceCarSandbox } from './components/edit-car-service-car/edit-car-service-car.sandbox';
import { EditCarServiceCarComponent } from './components/edit-car-service-car/edit-car-service-car.component';
import { EditCarServiceDataComponent } from './components/edit-car-service-data/edit-car-service-data.component';
import { EditCarServiceDataSandbox } from './components/edit-car-service-data/edit-car-service-data.sandbox';
import { CarServiceListDashboardComponent } from './components/car-service-list-dashboard/car-service-list-dashboard.component';
import { CarServiceListDashboardSandbox } from './components/car-service-list-dashboard/car-service-list-dashboard.sandbox';
import { CarServiceActionListComponent } from './components/car-service-action-list/car-service-action-list.component';
import { CarServiceAddActionComponent } from './components/car-service-add-action/car-service-add-action.component';
import { CarServiceAddActionSandbox } from './components/car-service-add-action/car-service-add-action.sandbox';
import { CarServiceDetailsCarPartsComponent } from './components/car-service-details-car-parts/car-service-details-car-parts.component';
import { CarServiceDetailsCarPartsSandbox } from './components/car-service-details-car-parts/car-service-details-car-parts.sandbox';
import { CarServiceSaveCarPartComponent } from './components/car-service-save-car-part/car-service-save-car-part.component';
import { CarServiceSaveCarPartSandbox } from './components/car-service-save-car-part/car-service-save-car-part.sandbox';
import { CarServiceActionListSandbox } from './components/car-service-action-list/car-service-action-list.sandbox';
import { CarServiceDetailsClientCarPartsComponent } from './components/car-service-details-client-car-parts/car-service-details-client-car-parts.component';
import { CarServiceDetailsClientCarPartsSandbox } from './components/car-service-details-client-car-parts/car-service-details-client-car-parts.sandbox';
import { CarServiceSaveClientCarPartComponent } from './components/car-service-save-client-car-part/car-service-save-client-car-part.component';
import { CarServiceSaveClientCarPartSandbox } from './components/car-service-save-client-car-part/car-service-save-client-car-part.sandbox';
import { CarServiceDetailsWorkmanshipsComponent } from './components/car-service-details-workmanships/car-service-details-workmanships.component';
import { CarServiceSaveWorkmanshipComponent } from './components/car-service-save-workmanship/car-service-save-workmanship.component';
import { WorkmanshipModule } from '../workmanship/workmanship.module';
import { CarServiceDetailsWorkmanshipsSandbox } from './components/car-service-details-workmanships/car-service-details-workmanships.sandbox';
import { CarServiceSaveWorkmanshipSandbox } from './components/car-service-save-workmanship/car-service-save-workmanship.sandbox';
import { CarServiceOverviewUnauthorizedComponent } from './components/car-service-overview-unauthorized/car-service-overview-unauthorized.component';
import { CarServiceOverviewUnauthorizedSandbox } from './components/car-service-overview-unauthorized/car-service-overview-unauthorized.sandbox';
import { OpenAddCarInServiceComponent } from './components/open-add-car-in-service/open-add-car-in-service.component';
import { CarServiceStatisticsComponent } from './components/car-service-statistics/car-service-statistics.component';
import {CarServiceStatisticsSandbox} from './components/car-service-statistics/car-service-statistics.sandbox';
import { CarServiceFinalInspectionComponent } from './components/car-service-final-inspection/car-service-final-inspection.component';
import { CarServiceSaveFinalInspectionComponent } from './components/car-service-save-final-inspection/car-service-save-final-inspection.component';
import { CarServiceFinalInspectionSandbox } from './components/car-service-final-inspection/car-service-final-inspection.sandbox';
import { CarServiceSaveFinalInspectionSandbox } from './components/car-service-save-final-inspection/car-service-save-final-inspection.sandbox';
import { CarServiceCreditLimitComponent } from './components/car-service-credit-limit/car-service-credit-limit.component';
import { CarServiceCreditLimitSandbox } from './components/car-service-credit-limit/car-service-credit-limit.sandbox';
import { AddEditCarInServiceWizardComponent } from './components/add-edit-car-in-service-wizard/add-edit-car-in-service-wizard.component';
import { AddEditCarInServiceWizardSandbox } from './components/add-edit-car-in-service-wizard/add-edit-car-in-service-wizard.sandbox';
import { CarServiceClientComponent } from './components/car-service-client/car-service-client.component';
import { CarServiceClientSandbox } from './components/car-service-client/car-service-client.sandbox';
import { CarServiceCarSandbox } from './components/car-service-car/car-service-car.sandbox';
import { CarServiceCarComponent } from './components/car-service-car/car-service-car.component';
import { CarServiceSummaryComponent } from './components/car-service-summary/car-service-summary.component';
import { CarServiceSummarySandbox } from './components/car-service-summary/car-service-summary.sandbox';
import { CarServiceCarWorksSandbox } from './components/car-service-car-works/car-service-car-works.sandbox';
import { CarServiceCarWorksComponent } from './components/car-service-car-works/car-service-car-works.component';
import { CarWorkModule } from '../car-works/car-work.module';
import { CarServiceListsOverviewComponent } from './components/car-service-lists-overview/car-service-lists-overview.component';
import { EditCarServiceCarWorksSandbox } from './components/edit-car-service-car-works/edit-car-service-car-works.sandbox';
import { EditCarServiceCarWorksComponent } from './components/edit-car-service-car-works/edit-car-service-car-works.component';


@NgModule({
  declarations: [
    AddCarInServiceComponent,
    CarServiceDataFormComponent,
    CarServicePhotosComponent,
    CarServiceCompleteComponent,
    CarServiceListComponent,
    CarServiceListDashboardComponent,
    CarServiceClientPartsComponent,
    CarServiceDetailsCarPartsComponent,
    CarServiceSearchComponent,
    CarServiceDetailsComponent,
    EditCarServiceClientComponent,
    EditCarServiceCarComponent,
    EditCarServiceDataComponent,
    CarServiceActionListComponent,
    CarServiceAddActionComponent,
    CarServiceSaveCarPartComponent,
    CarServiceDetailsClientCarPartsComponent,
    CarServiceSaveClientCarPartComponent,
    CarServiceDetailsWorkmanshipsComponent,
    CarServiceSaveWorkmanshipComponent,
    CarServiceOverviewUnauthorizedComponent,
    OpenAddCarInServiceComponent,
    CarServiceStatisticsComponent,
    CarServiceFinalInspectionComponent,
    CarServiceSaveFinalInspectionComponent,
    CarServiceCreditLimitComponent,
    AddEditCarInServiceWizardComponent,
    CarServiceClientComponent,
    CarServiceCarComponent,
    CarServiceSummaryComponent,
    CarServiceCarWorksComponent,
    CarServiceListsOverviewComponent,
    EditCarServiceCarWorksComponent
  ],
  imports: [
    CoreModule,
    CommonModule,
    CalendarModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    DataTablesModule,
    RouterModule,
    NgSelectModule,
    ArchwizardModule,
    CarModule,
    CarWorkModule,
    OrganizationClientModule,
    Ng5SliderModule,
    CustomCalendarModule,
    CarPartModule,
    WorkmanshipModule,
    UploadModule,
    DocumentsModule,
    BsDatepickerModule,
    TimepickerModule,
    ModalModule.forRoot()
  ],
  providers: [
    AddCarInServiceSandbox,
    CarServiceDataFormSandbox,
    CarServiceListSandbox,
    CarServiceListDashboardSandbox,
    CarServicePhotosSandbox,
    CarServiceCompleteSandbox,
    CarServiceClientPartsSandbox,
    CarServiceDetailsCarPartsSandbox,
    CarServiceSearchSandbox,
    CarServiceDetailsSandbox,
    EditCarServiceClientSandbox,
    EditCarServiceCarSandbox,
    EditCarServiceDataSandbox,
    CarServiceAddActionSandbox,
    CarServiceSaveCarPartSandbox,
    CarServiceActionListSandbox,
    CarServiceDetailsClientCarPartsSandbox,
    CarServiceSaveClientCarPartSandbox,
    CarServiceDetailsWorkmanshipsSandbox,
    CarServiceSaveWorkmanshipSandbox,
    CarServiceOverviewUnauthorizedSandbox,
    CarServiceStatisticsSandbox,
    CarServiceFinalInspectionSandbox,
    CarServiceSaveFinalInspectionSandbox,
    CarServiceCreditLimitSandbox,
    AddEditCarInServiceWizardSandbox,
    CarServiceClientSandbox,
    CarServiceCarSandbox,
    CarServiceSummarySandbox,
    CarServiceCarWorksSandbox,
    EditCarServiceCarWorksSandbox
  ],
  entryComponents: [
    AddCarInServiceComponent,
    EditCarServiceClientComponent,
    EditCarServiceCarComponent,
    EditCarServiceDataComponent,
    CarServiceAddActionComponent,
    CarServiceSaveCarPartComponent,
    CarServiceSaveClientCarPartComponent,
    CarServiceSaveWorkmanshipComponent,
    CarServiceSaveFinalInspectionComponent,
    AddEditCarInServiceWizardComponent,
    EditCarServiceCarWorksComponent
  ],
    exports: [
        AddCarInServiceComponent,
        CarServiceListDashboardComponent,
        CarServiceStatisticsComponent,
        AddEditCarInServiceWizardComponent,
        CarServiceClientPartsComponent,
        CarServicePhotosComponent
    ]
})
export class CarServiceModule { }
