import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {ConfigService} from "./core/core/app-config.service";
import {AppSandbox} from "./app.sandbox";
import {AppUserBusinessRuleProvider} from "./core/core/business-rule-provider/app-user.business-rule.provider";
import {isValidObject} from "./core/shared/helpers/common.helpers";
import {LoggedUser_Dto, StickyHeaderNotification, StickyHeaderNotificationImportance} from './core/app-dto/core.dto';
import {BsLocaleService} from "ngx-bootstrap";
import {defineLocale} from 'ngx-bootstrap/chronos';
import {roLocale} from 'ngx-bootstrap/locale';
import {DeviceDetectorService} from 'ngx-device-detector';
import {BaseWebComponent} from "./core/shared/base/base.component";
import * as moment from "moment";
import {AddStickyHeaderNotificationActionProxy} from "./core/core/components/sticky-header-notification/add-sticky-header-notification.action-proxy";
import {ObjectValidators} from "./core/shared/object.validators";

defineLocale('ro', roLocale);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseWebComponent implements OnInit {

  public showHeader = false;
  public showSidebar = false;
  public showFooter = false;
  public isMobile: boolean = false;

  public dataLoaded: boolean = false;
  public organizationId: string = null;

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        let data = this.activatedRoute.firstChild.snapshot.data;
        if (isValidObject(this.activatedRoute.firstChild.firstChild)) {
          if (isValidObject(this.activatedRoute.firstChild.firstChild.snapshot))
            if (isValidObject(this.activatedRoute.firstChild.firstChild.snapshot.data)) {
              data = this.activatedRoute.firstChild.firstChild.snapshot.data;
            }
        }
        this.showHeader = data.showHeader !== false;
        this.showSidebar = data.showSidebar !== false;
        this.showFooter = data.showFooter !== false;
      }
    });
  }

  constructor(private titleService: Title, public sandbox: AppSandbox, private router: Router, private activatedRoute: ActivatedRoute,
              private configService: ConfigService, public rulesProvider: AppUserBusinessRuleProvider,
              private localeService: BsLocaleService, private deviceService: DeviceDetectorService) {
    super(sandbox, null);
    this.localeService.use("ro");
    this.isMobile = this.deviceService.isMobile();
    this.sandbox.initialize(this, (loggedUser: LoggedUser_Dto) => {
      this.organizationId = loggedUser.organizationId;
      this.generateStickyNotificationsForCurrentUser(loggedUser);
      if (loggedUser.organization != null) {
        this.titleService.setTitle("Servify - " + loggedUser.organization.name);
      }
      else {
        this.titleService.setTitle("Servify - Administrare");
      }
      this.dataLoaded = true;
    });
  }

  private generateStickyNotificationsForCurrentUser(user: LoggedUser_Dto) {
    if (isValidObject(user.organization) && isValidObject(user.organization.subscription)) {
      const ap = new AddStickyHeaderNotificationActionProxy(this, this.sandbox.appState);
      if (user.organization.subscription.subscriptionState == 'trialing') {
        const deadlineForTrialNotification = moment().add(3, 'days').toDate();

        if (ObjectValidators.isValidDate(user.organization.subscription.expiresOn) && user.organization.subscription.expiresOn < deadlineForTrialNotification) {
          const noOfDaysForTrialing = moment(user.organization.subscription.expiresOn).diff(moment(), 'days');
          ap.execute(new StickyHeaderNotification({
            title: `Au mai ramas doar ${noOfDaysForTrialing} zile din perioada de proba.`,
            description: `Adauga acum metoda de plata pentru a continua sa cresti profitul afacerii tale.`,
            hasDismissOption: true,
            type: StickyHeaderNotificationImportance.low
          }));
        }
      }
      if (user.organization.subscription.subscriptionState == 'past_due') {
        ap.execute(new StickyHeaderNotification({
          title: `Plata subscriptiei nu s-a putut efectua.`,
          description: '  Adauga acum metoda de plata pentru a continua sa cresti profitul afacerii tale.',
          hasDismissOption: true,
          type: StickyHeaderNotificationImportance.medium
        }));
      }
    }
  }
}

