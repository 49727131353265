import {Injectable} from '@angular/core';
import {BaseApiService} from './base-api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiErrorDto} from '../utils/api-error.dto';
import {ConfigService} from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {DataTableParameters, PagedResult} from '../../shared/datatable/datatable.helpers';
import {ErrorHelpers} from '../../shared/error.helpers';
import {
  ReportingGroupinOption,
  ReportingItem, ReportingListProfitItem,
  ReportingMechanicItem,
  ReportingProviderItem,
  ReportingSet
} from "../../app-dto/reporting.dto";
import {CarService_Dto} from "../../app-dto/car-service.dto";

class ReportApiRoutes {
  public static route_for_GET_PROFIT_REPORTING() {
    return '/api/reporting/profit';
  }

  public static route_for_GET_PROVIDERS_REPORTING() {
    return '/api/reporting/providers';
  }
  public static route_for_GET_PROVIDERS_LIST_REPORTING() {
    return '/api/reporting/providers-list';
  }

  public static route_for_GET_ORDERS_REPORTING() {
    return '/api/reporting/orders';
  }

  public static route_for_GET_MECHANICS_REPORTING() {
    return '/api/reporting/mechanics';
  }

  public static route_for_GET_CAR_SERVICE_ENTRIES_PAGINATED() {
    return '/api/reporting/all-orders';
  }

}

@Injectable()
export class ReportingApiService extends BaseApiService {

  constructor(private http: HttpClient, configService: ConfigService) {
    super(configService);
  }

  u_getProfitReport(groupingOption: ReportingGroupinOption, startDate: Date, endDate: Date): Observable<ReportingSet<ReportingListProfitItem>> {
    const url = this.configService.getAPIBaseUrl() + ReportApiRoutes.route_for_GET_PROFIT_REPORTING() + "?groupOption=" + groupingOption + "&startDate=" + startDate.toISOString() + "&endDate=" + endDate.toISOString();

    return this.http.get(url)
      .map((res: any) => {
        let result: ReportingSet<ReportingListProfitItem> = new ReportingSet<ReportingListProfitItem>([]);
        if (res.data != null) result = new ReportingSet<ReportingListProfitItem>(res.data.map(r => new ReportingListProfitItem(r)));
        return result;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getProvidersReport(startDate: Date, endDate: Date, providerId:string): Observable<ReportingSet<ReportingProviderItem>> {
    if(providerId ==null) providerId= "";
    const url = this.configService.getAPIBaseUrl() + ReportApiRoutes.route_for_GET_PROVIDERS_REPORTING()
      + "?startDate=" + startDate.toISOString() + "&endDate=" + endDate.toISOString()+ "&providerId=" + providerId;

    return this.http.get(url)
      .map((res: any) => {
        let result: ReportingSet<ReportingProviderItem> = new ReportingSet<ReportingProviderItem>([]);
        if (res.data != null) result = new ReportingSet<ReportingProviderItem>(res.data.map(r => new ReportingProviderItem(r)));
        return result;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }
  u_getProvidersListReport(startDate: Date, endDate: Date, providerId:string): Observable<ReportingSet<ReportingProviderItem>> {
    if(providerId ==null) providerId= "";
    const url = this.configService.getAPIBaseUrl() + ReportApiRoutes.route_for_GET_PROVIDERS_LIST_REPORTING()
      + "?startDate=" + startDate.toISOString() + "&endDate=" + endDate.toISOString()+ "&providerId=" + providerId;

    return this.http.get(url)
      .map((res: any) => {
        let result: ReportingSet<ReportingProviderItem> = new ReportingSet<ReportingProviderItem>([]);
        if (res.data != null) result = new ReportingSet<ReportingProviderItem>(res.data.map(r => new ReportingProviderItem(r)));
        return result;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getOrdersReport(groupingOption: ReportingGroupinOption, startDate: Date, endDate: Date): Observable<ReportingSet<ReportingItem>> {
    const url = this.configService.getAPIBaseUrl() + ReportApiRoutes.route_for_GET_ORDERS_REPORTING() + "?groupOption=" + groupingOption + "&startDate=" + startDate.toISOString() + "&endDate=" + endDate.toISOString();

    return this.http.get(url)
      .map((res: any) => {
        let result: ReportingSet<ReportingItem> = new ReportingSet<ReportingItem>([]);
        if (res.data != null) result = new ReportingSet<ReportingItem>(res.data.map(r => new ReportingItem(r)));
        return result;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getMechanicsReport(startDate: Date, endDate: Date): Observable<ReportingSet<ReportingMechanicItem>> {
    const url = this.configService.getAPIBaseUrl() + ReportApiRoutes.route_for_GET_MECHANICS_REPORTING() + "?startDate=" + startDate.toISOString() + "&endDate=" + endDate.toISOString();

    return this.http.get(url)
      .map((res: any) => {
        let result: ReportingSet<ReportingMechanicItem> = new ReportingSet<ReportingMechanicItem>([]);
        if (res.data != null) result = new ReportingSet<ReportingMechanicItem>(res.data.map(r => new ReportingMechanicItem(r)));
        return result;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }


  u_getCarServiceEntriesPaginated(data: DataTableParameters, startDate: Date, endDate: Date): Observable<PagedResult<CarService_Dto>> {
    const url = this.configService.getAPIBaseUrl() + ReportApiRoutes.route_for_GET_CAR_SERVICE_ENTRIES_PAGINATED() + "?startDate=" + startDate.toISOString()
      + "&endDate=" + endDate.toISOString() + "&" +data.toUrlParameters();

    return this.http.get(url)
      .map((res: PagedResult<CarService_Dto>) => {
        return new PagedResult<CarService_Dto>(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }
}
