import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared/shared.module';
import { DataTablesModule } from 'angular-datatables';

import { DepartmentsListComponent } from './components/department-list/departments-list.component';
import { DepartmentDetailsComponent } from './components/department-details/department-details.component';
import { SaveDepartmentComponent } from './components/save-department/save-department.component';
import { DepartmentFormComponent } from './components/department-form/department-form.component';

import { DepartmentDetailsSandbox } from './components/department-details/department-details.sandbox';
import { DepartmentsListSandbox } from './components/department-list/departments-list.sandbox';
import { DepartmentFormSandbox } from './components/department-form/department-form.sandbox';
import { SaveDepartmentSandbox } from './components/save-department/save-department.sandbox';
import {ModalModule} from "ngx-bootstrap";

@NgModule({
  declarations: [
    DepartmentsListComponent,
    DepartmentDetailsComponent,
    SaveDepartmentComponent,
    DepartmentFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    SharedModule,
    DataTablesModule,
    ModalModule.forRoot()
  ],
  providers: [
    DepartmentsListSandbox,
    DepartmentDetailsSandbox,
    SaveDepartmentSandbox,
    DepartmentFormSandbox
  ],
  entryComponents: [
    SaveDepartmentComponent
  ],
  exports: [
    DepartmentFormComponent
  ]
})
export class DepartmentModule { }
