import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { BaseFormComponent } from 'src/app/core/shared/base/base.component';
import { CarServiceSummarySandbox } from './car-service-summary.sandbox';
import { CarServiceSummaryDi } from './car-service-summary.di';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';
import { FileImageWrapper } from '../car-service-photos/car-service-photos.component';
import { EditCarServiceClientComponent } from '../edit-car-service-client/edit-car-service-client.component';
import { EditCarServiceCarComponent } from '../edit-car-service-car/edit-car-service-car.component';
import { EditCarServiceDataComponent } from '../edit-car-service-data/edit-car-service-data.component';
import { BsModalService } from 'ngx-bootstrap';

@Component({
    selector: 'app-car-service-summary',
    templateUrl: './car-service-summary.component.html',
    styleUrls: ['./car-service-summary.component.scss']
})
export class CarServiceSummaryComponent extends BaseFormComponent implements OnInit, OnDestroy {

    @Output()
    modelSaved = new EventEmitter<CarService_Dto>();

    @Input('carService') carService: CarService_Dto;
    @Input('urls') urls: Array<FileImageWrapper>;

    public isReadOnlyValue:boolean;
    @Input() set isReadOnly(value:boolean){
        this.isReadOnlyValue = value;
    }

    constructor(public appState: Store<store.State>,
                private modalService: BsModalService,
                public sandbox: CarServiceSummarySandbox) {
        super(sandbox);
    }

    ngOnInit() {
        this.di = new CarServiceSummaryDi(null);
    }

    ngOnDestroy() {
        this.destroy();
    }

    editClient() {
        const modalRef = this.modalService.show(EditCarServiceClientComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
        modalRef.content.dataInput = this.di.model;
    }

    editCar() {
        const modalRef = this.modalService.show(EditCarServiceCarComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
        modalRef.content.dataInput = this.di.model;
    }

    editCarService() {
        const modalRef = this.modalService.show(EditCarServiceDataComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
        modalRef.content.dataInput = this.di.model;
    }
}
