import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import {GetCalendarEventsEvent, GetCalendarEventStatisticsEvent} from 'src/app/core/app-store/events/calendar-events';
import { NgxSpinnerService } from 'ngx-spinner';
import {CalendarState} from "../../../core/app-store/stores/calendar.store";
import {CalendarStatistics} from "../../../core/app-dto/calendar-event.dto";
import {GetCarServiceEntryStatisticsEvent} from "../../../core/app-store/events/car-service.events";
import {CarServiceEntryStatistics} from "../../../core/app-dto/car-service.dto";

export class GetCarServiceEntryStatisticsActionProxy extends BaseActionProxy {
  constructor(component: IWebComponent,
              appState: Store<store.State>,
              private spinner: NgxSpinnerService) {
    super(component, appState);
  }

  canExecute(): boolean {
    return true;
  }

  execute(callback:(data:CarServiceEntryStatistics)=>void): any {
    setTimeout(() => {
      this.spinner.show('car-service-statistics');
    }, 0);
    this.appState.dispatch(new GetCarServiceEntryStatisticsEvent( (data:CarServiceEntryStatistics) => {
      this.spinner.hide('car-service-statistics');
      callback(data);
    }));
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }
}

