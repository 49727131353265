import { State } from '../state';
import * as fromReporting from '../stores/reporting.store';
import { createSelector } from '@ngrx/store';

export const getReportingStateCallback = (state: State) => state.reporting;

export const getProfitReport = createSelector(getReportingStateCallback, fromReporting.getProfitReportCallback);
export const getProviderReport = createSelector(getReportingStateCallback, fromReporting.getProviderReportCallback);
export const getProviderListReport = createSelector(getReportingStateCallback, fromReporting.getProviderListReportCallback);
export const getOrderReport = createSelector(getReportingStateCallback, fromReporting.getOrderReportCallback);
export const getAllOrderReport = createSelector(getReportingStateCallback, fromReporting.getAllOrderReportCallback);
export const getMechanicReport = createSelector(getReportingStateCallback, fromReporting.getMechanicReportCallback);
