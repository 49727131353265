import { ObjectValidators } from '../shared/object.validators';
import { Base_Dto, BaseCRUDRules, BusinessRule } from './misc.dto';
import * as moment from 'moment';
import { CarPart_Dto } from './car-part.dto';
import { CachingUtils } from '../shared/caching.utils';
import { LoggedUser_Dto } from './core.dto';

export class WorkmanshipRules extends BaseCRUDRules {
  public canActivate: BusinessRule;
  public canDeactivate: BusinessRule;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.canActivate = new BusinessRule(data.canActivate);
      this.canDeactivate = new BusinessRule(data.canDeactivate);

    } else {
      this.canActivate = new BusinessRule();
      this.canDeactivate = new BusinessRule();
    }
  }
}

export class Workmanship_Dto extends Base_Dto {
  public description: string;
  public totalValue: number;
  public totalValueHasVAT: boolean;
  public totalValueWithVAT: number;
  public currency: string;
  public quantity: number;
  public pricePerUnit: number;
  public rules: WorkmanshipRules;
  public relatedActionId?: string;
  public createdOn: Date;
  public updatedOn: Date;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.description = data.description;
      this.totalValue = data.totalValue;
      this.totalValueHasVAT = data.totalValueHasVAT;
      this.totalValueWithVAT = data.totalValueWithVAT;
      this.currency = data.currency;
      this.quantity = data.quantity;
      this.pricePerUnit = data.pricePerUnit;
      this.relatedActionId = data.relatedActionId;
      this.rules = new WorkmanshipRules(data.rules);
      if (ObjectValidators.isValidObject(data.createdOn))
        this.createdOn = moment.utc(data.createdOn).toDate();
      if (ObjectValidators.isValidObject(data.updatedOn))
        this.updatedOn = moment.utc(data.updatedOn).toDate();
    }
  }
}

export class CarServiceWorkmanship_Dto {
  public carServiceId: string;
  public workmanship: Workmanship_Dto;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      this.carServiceId = data.carServiceId;
      this.workmanship = new Workmanship_Dto(data.workmanship);
    }
  }
}
