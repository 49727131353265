import { Component, EventEmitter, HostListener, Input, NgZone, OnInit, Output } from '@angular/core';
import { UploadNotificationSandbox } from './upload-notification.sandbox';
import { DIUploadItem } from './upload-notification.di';
import { BaseWebComponent } from "../../../shared/base/base.component";
import { ObjectValidators } from "../../../shared/object.validators";
import { isValidObject } from "../../../shared/helpers/common.helpers";
import { FileAttachment, FileToUpload } from "../../../app-dto/core.dto";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs';
import { ActionsSubject } from '@ngrx/store';
import { SeamlessEvent } from 'src/app/core/app-store/events/base.events';
import { UploadInputEvent } from 'src/app/core/app-store';

@Component({
  selector: 'upload-notification',
  templateUrl: './upload-notification.component.html',
  styleUrls: ['./upload-notification.component.css']
})
export class UploadNotificationComponent extends BaseWebComponent implements OnInit {

  @Output() uploadCompleted = new EventEmitter<Array<FileAttachment>>();
  @Input("shouldDisplayFileNames") shouldDisplayFileNames: boolean = false;
  public uploadingNotifications = 0;
  public uploadedNotifications = 0;
  public showNotification: boolean = false;
  public message: string = '';
  public showNotificationMenu: boolean = false;
  public isUploading: boolean = false;

  private searchSubscription = new Subscription();

  constructor(
    public sandbox: UploadNotificationSandbox,
    private spinner: NgxSpinnerService,
    private actionsSubj: ActionsSubject
  ) {
    super(sandbox);


    this.searchSubscription = actionsSubj.subscribe(data => {
      if (data.type == SeamlessEvent.identifier + UploadInputEvent.identifier) {
        let uploadInput = (<UploadInputEvent>data).uploadInput;
        if (ObjectValidators.isValidNonEmptyArray(uploadInput.filesToUpload)) {
          //this.initializeUpload(uploadInput);

          console.log("initialize: " + NgZone.isInAngularZone());
          this.isUploading = true;
          if (this.shouldDisplayFileNames == false) this.spinner.show().then((obj) => {

          }, (error) => {

          });
          this.sandbox.initializeUpload(uploadInput, (value) => {
            this.uploadingNotifications = value.uploading;
            this.uploadedNotifications = value.uploaded;
            console.log("sandbox callback: " + NgZone.isInAngularZone());
            // 
            if (value.uploading == 0) {
              if (this.shouldDisplayFileNames == false) {
                setTimeout(() => {
                  this.spinner.hide();
                }, 0);
              }
            }

            this.message = 'upload';

            if (this.uploadedNotifications > 0) {
              if (this.uploadedNotifications == 1)
                this.message = 'One file uploaded';
              if (this.uploadedNotifications > 1)
                this.message = this.uploadedNotifications + ' files uploaded';

              this.showNotification = true;
            }

            if (this.uploadingNotifications > 0) {
              if (this.uploadingNotifications == 1)
                this.message = 'One file uploading'
              if (this.uploadingNotifications > 1)
                this.message = this.uploadingNotifications + ' files uploading';

              this.showNotification = true;
            }

            if (this.uploadedNotifications == 0 && this.uploadingNotifications == 0 && this.showNotification == true) {
              //$('.uploading-notification-btn').click();
              this.showNotification = false;
            }
            if (value.uploading == 0 && value.uploaded > 0) {
              this.isUploading = false;
            }
            if (this.sandbox.fileUploaded.length > 0) {
              this.sandbox.dispatchUploadInputFinishedEvent(this.sandbox.fileUploaded[0]);
              this.sandbox.fileUploaded.splice(0, 1);
              //this.uploadCompleted.emit(this.sandbox.fileUploaded);
            }
          });

        }
      }
    });
  }

  @Input("filesToUpload") set filesToUpload(value: UploadInput) {
    if (isValidObject(value)) {
      if (ObjectValidators.isValidNonEmptyArray(value.filesToUpload)) {
        this.initializeUpload(value);
      }
    }
  }

  ngOnInit() {
    this.sandbox.initialize();
  }

  public initializeUpload(data: UploadInput) {
  }

  ngOnDestroy() {
    this.destroy();
  }

  removeAll(event: any) {
    event.stopPropagation();
    this.sandbox.removeAll();
  }

  cancelItem(file: DIUploadItem, event: any) {

    event.stopPropagation();
    if (file != null) {
      file.cancelItem();
      if (file.progressValue == 100)
        this.sandbox.removeUploadWithoutDelete(file);
      else this.sandbox.removeUploadWithDelete(file);
    }
  }

  toggleNotificationMenu() {
    this.showNotificationMenu = !this.showNotificationMenu;
  }

  closeNotificationMenu() {
    this.showNotificationMenu = false;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.uploadingNotifications > 0) {
      $event.returnValue = true;
    }
  }
}


export class UploadInput {
  constructor(public filesToUpload: Array<FileToUpload>,
    public purpose: number) {

  }
}
