import { Component, Input, OnInit } from '@angular/core';
import { CalendarEventState, CalendarEventStateLabel } from '../../../app-dto/calendar-event.dto';

@Component({
  selector: 'app-event-status-label',
  templateUrl: './event-status-button.component.html',
  styleUrls: ['./event-status-button.component.scss']
})
export class EventStatusButtonComponent implements OnInit {

  public backgroundColor: string = '#00616B';
  @Input('status') status: CalendarEventState;
  @Input('statusLabel') statusLabel: string;

  constructor() {}

  ngOnInit() {
    if (this.status) {
      this.statusLabel = CalendarEventStateLabel[this.status];
    }
    switch (this.status) {
      case CalendarEventState.Appointment:
        this.backgroundColor = '#201853';
        break;
      case CalendarEventState.Todo:
        this.backgroundColor = '#201853';
        break;
      case CalendarEventState.InProgress:
        this.backgroundColor = '#00616B';
        break;
      case CalendarEventState.Waiting:
        this.backgroundColor = '#00587F';
        break;
      case CalendarEventState.Reception:
        this.backgroundColor = '#00306C';
        break;
      case CalendarEventState.Done:
        this.backgroundColor = '#007567';
        break;
      case CalendarEventState.LeftTheService:
        this.backgroundColor = '#00885B';
        break;
    }
  }
}
