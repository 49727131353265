import {Observable} from "rxjs";
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Injectable} from "@angular/core";
import * as store from "../../core/app-store";
import "rxjs-compat/add/operator/switchMap";
import {map, switchMap} from "rxjs/operators";
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  EventWithScreenLoading,
  SeamlessEvent
} from 'src/app/core/app-store/events/base.events';
import {PagedResult} from 'src/app/core/shared/datatable/datatable.helpers';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {
  DoSagaExportForInvoicesEvent,
  GetInvoiceEvent,
  GetInvoicesPagedResultEvent,
  GetOrganizationInvoiceEvent,
  GetOrganizationInvoicesPagedResultEvent,
  GetOrganizationSubscriptionInvoiceEvent,
  GetOrganizationSubscriptionInvoicesPagedResultEvent,
  GetOrgInvoiceDownloadLink,
  GetPayButtonAuthEvent,
  GetPayButtonUnAuthEvent,
  GetSubInvoiceDownloadLink,
  GetUnauthorizedInvoiceEvent,
  MarkOrgInvoiceAsNotPaidEvent,
  MarkOrgInvoiceAsPaidEvent,
  MarkSubInvoiceAsNotPaidEvent,
  MarkSubInvoiceAsPaidEvent,
  RegenerateOrgInvoiceEvent,
  UpdateInvoiceDeadlineEvent
} from 'src/app/core/app-store/events/invoices.events';
import {Invoice_Dto, InvoiceDetails_Dto} from 'src/app/core/app-dto/invoice.dto';
import {InvoiceApiService} from 'src/app/core/api-integration/services/invoice-api.service';
import {isValidObject} from "../../core/shared/helpers/common.helpers";


@Injectable()
export class InvoiceUIEventProcessor {

  constructor(
    private actions$: Actions,
    private appState$: Store<store.State>,
    private invoiceApiService: InvoiceApiService,
  ) {
  }

  @Effect()
  getInvoicePagedResult$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GetInvoicesPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetInvoicesPagedResultEvent.identifier),
      map((action: GetInvoicesPagedResultEvent) => action),
      switchMap((action: GetInvoicesPagedResultEvent) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_getFilteredSubscriptionInvoicesPaginated(action.parameters)
              .subscribe((res: PagedResult<Invoice_Dto>) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<PagedResult<Invoice_Dto>>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  getOrganizationInvoicePagedResult$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GetOrganizationInvoicesPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetOrganizationInvoicesPagedResultEvent.identifier),
      map((action: GetOrganizationInvoicesPagedResultEvent) => action),
      switchMap((action: GetOrganizationInvoicesPagedResultEvent) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_getOrganizationFilteredInvoicesPaginated(action.parameters, action.organizationId)
              .subscribe((res: PagedResult<Invoice_Dto>) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<PagedResult<Invoice_Dto>>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  getInvoice$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GetInvoiceEvent.identifier, EventWithScreenLoading.identifier + GetInvoiceEvent.identifier),
      map((action: GetInvoiceEvent) => action),
      switchMap((action: GetInvoiceEvent) => {
          return new Observable((observer) => {

            this.invoiceApiService.u_getInvoice(action.invoiceId)
              .subscribe((res: InvoiceDetails_Dto) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<InvoiceDetails_Dto>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  getOrganizationInvoice$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GetOrganizationInvoiceEvent.identifier, EventWithScreenLoading.identifier + GetOrganizationInvoiceEvent.identifier),
      map((action: GetOrganizationInvoiceEvent) => action),
      switchMap((action: GetOrganizationInvoiceEvent) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_getOrganizationInvoice(action.invoiceId, action.organizationId)
              .subscribe((res: InvoiceDetails_Dto) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<InvoiceDetails_Dto>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  getOrganizationSubInvoice$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GetOrganizationSubscriptionInvoiceEvent.identifier, EventWithScreenLoading.identifier + GetOrganizationSubscriptionInvoiceEvent.identifier),
      map((action: GetOrganizationSubscriptionInvoiceEvent) => action),
      switchMap((action: GetOrganizationSubscriptionInvoiceEvent) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_getSubscriptionInvoiceForOrganization(action.invoiceId, action.organizationId)
              .subscribe((res: InvoiceDetails_Dto) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<InvoiceDetails_Dto>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  getUnauthorizedInvoice$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GetUnauthorizedInvoiceEvent.identifier, EventWithScreenLoading.identifier + GetUnauthorizedInvoiceEvent.identifier),
      map((action: GetUnauthorizedInvoiceEvent) => action),
      switchMap((action: GetUnauthorizedInvoiceEvent) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_getUnauthorizedInvoice(action.invoiceId, action.code)
              .subscribe((res: InvoiceDetails_Dto) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<InvoiceDetails_Dto>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  getUnauthorizedInvoicePayButton$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GetPayButtonUnAuthEvent.identifier, SeamlessEvent.identifier + GetPayButtonUnAuthEvent.identifier),
      map((action: GetPayButtonUnAuthEvent) => action),
      switchMap((action: GetPayButtonUnAuthEvent) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_getUnauthorizedPayButton(action.invoiceId, action.code)
              .subscribe((res: string) => {
                if (ObjectValidators.isValidString(res))
                  action.callback(res);
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  getAuthorizedInvoicePayButton$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GetPayButtonAuthEvent.identifier, SeamlessEvent.identifier + GetPayButtonAuthEvent.identifier),
      map((action: GetPayButtonAuthEvent) => action),
      switchMap((action: GetPayButtonAuthEvent) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_getAuthorizedPayButton(action.invoiceId)
              .subscribe((res: string) => {
                if (ObjectValidators.isValidString(res))
                  action.callback(res);
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  markOrgInvoiceAsPaid$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(MarkOrgInvoiceAsPaidEvent.identifier, EventWithScreenLoading.identifier + MarkOrgInvoiceAsPaidEvent.identifier),
      map((action: MarkOrgInvoiceAsPaidEvent) => action),
      switchMap((action: MarkOrgInvoiceAsPaidEvent) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_markOrganizationInvoiceAsPaid(action.invoiceId, action.organizationId)
              .subscribe((res: Invoice_Dto) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<Invoice_Dto>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  regenerateOrgInvoiceAsPaid$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(RegenerateOrgInvoiceEvent.identifier, EventWithScreenLoading.identifier + RegenerateOrgInvoiceEvent.identifier),
      map((action: RegenerateOrgInvoiceEvent) => action),
      switchMap((action: RegenerateOrgInvoiceEvent) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_regenerateOrganizationInvoice(action.invoiceId, action.organizationId)
              .subscribe((res: Invoice_Dto) => {
                if (ObjectValidators.isValidObject(res)) {
                  observer.next(new Do_GenericSuccessEvent<Invoice_Dto>(res, action));
                  if (isValidObject(action.callback)) action.callback(res.id);
                }
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  markOrgInvoiceAsNotPaid$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(MarkOrgInvoiceAsNotPaidEvent.identifier, EventWithScreenLoading.identifier + MarkOrgInvoiceAsNotPaidEvent.identifier),
      map((action: MarkOrgInvoiceAsNotPaidEvent) => action),
      switchMap((action: MarkOrgInvoiceAsNotPaidEvent) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_markOrganizationInvoiceAsNotPaid(action.invoiceId, action.organizationId)
              .subscribe((res: Invoice_Dto) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<Invoice_Dto>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  markSubInvoiceAsPaid$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(MarkSubInvoiceAsPaidEvent.identifier, EventWithScreenLoading.identifier + MarkSubInvoiceAsPaidEvent.identifier),
      map((action: MarkSubInvoiceAsPaidEvent) => action),
      switchMap((action: MarkSubInvoiceAsPaidEvent) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_markSubscriptionInvoiceAsPaid(action.invoiceId)
              .subscribe((res: Invoice_Dto) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<Invoice_Dto>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  markSubInvoiceAsNotPaid$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(MarkSubInvoiceAsNotPaidEvent.identifier, EventWithScreenLoading.identifier + MarkSubInvoiceAsNotPaidEvent.identifier),
      map((action: MarkSubInvoiceAsNotPaidEvent) => action),
      switchMap((action: MarkSubInvoiceAsNotPaidEvent) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_markSubscriptionInvoiceAsNotPaid(action.invoiceId)
              .subscribe((res: Invoice_Dto) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<Invoice_Dto>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  downloadOrgInvoice$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GetOrgInvoiceDownloadLink.identifier, EventWithScreenLoading.identifier + GetOrgInvoiceDownloadLink.identifier),
      map((action: GetOrgInvoiceDownloadLink) => action),
      switchMap((action: GetOrgInvoiceDownloadLink) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_getDownloadLinkForOrganizationInvoice(action.invoiceId, action.organizationId)
              .subscribe((res: string) => {
                if (ObjectValidators.isValidString(res)) {
                  observer.next(new Do_GenericSuccessEvent<string>(res, action));
                  if (isValidObject(action.callback)) action.callback(res);
                }
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  downloadSubInvoice$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GetSubInvoiceDownloadLink.identifier, EventWithScreenLoading.identifier + GetSubInvoiceDownloadLink.identifier),
      map((action: GetSubInvoiceDownloadLink) => action),
      switchMap((action: GetSubInvoiceDownloadLink) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_getDownloadLinkForSubscriptionInvoice(action.invoiceId)
              .subscribe((res: string) => {
                if (ObjectValidators.isValidString(res)) {
                  observer.next(new Do_GenericSuccessEvent<string>(res, action));
                  if (isValidObject(action.callback)) action.callback(res);
                }
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  getOrganizationSubscriptionInvoicePagedResult$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GetOrganizationSubscriptionInvoicesPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetOrganizationSubscriptionInvoicesPagedResultEvent.identifier),
      map((action: GetOrganizationSubscriptionInvoicesPagedResultEvent) => action),
      switchMap((action: GetOrganizationSubscriptionInvoicesPagedResultEvent) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_getOrganizationFilteredSubscriptionInvoicesPaginated(action.parameters, action.organizationId)
              .subscribe((res: PagedResult<Invoice_Dto>) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<PagedResult<Invoice_Dto>>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  updateInvoiceDeadlineEvent$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(UpdateInvoiceDeadlineEvent.identifier, EventWithScreenLoading.identifier + UpdateInvoiceDeadlineEvent.identifier),
      map((action: UpdateInvoiceDeadlineEvent) => action),
      switchMap((action: UpdateInvoiceDeadlineEvent) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_updateInvoiceDeadlineEvent(action.invoice)
              .subscribe((res: InvoiceDetails_Dto) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<InvoiceDetails_Dto>(res, action));
                observer.complete();
                if (action.callback) action.callback(res);
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  doSagaExportForInvoiceEvent$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(DoSagaExportForInvoicesEvent.identifier, EventWithScreenLoading.identifier + DoSagaExportForInvoicesEvent.identifier),
      map((action: DoSagaExportForInvoicesEvent) => action),
      switchMap((action: DoSagaExportForInvoicesEvent) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_startSagaExport(action.data)
              .subscribe((res: any) => {
                observer.next(new Do_GenericSuccessEvent<any>(res, action));
                observer.complete();
                if (action.callback) action.callback();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

}
