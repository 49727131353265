import {isValidObject} from "../shared/helpers/common.helpers";

export class NumericLookupDto {
    public key: string = '';
    public name: string = '';
    public value: number = 0;

    constructor(data?: any) {
        if (isValidObject(data)) {
            this.key = data.key;
            this.name = data.name;
            this.value = data.value;
        }
    }

    public static initWith(key: string, name: string, value: number): NumericLookupDto {
        let instance = new NumericLookupDto();
        instance.key = key;
        instance.name = name;
        instance.value = value;
        return instance;
    }
}
