import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppointmentCar_Dto } from '../../../core/app-dto/appointment-car.dto';

export class AppointmentCarDi extends BaseFormDI<AppointmentCar_Dto> {

    constructor(data: AppointmentCar_Dto = new AppointmentCar_Dto(null)) {
        super(new AppointmentCar_Dto(data));
        this.initForm();
    }

    getModel(): AppointmentCar_Dto {
        this.model.make = this.form.value.make;
        this.model.model = this.form.value.model;
        this.model.bodySeries = this.form.value.bodySeries;

        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
            make: new FormControl(this.model.make, Validators.compose([Validators.required])),
            model: new FormControl(this.model.model, Validators.compose([])),
            bodySeries: new FormControl(this.model.bodySeries, Validators.compose([])),
        });
    }
}
