export interface BaseComponentState {
  loading: boolean;
  loaded: boolean;
  failed: boolean;
  value: any;
  stateMessage: string;
}

export var BASE_INITIAL_STATE: BaseComponentState = {
  loading: false,
  loaded: false,
  failed: false,
  value: null,
  stateMessage:''
};

export var BASE_EXECUTING_STATE: BaseComponentState = {
  loading: true,
  loaded: false,
  failed: false,
  value: null,
  stateMessage: ''
};

export var BASE_SUCCEEDED_STATE: BaseComponentState = {
  loading: false,
  loaded: true,
  failed: false,
  value : null,
  stateMessage:''
};

export var BASE_FAILED_STATE: BaseComponentState = {
  loading: false,
  loaded: false,
  failed: true,
  value: null,
  stateMessage: ''
};
