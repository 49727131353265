import { FormGroup, FormControl } from '@angular/forms';
import { map, join } from 'lodash';

let typeCache: { [label: string]: boolean } = {};

type Predicate = (oldValues: Array<any>, newValues: Array<any>) => boolean;

/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 * 
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels are unique.
 *
 * @param label
 */
export function type<T>(label: T | ''): T {
  if (typeCache[<string>label]) {
    throw new Error(`Action type "${label}" is not unqiue"`);
  }

  typeCache[<string>label] = true;

  return <T>label;
}

/**
 * Runs through every condition, compares new and old values and returns true/false depends on condition state.
 * This is used to distinct if two observable values have changed.
 *
 * @param oldValues
 * @param newValues
 * @param conditions
 */
export function distinctChanges(oldValues: Array<any>, newValues: Array<any>, conditions: Predicate[]): boolean {
  if (conditions.every(cond => cond(oldValues, newValues))) return false;
  return true;
}

/**
 * Returns true if the given value is type of Object
 *
 * @param val
 */
export function isObject(val: any) {
  if (val === null) return false;

  return ((typeof val === 'function') || (typeof val === 'object'));
}

/**
 * Capitalizes the first character in given string
 *
 * @param s
 */
export function capitalize(s: string) {
  if (!s || typeof s !== 'string') return s;
  return s && s[0].toUpperCase() + s.slice(1);
}

/**
 * Uncapitalizes the first character in given string
 *
 * @param s
 */
export function uncapitalize(s: string) {
  if (!s || typeof s !== 'string') return s;
  return s && s[0].toLowerCase() + s.slice(1);
}

/**
 * Flattens multi dimensional object into one level deep
 *
 * @param obj
 * @param preservePath
 */
export function flattenObject(ob: any, preservePath: boolean = false): any {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) == 'object') {
      var flatObject = flattenObject(ob[i], preservePath);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        let path = preservePath ? (i + '.' + x) : x;

        toReturn[path] = flatObject[x];
      }
    } else toReturn[i] = ob[i];
  }

  return toReturn;
}

/**
 * Returns formated date based on given culture
 *
 * @param dateString
 * @param culture
 */
export function localeDateString(dateString: string, culture: string = 'en-EN'): string {
  let date = new Date(dateString);
  return date.toLocaleDateString(culture);
}


export function validateAllFormFields(formGroup: FormGroup) {
  if (formGroup != null && formGroup != undefined) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        validateAllFormFields(control);            //{6}
      }
    });
  }
}

export function isValidNumber(data: any): boolean {
  if (data != undefined && data != null) return !isNaN(data);
  return false;
}

export function isValidString(data: string): boolean {
  if (data != undefined && data != null && data != '') return true;
  return false;
}
export function isValidObject(data: any): boolean {
  if (data != undefined && data != null) return true;
  return false;
}


export function isValidArray(data: any): boolean {
  if (data != undefined && data != null && Array.isArray(data)) return true;
  return false;
}

export function isValidArrayAndHasElements(data: any): boolean {
  if (data != undefined && data != null && Array.isArray(data) && data.length > 0) return true;
  return false;
}

export function isNonEmptyArray(data: Array<any>): boolean {
  if (data != null && data.length > 0) return true;
  else return false;
}

function hasExtension(fileName, exts) {
  return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$',"i")).test(fileName);
}
export function isValidFile(data: File): boolean {
  return hasExtension(data.name, [".pdf", ".doc", ".docx", ".ppt", ".pps", ".pptx", ".ppsx", ".rtf", ".txt", ".xls", ".csv", ".xlsx", ".zip", ".rar", ".png", ".jpg", ".jpeg", ".gif", ".ps", ".eps", ".tiff", ".tif", ".mp4", ".ogv", ".ogg", ".webm", ".flv", ".mov", ".avi", ".wmv"]);
}

export function isValidImage(data: File): boolean {
  return hasExtension(data.name, [".png", ".jpg", ".jpeg"]);
}
export function isValidVideo(data: File): boolean {
  return hasExtension(data.name, [".mp4", ".ogv", ".ogg", ".webm", ".flv", ".mov", ".avi", ".wmv"]);
}

export function isValidIcsFile(data: File): boolean {
  return hasExtension(data.name, [".ics"]);
}

export function isValidExcelFile(data: File): boolean {
  return hasExtension(data.name, ['.xlsx', '.xls']);
}

var illegalRe = /[\/\?<>\\:\*\|":]/g;
var controlRe = /[\x00-\x1f\x80-\x9f]/g;
var reservedRe = /^\.+$/;
var windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
var windowsTrailingRe = /[\. ]+$/;

export function sanitize(input:string, replacement:string = '') {
  var sanitized = input
    .replace(illegalRe, replacement)
    .replace(controlRe, replacement)
    .replace(reservedRe, replacement)
    .replace(windowsReservedRe, replacement)
    .replace(windowsTrailingRe, replacement);
  return sanitized.substr(0,255);
}

export function getFormattedIdNameList(idNameList) {
  return join(map(idNameList, 'name'), ', ');
}

export function makeInputPositive(event: any) {
  if (event.keyCode === 189) {
    return;
  }
  const input: number = event.target.value;
  if (input < 0) {
    event.target.value = -input;
  }
}

export function makeInputPositiveInteger(event: any) {
  if (event.keyCode === 189) {
    return;
  }
  const input: number = Math.round(event.target.value);
  if (input < 0) {
    event.target.value = -input;
  } else {
    event.target.value = input;
  }
}

export function makeInputPositiveAndGreaterThenOne(event: any) {
  if (event.keyCode === 189) {
    return;
  }
  const input: number = event.target.value;
  if (input > -1 && input < 1) {
    event.target.value = 1;
  } else if (input < 0) {
    event.target.value = -input;
  }
}

export function makeValidDiscount(event: any) {
  if (event.keyCode === 189) {
    return;
  }
  const input: number = event.target.value;
  if (input < 0) {
    event.target.value = -input;
  }
  if (input > 100) {
    event.target.value = 100;
  }
}

export function makeValidDiscountAndGreaterThenOne(event: any) {
  if (event.keyCode === 189) {
    return;
  }
  const input: number = event.target.value;
  if (input > -1 && input < 1) {
    event.target.value = 1;
  } else if (input < 0) {
    event.target.value = -input;
  }
  if (input > 100) {
    event.target.value = 100;
  }
}
