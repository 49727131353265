import {Action} from '@ngrx/store';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from '../events/base.events';
import {GetServicePlatformStatusesEvent} from '../events/service-platform-status.events';
import {ServicePlatformStatus_Dto} from '../../app-dto/service-platform-status.dto';
import {ServicePlatform_Dto} from "../../app-dto/service-platform.dto";

export interface ServicePlatformStatusState {
  lastRefreshTime: number;
  servicePlatformStatusesPagedResult:Array<ServicePlatform_Dto>;
}

export const INITIAL_STATE: ServicePlatformStatusState = Object.assign({}, {
  lastRefreshTime: 0,
  servicePlatformStatusesPagedResult: null,
});


export function reducer(state = INITIAL_STATE, action: Action): ServicePlatformStatusState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {
    // Get Service Platform Statuses

    case Do_ResetStateForSection.identifier + ResetSection.ServicePlatformStatuses: {
      return Object.assign({}, state, {servicePlatformStatusesPagedResult: []});
    }

    case GetServicePlatformStatusesEvent.identifier: {

      state.servicePlatformStatusesPagedResult = null;
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + GetServicePlatformStatusesEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<Array<ServicePlatform_Dto>>).data;
      state.servicePlatformStatusesPagedResult = data;
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetServicePlatformStatusesEvent.identifier: {
      state.servicePlatformStatusesPagedResult = null;
      return Object.assign({}, state);
    }

    // Get Service Platform Statuses

    default: {
      return state;
    }
  }
}

export const getTimestampCallback = (state: ServicePlatformStatusState) => state.lastRefreshTime;
export const getServicePlatformStatusesPagedResultCallback = (state: ServicePlatformStatusState) => state.servicePlatformStatusesPagedResult;
