import { Store } from "@ngrx/store";
import * as store from '../../app-store';
import { Observable } from "rxjs";
import { Do_ResetStateForSection, ResetSection } from "../../app-store/events/base.events";
import { IWebComponent, IWebFormComponent } from "./base.component";

export interface IActionProxy {
  component: IWebComponent;
  appState: Store<store.State>
  isValidating: boolean;
  isExecuting: boolean;
  canExecute(): boolean;
  canExecuteAsync(): Observable<boolean>;
  execute();
}

export abstract class BaseActionProxy implements IActionProxy {
  component: IWebComponent;
  appState: Store<store.State>;
  isValidating: boolean = false;
  isExecuting: boolean = false;
  constructor(component: IWebComponent, appState: Store<store.State>) {
    this.component = component;
    this.appState = appState;
  }

  abstract canExecute(): boolean;
  abstract canExecuteAsync(): Observable<boolean>;
  abstract execute(param1?: any, param2?: any, param3?: any, param4?: any, param5?: any, param6?:any): boolean;
}

export abstract class BaseFormActionProxy implements IActionProxy {
  component: IWebFormComponent;
  appState: Store<store.State>;
  isValidating: boolean = false;
  isExecuting: boolean = false;
  constructor(component: IWebFormComponent, appState: Store<store.State>) {
    this.component = component;
    this.appState = appState;
  }

  abstract canExecute(): boolean;
  abstract canExecuteAsync(): Observable<boolean>;
  abstract execute(param1?: any, param2?: any): boolean;
}

export class ResetStateActionProxy {
  appState: Store<store.State>;
  resetSection: ResetSection;
  constructor(appState: Store<store.State>, resetSection: ResetSection) {
    this.appState = appState;
    this.resetSection = resetSection;
  }

  execute(): void {
    this.appState.dispatch(new Do_ResetStateForSection(this.resetSection));
  };
}

