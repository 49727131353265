import {Injectable} from '@angular/core';
import * as store from '../../../core/app-store/index';
import {select, Store} from '@ngrx/store';
import {BaseSandbox} from 'src/app/core/shared/base/base.sandbox';
import {ActiveDataTableParameters, DataTableParameters, PagedResult} from 'src/app/core/shared/datatable/datatable.helpers';
import {OrganizationClientsListItemDi} from './organization-clients-list-item.di';
import {OrganizationClient_Dto} from '../../../core/app-dto/organization-client.dto';
import {OffertedClientContact_Dto} from '../../../core/app-dto/offer.dto';
import {isValidArrayAndHasElements, isValidObject} from '../../../core/shared/helpers/common.helpers';

@Injectable()
export class OrganizationClientsListSandbox extends BaseSandbox {
    public organizationClients$ = this.appState$.pipe(select(store.getOrganizationClientsListing));
    public timestamp$ = this.appState$.pipe(select(store.getOrganizationClientTimestamp));

    public totalNumberOfResults: number = null;
    public callback: any;
    public dataTableParameters: ActiveDataTableParameters;
    public resetTableCallback: Function;
    public lastRefreshTime: number = 0;

    public totalPages: number = 0;
    public items: Array<OrganizationClientsListItemDi> = [];
    private draw: number = 1;


    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
        this.items = [];
        this.draw = 1;
        this.lastRefreshTime = 0;
    }

    public initialize(): void {
        this.clearSandboxState();
        this.subscriptions.push(this.organizationClients$.subscribe((data: PagedResult<OrganizationClient_Dto>) => {
            if (data != null && data.items != null) {
                this.items = data.items.map(r => new OrganizationClientsListItemDi(r));
                this.totalNumberOfResults = data.totalItems;
                this.totalPages = data.totalPages;
                if (this.callback != null) {
                    this.callback({
                        draw: this.draw++,
                        recordsTotal: data.totalItems,
                        recordsFiltered: data.totalItems,
                        data: []
                    });
                }
            } else {
                this.items = [];
                this.draw = 1;
            }
            this.processSelectionOnOffertedCients();
        }));

        this.subscriptions.push(this.timestamp$.subscribe((result: number) => {
            if (result > this.lastRefreshTime) {
                this.lastRefreshTime = result;
                this.resetTable();
            }
        }));
    }

    public setResetTableCallback(callback: Function) {
        this.resetTableCallback = callback;
    }

    public resetTable() {
        if (this.resetTableCallback != null) {
            this.resetTableCallback();
        }
    }

    public offertedClients: Array<OffertedClientContact_Dto> = [];

    public setOffertedClients(data: Array<OffertedClientContact_Dto>) {
        this.offertedClients = data;
        this.processSelectionOnOffertedCients();
    }

    private processSelectionOnOffertedCients() {
        if (isValidArrayAndHasElements(this.items) && isValidArrayAndHasElements(this.offertedClients)) {
            this.items.forEach((item) => {
                const foundItem = this.offertedClients.find(f => f.email === item.model.email && f.phoneNumber === item.model.phoneNumber
                    && f.name === item.model.name);
                if (isValidObject(foundItem)) {
                    item.isSelected = true;
                }
            });
        }
    }
}
