import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { RegisterMechanicActionProxy } from './register-mechanic.action-proxy';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { EditMechanicActionProxy } from './edit-mechanic.action-proxy';
import { GetMechanicActionProxy } from '../mechanic-details/get-mechanic.action-proxy';
import { ActivatedRoute } from '@angular/router';
import { SaveMechanicSandbox } from './save-mechanic.sandbox';
import { Mechanic_Dto } from '../../../core/app-dto/mechanic.dto';
import { SaveMechanicDI } from './save-mechanic.di';
import { StaticDataSelectorInput } from '../../../core/shared/components/static-data-selector/static-data-selector.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { GetDepartmentsActionProxy } from './get-departments.action-proxy';
import { IdName_Dto } from '../../../core/app-dto/misc.dto';
import { Department_Dto } from '../../../core/app-dto/department.dto';
import { SaveDepartmentComponent } from 'src/app/department/components/save-department/save-department.component';
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";
import {makeInputPositive} from '../../../core/shared/helpers/common.helpers';


@Component({
  selector: 'app-save-mechanic',
  templateUrl: './save-mechanic.component.html',
  styleUrls: ['./save-mechanic.component.scss']
})
export class SaveMechanicComponent extends BaseFormModelComponent<Mechanic_Dto> implements OnInit, OnDestroy {
  public registerMechanicAP: RegisterMechanicActionProxy;
  public editMechanicAP: EditMechanicActionProxy;
  public getMechanicAP: GetMechanicActionProxy;
  public getDepartmentAP: GetDepartmentsActionProxy;
  public title: string;
  public onClose: Subject<boolean>;
  public mechanicSpecializationTypesDataInput: StaticDataSelectorInput;
  public currenciesDataInput: StaticDataSelectorInput;
  public di : SaveMechanicDI;

  constructor(
    public appState: Store<store.State>,
    public sandbox: SaveMechanicSandbox,
    public activeModal: BsModalRef,
    public activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    public rulesProvider: AppUserBusinessRuleProvider

  ) {
    super(sandbox, ResetSection.SaveMechanic);
    this.registerMechanicAP = new RegisterMechanicActionProxy(this, sandbox.appState);
    this.editMechanicAP = new EditMechanicActionProxy(this, sandbox.appState);
    this.getMechanicAP = new GetMechanicActionProxy(this, sandbox.appState);
    this.getDepartmentAP = new GetDepartmentsActionProxy(this, sandbox.appState);
    this.getDepartmentAP.execute();
  }

  public diInitialize(data: Mechanic_Dto) {
    this.title = ObjectValidators.isValidObject(data) ? 'Editare mecanic' : 'Adauga mecanic';
    this.di = new SaveMechanicDI(data);
    this.mechanicSpecializationTypesDataInput = {
      form: this.di.form,
      type: 'mechanicSpecializationTypes',
      formControlName: 'mechanicSpecializationType',
      isRequired: true,
      label: 'Specializari',
      isReadOnly:false
    };
    this.currenciesDataInput = {
      form: this.di.form,
      type: 'currencies',
      formControlName: 'currency',
      isRequired: true,
      label: 'Moneda',
      isReadOnly:false
    };
    this.dataLoaded = true;
  }

  public changedDepartmentValue(value: Array<Department_Dto>): void {
    const control = this.di.form.controls.department;
    control.setValue(value);
  }

  ngOnInit() {
    this.onClose = new Subject();
    this.initialize();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.formSubmitted = true;
    if (this.di.model.id) {
      if (this.editMechanicAP.canExecute()) {
        this.editMechanicAP.execute();
        this.onClose.next(true);
        this.activeModal.hide();
      }
    } else {
      if (this.registerMechanicAP.canExecute()) {
        this.registerMechanicAP.execute();
        this.onClose.next(true);
        this.activeModal.hide();
      }
    }
  }

  createDepartment() {
    this.onClose.next(false);
    this.activeModal.hide();
    const modalRef = this.modalService.show(SaveDepartmentComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = null;
  }

  makeValuePositive(event: any) {
    makeInputPositive(event);
  }
}
