import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { Department_Dto } from '../../../core/app-dto/department.dto';
import { DepartmentFormDi } from './department-form.di';
import { DepartmentFormSandbox } from './department-form.sandbox';
import {StaticDataSelectorInput} from "../../../core/shared/components/static-data-selector/static-data-selector.component";
import {number} from 'ngx-custom-validators/src/app/number/validator';

@Component({
  selector: 'app-department-form',
  templateUrl: './department-form.component.html',
  styleUrls: ['./department-form.component.scss']
})
export class DepartmentFormComponent extends BaseFormModelComponent<Department_Dto> implements OnInit, OnDestroy {
  @Output()
  formSaved = new EventEmitter<Department_Dto>();
  public di:DepartmentFormDi;

  public currenciesDataInput: StaticDataSelectorInput;

  constructor(public appState: Store<store.State>,
              public sandbox: DepartmentFormSandbox
  ) {
    super(sandbox,null);
  }

  public diInitialize(data: Department_Dto) {
    this.di = new DepartmentFormDi(data);
    this.dataLoaded = true;
    this.currenciesDataInput = {
      form: this.di.form,
      type: 'currencies',
      formControlName: 'currency',
      isRequired: true,
      label: 'Moneda',
      isReadOnly:false
    };
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();
    if (this.di.isValid()) {
      if (this.formSaved != null) {
        this.formSaved.emit(this.di.getModel());
      }
    }
  }

  isValid() {
    return this.di.isValid();
  }

  makeNumberPositive() {
    const inputNumber: number = this.di.getModel().laborPricePerHour;
    if (inputNumber < 0) {
      this.di.form.get('laborPricePerHour').setValue(-inputNumber);
    }
  }
}
