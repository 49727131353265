import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared/shared.module';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectModule } from '@ng-select/ng-select';

import { CarPartsListComponent } from './components/car-parts-list/car-parts-list.component';
import { CarPartDetailsComponent } from './components/car-part-details/car-part-details.component';
import { SaveCarPartComponent } from './components/save-car-part/save-car-part.component';
import { CarPartFormComponent } from './components/car-part-form/car-part-form.component';
import { CarPartSearchComponent } from './components/car-part-search/car-part-search.component';

import { CarPartSearchSandbox } from './components/car-part-search/car-part-serach.sandbox';
import { CarPartFormSandbox } from './components/car-part-form/car-part-form.sandbox';
import { SaveCarPartSandbox } from './components/save-car-part/save-car-part.sandbox';
import { CarPartDetailsSandbox } from './components/car-part-details/car-part-details.sandbox';
import { CarPartsListSandbox } from './components/car-parts-list/car-parts-list.sandbox';
import { ClientCarPartFormComponent } from './components/client-car-part-form/client-car-part-form.component';
import { ClientCarPartFormSandbox } from './components/client-car-part-form/client-car-part-form.sandbox';
import { SupplyItemsComponent } from './components/supply-items/supply-items.component';
import {SupplyItemsSandbox} from "./components/supply-items/supply-items.sandbox";
import { TotalCarPartsValueComponent } from './components/total-car-parts-value/total-car-parts-value.component';
import {TotalCarPartsValueSandbox} from "./components/total-car-parts-value/total-car-parts-value.sandbox";

@NgModule({
  declarations: [
    CarPartsListComponent,
    CarPartDetailsComponent,
    SaveCarPartComponent,
    CarPartSearchComponent,
    CarPartFormComponent,
    ClientCarPartFormComponent,
    SupplyItemsComponent,
    TotalCarPartsValueComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    SharedModule,
    DataTablesModule
  ],
  providers: [
    CarPartsListSandbox,
    CarPartDetailsSandbox,
    SaveCarPartSandbox,
    CarPartSearchSandbox,
    CarPartFormSandbox,
    ClientCarPartFormSandbox,
    SupplyItemsSandbox,
    TotalCarPartsValueSandbox
  ],
  entryComponents: [
    SaveCarPartComponent
  ],
  exports: [
    CarPartFormComponent,
    CarPartSearchComponent,
    ClientCarPartFormComponent
  ]
})
export class CarPartModule { }
