import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseFormComponent } from "../../../shared/base/base.component";
import { ForgotPasswordActionProxy } from "../forgot-password/forget-password.action-proxy";
import { ForgotPasswordSandbox } from "../forgot-password/forgot-password.sandbox";
import { ResetSection } from "../../../app-store/events/base.events";
import { ForgotPasswordDI } from "../forgot-password/forgot-password.di";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { GenerateActivtionLinkActionProxy } from "./generate-activtion-link.action-proxy";
import { GenerateActivationLinkDI } from "./generate-activation-link.di";

@Component({
  selector: 'app-generate-activation-link',
  templateUrl: './generate-activation-link.component.html',
  styleUrls: ['./generate-activation-link.component.scss']
})
export class GenerateActivationLinkComponent extends BaseFormComponent implements OnInit, OnDestroy {

  public ap: GenerateActivtionLinkActionProxy;

  public errorMessage: string = '';

  constructor(
    public sandbox: ForgotPasswordSandbox
  ) {
    super(sandbox, ResetSection.GenerateActivationLink);
    this.ap = new GenerateActivtionLinkActionProxy(this, sandbox.appState);
    this.di = new GenerateActivationLinkDI();
  }

  ngOnInit() {
    this.initForm();
    this.initialize((message: string) => {
      this.errorMessage = message;
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  initForm() {

  }

  saveForm() {
    this.formSubmitted = true;
    this.ap.execute();
  }
}
