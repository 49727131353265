import {Injectable} from '@angular/core';
import * as store from '../../../core/app-store/index';
import {Store} from '@ngrx/store';
import {BaseSandbox, BaseSandboxWithCurrentUserLoaded} from 'src/app/core/shared/base/base.sandbox';

@Injectable()
export class DashboardSandbox extends BaseSandboxWithCurrentUserLoaded {
    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    clearSandboxState() {
    }

    initialize(dataLoaded: () => void) {
        this.clearSandboxState();
        super.loadUser(() => {
            dataLoaded();
        });
    }
}
