import {
    UIGeneratedEvent,
    EventWithScreenLoading,
    SeamlessEvent,
} from './base.events';
import { ActiveDataTableParameters } from '../../shared/datatable/datatable.helpers';
import { Car_Dto } from '../../app-dto/car.dto';

export class GetCarsPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "GetCarsPagedResultEvent";
    constructor(public parameters: ActiveDataTableParameters, public withReset: boolean = false) {
        super();
        this.type += GetCarsPagedResultEvent.identifier;
    }
}

export class RegisterCarEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "RegisterCarEvent";
    constructor(public model: Car_Dto) {
        super();
        this.type += RegisterCarEvent.identifier;
    }
}

export class GetCarEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "GetCarEvent";
    constructor(public id: string, public callback:(data:Car_Dto)=>void) {
        super();
        this.type += GetCarEvent.identifier;
    }
}


export class EditCarEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "EditCarEvent";
    constructor(public model: Car_Dto) {
        super();
        this.type += EditCarEvent.identifier;
    }
}


export class DeleteCarEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "DeleteCarEvent";
    constructor(public id: string, public callback:Function = null) {
        super();
        this.type += DeleteCarEvent.identifier;
    }
}

export class ActivateCarEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "ActivateCarEvent";
    constructor(public id: string) {
        super();
        this.type += ActivateCarEvent.identifier;
    }
}

export class DeactivateCarEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "DeactivateCarEvent";
    constructor(public id: string) {
        super();
        this.type += DeactivateCarEvent.identifier;
    }
}

export class SearchCarEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier:string = "SearchCarEvent";
    constructor(public search: string, public callBack: Function) {
        super();
        this.type += SearchCarEvent.identifier;
    }
}
