import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NotFoundComponent} from './core/core/components/not-found/not-found.component';
import {UnauthorizedComponent} from './core/core/components/unauthorized/unauthorized.component';
import {CoreModule} from './core/core/core.module';
import {AuthGuard} from './core/core/route-guards/auth.guard';
import {RulesGuard} from './core/core/route-guards/rules.guard';
import {AppUserBusinessRule} from './core/core/business-rule-provider/app-user.business-rule.provider';
import {PaymentSuccessfulComponent} from "./core/core/components/payment-successful/payment-successful.component";
import {PaymentFailedComponent} from "./core/core/components/payment-failed/payment-failed.component";
import {AddPaymentMethodComponent} from "./core/core/components/add-payment-method/add-payment-method.component";

export function canManageDashboard(data: AppUserBusinessRule) {
    return data.canViewServiceDashboard === true || data.canViewSuperAdminDashboard === true;
}

export function canManageSuperAdminDashboard(data: AppUserBusinessRule) {
    return data.canViewSuperAdminDashboard;
}

export function canManageSubscriptions(data: AppUserBusinessRule) {
    return data.canManageSubscriptions;
}

export function canManageServicePlatforms(data: AppUserBusinessRule) {
    return data.canManageServicePlatforms;
}

export function canManageMechanics(data: AppUserBusinessRule) {
    return data.canViewMechanics;
}

export function canManageCarParts(data: AppUserBusinessRule) {
    return data.canManageCarParts;
}

export function canManageCars(data: AppUserBusinessRule) {
    return data.canManageCars;
}

export function canManageOrganizationClients(data: AppUserBusinessRule) {
    return data.canManageOrganizationClients;
}

export function canViewAudit(data: AppUserBusinessRule) {
    return data.canViewAudit;
}

export function canViewSuperAdminLogs(data: AppUserBusinessRule) {
    return data.canViewSuperAdminLogs;
}

export function canViewReporting(data: AppUserBusinessRule) {
    return data.canViewMechanicReport || data.canViewOrdersReport || data.canViewProfitReport || data.canViewProviderReport;
}

export function canManageDepartments(data: AppUserBusinessRule) {
    return data.canManageDepartments;
}

export function canManageServiceCalendar(data: AppUserBusinessRule) {
    return data.canViewServiceDashboard;
}

export function canManageOrganizationCosts(data: AppUserBusinessRule) {
    return data.canManageOrganizationCosts;
}

export function canManageSms(data: AppUserBusinessRule) {
    return data.canViewSMSBought || data.canViewSMSSent;
}

export function canManageSupplies(data: AppUserBusinessRule) {
    return data.canManageSupplies;
}

export function canManageCashRegisters(data: AppUserBusinessRule) {
    return data.canManageCashRegisters;
}
export function canManageOffers(data: AppUserBusinessRule) {
    return data.canManageOffers;
}
/**/


const routes: Routes = [
    {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
    {
        path: 'auth',
        loadChildren: './core/auth/auth-routing.module#AuthRoutingModule',
        data: {showHeader: false, showSidebar: false, showFooter: false}
    },
    {
        path: 'offer',
        loadChildren: './offer/offer-routing.module#OfferRoutingModule',

    },
    {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard-routing.module#DashboardRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true, predicate: canManageDashboard},
        canActivate: [AuthGuard, RulesGuard],
    },
    {
        path: 'calendar',
        loadChildren: './calendar/calendar-routing.module#CalendarRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true, predicate: canManageServiceCalendar},
        canActivate: [AuthGuard, RulesGuard],
    },
    {
        path: 'subscription',
        loadChildren: './subscription/subscription-routing.module#SubscriptionRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true}
    },
    {
        path: 'invoice',
        loadChildren: './invoice/invoice-routing.module#InvoiceRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true}
    },
    {
        path: 'receipt',
        loadChildren: './receipt/receipt-routing.module#ReceiptRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true}
    },
    {
        path: 'service-platform',
        loadChildren: './service-platform/service-platform-routing.module#ServicePlatformRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true, predicate: canManageServicePlatforms},
        canActivate: [AuthGuard, RulesGuard],
    },
    {
        path: 'mechanic',
        loadChildren: './mechanic/mechanic-routing.module#MechanicRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true, predicate: canManageMechanics},
        canActivate: [AuthGuard, RulesGuard],
    },
    {
        path: 'car-part',
        loadChildren: './car-part/car-part-routing.module#CarPartRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true, predicate: canManageCarParts},
        canActivate: [AuthGuard, RulesGuard],
    },
    {
        path: 'car',
        loadChildren: './car/car-routing.module#CarRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true, predicate: canManageCars},
        canActivate: [AuthGuard, RulesGuard],
    },
    {
        path: 'department',
        loadChildren: './department/department-routing.module#DepartmentRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true, predicate: canManageDepartments},
        canActivate: [AuthGuard, RulesGuard],
    },
    {
        path: 'supplier',
        loadChildren: './supplier/supplier-routing.module#SupplierRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true, predicate: canManageSupplies},
        canActivate: [AuthGuard, RulesGuard],
    },
    {
        path: 'supply',
        loadChildren: './supply/supply-routing.module#SupplyRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true, predicate: canManageSupplies},
        canActivate: [AuthGuard, RulesGuard],
    },
    {
        path: 'car-service',
        loadChildren: './car-service/car-service-routing.module#CarServiceRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true},
    },
    {
        path: 'client',
        loadChildren: './organization-client/organization-client-routing.module#OrganizationClientRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true, predicate: canManageOrganizationClients},
        canActivate: [AuthGuard, RulesGuard],
    },
    {
        path: 'cash-register',
        loadChildren: './cash-register/cash-register-routing.module#CashRegisterRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true, predicate: canManageCashRegisters},
        canActivate: [AuthGuard, RulesGuard],
    },
    {
        path: 'audit',
        loadChildren: './audit/audit-routing.module#AuditRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true, predicate: canViewAudit},
        canActivate: [AuthGuard, RulesGuard],
    },
    {
        path: 'logs',
        loadChildren: './super-admin-logs/super-admin-logs-routing.module#SuperAdminLogsRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true, predicate: canViewSuperAdminLogs},
        canActivate: [AuthGuard, RulesGuard],
    },
    {
        path: 'reports',
        loadChildren: './reporting/reporting-routing.module#ReportingRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true, predicate: canViewReporting},
        canActivate: [AuthGuard, RulesGuard],
    },
    {
        path: 'user',
        loadChildren: './user/user-routing.module#UserRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true},
        // canActivate: [AuthGuard]
    },
    {
        path: 'profile',
        loadChildren: './profile/profile-routing.module#ProfileRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true},
        // canActivate: [AuthGuard]
    },
    {
        path: 'organization-cost',
        loadChildren: './organization-cost/organization-cost-routing.module#OrganizationCostRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true, predicate: canManageOrganizationCosts},
        canActivate: [AuthGuard, RulesGuard],
    },
    {
        path: 'sms',
        loadChildren: './sms/sms-routing.module#SmsRoutingModule',
        data: {showHeader: true, showSidebar: true, showFooter: true, predicate: canManageSms},
        canActivate: [AuthGuard, RulesGuard],
    },
    {
        path: 'unauthorized', component: UnauthorizedComponent,
        data: {showHeader: false, showSidebar: false, showFooter: false},
    },
    {
        path: 'checkout-success', component: PaymentSuccessfulComponent,
        data: {showHeader: false, showSidebar: false, showFooter: false},
    },
    {
        path: 'checkout-failed', component: PaymentFailedComponent,
        data: {showHeader: false, showSidebar: false, showFooter: false},
    },
    {
        path: 'setup-billing', component: AddPaymentMethodComponent,
        data: {showHeader: false, showSidebar: false, showFooter: false},
    },
    {
        path: '**', component: NotFoundComponent,
        data: {showHeader: false, showSidebar: false, showFooter: false},
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes), CoreModule],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
