import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiErrorDto } from '../utils/api-error.dto';
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ErrorHelpers } from '../../shared/error.helpers';
import { CarWork_Dto } from '../../app-dto/car-work.dto';
import {isValidString} from "../../shared/helpers/common.helpers";

class CarWorkApiRoutes {
     public static route_for_GET_CAR_WORK(carWorkId: string) {
        return `/api/carWork/${carWorkId}`;
    }
    public static route_for_SEARCH_CAR_WORK() {
        return `/api/carWork/list`;
    }

}

@Injectable()
export class CarWorkApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_getCarWork(carWorkId: string): Observable<CarWork_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarWorkApiRoutes.route_for_GET_CAR_WORK(carWorkId);

        return this.http.get(url)
            .map((res: any) => {
                return new CarWork_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_searchCarWork(search: string, model:string, make:string, year:number): Observable<Array<CarWork_Dto>> {
      if(isValidString(search)==false) search = '';
      if(isValidString(model)==false) model = '';
      if(isValidString(make)==false) make = '';
      let yearAsString = year != null && year != undefined ? year.toString() : '';
        const url = this.configService.getAPIBaseUrl() + CarWorkApiRoutes.route_for_SEARCH_CAR_WORK() + '?search=' + search + "&model=" + model+ "&make=" + make+ "&year=" + yearAsString;

        return this.http.get(url)
            .map((res: any) => {
                return res.map(r => new CarWork_Dto(r));
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
}
