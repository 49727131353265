import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import * as store from '../../core/app-store';
import {Observable} from 'rxjs';
import {
    Do_GenericErrorEvent,
    Do_GenericSuccessEvent,
    EventWithScreenLoading
} from '../../core/app-store/events/base.events';
import {map, switchMap} from 'rxjs/operators';
import {Supplier_Dto} from '../../core/app-dto/supplier.dto';
import {ObjectValidators} from '../../core/shared/object.validators';
import {SupplierApiService} from '../../core/api-integration/services/supplier-api.service';
import {
    ActivateSupplierEvent, DeactivateSupplierEvent,
    DeleteSupplierEvent,
    GetSupplierEvent,
    GetSuppliersPagedResultEvent,
    RegisterSupplierEvent, UpdateSupplierEvent
} from '../../core/app-store/events/supplier.events';
import {PagedResult} from '../../core/shared/datatable/datatable.helpers';
import {isValidObject} from '../../core/shared/helpers/common.helpers';

@Injectable()
export class SupplierUIEventProcessor {
    constructor(private actions$: Actions,
                private appState$: Store<store.State>,
                private supplierApiService: SupplierApiService) {
    }

    @Effect()
    getSupplier$: Observable<any> = this.actions$
        .pipe(
            ofType(GetSupplierEvent.identifier, EventWithScreenLoading.identifier + GetSupplierEvent.identifier),
            map((action: GetSupplierEvent) => action),
            switchMap((action: GetSupplierEvent) => {
                return new Observable((observer) => {
                    this.supplierApiService.u_getSupplier(action.id)
                        .subscribe((res: Supplier_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Supplier_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    GetSuppliersPagedResultEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetSuppliersPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetSuppliersPagedResultEvent.identifier),
            map((action: GetSuppliersPagedResultEvent) => action),
            switchMap((action: GetSuppliersPagedResultEvent) => {
                return new Observable((observer) => {
                    this.supplierApiService.u_getSuppliersPaged(action.parameters)
                        .subscribe((res: PagedResult<Supplier_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<Supplier_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doRegisterSupplier$: Observable<any> = this.actions$
        .pipe(
            ofType(RegisterSupplierEvent.identifier, EventWithScreenLoading.identifier + RegisterSupplierEvent.identifier),
            map((action: RegisterSupplierEvent) => action),
            switchMap((action: RegisterSupplierEvent) => {
                    return new Observable((observer) => {
                        this.supplierApiService.u_registerSupplier(action.model)
                            .subscribe((res: Supplier_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<Supplier_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doUpdateSupplier$: Observable<any> = this.actions$
        .pipe(
            ofType(UpdateSupplierEvent.identifier, EventWithScreenLoading.identifier + UpdateSupplierEvent.identifier),
            map((action: UpdateSupplierEvent) => action),
            switchMap((action: UpdateSupplierEvent) => {
                    return new Observable((observer) => {
                        this.supplierApiService.u_updateSupplier(action.model)
                            .subscribe((res: Supplier_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<Supplier_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doActivateSupplier$: Observable<any> = this.actions$
        .pipe(
            ofType(ActivateSupplierEvent.identifier, EventWithScreenLoading.identifier + ActivateSupplierEvent.identifier),
            map((action: ActivateSupplierEvent) => action),
            switchMap((action: ActivateSupplierEvent) => {
                    return new Observable((observer) => {
                        this.supplierApiService.u_activateSupplier(action.id)
                            .subscribe((res: Supplier_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<Supplier_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doDeactivateCarPart$: Observable<any> = this.actions$
        .pipe(
            ofType(DeactivateSupplierEvent.identifier, EventWithScreenLoading.identifier + DeactivateSupplierEvent.identifier),
            map((action: DeactivateSupplierEvent) => action),
            switchMap((action: DeactivateSupplierEvent) => {
                    return new Observable((observer) => {
                        this.supplierApiService.u_deactivateSupplier(action.id)
                            .subscribe((res: Supplier_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<Supplier_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doDeleteSupplier$: Observable<any> = this.actions$
        .pipe(
            ofType(DeleteSupplierEvent.identifier, EventWithScreenLoading.identifier + DeleteSupplierEvent.identifier),
            map((action: DeleteSupplierEvent) => action),
            switchMap((action: DeleteSupplierEvent) => {
                    return new Observable((observer) => {
                        this.supplierApiService.u_deleteSupplier(action.id)
                            .subscribe((res: Supplier_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<Supplier_Dto>(res, action));
                                if (isValidObject(action.callback)) { action.callback(); }
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );
}
