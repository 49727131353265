import { Component, OnInit, OnDestroy } from '@angular/core';
import {BaseWebComponent, BaseWebComponentWithBreadcrumb} from 'src/app/core/shared/base/base.component';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { GetMechanicActionProxy } from './get-mechanic.action-proxy';
import { MechanicDetailsSandbox } from './mechanic-details.sandbox';
import { MechanicDetailsDi } from './mechanic-details.di';
import { BsModalService } from 'ngx-bootstrap';
import { SaveMechanicComponent } from '../save-mechanic/save-mechanic.component';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { Mechanic_Dto } from '../../../core/app-dto/mechanic.dto';
import { DeleteMechanicActionProxy } from '../../common/action-proxies/delete-mechanic.action-proxy';
import {UserDetailsDi} from "../../../user/components/user-details/user-details.di";
import {ConfirmationDialogService} from "../../../core/core/dialog/confirmation-dialog.service";
import { ActivateMechanicComponent } from '../activate-mechanic/activate-mechanic.component';
import { DeactivateMechanicComponent } from '../deactivate-mechanic/deactivate-mechanic.component';
import { EditMechanicHistoryComponent } from '../edit-mechanic-history/edit-mechanic-history.component';
import {AddUserAccountComponent} from "../add-user-account/add-user-account.component";
import {DeleteAccountForMechanicActionProxy} from "../../common/action-proxies/delete-account-for-mechanic.action-proxy";
import {Breadcrumb} from "../../../core/app-dto/breadcrumb.dto";


@Component({
  selector: 'app-mechanic-details',
  templateUrl: './mechanic-details.component.html',
  styleUrls: ['./mechanic-details.component.scss']
})
export class MechanicDetailsComponent extends BaseWebComponentWithBreadcrumb implements OnInit, OnDestroy {
  public getMechanicAP: GetMechanicActionProxy;
  public deleteMechanicAP: DeleteMechanicActionProxy;
  public deleteAccountForMechanicAP: DeleteAccountForMechanicActionProxy;
  public mechanicId: string = null;

  public di: MechanicDetailsDi;//we need to overwrite di with specific type for production build

  constructor(
    public sandbox: MechanicDetailsSandbox,
    public activatedRoute: ActivatedRoute,
    public router:Router, public confirmationDialogService: ConfirmationDialogService,
    private modalService: BsModalService,
  ) {
    super(sandbox, ResetSection.MechanicDetails);
    this.getMechanicAP = new GetMechanicActionProxy(this, sandbox.appState);
    this.deleteMechanicAP = new DeleteMechanicActionProxy(this, sandbox.appState);
    this.deleteAccountForMechanicAP = new DeleteAccountForMechanicActionProxy(this, sandbox.appState);
    this.di = new MechanicDetailsDi();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id !== undefined) {
        this.mechanicId = params.id;
        this.getMechanicAP.execute(this.mechanicId);
      }
    });

    this.initialize((data: Mechanic_Dto) => {
      this.di = new MechanicDetailsDi(data);
      this.setBreadcrumbAP.execute([ new Breadcrumb({text: 'Acasa', url: '/dashboard'}),new Breadcrumb({text: 'Mecanici', url:'/mechanic/all'}),
        new Breadcrumb({text:data.firstName + " " + data.lastName})]);
      this.dataLoaded = true;
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  updateMechanic() {
    const modalRef = this.modalService.show(SaveMechanicComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = this.di.model;
  }

  activateMechanic(mechanic: Mechanic_Dto) {
    const modalRef = this.modalService.show(ActivateMechanicComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = mechanic;
  }

  deactivateMechanic(mechanic: Mechanic_Dto) {
    const modalRef = this.modalService.show(DeactivateMechanicComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = mechanic;
  }
  addAccountForMechanic(mechanic: Mechanic_Dto) {
    const modalRef = this.modalService.show(AddUserAccountComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = mechanic;
  }

  deleteMechanic(mechanicId: string) {
    this.confirmationDialogService.confirm(
      "Stergere mecanic",
      "Urmeaza sa stergeti mecanicul. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        this.deleteMechanicAP.execute(mechanicId, ()=>{
          this.router.navigate(['/mechanic/all']);
        });
      }
    });
  }

  deleteAccountForMechanic(mechanic: Mechanic_Dto) {
    this.confirmationDialogService.confirm(
      "Stergere Cont Mecanic",
      "Urmeaza sa stergeti contul pentru mecanicul <b>" + mechanic.firstName + " " + mechanic.lastName + "</b>. Acesta nu va mai avea acces in aplicatie. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        this.deleteAccountForMechanicAP.execute(mechanic.id);
      }
    });

  }

  updateMechanicHistory() {
    const modalRef = this.modalService.show(EditMechanicHistoryComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = this.di.model;
  }
}
