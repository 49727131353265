import {Component, OnDestroy, OnInit} from "@angular/core";
import {BaseFormModelComponent} from "../../base/base.component";
import {Subject} from "rxjs";
import {Store} from "@ngrx/store";
import * as store from "../../../app-store";
import {BsModalRef} from "ngx-bootstrap";
import {ActivatedRoute} from "@angular/router";
import {AppUserBusinessRuleProvider} from "../../../core/business-rule-provider/app-user.business-rule.provider";
import {ResetSection} from "../../../app-store/events/base.events";
import {RegisterPrintJobActionProxy} from "./register-print-job.action-proxy";
import {GetActiveCashRegistersActionProxy} from "./get-active-cash-registers.action-proxy";
import {NumericIdName, RegisterPrintJobDi} from "./register-print-job.di";
import {FiscalPrintInvoiceSandbox} from "./fiscal-print-invoice.sandbox";
import {Invoice_Dto} from "../../../app-dto/invoice.dto";
import {NotifierService} from "angular-notifier";
import {IdName_Dto} from "../../../app-dto/misc.dto";
import {ReportingGroupinOption} from "../../../app-dto/reporting.dto";
import {CashRegister_Dto} from "../../../app-dto/cash-register.dto";
import {ObjectValidators} from "../../object.validators";

@Component({
    selector: 'app-fiscal-print-invoice',
    templateUrl: './fiscal-print-invoice.component.html',
    styleUrls: ['./fiscal-print-invoice.component.scss']
})
export class FiscalPrintInvoiceComponent extends BaseFormModelComponent<Invoice_Dto> implements OnInit, OnDestroy {
    public registerAP: RegisterPrintJobActionProxy;
    public getActiveCashRegistersAP: GetActiveCashRegistersActionProxy;
    public title: string;
    public onClose: Subject<boolean>;
    public di: RegisterPrintJobDi;

    constructor(
        public appState: Store<store.State>,
        public sandbox: FiscalPrintInvoiceSandbox,
        public activeModal: BsModalRef,
        public activatedRoute: ActivatedRoute,
        public rulesProvider: AppUserBusinessRuleProvider,
        public notifierService: NotifierService
    ) {
        super(sandbox, ResetSection.SavePrintJob);
        this.registerAP = new RegisterPrintJobActionProxy(this, sandbox.appState);
        this.getActiveCashRegistersAP = new GetActiveCashRegistersActionProxy(this, sandbox.appState);
        this.getActiveCashRegistersAP.execute();
    }

    public setData(invoice: Invoice_Dto) {
        this.di = new RegisterPrintJobDi(this.notifierService, invoice);
        this.dataLoaded = true;
    }

    selectedCashRegisterChanged(data: CashRegister_Dto) {
        if (ObjectValidators.isValidObject(data)) {
            let chargeTypes: NumericIdName[] = [];
            if (ObjectValidators.isValidObject(data.cashIndex))
                chargeTypes.push(new NumericIdName(data.cashIndex.value, `${data.cashIndex.name} (${data.cashIndex.value})`));
            if (ObjectValidators.isValidObject(data.cardIndex))
                chargeTypes.push(new NumericIdName(data.cardIndex.value, `${data.cardIndex.name} (${data.cardIndex.value})`));
            this.di.resetChargeTypes(chargeTypes);
        }
    }

    public diInitialize(invoice: Invoice_Dto) {

    }

    ngOnInit() {
        this.onClose = new Subject();
        this.initialize();
    }

    ngOnDestroy() {
        this.destroy();
    }

    public decline() {
        this.onClose.next(false);
        this.activeModal.hide();
    }

    public accept() {
        this.formSubmitted = true;
        if (this.registerAP.canExecute()) {
            this.registerAP.execute();
            this.onClose.next(true);
            this.activeModal.hide();
        }
    }
}
