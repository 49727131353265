import {DataGeneratedEvent, EventWithScreenLoading, SeamlessEvent, UIGeneratedEvent} from "./base.events";
import {ActiveDataTableParameters} from '../../shared/datatable/datatable.helpers';
import {DeactivateSubscription_Dto, RequestSubscription_Dto} from '../../app-dto/authentication.dto';
import { Organization, Preorder } from '../../app-dto/organization.dto';
import {FileAttachment} from '../../app-dto/core.dto';

export class GetSubscriptionsPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetSubscriptionsPagedResultEvent";

  constructor(public parameters: ActiveDataTableParameters, public withReset: boolean = false) {
    super();
    this.type += GetSubscriptionsPagedResultEvent.identifier;
  }
}

export class EditSubscriptionEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "EditSubscriptionEvent";

  constructor(public model: Organization) {
    super();
    this.type += EditSubscriptionEvent.identifier;
  }
}

export class EditSubscriptionProfileEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "EditSubscriptionProfileEvent";

  constructor(public model: Organization) {
    super();
    this.type += EditSubscriptionProfileEvent.identifier;
  }
}

export class GetSubscriptionEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetSubscriptionEvent";

  constructor(public id: string) {
    super();
    this.type += GetSubscriptionEvent.identifier;
  }
}

export class GetSubscriptionProfileEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetSubscriptionProfileEvent";

  constructor() {
    super();
    this.type += GetSubscriptionProfileEvent.identifier;
  }
}

export class DeactivateSubscriptionEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeactivateSubscriptionEvent";

  constructor(public model: DeactivateSubscription_Dto) {
    super();
    this.type += DeactivateSubscriptionEvent.identifier;
  }
}

export class ValidateSubscriptionEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "ValidateSubscriptionEvent";

  constructor(public id: string) {
    super();
    this.type += ValidateSubscriptionEvent.identifier;
  }
}

export class OrganizationImageChanged extends SeamlessEvent implements DataGeneratedEvent {
  public static identifier: string = "OrganizationImageChanged";

  constructor(public data: FileAttachment) {
    super();
    this.type += OrganizationImageChanged.identifier;
  }
}

export class OrganizationCuiChanged extends SeamlessEvent implements DataGeneratedEvent {
  public static identifier: string = "OrganizationCuiChanged";

  constructor(public data: FileAttachment) {
    super();
    this.type += OrganizationCuiChanged.identifier;
  }
}


export class GetOrganizationCuiUrlEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetOrganizationCuiUrlEvent";

  constructor(public organziationId: string, public callback: (url: string) => void) {
    super();
    this.type += GetOrganizationCuiUrlEvent.identifier;
  }
}

export class DeleteOrganizationCuiUrlEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "DeleteOrganizationCuiUrlEvent";

  constructor() {
    super();
    this.type += DeleteOrganizationCuiUrlEvent.identifier;
  }
}

export class DeleteOrganizationImageProfileUrlEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "DeleteOrganizationImageProfileUrlEvent";

  constructor() {
    super();
    this.type += DeleteOrganizationImageProfileUrlEvent.identifier;
  }
}

export class ActivateAddonEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier = 'ActivateAddonEvent';

  constructor(public id: string) {
    super();
    this.type += ActivateAddonEvent.identifier;
  }
}

export class DeactivateAddonEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier = 'DeactivateAddonEvent';

  constructor(public id: string) {
    super();
    this.type += DeactivateAddonEvent.identifier;
  }
}

export class CancelDeactivateAddonEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier = 'CancelDeactivateAddonEvent';

  constructor(public id: string) {
    super();
    this.type += CancelDeactivateAddonEvent.identifier;
  }
}

export class RegisterPreorderAddonEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier = 'RegisterPreorderAddonEvent';

  constructor(public id: string, public callback: (result: Preorder) => void) {
    super();
    this.type += RegisterPreorderAddonEvent.identifier;
  }
}

export class GetPayButtonAddonEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier = 'GetPayButtonAddonEvent';

  constructor(public id: string, public callback: (result: string) => void) {
    super();
    this.type += GetPayButtonAddonEvent.identifier;
  }
}

export class ChangeSubscriptionPlanEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "ChangeSubscriptionPlanEvent";

  constructor(public organizationId:string, public subscriptionTypeId:string) {
    super();
    this.type += ChangeSubscriptionPlanEvent.identifier;
  }
}

