import { BaseDI } from 'src/app/core/shared/base/base.di';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';
import {DateHelpers} from "../../../core/shared/date.helpers";
import {ObjectValidators} from "../../../core/shared/object.validators";

export class CarServiceListItemDi extends BaseDI<CarService_Dto> {
  public lastUpdatedOnDate:string = '-';
    constructor(data: any) {
        super(new CarService_Dto(data));
        if(ObjectValidators.isValidObject(this.model.finishedDate) && this.model.rules.canFinishCar.value==false) {
          this.lastUpdatedOnDate = DateHelpers.dateToString(this.model.finishedDate, 'DD/MM/YYYY HH:mm');
        }
    }

    getModel(): CarService_Dto {
        return this.model;
    }
}
