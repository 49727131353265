import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {BaseListWebWithSelectBehaviorComponent} from '../../../core/shared/base/base.component';
import {ReceiptListSandbox} from './receipt-list.sandbox';
import {IdName_Dto} from '../../../core/app-dto/misc.dto';
import {
    DataTableParametersOrderEntity,
    ReceiptDataTableParameters
} from '../../../core/shared/datatable/datatable.helpers';
import {ConfigService} from '../../../core/core/app-config.service';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {GetOrganizationReceiptsActionProxy} from './get-organization-receipts.action-proxy';
import {Receipt_Dto} from '../../../core/app-dto/receipt.dto';
import {DataTableDirective} from 'angular-datatables';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {SeamlessEvent} from '../../../core/app-store/events/base.events';
import {SearchEvent} from '../../../core/app-store';
import {ActionsSubject} from '@ngrx/store';
import {ReceiptListItemDi} from './receipt-list-item.di';
import {InvoiceRequestType} from '../../../invoice/components/invoice/invoice.component';
import {Router} from '@angular/router';

@Component({
    selector: 'app-receipt-list',
    templateUrl: './receipt-list.component.html',
    styleUrls: ['./receipt-list.component.scss']
})
export class ReceiptListComponent extends BaseListWebWithSelectBehaviorComponent<Receipt_Dto> implements OnInit {
    public columns: any = [];
    public sortColumns: Array<IdName_Dto> = [];
    public searchValue: string = '';
    public currentPage = 1;
    public getOrganizationReceiptsAP: GetOrganizationReceiptsActionProxy;
    modelChanged: Subject<string> = new Subject<string>();
    private searchSubscription = new Subscription();

    @Input() invoiceRequestType: InvoiceRequestType = null;
    @Input() invoiceId: string = null;
    @ViewChildren(DataTableDirective) set datatableValue(datatable: QueryList<DataTableDirective>) {
        if (datatable != null) {
            datatable.first.dtInstance.then((dtInstance: DataTables.Api) => {
                this.dtApiInstance = dtInstance;
                this.sandbox.setResetTableCallback(() => {
                    dtInstance.ajax.reload();
                });
            });
        }
    }

    constructor(
        public sandbox: ReceiptListSandbox,
        private configService: ConfigService,
        private actionsSubj: ActionsSubject,
        public router: Router
    ) {
        super(sandbox);
        this.getOrganizationReceiptsAP = new GetOrganizationReceiptsActionProxy(this, sandbox.appState);

        this.modelChanged.pipe(
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(searchQuery => {
                this.searchValue = searchQuery;
                this.dtApiInstance.search(this.searchValue).draw();
            });
        this.searchSubscription = actionsSubj.subscribe(data => {
            if (data.type == SeamlessEvent.identifier + SearchEvent.identifier) {
                this.searchValue = (<SearchEvent> data).value;
                this.dtApiInstance.search(this.searchValue).draw();
            }
        });
        this.columns = [
            {data: 'select', name: 'Selecteaza', width: '50px', orderable: false},
            {data: 'number', name: 'Numar', width: '10%', orderable: true},
            {data: 'invoiceNumber', name: 'Factura Asociata', width: '10%', orderable: true},
            {data: 'value', name: 'Total', width: '10%', orderable: false},
            {data: 'date', name: 'Data Emiterii', width: '10%', orderable: true},
            {data: 'actiune', name: 'Actiune', width: '70px', orderable: false}
        ];
    }
    ngOnInit(): void {
        this.sortColumns = this.columns.filter(c => c.orderable).map(c => ({id: c.data, name: c.name} as IdName_Dto));
        this.initialize((result: Array<ReceiptListItemDi>) => {
            this.items = result;
        });
        const defaultSortIndex = this.columns.indexOf(this.columns.find(f => f.name === 'Numar'));
        this.dtOptions = {
            responsive: true,
            paging: false,
            info: false,
            lengthChange: false,
            searching: false,
            pageLength: this.configService.getDefaultPageSize(),
            scrollX: false,
            serverSide: true,
            processing: false,
            ordering: false,
            autoWidth: false,
            scrollY: '300px',
            scrollCollapse: true,
            ajax: (dataTablesParameters: ReceiptDataTableParameters, callback) => {
                dataTablesParameters.search.value = this.searchValue;
                dataTablesParameters.invoiceId = this.invoiceId;
                this.doGetAll(new ReceiptDataTableParameters(dataTablesParameters), callback, true);
            },
            columns: this.columns,
            order: [[defaultSortIndex, 'desc']],
            dom: '<\'row mx-1\'<\'col-sm-12 col-md-6 px-3\'l><\'col-sm-12 col-md-6 px-3\'f>>' + '<\'table-responsive\'tr>' + '<\'row mx-1 align-items-center justify-content-center justify-content-md-between\'<\'col-auto mb-2 mb-sm-0\'i><\'col-auto\'p>>'
        };
    }

    public doGetAll(dataTablesParameters: ReceiptDataTableParameters, callback: any, withReset: boolean) {
        this.sandbox.callback = callback;
        this.sandbox.dataTableParameters = dataTablesParameters;
        if (withReset) {
            setTimeout(() => {this.currentPage = 1; }, 0);
            this.sandbox.dataTableParameters.pageNumber = 1;
            dataTablesParameters.pageNumber = 1;
        }
        this.getOrganizationReceiptsAP.execute(dataTablesParameters, withReset);
    }

    search(data: any) {
        if (ObjectValidators.isValidString(data)) {
            this.modelChanged.next(data);
        }
        if (data === '') {
            this.modelChanged.next(null);
        }
        return false;
    }

    onSortChange($event) {
        if ($event) {
            const columnId = this.columns.map((e) => e.data).indexOf($event.id);
            const orderEntity = new DataTableParametersOrderEntity({column: columnId, dir: 'asc'});
            this.sandbox.dataTableParameters.order = [orderEntity];
        }
        this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, true);
    }

    onPageChange(currentPage: number) {
        this.currentPage = currentPage;
        this.sandbox.dataTableParameters.pageNumber = currentPage;
        this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
    }

    public openReceipt(receiptId: string) {}

    public openInvoice(invoiceId: string) {
        switch (this.invoiceRequestType) {
            case InvoiceRequestType.SuperAdmin: {
                this.router.navigate(['/invoice', invoiceId, 'details']);
                break;
            }
            case InvoiceRequestType.Organization: {
                this.router.navigate(['/invoice', this.sandbox.organizationId, invoiceId, 'details']);
                break;
            }
            case InvoiceRequestType.SubscriptionOrganization: {
                this.router.navigate(['/invoice/subscription', this.sandbox.organizationId, invoiceId, 'details']);
                break;
            }
        }
    }
}
