import { Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import { map, switchMap } from 'rxjs/operators';
import { Do_GenericSuccessEvent, Do_GenericErrorEvent, EventWithScreenLoading } from 'src/app/core/app-store/events/base.events';
import { PagedResult } from 'src/app/core/shared/datatable/datatable.helpers';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { UserApiService } from 'src/app/core/api-integration/services/user-api.service';
import {
    ActivateUserEvent,
    DeactivateUserEvent,
    DeleteUserEvent, EditUserEvent,
    GetUserEvent,
    GetUsersPagedResultEvent, RegisterUserEvent
} from 'src/app/core/app-store/events/user.events';
import { User_Dto } from 'src/app/core/app-dto/core.dto';
import {isValidObject} from "../../core/shared/helpers/common.helpers";


@Injectable()
export class UserUIEventProcessor {

    constructor(private actions$: Actions,
                private appState$: Store<store.State>,
                private userApiService: UserApiService) {
    }

    @Effect()
    GetUsersPagedResultEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetUsersPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetUsersPagedResultEvent.identifier),
            map((action: GetUsersPagedResultEvent) => action),
            switchMap((action: GetUsersPagedResultEvent) => {
                return new Observable((observer) => {
                    this.userApiService.u_getFilteredUsersPaginated(action.parameters)
                        .subscribe((res: PagedResult<User_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<User_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    getUser$: Observable<any> = this.actions$
        .pipe(
            ofType(GetUserEvent.identifier, EventWithScreenLoading.identifier + GetUserEvent.identifier),
            map((action: GetUserEvent) => action),
            switchMap((action: GetUserEvent) => {
                return new Observable((observer) => {
                    this.userApiService.u_getUser(action.id)
                        .subscribe((res: User_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<User_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doRegisterUser$: Observable<any> = this.actions$
        .pipe(
            ofType(RegisterUserEvent.identifier, EventWithScreenLoading.identifier + RegisterUserEvent.identifier),
            map((action: RegisterUserEvent) => action),
            switchMap((action: RegisterUserEvent) => {
                    return new Observable((observer) => {
                        this.userApiService.u_registerUser(action.model)
                            .subscribe((res: User_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<User_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doEditUser$: Observable<any> = this.actions$
        .pipe(
            ofType(EditUserEvent.identifier, EventWithScreenLoading.identifier + EditUserEvent.identifier),
            map((action: EditUserEvent) => action),
            switchMap((action: EditUserEvent) => {
                    return new Observable((observer) => {
                        this.userApiService.u_editUser(action.model, action.updatedHistory)
                            .subscribe((res: User_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<User_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doDeleteUser$: Observable<any> = this.actions$
        .pipe(
            ofType(DeleteUserEvent.identifier, EventWithScreenLoading.identifier + DeleteUserEvent.identifier),
            map((action: DeleteUserEvent) => action),
            switchMap((action: DeleteUserEvent) => {
                    return new Observable((observer) => {
                        this.userApiService.u_deleteUser(action.id)
                            .subscribe((res: User_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<User_Dto>(res, action));
                                if(isValidObject(action.callback)) action.callback();
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doActivateUser$: Observable<any> = this.actions$
        .pipe(
            ofType(ActivateUserEvent.identifier, EventWithScreenLoading.identifier + ActivateUserEvent.identifier),
            map((action: ActivateUserEvent) => action),
            switchMap((action: ActivateUserEvent) => {
                    return new Observable((observer) => {
                        this.userApiService.u_activateUser(action.id, action.startDate)
                            .subscribe((res: User_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<User_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doDeactivateUser$: Observable<any> = this.actions$
        .pipe(
            ofType(DeactivateUserEvent.identifier, EventWithScreenLoading.identifier + DeactivateUserEvent.identifier),
            map((action: DeactivateUserEvent) => action),
            switchMap((action: DeactivateUserEvent) => {
                    return new Observable((observer) => {
                        this.userApiService.u_deactivateUser(action.id, action.endDate)
                            .subscribe((res: User_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<User_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );
}
