import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store/index';
import {Observable} from 'rxjs';
import {IWebComponent} from 'src/app/core/shared/base/base.component';
import {BaseActionProxy} from 'src/app/core/shared/base/base.action-proxy';
import {ContinueDraftCarServiceEntryEvent, ContinueDraftOfferEntryEvent} from '../../../core/app-store/events/draft.events';
import {Draft_Dto, DraftType} from "../../../core/app-dto/draft.dto";

export class ContinueDraftActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent, appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(model: Draft_Dto): any {
        if (this.canExecute()) {
            if (model.type == DraftType.CarServiceEntry)
                this.appState.dispatch(new ContinueDraftCarServiceEntryEvent(model));
            else if (model.type == DraftType.OfferEntry)
                this.appState.dispatch(new ContinueDraftOfferEntryEvent(model));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

