import { Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import { map, switchMap } from 'rxjs/operators';
import { Do_GenericSuccessEvent, Do_GenericErrorEvent, EventWithScreenLoading, SeamlessEvent } from 'src/app/core/app-store/events/base.events';
import { PagedResult } from 'src/app/core/shared/datatable/datatable.helpers';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import {
    DeactivateOrganizationCostEvent,
    DeleteOrganizationCostEvent,
    EditOrganizationCostEvent,
    GetOrganizationCostsPagedResultEvent,
    RegisterOrganizationCostEvent,
    GetOrganizationCostEvent,
    DeleteOrganizationCostMonthlyEvent
} from '../../core/app-store/events/organization-cost.events';
import { OrganizationCostApiService } from '../../core/api-integration/services/organization-cost.service';
import { isValidObject } from "../../core/shared/helpers/common.helpers";
import { OrganizationCost } from 'src/app/core/app-dto/organization.dto';


@Injectable()
export class OrganizationCostUIEventProcessor {

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private organizationCostApiService: OrganizationCostApiService
    ) {
    }

    @Effect()
    GetOrganizationCostsPagedResultEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetOrganizationCostsPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetOrganizationCostsPagedResultEvent.identifier),
            map((action: GetOrganizationCostsPagedResultEvent) => action),
            switchMap((action: GetOrganizationCostsPagedResultEvent) => {
                return new Observable((observer) => {
                    this.organizationCostApiService.u_getFilteredOrganizationCostsPaginated(action.parameters)
                        .subscribe((res: PagedResult<OrganizationCost>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<OrganizationCost>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    getOrganizationCost$: Observable<any> = this.actions$
        .pipe(
            ofType(GetOrganizationCostEvent.identifier, EventWithScreenLoading.identifier + GetOrganizationCostEvent.identifier),
            map((action: GetOrganizationCostEvent) => action),
            switchMap((action: GetOrganizationCostEvent) => {
                return new Observable((observer) => {
                    this.organizationCostApiService.u_getOrganizationCost(action.id)
                        .subscribe((res: OrganizationCost) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<OrganizationCost>(res, action));
                            }
                            if (action.callback) action.callback(res);
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doRegisterOrganizationCost$: Observable<any> = this.actions$
        .pipe(
            ofType(RegisterOrganizationCostEvent.identifier, EventWithScreenLoading.identifier + RegisterOrganizationCostEvent.identifier),
            map((action: RegisterOrganizationCostEvent) => action),
            switchMap((action: RegisterOrganizationCostEvent) => {
                return new Observable((observer) => {
                    this.organizationCostApiService.u_registerOrganizationCost(action.model)
                        .subscribe((res: OrganizationCost) => {
                            observer.next(new Do_GenericSuccessEvent<OrganizationCost>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doEditOrganizationCost$: Observable<any> = this.actions$
        .pipe(
            ofType(EditOrganizationCostEvent.identifier, EventWithScreenLoading.identifier + EditOrganizationCostEvent.identifier),
            map((action: EditOrganizationCostEvent) => action),
            switchMap((action: EditOrganizationCostEvent) => {
                return new Observable((observer) => {
                    this.organizationCostApiService.u_editOrganizationCost(action.model)
                        .subscribe((res: OrganizationCost) => {
                            observer.next(new Do_GenericSuccessEvent<OrganizationCost>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doDeleteOrganizationCost$: Observable<any> = this.actions$
        .pipe(
            ofType(DeleteOrganizationCostEvent.identifier, EventWithScreenLoading.identifier + DeleteOrganizationCostEvent.identifier),
            map((action: DeleteOrganizationCostEvent) => action),
            switchMap((action: DeleteOrganizationCostEvent) => {
                return new Observable((observer) => {
                    this.organizationCostApiService.u_deleteOrganizationCost(action.id)
                        .subscribe((res: OrganizationCost) => {
                            observer.next(new Do_GenericSuccessEvent<OrganizationCost>(res, action));
                            if (isValidObject(action.callback)) action.callback();
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doDeleteOrganizationCostMonthly$: Observable<any> = this.actions$
        .pipe(
            ofType(DeleteOrganizationCostMonthlyEvent.identifier, EventWithScreenLoading.identifier + DeleteOrganizationCostMonthlyEvent.identifier),
            map((action: DeleteOrganizationCostMonthlyEvent) => action),
            switchMap((action: DeleteOrganizationCostMonthlyEvent) => {
                return new Observable((observer) => {
                    this.organizationCostApiService.u_deleteOrganizationCostMonthly(action.id)
                        .subscribe((res: OrganizationCost) => {
                            observer.next(new Do_GenericSuccessEvent<OrganizationCost>(res, action));
                            if (isValidObject(action.callback)) action.callback();
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doDeactivateOrganizationCost$: Observable<any> = this.actions$
        .pipe(
            ofType(DeactivateOrganizationCostEvent.identifier, EventWithScreenLoading.identifier + DeactivateOrganizationCostEvent.identifier),
            map((action: DeactivateOrganizationCostEvent) => action),
            switchMap((action: DeactivateOrganizationCostEvent) => {
                return new Observable((observer) => {
                    this.organizationCostApiService.u_deactivateOrganizationCost(action.id, action.endingDate)
                        .subscribe((res: OrganizationCost) => {
                            observer.next(new Do_GenericSuccessEvent<OrganizationCost>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );
}
