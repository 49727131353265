import { Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import { map, switchMap } from 'rxjs/operators';
import { Do_GenericSuccessEvent, Do_GenericErrorEvent, EventWithScreenLoading, SeamlessEvent } from 'src/app/core/app-store/events/base.events';
import { PagedResult } from 'src/app/core/shared/datatable/datatable.helpers';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { Supply_Dto } from 'src/app/core/app-dto/supply.dto';
import {
    ActivateSupplyEvent,
    DeactivateSupplyEvent,
    DeleteSupplyEvent,
    EditSupplyEvent,
    GetSupplyEvent,
    GetSuppliesEvent,
    GetSuppliesPagedResultEvent,
    RegisterSupplyEvent,
    GetSuppliesWithUnpaidInvoicePagedResultEvent,
    GetSupplyNoteLinkEvent,
    MarkSupplyAsPaidEvent,
    MarkSupplyAsNotPaidEvent
} from '../../core/app-store/events/supply.events';
import { SupplyApiService } from '../../core/api-integration/services/supply-api.service';
import {isValidObject} from '../../core/shared/helpers/common.helpers';
import {Supplier_Dto} from "../../core/app-dto/supplier.dto";


@Injectable()
export class SupplyUIEventProcessor {

    constructor(private actions$: Actions,
                private appState$: Store<store.State>,
                private supplyApiService: SupplyApiService) {
    }

    @Effect()
    GetSuppliesPagedResultEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetSuppliesPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetSuppliesPagedResultEvent.identifier),
            map((action: GetSuppliesPagedResultEvent) => action),
            switchMap((action: GetSuppliesPagedResultEvent) => {
                return new Observable((observer) => {
                    this.supplyApiService.u_getFilteredSuppliesPaginated(action.parameters, action.isStorno)
                        .subscribe((res: PagedResult<Supply_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<Supply_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    GetSuppliesWithUnpaidInvoicePagedResultEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetSuppliesWithUnpaidInvoicePagedResultEvent.identifier, EventWithScreenLoading.identifier + GetSuppliesWithUnpaidInvoicePagedResultEvent.identifier),
            map((action: GetSuppliesWithUnpaidInvoicePagedResultEvent) => action),
            switchMap((action: GetSuppliesWithUnpaidInvoicePagedResultEvent) => {
                return new Observable((observer) => {
                    this.supplyApiService.u_getFilteredSuppliesWithUnpaidInvoicePaginated(action.parameters)
                        .subscribe((res: PagedResult<Supply_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<Supply_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    GetSuppliesEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetSuppliesEvent.identifier, EventWithScreenLoading.identifier + GetSuppliesEvent.identifier),
            map((action: GetSuppliesEvent) => action),
            switchMap((action: GetSuppliesEvent) => {
                return new Observable((observer) => {
                    this.supplyApiService.u_getSupplies(action.isStorno, action.supplierId)
                        .subscribe((res: Supply_Dto[]) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Supply_Dto[]>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    getSupply$: Observable<any> = this.actions$
        .pipe(
            ofType(GetSupplyEvent.identifier, EventWithScreenLoading.identifier + GetSupplyEvent.identifier),
            map((action: GetSupplyEvent) => action),
            switchMap((action: GetSupplyEvent) => {
                return new Observable((observer) => {
                    this.supplyApiService.u_getSupply(action.id)
                        .subscribe((res: Supply_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Supply_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doRegisterSupply$: Observable<any> = this.actions$
        .pipe(
            ofType(RegisterSupplyEvent.identifier, EventWithScreenLoading.identifier + RegisterSupplyEvent.identifier),
            map((action: RegisterSupplyEvent) => action),
            switchMap((action: RegisterSupplyEvent) => {
                return new Observable((observer) => {
                    this.supplyApiService.u_registerSupply(action.model)
                        .subscribe((res: Supply_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Supply_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doEditSupply$: Observable<any> = this.actions$
        .pipe(
            ofType(EditSupplyEvent.identifier, EventWithScreenLoading.identifier + EditSupplyEvent.identifier),
            map((action: EditSupplyEvent) => action),
            switchMap((action: EditSupplyEvent) => {
                return new Observable((observer) => {
                    this.supplyApiService.u_editSupply(action.model)
                        .subscribe((res: Supply_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Supply_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doDeleteSupply$: Observable<any> = this.actions$
        .pipe(
            ofType(DeleteSupplyEvent.identifier, EventWithScreenLoading.identifier + DeleteSupplyEvent.identifier),
            map((action: DeleteSupplyEvent) => action),
            switchMap((action: DeleteSupplyEvent) => {
                return new Observable((observer) => {
                    this.supplyApiService.u_deleteSupply(action.id)
                        .subscribe((res: Supply_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Supply_Dto>(res, action));
                            if(isValidObject(action.callback)) action.callback();
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doActivateSupply$: Observable<any> = this.actions$
        .pipe(
            ofType(ActivateSupplyEvent.identifier, EventWithScreenLoading.identifier + ActivateSupplyEvent.identifier),
            map((action: ActivateSupplyEvent) => action),
            switchMap((action: ActivateSupplyEvent) => {
                return new Observable((observer) => {
                    this.supplyApiService.u_activateSupply(action.id)
                        .subscribe((res: Supply_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Supply_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doDeactivateSupply$: Observable<any> = this.actions$
        .pipe(
            ofType(DeactivateSupplyEvent.identifier, EventWithScreenLoading.identifier + DeactivateSupplyEvent.identifier),
            map((action: DeactivateSupplyEvent) => action),
            switchMap((action: DeactivateSupplyEvent) => {
                return new Observable((observer) => {
                    this.supplyApiService.u_deactivateSupply(action.id)
                        .subscribe((res: Supply_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Supply_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doGetSupplyNoteLink$: Observable<any> = this.actions$
        .pipe(
            ofType(GetSupplyNoteLinkEvent.identifier, EventWithScreenLoading.identifier + GetSupplyNoteLinkEvent.identifier),
            map((action: GetSupplyNoteLinkEvent) => action),
            switchMap((action: GetSupplyNoteLinkEvent) => {
                    return new Observable((observer) => {
                        this.supplyApiService.u_getSupplyNoteLink(action.id)
                            .subscribe((res: string) => {
                                observer.next(new Do_GenericSuccessEvent<string>(res, action));
                                if (isValidObject(action.callback)) action.callback(res);
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doMarkSupplyAsPaid$: Observable<any> = this.actions$
        .pipe(
            ofType(MarkSupplyAsPaidEvent.identifier, EventWithScreenLoading.identifier + MarkSupplyAsPaidEvent.identifier),
            map((action: MarkSupplyAsPaidEvent) => action),
            switchMap((action: MarkSupplyAsPaidEvent) => {
                    return new Observable((observer) => {
                        this.supplyApiService.u_markSupplyAsPaid(action.id)
                            .subscribe((res: Supply_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<Supply_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doMarkSupplyAsNotPaid$: Observable<any> = this.actions$
        .pipe(
            ofType(MarkSupplyAsNotPaidEvent.identifier, EventWithScreenLoading.identifier + MarkSupplyAsNotPaidEvent.identifier),
            map((action: MarkSupplyAsNotPaidEvent) => action),
            switchMap((action: MarkSupplyAsNotPaidEvent) => {
                    return new Observable((observer) => {
                        this.supplyApiService.u_markSupplyAsNotPaid(action.id)
                            .subscribe((res: Supply_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<Supply_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );
}
