import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { EditSupplyItemSandbox } from './edit-supply-item.sandbox';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { SupplyItem_Dto } from '../../../core/app-dto/supply.dto';
import { SupplyItemFormDi } from '../supply-form/supply-item-form.di';
import { CarPart_Dto } from '../../../core/app-dto/car-part.dto';
import { ClientCarParts_Dto } from '../../../core/app-dto/car-service.dto';
import { StaticDataSelectorInput } from '../../../core/shared/components/static-data-selector/static-data-selector.component';
import {ConfigService} from "../../../core/core/app-config.service";


@Component({
  selector: 'app-edit-supply-item',
  templateUrl: './edit-supply-item.component.html',
  styleUrls: ['./edit-supply-item.component.scss']
})
export class EditSupplyItemComponent extends BaseFormModelComponent<SupplyItem_Dto> implements OnInit, OnDestroy {
  public title: string;
  public onClose: Subject<any>;
  public data: SupplyItem_Dto = null;
  public di: SupplyItemFormDi;
  public supplyVatsDataInput: StaticDataSelectorInput;

  constructor(public appState: Store<store.State>,
              public sandbox: EditSupplyItemSandbox,
              private activeModal: BsModalRef,
              public activatedRoute: ActivatedRoute,
              private configService:ConfigService) {
    super(sandbox, null);
  }

  public diInitialize(data: SupplyItem_Dto) {
    this.di = new SupplyItemFormDi(this.configService, data, false);
    this.data = data;
    this.title = 'Editare piesa receptie';
    this.supplyVatsDataInput = {
      form: this.di.form,
      type: 'supplyVats',
      formControlName: 'vatValue',
      isRequired: true,
      label: 'Cota TVA',
      isReadOnly:false
    };
    this.sandbox.initialize();
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();
    if (this.di.isValid()) {
      this.onClose.next(this.di.getModel());
      this.activeModal.hide();
    }
  }

  carPartSelected(value: CarPart_Dto) {
    const carPart = new ClientCarParts_Dto(value);
    this.di.form.controls.code.setValue(carPart.code);
    this.di.form.controls.name.setValue(carPart.name);
    this.di.form.controls.documentedQuantity.setValue(1);
    this.di.form.controls.receivedQuantity.setValue(1);
  }

  addCarPartPromise(carPart) {
    return new Promise((resolve) => {
      const carPartObj = new CarPart_Dto(null);
      carPartObj.name = carPart;
      resolve({...carPartObj, valid: true});
    });
  }
}
