import { BaseDI } from 'src/app/core/shared/base/base.di';
import {CalendarEvent_Dto, CalendarEventType, CalendarStatistics} from 'src/app/core/app-dto/calendar-event.dto';

export class StatisticsDI extends BaseDI<CalendarStatistics> {

  constructor(data: CalendarStatistics = new CalendarStatistics(null)) {
    super(data);
  }

  getModel(): CalendarStatistics {
    return this.model;
  }
}
