import {Action} from '@ngrx/store';
import {
    Do_GenericErrorEvent,
    Do_GenericSuccessEvent,
    Do_ResetStateForSection,
    EventWithScreenLoading,
    EventWithStopingScreenLoader,
    ResetSection,
    SeamlessEvent
} from '../events/base.events';
import * as moment from 'moment';
import {ObjectValidators} from '../../shared/object.validators';
import {Organization_Dto, SuperAdminLogs_Dto} from '../../app-dto/super-admin-logs.dto';
import {GetSuperAdminLogsPagedResultEvent, SearchOrganizationEvent} from '../events/super-admin-logs.events';
import {CarPartState} from './car-part.store';
import {GetCarPartsPagedResultEvent, SearchCarPartEvent} from '../events/car-part.events';
import {PagedResult} from '../../shared/datatable/datatable.helpers';
import {CarPart_Dto} from '../../app-dto/car-part.dto';
import {Audit_Dto} from '../../app-dto/audit.dto';
import {GetAuditPagedResultEvent} from '../events/audit.events';
// import {getOrganizationsSearchList} from '../core/app-store/state-selectors/super-admin-logs.selector';

export interface SuperAdminLogsState {
    lastRefreshTime: number;
    organization: Organization_Dto;
    organizationSearchList: Array<Organization_Dto>;
    superAdminLogsPagedResult: PagedResult<SuperAdminLogs_Dto>;

}

export const INITIAL_STATE: SuperAdminLogsState = Object.assign({}, {
    lastRefreshTime: 0,
    organization: null,
    organizationSearchList: [],
    superAdminLogsPagedResult: new PagedResult<SuperAdminLogs_Dto>([]),
});


export function reducer(state = INITIAL_STATE, action: Action): SuperAdminLogsState {
    if (!action) return state;
    let actionType = action.type;

    actionType = actionType.replace(EventWithScreenLoading.identifier, '');
    actionType = actionType.replace(SeamlessEvent.identifier, '');
    actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
    switch (actionType) {


        case Do_ResetStateForSection.identifier + ResetSection.SuperAdminLogs: {
            return Object.assign({}, state, { superAdminLogsPagedResult: new PagedResult<SuperAdminLogs_Dto>([]) });
        }

        case GetAuditPagedResultEvent.identifier: {
            if ((action as GetSuperAdminLogsPagedResultEvent).withReset) {
                state.superAdminLogsPagedResult = new PagedResult<SuperAdminLogs_Dto>([]);
                return Object.assign({}, state);
            }
            return state;
        }

        case Do_GenericSuccessEvent.identifier + GetSuperAdminLogsPagedResultEvent.identifier: {
            const data = (action as Do_GenericSuccessEvent<PagedResult<SuperAdminLogs_Dto>>).data;
            state.superAdminLogsPagedResult = new PagedResult<SuperAdminLogs_Dto>(data);
            return Object.assign({}, state);
        }

        case Do_GenericErrorEvent.identifier + GetSuperAdminLogsPagedResultEvent.identifier: {
            state.superAdminLogsPagedResult = new PagedResult<SuperAdminLogs_Dto>([]);
            return Object.assign({}, state);
        }


        // SearchOrganization
        case Do_GenericSuccessEvent.identifier + SearchOrganizationEvent.identifier: {
            const organizationsSearchData = (action as Do_GenericSuccessEvent<Array<Organization_Dto>>).data.map(r => new Organization_Dto(r));
            return Object.assign({}, state, {organizationSearchList: organizationsSearchData});
        }

        case Do_GenericErrorEvent.identifier + SearchOrganizationEvent.identifier: {
            return Object.assign({}, state, {organizationSearchList: []});
        }

        default: {
            return state;
        }
    }
}

export const getTimestampCallback = (state: SuperAdminLogsState) => state.lastRefreshTime;
export const getOrganizationCallback = (state: SuperAdminLogsState) => state.organization;
export const getOrganizationSearchListCallback = (state: SuperAdminLogsState) => state.organizationSearchList;
export const getSuperAdminLogsPagedResultCallback = (state: SuperAdminLogsState) => state.superAdminLogsPagedResult;
