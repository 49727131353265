import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { ApiErrorDto } from '../utils/api-error.dto';
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ActiveDataTableParameters, PagedResult } from '../../shared/datatable/datatable.helpers';
import { ErrorHelpers } from '../../shared/error.helpers';
import { OrganizationCost } from '../../app-dto/organization.dto';

class OrganizationCostApiRoutes {
    public static route_for_GET_ORGANIZATION_COSTS_PAGINATED() {
        return '/api/organizationCost/all';
    }
    public static route_for_GET_ORGANIZATION_COST(organizationCostId: string) {
        return `/api/organizationCost/${organizationCostId}`;
    }
    public static route_for_REGISTER_ORGANIZATION_COST() {
        return '/api/organizationCost/';
    }
    public static route_for_EDIT_ORGANIZATION_COST() {
        return '/api/organizationCost/';
    }
    public static route_for_DELETE_ORGANIZATION_COST(organizationCostId: string) {
        return `/api/organizationCost/${organizationCostId}`;
    }
    public static route_for_DELETE_ORGANIZATION_COST_MONTHLY(organizationCostId: string) {
        return `/api/organizationCost/${organizationCostId}/monthly`;
    }
    public static route_for_DEACTIVATE_ORGANIZATION_COST(organizationCostId: string) {
        return `/api/organizationCost/${organizationCostId}/deactivate`;
    }
}

@Injectable()
export class OrganizationCostApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_registerOrganizationCost(organizationCostData: OrganizationCost): Observable<OrganizationCost> {
        const url = this.configService.getAPIBaseUrl() + OrganizationCostApiRoutes.route_for_REGISTER_ORGANIZATION_COST();

        return this.http.post(url, organizationCostData)
            .map((res: any) => {
                return new OrganizationCost(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_editOrganizationCost(organizationCostData: OrganizationCost): Observable<OrganizationCost> {
        const url = this.configService.getAPIBaseUrl() + OrganizationCostApiRoutes.route_for_EDIT_ORGANIZATION_COST();

        return this.http.put(url, organizationCostData)
            .map((res: any) => {
                return new OrganizationCost(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getOrganizationCost(organizationCostId: string): Observable<OrganizationCost> {
        const url = this.configService.getAPIBaseUrl() + OrganizationCostApiRoutes.route_for_GET_ORGANIZATION_COST(organizationCostId);

        return this.http.get(url)
            .map((res: any) => {
                return new OrganizationCost(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getFilteredOrganizationCostsPaginated(data: ActiveDataTableParameters): Observable<PagedResult<OrganizationCost>> {
        const url = this.configService.getAPIBaseUrl() + OrganizationCostApiRoutes.route_for_GET_ORGANIZATION_COSTS_PAGINATED() + '?' + data.toUrlParameters();

        return this.http.get(url)
            .map((res: PagedResult<OrganizationCost>) => {
                return new PagedResult<OrganizationCost>(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deleteOrganizationCost(organizationCostId: string): Observable<OrganizationCost> {
        const url = this.configService.getAPIBaseUrl() + OrganizationCostApiRoutes.route_for_DELETE_ORGANIZATION_COST(organizationCostId);

        return this.http.delete(url)
            .map((res: any) => {
                return new OrganizationCost(null);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deleteOrganizationCostMonthly(organizationCostId: string): Observable<OrganizationCost> {
        const url = this.configService.getAPIBaseUrl() + OrganizationCostApiRoutes.route_for_DELETE_ORGANIZATION_COST_MONTHLY(organizationCostId);

        return this.http.delete(url)
            .map((res: any) => {
                return new OrganizationCost(null);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deactivateOrganizationCost(organizationCostId: string, endingDate: Date): Observable<OrganizationCost> {
        const url = this.configService.getAPIBaseUrl() + OrganizationCostApiRoutes.route_for_DEACTIVATE_ORGANIZATION_COST(organizationCostId) + '?endingDate=' + endingDate.toISOString();
        
        return this.http.put(url, {})
            .map((res: any) => {
                return new OrganizationCost(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
}
