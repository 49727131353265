import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {BaseListWebWithSelectBehaviorComponent} from 'src/app/core/shared/base/base.component';
import {GetInvoicesActionProxy} from './get-invoices.action-proxy';
import {Subject, Subscription} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import {ActionsSubject} from '@ngrx/store';
import {ConfigService} from 'src/app/core/core/app-config.service';
import {Router} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap';
import {ConfirmationDialogService} from 'src/app/core/core/dialog/confirmation-dialog.service';
import {ResetSection, SeamlessEvent} from 'src/app/core/app-store/events/base.events';
import {SearchEvent} from 'src/app/core/app-store';
import {InvoiceListSandbox} from './invoice-list.sandbox';
import {GetOrganizationInvoicesActionProxy} from './get-organization-invoices.action-proxy';
import {InvoiceRequestType} from '../invoice/invoice.component';
import {
    DataTableParametersOrderEntity,
    InvoiceDataTableParameters
} from 'src/app/core/shared/datatable/datatable.helpers';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {GetSubInvoicesForOrganizationActionProxy} from './get-sub-invoices-for-organization.action-proxy';
import {IdName_Dto} from '../../../core/app-dto/misc.dto';
import {Invoice_Dto, InvoiceMaxPermittedAmount_Dto} from '../../../core/app-dto/invoice.dto';
import {SagaExportComponent, SagaExportInput} from '../saga-export/saga-export.component';
import {InvoiceListItemDI} from './invoice-list-item.di';
import {AppUserBusinessRuleProvider} from '../../../core/core/business-rule-provider/app-user.business-rule.provider';
import {ReceiptComponent} from '../../../core/shared/components/receipt/receipt.component';
import {NotifierService} from 'angular-notifier';
import {InvoiceApiService} from '../../../core/api-integration/services/invoice-api.service';

@Component({
    selector: 'app-invoice-list',
    templateUrl: './invoice-list.component.html',
    styleUrls: ['./invoice-list.component.scss']
})

export class InvoiceListComponent extends BaseListWebWithSelectBehaviorComponent<Invoice_Dto> implements OnInit {
    public getInvoicesAP: GetInvoicesActionProxy;
    public getOrganizationInvoicesAP: GetOrganizationInvoicesActionProxy;
    public getOrganizationSubscriptionInvoicesAP: GetSubInvoicesForOrganizationActionProxy;

    public onlyUnpaid: boolean = false;
    public shouldDisplaySubscriptionOrganizationBreadcrumb: boolean = false;
    public searchValue: string = '';
    private searchSubscription = new Subscription();

    public columns: any = [];
    public sortColumns: Array<IdName_Dto> = [];
    public currentPage = 1;


    @Input() organizationId: string = null;
    @Input() invoiceRequestType: InvoiceRequestType = null;
    @Input() canExportSaga: boolean = false;
    @Input() forDashboard: boolean = false;

    @ViewChildren(DataTableDirective) set datatableValue(datatable: QueryList<DataTableDirective>) {
        if (datatable != null) {
            datatable.first.dtInstance.then((dtInstance: DataTables.Api) => {
                this.dtApiInstance = dtInstance;
                this.sandbox.setResetTableCallback(() => {
                    dtInstance.ajax.reload();
                });
            });
        }
    }

    modelChanged: Subject<string> = new Subject<string>();

    constructor(
        public sandbox: InvoiceListSandbox,
        private actionsSubj: ActionsSubject,
        private configService: ConfigService,
        public router: Router,
        private modalService: BsModalService,
        public confirmationDialogService: ConfirmationDialogService,
        public rulesProvider: AppUserBusinessRuleProvider,
        public notifierService: NotifierService,
        private invoiceApiService: InvoiceApiService,
    ) {
        super(sandbox, ResetSection.Invoices);
        this.getInvoicesAP = new GetInvoicesActionProxy(this, sandbox.appState);
        this.getOrganizationInvoicesAP = new GetOrganizationInvoicesActionProxy(this, sandbox.appState);
        this.getOrganizationSubscriptionInvoicesAP = new GetSubInvoicesForOrganizationActionProxy(this, sandbox.appState);

        this.modelChanged.pipe(
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(searchQuery => {
                this.searchValue = searchQuery;
                this.dtApiInstance.search(this.searchValue).draw();
            });

        this.searchSubscription = actionsSubj.subscribe(data => {
            if (data.type == SeamlessEvent.identifier + SearchEvent.identifier) {
                this.searchValue = (<SearchEvent> data).value;
                this.dtApiInstance.search(this.searchValue).draw();
            }
        });
        this.columns = [
            {data: 'select', name: 'Selecteaza', width: '50px', orderable: false},
            {data: 'number', name: 'Numar', width: '10%', orderable: true},
            {data: 'client', name: 'Client', width: '15%', orderable: true},
            {data: 'email', name: 'Email', width: '20%', orderable: true},
            {data: 'cui', name: 'CUI', width: '10%', orderable: true},
            {data: 'deadline', name: 'Scadenta', width: '10%', orderable: true},
            {data: 'type', name: 'Tip', width: '10%', orderable: false},
            {data: 'status', name: 'Status', width: '10%', orderable: true},
            {data: 'totalValue', name: 'Total', width: '5%', orderable: false},
            {data: 'actiune', name: 'Actiune', width: '70px', orderable: false}
        ];
    }

    ngOnInit() {

        if (this.canExportSaga === false) {
            this.columns.splice(0, 1);
        }
        if (this.forDashboard) {
            this.columns = [
                {data: 'number', name: 'Numar', width: '80px', orderable: true},
                {data: 'client', name: 'Client', width: '30%', orderable: true},
                {data: 'deadline', name: 'Scadenta', width: '20%', orderable: true},
                {data: 'status', name: 'Status', width: '20%', orderable: true},
                {data: 'actiune', name: 'Actiune', width: '70px', orderable: false}
            ];
        }

        this.sortColumns = this.columns.filter(c => c.orderable).map(c => ({id: c.data, name: c.name} as IdName_Dto));
        this.initialize((result: Array<InvoiceListItemDI>) => {
            this.items = result;
        });
        if (this.invoiceRequestType == InvoiceRequestType.SubscriptionOrganization) {
            this.shouldDisplaySubscriptionOrganizationBreadcrumb = true;
        }
        const defaultSortIndex = this.columns.indexOf(this.columns.find(f => f.name === 'Status'));
        this.dtOptions = {
            responsive: true,
            paging: false,
            info: false,
            lengthChange: false,
            searching: false,
            pageLength: this.configService.getDefaultPageSize(),
            scrollX: false,
            serverSide: true,
            processing: false,
            ordering: false,
            autoWidth: false,
            scrollY: '300px',
            scrollCollapse: true,
            ajax: (dataTablesParameters: InvoiceDataTableParameters, callback) => {
                dataTablesParameters.search.value = this.searchValue;
                dataTablesParameters.onlyUnpaid = this.onlyUnpaid;
                this.doGetAll(new InvoiceDataTableParameters(dataTablesParameters), callback, true);
            },
            columns: this.columns,
            order: [[defaultSortIndex, 'desc']],
            dom: '<\'row mx-1\'<\'col-sm-12 col-md-6 px-3\'l><\'col-sm-12 col-md-6 px-3\'f>>' + '<\'table-responsive\'tr>' + '<\'row mx-1 align-items-center justify-content-center justify-content-md-between\'<\'col-auto mb-2 mb-sm-0\'i><\'col-auto\'p>>'
        };
    }

    public doGetAll(dataTablesParameters: InvoiceDataTableParameters, callback: any, withReset: boolean) {
        this.sandbox.callback = callback;
        this.sandbox.dataTableParameters = dataTablesParameters;
        if (withReset) {
            setTimeout(() => {this.currentPage = 1; }, 0);
            this.sandbox.dataTableParameters.pageNumber = 1;
            dataTablesParameters.pageNumber = 1;
        }
        switch (this.invoiceRequestType) {
            case InvoiceRequestType.SuperAdmin: {
                this.getInvoicesAP.execute(dataTablesParameters, withReset);
                break;
            }
            case InvoiceRequestType.Organization: {
                if (this.organizationId != null) {
                    this.getOrganizationInvoicesAP.execute(dataTablesParameters, withReset, this.organizationId);
                }
                break;
            }
            case InvoiceRequestType.SubscriptionOrganization: {
                if (this.organizationId != null) {
                    this.getOrganizationSubscriptionInvoicesAP.execute(dataTablesParameters, withReset, this.organizationId);
                }
                break;
            }
        }
    }

    public openInvoice(invoiceId: string) {
        switch (this.invoiceRequestType) {
            case InvoiceRequestType.SuperAdmin: {
                this.router.navigate(['/invoice', invoiceId, 'details']);
                break;
            }
            case InvoiceRequestType.Organization: {
                this.router.navigate(['/invoice', this.organizationId, invoiceId, 'details']);
                break;
            }
            case InvoiceRequestType.SubscriptionOrganization: {
                this.router.navigate(['/invoice/subscription', this.organizationId, invoiceId, 'details']);
                break;
            }
        }
    }


    search(data: any) {
        if (ObjectValidators.isValidString(data)) {
            this.modelChanged.next(data);
        }
        if (data === '') {
            this.modelChanged.next(null);
        }
        return false;
    }

    onSortChange($event) {
        if ($event) {
            const columnId = this.columns.map((e) => e.data).indexOf($event.id);
            const orderEntity = new DataTableParametersOrderEntity({column: columnId, dir: 'asc'});
            this.sandbox.dataTableParameters.order = [orderEntity];
        }
        this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, true);
    }

    onPageChange(currentPage: number) {
        this.currentPage = currentPage;
        this.sandbox.dataTableParameters.pageNumber = currentPage;
        this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
    }


    openSagaExport() {
        const data = new SagaExportInput(this.items.filter(f => f.isSelected).map(r => r.getModel()));
        const modalRef = this.modalService.show(SagaExportComponent, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-xl'
        });
        modalRef.content.dataInput = data;
    }

    printReceipt(invoice: Invoice_Dto) {
        if (invoice.id != null) {
            this.invoiceApiService.u_getMaxPermittedAmount(invoice.id)
                .subscribe((result: InvoiceMaxPermittedAmount_Dto) => {
                if (result.canPrintReceipt) {
                    const modalRef = this.modalService.show(ReceiptComponent, {
                        backdrop: 'static',
                        keyboard: false,
                        class: 'modal-xl'
                    });
                    (<ReceiptComponent> modalRef.content).setData(invoice);
                    (<ReceiptComponent> modalRef.content).setValue(result.maxPermittedAmount);
                } else {
                    this.notifierService.notify('error', result.message);
                }
            });
        }
    }
}
