import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';

import {Observable} from "rxjs";
import {
    AppUserBusinessRule,
    AppUserBusinessRuleProvider
} from "../business-rule-provider/app-user.business-rule.provider";

@Injectable()
export class RulesGuard implements CanActivate {

    private rules: AppUserBusinessRule;
    private predicate: (rule: AppUserBusinessRule) => boolean;

    constructor(private businessRuleProvider: AppUserBusinessRuleProvider) {
        this.businessRuleProvider.subscribeToRules().subscribe((rules: AppUserBusinessRule) => {
            this.rules = rules;
            if (this.rules != null && this.predicate != null) {
                this.resolvePromise();
            }
        });
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        this.isResolved = false;
        this.predicate = next.data.predicate;
        return new Promise((resolve) => {
            this.resolve = resolve;
            if (this.rules != null && this.predicate != null) {
                this.resolvePromise();
            }
        });
    }

    private resolve: any;
    private isResolved: boolean = false;

    private resolvePromise() {
        if (this.isResolved) return;
        if ((this.resolve instanceof Function) == false) return;

        let result = this.predicate(this.rules);

        if (!result) {
            this.resolve(false);
        } else this.resolve(true);
        this.isResolved = true;
    }
}
