import {SendOfferEvent} from '../../../core/app-store/events/offer.events';


import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {Observable} from 'rxjs';
import {IWebComponent} from 'src/app/core/shared/base/base.component';
import {BaseActionProxy} from 'src/app/core/shared/base/base.action-proxy';
import {GetCarServiceEvent} from '../../../core/app-store/events/car-service.events';
import {GetOfferEvent, UpdateOfferClientDataEvent} from '../../../core/app-store/events/offer.events';
import {Offer_Dto, SendOfferPayload_Dto} from '../../../core/app-dto/offer.dto';

export class SendOfferActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(data: Offer_Dto, payload: SendOfferPayload_Dto): any {
        this.appState.dispatch(new SendOfferEvent(data.id, payload));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

