import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {BaseWebComponent, BaseWebComponentWithBreadcrumb} from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { RegisterSupplyActionProxy } from './register-supply.action-proxy';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { EditSupplyActionProxy } from './edit-supply.action-proxy';
import { GetSupplyActionProxy } from '../supply-details/get-supply.action-proxy';
import { ActivatedRoute, Params } from '@angular/router';
import { SaveSupplySandbox } from './save-supply.sandbox';
import { Supply_Dto } from '../../../core/app-dto/supply.dto';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { SupplyFormComponent } from '../supply-form/supply-form.component';
import { SaveSupplyDi } from './save-supply.di';
import {Breadcrumb} from "../../../core/app-dto/breadcrumb.dto";


@Component({
  selector: 'app-save-supply',
  templateUrl: './save-supply.component.html',
  styleUrls: ['./save-supply.component.scss']
})
export class SaveSupplyComponent extends BaseWebComponentWithBreadcrumb implements OnInit, OnDestroy {
  public registerSupplyAP: RegisterSupplyActionProxy;
  public editSupplyAP: EditSupplyActionProxy;
  public getSupplyAP: GetSupplyActionProxy;
  public title: string;
  public objectName: string;
  public supplyId: string = null;
  public data: Supply_Dto = null;
  public isStorno = false;
  public forStorno = false;

  @ViewChild(SupplyFormComponent, { static: false })
  private supplyFormComponent: SupplyFormComponent;


  constructor(public appState: Store<store.State>,
              public sandbox: SaveSupplySandbox,
              public activatedRoute: ActivatedRoute) {
    super(sandbox, ResetSection.Supply);
    this.registerSupplyAP = new RegisterSupplyActionProxy(this, sandbox.appState);
    this.editSupplyAP = new EditSupplyActionProxy(this, sandbox.appState);
    this.getSupplyAP = new GetSupplyActionProxy(this, sandbox.appState);
    this.activatedRoute.queryParams
        .subscribe((params: any) => {
          if (ObjectValidators.isValidObject(params) && ObjectValidators.isValidObject(params.new) != null) {
            if (params.forStorno === 'true') {
              this.forStorno = true;
            }
          }
        });
    this.setTitle();
    this.setBreadcrumbAP.execute([ new Breadcrumb({text: 'Acasa', url: '/dashboard'}),new Breadcrumb({text: 'Receptii', url:'/supply/all?isStorno=' + (this.isStorno || this.forStorno)}),
      new Breadcrumb({text:'Salveaza ' +this.objectName})]);
  }

  ngOnInit() {
    this.setTitle();
    this.activatedRoute.params.subscribe((params: Params) => {
      if (ObjectValidators.isValidObject(params) && ObjectValidators.isValidObject(params.new) != null) {
        if (params.id !== undefined) {
          this.supplyId = params.id;
          this.getSupplyAP.execute(this.supplyId);
        } else {
          this.dataLoaded = true;
        }

        if (params.stornoId !== undefined) {
          this.getSupplyAP.execute(params.stornoId);
        }
      }
    });

    this.initialize((data: Supply_Dto) => {
      this.isStorno = !!data.isStorno;
      this.setTitle();
      this.di = new SaveSupplyDi(data);
      this.data = data;
      this.setBreadcrumbAP.execute([ new Breadcrumb({text: 'Acasa', url: '/dashboard'}),new Breadcrumb({text: 'Receptii', url:'/supply/all?isStorno=' + (this.isStorno || this.forStorno)}),
        new Breadcrumb({text:'Salveaza ' +this.objectName})]);
      this.dataLoaded = true;
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  public accept() {
    this.supplyFormComponent.accept();
  }

  public formSaved(model: Supply_Dto) {
    if (model.id && this.forStorno === false) {
      this.editSupplyAP.execute(model);
    } else {
      this.registerSupplyAP.execute(model);
    }
  }

  public setTitle() {
    this.title = this.isStorno || this.forStorno ? 'Stornari' : 'Receptii';
    this.objectName = this.isStorno || this.forStorno ? 'Stornare' : 'Receptie';
  }
}
