import { ObjectValidators } from '../shared/object.validators';
import { Base_Dto, BaseCRUDRules, BusinessRule, IdName_Dto } from './misc.dto';
import * as moment from 'moment';
import { isValidObject } from "../shared/helpers/common.helpers";

export class DepartmentRules extends BaseCRUDRules {
  public canActivate: BusinessRule;
  public canDeactivate: BusinessRule;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.canActivate = new BusinessRule(data.canActivate);
      this.canDeactivate = new BusinessRule(data.canDeactivate);

    } else {
      this.canActivate = new BusinessRule();
      this.canDeactivate = new BusinessRule();
    }
  }
}

export class Department_Dto extends Base_Dto {
  public name: string;
  public laborPricePerHour: number;

  public currency: IdName_Dto;
  public rules: DepartmentRules;
  public createdOn: Date;
  public updatedOn: Date;
  public priceHasVAT: boolean;
  public priceWithoutVAT: number;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      
      this.name = data.name;
      this.priceHasVAT = data.priceHasVAT;
      this.priceWithoutVAT = data.priceWithoutVAT;
      this.laborPricePerHour = data.laborPricePerHour;
      if (isValidObject(data.currency)) {
        this.currency = new IdName_Dto(data.currency);
      }
      this.rules = new DepartmentRules(data.rules);
      if (ObjectValidators.isValidObject(data.createdOn))
        this.createdOn = moment.utc(data.createdOn).toDate();
      if (ObjectValidators.isValidObject(data.updatedOn))
        this.updatedOn = moment.utc(data.updatedOn).toDate();
    }
  }
}

export class DepartmentDropdown_Dto extends Department_Dto {
  public disabled: boolean = false;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.disabled = this.rules.canActivate.value;
    }
  }
}
