import {SeamlessEvent, UIGeneratedEvent} from "./base.events";
import {
    ActiveDataTableParameters,
    DataTableParameters,
    DataTableParametersColumnEntity, DataTableParametersOrderEntity
} from "../../shared/datatable/datatable.helpers";
import {AppNotification} from "../../app-dto/core.dto";

export class NotificationDataTableParameters extends DataTableParameters {
    public onlyNotRead: boolean = false;

    constructor(data?: any) {
        super(data);
        if (data != null && data !== undefined) {
            this.onlyNotRead = data.onlyNotRead || false;
            this.pageNumber = data.pageNumber || 1;
        } else {
            this.onlyNotRead = false;
            this.pageNumber = 1;
        }
    }

    toUrlParameters(): string {
        let result = super.toUrlParameters();
        result = result + '&onlyNotRead=' + this.onlyNotRead.toString();
        return result;
    }

    public static getDefault(): NotificationDataTableParameters {
        return new NotificationDataTableParameters({
            columns: [new DataTableParametersColumnEntity({data: 'createdOn', name: 'createdOn', orderable: true})],
            order: [new DataTableParametersOrderEntity({column: 0, name: 'desc'})],
            pageNumber: 1
        });
    }
}

export class GetNotificationsPagedResultEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = "GetNotificationsPagedResultEvent";

    constructor(public parameters: NotificationDataTableParameters, public  callback: (result: Array<AppNotification>) => void, public withReset: boolean = false) {
        super();
        this.type += GetNotificationsPagedResultEvent.identifier;
    }
}

export class ProcessAppNotificationEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = "ProcessAppNotificationEvent";

    constructor(public data: AppNotification) {
        super();
        this.type += ProcessAppNotificationEvent.identifier;
    }
}

export class MarkAppNotificationAsReadEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = "MarkAppNotificationAsReadEvent";

    constructor(public data: AppNotification) {
        super();
        this.type += MarkAppNotificationAsReadEvent.identifier;
    }
}

export class MarkAppNotificationAsNotReadEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = "MarkAppNotificationAsNotReadEvent";

    constructor(public data: AppNotification) {
        super();
        this.type += MarkAppNotificationAsNotReadEvent.identifier;
    }
}

export class DeleteAppNotificationEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = "DeleteAppNotificationEvent";

    constructor(public data: AppNotification) {
        super();
        this.type += DeleteAppNotificationEvent.identifier;
    }
}

export class GetNoOfUnreadNotificationsEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = "GetNoOfUnreadNotificationsEvent";

    constructor() {
        super();
        this.type += GetNoOfUnreadNotificationsEvent.identifier;
    }
}
