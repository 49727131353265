import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseFormComponent } from 'src/app/core/shared/base/base.component';
import { ActivateSubscriptionDI } from './activate-subscription.di';
import { ActivateSubscriptionSandbox } from './activate-subscription.sandbox';
import { ActivateSubscriptionActionProxy } from './activate-subscription.action-proxy';
import { ActivatedRoute, Params } from '@angular/router';
import { ResetSection } from 'src/app/core/app-store/events/base.events';


@Component({
  selector: 'app-activate-subscription',
  templateUrl: './activate-subscription.component.html',
  styleUrls: ['./activate-subscription.component.scss']
})
export class ActivateSubscriptionComponent extends BaseFormComponent implements OnInit, OnDestroy {

  public activateSubscriptionAP: ActivateSubscriptionActionProxy;

  public uid: string = null;

  public errorMessage: string = '';

  constructor(
    public sandbox: ActivateSubscriptionSandbox,
    public activatedRoute: ActivatedRoute,
  ) {
    super(sandbox, ResetSection.ActivateSubscription);
    this.activateSubscriptionAP = new ActivateSubscriptionActionProxy(this, sandbox.appState);

  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params["code"] != undefined) {
        this.uid = params["code"];
        this.di = new ActivateSubscriptionDI();
        this.di.model.code = decodeURI(this.uid);
      }
    });

    this.initialize((message: string) => {
      this.errorMessage = message;
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  saveForm() {

    this.formSubmitted = true;
    this.activateSubscriptionAP.execute();
  }
}
