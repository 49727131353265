import { EventWithScreenLoading, SeamlessEvent, UIGeneratedEvent, } from './base.events';
import { CarWork_Dto } from '../../app-dto/car-work.dto';

export class GetCarWorkEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetCarWorkEvent";

  constructor(public id: string, public callback: (data: CarWork_Dto) => void) {
    super();
    this.type += GetCarWorkEvent.identifier;
  }
}


export class SearchCarWorkEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "SearchCarWorkEvent";

  constructor(public search: string,public model: string,public make: string,public year: number, public callBack: Function) {
    super();
    this.type += SearchCarWorkEvent.identifier;
  }
}

