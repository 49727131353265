import { Component, OnInit, OnDestroy } from '@angular/core';
import {BaseWebComponent, BaseWebComponentWithBreadcrumb} from 'src/app/core/shared/base/base.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GetCarActionProxy } from './get-car.action-proxy';
import { CarDetailsSandbox } from './car-details.sandbox';
import { CarDetailsDi } from './car-details.di';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { Car_Dto } from '../../../core/app-dto/car.dto';
import { SaveCarComponent } from '../save-car/save-car.component';
import { BsModalService } from 'ngx-bootstrap';
import { join, map } from 'lodash';
import { ActivateCarActionProxy } from '../car-list/activate-car.action-proxy';
import { DeactivateCarActionProxy } from '../car-list/deactivate-car.action-proxy';
import { DeleteCarActionProxy } from '../car-list/delete-car.action-proxy';
import {ConfirmationDialogService} from "../../../core/core/dialog/confirmation-dialog.service";
import {Breadcrumb} from "../../../core/app-dto/breadcrumb.dto";
import {CarsListItemDi} from "../car-list/cars-list-item.di";
import {CarService_Dto} from "../../../core/app-dto/car-service.dto";
import {AddEditCarInServiceWizardComponent} from "../../../car-service/components/add-edit-car-in-service-wizard/add-edit-car-in-service-wizard.component";

@Component({
  selector: 'app-car-details',
  templateUrl: './car-details.component.html',
  styleUrls: ['./car-details.component.scss']
})
export class CarDetailsComponent extends BaseWebComponentWithBreadcrumb implements OnInit, OnDestroy {
  public getCarAP: GetCarActionProxy;
  public deleteCarAP: DeleteCarActionProxy;
  public activateCarAP: ActivateCarActionProxy;
  public deactivateCarAP: DeactivateCarActionProxy;
  public carId: string = null;

  constructor(
    public sandbox: CarDetailsSandbox,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public confirmationDialogService: ConfirmationDialogService,
    private modalService: BsModalService
  ) {
    super(sandbox, ResetSection.Car);
    this.getCarAP = new GetCarActionProxy(this, sandbox.appState);
    this.activateCarAP = new ActivateCarActionProxy(this, sandbox.appState);
    this.deactivateCarAP = new DeactivateCarActionProxy(this, sandbox.appState);
    this.deleteCarAP = new DeleteCarActionProxy(this, sandbox.appState);
    this.di = new CarDetailsDi();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id !== undefined) {
        this.carId = params.id;
        this.getCarAP.execute(this.carId, null);
      }
    });

    this.initialize((data: Car_Dto) => {
      this.di = new CarDetailsDi(data);
      this.setBreadcrumbAP.execute([ new Breadcrumb({text: 'Acasa', url: '/dashboard'}), new Breadcrumb({text: 'Masini', url: '/car-service/cars/just-cars'}),
        new Breadcrumb({text: data.registrationNumber})]);
      this.dataLoaded = true;
    });

  }

  ngOnDestroy() {
    this.destroy();
  }

  formatFuels(fuelsList) {
    return join(map(fuelsList, 'name'), ', ');
  }

  updateCar() {
    const modalRef = this.modalService.show(SaveCarComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = this.di.model;
  }
  addCarInService(){
    const carService = new CarService_Dto({
      car: this.di.getModel(),
      carServiceEntryData : {}
    });
    const initialState = {
      carService,
      title: 'Adauga masina in service'
    };
    const modalRef = this.modalService.show(AddEditCarInServiceWizardComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
    this.router.navigate(['car-service','cars', 'all']);
  }

  activateCar(carId: string) {
    this.activateCarAP.execute(carId);
  }

  deactivateCar(carId: string) {
    this.deactivateCarAP.execute(carId);
  }

  deleteCar(carId: string) {
    this.confirmationDialogService.confirm(
      "Stergere masina",
      "Urmeaza sa stergeti masina <b>" + this.di.model.registrationNumber + "</b>. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        this.deleteCarAP.execute(carId, ()=>{
          this.router.navigate(['/car/all']);
        });
      }
    });

  }
}
