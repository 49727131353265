import {AfterViewInit, Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActionsSubject} from '@ngrx/store';
import {BaseListWebComponent} from 'src/app/core/shared/base/base.component';
import {DataTableDirective} from 'angular-datatables';
import {Subscription} from 'rxjs/Subscription';
import {ConfigService} from 'src/app/core/core/app-config.service';
import {Router} from '@angular/router';
import {ResetSection, SeamlessEvent} from 'src/app/core/app-store/events/base.events';
import {SearchEvent} from 'src/app/core/app-store';
import {ActiveDataTableParameters, DataTableParametersOrderEntity} from 'src/app/core/shared/datatable/datatable.helpers';
import {BsModalService} from 'ngx-bootstrap';
import {GetMechanicsActionProxy} from './get-mechanics.action-proxy';
import {MechanicsListSandbox} from './mechanics-list.sandbox';
import {DeleteMechanicActionProxy} from '../../common/action-proxies/delete-mechanic.action-proxy';
import {SaveMechanicComponent} from '../save-mechanic/save-mechanic.component';
import {getFormattedIdNameList} from '../../../core/shared/helpers/common.helpers';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {Mechanic_Dto} from 'src/app/core/app-dto/mechanic.dto';
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";
import {ConfirmationDialogService} from "../../../core/core/dialog/confirmation-dialog.service";
import {ActivateMechanicComponent} from '../activate-mechanic/activate-mechanic.component';
import {DeactivateMechanicComponent} from '../deactivate-mechanic/deactivate-mechanic.component';
import {EditMechanicHistoryComponent} from '../edit-mechanic-history/edit-mechanic-history.component';
import {AddUserAccountComponent} from "../add-user-account/add-user-account.component";
import {DeleteAccountForMechanicActionProxy} from "../../common/action-proxies/delete-account-for-mechanic.action-proxy";
import { SetBreadcrumbActionProxy } from 'src/app/core/breadcrumb/set-breadcrumb.action-proxy';
import { IdName_Dto } from 'src/app/core/app-dto/misc.dto';


@Component({
  selector: 'app-mechanics-list',
  templateUrl: './mechanics-list.component.html',
  styleUrls: ['./mechanics-list.component.scss']
})
export class MechanicsListComponent extends BaseListWebComponent implements OnInit, AfterViewInit {
  public getMechanicsAP: GetMechanicsActionProxy;
  public deleteMechanicAP: DeleteMechanicActionProxy;
  public deleteAccountForMechanicAP: DeleteAccountForMechanicActionProxy;
  public isActive: boolean = true;
  public searchValue: string = '';
  private searchMechanic = new Subscription();
  public columns: any = [];
  public sortColumns: Array<IdName_Dto> = [];
  public currentPage = 1;

  @ViewChildren(DataTableDirective) set datatableValue(datatable: QueryList<DataTableDirective>) {
    if (datatable != null) {
      datatable.first.dtInstance.then((dtInstance: DataTables.Api) => {
        this.dtApiInstance = dtInstance;
        this.sandbox.setResetTableCallback(() => {
          dtInstance.ajax.reload();
        });
      });
    }
  }

  modelChanged: Subject<string> = new Subject<string>();

  constructor(
    public sandbox: MechanicsListSandbox,
    private actionsSubj: ActionsSubject,
    private configService: ConfigService,
    public router: Router,
    private modalService: BsModalService, public confirmationDialogService: ConfirmationDialogService,
    public rulesProvider: AppUserBusinessRuleProvider
  ) {
    super(sandbox, ResetSection.Mechanics);
    this.getMechanicsAP = new GetMechanicsActionProxy(this, sandbox.appState);
    this.deleteMechanicAP = new DeleteMechanicActionProxy(this, sandbox.appState);
    this.deleteAccountForMechanicAP = new DeleteAccountForMechanicActionProxy(this, sandbox.appState);

    this.modelChanged.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(searchQuery => {
        this.searchValue = searchQuery;
        this.dtApiInstance.search(this.searchValue).draw();
      });

    this.searchMechanic = actionsSubj.subscribe(data => {
      if (data.type === SeamlessEvent.identifier + SearchEvent.identifier) {
        this.searchValue = (data as SearchEvent).value;
        this.dtApiInstance.search(this.searchValue).draw();
      }
    });
    this.columns = [
      { data: 'name', name: 'Nume', width: '30%' },
      { data: 'age', name: 'Data nasterii', width: '20%' },
      { data: 'specializations', name: 'Specializari', width: '30%'},
      { data: 'salary', name: 'Salariu', width: '30%' },
      { data: 'action', width: '50px'},
      { data: 'createdOn', width: '0'}
    ];
    this.sortColumns = this.columns.filter(c => c.name).map(c => ({id: c.data, name: c.name} as IdName_Dto));
  }

  ngAfterViewInit() {
    this.setBreadcrumbAP.execute([{text: 'Acasa', url: '/dashboard'}, {text: 'Mecanici'}]);
  }

  ngOnInit() {
    this.initialize();

    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      pageLength: this.configService.getDefaultPageSize(),
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      ajax: (dataTablesParameters: ActiveDataTableParameters, callback) => {
        dataTablesParameters.active = this.isActive;
        dataTablesParameters.search.value = this.searchValue;
        this.doGetAll(new ActiveDataTableParameters(dataTablesParameters), callback, true);
      },
      columns: this.columns,
      order: [[5, 'desc']],
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
  }

  public doGetAll(dataTablesParameters: ActiveDataTableParameters, callback: any, withReset: boolean) {
    this.sandbox.callback = callback;
    this.sandbox.dataTableParameters = dataTablesParameters;

    if (withReset) {
      setTimeout(() => {this.currentPage = 1; }, 0);
      this.sandbox.dataTableParameters.pageNumber = 1;
      dataTablesParameters.pageNumber = 1;
    }
    this.getMechanicsAP.execute(dataTablesParameters, withReset);
  }

  registerMechanic() {
    const modalRef = this.modalService.show(SaveMechanicComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
    modalRef.content.dataInput = null;
  }

  getMechanicSpecializationsString(mechanicSpecializations) {
    return getFormattedIdNameList(mechanicSpecializations);
  }

  activateMechanic(mechanic: Mechanic_Dto) {
    const modalRef = this.modalService.show(ActivateMechanicComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
    modalRef.content.dataInput = mechanic;
  }

  deactivateMechanic(mechanic: Mechanic_Dto) {
    const modalRef = this.modalService.show(DeactivateMechanicComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
    modalRef.content.dataInput = mechanic;
  }

  deleteMechanic(mechanic: Mechanic_Dto) {
    this.confirmationDialogService.confirm(
      "Stergere mecanic",
      "Urmeaza sa stergeti mecanicul <b>" + mechanic.firstName + " " + mechanic.lastName + "</b>. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        this.deleteMechanicAP.execute(mechanic.id);
      }
    });

  }
 deleteAccountForMechanic(mechanic: Mechanic_Dto) {
    this.confirmationDialogService.confirm(
      "Stergere Cont Mecanic",
      "Urmeaza sa stergeti contul pentru mecanicul <b>" + mechanic.firstName + " " + mechanic.lastName + "</b>. Acesta nu va mai avea acces in aplicatie. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        this.deleteAccountForMechanicAP.execute(mechanic.id);
      }
    });

  }

  updateMechanic(mechanic: Mechanic_Dto) {
    const modalRef = this.modalService.show(SaveMechanicComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
    modalRef.content.dataInput = mechanic;
  }

  addAccountForMechanic(mechanic: Mechanic_Dto) {
    const modalRef = this.modalService.show(AddUserAccountComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
    modalRef.content.dataInput = mechanic;
  }

  updateMechanicHistory(mechanic: Mechanic_Dto) {
    const modalRef = this.modalService.show(EditMechanicHistoryComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
    modalRef.content.dataInput = mechanic;
  }

  search(data: any) {
    if (ObjectValidators.isValidString(data))
      this.modelChanged.next(data);
    if (data === "")
      this.modelChanged.next(null);
    return false;
  }

  includeDeactivatedChecked(value: boolean) {
    this.isActive = value;
    this.dtApiInstance.ajax.reload();
  }

  onSortChange($event) {
    if ($event) {
      const columnId = this.columns.map((e) => e.data).indexOf($event.id);
      const orderEntity = new DataTableParametersOrderEntity({column: columnId, dir: 'asc'});
      this.sandbox.dataTableParameters.order = [orderEntity];
    }
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, true);
  }

  onPageChange(currentPage: number) {
    this.currentPage = currentPage;
    this.sandbox.dataTableParameters.pageNumber = currentPage;
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
  }
}
