import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { YesNoComponent, YesNoComponentInput } from '../../../core/shared/components/yes-no/yes-no.component';
import { Supplier_Dto } from '../../../core/app-dto/supplier.dto';
import { Address } from '../../../core/app-dto/core.dto';
import { PhoneReg } from '../../../core/shared/form/custom-form.validator';

export class SaveSupplierModalDi extends BaseFormDI<Supplier_Dto> {
  public vatPayer: YesNoComponentInput;
  public vatAtPayment: YesNoComponentInput;

  constructor(data: Supplier_Dto = new Supplier_Dto(null)) {
    super(new Supplier_Dto(data));
    this.initForm();
    this.vatPayer = new YesNoComponentInput(this.model.vatPayer, !this.model.vatPayer, 'Platitor TVA', this.form, 'vatPayer');
    this.vatAtPayment = new YesNoComponentInput(this.model.vatAtPayment, !this.model.vatAtPayment, 'TVA la incasare', this.form, 'vatAtPayment');
  }

  getModel(): Supplier_Dto {
    this.model.cifCnp = this.form.value.cifCnp;
    this.model.name = this.form.value.name;
    this.model.code = this.form.value.code;
    this.model.regCom = this.form.value.regCom;
    this.model.address = new Address(this.form.value.address);
    this.model.iban = this.form.value.iban;
    this.model.bank = this.form.value.bank;
    this.model.email = this.form.value.email;
    this.model.contactPerson = this.form.value.contactPerson;
    this.model.phone = this.form.value.phone;
    this.model.vatPayer = this.form.get(this.vatPayer.formGroupName).get(YesNoComponent.YES_CONTROL_NAME).value || false;
    this.model.vatAtPayment = this.form.get(this.vatPayer.formGroupName).get(YesNoComponent.YES_CONTROL_NAME).value || false;

    return this.model;
  }

  initForm() {
    this.form = new FormGroup({
      cifCnp: new FormControl(this.model.cifCnp, Validators.compose([Validators.required])),
      name: new FormControl(this.model.name, Validators.compose([Validators.required])),
      code: new FormControl(this.model.code, Validators.compose([])),
      regCom: new FormControl(this.model.regCom, Validators.compose([])),
      iban: new FormControl(this.model.iban, Validators.compose([])),
      bank: new FormControl(this.model.bank, Validators.compose([])),
      email: new FormControl(this.model.email, Validators.compose([])),
      contactPerson: new FormControl(this.model.contactPerson, Validators.compose([])),
      phone: new FormControl(this.model.phone, Validators.compose([PhoneReg()]))
    });
  }
}
