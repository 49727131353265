import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared/shared.module';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap';

import { CarsListComponent } from './components/car-list/cars-list.component';
import { CarDetailsComponent } from './components/car-details/car-details.component';
import { SaveCarComponent } from './components/save-car/save-car.component';
import { CarFormComponent } from './components/car-form/car-form.component';
import { CarSearchComponent } from './components/car-search/car-search.component';

import { SaveCarSandbox } from './components/save-car/save-car.sandbox';
import { CarDetailsSandbox } from './components/car-details/car-details.sandbox';
import { CarsListSandbox } from './components/car-list/cars-list.sandbox';
import { CarFormSandbox } from './components/car-form/car-form.sandbox';
import { CarSearchSandbox } from './components/car-search/car-search.sandbox';

@NgModule({
  declarations: [
    CarsListComponent,
    CarDetailsComponent,
    SaveCarComponent,
    CarFormComponent,
    CarSearchComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    SharedModule,
    DataTablesModule,
    BsDatepickerModule
  ],
  providers: [
    CarsListSandbox,
    CarDetailsSandbox,
    SaveCarSandbox,
    CarFormSandbox,
    CarSearchSandbox
  ],
  entryComponents: [
    SaveCarComponent
  ],
  exports: [
    CarFormComponent,
    CarSearchComponent
  ]
})
export class CarModule { }
