import { BaseFormActionProxy } from "../../../shared/base/base.action-proxy";
import { Store } from "@ngrx/store";
import * as store from "../../../app-store";
import { Observable } from "rxjs";
import { IWebFormComponent } from 'src/app/core/shared/base/base.component';
import {RequestSubscriptionEvent} from "../../../app-store/events/authentication.events";

export class RequestSubscriptionActionProxy extends BaseFormActionProxy {
    constructor(component: IWebFormComponent,
        appState: Store<store.State>) {
        super(component, appState)
    }

    canExecute(): boolean {
        this.component.markFormAsDirtyAndTouched();
        return this.component.di.isValid();
    }

    execute(): any {
        if (this.canExecute()) {
            this.component.markFormAsNotDirty();
            let model = this.component.di.getModel();
            
            this.appState.dispatch(new RequestSubscriptionEvent(model));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

