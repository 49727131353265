import {BaseDI} from 'src/app/core/shared/base/base.di';
import {Offer_Dto, OfferAcceptedTrace_Dto, OfferDeclinedTrace_Dto, OfferRecipient_Dto} from '../../../core/app-dto/offer.dto';
import {isValidArrayAndHasElements, isValidString} from '../../../core/shared/helpers/common.helpers';

export class ReceiverDi extends BaseDI<OfferRecipient_Dto> {
    public isAccepted: boolean = false;
    public isDeclined: boolean = false;
    public status: string = 'Trimisa';
    public reason: string = '';

    constructor(data: OfferRecipient_Dto, acceptedList: Array<OfferAcceptedTrace_Dto>, declinedList: Array<OfferDeclinedTrace_Dto>) {
        super(new OfferRecipient_Dto(data));
        if (isValidArrayAndHasElements(acceptedList)) {
            this.isAccepted = acceptedList.find(f =>
                (isValidString(this.model.name) ? f.name === this.model.name : true) &&
                (isValidString(this.model.email) ? f.email === this.model.email : true) &&
                (isValidString(this.model.phoneNumber) ? f.phoneNumber === this.model.phoneNumber : true)) != null;

        }
        if (!this.isAccepted && isValidArrayAndHasElements(declinedList)) {
            const foundDeclinedObj = declinedList.find(f =>
                (isValidString(this.model.name) ? f.name === this.model.name : true) &&
                (isValidString(this.model.email) ? f.email === this.model.email : true) &&
                (isValidString(this.model.phoneNumber) ? f.phoneNumber === this.model.phoneNumber : true));
            this.isDeclined = foundDeclinedObj != null;
            if(this.isDeclined) this.reason = foundDeclinedObj.reason;
        }
        this.processStatus();
    }

    processStatus() {
        if (this.isAccepted) {
            this.status = 'Acceptata';
        } else if (this.isDeclined) {
            this.status = 'Refuzata';
        } else {
            this.status = 'Trimisa';
        }
    }

    getModel(): OfferRecipient_Dto {
        return this.model;
    }
}
