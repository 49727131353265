import { Injectable, NgZone } from "@angular/core";
import { select, Store } from '@ngrx/store';
import * as ApplicationStore from '../../../app-store/state';
import { DIUploadItem } from "./upload-notification.di";
import { AzureUploaderService } from "./azure-uploader.service";
import { BaseSandbox } from "../../../shared/base/base.sandbox";
import { DoCancelUploadingFile, DoCancelUploadingTaskFile, UploadInputFinishedEvent, UploadInputEvent } from "../../../app-store/events/upload.events";
import * as uploadStore from "../../../app-store/state-selectors/upload.selectors";
import { UploadInput } from "./upload-notification.component";
import { FileAttachment, FileToUpload } from "../../../app-dto/core.dto";
import { ConfigService } from "../../../core/app-config.service";

@Injectable({ providedIn: 'root' })
export class UploadNotificationSandbox extends BaseSandbox {
  public filesToUpload$ = this.appState$.pipe(select(uploadStore.getUploadItem));
  public items: Array<DIUploadItem> = new Array<DIUploadItem>();
  public currentFilesToUpload: Array<FileToUpload>;
  public message: string = '';
  public fileUploaded: Array<FileAttachment> = [];

  constructor(
    protected appState$: Store<ApplicationStore.State>, private service: AzureUploaderService,
    private configService: ConfigService, private ngZone: NgZone) {
    super(appState$);

  }

  private lockUploadCallback: boolean = false;

  public clearSandboxState() {
    this.items = [];
    this.currentFilesToUpload = [];
    this.lockUploadCallback = false;
    this.fileUploaded = [];
  }

  public initialize() {
    this.clearSandboxState();
  }

  public initializeUpload(result: UploadInput, dataLoaded: (data) => void) {
    
    if (result != null) {
      this.currentFilesToUpload = result.filesToUpload;

      for (var i = 0; i < this.items.length; i++) {
        var missingItem = result.filesToUpload.find(d => d.id == this.items[i].file.id);
        if (missingItem == null) this.items.splice(i, 1);
      }
      for (var i = 0; i < result.filesToUpload.length; i++) {
        var existingItem = this.items.find(d => d.file.id == result.filesToUpload[i].id);
        if (existingItem == null) {

          var diToAdd = new DIUploadItem(result.filesToUpload[i], this.service, (di: DIUploadItem) => {
            if (di.hasError == true) {
              this.items.splice(this.items.indexOf(di), 1);
              result.filesToUpload.splice(result.filesToUpload.indexOf(result.filesToUpload[i]), 1);
            }
            if (di.fileUploaded != null) {
              this.fileUploaded.push(di.fileUploaded);
            }
            this.uploadData = this.updateMessage();
            dataLoaded(this.uploadData);

            this.uploadNextItem(di);
          }, this.ngZone);
          var uploadingDI = this.items.filter(d => d.isUploading == true);
          this.items.push(diToAdd);
          if (uploadingDI == null || uploadingDI.length == 0) diToAdd.initializeUpload();

        }
      }
    } else {
      this.items = [];
      this.currentFilesToUpload = [];
      this.service.cancel();
    }

    // this.uploadData = this.updateMessage();
    // dataLoaded(this.uploadData);
  }

  // public initialize(input: UploadInput, dataLoaded: (data) => void) {
  //   this.clearSandboxState();

  //   if (input.filesToUpload != null) {
  //     this.currentFilesToUpload = input.filesToUpload;

  //     for (var i = 0; i < this.items.length; i++) {
  //       var missingItem = input.filesToUpload.find(d => d.id == this.items[i].file.id);
  //       if (missingItem == null) this.items.splice(i, 1);
  //     }
  //     for (var i = 0; i < input.filesToUpload.length; i++) {
  //       var existingItem = this.items.find(d => d.file.id == input.filesToUpload[i].id);
  //       if (existingItem == null) {

  //         var diToAdd = new DIUploadItem(input.filesToUpload[i], this.service, (di: DIUploadItem) => {
  //           if (di.hasError == true) {
  //             this.items.splice(this.items.indexOf(di), 1);
  //             input.filesToUpload.splice(input.filesToUpload.indexOf(input.filesToUpload[i]), 1);
  //           }
  //           if (di.fileUploaded != null) {
  //             this.fileUploaded.push(di.fileUploaded);
  //           }
  //           this.uploadData = this.updateMessage();
  //           dataLoaded(this.uploadData);

  //           this.uploadNextItem(di);
  //         }, this.ngZone);

  //         var uploadingDI = this.items.filter(d => d.isUploading == true);
  //         this.items.push(diToAdd);
  //         if (uploadingDI == null || uploadingDI.length == 0) diToAdd.initializeUpload();

  //       }
  //     }
  //   } else {
  //     this.items = [];
  //     this.currentFilesToUpload = [];
  //     this.service.cancel();
  //   }

  // }

  public uploadData: any;

  public updateMessage() {
    var uploadingFiles = 0;
    var uploadedFiles = 0;
    this.items.forEach(r => {
      if (r.isUploading)
        uploadingFiles++;
      else if (r.progressValue == 100)
        uploadedFiles++;
      else uploadingFiles++;
    });

    if (uploadingFiles == 0) {
      //setTimeout(() => {
      //}, 3500);
      this.message = "No Files Uploading";
    }
    if (uploadingFiles == 1) {
      this.message = "One File Uploading";
    }
    if (uploadingFiles > 1) {
      this.message = uploadingFiles + "Files Uploading";
    }

    return { uploaded: uploadedFiles, uploading: uploadingFiles };
  }

  private uploadNextItem(di: DIUploadItem) {

    if (this.lockUploadCallback == false) {
      this.lockUploadCallback = true;
      setTimeout(() => {
        if (di.hasError != true) {
          //this.appState$.dispatch(new DoPublishDocument(di.file.uuId, di.file.id));
          
        }
        var itemsToUpload = this.items.filter(d => d.isUploading == false && d.progressValue != 100);
        if (itemsToUpload != null && itemsToUpload.length > 0) {
          itemsToUpload[0].initializeUpload();
        }
        this.lockUploadCallback = false;
      }, 2000);

    }
  }

  public removeAll() {

    var itemsToDelete: Array<DIUploadItem> = new Array<DIUploadItem>();

    this.items.forEach(f => {
      if (f.progressValue == 100)
        itemsToDelete.push(f);
    });

    itemsToDelete.forEach(f => this.removeUploadWithoutDelete(f));
  }

  public removeUploadWithoutDelete(item: DIUploadItem) {
    this.appState$.dispatch(new DoCancelUploadingFile(item.file));
  }

  public removeUploadWithDelete(item: DIUploadItem) {
    this.appState$.dispatch(new DoCancelUploadingFile(item.file));
    if (item.file.uuId != undefined && item.file.uuId != null)
      this.appState$.dispatch(new DoCancelUploadingTaskFile(item.file));
  }

  public unsubscribe(): any {
    this.unregisterEvents();
  }

  dispatchUploadInputFinishedEvent(fileAttachment: FileAttachment) {

    this.appState$.dispatch(new UploadInputFinishedEvent(fileAttachment));
  }
}
