import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';

@Injectable()
export class CarServiceDetailsSandbox extends BaseSandbox {
    public getCarService$ = this.appState$.pipe(select(store.getCarService));

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    clearSandboxState() {
    }

    initialize(carService: (data: CarService_Dto) => {}) {
        this.clearSandboxState();

        this.subscriptions.push(this.getCarService$.subscribe((data: CarService_Dto) => {
            if (ObjectValidators.isValidObject(data)) {
                carService(data);
            }
        }));
    }
}
