import {Observable} from "rxjs";
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Injectable} from "@angular/core";
import * as store from "../../core/app-store";
import "rxjs-compat/add/operator/switchMap";
import {map, switchMap} from "rxjs/operators";
import {SubscriptionApiService} from 'src/app/core/api-integration/services/subscription-api.service';
import {
  ActivateAddonEvent,
  CancelDeactivateAddonEvent,
  ChangeSubscriptionPlanEvent,
  DeactivateAddonEvent,
  DeactivateSubscriptionEvent,
  DeleteOrganizationCuiUrlEvent,
  DeleteOrganizationImageProfileUrlEvent,
  EditSubscriptionEvent,
  EditSubscriptionProfileEvent,
  GetOrganizationCuiUrlEvent,
  GetPayButtonAddonEvent,
  GetSubscriptionEvent,
  GetSubscriptionProfileEvent,
  GetSubscriptionsPagedResultEvent,
  RegisterPreorderAddonEvent,
  ValidateSubscriptionEvent
} from 'src/app/core/app-store/events/subscription.events';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  EventWithScreenLoading,
  SeamlessEvent
} from 'src/app/core/app-store/events/base.events';
import {PagedResult} from 'src/app/core/shared/datatable/datatable.helpers';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {Organization, Preorder} from '../../core/app-dto/organization.dto';
import {SetInitialSetupEvent} from '../../core/app-store/events/user.events';
import {UserApiService} from '../../core/api-integration/services/user-api.service';
import {Router} from "@angular/router";


@Injectable()
export class SubscriptionUIEventProcessor {

  constructor(
    private actions$: Actions,
    private appState$: Store<store.State>,
    private subscriptionApiService: SubscriptionApiService,
    private userApiService: UserApiService,
    private router:Router
  ) {
  }

  @Effect()
  changeSubscriptionPlan$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(ChangeSubscriptionPlanEvent.identifier, EventWithScreenLoading.identifier + ChangeSubscriptionPlanEvent.identifier),
      map((action: ChangeSubscriptionPlanEvent) => action),
      switchMap((action: ChangeSubscriptionPlanEvent) => {
          return new Observable((observer) => {
            this.subscriptionApiService.u_changeSubscriptionPlan(action.organizationId, action.subscriptionTypeId)
              .subscribe((res: Organization) => {
                if (ObjectValidators.isValidObject(res)) {
                  observer.next(new Do_GenericSuccessEvent<Organization>(res, action));
                  this.router.navigate(['auth', 'login']);
                }
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );
  @Effect()
  getSubscriptionPagedResult$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GetSubscriptionsPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetSubscriptionsPagedResultEvent.identifier),
      map((action: GetSubscriptionsPagedResultEvent) => action),
      switchMap((action: GetSubscriptionsPagedResultEvent) => {
          return new Observable((observer) => {
            this.subscriptionApiService.u_getFilteredSubscriptionsPaginated(action.parameters)
              .subscribe((res: PagedResult<Organization>) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<PagedResult<Organization>>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  getSubscription$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GetSubscriptionEvent.identifier, EventWithScreenLoading.identifier + GetSubscriptionEvent.identifier),
      map((action: GetSubscriptionEvent) => action),
      switchMap((action: GetSubscriptionEvent) => {
          return new Observable((observer) => {
            this.subscriptionApiService.u_getSubscription(action.id)
              .subscribe((res: Organization) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<Organization>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  getSubscriptionProfile$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GetSubscriptionProfileEvent.identifier, EventWithScreenLoading.identifier + GetSubscriptionProfileEvent.identifier),
      map((action: GetSubscriptionProfileEvent) => action),
      switchMap((action: GetSubscriptionProfileEvent) => {
          return new Observable((observer) => {
            this.subscriptionApiService.u_getSubscriptionProfile()
              .subscribe((res: Organization) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<Organization>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  editSubscription$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(EditSubscriptionEvent.identifier, EventWithScreenLoading.identifier + EditSubscriptionEvent.identifier),
      map((action: EditSubscriptionEvent) => action),
      switchMap((action: EditSubscriptionEvent) => {
          return new Observable((observer) => {
            this.subscriptionApiService.u_editSubscription(action.model)
              .subscribe((res: Organization) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<Organization>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  editSubscriptionProfile$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(EditSubscriptionProfileEvent.identifier, EventWithScreenLoading.identifier + EditSubscriptionProfileEvent.identifier),
      map((action: EditSubscriptionProfileEvent) => action),
      switchMap((action: EditSubscriptionProfileEvent) => {
          return new Observable((observer) => {
            this.subscriptionApiService.u_editSubscriptionProfile(action.model)
              .subscribe((res: Organization) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<Organization>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  deactivateSubscription$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(DeactivateSubscriptionEvent.identifier, EventWithScreenLoading.identifier + DeactivateSubscriptionEvent.identifier),
      map((action: DeactivateSubscriptionEvent) => action),
      switchMap((action: DeactivateSubscriptionEvent) => {
          return new Observable((observer) => {
            this.subscriptionApiService.u_deactivateSubscription(action.model)
              .subscribe((res: Organization) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<Organization>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  validateSubscription$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(ValidateSubscriptionEvent.identifier, EventWithScreenLoading.identifier + ValidateSubscriptionEvent.identifier),
      map((action: ValidateSubscriptionEvent) => action),
      switchMap((action: ValidateSubscriptionEvent) => {
          return new Observable((observer) => {
            this.subscriptionApiService.u_validateSubscription(action.id)
              .subscribe((res: Organization) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<Organization>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  setUserInitialSetup$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(SetInitialSetupEvent.identifier, EventWithScreenLoading.identifier + SetInitialSetupEvent.identifier),
      map((action: SetInitialSetupEvent) => action),
      switchMap((action: SetInitialSetupEvent) => {
          return new Observable((observer) => {
            this.userApiService.u_setUserInitialSetup(action.id, action.value)
              .subscribe((res: any) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<Organization>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );


  @Effect()
  doGetOrganizationCuiUrlEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetOrganizationCuiUrlEvent.identifier, SeamlessEvent.identifier + GetOrganizationCuiUrlEvent.identifier),
      map((action: GetOrganizationCuiUrlEvent) => action),
      switchMap((action: GetOrganizationCuiUrlEvent) => {
          return new Observable((observer) => {
            this.subscriptionApiService.u_getCuiUrl(action.organziationId)
              .subscribe((res: string) => {
                action.callback(res);
                observer.next(new Do_GenericSuccessEvent<string>(res, action));
                observer.complete();
              }, (error: string) => {
                action.callback(null);
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  doDeleteOrganizationCuiUrlEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(DeleteOrganizationCuiUrlEvent.identifier, SeamlessEvent.identifier + DeleteOrganizationCuiUrlEvent.identifier),
      map((action: DeleteOrganizationCuiUrlEvent) => action),
      switchMap((action: DeleteOrganizationCuiUrlEvent) => {
          return new Observable((observer) => {
            this.subscriptionApiService.u_deleteCuiUrl()
              .subscribe((res: string) => {
                observer.next(new Do_GenericSuccessEvent<string>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  doDeleteOrganizationImageProfileUrlEvent$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(DeleteOrganizationImageProfileUrlEvent.identifier, SeamlessEvent.identifier + DeleteOrganizationImageProfileUrlEvent.identifier),
      map((action: DeleteOrganizationImageProfileUrlEvent) => action),
      switchMap((action: DeleteOrganizationImageProfileUrlEvent) => {
          return new Observable((observer) => {
            this.subscriptionApiService.u_deleteProfileImageUrl()
              .subscribe((res: any) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<string>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  activateAddon$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(ActivateAddonEvent.identifier, EventWithScreenLoading.identifier + ActivateAddonEvent.identifier),
      map((action: ActivateAddonEvent) => action),
      switchMap((action: ActivateAddonEvent) => {
          return new Observable((observer) => {
            this.subscriptionApiService.u_activateAddon(action.id)
              .subscribe((res: Organization) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<Organization>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  deactivateAddon$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(DeactivateAddonEvent.identifier, EventWithScreenLoading.identifier + DeactivateAddonEvent.identifier),
      map((action: DeactivateAddonEvent) => action),
      switchMap((action: DeactivateAddonEvent) => {
          return new Observable((observer) => {
            this.subscriptionApiService.u_deactivateAddon(action.id)
              .subscribe((res: Organization) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<Organization>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  cancelDeactivateAddon$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(CancelDeactivateAddonEvent.identifier, EventWithScreenLoading.identifier + CancelDeactivateAddonEvent.identifier),
      map((action: CancelDeactivateAddonEvent) => action),
      switchMap((action: CancelDeactivateAddonEvent) => {
          return new Observable((observer) => {
            this.subscriptionApiService.u_cancelDeactivateAddon(action.id)
              .subscribe((res: Organization) => {
                if (ObjectValidators.isValidObject(res))
                  observer.next(new Do_GenericSuccessEvent<Organization>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  registerPreorderAddon$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(RegisterPreorderAddonEvent.identifier, EventWithScreenLoading.identifier + RegisterPreorderAddonEvent.identifier),
      map((action: RegisterPreorderAddonEvent) => action),
      switchMap((action: RegisterPreorderAddonEvent) => {
          return new Observable((observer) => {
            this.subscriptionApiService.u_registerPreorderAddon(action.id)
              .subscribe((res: Preorder) => {
                if (ObjectValidators.isValidObject(res)) {
                  observer.next(new Do_GenericSuccessEvent<Preorder>(res, action));
                }
                if (action.callback) action.callback(res);
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  getPayButtonAddon$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GetPayButtonAddonEvent.identifier, EventWithScreenLoading.identifier + GetPayButtonAddonEvent.identifier),
      map((action: GetPayButtonAddonEvent) => action),
      switchMap((action: GetPayButtonAddonEvent) => {
          return new Observable((observer) => {
            this.subscriptionApiService.u_getPayButtonAddon(action.id)
              .subscribe((res: string) => {
                if (ObjectValidators.isValidObject(res)) {
                  observer.next(new Do_GenericSuccessEvent<string>(res, action));
                }
                if (action.callback) action.callback(res);
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );
}
