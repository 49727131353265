import { Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import { map, switchMap } from 'rxjs/operators';
import { Do_GenericSuccessEvent, Do_GenericErrorEvent, EventWithScreenLoading, SeamlessEvent } from 'src/app/core/app-store/events/base.events';
import { PagedResult } from 'src/app/core/shared/datatable/datatable.helpers';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { Department_Dto } from 'src/app/core/app-dto/department.dto';
import {
    ActivateDepartmentEvent,
    DeactivateDepartmentEvent,
    DeleteDepartmentEvent,
    EditDepartmentEvent,
    GetDepartmentEvent, GetDepartmentsEvent,
    GetDepartmentsPagedResultEvent,
    RegisterDepartmentEvent
} from '../../core/app-store/events/department.events';
import { DepartmentApiService } from '../../core/api-integration/services/department-api.service';
import {isValidObject} from "../../core/shared/helpers/common.helpers";


@Injectable()
export class DepartmentUIEventProcessor {

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private departmentApiService: DepartmentApiService
    ) {
    }

    @Effect()
    GetDepartmentsPagedResultEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetDepartmentsPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetDepartmentsPagedResultEvent.identifier),
            map((action: GetDepartmentsPagedResultEvent) => action),
            switchMap((action: GetDepartmentsPagedResultEvent) => {
                return new Observable((observer) => {
                    this.departmentApiService.u_getFilteredDepartmentsPaginated(action.parameters)
                        .subscribe((res: PagedResult<Department_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<Department_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    GetDepartmentsEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetDepartmentsEvent.identifier, SeamlessEvent.identifier + GetDepartmentsEvent.identifier),
            map((action: GetDepartmentsEvent) => action),
            switchMap((action: GetDepartmentsEvent) => {
                return new Observable((observer) => {
                    this.departmentApiService.u_getDepartments()
                        .subscribe((res: Department_Dto[]) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Department_Dto[]>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    getDepartment$: Observable<any> = this.actions$
        .pipe(
            ofType(GetDepartmentEvent.identifier, EventWithScreenLoading.identifier + GetDepartmentEvent.identifier),
            map((action: GetDepartmentEvent) => action),
            switchMap((action: GetDepartmentEvent) => {
                return new Observable((observer) => {
                    this.departmentApiService.u_getDepartment(action.id)
                        .subscribe((res: Department_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Department_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doRegisterDepartment$: Observable<any> = this.actions$
        .pipe(
            ofType(RegisterDepartmentEvent.identifier, EventWithScreenLoading.identifier + RegisterDepartmentEvent.identifier),
            map((action: RegisterDepartmentEvent) => action),
            switchMap((action: RegisterDepartmentEvent) => {
                return new Observable((observer) => {
                    this.departmentApiService.u_registerDepartment(action.model)
                        .subscribe((res: Department_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Department_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doEditDepartment$: Observable<any> = this.actions$
        .pipe(
            ofType(EditDepartmentEvent.identifier, EventWithScreenLoading.identifier + EditDepartmentEvent.identifier),
            map((action: EditDepartmentEvent) => action),
            switchMap((action: EditDepartmentEvent) => {
                return new Observable((observer) => {
                    this.departmentApiService.u_editDepartment(action.model)
                        .subscribe((res: Department_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Department_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doDeleteDepartment$: Observable<any> = this.actions$
        .pipe(
            ofType(DeleteDepartmentEvent.identifier, EventWithScreenLoading.identifier + DeleteDepartmentEvent.identifier),
            map((action: DeleteDepartmentEvent) => action),
            switchMap((action: DeleteDepartmentEvent) => {
                return new Observable((observer) => {
                    this.departmentApiService.u_deleteDepartment(action.id)
                        .subscribe((res: Department_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Department_Dto>(res, action));
                            if(isValidObject(action.callback)) action.callback();
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doActivateDepartment$: Observable<any> = this.actions$
        .pipe(
            ofType(ActivateDepartmentEvent.identifier, EventWithScreenLoading.identifier + ActivateDepartmentEvent.identifier),
            map((action: ActivateDepartmentEvent) => action),
            switchMap((action: ActivateDepartmentEvent) => {
                return new Observable((observer) => {
                    this.departmentApiService.u_activateDepartment(action.id)
                        .subscribe((res: Department_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Department_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doDeactivateDepartment$: Observable<any> = this.actions$
        .pipe(
            ofType(DeactivateDepartmentEvent.identifier, EventWithScreenLoading.identifier + DeactivateDepartmentEvent.identifier),
            map((action: DeactivateDepartmentEvent) => action),
            switchMap((action: DeactivateDepartmentEvent) => {
                return new Observable((observer) => {
                    this.departmentApiService.u_deactivateDepartment(action.id)
                        .subscribe((res: Department_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Department_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );
}
