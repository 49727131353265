import { EventWithScreenLoading, SeamlessEvent, UIGeneratedEvent, } from './base.events';
import { CarAction_Dto, CarService_Dto, ClientCarParts_Dto } from '../../app-dto/car-service.dto';
import {
  ActiveDataTableParameters,
  CarServiceListDataTableParameters,
  DataTableParameters
} from '../../shared/datatable/datatable.helpers';
import { CarPart_Dto } from '../../app-dto/car-part.dto';
import { Workmanship_Dto } from '../../app-dto/workmanship.dto';

export class AddCarInServiceEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "AddCarInServiceEvent";

  constructor(public model: CarService_Dto, public appointmentId: string, public callback: Function) {
    super();
    this.type += AddCarInServiceEvent.identifier;
  }
}

export class GetCarServiceEntriesPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetCarServiceEntriesPagedResultEvent";

  constructor(public parameters: CarServiceListDataTableParameters, public withReset: boolean = false, public callback: Function) {
    super();
    this.type += GetCarServiceEntriesPagedResultEvent.identifier;
  }
}

export class GetAllCarServiceEntriesForReportingEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetAllCarServiceEntriesForReportingEvent";

  constructor(public parameters: DataTableParameters, public startDate: Date, public endDate: Date, public callback: Function) {
    super();
    this.type += GetAllCarServiceEntriesForReportingEvent.identifier;
  }
}

export class GetCarServiceEntriesEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetCarServiceEntriesEvent";

  constructor(public callback: Function) {
    super();
    this.type += GetCarServiceEntriesEvent.identifier;
  }
}

export class GetCarServiceEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetCarServiceEvent";

  constructor(public id: string) {
    super();
    this.type += GetCarServiceEvent.identifier;
  }
}

export class GetCarServiceUnatuhorizedEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetCarServiceUnatuhorizedEvent";

  constructor(public code: string, public carServiceEntryId: string) {
    super();
    this.type += GetCarServiceUnatuhorizedEvent.identifier;
  }
}

export class DeleteCarServiceEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeleteCarServiceEvent";

  constructor(public id: string, public callback: Function) {
    super();
    this.type += DeleteCarServiceEvent.identifier;
  }
}

export class FinalizeCarServiceEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "FinalizeCarServiceEvent";

  constructor(public id: string) {
    super();
    this.type += FinalizeCarServiceEvent.identifier;
  }
}

export class EditCarServiceCarEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "EditCarServiceCarEvent";

  constructor(public model: CarService_Dto) {
    super();
    this.type += EditCarServiceCarEvent.identifier;
  }
}

export class EditCarServiceClientEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "EditCarServiceClientEvent";
  constructor(public model: CarService_Dto) {
    super();
    this.type += EditCarServiceClientEvent.identifier;
  }
}

export class EditCarServiceCarWorksEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "EditCarServiceCarWorksEvent";
  constructor(public model: CarService_Dto) {
    super();
    this.type += EditCarServiceCarWorksEvent.identifier;
  }
}

export class EditCarServiceEntryDataEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "EditCarServiceEntryDataEvent";
  constructor(public model: CarService_Dto) {
    super();
    this.type += EditCarServiceEntryDataEvent.identifier;
  }
}

export class EditCarServiceEntryFinalInspectionEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "EditCarServiceEntryFinalInspectionEvent";
  constructor(public model: CarService_Dto) {
    super();
    this.type += EditCarServiceEntryFinalInspectionEvent.identifier;
  }
}

export class UpdateCarServiceAddClientPartEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "UpdateCarServiceAddClientPartEvent";
  constructor(public model: ClientCarParts_Dto, public id: string) {
    super();
    this.type += UpdateCarServiceAddClientPartEvent.identifier;
  }
}

export class UpdateCarServiceUpdateClientPartEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "UpdateCarServiceUpdateClientPartEvent";
  constructor(public model: ClientCarParts_Dto, public id: string) {
    super();
    this.type += UpdateCarServiceUpdateClientPartEvent.identifier;
  }
}

export class UpdateCarServiceAddCarPartEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "UpdateCarServiceAddCarPartEvent";
  constructor(public model: CarPart_Dto, public id: string) {
    super();
    this.type += UpdateCarServiceAddCarPartEvent.identifier;
  }
}

export class UpdateCarServiceUpdateCarPartEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "UpdateCarServiceUpdateCarPartEvent";
  constructor(public model: CarPart_Dto, public id: string) {
    super();
    this.type += UpdateCarServiceUpdateCarPartEvent.identifier;
  }
}

export class DeleteCarPartFromCarService{
  constructor(public carServiceId:string, public carPart:CarPart_Dto){}
}
export class DeleteCarServiceCarPartEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeleteCarServiceCarPartEvent";
  constructor(public data:DeleteCarPartFromCarService) {
    super();
    this.type += DeleteCarServiceCarPartEvent.identifier;
  }
}

export class DeleteCarServiceClientCarPartEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeleteCarServiceClientCarPartEvent";
  constructor(public carServiceId, public carPartId: string) {
    super();
    this.type += DeleteCarServiceClientCarPartEvent.identifier;
  }
}

//////////////////////////////

export class AddCarInServiceActionEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "AddCarInServiceActionEvent";
  constructor(public model: CarAction_Dto) {
    super();
    this.type += AddCarInServiceActionEvent.identifier;
  }
}

export class UpdateCarInServiceActionEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "UpdateCarInServiceActionEvent";
  constructor(public model: CarAction_Dto) {
    super();
    this.type += UpdateCarInServiceActionEvent.identifier;
  }
}

export class DeleteCarInServiceActionEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeleteCarInServiceActionEvent";
  constructor(public id: string) {
    super();
    this.type += DeleteCarInServiceActionEvent.identifier;
  }
}

export class GetCarInServiceActionListEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetCarInServiceActionListEvent";
  constructor(public id: string) {
    super();
    this.type += GetCarInServiceActionListEvent.identifier;
  }
}

export class GetCarForClientEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetCarForClientEvent";
  constructor(public id: string) {
    super();
    this.type += GetCarForClientEvent.identifier;
  }
}

export class GetClientForCarEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetClientForCarEvent";
  constructor(public id: string) {
    super();
    this.type += GetClientForCarEvent.identifier;
  }
}

export class GetCarInServiceActionListUnauthorizedEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetCarInServiceActionListUnauthorizedEvent";
  constructor(public code: string, public carServiceEntryId: string) {
    super();
    this.type += GetCarInServiceActionListUnauthorizedEvent.identifier;
  }
}


export class GetCarServiceEntryActionsPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetCarServiceEntryActionsPagedResultEvent";
  constructor(public parameters: ActiveDataTableParameters, public withReset: boolean = false) {
    super();
    this.type += GetCarServiceEntryActionsPagedResultEvent.identifier;
  }
}

//////////////////////////////

export class UpdateCarServiceAddWorkmanshipEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "UpdateCarServiceAddWorkmanshipEvent";
  constructor(public model: Workmanship_Dto, public id: string) {
    super();
    this.type += UpdateCarServiceAddWorkmanshipEvent.identifier;
  }
}

export class UpdateCarServiceUpdateWorkmanshipEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "UpdateCarServiceUpdateWorkmanshipEvent";
  constructor(public model: Workmanship_Dto, public id: string) {
    super();
    this.type += UpdateCarServiceUpdateWorkmanshipEvent.identifier;
  }
}

export class DeleteCarServiceWorkmanshipEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeleteCarServiceWorkmanshipEvent";
  constructor(public carServiceId, public workmanshipId: string) {
    super();
    this.type += DeleteCarServiceWorkmanshipEvent.identifier;
  }
}


export class GetCarServiceEntryStatisticsEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetCarServiceEntryStatisticsEvent";
  constructor(public callback: Function) {
    super();
    this.type += GetCarServiceEntryStatisticsEvent.identifier;
  }
}

export class GetCarServiceEntryUsersEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetCarServiceEntryUsersEvent";
  constructor() {
    super();
    this.type += GetCarServiceEntryUsersEvent.identifier;
  }
}

export class DownloadCarServiceEntryFinalInspectionEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DownloadCarServiceEntryFinalInspectionEvent";
  constructor(public id: string, public callback: Function) {
    super();
    this.type += DownloadCarServiceEntryFinalInspectionEvent.identifier;
  }
}
