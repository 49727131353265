import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'payment-success',
    templateUrl: './payment-successful.component.html',
    styleUrls: ['./payment-successful.component.scss']
})
export class PaymentSuccessfulComponent implements OnInit {
    private mode: string;
    public title:string = '';
    public subTitle:string = '';

    constructor(public modalService: BsModalService, private route: ActivatedRoute) { }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.mode = params['mode'];
            if(this.mode == 'payment'){
                this.title = 'Plata s-a realizat cu succes!';
                this.subTitle = 'In cateva momente tranzactia Dumneavoastra va fi sincronizata in contul ServiFy.';
            }
            else if(this.mode == 'setup'){
                this.title = 'Informatiile cardului au fost validate cu succes!';
                this.subTitle = 'Aceast card va fi folosit ca si metoda de plata pentru abonamentul Dumneavoastra.';
            }
        });

        for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
            this.modalService.hide(i);
        }
    }
}
