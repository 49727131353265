import {BaseFormDI} from '../../../core/shared/base/base.di';
import {OfferWorkEntry_Dto} from '../../../core/app-dto/offer.dto';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {min} from '../../../core/shared/form/custom-form.validator';
import {YesNoComponent, YesNoComponentInput} from '../../../core/shared/components/yes-no/yes-no.component';
import {round} from 'lodash';
import {StaticDataSelectorInput} from '../../../core/shared/components/static-data-selector/static-data-selector.component';

export class AddOfferCarWorkDi extends BaseFormDI<OfferWorkEntry_Dto> {
    public totalPriceVATInput: YesNoComponentInput;
    public priceCurrenciesDataInput: StaticDataSelectorInput;
    public priceVatsDataInput: StaticDataSelectorInput;

    constructor(data: OfferWorkEntry_Dto = null) {
        super((data != null) ? data : new OfferWorkEntry_Dto());
        this.model.totalPriceHasVAT = true;
        this.initForm();
        this.totalPriceVATInput = new YesNoComponentInput(this.model.totalPriceHasVAT, !this.model.totalPriceHasVAT, 'Pretul include TVA?', this.form, 'totalPriceVAT', false);
        this.priceCurrenciesDataInput = {
            form: this.form,
            type: 'currencies',
            formControlName: 'currency',
            isRequired: true,
            label: 'Moneda',
            isReadOnly: true
        };
        this.priceVatsDataInput = {
            form: this.form,
            type: 'supplyVats',
            formControlName: 'priceVatValue',
            isRequired: true,
            label: 'Cota TVA',
            isReadOnly: false
        };

    }

    getModel(): OfferWorkEntry_Dto {
        const formValue = this.form.getRawValue();
        this.model.duration = formValue.duration;
        this.model.totalPrice = formValue.totalPrice;
        this.model.totalPriceHasVAT = this.form.get(this.totalPriceVATInput.formGroupName).get(YesNoComponent.YES_CONTROL_NAME).value || false;
        this.model.currency = formValue.currency.name;
        this.model.priceVatValue = formValue.priceVatValue;

        if (this.model.totalPriceHasVAT) {
            this.model.totalPriceWithVAT = this.model.totalPrice;
            this.model.totalPrice = round(this.model.totalPriceWithVAT * 100 / (100 + this.form.value.priceVatValue.id), 2);
        } else {
            this.model.totalPriceWithVAT = round(this.model.totalPrice +
                (this.model.totalPrice * formValue.priceVatValue.id / 100), 2);
        }

        return this.model;
    }

    initForm() {
        let totalPrice: number = this.model.totalPrice;
        if (this.model.totalPriceHasVAT) {
            totalPrice = this.model.totalPriceWithVAT;
        }

        this.form = new FormGroup({
            duration: new FormControl(this.model.duration, Validators.compose([Validators.required, min(0.5)])),
            totalPrice: new FormControl({value: totalPrice, disabled: false},
                Validators.compose([Validators.required, min(0)])),
        });
    }
}
