import {Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, Input} from '@angular/core';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { BaseFormComponent, BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import { CarServiceCarWorksSandbox } from './car-service-car-works.sandbox';
import { CarServiceCarWorksDi } from './car-service-car-works.di';
import { OrganizationClientSearchComponent } from 'src/app/organization-client/components/organization-client-search/organization-client-search.component';
import { CarService_Dto, ClientCarParts_Dto } from '../../../core/app-dto/car-service.dto';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { CarWork_Dto } from '../../../core/app-dto/car-work.dto';
import {Car_Dto} from "../../../core/app-dto/car.dto";
import {makeInputPositive} from '../../../core/shared/helpers/common.helpers';

@Component({
  selector: 'app-car-service-car-works',
  templateUrl: './car-service-car-works.component.html',
  styleUrls: ['./car-service-car-works.component.scss']
})
export class CarServiceCarWorksComponent extends BaseFormModelComponent<CarService_Dto> implements OnInit, OnDestroy {

  public di: CarServiceCarWorksDi;
  @Output()
  modelSaved = new EventEmitter<CarService_Dto>();
  @Output()
  eventSelectedOutput = new EventEmitter<string>();

  @Input("car") car:Car_Dto;

  @ViewChild(OrganizationClientSearchComponent, { static: false }) organizationClientSearchComponent: OrganizationClientSearchComponent;

  constructor(public appState: Store<store.State>,
              public sandbox: CarServiceCarWorksSandbox) {
    super(sandbox);
  }

  public diInitialize(data: CarService_Dto) {
    this.di = new CarServiceCarWorksDi(data);
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();
    if (this.di.isValid()) {
      if (this.modelSaved != null) {
        this.modelSaved.emit(this.di.getModel());
      }
    }
  }

  isValid() {
    return this.di.isValid();
  }

  carWorksSelected(carWorks: Array<CarWork_Dto>) {
    this.di.model.carServiceEntryData.clientCarWorksRequests = carWorks;
    this.modelSaved.emit(this.di.getModel());
  }

  recommendedCarWorksSelected(carWorks: Array<CarWork_Dto>) {
    this.di.model.carServiceEntryData.serviceCarWorksRecomandations = carWorks;
    this.modelSaved.emit(this.di.getModel());
  }

  clientCarPartsSaved(data: Array<ClientCarParts_Dto>) {
    if (ObjectValidators.isValidArray(data))
      this.di.model.clientCarParts = data;
  }

  makeValuePositive(event: any) {
    makeInputPositive(event);
  }
}
