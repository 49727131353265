import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseListWebComponent} from "../../../core/shared/base/base.component";
import {GetServicePlatformStatusesActionProxy} from "../../../service-platform-status-list/components/service-platform-status-list/get-service-platform-statuses.action-proxy";
import {ServicePlatformStatusListSandbox} from "../../../service-platform-status-list/components/service-platform-status-list/service-platform-status-list.sandbox";
import {NgxSpinnerService} from "ngx-spinner";
import {
  Do_GenericSuccessEvent,
  EventWithStopingScreenLoader,
  ResetSection
} from "../../../core/app-store/events/base.events";
import {GetMechanicsWithStatusActionProxy} from "./get-mechanics-with-status.action-proxy";
import {MechanicListWithStatusSandbox} from "./mechanic-list-with-status.sandbox";
import {
  AddCarInServiceEvent,
  EditCarServiceCarEvent,
  EditCarServiceClientEvent
} from "../../../core/app-store/events/car-service.events";
import {ActionsSubject} from "@ngrx/store";

@Component({
  selector: 'app-mechanic-list-with-status',
  templateUrl: './mechanic-list-with-status.component.html',
  styleUrls: ['./mechanic-list-with-status.component.scss']
})
export class MechanicListWithStatusComponent extends BaseListWebComponent implements OnInit, AfterViewInit {
  public getDataAP: GetMechanicsWithStatusActionProxy;
  public columns: any = [];

  constructor(public sandbox: MechanicListWithStatusSandbox,private actionsSubj: ActionsSubject,
              private spinner: NgxSpinnerService) {
    super(sandbox, ResetSection.Cars);
    this.getDataAP = new GetMechanicsWithStatusActionProxy(this, sandbox.appState, this.spinner);
    this.columns = [
      { data: 'mechanic', name : 'Mecanic', width: '25%'},
      { data: 'status', name : 'Status', width: '15%'},
      { data: 'masina', name: 'Masina', width: '30%'},
      { data: 'lucrare', name: 'Lucrare', width: '30%'}
    ];


    this.sandbox.addSubscription(actionsSubj.subscribe(data => {
      if (data.type === Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + AddCarInServiceEvent.identifier ||
        data.type === Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + EditCarServiceClientEvent.identifier ||
        data.type === Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + EditCarServiceCarEvent.identifier) {
        this.getDataAP.execute();
      }
    }));
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.initialize();
    this.getDataAP.execute();
    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      scrollY: '300px',
      scrollCollapse: true,
      ajax: () => {
      },
      columns: this.columns,
      order: [[1, 'asc']],
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
  }

}
