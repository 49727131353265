import { PagedResult } from '../../shared/datatable/datatable.helpers';
import { Action } from '@ngrx/store';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from '../events/base.events';
import { ResetStateActionProxy } from '../../shared/base/base.action-proxy';
import * as moment from 'moment';
import {Audit_Dto} from "../../app-dto/audit.dto";
import {GetAuditPagedResultEvent} from "../events/audit.events";

export interface AuditState {
  lastRefreshTime: number;
  auditPagedResult: PagedResult<Audit_Dto>;
}

export const INITIAL_STATE: AuditState = Object.assign({}, {
  lastRefreshTime: 0,
  auditPagedResult: new PagedResult<Audit_Dto>([]),
});


export function reducer(state = INITIAL_STATE, action: Action): AuditState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {
    // Get Car


    case Do_ResetStateForSection.identifier + ResetSection.Audit: {
      return Object.assign({}, state, { auditPagedResult: new PagedResult<Audit_Dto>([]) });
    }

    case GetAuditPagedResultEvent.identifier: {
      if ((action as GetAuditPagedResultEvent).withReset) {
        state.auditPagedResult = new PagedResult<Audit_Dto>([]);
        return Object.assign({}, state);
      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetAuditPagedResultEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<PagedResult<Audit_Dto>>).data;
      state.auditPagedResult = new PagedResult<Audit_Dto>(data);
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetAuditPagedResultEvent.identifier: {
      state.auditPagedResult = new PagedResult<Audit_Dto>([]);
      return Object.assign({}, state);
    }

    default: {
      return state;
    }
  }
}

export const getTimestampCallback = (state: AuditState) => state.lastRefreshTime;
export const getAuditPagedResultCallback = (state: AuditState) => state.auditPagedResult;
