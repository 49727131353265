import { PagedResult } from '../../shared/datatable/datatable.helpers';
import { Action } from '@ngrx/store';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from '../events/base.events';
import { ResetStateActionProxy } from '../../shared/base/base.action-proxy';
import {
  ActivateUserEvent,
  DeactivateUserEvent,
  DeleteUserEvent, EditUserEvent,
  GetUserEvent,
  GetUsersPagedResultEvent,
  RegisterUserEvent
} from '../events/user.events';
import { User_Dto } from '../../app-dto/core.dto';
import * as moment from 'moment';
import {Mechanic_Dto} from "../../app-dto/mechanic.dto";
import {ObjectValidators} from "../../shared/object.validators";

export interface UserState {
  lastRefreshTime: number;
  user: User_Dto;
  usersPagedResult: PagedResult<User_Dto>;
}

export const INITIAL_STATE: UserState = Object.assign({}, {
  lastRefreshTime: 0,
  user: null,
  usersPagedResult: new PagedResult<User_Dto>([]),
});


export function reducer(state = INITIAL_STATE, action: Action): UserState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {
    // Get User

    case Do_ResetStateForSection.identifier + ResetSection.UserDetails: {
      return Object.assign({}, state, { user: null });
    }

    case Do_GenericSuccessEvent.identifier + GetUserEvent.identifier: {
      const userData = new User_Dto((action as Do_GenericSuccessEvent<User_Dto>).data);
      return Object.assign({}, state, { user: userData });
    }

    case Do_GenericErrorEvent.identifier + GetUserEvent.identifier: {
      return Object.assign({}, state, { user: null });
    }

    case ResetStateActionProxy.name + GetUserEvent.identifier: {
      return Object.assign({}, state, { user: null });
    }

    // Get User



    // Get Users

    case Do_ResetStateForSection.identifier + ResetSection.Users: {
      return Object.assign({}, state, { usersPagedResult: new PagedResult<User_Dto>([]) });
    }

    case GetUsersPagedResultEvent.identifier: {
      if ((action as GetUsersPagedResultEvent).withReset) {
        state.usersPagedResult = new PagedResult<User_Dto>([]);
        return Object.assign({}, state);
      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetUsersPagedResultEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<PagedResult<User_Dto>>).data;
      state.usersPagedResult = new PagedResult<User_Dto>(data);
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetUsersPagedResultEvent.identifier: {
      state.usersPagedResult = new PagedResult<User_Dto>([]);
      return Object.assign({}, state);
    }

    // Get Users



    case Do_GenericSuccessEvent.identifier + RegisterUserEvent.identifier: {
      return Object.assign({}, state, { lastRefreshTime: moment().unix(), user: null });
    }
    case Do_GenericSuccessEvent.identifier + ActivateUserEvent.identifier:
    case Do_GenericSuccessEvent.identifier + DeactivateUserEvent.identifier:
    case Do_GenericSuccessEvent.identifier + EditUserEvent.identifier: {
      const data = new User_Dto((action as Do_GenericSuccessEvent<User_Dto>).data);
      if(ObjectValidators.isValidObject(state.user) && state.user.id == data.id) {
        state.user = data;
      }
      state.lastRefreshTime =  moment().unix();
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + DeleteUserEvent.identifier: {
      state.lastRefreshTime = moment().unix();
      return Object.assign({}, state);
    }

    default: {
      return state;
    }
  }
}

export const getTimestampCallback = (state: UserState) => state.lastRefreshTime;
export const getUserCallback = (state: UserState) => state.user;
export const getUsersPagedResultCallback = (state: UserState) => state.usersPagedResult;
