import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';

@Component({
    selector: 'payment-failed',
    templateUrl: './payment-failed.component.html',
    styleUrls: ['./payment-failed.component.scss']
})
export class PaymentFailedComponent implements OnInit {
    constructor(public modalService: BsModalService) { }

    ngOnInit() {
        for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
            this.modalService.hide(i);
        }
    }
}
