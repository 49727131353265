import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import {
  DataTableParameters,
  InvoiceDataTableParameters,
  PagedResult
} from 'src/app/core/shared/datatable/datatable.helpers';
import { Invoice_Dto } from 'src/app/core/app-dto/invoice.dto';
import { InvoiceOverdueListDI } from './invoice-overdue-list.di';

@Injectable()
export class InvoiceOverdueListSandbox extends BaseSandbox {
    public invoices$ = this.appState$.pipe(select(store.getInvoicesListing));
    public timestamp$ = this.appState$.pipe(select(store.getInvoiceTimestamp));

    public totalNumberOfResults: number = null;
    public callback: any;
    public dataTableParameters: InvoiceDataTableParameters;
    public resetTableCallback: Function;
    public lastRefreshTime: number = 0;

    public totalPages: number = 0;
    public items: Array<InvoiceOverdueListDI> = [];
    private draw: number = 1;

    constructor(
        protected appState$: Store<store.State>
    ) {
        super(appState$);
    }

    public clearSandboxState() {
        this.items = [];
        this.draw = 1;
        this.lastRefreshTime = 0;
    }

    public initialize(): any {
        this.clearSandboxState();
        this.subscriptions.push(this.invoices$.subscribe((data: PagedResult<Invoice_Dto>) => {
            if (data != null && data.items != null) {
                this.items = data.items.map(r => new InvoiceOverdueListDI(r));
                this.totalNumberOfResults = data.totalItems;
                this.totalPages = data.totalPages;
                if (this.callback != null) {
                    this.callback({
                        draw: this.draw++,
                        recordsTotal: data.totalItems,
                        recordsFiltered: data.totalItems,
                        data: []
                    });

                }
            } else {
                this.items = [];
                this.draw = 1;
            }
        }));

        this.subscriptions.push(this.timestamp$.subscribe((result: number) => {
            if (result > this.lastRefreshTime) {
                this.lastRefreshTime = result;
                this.resetTable();
            }
        }));
    }

    public setResetTableCallback(callback: Function) {
        this.resetTableCallback = callback;
    }

    public resetTable() {
        if (this.resetTableCallback != null) this.resetTableCallback();
    }
}
