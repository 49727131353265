import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class ConfigService {

    private config: Object;
    private env: Object;

    constructor(private http: HttpClient) {
    }

    /**
     * Loads the environment config file first. Reads the environment variable from the file
     * and based on that loads the appropriate configuration file - development or production
     */
    load() {

        return new Promise((resolve, reject) => {
            let headers = new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'DataType': 'application/json'
            });

            this.http.get('/config/env.json', {headers: headers})
            // .map(res => res.json())
                .subscribe((env_data: any) => {
                    this.env = env_data;

                    this.http.get('/config/' + env_data.env + '.json')
                    //.map(res => res.json())
                        .subscribe((data) => {
                            this.config = data;
                            resolve(true);
                        }, (error) => {
                            reject("Failed loading the configurations: " + error);
                        });
                });

        });
    }

    /**
     * Returns environment variable based on given key
     *
     * @param key
     */
    getEnv(key: any) {
        return this.env[key];
    }

    /**
     * Returns configuration value based on given key
     *
     * @param key
     */
    get(key: any) {
        return this.config[key];
    }

    getAPIBaseUrl() {
        if (this.config != null) {

            return this.config["api"].APIBaseUrl;
        }
    }

    getAPIClientId() {
        if (this.config != null) {
            return this.config["api"].apiClientId;
        }
    }

    getAPIClientSecret() {
        if (this.config != null) {
            return this.config["api"].apiClientSecret;
        }
    }

    getAPISocketUrl() {
        if (this.config != null) {
            let url = this.config["api"].APISocketUrl;
            if (url.indexOf('http') < 0) {
                url = window.location.origin + url;
            }
            console.log('web socket URL is: ' + url);
            return url;
        }
    }


    getDefaultPageSize(): number {
        if (this.config != null) {
            return this.config["api"].defaultPageSize;
        }
    }

    getDefaultSupplyVatName() {
        if (this.config != null) {

            return this.config["default"].supplyVatName;
        }
    }

    getDefaultSupplyVatValue() {
        if (this.config != null) {

            return this.config["default"].supplyVatValue;
        }
    }

    getDesktopPluginInstallerUrl(): string {
        if (this.config != null) {
            const baseUrl = this.config["blobStorageBaseUrl"];
            return `${baseUrl}/public/ServiFy.Desktop.Installer.msi`;
        }
        return '';
    }
}
