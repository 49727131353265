import {
    UIGeneratedEvent,
    EventWithScreenLoading, SeamlessEvent
} from './base.events';
import {
    ActiveDataTableParameters,
    InvoicePrintJobsDataTableParameters
} from '../../shared/datatable/datatable.helpers';
import {CashRegister_Dto} from "../../app-dto/cash-register.dto";
import {InvoicePrintJob_Dto} from "../../app-dto/invoice-print-job.dto";
import {AppNotification} from "../../app-dto/core.dto";

export class Do_GetAll_CashRegisters_AsPaginated_Event extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "Do_GetAll_CashRegisters_AsPaginated_Event";

    constructor(public parameters: ActiveDataTableParameters, public withReset: boolean = false) {
        super();
        this.type += Do_GetAll_CashRegisters_AsPaginated_Event.identifier;
    }
}

export class Do_GetActive_CashRegisters_Event extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = "Do_GetActive_CashRegisters_Event";

    constructor() {
        super();
        this.type += Do_GetActive_CashRegisters_Event.identifier;
    }
}

export class Do_Register_CashRegister_Event extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "Do_Register_CashRegister_Event";

    constructor(public model: CashRegister_Dto) {
        super();
        this.type += Do_Register_CashRegister_Event.identifier;
    }
}

export class Do_GetOne_CashRegister_Event extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "Do_GetOne_CashRegister_Event";

    constructor(public id: string) {
        super();
        this.type += Do_GetOne_CashRegister_Event.identifier;
    }
}

export class Do_Edit_CashRegister_Event extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "Do_Edit_CashRegister_Event";

    constructor(public model: CashRegister_Dto) {
        super();
        this.type += Do_Edit_CashRegister_Event.identifier;
    }
}

export class Do_Delete_CashRegister_Event extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "Do_Delete_CashRegister_Event";

    constructor(public id: string, public callback: Function = null) {
        super();
        this.type += Do_Delete_CashRegister_Event.identifier;
    }
}

export class Do_ResetActivation_For_CashRegister_Event extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "Do_ResetActivation_For_CashRegister_Event";

    constructor(public id: string, public callback: Function = null) {
        super();
        this.type += Do_ResetActivation_For_CashRegister_Event.identifier;
    }
}

export class Do_Deactivate_CashRegister_Event extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "Do_Deactivate_CashRegister_Event";

    constructor(public id: string, public callback: Function = null) {
        super();
        this.type += Do_Deactivate_CashRegister_Event.identifier;
    }
}

export class Do_GetAll_PrintJobs_AsPaginated_Event extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "Do_GetAll_PrintJobs_AsPaginated_Event";

    constructor(public parameters: InvoicePrintJobsDataTableParameters, public withReset: boolean = false) {
        super();
        this.type += Do_GetAll_PrintJobs_AsPaginated_Event.identifier;
    }
}

export class Do_Register_PrintJob_Event extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "Do_Register_PrintJob_Event";

    constructor(public model: InvoicePrintJob_Dto) {
        super();
        this.type += Do_Register_PrintJob_Event.identifier;
    }
}

export class Do_Delete_PrintJob_Event extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "Do_Delete_PrintJob_Event";

    constructor(public id: string, public callback: Function = null) {
        super();
        this.type += Do_Delete_PrintJob_Event.identifier;
    }
}

export class Do_Delete_All_PrintJob_Event extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "Do_Delete_All_PrintJob_Event";

    constructor(public cashRegisterId: string, public callback: Function = null) {
        super();
        this.type += Do_Delete_All_PrintJob_Event.identifier;
    }
}

export class CashRegisterUpdateEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = "CashRegisterUpdateEvent";

    constructor(public data: AppNotification) {
        super();
        this.type += CashRegisterUpdateEvent.identifier;
    }
}

export class PrintJobStatusUpdateEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = "PrintJobStatusUpdateEvent";

    constructor(public data: AppNotification) {
        super();
        this.type += PrintJobStatusUpdateEvent.identifier;
    }
}


