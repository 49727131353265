import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { OrganizationClient_Dto } from '../../../core/app-dto/organization-client.dto';

@Injectable()
export class OrganizationClientDetailsSandbox extends BaseSandbox {
    public getOrganizationClient$ = this.appState$.pipe(select(store.getOrganizationClient));

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    clearSandboxState() {
    }

    initialize(organizationClient: (data: OrganizationClient_Dto) => {}) {
        this.clearSandboxState();

        this.subscriptions.push(this.getOrganizationClient$.subscribe((data: OrganizationClient_Dto) => {
            if (ObjectValidators.isValidObject(data)) {
                organizationClient(data);
            }
        }));
    }
}
