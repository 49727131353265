import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import {StripeConfigResponse} from "../dto/stripe.dto";
import {GetStripeConfigEvent} from "../events/stripe.events";

export class GetStripeConfigActionProxy extends BaseActionProxy {
  constructor(component: IWebComponent, appState: Store<store.State>) {
    super(component, appState);
  }

  canExecute(): boolean {
    return true;
  }

  execute(callback:(data:StripeConfigResponse) => void): any {
    if (this.canExecute()) {
      this.appState.dispatch(new GetStripeConfigEvent(callback));
    }
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }
}

