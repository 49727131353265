import {ApiResourceConstants} from "../utils/api-resource.constants";
import {Injectable} from "@angular/core";
import {BaseApiService} from "./base-api.service";
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "../../core/app-config.service";
import {Observable} from "rxjs";
import {ApiErrorDto} from "../utils/api-error.dto";
import {ErrorHelpers} from "../../shared/error.helpers";
import {
    InvoicePrintJobsDataTableParameters,
    PagedResult
} from "../../shared/datatable/datatable.helpers";
import {ObjectValidators} from "../../shared/object.validators";
import {InvoicePrintJob_Dto} from "../../app-dto/invoice-print-job.dto";

class InvoicePrintJobApiRoutes {
    public static route_for_GET_PRINT_JOBS() {
        return `${ApiResourceConstants.INVOICE_PRINT_JOB}/web-client/all/paginated`;
    }

    public static route_for_REGISTER_PRINT_JOB() {
        return `${ApiResourceConstants.INVOICE_PRINT_JOB}`;
    }

    public static route_for_DELETE_ONE_PRINT_JOB(id: string) {
        return `${ApiResourceConstants.INVOICE_PRINT_JOB}/${id}`;
    }

    public static route_for_DELETE_ALL_PRINT_JOBS() {
        return `${ApiResourceConstants.INVOICE_PRINT_JOB}/all`;
    }
}

@Injectable()
export class InvoicePrintApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    pj_registerPrintJob(data: InvoicePrintJob_Dto): Observable<InvoicePrintJob_Dto> {
        const url = this.configService.getAPIBaseUrl() + InvoicePrintJobApiRoutes.route_for_REGISTER_PRINT_JOB();

        return this.http.post(url, data)
            .map((res: any) => {
                return new InvoicePrintJob_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    pj_getFilteredInvoicePrintJobsAsPaginated(data: InvoicePrintJobsDataTableParameters): Observable<PagedResult<InvoicePrintJob_Dto>> {
        const url = this.configService.getAPIBaseUrl() + InvoicePrintJobApiRoutes.route_for_GET_PRINT_JOBS() + '?' + data.toUrlParameters();

        return this.http.get(url)
            .map((res: PagedResult<InvoicePrintJob_Dto>) => {
                return new PagedResult<InvoicePrintJob_Dto>(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    pj_deletePrintJob(id: string): Observable<boolean> {
        const url = this.configService.getAPIBaseUrl() + InvoicePrintJobApiRoutes.route_for_DELETE_ONE_PRINT_JOB(id);

        return this.http.delete(url)
            .map((res: any) => {
                return true;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    pj_deleteAllPrintJobs(cashRegisterId: string = ""): Observable<boolean> {
        let url = this.configService.getAPIBaseUrl() + InvoicePrintJobApiRoutes.route_for_DELETE_ALL_PRINT_JOBS();
        if (ObjectValidators.isValidString(cashRegisterId)) url = url + `?cashRegisterId=${cashRegisterId}`;

        return this.http.delete(url)
            .map((res: any) => {
                return true;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
}
