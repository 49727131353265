import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { ActiveDataTableParameters, DataTableParametersOrderEntity } from '../datatable/datatable.helpers';
import { BaseListWebComponentWithSearchAndActiveBehavior } from '../base/base.component';
import { ResetSection } from '../../app-store/events/base.events';
import { CarPartsListSandbox } from '../../../car-part/components/car-parts-list/car-parts-list.sandbox';
import { ConfigService } from '../../core/app-config.service';
import { Router } from '@angular/router';
import { GetCarPartsActionProxy } from '../../../car-part/components/car-parts-list/get-car-parts.action-proxy';
import { ActivateCarPartActionProxy } from './activate-car-part.action-proxy';
import { DeactivateCarPartActionProxy } from './deactivate-car-part.action-proxy';
import { DeleteCarPartActionProxy } from './delete-car-part.action-proxy';
import { SaveCarPartComponent } from 'src/app/car-part/components/save-car-part/save-car-part.component';
import { BsModalService } from 'ngx-bootstrap';
import { CarPart_Dto } from '../../app-dto/car-part.dto';
import { SetBreadcrumbActionProxy } from '../../breadcrumb/set-breadcrumb.action-proxy';
import { IdName_Dto } from '../../app-dto/misc.dto';
import { ConfirmationDialogService } from '../../core/dialog/confirmation-dialog.service';
import { AppUserBusinessRuleProvider } from '../../core/business-rule-provider/app-user.business-rule.provider';
import { ActivateCarPartStockAlertActionProxy } from './activate-car-part-stock-alert.action-proxy';
import { DeactivateCarPartStockAlertActionProxy } from './deactivate-car-part-stock-alert.action-proxy';

@Component({
  selector: 'app-car-parts-table',
  templateUrl: './car-parts-table.component.html',
  styleUrls: ['./car-parts-table.component.scss']
})
export class CarPartsTableComponent extends BaseListWebComponentWithSearchAndActiveBehavior implements OnInit, AfterViewInit {
  public getCarPartsAP: GetCarPartsActionProxy;
  public activateCarPartAP: ActivateCarPartActionProxy;
  public deactivateCarPartAP: DeactivateCarPartActionProxy;
  public deleteCarPartAP: DeleteCarPartActionProxy;
  public columns: any = [];
  public sortColumns: Array<IdName_Dto> = [];
  public currentPage = 1;
  public activateCarPartStockAlertAP: ActivateCarPartStockAlertActionProxy;
  public deactivateCarPartStockAlertAP: DeactivateCarPartStockAlertActionProxy;

  @Input('supplierFilter') supplierFilter = '';

  constructor(public sandbox: CarPartsListSandbox,
              actionsSubj: ActionsSubject,
              private configService: ConfigService,
              public router: Router, public confirmationDialogService: ConfirmationDialogService,
              private modalService: BsModalService,
              public rulesProvider: AppUserBusinessRuleProvider) {
    super(sandbox, actionsSubj, ResetSection.CarParts);
    this.getCarPartsAP = new GetCarPartsActionProxy(this, sandbox.appState);
    this.activateCarPartAP = new ActivateCarPartActionProxy(this, sandbox.appState);
    this.deactivateCarPartAP = new DeactivateCarPartActionProxy(this, sandbox.appState);
    this.deleteCarPartAP = new DeleteCarPartActionProxy(this, sandbox.appState);
    this.columns = [
      { data: 'name', name: 'Denumire piesa', width: '20%' },
      { data: 'code', name: 'Cod', width: '10%' },
      { data: 'supplier', name: 'Furnizor', width: '20%' },
      { data: 'acquisitionPriceWithVat', name: 'Pret de achizitie cu TVA', width: '20%' },
      { data: 'sellingPriceWithVat', name: 'Pret de vanzare cu TVA', width: '20%' },
      { data: 'quantity', name: 'Cantitate', width: '10%' },
      { data: 'action', width: '50px'},
      { data: 'createdOn', width: '0'}
    ];
    this.sortColumns = this.columns.filter(c => c.name).map(c => ({id: c.data, name: c.name} as IdName_Dto));
  }

  ngAfterViewInit() {
    this.setBreadcrumbAP.execute([{text: 'Acasa', url: '/dashboard'}, {text: 'Masini'}]);
    this.activateCarPartStockAlertAP = new ActivateCarPartStockAlertActionProxy(this, this.sandbox.appState);
    this.deactivateCarPartStockAlertAP = new DeactivateCarPartStockAlertActionProxy(this, this.sandbox.appState);
  }

  ngOnInit() {
    this.initialize();

    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      pageLength: this.configService.getDefaultPageSize(),
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      ajax: (dataTablesParameters: ActiveDataTableParameters, callback) => {
        dataTablesParameters.active = this.isActive;
        dataTablesParameters.search.value = this.searchValue;
        this.doGetAll(new ActiveDataTableParameters(dataTablesParameters), callback, false);
      },
      columns: this.columns,
      order: [[6, 'desc']],
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
  }

  public doGetAll(dataTablesParameters: ActiveDataTableParameters, callback: any, withReset: boolean) {
    this.sandbox.callback = callback;
    this.sandbox.dataTableParameters = dataTablesParameters;
    this.getCarPartsAP.execute(dataTablesParameters, withReset, this.supplierFilter || '');
  }

  activateCarPart(carPartId: string) {
    this.activateCarPartAP.execute(carPartId);
  }

  deactivateCarPart(carPartId: string) {
    this.deactivateCarPartAP.execute(carPartId);
  }

  activateStockAlertCarPart(carPartId: string) {
    this.activateCarPartStockAlertAP.execute(carPartId);
  }

  deactivateStockAlertCarPart(carPartId: string) {
    this.deactivateCarPartStockAlertAP.execute(carPartId);
  }

  deleteCarPart(carPart: CarPart_Dto) {
    this.confirmationDialogService.confirm(
      'Stergere piesa',
      "Urmeaza sa stergeti piesa <b>" + carPart.code + "</b>. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        this.deleteCarPartAP.execute(carPart.id);
      }
    });

  }

  updateCarPart(carPart: CarPart_Dto) {
    const modalRef = this.modalService.show(SaveCarPartComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = carPart;
  }

  onPageChange(currentPage: number) {
    this.sandbox.dataTableParameters.pageNumber = currentPage;
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
  }

  onSortChange($event) {
    if ($event) {
      const columnId = this.columns.map((e) => e.data).indexOf($event.id);
      const orderEntity = new DataTableParametersOrderEntity({column: columnId, dir: 'asc'});
      this.sandbox.dataTableParameters.order = [orderEntity];
    }
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
  }
}

