export class AppConstants {
    public static SUPER_ADMIN_ROLE_NAME: string = "superadmin";
    public static COUNTRY_ADMIN_ROLE_NAME: string = "countryadmin";

    public static CASH_REGISTER_PARAMETER_CASH = "CashIndex";
    public static CASH_REGISTER_PARAMETER_CASH_DISPLAY_AS = "Numerar";

    public static CASH_REGISTER_PARAMETER_CARD = "CardIndex";
    public static CASH_REGISTER_PARAMETER_CARD_DISPLAY_AS = "Card";
}
