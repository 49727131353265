import {ObjectValidators} from "../shared/object.validators";

export class Base_Dto {
  public id: string;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) this.id = data.id;
  }
}

export class IdName_Dto extends Base_Dto {
  public name: string;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) this.name = data.name;
  }
}

export class IdNameKey_Dto extends IdName_Dto{
  public key:string;
  constructor(data:any){
    super(data);
    if (ObjectValidators.isValidObject(data)) this.key = data.key;
  }
}

export class BaseKeyNameEntity {
  public id: string;
  public key: string;
  public name: string;
  public description: string;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      this.id = data.id;
      this.key = data.key;
      this.name = ObjectValidators.isValidString(data.name) ? data.name : data.displayAs;
      this.description = data.description;
    }
  }
}

export class BaseLocationEntity extends BaseKeyNameEntity {
  public code: string;

  constructor(data?: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.code = data.code;
    }
    else {
      this.id = '';
      this.key = '';
      this.name = '';
      this.description = '';
    }
  }

  public isEmpty() {
    return (ObjectValidators.isEmptyString(this.code)) &&
      (ObjectValidators.isEmptyString(this.id)) &&
      (ObjectValidators.isEmptyString(this.key));
  }
}


export interface IWithIdName {
  id: string;
  name: string;
}

export class NameValueDto {
  constructor(public name: string, public value: string) {
  }
}

export class IdNameDto implements IWithIdName {
  public id: string = '';
  public name: string = '';

  constructor(data?: any) {
    if (data != null && data !== undefined) {
      this.id = data.id;
      this.name = data.name;
    }
  }
}

export class IdNameDescriptionDto extends IdNameDto {
  public description: string;

  constructor(data?: any) {
    super(data);
    if (data != null && data !== undefined) {
      this.description = data.description;
    }
  }
}

export class BusinessRule {
    public value: boolean;
    public reasonForValue: string = '';

    constructor(data?: any) {
        if (ObjectValidators.isValidObject(data)) {
            this.value = data.value;
            this.reasonForValue = data.reasonForValue;
        }
    }

    public static getTrueRuleValue(): BusinessRule {
        return new BusinessRule({value: true, reasonForValue: ''});
    }

    public static getFalseRuleValue(): BusinessRule {
        return new BusinessRule({value: false, reasonForValue: ''});
    }
}

export class BaseCRUDRules {
  public canCreate: BusinessRule;
  public canUpdate: BusinessRule;
  public canView: BusinessRule;
  public canDelete: BusinessRule;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      this.canCreate = new BusinessRule(data.canCreate);
      this.canUpdate = new BusinessRule(data.canUpdate);
      this.canView = new BusinessRule(data.canView);
      this.canDelete = new BusinessRule(data.canDelete);
    }
    else {
      this.canCreate = new BusinessRule();
      this.canUpdate = new BusinessRule();
      this.canView = new BusinessRule();
      this.canDelete = new BusinessRule();
    }
  }
}

export class ApiResult_Dto {
  public messages: string[];
  public status: ResponseStatus;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      this.messages = data.messages;
      this.status = data.rstatus;
    }
  }
}

export class ApiResponse_Dto {
  public result: ApiResult_Dto;
  public data: any;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      this.result = new ApiResult_Dto(data.result);
      this.data = data;
    }
  }
}

export class IdNameNumber_Dto{
  public id: number = 0;
  public name: string = '';

  constructor(data?: any) {
    if (data != null && data !== undefined) {
      this.id = data.id;
      this.name = data.name;
    }
  }
}

export enum ResponseStatus
{
  SUCCESS = 1,
  ERROR = 0
}

