import {AbstractControl, FormControl, FormGroup} from "@angular/forms";
import {BaseListSandboxWithActiveAndSearchBehavior, BaseSandbox} from "./base.sandbox";
import {HostListener, Input, OnDestroy, QueryList, ViewChildren} from "@angular/core";
import {Observable, Subject, Subscription} from "rxjs";
import {BaseDI, BaseFormDI, BaseSelectWrapperDI} from "./base.di";
import {ResetStateActionProxy} from "./base.action-proxy";
import {ObjectValidators} from "../object.validators";
import {DataTableDirective} from 'angular-datatables';
import {ResetSection, SeamlessEvent} from '../../app-store/events/base.events';
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {SearchEvent} from "../../app-store/events";
import {ActionsSubject} from "@ngrx/store";
import {isValidObject} from "../helpers/common.helpers";
import {SetBreadcrumbActionProxy} from "../../breadcrumb/set-breadcrumb.action-proxy";

export interface IWebComponent {
  initialize();

  destroy();
}

export interface IWebFormComponent extends IWebComponent {
  di: BaseFormDI<any>;

  markFormAsDirtyAndTouched();

  markFormAsNotDirty();
}

export abstract class BaseEmbeddedWebComponent implements IWebComponent, OnDestroy {
  protected constructor() {
  }

  initialize() {
  }

  destroy() {
  }

  ngOnDestroy() {
    this.destroy();
  }
}

export abstract class BaseWebComponent implements IWebComponent, OnDestroy {
  sandbox: BaseSandbox;
  resetStateAP: ResetStateActionProxy;
  di: BaseDI<any>;
  dataLoaded: boolean = false;

  protected constructor(sandbox: BaseSandbox, resetSection?: ResetSection) {
    this.sandbox = sandbox;
    if (resetSection != null && sandbox != null) {
      this.resetStateAP = new ResetStateActionProxy(sandbox.appState, resetSection);
      this.resetStateAP.execute();
    }
  }

  initialize(param1?: any, param2?: any, param3?: any, param4?: any, param5?: any) {
    if (this.sandbox != null) {
      this.sandbox.initialize(param1, param2, param3, param4, param5);
    }
    // if (this.resetStateAP != null) {
    //   this.resetStateAP.execute();
    // }
  }

  destroy() {
    if (this.sandbox != null)
      this.sandbox.unsubscribe();
  }

  ngOnDestroy() {
    this.destroy();
  }
  hasOptionItems(parentElem){
    return $(parentElem).children('.dropdown').children('.dropdown-menu').children('.bg-white').children('.dropdown-item').length == 0;
  }
}
export abstract class BaseWebComponentWithBreadcrumb extends BaseWebComponent {

  public setBreadcrumbAP: SetBreadcrumbActionProxy;
  protected constructor(sandbox: BaseSandbox, resetSection?: ResetSection) {
    super(sandbox, resetSection);
    this.setBreadcrumbAP = new SetBreadcrumbActionProxy(this, sandbox.appState);
  }
}

export abstract class BaseListWebComponent extends BaseWebComponentWithBreadcrumb {

  public dtApiInstance: DataTables.Api;
  public dtOptions = null;
  public datatable: DataTableDirective;


  protected constructor(sandbox: BaseSandbox, resetSection?: ResetSection) {
    super(sandbox, resetSection);
  }
}

export abstract class BaseListWebWithSelectBehaviorComponent<T> extends BaseListWebComponent {

  public hasItemsSelected:boolean=false;
  public items : Array<BaseSelectWrapperDI<T>> = [];
  protected constructor(sandbox: BaseSandbox, resetSection?: ResetSection) {
    super(sandbox, resetSection);
  }

  toggleSelectedItem(item:BaseSelectWrapperDI<T>){
    item.toggleSelection();
    if(item.isSelected) this.hasItemsSelected = true;
    else{
      const firstSelectedItem  =this.items.find(f=>f.isSelected==true);
      if(firstSelectedItem==null) this.hasItemsSelected=false;
      else this.hasItemsSelected = true;
    }
  }

}

export abstract class BaseListWebComponentWithSearchAndActiveBehavior extends BaseWebComponentWithBreadcrumb {
  public isActive: boolean = true;
  public searchValue: string = '';
  private searchSubscription = new Subscription();

  @ViewChildren(DataTableDirective) set datatableValue(datatable: QueryList<DataTableDirective>) {
    if (datatable != null && datatable.first != null) {
      datatable.first.dtInstance.then((dtInstance: DataTables.Api) => {
        this.dtApiInstance = dtInstance;
        (<BaseListSandboxWithActiveAndSearchBehavior>this.sandbox).setResetTableCallback(() => {
          dtInstance.ajax.reload();
        });
      });
    }
  }

  modelChanged: Subject<string> = new Subject<string>();

  public dtApiInstance: DataTables.Api;
  public dtOptions = null;
  public datatable: DataTableDirective;

  protected constructor(sandbox: BaseListSandboxWithActiveAndSearchBehavior,
                        private actionsSubj: ActionsSubject,
                        resetSection?: ResetSection) {
    super(sandbox, resetSection);
    this.modelChanged.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(searchQuery => {
        this.searchValue = searchQuery;
        this.dtApiInstance.search(this.searchValue).draw();
      });

    this.searchSubscription = actionsSubj.subscribe(data => {
      if (data.type === SeamlessEvent.identifier + SearchEvent.identifier) {
        this.searchValue = (data as SearchEvent).value;
        this.dtApiInstance.search(this.searchValue).draw();
      }
    });
  }

  search(data: any) {
    if (ObjectValidators.isValidString(data))
      this.modelChanged.next(data);
    if (data === "")
      this.modelChanged.next(null);
    return false;
  }

  includeDeactivatedChecked(value: boolean) {
    this.isActive = value;
    if (this.dtApiInstance != null)
      this.dtApiInstance.ajax.reload();
  }

}

export class BaseSelectorComponent extends BaseWebComponent {
  public selectedValueId: string = undefined;

  @Input('group') group: FormGroup;
  @Input('formMode') formMode: boolean;
  @Input("placeholder") placeholder: string;
  @Input("isDisabled") isDisabled: boolean;

  protected constructor(sandbox: BaseSandbox) {
    super(sandbox);
  }

  resetValue() {
    this.selectedValueId = undefined;
  }
}

export class BaseMultipleSelectorComponent extends BaseWebComponent {
  public selectedValueIds: string[] = [];

  @Input('group') group: FormGroup;
  @Input('formMode') formMode: boolean;
  @Input("placeholder") placeholder: string;
  @Input("isDisabled") isDisabled: boolean;

  protected constructor(sandbox: BaseSandbox) {
    super(sandbox);
  }

  resetValue() {
    this.selectedValueIds = [];
  }

}

export abstract class BaseFormComponent extends BaseWebComponent implements IWebFormComponent {
  form: FormGroup;
  di: BaseFormDI<any>;
  shouldListentToBeforeUnload: boolean = true;
  formSubmitted: boolean = false;
  dataLoaded: boolean = false;

  constructor(sandbox: BaseSandbox, resetSection?: ResetSection) {
    super(sandbox, resetSection);
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.shouldListentToBeforeUnload) {
      if (ObjectValidators.isValidObject(this.form)) return true;
      return this.form.dirty == false || (this.form.dirty == true && this.form.untouched == true);
    } else return true;
  }

  doCancel() {
    if (ObjectValidators.isValidObject(this.form)) {
      this.form.markAsUntouched();
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    if (ObjectValidators.isValidObject(formGroup)) {
      (<any>Object).values(formGroup.controls).forEach(control => {
        control.markAsTouched();

        if (control.controls) {
          this.markFormGroupTouched(control);
        }
      });
    }
  }

  markFormAsDirtyAndTouched() {
    if (ObjectValidators.isValidObject(this.form)) {
      this.markFormGroupTouched(this.form);
      this.form.markAsTouched();
      this.form.markAsDirty();
    } else {
      if (ObjectValidators.isValidObject(this.di) &&
        ObjectValidators.isValidObject(this.di.form)) {
        this.markFormGroupTouched(this.di.form);
        this.di.form.markAsTouched();
        this.di.form.markAsDirty();
      }
    }
  }

  markOutsideFormAsDirtyAndTouched(form: FormGroup) {
    if (ObjectValidators.isValidObject(form)) {
      this.markFormGroupTouched(form);
      form.markAsTouched();
      form.markAsDirty();
    }
  }

  markFormAsNotDirty() {
    if (ObjectValidators.isValidObject(this.form)) {
      this.form.markAsPristine();
      this.form.markAsUntouched();
    }
  }

  markOutsideFormAsNotDirty(form: FormGroup) {
    if (ObjectValidators.isValidObject(form)) {
      form.markAsPristine();
      form.markAsUntouched();
    }
  }

  isInvalidControl(control: AbstractControl): boolean {
    if (isValidObject(control) && control.disabled==false && !control.valid && this.formSubmitted)
      return true;
    return false;
  }
}

export abstract class BaseFormModelComponent<T> extends BaseFormComponent {

  @Input("dataInput") set dataInput(data: T) {
    this.diInitialize(data);
  }

  public abstract diInitialize(data: T);
}
