import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebFormComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import {
    UpdateCarServiceUpdateClientPartEvent
} from '../../../core/app-store/events/car-service.events';
import { ClientCarParts_Dto } from '../../../core/app-dto/car-service.dto';

export class EditCarServiceClientCarPartActionProxy extends BaseActionProxy {
    constructor(component: IWebFormComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(model: ClientCarParts_Dto, carServiceId: string): any {
        if (this.canExecute()) {
            this.appState.dispatch(new UpdateCarServiceUpdateClientPartEvent(model, carServiceId));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

