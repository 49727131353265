import {
    Base_Dto,
    BaseCRUDRules,
    BaseKeyNameEntity,
    BusinessRule,
    IdName_Dto,
    IdNameKey_Dto,
    IdNameNumber_Dto
} from './misc.dto';
import {ObjectValidators} from '../shared/object.validators';
import * as moment from 'moment';
import {isValidArrayAndHasElements, isValidObject} from '../shared/helpers/common.helpers';
import {OrganizationClient_Dto} from './organization-client.dto';
import {Car_Dto, CarRules} from './car.dto';
import {FileAttachment} from './core.dto';
import {ClientCarParts_Dto} from './car-service.dto';
import {CarPart_Dto} from './car-part.dto';

export class OfferRules extends BaseCRUDRules {
    public canChangeInitialImages: BusinessRule = new BusinessRule();
    public canManageDocuments: BusinessRule = new BusinessRule();

    public canManageClientCarParts: BusinessRule = new BusinessRule();
    public canManageServiceClientParts: BusinessRule = new BusinessRule();
    public canManageWorkEntries: BusinessRule = new BusinessRule();

    public canFinalize: BusinessRule = new BusinessRule();
    public canSendOffer: BusinessRule = new BusinessRule();
    public canAcceptOffer: BusinessRule = new BusinessRule();
    public canDeclineOffer: BusinessRule = new BusinessRule();

    public canViewFinancialInfo: BusinessRule = new BusinessRule();

    constructor(data: any) {
        super(data);
        if (isValidObject(data)) {
            this.canDeclineOffer = new BusinessRule(data.canDeclineOffer);
            this.canAcceptOffer = new BusinessRule(data.canAcceptOffer);
            this.canChangeInitialImages = new BusinessRule(data.canChangeInitialImages);
            this.canManageDocuments = new BusinessRule(data.canManageDocuments);
            this.canManageClientCarParts = new BusinessRule(data.canManageClientCarParts);
            this.canManageServiceClientParts = new BusinessRule(data.canManageServiceClientParts);
            this.canManageWorkEntries = new BusinessRule(data.canManageWorkEntries);
            this.canFinalize = new BusinessRule(data.canFinalize);
            this.canSendOffer = new BusinessRule(data.canSendOffer);
            this.canViewFinancialInfo = new BusinessRule(data.canViewFinancialInfo);
        }
    }
}

export class OfferWorkEntry_Dto {
    public description: string;
    public duration: number;
    public pricePerUnit: number;
    public totalPrice: number;
    public totalPriceHasVAT: boolean;
    public totalPriceWithVAT: number;
    public currency: string;
    public priceVatValue: IdNameNumber_Dto;

    constructor(data?: any) {
        if (isValidObject(data)) {
            this.description = data.description;
            this.duration = data.duration;
            this.pricePerUnit = data.pricePerUnit;
            this.totalPrice = data.totalPrice;
            this.totalPriceHasVAT = data.totalPriceHasVAT;
            this.totalPriceWithVAT = data.totalPriceWithVAT;
            this.currency = data.currency;
            if (isValidObject(data.priceVatValue)) {
                this.priceVatValue = new IdNameNumber_Dto(data.priceVatValue);
            }
        }
        else {
            this.description = '';
            this.duration = 0;
            this.pricePerUnit = 0;
            this.totalPrice = 0;
            this.totalPriceHasVAT = true;
            this.totalPriceWithVAT = 0;
            this.currency = '';
        }
    }
}

export class OffertedClientContact_Dto {
    public name: string;
    public email: string;
    public phoneNumber: string;

    constructor(data) {
        if (isValidObject(data)) {
            this.name = data.name;
            this.email = data.email;
            this.phoneNumber = data.phoneNumber;
        }
    }
}
export class SendOfferPayload_Dto{
    public sendToEmail:boolean = false;
    public sendToSMS:boolean = false;
    public contacts: Array<OffertedClientContact_Dto> = [];
}

export class OfferAcceptedTrace_Dto {
    public name: string;
    public email: string;
    public phoneNumber: string;

    constructor(data) {
        if (isValidObject(data)) {
            this.name = data.name;
            this.email = data.email;
            this.phoneNumber = data.phoneNumber;
        }
    }
}

export class OfferDeclinedTrace_Dto extends OfferAcceptedTrace_Dto {
    public reason: string;

    constructor(data: any) {
        super(data);
        if (isValidObject(data)) {
            this.reason = data.reason;
        }
    }
}

export class OfferRecipient_Dto extends OfferAcceptedTrace_Dto {
    public sentToEmail: boolean;
    public sentToSMS: boolean;
    public sentOn: Date;

    constructor(data: any) {
        super(data);
        if (isValidObject(data)) {
            this.sentToEmail = data.sentToEmail;
            this.sentToSMS = data.sentToSMS;
            if (isValidObject(this.sentOn)) {
                this.sentOn = moment(data.sentOn).toDate();
            }
        }
    }
}

export class OfferEntryData_Dto {
    public discount: number;

    public deadline: string;
    public expiresOn: Date;

    public estimatedDeadlineType: IdNameKey_Dto;
    public deadlineNumber: number;

    public priceEstimate: number;
    public priceEstimateNumber: number;
    public priceEstimateCurrency: string;

    public carWorksRequests: Array<OfferWorkEntry_Dto> = [];

    constructor(data?: any) {
        if (isValidObject(data)) {
            this.discount = data.discount;
            this.deadline = data.deadline;

            if(ObjectValidators.isValidObject(data.expiresOn))
                this.expiresOn = moment(data.expiresOn).toDate();

            this.deadlineNumber = data.deadlineNumber;
            this.estimatedDeadlineType = data.estimatedDeadlineType;

            this.priceEstimate = data.priceEstimate;
            this.priceEstimateNumber = data.priceEstimateNumber;
            this.priceEstimateCurrency = data.priceEstimateCurrency;

            if (isValidArrayAndHasElements(data.carWorksRequests)) {
                this.carWorksRequests = data.carWorksRequests.map(r => new OfferWorkEntry_Dto(r));
            }
        }
    }
}

export class OfferCar_Dto extends Base_Dto {
    public registrationNumber: string;
    public bodySeries: string;
    public make: string;
    public model: string;
    public year: number;
    public power: number;
    public fuel: IdName_Dto[];
    public cilindricalCapacity: number;
    public rules: CarRules;
    public details: string;
    public itpExpirationDate: Date;
    public insuranceExpirationDate: Date;
    public createdOn: Date;
    public updatedOn: Date;

    constructor(data?: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.registrationNumber = data.registrationNumber;
            this.bodySeries = data.bodySeries;
            this.make = data.make;
            this.model = data.model;
            this.year = data.year;
            this.power = data.power;
            this.fuel = data.fuel;
            this.cilindricalCapacity = data.cilindricalCapacity;
            this.rules = new CarRules(data.rules);
            this.details = data.details;
            if (ObjectValidators.isValidObject(data.itpExpirationDate))
                this.itpExpirationDate = moment.utc(data.itpExpirationDate).toDate();
            if (ObjectValidators.isValidObject(data.insuranceExpirationDate))
                this.insuranceExpirationDate = moment.utc(data.insuranceExpirationDate).toDate();
            if (ObjectValidators.isValidObject(data.createdOn))
                this.createdOn = moment.utc(data.createdOn).toDate();
            if (ObjectValidators.isValidObject(data.updatedOn))
                this.updatedOn = moment.utc(data.updatedOn).toDate();
        }
    }
}
export class Offer_Dto extends Base_Dto {
    public title: string;
    public rules: OfferRules;
    public createdOn: Date;
    public updatedOn: Date;
    public organizationId: string;
    public type: IdNameKey_Dto;
    public isIndividual: boolean = false;
    public client: OrganizationClient_Dto;
    public car: OfferCar_Dto;
    public offerEntryData: OfferEntryData_Dto;
    public initialFiles: Array<FileAttachment> = [];
    public clientCarParts: Array<ClientCarParts_Dto> = [];
    public serviceCarParts: Array<CarPart_Dto> = [];
    public status: IdNameKey_Dto;
    public reasonForStatus: string;
    public internalNumber: number;
    public offerNotificationSent: boolean;
    public offerSendingTime: Date;
    public sentList: Array<OfferRecipient_Dto> = [];
    public acceptedList: Array<OfferAcceptedTrace_Dto> = [];
    public declinedList: Array<OfferDeclinedTrace_Dto> = [];
    public publicUrl: string;
    public computedTotalPrice: number;
    public withDiscount: boolean = false;

    constructor(data?: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.title = data.title;
            this.organizationId = data.organizationId;
            this.type = new BaseKeyNameEntity(data.type);

            if(ObjectValidators.isValidObject(this.type) && ObjectValidators.isValidString(this.type.name))
                this.withDiscount = this.type.name.toLowerCase().indexOf('discount') > -1;

            this.isIndividual = data.isIndividual;

            if (isValidObject(data.client)) {
                this.client = new OrganizationClient_Dto(data.client);
            }
            if (isValidObject(data.car)) {
                this.car = new OfferCar_Dto(data.car);
            }
            if (isValidObject(data.offerEntryData)) {
                this.offerEntryData = new OfferEntryData_Dto(data.offerEntryData);
            }
            if (isValidArrayAndHasElements(data.initialFiles)) {
                this.initialFiles = data.initialFiles.map(r => new FileAttachment(r));
            }
            if (isValidArrayAndHasElements(data.clientCarParts)) {
                this.clientCarParts = data.clientCarParts.map(r => new ClientCarParts_Dto(r));
            }
            if (isValidArrayAndHasElements(data.serviceCarParts)) {
                this.serviceCarParts = data.serviceCarParts.map(r => new CarPart_Dto(r));
            }
            this.status = new BaseKeyNameEntity(data.status);
            this.reasonForStatus = data.reasonForStatus;
            this.internalNumber = data.internalNumber;
            this.offerNotificationSent = data.offerNotificationSent;
            if (isValidObject(data.offerSendingTime)) {
                this.offerSendingTime = moment(data.offerSendingTime).toDate();
            }
            if (isValidArrayAndHasElements(data.sentList)) {
                this.sentList = data.sentList.map(r => new OfferRecipient_Dto(r));
            }
            if (isValidArrayAndHasElements(data.acceptedList)) {
                this.acceptedList = data.acceptedList.map(r => new OfferAcceptedTrace_Dto(r));
            }
            if (isValidArrayAndHasElements(data.declinedList)) {
                this.declinedList = data.declinedList.map(r => new OfferDeclinedTrace_Dto(r));
            }
            this.publicUrl = data.publicUrl;

            this.computedTotalPrice = data.computedTotalPrice;
            this.rules = new OfferRules(data.rules);
            if (ObjectValidators.isValidObject(data.createdOn)) {
                this.createdOn = moment.utc(data.createdOn).toDate();
            }
            if (ObjectValidators.isValidObject(data.updatedOn)) {
                this.updatedOn = moment.utc(data.updatedOn).toDate();
            }
        }
        else{
            this.car = new Car_Dto();
            this.offerEntryData = new OfferEntryData_Dto();
        }
    }
}
