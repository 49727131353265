import {Component, Input} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {BsModalRef} from 'ngx-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  public confimAction: () => {};
  constructor(private activeModal: BsModalRef,
              public activatedRoute: ActivatedRoute) {
  }

  @Input('dataInput') set dataInput(data: any) {
    this.confimAction = data;
  }

  public decline() {
    this.activeModal.hide();
  }

  public accept() {
    this.confimAction();
    this.activeModal.hide();
  }
}
