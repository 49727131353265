import { PagedResult } from '../../shared/datatable/datatable.helpers';
import { Action } from '@ngrx/store';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from '../events/base.events';
import { ResetStateActionProxy } from '../../shared/base/base.action-proxy';
import {
  DeactivateOrganizationCostEvent,
  DeleteOrganizationCostEvent,
  EditOrganizationCostEvent,
  GetOrganizationCostsPagedResultEvent,
  RegisterOrganizationCostEvent,
  GetOrganizationCostEvent,
  DeleteOrganizationCostMonthlyEvent
} from '../events/organization-cost.events';
import * as moment from 'moment';
import {ObjectValidators} from "../../shared/object.validators";
import { OrganizationCost } from '../../app-dto/organization.dto';

export interface OrganizationCostState {
  lastRefreshTime: number;
  organizationCost: OrganizationCost;
  organizationCostsPagedResult: PagedResult<OrganizationCost>;
}

export const INITIAL_STATE: OrganizationCostState = Object.assign({}, {
  lastRefreshTime: 0,
  organizationCost: null,
  organizationCostsPagedResult: new PagedResult<OrganizationCost>([])
});


export function reducer(state = INITIAL_STATE, action: Action): OrganizationCostState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {

    // Get OrganizationCost

    case Do_ResetStateForSection.identifier + ResetSection.OrganizationCost: {
      return Object.assign({}, state, { organizationCost: null });
    }

    case Do_GenericSuccessEvent.identifier + GetOrganizationCostEvent.identifier: {
      const organizationCostData = new OrganizationCost((action as Do_GenericSuccessEvent<OrganizationCost>).data);
      return Object.assign({}, state, { organizationCost: organizationCostData });
    }

    case Do_GenericErrorEvent.identifier + GetOrganizationCostEvent.identifier: {
      return Object.assign({}, state, { organizationCost: null });
    }

    case ResetStateActionProxy.name + GetOrganizationCostEvent.identifier: {
      return Object.assign({}, state, { organizationCost: null });
    }

    // Get OrganizationCost

    // Get OrganizationCost

    case Do_ResetStateForSection.identifier + ResetSection.OrganizationCosts: {
      return Object.assign({}, state, { organizationCostsPagedResult: new PagedResult<OrganizationCost>([]) });
    }

    case GetOrganizationCostsPagedResultEvent.identifier: {
      if ((action as GetOrganizationCostsPagedResultEvent).withReset) {
        state.organizationCostsPagedResult = new PagedResult<OrganizationCost>([]);
        return Object.assign({}, state);
      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetOrganizationCostsPagedResultEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<PagedResult<OrganizationCost>>).data;
      state.organizationCostsPagedResult = new PagedResult<OrganizationCost>(data);
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetOrganizationCostsPagedResultEvent.identifier: {
      state.organizationCostsPagedResult = new PagedResult<OrganizationCost>([]);
      return Object.assign({}, state);
    }

    // Get OrganizationCost

    case Do_GenericSuccessEvent.identifier + RegisterOrganizationCostEvent.identifier: {
      return Object.assign({}, state, { lastRefreshTime: moment().unix()});
    }

    case Do_GenericSuccessEvent.identifier + DeactivateOrganizationCostEvent.identifier:
    case Do_GenericSuccessEvent.identifier + EditOrganizationCostEvent.identifier: {
      const data = new OrganizationCost((action as Do_GenericSuccessEvent<OrganizationCost>).data);
      if(ObjectValidators.isValidObject(state.organizationCost) && state.organizationCost.id == data.id) {
        state.organizationCost = data;
      }
      state.lastRefreshTime =  moment().unix();
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + DeleteOrganizationCostEvent.identifier:
    case Do_GenericSuccessEvent.identifier + DeleteOrganizationCostMonthlyEvent.identifier: {
      state.lastRefreshTime =  moment().unix();
      return Object.assign({}, state);
    }

    default: {
      return state;
    }
  }
}

export const getTimestampCallback = (state: OrganizationCostState) => state.lastRefreshTime;
export const getOrganizationCostCallback = (state: OrganizationCostState) => state.organizationCost;
export const getOrganizationCostsPagedResultCallback = (state: OrganizationCostState) => state.organizationCostsPagedResult;
