import { BaseDI } from 'src/app/core/shared/base/base.di';
import {ServicePlatform_Dto} from "../../../core/app-dto/service-platform.dto";
import {CarAction_Dto} from "../../../core/app-dto/car-service.dto";
import {isValidArrayAndHasElements} from "../../../core/shared/helpers/common.helpers";

export class ServicePlatformStatusListItemDi extends BaseDI<ServicePlatform_Dto> {
    constructor(data: ServicePlatform_Dto) {
        super(new ServicePlatform_Dto(data));
    }

    getModel(): ServicePlatform_Dto {
        return this.model;
    }
}
