import { Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import { map, switchMap } from 'rxjs/operators';
import { Do_GenericSuccessEvent, Do_GenericErrorEvent, EventWithScreenLoading, SeamlessEvent } from 'src/app/core/app-store/events/base.events';
import { PagedResult } from 'src/app/core/shared/datatable/datatable.helpers';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { Car_Dto } from 'src/app/core/app-dto/car.dto';
import {
    ActivateCarEvent,
    DeactivateCarEvent,
    DeleteCarEvent,
    EditCarEvent,
    GetCarEvent,
    GetCarsPagedResultEvent,
    RegisterCarEvent,
    SearchCarEvent
} from '../../core/app-store/events/car.events';
import { CarApiService } from '../../core/api-integration/services/car-api.service';
import {isValidObject} from "../../core/shared/helpers/common.helpers";
import {GetCarForClientEvent, GetClientForCarEvent} from '../../core/app-store/events/car-service.events';
import {OrganizationClient_Dto} from '../../core/app-dto/organization-client.dto';


@Injectable()
export class CarUIEventProcessor {

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private carApiService: CarApiService
    ) {
    }

    @Effect()
    GetCarsPagedResultEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetCarsPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetCarsPagedResultEvent.identifier),
            map((action: GetCarsPagedResultEvent) => action),
            switchMap((action: GetCarsPagedResultEvent) => {
                return new Observable((observer) => {
                    this.carApiService.u_getFilteredCarsPaginated(action.parameters)
                        .subscribe((res: PagedResult<Car_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<Car_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    getCar$: Observable<any> = this.actions$
        .pipe(
            ofType(GetCarEvent.identifier, EventWithScreenLoading.identifier + GetCarEvent.identifier),
            map((action: GetCarEvent) => action),
            switchMap((action: GetCarEvent) => {
                return new Observable((observer) => {
                    this.carApiService.u_getCar(action.id)
                        .subscribe((res: Car_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Car_Dto>(res, action));
                            }
                            if(action.callback) action.callback(res);
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doRegisterCar$: Observable<any> = this.actions$
        .pipe(
            ofType(RegisterCarEvent.identifier, EventWithScreenLoading.identifier + RegisterCarEvent.identifier),
            map((action: RegisterCarEvent) => action),
            switchMap((action: RegisterCarEvent) => {
                return new Observable((observer) => {
                    this.carApiService.u_registerCar(action.model)
                        .subscribe((res: Car_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Car_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doEditCar$: Observable<any> = this.actions$
        .pipe(
            ofType(EditCarEvent.identifier, EventWithScreenLoading.identifier + EditCarEvent.identifier),
            map((action: EditCarEvent) => action),
            switchMap((action: EditCarEvent) => {
                return new Observable((observer) => {
                    this.carApiService.u_editCar(action.model)
                        .subscribe((res: Car_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Car_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doDeleteCar$: Observable<any> = this.actions$
        .pipe(
            ofType(DeleteCarEvent.identifier, EventWithScreenLoading.identifier + DeleteCarEvent.identifier),
            map((action: DeleteCarEvent) => action),
            switchMap((action: DeleteCarEvent) => {
                return new Observable((observer) => {
                    this.carApiService.u_deleteCar(action.id)
                        .subscribe((res: Car_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Car_Dto>(res, action));
                            if(isValidObject(action.callback)) action.callback();
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doActivateCar$: Observable<any> = this.actions$
        .pipe(
            ofType(ActivateCarEvent.identifier, EventWithScreenLoading.identifier + ActivateCarEvent.identifier),
            map((action: ActivateCarEvent) => action),
            switchMap((action: ActivateCarEvent) => {
                return new Observable((observer) => {
                    this.carApiService.u_activateCar(action.id)
                        .subscribe((res: Car_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Car_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doDeactivateCar$: Observable<any> = this.actions$
        .pipe(
            ofType(DeactivateCarEvent.identifier, EventWithScreenLoading.identifier + DeactivateCarEvent.identifier),
            map((action: DeactivateCarEvent) => action),
            switchMap((action: DeactivateCarEvent) => {
                return new Observable((observer) => {
                    this.carApiService.u_deactivateCar(action.id)
                        .subscribe((res: Car_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Car_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doSearchCars$: Observable<any> = this.actions$
        .pipe(
            ofType(SearchCarEvent.identifier, SeamlessEvent.identifier + SearchCarEvent.identifier),
            map((action: SearchCarEvent) => action),
            switchMap((action: SearchCarEvent) => {
                return new Observable((observer) => {
                    this.carApiService.u_searchCars(action.search)
                        .subscribe((res: Array<Car_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Array<Car_Dto>>(res, action));
                            }
                            action.callBack();
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            action.callBack();
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doGetCarForClientEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetCarForClientEvent.identifier, SeamlessEvent.identifier + GetCarForClientEvent.identifier),
            map((action: GetCarForClientEvent) => action),
            switchMap((action: GetCarForClientEvent) => {
                return new Observable((observer) => {
                    this.carApiService.u_getCarForClient(action.id)
                        .subscribe((res: Car_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Car_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doGetClientForCarEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetClientForCarEvent.identifier, SeamlessEvent.identifier + GetClientForCarEvent.identifier),
            map((action: GetClientForCarEvent) => action),
            switchMap((action: GetClientForCarEvent) => {
                return new Observable((observer) => {
                    this.carApiService.u_getClientForCar(action.id)
                        .subscribe((res: OrganizationClient_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<OrganizationClient_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
}
