import { PagedResult } from '../../shared/datatable/datatable.helpers';
import { Action } from '@ngrx/store';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from '../events/base.events';
import { ResetStateActionProxy } from '../../shared/base/base.action-proxy';
import { CarWork_Dto } from '../../app-dto/car-work.dto';
import { GetCarWorkEvent, SearchCarWorkEvent } from '../events/car-works.events';

export interface CarWorkState {
  carWork: CarWork_Dto;
  carWorkSearchList: Array<CarWork_Dto>;
}

export const INITIAL_STATE: CarWorkState = Object.assign({}, {
  carWork: null,
  carWorkSearchList: []
});


export function reducer(state = INITIAL_STATE, action: Action): CarWorkState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {
    // Get Car Work

    case Do_ResetStateForSection.identifier + ResetSection.CarWork: {
      return Object.assign({}, state, { carWork: null });
    }

    case Do_GenericSuccessEvent.identifier + GetCarWorkEvent.identifier: {
      const carWorkData = new CarWork_Dto((action as Do_GenericSuccessEvent<CarWork_Dto>).data);
      return Object.assign({}, state, { carWork: carWorkData });
    }

    case Do_GenericErrorEvent.identifier + GetCarWorkEvent.identifier: {
      return Object.assign({}, state, { carWork: null });
    }

    case ResetStateActionProxy.name + GetCarWorkEvent.identifier: {
      return Object.assign({}, state, { carWork: null });
    }

    // Get Car Work


    // Search Car Work

    case Do_GenericSuccessEvent.identifier + SearchCarWorkEvent.identifier: {
      const carWorkSearchData = (action as Do_GenericSuccessEvent<Array<CarWork_Dto>>).data.map(r => new CarWork_Dto(r));
      return Object.assign({}, state, { carWorkSearchList: carWorkSearchData });
    }

    case Do_GenericErrorEvent.identifier + SearchCarWorkEvent.identifier: {
      return Object.assign({}, state, { carWorkSearchList: [] });
    }

    // Search Car Work

    default: {
      return state;
    }
  }
}

export const getCarWorkCallback = (state: CarWorkState) => state.carWork;
export const getCarWorkSearchListCallback = (state: CarWorkState) => state.carWorkSearchList;
