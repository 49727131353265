import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { Supply_Dto } from 'src/app/core/app-dto/supply.dto';

@Injectable()
export class SupplyDetailsSandbox extends BaseSandbox {
    public getSupply$ = this.appState$.pipe(select(store.getSupply));

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    clearSandboxState() {
    }

    initialize(supply: (data: Supply_Dto) => {}) {
        this.clearSandboxState();

        this.subscriptions.push(this.getSupply$.subscribe((data: Supply_Dto) => {
            if (ObjectValidators.isValidObject(data)) {
                supply(data);
            }
        }));
    }
}
