import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebFormComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { RegisterDepartmentEvent } from '../../../core/app-store/events/department.events';
import { Department_Dto } from 'src/app/core/app-dto/department.dto';

export class RegisterDepartmentActionProxy extends BaseActionProxy {
    constructor(component: IWebFormComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(model: Department_Dto): any {
        if (this.canExecute()) {
            this.appState.dispatch(new RegisterDepartmentEvent(model));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

