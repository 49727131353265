import {Injectable} from '@angular/core';
import {BaseSandbox} from '../../../core/shared/base/base.sandbox';
import {select, Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {ActiveDataTableParameters, DataTableParameters, PagedResult} from '../../../core/shared/datatable/datatable.helpers';
import {MechanicsListItemDi} from '../../../mechanic/components/mechanics-list/mechanics-list-item.di';
import {Mechanic_Dto} from '../../../core/app-dto/mechanic.dto';
import {getOfferTimestamp} from '../../../core/app-store';
import {ListOfferEntryItemDi} from './list-offer-entry-item.di';
import {Offer_Dto} from '../../../core/app-dto/offer.dto';
import {OfferDataTableParameters} from '../../../core/app-store/events/offer.events';

@Injectable()
export class ListOfferEntriesSandbox extends BaseSandbox {
    public offers$ = this.appState$.pipe(select(store.getOffersPagedResult));
    public timestamp$ = this.appState$.pipe(select(store.getOfferTimestamp));

    public totalNumberOfResults: number = null;
    public callback: any;
    public dataTableParameters: OfferDataTableParameters;
    public resetTableCallback: () => void;
    public lastRefreshTime: number = 0;

    public totalPages: number = 0;
    public items: Array<ListOfferEntryItemDi> = [];
    private draw: number = 1;

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
        this.items = [];
        this.draw = 1;
        this.lastRefreshTime = 0;
    }

    public initialize(): void {
        this.clearSandboxState();
        this.subscriptions.push(this.offers$.subscribe((data: PagedResult<Offer_Dto>) => {
            if (data != null && data.items != null) {
                this.items = data.items.map(r => new ListOfferEntryItemDi(r));

                this.totalNumberOfResults = data.totalItems;
                this.totalPages = data.totalPages;
                if (this.callback != null) {
                    this.callback({
                        draw: this.draw++,
                        recordsTotal: data.totalItems,
                        recordsFiltered: data.totalItems,
                        data: []
                    });
                }
            } else {
                this.items = [];
                this.draw = 1;
            }
        }));

        this.subscriptions.push(this.timestamp$.subscribe((result: number) => {
            if (result > this.lastRefreshTime) {
                this.lastRefreshTime = result;
                this.resetTable();
            }
        }));
    }

    public setResetTableCallback(callback: () => void) {
        this.resetTableCallback = callback;
    }

    public resetTable() {
        if (this.resetTableCallback != null) {
            this.resetTableCallback();
        }
    }
}
