import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SupplyItem_Dto } from '../../../core/app-dto/supply.dto';
import { YesNoComponent, YesNoComponentInput } from '../../../core/shared/components/yes-no/yes-no.component';
import {isValidString} from "../../../core/shared/helpers/common.helpers";
import {IdNameNumber_Dto} from "../../../core/app-dto/misc.dto";
import {ConfigService} from "../../../core/core/app-config.service";

export class SupplyItemFormDi extends BaseFormDI<SupplyItem_Dto> {
  public acquisitionPriceVATInput: YesNoComponentInput;

  constructor(configService:ConfigService, data: SupplyItem_Dto = new SupplyItem_Dto(null), isForFullPage: boolean = false) {
    super(new SupplyItem_Dto(data));
    this.initForm();
    if(isValidString(this.model.id)==false){
      this.model.acquisitionPriceHasVat = true; //default value;
      this.model.vatValue =new IdNameNumber_Dto({id : configService.getDefaultSupplyVatValue(), name : configService.getDefaultSupplyVatName()});
    }
    this.acquisitionPriceVATInput = new YesNoComponentInput(this.model.acquisitionPriceHasVat, !this.model.acquisitionPriceHasVat,
        'Pretul include TVA?', this.form, isForFullPage ? 'acquisitionPriceHasVat' : 'acquisitionPriceHasVatModal');
  }

  getModel(): SupplyItem_Dto {
    this.model.name = this.form.value.name;
    this.model.code = this.form.value.code;
    this.model.um = this.form.value.um;
    this.model.documentedQuantity = this.form.value.documentedQuantity;
    this.model.receivedQuantity = this.form.value.receivedQuantity;
    this.model.acquisitionPriceHasVat = this.form.get(this.acquisitionPriceVATInput.formGroupName).get(YesNoComponent.YES_CONTROL_NAME).value || false;
    if (this.model.id || this.form.value.acquisitionPriceWithVat) {
      if (this.model.acquisitionPriceHasVat) {
        this.model.acquisitionPriceWithVat = this.form.value.acquisitionPriceWithVat;
        this.model.acquisitionPriceWithoutVat = this.form.value.acquisitionPriceWithoutVat;
      } else {
        this.model.acquisitionPriceWithVat = this.form.value.acquisitionPriceWithoutVat;
        this.model.acquisitionPriceWithoutVat = this.form.value.acquisitionPriceWithVat;
      }
    } else {
      this.model.acquisitionPriceWithVat = this.form.value.acquisitionPriceWithoutVat;
      this.model.acquisitionPriceWithoutVat = this.form.value.acquisitionPriceWithoutVat;
    }
    this.model.vatValue = this.form.value.vatValue;
    this.model.totalVat = this.form.value.totalVat;

    return this.model;
  }

  initForm() {
    if(!isValidString(this.model.um)) this.model.um = 'buc';
    this.form = new FormGroup({
      name: new FormControl(this.model.name, Validators.compose([Validators.required])),
      code: new FormControl(this.model.code, Validators.compose([Validators.required])),
      um: new FormControl(this.model.um, Validators.compose([Validators.required])),
      documentedQuantity: new FormControl(this.model.documentedQuantity, Validators.compose([Validators.required])),
      receivedQuantity: new FormControl(this.model.receivedQuantity, Validators.compose([Validators.required])),
      acquisitionPriceWithoutVat: new FormControl(this.model.acquisitionPriceHasVat ? this.model.acquisitionPriceWithoutVat : this.model.acquisitionPriceWithVat, Validators.compose([Validators.required])),
      acquisitionPriceWithVat: new FormControl(this.model.acquisitionPriceHasVat ? this.model.acquisitionPriceWithVat : this.model.acquisitionPriceWithoutVat, Validators.compose([])),
      totalVat: new FormControl(this.model.totalVat, Validators.compose([]))
    });
  }
}
