import {BaseDI} from 'src/app/core/shared/base/base.di';
import {InvoiceDetails_Dto, InvoiceStatus} from 'src/app/core/app-dto/invoice.dto';
import {isValidObject} from "../../../core/shared/helpers/common.helpers";
import {StringHelpers} from "../../../core/shared/string.helpers";

export class InvoiceDI extends BaseDI<InvoiceDetails_Dto> {

  public providerComputedAddress: string;
  public clientComputedAddress: string;
  public computedStatus:string;
  public isNew:boolean = false;
  public isPaid : boolean=false;
  public isOverdue : boolean = false;
  constructor(data: InvoiceDetails_Dto) {
    super(new InvoiceDetails_Dto(data));
    if(isValidObject(data.invoice.provider.address)){
      this.providerComputedAddress = StringHelpers.computeAddress(data.invoice.provider.address);
    }
    else  this.providerComputedAddress = '-';
    if(isValidObject(data.invoice.client.address)){
      this.clientComputedAddress = StringHelpers.computeAddress(data.invoice.client.address);
    }
    else this.clientComputedAddress = '-';
    if(data.invoice.status ==InvoiceStatus.Paid) {
      this.computedStatus = "Platita";
      this.isPaid = true;


    }
    else if(data.invoice.status ==InvoiceStatus.New){
      this.computedStatus= "Neplatita";
      this.isNew = true;
    }
    else {
      this.computedStatus = "Depasita";
      this.isOverdue = true;
    }
  }

  getModel(): InvoiceDetails_Dto {
    return this.model;
  }
}
