import {PagedResult} from '../../shared/datatable/datatable.helpers';
import {Action} from '@ngrx/store';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from '../events/base.events';
import {
  AddCarInServiceActionEvent,
  AddCarInServiceEvent,
  DeleteCarInServiceActionEvent,
  DeleteCarPartFromCarService,
  DeleteCarServiceCarPartEvent,
  DeleteCarServiceEvent,
  EditCarServiceCarEvent,
  EditCarServiceCarWorksEvent,
  EditCarServiceClientEvent,
  EditCarServiceEntryDataEvent,
  EditCarServiceEntryFinalInspectionEvent,
  FinalizeCarServiceEvent,
  GetCarInServiceActionListEvent,
  GetCarInServiceActionListUnauthorizedEvent,
  GetCarServiceEntriesEvent,
  GetCarServiceEntriesPagedResultEvent,
  GetCarServiceEntryUsersEvent,
  GetCarServiceEvent,
  GetCarServiceUnatuhorizedEvent,
  UpdateCarInServiceActionEvent,
  UpdateCarServiceAddCarPartEvent,
  UpdateCarServiceAddClientPartEvent,
  UpdateCarServiceAddWorkmanshipEvent,
  UpdateCarServiceUpdateCarPartEvent,
  UpdateCarServiceUpdateClientPartEvent,
  UpdateCarServiceUpdateWorkmanshipEvent
} from '../events/car-service.events';
import {
  CarAction_Dto,
  CarService_Dto,
  CarServiceEntryActionListChanged_Dto,
  FinalInspectionUser_Dto
} from '../../app-dto/car-service.dto';
import {ResetStateActionProxy} from '../../shared/base/base.action-proxy';
import {ObjectValidators} from '../../shared/object.validators';
import * as moment from 'moment';
import {GenerateInvoiceForCarServiceEntry} from "../events/invoices.events";
import {isValidArrayAndHasElements, isValidObject} from "../../shared/helpers/common.helpers";

export interface CarServiceState {
  lastRefreshTime: number;
  carService: CarService_Dto;
  carServiceEntries: Array<CarService_Dto>;
  carServicePagedResult: PagedResult<CarService_Dto>;
  carServiceActionList: Array<CarAction_Dto>;
  carServiceUsers: Array<FinalInspectionUser_Dto>;
}

export const INITIAL_STATE: CarServiceState = Object.assign({}, {
  lastRefreshTime: 0,
  carService: null,
  carServiceEntries: [],
  carServicePagedResult: new PagedResult<CarService_Dto>([]),
  carServiceActionList: [],
  carServiceUsers: []
});


export function reducer(state = INITIAL_STATE, action: Action): CarServiceState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {

    // Get Car service entries
    case Do_ResetStateForSection.identifier + ResetSection.CarServices: {
      return Object.assign({}, state, {carServicePagedResult: new PagedResult<CarService_Dto>([])});
    }

    case GetCarServiceEntriesPagedResultEvent.identifier: {
      if ((action as GetCarServiceEntriesPagedResultEvent).withReset) {
        state.carServicePagedResult = new PagedResult<CarService_Dto>([]);
        return Object.assign({}, state);
      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetCarServiceEntriesPagedResultEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<PagedResult<CarService_Dto>>).data;
      state.carServicePagedResult = new PagedResult<CarService_Dto>(data);
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetCarServiceEntriesPagedResultEvent.identifier: {
      state.carServicePagedResult = new PagedResult<CarService_Dto>([]);
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + GetCarServiceEntriesEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<CarService_Dto[]>).data;
      state.carServiceEntries = data;
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetCarServiceEntriesEvent.identifier: {
      state.carServiceEntries = [];
      return Object.assign({}, state);
    }
    // Get Car service entries


    // Get Car service entry
    case Do_ResetStateForSection.identifier + ResetSection.CarService: {
      return Object.assign({}, state, {carService: null});
    }

    case Do_GenericSuccessEvent.identifier + FinalizeCarServiceEvent.identifier:
    case Do_GenericSuccessEvent.identifier + GetCarServiceEvent.identifier: {
      const carServiceData = (action as Do_GenericSuccessEvent<CarService_Dto>).data;
      return Object.assign({}, state, {carService: carServiceData});
    }

    case Do_GenericErrorEvent.identifier + GetCarServiceEvent.identifier: {
      return Object.assign({}, state, {carService: null});
    }

    case ResetStateActionProxy.name + GetCarServiceEvent.identifier: {
      return Object.assign({}, state, {carService: null});
    }
    // Get Car service entry

    // Get Car service entry unauth
    case Do_GenericSuccessEvent.identifier + GetCarServiceUnatuhorizedEvent.identifier: {
      const carServiceData = (action as Do_GenericSuccessEvent<CarService_Dto>).data;
      return Object.assign({}, state, {carService: carServiceData});
    }

    case Do_GenericErrorEvent.identifier + GetCarServiceUnatuhorizedEvent.identifier: {
      return Object.assign({}, state, {carService: null});
    }
    // Get Car service entry unauth

    //
    case Do_GenericSuccessEvent.identifier + DeleteCarServiceEvent.identifier:
    case Do_GenericSuccessEvent.identifier + AddCarInServiceEvent.identifier: {
      return Object.assign({}, state, {lastRefreshTime: moment().unix()});
    }
    //

    // Get Car service entry action list

    case Do_GenericSuccessEvent.identifier + GetCarInServiceActionListEvent.identifier: {
      const carActionEventsData = (action as Do_GenericSuccessEvent<Array<CarAction_Dto>>).data;
      return Object.assign({}, state, {carServiceActionList: carActionEventsData});
    }

    case Do_GenericErrorEvent.identifier + GetCarInServiceActionListEvent.identifier: {
      return Object.assign({}, state, {carServiceActionList: []});
    }

    case ResetStateActionProxy.name + GetCarInServiceActionListEvent.identifier: {
      return Object.assign({}, state, {carServiceActionList: []});
    }
    // /Get Car service entry action list

    // Get Car service entry action list unauth

    case Do_GenericSuccessEvent.identifier + GetCarInServiceActionListUnauthorizedEvent.identifier: {
      const carActionEventsData = (action as Do_GenericSuccessEvent<Array<CarAction_Dto>>).data;
      return Object.assign({}, state, {carServiceActionList: carActionEventsData});
    }

    case Do_GenericErrorEvent.identifier + GetCarInServiceActionListUnauthorizedEvent.identifier: {
      return Object.assign({}, state, {carServiceActionList: []});
    }
    // /Get Car service entry action list unauth

    case Do_GenericSuccessEvent.identifier + AddCarInServiceActionEvent.identifier: {
      const carActionEventData = (action as Do_GenericSuccessEvent<CarServiceEntryActionListChanged_Dto>).data;
      if (ObjectValidators.isValidObject(state.carService) == false) return;
      if (state.carService.id != carActionEventData.carServiceEntry.id) return;

      if (!ObjectValidators.isValidArray(state.carServiceActionList)) {
        state.carServiceActionList = [];
      }
      state.carServiceActionList.push(carActionEventData.action);
      state.carServiceActionList = state.carServiceActionList.map(r => r);
      state.carService = new CarService_Dto(carActionEventData.carServiceEntry);
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + UpdateCarInServiceActionEvent.identifier: {

      const carActionEventData = (action as Do_GenericSuccessEvent<CarServiceEntryActionListChanged_Dto>).data;

      if (ObjectValidators.isValidObject(state.carService) == false) return;
      if (state.carService.id != carActionEventData.carServiceEntry.id) return;

      if (ObjectValidators.isValidArray(state.carServiceActionList)) {
        const index = state.carServiceActionList.findIndex(r => r.id == carActionEventData.action.id);
        if (index > -1) {
          state.carServiceActionList[index] = carActionEventData.action;
        }
        state.carServiceActionList = state.carServiceActionList.map(r => r);
      }
      state.carService = new CarService_Dto(carActionEventData.carServiceEntry);
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + DeleteCarInServiceActionEvent.identifier: {
      const carActionEventData = (action as Do_GenericSuccessEvent<CarServiceEntryActionListChanged_Dto>).data;

      if (ObjectValidators.isValidObject(state.carService) == false) return;
      if (state.carService.id != carActionEventData.carServiceEntry.id) return;

      if (ObjectValidators.isValidArray(state.carServiceActionList)) {
        const index = state.carServiceActionList.findIndex(r => r.id == carActionEventData.action.id);
        if (index > -1) {
          state.carServiceActionList.splice(index, 1);
        }
        state.carServiceActionList = state.carServiceActionList.map(r => r);
      }

      state.carService = new CarService_Dto(carActionEventData.carServiceEntry);

      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + EditCarServiceClientEvent.identifier:
    case Do_GenericSuccessEvent.identifier + EditCarServiceCarWorksEvent.identifier:
    case Do_GenericSuccessEvent.identifier + EditCarServiceEntryDataEvent.identifier:
    case Do_GenericSuccessEvent.identifier + EditCarServiceEntryFinalInspectionEvent.identifier:
    case Do_GenericSuccessEvent.identifier + EditCarServiceCarEvent.identifier: {
      const carServiceData = (action as Do_GenericSuccessEvent<CarService_Dto>).data;
      return Object.assign({}, state, {carService: carServiceData});
    }

    // Get car service after update of clients parts
    case Do_GenericSuccessEvent.identifier + UpdateCarServiceAddClientPartEvent.identifier:
    case Do_GenericSuccessEvent.identifier + UpdateCarServiceUpdateClientPartEvent.identifier:
    case Do_GenericSuccessEvent.identifier + UpdateCarServiceUpdateWorkmanshipEvent.identifier:
    case Do_GenericSuccessEvent.identifier + UpdateCarServiceAddCarPartEvent.identifier:
    case Do_GenericSuccessEvent.identifier + UpdateCarServiceUpdateCarPartEvent.identifier:
    case Do_GenericSuccessEvent.identifier + GenerateInvoiceForCarServiceEntry.identifier:
    case Do_GenericSuccessEvent.identifier + UpdateCarServiceAddWorkmanshipEvent.identifier: {
      const carServiceData = new CarService_Dto((action as Do_GenericSuccessEvent<CarService_Dto>).data);
      state.carService = carServiceData;

      return Object.assign({}, state);
    }
    case Do_GenericSuccessEvent.identifier + DeleteCarServiceCarPartEvent.identifier: {
      if (isValidObject(state.carService)) {
        const deleteCarPartResponse = (action as Do_GenericSuccessEvent<DeleteCarPartFromCarService>).data;
        if (isValidObject(deleteCarPartResponse) && deleteCarPartResponse.carServiceId == state.carService.id) {
          if (isValidArrayAndHasElements(state.carService.serviceCarParts)) {
            const foundItem = state.carService.serviceCarParts.find(f => f.id === deleteCarPartResponse.carPart.id &&
              f.supplier.id === deleteCarPartResponse.carPart.supplier.id &&
              f.acquisitionPriceWithVat === deleteCarPartResponse.carPart.acquisitionPriceWithVat);
            if (isValidObject(foundItem)) {
              state.carService.serviceCarParts.splice(state.carService.serviceCarParts.indexOf(foundItem), 1);
              state.carService.serviceCarParts = state.carService.serviceCarParts.map(r => r);
              const newTotalPrice = state.carService.computedTotalPrice - (deleteCarPartResponse.carPart.sellingPriceWithVat * deleteCarPartResponse.carPart.quantity);
              state.carService.computedTotalPrice = Math.round((newTotalPrice + Number.EPSILON) * 100) / 100;
              return Object.assign({}, state);
            }
          }
        }

      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetCarServiceEntryUsersEvent.identifier: {
      const carServiceUsers = (action as Do_GenericSuccessEvent<Array<FinalInspectionUser_Dto>>).data;
      state.carServiceUsers = carServiceUsers;
      return Object.assign({}, state);
    }

    default: {
      return state;
    }
  }
}

export const getTimestampCallback = (state: CarServiceState) => state.lastRefreshTime;
export const getCarServiceCallback = (state: CarServiceState) => state.carService;
export const getCarServiceEntriesCallback = (state: CarServiceState) => state.carServiceEntries;
export const getCarServicePagedResultCallback = (state: CarServiceState) => state.carServicePagedResult;
export const getCarServiceActionsCallback = (state: CarServiceState) => state.carServiceActionList;
export const getCarServiceUsersCallback = (state: CarServiceState) => state.carServiceUsers;

