import {BaseDI, BaseFormDI} from 'src/app/core/shared/base/base.di';
import { ForgotPassword_Dto } from 'src/app/core/app-dto/authentication.dto';
import {FormControl, FormGroup, Validators} from "@angular/forms";

export class GenerateActivationLinkDI extends BaseFormDI<ForgotPassword_Dto> {

  constructor(data: ForgotPassword_Dto = new ForgotPassword_Dto('')) {
    super(data);
    this.initForm();
  }

  getModel(): ForgotPassword_Dto {
    const formValue: ForgotPassword_Dto = <ForgotPassword_Dto>this.form.value;
    this.model.email = formValue.email;
    return this.model;
  }

  initForm() {
    this.form = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    });
  }

}
