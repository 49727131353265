import { BaseDI } from 'src/app/core/shared/base/base.di';
import { Department_Dto } from '../../../core/app-dto/department.dto';

export class DepartmentsListItemDi extends BaseDI<Department_Dto> {
    constructor(data: any) {
        super(new Department_Dto(data));
    }

    getModel(): Department_Dto {
        return this.model;
    }
}
