import { BaseDI } from 'src/app/core/shared/base/base.di';
import { FileAttachment, FileUploadTarget } from 'src/app/core/app-dto/core.dto';

export class FileDI extends BaseDI<FileAttachment> {
  public size: string = '';
  public type: string = '';

  constructor(data: FileAttachment = new FileAttachment(null)) {
    super(data);
    this.size = Math.round(this.model.size / 1000) + 'kb';
    switch (this.model.type) {
      case FileUploadTarget.CarServiceEntryGDPR: {
        this.type = "GDPR";
        break;
      }
      case FileUploadTarget.CarServiceEntryInitialCommand: {
        this.type = "Comanda initiala";
        break;
      }
      case FileUploadTarget.CarServiceEntrySHDeclaration: {
        this.type = "SH";
        break;
      }
      case FileUploadTarget.CarServiceEntryPV: {
        this.type = "PV";
        break;
      }
      case FileUploadTarget.CarServiceEntryEstimate: {
        this.type = "Deviz";
        break;
      }
      case FileUploadTarget.Invoice: {
        this.type = "Factura";
        break;
      }
      case FileUploadTarget.CarServiceEntryFinalInspection: {
        this.type = "Inspectie finala";
        break;
      }
      default:
        this.type = "General";
        break;
    }
  }

  getModel(): FileAttachment {
    return this.model;
  }
}
