import { PagedResult } from "../../shared/datatable/datatable.helpers";
import { Action } from "@ngrx/store";


import { DoLoadStaticData, DoAddStaticDataToState, DoRemoveStaticDataFromState } from "../events/static-data.events";
import {Do_GenericSuccessEvent, SeamlessEvent} from "../events/base.events";
import {StaticData} from "../../app-dto/static-data.dto";

export interface StaticDataState {
  data: StaticData;
};

export const INITIAL_STATE: StaticDataState = Object.assign({}, {
  data: null
});


export function reducer(state = INITIAL_STATE, action: Action): StaticDataState {
  if (!action) return state;
  var actionType = action.type;

  actionType = actionType.replace(SeamlessEvent.identifier, '');
  switch (actionType) {
    case Do_GenericSuccessEvent.identifier + DoLoadStaticData.identifier: {
      state.data = (<Do_GenericSuccessEvent<StaticData>>action).data;
      return Object.assign({}, state);
    }

    case DoAddStaticDataToState.identifier: {
      state.data = (<DoAddStaticDataToState>action).staticData;
      return Object.assign({}, state);
    }

    case DoRemoveStaticDataFromState.identifier:  {
      state.data = null;
      return Object.assign({}, state);
    }

    default: {
      return state;
    }
  }
}

export const getStaticDataCallback = (state: StaticDataState) => state.data;
