import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { User_Dto } from 'src/app/core/app-dto/core.dto';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {EmailRegForControl, min, PhoneReg, validEndDate} from 'src/app/core/shared/form/custom-form.validator';
import { isValidObject, isValidString } from "../../../core/shared/helpers/common.helpers";
import { IdNameKey_Dto } from "../../../core/app-dto/misc.dto";
import * as moment from 'moment';
import {Mechanic_Dto, RequestAccountForMechanic_Dto} from "../../../core/app-dto/mechanic.dto";

export class AddUserAccountDI extends BaseFormDI<RequestAccountForMechanic_Dto> {

  public mechanic : Mechanic_Dto;

  constructor(mechanic:Mechanic_Dto, data: RequestAccountForMechanic_Dto = new RequestAccountForMechanic_Dto(null, null, null)) {
    super(new RequestAccountForMechanic_Dto(data.email, data.phoneNumber, data.mechanicId));
    this.mechanic = mechanic;
    this.initForm();
  }

  getModel(): RequestAccountForMechanic_Dto {
    this.model.phoneNumber = this.form.value.phoneNumber;
    this.model.email = this.form.value.email;
    this.model.mechanicId = isValidObject(this.mechanic)? this.mechanic.id : null;
    return this.model;
  }

  initForm() {
    this.form = new FormGroup({
      email: new FormControl(this.model.email, Validators.compose([Validators.required, EmailRegForControl()])),
      phoneNumber: new FormControl(this.model.phoneNumber, Validators.compose([Validators.required, PhoneReg()])),
    });
  }
}
