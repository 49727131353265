import { Store } from "@ngrx/store";
import * as store from "../../../core/app-store";
import { Observable } from "rxjs";
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import {DoSagaExportForInvoicesEvent, GetInvoicesPagedResultEvent} from 'src/app/core/app-store/events/invoices.events';
import { InvoiceDataTableParameters } from 'src/app/core/shared/datatable/datatable.helpers';

export class DoSagaExportActionProxy extends BaseActionProxy {
  constructor(component: IWebComponent, appState: Store<store.State>) {
    super(component, appState)
  }

  canExecute(): boolean {
    return true;
  }

  execute(data,callback:()=>void): any {
    if (this.canExecute()) {
      this.appState.dispatch(new DoSagaExportForInvoicesEvent(data,callback));
    }
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }
}

