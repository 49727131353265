import {BaseActionProxy} from "../../../../core/shared/base/base.action-proxy";
import {IWebComponent} from "../../../../core/shared/base/base.component";
import {Store} from "@ngrx/store";
import * as store from "../../../../core/app-store";
import {GetCarForClientEvent} from "../../../../core/app-store/events/car-service.events";
import {Observable} from "rxjs";

export class SyncCarForClientActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent, appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(id: string): any {
        if (this.canExecute()) {
            this.appState.dispatch(new GetCarForClientEvent(id));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}
