import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, AbstractControlDirective} from "@angular/forms";

@Component({
  selector: 'show-errors',
  templateUrl: './show-errors.component.html',
  styleUrls: ['./show-errors.component.scss']
})
export class ShowErrorsComponent implements OnInit {

  constructor() { }

  private errorMessages = {
    'required': () => 'Campul este obligatoriu',
    'minlength': (params) => params.message,
    'maxlength': (params) => params.message,
    'pattern': () => "Valoarea invalida",
    'birthYear': (params) => params.message,
    'uniqueEmail': (params) => params.message,
    'password': (params) => params.message,
    'phone': (params) => params.message,
    'isEmpty': (params) => params.message,
    'isDate': (params) => params.message,
    'ngbDate': (params) => "Data invalida",
    'equalTo': (params) => "Parolele nu se potrivesc",
    'emailExists': (params) => params.message,
    'isInteger': (params) => params.message,
    'min': (params) => "Valoarea minima este " + params.min,
    'max': (params) => "Valoarea maxima este " + params.max,
    'lessThan': (params) => params.message,
    'moreThan': (params) => params.message,
    'isDouble': (params) => params.message,
    'invalidShortName': (params) => "Numele trebuie sa inceapa cu o litera si sa contina doar litere si cifre",
    'mustMatch': (params) => "Parolele nu sunt identice",
    'mustHaveChoices': (params) => "Optiunile sunt obligatorii",
    'hasSpaces': (params) => "Textul nu poate contine spatii",
    'emailReg': (params) => "Adresa de e-mail este invalida",
    'email': (params) => "Adresa de e-mail este invalida",
    'phoneReg': (params) => "Trebuie sa fie un numar de telefon valid",
    'passwordReg': (params) => "Parola trebuie sa aiba intre 4 si 8 caractere si trebuie sa includa cel putin o litera mare, o litera mica, si un numar.",
    'minValue': (params) => "Valoarea minima este " + params.min,
    'maxTextLength': (params) => "Lungimea maxima a textului este " + params.max,
    'numeric': (params) => "Valoarea trebuie sa fie un numar pozitiv",
    'whitespace': (params) => "Cui/Cnp nu poate contine spatii",
    'cnpReg': (params) => "CNP-ul este invalid",

  };

  @Input()
  private control: AbstractControlDirective | AbstractControl;

  private formSubmitted: boolean = true;
  @Input('formSubmitted') set submitted(value: boolean) {
    this.formSubmitted = value;
  }

  shouldShowErrors(): boolean {
    return this.control &&
      this.control.errors &&
      this.formSubmitted;
  }

  listOfErrors(): string[] {
    var error = Object.keys(this.control.errors)
      .map(field => this.getMessage(field, this.control.errors[field]))[0];
    if (error != undefined)
      return [error];
    else return [];
  }

  private getMessage(type: string, params: any) {
    return this.errorMessages[type](params);
  }

  ngOnInit() {
  }
}
