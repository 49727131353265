import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import {FinalizeCarServiceEvent} from '../../../core/app-store/events/car-service.events';

export class FinalizeCarServiceActionProxy extends BaseActionProxy {
  constructor(component: IWebComponent,
              appState: Store<store.State>) {
    super(component, appState);
  }

  canExecute(): boolean {
    return true;
  }

  execute(carServiceId: string): any {
    this.appState.dispatch(new FinalizeCarServiceEvent(carServiceId));
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }
}

