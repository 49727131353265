import { ObjectValidators } from '../shared/object.validators';
import { Base_Dto } from './misc.dto';
import {CarAppointment_Dto} from './car-appointment.dto';


export class AppointmentCar_Dto extends Base_Dto {
  public registrationNumber: string;
  public bodySeries: string;
  public make: string;
  public model: string;
  public carRelatedId: string;
  public details: string;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.registrationNumber = data.registrationNumber;
      this.bodySeries = data.bodySeries;
      this.make = data.make;
      this.model = data.model;
      this.carRelatedId = data.carRelatedId;
      this.details = data.details;
    }
  }
}
