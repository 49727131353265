import {select, Store} from "@ngrx/store";
import * as store from '../../app-store/index';
import { Subscription } from "rxjs";
import * as coreStore from "../../app-store/state-selectors/core.selectors";
import {LoggedUser_Dto} from "../../app-dto/core.dto";
import {Injectable} from "@angular/core";

export abstract class BaseSandbox {
  appState: Store<store.State>;
  protected subscriptions: Array<Subscription> = [];

  protected constructor(appState: Store<store.State>) {
    this.appState = appState;
  }

  abstract initialize(param1?: any, param2?: any, param3?: any, param4?:any, param5?:any): any;

  public unsubscribe(): any {
    this.unregisterEvents();
  }
  public addSubscription(subscription :Subscription){
    this.subscriptions.push(subscription);
  }
  
  abstract clearSandboxState();

  protected unregisterEvents() {
    //setTimeout(() => {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    //});
  }
}

export abstract class BaseListSandboxWithActiveAndSearchBehavior extends BaseSandbox{
  abstract setResetTableCallback(callback:any);
}
@Injectable()
export class BaseSandboxWithCurrentUserLoaded  extends BaseSandbox {
  public currentUser$ = this.appState$.pipe(select(coreStore.getLoggedUser));
  public currentUser: LoggedUser_Dto = null;
  public currentOrganizationCurrency = "";

  constructor(
    protected appState$: Store<store.State>
  ) {
    super(appState$);
  }
  public clearSandboxState() {
    this.currentUser = null;
    this.currentOrganizationCurrency = "";
  }

  public initialize(param1?: any, param2?: any, param3?: any, param4?:any, param5?:any){}

  public loadUser(dataLoaded: () => void): any {
    this.clearSandboxState();

    this.subscriptions.push(this.currentUser$.subscribe((state: LoggedUser_Dto) => {
      if (state != null ) {
        this.currentUser = state;
        this.currentOrganizationCurrency = this.currentUser.organization != null ? this.currentUser.organization.currency : "";
        dataLoaded();
      }
    }));
  }
}
