import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandboxWithCurrentUserLoaded } from 'src/app/core/shared/base/base.sandbox';

@Injectable()
export class CarServiceFinalInspectionSandbox extends BaseSandboxWithCurrentUserLoaded {

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public initialize(): any {
        this.clearSandboxState();
        this.loadUser(() => { });
    }
}
