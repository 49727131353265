import { BaseDI } from 'src/app/core/shared/base/base.di';
import { OrganizationClient_Dto } from '../../../core/app-dto/organization-client.dto';

export class OrganizationClientDetailsDi extends BaseDI<OrganizationClient_Dto> {
    constructor(data: OrganizationClient_Dto = new OrganizationClient_Dto(null)) {
        super(data);
    }

    getModel(): OrganizationClient_Dto {
        return this.model;
    }
}
