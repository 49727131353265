import { Store } from '@ngrx/store';
import * as store from '../../../app-store/index';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import {
  DeleteAppNotificationEvent,
  GetNotificationsPagedResultEvent, MarkAppNotificationAsNotReadEvent,
  NotificationDataTableParameters
} from "../../../app-store/events/notification.events";
import {AppNotification} from "../../../app-dto/core.dto";

export class MarkNotificationAsNotReadActionProxy extends BaseActionProxy {
  constructor(component: IWebComponent,
              appState: Store<store.State>) {
    super(component, appState);
  }

  canExecute(): boolean {
    return true;
  }

  execute(data:AppNotification): any {
    this.appState.dispatch(new MarkAppNotificationAsNotReadEvent(data));
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }
}

