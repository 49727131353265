import {BaseFormDI} from "../../base/base.di";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {InvoicePrintJob_Dto} from "../../../app-dto/invoice-print-job.dto";
import {InvoiceChargeDi} from "./invoice-charge.di";
import {NotifierService} from "angular-notifier";
import {Invoice_Dto} from "../../../app-dto/invoice.dto";
import {AppConstants} from "../../../app-dto/app.constants";

export class NumericIdName {
    constructor(public id: number, public name: string) {
    }
}

export class RegisterPrintJobDi extends BaseFormDI<InvoicePrintJob_Dto> {
    public chargeTypes: Array<NumericIdName> = [];
    public charges: Array<InvoiceChargeDi> = [];

    constructor(private notifierService: NotifierService, public invoice: Invoice_Dto, data?: any) {
        super(new InvoicePrintJob_Dto(data));

        this.model.organizationId = this.invoice.organizationId;
        this.model.invoiceId = this.invoice.id;

        this.charges.push(new InvoiceChargeDi(1, {value: invoice.totalValue}));

        this.initForm();
        this.initializeChargeTypes();
    }

    public resetChargeTypes(values: NumericIdName[]): void {
        this.chargeTypes = [];
        this.chargeTypes.push.apply(this.chargeTypes, values);
    }

    private initializeChargeTypes() {
        this.chargeTypes.push(new NumericIdName(1, `${AppConstants.CASH_REGISTER_PARAMETER_CASH_DISPLAY_AS} (1)`));
        this.chargeTypes.push(new NumericIdName(2, `${AppConstants.CASH_REGISTER_PARAMETER_CARD_DISPLAY_AS} (2)`));
    }

    getModel(): InvoicePrintJob_Dto {
        this.model.cashRegisterId = this.form.value.cashRegisterId;
        this.model.freeText = this.form.value.freeText;
        this.model.charges = [];
        this.charges.forEach(x => {
            let charge = x.getModel();
            let foundChargeType = this.chargeTypes.find(x => x.id == charge.type);
            if(foundChargeType)
                charge.description = foundChargeType.name;
            this.model.charges.push(charge);
        });
        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
            cashRegisterId: new FormControl(this.model.cashRegisterId, Validators.compose([Validators.required])),
            freeText: new FormControl(this.model.freeText, Validators.compose([]))
        });
    }

    public addCharge() {
        const currentLength = this.charges.length;
        this.charges.push(new InvoiceChargeDi(currentLength + 1, null));
    }

    public deleteCharge(index: number) {
        const currentLength = this.charges.length;
        if (currentLength == 1) {
            this.notifierService.notify('warning', 'Nu se poate elimina! Este nevoie de cel putin o plata.');
            return;
        }

        const found = this.charges.find(x => x.index == index);
        if (found) {
            const indexOf = this.charges.indexOf(found);
            if (indexOf > -1) this.charges.splice(indexOf, 1);
        }
    }

    private validateChargesTotalAmount(): boolean {
        let invoiceAmount = this.invoice.totalValue;
        const values = this.charges.map(x => x.getModel().amount);
        let candidateAmount = 0;
        if (values.length > 0)
            candidateAmount = values.reduce((a, b) => a + b, 0);

        return invoiceAmount >= candidateAmount;
    }

    public isValid(): boolean {
        const mainFormIsValid = this.form.valid;
        if (!mainFormIsValid)
            return false;

        let secondFormsValidation = [];
        this.charges.forEach(c => {
            secondFormsValidation.push(
                {
                    id: c.index, isValid: c.isValid()
                });
        });

        const oneInvalidCharge = secondFormsValidation.find(x => x.isValid == false);
        if (oneInvalidCharge)
            return false;


        const isChargedAmountValid = this.validateChargesTotalAmount();
        if (!isChargedAmountValid) {
            this.notifierService.notify('warning', 'Valorile introduse nu sunt valide! Suma tuturor platilor nu este egala cu suma de pe factura.');
            return false;
        }

        return mainFormIsValid;
    }
}
