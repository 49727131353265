import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { AppointmentClient_Dto } from '../../../core/app-dto/appointment-client.dto';
import {AppointmentCarWorks_Dto, CarWork_Dto} from '../../../core/app-dto/car-work.dto';

// export class AppointmentCarWorksDi extends BaseFormDI<Array<CarWork_Dto>> {
export class AppointmentCarWorksDi extends BaseFormDI<AppointmentCarWorks_Dto> {

    constructor(data: AppointmentCarWorks_Dto = new AppointmentCarWorks_Dto(null)) {
        super(data);
        this.initForm();
    }

    getModel(): AppointmentCarWorks_Dto {
        this.model.details = this.form.value.details;
        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
            details: new FormControl(this.model.details, Validators.compose([])),
        });
    }
}
