import { PagedResult } from '../../shared/datatable/datatable.helpers';
import { Action } from '@ngrx/store';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from '../events/base.events';
import { ResetStateActionProxy } from '../../shared/base/base.action-proxy';
import { Car_Dto } from '../../app-dto/car.dto';
import {
  ActivateCarEvent,
  DeactivateCarEvent, DeleteCarEvent, EditCarEvent,
  GetCarEvent,
  GetCarsPagedResultEvent,
  RegisterCarEvent,
  SearchCarEvent
} from '../events/car.events';
import * as moment from 'moment';
import {ObjectValidators} from "../../shared/object.validators";
import { GetCarForClientEvent } from '../events/car-service.events';
import { CarAction_Dto } from '../../app-dto/car-service.dto';

export interface CarState {
  lastRefreshTime: number;
  car: Car_Dto;
  CarsPagedResult: PagedResult<Car_Dto>;
  carSearchList: Array<Car_Dto>;
  carForClient: Car_Dto;
}

export const INITIAL_STATE: CarState = Object.assign({}, {
  lastRefreshTime: 0,
  car: null,
  CarsPagedResult: new PagedResult<Car_Dto>([]),
  carSearchList: [],
  carForClient: null
});


export function reducer(state = INITIAL_STATE, action: Action): CarState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {
    // Get Car

    case Do_ResetStateForSection.identifier + ResetSection.Car: {
      return Object.assign({}, state, { car: null });
    }

    case Do_GenericSuccessEvent.identifier + GetCarEvent.identifier: {
      const carData = new Car_Dto((action as Do_GenericSuccessEvent<Car_Dto>).data);
      return Object.assign({}, state, { car: carData });
    }

    case Do_GenericErrorEvent.identifier + GetCarEvent.identifier: {
      return Object.assign({}, state, { car: null });
    }

    case ResetStateActionProxy.name + GetCarEvent.identifier: {
      return Object.assign({}, state, { car: null });
    }

    // Get Car

    case Do_ResetStateForSection.identifier + ResetSection.Cars: {
      return Object.assign({}, state, { CarsPagedResult: new PagedResult<Car_Dto>([]) });
    }

    case GetCarsPagedResultEvent.identifier: {
      if ((action as GetCarsPagedResultEvent).withReset) {
        state.CarsPagedResult = new PagedResult<Car_Dto>([]);
        return Object.assign({}, state);
      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetCarsPagedResultEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<PagedResult<Car_Dto>>).data;
      state.CarsPagedResult = new PagedResult<Car_Dto>(data);
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetCarsPagedResultEvent.identifier: {
      state.CarsPagedResult = new PagedResult<Car_Dto>([]);
      return Object.assign({}, state);
    }

    // Get Car

    // Get Car for Client

    case Do_GenericSuccessEvent.identifier + GetCarForClientEvent.identifier: {
      const carForClientData = (action as Do_GenericSuccessEvent<Array<CarAction_Dto>>).data;
      return Object.assign({}, state, { carForClient: carForClientData });
    }

    case Do_GenericErrorEvent.identifier + GetCarForClientEvent.identifier: {
      return Object.assign({}, state, { carForClient: null });
    }

    case Do_ResetStateForSection.identifier + ResetSection.CarForClient: {
      return Object.assign({}, state, { carForClient: null });
    }
    // Get Car for Client

    // Search Car

    case Do_GenericSuccessEvent.identifier + SearchCarEvent.identifier: {
      const carSearchData = (action as Do_GenericSuccessEvent<Array<Car_Dto>>).data.map(r => new Car_Dto(r));
      return Object.assign({}, state, { carSearchList: carSearchData });
    }

    case Do_GenericErrorEvent.identifier + SearchCarEvent.identifier: {
      return Object.assign({}, state, { carSearchList: [] });
    }

    // Search Car

    case Do_GenericSuccessEvent.identifier + RegisterCarEvent.identifier: {
      return Object.assign({}, state, { lastRefreshTime: moment().unix()});
    }
    case Do_GenericSuccessEvent.identifier + ActivateCarEvent.identifier:
    case Do_GenericSuccessEvent.identifier + DeactivateCarEvent.identifier:
    case Do_GenericSuccessEvent.identifier + EditCarEvent.identifier: {
      const data = new Car_Dto((action as Do_GenericSuccessEvent<Car_Dto>).data);
      if(ObjectValidators.isValidObject(state.car) && state.car.id == data.id) {
        state.car = data;
      }
      state.lastRefreshTime =  moment().unix();
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + DeleteCarEvent.identifier: {
      state.lastRefreshTime =  moment().unix();
      return Object.assign({}, state);
    }

    default: {
      return state;
    }
  }
}

export const getTimestampCallback = (state: CarState) => state.lastRefreshTime;
export const getCarCallback = (state: CarState) => state.car;
export const getCarsPagedResultCallback = (state: CarState) => state.CarsPagedResult;
export const getCarSearchListCallback = (state: CarState) => state.carSearchList;
export const getCarForClientCallback = (state: CarState) => state.carForClient;
