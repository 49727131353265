import {BaseActionProxy} from '../../../core/shared/base/base.action-proxy';
import {IWebComponent} from '../../../core/shared/base/base.component';
import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {Observable} from 'rxjs';
import {GetOrgReceiptDownloadLink} from '../../../core/app-store/events/receipt.events';

export class GetDownloadLinkDorOrgReceiptActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(receiptId: string, callback: (link: string) => void): any {
        this.appState.dispatch(new GetOrgReceiptDownloadLink(receiptId, callback));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}
