import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import {
  BaseListWebComponent,
  BaseListWebComponentWithSearchAndActiveBehavior
} from 'src/app/core/shared/base/base.component';
import { Subscription } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ActionsSubject } from '@ngrx/store';
import { ConfigService } from 'src/app/core/core/app-config.service';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap';
import { ConfirmationDialogService } from 'src/app/core/core/dialog/confirmation-dialog.service';
import { ResetSection, SeamlessEvent } from 'src/app/core/app-store/events/base.events';
import { SearchEvent } from 'src/app/core/app-store';
import { InvoiceRequestType } from '../invoice/invoice.component';
import {
  DataTableParametersOrderEntity,
  InvoiceDataTableParameters
} from 'src/app/core/shared/datatable/datatable.helpers';
import { GetOverdueInvoicesActionProxy } from './get-overdue-invoices.action-proxy';
import { InvoiceOverdueListSandbox } from './invoice-overdue-list.sandbox';
import {IdName_Dto} from "../../../core/app-dto/misc.dto";

@Component({
  selector: 'app-invoice-overdue-list',
  templateUrl: './invoice-overdue-list.component.html',
  styleUrls: ['./invoice-overdue-list.component.scss']
})
export class InvoiceOverdueListComponent extends BaseListWebComponentWithSearchAndActiveBehavior implements OnInit, AfterViewInit {
  public getOverdueInvoicesAP: GetOverdueInvoicesActionProxy;

  public onlyUnpaid: boolean = false;
  public searchValue: string = '';

  public columns: any = [];
  public sortColumns: Array<IdName_Dto> = [];
  public currentPage = 1;


  constructor(
    public sandbox: InvoiceOverdueListSandbox,
    actionsSubj: ActionsSubject,
    private configService: ConfigService,
    public router: Router,
    private modalService: BsModalService,
    public confirmationDialogService: ConfirmationDialogService,
  ) {
    super(sandbox,actionsSubj,ResetSection.Invoices);
    this.getOverdueInvoicesAP = new GetOverdueInvoicesActionProxy(this, sandbox.appState);

    this.columns = [
      { data: 'number', width: '10%', name : 'Numar' },
      { data: 'client', width: '25%', name:'Client' },
      { data: 'email', width: '20%', orderable: false },
      { data: 'cui', width: '10%', orderable: false },
      { data: 'deadline', width: '10%', orderable: false },
      { data: 'status', width: '10%', orderable: false },
      {data: 'totalValue', name: 'Total', width: '5%', orderable: false},
      { data: 'action', width: '50px', orderable: false },
    ];
    this.sortColumns = this.columns.filter(c => c.name).map(c => ({id: c.data, name: c.name} as IdName_Dto));

  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.initialize();

    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      pageLength: this.configService.getDefaultPageSize(),
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      ajax: (dataTablesParameters: InvoiceDataTableParameters, callback) => {
        dataTablesParameters.search.value = this.searchValue;
        dataTablesParameters.onlyUnpaid = true;
        this.doGetAll(new InvoiceDataTableParameters(dataTablesParameters), callback, false);
      },
      columns: this.columns,
      order: [[0, 'asc']],
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
  }

  public doGetAll(dataTablesParameters: InvoiceDataTableParameters, callback: any, withReset: boolean) {
    this.sandbox.callback = callback;
    this.sandbox.dataTableParameters = dataTablesParameters;
    this.getOverdueInvoicesAP.execute(dataTablesParameters, withReset);

  }

  public openInvoice(invoiceId: string) {
    this.router.navigate(['/invoice', invoiceId, 'details']);
  }
  onSortChange($event) {
    if ($event) {
      const columnId = this.columns.map((e) => e.data).indexOf($event.id);
      const orderEntity = new DataTableParametersOrderEntity({column: columnId, dir: 'asc'});
      this.sandbox.dataTableParameters.order = [orderEntity];
    }
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
  }
  onPageChange(currentPage: number) {
    this.sandbox.dataTableParameters.pageNumber = currentPage;
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
  }
}
