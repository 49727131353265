import {Injectable} from '@angular/core';
import * as store from '../../../core/app-store/index';
import {select, Store} from '@ngrx/store';
import {BaseSandbox, BaseSandboxWithCurrentUserLoaded} from 'src/app/core/shared/base/base.sandbox';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {CarService_Dto} from '../../../core/app-dto/car-service.dto';
import {Offer_Dto} from '../../../core/app-dto/offer.dto';

@Injectable()
export class OfferDetailsOverviewSandbox extends BaseSandboxWithCurrentUserLoaded {
    public getOffer$ = this.appState$.pipe(select(store.getCurrentOffer));

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    clearSandboxState() {
    }

    initialize(dataLoaded: (data: Offer_Dto) => {}) {
        this.clearSandboxState();
        super.loadUser(() => {

        });

        this.subscriptions.push(this.getOffer$.subscribe((data: Offer_Dto) => {
            if (ObjectValidators.isValidObject(data)) {
                dataLoaded(data);
            }
        }));
    }
}
