import * as escapeStringRegexp from "escape-string-regexp";
import {ObjectValidators} from "../object.validators";
import {isValidString} from '../helpers/common.helpers';

export class DataTableParametersSearchEntity {
  public value: string;
  public regex: boolean;

  constructor(data: any) {
    if (data != null) {
      this.value = data.value;
      this.regex = data.regex;
    }
  }
}

export class DataTableParametersColumnEntity {
  public data: string;
  public name: string;
  public orderable: boolean;
  public search: DataTableParametersSearchEntity;
  public searchable: boolean;

  constructor(data: any) {
    if (data != null) {
      this.data = data.data;
      this.name = data.name;
      this.orderable = data.orderable;
      this.search = new DataTableParametersSearchEntity(data.search);
      this.searchable = data.searchable;
    }
  }
}

export class DataTableParametersOrderEntity {
  public column: number;
  public dir: string;

  constructor(data: any) {
    if (data != null) {
      this.column = data.column;
      this.dir = data.dir;
    }
  }
}

export class DataTableParameters {
  public columns: Array<DataTableParametersColumnEntity>;
  public order: Array<DataTableParametersOrderEntity>;
  public search: DataTableParametersSearchEntity;
  public pageNumber: number;
  public pageSize: number;

  constructor(data: any) {
    if (data != null) {
      this.columns = data.columns.map(d => new DataTableParametersColumnEntity(d));
      if (data.order != null) {
        this.order = data.order.map(d => new DataTableParametersOrderEntity(d));
      }
      else this.order = [];
      this.search = new DataTableParametersSearchEntity(data.search);
      this.pageNumber = (data.start / data.length) + 1;
      this.pageSize = data.length;
    }
  }
  toUrlParameters(): string {
    var result = [];

    if (this.order != null && this.order.length > 0) {
      var column = this.columns[this.order[0].column];
      result.push('sortBy=' + column.data);
      result.push('isAscending=' + (this.order[0].dir == 'asc'));
    }
    else {
      result.push("sortBy=");
      result.push("isAscending=false");
    }
    if (this.search != null && this.search.value != null && this.search.value != undefined && this.search.value.trim() != '') {
      result.push('search=' + escapeStringRegexp(this.search.value.trim()));
    } else {
      result.push("search=");
    }
    result.push('pageNumber=' + this.pageNumber);
    result.push('pageSize=' + this.pageSize);

    return result.join('&');
  }
}

export class PagedResult<T> {
  public items: Array<T>;
  public currentPage: number;
  public totalPages: number;
  public totalItems: number;
  public searchTerm: string;

  constructor(data?: any) {
    if (data != null && data !== undefined) {
      this.items = data.items;
      this.currentPage = data.currentPage;
      this.totalPages = data.totalPages;
      this.totalItems = data.totalItems;
      this.searchTerm = data.searchTerm;
    } else {
      this.items = [];
      this.currentPage = 0;
      this.totalPages = 0;
      this.totalItems = 0;
      this.searchTerm = "";
    }
  }
}

export class ActiveDataTableParameters extends DataTableParameters {
  public active: boolean;

  constructor(data?: any) {
    super(data);
    if (data != null && data !== undefined) {
      this.active = data.active;
    } else {
      this.active = true;
    }
  }

  toUrlParameters(): string {
    let result = super.toUrlParameters();
    result = result + '&isActive=' + this.active.toString();
    return result;
  }
}

export class OrganizationListTableParameters extends ActiveDataTableParameters {
  public status: string;

  constructor(data?: any) {
    super(data);
    if (data != null && data !== undefined) {
      this.status = data.status;
    } else {
      this.status = null;
    }
  }

  toUrlParameters(): string {
    let result = super.toUrlParameters();
    result = result + '&status=' + this.status.toString();
    return result;
  }
}

export class CarServiceListDataTableParameters extends DataTableParameters {
  public onlyCarsThatLeftTheService: boolean;

  constructor(data?: any) {
    super(data);
    if (data != null && data !== undefined) {
      this.onlyCarsThatLeftTheService = data.onlyCarsThatLeftTheService;
    } else {
      this.onlyCarsThatLeftTheService = false;
    }
  }

  toUrlParameters(): string {
    let result = super.toUrlParameters();
    result = result + '&onlyCarsThatLeftTheService=' + this.onlyCarsThatLeftTheService.toString();
    return result;
  }
}

export class InvoiceDataTableParameters extends DataTableParameters {
  public onlyUnpaid: boolean;

  constructor(data?: any) {
    super(data);
    if (data != null && data !== undefined) {
      this.onlyUnpaid = data.onlyUnpaid;
    } else {
      this.onlyUnpaid = true;
    }
  }

  toUrlParameters(): string {
    let result = super.toUrlParameters();
    result = result + '&onlyUnpaid=' + this.onlyUnpaid.toString();
    return result;
  }
}

export class ReceiptDataTableParameters extends DataTableParameters {
  public invoiceId: string;
  constructor(data?: any) {
    super(data);
    if (data != null && data !== undefined) {
      this.invoiceId = data.invoiceId;
    } else {
      this.invoiceId = null;
    }
  }

  toUrlParameters(): string {
    let result = super.toUrlParameters();
    if (isValidString(this.invoiceId)) {
      result = result + '&invoiceId=' + this.invoiceId.toString();
    }
    return result;
  }
}

export class InvoicePrintJobsDataTableParameters extends DataTableParameters {
    public onlyActive: boolean = true;
    public cashRegisterId: string = '';

    constructor(data?: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.onlyActive = data.onlyActive;
            this.cashRegisterId = data.cashRegisterId;
        }
    }

    toUrlParameters(): string {
        let result = super.toUrlParameters();
        result = result + '&isActive=' + this.onlyActive.toString();
        if (ObjectValidators.isValidString(this.cashRegisterId))
            result = result + '&cashRegisterId=' + this.cashRegisterId.toString();
        return result;
    }
}
