import {Observable} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import {map, switchMap} from 'rxjs/operators';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  EventWithScreenLoading,
  SeamlessEvent
} from 'src/app/core/app-store/events/base.events';
import {CalendarApiService} from 'src/app/core/api-integration/services/calendar-api.service';
import {
  ConfirmCalendarEvent,
  DeleteCalendarEventEvent,
  DeleteCarAppointmentEvent,
  EditCalendarEventEvent,
  GetCalendarEventsEvent,
  GetCalendarEventStatisticsEvent,
  GetCarAppointmentEvent,
  RegisterCalendarEventEvent,
  RegisterCarAppointmentEvent,
  SearchCalendarEventEvent,
  UpdateCarAppointmentEvent
} from 'src/app/core/app-store/events/calendar-events';
import {CalendarEvent_Dto, CalendarEventType, CalendarStatistics} from 'src/app/core/app-dto/calendar-event.dto';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {CarAppointment_Dto} from '../../core/app-dto/car-appointment.dto';


@Injectable()
export class CalendarUIEventProcessor {
  constructor(private actions$: Actions,
              private appState$: Store<store.State>,
              private calendarApiService: CalendarApiService) {
  }

  @Effect()
  doDeleteCarAppointmentEvents$: Observable<any> = this.actions$
    .pipe(
      ofType(DeleteCarAppointmentEvent.identifier, EventWithScreenLoading.identifier + DeleteCarAppointmentEvent.identifier),
      map((action: DeleteCarAppointmentEvent) => action),
      switchMap((action: DeleteCarAppointmentEvent) => {
          return new Observable((observer) => {
            this.calendarApiService.u_deletetCarAppointmentEvent(action.id)
              .subscribe((res: string) => {
                if (action.callback)
                  action.callback();
                observer.next(new Do_GenericSuccessEvent<string>(res, action));
                observer.complete();
              }, (error: string) => {
                action.callback();
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  doGetCalendarEvents$: Observable<any> = this.actions$
    .pipe(
      ofType(GetCalendarEventsEvent.identifier, SeamlessEvent.identifier + GetCalendarEventsEvent.identifier),
      map((action: GetCalendarEventsEvent) => action),
      switchMap((action: GetCalendarEventsEvent) => {
          return new Observable((observer) => {
            this.calendarApiService.u_getCalendarEvents(action.startDate, action.endDate, action.eventType, action.search)
              .subscribe((res: Array<CalendarEvent_Dto>) => {
                action.callback();
                observer.next(new Do_GenericSuccessEvent<Array<CalendarEvent_Dto>>(res, action));
                observer.complete();
              }, (error: string) => {
                action.callback();
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );
  @Effect()
  doGetCalendarEventStatistics$: Observable<any> = this.actions$
    .pipe(
      ofType(GetCalendarEventStatisticsEvent.identifier, SeamlessEvent.identifier + GetCalendarEventStatisticsEvent.identifier),
      map((action: GetCalendarEventStatisticsEvent) => action),
      switchMap((action: GetCalendarEventStatisticsEvent) => {
          return new Observable((observer) => {
            this.calendarApiService.u_getCalendarEventStatistics()
              .subscribe((res: CalendarStatistics) => {
                action.callback();
                observer.next(new Do_GenericSuccessEvent<CalendarStatistics>(res, action));
                if (action.callback != null) action.callback(res);
                observer.complete();
              }, (error: string) => {
                action.callback();
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  doRegisterCalendarEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(RegisterCalendarEventEvent.identifier, SeamlessEvent.identifier + RegisterCalendarEventEvent.identifier),
      map((action: RegisterCalendarEventEvent) => action),
      switchMap((action: RegisterCalendarEventEvent) => {
          return new Observable((observer) => {
            this.calendarApiService.u_registerCalendarEvent(action.model)
              .subscribe((res: CalendarEvent_Dto) => {
                action.callback();
                observer.next(new Do_GenericSuccessEvent<CalendarEvent_Dto>(res, action));
                observer.complete();
              }, (error: string) => {
                action.callback();
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );


  @Effect()
  doEditCalendarEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(EditCalendarEventEvent.identifier, SeamlessEvent.identifier + EditCalendarEventEvent.identifier),
      map((action: EditCalendarEventEvent) => action),
      switchMap((action: EditCalendarEventEvent) => {
          return new Observable((observer) => {
            this.calendarApiService.u_editCalendarEvent(action.model)
              .subscribe((res: CalendarEvent_Dto) => {
                action.callback();
                observer.next(new Do_GenericSuccessEvent<CalendarEvent_Dto>(res, action));
                observer.complete();
              }, (error: string) => {
                action.callback();
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  doDeleteCalendarEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(DeleteCalendarEventEvent.identifier, SeamlessEvent.identifier + DeleteCalendarEventEvent.identifier),
      map((action: DeleteCalendarEventEvent) => action),
      switchMap((action: DeleteCalendarEventEvent) => {
          return new Observable((observer) => {
            this.calendarApiService.u_deleteCalendarEvent(action.id)
              .subscribe((res: boolean) => {
                action.callback();
                observer.next(new Do_GenericSuccessEvent<string>(action.id, action));
                observer.complete();
              }, (error: string) => {
                action.callback();
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  doSearchCalendarEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(SearchCalendarEventEvent.identifier, SeamlessEvent.identifier + SearchCalendarEventEvent.identifier),
      map((action: SearchCalendarEventEvent) => action),
      switchMap((action: SearchCalendarEventEvent) => {
        return new Observable((observer) => {
          this.calendarApiService.u_getCalendarEvents(action.startDate, action.endDate, CalendarEventType.Appointment.toString(), action.search)
            .subscribe((res: Array<CalendarEvent_Dto>) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<Array<CalendarEvent_Dto>>(res, action));
              }
              action.callBack();
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              action.callBack();
              observer.complete();
            });
        });
      })
    );

  @Effect()
  doConfirmCalendarEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(ConfirmCalendarEvent.identifier, SeamlessEvent.identifier + ConfirmCalendarEvent.identifier),
      map((action: ConfirmCalendarEvent) => action),
      switchMap((action: ConfirmCalendarEvent) => {
          return new Observable((observer) => {
            this.calendarApiService.u_confirmCalendarEvent(action.id)
              .subscribe((res: CalendarEvent_Dto) => {
                action.callback();
                observer.next(new Do_GenericSuccessEvent<CalendarEvent_Dto>(res, action));
                observer.complete();
              }, (error: string) => {
                action.callback();
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );


  @Effect()
  doRegisterCarAppointmentEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(RegisterCarAppointmentEvent.identifier, EventWithScreenLoading.identifier + RegisterCarAppointmentEvent.identifier),
      map((action: RegisterCarAppointmentEvent) => action),
      switchMap((action: RegisterCarAppointmentEvent) => {
          return new Observable((observer) => {
            this.calendarApiService.u_registerCarAppointmentEvent(action.model)
              .subscribe((res: CarAppointment_Dto) => {
                action.callback();
                observer.next(new Do_GenericSuccessEvent<CarAppointment_Dto>(res, action));
                observer.complete();
              }, (error: string) => {
                action.callback();
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  doGetCarAppointmentEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetCarAppointmentEvent.identifier, EventWithScreenLoading.identifier + GetCarAppointmentEvent.identifier),
      map((action: GetCarAppointmentEvent) => action),
      switchMap((action: GetCarAppointmentEvent) => {
          return new Observable((observer) => {
            this.calendarApiService.u_getCarAppointmentEvent(action.id)
              .subscribe((res: CarAppointment_Dto) => {
                action.callback(res);
                observer.next(new Do_GenericSuccessEvent<CarAppointment_Dto>(res, action));
                observer.complete();
              }, (error: string) => {
                action.callback(null);
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  doUpdateCarAppointmentEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(UpdateCarAppointmentEvent.identifier, EventWithScreenLoading.identifier + UpdateCarAppointmentEvent.identifier),
      map((action: UpdateCarAppointmentEvent) => action),
      switchMap((action: UpdateCarAppointmentEvent) => {
          return new Observable((observer) => {
            this.calendarApiService.u_updateCarAppointmentEvent(action.model)
              .subscribe((res: CarAppointment_Dto) => {
                action.callback();
                observer.next(new Do_GenericSuccessEvent<CarAppointment_Dto>(res, action));
                observer.complete();
              }, (error: string) => {
                action.callback();
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );
}
