import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationClientsListComponent } from './components/organization-clients-list/organization-clients-list.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared/shared.module';
import { DataTablesModule } from 'angular-datatables';
import { OrganizationClientDetailsComponent } from './components/organization-client-details/organization-client-details.component';
import { SaveOrganizationClientComponent } from './components/save-organization-client/save-organization-client.component';
import { SaveOrganizationClientSandbox } from './components/save-organization-client/save-organization-client.sandbox';
import { OrganizationClientDetailsSandbox } from './components/organization-client-details/organization-client-details.sandbox';
import { OrganizationClientsListSandbox } from './components/organization-clients-list/organization-clients-list.sandbox';
import { NgSelectModule } from '@ng-select/ng-select';
import { OrganizationClientFormComponent } from './components/organization-client-form/organization-client-form.component';
import { OrganizationClientFormSandbox } from './components/organization-client-form/organization-client-form.sandbox';
import { OrganizationClientSearchComponent } from './components/organization-client-search/organization-client-search.component';
import { OrganizationClientSearchSandbox } from './components/organization-client-search/organization-client-search.sandbox';
import { ModalModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [
    OrganizationClientsListComponent,
    OrganizationClientDetailsComponent,
    SaveOrganizationClientComponent,
    OrganizationClientFormComponent,
    OrganizationClientSearchComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    SharedModule,
    DataTablesModule,
    ModalModule.forRoot()
  ],
  providers: [
    OrganizationClientsListSandbox,
    OrganizationClientDetailsSandbox,
    SaveOrganizationClientSandbox,
    OrganizationClientFormSandbox,
    OrganizationClientSearchSandbox
  ],
  entryComponents: [
    SaveOrganizationClientComponent
  ],
  exports: [
    OrganizationClientFormComponent,
    OrganizationClientSearchComponent,
    OrganizationClientsListComponent
  ]
})
export class OrganizationClientModule { }
