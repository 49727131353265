import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppointmentClient_Dto } from '../../../core/app-dto/appointment-client.dto';
import { PhoneReg } from '../../../core/shared/form/custom-form.validator';

export class AppointmentClientDi extends BaseFormDI<AppointmentClient_Dto> {

    constructor(data: AppointmentClient_Dto = new AppointmentClient_Dto(null)) {
        super(new AppointmentClient_Dto(data));
        this.initForm();
    }

    getModel(): AppointmentClient_Dto {
        this.model.phoneNumber = this.form.value.phoneNumber;

        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
            phoneNumber: new FormControl(this.model.phoneNumber, Validators.compose([Validators.required, PhoneReg()]))
        });
    }
}
