import {BaseFormActionProxy} from "../../../core/shared/base/base.action-proxy";
import {IWebFormComponent} from "../../../core/shared/base/base.component";
import {Store} from "@ngrx/store";
import * as store from "../../../core/app-store";
import {NgxSpinnerService} from "ngx-spinner";
import {CarService_Dto} from "../../../core/app-dto/car-service.dto";
import {Observable} from "rxjs";
import {RegisterOfferEvent} from "../../../core/app-store/events/offer.events";

export class RegisterOfferActionProxy extends BaseFormActionProxy {
    constructor(component: IWebFormComponent,
                appState: Store<store.State>,
                private spinner: NgxSpinnerService) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(callback: Function): any {
        if (this.canExecute()) {
            this.component.markFormAsNotDirty();
            const model = this.component.di.getModel();
            this.spinner.show('calendar');
            this.appState.dispatch(new RegisterOfferEvent(model, (data: CarService_Dto) => {
                this.spinner.hide('calendar');
                callback(data);
            }));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}
