import { Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import { Do_GenericSuccessEvent, Do_GenericErrorEvent, EventWithScreenLoading, SeamlessEvent } from 'src/app/core/app-store/events/base.events';
import { DocumentsApiService } from 'src/app/core/api-integration/services/documents-api.service';
import { FileAttachment } from 'src/app/core/app-dto/core.dto';
import { GetDocumentsByEntityAndTypeEvent, DeleteDocumentEvent, GenerateDevizEvent, GenerateGDPREvent, GenerateSHEvent, GeneratePVEvent, GenerateICEvent, GetDocumentsByEntityAndTypeAnnonymusEvent } from 'src/app/core/app-store/events/documents-events';
import {FileDictionary} from "../../core/app-store/stores/documents.store";


@Injectable()
export class DocumentsUIEventProcessor {

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private documentsApiService: DocumentsApiService
    ) {
    }

    @Effect()
    doGetDocumentsEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetDocumentsByEntityAndTypeEvent.identifier, SeamlessEvent.identifier + GetDocumentsByEntityAndTypeEvent.identifier),
            map((action: GetDocumentsByEntityAndTypeEvent) => action),
          mergeMap((action: GetDocumentsByEntityAndTypeEvent) => {
                return new Observable((observer) => {
                    this.documentsApiService.u_getDocuments(action.target, action.relatedEntityId)
                        .subscribe((res: Array<FileAttachment>) => {
                            const dictionary = new FileDictionary();
                            dictionary.documents = res;
                            dictionary.id = action.relatedEntityId;
                            dictionary.type = action.target;
                            observer.next(new Do_GenericSuccessEvent<FileDictionary>(dictionary, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doGetDocumentsByEntityAndTypeAnnonymusEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetDocumentsByEntityAndTypeAnnonymusEvent.identifier, SeamlessEvent.identifier + GetDocumentsByEntityAndTypeAnnonymusEvent.identifier),
            map((action: GetDocumentsByEntityAndTypeAnnonymusEvent) => action),
          mergeMap((action: GetDocumentsByEntityAndTypeAnnonymusEvent) => {
                return new Observable((observer) => {
                    this.documentsApiService.u_getDocumentsAnnonymus(action.target, action.relatedEntityId)
                        .subscribe((res: Array<FileAttachment>) => {
                            const dictionary = new FileDictionary();
                            dictionary.documents = res;
                            dictionary.id = action.relatedEntityId;
                            dictionary.type = action.target;
                            observer.next(new Do_GenericSuccessEvent<FileDictionary>(dictionary, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doDeleteDocumentEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(DeleteDocumentEvent.identifier, EventWithScreenLoading.identifier + DeleteDocumentEvent.identifier),
            map((action: DeleteDocumentEvent) => action),
            mergeMap((action: DeleteDocumentEvent) => {
                return new Observable(observer => {
                    this.documentsApiService.u_deleteDocument(action.id)
                        .subscribe((res: boolean) => {
                          const dictionary = new FileDictionary();
                          dictionary.documents = [];
                          dictionary.documents.push(new FileAttachment({id:action.id}))
                          dictionary.id = action.relatedEntityId;
                          dictionary.type = action.target;
                            observer.next(new Do_GenericSuccessEvent<FileDictionary>(dictionary, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doGenerateDevizEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GenerateDevizEvent.identifier, EventWithScreenLoading.identifier + GenerateDevizEvent.identifier),
            map((action: GenerateDevizEvent) => action),
            switchMap((action: GenerateDevizEvent) => {
                return new Observable((observer) => {
                    this.documentsApiService.u_generateDeviz(action.id)
                        .subscribe((res: FileAttachment) => {
                            observer.next(new Do_GenericSuccessEvent<FileAttachment>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doGenerateGDPREvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GenerateGDPREvent.identifier, EventWithScreenLoading.identifier + GenerateGDPREvent.identifier),
            map((action: GenerateGDPREvent) => action),
            switchMap((action: GenerateGDPREvent) => {
                return new Observable((observer) => {
                    this.documentsApiService.u_generateGDPR(action.id)
                        .subscribe((res: FileAttachment) => {
                            observer.next(new Do_GenericSuccessEvent<FileAttachment>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doGenerateSHEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GenerateSHEvent.identifier, EventWithScreenLoading.identifier + GenerateSHEvent.identifier),
            map((action: GenerateSHEvent) => action),
            switchMap((action: GenerateSHEvent) => {
                return new Observable((observer) => {
                    this.documentsApiService.u_generateSH(action.id)
                        .subscribe((res: FileAttachment) => {
                            observer.next(new Do_GenericSuccessEvent<FileAttachment>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doGeneratePVEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GeneratePVEvent.identifier, EventWithScreenLoading.identifier + GeneratePVEvent.identifier),
            map((action: GeneratePVEvent) => action),
            switchMap((action: GeneratePVEvent) => {
                return new Observable((observer) => {
                    this.documentsApiService.u_generatePV(action.id)
                        .subscribe((res: FileAttachment) => {
                            observer.next(new Do_GenericSuccessEvent<FileAttachment>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doGenerateICEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GenerateICEvent.identifier, EventWithScreenLoading.identifier + GenerateICEvent.identifier),
            map((action: GenerateICEvent) => action),
            switchMap((action: GenerateICEvent) => {
                return new Observable((observer) => {
                    this.documentsApiService.u_generateIC(action.id)
                        .subscribe((res: FileAttachment) => {
                            observer.next(new Do_GenericSuccessEvent<FileAttachment>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );
}
