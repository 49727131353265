import {Injectable} from "@angular/core";
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpResponse
} from "@angular/common/http";
import 'rxjs/add/operator/catch';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ApiErrorDto} from "./api-error.dto";
import {Router} from "@angular/router";
import {CachingUtils} from "../../shared/caching.utils";
import { LoggedUser_Dto } from '../../app-dto/core.dto';
import {ObjectValidators} from "../../shared/object.validators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user: any = CachingUtils.loadData(LoggedUser_Dto.identifier);


    if (request.url.indexOf('/token') == -1 && request.url.indexOf('/reset-password') == -1 &&
      request.url.indexOf('forgot-password') == -1 && request.url.indexOf('organization/info') == -1) {
      if (ObjectValidators.isValidObject(user)) {
        request = request.clone({headers: request.headers.set('Authorization', 'Bearer ' + user.token)});
      }
      if (!request.headers.has('Content-Type')) {
        request = request.clone({headers: request.headers.set('Content-Type', 'application/json')});
      }
    }


    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorDto: ApiErrorDto = new ApiErrorDto('', null);
        if (error.status === 401) {
          CachingUtils.remove(LoggedUser_Dto.identifier);
          this.router.navigate(['/auth/login'], { queryParams: { reason: '401' } });
          errorDto.message = 'Sesiunea a expirat';
          return throwError(errorDto);
        }

        if (error.error instanceof Error) {
          console.error('An error occurred, please contact support! Error is: ', error.error.message);
          errorDto.message = error.error.message;
          errorDto.error = error.error;
        } else if (typeof (error.error) === 'string') {
          console.error(`Server error - backend returned code ${error.status}, message is: ${error.error}`);
          errorDto.message = error.error;
          errorDto.error = error;
        } else {
          console.error(`Server error - backend returned code ${error.status}, body was: ${JSON.stringify(error)}`);
          if (error.error != null && error.error.Messages != null && error.error.Messages.length > 0) {
            errorDto.message = error.error.Messages[0];
          } else if (error.error != null && error.error.message) {
            errorDto.message = error.error.message;
          } else
          if (error.error != null && error.error.error_description != null) {
            errorDto.message = error.error.error_description;
          } else {
            errorDto.message = 'An error has occurred. Please contact support!';
          }
          errorDto.error = error;
        }

        return throwError(errorDto);
      }));

  }
}

export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
