import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BaseFormDI} from "../../../../core/shared/base/base.di";
import {Offer_Dto} from "../../../../core/app-dto/offer.dto";
import {StaticDataSelectorInput} from "../../../../core/shared/components/static-data-selector/static-data-selector.component";
import {isValidNumber, isValidObject, isValidString} from "../../../../core/shared/helpers/common.helpers";

export class AddOfferThirdStepDi extends BaseFormDI<Offer_Dto> {
    public estimatedDeadlineTypesInput: StaticDataSelectorInput;
    public shouldUseEstimatedDeadlineTypeSelection: boolean = false;

    constructor(data: Offer_Dto = new Offer_Dto(null), public forOffer: boolean = false) {
        super(new Offer_Dto(data));
        this.initForm();
        this.estimatedDeadlineTypesInput = {
            form: this.form,
            type: 'estimatedDeadlineTypes',
            formControlName: 'estimatedDeadlineType',
            isRequired: true,
            label: null,
            isReadOnly:false
        };
        if (isValidString(this.model.offerEntryData.deadline) == false || isValidObject(this.model.offerEntryData.estimatedDeadlineType)) {
            this.shouldUseEstimatedDeadlineTypeSelection = true;
            this.form.addControl("deadlineNumber", new FormControl(this.model.offerEntryData.deadlineNumber, []));
        } else {
            this.form.addControl("deadline", new FormControl(this.model.offerEntryData.deadline, [Validators.required]));
        }
    }

    getModel(): Offer_Dto {
        if (this.shouldUseEstimatedDeadlineTypeSelection == false)
            this.model.offerEntryData.deadline = this.form.value.deadline;
        else {
            this.model.offerEntryData.deadlineNumber = this.form.value.deadlineNumber;
            this.model.offerEntryData.estimatedDeadlineType = this.form.value.estimatedDeadlineType != null ? this.form.value.estimatedDeadlineType : null;
            if (isValidNumber(this.model.offerEntryData.deadlineNumber))
                this.model.offerEntryData.deadline = this.model.offerEntryData.deadlineNumber + ' ' + (this.model.offerEntryData.estimatedDeadlineType != null ? this.model.offerEntryData.estimatedDeadlineType.name : '');
        }
        return this.model;
    }

    initForm() {
        this.form = new FormGroup({

        });
    }
}
