import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as coreStore from '../../app-store/state-selectors/core.selectors';
import * as ApplicationStore from '../../app-store/state';
import { Observable } from "rxjs";
import { LoggedUser_Dto } from "../../app-dto/core.dto";
import { CachingUtils } from "../../shared/caching.utils";
import {isValidString} from "../../shared/helpers/common.helpers";
import {AuthenticationUIEventProcessor} from '../../auth/event-processor/ui-event.processor';

@Injectable()
export class AuthGuard implements CanActivate {

  private currentLoggedUser$: Observable<any> = this.appState$.pipe(select(coreStore.getLoggedUser));
  private currentToken: LoggedUser_Dto;

  constructor(private appState$: Store<ApplicationStore.State>, private router: Router, private authenticationUIEventProcessor: AuthenticationUIEventProcessor) {
    this.currentLoggedUser$.subscribe((user: LoggedUser_Dto) => {
      this.currentToken = user;
      if (user == null || (isValidString(user.token))) {
        const stToken = CachingUtils.loadData(LoggedUser_Dto.identifier);
        if (stToken != null) {
          this.currentToken = new LoggedUser_Dto(stToken);
        }
      }
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.authenticationUIEventProcessor.redirectUrl = state.url;
    if (this.currentToken == null || (this.currentToken.token != null && isValidString(this.currentToken.token) == false)) {
      this.router.navigate(['/auth/login']);
      return false;
    }
    return true;
  }
}
