import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { AddCarInServiceEvent } from 'src/app/core/app-store/events/car-service.events';
import { CarService_Dto } from 'src/app/core/app-dto/car-service.dto';

export class AddCarInServiceActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
        appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(model: CarService_Dto, eventId:string, callback: Function): any {
        if (this.canExecute()) {
            this.appState.dispatch(new AddCarInServiceEvent(model, eventId, (data: CarService_Dto) => {
                callback(data);
            }));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

