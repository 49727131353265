import {EventWithScreenLoading, SeamlessEvent, UIGeneratedEvent,} from './base.events';
import {ActiveDataTableParameters} from '../../shared/datatable/datatable.helpers';
import {Mechanic_Dto, RequestAccountForMechanic_Dto} from '../../app-dto/mechanic.dto';
import {CalendarEventRelatedEntityType} from "../../app-dto/calendar-event.dto";

export class GetMechanicsPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetMechanicsPagedResultEvent";

  constructor(public parameters: ActiveDataTableParameters, public withReset: boolean = false) {
    super();
    this.type += GetMechanicsPagedResultEvent.identifier;
  }
}

export class GetMechanicListEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetMechanicListEvent";

  constructor() {
    super();
    this.type += GetMechanicListEvent.identifier;
  }
}

export class RegisterMechanicEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "RegisterMechanicEvent";

  constructor(public model: Mechanic_Dto) {
    super();
    this.type += RegisterMechanicEvent.identifier;
  }
}

export class GetMechanicEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetMechanicEvent";

  constructor(public id: string) {
    super();
    this.type += GetMechanicEvent.identifier;
  }
}


export class EditMechanicEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "EditMechanicEvent";

  constructor(public model: Mechanic_Dto, public updatedHistory: boolean) {
    super();
    this.type += EditMechanicEvent.identifier;
  }
}


export class DeleteMechanicEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeleteMechanicEvent";

  constructor(public id: string, public callback: Function = null) {
    super();
    this.type += DeleteMechanicEvent.identifier;
  }
}

export class DeleteAccountForMechanicEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeleteAccountForMechanicEvent";

  constructor(public id: string, public callback: Function = null) {
    super();
    this.type += DeleteAccountForMechanicEvent.identifier;
  }
}

export class ActivateMechanicEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "ActivateMechanicEvent";

  constructor(public id: string, public startDate: Date) {
    super();
    this.type += ActivateMechanicEvent.identifier;
  }
}

export class DeactivateMechanicEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeactivateMechanicEvent";

  constructor(public id: string, public endDate: Date) {
    super();
    this.type += DeactivateMechanicEvent.identifier;
  }
}


export class AddUserAccountForMechanicEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "AddUserAccountForMechanicEvent";

  constructor(public model: RequestAccountForMechanic_Dto) {
    super();
    this.type += AddUserAccountForMechanicEvent.identifier;
  }
}

export class GetMechanicsWithStatusEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetMechanicsWithStatusEvent";
  constructor(public callback:Function) {
    super();
    this.type += GetMechanicsWithStatusEvent.identifier;
  }
}

export class GetMechanicAvailabilityEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetMechanicAvailabilityEvent";

  constructor(public startDate: Date, public endDate: Date, public mechanicId: string = null, public except: string = null, public relatedEntityType:CalendarEventRelatedEntityType, public callback: (data: boolean) => void) {
    super();
    this.type += GetMechanicAvailabilityEvent.identifier;
  }
}
