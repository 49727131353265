import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseFormModelComponent} from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import {Store} from '@ngrx/store';
import {ActivatedRoute} from '@angular/router';
import {SaveSupplierModalSandbox} from './save-supplier-modal.sandbox';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap';
import {SaveSupplierModalDi} from './save-supplier-modal.di';
import {Supplier_Dto} from '../../../core/app-dto/supplier.dto';

@Component({
    selector: 'app-save-supplier-modal',
    templateUrl: './save-supplier-modal.component.html',
    styleUrls: ['./save-supplier-modal.component.scss']
})
export class SaveSupplierModalComponent extends BaseFormModelComponent<Supplier_Dto> implements OnInit, OnDestroy {
    public title: string;
    public onClose: Subject<any>;
    public data: Supplier_Dto = null;
    public di: SaveSupplierModalDi;

    constructor(public appState: Store<store.State>,
                public sandbox: SaveSupplierModalSandbox,
                private activeModal: BsModalRef,
                public activatedRoute: ActivatedRoute) {
        super(sandbox, null);
    }

    public diInitialize(data: Supplier_Dto) {
        this.di = new SaveSupplierModalDi(data);
        this.data = data;
        this.title = data && data.id ? 'Editare furnizor' : 'Adauga furnizor';
        this.sandbox.initialize();
        this.dataLoaded = true;
    }

    ngOnInit() {
        this.onClose = new Subject();
    }

    ngOnDestroy() {
        this.destroy();
    }

    public decline() {
        this.onClose.next(false);
        this.activeModal.hide();
    }

    public accept() {
        this.formSubmitted = true;
        this.markFormAsDirtyAndTouched();
        if (this.di.isValid()) {
            this.onClose.next(this.di.getModel());
            this.activeModal.hide();
        }
    }
}
