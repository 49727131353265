import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {BaseListWebComponentWithSearchAndActiveBehavior} from '../../../core/shared/base/base.component';
import {IdName_Dto} from '../../../core/app-dto/misc.dto';
import {ActionsSubject, Store} from '@ngrx/store';
import {ConfigService} from '../../../core/core/app-config.service';
import {Router} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap';
import {ConfirmationDialogService} from '../../../core/core/dialog/confirmation-dialog.service';
import {AppUserBusinessRuleProvider} from '../../../core/core/business-rule-provider/app-user.business-rule.provider';
import {ResetSection} from '../../../core/app-store/events/base.events';

import {
    DataTableParametersOrderEntity
} from '../../../core/shared/datatable/datatable.helpers';

import {GetOffersActionProxy} from './get-offers.action-proxy';
import {DeleteOfferActionProxy} from '../../common/ap/delete-offer.action-proxy';
import {ListOfferEntriesSandbox} from './list-offer-entries.sandbox';

import {ListOfferEntryItemDi} from './list-offer-entry-item.di';
import {OfferDataTableParameters} from '../../../core/app-store/events/offer.events';
import {AddOfferWizardComponent} from "../add-offer-wizard/add-offer-controller/add-offer-wizard.component";
import {Offer_Dto} from "../../../core/app-dto/offer.dto";

@Component({
    selector: 'app-list-offer-entries',
    templateUrl: './list-offer-entries.component.html',
    styleUrls: ['./list-offer-entries.component.scss']
})
export class ListOfferEntriesComponent extends BaseListWebComponentWithSearchAndActiveBehavior implements OnInit, AfterViewInit {
    public getDataAP: GetOffersActionProxy;
    public deleteDataAP: DeleteOfferActionProxy;
    public columns: any = [];
    public sortColumns: Array<IdName_Dto> = [];
    public currentPage = 1;

    constructor(
        public sandbox: ListOfferEntriesSandbox,
        public rulesProvider: AppUserBusinessRuleProvider,
        actionsSubj: ActionsSubject,
        private configService: ConfigService,
        public router: Router, public confirmationDialogService: ConfirmationDialogService,
        private modalService: BsModalService
    ) {
        super(sandbox, actionsSubj, ResetSection.Departments);
        this.getDataAP = new GetOffersActionProxy(this, sandbox.appState);
        this.deleteDataAP = new DeleteOfferActionProxy(this, sandbox.appState);
        this.columns = [
            {data: 'title', name: 'Nume', width: '30%'},
            {data: 'type', name: 'Tip', width: '30%'},
            {data: 'status', name: 'Status', width: '30%'},
            {data: 'createdOn', name: 'Data Inregistrarii', width: '150px'},
            {data: 'action', width: '50px'},
        ];
        this.sortColumns = this.columns.filter(c => c.name).map(c => ({id: c.data, name: c.name} as IdName_Dto));
    }

    ngAfterViewInit() {
        this.setBreadcrumbAP.execute([{text: 'Acasa', url: '/dashboard'}, {text: 'Oferte'}]);
    }

    ngOnInit() {
        this.initialize();
        const sortIndex = this.columns.indexOf(this.columns.find(f => f.data === 'createdOn'));
        this.dtOptions = {
            responsive: true,
            paging: false,
            info: false,
            lengthChange: false,
            searching: false,
            pageLength: this.configService.getDefaultPageSize(),
            scrollX: false,
            serverSide: true,
            processing: false,
            ordering: false,
            autoWidth: false,
            ajax: (dataTablesParameters: OfferDataTableParameters, callback) => {
                dataTablesParameters.search.value = this.searchValue;
                dataTablesParameters.status = '';//@todo : add filter by status
                this.doGetAll(new OfferDataTableParameters(dataTablesParameters), callback, true);
            },
            columns: this.columns,
            order: [[sortIndex, 'desc']],
            dom: '<\'row mx-1\'<\'col-sm-12 col-md-6 px-3\'l><\'col-sm-12 col-md-6 px-3\'f>>' + '<\'table-responsive\'tr>' + '<\'row mx-1 align-items-center justify-content-center justify-content-md-between\'<\'col-auto mb-2 mb-sm-0\'i><\'col-auto\'p>>'
        };
    }

    public doGetAll(dataTablesParameters: OfferDataTableParameters, callback: any, withReset: boolean) {
        this.sandbox.callback = callback;
        this.sandbox.dataTableParameters = dataTablesParameters;
        if (withReset) {
            setTimeout(() => {this.currentPage = 1; }, 0);
            this.sandbox.dataTableParameters.pageNumber = 1;
            dataTablesParameters.pageNumber = 1;
        }
        this.getDataAP.execute(dataTablesParameters, withReset);
    }

    createOffer() {
        this.isActive = false;
        const initialState = {
            carInService: new Offer_Dto(null),
            title: 'Adauga Oferta'
        };
        const modalRef = this.modalService.show(AddOfferWizardComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
            class: 'modal-xl'
        });
    }

    deleteOffer(data: ListOfferEntryItemDi) {
        this.confirmationDialogService.confirm(
            'Stergere oferta',
            'Urmeaza sa stergeti oferta <b>' + data.model.title + '</b>. Sunteti sigur?',
            'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
            if (confirmed) {
                this.deleteDataAP.execute(data.model.id, () => {
                    //do nothing - the list will be refreshed automatically
                });
            }
        });
    }

    onSortChange($event) {
        if ($event) {
            const columnId = this.columns.map((e) => e.data).indexOf($event.id);
            const orderEntity = new DataTableParametersOrderEntity({column: columnId, dir: 'asc'});
            this.sandbox.dataTableParameters.order = [orderEntity];
        }
        this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, true);
    }

    onPageChange(currentPage: number) {
        this.currentPage = currentPage;
        this.sandbox.dataTableParameters.pageNumber = currentPage;
        this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
    }
}
