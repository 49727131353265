import { Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import { map, switchMap } from 'rxjs/operators';
import {
  Do_GenericSuccessEvent,
  Do_GenericErrorEvent,
  EventWithScreenLoading,
  SeamlessEvent
} from 'src/app/core/app-store/events/base.events';
import { PagedResult } from 'src/app/core/shared/datatable/datatable.helpers';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { ServicePlatformStatusApiService } from '../../core/api-integration/services/service-platform-status-api.service';
import { GetServicePlatformStatusesEvent } from '../../core/app-store/events/service-platform-status.events';
import { ServicePlatformStatus_Dto } from '../../core/app-dto/service-platform-status.dto';
import {GetServicePlatformsListEvent} from "../../core/app-store/events/service-platform.events";
import {ServicePlatform_Dto} from "../../core/app-dto/service-platform.dto";
import {isValidObject} from "../../core/shared/helpers/common.helpers";


@Injectable()
export class ServicePlatformStatusUIEventProcessor {

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private servicePlatformStatusApiService: ServicePlatformStatusApiService
    ) {
    }

  @Effect()
  getMechanicList$: Observable<any> = this.actions$
    .pipe(
      ofType(GetServicePlatformStatusesEvent.identifier, SeamlessEvent.identifier + GetServicePlatformStatusesEvent.identifier),
      map((action: GetServicePlatformStatusesEvent) => action),
      switchMap((action: GetServicePlatformStatusesEvent) => {
        return new Observable((observer) => {
          this.servicePlatformStatusApiService.u_getFilteredServicePlatformStatusesPaginated()
            .subscribe((res: Array<ServicePlatform_Dto>) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<Array<ServicePlatform_Dto>>(res, action));
              }
              if(isValidObject(action.callback)) action.callback();
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );
}
