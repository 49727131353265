import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { DeleteOrganizationClientEvent } from '../../../core/app-store/events/organization-client.events';

export class DeleteOrganizationClientActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent, appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(organizationClientId: string,callback:Function = null): any {
        if (this.canExecute()) {
            this.appState.dispatch(new DeleteOrganizationClientEvent(organizationClientId,callback));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

