import { Observable, Subscriber } from 'rxjs';
import { ApiErrorDto } from '../api-integration/utils/api-error.dto';

export class ErrorHelpers {
  public static throwError(err: ApiErrorDto) {
    return Observable.create((observer: Subscriber<any>) => {
      observer.error(err.message);
      observer.complete();
    });
  }
}
