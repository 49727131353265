import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseFormComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { ActivatedRoute, Params } from '@angular/router';
import { AddEditCalendarEventSandbox } from './add-edit-calendar-event.sandbox';
import { AddEditCalendarEventDI } from './add-edit-calendar-event.di';
import { BsModalRef } from 'ngx-bootstrap';
import { CalendarEvent_Dto, CalendarEventType } from 'src/app/core/app-dto/calendar-event.dto';
import { RegisterCalendarEventActionProxy } from './register-calendar-event.action-proxy';
import { EditCalendarEventActionProxy } from './edit-calendar-event.action-proxy';
import { DateTimeService } from 'src/app/core/core/services/date-time.service';
import { IdName_Dto } from 'src/app/core/app-dto/misc.dto';
import { NgxSpinnerService } from 'ngx-spinner';
import {InvoiceDI} from "../../../invoice/components/invoice/invoice.di";


@Component({
  selector: 'app-add-edit-calendar-event',
  templateUrl: './add-edit-calendar-event.component.html',
  styleUrls: ['./add-edit-calendar-event.component.scss']
})
export class AddEditCalendarEventComponent extends BaseFormComponent implements OnInit, OnDestroy {
  public registerCalendarEventAP: RegisterCalendarEventActionProxy;
  public editCalendarEventAP: EditCalendarEventActionProxy;

  public onClose: Subject<boolean>;

  public event: CalendarEvent_Dto;
  public title: string = "";
  public calendarEventTypes: Array<IdName_Dto> = [];

  public di: AddEditCalendarEventDI;//we need to overwrite di with specific type for production build

  constructor(
    public appState: Store<store.State>,
    public sandbox: AddEditCalendarEventSandbox,
    public activatedRoute: ActivatedRoute,
    private activeModal: BsModalRef,
    public dateTimeService: DateTimeService,
    private spinner: NgxSpinnerService
  ) {
    super(sandbox, ResetSection.SaveServicePlatform);
    this.registerCalendarEventAP = new RegisterCalendarEventActionProxy(this, sandbox.appState, spinner);
    this.editCalendarEventAP = new EditCalendarEventActionProxy(this, sandbox.appState, spinner);
    this.di = new AddEditCalendarEventDI(this.dateTimeService);

    for (let i = 0; i < 3; i++)
      this.calendarEventTypes.push(new IdName_Dto({ id: i + '', name: CalendarEventType[i] }));

  }

  ngOnInit() {
    if (this.event)
      this.di = new AddEditCalendarEventDI(this.dateTimeService, this.event);

    this.onClose = new Subject();
    this.dataLoaded = true;
    this.initialize();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public saveForm() {
    this.formSubmitted = true;
    if (this.di.model.id) {

    } else {

    }
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.formSubmitted = true;
    if (this.di.model.id) {
      if (this.editCalendarEventAP.canExecute()) {
        this.editCalendarEventAP.execute();
        this.onClose.next(true);
        this.activeModal.hide();
      }
    } else {
      if (this.registerCalendarEventAP.canExecute()) {
        this.registerCalendarEventAP.execute();
        this.onClose.next(true);
        this.activeModal.hide();
      }
    }
  }
}
