import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { DeactivateMechanicSandbox } from './deactivate-mechanic.sandbox';
import { DeactivateMechanicActionProxy } from './deactivate-mechanic.action-proxy';
import { DeactivateMechanicDi } from './deactivate-mechanic.di';
import { Mechanic_Dto } from 'src/app/core/app-dto/mechanic.dto';

@Component({
  selector: 'app-deactivate-mechanic',
  templateUrl: './deactivate-mechanic.component.html',
  styleUrls: ['./deactivate-mechanic.component.scss']
})
export class DeactivateMechanicComponent extends BaseFormModelComponent<Mechanic_Dto> implements OnInit, OnDestroy {
  public deactivateMechanicAP: DeactivateMechanicActionProxy;
  public onClose: Subject<boolean>;

  constructor(public appState: Store<store.State>,
    public sandbox: DeactivateMechanicSandbox,
    private activeModal: BsModalRef,
    public activatedRoute: ActivatedRoute) {
    super(sandbox, null);
    this.deactivateMechanicAP = new DeactivateMechanicActionProxy(this, sandbox.appState);
  }

  public diInitialize(data: Mechanic_Dto) {
    this.di = new DeactivateMechanicDi(data);
    this.dataLoaded = true;
  }

  ngOnInit() {
    
    this.onClose = new Subject();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();
    if (this.di.isValid()) {
      let model: Mechanic_Dto = this.di.getModel();
      let endDate: Date = (<DeactivateMechanicDi>this.di).getDate();
      this.deactivateMechanicAP.execute(model.id, endDate);
      this.onClose.next(true);
      this.activeModal.hide();
    }
  }
}
