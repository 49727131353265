import {BaseActionProxy} from "../../shared/base/base.action-proxy";
import {IWebComponent} from "../../shared/base/base.component";
import {Store} from "@ngrx/store";
import * as store from "../../app-store";
import {CreateCardSetupSession} from "../dto/stripe.dto";
import {Observable} from "rxjs";
import {CreateCardSetupEvent} from "../events/stripe.events";

export class CreateCardSetupActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent, appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(data:CreateCardSetupSession, callback:(data:string) => void): any {
        if (this.canExecute()) {
            this.appState.dispatch(new CreateCardSetupEvent(data,callback));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

