import {Injectable} from "@angular/core";
import {BaseSandboxWithCurrentUserLoaded} from "../../../../core/shared/base/base.sandbox";
import {Organization} from "../../../../core/app-dto/organization.dto";
import {Store} from "@ngrx/store";
import * as store from "../../../../core/app-store";
import {Do_ResetStateForSection, ResetSection} from "../../../../core/app-store/events/base.events";

@Injectable()
export class AddOfferClientSandbox extends BaseSandboxWithCurrentUserLoaded {
    public organization: Organization;

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
    }

    public initialize(): any {
        this.clearSandboxState();
        this.loadUser(() => {
            this.organization = this.currentUser.organization;
        });
    }

    public resetCarForClient() {
        this.appState$.dispatch(new Do_ResetStateForSection(ResetSection.CarForClient));
    }
}
