import {Store} from '@ngrx/store';
import * as store from '../../../app-store/index';
import {Observable} from 'rxjs';
import {IWebComponent} from 'src/app/core/shared/base/base.component';
import {BaseActionProxy} from 'src/app/core/shared/base/base.action-proxy';
import {GetNoOfUnreadNotificationsEvent} from "../../../app-store/events/notification.events";
import {AppNotification} from "../../../app-dto/core.dto";

export class GetUnreadNotificationsCountActionProxy extends BaseActionProxy {
  constructor(component: IWebComponent,
              appState: Store<store.State>) {
    super(component, appState);
  }

  canExecute(): boolean {
    return true;
  }

  execute(): any {
    this.appState.dispatch(new GetNoOfUnreadNotificationsEvent());
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }
}

