import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import {BaseSandbox, BaseSandboxWithCurrentUserLoaded} from 'src/app/core/shared/base/base.sandbox';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';

@Injectable()
export class CarServiceStatisticsSandbox extends BaseSandboxWithCurrentUserLoaded {

  constructor(protected appState$: Store<store.State>) {
    super(appState$);
  }

}
