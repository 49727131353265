import {BaseSelectWrapperDI} from '../../../core/shared/base/base.di';
import {Receipt_Dto} from '../../../core/app-dto/receipt.dto';

export class ReceiptListItemDi extends BaseSelectWrapperDI<Receipt_Dto> {
    constructor(data: Receipt_Dto) {
        super(new Receipt_Dto(data));
    }
    getModel(): Receipt_Dto {
        return this.model;
    }
}
