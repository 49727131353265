import { Store } from "@ngrx/store";
import * as store from "../../../core/app-store";
import { Observable } from "rxjs";
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { InvoiceDataTableParameters } from 'src/app/core/shared/datatable/datatable.helpers';
import {
  GetOrganizationSubscriptionInvoicesPagedResultEvent
} from 'src/app/core/app-store/events/invoices.events';

export class GetSubInvoicesForOrganizationActionProxy extends BaseActionProxy {
  constructor(component: IWebComponent, appState: Store<store.State>) {
    super(component, appState);
  }

  canExecute(): boolean {
    return true;
  }

  execute(dataTablesParameters: InvoiceDataTableParameters, withReset: boolean, organizationId: string = null): any {
    if (this.canExecute()) {
      this.appState.dispatch(new GetOrganizationSubscriptionInvoicesPagedResultEvent(dataTablesParameters, withReset, organizationId));
    }
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }
}

