import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import { CarServiceDetailsWorkmanshipsDi } from './car-service-details-workmanships.di';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { CarServiceDetailsWorkmanshipsSandbox } from './car-service-details-workmanships.sandbox';
import { BsModalService } from 'ngx-bootstrap';
import { CarSerivceDetailsDeleteWorkmanshipActionProxy } from './car-service-details-delete-workmanship.action-proxy';
import { Workmanship_Dto } from '../../../core/app-dto/workmanship.dto';
import { CarServiceSaveWorkmanshipComponent } from '../car-service-save-workmanship/car-service-save-workmanship.component';

@Component({
  selector: 'app-car-service-details-workmanships',
  templateUrl: './car-service-details-workmanships.component.html',
  styleUrls: ['./car-service-details-workmanships.component.scss']
})
export class CarServiceDetailsWorkmanshipsComponent extends BaseFormModelComponent<Array<Workmanship_Dto>> implements OnInit, OnDestroy {
  @Input() carServiceId: string;

  public carServiceDetailsDeleteWorkmanshipAP: CarSerivceDetailsDeleteWorkmanshipActionProxy;

  constructor(public appState: Store<store.State>,
              private modalService: BsModalService,
              public sandbox: CarServiceDetailsWorkmanshipsSandbox) {
    super(sandbox);
    this.carServiceDetailsDeleteWorkmanshipAP = new CarSerivceDetailsDeleteWorkmanshipActionProxy(this, sandbox.appState);
  }

  public diInitialize(data: Array<Workmanship_Dto>) {
    this.di = new CarServiceDetailsWorkmanshipsDi(data);
    this.dataLoaded = true;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy();
  }

  public addWorkmanship() {
    const modalRef = this.modalService.show(
        CarServiceSaveWorkmanshipComponent,
        { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = {
      carServiceId: this.carServiceId,
      workmanship: null
    };
  }

  public updateWorkmanship(workmanship: Workmanship_Dto) {
    const modalRef = this.modalService.show(
        CarServiceSaveWorkmanshipComponent,
        { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = {
      carServiceId: this.carServiceId,
      workmanship
    };
  }

  delete(id: string) {
    let i = (<CarServiceDetailsWorkmanshipsDi>this.di).model.findIndex(r => r.id === id);
    (<CarServiceDetailsWorkmanshipsDi>this.di).model.splice(i, 1);

    // replace with faster .filter function

    if (this.carServiceDetailsDeleteWorkmanshipAP.canExecute()) {
      this.carServiceDetailsDeleteWorkmanshipAP.execute(this.carServiceId, id);
    }
  }

  isValid() {
    return this.di.isValid();
  }
}
