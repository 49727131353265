import {Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, Input} from '@angular/core';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import { CarServiceClientSandbox } from './car-service-client.sandbox';
import { CarServiceClientDi } from './car-service-client.di';
import { OrganizationClient_Dto } from 'src/app/core/app-dto/organization-client.dto';
import { OrganizationClientSearchComponent } from 'src/app/organization-client/components/organization-client-search/organization-client-search.component';
import {isValidObject, isValidString} from '../../../core/shared/helpers/common.helpers';
import { Address } from '../../../core/app-dto/core.dto';
import { GetCarForClientActionProxy } from './get-car-for-client.action-proxy';
import { ResetSection } from '../../../core/app-store/events/base.events';
import { Organization } from '../../../core/app-dto/organization.dto';
import {
  GetOrganizationClientFromAnafActionProxy
} from '../../../organization-client/components/organization-client-form/get-organization-client-from-anaf.action-proxy';
import {BaseLocationEntity} from '../../../core/app-dto/misc.dto';
import {ResetInfoFromCuiEvent} from '../../../core/app-store/events/organization-client.events';
import {AppUserBusinessRuleProvider} from '../../../core/core/business-rule-provider/app-user.business-rule.provider';
import {AppointmentClientDi} from '../../../calendar/components/appointment-client/appointment-client.di';
import {AppointmentClient_Dto} from '../../../core/app-dto/appointment-client.dto';


@Component({
  selector: 'app-car-service-client',
  templateUrl: './car-service-client.component.html',
  styleUrls: ['./car-service-client.component.scss']
})
export class CarServiceClientComponent extends BaseFormModelComponent<OrganizationClient_Dto> implements OnInit, OnDestroy {
  public getCarForClientAP: GetCarForClientActionProxy;
  public clientSearch: string = '';
  public di: CarServiceClientDi;
  public getOrganizationClientFromAnafAP: GetOrganizationClientFromAnafActionProxy;
  public anafCompany: OrganizationClient_Dto;

  @Output()
  modelSaved = new EventEmitter<OrganizationClient_Dto>();
  @Output()
  eventSelectedOutput = new EventEmitter<string>();

  private isFromAppointmentValue: boolean = false;
  @Input('isFromAppointment') set isFromAppointment(value: boolean) {
    this.isFromAppointmentValue = value;
  }

  @ViewChild(OrganizationClientSearchComponent, { static: false }) organizationClientSearchComponent: OrganizationClientSearchComponent;

  public  willClientAcceptNotifications: boolean = true;

  constructor(public appState: Store<store.State>,
              public sandbox: CarServiceClientSandbox,
              public rulesProvider: AppUserBusinessRuleProvider) {
    super(sandbox, ResetSection.CarForClient);
    this.getCarForClientAP = new GetCarForClientActionProxy(this, sandbox.appState);
    this.getOrganizationClientFromAnafAP = new GetOrganizationClientFromAnafActionProxy(this, appState);
  }

  public diInitialize(data: OrganizationClient_Dto) {
    this.sandbox.initialize(
        (client) => {
        },
        () => this.whenUserIsLoaded(data)
    );
    this.sandbox.appState.dispatch(new ResetInfoFromCuiEvent());
  }

  private whenUserIsLoaded(data: OrganizationClient_Dto) {
    if (isValidObject(data) === false) {
      data = new OrganizationClient_Dto(null);
    }
    if (isValidString(data.id) === false && isValidObject(this.sandbox.currentUser.organization)) {
      data.address = new Address(null);
      if (isValidObject(this.sandbox.currentUser.organization.address)) {
        data.address.city = this.sandbox.currentUser.organization.address.city;
        data.address.region = this.sandbox.currentUser.organization.address.region;
        data.address.country = this.sandbox.currentUser.organization.address.country;
      }
    }
    this.di = new CarServiceClientDi(data);
    if (isValidObject(data)) {
      this.clientSearch = data.name;
    }
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.initialize((data: OrganizationClient_Dto) => {
      if (!this.isFromAppointmentValue) {
        this.organizationClientSelected(data);
        if (data) {
          this.clientSearch = this.sandbox.clientForCar.name;
        } else {
          this.clientSearch = '';
          this.clearSearch();
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();
    if (this.di.isValid()) {
      if (this.modelSaved != null) {
        this.modelSaved.emit(this.di.getModel());
      }
    }
  }

  isValid() {
    return this.di.isValid();
  }

  organizationClientSelected(value: any) {
    this.di.resetAnafUsedFields();
    const client = new OrganizationClient_Dto(value);

    this.di = new CarServiceClientDi(client);
    setTimeout(() => {
      this.di.form.controls['isLegalEntity'].setValue(client.isLegalEntity + '');
      this.di.changeLegalForm({target: {value: client.isLegalEntity + ''}});
      if (this.di.isLegalEntity) {
        if (!client.id) {
          client.address = new Address(null);
          client.address.country = this.sandbox.organization.address.country;
          client.address.countryId = this.sandbox.organization.address.countryId;
          client.address.city = this.sandbox.organization.address.city;
          client.address.region = this.sandbox.organization.address.region;
          client.address.regionId = this.sandbox.organization.address.regionId;
        }
      }
      this.di.form.controls['phoneNumber'].setValue(client.phoneNumber);
      this.di.form.controls['cuiCnp'].setValue(client.cuiCnp);
      this.di.form.controls['nrRegCi'].setValue(client.nrRegCi);
      this.di.form.controls['email'].setValue(client.email);
      this.di.form.controls['iban'].setValue(client.iban);
      if (this.modelSaved != null) {
        const newModel = this.di.getModel();
        newModel.address = client.address;
        this.modelSaved.emit(newModel);
      }
      if (!this.isFromAppointmentValue) {
        if (client.id) {
          if (!this.sandbox.isCarComponentTriggered) {
            this.getCarForClientAP.execute(client.id);
          }
        } else {
          this.sandbox.resetCarForClient();
        }
      }
    }, 0);
  }

  clearSearch() {
    if (this.organizationClientSearchComponent) {
      this.organizationClientSearchComponent.clearSelect();
    }
  }

  public companyInfoLookupAnaf() {
    if (this.di.getModel().isLegalEntity) {
      const inputCui: string = this.di.getModel().cuiCnp;
      if (isValidString(inputCui)) {
        this.getOrganizationClientFromAnafAP.execute(inputCui, (result: Organization) => {
          this.whenAnafCompanyFound(result);
        });
      }
    }
  }

  public whenAnafCompanyFound(data: Organization) {
    if (isValidObject(data)) {
      const address = new Address(null);
      if (isValidString(data.address.address1)) {
        address.address1 = data.address.address1;
      }
      if (isValidString(data.address.city)) {
        address.city = data.address.city;
      }
      if (isValidString(data.address.region.id)) {
        address.region = new BaseLocationEntity(data.address.region);
      }
      if (isValidString(data.address.country.id)) {
        address.country = new BaseLocationEntity(data.address.country);
      }
      this.anafCompany = new OrganizationClient_Dto({
        isLegalEntity: true,
        name: data.name,
        nrRegCi: data.registrationNumber,
        phoneNumber: data.representativePersonPhoneNumber,
        cuiCnp: this.di.getModel().cuiCnp,
        address: new Address(address)
      });
    }
  }

  doesNotAcceptNotifications(value: boolean) {
    this.willClientAcceptNotifications = value;
  }

}
