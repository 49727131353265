import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import * as store from '../../core/app-store';
import {Observable} from 'rxjs';
import {Do_GenericErrorEvent, Do_GenericSuccessEvent, EventWithScreenLoading} from '../../core/app-store/events/base.events';
import {map, switchMap} from 'rxjs/operators';
import {
    DoGenerateReceiptEvent,
    GetOrganizationReceiptsPagedResultEvent, GetOrgReceiptDownloadLink,
    GetReceiptEvent
} from '../../core/app-store/events/receipt.events';
import {ReceiptPrintApiService} from '../../core/api-integration/services/receipt-print-api.service';
import {PagedResult} from '../../core/shared/datatable/datatable.helpers';
import {ObjectValidators} from '../../core/shared/object.validators';
import {Receipt_Dto} from '../../core/app-dto/receipt.dto';
import {FileAttachment} from '../../core/app-dto/core.dto';
import {isValidObject} from '../../core/shared/helpers/common.helpers';

@Injectable()
export class ReceiptUIEventProcessor {

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private printJobApiService: ReceiptPrintApiService
    ) {
    }

    @Effect()
    GenerateReceiptJob$: Observable<any> = this.actions$
        .pipe(
            ofType(DoGenerateReceiptEvent.identifier, EventWithScreenLoading.identifier + DoGenerateReceiptEvent.identifier),
            map((action: DoGenerateReceiptEvent) => action),
            switchMap((action: DoGenerateReceiptEvent) => {
                    return new Observable((observer) => {
                        this.printJobApiService.pj_generateReceiptPrintJob(action.model, action.invoiceId)
                            .subscribe((res: FileAttachment) => {
                                observer.next(new Do_GenericSuccessEvent<FileAttachment>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    getOrganizationReceiptPagedResult$
        :
        Observable<any> = this.actions$
        .pipe(
            ofType(GetOrganizationReceiptsPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetOrganizationReceiptsPagedResultEvent.identifier),
            map((action: GetOrganizationReceiptsPagedResultEvent) => action),
            switchMap((action: GetOrganizationReceiptsPagedResultEvent) => {
                    return new Observable((observer) => {
                        this.printJobApiService.u_getOrganizationFilteredReceiptsPaginated(action.parameters)
                            .subscribe((res: PagedResult<Receipt_Dto>) => {
                                if (ObjectValidators.isValidObject(res))
                                    observer.next(new Do_GenericSuccessEvent<PagedResult<Receipt_Dto>>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    getReceipt$
        :
        Observable<any> = this.actions$
        .pipe(
            ofType(GetReceiptEvent.identifier, EventWithScreenLoading.identifier + GetReceiptEvent.identifier),
            map((action: GetReceiptEvent) => action),
            switchMap((action: GetReceiptEvent) => {
                    return new Observable((observer) => {
                        this.printJobApiService.u_getReceipt(action.receiptId)
                            .subscribe((res: Receipt_Dto) => {
                                if (ObjectValidators.isValidObject(res)) {
                                    observer.next(new Do_GenericSuccessEvent<Receipt_Dto>(res, action));
                                }
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    downloadOrgReceipt$
        :
        Observable<any> = this.actions$
        .pipe(
            ofType(GetOrgReceiptDownloadLink.identifier, EventWithScreenLoading.identifier + GetOrgReceiptDownloadLink.identifier),
            map((action: GetOrgReceiptDownloadLink) => action),
            switchMap((action: GetOrgReceiptDownloadLink) => {
                    return new Observable((observer) => {
                        this.printJobApiService.u_getDownloadLinkForOrganizationReceipt(action.receiptId)
                            .subscribe((res: string) => {
                                if (ObjectValidators.isValidString(res)) {
                                    observer.next(new Do_GenericSuccessEvent<string>(res, action));
                                    if (isValidObject(action.callback)) { action.callback(res); }
                                }
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );
}
