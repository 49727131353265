import {Injectable} from '@angular/core';
import {
    AddPaymentMethod, CreateAnonymousCardSetupSession,
    CreateCardSetupSession,
    CreateCheckoutSession,
    StripeConfigResponse,
    UpdatePaymentMethod
} from "../dto/stripe.dto";
import {Observable, of} from "rxjs";
import {isValidObject, isValidString} from "../../shared/helpers/common.helpers";
import {GetStripeConfigActionProxy} from "../common/get-stripe-config.action-proxy";
import {Store} from "@ngrx/store";
import * as store from "../../app-store";
import {CreateStripeCheckoutActionProxy} from "../common/create-stripe-checkout.action-proxy";
import {AddStripePaymentMethodActionProxy} from "../common/add-stripe-payment-method.action-proxy";
import {UpdateStripePaymentMethodActionProxy} from "../common/update-stripe-payment-method.action-proxy";
import {CreateCardSetupActionProxy} from "../common/create-card-setup.action-proxy";
import {CreateAnonymousCardSetupActionProxy} from "../common/create-anonymous-card-setup.action-proxy";

@Injectable()
export class StripeService {

    public currentConfig: StripeConfigResponse;

    constructor(private appState: Store<store.State>) {
    }

    public getStripeConfig(): Observable<StripeConfigResponse> {
        if (isValidObject(this.currentConfig) && isValidString(this.currentConfig.publicKey)) return of(this.currentConfig);
        const resultObs = new Observable<StripeConfigResponse>((observer) => {
            let ap = new GetStripeConfigActionProxy(null, this.appState);
            ap.execute((result: StripeConfigResponse) => {
                this.currentConfig = result;
                observer.next(result);
                observer.complete();
            });
        });
        return resultObs;
    }

    public createCheckoutSession(data: CreateCheckoutSession): Observable<string> {
        let observerObj = null;
        const result = new Observable<string>((observer) => {
            observerObj = observer;
        });
        this.getStripeConfig().subscribe(
            (config: StripeConfigResponse) => {
                let ap = new CreateStripeCheckoutActionProxy(null, this.appState);
                ap.execute(data, (result: string) => {
                    observerObj.next(result);
                    observerObj.complete();
                });
            });
        return result;
    }

    public addPaymentMethod(data: AddPaymentMethod): Observable<string> {
        let observerObj = null;
        var result = new Observable<string>((observer) => {
            observerObj = observer;
        });
        let ap = new AddStripePaymentMethodActionProxy(null, this.appState);
        ap.execute(data, (result: string) => {
            observerObj.next(result);
            observerObj.complete();
        });
        return result;
    }

    public updatePaymentMethod(data: UpdatePaymentMethod): Observable<string> {
        let observerObj = null;
        var result = new Observable<string>((observer) => {
            observerObj = observer;
        });
        let ap = new UpdateStripePaymentMethodActionProxy(null, this.appState);
        ap.execute(data, (result: string) => {
            observerObj.next(result);
            observerObj.complete();
        });
        return result;
    }

    public createCardSetupSession(data: CreateCardSetupSession): Observable<string> {
        let observerObj = null;
        const result = new Observable<string>((observer) => {
            observerObj = observer;
        });
        this.getStripeConfig().subscribe(
            (config: StripeConfigResponse) => {
                let ap = new CreateCardSetupActionProxy(null, this.appState);
                ap.execute(data, (result: string) => {
                    observerObj.next(result);
                    observerObj.complete();
                });
            });
        return result;
    }

    public createAmonymousCardSetupSession(data: CreateAnonymousCardSetupSession): Observable<string> {
        let observerObj = null;
        const result = new Observable<string>((observer) => {
            observerObj = observer;
        });
        this.getStripeConfig().subscribe(
            (config: StripeConfigResponse) => {
                let ap = new CreateAnonymousCardSetupActionProxy(null, this.appState);
                ap.execute(data, (result: string) => {
                    observerObj.next(result);
                    observerObj.complete();
                });
            });
        return result;
    }
}
