import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CalendarEvent_Dto, CalendarEventType } from 'src/app/core/app-dto/calendar-event.dto';
import { DateTimeService } from 'src/app/core/core/services/date-time.service';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { PhoneReg } from '../../../core/shared/form/custom-form.validator';

export class AddEditCalendarEventDI extends BaseFormDI<CalendarEvent_Dto> {
  endTime: Date;
  startTime: Date;

  constructor(public dateTimeService: DateTimeService, data: CalendarEvent_Dto = new CalendarEvent_Dto(null)) {
    super(new CalendarEvent_Dto(data));

    this.startTime = data.startDate;
    this.endTime = data.endDate;

    this.initForm();
  }

  getModel(): CalendarEvent_Dto {
    this.model.title = this.form.value.title;
    this.model.description = this.form.value.description;
    this.model.clientName = this.form.value.clientName;
    this.model.clientPhoneNumber = this.form.value.clientPhoneNumber;
    this.model.carNumber = this.form.value.carNumber;
    this.model.type = this.form.value.type;
    this.model.startDate = this.form.value.startDate;
    this.model.endDate = this.form.value.endDate;
    this.model.startDate.setHours(this.startTime.getHours(), this.startTime.getMinutes());
    this.model.startDate = this.dateTimeService.toUTC(this.model.startDate);
    this.model.endDate.setHours(this.endTime.getHours(), this.endTime.getMinutes());
    this.model.endDate = this.dateTimeService.toUTC(this.model.endDate);
    this.model.isFullDayEvent = false;
    if (!ObjectValidators.isValidObject(this.model.type))
      this.model.type = CalendarEventType.Appointment;

    return this.model;
  }

  initForm() {
    this.form = new FormGroup({
      title: new FormControl(this.model.title, Validators.compose([Validators.required])),
      description: new FormControl(this.model.description, Validators.compose([Validators.required])),
      clientName: new FormControl(this.model.clientName, Validators.compose([Validators.required])),
      clientPhoneNumber: new FormControl(this.model.clientPhoneNumber, Validators.compose([Validators.required, PhoneReg()])),
      carNumber: new FormControl(this.model.carNumber, Validators.compose([Validators.required])),
      startDate: new FormControl(this.model.startDate || new Date(), Validators.compose([Validators.required])),
      startTime: new FormControl(this.startTime, Validators.compose([Validators.required])),
      endDate: new FormControl(this.model.endDate || new Date(), Validators.compose([Validators.required])),
      endTime: new FormControl(this.endTime, Validators.compose([Validators.required]))
    });
  }
}
