import {
  UIGeneratedEvent,
  EventWithScreenLoading, SeamlessEvent
} from './base.events';
import { ActiveDataTableParameters } from '../../shared/datatable/datatable.helpers';
import { Supply_Dto } from '../../app-dto/supply.dto';

export class GetSuppliesPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = 'GetSuppliesPagedResultEvent';
    constructor(public parameters: ActiveDataTableParameters, public withReset: boolean = false, public isStorno: boolean = false) {
        super();
        this.type += GetSuppliesPagedResultEvent.identifier;
    }
}

export class GetSuppliesWithUnpaidInvoicePagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'GetSuppliesWithUnpaidInvoicePagedResultEvent';
    constructor(public callback: Function, public parameters: ActiveDataTableParameters, public withReset: boolean = false) {
        super();
        this.type += GetSuppliesWithUnpaidInvoicePagedResultEvent.identifier;
    }
}

export class GetSuppliesEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = 'GetSuppliesEvent';
    constructor(public isStorno: boolean, public supplierId: string) {
        super();
        this.type += GetSuppliesEvent.identifier;
    }
}

export class RegisterSupplyEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = 'RegisterSupplyEvent';
    constructor(public model: Supply_Dto) {
        super();
        this.type += RegisterSupplyEvent.identifier;
    }
}

export class GetSupplyEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = 'GetSupplyEvent';
    constructor(public id: string) {
        super();
        this.type += GetSupplyEvent.identifier;
    }
}


export class EditSupplyEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = 'EditSupplyEvent';
    constructor(public model: Supply_Dto) {
        super();
        this.type += EditSupplyEvent.identifier;
    }
}


export class DeleteSupplyEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = 'DeleteSupplyEvent';
    constructor(public id: string, public callback:Function = null) {
        super();
        this.type += DeleteSupplyEvent.identifier;
    }
}

export class ActivateSupplyEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = 'ActivateSupplyEvent';
    constructor(public id: string) {
        super();
        this.type += ActivateSupplyEvent.identifier;
    }
}

export class DeactivateSupplyEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = 'DeactivateSupplyEvent';
    constructor(public id: string) {
        super();
        this.type += DeactivateSupplyEvent.identifier;
    }
}

export class GetSupplyNoteLinkEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'GetSupplyNoteLinkEvent';
    constructor(public id: string, public callback: Function = null) {
        super();
        this.type += GetSupplyNoteLinkEvent.identifier;
    }
}

export class MarkSupplyAsPaidEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'MarkSupplyAsPaidEvent';
    constructor(public id: string) {
        super();
        this.type += MarkSupplyAsPaidEvent.identifier;
    }
}

export class MarkSupplyAsNotPaidEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'MarkSupplyAsNotPaidEvent';
    constructor(public id: string) {
        super();
        this.type += MarkSupplyAsNotPaidEvent.identifier;
    }
}
