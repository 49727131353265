import { Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import { map, switchMap } from 'rxjs/operators';
import { Do_GenericSuccessEvent, Do_GenericErrorEvent, SeamlessEvent } from 'src/app/core/app-store/events/base.events';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { SearchCarWorkEvent } from '../../core/app-store/events/car-works.events';
import { CarWorkApiService } from '../../core/api-integration/services/car-work-api.service';
import { CarWork_Dto } from '../../core/app-dto/car-work.dto';


@Injectable()
export class CarWorkUIEventProcessor {

    constructor(private actions$: Actions,
                private appState$: Store<store.State>,
                private carWorkApiService: CarWorkApiService) {
    }

    @Effect()
    doSearchCarWork$: Observable<any> = this.actions$
        .pipe(
            ofType(SearchCarWorkEvent.identifier, SeamlessEvent.identifier + SearchCarWorkEvent.identifier),
            map((action: SearchCarWorkEvent) => action),
            switchMap((action: SearchCarWorkEvent) => {
                return new Observable((observer) => {
                    this.carWorkApiService.u_searchCarWork(action.search, action.model, action.make,action.year)
                        .subscribe((res: Array<CarWork_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Array<CarWork_Dto>>(res, action));
                            }
                            action.callBack(res);
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            action.callBack([]);
                            observer.complete();
                        });
                });
            })
        );
}
