import {Injectable} from '@angular/core';
import * as store from '../../../core/app-store/index';
import {select, Store} from '@ngrx/store';
import {BaseSandbox} from 'src/app/core/shared/base/base.sandbox';
import {ServicePlatformStatusListItemDi} from './service-platform-status-list-item.di';
import {ServicePlatform_Dto,} from "../../../core/app-dto/service-platform.dto";
import {isValidArrayAndHasElements, isValidString} from "../../../core/shared/helpers/common.helpers";
import {UnassignedServicePlatformDI} from "./unassigned-service-platform.di";

@Injectable()
export class ServicePlatformStatusListSandbox extends BaseSandbox {
  public servicePlatformStatus$ = this.appState$.pipe(select(store.getServicePlatformStatusesListing));

  public items: Array<ServicePlatformStatusListItemDi> = [];
  public unassignedPlatform: UnassignedServicePlatformDI = null;

  constructor(protected appState$: Store<store.State>) {
    super(appState$);
  }

  public clearSandboxState() {
    this.items = [];
    this.unassignedPlatform = new UnassignedServicePlatformDI([]);
  }

  public initialize(): void {
    this.clearSandboxState();
    this.subscriptions.push(this.servicePlatformStatus$.subscribe((data: Array<ServicePlatform_Dto>) => {
      if (isValidArrayAndHasElements(data)) {
        let unassingedPlatforms = data.filter(f => isValidString(f.id) == false);
        this.unassignedPlatform = new UnassignedServicePlatformDI(unassingedPlatforms);
        this.items = data.filter(f=> isValidString(f.id)).map(r => new ServicePlatformStatusListItemDi(r));
      } else {
        this.items = [];
        this.unassignedPlatform = new UnassignedServicePlatformDI([]);
      }
    }));
  }

}
