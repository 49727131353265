import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ListOfferEntriesComponent} from './components/list-offer-entries/list-offer-entries.component';
import {OfferDetailsOverviewComponent} from './components/offer-details-overview/offer-details-overview.component';
import {OfferDetailsListClientCarPartsComponent} from './components/offer-details-list-client-car-parts/offer-details-list-client-car-parts.component';
import {OfferDetailsListServiceCarPartsComponent} from './components/offer-details-list-service-car-parts/offer-details-list-service-car-parts.component';
import {OfferDetailsListCarServiceActionsComponent} from './components/offer-details-list-car-service-actions/offer-details-list-car-service-actions.component';
import {OfferDetailsListClientDetailsComponent} from './components/offer-details-list-client-details/offer-details-list-client-details.component';
import {OfferDetailsListCarDetailsComponent} from './components/offer-details-list-car-details/offer-details-list-car-details.component';
import {OfferDetailsListSendersComponent} from './components/offer-details-list-senders/offer-details-list-senders.component';
import {SharedModule} from '../core/shared/shared.module';
import {DataTablesModule} from 'angular-datatables';
import {ListOfferEntriesSandbox} from './components/list-offer-entries/list-offer-entries.sandbox';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {BsDatepickerModule, ModalModule, TimepickerModule} from 'ngx-bootstrap';
import {OfferStatusLabelComponent} from './components/offer-status-label/offer-status-label.component';
import {OfferDetailsOverviewSandbox} from './components/offer-details-overview/offer-details-overview.sandbox';
import {DocumentsModule} from '../documents/documents.module';
import {UpdateOfferClientDataComponent} from './components/update-offer-client-data/update-offer-client-data.component';
import {UpdateOfferCarDataComponent} from './components/update-offer-car-data/update-offer-car-data.component';
import {UpdateOfferCarWorksComponent} from './components/update-offer-car-works/update-offer-car-works.component';
import {UpdateOfferClientCarPartsComponent} from './components/update-offer-client-car-parts/update-offer-client-car-parts.component';
import {CarModule} from '../car/car.module';
import {OrganizationClientModule} from '../organization-client/organization-client.module';
import {CarServiceModule} from '../car-service/car-service.module';
import {UpdateOfferSaveServiceCarPartComponent} from './components/update-offer-save-service-car-part/update-offer-save-service-car-part.component';
import {CarPartModule} from '../car-part/car-part.module';
import {CarWorkModule} from '../car-works/car-work.module';
import {SendOfferToClientsComponent} from './components/send-offer-to-clients/send-offer-to-clients.component';
import {ResponseToOfferComponent} from './components/response-to-offer/response-to-offer.component';
import {AddOfferWizardComponent} from "./components/add-offer-wizard/add-offer-controller/add-offer-wizard.component";
import {AddOfferWizardSandbox} from "./components/add-offer-wizard/add-offer-controller/add-offer-wizard.sandbox";
import {AddOfferThirdStepSandbox} from "./components/add-offer-wizard/add-offer-third-step/add-offer-third-step.sandbox";
import {AddOfferCarWorksListSandbox} from "./components/add-offer-car-works-list/add-offer-car-works-list.sandbox";
import {AddOfferSummarySandbox} from "./components/add-offer-wizard/add-offer-summary/add-offer-summary.sandbox";
import {AddOfferClientSandbox} from "./components/add-offer-wizard/add-offer-client-data/add-offer-client.sandbox";
import {AddOfferThirdStepComponent} from "./components/add-offer-wizard/add-offer-third-step/add-offer-third-step.component";
import {AddOfferCarWorksListComponent} from "./components/add-offer-car-works-list/add-offer-car-works-list.component";
import {AddOfferSummaryComponent} from "./components/add-offer-wizard/add-offer-summary/add-offer-summary.component";
import {AddOfferClientComponent} from "./components/add-offer-wizard/add-offer-client-data/add-offer-client.component";
import {AddOfferFirstStepComponent} from "./components/add-offer-wizard/add-offer-first-step/add-offer-first-step.component";
import {AddOfferFirstStepSandbox} from "./components/add-offer-wizard/add-offer-first-step/add-offer-first-step.sandbox";
import {ArchwizardModule} from "angular-archwizard";
import {UploadModule} from "../core/upload/upload.module";
import {UpdateOfferTitleComponent} from "./components/update-offer-title/update-offer-title.component";

@NgModule({
    declarations: [
        ListOfferEntriesComponent,
        OfferDetailsOverviewComponent,
        OfferDetailsListClientCarPartsComponent,
        OfferDetailsListServiceCarPartsComponent,
        OfferDetailsListCarServiceActionsComponent,
        OfferDetailsListClientDetailsComponent,
        OfferDetailsListCarDetailsComponent,
        OfferDetailsListSendersComponent,
        OfferStatusLabelComponent,
        UpdateOfferClientDataComponent,
        UpdateOfferCarDataComponent,
        UpdateOfferCarWorksComponent,
        UpdateOfferClientCarPartsComponent,
        UpdateOfferSaveServiceCarPartComponent,
        SendOfferToClientsComponent,
        ResponseToOfferComponent,
        AddOfferWizardComponent,
        AddOfferFirstStepComponent,
        AddOfferClientComponent,
        AddOfferCarWorksListComponent,
        AddOfferThirdStepComponent,
        AddOfferSummaryComponent,
        UpdateOfferTitleComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        DataTablesModule,
        RouterModule,
        NgSelectModule,
        ArchwizardModule,
        CarModule,
        CarServiceModule,
        CarWorkModule,
        OrganizationClientModule,
        CarPartModule,
        UploadModule,
        DocumentsModule,
        //BsDatepickerModule,
        //TimepickerModule,
        ModalModule.forRoot()
    ],
    providers: [
        ListOfferEntriesSandbox,
        OfferDetailsOverviewSandbox,
        AddOfferWizardSandbox, AddOfferThirdStepSandbox, AddOfferCarWorksListSandbox, AddOfferSummarySandbox, AddOfferClientSandbox,
        AddOfferFirstStepSandbox
    ],
    entryComponents: [
        UpdateOfferClientDataComponent,
        UpdateOfferCarDataComponent,
        UpdateOfferCarWorksComponent,
        UpdateOfferClientCarPartsComponent,
        UpdateOfferSaveServiceCarPartComponent,
        SendOfferToClientsComponent,
        ResponseToOfferComponent,
        AddOfferWizardComponent,
        UpdateOfferTitleComponent
    ],
    exports: [
        AddOfferWizardComponent,
    ]
})
export class OfferModule {
}
