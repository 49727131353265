import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CustomCalendarModule } from '../calendar/calendar.module';
import { CarServiceModule } from '../car-service/car-service.module';
import { InvoiceModule } from '../invoice/invoice.module';
import { ServicePlatformStatusModule } from '../service-platform-status-list/service-platform-status.module';
import { CoreModule } from '../core/core/core.module';
import { DocumentsModule } from '../documents/documents.module';
import {MechanicModule} from '../mechanic/mechanic.module';
import { SupplyModule } from '../supply/supply.module';
import { DashboardSandbox } from './components/dashboard/dashboard.sandbox';


@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CoreModule,
    CommonModule,
    CustomCalendarModule,
    CarServiceModule,
    InvoiceModule,
    ServicePlatformStatusModule,
    DocumentsModule,
    MechanicModule,
    SupplyModule
  ],
  providers: [
    DashboardSandbox
  ]
})
export class DashboardModule { }
