import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiErrorDto } from '../utils/api-error.dto';
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ActiveDataTableParameters, PagedResult } from '../../shared/datatable/datatable.helpers';
import { Department_Dto } from '../../app-dto/department.dto';
import { ErrorHelpers } from '../../shared/error.helpers';

class DepartmentApiRoutes {
    public static route_for_GET_DEPARTMENTS_PAGINATED() {
        return '/api/department/paginated';
    }
    public static route_for_GET_DEPARTMENT(departmentId: string) {
        return `/api/department/${departmentId}`;
    }
    public static route_for_REGISTER_DEPARTMENT() {
        return '/api/department/';
    }
    public static route_for_EDIT_DEPARTMENT() {
        return '/api/department/';
    }
    public static route_for_DELETE_DEPARTMENT(departmentId: string) {
        return `/api/department/${departmentId}`;
    }
    public static route_for_ACTIVATE_DEPARTMENT(departmentId: string) {
        return `/api/department/${departmentId}/activate`;
    }
    public static route_for_DEACTIVATE_DEPARTMENT(departmentId: string) {
        return `/api/department/${departmentId}/deactivate`;
    }
    public static route_for_GET_DEPARTMENTS() {
        return `/api/department/all`;
    }
}

@Injectable()
export class DepartmentApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_registerDepartment(departmentData: Department_Dto): Observable<Department_Dto> {
        const url = this.configService.getAPIBaseUrl() + DepartmentApiRoutes.route_for_REGISTER_DEPARTMENT();

        return this.http.post(url, departmentData)
            .map((res: any) => {
                return new Department_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_editDepartment(departmentData: Department_Dto): Observable<Department_Dto> {
        const url = this.configService.getAPIBaseUrl() + DepartmentApiRoutes.route_for_EDIT_DEPARTMENT();

        return this.http.put(url, departmentData)
            .map((res: any) => {
                return new Department_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getFilteredDepartmentsPaginated(data: ActiveDataTableParameters): Observable<PagedResult<Department_Dto>> {
        const url = this.configService.getAPIBaseUrl() + DepartmentApiRoutes.route_for_GET_DEPARTMENTS_PAGINATED() + '?' + data.toUrlParameters();

        return this.http.get(url)
            .map((res: PagedResult<Department_Dto>) => {
                return new PagedResult<Department_Dto>(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getDepartment(departmentId: string): Observable<Department_Dto> {
        const url = this.configService.getAPIBaseUrl() + DepartmentApiRoutes.route_for_GET_DEPARTMENT(departmentId);

        return this.http.get(url)
            .map((res: any) => {
                return new Department_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getDepartments(): Observable<Department_Dto[]> {
        const url = this.configService.getAPIBaseUrl() + DepartmentApiRoutes.route_for_GET_DEPARTMENTS();

        return this.http.get(url)
            .map((res: Department_Dto[]) => {
                return res;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deleteDepartment(departmentId: string): Observable<Department_Dto> {
        const url = this.configService.getAPIBaseUrl() + DepartmentApiRoutes.route_for_DELETE_DEPARTMENT(departmentId);

        return this.http.delete(url)
            .map((res: any) => {
                return new Department_Dto(null);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_activateDepartment(departmentId: string): Observable<Department_Dto> {
        const url = this.configService.getAPIBaseUrl() + DepartmentApiRoutes.route_for_ACTIVATE_DEPARTMENT(departmentId);

        return this.http.put(url, {})
            .map((res: any) => {
                return new Department_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deactivateDepartment(departmentId: string): Observable<Department_Dto> {
        const url = this.configService.getAPIBaseUrl() + DepartmentApiRoutes.route_for_DEACTIVATE_DEPARTMENT(departmentId);

        return this.http.put(url, {})
            .map((res: any) => {
                return new Department_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
}
