import {
  UIGeneratedEvent,
  EventWithScreenLoading,
  SeamlessEvent,
} from './base.events';
import { ActiveDataTableParameters } from '../../shared/datatable/datatable.helpers';
import { OrganizationCost } from '../../app-dto/organization.dto';

export class GetOrganizationCostsPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetOrganizationCostsPagedResultEvent";
  constructor(public parameters: ActiveDataTableParameters, public withReset: boolean = false) {
    super();
    this.type += GetOrganizationCostsPagedResultEvent.identifier;
  }
}

export class RegisterOrganizationCostEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "RegisterOrganizationCostEvent";
  constructor(public model: OrganizationCost) {
    super();
    this.type += RegisterOrganizationCostEvent.identifier;
  }
}

export class GetOrganizationCostEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetOrganizationCostEvent";
  constructor(public id: string, public callback: (data: OrganizationCost) => void) {
    super();
    this.type += GetOrganizationCostEvent.identifier;
  }
}

export class EditOrganizationCostEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "EditOrganizationCostEvent";
  constructor(public model: OrganizationCost) {
    super();
    this.type += EditOrganizationCostEvent.identifier;
  }
}

export class DeleteOrganizationCostEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeleteOrganizationCostEvent";
  constructor(public id: string, public callback: Function = null) {
    super();
    this.type += DeleteOrganizationCostEvent.identifier;
  }
}

export class DeleteOrganizationCostMonthlyEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeleteOrganizationCostMonthlyEvent";
  constructor(public id: string, public callback: Function = null) {
    super();
    this.type += DeleteOrganizationCostMonthlyEvent.identifier;
  }
}

export class DeactivateOrganizationCostEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeactivateOrganizationCostEvent";
  constructor(public id: string, public endingDate: Date) {
    super();
    this.type += DeactivateOrganizationCostEvent.identifier;
  }
}
