import {Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, Input} from '@angular/core';
import {BaseWebComponent} from 'src/app/core/shared/base/base.component';
import {IdName_Dto} from 'src/app/core/app-dto/misc.dto';
import {SearchComponent} from 'src/app/core/shared/components/search/search.component';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {SearchCarPartActionProxy} from './search-car-part.action-proxy';
import {CarPartSearchSandbox} from './car-part-serach.sandbox';
import {CarPart_Dto} from 'src/app/core/app-dto/car-part.dto';
import {isValidObject} from '../../../core/shared/helpers/common.helpers';

@Component({
    selector: 'app-car-part-search',
    templateUrl: './car-part-search.component.html',
    styleUrls: ['./car-part-search.component.scss']
})
export class CarPartSearchComponent extends BaseWebComponent implements OnInit, OnDestroy {
    public searchCarPartAP: SearchCarPartActionProxy;

    public items: Array<IdName_Dto> = [];
    public isLoading: boolean = false;

    @Input() displayOnlyAvailableItems: boolean = true;
    @Input() allowedCustomInput: boolean = false;
    @Output() itemSelected = new EventEmitter<CarPart_Dto>();

    @ViewChild(SearchComponent, {static: false}) searchComponent: SearchComponent;

    constructor(
        public sandbox: CarPartSearchSandbox
    ) {
        super(sandbox);
        this.searchCarPartAP = new SearchCarPartActionProxy(this, sandbox.appState);
    }

    ngOnInit() {
        this.items = [];
        this.search('');
        this.initialize((data: Array<IdName_Dto>) => {
            this.items = data;
            this.dataLoaded = true;
        });
    }

    ngOnDestroy() {
        this.destroy();
    }

    public search(value: string) {
        this.isLoading = true;
        this.searchCarPartAP.execute(value, () => {
            this.isLoading = false;
        }, this.displayOnlyAvailableItems);
    }

    public selected(value: IdName_Dto) {
        if (ObjectValidators.isValidObject(value)) {
            const data = this.sandbox.originalCarPartSearchList.find(r => {
                const name = `${r.name} - ${r.code} - ${r.supplier.name} - ${r.acquisitionPriceWithVat} ${r.acquisitionPriceCurrency}`;
                return r.id === value.id && name === value.name;
            });
            if (this.itemSelected != null) {
                if (this.allowedCustomInput && isValidObject(data) == false) {
                    this.itemSelected.emit(new CarPart_Dto({name: value.name}));
                } else {
                    this.itemSelected.emit(data);
                }
            }
        } else {
            if (this.itemSelected != null) {
                this.itemSelected.emit(null);
            }
        }
    }

    clearSelect() {
        this.searchComponent.clearSelect();
    }
}
