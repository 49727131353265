import {Injectable} from "@angular/core";
import {BaseSandbox} from "../../../core/shared/base/base.sandbox";
import {Store} from "@ngrx/store";
import * as store from "../../../core/app-store";

@Injectable()
export class AddOfferCarWorksListSandbox extends BaseSandbox {

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
    }

    public initialize(): any {
        this.clearSandboxState();
    }
}
