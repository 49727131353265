import {Injectable} from '@angular/core';
import * as store from '../../../core/app-store/index';
import {select, Store} from '@ngrx/store';
import {BaseSandboxWithCurrentUserLoaded} from 'src/app/core/shared/base/base.sandbox';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {OrganizationClient_Dto} from '../../../core/app-dto/organization-client.dto';
import {CompanyInfo_Dto} from '../../../core/app-dto/company-info.dto';
@Injectable()
export class OrganizationClientFormSandbox extends BaseSandboxWithCurrentUserLoaded {
    public getOrganizationClient$ = this.appState$.pipe(select(store.getOrganizationClient));
    public getOrganizationCompanyInfo$ = this.appState$.pipe(select(store.getOrganizationCompanyInfo));

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
    }

    public initialize(organizationClient: (data: OrganizationClient_Dto) => void, userLoaded: () => void,
                      externalCompanyInfoLoaded: (companyInfo: CompanyInfo_Dto) => void): any {
        this.clearSandboxState();

        this.loadUser(() => {
            userLoaded();
        });
        this.subscriptions.push(this.getOrganizationClient$.subscribe((data: OrganizationClient_Dto) => {
            if (ObjectValidators.isValidObject(data)) {
                organizationClient(data);
            }
        }));

        this.subscriptions.push(this.getOrganizationCompanyInfo$.subscribe((companyInfo: CompanyInfo_Dto) => {
            if (ObjectValidators.isValidObject(companyInfo)) {
                externalCompanyInfoLoaded(companyInfo);
            }
        }));
    }
}
