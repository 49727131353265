import {Component, OnInit} from '@angular/core';
import {Version_Dto} from '../../app-dto/version.dto';
import {GetVersionActionProxy} from './get-version.action-proxy';
import {BaseWebComponent} from '../../shared/base/base.component';
import {FooterSandbox} from './footer.sandbox';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseWebComponent implements OnInit {
    public version: string = 'v1.0.0';
    public getVersionAP: GetVersionActionProxy;

    constructor(
        public sandbox: FooterSandbox
    ) {
        super(sandbox);
        this.getVersionAP = new GetVersionActionProxy(this, sandbox.appState);
    }

    ngOnInit() {
        this.getVersionAP.execute();
        this.initialize((data: Version_Dto) => {
            this.version = 'v' + data.version;
        });
    }
}
