import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store/index';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import {DeleteAccountForMechanicEvent, DeleteMechanicEvent} from '../../../core/app-store/events/mechanic.events';

export class DeleteAccountForMechanicActionProxy extends BaseActionProxy {
  constructor(component: IWebComponent, appState: Store<store.State>) {
    super(component, appState);
  }

  canExecute(): boolean {
    return true;
  }

  execute(mechanicId: string, callback:Function = null): any {
    if (this.canExecute()) {
      this.appState.dispatch(new DeleteAccountForMechanicEvent(mechanicId, callback));
    }
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }
}

