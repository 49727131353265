import {AfterViewInit, Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActionsSubject} from '@ngrx/store';
import {BaseListWebComponent} from 'src/app/core/shared/base/base.component';
import {Subscription} from 'rxjs/Subscription';
import {Router} from '@angular/router';
import {
  Do_GenericSuccessEvent,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from 'src/app/core/app-store/events/base.events';
import {SearchEvent} from 'src/app/core/app-store';
import {GetCarServiceListDashboardActionProxy} from './get-car-service-list-dashboard.action-proxy';
import {CarServiceListDashboardSandbox} from './car-service-list-dashboard.sandbox';
import {BsModalService} from 'ngx-bootstrap';
import {ConfigService} from 'src/app/core/core/app-config.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {DataTableDirective} from 'angular-datatables';
import {
  AddCarInServiceEvent,
  EditCarServiceCarEvent,
  EditCarServiceClientEvent
} from '../../../core/app-store/events/car-service.events';


@Component({
  selector: 'app-car-service-list-dashboard',
  templateUrl: './car-service-list-dashboard.component.html',
  styleUrls: ['./car-service-list-dashboard.component.scss']
})
export class CarServiceListDashboardComponent extends BaseListWebComponent implements OnInit, AfterViewInit {
  public getCarServiceEntriesAP: GetCarServiceListDashboardActionProxy;
  public searchValue: string = '';
  private searchCar = new Subscription();
  public columns: any = [];

  @ViewChildren(DataTableDirective) set datatableValue(datatable: QueryList<DataTableDirective>) {
    if (datatable != null) {
      datatable.first.dtInstance.then((dtInstance: DataTables.Api) => {
        this.dtApiInstance = dtInstance;
      });
    }
  }

  constructor(public sandbox: CarServiceListDashboardSandbox,
              private actionsSubj: ActionsSubject,
              private configService: ConfigService,
              private modalService: BsModalService,
              public router: Router,
              private spinner: NgxSpinnerService) {
    super(sandbox, ResetSection.CarService);
    this.getCarServiceEntriesAP = new GetCarServiceListDashboardActionProxy(this, sandbox.appState, this.spinner);

    this.searchCar = actionsSubj.subscribe(data => {
      if (data.type === SeamlessEvent.identifier + SearchEvent.identifier) {
        this.searchValue = (data as SearchEvent).value;
      }
    });

    this.sandbox.addSubscription(actionsSubj.subscribe(data => {
      if (data.type === Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + AddCarInServiceEvent.identifier ||
        data.type === Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + EditCarServiceClientEvent.identifier ||
        data.type === Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + EditCarServiceCarEvent.identifier) {
        this.getCarServiceEntriesAP.execute();
      }
    }));

    this.columns = [
      {data: 'registrationNumber', name: 'Numar Inmatriculare', width: '30%'},
      {data: 'client', name: 'Client', width: '25%'},
      {data: 'phoneNumber', name: 'Telefon', width: '20%', orderable: false},
      {data: 'status', name: 'Status', width: '15%', orderable: false}
    ];
  }


  ngAfterViewInit() {
  }

  ngOnInit() {
    this.initialize();
    this.getCarServiceEntriesAP.execute();
    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      pageLength: this.configService.getDefaultPageSize(),
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      scrollY: '300px',
      scrollCollapse: true,
      ajax: () => {
      },
      columns: this.columns,
      order: [[1, 'asc']],
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
  }


  viewAllCarServiceEntries() {
    this.router.navigate(['/car-service/cars/all']);
  }
}
