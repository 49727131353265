import {Component, Input, OnInit} from '@angular/core';
import {CalendarEventState, CalendarEventStateLabel} from '../../../app-dto/calendar-event.dto';

@Component({
  selector: 'app-car-status-label',
  templateUrl: './car-status-label.component.html',
  styleUrls: ['./car-status-label.component.scss']
})
export class CarStatusLabelComponent implements OnInit {
  public statusLabel :string = '';
  public backgroundColor: string = '#00616B';
  @Input('status') status: CarStatusKey;


  constructor() {
  }

  ngOnInit() {

    switch (this.status) {
      case CarStatusKey.New:
        this.backgroundColor = '#00306C';
        this.statusLabel = 'In service';
        break;
      case CarStatusKey.InProgress:
        this.backgroundColor = '#00616B';
        this.statusLabel = 'In lucru';
        break;
      case CarStatusKey.Waiting:
        this.backgroundColor = '#00587F';
        this.statusLabel = 'In asteptare';
        break;
      case CarStatusKey.Ready:
        this.backgroundColor = '#007567';
        this.statusLabel = 'Finalizata';
        break;
      case CarStatusKey.LeftTheService:
        this.backgroundColor = '#00885B';
        this.statusLabel = 'Iesita din service';
        break;
    }
  }
}

export enum CarStatusKey {
  New = 'New',
  InProgress = 'InProgress',
  LeftTheService = 'LeftTheService',
  Ready = 'Ready',
  Waiting = 'Waiting'
}

