import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { CalendarEvent_Dto, CalendarEventRelatedEntityType, CalendarEventState } from '../../../core/app-dto/calendar-event.dto';
import { CalendarView } from 'angular-calendar';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {

  public minHeightForDetails = 80;
  public minWidthForVertical = 80;

  @ViewChild('calendarEventContainer', {} as any) calendarEventContainer: ElementRef;

  @Input('calendarView') calendarView: CalendarView;
  @Input('calendarEvent') calendarEvent: CalendarEvent_Dto;

  @HostBinding('style.width.px')
  get width(): number {
    return this.calendarView === CalendarView.Day ?
        (this.calendarEvent.cardPosition.width - 4) / this.calendarEvent.cardPosition.overlapping - 1 : 52;
  }

  @HostBinding('style.height.px')
  get height(): number {
    if (this.calendarEvent.cardPosition.height < 50 ) {
      this.calendarEventContainer.nativeElement.style['padding-top'] = this.calendarEvent.cardPosition.height * 0.1 + 'px';
    }

    return this.calendarEvent.cardPosition.height;
  }

  @HostBinding('style.top.px')
  get top(): number {
    return this.calendarEvent.cardPosition.top;
  }

  @HostBinding('style.left.px')
  get left(): number {
    return this.calendarEvent.cardPosition.left + 60 + this.calendarEvent.cardPosition.order *
        (this.calendarEvent.cardPosition.width - 4) / this.calendarEvent.cardPosition.overlapping;
  }

  constructor() {}

  ngOnInit() {
    const opacity90 = 'E5';
    let backgroundColor = '#362F64';
    switch (this.calendarEvent.state) {
      case CalendarEventState.Appointment:
      case CalendarEventState.Todo:
        backgroundColor = '#201853';
        break;
      case CalendarEventState.InProgress:
        backgroundColor = '#00616B';
        break;
      case CalendarEventState.Waiting:
        backgroundColor = '#00587F';
        break;
      case CalendarEventState.Reception:
        backgroundColor = '#00306C';
        break;
      case CalendarEventState.Done:
        backgroundColor = '#007567';
      case CalendarEventState.LeftTheService:
        backgroundColor = '#00885B';
        break;


    }

    this.calendarEventContainer.nativeElement.style.background = backgroundColor + opacity90;
  }

  displayVertical() {
    return (this.calendarEvent.cardPosition.width - 4) / this.calendarEvent.cardPosition.overlapping - 1 < this.minWidthForVertical
        || this.calendarView === CalendarView.Week;
  }

  showDetails() {
    return this.calendarEvent.cardPosition.height > this.minHeightForDetails
      && (this.calendarEvent.cardPosition.width - 4) / this.calendarEvent.cardPosition.overlapping > 110
      && !this.displayVertical() && this.isCarServiceEntryAction();
  }

  isCarServiceEntryAction() {
    return this.calendarEvent.relatedEntityType === CalendarEventRelatedEntityType.Appointment
        || this.calendarEvent.relatedEntityType === CalendarEventRelatedEntityType.CarServiceAction;
  }
}
