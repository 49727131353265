import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import {
  BaseListWebComponentWithSearchAndActiveBehavior
} from 'src/app/core/shared/base/base.component';
import { ConfigService } from 'src/app/core/core/app-config.service';
import { Router } from '@angular/router';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { ActiveDataTableParameters } from 'src/app/core/shared/datatable/datatable.helpers';
import { BsModalService } from 'ngx-bootstrap';
import { GetCarPartsActionProxy } from './get-car-parts.action-proxy';
import { CarPartsListSandbox } from './car-parts-list.sandbox';
import { DateHelpers } from '../../../core/shared/date.helpers';
import {SaveCarPartComponent} from '../save-car-part/save-car-part.component';
import { CarPartsTableComponent } from 'src/app/core/shared/car-parts-table/car-parts-table.component';
import { GetExcelCarPartsActionProxy } from './get-excel-car-parts-action.proxy';
import { GetSuppliersActionProxy } from '../save-car-part/get-suppliers.action-proxy';
import { FileWrapper } from '../../../core/upload/components/upload-notification/upload-notification.di';
import { isValidExcelFile, isValidIcsFile, isValidObject } from '../../../core/shared/helpers/common.helpers';
import { AsyncJob, AsyncJobType, FileToUpload, FileUploadTarget } from '../../../core/app-dto/core.dto';
import { UploadInputEvent } from '../../../core/app-store/events';
import { UploadInput } from '../../../core/upload/components/upload-notification/upload-notification.component';
import * as store from '../../../core/app-store';
import { NotifierService } from 'angular-notifier';
import { AddImportTaskActionProxy } from '../../../calendar/components/calendar/add-import-task.action-proxy';
import { GetExcelCarPartsImportTemplateActionProxy } from './get-excel-car-parts-import-template-action.proxy';
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";


@Component({
  selector: 'app-car-parts-list',
  templateUrl: './car-parts-list.component.html',
  styleUrls: ['./car-parts-list.component.scss']
})
export class CarPartsListComponent extends BaseListWebComponentWithSearchAndActiveBehavior implements OnInit, AfterViewInit {
  public getCarPartsAP: GetCarPartsActionProxy;
  public getExcelCarPartsAP: GetExcelCarPartsActionProxy;
  public getSuppliersAP: GetSuppliersActionProxy;
  public getExcelCarPartsImportTemplateAP: GetExcelCarPartsImportTemplateActionProxy;
  public supplierFilter = '';
  public addImportAP: AddImportTaskActionProxy;
  public currentPage = 1;

  @ViewChild(CarPartsTableComponent, { static: false }) carPartsTableComponent: CarPartsTableComponent;


  constructor(public sandbox: CarPartsListSandbox,
              actionsSubj: ActionsSubject,
              private configService: ConfigService,
              public router: Router,
              private modalService: BsModalService,
              public appState: Store<store.State>,
              private notifierService: NotifierService,
              public rulesProvider:AppUserBusinessRuleProvider
            ) {
    super(sandbox, actionsSubj, ResetSection.CarParts);
    this.getCarPartsAP = new GetCarPartsActionProxy(this, sandbox.appState);
    this.getExcelCarPartsAP = new GetExcelCarPartsActionProxy(this, sandbox.appState);
    this.getSuppliersAP = new GetSuppliersActionProxy(this, sandbox.appState);
    this.addImportAP = new AddImportTaskActionProxy(this, sandbox.appState);
    this.getExcelCarPartsImportTemplateAP = new GetExcelCarPartsImportTemplateActionProxy(this, sandbox.appState);
    this.getSuppliersAP.execute();
    this.getExcelCarPartsImportTemplateAP.execute();
  }

  ngAfterViewInit() {
    this.setBreadcrumbAP.execute([{text: 'Acasa', url: '/dashboard'}, {text: 'Piese'}]);
  }
  ngOnInit() {
    this.initialize();

    this.dtOptions = {
      responsive: true,
      pagingType: 'simple',
      lengthChange: false,
      searching: false,
      pageLength: 8,
      // pageLength: this.configService.getDefaultPageSize(),
      scrollX: false,
      serverSide: true,
      processing: false,
      language: {
        emptyTable: '',
        zeroRecords: '',
        info: '_START_ la _END_ Articole din _TOTAL_',
        paginate: {
          previous: 'Anterior',
          next: 'Urmator'
        }
      },
      autoWidth: false,
      ajax: (dataTablesParameters: ActiveDataTableParameters, callback) => {
        dataTablesParameters.active = this.isActive;
        dataTablesParameters.search.value = this.searchValue;
        this.doGetAll(new ActiveDataTableParameters(dataTablesParameters), callback, true);
      },
      columns: [
        { data: 'name', width: '30%' },
        { data: 'age', width: '20%' },
        { data: 'specializations', width: '30%' },
        { data: 'salary', width: '30%' },
        { data: 'action', width: '50px', orderable: false },
      ],
      order: [[0, 'asc']],
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
  }

  public doGetAll(dataTablesParameters: ActiveDataTableParameters, callback: any, withReset: boolean) {
    this.sandbox.callback = callback;
    this.sandbox.dataTableParameters = dataTablesParameters;

    if (withReset) {
      setTimeout(() => {this.currentPage = 1; }, 0);
      this.sandbox.dataTableParameters.pageNumber = 1;
      dataTablesParameters.pageNumber = 1;
    }
    this.getCarPartsAP.execute(dataTablesParameters, withReset, this.supplierFilter);
  }

  dateToString(date: Date) {
    return DateHelpers.dateToString(date, 'DD/MM/YYYY');
  }

  createCarPart() {
    if(this.rulesProvider.rules == null) return;
    if(this.rulesProvider.rules.canManageSupplies){
      this.router.navigate(['/supply/all']);
    } else {
      const modalRef = this.modalService.show(SaveCarPartComponent, {backdrop: 'static', keyboard: false, class: 'modal-xl'});
      modalRef.content.dataInput = null;
    }
  }

  downloadExcelCarParts(withSupplierFilter: boolean) {
    this.getExcelCarPartsAP.execute(withSupplierFilter ? this.supplierFilter : '');
  }

  downloadCarPartsImportTemplate() {
    window.open(this.sandbox.carPartsImportTemplateUrl, '_blank');
  }

  updateSupplierFilter($event) {
    this.supplierFilter = $event ? $event.id : '';
    this.doGetAll(this.sandbox.dataTableParameters as ActiveDataTableParameters, this.sandbox.callback, true);
  }

  startImport(data: FileList) {
    let files: Array<FileWrapper>;
    let validFiles = true;
    const arrayData = Array.from(data);
    arrayData.forEach(r => {
      if (!isValidExcelFile(r)) {
        // @Todo: add notification error
        validFiles = false;
      }
    });
    if (validFiles) {
      files = arrayData.map(r => {
        const result = new FileWrapper();
        result.file = r;
        result.id = new Date().getTime().toString();
        return result;
      });
      this.addImportAP.execute(new AsyncJob({
        type: AsyncJobType.CarPartsImport
      }), (data: AsyncJob) => {
        if (!isValidObject(data)) return;
        if (files != null && files.length > 0) {
          let timestamp = new Date().getTime();
          const filesToUpload = new Array<FileToUpload>();

          for (let i = 0; i < files.length; i++) {
            files[i].id = undefined;
            timestamp = timestamp + 1;
            filesToUpload.push(new FileToUpload(timestamp.toString(), files[i].file, FileUploadTarget.AsyncJob, data.id));
          }
          if (filesToUpload.length > 0) {
            this.appState.dispatch(new UploadInputEvent(new UploadInput(filesToUpload, FileUploadTarget.AsyncJob)));
          }
        }
      });

    } else {
      this.notifierService.notify('error', 'Tipul fisierului nu este acceptat!');
    }
  }
  onPageChange(currentPage: number) {
    this.currentPage = currentPage;
    this.sandbox.dataTableParameters.pageNumber = currentPage;
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
  }
}
