import { Component, OnDestroy, OnInit, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseFormComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { ActivatedRoute } from '@angular/router';
import { AddEditCalendarEventWizardSandbox } from './add-edit-calendar-event-wizard.sandbox';
import { AddEditCalendarEventWizardDi } from './add-edit-calendar-event-wizard.di';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CalendarEvent_Dto } from 'src/app/core/app-dto/calendar-event.dto';
import { RegisterCarAppointmentEventActionProxy } from './register-calendar-event.action-proxy';
import { DateTimeService } from 'src/app/core/core/services/date-time.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { WizardComponent } from 'angular-archwizard';
import { CarAppointment_Dto } from '../../../core/app-dto/car-appointment.dto';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { AppointmentClient_Dto } from '../../../core/app-dto/appointment-client.dto';
import { AppointmentClientComponent } from '../appointment-client/appointment-client.component';
import { AppointmentCar_Dto } from '../../../core/app-dto/appointment-car.dto';
import { AppointmentCarComponent } from '../appointment-car/appointment-car.component';
import { AppointmentDetailsComponent } from '../appointment-details/appointment-details.component';
import {AppointmentCarWorks_Dto, CarWork_Dto} from '../../../core/app-dto/car-work.dto';
import { GetCarAppointmentActionProxy } from './get-car-appointment.action-proxy';
import { UpdateCarAppointmentEventActionProxy } from './update-calendar-event.action-proxy';
import { AppointmentCarWorksComponent } from '../appointment-car-works/appointment-car-works.component';
import { AddEditCarInServiceWizardComponent } from '../../../car-service/components/add-edit-car-in-service-wizard/add-edit-car-in-service-wizard.component';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';
import {DeleteCarAppointmentActionProxy} from "./delete-car-appointment.action-proxy";


@Component({
  selector: 'app-add-edit-calendar-event-wizard',
  templateUrl: './add-edit-calendar-event-wizard.component.html',
  styleUrls: ['./add-edit-calendar-event-wizard.component.scss']
})
export class AddEditCalendarEventWizardComponent extends BaseFormComponent implements OnInit, OnDestroy {
  public registerCarAppointmentEventAP: RegisterCarAppointmentEventActionProxy;
  public updateCarAppointmentEventAP: UpdateCarAppointmentEventActionProxy;
  public getCarAppointmentEventAP: GetCarAppointmentActionProxy;
  public deleteCarAppointmentEventAP: DeleteCarAppointmentActionProxy;

  public onClose: Subject<boolean>;

  public event: CalendarEvent_Dto = null;
  public appointmentId: string = null;
  public title: string = '';
  public isUpdated = false;
  public initialStep: number = 0;
  public step: number = 0;
  public stepMin: number = 0;
  public stepMax: number = 4;
  public isClientStepValid = true;
  public isCarStepValid = true;
  public isAppointmentDetailsStepValid = true;
  public isAppointmentCarWorksStepValid = true;
  public isSummary = false;
  public carAppointment: CarAppointment_Dto;
  public model: CarAppointment_Dto;
  public carWorksInput: AppointmentCarWorks_Dto;

  public di: AddEditCalendarEventWizardDi;//we need to overwrite di with specific type for production build

  @ViewChild(WizardComponent, { static: false })
  public wizard: WizardComponent;

  @ViewChild(AppointmentClientComponent, { static: false })
  private appointmentClientFormComponent: AppointmentClientComponent;

  @ViewChild(AppointmentCarComponent, { static: false })
  private appointmentCarFormComponent: AppointmentCarComponent;

  @ViewChild(AppointmentCarWorksComponent, { static: false })
  private appointmentCarWorksFormComponent: AppointmentCarWorksComponent;

  @ViewChild(AppointmentDetailsComponent, { static: false })
  private appointmentDetailsFormComponent: AppointmentDetailsComponent;

  constructor(public appState: Store<store.State>,
              public sandbox: AddEditCalendarEventWizardSandbox,
              public activatedRoute: ActivatedRoute,
              private activeModal: BsModalRef,
              private modalService: BsModalService,
              public dateTimeService: DateTimeService,
              private spinner: NgxSpinnerService) {
    super(sandbox, ResetSection.SaveServicePlatform);
    this.registerCarAppointmentEventAP = new RegisterCarAppointmentEventActionProxy(this, sandbox.appState, spinner);
    this.updateCarAppointmentEventAP = new UpdateCarAppointmentEventActionProxy(this, sandbox.appState, spinner);
    this.getCarAppointmentEventAP = new GetCarAppointmentActionProxy(this, sandbox.appState);
    this.deleteCarAppointmentEventAP = new DeleteCarAppointmentActionProxy(this, sandbox.appState);
    this.di = new AddEditCalendarEventWizardDi(this.dateTimeService);
    this.model = new CarAppointment_Dto(null);
  }

  ngOnInit() {
    if (this.event && this.event.relatedEntityId) {
      this.isSummary = true;
      this.step = 4;
      this.getCarAppointmentEventAP.execute(this.event.relatedEntityId, (data: CarAppointment_Dto) => {
        this.carAppointment = data;
        this.di = new AddEditCalendarEventWizardDi(this.dateTimeService, data);
        this.carWorksInput = new AppointmentCarWorks_Dto(null);
        this.carWorksInput.carWorks = data.carWorks;
        this.carWorksInput.details = data.details;
      });
    } else {
      this.di = new AddEditCalendarEventWizardDi(this.dateTimeService);
    }

    if (this.event.id) {
      this.title = 'Modifica programare';
    } else {
      this.title = 'Adauga programare';
    }

    this.onClose = new Subject();
    this.dataLoaded = true;

    if (this.initialStep > 0) {
      this.step = this.initialStep;
      this.wizard.goToStep(this.initialStep);
    }

    this.initialize();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  appointmentDetailsFormSaved(data: CarAppointment_Dto) {
    if (ObjectValidators.isValidObject(data)) {
      this.model.startDate = data.startDate;
      this.model.endDate = data.endDate;
      this.model.mechanic = data.mechanic;
      this.model.servicePlatform = data.servicePlatform;
      this.model.paymentInAdvance = data.paymentInAdvance;
      this.model.paymentInAdvanceCurrency = data.paymentInAdvanceCurrency;
      this.model.details = data.details;
    }
  }

  clientDetailsFormSaved(data: AppointmentClient_Dto) {
    if (ObjectValidators.isValidObject(data))
      this.model.client = data;
  }

  carDetailsFormSaved(data: AppointmentCar_Dto) {
    if (ObjectValidators.isValidObject(data))
      this.model.car = data;
  }

  carWorksFormSaved(data: AppointmentCarWorks_Dto) {
    if (ObjectValidators.isValidObject(data))
      this.model.carWorks = data.carWorks;
      this.model.details = data.details;
  }

  updateModel() {
    this.model = this.appointmentDetailsFormComponent.di.getModel();
    this.model.client = this.appointmentClientFormComponent.di.getModel();
    this.model.car = this.appointmentCarFormComponent.di.getModel();
    const carWorksModel = this.appointmentCarWorksFormComponent.di.getModel();
    this.model.carWorks = carWorksModel.carWorks;
    this.model.details = carWorksModel.details;
  }

  goToNextStep() {
    this.isUpdated = true;
    this.step++;
    this.wizard.goToNextStep();
    this.updateValidation();
    this.updateModel();
    $('.scroll-container').animate({ scrollTop: 0 }, 'slow');
  }

  goToPreviousStep() {
    this.step--;
    this.wizard.goToPreviousStep();
    this.updateValidation();
    this.updateModel();
    $('.scroll-container').animate({ scrollTop: 0 }, 'slow');
  }

  goToStep(index: number) {
    this.isUpdated = true;
    this.isSummary = false;
    this.step = index;
    this.wizard.goToStep(index);
    this.updateValidation();
    this.updateModel();
    $('.scroll-container').animate({ scrollTop: 0 }, 'slow');
  }

  goToSummary() {
    this.formSubmitted = true;
    this.appointmentClientFormComponent.accept();
    this.appointmentCarFormComponent.accept();
    this.appointmentDetailsFormComponent.accept();
    this.appointmentCarWorksFormComponent.accept();
    this.updateValidation();
    if (this.isClientStepValid && this.isCarStepValid && this.isAppointmentDetailsStepValid && this.isAppointmentCarWorksStepValid) {
      this.di.model = this.model;
      this.isSummary = true;
    }
    $('.scroll-container').animate({ scrollTop: 0 }, 'slow');
  }

  updateValidation() {
    if (this.formSubmitted) {
      this.isClientStepValid = this.appointmentClientFormComponent.isValid();
      this.isCarStepValid = this.appointmentCarFormComponent.isValid();
      this.isAppointmentDetailsStepValid = this.appointmentDetailsFormComponent.isValid();
      this.isAppointmentCarWorksStepValid = this.appointmentCarWorksFormComponent.isValid();
      this.appointmentClientFormComponent.markFormAsDirtyAndTouched();
      this.appointmentCarFormComponent.markFormAsDirtyAndTouched();
      this.appointmentDetailsFormComponent.markFormAsDirtyAndTouched();
      this.appointmentCarWorksFormComponent.markFormAsDirtyAndTouched();
    }
  }

  saveForm() {
    if (this.isClientStepValid && this.isCarStepValid && this.isAppointmentDetailsStepValid && this.isAppointmentCarWorksStepValid) {
      this.di.model = this.model;

      if (this.di.model.id) {
        this.updateCarAppointmentEventAP.execute(() => {
          this.onClose.next(true);
          this.activeModal.hide();
        });
      } else {
        this.registerCarAppointmentEventAP.execute(() => {
          this.onClose.next(true);
          this.activeModal.hide();
        });
      }
    } else {
      $('.modal').animate({ scrollTop: 0 }, 'slow');
    }
  }

  addCarInService() {
    const carService = new CarService_Dto({
      car: {
        id: this.carAppointment.car.carRelatedId,
        registrationNumber: this.carAppointment.car.registrationNumber,
        bodySeries: this.carAppointment.car.bodySeries,
        make: this.carAppointment.car.make,
        model: this.carAppointment.car.model
      },
      client: {
        id: this.carAppointment.client.clientId,
        phoneNumber: this.carAppointment.client.phoneNumber,
        name: this.carAppointment.client.name
      },
      carServiceEntryData: {
        clientCarWorksRequests: this.carAppointment.carWorks,
        appointmentDetails: this.carAppointment.details
      }
    });
    const initialState = {
      carService,
      appointmentId: this.carAppointment.id,
      title: 'Adauga masina in service',
      isFromAppointment: true
    };
    const modalRef = this.modalService.show(AddEditCarInServiceWizardComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
    setTimeout(() => {
      this.onClose.next(true);
      this.activeModal.hide();
    }, 1000);
  }

  deleteCarAppointment(){
    this.deleteCarAppointmentEventAP.execute(this.carAppointment.id, ()=>{
      this.onClose.next(true);
      this.activeModal.hide();
    });
  }
}
