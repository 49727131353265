import { Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import { map, switchMap } from 'rxjs/operators';
import {
  Do_GenericSuccessEvent,
  Do_GenericErrorEvent,
  EventWithScreenLoading,
  SeamlessEvent
} from 'src/app/core/app-store/events/base.events';
import { PagedResult } from 'src/app/core/shared/datatable/datatable.helpers';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { Mechanic_Dto } from 'src/app/core/app-dto/mechanic.dto';
import { MechanicApiService } from 'src/app/core/api-integration/services/mechanic-api.service';
import {
  ActivateMechanicEvent,
  DeactivateMechanicEvent, DeleteMechanicEvent, EditMechanicEvent,
  GetMechanicEvent,
  GetMechanicsPagedResultEvent, RegisterMechanicEvent, GetMechanicListEvent
} from '../../core/app-store/events/mechanic.events';
import {isValidObject} from "../../core/shared/helpers/common.helpers";
import {ReportingApiService} from "../../core/api-integration/services/reporting-api.service";
import {
  GetMechanicsReportingEvent,
  GetOrdersReportingEvent,
  GetProfitReportingEvent, GetProvidersListReportingEvent,
  GetProvidersReportingEvent
} from "../../core/app-store/events/reporting.events";
import {
  ReportingItem, ReportingListProfitItem, ReportingMechanicItem,
  ReportingProviderItem,
  ReportingSet
} from "../../core/app-dto/reporting.dto";
import {
  GetAllCarServiceEntriesForReportingEvent,
  GetCarServiceEntriesPagedResultEvent
} from "../../core/app-store/events/car-service.events";
import {CarService_Dto} from "../../core/app-dto/car-service.dto";
import {AllPagesLoader} from "../../core/shared/all-pages.loader";


@Injectable()
export class ReportingUIEventProcessor {

  constructor(
    private actions$: Actions,
    private appState$: Store<store.State>,
    private apiService: ReportingApiService
  ) {
  }

  @Effect()
  GetProfitReportEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetProfitReportingEvent.identifier, SeamlessEvent.identifier + GetProfitReportingEvent.identifier),
      map((action: GetProfitReportingEvent) => action),
      switchMap((action: GetProfitReportingEvent) => {
        return new Observable((observer) => {
          this.apiService.u_getProfitReport(action.groupingOption, action.startDate, action.endDate)
            .subscribe((res: ReportingSet<ReportingListProfitItem>) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<ReportingSet<ReportingListProfitItem>>(res, action));
              }
              if(action.callback) action.callback();
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );
  @Effect()
  GetProvidersReportEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetProvidersReportingEvent.identifier, SeamlessEvent.identifier + GetProvidersReportingEvent.identifier),
      map((action: GetProvidersReportingEvent) => action),
      switchMap((action: GetProvidersReportingEvent) => {
        return new Observable((observer) => {
          this.apiService.u_getProvidersReport( action.startDate, action.endDate, action.providerId)
            .subscribe((res: ReportingSet<ReportingProviderItem>) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<ReportingSet<ReportingProviderItem>>(res, action));
              }
              if(action.callback) action.callback();
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );
  @Effect()
  GetProvidersListReportEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetProvidersListReportingEvent.identifier, SeamlessEvent.identifier + GetProvidersListReportingEvent.identifier),
      map((action: GetProvidersListReportingEvent) => action),
      switchMap((action: GetProvidersListReportingEvent) => {
        return new Observable((observer) => {
          this.apiService.u_getProvidersListReport( action.startDate, action.endDate, action.providerId)
            .subscribe((res: ReportingSet<ReportingProviderItem>) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<ReportingSet<ReportingProviderItem>>(res, action));
              }
              if(action.callback) action.callback();
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );
  @Effect()
  GetOrdersReportEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetOrdersReportingEvent.identifier, SeamlessEvent.identifier + GetOrdersReportingEvent.identifier),
      map((action: GetOrdersReportingEvent) => action),
      switchMap((action: GetOrdersReportingEvent) => {
        return new Observable((observer) => {
          this.apiService.u_getOrdersReport(action.groupingOption, action.startDate, action.endDate)
            .subscribe((res: ReportingSet<ReportingItem>) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<ReportingSet<ReportingItem>>(res, action));
              }
              if(action.callback) action.callback();
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );
  @Effect()
  GetMechanicsReportEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetMechanicsReportingEvent.identifier, SeamlessEvent.identifier + GetMechanicsReportingEvent.identifier),
      map((action: GetMechanicsReportingEvent) => action),
      switchMap((action: GetMechanicsReportingEvent) => {
        return new Observable((observer) => {
          this.apiService.u_getMechanicsReport(action.startDate, action.endDate)
            .subscribe((res: ReportingSet<ReportingMechanicItem>) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<ReportingSet<ReportingMechanicItem>>(res, action));
              }
              if(action.callback) action.callback();
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  GetAllCarServiceEntriesPagedResultEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetAllCarServiceEntriesForReportingEvent.identifier, SeamlessEvent.identifier + GetAllCarServiceEntriesForReportingEvent.identifier),
      map((action: GetAllCarServiceEntriesForReportingEvent) => action),
      switchMap((action: GetAllCarServiceEntriesForReportingEvent) => {
        return new Observable((observer) => {
          
          let callback = this.apiService.u_getCarServiceEntriesPaginated.bind(this.apiService);
          AllPagesLoader.run<CarService_Dto>(callback,action.parameters, action.startDate, action.endDate)
            .subscribe((res: Array<CarService_Dto>) => {
              
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<Array<CarService_Dto>>(res, action));
              }
              if(action.callback) action.callback();
              observer.complete();
            }, (error: string) => {
              if(action.callback) action.callback();
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );
}
