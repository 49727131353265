import {Observable, of} from "rxjs";
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Injectable} from "@angular/core";
import * as store from "../../app-store";
import "rxjs-compat/add/operator/switchMap";
import {catchError, map, mapTo, switchMap} from "rxjs/operators";
import {
    ForgotPasswordEvent,
    LoginEvent,
    LogoutEvent, ResetPasswordEvent, ActivateSubscriptionEvent, RequestSubscriptionEvent, GenerateActivationLinkEvent
} from "../../app-store/events/authentication.events";
import {AuthApiService} from '../../api-integration/services/auth-api.service';
import {EventWithScreenLoading, Do_GenericSuccessEvent, Do_GenericErrorEvent} from '../../app-store/events/base.events';
import {LoggedUser_Dto} from "../../app-dto/core.dto";
import {Router} from "@angular/router";
import {NotifierService} from "angular-notifier";
import {ObjectValidators} from "../../shared/object.validators";


@Injectable()
export class AuthenticationUIEventProcessor {

    public redirectUrl: string;

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private authApiService: AuthApiService,
        private router: Router,
        private notifierService: NotifierService
    ) {
    }

    @Effect()
    doLogin$
        :
        Observable<any> = this.actions$
        .pipe(
            ofType(LoginEvent.identifier, EventWithScreenLoading.identifier + LoginEvent.identifier),
            map((action: LoginEvent) => action),
            switchMap((action: LoginEvent) => {
                    return new Observable((observer) => {
                        this.authApiService.u_authenticate(action.form)
                            .subscribe((res: LoggedUser_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<LoggedUser_Dto>(res, action));
                                observer.complete();
                                if (!ObjectValidators.isValidString(this.redirectUrl))
                                    this.redirectUrl = '/dashboard';
                                this.router.navigate([this.redirectUrl]);
                                this.redirectUrl = null;
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doForgotPassword$
        :
        Observable<any> = this.actions$
        .pipe(
            ofType(ForgotPasswordEvent.identifier, EventWithScreenLoading.identifier + ForgotPasswordEvent.identifier),
            map((action: ForgotPasswordEvent) => action),
            switchMap((action: ForgotPasswordEvent) => {
                    return new Observable((observer) => {
                        this.authApiService.u_forgotPassword(action.form)
                            .subscribe((res: any) => {
                                observer.next(new Do_GenericSuccessEvent<string>('', action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doResetPassword$
        :
        Observable<any> = this.actions$
        .pipe(
            ofType(ResetPasswordEvent.identifier, EventWithScreenLoading.identifier + ResetPasswordEvent.identifier),
            map((action: ResetPasswordEvent) => action),
            switchMap((action: ResetPasswordEvent) => {
                    return new Observable((observer) => {
                        this.authApiService.u_resetPassword(action.form)
                            .subscribe((res: any) => {
                                observer.next(new Do_GenericSuccessEvent<string>('', action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doLogout$
        :
        Observable<any> = this.actions$
        .pipe(
            ofType(LogoutEvent.identifier, EventWithScreenLoading.identifier + LogoutEvent.identifier),
            map((action: LogoutEvent) => action),
            switchMap((action: LogoutEvent) => {
                    return new Observable((observer) => {
                        if (action.shouldDoApiRequest) {
                            this.authApiService.u_logout()
                                .subscribe((response: any) => {
                                    observer.next(new Do_GenericSuccessEvent<any>(null, action));
                                    observer.complete();
                                });
                        } else {
                            observer.next(new Do_GenericSuccessEvent<any>(null, action));
                            observer.complete();
                        }
                    });
                }
            )
        );

    @Effect()
    doRequestSubscription$
        :
        Observable<any> = this.actions$
        .pipe(
            ofType(RequestSubscriptionEvent.identifier, EventWithScreenLoading.identifier + RequestSubscriptionEvent.identifier),
            map((action: RequestSubscriptionEvent) => action),
            switchMap((action: RequestSubscriptionEvent) => {
                    return new Observable((observer) => {
                        this.authApiService.u_requestSubscription(action.form)
                            .subscribe((res: any) => {
                                observer.next(new Do_GenericSuccessEvent<string>('', action));
                                observer.complete();
                                this.notifierService.notify('success', 'Felicitari! Contul dumneavoastra a fost inregistrat si veti primi un email cu pasii urmatori!');
                                this.router.navigate(["/auth/login"]);
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doActivateSubscription$
        :
        Observable<any> = this.actions$
        .pipe(
            ofType(ActivateSubscriptionEvent.identifier, EventWithScreenLoading.identifier + ActivateSubscriptionEvent.identifier),
            map((action: ActivateSubscriptionEvent) => action),
            switchMap((action: ActivateSubscriptionEvent) => {
                    return new Observable((observer) => {
                        this.authApiService.u_activateSubscription(action.form)
                            .subscribe((res: any) => {
                                observer.next(new Do_GenericSuccessEvent<string>('', action));
                                observer.complete();
                                this.notifierService.notify('success', 'Felicitari! Contul dumneavoastra a fost activat!');
                                this.router.navigate(['/auth/login']);
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doGenerateNewActivationLink$
        :
        Observable<any> = this.actions$
        .pipe(
            ofType(GenerateActivationLinkEvent.identifier, EventWithScreenLoading.identifier + GenerateActivationLinkEvent.identifier),
            map((action: GenerateActivationLinkEvent) => action),
            switchMap((action: GenerateActivationLinkEvent) => {
                    return new Observable((observer) => {
                        this.authApiService.u_generateActivationLink(action.form)
                            .subscribe((res: any) => {
                                observer.next(new Do_GenericSuccessEvent<string>('Cererea ta a fost inregistrata! Vei primi un email cu noul link de activare!', action));
                                observer.complete();
                                this.notifierService.notify('success', 'Cererea dumneavoastra a fost inregistrata! Vei primi un email cu noul link de activare!');
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );
}
