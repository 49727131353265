import { Component, OnInit } from '@angular/core';
import { AppUserBusinessRuleProvider } from '../../core/business-rule-provider/app-user.business-rule.provider';
import { HeaderSandbox } from './header.sandbox';
import { BaseWebComponent } from '../../shared/base/base.component';
import { LoggedUser_Dto } from '../../app-dto/core.dto';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseWebComponent implements OnInit {
  public imageUrl: string = '';
  public currentOrganizationName: string = '';
  public currentUserName = '';

  constructor(public sandbox: HeaderSandbox,
              public rulesProvider: AppUserBusinessRuleProvider) {
    super(sandbox);
  }

  ngOnInit() {
    this.initialize((data: LoggedUser_Dto) => {
      this.imageUrl = data.displayImageUrl;
      this.currentUserName = `${data.firstName} ${data.lastName}`;
      this.currentOrganizationName = data.organization != null ? data.organization.name : '';
    });
  }
}
