import {SeamlessEvent, UIGeneratedEvent} from "../../app-store/events/base.events";
import {
    AddPaymentMethod, CreateAnonymousCardSetupSession,
    CreateCardSetupSession,
    CreateCheckoutSession,
    StripeConfigResponse,
    UpdatePaymentMethod
} from "../dto/stripe.dto";

export class GetStripeConfigEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = "GetStripeConfigEvent";

    constructor(public callback: (result: StripeConfigResponse) => void) {
        super();
        this.type += GetStripeConfigEvent.identifier;
    }
}

export class CreateStripeCheckoutPaymentEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = "CreateStripeCheckoutPaymentEvent";

    constructor(public data: CreateCheckoutSession, public callback: (result: string) => void) {
        super();
        this.type += CreateStripeCheckoutPaymentEvent.identifier;
    }
}

export class AddPaymentMethodEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = "AddPaymentMethodEvent";

    constructor(public data: AddPaymentMethod, public callback: (result: string) => void) {
        super();
        this.type += AddPaymentMethodEvent.identifier;
    }
}

export class UpdatePaymentMethodEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = "UpdatePaymentMethodEvent";

    constructor(public data: UpdatePaymentMethod, public callback: (result: string) => void) {
        super();
        this.type += UpdatePaymentMethodEvent.identifier;
    }
}

export class CreateCardSetupEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = "CreateCardSetupEvent";

    constructor(public data: CreateCardSetupSession, public callback: (result: string) => void) {
        super();
        this.type += CreateCardSetupEvent.identifier;
    }
}

export class CreateAnonymousCardSetupEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = "CreateAnonymousCardSetupEvent";

    constructor(public data: CreateAnonymousCardSetupSession, public callback: (result: string) => void) {
        super();
        this.type += CreateAnonymousCardSetupEvent.identifier;
    }
}





