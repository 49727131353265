import { State } from '../state';
import * as fromCarService from '../stores/car-service.store';
import { createSelector } from '@ngrx/store';

export const getCarServiceStateCallback = (state: State) => state.carService;

export const getCarServiceTimestamp = createSelector(getCarServiceStateCallback, fromCarService.getTimestampCallback);
export const getCarService = createSelector(getCarServiceStateCallback, fromCarService.getCarServiceCallback);
export const getCarServiceListing = createSelector(getCarServiceStateCallback, fromCarService.getCarServicePagedResultCallback);
export const getCarServiceActionListing = createSelector(getCarServiceStateCallback, fromCarService.getCarServiceActionsCallback);
export const getCarServiceEntriesListing = createSelector(getCarServiceStateCallback, fromCarService.getCarServiceEntriesCallback);
export const getCarServiceUsersListing = createSelector(getCarServiceStateCallback, fromCarService.getCarServiceUsersCallback);
