import {Injectable} from "@angular/core";
import {BaseApiService} from "./base-api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiErrorDto} from "../utils/api-error.dto";
import {ConfigService} from '../../core/app-config.service';
import {
  Login_Dto,
  ForgotPassword_Dto,
  ResetPassword_Dto,
  ChangePassword_Dto,
  RequestSubscription_Dto,
  ActivateSubscription_Dto
} from '../../app-dto/authentication.dto';
import {LoggedUser_Dto} from '../../app-dto/core.dto';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {CachingUtils} from '../../shared/caching.utils';
import {AppUserBusinessRule} from "../../core/business-rule-provider/app-user.business-rule.provider";
import { ErrorHelpers } from '../../shared/error.helpers';

class AuthApiRoutes {
  public static route_for_USER_LOGIN() {
    return '/api/account/login';
  }

  public static route_for_USER_FORGOT_PASSWORD() {
    return '/api/account/forgotPassword';
  }

  public static route_for_USER_RESET_PASSWORD() {
    return '/api/account/resetPassword';
  }

  public static route_for_USER_LOGOUT() {
    return '/api/account/logout';
  }


  public static route_for_CHANGE_PASSWORD() {
    return '/api/account/change-password';
  }

  public static route_for_REQUEST_SUBSCRIPTION() {
    return '/api/account/registerSubscription';
  }

  public static route_for_ACTIVATE_SUBSCRIPTION() {
    return '/api/account/activationLink';
  }
  public static route_for_GENERATE_ACTIVATION_LINK() {
    return '/api/account/activationLink/new';
  }

  public static route_for_USER_RULES() {
    return '/api/account/rules';
  }

}

@Injectable()
export class AuthApiService extends BaseApiService {

  constructor(private http: HttpClient, configService: ConfigService) {
    super(configService);
  }

  u_authenticate(loginData: Login_Dto): Observable<LoggedUser_Dto> {
    const url = this.configService.getAPIBaseUrl() + AuthApiRoutes.route_for_USER_LOGIN();
    let newData = Object.assign({}, loginData, {
      clientId: this.configService.getAPIClientId(),
      clientSecret: this.configService.getAPIClientSecret()
    });

    return this.http.post(url, newData
    )
      .map((res: any) => {
        return new LoggedUser_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_forgotPassword(data: ForgotPassword_Dto): Observable<any> {
    const url = this.configService.getAPIBaseUrl() + AuthApiRoutes.route_for_USER_FORGOT_PASSWORD();
    let newData = Object.assign({}, data, {
      clientId: this.configService.getAPIClientId(),
      clientSecret: this.configService.getAPIClientSecret()
    })
    return this.http.post(url, newData)
      .map(res => {
        return {};
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_resetPassword(data: ResetPassword_Dto): Observable<any> {
    const url = this.configService.getAPIBaseUrl() + AuthApiRoutes.route_for_USER_RESET_PASSWORD();
    let newData = Object.assign({}, data, {
      clientId: this.configService.getAPIClientId(),
      clientSecret: this.configService.getAPIClientSecret()
    })
    return this.http.post(url, newData)
      .map(res => {
        return {};
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_logout(): Observable<any> {
    const url = this.configService.getAPIBaseUrl() + AuthApiRoutes.route_for_USER_LOGOUT();

    let user: LoggedUser_Dto = CachingUtils.loadData(LoggedUser_Dto.identifier);
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.token
    });

    return this.http.get(url, {headers: headers})
      .map(res => {
        return {};
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_changePassword(data: ChangePassword_Dto): Observable<any> {
    const url = this.configService.getAPIBaseUrl() + AuthApiRoutes.route_for_CHANGE_PASSWORD();

    return this.http.post(url, data)
      .map((res: any) => {
        return {};
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_requestSubscription(data: RequestSubscription_Dto): Observable<any> {
    const url = this.configService.getAPIBaseUrl() + AuthApiRoutes.route_for_REQUEST_SUBSCRIPTION();

    return this.http.post(url, data)
      .map(res => {
        return {};
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_activateSubscription(data: ActivateSubscription_Dto): Observable<any> {
    const url = this.configService.getAPIBaseUrl() + AuthApiRoutes.route_for_ACTIVATE_SUBSCRIPTION();

    return this.http.post(url, data)
      .map(res => {
        return {};
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_generateActivationLink(data: ForgotPassword_Dto): Observable<any> {
    const url = this.configService.getAPIBaseUrl() + AuthApiRoutes.route_for_GENERATE_ACTIVATION_LINK();
    let newData = Object.assign({}, data, {
      clientId: this.configService.getAPIClientId(),
      clientSecret: this.configService.getAPIClientSecret()
    })

    return this.http.post(url, newData
    )
      .map((res: any) => {
        return {};
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getRules(): Observable<any> {
    const url = this.configService.getAPIBaseUrl() + AuthApiRoutes.route_for_USER_RULES();

    let user: LoggedUser_Dto = CachingUtils.loadData(LoggedUser_Dto.identifier);
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.token
    });

    return this.http.get(url, {headers: headers})
      .map(res => {
        return new AppUserBusinessRule(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

}
