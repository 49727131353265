import { BaseDI } from 'src/app/core/shared/base/base.di';
import { CarPart_Dto } from '../../../core/app-dto/car-part.dto';

export class CarPartDetailsDi extends BaseDI<CarPart_Dto> {
    constructor(data: CarPart_Dto = new CarPart_Dto(null)) {
        super(data);
    }

    getModel(): CarPart_Dto {
        return this.model;
    }
}
