import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormGroup } from '@angular/forms';
import { AppointmentClient_Dto } from '../../../core/app-dto/appointment-client.dto';
import { CarWork_Dto } from '../../../core/app-dto/car-work.dto';
import {isValidArrayAndHasElements} from "../../../core/shared/helpers/common.helpers";

export class CarWorksListDi extends BaseFormDI<Array<CarWork_Dto>> {

    constructor(data: Array<CarWork_Dto> =[]) {
        super(isValidArrayAndHasElements(data) ? data : []);
        this.initForm();
    }

    getModel(): Array<CarWork_Dto> {
        return this.model;
    }

    initForm() {
        this.form = new FormGroup({});
    }
}
