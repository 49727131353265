import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CalendarNavigationSandbox } from './calendar-navigation.sandbox';
import { Moment } from 'moment';
import * as moment from 'moment';
import { CalendarView } from 'angular-calendar';

@Component({
  selector: 'app-calendar-navigation',
  templateUrl: './calendar-navigation.component.html',
  styleUrls: ['./calendar-navigation.component.scss']
})
export class CalendarNavigationComponent implements OnInit {
  public currentDateLabel: string = '';

  @Input() calendarNavigation: CalendarNavigation;
  @Output() onChange: EventEmitter<CalendarNavigation> = new EventEmitter<CalendarNavigation>();

  constructor(public sandbox: CalendarNavigationSandbox) {
  }

  ngOnInit() {
    this.calendarNavigation.currentDate = this.calendarNavigation.currentDate.locale('ro');
    this.currentDateLabel = this.calendarNavigation.currentDate.format('dddd, DD MMMM YYYY');
  }

  onDateChange($event) {
    this.calendarNavigation.currentDate = moment($event).locale('ro');
    this.currentDateLabel = this.calendarNavigation.currentDate.format('dddd, DD MMMM YYYY');
    this.onChange.emit(this.calendarNavigation);
  }

  goBack() {
    this.calendarNavigation.currentDate = this.calendarNavigation.currentDate.add(-1, 'days');
    this.currentDateLabel = this.calendarNavigation.currentDate.format('dddd, DD MMMM YYYY');
    this.onChange.emit(this.calendarNavigation);
  }

  goForward() {
    this.calendarNavigation.currentDate.add(1, 'days');
    this.currentDateLabel = this.calendarNavigation.currentDate.format('dddd, DD MMMM YYYY');
    this.onChange.emit(this.calendarNavigation);
  }

  goToday() {
    this.calendarNavigation.currentDate = moment().locale('ro');
    this.currentDateLabel = this.calendarNavigation.currentDate.format('dddd, DD MMMM YYYY');
    this.onChange.emit(this.calendarNavigation);
  }

  setDayView() {
    this.calendarNavigation.view = CalendarView.Day;
    this.onChange.emit(this.calendarNavigation);
  }

  setWeekView() {
    this.calendarNavigation.view = CalendarView.Week;
    this.onChange.emit(this.calendarNavigation);
  }

  isWeekView() {
    return this.calendarNavigation.view === CalendarView.Week;
  }

  isDayView() {
    return this.calendarNavigation.view === CalendarView.Day;
  }
}

export enum CalendarViewType {
  Horizontal,
  Vertical,
  Table
}

export class CalendarNavigation {
  public currentDate: Moment;
  public view: CalendarView;
  public viewType: CalendarViewType;
}

