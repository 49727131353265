import {Injectable} from "@angular/core";
import {BaseApiService} from "./base-api.service";
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "../../core/app-config.service";
import {Observable} from "rxjs";
import {ApiErrorDto} from "../utils/api-error.dto";
import {ErrorHelpers} from "../../shared/error.helpers";
import {AppNotification} from '../../app-dto/core.dto';
import {PagedResult} from "../../shared/datatable/datatable.helpers";
import {Car_Dto} from "../../app-dto/car.dto";
import {NotificationDataTableParameters} from "../../app-store/events/notification.events";

class AppNotificationsApiRoutes {
  public static route_for_GET_PAGINATED() {
    return '/api/appnotification/all';
  }

  public static route_for_DELETE_NOTIFICATION(notificationId: string) {
    return `/api/appnotification/${notificationId}`;
  }

  public static route_for_MARK_AS_READ_NOTIFICATION(notificationId: string) {
    return `/api/appnotification/${notificationId}/read`;
  }

  public static route_for_MARK_AS_NOT_READ_NOTIFICATION(notificationId: string) {
    return `/api/appnotification/${notificationId}/not-read`;
  }
  public static route_for_GET_NO_OF_UNREAD_NOTIFICATIONS() {
    return `/api/appnotification/not-read/count`;
  }
}

@Injectable()
export class AppNotificationService extends BaseApiService {

  constructor(private http: HttpClient, configService: ConfigService) {
    super(configService);
  }

  u_getFilteredNotificationPaginated(data: NotificationDataTableParameters): Observable<PagedResult<AppNotification>> {
    const url = this.configService.getAPIBaseUrl() + AppNotificationsApiRoutes.route_for_GET_PAGINATED() + '?' + data.toUrlParameters();

    return this.http.get(url)
      .map((res: PagedResult<AppNotification>) => {
        return new PagedResult<AppNotification>(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_deleteNotification(notificationId: string): Observable<string> {
    const url = this.configService.getAPIBaseUrl() + AppNotificationsApiRoutes.route_for_DELETE_NOTIFICATION(notificationId);

    return this.http.delete(url)
      .map((res: any) => {
        return notificationId;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
       return ErrorHelpers.throwError(err);
      });
  }

  u_markNotificationAsRead(notificationId: string): Observable<AppNotification> {
    const url = this.configService.getAPIBaseUrl() + AppNotificationsApiRoutes.route_for_MARK_AS_READ_NOTIFICATION(notificationId);

    return this.http.put(url,{})
      .map((res: any) => {
        return new AppNotification(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }
  u_markNotificationAsNotRead(notificationId: string): Observable<AppNotification> {
    const url = this.configService.getAPIBaseUrl() + AppNotificationsApiRoutes.route_for_MARK_AS_NOT_READ_NOTIFICATION(notificationId);

    return this.http.put(url,{})
      .map((res: any) => {
        return new AppNotification(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getNoOfUnreadNotifications(): Observable<number> {
    const url = this.configService.getAPIBaseUrl() + AppNotificationsApiRoutes.route_for_GET_NO_OF_UNREAD_NOTIFICATIONS();

    return this.http.get(url)
      .map((res: any) => {
        return res;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }
}

