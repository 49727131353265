import {Action} from '@ngrx/store';
import {
    Do_GenericErrorEvent,
    Do_GenericSuccessEvent,
    Do_ResetStateForSection,
    EventWithScreenLoading,
    EventWithStopingScreenLoader,
    ResetSection,
    SeamlessEvent
} from '../events/base.events';
import {ResetStateActionProxy} from '../../shared/base/base.action-proxy';
import * as moment from 'moment';
import {ObjectValidators} from "../../shared/object.validators";
import {CashRegister_Dto} from "../../app-dto/cash-register.dto";
import {
    Do_Deactivate_CashRegister_Event,
    Do_Delete_All_PrintJob_Event,
    Do_Delete_CashRegister_Event,
    Do_Delete_PrintJob_Event,
    Do_Edit_CashRegister_Event,
    Do_GetActive_CashRegisters_Event,
    Do_GetAll_CashRegisters_AsPaginated_Event,
    Do_GetAll_PrintJobs_AsPaginated_Event,
    Do_GetOne_CashRegister_Event,
    Do_ResetActivation_For_CashRegister_Event,
    Do_Register_CashRegister_Event, Do_Register_PrintJob_Event
} from "../events/cash-register.events";
import {InvoicePrintJob_Dto} from "../../app-dto/invoice-print-job.dto";
import {PagedResult} from "../../shared/datatable/datatable.helpers";
import {
    BASE_EXECUTING_STATE, BASE_FAILED_STATE,
    BASE_INITIAL_STATE,
    BASE_SUCCEEDED_STATE,
    BaseComponentState
} from "../utils/base-component.state";

export interface CashRegisterState {
    cashRegistersLastRefreshTime: number;
    pagedCashRegisters: PagedResult<CashRegister_Dto>;
    activeCashRegisters: CashRegister_Dto[];
    cashRegister: CashRegister_Dto;
    printJobsLastRefreshTime: number;
    pagedPrintJobs: PagedResult<InvoicePrintJob_Dto>;
    registerPrintJob: BaseComponentState;
}

export const INITIAL_STATE: CashRegisterState = Object.assign({}, {
    cashRegistersLastRefreshTime: 0,
    pagedCashRegisters: new PagedResult<CashRegister_Dto>([]),
    activeCashRegisters: null,
    cashRegister: null,
    printJobsLastRefreshTime: 0,
    pagedPrintJobs: new PagedResult<InvoicePrintJob_Dto>([]),
    registerPrintJob: BASE_INITIAL_STATE
});

export function reducer(state = INITIAL_STATE, action: Action): CashRegisterState {
    if (!action) return state;
    let actionType = action.type;

    actionType = actionType.replace(EventWithScreenLoading.identifier, '');
    actionType = actionType.replace(SeamlessEvent.identifier, '');
    actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
    switch (actionType) {
        /* Reset list section */
        case Do_ResetStateForSection.identifier + ResetSection.CashRegisters: {
            return Object.assign({}, state, {pagedCashRegisters: new PagedResult<CashRegister_Dto>([])});
        }

        /* Reset details section */
        case Do_ResetStateForSection.identifier + ResetSection.CashRegisterDetails: {
            return Object.assign({}, state, {
                cashRegister: null,
                pagedPrintJobs: new PagedResult<InvoicePrintJob_Dto>([])
            });
        }

        /* Get All cash registers */
        case Do_GetAll_CashRegisters_AsPaginated_Event.identifier: {
            if ((action as Do_GetAll_CashRegisters_AsPaginated_Event).withReset) {
                state.pagedCashRegisters = new PagedResult<CashRegister_Dto>([]);
                return Object.assign({}, state);
            }
            return state;
        }
        case Do_GenericSuccessEvent.identifier + Do_GetAll_CashRegisters_AsPaginated_Event.identifier: {
            const data = (action as Do_GenericSuccessEvent<PagedResult<CashRegister_Dto>>).data;
            state.pagedCashRegisters = new PagedResult<CashRegister_Dto>(data);
            return Object.assign({}, state);
        }
        case Do_GenericErrorEvent.identifier + Do_GetAll_CashRegisters_AsPaginated_Event.identifier: {
            state.pagedCashRegisters = new PagedResult<CashRegister_Dto>([]);
            return Object.assign({}, state);
        }

        /* Get active cash registers */
        case Do_GenericSuccessEvent.identifier + Do_GetActive_CashRegisters_Event.identifier: {
            const data = (action as Do_GenericSuccessEvent<CashRegister_Dto[]>).data;
            state.activeCashRegisters = data.map(x => x);
            return Object.assign({}, state);
        }
        case Do_GenericErrorEvent.identifier + Do_GetActive_CashRegisters_Event.identifier: {
            state.activeCashRegisters = null;
            return Object.assign({}, state);
        }

        /* Get one cash register */
        case Do_GenericSuccessEvent.identifier + Do_GetOne_CashRegister_Event.identifier: {
            const incomingData = new CashRegister_Dto((action as Do_GenericSuccessEvent<CashRegister_Dto>).data);
            return Object.assign({}, state, {cashRegister: incomingData});
        }
        case Do_GenericErrorEvent.identifier + Do_GetOne_CashRegister_Event.identifier: {
            return Object.assign({}, state, {cashRegister: null});
        }
        case ResetStateActionProxy.name + Do_GetOne_CashRegister_Event.identifier: {
            return Object.assign({}, state, {
                cashRegister: null,
                pagedPrintJobs: new PagedResult<InvoicePrintJob_Dto>([])
            });
        }

        /* Register cash register */
        case Do_GenericSuccessEvent.identifier + Do_Register_CashRegister_Event.identifier: {
            state.cashRegistersLastRefreshTime = moment().unix();
            return Object.assign({}, state);
        }

        /* Edit, reset activation, deactivate cash register */
        case Do_GenericSuccessEvent.identifier + Do_Edit_CashRegister_Event.identifier:
        case Do_GenericSuccessEvent.identifier + Do_ResetActivation_For_CashRegister_Event.identifier:
        case Do_GenericSuccessEvent.identifier + Do_Deactivate_CashRegister_Event.identifier: {
            const incomingData = new CashRegister_Dto((action as Do_GenericSuccessEvent<CashRegister_Dto>).data);
            if (ObjectValidators.isValidObject(state.cashRegister) && state.cashRegister.id == incomingData.id) {
                state.cashRegister = incomingData;
            }
            state.cashRegistersLastRefreshTime = moment().unix();
            return Object.assign({}, state);
        }

        /* Delete cash register */
        case Do_GenericSuccessEvent.identifier + Do_Delete_CashRegister_Event.identifier: {
            state.cashRegistersLastRefreshTime = moment().unix();
            return Object.assign({}, state);
        }

        /* Get print jobs for one cash register */
        case Do_GetAll_PrintJobs_AsPaginated_Event.identifier: {
            if ((action as Do_GetAll_PrintJobs_AsPaginated_Event).withReset) {
                state.pagedPrintJobs = new PagedResult<InvoicePrintJob_Dto>([]);
                return Object.assign({}, state);
            }
            return state;
        }
        case Do_GenericSuccessEvent.identifier + Do_GetAll_PrintJobs_AsPaginated_Event.identifier: {
            const data = (action as Do_GenericSuccessEvent<PagedResult<InvoicePrintJob_Dto>>).data;
            state.pagedPrintJobs = new PagedResult<InvoicePrintJob_Dto>(data);
            return Object.assign({}, state);
        }
        case Do_GenericErrorEvent.identifier + Do_GetAll_PrintJobs_AsPaginated_Event.identifier: {
            state.pagedPrintJobs = new PagedResult<InvoicePrintJob_Dto>([]);
            return Object.assign({}, state);
        }

        /* Delete print job(s) for one cash register */
        case Do_GenericSuccessEvent.identifier + Do_Delete_PrintJob_Event.identifier:
        case Do_GenericSuccessEvent.identifier + Do_Delete_All_PrintJob_Event.identifier: {
            state.printJobsLastRefreshTime = moment().unix();
            return Object.assign({}, state);
        }

        /* Register print job */
        case Do_Register_PrintJob_Event.identifier: {
            state.registerPrintJob = BASE_EXECUTING_STATE;
            return Object.assign({}, state);
        }
        case Do_GenericSuccessEvent.identifier + Do_Register_PrintJob_Event.identifier: {
            state.registerPrintJob = BASE_SUCCEEDED_STATE;
            return Object.assign({}, state);
        }
        case Do_GenericErrorEvent.identifier + Do_Register_PrintJob_Event.identifier: {
            const newState = BASE_FAILED_STATE;
            newState.stateMessage = (<Do_GenericErrorEvent<string>>action).data;
            return Object.assign({}, state, {registerPrintJob: newState});
        }

        default: {
            return state;
        }
    }
}

export const getCashRegistersTimestampCallback = (state: CashRegisterState) => state.cashRegistersLastRefreshTime;
export const getCashRegistersPagedResultCallback = (state: CashRegisterState) => state.pagedCashRegisters;
export const getAllActiveCashRegistersCallback = (state: CashRegisterState) => state.activeCashRegisters;
export const getCashRegisterCallback = (state: CashRegisterState) => state.cashRegister;
export const getPrintJobsTimestampCallback = (state: CashRegisterState) => state.printJobsLastRefreshTime;
export const getPrintJobsPagedResultCallback = (state: CashRegisterState) => state.pagedPrintJobs;
export const getRegisterPrintJobCallback = (state: CashRegisterState) => state.registerPrintJob;
