import { Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import { map, switchMap } from 'rxjs/operators';
import { Do_GenericSuccessEvent, Do_GenericErrorEvent, EventWithScreenLoading, SeamlessEvent } from 'src/app/core/app-store/events/base.events';
import { PagedResult } from 'src/app/core/shared/datatable/datatable.helpers';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { OrganizationClient_Dto } from 'src/app/core/app-dto/organization-client.dto';
import {
    DeleteOrganizationClientEvent,
    EditOrganizationClientEvent, GetInfoFromCuiAnafEvent,
    GetOrganizationClientCreditEvent,
    GetOrganizationClientEvent,
    GetOrganizationClientsPagedResultEvent,
    RegisterOrganizationClientEvent,
    SearchOrganizationClientEvent
} from '../../core/app-store/events/organization-client.events';
import { OrganizationClientApiService } from '../../core/api-integration/services/organization-client-api.service';
import { isValidObject } from '../../core/shared/helpers/common.helpers';
import {Organization} from "../../core/app-dto/organization.dto";


@Injectable()
export class OrganizationClientUIEventProcessor {

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private organizationClientApiService: OrganizationClientApiService
    ) {
    }

    @Effect()
    GetOrganizationClientsPagedResultEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetOrganizationClientsPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetOrganizationClientsPagedResultEvent.identifier),
            map((action: GetOrganizationClientsPagedResultEvent) => action),
            switchMap((action: GetOrganizationClientsPagedResultEvent) => {
                return new Observable((observer) => {
                    this.organizationClientApiService.u_getFilteredOrganizationClientsPaginated(action.parameters)
                        .subscribe((res: PagedResult<OrganizationClient_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<OrganizationClient_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    getOrganizationClient$: Observable<any> = this.actions$
        .pipe(
            ofType(GetOrganizationClientEvent.identifier, EventWithScreenLoading.identifier + GetOrganizationClientEvent.identifier),
            map((action: GetOrganizationClientEvent) => action),
            switchMap((action: GetOrganizationClientEvent) => {
                return new Observable((observer) => {
                    this.organizationClientApiService.u_getOrganizationClient(action.id)
                        .subscribe((res: OrganizationClient_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<OrganizationClient_Dto>(res, action));
                            }
                            if (action.callback) action.callback(res);
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doRegisterOrganizationClient$: Observable<any> = this.actions$
        .pipe(
            ofType(RegisterOrganizationClientEvent.identifier, EventWithScreenLoading.identifier + RegisterOrganizationClientEvent.identifier),
            map((action: RegisterOrganizationClientEvent) => action),
            switchMap((action: RegisterOrganizationClientEvent) => {
                return new Observable((observer) => {
                    this.organizationClientApiService.u_registerOrganizationClient(action.model)
                        .subscribe((res: OrganizationClient_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<OrganizationClient_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doEditOrganizationClient$: Observable<any> = this.actions$
        .pipe(
            ofType(EditOrganizationClientEvent.identifier, EventWithScreenLoading.identifier + EditOrganizationClientEvent.identifier),
            map((action: EditOrganizationClientEvent) => action),
            switchMap((action: EditOrganizationClientEvent) => {
                return new Observable((observer) => {
                    this.organizationClientApiService.u_editOrganizationClient(action.model)
                        .subscribe((res: OrganizationClient_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<OrganizationClient_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doDeleteOrganizationClient$: Observable<any> = this.actions$
        .pipe(
            ofType(DeleteOrganizationClientEvent.identifier, EventWithScreenLoading.identifier + DeleteOrganizationClientEvent.identifier),
            map((action: DeleteOrganizationClientEvent) => action),
            switchMap((action: DeleteOrganizationClientEvent) => {
                return new Observable((observer) => {
                    this.organizationClientApiService.u_deleteOrganizationClient(action.id)
                        .subscribe((res: OrganizationClient_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<OrganizationClient_Dto>(res, action));
                            if (isValidObject(action.callback)) action.callback();
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doSearchOrganizationClient$: Observable<any> = this.actions$
        .pipe(
            ofType(SearchOrganizationClientEvent.identifier, SeamlessEvent.identifier + SearchOrganizationClientEvent.identifier),
            map((action: SearchOrganizationClientEvent) => action),
            switchMap((action: SearchOrganizationClientEvent) => {
                return new Observable((observer) => {
                    this.organizationClientApiService.u_searchOrganizationClients(action.search)
                        .subscribe((res: Array<OrganizationClient_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Array<OrganizationClient_Dto>>(res, action));
                            }
                            action.callBack();
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            action.callBack();
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doGetOrganizationClientCreditLimit$: Observable<any> = this.actions$
        .pipe(
            ofType(GetOrganizationClientCreditEvent.identifier,
                SeamlessEvent.identifier + GetOrganizationClientCreditEvent.identifier),
            map((action: GetOrganizationClientCreditEvent) => action),
            switchMap((action: GetOrganizationClientCreditEvent) => {
                return new Observable((observer) => {
                    this.organizationClientApiService.u_getOrganizationClientActualCredit(action.clientId)
                        .subscribe((res: number) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<number>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doGetInfoFromCuiAnaf$: Observable<any> = this.actions$
        .pipe(
            ofType(GetInfoFromCuiAnafEvent.identifier, EventWithScreenLoading.identifier + GetInfoFromCuiAnafEvent.identifier),
            map((action: GetInfoFromCuiAnafEvent) => action),
            switchMap((action: GetInfoFromCuiAnafEvent) => {
                return new Observable((observer) => {
                    this.organizationClientApiService.u_getOrganizationClientInfoFromCuiAnaf(action.cui)
                        .subscribe((res: Organization) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Organization>(res, action));
                                if (action.callback != null){
                                    action.callback(res);
                                }
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
}
