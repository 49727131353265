import { Component, OnInit } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { BaseListWebComponentWithSearchAndActiveBehavior } from 'src/app/core/shared/base/base.component';
import { Router } from '@angular/router';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { GetCarPartStockAlertListDashboardActionProxy } from './get-car-part-stock-alert-list-dashboard.action-proxy';
import { CarPartStockAlertListDashboardSandbox } from './car-part-stock-alert-list-dashboard.sandbox';
import { BsModalService } from 'ngx-bootstrap';
import { ConfigService } from 'src/app/core/core/app-config.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {ActiveDataTableParameters, DataTableParametersOrderEntity} from '../../../core/shared/datatable/datatable.helpers';
import { ActivateCarPartStockAlertActionProxy } from '../../../core/shared/car-parts-table/activate-car-part-stock-alert.action-proxy';
import { DeactivateCarPartStockAlertActionProxy } from '../../../core/shared/car-parts-table/deactivate-car-part-stock-alert.action-proxy';
import { AppUserBusinessRuleProvider } from '../../../core/core/business-rule-provider/app-user.business-rule.provider';
import {IdName_Dto} from '../../../core/app-dto/misc.dto';
import {ObjectValidators} from '../../../core/shared/object.validators';


@Component({
  selector: 'app-car-part-stock-alert-list-dashboard',
  templateUrl: './car-part-stock-alert-list-dashboard.component.html',
  styleUrls: ['./car-part-stock-alert-list-dashboard.component.scss']
})
export class CarPartStockAlertListDashboardComponent extends BaseListWebComponentWithSearchAndActiveBehavior implements OnInit {
  public getCarPartStockAlertsAP: GetCarPartStockAlertListDashboardActionProxy;
  public searchValue: string = '';
  public activateCarPartStockAlertAP: ActivateCarPartStockAlertActionProxy;
  public deactivateCarPartStockAlertAP: DeactivateCarPartStockAlertActionProxy;
  public currentPage = 1;

  public columns: any = [];
  public sortColumns: Array<IdName_Dto> = [];

  constructor(public sandbox: CarPartStockAlertListDashboardSandbox,
              actionsSubj: ActionsSubject,
              private configService: ConfigService,
              private modalService: BsModalService,
              public router: Router,
              private spinner: NgxSpinnerService,
              public rulesProvider: AppUserBusinessRuleProvider) {
    super(sandbox, actionsSubj, ResetSection.CarService);
    this.getCarPartStockAlertsAP = new GetCarPartStockAlertListDashboardActionProxy(this, sandbox.appState, this.spinner);
    this.activateCarPartStockAlertAP = new ActivateCarPartStockAlertActionProxy(this, sandbox.appState);
    this.deactivateCarPartStockAlertAP = new DeactivateCarPartStockAlertActionProxy(this, sandbox.appState);

    this.columns = [
      { data: 'name', name: 'Nume', width: '40%', orderable: true },
      { data: 'code', name: 'Cod Piesa', width: '40%', orderable: true },
      { data: 'quantity', name: 'Stoc', width: '10%', orderable: true },
      { data: 'action', name: 'Actiune', width: '50px', orderable: false }
    ];
  }

  ngOnInit() {
    this.columns = [
      { data: 'name', name: 'Nume', width: '40%', orderable: true },
      { data: 'code', name: 'Cod Piesa', width: '40%', orderable: true },
      { data: 'quantity', name: 'Stoc', width: '10%', orderable: true },
      { data: 'action', name: 'Actiune', width: '50px', orderable: false }
    ];
    this.sortColumns = this.columns.filter(c => c.orderable).map(c => ({id: c.data, name: c.name} as IdName_Dto));
    this.initialize();
    const defaultSortIndex = this.columns.indexOf(this.columns.find(f => f.name === 'Nume'));
    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      pageLength: this.configService.getDefaultPageSize(),
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      scrollY: '300px',
      scrollCollapse: true,
      ajax: (dataTablesParameters: ActiveDataTableParameters, callback) => {
        dataTablesParameters.active = this.isActive;
        dataTablesParameters.search.value = this.searchValue;
        this.doGetAll(new ActiveDataTableParameters(dataTablesParameters), callback, false);
      },
      columns: this.columns,
      order: [[defaultSortIndex, 'asc']],
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
  }

  public doGetAll(dataTablesParameters: ActiveDataTableParameters, callback: any, withReset: boolean) {
    this.sandbox.callback = callback;
    this.sandbox.dataTableParameters = dataTablesParameters;
    this.getCarPartStockAlertsAP.execute(dataTablesParameters, withReset);
  }

  deactivateStockAlertCarPart(carPartId: string) {
    this.deactivateCarPartStockAlertAP.execute(carPartId);
  }
  onPageChange(currentPage: number) {
    this.sandbox.dataTableParameters.pageNumber = currentPage;
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
  }

  onSortChange($event) {
    if ($event) {
      const columnId = this.columns.map((e) => e.data).indexOf($event.id);
      const orderEntity = new DataTableParametersOrderEntity({column: columnId, dir: 'asc'});
      this.sandbox.dataTableParameters.order = [orderEntity];
    }
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
  }

  search(data: any) {
    if (ObjectValidators.isValidString(data)) {
      this.modelChanged.next(data);
    }
    if (data === '') {
      this.modelChanged.next(null);
    }
    return false;
  }
}
