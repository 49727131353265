import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebFormComponent } from 'src/app/core/shared/base/base.component';
import { BaseFormActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import {DeleteCarAppointmentEvent, RegisterCarAppointmentEvent} from 'src/app/core/app-store/events/calendar-events';
import { NgxSpinnerService } from 'ngx-spinner';

export class DeleteCarAppointmentActionProxy extends BaseFormActionProxy {
  constructor(component: IWebFormComponent,
              appState: Store<store.State>) {
    super(component, appState);
  }

  canExecute(): boolean {
    return true;
  }

  execute(id:string,callback: Function): any {
    if (this.canExecute()) {
      this.appState.dispatch(new DeleteCarAppointmentEvent(id, () => {
        callback();
      }));
    }
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }
}

