import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebFormComponent } from 'src/app/core/shared/base/base.component';
import { BaseFormActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { EditCarServiceEntryFinalInspectionEvent } from '../../../core/app-store/events/car-service.events';

export class CarServiceSaveFinalInspectionActionProxy extends BaseFormActionProxy {
    constructor(component: IWebFormComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        this.component.markFormAsDirtyAndTouched();
        return this.component.di.isValid();
    }

    execute(): any {
        if (this.canExecute()) {
            this.component.markFormAsNotDirty();
            const model = this.component.di.getModel();
            this.appState.dispatch(new EditCarServiceEntryFinalInspectionEvent(model));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

