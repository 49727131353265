import { BaseDI } from 'src/app/core/shared/base/base.di';
import { CarPart_Dto } from '../../../core/app-dto/car-part.dto';
import {SupplyItem_Dto} from "../../../core/app-dto/supply.dto";

export class SupplyItemDi extends BaseDI<SupplyItem_Dto> {
    constructor(data: any) {
        super(new SupplyItem_Dto(data));
    }

    getModel(): SupplyItem_Dto {
        return this.model;
    }
}
