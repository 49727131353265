import { Injectable } from "@angular/core";
import { BaseSandbox } from "../../../shared/base/base.sandbox";
import { Store } from "@ngrx/store";
import * as ApplicationStore from "../../../app-store/state";
import { Router } from '@angular/router';


@Injectable()
export class LogoutSandbox extends BaseSandbox {

    constructor(
        protected appState$: Store<ApplicationStore.State>,
        public router: Router
    ) {
        super(appState$);
    }

    public clearSandboxState() {
    }

    public initialize(): any {
        this.clearSandboxState();
    }
}
