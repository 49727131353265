import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {SharedModule} from '../core/shared/shared.module';
import {DataTablesModule} from 'angular-datatables';

import {SuppliesListComponent} from './components/supplies-list/supplies-list.component';
import {SupplyDetailsComponent} from './components/supply-details/supply-details.component';
import {SaveSupplyComponent} from './components/save-supply/save-supply.component';
import {SupplyFormComponent} from './components/supply-form/supply-form.component';

import {SupplyDetailsSandbox} from './components/supply-details/supply-details.sandbox';
import {SuppliesListSandbox} from './components/supplies-list/supplies-list.sandbox';
import {SupplyFormSandbox} from './components/supply-form/supply-form.sandbox';
import {SaveSupplySandbox} from './components/save-supply/save-supply.sandbox';
import {BsDatepickerModule, ModalModule} from 'ngx-bootstrap';
import {CarPartModule} from '../car-part/car-part.module';
import {EditSupplyItemComponent} from './components/edit-supply-item/edit-supply-item.component';
import {EditSupplyItemSandbox} from './components/edit-supply-item/edit-supply-item.sandbox';
import {CarPartStockAlertListDashboardComponent} from './components/car-part-stock-alert-list-dashboard/car-part-stock-alert-list-dashboard.component';
import {CarPartStockAlertListDashboardSandbox} from './components/car-part-stock-alert-list-dashboard/car-part-stock-alert-list-dashboard.sandbox';
import {SupplyUnpaidInvoiceListDashboardSandbox} from './components/supply-unpaid-invoice-list-dashboard/supply-unpaid-invoice-list-dashboard.sandbox';
import {SupplyUnpaidInvoiceListDashboardComponent} from './components/supply-unpaid-invoice-list-dashboard/supply-unpaid-invoice-list-dashboard.component';
import {FindSupplyComponent} from './components/find-supply/find-supply.component';
import {FindSupplySandbox} from './components/find-supply/find-supply.sandbox';
import {SaveSupplierModalComponent} from './components/save-supplier-modal/save-supplier-modal.component';
import {SaveSupplierModalSandbox} from './components/save-supplier-modal/save-supplier-modal.sandbox';

@NgModule({
    declarations: [
        SuppliesListComponent,
        SupplyDetailsComponent,
        SaveSupplyComponent,
        SupplyFormComponent,
        EditSupplyItemComponent,
        CarPartStockAlertListDashboardComponent,
        SupplyUnpaidInvoiceListDashboardComponent,
        FindSupplyComponent,
        SaveSupplierModalComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        CarPartModule,
        NgSelectModule,
        SharedModule,
        BsDatepickerModule,
        DataTablesModule,
        ModalModule.forRoot()
    ],
    providers: [
        SuppliesListSandbox,
        SupplyDetailsSandbox,
        SaveSupplySandbox,
        SupplyFormSandbox,
        EditSupplyItemSandbox,
        FindSupplySandbox,
        CarPartStockAlertListDashboardSandbox,
        SupplyUnpaidInvoiceListDashboardSandbox,
        SaveSupplierModalSandbox
    ],
    entryComponents: [
        SaveSupplyComponent,
        EditSupplyItemComponent,
        FindSupplyComponent,
        SaveSupplierModalComponent
    ],
    exports: [
        SupplyFormComponent,
        CarPartStockAlertListDashboardComponent,
        SupplyUnpaidInvoiceListDashboardComponent,
        SaveSupplierModalComponent
    ]
})
export class SupplyModule {
}
