import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppointmentCar_Dto } from '../../../core/app-dto/appointment-car.dto';
import { CarAppointment_Dto } from '../../../core/app-dto/car-appointment.dto';

export class AppointmentSummaryDi extends BaseFormDI<CarAppointment_Dto> {

    constructor(data: CarAppointment_Dto = new CarAppointment_Dto(null)) {
        super(new CarAppointment_Dto(data));
        this.initForm();
    }

    getModel(): CarAppointment_Dto {
        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
        });
    }
}
