import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebFormComponent } from 'src/app/core/shared/base/base.component';
import { BaseFormActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddCarInServiceEvent } from '../../../core/app-store/events/car-service.events';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';

export class RegisterCarInServiceActionProxy extends BaseFormActionProxy {
    constructor(component: IWebFormComponent,
                appState: Store<store.State>,
                private spinner: NgxSpinnerService) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(appointmentId: string, callback: Function): any {
        if (this.canExecute()) {
            this.component.markFormAsNotDirty();
            const model = this.component.di.getModel();
            this.spinner.show('calendar');
            this.appState.dispatch(new AddCarInServiceEvent(model, appointmentId, (data: CarService_Dto) => {
                this.spinner.hide('calendar');
                callback(data);
            }));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

