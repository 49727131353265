import { Store } from "@ngrx/store";
import * as store from "../../../core/app-store";
import { Observable } from "rxjs";
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { GetOrganizationInvoiceEvent } from 'src/app/core/app-store/events/invoices.events';

export class GetOrganizationInvoiceActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
        appState: Store<store.State>) {
        super(component, appState)
    }

    canExecute(): boolean {
        return true;
    }

    execute(organizationId: string, invoiceId: string): any {
        this.appState.dispatch(new GetOrganizationInvoiceEvent(organizationId, invoiceId));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

