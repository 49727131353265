import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ActiveDataTableParameters, PagedResult } from 'src/app/core/shared/datatable/datatable.helpers';
import { DepartmentsListItemDi } from './departments-list-item.di';
import { Department_Dto } from '../../../core/app-dto/department.dto';

@Injectable()
export class DepartmentsListSandbox extends BaseSandbox {
    public departments$ = this.appState$.pipe(select(store.getDepartmentsListing));
    public timestamp$ = this.appState$.pipe(select(store.getDepartmentTimestamp));

    public totalNumberOfResults: number = null;
    public callback: any;
    public dataTableParameters: ActiveDataTableParameters;
    public resetTableCallback: Function;
    public lastRefreshTime: number = 0;

    public totalPages: number = 0;
    public items: Array<DepartmentsListItemDi> = [];
    private draw: number = 1;

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
        this.items = [];
        this.draw = 1;
        this.lastRefreshTime = 0;
    }

    public initialize(): void {
        this.clearSandboxState();
        this.subscriptions.push(this.departments$.subscribe((data: PagedResult<Department_Dto>) => {
            if (data != null && data.items != null) {
                this.items = data.items.map(r => new DepartmentsListItemDi(r));
                this.totalNumberOfResults = data.totalItems;
                this.totalPages = data.totalPages;
                if (this.callback != null) {
                    this.callback({
                        draw: this.draw++,
                        recordsTotal: data.totalItems,
                        recordsFiltered: data.totalItems,
                        data: []
                    });
                }
            } else {
                this.items = [];
                this.draw = 1;
            }
        }));

        this.subscriptions.push(this.timestamp$.subscribe((result: number) => {
            if (result > this.lastRefreshTime) {
                this.lastRefreshTime = result;
                this.resetTable();
            }
        }));
    }

    public setResetTableCallback(callback: Function) {
        this.resetTableCallback = callback;
    }

    public resetTable() {
        if (this.resetTableCallback != null) {
            this.resetTableCallback();
        }
    }
}
