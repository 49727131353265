import {
    UIGeneratedEvent,
    EventWithScreenLoading,
} from './base.events';
import {ActiveDataTableParameters} from "../../shared/datatable/datatable.helpers";
import {Supplier_Dto} from "../../app-dto/supplier.dto";

export class GetSuppliersEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetSuppliersEvent";
    constructor() {
        super();
        this.type += GetSuppliersEvent.identifier;
    }
}

export class GetSuppliersPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "GetSuppliersPagedResultEvent";

    constructor(public parameters: ActiveDataTableParameters, public withReset: boolean = false) {
        super();
        this.type += GetSuppliersPagedResultEvent.identifier;
    }
}

export class GetSupplierEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "GetSupplierEvent";
    constructor(public id: string, public callback: (data: Supplier_Dto) => void) {
        super();
        this.type += GetSupplierEvent.identifier;
    }
}

export class RegisterSupplierEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "RegisterSupplierEvent";

    constructor(public model: Supplier_Dto) {
        super();
        this.type += RegisterSupplierEvent.identifier;
    }
}

export class UpdateSupplierEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "UpdateSupplierEvent";

    constructor(public model: Supplier_Dto) {
        super();
        this.type += UpdateSupplierEvent.identifier;
    }
}

export class DeleteSupplierEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "DeleteSupplierEvent";
    constructor(public id: string, public callback: Function = null) {
        super();
        this.type += DeleteSupplierEvent.identifier;
    }
}

export class ActivateSupplierEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier:string = "ActivateSupplierEvent";
    constructor(public id: string) {
        super();
        this.type += ActivateSupplierEvent.identifier;
    }
}

export class DeactivateSupplierEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier:string = "DeactivateSupplierEvent";
    constructor(public id: string) {
        super();
        this.type += DeactivateSupplierEvent.identifier;
    }
}


