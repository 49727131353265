import {EventWithScreenLoading, SeamlessEvent, UIGeneratedEvent,} from './base.events';
import {Draft_Dto} from '../../app-dto/draft.dto';

export class GetDraftsEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetDraftsEvent";

  constructor() {
    super();
    this.type += GetDraftsEvent.identifier;
  }
}


export class RegisterDraftEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "RegisterDraftEvent";

  constructor(public model: Draft_Dto) {
    super();
    this.type += RegisterDraftEvent.identifier;
  }
}


export class DeleteDraftEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "DeleteDraftEvent";

  constructor(public id: string) {
    super();
    this.type += DeleteDraftEvent.identifier;
  }
}

export class ContinueDraftCarServiceEntryEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "ContinueDraftCarServiceEntryEvent";

  constructor(public model: Draft_Dto) {
    super();
    this.type += ContinueDraftCarServiceEntryEvent.identifier;
  }
}

export class ContinueDraftOfferEntryEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = "ContinueDraftOfferEntryEvent";

    constructor(public model: Draft_Dto) {
        super();
        this.type += ContinueDraftOfferEntryEvent.identifier;
    }
}

