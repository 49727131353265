import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseWebComponent} from "../../../core/shared/base/base.component";
import {GetCalendarEventsActionProxy} from "../calendar/get-calendar-events.action-proxy";
import {DeleteCalendarEventActionProxy} from "../calendar/delete-calendar-event.action-proxy";
import {CalendarView} from "angular-calendar";
import {Subject, Subscription} from "rxjs";
import {ActionsSubject, Store} from "@ngrx/store";
import * as store from "../../../core/app-store";
import {CalendarSandbox} from "../calendar/calendar.sandbox";
import {BsModalService} from "ngx-bootstrap";
import {DateTimeService} from "../../../core/core/services/date-time.service";
import {ConfirmationDialogService} from "../../../core/core/dialog/confirmation-dialog.service";
import {NgxSpinnerService} from "ngx-spinner";
import {DeviceDetectorService} from "ngx-device-detector";
import {
  Do_GenericSuccessEvent,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from "../../../core/app-store/events/base.events";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {CalendarEvent_Dto, CalendarEventType, CalendarStatistics} from "../../../core/app-dto/calendar-event.dto";
import {CalendarEventDI} from "../calendar/calendar.di";
import {AddEditCalendarEventComponent} from "../add-edit-calendar-event/add-edit-calendar-event.component";
import {isSameDay, isSameMonth} from "date-fns";
import * as moment from "moment";
import {ObjectValidators} from "../../../core/shared/object.validators";
import {
  DeleteCalendarEventEvent, EditCalendarEventEvent,
  GetCalendarEventStatisticsEvent, RegisterCalendarEventEvent, RegisterCarAppointmentEvent, UpdateCarAppointmentEvent
} from "../../../core/app-store/events/calendar-events";
import {StatisticsDI} from "./statistics.di";
import {GetStatisticsActionProxy} from "./get-statistics.action-proxy";
import {Actions} from "@ngrx/effects";
import {
  AddCarInServiceEvent,
  EditCarServiceCarEvent,
  EditCarServiceClientEvent
} from "../../../core/app-store/events/car-service.events";

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent extends BaseWebComponent implements OnInit, OnDestroy {
  public getDataAP: GetStatisticsActionProxy;
  public data: StatisticsDI = null;
  private subscription:Subscription;

  constructor(
    public appState: Store<store.State>,
    private spinner: NgxSpinnerService,
    private actionsSubj: ActionsSubject,

  ) {
    super(null,null);
    this.subscription = actionsSubj.subscribe(data => {
      
      if (data.type == Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + RegisterCarAppointmentEvent.identifier ||
      data.type == Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + UpdateCarAppointmentEvent.identifier ||
        data.type === Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + AddCarInServiceEvent.identifier ||
        data.type === Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + EditCarServiceClientEvent.identifier ||
        data.type === Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + EditCarServiceCarEvent.identifier ||
      data.type == Do_GenericSuccessEvent.identifier + DeleteCalendarEventEvent.identifier
      ) {
        this.getData();
      }
    });
    this.getDataAP = new GetStatisticsActionProxy(this, appState, this.spinner);
  }

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy(){
    if(this.subscription) this.subscription.unsubscribe();
  }
  private getData(){
    this.getDataAP.execute((data:CalendarStatistics)=>{
      this.data = new StatisticsDI(data);
    });
  }
}
