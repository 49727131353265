import {DataGeneratedEvent, EventWithScreenLoading, SeamlessEvent, UIGeneratedEvent,} from './base.events';
import {FileAttachment, User_Dto} from '../../app-dto/core.dto';

export class EditProfileEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "EditProfileEvent";

  constructor(public model: User_Dto) {
    super();
    this.type += EditProfileEvent.identifier;
  }
}

export class GetProfileEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetProfileEvent";

  constructor() {
    super();
    this.type += GetProfileEvent.identifier;
  }
}

export class ProfileImageChanged extends SeamlessEvent implements DataGeneratedEvent {
  public static identifier: string = "ProfileImageChanged";

  constructor(public data: FileAttachment) {
    super();

    this.type += ProfileImageChanged.identifier;
  }
}

export class DeleteUserImageProfileUrlEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "DeleteUserImageProfileUrlEvent";

  constructor() {
    super();
    this.type += DeleteUserImageProfileUrlEvent.identifier;
  }
}

