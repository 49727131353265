import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { BaseFormComponent } from 'src/app/core/shared/base/base.component';
import { AppointmentCarWorksSandbox } from './appointment-car-works.sandbox';
import { AppointmentCarWorksDi } from './appointment-car-works.di';
import { CarWorksSearchComponent } from '../../../car-works/components/car-works-search/car-works-search.component';
import {AppointmentCarWorks_Dto, CarWork_Dto} from '../../../core/app-dto/car-work.dto';
import {AppointmentCar_Dto} from "../../../core/app-dto/appointment-car.dto";
import {CarAppointment_Dto} from '../../../core/app-dto/car-appointment.dto';
import {Car_Dto} from '../../../core/app-dto/car.dto';
import {AppointmentCarDi} from '../appointment-car/appointment-car.di';

@Component({
  selector: 'app-appointment-car-works',
  templateUrl: './appointment-car-works.component.html',
  styleUrls: ['./appointment-car-works.component.scss']
})
export class AppointmentCarWorksComponent extends BaseFormComponent implements OnInit, OnDestroy {

  public clientSearch: string = '';

  @Output()
  modelSaved = new EventEmitter<AppointmentCarWorks_Dto>();
  @Output()
  eventSelectedOutput = new EventEmitter<string>();

  @ViewChild('carWorkSearch', {static: false}) carWorkSearch: ElementRef;

  @ViewChild(CarWorksSearchComponent, { static: false }) carWorkSearchComponent: CarWorksSearchComponent;

   @Input("carWorks") set carWorks(carWorks: AppointmentCarWorks_Dto) {
    this.di = new AppointmentCarWorksDi(carWorks);
  }
  @Input("car") car: AppointmentCar_Dto;

  constructor(public appState: Store<store.State>,
              public sandbox: AppointmentCarWorksSandbox) {
    super(sandbox);
  }

  ngOnInit() {
    this.di = new AppointmentCarWorksDi(new AppointmentCarWorks_Dto(null));
  }

  ngOnDestroy() {
    this.destroy();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();
    if (this.di.isValid()) {
      if (this.modelSaved != null) {
        this.modelSaved.emit(this.di.getModel());
      }
    }
  }

  isValid() {
    return this.di.isValid();
  }

  carWorksSelected(carWorks: Array<CarWork_Dto>) {
    this.di.model.carWorks = carWorks;
    this.di.model.details = this.di.form.value.details;
    this.modelSaved.emit(this.di.getModel());
  }
}
