import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { LoggedUser_Dto } from '../../app-dto/core.dto';
import { Breadcrumb } from '../../app-dto/breadcrumb.dto';

@Injectable()
export class HeaderSandbox extends BaseSandbox {
    public getUser$ = this.appState$.pipe(select(store.getLoggedUser));
    public getBreadcrumb$ = this.appState$.pipe(select(store.getBreadcrumb));
    public breadcrumb: Array<Breadcrumb> = [];

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    clearSandboxState() {
    }

    initialize(profile: (data: LoggedUser_Dto) => {}) {
        this.clearSandboxState();

        this.subscriptions.push(this.getUser$.subscribe((data: LoggedUser_Dto) => {
            if (ObjectValidators.isValidObject(data)) {
                profile(data);
            }
        }));

        this.subscriptions.push(this.getBreadcrumb$.subscribe((data: Array<Breadcrumb>) => {
            if (ObjectValidators.isValidObject(data)) {
                setTimeout(() => {
                    this.breadcrumb = data;
                });
            }
        }));
    }
}
