import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseEmbeddedWebComponent, IWebComponent} from "../../../shared/base/base.component";
import {CheckBusyIntervalSandbox} from "./check-busy-interval.sandbox";
import {LoggedUser_Dto} from "../../../app-dto/core.dto";
import {isValidArrayAndHasElements, isValidObject} from "../../../shared/helpers/common.helpers";
import {DateHelpers} from "../../../shared/date.helpers";
import {DateRange} from "../../../../calendar/components/calendar/calendar.component";
import {Organization} from "../../../app-dto/organization.dto";
import * as moment from "moment";
import {CalendarEvent_Dto} from "../../../app-dto/calendar-event.dto";

@Component({
  selector: 'app-check-busy-interval',
  templateUrl: './check-busy-interval.component.html',
  styleUrls: ['./check-busy-interval.component.scss']
})
export class CheckBusyIntervalComponent extends BaseEmbeddedWebComponent implements IWebComponent, OnInit, OnDestroy {

  private currentOrganization: Organization;
  public shouldShowWarning: boolean = false;
  public startDateValue: Date;
  public endDateValue: Date;

  @Input("startDate") set startDate(data: Date) {
    this.startDateValue = data;
    this.checkIfIntervalOutsideTheWorkingHours();
  }

  @Input("endDate") set endDate(data: Date) {
    this.endDateValue = data;
    this.checkIfIntervalOutsideTheWorkingHours();
  }

  @Output() busyStateChanged : EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private sandbox: CheckBusyIntervalSandbox) {
    super();
  }

  ngOnInit() {
    this.sandbox.initialize((data: LoggedUser_Dto) => {
      if (isValidObject(data)) {
        this.currentOrganization = data.organization;
        this.checkIfIntervalOutsideTheWorkingHours();
      }

    });
  }
  getDateRangeInterval(targetDate:Date): DateRange {
    let result = new DateRange();

    if (this.currentOrganization.workingHoursSettings == null) return result;
    if (this.currentOrganization.workingHoursSettings.workingHours == null) return result;

      const currentDayStartTime = moment(targetDate);
      const dayIndexForWorkingHours = currentDayStartTime.day() == 0 ? 6 : currentDayStartTime.day() - 1;
      let workingHoursProfile = this.currentOrganization.workingHoursSettings.workingHours.find(x => x.key == dayIndexForWorkingHours);
      if (workingHoursProfile != null && isValidArrayAndHasElements(workingHoursProfile.hoursRange)) {
        workingHoursProfile.hoursRange.forEach((range) => {

          let currentDayStartTimeAsDate = currentDayStartTime.toDate();
          let rangeStartDate = new Date(currentDayStartTimeAsDate.getFullYear(), currentDayStartTimeAsDate.getMonth(), currentDayStartTimeAsDate.getDate(), range.fromHours, range.fromMinutes, 0, 0);
          let rangeEndDate = new Date(currentDayStartTimeAsDate.getFullYear(), currentDayStartTimeAsDate.getMonth(), currentDayStartTimeAsDate.getDate(), range.toHours, range.toMinutes, 0, 0);

          if(result.start ==null) result.start = rangeStartDate;
          if(result.end ==null) result.end = rangeEndDate;
          if(result.start > rangeStartDate) result.start = rangeStartDate;
          if(result.end < rangeEndDate) result.end = rangeEndDate;
        });
    }
    return result;
  }

  getDatesInterval() {
    let dates: Array<DateRange> = [];
    if (this.currentOrganization.workingHoursSettings == null) return dates;
    if (this.currentOrganization.workingHoursSettings.workingHours == null) return dates;
    if (moment(this.startDateValue).format("M/D/YYYY") == moment(this.endDateValue).format("M/D/YYYY")) {
      dates.push({start: this.startDateValue, end: this.endDateValue});
      return dates;
    }

    let currDate = moment(this.startDateValue).startOf('day');
    let lastDate = moment(this.endDateValue).startOf('day');

    let index = 0;
    do  {
      let minMaxRangeForCurrentDate = this.getDateRangeInterval(currDate.toDate());
      let currentDate = moment(currDate.toDate());
      if (moment(this.startDateValue).format("M/D/YYYY") == currentDate.format("M/D/YYYY")) {
        //first day
        dates.push({start: this.startDateValue, end: currentDate.set("hours", minMaxRangeForCurrentDate.end.getHours()).set("minutes", minMaxRangeForCurrentDate.end.getMinutes()).set("seconds",0).toDate()});
      } else if (moment(this.endDateValue).format("M/D/YYYY") == currDate.format("M/D/YYYY")) {
        //last day
        dates.push({start: currentDate.startOf('day').set("hours", minMaxRangeForCurrentDate.start.getHours()).set("minutes", minMaxRangeForCurrentDate.start.getMinutes()).set("seconds",0).toDate(),
          end: this.endDateValue});
      }
      else {
        dates.push({start: currentDate.startOf('day').set("hours", minMaxRangeForCurrentDate.start.getHours()).set("minutes", minMaxRangeForCurrentDate.start.getMinutes()).set("seconds",0).toDate(),
          end: currentDate.endOf('day').set("hours", minMaxRangeForCurrentDate.end.getHours()).set("minutes", minMaxRangeForCurrentDate.end.getMinutes()).set("seconds",0).toDate()});
      }
    }while(currDate.add(1, 'days').diff(lastDate) <= 0);
    return dates;
  }

  private checkIntervalIsBusy(interval:DateRange,daysOff: Array<string> ) : boolean{
    if (isValidArrayAndHasElements(daysOff)) {
      if (daysOff.find(x => x == DateHelpers.dateToString(interval.start)) != null) {

        return true;
      }
    }
    let dayIndex = interval.start.getDay() == 0 ? 6 : interval.start.getDay() - 1;
    let hoursRanges = this.currentOrganization.workingHoursSettings.workingHours[dayIndex].hoursRange;
    if (isValidArrayAndHasElements(hoursRanges) == false) {

      return true;
    }
    let foundBetweenWorkingHours = false;
    const firstRangeItem = new Date(interval.start.getFullYear(), interval.start.getMonth(), interval.start.getDate(),  hoursRanges[0].fromHours,  hoursRanges[0].fromMinutes, 0, 0);
    const lastRangeItem = new Date(interval.start.getFullYear(), interval.start.getMonth(), interval.start.getDate(), hoursRanges[hoursRanges.length-1].toHours, hoursRanges[hoursRanges.length-1].toMinutes, 0, 0);
    if(firstRangeItem > interval.start || lastRangeItem < interval.end){

      return true;
    }
    return false;
}

  checkIfIntervalOutsideTheWorkingHours() {
    this.shouldShowWarning = false;

    if (this.currentOrganization == null) return;
    if (this.currentOrganization.workingHoursSettings == null) return;
    if (isValidArrayAndHasElements(this.currentOrganization.workingHoursSettings.workingHours) == false) return;

    if (this.startDateValue == null) {
      this.shouldShowWarning = false;
      this.busyStateChanged.emit(false);
      return;
    }
    if (this.endDateValue == null) {
      this.shouldShowWarning = false;
      this.busyStateChanged.emit(false);
      return;
    }
    let dayIntervalForSelectedPeriodOfTime: Array<DateRange> = this.getDatesInterval();
    if (isValidArrayAndHasElements(dayIntervalForSelectedPeriodOfTime)) {
      let daysOff: Array<string> = this.currentOrganization != null && this.currentOrganization.workingHoursSettings != null && this.currentOrganization.workingHoursSettings.daysOff != null ?
        this.currentOrganization.workingHoursSettings.daysOff.map(r => DateHelpers.dateToString(r.date)) : [];

      const firstDay = dayIntervalForSelectedPeriodOfTime[0];
      const lastDay = dayIntervalForSelectedPeriodOfTime[dayIntervalForSelectedPeriodOfTime.length - 1];

      let isFirstDayBusy = this.checkIntervalIsBusy(firstDay,daysOff);
      if (isFirstDayBusy) {
        this.shouldShowWarning = true;
        this.busyStateChanged.emit(true);
      }
      else if(this.checkIntervalIsBusy(lastDay,daysOff)){
        this.shouldShowWarning = true;
        this.busyStateChanged.emit(true);
      }
    }
    if(this.shouldShowWarning==false) this.busyStateChanged.emit(false);
  }

  destroy() {
    if (this.sandbox != null) {
      this.sandbox.unsubscribe();
    }
  }
}
