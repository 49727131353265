import {State} from '../state';
import {createSelector} from '@ngrx/store';
import * as fromReceipt from '../stores/receipt.store';
import {getReceiptIdCallback} from '../stores/receipt.store';

export const getReceiptStateCallback = (state: State) => state.receipt;

export const getReceiptTimestamp = createSelector(getReceiptStateCallback, fromReceipt.getTimestampCallback);
export const getReceipt = createSelector(getReceiptStateCallback, fromReceipt.getReceiptCallback);
export const getReceiptsListing = createSelector(getReceiptStateCallback, fromReceipt.getReceiptsPagedResultCallback);
export const getReceiptId = createSelector(getReceiptStateCallback, fromReceipt.getReceiptIdCallback);
