import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CalendarEvent_Dto, CalendarEventType } from 'src/app/core/app-dto/calendar-event.dto';
import { DateTimeService } from 'src/app/core/core/services/date-time.service';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { CarAppointment_Dto, CarAppointmentRules } from '../../../core/app-dto/car-appointment.dto';
import { AppointmentCar_Dto } from '../../../core/app-dto/appointment-car.dto';
import { AppointmentClient_Dto } from '../../../core/app-dto/appointment-client.dto';
import { CarWork_Dto } from '../../../core/app-dto/car-work.dto';
import { ServicePlatform_Dto } from '../../../core/app-dto/service-platform.dto';
import { Mechanic_Dto } from '../../../core/app-dto/mechanic.dto';

export class AddEditCalendarEventWizardDi extends BaseFormDI<CarAppointment_Dto> {

  constructor(public dateTimeService: DateTimeService, data: CarAppointment_Dto = new CarAppointment_Dto(null)) {
    super(new CarAppointment_Dto(data));

    this.initForm();
  }

  getModel(): CarAppointment_Dto {
    return this.model;
  }

  initForm() {
    this.form = new FormGroup({
    });
  }
}
