import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AppUserBusinessRuleProvider } from "../../../core/core/business-rule-provider/app-user.business-rule.provider";
import { InvoiceUpdateDeadlineDI } from './invoice-update-deadline.di';
import { InvoiceUpdateDeadlineSandbox } from './invoice-update-deadline.sandbox';
import { InvoiceUpdateDeadlineActionProxy } from './invoice-update-deadline.action-proxy';
import { InvoiceDetails_Dto } from 'src/app/core/app-dto/invoice.dto';
import { DateTimeService } from 'src/app/core/core/services/date-time.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-invoice-update-deadline',
  templateUrl: './invoice-update-deadline.component.html',
  styleUrls: ['./invoice-update-deadline.component.scss']
})
export class InvoiceUpdateDeadlineComponent extends BaseFormModelComponent<InvoiceDetails_Dto> implements OnInit, OnDestroy {
  public invoiceUpdateDeadlineAP: InvoiceUpdateDeadlineActionProxy;
  public onClose: Subject<boolean>;
  public di: InvoiceUpdateDeadlineDI;
  private initialValue: InvoiceDetails_Dto;

  constructor(
    public appState: Store<store.State>,
    public sandbox: InvoiceUpdateDeadlineSandbox,
    public activeModal: BsModalRef,
    public activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    public rulesProvider: AppUserBusinessRuleProvider,
    public dateTimeService: DateTimeService,
    private location: Location

  ) {
    super(sandbox);
    this.invoiceUpdateDeadlineAP = new InvoiceUpdateDeadlineActionProxy(this, sandbox.appState);
  }

  public diInitialize(data: InvoiceDetails_Dto) {
    this.initialValue = data;
    this.di = new InvoiceUpdateDeadlineDI(this.dateTimeService, data);
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.onClose = new Subject();
    this.initialize();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.formSubmitted = true;
    if (this.invoiceUpdateDeadlineAP.canExecute()) {
      this.invoiceUpdateDeadlineAP.execute((result) => {
        let currentLocation = this.location.path();
        currentLocation = currentLocation.replace(this.initialValue.invoice.id, result.invoice.id);
        this.location.replaceState(currentLocation);
      });
      this.onClose.next(true);
      this.activeModal.hide();
    }
  }
}
