import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebFormComponent } from 'src/app/core/shared/base/base.component';
import { BaseFormActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { RegisterCarAppointmentEvent } from 'src/app/core/app-store/events/calendar-events';
import { NgxSpinnerService } from 'ngx-spinner';

export class RegisterCarAppointmentEventActionProxy extends BaseFormActionProxy {
    constructor(component: IWebFormComponent,
                appState: Store<store.State>,
                private spinner: NgxSpinnerService) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(callback: Function): any {
        if (this.canExecute()) {
            this.component.markFormAsNotDirty();
            const model = this.component.di.getModel();
            this.spinner.show('calendar');
            this.appState.dispatch(new RegisterCarAppointmentEvent(model, () => {
                this.spinner.hide('calendar');
                callback();
            }));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

