import { BaseDI } from 'src/app/core/shared/base/base.di';
import { CalendarEvent_Dto, CalendarEventType } from 'src/app/core/app-dto/calendar-event.dto';
import { get } from 'jquery';
import {isValidString} from "../../../core/shared/helpers/common.helpers";

export class CalendarEventDI extends BaseDI<CalendarEvent_Dto> {
    public start: Date;
    public end: Date;
    public title: string;
    public color: any;
    public actions: any;
    public allDay: boolean;
    public customTemplate:string;

    constructor(data: CalendarEvent_Dto = new CalendarEvent_Dto(null), public editCalled: (event: CalendarEventDI) => void, public deleteCalled: (event: CalendarEventDI) => void, public confirmedCalled: (event: CalendarEventDI) => void) {
        super(new CalendarEvent_Dto(data));

        this.start = this.model.startDate;
        this.end = this.model.endDate;
        this.title = this.model.title;
        this.customTemplate = this.model.title;
        if(isValidString(this.model.clientName)) this.customTemplate += ', ' + this.model.clientName;
        if(isValidString(this.model.clientPhoneNumber)) this.customTemplate += ', ' + this.model.clientPhoneNumber;
        if(isValidString(this.model.carNumber)) this.customTemplate += ', ' + this.model.carNumber;

        if(data.isFinished)
          this.color = CalendarEventColors["Finished"];
        else {
          if (data.isConfirmed)
            this.color = CalendarEventColors['Confirmed'];
          else
            this.color = CalendarEventColors[CalendarEventType[this.model.type]];
        }
        this.actions = this.getEventActions(data.isConfirmed);
        this.allDay = this.model.isFullDayEvent;
    }

    getModel(): CalendarEvent_Dto {
        return this.model;
    }

    getEventActions(isConfirmed: boolean = false) {
        let actions = [
            {
                label: '<i class="fas fa-pencil-alt ml-1"></i>',
                a11yLabel: 'Edit',
                onClick: ({ event }: { event: CalendarEventDI }): void => {
                    this.editCalled(event);
                }
            }
        ];
        if (isConfirmed)
            actions.push(
                {
                    label: '<i class="fas fa-times ml-2 mr-2"></i>',
                    a11yLabel: 'Delete',
                    onClick: ({ event }: { event: CalendarEventDI }): void => {
                        this.deleteCalled(event);
                    }
                });
        else
            actions.push(
                {
                    label: '<i class="fas fa-times ml-2"></i>',
                    a11yLabel: 'Delete',
                    onClick: ({ event }: { event: CalendarEventDI }): void => {
                        this.deleteCalled(event);
                    }
                },
                {
                    label: '<i class="fa fa-check ml-2 mr-2"></i>',
                    a11yLabel: 'Confirm',
                    onClick: ({ event }: { event: CalendarEventDI }): void => {
                        this.confirmedCalled(event);
                    }
                });
        return actions;
    }
}

export const CalendarEventColors = {
    Appointment: {
        primary: '#fd7e14',
        secondary: '#fd7e14'
    },
    ITPNotification: {
        primary: '#748194',
        secondary: '#748194'
    },
    InsuranceNotification: {
        primary: '#748194',
        secondary: '#748194'
    },
    Confirmed: {
        primary: '#00d27a',
        secondary: '#00d27a'
    },
  Finished: {
    primary: '#27bcfd',
    secondary: '#27bcfd'
  },
};
