import { Store } from '@ngrx/store';
import * as store from '../../../app-store/index';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import {
  GenerateInvoiceForCarServiceEntry
} from '../../../app-store/events/invoices.events';

export class GenerateInvoiceForCarEntryActionProxy extends BaseActionProxy {
  constructor(component: IWebComponent,
              appState: Store<store.State>) {
    super(component, appState);
  }

  canExecute(): boolean {
    return true;
  }

  execute(carServiceId: string, organizationId: string): any {
    this.appState.dispatch(new GenerateInvoiceForCarServiceEntry(carServiceId, organizationId));
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }




}

