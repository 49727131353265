import {isValidObject} from '../shared/helpers/common.helpers';

export class Breadcrumb {
  public text: string;
  public url?: string;

  constructor(data: any) {
    if (isValidObject(data)) {
      this.text = data.text;
      this.url = data.url;
    }
  }
}
