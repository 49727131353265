import {Injectable} from '@angular/core';
import {BaseApiService} from './base-api.service';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscriber} from 'rxjs';
import {ApiErrorDto} from '../utils/api-error.dto';
import {ConfigService} from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {CarServiceListDataTableParameters, PagedResult} from '../../shared/datatable/datatable.helpers';
import {
  CarAction_Dto,
  CarService_Dto,
  CarServiceEntryActionListChanged_Dto,
  CarServiceEntryStatistics,
  ClientCarParts_Dto,
  FinalInspectionUser_Dto
} from '../../app-dto/car-service.dto';
import {CarPart_Dto} from '../../app-dto/car-part.dto';
import {Workmanship_Dto} from '../../app-dto/workmanship.dto';
import {ErrorHelpers} from '../../shared/error.helpers';
import {isValidString} from "../../shared/helpers/common.helpers";
import {DeleteCarPartFromCarService} from "../../app-store/events/car-service.events";

class CarServiceEntryApiRoutes {
  public static route_for_GET_CAR_SERVICE_ENTRIES_PAGINATED() {
    return '/api/carServiceEntry/paginated';
  }

  public static route_for_GET_CAR_SERVICE_ENTRIES() {
    return '/api/carServiceEntry/all';
  }

  public static route_for_ADD_CAR_SERVICE_ENTRY(appointmentId: string) {
    if (isValidString(appointmentId) == false) appointmentId = '';
    return `/api/carServiceEntry?appointmentId=${appointmentId}`;
  }

  public static route_for_GET_CAR_SERVICE(carServiceId: string) {
    return `/api/carServiceEntry/${carServiceId}`;
  }

  public static route_for_DELETE_CAR_SERVICE(carServiceId: string) {
    return `/api/carServiceEntry/${carServiceId}`;
  }

  public static route_for_FINALIZE_CAR_SERVICE(carServiceId: string) {
    return `/api/carServiceEntry/${carServiceId}/finalize`;
  }

  public static route_for_GET_CAR_SERVICE_UNAUTHORIZED(code: string, carServiceEntryId: string) {
    return `/api/carServiceEntry/annonymus/${code}/${carServiceEntryId}`;
  }

  public static route_for_EDIT_CAR_SERVICE_CAR() {
    return '/api/carServiceEntry/car';
  }

  public static route_for_EDIT_CAR_SERVICE_CLIENT() {
    return '/api/carServiceEntry/client';
  }

  public static route_for_EDIT_CAR_SERVICE_RECOMMENDED_CAR_WORKS() {
    return '/api/carServiceEntry/recommendedCarWorks';
  }

  public static route_for_EDIT_CAR_SERVICE_ENTRY_DATA() {
    return '/api/carServiceEntry/data';
  }

  public static route_for_EDIT_CAR_SERVICE_ENTRY_FINAL_INSPECTION() {
    return '/api/carServiceEntry/finalInspection';
  }

  public static route_for_UPDATE_CAR_SERVICE_ADD_CLIENT_PARTS(carServiceId: string) {
    return `/api/carServiceEntryCarPart/${carServiceId}/addClientParts`;
  }

  public static route_for_UPDATE_CAR_SERVICE_UPDATE_CLIENT_PARTS(carServiceId: string) {
    return `/api/carServiceEntryCarPart/${carServiceId}/updateClientParts`;
  }

  public static route_for_UPDATE_CAR_SERVICE_ADD_CAR_PART(carServiceId: string) {
    return `/api/carServiceEntryCarPart/${carServiceId}/addCarParts`;
  }

  public static route_for_UPDATE_CAR_SERVICE_UPDATE_CAR_PART(carServiceId: string) {
    return `/api/carServiceEntryCarPart/${carServiceId}/updateCarParts`;
  }

  public static route_for_UPDATE_CAR_SERVICE_ADD_WORKMANSHIP(carServiceId: string) {
    return `/api/carServiceEntryCarPart/${carServiceId}/addWorkmanship`;
  }

  public static route_for_UPDATE_CAR_SERVICE_UPDATE_WORKMANSHIP(carServiceId: string) {
    return `/api/carServiceEntryCarPart/${carServiceId}/updateWorkmanship`;
  }

  public static route_for_ADD_CAR_SERVICE_ENTRY_ACTION() {
    return `/api/carServiceEntryAction`;
  }

  public static route_for_UPDATE_CAR_SERVICE_ENTRY_ACTION() {
    return `/api/carServiceEntryAction`;
  }

  public static route_for_DELETE_CAR_SERVICE_ENTRY_ACTION(id: string) {
    return `/api/carServiceEntryAction/${id}`;
  }

  public static route_for_GET_CAR_SERVICE_ENTRY_ACTION_LIST(id: string) {
    return `/api/carServiceEntryAction/list/${id}`;
  }

  public static route_for_GET_CAR_SERVICE_ENTRY_ACTION_LIST_UNAUTHORIZED(code: string, carServiceEntryId: string) {
    return `/api/carServiceEntryAction/annonymus/list/${code}/${carServiceEntryId}`;
  }

  public static route_for_UPDATE_CAR_SERVICE_DELETE_SERVICE_CAR_PART(carServiceId: string, carPartId: string) {
    return `/api/carServiceEntryCarPart/${carServiceId}/deleteServiceCarPart/${carPartId}`;
  }

  public static route_for_UPDATE_CAR_SERVICE_DELETE_CLIENT_CAR_PART(carServiceId: string, carPartId: string) {
    return `/api/carServiceEntryCarPart/${carServiceId}/deleteClientCarPart/${carPartId}`;
  }

  public static route_for_UPDATE_CAR_SERVICE_DELETE_WORKMANSHIP(carServiceId: string, workmanshipId: string) {
    return `/api/carServiceEntryCarPart/${carServiceId}/deleteWorkmanship/${workmanshipId}`;
  }

  public static route_for_DOWNLOAD_CAR_SERVICE_FINAL_INSPECTION(id: string) {
    return `/api/carServiceEntry/${id}/finalInspection/download`;
  }

  public static route_for_GET_CAR_SERVICE_ENTRIES_STATISTICS() {
    return '/api/carServiceEntry/statistics';
  }

  public static route_for_GET_CAR_SERVICE_ENTRIES_USERS() {
    return '/api/carServiceEntry/users';
  }

  public static route_for_GENERATE_CAR_SERVICE_INVOICE(carServiceId: string) {
    return `/api/carServiceEntry/${carServiceId}/generateInvoice`;
  }
}

@Injectable()
export class CarServiceApiService extends BaseApiService {

  constructor(private http: HttpClient, configService: ConfigService) {
    super(configService);
  }

  u_getFilteredCarServiceEntriesPaginated(data: CarServiceListDataTableParameters): Observable<PagedResult<CarService_Dto>> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_GET_CAR_SERVICE_ENTRIES_PAGINATED() + '?' + data.toUrlParameters();

    return this.http.get(url)
      .map((res: PagedResult<CarService_Dto>) => {
        return new PagedResult<CarService_Dto>(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getCarServiceEntries(): Observable<Array<CarService_Dto>> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_GET_CAR_SERVICE_ENTRIES();

    return this.http.get(url)
      .map((res: Array<CarService_Dto>) => {
        return res.map(r => new CarService_Dto(r));
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_addCarServiceEntry(carServiceData: CarService_Dto, appointmentId: string): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_ADD_CAR_SERVICE_ENTRY(appointmentId);

    return this.http.post(url, carServiceData)
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getCarServiceEntry(carServiceId: string): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_GET_CAR_SERVICE(carServiceId);

    return this.http.get(url)
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_deleteCarServiceEntry(carServiceId: string): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_DELETE_CAR_SERVICE(carServiceId);

    return this.http.delete(url)
      .map((res: any) => {
        return new CarService_Dto(null);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return Observable.create((observer: Subscriber<any>) => {
          observer.error(err.error.error.message);
          observer.complete();
        });
      });
  }

  u_finalizeCarServiceEntry(carServiceId: string): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_FINALIZE_CAR_SERVICE(carServiceId);

    return this.http.put(url, {})
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return Observable.create((observer: Subscriber<any>) => {
          observer.error(err.error.error.message);
          observer.complete();
        });
      });
  }

  u_getCarServiceEntryUnauthorized(code: string, carServiceEntryId: string): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_GET_CAR_SERVICE_UNAUTHORIZED(code, carServiceEntryId);

    return this.http.get(url)
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return Observable.create((observer: Subscriber<any>) => {
          observer.error(err.error.error.message);
          observer.complete();
        });
      });
  }

  u_editCarServiceClient(carServiceData: CarService_Dto): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_EDIT_CAR_SERVICE_CLIENT();

    return this.http.put(url, carServiceData)
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_editCarServiceCarWorks(carServiceData: CarService_Dto): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_EDIT_CAR_SERVICE_RECOMMENDED_CAR_WORKS();

    return this.http.put(url, carServiceData)
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_editCarServiceCar(carServiceData: CarService_Dto): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_EDIT_CAR_SERVICE_CAR();

    return this.http.put(url, carServiceData)
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_editCarServiceEntryData(carServiceData: CarService_Dto): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_EDIT_CAR_SERVICE_ENTRY_DATA();

    return this.http.put(url, carServiceData)
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_editCarServiceEntryFinalInspection(carServiceData: CarService_Dto): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_EDIT_CAR_SERVICE_ENTRY_FINAL_INSPECTION();

    return this.http.put(url, carServiceData)
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_updateCarServiceAddClientPart(carServiceClientPart: ClientCarParts_Dto, carServiceId: string): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_UPDATE_CAR_SERVICE_ADD_CLIENT_PARTS(carServiceId);

    return this.http.put(url, carServiceClientPart)
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_updateCarServiceUpdateClientPart(carServiceClientPart: ClientCarParts_Dto, carServiceId: string): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_UPDATE_CAR_SERVICE_UPDATE_CLIENT_PARTS(carServiceId);

    return this.http.put(url, carServiceClientPart)
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_updateCarServiceAddCarPart(carServiceCarPart: CarPart_Dto, carServiceId: string): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_UPDATE_CAR_SERVICE_ADD_CAR_PART(carServiceId);

    return this.http.put(url, carServiceCarPart)
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_updateCarServiceUpdateCarPart(carServiceCarPart: CarPart_Dto, carServiceId: string): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_UPDATE_CAR_SERVICE_UPDATE_CAR_PART(carServiceId);

    return this.http.put(url, carServiceCarPart)
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_updateCarServiceDeleteServiceCarPart(data:DeleteCarPartFromCarService): Observable<DeleteCarPartFromCarService> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_UPDATE_CAR_SERVICE_DELETE_SERVICE_CAR_PART(data.carServiceId, data.carPart.id);

    return this.http.put(url, data.carPart)
      .map((res: any) => {
        return data;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_updateCarServiceDeleteClientCarPart(carServiceId: string, carPartCode: string): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_UPDATE_CAR_SERVICE_DELETE_CLIENT_CAR_PART(carServiceId, carPartCode);

    return this.http.put(url, '')
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_addCarServiceEntryAction(carServiceActionData: CarAction_Dto): Observable<CarServiceEntryActionListChanged_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_ADD_CAR_SERVICE_ENTRY_ACTION();

    return this.http.post(url, carServiceActionData)
      .map((res: any) => {
        return new CarServiceEntryActionListChanged_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_updateCarServiceEntryAction(carServiceActionData: CarAction_Dto): Observable<CarServiceEntryActionListChanged_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_UPDATE_CAR_SERVICE_ENTRY_ACTION();
    return this.http.put(url, carServiceActionData)
      .map((res: any) => {
        return new CarServiceEntryActionListChanged_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_deleteCarServiceEntryAction(id: string): Observable<CarServiceEntryActionListChanged_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_DELETE_CAR_SERVICE_ENTRY_ACTION(id);

    return this.http.delete(url)
      .map((res: any) => {
        return new CarServiceEntryActionListChanged_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getCarServiceEntryActionList(id: string): Observable<Array<CarAction_Dto>> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_GET_CAR_SERVICE_ENTRY_ACTION_LIST(id);

    return this.http.get(url)
      .map((res: any) => {
        return res.map(r => new CarAction_Dto(r));
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getCarServiceEntryActionUnauthorizedList(code: string, carServiceEntryId: string): Observable<Array<CarAction_Dto>> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_GET_CAR_SERVICE_ENTRY_ACTION_LIST_UNAUTHORIZED(code, carServiceEntryId);

    return this.http.get(url)
      .map((res: any) => {
        return res.map(r => new CarAction_Dto(r));
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return Observable.create((observer: Subscriber<any>) => {
          observer.error(err.error.error.message);
          observer.complete();
        });
      });
  }

  u_updateCarServiceUpdateWorkmanship(carServiceWorkmanship: Workmanship_Dto, carServiceId: string): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_UPDATE_CAR_SERVICE_UPDATE_WORKMANSHIP(carServiceId);

    return this.http.put(url, carServiceWorkmanship)
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_updateCarServiceAddWorkmanship(carServiceWorkmanship: Workmanship_Dto, carServiceId: string): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_UPDATE_CAR_SERVICE_ADD_WORKMANSHIP(carServiceId);

    return this.http.put(url, carServiceWorkmanship)
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_updateCarServiceDeleteWorkmanship(carServiceId: string, workmanshipId: string): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_UPDATE_CAR_SERVICE_DELETE_WORKMANSHIP(carServiceId, workmanshipId);

    return this.http.put(url, '')
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getStatistics(): Observable<CarServiceEntryStatistics> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_GET_CAR_SERVICE_ENTRIES_STATISTICS();

    return this.http.get(url)
      .map((res: any) => {
        return new CarServiceEntryStatistics(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getCarServiceEntryUsersEvent(): Observable<Array<FinalInspectionUser_Dto>> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_GET_CAR_SERVICE_ENTRIES_USERS();

    return this.http.get(url)
      .map((res: any) => {
        return res.map(r => new FinalInspectionUser_Dto(r));
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_downloadCarServiceEntryFinalInspectionEvent(id: string): Observable<string> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_DOWNLOAD_CAR_SERVICE_FINAL_INSPECTION(id);

    return this.http.get(url)
      .map((res: any) => {
        return res.data;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_generateCarServiceEntryInvoice(carServiceId: string): Observable<CarService_Dto> {
    const url = this.configService.getAPIBaseUrl() + CarServiceEntryApiRoutes.route_for_GENERATE_CAR_SERVICE_INVOICE(carServiceId);

    return this.http.get(url)
      .map((res: any) => {
        return new CarService_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return Observable.create((observer: Subscriber<any>) => {
          observer.error(err.error.error.message);
          observer.complete();
        });
      });
  }
}
