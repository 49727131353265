import {BaseDI, BaseFormDI} from 'src/app/core/shared/base/base.di';
import {ForgotPassword_Dto, Login_Dto} from 'src/app/core/app-dto/authentication.dto';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {EmailRegForControl} from '../../../shared/form/custom-form.validator';

export class LoginDI extends BaseFormDI<Login_Dto> {

  constructor(data: Login_Dto = new Login_Dto('', '')) {
    super(data);
    this.initForm();
  }

  getModel(): Login_Dto {
    const formValue: Login_Dto = <Login_Dto>this.form.value;
    this.model.email = formValue.email;
    this.model.password = formValue.password;
    return this.model;
  }

  initForm() {
    this.form = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, EmailRegForControl()])),
      password: new FormControl('', Validators.compose([Validators.required])),
    });
  }
}
