import { Component, OnInit } from '@angular/core';
import {InvoiceRequestType} from '../invoice/invoice.component';
import {ActivatedRoute, Params} from '@angular/router';
import { BaseWebComponentWithBreadcrumb } from '../../../core/shared/base/base.component';
import { SubInvoiceListOverviewOrganizationSandbox } from './sub-invoice-list-overview-organization.sandbox';
import { ResetSection } from '../../../core/app-store/events/base.events';

@Component({
  selector: 'app-sub-invoice-list-overview-organziation',
  templateUrl: './sub-invoice-list-overview-organziation.component.html',
  styleUrls: ['./sub-invoice-list-overview-organziation.component.scss']
})
export class SubInvoiceListOverviewOrganziationComponent extends BaseWebComponentWithBreadcrumb implements OnInit {

  public organizationId: string = null;
  public invoiceRequestType: InvoiceRequestType = InvoiceRequestType.SubscriptionOrganization;
  public dataLoaded: boolean = false;

  constructor(public activatedRoute: ActivatedRoute,
              public sandbox: SubInvoiceListOverviewOrganizationSandbox) {
    super(sandbox, ResetSection.Invoices);
  }

  ngOnInit() {
    this.setBreadcrumbAP.execute([{text: 'Acasa', url: '/dashboard'}, {text: 'Facturi'}]);
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.organizationId !== undefined) {
        this.organizationId = params.organizationId;
        this.dataLoaded = true;
      }
    });
  }
}
