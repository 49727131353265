import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { RegisterCarServiceWorkmanshipActionProxy } from './register-car-service-workmanship.action-proxy';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { EditCarServiceWorkmanshipActionProxy } from './edit-car-service-workmanship.action-proxy';
import { ActivatedRoute } from '@angular/router';
import { CarServiceSaveWorkmanshipSandbox } from './car-service-save-workmanship.sandbox';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { CarServiceWorkmanship_Dto, Workmanship_Dto } from '../../../core/app-dto/workmanship.dto';
import { WorkmanshipFormComponent } from '../../../workmanship/components/workmanship-form/workmanship-form.component';


@Component({
  selector: 'app-car-service-save-workmanship',
  templateUrl: './car-service-save-workmanship.component.html',
  styleUrls: ['./car-service-save-workmanship.component.scss']
})
export class CarServiceSaveWorkmanshipComponent extends BaseFormModelComponent<CarServiceWorkmanship_Dto> implements OnInit, OnDestroy {
  public registerCarServiceWorkmanshipAP: RegisterCarServiceWorkmanshipActionProxy;
  public editCarServiceWorkmanshipAP: EditCarServiceWorkmanshipActionProxy;
  public title: string;
  public onClose: Subject<boolean>;

  public data: Workmanship_Dto = null;
  public carServiceId: string;

  @ViewChild(WorkmanshipFormComponent, { static: false })
  private workmanshipFormComponent: WorkmanshipFormComponent;


  constructor(public appState: Store<store.State>,
              public sandbox: CarServiceSaveWorkmanshipSandbox,
              private activeModal: BsModalRef,
              public activatedRoute: ActivatedRoute) {
    super(sandbox, ResetSection.CarPart);
    this.registerCarServiceWorkmanshipAP = new RegisterCarServiceWorkmanshipActionProxy(this, sandbox.appState);
    this.editCarServiceWorkmanshipAP = new EditCarServiceWorkmanshipActionProxy(this, sandbox.appState);
  }

  public diInitialize(data: CarServiceWorkmanship_Dto) {
    this.data = data.workmanship;
    this.carServiceId = data.carServiceId;
    this.title = ObjectValidators.isValidObject(this.data) ? 'Editare manopera' : 'Adauga manopera';
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.workmanshipFormComponent.accept();
  }

  public formSaved(model: Workmanship_Dto) {
    if (model.id) {
      this.editCarServiceWorkmanshipAP.execute(model, this.carServiceId);
    } else {
      this.registerCarServiceWorkmanshipAP.execute(model, this.carServiceId);
    }
    this.onClose.next(true);
    this.activeModal.hide();
  }
}
