import {BaseDI, BaseFormDI} from 'src/app/core/shared/base/base.di';
import {ActivateSubscription_Dto} from 'src/app/core/app-dto/authentication.dto';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "ngx-custom-validators";
import {Organization} from "../../../app-dto/organization.dto";

export class ActivateSubscriptionDI extends BaseFormDI<ActivateSubscription_Dto> {

  constructor(data: ActivateSubscription_Dto = new ActivateSubscription_Dto()) {
    super(data);
    this.initForm();
  }

  getModel(): ActivateSubscription_Dto {
    const formValue:ActivateSubscription_Dto = <ActivateSubscription_Dto>this.form.value;
    this.model.email = formValue.email;
    this.model.password = formValue.password;
    return this.model;
  }

  initForm() {
    let password = new FormControl('', Validators.compose([Validators.required]));
    let confirmPassword = new FormControl('', Validators.compose([Validators.required, CustomValidators.equalTo(password)]));

    this.form = new FormGroup({
      password: password,
      email: new FormControl('', Validators.compose([Validators.required, CustomValidators.email])),
      confirmPassword: confirmPassword
    });
  }

  isValid(): boolean {
    return this.form.valid;
  }
}
