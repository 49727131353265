import {Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, ElementRef, Input, ViewChildren, QueryList} from '@angular/core';
import * as store from '../../../core/app-store/index';
import {Store} from '@ngrx/store';
import {BaseFormComponent} from 'src/app/core/shared/base/base.component';
import {CarWorksListSandbox} from './car-works-list.sandbox';
import {CarWorksListDi} from './car-works-list.di';
import {CarWork_Dto} from '../../../core/app-dto/car-work.dto';
import {CarWorksSearchComponent} from '../car-works-search/car-works-search.component';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {CarAppointment_Dto} from '../../../core/app-dto/car-appointment.dto';
import {data} from 'jquery';

@Component({
    selector: 'app-car-works-list',
    templateUrl: './car-works-list.component.html',
    styleUrls: ['./car-works-list.component.scss']
})
export class CarWorksListComponent extends BaseFormComponent implements OnInit, OnDestroy {

    public clientSearch: string = '';
    public selectedCarWork: CarWork_Dto;

    @Output()
    modelSaved = new EventEmitter<Array<CarWork_Dto>>();

    @ViewChild('carWorkSearch', {static: false}) carWorkSearch: ElementRef;
    @ViewChildren(CarWorksSearchComponent) carWorkSearchComponent: QueryList<CarWorksSearchComponent>;

    @Input('carWorks') set carWorks(carWorks: Array<CarWork_Dto>) {
        this.di = new CarWorksListDi(carWorks);
    }

    @Input('model') model;
    @Input('make') make;
    @Input('year') year;

    constructor(public appState: Store<store.State>,
                public sandbox: CarWorksListSandbox) {
        super(sandbox);
        this.di = new CarWorksListDi([]);
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.destroy();
    }

    public accept() {
        this.formSubmitted = true;
        this.markFormAsDirtyAndTouched();
        if (this.di.isValid()) {
            if (this.modelSaved != null) {
                this.modelSaved.emit(this.di.getModel());
            }
        }
    }

    public emitValues() {

        if (this.modelSaved != null) {
            this.modelSaved.emit(this.di.getModel());
        }
    }

    isValid() {
        return this.di.isValid();
    }

    carWorkSelected($event) {
    }

    doNothing($event: any) {
    }

    clearSearch() {
        this.carWorkSearchComponent.last.clearSelect();
    }

    addCarWork() {
        this.setCarWorksInModel();
        this.clearSearch();
    }


    setCarWorksInModel(): void {
        this.di.model = [];
        this.carWorkSearchComponent.forEach(carWorkComponent => {
            const carWork = carWorkComponent.selectedItem;
            if (carWork) {
                if (!carWork.id) {
                    this.di.model.push({
                        id: '',
                        description: carWork.description
                    } as CarWork_Dto);
                } else {
                    this.di.model.push(carWork);
                }
            } else {
                const carWorkAsIdName = carWorkComponent.selectedItemValue;
                if (!carWorkAsIdName.id) {
                    this.di.model.push({
                        id: '',
                        description: carWorkAsIdName.name
                    } as CarWork_Dto);
                } else {
                    this.di.model.push({
                        id: carWorkAsIdName.id,
                        description: carWorkAsIdName.name
                    } as CarWork_Dto);
                }
            }
        });
        this.modelSaved.emit(this.di.getModel());
    }

    deleteCarWork(index: number) {
        this.di.model.splice(index, 1);
        this.modelSaved.emit(this.di.getModel());
    }
}
