import {BaseFormDI} from 'src/app/core/shared/base/base.di';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Car_Dto} from '../../../core/app-dto/car.dto';
import {min} from 'src/app/core/shared/form/custom-form.validator';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {StaticDataSelectorInput} from '../../../core/shared/components/static-data-selector/static-data-selector.component';
import * as moment from "moment";

export class CarFormDI extends BaseFormDI<Car_Dto> {
    public fuelTypesDataInput: StaticDataSelectorInput;
    public selectedValue: string = null;

    constructor(data: Car_Dto = new Car_Dto(null), public forGeneralOffer: boolean = false, public forOffer: boolean = false, public forOfferEdit: boolean = false) {
        super(new Car_Dto(data));
        if (ObjectValidators.isValidString(this.model.id)) {
            this.selectedValue = this.model.id;
        }
        this.initForm();
        if(this.forOffer==true) {
            this.fuelTypesDataInput = {
                form: this.form,
                type: 'fuelTypes',
                formControlName: 'fuel',
                isRequired: false,
                label: 'Combustibil',
                isReadOnly: false
            };
        }
        else{
            this.fuelTypesDataInput = {
                form: this.form,
                type: 'fuelTypes',
                formControlName: 'fuel',
                isRequired: true,
                label: 'Combustibil',
                isReadOnly: false
            };
        }
    }

    getModel(): Car_Dto {
        if (!this.forOffer || this.forOfferEdit) {
            this.model.registrationNumber = this.form.value.registrationNumber;
        }
        this.model.bodySeries = this.form.value.bodySeries;
        this.model.make = this.form.value.make;
        this.model.model = this.form.value.model;
        this.model.year = this.form.value.year;
        this.model.power = this.form.value.power;
        this.model.fuel = this.form.value.fuel;
        this.model.cilindricalCapacity = this.form.value.cilindricalCapacity;
        this.model.details = this.form.value.details;
        this.model.itpExpirationDate = this.form.value.itpExpirationDate;
        this.model.insuranceExpirationDate = this.form.value.insuranceExpirationDate;
        return this.model;
    }

    initForm() {
        if (this.forOffer) {
            if (this.forGeneralOffer === false) {
                if (this.forOfferEdit === false) {
                    this.form = new FormGroup({
                        bodySeries: new FormControl(this.model.bodySeries, Validators.compose([])),
                        make: new FormControl(this.model.make, Validators.compose([Validators.required])),
                        model: new FormControl(this.model.model, Validators.compose([Validators.required])),
                        year: new FormControl(this.model.year, Validators.compose([Validators.required])),
                        power: new FormControl(this.model.power, Validators.compose([min(-0.01)])),
                        cilindricalCapacity: new FormControl(this.model.cilindricalCapacity, Validators.compose([min(-0.01)])),
                        details: new FormControl(this.model.details, Validators.compose([]))
                    });
                } else {
                    this.form = new FormGroup({
                        registrationNumber: new FormControl(this.model.registrationNumber, Validators.compose([Validators.required])),
                        bodySeries: new FormControl(this.model.bodySeries, Validators.compose([])),
                        make: new FormControl(this.model.make, Validators.compose([Validators.required])),
                        model: new FormControl(this.model.model, Validators.compose([Validators.required])),
                        year: new FormControl(this.model.year, Validators.compose([Validators.required])),
                        power: new FormControl(this.model.power, Validators.compose([min(-0.01)])),
                        cilindricalCapacity: new FormControl(this.model.cilindricalCapacity, Validators.compose([min(-0.01)])),
                        details: new FormControl(this.model.details, Validators.compose([]))
                    });
                }
            } else {
                this.form = new FormGroup({
                    make: new FormControl(this.model.make, Validators.compose([Validators.required])),
                    model: new FormControl(this.model.model, Validators.compose([Validators.required])),
                    year: new FormControl(this.model.year, Validators.compose([Validators.required])),
                    power: new FormControl(this.model.power, Validators.compose([min(-0.01)])),
                    cilindricalCapacity: new FormControl(this.model.cilindricalCapacity, Validators.compose([min(-0.01)])),
                    details: new FormControl(this.model.details, Validators.compose([]))
                });
            }
        } else {
            this.form = new FormGroup({
                registrationNumber: new FormControl(this.model.registrationNumber, Validators.compose([Validators.required])),
                bodySeries: new FormControl(this.model.bodySeries, Validators.compose([Validators.required])),
                make: new FormControl(this.model.make, Validators.compose([Validators.required])),
                model: new FormControl(this.model.model, Validators.compose([Validators.required])),
                year: new FormControl(this.model.year, Validators.compose([Validators.required])),
                power: new FormControl(this.model.power, Validators.compose([Validators.required])),
                cilindricalCapacity: new FormControl(this.model.cilindricalCapacity, Validators.compose([Validators.required, min(0)])),
                details: new FormControl(this.model.details, Validators.compose([])),
                itpExpirationDate: new FormControl(this.model.itpExpirationDate, Validators.compose([])),
                insuranceExpirationDate: new FormControl(this.model.insuranceExpirationDate, Validators.compose([]))
            });
        }
    }
}
