import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeRo from '@angular/common/locales/ro';
import { ClipboardModule } from 'ngx-clipboard';

registerLocaleData(localeRo);

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EffectsModule} from "@ngrx/effects";

import {FlexLayoutModule} from '@angular/flex-layout';

import {AppSandbox} from "./app.sandbox";
import {LayoutModule} from "./core/layout/layout.module";
import {CoreModule} from "./core/core/core.module";
import {StoreModule} from "@ngrx/store";
import {reducers} from "./core/app-store/reducers";
import {INITIAL_STATE} from "./core/app-store";
import {NotifierModule} from "angular-notifier";
import {HttpClientModule} from "@angular/common/http";
import {ApiIntegrationModule} from './core/api-integration/api-integration.module';

import {CoreDataEventProcessor} from "./core/core/event-processors/data-event.processor";
import {AuthenticationUIEventProcessor} from './core/auth/event-processor/ui-event.processor';
import {SharedModule} from './core/shared/shared.module';
import {DataTablesModule} from 'angular-datatables';
import {SubscriptionUIEventProcessor} from './subscription/event-processor/ui-event.processor';
import {ModalModule} from 'ngx-bootstrap';
import {ServicePlatformUIEventProcessor} from './service-platform/event-processor/ui-event.processor';
import {MechanicUIEventProcessor} from './mechanic/event-processor/ui-event.processor';
import {ConfigService} from "./core/core/app-config.service";
import {LOCATION_INITIALIZED} from "@angular/common";
import {StaticDataModule} from "./core/static-data/static-data.module";
import {StaticDataEventProcessor} from "./core/static-data/events-processors/ui-event.processor";
import {UserUIEventProcessor} from './user/event-processor/ui-event.processor';
import {ProfileUIEventProcessor} from './profile/event-processor/ui-event.processor';
import {InvoiceUIEventProcessor} from './invoice/event-processor/ui-event.processor';
import {CarPartUIEventProcessor} from './car-part/event-processor/ui-event.processor';
import {CarUIEventProcessor} from './car/event-processor/ui-event.processor';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {CalendarUIEventProcessor} from './calendar/event-processor/ui-event.processor';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {OrganizationClientUIEventProcessor} from './organization-client/event-processor/ui-event.processor';
import {CarServiceUIEventProcessor} from './car-service/event-processor/ui-event.processor';
import {ServicePlatformStatusUIEventProcessor} from './service-platform-status-list/event-processor/ui-event.processor';
import {UploadUIEventProcessor} from './core/upload/events-processor/ui-event.processor';
import {AuditUIEventProcessor} from './audit/event-processor/ui-event.processor';
import {DocumentsUIEventProcessor} from './documents/event-processor/ui-event.processor';
import {DepartmentUIEventProcessor} from './department/event-processor/ui-event.processor';
import {BsDatepickerModule, TimepickerModule} from 'ngx-bootstrap';
import {DashboardModule} from './dashboard/dashboard.module';
import {QuillModule} from 'ngx-quill';
import {WorkmanshipUIEventProcessor} from './workmanship/event-processor/ui-event.processor';
import {ReportingUIEventProcessor} from './reporting/event-processor/ui-event.processor';
import {OrganizationCostUIEventProcessor} from './organization-cost/event-processor/ui-event.processor';
import {SmsUIEventProcessor} from "./sms/event-processor/ui-event.processor";
import {CarWorkUIEventProcessor} from './car-works/event-processor/ui-event.processor';
import {SupplyUIEventProcessor} from './supply/event-processor/ui-event.processor';
import {StripeEventProcessor} from "./core/stripe-integration/event-processors/stripe.event-processor";
import {CashRegisterUIEventProcessor} from "./cash-register/event-processors/ui-event.processor";
import {SupplierUIEventProcessor} from "./supplier/event-processor/ui-event.processor";
import {OfferUIEventProcessor} from './offer/event-processor/ui-event.processor';
import {SuperAdminLogsUIEventProcessor} from './super-admin-logs/event-processor/ui-event.processor';
import {OrganizationLogsSearchUIEventProcessor} from './super-admin-logs/organization-logs-search/event-processor/ui-event.processor';
import {ReceiptUIEventProcessor} from './receipt/event-processor/ui-event.processor';

export function appInitializerFactory(injector: Injector, config: ConfigService) {
    return () => new Promise<any>((resolve: any) => {
        config.load().then((configResult) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(() => {
                resolve(null);
            });
        }, err => {
            console.error(`Problem with loading settings`);
        });
    });
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        EffectsModule.forRoot([
            CoreDataEventProcessor,
            AuthenticationUIEventProcessor,
            SubscriptionUIEventProcessor,
            ServicePlatformUIEventProcessor,
            MechanicUIEventProcessor,
            UserUIEventProcessor,
            ProfileUIEventProcessor,
            StaticDataEventProcessor,
            InvoiceUIEventProcessor,
            CarPartUIEventProcessor,
            CarUIEventProcessor,
            CalendarUIEventProcessor,
            OrganizationClientUIEventProcessor,
            CarServiceUIEventProcessor,
            ServicePlatformStatusUIEventProcessor,
            UploadUIEventProcessor,
            AuditUIEventProcessor,
            DocumentsUIEventProcessor,
            DepartmentUIEventProcessor,
            WorkmanshipUIEventProcessor,
            ReportingUIEventProcessor,
            OrganizationCostUIEventProcessor,
            SmsUIEventProcessor,
            CarWorkUIEventProcessor,
            SupplyUIEventProcessor,
            StripeEventProcessor,
            CashRegisterUIEventProcessor,
            SupplierUIEventProcessor,
            OfferUIEventProcessor,
            SuperAdminLogsUIEventProcessor,
            OrganizationLogsSearchUIEventProcessor,
            ReceiptUIEventProcessor
        ]),
        LayoutModule,
        CoreModule,
        StaticDataModule,
        SharedModule,
        ApiIntegrationModule,
        FlexLayoutModule,
        DataTablesModule,
        StoreModule.forRoot(reducers, {
            initialState: INITIAL_STATE
        }),
        NotifierModule.withConfig({
            // Custom options in here
            position: {
                horizontal: {
                    position: 'right',
                    distance: 12
                },

                vertical: {
                    position: 'top',
                    distance: 12,
                    gap: 10
                }
            }
        }),
        ModalModule.forRoot(),
        TimepickerModule.forRoot(),
        BsDatepickerModule.forRoot(),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        DeviceDetectorModule.forRoot(),
        QuillModule.forRoot(),
        DashboardModule,
        ClipboardModule
    ],
    providers: [AppSandbox, {
        provide: APP_INITIALIZER,
        useFactory: appInitializerFactory,
        deps: [Injector, ConfigService],
        multi: true,
    }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
