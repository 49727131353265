import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseFormModelComponent} from '../../../core/shared/base/base.component';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {BsModalRef} from 'ngx-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {EditCarServiceClientDi} from '../../../car-service/components/edit-car-service-client/edit-car-service-client.di';
import {OrganizationClient_Dto} from '../../../core/app-dto/organization-client.dto';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {Offer_Dto} from '../../../core/app-dto/offer.dto';
import {UpdateOfferClientDataActionProxy} from '../../common/ap/update-offer-client-data.action-proxy';
import {AddOfferClientComponent} from '../add-offer-wizard/add-offer-client-data/add-offer-client.component';

@Component({
    selector: 'app-update-offer-client-data',
    templateUrl: './update-offer-client-data.component.html',
    styleUrls: ['./update-offer-client-data.component.scss']
})
export class UpdateOfferClientDataComponent extends BaseFormModelComponent<Offer_Dto> implements OnInit, OnDestroy {
    public editDataAP: UpdateOfferClientDataActionProxy;
    public onClose: Subject<boolean>;
    public offer: Offer_Dto;

    @ViewChild(AddOfferClientComponent, {static: false})
    private clientFormComponent: AddOfferClientComponent;

    constructor(public appState: Store<store.State>,
                private activeModal: BsModalRef,
                public activatedRoute: ActivatedRoute) {
        super(null, null);
        this.editDataAP = new UpdateOfferClientDataActionProxy(this, appState);
        this.di = new EditCarServiceClientDi();
    }

    public diInitialize(data: Offer_Dto) {
        this.offer = new Offer_Dto(data);
        this.dataLoaded = true;
    }

    ngOnInit() {
        this.onClose = new Subject();
    }

    ngOnDestroy() {
        this.destroy();
    }

    public decline() {
        this.onClose.next(false);
        this.activeModal.hide();
    }

    public accept() {
        this.formSubmitted = true;
        this.clientFormComponent.accept();
        this.clientFormComponent.markFormAsDirtyAndTouched();
    }

    clientDetailsFormSaved(data: OrganizationClient_Dto) {
        if (ObjectValidators.isValidObject(data)) {
            this.offer.client = data;
            if (this.editDataAP.canExecute()) {
                this.editDataAP.execute(this.offer);
                this.onClose.next(true);
                this.activeModal.hide();
            }
        }
    }
}
