import {Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {BaseFormModelComponent} from 'src/app/core/shared/base/base.component';
import {CarServiceDetailsCarPartsDi} from './car-service-details-car-parts.di';
import * as store from '../../../core/app-store/index';
import {Store} from '@ngrx/store';
import {CarServiceDetailsCarPartsSandbox} from './car-service-details-car-parts.sandbox';
import {CarPart_Dto} from 'src/app/core/app-dto/car-part.dto';
import {CarPartSearchComponent} from 'src/app/car-part/components/car-part-search/car-part-search.component';
import {BsModalService} from 'ngx-bootstrap';
import {CarSerivceDetailsAddCarPartActionProxy} from './car-service-details-add-car-part.action-proxy';
import {CarServiceSaveCarPartComponent} from '../car-service-save-car-part/car-service-save-car-part.component';
import {CarSerivceDetailsDeleteCarPartActionProxy} from './car-service-details-delete-car-part.action-proxy';
import {ConfirmationDialogService} from "../../../core/core/dialog/confirmation-dialog.service";
import {DataTableDirective} from 'angular-datatables';
import {ConfigService} from '../../../core/core/app-config.service';
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";
import {Router} from "@angular/router";

@Component({
  selector: 'app-car-service-details-car-parts',
  templateUrl: './car-service-details-car-parts.component.html',
  styleUrls: ['./car-service-details-car-parts.component.scss']
})
export class CarServiceDetailsCarPartsComponent extends BaseFormModelComponent<Array<CarPart_Dto>> implements OnInit, OnDestroy {

  public columns: any = [];
  public dtApiInstance: DataTables.Api;
  public dtOptions = null;
  public datatable: DataTableDirective;

  @ViewChild(CarPartSearchComponent, {static: false}) carPartSearchComponent: CarPartSearchComponent;

  @Input() carServiceId: string;
  @Input() isReadOnly: boolean = false;

  @ViewChildren(DataTableDirective) set datatableValue(datatable: QueryList<DataTableDirective>) {
    if (datatable != null && datatable.first != null) {
      datatable.first.dtInstance.then((dtInstance: DataTables.Api) => {
        this.dtApiInstance = dtInstance;
      });
    }
  }

  public di: CarServiceDetailsCarPartsDi;
  public originalItems: Array<CarPart_Dto>;
  public carSerivceDetailsAddCarPartAP: CarSerivceDetailsAddCarPartActionProxy;
  public carServiceDetailsDeleteCarPartAP: CarSerivceDetailsDeleteCarPartActionProxy;

  constructor(public appState: Store<store.State>,
              private modalService: BsModalService,
              private configService: ConfigService,
              public confirmationDialogService: ConfirmationDialogService,
              public rulesProvider: AppUserBusinessRuleProvider,
              public router: Router,
              public sandbox: CarServiceDetailsCarPartsSandbox) {
    super(sandbox);
    this.carSerivceDetailsAddCarPartAP = new CarSerivceDetailsAddCarPartActionProxy(this, sandbox.appState);
    this.carServiceDetailsDeleteCarPartAP = new CarSerivceDetailsDeleteCarPartActionProxy(this, sandbox.appState);
  }

  public diInitialize(data: Array<CarPart_Dto>) {
    this.di = new CarServiceDetailsCarPartsDi(data);
    this.originalItems = data;
    this.dataLoaded = true;
    this.sandbox.initialize();
  }

  ngOnInit() {
    this.columns = [
      {data: 'code', name: 'Cod piesa', width: '15%'},
      {data: 'name', name: 'Denumire', width: '15%'},
      {data: 'quantity', name: 'Cantitate', width: '10%'},
      {data: 'priceWithNoVAT', name: 'Pret fara TVA', width: '15%'},
      {data: 'priceWithVAT', name: 'Pret cu TVA', width: '15%'},
      {data: 'totalPriceWithVAT', name: 'Pret total cu TVA', width: '15%'},
      {data: 'action', width: '100px'}
    ];

    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      pageLength: this.configService.getDefaultPageSize(),
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      columns: this.columns,
      order: [[3, 'asc']],
      ajax: () => {
      },
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
  }

  ngOnDestroy() {
    this.destroy();
  }

  public addNewCarPart() {

    const modalRef = this.modalService.show(CarServiceSaveCarPartComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
    modalRef.content.allItems = this.originalItems;
    modalRef.content.dataInput = {
      carServiceId: this.carServiceId,
      carPart: null
    };
  }

  public updateCarPart(carPart: CarPart_Dto) {
    const modalRef = this.modalService.show(CarServiceSaveCarPartComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
    modalRef.content.allItems = this.originalItems;
    modalRef.content.dataInput = {
      carServiceId: this.carServiceId,
      carPart: carPart
    };
  }

  delete(carPart:CarPart_Dto) {
    this.confirmationDialogService.confirm(
      "Stergere piesa",
      "Urmeaza sa stergeti piesa. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        // let i = (<CarServiceDetailsCarPartsDi>this.di).model.indexOf(carPart);
        // (<CarServiceDetailsCarPartsDi>this.di).model.splice(i, 1);

        // replace with faster .filter function

        if (this.carServiceDetailsDeleteCarPartAP.canExecute()) {
          this.carServiceDetailsDeleteCarPartAP.execute(this.carServiceId, carPart);
        }
      }
    });

  }

  isValid() {
    return this.di.isValid();
  }

  carPartSelected(carPart: CarPart_Dto) {
    if (carPart) {
      carPart.quantity = 1;
      this.updateCarPart(carPart);
    }
    this.carPartSearchComponent.clearSelect();
  }

  public canAddServiceCarPart() {
    if (!this.sandbox.organization) {
      return false;
    }
    return this.rulesProvider.rules.canManageSupplies == false;
  }
}
