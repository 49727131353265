import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreModule} from '../core/core.module';

import {AuthApiService} from './services/auth-api.service';
import {SubscriptionApiService} from './services/subscription-api.service';
import {ServicePlatformApiService} from './services/service-platform-api.service';
import {MechanicApiService} from './services/mechanic-api.service';
import {UserApiService} from './services/user-api.service';
import {StaticDataApiService} from './services/static-data.service';
import {ErrorInterceptorProvider} from './utils/api-error.interceptor';
import {ProfileApiService} from './services/profile-api.service';
import {InvoiceApiService} from './services/invoice-api.service';
import {CarApiService} from './services/car-api.service';
import {CarPartApiService} from './services/car-part-api.service';
import {CalendarApiService} from './services/calendar-api.service';
import {SupplierApiService} from './services/supplier-api.service';
import {OrganizationClientApiService} from './services/organization-client-api.service';
import {DraftApiService} from './services/draft-api.service';
import {CarServiceApiService} from './services/car-service-api.service';
import {ServicePlatformStatusApiService} from './services/service-platform-status-api.service';
import {AuditApiService} from './services/audit-api.service';
import {DepartmentApiService} from './services/department-api.service';
import {DocumentsApiService} from './services/documents-api.service';
import {ReportingApiService} from './services/reporting-api.service';
import {OrganizationCostApiService} from './services/organization-cost.service';
import {SmsApiService} from './services/sms-api.service';
import {AsyncJobApiSerivce} from './services/async-job-api.serivce';
import {CarWorkApiService} from './services/car-work-api.service';
import {SupplyApiService} from './services/supply-api.service';
import {StripeApiService} from "./services/stripe-api.service";
import {AppNotificationService} from "./services/app-notification.service";
import {CashRegisterApiService} from "./services/cash-register-api.service";
import {InvoicePrintApiService} from "./services/invoice-print-api.service";
import {OfferApiService} from './services/offer-api.service';
import {SuperAdminLogsApiService} from './services/super-admin-logs.api.service';
import {ReceiptPrintApiService} from './services/receipt-print-api.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreModule
  ],
  providers: [
    AuthApiService,
    SubscriptionApiService,
    ServicePlatformApiService,
    MechanicApiService,
    UserApiService,
    ProfileApiService,
    StaticDataApiService,
    ErrorInterceptorProvider,
    InvoiceApiService,
    CarPartApiService,
    CarApiService,
    CalendarApiService,
    SupplierApiService,
    OrganizationClientApiService,
    DraftApiService,
    CarServiceApiService,
    ServicePlatformStatusApiService,
    AuditApiService,
    DocumentsApiService,
    DepartmentApiService,
    ReportingApiService,
    OrganizationCostApiService,
    SmsApiService,
    AsyncJobApiSerivce,
    CarWorkApiService,
    SupplyApiService,
    StripeApiService,
    CashRegisterApiService,
    InvoicePrintApiService,
    AppNotificationService,
    OfferApiService,
    SuperAdminLogsApiService,
    ReceiptPrintApiService
  ]
})
export class ApiIntegrationModule {
}
