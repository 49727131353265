import { BaseDI } from 'src/app/core/shared/base/base.di';
import { Department_Dto } from '../../../core/app-dto/department.dto';

export class DepartmentDetailsDi extends BaseDI<Department_Dto> {
    constructor(data: Department_Dto = new Department_Dto(null)) {
        super(data);
    }

    getModel(): Department_Dto {
        return this.model;
    }
}
