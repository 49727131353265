import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {BaseWebComponent} from 'src/app/core/shared/base/base.component';
import {ActivatedRoute} from '@angular/router';
import {GetCarServiceCreditLimitActionProxy} from './get-car-service-credit-limit.action-proxy';
import {CarServiceCreditLimitSandbox} from './car-service-credit-limit.sandbox';
import {ResetSection} from 'src/app/core/app-store/events/base.events';
import {isValidNumber} from "../../../core/shared/helpers/common.helpers";

@Component({
  selector: 'app-car-service-credit-limit',
  templateUrl: './car-service-credit-limit.component.html',
  styleUrls: ['./car-service-credit-limit.component.scss']
})
export class CarServiceCreditLimitComponent extends BaseWebComponent implements OnInit, OnDestroy {
  public getCarServiceAP: GetCarServiceCreditLimitActionProxy;
  public credit: number = 0;
  public showCreditWarning = false;
  public warningMessage = '';
  private creditWarningTreshhold = 0.9;

  @Input() organizationClientId: string = '';
  @Input() organizationClientCreditLimit: number = 0;
  @Input() organizationClientCurreny: string = '';

  constructor(public sandbox: CarServiceCreditLimitSandbox,
              public activatedRoute: ActivatedRoute) {
    super(sandbox, ResetSection.Car);
    this.getCarServiceAP = new GetCarServiceCreditLimitActionProxy(this, sandbox.appState);
  }

  ngOnInit() {
    this.initialize((credit: number) => {
      if(!isValidNumber(this.organizationClientCreditLimit)) {
        this.showCreditWarning = false;
        return;
      }

      if ( this.organizationClientCreditLimit  !== 0 && credit >= this.organizationClientCreditLimit * this.creditWarningTreshhold &&
          credit <= this.organizationClientCreditLimit) {
        this.showCreditWarning = true;
        this.warningMessage = `Creditul ${credit}${this.organizationClientCurreny} este aproape de limita de credit: ` +
            `${this.organizationClientCreditLimit}${this.organizationClientCurreny}`;
      } else if (this.organizationClientCreditLimit !== 0 && credit > this.organizationClientCreditLimit) {
        this.warningMessage = `Creditul ${credit}${this.organizationClientCurreny} a depasit limita de credit: ` +
            `${this.organizationClientCreditLimit}${this.organizationClientCurreny}`;
        this.showCreditWarning = true;
      } else {
        this.showCreditWarning = false;
      }
    });
    this.getCarServiceAP.execute(this.organizationClientId);
  }

  ngOnDestroy() {
    this.destroy();
  }
}
