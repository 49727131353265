import {Injectable} from '@angular/core';
import {BaseApiService} from './base-api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiErrorDto} from '../utils/api-error.dto';
import {ConfigService} from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {ActiveDataTableParameters, PagedResult} from '../../shared/datatable/datatable.helpers';
import {Mechanic_Dto, MechanicWithActionsAndCar_Dto, RequestAccountForMechanic_Dto} from '../../app-dto/mechanic.dto';
import {ErrorHelpers} from '../../shared/error.helpers';
import {ObjectValidators} from '../../shared/object.validators';
import {cleanSearchValue} from '../utils/api-integration.helper';
import {CalendarEventRelatedEntityType} from "../../app-dto/calendar-event.dto";

class MechanicApiRoutes {
  public static route_for_GET_MECHANICS_PAGINATED() {
    return '/api/mechanic/all';
  }

  public static route_for_GET_MECHANIC(mechanicId: string) {
    return `/api/mechanic/${mechanicId}`;
  }

  public static route_for_GET_MECHANIC_LIST() {
    return `/api/mechanic/list`;
  }

  public static route_for_REGISTER_MECHANIC() {
    return '/api/mechanic/';
  }

  public static route_for_EDIT_MECHANIC() {
    return '/api/mechanic/';
  }

  public static route_for_DELETE_MECHANIC(mechanicId: string) {
    return `/api/mechanic/${mechanicId}`;
  }

  public static route_for_ACTIVATE_MECHANIC(mechanicId: string) {
    return `/api/mechanic/${mechanicId}/activate`;
  }

  public static route_for_DEACTIVATE_MECHANIC(mechanicId: string) {
    return `/api/mechanic/${mechanicId}/deactivate`;
  }

  public static route_for_REGISTER_MECHANIC_ACCOUNT(mechanicId: string) {
    return `/api/mechanic/${mechanicId}/account`;
  }

  public static route_for_DELETE_MECHANIC_ACCOUNT(mechanicId: string) {
    return `/api/mechanic/${mechanicId}/account`;
  }

  public static route_for_GET_MECHANIC_WITH_STATUS_LIST() {
    return `/api/mechanic/list/extended`;
  }

  public static route_for_GET_MECHANIC_AVAILABILITY() {
    return `/api/mechanic/availability`;
  }
}

@Injectable()
export class MechanicApiService extends BaseApiService {

  constructor(private http: HttpClient, configService: ConfigService) {
    super(configService);
  }

  u_registerMechanicAccount(data: RequestAccountForMechanic_Dto): Observable<Mechanic_Dto> {
    const url = this.configService.getAPIBaseUrl() + MechanicApiRoutes.route_for_REGISTER_MECHANIC_ACCOUNT(data.mechanicId);

    return this.http.post(url, data)
      .map((res: any) => {
        return new Mechanic_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_registerMechanic(mechanicData: Mechanic_Dto): Observable<Mechanic_Dto> {
    const url = this.configService.getAPIBaseUrl() + MechanicApiRoutes.route_for_REGISTER_MECHANIC();

    return this.http.post(url, mechanicData)
      .map((res: any) => {
        return new Mechanic_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_editMechanic(mechanicData: Mechanic_Dto, updatedHistory: boolean): Observable<Mechanic_Dto> {
    const url = this.configService.getAPIBaseUrl() + MechanicApiRoutes.route_for_EDIT_MECHANIC() + '?updatedHistory=' + updatedHistory;

    return this.http.put(url, mechanicData)
      .map((res: any) => {
        return new Mechanic_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getFilteredMechanicsPaginated(data: ActiveDataTableParameters): Observable<PagedResult<Mechanic_Dto>> {
    const url = this.configService.getAPIBaseUrl() + MechanicApiRoutes.route_for_GET_MECHANICS_PAGINATED() + '?' + data.toUrlParameters();

    return this.http.get(url)
      .map((res: PagedResult<Mechanic_Dto>) => {
        //@todo : add check to validate result ( response will be wrapped in a ApiResponse object
        return new PagedResult<Mechanic_Dto>(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getMechanic(mechanicId: string): Observable<Mechanic_Dto> {
    const url = this.configService.getAPIBaseUrl() + MechanicApiRoutes.route_for_GET_MECHANIC(mechanicId);

    return this.http.get(url)
      .map((res: any) => {
        //@todo : add check to validate result ( response will be wrapped in a ApiResponse object
        return new Mechanic_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getMechanicList(): Observable<Array<Mechanic_Dto>> {
    const url = this.configService.getAPIBaseUrl() + MechanicApiRoutes.route_for_GET_MECHANIC_LIST();

    return this.http.get(url)
      .map((res: any) => {
        //@todo : add check to validate result ( response will be wrapped in a ApiResponse object
        return res.map(r => new Mechanic_Dto(r));
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_deleteMechanic(mechanicId: string): Observable<Mechanic_Dto> {
    const url = this.configService.getAPIBaseUrl() + MechanicApiRoutes.route_for_DELETE_MECHANIC(mechanicId);

    return this.http.delete(url)
      .map((res: any) => {
        //@todo : add check to validate result ( response will be wrapped in a ApiResponse object
        return new Mechanic_Dto(null);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_activateMechanic(mechanicId: string, startDate: Date): Observable<Mechanic_Dto> {
    const url = this.configService.getAPIBaseUrl() + MechanicApiRoutes.route_for_ACTIVATE_MECHANIC(mechanicId) + '?startDate=' + startDate.toISOString();

    return this.http.patch(url, {})
      .map((res: any) => {
        //@todo : add check to validate result ( response will be wrapped in a ApiResponse object
        return new Mechanic_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_deactivateMechanic(mechanicId: string, endDate: Date): Observable<Mechanic_Dto> {
    const url = this.configService.getAPIBaseUrl() + MechanicApiRoutes.route_for_DEACTIVATE_MECHANIC(mechanicId) + '?endDate=' + endDate.toISOString();

    return this.http.patch(url, {})
      .map((res: any) => {
        //@todo : add check to validate result ( response will be wrapped in a ApiResponse object
        return new Mechanic_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_deleteAccountForMechanic(mechanicId: string): Observable<Mechanic_Dto> {
    const url = this.configService.getAPIBaseUrl() + MechanicApiRoutes.route_for_DELETE_MECHANIC_ACCOUNT(mechanicId);

    return this.http.delete(url, {})
      .map((res: any) => {
        //@todo : add check to validate result ( response will be wrapped in a ApiResponse object
        return new Mechanic_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getMechanicsWithStatusList(): Observable<Array<MechanicWithActionsAndCar_Dto>> {
    const url = this.configService.getAPIBaseUrl() + MechanicApiRoutes.route_for_GET_MECHANIC_WITH_STATUS_LIST();

    return this.http.get(url)
      .map((res: any) => {
        //@todo : add check to validate result ( response will be wrapped in a ApiResponse object
        return res.map(r => new MechanicWithActionsAndCar_Dto(r));
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getMechanicAvailability(startDate: Date, endDate: Date, mechanicId: string = null, except: string = null, relatedEntityType:CalendarEventRelatedEntityType = CalendarEventRelatedEntityType.Appointment): Observable<boolean> {
    var url = this.configService.getAPIBaseUrl() + MechanicApiRoutes.route_for_GET_MECHANIC_AVAILABILITY() + '?' +
        'startDate=' + startDate.toISOString()  + '&' +
        'endDate=' + endDate.toISOString() ;
    if (ObjectValidators.isValidString(mechanicId))
      url = url + '&mechanicId=' + mechanicId;
    if (ObjectValidators.isValidString(except))
      url = url + '&except=' + cleanSearchValue(except) + "&relatedEntityType=" + relatedEntityType;

    return this.http.get(url)
        .map((res: any) => {
          return res;
        })
        .catch<any, any>((err: ApiErrorDto, o) => {
          return ErrorHelpers.throwError(err);
        });
  }
}
