import {ViewChild} from "@angular/core";
import * as moment from 'moment';
import {DaterangePickerComponent} from "ng2-daterangepicker";

export class DatePickerDI {
  public options: any;

  constructor() {
    this.options = {
      locale: {
        format: 'DD/MM/YYYY',
        cancelLabel: "Anuleaza",
        applyLabel: "Aplica",
        customRangeLabel: "Custom",
        daysOfWeek: [
          "D", "L", "M", "M", "J", "V", "S"
        ],
        monthNames: ["Ian", "Feb", "Mar", "Apr", "Mai", "Iun", "Iul", "Aug", "Sept", "Oct", "Noi", "Dec"
        ],
      },
      alwaysShowCalendars: true,
      autoUpdateInput: true,
      showClearDateBtn: true,
      ranges: {
        'Luna trecuta': [moment().subtract(1, 'month'), moment()],
        'Ultimele 3 luni': [moment().subtract(4, 'month'), moment()],
        'Ultimele 6 luni': [moment().subtract(6, 'month'), moment()],
        'Ultimele 12 luni': [moment().subtract(12, 'month'), moment()],
        'Anul trecut': [moment().subtract(12, 'month').startOf('year'), moment().subtract(12, 'month').endOf('year')],
        'Anul curent': [moment().startOf('year'), moment()],
      },
      startDate: moment().subtract(1, 'month').format("DD/MM/YYYY"),
      endDate:  moment().format("DD/MM/YYYY")
    }
  }
  public getDefaultStartDate():Date{
    return moment().subtract(1, 'month').toDate();
  }
  public getDefaultEndDate():Date{
    return moment().toDate();
  }
}
