import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {BaseFormModelComponent} from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { ActivatedRoute } from '@angular/router';
import { EditCarServiceDataSandbox } from './edit-car-service-data.sandbox';
import { EditCarServiceDataDi } from './edit-car-service-data.di';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { EditCarServiceDataActionProxy } from './edit-car-service-data.action-proxy';
import { OrganizationClient_Dto } from '../../../core/app-dto/organization-client.dto';
import { CarService_Dto, CarServiceEntryData_Dto } from '../../../core/app-dto/car-service.dto';
import { CarServiceDataFormComponent } from '../car-service-data-form/car-service-data-form.component';


@Component({
  selector: 'app-edit-car-service-data',
  templateUrl: './edit-car-service-data.component.html',
  styleUrls: ['./edit-car-service-data.component.scss']
})
export class EditCarServiceDataComponent extends BaseFormModelComponent<CarService_Dto> implements OnInit, OnDestroy {
  public editCarServiceDataAP: EditCarServiceDataActionProxy;
  public onClose: Subject<boolean>;

  @ViewChild(CarServiceDataFormComponent, { static: false })
  private carServiceDataFormComponent: CarServiceDataFormComponent;

  constructor(public appState: Store<store.State>,
              public sandbox: EditCarServiceDataSandbox,
              private activeModal: BsModalRef,
              public activatedRoute: ActivatedRoute) {
    super(sandbox, ResetSection.CarService);
    this.editCarServiceDataAP = new EditCarServiceDataActionProxy(this, sandbox.appState);
    this.di = new EditCarServiceDataDi();
  }

  public diInitialize(data: CarService_Dto) {
    this.di = new EditCarServiceDataDi(data);
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.formSubmitted = true;
    this.carServiceDataFormComponent.accept();
    this.carServiceDataFormComponent.markFormAsDirtyAndTouched();
  }

  carServiceEntryDataFormSaved(data: CarServiceEntryData_Dto) {
    if (ObjectValidators.isValidObject(data)) {
      this.di.model.carServiceEntryData = data;
      if (this.editCarServiceDataAP.canExecute()) {
        this.editCarServiceDataAP.execute();
        this.onClose.next(true);
        this.activeModal.hide();
      }
    }
  }
}
