import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { ObjectValidators } from '../../../core/shared/object.validators';
import {CarAction_Dto, CarService_Dto} from 'src/app/core/app-dto/car-service.dto';
import { RegisterActionActionProxy } from './register-action.action-proxy';
import { CarServiceAddActionSandbox } from './car-service-add-action.sandbox';
import { CarServiceAddActionDI } from './car-service-add-action.di';
import { GetDataActionProxy } from './get-data.action-proxy';
import { StaticDataSelectorInput } from 'src/app/core/shared/components/static-data-selector/static-data-selector.component';
import { UpdateActionActionProxy } from './update-action.action-proxy';
import { CarWork_Dto } from '../../../core/app-dto/car-work.dto';
import {DateTimeService} from "../../../core/core/services/date-time.service";
import {isValidObject, makeInputPositive} from '../../../core/shared/helpers/common.helpers';

@Component({
  selector: 'app-car-service-add-action',
  templateUrl: './car-service-add-action.component.html',
  styleUrls: ['./car-service-add-action.component.scss']
})
export class CarServiceAddActionComponent extends BaseFormModelComponent<CarAction_Dto> implements OnInit, OnDestroy {
  public registerActionAP: RegisterActionActionProxy;
  public updateActionAP: UpdateActionActionProxy;
  public getDataAP: GetDataActionProxy;
  public title: string;
  public onClose: Subject<boolean>;
  public di:CarServiceAddActionDI;

  public data: CarAction_Dto = null;
  public carServiceEntryId: string = null;
  public canChangeAllInfo : boolean = false;

  public statusTypesDataInput: StaticDataSelectorInput;
  public isEditing: boolean = false;
  public carWorkSearch: string = '';

  @Input('carName') carName: string;
  @Input('carInServiceState') carInServiceState: string;

  public carMake:string;
  public carModel:string;
  public carYear:number;

  constructor(
    public appState: Store<store.State>,
    public sandbox: CarServiceAddActionSandbox,
    private activeModal: BsModalRef,
    public dateTimeService: DateTimeService,
  ) {
    super(sandbox, ResetSection.OrganizationClient);
    this.registerActionAP = new RegisterActionActionProxy(this, sandbox.appState);
    this.updateActionAP = new UpdateActionActionProxy(this, sandbox.appState);
    this.getDataAP = new GetDataActionProxy(this, sandbox.appState);
  }

  public diInitialize(data: CarAction_Dto) {
    this.di = new CarServiceAddActionDI(data,this.dateTimeService, this.canChangeAllInfo);
    this.di.model.carServiceEntryId = this.carServiceEntryId;
    if (data) {
      this.carWorkSearch = data.carWork != null ? data.carWork.description : data.action;
      this.di.model.carWork = data.carWork;
      this.setCarWork(data.carWork);
    }

    this.statusTypesDataInput = {
      form: this.di.form,
      type: 'carServiceEntryActionStatus',
      formControlName: 'status',
      isRequired: true,
      label: 'Status',
      isReadOnly:false
    };
    this.dataLoaded = true;
  }



  ngOnInit() {
    this.getDataAP.execute();
    this.sandbox.initialize((carService:CarService_Dto)=> {
      if(isValidObject(carService.car)){
        this.carMake = carService.car.make;
        this.carModel = carService.car.model;
        this.carYear = carService.car.year;
      }
    });
    if (ObjectValidators.isValidObject(this.data))
      this.isEditing = true;
    this.diInitialize(this.data);
    this.onClose = new Subject();
  }

  ngOnDestroy() {
    this.destroy();
  }

  setCarWork(carWork: CarWork_Dto) {
    this.di.form.controls['carWork'].setValue(carWork);
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    if(this.di.busyFlag) return;
    this.formSubmitted = true;
    if (this.registerActionAP.canExecute()) {
      if (this.isEditing)
        this.updateActionAP.execute();
      else
        this.registerActionAP.execute();
      this.onClose.next(true);
      this.activeModal.hide();
    }
  }
  dismissUnavailableServicePlatform() {
    this.di.form.controls.servicePlatform.setValue(null);
  }


  dismissUnavailableMechanic() {
    this.di.form.controls.mechanic.setValue(null);
  }

  makeValuePositive(event: any) {
    makeInputPositive(event);
  }

}
