import { Component, OnInit, OnDestroy } from '@angular/core';
import {BaseWebComponent, BaseWebComponentWithBreadcrumb} from 'src/app/core/shared/base/base.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GetCarPartActionProxy } from './get-car-part.action-proxy';
import { CarPartDetailsSandbox } from './car-part-details.sandbox';
import { CarPartDetailsDi } from './car-part-details.di';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { CarPart_Dto } from '../../../core/app-dto/car-part.dto';
import { SaveCarPartComponent } from '../save-car-part/save-car-part.component';
import { BsModalService} from 'ngx-bootstrap';
import { ActivateCarPartActionProxy } from '../../../core/shared/car-parts-table/activate-car-part.action-proxy';
import { DeactivateCarPartActionProxy } from '../../../core/shared/car-parts-table/deactivate-car-part.action-proxy';
import { DeleteCarPartActionProxy } from '../../../core/shared/car-parts-table/delete-car-part.action-proxy';
import { ConfirmationDialogService } from '../../../core/core/dialog/confirmation-dialog.service';
import { Supply_Dto } from '../../../core/app-dto/supply.dto';
import { DataTableParameters } from '../../../core/shared/datatable/datatable.helpers';
import { ConfigService } from '../../../core/core/app-config.service';
import { AppUserBusinessRuleProvider } from '../../../core/core/business-rule-provider/app-user.business-rule.provider';
import { ActivateCarPartStockAlertActionProxy } from '../../../core/shared/car-parts-table/activate-car-part-stock-alert.action-proxy';
import { DeactivateCarPartStockAlertActionProxy } from '../../../core/shared/car-parts-table/deactivate-car-part-stock-alert.action-proxy';
import {Breadcrumb} from "../../../core/app-dto/breadcrumb.dto";
import {isValidString} from "../../../core/shared/helpers/common.helpers";


@Component({
  selector: 'app-car-part-details',
  templateUrl: './car-part-details.component.html',
  styleUrls: ['./car-part-details.component.scss']
})
export class CarPartDetailsComponent extends BaseWebComponentWithBreadcrumb implements OnInit, OnDestroy {
  public getCarPartAP: GetCarPartActionProxy;
  public activateCarPartAP: ActivateCarPartActionProxy;
  public deactivateCarPartAP: DeactivateCarPartActionProxy;
  public deleteCarPartAP: DeleteCarPartActionProxy;
  public activateCarPartStockAlertAP: ActivateCarPartStockAlertActionProxy;
  public deactivateCarPartStockAlertAP: DeactivateCarPartStockAlertActionProxy;
  public carPartId: string = null;
  public supplies: Supply_Dto[] = [];
  public dtOptions;

  constructor(public sandbox: CarPartDetailsSandbox,
              public activatedRoute: ActivatedRoute,
              public router: Router, public confirmationDialogService: ConfirmationDialogService,
              private modalService: BsModalService,
              private configService: ConfigService,
              public rulesProvider: AppUserBusinessRuleProvider) {
    super(sandbox, ResetSection.CarPart);
    this.getCarPartAP = new GetCarPartActionProxy(this, sandbox.appState);
    this.activateCarPartAP = new ActivateCarPartActionProxy(this, sandbox.appState);
    this.deactivateCarPartAP = new DeactivateCarPartActionProxy(this, sandbox.appState);
    this.deleteCarPartAP = new DeleteCarPartActionProxy(this, sandbox.appState);
    this.activateCarPartStockAlertAP = new ActivateCarPartStockAlertActionProxy(this, sandbox.appState);
    this.deactivateCarPartStockAlertAP = new DeactivateCarPartStockAlertActionProxy(this, sandbox.appState);
    this.di = new CarPartDetailsDi();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id !== undefined) {
        this.carPartId = params.id;
        this.getCarPartAP.execute(this.carPartId);
      }
    });

    this.initialize((data: CarPart_Dto, supplies: Supply_Dto[]) => {
      this.di = new CarPartDetailsDi(data);
      this.supplies = supplies;
      this.setBreadcrumbAP.execute([ new Breadcrumb({text: 'Acasa', url: '/dashboard'}),new Breadcrumb({text: 'Piese', url:'/car-part/all'}),
        new Breadcrumb({text:data.code})]);
      this.dataLoaded = true;
    }, ()=>{
      if(isValidString(this.carPartId)){
        this.getCarPartAP.execute(this.carPartId);
      }
    });

  }

  ngOnDestroy() {
    this.destroy();
  }

  updateCarPart() {
    const modalRef = this.modalService.show(SaveCarPartComponent, { backdrop: 'static', keyboard: false,class: 'modal-xl' });
    modalRef.content.dataInput = this.di.model;
  }

  activateCarPart(carPartId: string) {
    this.activateCarPartAP.execute(carPartId);
  }

  deactivateCarPart(carPartId: string) {
    this.deactivateCarPartAP.execute(carPartId);
  }

  activateStockAlertCarPart(carPartId: string) {
    this.activateCarPartStockAlertAP.execute(carPartId);
  }

  deactivateStockAlertCarPart(carPartId: string) {
    this.deactivateCarPartStockAlertAP.execute(carPartId);
  }

  deleteCarPart(carPart: CarPart_Dto) {
    this.confirmationDialogService.confirm(
      "Stergere piesa",
      "Urmeaza sa stergeti piesa <b>" + carPart.code + "</b>. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        this.deleteCarPartAP.execute(carPart.id, ()=>{
          this.router.navigate(['/car-part/all']);
        });
      }
    });
  }


}
