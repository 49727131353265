import {FormGroup} from "@angular/forms";

export abstract class BaseDI<T> {
    public model: T;

    constructor(data: T) {
        this.model = data;
    }

    abstract getModel(): T;
}


export abstract class BaseFormDI<T> extends BaseDI<T> {
    public form: FormGroup;

    constructor(data: T) {
        super(data);
    }

    abstract initForm();

    public isValid(): boolean {
        return this.form.valid;
    }
}

export abstract class BaseSelectWrapperDI<T> {
    public model: T;
    public isSelected: boolean = false;

    constructor(data: T) {
        this.model = data;
    }

    abstract getModel(): T;

    public toggleSelection() {
        this.isSelected = !this.isSelected;
    }
}
