import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseFormModelComponent} from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import {Store} from '@ngrx/store';
import {ResetSection} from 'src/app/core/app-store/events/base.events';
import {OrganizationClient_Dto} from '../../../core/app-dto/organization-client.dto';
import {OrganizationClientFormDI} from './organization-client-form.di';
import {OrganizationClientFormSandbox} from './organization-client-form.sandbox';
import {isValidObject, isValidString, makeValidDiscount} from '../../../core/shared/helpers/common.helpers';
import {Address} from "../../../core/app-dto/core.dto";
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";
import {ResetInfoFromCuiEvent} from "../../../core/app-store/events/organization-client.events";
import { GetOrganizationClientFromAnafActionProxy } from './get-organization-client-from-anaf.action-proxy';
import {Organization} from "../../../core/app-dto/organization.dto";
import {BaseLocationEntity} from '../../../core/app-dto/misc.dto';

@Component({
    selector: 'app-organization-client-form',
    templateUrl: './organization-client-form.component.html',
    styleUrls: ['./organization-client-form.component.scss']
})
export class OrganizationClientFormComponent extends BaseFormModelComponent<OrganizationClient_Dto> implements OnInit, OnDestroy {
    @Output()
    formSaved = new EventEmitter<OrganizationClient_Dto>();

    @Input("carServiceEntry") carServiceEntry: boolean = false;
    @Input('forOffer') forOffer: boolean = false;

    public di: OrganizationClientFormDI = null;
    public defaultSearchValue: string;
    public getOrganizationClientFromAnafAP: GetOrganizationClientFromAnafActionProxy;
    public lastCuiSearched: string;
    public add: boolean;
    public  willClientAcceptNotifications: boolean;

    constructor(
        public appState: Store<store.State>,
        public sandbox: OrganizationClientFormSandbox,
        public rulesProvider: AppUserBusinessRuleProvider
    ) {
        super(sandbox, ResetSection.OrganizationClient);
        this.getOrganizationClientFromAnafAP = new GetOrganizationClientFromAnafActionProxy(this, appState);
    }

    public diInitialize(data: OrganizationClient_Dto) {
        this.sandbox.initialize(
            (client) => {
            },
            () => this.whenUserIsLoaded(data),
            (companyInfo) => this.di.updateFormAfterCuiData(companyInfo)
        );

        this.sandbox.appState.dispatch(new ResetInfoFromCuiEvent());
    }

    doesNotAcceptNotifications(value: boolean) {
        this.willClientAcceptNotifications = value;
    }

    private whenUserIsLoaded(data: OrganizationClient_Dto) {
        if (isValidObject(data) == false) data = new OrganizationClient_Dto(null);
        if (this.dataLoaded == false) {
            if (isValidString(data.id) == false && isValidObject(this.sandbox.currentUser.organization)) {
                data.address = new Address(null);
                if (isValidObject(this.sandbox.currentUser.organization.address)) {
                    data.address.city = this.sandbox.currentUser.organization.address.city;
                    data.address.region = this.sandbox.currentUser.organization.address.region;
                    data.address.country = this.sandbox.currentUser.organization.address.country;
                }
            }
        }
        else data.address = this.di.model.address;
        this.di = new OrganizationClientFormDI(data);
        this.defaultSearchValue = this.di.model.name;
        this.dataLoaded = true;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.destroy();
    }

    public accept() {
        this.formSubmitted = true;
        this.markFormAsDirtyAndTouched();
        if (this.di.isValid()) {
            if (this.formSaved != null) {
                this.formSaved.emit(this.di.getModel());
            }
        }
    }

    public companyInfoLookupAnaf() {
        if (this.di.getModel().isLegalEntity) {
            const inputCui: string = this.di.getModel().cuiCnp;
            if (isValidString(inputCui)) {
                this.getOrganizationClientFromAnafAP.execute(inputCui, (result: Organization) => {
                    this.whenAnafCompanyFound(result);
                });
            }
        }
    }

    public whenAnafCompanyFound(data: Organization) {
        if (isValidObject(data)) {
            this.di.resetAnafUsedFields();
            this.di.form.get('name').setValue(data.name);
            this.di.form.get('nrRegCi').setValue(data.registrationNumber);
            this.di.form.get('phoneNumber').setValue(data.representativePersonPhoneNumber);
            const address = new Address(null);
            if (isValidString(data.address.address1)) {
                address.address1 = data.address.address1;
            }
            if (isValidString(data.address.city)) {
                address.city = data.address.city;
            }
            if (isValidString(data.address.region.id)) {
                address.region = new BaseLocationEntity(data.address.region);
            }
            if (isValidString(data.address.country.id)) {
                address.country = new BaseLocationEntity(data.address.country);
            }
            this.di.model.address = address;
        }
    }

    isValid() {
        return this.di.isValid();
    }

    public clientError: boolean = false;

    organizationClientSelected(value: OrganizationClient_Dto) {
        if (value != null) {
            this.di = new OrganizationClientFormDI(value);
            this.clientError = false;
        } else {
            const client = new OrganizationClient_Dto(value);
            this.di = new OrganizationClientFormDI(client);
            this.di.form.controls['isLegalEntity'].setValue(client.isLegalEntity + '');
            this.di.changeLegalForm({target: {value: client.isLegalEntity + ''}});
            this.clientError = true;
        }
    }

    inputChanged(value: string) {
        this.di.form.get('name').setValue(value);
        this.di.form.get('name').updateValueAndValidity();
        if (value != "")
            this.clientError = false;
        else
            this.clientError = true;
    }

    makeValuePositive(event: any) {
        makeValidDiscount(event);
    }
}
