import { Component, OnDestroy, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { Supply_Dto, SupplyItem_Dto } from '../../../core/app-dto/supply.dto';
import { SupplyFormDi } from './supply-form.di';
import { SupplyFormSandbox } from './supply-form.sandbox';
import {StaticDataSelectorInput} from '../../../core/shared/components/static-data-selector/static-data-selector.component';
import { GetSuppliersActionProxy } from '../save-supply/get-suppliers.action-proxy';
import { SupplyItemFormDi } from './supply-item-form.di';
import { ConfigService } from '../../../core/core/app-config.service';
import { round } from 'lodash';
import { CarPart_Dto } from '../../../core/app-dto/car-part.dto';
import { ClientCarParts_Dto } from '../../../core/app-dto/car-service.dto';
import { CarPartSearchComponent } from '../../../car-part/components/car-part-search/car-part-search.component';
import { BsModalService } from 'ngx-bootstrap';
import { EditSupplyItemComponent } from '../edit-supply-item/edit-supply-item.component';
import { IdNameNumber_Dto } from '../../../core/app-dto/misc.dto';
import { ConfirmationDialogService } from '../../../core/core/dialog/confirmation-dialog.service';
import { Router } from '@angular/router';
import { ActiveDataTableParameters } from '../../../core/shared/datatable/datatable.helpers';
import { FindSupplyComponent } from '../find-supply/find-supply.component';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { SearchCarPartActionProxy } from './search-car-part.action-proxy';
import { SaveSupplierModalComponent } from '../save-supplier-modal/save-supplier-modal.component';
import { Supplier_Dto } from '../../../core/app-dto/supplier.dto';
import { StringHelpers } from '../../../core/shared/string.helpers';

@Component({
  selector: 'app-supply-form',
  templateUrl: './supply-form.component.html',
  styleUrls: ['./supply-form.component.scss']
})
export class SupplyFormComponent extends BaseFormModelComponent<Supply_Dto> implements OnInit, OnDestroy {
  public di: SupplyFormDi;
  public diItem: SupplyItemFormDi;
  public getSuppliersAP: GetSuppliersActionProxy;
  public searchCarPartAP: SearchCarPartActionProxy;
  public supplyItems: SupplyItem_Dto[] = [];
  public dtOptions;
  public currenciesDataInput: StaticDataSelectorInput;
  public supplyVatsDataInput: StaticDataSelectorInput;
  public formSubmittedSupplyItem = false;
  public totalWithoutVat: number;
  public totalWithVat: number;
  public totalVat: number;
  public objectName: string;
  public computedAddress: string = '';
  public computedRegComCifCnp: string = '';
  public selectedSupplier: Supplier_Dto;

  @Input('forStorno') forStorno: boolean;
  @Input('isStorno') isStorno: boolean;

  @Output()
  formSaved = new EventEmitter<Supply_Dto>();

  @ViewChild(CarPartSearchComponent, { static: false }) carPartSearchComponent: CarPartSearchComponent;

  constructor(public appState: Store<store.State>,
              public sandbox: SupplyFormSandbox,
              private configService: ConfigService,
              private modalService: BsModalService,
              public router: Router,
              public confirmationDialogService: ConfirmationDialogService) {
    super(sandbox, null);
    this.getSuppliersAP = new GetSuppliersActionProxy(this, sandbox.appState);
    this.searchCarPartAP = new SearchCarPartActionProxy(this, sandbox.appState);
  }

  public diInitialize(data: Supply_Dto) {
    this.di = new SupplyFormDi(data);
    this.diItem = new SupplyItemFormDi(this.configService,null, true);
    if (this.di.model.supplier) {
      this.selectedSupplier = this.di.model.supplier;
      this.setSelectedSupplier(this.selectedSupplier);
    }
    this.dataLoaded = true;
    this.currenciesDataInput = {
      form: this.di.form,
      type: 'currencies',
      formControlName: 'currency',
      isRequired: true,
      label: 'Moneda',
      isReadOnly:false
    };
    this.supplyVatsDataInput = {
      form: this.diItem.form,
      type: 'supplyVats',
      formControlName: 'vatValue',
      isRequired: true,
      label: 'Cota TVA',
      isReadOnly:false
    };
    this.getSuppliersAP.execute();
    const supplierName = data ? data.supplier.name : '';
    this.searchCarPartAP.execute('', supplierName, () => {});
    this.supplyItems = data ? data.supplyItems : [];
    this.processSupplyItems();
  }

  processSupplyItems(){
    if (this.forStorno || this.isStorno) {
      this.supplyItems.forEach(supplyItem => {
        supplyItem.documentedQuantity = -Math.abs(supplyItem.documentedQuantity);
        supplyItem.receivedQuantity = -Math.abs(supplyItem.receivedQuantity);
        supplyItem.acquisitionPriceWithVat = -Math.abs(supplyItem.acquisitionPriceWithVat);
        supplyItem.acquisitionPriceWithoutVat = -Math.abs(supplyItem.acquisitionPriceWithoutVat);
        supplyItem.totalVat = -Math.abs(supplyItem.totalVat);
      });
      this.calculateTotals();
    }
  }
  ngOnInit() {
    this.processSupplyItems();
    if (this.forStorno) {
      this.di.form.controls.invoiceNumber.setValue('');
      this.di.form.controls.totalValueWithVat.setValue(null);
      this.di.form.controls.totalVatValue.setValue(null);
      this.di.form.controls.invoiceDate.setValue(new Date());
      this.di.form.controls.deadline.setValue(new Date());
      this.di.form.controls.supplyDate.setValue(new Date());
    }
    this.calculateTotals();
    this.objectName = this.isStorno || this.forStorno ? 'stornare' : 'receptie';
    this.sandbox.initialize();
    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      pageLength: this.configService.getDefaultPageSize(),
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      columns: [
        { data: 'nrCrt', width: '7%', orderable: false },
        { data: 'nameCode', width: '15%' },
        { data: 'documentedQuantity', width: '15%' },
        { data: 'receivedQuantity', width: '15%' },
        { data: 'acquisitionPriceWithoutVat', width: '10%' },
        { data: 'acquisitionPriceWithVat', width: '10%' },
        { data: 'totalVat', width: '10%' },
        { data: 'vatValue', width: '10%' },
        { data: 'action', width: '50px', orderable: false },
        { data: 'createdOn', width: '0', orderable: false }
      ],
      ajax: (dataTablesParameters: ActiveDataTableParameters, callback) => {
      },
      order: [[4, 'desc']],
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
    if ((this.isStorno || this.forStorno) && !this.di.model.id) {
      this.addNewStorno();
    }
  }

  ngOnDestroy() {
    this.destroy();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();
    if (this.di.isValid()) {
      if (this.formSaved != null) {
        const model = this.di.getModel();
        model.supplyItems = this.supplyItems;
        model.isStorno = this.isStorno || this.forStorno;
        this.formSaved.emit(model);
        const suppliesRoute = '/supply/all';
        this.router.navigate([suppliesRoute], { queryParams: {isStorno: this.isStorno || this.forStorno}});
      }
    }
  }

  isValid() {
    return this.di.isValid();
  }

  addSupplierPromise(name) {
    return new Promise((resolve) => {
      resolve({name, valid: true});
    });
  }

  addNewSupplier() {
    const modalRef = this.modalService.show(SaveSupplierModalComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = {};
    modalRef.content.onClose.subscribe((data: Supplier_Dto) => {
      if (ObjectValidators.isValidObject(data) && data) {
        this.setSelectedSupplier(data);
        this.sandbox.items = [...this.sandbox.items, data];
        this.di.form.controls.supplier.setValue(data);
      }
    });
  }

  updateSupplier() {
    const modalRef = this.modalService.show(SaveSupplierModalComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = this.di.form.controls.supplier.value;
    modalRef.content.onClose.subscribe((data: Supplier_Dto) => {
      if (ObjectValidators.isValidObject(data) && data) {
        this.setSelectedSupplier(data);
        this.sandbox.items = [...this.sandbox.items, data];
        this.di.form.controls.supplier.setValue(data);
      }
    });
  }

  setSelectedSupplier(supplier) {
    this.computedRegComCifCnp = '';
    this.computedAddress = '';
    if (supplier) {
      this.computedAddress = StringHelpers.computeAddress(supplier.address);
      let delimiter = '';
      if (supplier.regCom) {
        this.computedRegComCifCnp = `${delimiter}${supplier.regCom}`;
        delimiter = ', ';
      }
      if (supplier.cifCnp) {
        this.computedRegComCifCnp += `${delimiter}${supplier.cifCnp}`;
      }
    }
    this.selectedSupplier = supplier;
    if (this.isStorno || this.forStorno) {
      this.di.form.controls.supplier.disable();
    }
  }

  addCarPartPromise(carPart) {
    return new Promise((resolve) => {
      const carPartObj = new CarPart_Dto(null);
      carPartObj.name = carPart;
      resolve({...carPartObj, valid: true});
    });
  }

  addSupplyItem() {
    this.formSubmittedSupplyItem = true;
    this.markOutsideFormAsDirtyAndTouched(this.diItem.form);
    if (this.diItem.isValid()) {
      const supplyItem = this.getVatValues(new SupplyItem_Dto(this.diItem.getModel()));
      this.supplyItems.push(supplyItem);
      this.calculateTotals();
      this.supplyVatsDataInput.form.reset();
      this.markOutsideFormAsNotDirty(this.diItem.form);
      this.formSubmittedSupplyItem = false;
      this.diItem.form.reset();
    }
  }

  calculateTotals() {
    this.totalWithVat = 0;
    this.totalWithoutVat = 0;
    this.totalVat = 0;
    this.supplyItems.forEach((supplyItem: SupplyItem_Dto) => {
      this.totalWithVat += round(supplyItem.acquisitionPriceWithVat * supplyItem.documentedQuantity, 2);
      this.totalWithoutVat += round(supplyItem.acquisitionPriceWithoutVat * supplyItem.documentedQuantity, 2);
      this.totalVat += round(supplyItem.totalVat * supplyItem.documentedQuantity, 2);
    });
    if (this.isStorno || this.forStorno) {
      this.totalWithVat = -Math.abs(round(this.totalWithVat, 2));
      this.totalWithoutVat = -Math.abs(round(this.totalWithoutVat, 2));
      this.totalVat = -Math.abs(round(this.totalVat, 2));
    } else {
      this.totalWithVat = round(this.totalWithVat, 2);
      this.totalWithoutVat = round(this.totalWithoutVat, 2);
      this.totalVat = round(this.totalVat, 2);
    }
  }

  carPartSelected(value: any) {
    const carPart = new ClientCarParts_Dto(value);
    this.diItem.form.controls.code.setValue(carPart.code);
    this.diItem.form.controls.name.setValue(carPart.name);
    this.diItem.form.controls.documentedQuantity.setValue(1);
    this.diItem.form.controls.receivedQuantity.setValue(1);
    this.diItem.form.controls.um.setValue('buc');
  }

  editSupplyItem(supplyItem: SupplyItem_Dto, i: number) {
    const modalRef = this.modalService.show(EditSupplyItemComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = supplyItem;
    modalRef.content.onClose.subscribe(result => {
      if (result) {
        this.supplyItems[i] = this.getVatValues(new SupplyItem_Dto(result));
        this.calculateTotals();
      }
    });
  }

  addNewStorno(): void {
    const modalRef = this.modalService.show(FindSupplyComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = this.di.form.controls.supplier.value;
    modalRef.content.onClose.subscribe((result: Supply_Dto[]) => {
      if (result) {
        if (ObjectValidators.isValidObject(result)) {
          let showWarningMessage = false;
          result.forEach((supply: Supply_Dto) => {
            if (this.di.form.controls.supplier) {
              this.di.form.controls.supplier.setValue(supply.supplier);
              this.setSelectedSupplier(supply.supplier);
              if (this.isStorno || this.forStorno) {
                this.updateCarPartList(supply.supplier);
              }
            }
            supply.supplyItems.forEach((item: SupplyItem_Dto) => {
              const findExistingSupplyItem = this.supplyItems.find((s: SupplyItem_Dto) => s.id === item.id);
              if (!findExistingSupplyItem) {
                item.documentedQuantity *= -1;
                item.receivedQuantity *= -1;
                item.acquisitionPriceWithVat = -Math.abs(item.acquisitionPriceWithVat);
                item.acquisitionPriceWithoutVat = -Math.abs(item.acquisitionPriceWithoutVat);
                item.totalVat = -Math.abs(item.totalVat);
                item.observations = `Retur de pe Factura ${supply.invoiceNumber} din ${supply.invoiceDate}`;
                this.supplyItems.push(item);
              } else {
                showWarningMessage = true;
              }
            });
          });
          if (showWarningMessage) {
            this.confirmationDialogService.info(
                'Atentie',
                'O parte din produsele filtrate nu au putut fi adaugate in stornare pentru ca exista deja pe document!',
                'Continua', 'sm', true).subscribe((confirmed) => {
            });
          }
          this.calculateTotals();
        }
      }
    });
  }

  getVatValues(supplyItem: SupplyItem_Dto) {
    const vat: IdNameNumber_Dto = supplyItem.vatValue;
    if (supplyItem.acquisitionPriceHasVat) {
      supplyItem.acquisitionPriceWithoutVat = round(supplyItem.acquisitionPriceWithVat * 100 / (100 + vat.id), 2);
    } else {
      supplyItem.acquisitionPriceWithVat = round(supplyItem.acquisitionPriceWithoutVat +
          (supplyItem.acquisitionPriceWithoutVat * vat.id / 100), 2);
    }
    supplyItem.totalVat = Math.abs(round(supplyItem.acquisitionPriceWithVat - supplyItem.acquisitionPriceWithoutVat, 2));
    if (this.isStorno || this.forStorno) {
      supplyItem.documentedQuantity = -Math.abs(supplyItem.documentedQuantity);
      supplyItem.receivedQuantity = -Math.abs(supplyItem.receivedQuantity);
      supplyItem.acquisitionPriceWithVat = -Math.abs(supplyItem.acquisitionPriceWithVat);
      supplyItem.acquisitionPriceWithoutVat = -Math.abs(supplyItem.acquisitionPriceWithoutVat);
      supplyItem.totalVat = -Math.abs(supplyItem.totalVat);
    }

    return supplyItem;
  }

  deleteSupplyItem(deletedSupplyItem: SupplyItem_Dto) {
    this.confirmationDialogService.confirm(
        'Stergere piesa din ' + this.objectName,
        "Urmeaza sa stergeti piesa <b>" + deletedSupplyItem.name + "</b>. Sunteti sigur?",
        'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        this.supplyItems = this.supplyItems.filter((supplyItem: SupplyItem_Dto) => {
          return supplyItem.id !== deletedSupplyItem.id;
        });
        this.calculateTotals();
      }
    });
  }

  updateCarPartList($event) {
    this.setSelectedSupplier($event);
    const supplierName = $event ? $event.name : '';
    this.searchCarPartAP.execute('', supplierName, () => {});
  }
}
