import {Action} from '@ngrx/store';
import {
  Do_GenericSuccessEvent,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  SeamlessEvent
} from '../events/base.events';
import {FileAttachment, FileUploadTarget} from '../../app-dto/core.dto';
import {
  DeleteDocumentEvent,
  DocumentUploadedEvent,
  GenerateDevizEvent,
  GenerateGDPREvent,
  GenerateICEvent,
  GeneratePVEvent,
  GenerateSHEvent,
  GetDocumentsByEntityAndTypeEvent,
  GetDocumentsByEntityAndTypeAnnonymusEvent
} from '../events/documents-events';
import {ObjectValidators} from '../../shared/object.validators';
import {isValidObject} from "../../shared/helpers/common.helpers";

export class FileDictionary {
  public documents: Array<FileAttachment>;
  public id: string;
  public type: Array<FileUploadTarget>
}

export interface DocumentsState {
  lastRefreshTime: number;
  documentsList: Array<FileDictionary>;
}

export const INITIAL_STATE: DocumentsState = Object.assign({}, {
  lastRefreshTime: 0,
  documentsList: [],
});


export function reducer(state = INITIAL_STATE, action: Action): DocumentsState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {

    // Get Documents events
    case Do_GenericSuccessEvent.identifier + GetDocumentsByEntityAndTypeEvent.identifier:
    case Do_GenericSuccessEvent.identifier + GetDocumentsByEntityAndTypeAnnonymusEvent.identifier: {
      const documentsList = (action as Do_GenericSuccessEvent<FileDictionary>).data;
      const stringifiedTypes = JSON.stringify(documentsList.type);
      const foundItem = state.documentsList.find(f => f.id == documentsList.id && JSON.stringify(f.type) == stringifiedTypes);
      if (isValidObject(foundItem)) foundItem.documents = documentsList.documents;
      else {
        state.documentsList.push(documentsList);
      }
      state.documentsList = state.documentsList.map(r=>r);
      return Object.assign({}, state, );
    }

    // Delete Documents events
    case Do_GenericSuccessEvent.identifier + DeleteDocumentEvent.identifier: {
      const documentsList = (action as Do_GenericSuccessEvent<FileDictionary>).data;
      const stringifiedTypes = JSON.stringify(documentsList.type);
      const foundItem = state.documentsList.find(f => f.id == documentsList.id && JSON.stringify(f.type) == stringifiedTypes);
      if (isValidObject(foundItem)) {
        const documentId = documentsList.documents[0].id;
        if (ObjectValidators.isValidArray(foundItem.documents)) {
          let i = foundItem.documents.findIndex(r => r.id == documentId);
          foundItem.documents.splice(i, 1);
          foundItem.documents = foundItem.documents.map(r => r);
        }
      }

      state.documentsList = state.documentsList.map(r=>r);
      return Object.assign({}, state, );
    }
    // Delete Documents events

    case DocumentUploadedEvent.identifier: {
      const document = (action as DocumentUploadedEvent).data;
      const foundItems = state.documentsList.filter(f => f.id == document.relatedEntityId && f.type.indexOf(document.type)>-1);
      if (ObjectValidators.isValidArray(foundItems)) {
        foundItems.forEach(f=>{
          f.documents.push(document);
        });
      }
      state.documentsList = state.documentsList.map(r=>r);
      return Object.assign({}, state, );
    }

    case Do_GenericSuccessEvent.identifier + GenerateDevizEvent.identifier:
    case Do_GenericSuccessEvent.identifier + GenerateDevizEvent.identifier:
    case Do_GenericSuccessEvent.identifier + GenerateGDPREvent.identifier:
    case Do_GenericSuccessEvent.identifier + GenerateSHEvent.identifier:
    case Do_GenericSuccessEvent.identifier + GeneratePVEvent.identifier:
    case Do_GenericSuccessEvent.identifier + GenerateICEvent.identifier: {
      const document = (action as Do_GenericSuccessEvent<FileAttachment>).data;
      const foundItems = state.documentsList.filter(f => f.id == document.relatedEntityId && f.type.indexOf(document.type)>-1);
      if (ObjectValidators.isValidArray(foundItems)) {
        foundItems.forEach(f=>{
          f.documents.push(document);
        });
      }
      state.documentsList = state.documentsList.map(r=>r);
      return Object.assign({}, state, );
    }

    default: {
      return state;
    }
  }
}

export const getTimestampCallback = (state: DocumentsState) => state.lastRefreshTime;
export const getDocumentsListCallback = (state: DocumentsState) => state.documentsList;
