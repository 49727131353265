import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {BaseWebComponent} from 'src/app/core/shared/base/base.component';
import {FileAttachment, FileToUpload, FileUploadTarget} from 'src/app/core/app-dto/core.dto';
import {FileWrapper} from 'src/app/core/upload/components/upload-notification/upload-notification.di';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {UploadInput} from 'src/app/core/upload/components/upload-notification/upload-notification.component';
import {DeletePhotosActionProxy} from './delete-photo.action-proxy';
import {GetPhotosActionProxy} from './get-photos.action-proxy';
import {FileDI} from '../di/file.di';
import {PhotosSandbox} from './photos.sandbox';
import {isValidImage} from 'src/app/core/shared/helpers/common.helpers';
import {NotifierService} from 'angular-notifier';
import {GetPhotosAnnonymusActionProxy} from './get-photos-annonymus.action-proxy';
import {ConfirmationDialogService} from "../../../core/core/dialog/confirmation-dialog.service";
import {ImageHelpers} from "../../../core/shared/image.helpers";

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss'],
  providers: [PhotosSandbox]
})
export class PhotosComponent extends BaseWebComponent implements OnInit, OnDestroy {
  public getFilesAP: GetPhotosActionProxy;
  public getFilesAnnonymusAP: GetPhotosAnnonymusActionProxy;
  public deleteFilesAP: DeletePhotosActionProxy;

  @Input() viewOnly: boolean = false;
  @Input() annonymus: boolean = false;
  @Input() types: Array<FileUploadTarget> = null;
  @Input() type: FileUploadTarget;
  @Input() relatedEntityId: string = '';

  public files: Array<FileDI> = [];

  constructor(
    public sandbox: PhotosSandbox,
    public confirmationDialogService: ConfirmationDialogService,
    private notifierService: NotifierService,
    private imageHelpers: ImageHelpers
  ) {
    super(sandbox);
    this.getFilesAP = new GetPhotosActionProxy(this, sandbox.appState);
    this.getFilesAnnonymusAP = new GetPhotosAnnonymusActionProxy(this, sandbox.appState);
    this.deleteFilesAP = new DeletePhotosActionProxy(this, sandbox.appState);
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.annonymus)
        this.getFilesAnnonymusAP.execute(this.types, this.relatedEntityId);
      else
        this.getFilesAP.execute(this.types, this.relatedEntityId);
    });

    this.initialize(this.relatedEntityId, this.types, (data: Array<FileAttachment>) => {
      this.files = data.map(r => new FileDI(r));
      this.dataLoaded = true;
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  deleteFile(id: string) {
    this.confirmationDialogService.confirm(
      "Stergere fisier",
      "Urmeaza sa stergeti fisierul. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        this.deleteFilesAP.execute(id, this.types, this.relatedEntityId);
      }
    });

  }

  onFileUpload(data: FileList) {
    console.log("onImageChange: " + NgZone.name);

    let validFiles = true;
    let arrayData = Array.from(data);
    arrayData.forEach(r => {
      if (isValidImage(r) == false) {
        //@Todo: add notification error
        validFiles = false;
      }
    });
    if (validFiles) {

      if (this.imageHelpers.isNoOfImagesValid(this.files.length + arrayData.length) == false) return;
      let files = arrayData.map(r => {
        let result = new FileWrapper();
        result.file = r;
        return result;
      });
      var timestamp = new Date().getTime();
      var filesToUpload = new Array<FileToUpload>();
      for (var i = 0; i < files.length; i++) {
        if (ObjectValidators.isValidString(files[i].id) == false) {
          timestamp = timestamp + 1;
          filesToUpload.push(new FileToUpload(timestamp.toString(), files[i].file, this.type, this.relatedEntityId));
        }
      }
      if (filesToUpload.length > 0) {
        this.sandbox.dispatchUploadInputEvent(new UploadInput(filesToUpload, this.type))
      }
    } else {
      this.notifierService.notify('error', 'Tipul fisierului nu este acceptat!');
    }
  }
}
