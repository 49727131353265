import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import {
  InvoiceDataTableParameters,
  PagedResult
} from 'src/app/core/shared/datatable/datatable.helpers';
import { Invoice_Dto } from 'src/app/core/app-dto/invoice.dto';
import { InvoiceListItemDI } from './invoice-list-item.di';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {Router} from '@angular/router';

@Injectable()
export class InvoiceListSandbox extends BaseSandbox {
    public invoices$ = this.appState$.pipe(select(store.getInvoicesListing));
    public timestamp$ = this.appState$.pipe(select(store.getInvoiceTimestamp));
    public getReceiptId$ = this.appState$.pipe(select(store.getReceiptId));

    public totalNumberOfResults: number = null;
    public callback: any;
    public resetTableCallback: Function;
    public lastRefreshTime: number = 0;
    public dataTableParameters: InvoiceDataTableParameters;

    public totalPages: number = 0;
    public items: Array<InvoiceListItemDI> = [];
    private draw: number = 1;

    constructor(
        protected appState$: Store<store.State>,
        private router: Router
    ) {
        super(appState$);
    }

    public clearSandboxState() {
        this.items = [];
        this.draw = 1;
        this.lastRefreshTime = 0;
    }

    public initialize(itemsLoaded: (result: Array<InvoiceListItemDI>) => void): any {
        this.clearSandboxState();
        this.subscriptions.push(this.invoices$.subscribe((data: PagedResult<Invoice_Dto>) => {
            if (data != null && data.items != null) {
                this.items = data.items.map(r => new InvoiceListItemDI(r));
                this.totalNumberOfResults = data.totalItems;
                this.totalPages = data.totalPages;
                if (this.callback != null) {
                    this.callback({
                        draw: this.draw++,
                        recordsTotal: data.totalItems,
                        recordsFiltered: data.totalItems,
                        data: []
                    });

                }
            } else {
                this.items = [];
                this.draw = 1;
            }
            if (itemsLoaded != null) itemsLoaded(this.items);
        }));

        this.subscriptions.push(this.timestamp$.subscribe((result: number) => {
            if (result > this.lastRefreshTime) {
                this.lastRefreshTime = result;
                this.resetTable();
            }
        }));

        this.subscriptions.push(this.getReceiptId$.subscribe((data: string) => {
            if (ObjectValidators.isValidString(data)) {
                this.router.navigate(['/receipt', data, 'details']);
            }
        }));
    }

    public setResetTableCallback(callback: Function) {
        this.resetTableCallback = callback;
    }

    public resetTable() {
        if (this.resetTableCallback != null) this.resetTableCallback();
    }
}
