import {BaseActionProxy} from "../../../core/shared/base/base.action-proxy";
import {IWebComponent} from "../../../core/shared/base/base.component";
import {Store} from "@ngrx/store";
import * as store from "../../../core/app-store";
import {Draft_Dto, DraftType} from "../../../core/app-dto/draft.dto";
import {isValidObject} from "../../../core/shared/helpers/common.helpers";
import {StringHelpers} from "../../../core/shared/string.helpers";
import {RegisterDraftEvent} from "../../../core/app-store/events/draft.events";
import {Observable} from "rxjs";
import {Offer_Dto} from "../../../core/app-dto/offer.dto";

export class SaveDraftOfferActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(model: Offer_Dto, step: number): any {
        if (this.canExecute()) {
            const draftModel = new Draft_Dto(null);
            draftModel.currentPage = step.toString();

            draftModel.title = StringHelpers.tryAppendStringSeparatedByComma(draftModel.title, model.title);
            draftModel.title = StringHelpers.tryAppendStringSeparatedByComma(draftModel.title, model.type.name);

            if (isValidObject(model.client)) {
                draftModel.description = StringHelpers.tryAppendStringSeparatedByComma(draftModel.description, model.client.name);
                draftModel.description = StringHelpers.tryAppendStringSeparatedByComma(draftModel.description, model.client.phoneNumber);
                draftModel.description = StringHelpers.tryAppendStringSeparatedByComma(draftModel.description, model.client.cuiCnp);
            }

            if (isValidObject(model.car)) {
                draftModel.title = StringHelpers.tryAppendStringSeparatedByComma(draftModel.title, model.car.registrationNumber);
                draftModel.title = StringHelpers.tryAppendStringSeparatedByComma(draftModel.title, model.car.make);
                draftModel.title = StringHelpers.tryAppendStringSeparatedByComma(draftModel.title, model.car.model);
            }

            draftModel.data = JSON.stringify(model);
            draftModel.type = DraftType.OfferEntry;
            this.appState.dispatch(new RegisterDraftEvent(draftModel));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}
