import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import {BaseSandbox, BaseSandboxWithCurrentUserLoaded} from 'src/app/core/shared/base/base.sandbox';
import {Supplier_Dto} from '../../../core/app-dto/supplier.dto';
import { Organization } from '../../../core/app-dto/organization.dto';
import { ObjectValidators } from '../../../core/shared/object.validators';

@Injectable()
export class SaveCarPartSandbox extends BaseSandboxWithCurrentUserLoaded {
    public getSuppliers$ = this.appState$.pipe(select(store.getSuppliersListing));
    public organization: Organization;
    public items: Supplier_Dto[];

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
    }

    public initialize(): any {
        this.clearSandboxState();
        this.loadUser(()=>{
          this.organization = this.currentUser.organization;
        });
        this.subscriptions.push(this.getSuppliers$.subscribe((data: Supplier_Dto[]) => {
            if (data != null) {
                this.items = data;
            } else {
                this.items = [];
            }
        }));
    }
}
