import {Injectable} from "@angular/core";
import {BaseApiService} from "./base-api.service";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";

import {ApiErrorDto} from "../utils/api-error.dto";
import {ConfigService} from "../../core/app-config.service";
import {StaticData} from "../../app-dto/static-data.dto";
import {catchError, map} from "rxjs/operators";
import {Version_Dto} from '../../app-dto/version.dto';

class StaticDataApiRoutes {
  public static route_for_GET_STATIC_DATA() {

    return '/api/public/static';
  }
  public static route_for_GET_VERSION() {
    return '/api/public/version';
  }
}

@Injectable()
export class StaticDataApiService extends BaseApiService {

  constructor(private http: HttpClient, configService: ConfigService) {
    super(configService);
  }

  u_getStaticData(): Observable<StaticData> {
    const url = this.configService.getAPIBaseUrl() + StaticDataApiRoutes.route_for_GET_STATIC_DATA();
    return this.http.get(url).pipe(
      map((res: any) => {
        return new StaticData(res);
      }),
      catchError((err: ApiErrorDto, o: Observable<StaticData>) => {
        return of<StaticData>(null);
      })
    );
  }

  u_getVersion(): Observable<Version_Dto> {
    const url = this.configService.getAPIBaseUrl() + StaticDataApiRoutes.route_for_GET_VERSION();
    return this.http.get(url).pipe(
        map((res: any) => {
          return new Version_Dto(res);
        }),
        catchError((err: ApiErrorDto, o: Observable<Version_Dto>) => {
          return of<Version_Dto>(null);
        })
    );
  }

}
