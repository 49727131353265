import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebFormComponent } from 'src/app/core/shared/base/base.component';
import { BaseFormActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { UpdateInvoiceDeadlineEvent } from 'src/app/core/app-store/events/invoices.events';
import {InvoiceDetails_Dto} from '../../../core/app-dto/invoice.dto';


export class InvoiceUpdateDeadlineActionProxy extends BaseFormActionProxy {
    constructor(component: IWebFormComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        this.component.markFormAsDirtyAndTouched();
        return this.component.di.isValid();
    }

    execute(callback: (data: InvoiceDetails_Dto) => void): any {
        if (this.canExecute()) {
            this.component.markFormAsNotDirty();
            const model = this.component.di.getModel();
            this.appState.dispatch(new UpdateInvoiceDeadlineEvent(model, callback));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

