import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {SearchCarWorksActionProxy} from './search-car-works.action-proxy';
import {CarWorksSearchSandbox} from './car-works-search.sandbox';
import {BaseWebComponent} from 'src/app/core/shared/base/base.component';
import {IdName_Dto} from 'src/app/core/app-dto/misc.dto';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {SearchComponent} from 'src/app/core/shared/components/search/search.component';
import {
  isValidArrayAndHasElements,
  isValidNumber,
  isValidObject,
  isValidString
} from '../../../core/shared/helpers/common.helpers';
import * as escapeStringRegexp from 'escape-string-regexp';
import { CarWork_Dto } from '../../../core/app-dto/car-work.dto';

@Component({
  selector: 'app-car-works-search',
  templateUrl: './car-works-search.component.html',
  styleUrls: ['./car-works-search.component.scss']
})
export class CarWorksSearchComponent extends BaseWebComponent implements OnInit, OnDestroy {
  public searchCarWorkAP: SearchCarWorksActionProxy;

  public items: Array<IdName_Dto> = [];
  public isLoading: boolean = false;
  public selectedItem: CarWork_Dto;
  public selectedItemValue: IdName_Dto = null;
  public originalCarWorkSearchList: Array<CarWork_Dto> = [];

  private makeValue:string;
  private modelValue:string;
  private yearValue:number;
  private searchInputValue:string;

  @Output() itemSelected = new EventEmitter<CarWork_Dto>();
  @Output() inputValue = new EventEmitter<string>();

  @ViewChild(SearchComponent, {static: false}) searchComponent: SearchComponent;

  @Input() error: boolean = false;
  @Input() allowInput: boolean = false;

  @Input('search') set searchValue(value: string) {
    if (ObjectValidators.isValidString(value)) {
      this.searchInputValue = value;
      this.search(value);
      this.selectedItemValue = new IdName_Dto({id: undefined, name: value});
    }
  }

  private initialCarWork:CarWork_Dto =null;
  @Input('initialValue') set initialValue(value:CarWork_Dto) {
    if (ObjectValidators.isValidObject(value)) {
      const initialValueDto  = new IdName_Dto({id: value.id, name: value.description});
      //this.items.push(initialValueDto);
      this.selectedItemValue = initialValueDto;
      this.initialCarWork = value;
    }
  }
  


  @Input('model') set model(value: string) {
    if (ObjectValidators.isValidString(value)) {
     this.modelValue = value;
      if(this.initialCarWork != null && isValidString(this.searchInputValue)==false) return;
     this.search(this.searchInputValue);
    }
  }
  @Input('make') set make(value: string) {
    if (ObjectValidators.isValidString(value)) {
      this.makeValue = value;
      if(this.initialCarWork != null && isValidString(this.searchInputValue)==false) return;
      this.search(this.searchInputValue);
    }
  }
  @Input('year') set year(value: number) {
    if (value) {
      this.yearValue = value;
      if(this.initialCarWork != null && isValidString(this.searchInputValue)==false) return;
      this.search(this.searchInputValue);
    }
  }
  constructor(public sandbox: CarWorksSearchSandbox) {
    super(sandbox);
    this.searchCarWorkAP = new SearchCarWorksActionProxy(this, sandbox.appState);
  }

  ngOnInit() {
    this.items = [];
    if (!isValidObject(this.selectedItemValue))
      this.search('');
    this.initialize((data: Array<IdName_Dto>) => {
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  private shouldRedoSearch : boolean = false;
  public search(value: string) {
    if(this.isLoading){
      this.shouldRedoSearch = true;
      return;
    }
    this.isLoading = true;
    if (isValidString(value)) {
      value = escapeStringRegexp(value.trim());
    }
    this.searchCarWorkAP.execute(value,this.modelValue,this.makeValue, this.yearValue, (items: Array<CarWork_Dto>) => {
      this.originalCarWorkSearchList = items;
      const idNameMapper = items.map(r => {
        let description = r.description;
        let extraDescriptions:Array<string> = []
        if(isValidString(r.make)) extraDescriptions.push(r.make);
        if(isValidString(r.model)) extraDescriptions.push(r.model);
        if(isValidNumber(r.year)) extraDescriptions.push(r.year.toString());
        if(isValidObject(r.fuel)) extraDescriptions.push(r.fuel.map(d=>d.name).join(','));
        if(isValidNumber(r.cilindricalCapacity)) extraDescriptions.push(r.cilindricalCapacity.toString() + "kw");
        if(isValidNumber(r.duration)) extraDescriptions.push(r.duration.toString() + "h");
        if(extraDescriptions.length>0) description = r.description + "(" + extraDescriptions.join(',') + ")"
        return new IdName_Dto({
          id: r.id,
          name: description
        });
      })
      this.setData(idNameMapper);
      this.isLoading = false;
      if(this.shouldRedoSearch){
        this.shouldRedoSearch=false;
        this.search(this.searchInputValue);
      }
    });
  }

  public setData(data: Array<IdName_Dto>) {
    this.items = data;
    if (isValidObject(this.selectedItemValue) && this.selectedItemValue.id == "input") {
      if (isValidArrayAndHasElements(data)) {
        this.selectedItemValue = data[0];
        const item = this.originalCarWorkSearchList.find(r => r.id == this.selectedItemValue.id);
        if (this.itemSelected != null) {
          this.selectedItem = item;
          this.itemSelected.emit(item);
        } else {
          this.selectedItem = null;
          this.itemSelected.emit(null);
        }
      }
    }
    this.dataLoaded = true;
  }

  public selected(value: IdName_Dto) {
    if (ObjectValidators.isValidObject(value)) {
      const data = this.originalCarWorkSearchList.find(r => r.id === value.id);
      if (this.itemSelected != null) {
        this.selectedItem = data || new CarWork_Dto({id: value.id, description: value.name});
        this.itemSelected.emit(this.selectedItem);
      }
    } else {
      if (this.itemSelected != null) {
        this.selectedItemValue = null;
        this.selectedItem = null;
        this.itemSelected.emit(null);
        this.search('');
      }
    }
  }

  public clearSelect() {
    this.searchComponent.clearSelect();
  }

  inputChanged(value: string) {
    if (this.inputValue != null) {
      this.inputValue.emit(value);
    }
  }
}
