import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { CarServiceListDashboardItemDi } from './car-service-list-dashboard-item.di';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';

@Injectable()
export class CarServiceListDashboardSandbox extends BaseSandbox {
    public carServiceEntries$ = this.appState$.pipe(select(store.getCarServiceEntriesListing));

    public items: Array<CarServiceListDashboardItemDi> = [];

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
        this.items = [];
    }

    public initialize(): void {
        this.clearSandboxState();
        this.subscriptions.push(this.carServiceEntries$.subscribe((data: Array<CarService_Dto>) => {
            if (data != null) {
                this.items = data.map(r => new CarServiceListDashboardItemDi(r));
            } else {
                this.items = [];
            }
        }));

    }
}
