import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CarService_Dto } from 'src/app/core/app-dto/car-service.dto';

export class CarServiceSearchDI extends BaseFormDI<CarService_Dto> {

    constructor(data: CarService_Dto = new CarService_Dto(null)) {
        super(new CarService_Dto(data));
        this.initForm();
    }

    getModel(): CarService_Dto {
        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
        });
    }
}
