import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Do_GenericErrorEvent, Do_GenericSuccessEvent, SeamlessEvent} from '../../../core/app-store/events/base.events';
import {map, switchMap} from 'rxjs/operators';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {SuperAdminLogsApiService} from '../../../core/api-integration/services/super-admin-logs.api.service';
import {SearchOrganizationEvent} from '../../../core/app-store/events/super-admin-logs.events';
import {Organization_Dto} from '../../../core/app-dto/super-admin-logs.dto';



@Injectable()
export class OrganizationLogsSearchUIEventProcessor {

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private superAdminLogsApiService: SuperAdminLogsApiService,
    ) {

    }



@Effect()
    doSearchOrganizationLogs$: Observable < any > = this.actions$
        .pipe(
            ofType(SearchOrganizationEvent.identifier, SeamlessEvent.identifier + SearchOrganizationEvent.identifier),
            map((action: SearchOrganizationEvent) => action),
            switchMap((action: SearchOrganizationEvent) => {
                return new Observable((observer) => {
                    this.superAdminLogsApiService.u_searchOrganizationLogs(action.search, action.displayOnlyAvailableItems)
                        .subscribe((res: Array<Organization_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Array<Organization_Dto>>(res, action));
                            }
                            action.callBack();
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            action.callBack();
                            observer.complete();
                        });
                });
            })
        );
}
