import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { Mechanic_Dto } from '../../../core/app-dto/mechanic.dto';
import { DepartmentDropdown_Dto, Department_Dto } from '../../../core/app-dto/department.dto';
import {isValidArrayAndHasElements} from "../../../core/shared/helpers/common.helpers";

@Injectable()
export class SaveMechanicSandbox extends BaseSandbox {
    public getDepartments$ = this.appState$.pipe(select(store.getAllDepartmentsListing));
    public departments: DepartmentDropdown_Dto[];
    public hasDepartments: boolean = false;

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
        this.hasDepartments = false;
    }

    public initialize(mechanic: (data: Mechanic_Dto) => {}): any {
        this.clearSandboxState();
        this.subscriptions.push(this.getDepartments$.subscribe((data: Department_Dto[]) => {
            if (data!= null) {
              if(isValidArrayAndHasElements(data)) {
                this.hasDepartments = true;
                this.departments = data.map(r => {
                  return new DepartmentDropdown_Dto(r)
                });
              }
              else{
                this.hasDepartments =false;
              }
            } else {
                this.departments = [];
            }
        }));
    }
}
