import {EventWithScreenLoading, UIGeneratedEvent} from './base.events';
import {ActiveDataTableParameters, DataTableParameters} from '../../shared/datatable/datatable.helpers';
import {CarPart_Dto} from '../../app-dto/car-part.dto';
import {Offer_Dto, OfferAcceptedTrace_Dto, OfferDeclinedTrace_Dto, SendOfferPayload_Dto} from '../../app-dto/offer.dto';
import {isValidString} from '../../shared/helpers/common.helpers';

export class OfferDataTableParameters extends DataTableParameters {
    public status: string;

    constructor(data?: any) {
        super(data);
        if (data != null && data !== undefined) {
            this.status = data.status;
        } else {
            this.status = '';
        }
    }

    toUrlParameters(): string {
        let result = super.toUrlParameters();
        if (!isValidString(this.status)) {
            this.status = '';
        }
        result = result + '&status=' + this.status;
        return result;
    }
}

export class GetOffersPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'GetOffersPagedResultEvent';

    constructor(public parameters: OfferDataTableParameters, public withReset: boolean = false) {
        super();
        this.type += GetOffersPagedResultEvent.identifier;
    }
}

export class RegisterOfferEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'RegisterOfferEvent';

    constructor(public model: Offer_Dto, public callback: Function) {
        super();
        this.type += RegisterOfferEvent.identifier;
    }
}

export class GetOfferEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'GetOfferEvent';

    constructor(public id: string) {
        super();
        this.type += GetOfferEvent.identifier;
    }
}

export class GetUnauthOfferEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'GetUnauthOfferEvent';

    constructor(public id: string, public code: string) {
        super();
        this.type += GetUnauthOfferEvent.identifier;
    }
}

export class DeleteOfferEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'DeleteOfferEvent';

    constructor(public id: string, public deleteSuccessCallback: () => void) {
        super();
        this.type += DeleteOfferEvent.identifier;
    }
}

export class DeleteOfferServiceCarPartEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'DeleteOfferServiceCarPartEvent';

    constructor(public id: string, public data: CarPart_Dto) {
        super();
        this.type += DeleteOfferServiceCarPartEvent.identifier;
    }
}

export class UpdateOfferServiceCarPartEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'UpdateOfferServiceCarPartEvent';

    constructor(public id: string, public data: CarPart_Dto) {
        super();
        this.type += UpdateOfferServiceCarPartEvent.identifier;
    }
}

export class AddOfferServiceCarPartEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'AddOfferServiceCarPartEvent';

    constructor(public id: string, public data: CarPart_Dto) {
        super();
        this.type += AddOfferServiceCarPartEvent.identifier;
    }
}

export class UpdateOfferClientDataEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'UpdateOfferClientDataEvent';

    constructor(public id: string, public data: Offer_Dto) {
        super();
        this.type += UpdateOfferClientDataEvent.identifier;
    }
}
export class UpdateOfferCarDataEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'UpdateOfferCarDataEvent';

    constructor(public id: string, public data: Offer_Dto) {
        super();
        this.type += UpdateOfferCarDataEvent.identifier;
    }
}
export class UpdateOfferClientCarPartsEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'UpdateOfferClientCarPartsEvent';

    constructor(public id: string, public data: Offer_Dto) {
        super();
        this.type += UpdateOfferClientCarPartsEvent.identifier;
    }
}
export class UpdateOfferFinalizeEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'UpdateOfferFinalizeEvent';

    constructor(public id: string, public data: Offer_Dto) {
        super();
        this.type += UpdateOfferFinalizeEvent.identifier;
    }
}
export class UpdateOfferCarWorksEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'UpdateOfferCarWorksEvent';

    constructor(public id: string, public data: Offer_Dto) {
        super();
        this.type += UpdateOfferCarWorksEvent.identifier;
    }
}
export class UpdateOfferDataEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'UpdateOfferDataEvent';

    constructor(public id: string, public data: Offer_Dto) {
        super();
        this.type += UpdateOfferDataEvent.identifier;
    }
}
export class SendOfferEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'SendOfferEvent';

    constructor(public id: string, public data: SendOfferPayload_Dto) {
        super();
        this.type += SendOfferEvent.identifier;
    }
}
export class AcceptOfferEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'AcceptOfferEvent';

    constructor(public id: string, public code:string, public data: OfferAcceptedTrace_Dto) {
        super();
        this.type += AcceptOfferEvent.identifier;
    }
}
export class DeclineOfferEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier = 'DeclineOfferEvent';

    constructor(public id: string,public code:string, public data: OfferDeclinedTrace_Dto) {
        super();
        this.type += DeclineOfferEvent.identifier;
    }
}

export class GenerateOffer extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "GenerateOffer";

    constructor(public offerEntry: string) {
        super();
        this.type += GenerateOffer.identifier;
    }
}
