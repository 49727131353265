import {Component, Input} from "@angular/core";
import {ObjectValidators} from "../../object.validators";
import {Invoice_Dto, InvoiceMaxPermittedAmount_Dto} from '../../../app-dto/invoice.dto';
import {FiscalPrintInvoiceComponent} from "../fiscal-print-invoice/fiscal-print-invoice.component";
import {BsModalService} from "ngx-bootstrap";
import {CashRegisterApiService} from "../../../api-integration/services/cash-register-api.service";
import {NotifierService} from "angular-notifier";
import {InvoiceApiService} from '../../../api-integration/services/invoice-api.service';

@Component({
    selector: 'app-fiscal-print-button',
    templateUrl: './fiscal-print-button.component.html',
    styleUrls: ['./fiscal-print-button.component.scss']
})
export class FiscalPrintButtonComponent {
    public invoice: Invoice_Dto;

    @Input() anchorClass;

    @Input('invoice') set invoiceValue(value: Invoice_Dto) {
        if (ObjectValidators.isValidString(value)) {
            this.invoice = value;
        }
    }

    constructor(private modalService: BsModalService,
                private apiService: CashRegisterApiService,
                private invoiceApiService: InvoiceApiService,
                private notifierService: NotifierService) {

    }

    public printInvoice() {
        const noCashRegisterMessage = 'Pentru aceasta operatie aveti nevoie de cel putin o casa de marcat inregistrata si activa!';
        this.apiService.cr_getCountOfAllActiveCashRegisters()
            .subscribe((res: number) => {
                if (ObjectValidators.isValidPositiveInteger(res) && res > 0) {
                    this.invoiceApiService.u_getMaxPermittedAmount(this.invoice.id)
                        .subscribe((result: InvoiceMaxPermittedAmount_Dto) => {
                            if (result.canPrintReceipt) {
                                const modalRef = this.modalService.show(FiscalPrintInvoiceComponent, {
                                    backdrop: 'static',
                                    keyboard: false,
                                    class: 'modal-xl'
                                });
                                this.invoice.totalValue = result.maxPermittedAmount;
                                (<FiscalPrintInvoiceComponent> modalRef.content).setData(this.invoice);
                            } else {
                                this.notifierService.notify('error', result.message);
                            }
                        });
                }
                else this.notifierService.notify('warning', noCashRegisterMessage);
            }, (error: string) => {
                console.error(error);
                this.notifierService.notify('warning', noCashRegisterMessage);
            });
    }
}
