import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { InvoiceDetails_Dto } from 'src/app/core/app-dto/invoice.dto';
import { DateTimeService } from 'src/app/core/core/services/date-time.service';
import { validEndDate } from 'src/app/core/shared/form/custom-form.validator';
import * as moment from 'moment';

export class InvoiceUpdateDeadlineDI extends BaseFormDI<InvoiceDetails_Dto> {

    constructor(public dateTimeService: DateTimeService, data: InvoiceDetails_Dto = new InvoiceDetails_Dto(null)) {
        super(new InvoiceDetails_Dto(data));
        this.initForm();
    }

    getModel(): InvoiceDetails_Dto {
        this.model.invoice.deadline = this.dateTimeService.toUTC(this.form.value.deadline);
        this.model.invoice.formattedDeadline = moment(this.form.value.deadline).format('DD/MM/YYYY');
        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
            deadline: new FormControl(this.model.invoice.deadline, Validators.compose([Validators.required, validEndDate(this.model.invoice.sentOn)]))
        });
    }
}
