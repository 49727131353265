import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InvoiceRoutingModule } from './invoice-routing.module';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { InvoiceListComponent } from './components/invoice-list/invoice-list.component';
import { InvoiceListOverviewOrganizationComponent } from './components/invoice-list-overview-organization/invoice-list-overview-organization.component';
import { InvoiceListOverviewComponent } from './components/invoice-list-overview/invoice-list-overview.component';
import { InvoiceOverviewComponent } from './components/invoice-overview/invoice-overview.component';
import { InvoiceOverviewOrganizationComponent } from './components/invoice-overview-organization/invoice-overview-organization.component';
import { InvoiceOverviewUnauthorizedComponent } from './components/invoice-overview-unauthorized/invoice-overview-unauthorized.component';
import { InvoiceListSandbox } from './components/invoice-list/invoice-list.sandbox';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../core/shared/shared.module';
import { DataTablesModule } from 'angular-datatables';
import { InvoiceSandbox } from './components/invoice/invoice.sandbox';
import { InvoiceOverdueListComponent } from './components/invoice-overdue-list/invoice-overdue-list.component';
import { InvoiceOverdueListSandbox } from './components/invoice-overdue-list/invoice-overdue-list.sandbox';
import { SubInvoiceListOverviewOrganziationComponent } from './components/sub-invoice-list-overview-organziation/sub-invoice-list-overview-organziation.component';
import { SubInvoiceOverviewOrganziationComponent } from './components/sub-invoice-overview-organziation/sub-invoice-overview-organziation.component';
import { InvoiceUpdateDeadlineComponent } from './components/invoice-update-deadline/invoice-update-deadline.component';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InvoiceUpdateDeadlineSandbox } from './components/invoice-update-deadline/invoice-update-deadline.sandbox';
import { InvoiceListOverviewOrganizationSandbox } from './components/invoice-list-overview-organization/invoice-list-overview-organization.sandbox';
import { InvoiceListOverviewSandbox } from './components/invoice-list-overview/invoice-list-overview.sandbox';
import { SubInvoiceListOverviewOrganizationSandbox } from './components/sub-invoice-list-overview-organziation/sub-invoice-list-overview-organization.sandbox';
import { SagaExportComponent } from './components/saga-export/saga-export.component';
import {Daterangepicker} from "ng2-daterangepicker";
import {ReceiptModule} from '../receipt/receipt.module';

@NgModule({
  declarations: [
    InvoiceComponent,
    InvoiceListComponent,
    InvoiceListOverviewOrganizationComponent,
    InvoiceListOverviewComponent,
    InvoiceOverviewComponent,
    InvoiceOverviewOrganizationComponent,
    InvoiceOverviewUnauthorizedComponent,
    InvoiceOverdueListComponent,
    SubInvoiceListOverviewOrganziationComponent,
    SubInvoiceOverviewOrganziationComponent,
    InvoiceUpdateDeadlineComponent,
    SagaExportComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        DataTablesModule,
        BsDatepickerModule,
        ReactiveFormsModule,
        FormsModule,
        Daterangepicker,
        ReceiptModule,
    ],
  providers: [
    InvoiceListSandbox,
    InvoiceSandbox,
    InvoiceOverdueListSandbox,
    InvoiceUpdateDeadlineSandbox,
    InvoiceListOverviewOrganizationSandbox,
    InvoiceListOverviewSandbox,
    SubInvoiceListOverviewOrganizationSandbox
  ],
  entryComponents: [
    InvoiceUpdateDeadlineComponent,
    SagaExportComponent
  ],
  exports: [
    InvoiceOverdueListComponent,
    InvoiceListComponent
  ]
})
export class InvoiceModule { }
