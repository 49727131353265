import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {InvoiceInput, InvoiceRequestType} from '../invoice/invoice.component';

@Component({
  selector: 'app-invoice-overview',
  templateUrl: './invoice-overview.component.html',
  styleUrls: ['./invoice-overview.component.scss']
})
export class InvoiceOverviewComponent implements OnInit {

  public invoiceInput: InvoiceInput = null;
  public dataLoaded: boolean = false;

  constructor(
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.invoiceId !== undefined) {
        let data = new InvoiceInput();
        data.type = InvoiceRequestType.SuperAdmin;
        data.invoiceId = params.invoiceId;
        this.invoiceInput = data;

        this.dataLoaded = true;
      }
    });
  }

}
