import { Component, OnInit, Output, EventEmitter, OnDestroy, NgZone, ElementRef, ViewChild } from '@angular/core';
import { FileAttachment, FileToUpload, FileUploadTarget } from 'src/app/core/app-dto/core.dto';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import { CarServicePhotosSandbox } from './car-service-photos.sandbox';
import { CarServicePhotosDI } from './car-service-photos.di';
import { FileWrapper } from 'src/app/core/upload/components/upload-notification/upload-notification.di';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { UploadInput } from 'src/app/core/upload/components/upload-notification/upload-notification.component';
import { isValidImage } from 'src/app/core/shared/helpers/common.helpers';
import { NotifierService } from 'angular-notifier';
import {ImageHelpers} from "../../../core/shared/image.helpers";

@Component({
  selector: 'app-car-service-photos',
  templateUrl: './car-service-photos.component.html',
  styleUrls: ['./car-service-photos.component.scss']
})
export class CarServicePhotosComponent extends BaseFormModelComponent<Array<FileAttachment>> implements OnInit, OnDestroy {

  public files: Array<FileAttachment> = [];

  @Output()
  formSaved = new EventEmitter<Array<FileAttachment>>();
  @Output()
  urls = new EventEmitter<Array<FileImageWrapper>>();

  constructor(
    public sandbox: CarServicePhotosSandbox,
    private notifierService: NotifierService,
    private imageHelpers: ImageHelpers
  ) {
    super(sandbox);
  }

  public diInitialize(data: Array<FileAttachment>) {
    this.di = new CarServicePhotosDI([]);
    this.dataLoaded = true;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();
    if (this.di.isValid()) {
      if (this.formSaved != null) {
        this.formSaved.emit(this.di.getModel());
      }
    }
  }

  isValid() {
    return this.di.isValid();
  }

  @ViewChild('fileInput', { static: false })
  fileInput: ElementRef;

  public uploadInput: UploadInput;
  public imgUrls: Array<FileImageWrapper> = [];

  add(data: FileList) {
    console.log("onImageChange: " + NgZone.name);

    let validFiles = true;
    let arrayData = Array.from(data);
    arrayData.forEach(r => {
      if (isValidImage(r) == false) {
        //@Todo: add notification error
        validFiles = false;
      }
    });
    if (validFiles) {

      if(this.imageHelpers.isNoOfImagesValid((<CarServicePhotosDI>this.di).model.length + arrayData.length)==false) return;
      arrayData.map(r => {
        let result = new FileWrapper();
        let image = new FileImageWrapper();
        result.file = r;
        image.id = result.id = new Date().getTime().toString();
        image.name = result.file.name;
        var reader = new FileReader();
        reader.readAsDataURL(r);
        reader.onload = (_event) => {
          image.url = reader.result;
          this.imgUrls.push(image);
          this.urls.emit(this.imgUrls);
        }
        (<CarServicePhotosDI>this.di).model.push(result);
      });
    } else {
      this.notifierService.notify('error', 'Tipul fisierului nu este acceptat!');
    }

    this.formSaved.emit(this.di.getModel());
  }

  delete(id: string) {
    let i = (<CarServicePhotosDI>this.di).model.findIndex(r => r.id == id);
    (<CarServicePhotosDI>this.di).model.splice(i, 1);
    i = this.imgUrls.findIndex(r => r.id == id);
    this.imgUrls.splice(i, 1);
    this.formSaved.emit(this.di.getModel());
    this.urls.emit(this.imgUrls);
  }

  getImgUrl(): Array<FileImageWrapper> {
    return this.imgUrls;
  }

}

export class FileImageWrapper {
  public id: string;
  public name: string;
  public url: string | ArrayBuffer;
}
