import {BaseSelectWrapperDI} from 'src/app/core/shared/base/base.di';
import {Invoice_Dto, InvoiceStatus, InvoiceType} from 'src/app/core/app-dto/invoice.dto';
import {isValidObject} from 'src/app/core/shared/helpers/common.helpers';

export class InvoiceListItemDI extends BaseSelectWrapperDI<Invoice_Dto> {
  public status: string = "";
  public new: boolean = false;
  public paid: boolean = false;
  public overdue: boolean = false;
  public computedType: string = '';
  public canBeFiscallyPrinted: boolean = false;


  constructor(data: Invoice_Dto) {
    super(new Invoice_Dto(data));
    if (isValidObject(this.model.status)) {
      switch (this.model.status) {
        case InvoiceStatus.New: {
          this.new = true;
          this.status = "Generata";
          break;
        }
        case InvoiceStatus.Paid: {
          this.paid = true;
          this.status = "Platita";
          break;
        }
        case InvoiceStatus.Overdue: {
          this.overdue = true;
          this.status = "Depasita";
          break;
        }
      }
    }
    switch (this.model.type) {
      case InvoiceType.CarServiceEntryAdvancePayment :
        this.computedType = "Factura Avans";
        break;
      case InvoiceType.CarServiceEntry :
        this.computedType = "Factura Reparatie";
        break;
      case InvoiceType.Subscription :
        this.computedType = "Factura Subscriptie";
        break;
      case InvoiceType.SMS :
        this.computedType = "Factura Cumparare SMS-uri";
        break;
    }

    this.canBeFiscallyPrinted = !this.model.isProforma;
  }

  getModel(): Invoice_Dto {
    return this.model;
  }
}
