import {Action} from "@ngrx/store";
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  DoClearRulesForUser,
  DoLoadRulesForUser,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from "../events/base.events";

import {
  AddAuthenticationToState,
  LoginEvent,
  LogoutEvent,
  RemoveAuthenticationFromState,
} from "../events/authentication.events";
import {
  AppNotification,
  FileAttachment,
  FileUploadTarget,
  LoggedUser_Dto,
  StickyHeaderNotification
} from "../../app-dto/core.dto";
import {AppUserBusinessRule} from "../../core/business-rule-provider/app-user.business-rule.provider";
import {ProfileImageChanged} from "../events/profile.events";
import {SetInitialSetupEvent} from '../events/user.events';
import {EditSubscriptionEvent, EditSubscriptionProfileEvent} from "../events/subscription.events";
import {Organization} from "../../app-dto/organization.dto";
import {ObjectValidators} from "../../shared/object.validators";
import {
  AddStickyHeaderNotificationEvent,
  RemoveStickyHeaderNotificationEvent
} from "../events/sticky-header-notification.events";
import {isValidArrayAndHasElements, isValidObject} from "../../shared/helpers/common.helpers";
import {PagedResult} from "../../shared/datatable/datatable.helpers";
import {GetCarPartsPagedResultEvent} from "../events/car-part.events";
import {
  DeleteAppNotificationEvent,
  GetNoOfUnreadNotificationsEvent,
  GetNotificationsPagedResultEvent,
  MarkAppNotificationAsNotReadEvent,
  MarkAppNotificationAsReadEvent,
  ProcessAppNotificationEvent
} from "../events/notification.events";
import {Version_Dto} from '../../app-dto/version.dto';
import {GetClientForCarEvent} from '../events/car-service.events';
import {OrganizationClient_Dto} from '../../app-dto/organization-client.dto';
import {DoGetVersion} from '../events';

export interface CoreState {
  isLoading: boolean;
  user: LoggedUser_Dto;
  currentAppRules: AppUserBusinessRule;
  stickyHeaderNotifications: Array<StickyHeaderNotification>;
  notifications: PagedResult<AppNotification>;
  unreadNotificationsCount: number;
  version: Version_Dto;
};

export const INITIAL_STATE: CoreState = Object.assign({}, {
  isLoading: false,
  user: null,
  currentAppRules: null,
  stickyHeaderNotifications: [],
  notifications: new PagedResult<AppNotification>(null),
  unreadNotificationsCount: 0,
  version: null
});


export function reducer(state = INITIAL_STATE, action: Action): CoreState {
  if (!action) return state;
  var actionType = action.type;
  if (actionType.indexOf(EventWithScreenLoading.identifier) > -1) {
    state.isLoading = true;
    state = Object.assign({}, state);
  }
  if (actionType.indexOf(EventWithStopingScreenLoader.identifier) > -1) {
    state.isLoading = false;
    state = Object.assign({}, state);
  }

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  switch (actionType) {

    case Do_GenericSuccessEvent.identifier + LoginEvent.identifier: {

      let loginResponse = (<Do_GenericSuccessEvent<LoggedUser_Dto>>action).data;
      state.user = new LoggedUser_Dto(loginResponse);
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + SetInitialSetupEvent.identifier: {
      state.user.isInitialSetupDone = true;
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + DoLoadRulesForUser.identifier: {
      state.currentAppRules = (<Do_GenericSuccessEvent<AppUserBusinessRule>>action).data;
      return Object.assign({}, state);
    }

    case DoClearRulesForUser.identifier: {
      state.currentAppRules = null;
      return Object.assign({}, state);
    }

    case AddAuthenticationToState.identifier: {
      state.user = (<AddAuthenticationToState>action).user;
      return Object.assign({}, state);
    }

    case RemoveAuthenticationFromState.identifier: {
      state.user = null;
      return Object.assign({}, state);
    }

    case ProfileImageChanged.identifier: {
      const data: FileAttachment = (action as ProfileImageChanged).data;

      if (state.user != null && state.user.id == data.relatedEntityId && data.type == FileUploadTarget.UserProfile) {
        state.user.displayImageUrl = data.url;
        state.user = new LoggedUser_Dto(state.user);
        return Object.assign({}, state);
      }
    }

    case Do_GenericSuccessEvent.identifier + LogoutEvent.identifier: {
      return Object.assign({}, state, {
        user: null,
        currentAppRules: null,
        stickyHeaderNotifications: []
      });
    }

    case Do_GenericSuccessEvent.identifier + EditSubscriptionEvent.identifier:
    case Do_GenericSuccessEvent.identifier + EditSubscriptionProfileEvent.identifier: {
      var subscriptionData = (<Do_GenericSuccessEvent<Organization>>action).data;
      if (ObjectValidators.isValidObject(subscriptionData) && state.user != null && state.user.organization != null && state.user.organization.id == subscriptionData.id) {
        state.user.organization = subscriptionData;
        state.user = new LoggedUser_Dto(state.user);
        return Object.assign({}, state, {user: state.user});
      }
    }

    case AddStickyHeaderNotificationEvent.identifier: {
      state.stickyHeaderNotifications.push((<AddStickyHeaderNotificationEvent>action).model);
      return Object.assign({}, state);
    }

    case RemoveStickyHeaderNotificationEvent.identifier: {
      const itemFound = state.stickyHeaderNotifications.find(f => f.description == (<RemoveStickyHeaderNotificationEvent>action).model.description);
      if (isValidObject(itemFound)) state.stickyHeaderNotifications.splice(state.stickyHeaderNotifications.indexOf(itemFound), 1);
      state.stickyHeaderNotifications = state.stickyHeaderNotifications.map(r => r);
      return Object.assign({}, state);
    }
    case Do_ResetStateForSection.identifier + ResetSection.Notifications: {
      return Object.assign({}, state, {notifications: new PagedResult<AppNotification>(null)});
    }
    case GetNotificationsPagedResultEvent.identifier: {
      if ((action as GetCarPartsPagedResultEvent).withReset) {
        state.notifications = new PagedResult<AppNotification>([]);
        return Object.assign({}, state);
      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetNotificationsPagedResultEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<PagedResult<AppNotification>>).data;
      if (state.notifications == null)
        state.notifications = new PagedResult<AppNotification>(data);
      else {
        if (isValidArrayAndHasElements(state.notifications.items) == false)
          state.notifications.items = new Array<AppNotification>();
        state.notifications.items.push.apply(state.notifications.items, data.items);
        state.notifications.totalItems = data.totalItems;
        state.notifications.totalPages = data.totalPages;
        state.notifications.currentPage = data.currentPage;
        state.notifications = new PagedResult<AppNotification>(state.notifications);
      }
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetNotificationsPagedResultEvent.identifier: {
      state.notifications = new PagedResult<AppNotification>([]);
      return Object.assign({}, state);
    }

    case ProcessAppNotificationEvent.identifier: {
      const newNotification = (<ProcessAppNotificationEvent>action).data;
      if (newNotification.id != null && newNotification.id != undefined) {
        if (state.notifications.items == null) state.notifications.items = new Array<AppNotification>();
        state.notifications.items.splice(0, 0, newNotification);
        state.notifications = new PagedResult<AppNotification>(state.notifications);
        if (newNotification.isRead == false)
          state.unreadNotificationsCount++;
        return Object.assign({}, state, {notifications: state.notifications});
      }
      else {
        return state;
      }
    }

    case Do_GenericSuccessEvent.identifier + MarkAppNotificationAsReadEvent.identifier:
    case Do_GenericSuccessEvent.identifier + MarkAppNotificationAsNotReadEvent.identifier: {
      var readNotificationId = (<any>action).data.id;
      if (state.notifications != null) {
        const existingItem = state.notifications.items.find(f => f.id == readNotificationId);
        if (existingItem != null) {
          if (existingItem.isRead == false)
            state.unreadNotificationsCount--;
          else state.unreadNotificationsCount++;
          existingItem.isRead = !existingItem.isRead;
          existingItem.rules = (<any>action).data.rules;
          state.notifications = new PagedResult<AppNotification>(state.notifications);
        }
      }
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + DeleteAppNotificationEvent.identifier: {
      var notificationId = (<Do_GenericSuccessEvent<string>>action).data;
      if (state.notifications != null) {
        let existingItem = state.notifications.items.find(f => f.id == notificationId);
        if (existingItem != null) {
          state.notifications.items.splice(state.notifications.items.indexOf(existingItem), 1);
          state.notifications = new PagedResult<AppNotification>(state.notifications);
          if (existingItem.isRead == false)
            state.unreadNotificationsCount--;
        }
      }
      return Object.assign({}, state, {
        notifications: state.notifications
      });
    }
    case Do_GenericSuccessEvent.identifier + GetNoOfUnreadNotificationsEvent.identifier: {
      const notificationsCount = (<Do_GenericSuccessEvent<number>>action).data;

      return Object.assign({}, state, {
        unreadNotificationsCount: notificationsCount
      });
    }

      // Get Version

    case Do_GenericSuccessEvent.identifier + DoGetVersion.identifier: {
      const versionData = (action as Do_GenericSuccessEvent<Version_Dto>).data;
      return Object.assign({}, state, { version: versionData });
    }

    case Do_GenericErrorEvent.identifier + DoGetVersion.identifier: {
      return Object.assign({}, state, { version: null });
    }

    case Do_ResetStateForSection.identifier + ResetSection.ClientForCar: {
      return Object.assign({}, state, { version: null });
    }
      // Get Version
    default: {
      return state;
    }
  }
}

export const getLoadingCallback = (state: CoreState) => state.isLoading;
export const getLoggedUserCallback = (state: CoreState) => state.user;
export const getCurrentUserRulesCallback = (state: CoreState) => state.currentAppRules;
export const getStickyHeaderNotificationsCallback = (state: CoreState) => state.stickyHeaderNotifications;
export const getNotificationsCallback = (state: CoreState) => state.notifications;
export const getUnreadNotificationsCountCallback = (state: CoreState) => state.unreadNotificationsCount;
export const getVersionCallback = (state: CoreState) => state.version;




