import {BaseFormDI} from "../../../core/shared/base/base.di";
import {isValidArrayAndHasElements} from "../../../core/shared/helpers/common.helpers";
import {FormGroup} from "@angular/forms";
import {OfferWorkEntry_Dto} from "../../../core/app-dto/offer.dto";

export class AddOfferCarWorksListDi extends BaseFormDI<Array<OfferWorkEntry_Dto>> {
    constructor(data: Array<OfferWorkEntry_Dto> = []) {
        super(isValidArrayAndHasElements(data) ? data : []);
        this.initForm();
    }

    getModel(): Array<OfferWorkEntry_Dto> {
        return this.model;
    }

    initForm() {
        this.form = new FormGroup({});
    }
}
