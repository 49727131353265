import {BaseDI} from 'src/app/core/shared/base/base.di';
import {Offer_Dto} from '../../../core/app-dto/offer.dto';
import {isValidObject} from '../../../core/shared/helpers/common.helpers';
import * as moment from "moment";

export class OfferDetailsDi extends BaseDI<Offer_Dto> {
    public computedDeadline: string;
    public hasExpiration: boolean  = false;
    public computedExpiration: Date;

    constructor(data: Offer_Dto) {
        super(new Offer_Dto(data));
        if (isValidObject(data) && isValidObject(data.offerEntryData) && isValidObject(data.offerEntryData.deadline)) {
            this.computedDeadline = data.offerEntryData.deadline;
        }
        if (isValidObject(data) && isValidObject(data.offerEntryData) && isValidObject(data.offerEntryData.expiresOn)) {
            this.computedExpiration = moment.utc(data.offerEntryData.expiresOn).toDate();
            this.hasExpiration = true;
        }
        else this.hasExpiration = false;
    }

    getModel(): Offer_Dto {
        return this.model;
    }
}
