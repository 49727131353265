import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import {BaseFormComponent, BaseFormModelComponent} from 'src/app/core/shared/base/base.component';
import { AppointmentClientSandbox } from './appointment-client.sandbox';
import { AppointmentClientDi } from './appointment-client.di';
import { OrganizationClient_Dto } from 'src/app/core/app-dto/organization-client.dto';
import { OrganizationClientSearchComponent } from 'src/app/organization-client/components/organization-client-search/organization-client-search.component';
import { AppointmentClient_Dto } from '../../../core/app-dto/appointment-client.dto';
import { GetCarForClientActionProxy } from '../../../car-service/components/car-service-client/get-car-for-client.action-proxy';
import {Car_Dto} from '../../../core/app-dto/car.dto';
import {AppointmentCarDi} from '../appointment-car/appointment-car.di';
import {AppointmentCar_Dto} from '../../../core/app-dto/appointment-car.dto';

@Component({
  selector: 'app-appointment-client',
  templateUrl: './appointment-client.component.html',
  styleUrls: ['./appointment-client.component.scss']
})
export class AppointmentClientComponent extends BaseFormModelComponent<OrganizationClient_Dto> implements OnInit, OnDestroy {
  public getCarForClientAP: GetCarForClientActionProxy;
  public clientSearch: string = '';

  @Output()
  modelSaved = new EventEmitter<AppointmentClient_Dto>();
  @Output()
  eventSelectedOutput = new EventEmitter<string>();

  @Input("client") set client(client: AppointmentClient_Dto) {
    this.di = new AppointmentClientDi(client);
    this.clientSearch = this.di.model.name;
  }

  @ViewChild(OrganizationClientSearchComponent, { static: false }) organizationClientSearchComponent: OrganizationClientSearchComponent;

  constructor(public appState: Store<store.State>,
              public sandbox: AppointmentClientSandbox) {
    super(sandbox);
    this.getCarForClientAP = new GetCarForClientActionProxy(this, sandbox.appState);
  }

  public diInitialize() {
  }

  ngOnInit() {
    this.initialize((data: OrganizationClient_Dto) => {
      this.di = new AppointmentClientDi(new AppointmentClient_Dto({
        name: this.sandbox.clientForCar.name,
        clientId: this.sandbox.clientForCar.id,
        phoneNumber: this.sandbox.clientForCar.phoneNumber
      }));
      this.organizationClientSelected(data);
      if (data) {
        this.clientSearch = this.sandbox.clientForCar.name;
      } else {
        this.clientSearch = '';
        this.clearSearch();
      }
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();
    if (this.di.isValid()) {
      if (this.modelSaved != null) {
        this.modelSaved.emit(this.di.getModel());
      }
    }
  }

  isValid() {
    return this.di.isValid();
  }

  organizationClientSelected(value: any) {
    const client = new OrganizationClient_Dto(value);
    this.di.model.clientId = client.id;
    this.di.model.name = client.name;
    this.di.form.controls['phoneNumber'].setValue(client.phoneNumber);
    if (this.modelSaved != null) {
      this.modelSaved.emit(this.di.getModel());
    }

    if (client.id) {
      if (!this.sandbox.isCarComponentTriggered) {
        this.getCarForClientAP.execute(client.id);
      }
    } else {
      this.sandbox.resetCarForClient();
    }
  }

  clearSearch() {
    if (this.organizationClientSearchComponent) {
      this.organizationClientSearchComponent.clearSelect();
    }
  }
}
