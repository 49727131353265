import {AppRole_Dto, Country} from './core.dto';
import {ObjectValidators} from '../shared/object.validators';
import {IdName_Dto, IdNameKey_Dto, IdNameNumber_Dto} from './misc.dto';
import {isValidArrayAndHasElements} from '../shared/helpers/common.helpers';
import { AddonType } from './organization.dto';

export class StaticData {
  public roles: Array<AppRole_Dto> = [];
  public countries: Array<Country> = [];
  public organizationTypes: Array<IdNameKey_Dto> = [];
  public subscriptionTypes: Array<IdNameKey_Dto> = [];
  public addonTypes: Array<AddonType> = [];
  public currencies: Array<IdNameKey_Dto> = [];
  public mechanicSpecializationTypes: Array<IdNameKey_Dto> = [];
  public fuelTypes: Array<IdNameKey_Dto> = [];
  public carServiceEntryActionStatus: Array<IdNameKey_Dto> = [];
  public carServiceEntryStatus: Array<IdNameKey_Dto> = [];
  public estimatedDeadlineTypes: Array<IdNameKey_Dto> = [];
  public organizationCostVats: Array<IdNameNumber_Dto> = [];
  public organizationCostVatsForSettings: Array<IdNameNumber_Dto> = [];
  public supplyVats: Array<IdNameNumber_Dto> = [];
  public offerTypes: Array<IdNameKey_Dto> = [];
  public offerStatus: Array<IdNameKey_Dto> = [];

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
        this.roles = data.roles.map(r => new AppRole_Dto(r));
        this.countries = data.countries.map(r => new Country(r)).sort((a, b) => a.name < b.name ? -1 : 1);
        if (isValidArrayAndHasElements(data.organizationTypes)) {
            this.organizationTypes = data.organizationTypes.map(r => new IdNameKey_Dto(r));
        }
        if (isValidArrayAndHasElements(data.subscriptionTypes)) {
            this.subscriptionTypes = data.subscriptionTypes.map(r => new IdNameKey_Dto(r));
        }
        if (isValidArrayAndHasElements(data.addonTypes)) {
            this.addonTypes = data.addonTypes.map(r => new AddonType(r));
        }
        if (isValidArrayAndHasElements(data.currencies)) {
            this.currencies = data.currencies.map(r => new IdNameKey_Dto(r));
        }
        if (isValidArrayAndHasElements(data.mechanicSpecializationTypes)) {
            this.mechanicSpecializationTypes = data.mechanicSpecializationTypes.map(r => new IdNameKey_Dto(r));
        }
        if (isValidArrayAndHasElements(data.fuelTypes)) {
            this.fuelTypes = data.fuelTypes.map(r => new IdNameKey_Dto(r));
        }
        if (isValidArrayAndHasElements(data.carServiceEntryActionStatus)) {
            this.carServiceEntryActionStatus = data.carServiceEntryActionStatus.map(r => new IdNameKey_Dto(r));
        }
        if (isValidArrayAndHasElements(data.carServiceEntryStatus)) {
            this.carServiceEntryStatus = data.carServiceEntryStatus.map(r => new IdNameKey_Dto(r));
        }
        if (isValidArrayAndHasElements(data.estimatedDeadlineTypes)) {
            this.estimatedDeadlineTypes = data.estimatedDeadlineTypes.map(r => new IdNameKey_Dto(r));
        }
        if (isValidArrayAndHasElements(data.organizationCostVats)) {
            this.organizationCostVats = data.organizationCostVats.map(r => new IdNameNumber_Dto(r));
        }
        if (isValidArrayAndHasElements(data.organizationCostVats)) {
            this.organizationCostVatsForSettings = data.organizationCostVats.map(r => new IdNameNumber_Dto(r))
                .filter(item => item.id === 0 || item.id === 19);
        }
        if (isValidArrayAndHasElements(data.supplyVats)) {
            this.supplyVats = data.supplyVats.map(r => new IdNameNumber_Dto(r));
        }
        if (isValidArrayAndHasElements(data.offerTypes)) {
            this.offerTypes = data.offerTypes.map(r => new IdNameKey_Dto(r));
        }
        if (isValidArrayAndHasElements(data.offerStatus)) {
            this.offerStatus = data.offerStatus.map(r => new IdNameKey_Dto(r));
        }
    }

  }

  public isEmpty() {
    return !ObjectValidators.isValidArray(this.roles) ||
      !ObjectValidators.isValidArray(this.organizationTypes) ||
      !ObjectValidators.isValidArray(this.subscriptionTypes) ||
      !ObjectValidators.isValidArray(this.addonTypes) ||
      !ObjectValidators.isValidArray(this.currencies) ||
      !ObjectValidators.isValidArray(this.countries) ||
      !ObjectValidators.isValidArray(this.mechanicSpecializationTypes) ||
      !ObjectValidators.isValidArray(this.carServiceEntryActionStatus) ||
      !ObjectValidators.isValidArray(this.carServiceEntryStatus) ||
      !ObjectValidators.isValidArray(this.organizationCostVats) ||
      !ObjectValidators.isValidArray(this.fuelTypes) ||
      !ObjectValidators.isValidArray(this.supplyVats);
  }
}
