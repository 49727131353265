import { BaseDI } from 'src/app/core/shared/base/base.di';
import { Mechanic_Dto } from 'src/app/core/app-dto/mechanic.dto';
import {IdName_Dto} from "../../../core/app-dto/misc.dto";

export class MechanicsListItemDi extends BaseDI<Mechanic_Dto> {
   public selectedCurrency :IdName_Dto;
    constructor(data: any) {
        super(new Mechanic_Dto(data));
        
    }

    getModel(): Mechanic_Dto {
        return this.model;
    }
}
