import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { ActiveDataTableParameters } from 'src/app/core/shared/datatable/datatable.helpers';
import { GetDepartmentsPagedResultEvent } from 'src/app/core/app-store/events/department.events';

export class GetDepartmentsActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent, appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(dataTablesParameters: ActiveDataTableParameters, withReset: boolean): any {
        if (this.canExecute()) {
            this.appState.dispatch(new GetDepartmentsPagedResultEvent(dataTablesParameters, withReset));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

