import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { BaseFormComponent } from 'src/app/core/shared/base/base.component';
import { AppointmentDetailsSandbox } from './appointment-details.sandbox';
import { AppointmentDetailsDi } from './appointment-details.di';
import { CarSearchComponent } from '../../../car/components/car-search/car-search.component';
import { GetDataActionProxy } from '../../../car-service/components/car-service-add-action/get-data.action-proxy';
import { DateTimeService } from '../../../core/core/services/date-time.service';
import { CarAppointment_Dto } from '../../../core/app-dto/car-appointment.dto';
import { ServicePlatform_Dto } from '../../../core/app-dto/service-platform.dto';
import { CheckServicePlatformAvailabilityActionProxy } from '../../../core/core/components/check-mechanic-platform-busy/check-service-platform-availability.action-proxy';
import { CheckMechanicAvailabilityActionProxy } from '../../../core/core/components/check-mechanic-platform-busy/check-mechanic-availability.action-proxy';
import { Mechanic_Dto } from '../../../core/app-dto/mechanic.dto';
import {makeInputPositive} from '../../../core/shared/helpers/common.helpers';

@Component({
  selector: 'app-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.scss']
})
export class AppointmentDetailsComponent extends BaseFormComponent implements OnInit, OnDestroy {
  public getDataAP: GetDataActionProxy;
  public di: AppointmentDetailsDi;

  @Output()
  modelSaved = new EventEmitter<CarAppointment_Dto>();
  @Output()
  eventSelectedOutput = new EventEmitter<string>();

  @ViewChild(CarSearchComponent, { static: false })
  carSearchComponent: CarSearchComponent;

  @Input("carAppointment") set client(carAppointment: CarAppointment_Dto) {
    if (carAppointment) {
      this.di = new AppointmentDetailsDi(this.dateTimeService, carAppointment);
      this.dataLoaded = true;
    }
  }

  @Input('eventId') eventId: string;

  constructor(public appState: Store<store.State>,
              public sandbox: AppointmentDetailsSandbox,
              public dateTimeService: DateTimeService) {
    super(sandbox);
    this.getDataAP = new GetDataActionProxy(this, sandbox.appState);
  }

  ngOnInit() {
    this.getDataAP.execute();
    this.sandbox.initialize();
    this.di = new AppointmentDetailsDi(this.dateTimeService);
  }

  ngOnDestroy() {
    this.destroy();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();
    if (this.di.isValid()) {
      if (this.modelSaved != null) {
        this.modelSaved.emit(this.di.getModel());
      }
    }
  }

  isValid() {
    return this.di.isValid() && this.di.busyFlag == false;
  }

  dismissUnavailableServicePlatform() {
    this.di.form.controls.servicePlatform.setValue(null);
  }
  dismissUnavailableMechanic() {
    this.di.form.controls.mechanic.setValue(null);
  }

  makeValuePositive(event: any) {
    makeInputPositive(event);
  }
}
