import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { RequestSubscription_Dto } from 'src/app/core/app-dto/authentication.dto';
import { Address } from "../../../app-dto/core.dto";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {EmailRegForControl, PhoneReg} from 'src/app/core/shared/form/custom-form.validator';

export class RequestSubscriptionDI extends BaseFormDI<RequestSubscription_Dto> {
  public subscriptionId: string = '';

  constructor(data: RequestSubscription_Dto = new RequestSubscription_Dto(null)) {
    super(data);
    this.initForm();
  }

  getModel(): RequestSubscription_Dto {
    this.model.address = new Address(this.form.value.address);
    this.model.cui = this.form.value.cui.toString();
    this.model.serviceName = this.form.value.serviceName;
    this.model.firstName = this.form.value.firstName;
    this.model.lastName = this.form.value.lastName;
    this.model.registrationNumber = this.form.value.registrationNumber.toString();
    this.model.email = this.form.value.email;
    this.model.phoneNumber = this.form.value.phoneNumber;
    //this.model.subscriptionId = this.subscriptionId;
    this.model.currency = this.form.value.currency.id;
    return this.model;
  }

  initForm() {
    this.form = new FormGroup({
      serviceName: new FormControl('', Validators.compose([Validators.required])),
      firstName: new FormControl('', Validators.compose([Validators.required])),
      lastName: new FormControl('', Validators.compose([Validators.required])),
      cui: new FormControl('', Validators.compose([Validators.required])),
      registrationNumber: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([Validators.required, EmailRegForControl()])),
      phoneNumber: new FormControl('', Validators.compose([Validators.required, PhoneReg()])),
      //subscriptionId: new FormControl(this.subscriptionId, Validators.compose([Validators.required]))
    });
  }

  resetAnafUsedFields() {
    this.form.get('serviceName').setValue(null);
    this.form.get('registrationNumber').setValue(null);
    this.form.get('phoneNumber').setValue(null);
    const address = new Address(null);
    this.model.address = address;
  }
}
