import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiErrorDto } from '../utils/api-error.dto';
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { User_Dto } from '../../app-dto/core.dto';
import { ErrorHelpers } from '../../shared/error.helpers';

class ProfileApiRoutes {
    public static route_for_GET_PROFILE() {
        return '/api/account/profile';
    }

    public static route_for_UPDATE_PROFILE() {
        return '/api/account/profile';
    }

    public static route_for_DELETE_PROFILE_IMAGE_URL() {
        return '/api/user/profile-image';
    }
}

@Injectable()
export class ProfileApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_getProfile(): Observable<User_Dto> {
        const url = this.configService.getAPIBaseUrl() + ProfileApiRoutes.route_for_GET_PROFILE();

        return this.http.get(url)
            .map((res: any) => {
                return new User_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_updateProfile(data: User_Dto): Observable<User_Dto> {
        const url = this.configService.getAPIBaseUrl() + ProfileApiRoutes.route_for_UPDATE_PROFILE();

        return this.http.put(url, data)
            .map((res: any) => {
                return new User_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deleteProfileImageUrl(): Observable<string> {
        const url = this.configService.getAPIBaseUrl() + ProfileApiRoutes.route_for_DELETE_PROFILE_IMAGE_URL();
    
        return this.http.delete(url)
          .map((res: any) => {
            return res.value;
          })
          .catch<any, any>((err: ApiErrorDto, o) => {
            return ErrorHelpers.throwError(err);
          });
      }
}
