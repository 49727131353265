import { State } from '../state';
import * as fromSupplier from '../stores/supplier.store';
import { createSelector } from '@ngrx/store';
import {getSupplierSPagedResultCallbackNew} from "../stores/supplier.store";

export const getSupplierStateCallback = (state: State) => state.supplier;

export const getSupplierTimestamp = createSelector(getSupplierStateCallback, fromSupplier.getTimestampCallback);
export const getSupplier = createSelector(getSupplierStateCallback, fromSupplier.getSupplierCallback);
export const getSuppliersListing = createSelector(getSupplierStateCallback, fromSupplier.getSuppliersPagedResultCallback);
export const getSuppliersPaged = createSelector(getSupplierStateCallback, fromSupplier.getSupplierSPagedResultCallbackNew);
