import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

import { LoginSandbox } from './components/login/login.sandbox';
import { ResetPasswordSandbox } from './components/reset-password/reset-password.sandbox';
import { ForgotPasswordSandbox } from './components/forgot-password/forgot-password.sandbox';
import { LogoutSandbox } from './components/logout/logout.sandbox';
import { LogoutComponent } from './components/logout/logout.component';
import { RequestSubscriptionComponent } from './components/request-subscription/request-subscription.component';
import { RequestSubscriptionSandbox } from './components/request-subscription/request-subscription.sandbox';
import { ActivateSubscriptionComponent } from './components/activate-subscription/activate-subscription.component';
import { ActivateSubscriptionSandbox } from './components/activate-subscription/activate-subscription.sandbox';
import { GenerateActivationLinkComponent } from './components/generate-activation-link/generate-activation-link.component';
import {GenerateActivationLinkSandbox} from "./components/generate-activation-link/generate-activation-link.sandbox";
import {NgSelectModule} from "@ng-select/ng-select";
import {LoginActionProxy} from "./components/login/login.action-proxy";

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LogoutComponent,
    RequestSubscriptionComponent,
    ActivateSubscriptionComponent,
    GenerateActivationLinkComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    NgSelectModule
  ],
  providers: [
    LoginSandbox,
    ResetPasswordSandbox,
    ForgotPasswordSandbox,
    LogoutSandbox,
    RequestSubscriptionSandbox,
    ActivateSubscriptionSandbox,
    GenerateActivationLinkSandbox
  ],
  exports: [
    LogoutComponent
  ]
})
export class AuthModule { }
