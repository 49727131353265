import { Component, OnInit } from '@angular/core';
import {BaseWebComponent, BaseWebComponentWithBreadcrumb} from "../../../shared/base/base.component";
import {AppUserBusinessRuleProvider} from "../../business-rule-provider/app-user.business-rule.provider";
import {ConfigService} from "../../app-config.service";
import {DashboardSandbox} from "../../../../dashboard/components/dashboard/dashboard.sandbox";
import {ResetSection} from "../../../app-store/events/base.events";
import {StickyHeaderNotification} from "../../../app-dto/core.dto";
import {StickyHeaderNotificationSandbox} from "./sticky-header-notification.sandbox";
import {StickyHeaderNotificationDI} from "./sticky-header-notification.di";
import {isValidArrayAndHasElements} from "../../../shared/helpers/common.helpers";
import {RemoveStickyHeaderNotificationActionProxy} from "./remove-sticky-header-notification.action-proxy";

@Component({
  selector: 'app-sticky-header-notification',
  templateUrl: './sticky-header-notification.component.html',
  styleUrls: ['./sticky-header-notification.component.scss']
})
export class StickyHeaderNotificationComponent  extends BaseWebComponent implements OnInit {
  public data: StickyHeaderNotificationDI[] = [];
  public removeItemAP : RemoveStickyHeaderNotificationActionProxy;

  constructor(public rulesProvider: AppUserBusinessRuleProvider,
              public configService: ConfigService,
              public sandbox: StickyHeaderNotificationSandbox) {
    super(sandbox, null);
    this.removeItemAP = new RemoveStickyHeaderNotificationActionProxy(this,this.sandbox.appState);
  }

  ngOnInit() {
    this.sandbox.initialize((result:StickyHeaderNotification[])=>{
      if(isValidArrayAndHasElements(result)) {
        this.data = result.map(r=> new StickyHeaderNotificationDI(r));
      }
      else this.data = [];
    });
  }

  dismissItem(data:StickyHeaderNotificationDI) {
    this.removeItemAP.execute(data.getModel());
  }
}
