import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { StaticData } from '../../../core/app-dto/static-data.dto';
import { CarPart_Dto } from '../../../core/app-dto/car-part.dto';
import { Supplier_Dto } from '../../../core/app-dto/supplier.dto';

@Injectable()
export class EditSupplyItemSandbox extends BaseSandbox {
    public staticData$ = this.appState$.pipe(select(store.getStaticData));
    public getCarPartSearchList$ = this.appState$.pipe(select(store.getCarPartSearchList));
    public items: StaticData;
    public carParts: CarPart_Dto[];

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
    }

    public initialize(): any {
        this.clearSandboxState();
        this.subscriptions.push(this.staticData$.subscribe((data: StaticData) => {
            if (ObjectValidators.isValidObject(data)) {
                this.items = new StaticData(data);
            }
        }));
        this.subscriptions.push(this.getCarPartSearchList$.subscribe((data: Array<CarPart_Dto>) => {
            if (ObjectValidators.isValidArray(data)) {
                this.carParts = data;
            }
        }));
    }
}
