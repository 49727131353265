import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {SearchSandbox} from './search.sandbox';
import {IdName_Dto} from 'src/app/core/app-dto/misc.dto';
import {Subject} from 'rxjs/Subject';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {NgSelectComponent} from '@ng-select/ng-select';
import {ObjectValidators} from '../../object.validators';
import {isValidObject} from "../../helpers/common.helpers";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  public searchValue: string = null;
  public isCleared: boolean = false;
  public selectedValueId: string = null;


  @Input() items: Array<IdName_Dto>;
  @Input() isLoading: boolean = false;
  @Input() allowInput: boolean = false;
  @Input() error: boolean = false;

  @Input("initialValue") set initialValue(data: IdName_Dto) {
    if (isValidObject(data)) {

      if (data.id == undefined) {
        data.id = "input";
        let i = this.items.findIndex(r => r.id == 'input');
        if (i > 0) {
          this.items.splice(i, 1);
        }
        this.items.push(new IdName_Dto({id: 'input', name: data.name}));
        this.items = this.items.map(r => new IdName_Dto(r));
        this.input = data.name;
      }
      else{
        const existingItem = this.items.find(f=>f.id == data.id);
        if(existingItem ==null) {
          this.items.push(new IdName_Dto({id: data.id, name: data.name}));
          this.items = this.items.map(r => new IdName_Dto(r));
        }
      }
      this.selectedValueId = data.id;
    }
    else {
      this.selectedValueId = null;
    }
  }

  @Output() searchCalled: EventEmitter<string> = new EventEmitter<string>();
  @Output() inputValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected = new EventEmitter<IdName_Dto>();

  public input$ = new Subject<string | null>();
  public modelChanged: Subject<string> = new Subject<string>();

  @ViewChild(NgSelectComponent, {static: false}) ngSelectComponent: NgSelectComponent;

  constructor(
    public sandbox: SearchSandbox
  ) {
    this.input$.subscribe((searchQuery) => {
      this.modelChanged.next(searchQuery);
    });

    this.modelChanged.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(searchQuery => {
        this.searchValue = searchQuery;
        if (this.searchValue == null)
          this.searchValue = '';
        if (this.searchCalled != null) {
          this.searchCalled.emit(this.searchValue);
        }
      });

    this.isCleared = false;
  }

  selected(item: IdName_Dto) {
    if (this.allowInput && this.input != '') {
      let i = this.items.findIndex(r => r.id == 'input');
      if (i == -1) {
        this.items.push(new IdName_Dto({id: 'input', name: this.input}));
        this.items = this.items.map(r => new IdName_Dto(r));
      }
      this.input = '';
    }
    if (ObjectValidators.isValidObject(item)) {
      this.emitValue(item);
      this.selectedValueId = item.id;
    } else {
      if (!this.isCleared) {
        this.emitValue(null);
      }
      this.isCleared = false;
      this.selectedValueId = null;
    }
  }

  public input: string = '';

  inputChanged(val: any) {
    this.input = val.term;
  }

  clearSelect() {
    this.isCleared = true;
    this.ngSelectComponent.handleClearClick();
  }

  onClose() {
    if (this.allowInput && this.input != '') {
      let i = this.items.findIndex(r => r.id == 'input');
      if (i == -1) {
        this.items.push(new IdName_Dto({id: 'input', name: this.input}));
        this.items = this.items.map(r => new IdName_Dto(r));
      }
      this.selectedValueId = 'input';
      this.emitValue(new IdName_Dto({id: 'input', name: this.input}));
      if (this.inputValue != null) {
        this.inputValue.emit(this.input);
      }
    }
  }

  onOpen() {
    if (this.allowInput && this.input != '') {
      let i = this.items.findIndex(r => r.id == 'input');
      if (i >= 0) {
        this.items.splice(i, 1);
        this.items = this.items.map(r => new IdName_Dto(r));
      }
      this.selectedValueId = null;
      this.input = '';
      if (this.inputValue != null) {
        this.inputValue.emit(this.input);
      }
    }
  }

  addCustomItem(name) {
    return new Promise((resolve) => {
      resolve({id: '', name, valid: true});
    });
  }

  emitValue(value: IdName_Dto) {
    if (isValidObject(value)) {
      if (value.id == "input") value.id = null;
    }
    if (this.itemSelected != null) {
      this.itemSelected.emit(value);
    }
  }
}
