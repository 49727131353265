import { Injectable } from '@angular/core';
import * as store from '../../../app-store/index';
import { Store, select } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { Do_ResetStateForSection, ResetSection } from 'src/app/core/app-store/events/base.events';
import { BaseComponentState } from 'src/app/core/app-store/utils/base-component.state';
import { ObjectValidators } from 'src/app/core/shared/object.validators';

@Injectable()
export class ForgotPasswordSandbox extends BaseSandbox {
    public forgetPassword$ = this.appState$.pipe(select(store.getForgotPassword));

    constructor(
        protected appState$: Store<store.State>
    ) {
        super(appState$);
    }

    clearSandboxState() {
    }

    initialize(authMessage: (message: string) => {}) {
        this.clearSandboxState();
        this.appState$.dispatch(new Do_ResetStateForSection(ResetSection.ForgotPassword));

        this.subscriptions.push(this.forgetPassword$.subscribe((state: BaseComponentState) => {
            if (ObjectValidators.isValidObject(state)) {
                authMessage(state.stateMessage);
                if (state.loaded) {
                    authMessage('A fost trimis un email cu instructiuni.');
                }
            }
        }));
    }
}
