import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ForgotPasswordSandbox } from './forgot-password.sandbox';
import { BaseFormComponent } from 'src/app/core/shared/base/base.component';
import { ForgotPasswordDI } from './forgot-password.di';
import { ForgotPasswordActionProxy } from './forget-password.action-proxy';
import { ResetSection } from 'src/app/core/app-store/events/base.events';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends BaseFormComponent implements OnInit, OnDestroy {

  public forgotPasswordAP: ForgotPasswordActionProxy;

  public message: string = '';

  constructor(
    public sandbox: ForgotPasswordSandbox
  ) {
    super(sandbox, ResetSection.ForgotPassword);
    this.forgotPasswordAP = new ForgotPasswordActionProxy(this, sandbox.appState);
    this.di = new ForgotPasswordDI();
  }

  ngOnInit() {
    this.initialize((message: string) => {
      this.message = message;
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  saveForm() {
    this.formSubmitted = true;
    this.forgotPasswordAP.execute();
  }
}
