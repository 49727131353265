import {
  UIGeneratedEvent,
  DataGeneratedEvent,
  EventWithScreenLoading,
  EventWithStopingScreenLoader, SeamlessEvent
} from "./base.events";
import { ChangePassword_Dto, ForgotPassword_Dto, Login_Dto, ResetPassword_Dto, RequestSubscription_Dto, ActivateSubscription_Dto } from "../../app-dto/authentication.dto";


export class LoginEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "LoginEvent";
  constructor(public form: Login_Dto, public countryId: string = '', public callback: (error: string) => void = null) {
    super();
    this.type += LoginEvent.identifier;
  }
}

export class LogoutEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "LogoutEvent";
  constructor(public payload: any = null, public shouldDoApiRequest:boolean = true) {
    super();
    this.type += LogoutEvent.identifier;
  }
}

export class AddAuthenticationToState extends SeamlessEvent implements DataGeneratedEvent {
  public static identifier:string = "AddAuthenticationToState";
  constructor(public user: any) {
    super();
    this.type += AddAuthenticationToState.identifier;
  }
}

export class RemoveAuthenticationFromState extends SeamlessEvent implements DataGeneratedEvent {
  public static identifier:string = "RemoveAuthenticationFromState";
  constructor() {
    super();
    this.type += RemoveAuthenticationFromState.identifier;
  }
}

export class ForgotPasswordEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "ForgotPasswordEvent";
  constructor(public form: ForgotPassword_Dto) {
    super();
    this.type += ForgotPasswordEvent.identifier;
  }
}

export class ResetPasswordEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "ResetPasswordEvent";
  constructor(public form: ResetPassword_Dto) {
    super();
    this.type += ResetPasswordEvent.identifier;
  }
}

export class LoadCurrentLoggedUser extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier:string = "LoadCurrentLoggedUser";
  constructor() {
    super();
    this.type += LoadCurrentLoggedUser.identifier;
  }
}

export class OpenChangePasswordEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier:string = "OpenChangePasswordEvent";
  constructor() {
    super();
    this.type += OpenChangePasswordEvent.identifier;
  }
}

export class ChangePasswordEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "ChangePasswordEvent";
  constructor(public data: ChangePassword_Dto, public successCallback: Function) {
    super();
    this.type += ChangePasswordEvent.identifier;
  }
}

export class RequestSubscriptionEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "RequestSubscriptionEvent";
  constructor(public form: RequestSubscription_Dto) {
      super();
      this.type += RequestSubscriptionEvent.identifier;
  }
}

export class ActivateSubscriptionEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "ActivateSubscriptionEvent";
  constructor(public form: ActivateSubscription_Dto) {
      super();
      this.type += ActivateSubscriptionEvent.identifier;
  }
}

export class GenerateActivationLinkEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "GenerateActivationLinkEvent";
  constructor(public form: ForgotPassword_Dto) {
    super();
    this.type += GenerateActivationLinkEvent.identifier;
  }
}
