import {BaseDI} from 'src/app/core/shared/base/base.di';
import {AppNotification} from "../../../app-dto/core.dto";

export class NotificationDI extends BaseDI<AppNotification> {
  public timestamp = new Date().getUTCMilliseconds();
  constructor(data: any) {
    super(new AppNotification(data));
  }
  getModel(): AppNotification {
    return this.model;
  }
}
