import { Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from 'src/app/core/app-store/index';
import 'rxjs-compat/add/operator/switchMap';
import { map, switchMap } from 'rxjs/operators';
import {
  Do_GenericSuccessEvent,
  Do_GenericErrorEvent,
  EventWithScreenLoading,
  SeamlessEvent
} from 'src/app/core/app-store/events/base.events';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { DraftApiService } from 'src/app/core/api-integration/services/draft-api.service';
import {DeleteDraftEvent, GetDraftsEvent, RegisterDraftEvent} from '../../core/app-store/events/draft.events';
import { Draft_Dto } from '../../core/app-dto/draft.dto';


@Injectable()
export class DraftUIEventProcessor {

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private draftApiService: DraftApiService
    ) {
    }

    @Effect()
    GetDraftsEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetDraftsEvent.identifier, SeamlessEvent.identifier + GetDraftsEvent.identifier),
            map((action: GetDraftsEvent) => action),
            switchMap((action: GetDraftsEvent) => {
                return new Observable((observer) => {
                    this.draftApiService.u_getDrafts()
                        .subscribe((res: Draft_Dto[]) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Draft_Dto[]>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doDeleteDraft$: Observable<any> = this.actions$
        .pipe(
            ofType(DeleteDraftEvent.identifier, SeamlessEvent.identifier + DeleteDraftEvent.identifier),
            map((action: DeleteDraftEvent) => action),
            switchMap((action: DeleteDraftEvent) => {
                    return new Observable((observer) => {
                        this.draftApiService.u_deleteDraft(action.id)
                            .subscribe((res: Draft_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<Draft_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

  @Effect()
  doRegisterDraft$: Observable<any> = this.actions$
    .pipe(
      ofType(RegisterDraftEvent.identifier, EventWithScreenLoading.identifier + RegisterDraftEvent.identifier),
      map((action: RegisterDraftEvent) => action),
      switchMap((action: RegisterDraftEvent) => {
          return new Observable((observer) => {
            this.draftApiService.u_registerDraft(action.model)
              .subscribe((res: Draft_Dto) => {
                observer.next(new Do_GenericSuccessEvent<Draft_Dto>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );
}
