import { BaseActionProxy } from "../../../shared/base/base.action-proxy";
import { Store } from "@ngrx/store";
import * as store from "../../../app-store";
import { Observable } from "rxjs";
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import {LogoutEvent} from "../../../app-store/events/authentication.events";

export class LogoutActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent, appState: Store<store.State>) {
        super(component, appState)
    }

    canExecute(): boolean {
        return true;
    }

    execute(shouldDoApiRequest:boolean): any {
        if (this.canExecute()) {
            this.isExecuting = true;
            this.appState.dispatch(new LogoutEvent(null,shouldDoApiRequest));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}
