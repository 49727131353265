import {State} from "../state";
import {createSelector} from "@ngrx/store";
import * as fromCashRegister from "../stores/cash-register.store";

export const getCashRegisterStateCallback = (state: State) => state.cashRegister;

export const getCashRegistersTimestamp = createSelector(getCashRegisterStateCallback, fromCashRegister.getCashRegistersTimestampCallback);
export const getCashRegistersPagedResult = createSelector(getCashRegisterStateCallback, fromCashRegister.getCashRegistersPagedResultCallback);
export const getAllActiveCashRegisters = createSelector(getCashRegisterStateCallback, fromCashRegister.getAllActiveCashRegistersCallback);
export const getCashRegister = createSelector(getCashRegisterStateCallback, fromCashRegister.getCashRegisterCallback);
export const getPrintJobsTimestamp = createSelector(getCashRegisterStateCallback, fromCashRegister.getPrintJobsTimestampCallback);
export const getPrintJobsPagedResult = createSelector(getCashRegisterStateCallback, fromCashRegister.getPrintJobsPagedResultCallback);
export const getRegisterPrintJob = createSelector(getCashRegisterStateCallback, fromCashRegister.getRegisterPrintJobCallback);
