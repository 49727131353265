import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppointmentClient_Dto } from '../../../core/app-dto/appointment-client.dto';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';
import { min } from '../../../core/shared/form/custom-form.validator';
import { isValidNumber, isValidObject, isValidString } from '../../../core/shared/helpers/common.helpers';
import { StaticDataSelectorInput } from '../../../core/shared/components/static-data-selector/static-data-selector.component';

export class CarServiceCarWorksDi extends BaseFormDI<CarService_Dto> {

    public paymentInAdvanceCurrenciesDataInput: StaticDataSelectorInput;
    public estimatedCostCurrenciesDataInput: StaticDataSelectorInput;
    public estimatedDeadlineTypesInput: StaticDataSelectorInput;
    public shouldUseEstimatedDeadlineTypeSelection: boolean = false;

    constructor(data: CarService_Dto = new CarService_Dto(null)) {
        super(new CarService_Dto(data));
        this.initForm();
        this.paymentInAdvanceCurrenciesDataInput = {
            form: this.form,
            type: 'currencies',
            formControlName: 'paymentInAdvanceCurrency',
            isRequired: true,
            label: 'Moneda',
          isReadOnly:false
        };
        this.estimatedCostCurrenciesDataInput = {
            form: this.form,
            type: 'currencies',
            formControlName: 'priceEstimateCurrency',
            isRequired: true,
            label: 'Moneda',
          isReadOnly:false
        };
        this.estimatedDeadlineTypesInput = {
            form: this.form,
            type: 'estimatedDeadlineTypes',
            formControlName: 'estimatedDeadlineType',
            isRequired: true,
            label: null,
          isReadOnly:false
        };
        if (isValidString(this.model.carServiceEntryData.deadline) == false || isValidObject(this.model.carServiceEntryData.estimatedDeadlineType)) {
            this.shouldUseEstimatedDeadlineTypeSelection = true;
            this.form.addControl("deadlineNumber", new FormControl(this.model.carServiceEntryData.deadlineNumber, [Validators.required]));
            this.form.addControl("priceEstimateNumber", new FormControl(this.model.carServiceEntryData.priceEstimateNumber, [Validators.required]));
        } else {
            this.form.addControl("deadline", new FormControl(this.model.carServiceEntryData.deadline, [Validators.required]));
            this.form.addControl("priceEstimate", new FormControl(this.model.carServiceEntryData.priceEstimate, [Validators.required]));
        }
    }

    getModel(): CarService_Dto {
        if (this.shouldUseEstimatedDeadlineTypeSelection == false)
            this.model.carServiceEntryData.deadline = this.form.value.deadline;
        else {
            this.model.carServiceEntryData.deadlineNumber = this.form.value.deadlineNumber;
            this.model.carServiceEntryData.estimatedDeadlineType = this.form.value.estimatedDeadlineType != null ? this.form.value.estimatedDeadlineType : null;
            if (isValidNumber(this.model.carServiceEntryData.deadlineNumber))
                this.model.carServiceEntryData.deadline = this.model.carServiceEntryData.deadlineNumber + ' ' + (this.model.carServiceEntryData.estimatedDeadlineType != null ? this.model.carServiceEntryData.estimatedDeadlineType.name : '');
        }
        if (this.shouldUseEstimatedDeadlineTypeSelection == false)
            this.model.carServiceEntryData.priceEstimate = this.form.value.priceEstimate;
        else {
            this.model.carServiceEntryData.priceEstimateNumber = this.form.value.priceEstimateNumber;
            this.model.carServiceEntryData.priceEstimateCurrency = this.form.value.priceEstimateCurrency != null ? this.form.value.priceEstimateCurrency.name : null;
            if (isValidNumber(this.model.carServiceEntryData.priceEstimateNumber))
                this.model.carServiceEntryData.priceEstimate = this.model.carServiceEntryData.priceEstimateNumber + ' ' + this.model.carServiceEntryData.priceEstimateCurrency;
        }
        this.model.carServiceEntryData.paymentInAdvance = this.form.value.paymentInAdvance || 0;
        this.model.carServiceEntryData.paymentInAdvanceCurrency = this.form.value.paymentInAdvanceCurrency != null ? this.form.value.paymentInAdvanceCurrency.name : null;

        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
            paymentInAdvance: new FormControl(this.model.carServiceEntryData.paymentInAdvance, Validators.compose([Validators.min(0)])),
            appointmentDetails: new FormControl(this.model.carServiceEntryData.appointmentDetails, Validators.compose([Validators.min(0)]))

        });
    }
}
