import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CarSearchSandbox} from './car-search.sandbox';
import {BaseWebComponent} from 'src/app/core/shared/base/base.component';
import {SearchCarActionProxy} from './search-car.action-proxy';
import {IdName_Dto} from 'src/app/core/app-dto/misc.dto';
import {Car_Dto} from 'src/app/core/app-dto/car.dto';
import {SearchComponent} from 'src/app/core/shared/components/search/search.component';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {isValidArrayAndHasElements, isValidObject, isValidString} from "../../../core/shared/helpers/common.helpers";
import * as escapeStringRegexp from "escape-string-regexp";
import {GetCarActionProxy} from "../car-details/get-car.action-proxy";

@Component({
  selector: 'app-car-search',
  templateUrl: './car-search.component.html',
  styleUrls: ['./car-search.component.scss']
})
export class CarSearchComponent extends BaseWebComponent implements OnInit, OnDestroy {
  public searchCarAP: SearchCarActionProxy;
  public getCarAP: GetCarActionProxy;

  public items: Array<IdName_Dto> = [];
  public isLoading: boolean = false;

  public selectedItemValue: IdName_Dto = null;

  @Output() itemSelected = new EventEmitter<Car_Dto>();
  @Output() inputValue = new EventEmitter<string>();

  @ViewChild(SearchComponent, {static: false}) searchComponent: SearchComponent;

  @Input() error: boolean = false;
  @Input() allowInput: boolean = false;

  @Input('search') set searchValue(value: string) {
    if (ObjectValidators.isValidString(value)) {
      this.search(value);
      this.selectedItemValue = new IdName_Dto({id: undefined, name: value});
    }
  }

  @Input('carId') set carIdValue(value: string) {
    if (ObjectValidators.isValidString(value)) {
      this.getCarAP.execute(value, (data: Car_Dto) => {
        this.itemSelected.emit(data);
        this.selectedItemValue = new IdName_Dto({
          id: data.id,
          name: data.registrationNumber + " " + data.make + " " + data.model
        });
      });
    }
  }

  constructor(
    public sandbox: CarSearchSandbox
  ) {
    super(sandbox);
    this.searchCarAP = new SearchCarActionProxy(this, sandbox.appState);
    this.getCarAP = new GetCarActionProxy(this, sandbox.appState);
  }

  ngOnInit() {
    this.items = [];
    if (!isValidObject(this.selectedItemValue))
      this.search('');
    this.initialize((data: Array<IdName_Dto>) => {
        this.items = data;
        if (isValidObject(this.selectedItemValue) && this.selectedItemValue.id == "input") {
          // if (isValidArrayAndHasElements(data)) {
          //   this.selectedItemValue = data[0];
          //   let item = this.sandbox.originalCarSearchList.find(r => r.id == this.selectedItemValue.id);
          //   if (this.itemSelected != null) {
          //     this.itemSelected.emit(item);
          //   }
          //   else {
          //     this.itemSelected.emit(null);
          //   }
          // }

        }
      this.dataLoaded = true;
      }
    );
  }

  ngOnDestroy() {
    this.destroy();
  }

  public search(value: string) {
    this.isLoading = true;
    if (isValidString(value)) {
      value = escapeStringRegexp(value.trim())
    }
    this.searchCarAP.execute(value, () => {
      this.isLoading = false;
    });
  }

  public selected(value: IdName_Dto) {
    if (ObjectValidators.isValidObject(value)) {
      let data = this.sandbox.originalCarSearchList.find(r => r.id == value.id);
      if (this.itemSelected != null) {
        if(isValidObject(data)==false )data = new Car_Dto({registrationNumber : value.name});
        this.itemSelected.emit(data);
      }
    } else {
      if (this.itemSelected != null) {
        this.itemSelected.emit(null);
        this.search('');
      }
    }
  }

  clearSelect() {
    this.searchComponent.clearSelect();
  }

  inputChanged(value: string) {
    if (this.inputValue != null) {
      this.inputValue.emit(value);
    }
  }
}
