import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseListWebComponent} from "../../../core/shared/base/base.component";
import {GetCarServiceListDashboardActionProxy} from "../car-service-list-dashboard/get-car-service-list-dashboard.action-proxy";
import {Subscription} from "rxjs";
import {CarServiceListDashboardSandbox} from "../car-service-list-dashboard/car-service-list-dashboard.sandbox";
import {ActionsSubject} from "@ngrx/store";
import {ConfigService} from "../../../core/core/app-config.service";
import {BsModalService} from "ngx-bootstrap";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {ResetSection, SeamlessEvent} from "../../../core/app-store/events/base.events";
import {SearchEvent} from "../../../core/app-store/events";
import {AddCarInServiceComponent} from "../add-car-in-service/add-car-in-service.component";
import {CarService_Dto} from "../../../core/app-dto/car-service.dto";
import {AddEditCarInServiceWizardComponent} from "../add-edit-car-in-service-wizard/add-edit-car-in-service-wizard.component";

@Component({
  selector: 'app-open-add-car-in-service',
  templateUrl: './open-add-car-in-service.component.html',
  styleUrls: ['./open-add-car-in-service.component.scss']
})
export class OpenAddCarInServiceComponent implements OnInit, AfterViewInit {

  constructor(
    private configService: ConfigService,
    private modalService: BsModalService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
  ) {
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id !== undefined) {
        let carId = params.id;
        this.router.navigate(['car-service','cars', 'all']);
        const initialState = {
          title: 'Adauga masina in service'
        };
        const modalRef = this.modalService.show(AddEditCarInServiceWizardComponent, {
          initialState,
          backdrop: 'static',
          keyboard: false,
          class: 'modal-xl'
        });
      }
    });

  }
}
