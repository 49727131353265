import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { CarAppointment_Dto } from '../../../app-dto/car-appointment.dto';
import { AddEditCalendarEventWizardComponent } from '../../../../calendar/components/add-edit-calendar-event-wizard/add-edit-calendar-event-wizard.component';
import { AddEditCarInServiceWizardComponent } from '../../../../car-service/components/add-edit-car-in-service-wizard/add-edit-car-in-service-wizard.component';
import { CarService_Dto } from '../../../app-dto/car-service.dto';

@Component({
  selector: 'app-calendar-shortcut-add-resources',
  templateUrl: './calendar-shortcut-add-resources.component.html',
  styleUrls: ['./calendar-shortcut-add-resources.component.scss']
})
export class CalendarShortcutAddResourcesComponent implements OnInit {

  public isActive: boolean = false;

  constructor(private modalService: BsModalService) {}

  ngOnInit() {

  }

  activateOptions() {
    this.isActive = !this.isActive;
  }

  addCalendarEvent() {
    this.isActive = false;
    const event = new CarAppointment_Dto(null);
    event.startDate = new Date();
    event.endDate = new Date();
    const initialState = {
      event,
      title: 'Adauga programare'
    };
    const modalRef = this.modalService.show(AddEditCalendarEventWizardComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
  }

  addCarInService() {
    this.isActive = false;
    const initialState = {
      carInService: new CarService_Dto(null),
      title: 'Adauga Masina in Service'
    };
    const modalRef = this.modalService.show(AddEditCarInServiceWizardComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
  }
}
