import {EventWithScreenLoading, SeamlessEvent, UIGeneratedEvent,} from './base.events';
import {ActiveDataTableParameters} from '../../shared/datatable/datatable.helpers';
import {ServicePlatform_Dto} from '../../app-dto/service-platform.dto';
import {CalendarEventRelatedEntityType} from "../../app-dto/calendar-event.dto";

export class GetServicePlatformsPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetServicePlatformsPagedResultEvent";

  constructor(public parameters: ActiveDataTableParameters, public withReset: boolean = false) {
    super();
    this.type += GetServicePlatformsPagedResultEvent.identifier;
  }
}

export class GetServicePlatformsListEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetServicePlatformsListEvent";

  constructor() {
    super();
    this.type += GetServicePlatformsListEvent.identifier;
  }
}

export class RegisterServicePlatformEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "RegisterServicePlatformEvent";

  constructor(public model: ServicePlatform_Dto) {
    super();
    this.type += RegisterServicePlatformEvent.identifier;
  }
}

export class EditServicePlatformEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "EditServicePlatformEvent";

  constructor(public model: ServicePlatform_Dto) {
    super();
    this.type += EditServicePlatformEvent.identifier;
  }
}

export class GetServicePlatformEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetServicePlatformEvent";

  constructor(public id: string) {
    super();
    this.type += GetServicePlatformEvent.identifier;
  }
}

export class DeleteServicePlatformEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeleteServicePlatformEvent";

  constructor(public id: string, public callback: Function = null) {
    super();
    this.type += DeleteServicePlatformEvent.identifier;
  }
}

export class ActivateServicePlatformEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "ActivateServicePlatformEvent";

  constructor(public id: string) {
    super();
    this.type += ActivateServicePlatformEvent.identifier;
  }
}

export class DeactivateServicePlatformEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeactivateServicePlatformEvent";

  constructor(public id: string) {
    super();
    this.type += DeactivateServicePlatformEvent.identifier;
  }
}

export class GetServicePlatformAvailabilityEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetServicePlatformAvailabilityEvent";

  constructor(public startDate: Date, public endDate: Date, public servicePlatformId: string = null, public except: string = null, public relatedEntityType:CalendarEventRelatedEntityType, public callback: (data: boolean) => void) {
    super();
    this.type += GetServicePlatformAvailabilityEvent.identifier;
  }
}
