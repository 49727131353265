import { Component, OnInit } from '@angular/core';
import {InvoiceInput, InvoiceRequestType} from "../invoice/invoice.component";
import {ActivatedRoute, Params} from "@angular/router";

@Component({
  selector: 'app-sub-invoice-overview-organziation',
  templateUrl: './sub-invoice-overview-organziation.component.html',
  styleUrls: ['./sub-invoice-overview-organziation.component.scss']
})
export class SubInvoiceOverviewOrganziationComponent  implements OnInit {

  public invoiceInput: InvoiceInput = null;
  public dataLoaded: boolean = false;

  constructor(
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.organizationId !== undefined && params.invoiceId !== undefined) {
        let data = new InvoiceInput();
        data.type = InvoiceRequestType.SubscriptionOrganization;
        data.invoiceId = params.invoiceId;
        data.organizationId = params.organizationId;
        this.invoiceInput = data;

        this.dataLoaded = true;
      }
    });
  }

}

