import {Component, OnInit} from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import {
  BaseListWebComponentWithSearchAndActiveBehavior
} from 'src/app/core/shared/base/base.component';
import { ConfigService } from 'src/app/core/core/app-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import {  ResetSection } from 'src/app/core/app-store/events/base.events';
import {
  ActiveDataTableParameters,
  DataTableParametersOrderEntity
} from 'src/app/core/shared/datatable/datatable.helpers';
import { GetSuppliesActionProxy } from './get-supplies.action-proxy';
import { SuppliesListSandbox } from './supplies-list.sandbox';
import { ActivateSupplyActionProxy } from './activate-supply.action-proxy';
import { DeactivateSupplyActionProxy } from './deactivate-supply.action-proxy';
import { DeleteSupplyActionProxy } from './delete-supply.action-proxy';
import { Supply_Dto } from 'src/app/core/app-dto/supply.dto';
import {ConfirmationDialogService} from '../../../core/core/dialog/confirmation-dialog.service';
import { ObjectValidators } from '../../../core/shared/object.validators';
import {IdName_Dto} from "../../../core/app-dto/misc.dto";


@Component({
  selector: 'app-supplies-list',
  templateUrl: './supplies-list.component.html',
  styleUrls: ['./supplies-list.component.scss']
})
export class SuppliesListComponent extends BaseListWebComponentWithSearchAndActiveBehavior implements OnInit {
  public getSuppliesAP: GetSuppliesActionProxy;
  public activateSupplyAP: ActivateSupplyActionProxy;
  public deactivateSupplyAP: DeactivateSupplyActionProxy;
  public deleteSupplyAP: DeleteSupplyActionProxy;
  public isStorno = false;
  public title: string;
  public objectName: string;

  public columns: any = [];
  public sortColumns: Array<IdName_Dto> = [];
  public currentPage = 1;

  constructor(
    public sandbox: SuppliesListSandbox,
    actionsSubj: ActionsSubject,
    private configService: ConfigService,
    public router: Router, public confirmationDialogService: ConfirmationDialogService,
    public activatedRoute: ActivatedRoute
  ) {
    super(sandbox, actionsSubj, ResetSection.Supplies);
    this.getSuppliesAP = new GetSuppliesActionProxy(this, sandbox.appState);
    this.activateSupplyAP = new ActivateSupplyActionProxy(this, sandbox.appState);
    this.deactivateSupplyAP = new DeactivateSupplyActionProxy(this, sandbox.appState);
    this.deleteSupplyAP = new DeleteSupplyActionProxy(this, sandbox.appState);

    this.activatedRoute.queryParams
        .subscribe((params: any) => {
          if (ObjectValidators.isValidObject(params) && ObjectValidators.isValidObject(params.new) != null) {
            this.isStorno = params.isStorno === 'true';
            this.ngOnInit();
          }
        });
    this.columns = [
      { data: 'receptionSeries', width: '20%', name : 'Serie receptie' },
      { data: 'invoiceNumber', width: '10%', name: 'Numar factura' },
      { data: 'supplier', width: '20%', name:'Furnizor' },
      { data: 'totalWithoutVat', width: '10%', name:'Total fara TVA' },
      { data: 'totalWithVat', width: '10%', name:'Total cu TVA' },
      { data: 'currency', width: '10%' },
      { data: 'supplyDate', width: '215', name:'Data receptie' },
      { data: 'action', width: '50px', orderable: false },
      { data: 'createdOn', width: '0', orderable: false }
    ];
    this.sortColumns = this.columns.filter(c => c.name).map(c => ({id: c.data, name: c.name} as IdName_Dto));
  }

  ngAfterViewInit() {

  }

  ngOnInit() {
    this.title = this.isStorno ? 'Stornari' : 'Receptii';
    this.objectName = this.isStorno ? 'stornare' : 'receptie';
    this.initialize();
    this.setBreadcrumbAP.execute([{text: 'Acasa', url: '/dashboard'}, {text: this.title}]);

    this.dtOptions = null;
    setTimeout(() => {
      this.dtOptions = {
        responsive: true,
        paging: false,
        info: false,
        lengthChange: false,
        searching: false,
        pageLength: this.configService.getDefaultPageSize(),
        scrollX: false,
        serverSide: true,
        processing: false,
        ordering: false,
        autoWidth: false,
        ajax: (dataTablesParameters: ActiveDataTableParameters, callback) => {
          dataTablesParameters.active = this.isActive;
          dataTablesParameters.search.value = this.searchValue;
          this.doGetAll(new ActiveDataTableParameters(dataTablesParameters), callback, true);
        },
        columns: this.columns,
        order: [[8, 'desc']],
        dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
      };
    });
  }

  public doGetAll(dataTablesParameters: ActiveDataTableParameters, callback: any, withReset: boolean) {
    this.sandbox.callback = callback;
    this.sandbox.dataTableParameters = dataTablesParameters;
    if (withReset) {
      setTimeout(() => {this.currentPage = 1; }, 0);
      this.sandbox.dataTableParameters.pageNumber = 1;
      dataTablesParameters.pageNumber = 1;
    }
    this.getSuppliesAP.execute(dataTablesParameters, withReset, this.isStorno);
  }

  deleteSupply(supply: Supply_Dto) {
    this.confirmationDialogService.confirm(
      'Stergere ' + this.objectName,
      "Urmeaza sa stergeti " + this.objectName + " <b>" + supply.number + "</b>. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        this.deleteSupplyAP.execute(supply.id);
      }
    });
  }
  onSortChange($event) {
    if ($event) {
      const columnId = this.columns.map((e) => e.data).indexOf($event.id);
      const orderEntity = new DataTableParametersOrderEntity({column: columnId, dir: 'asc'});
      this.sandbox.dataTableParameters.order = [orderEntity];
    }
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, true);
  }

  onPageChange(currentPage: number) {
    this.currentPage = currentPage;
    this.sandbox.dataTableParameters.pageNumber = currentPage;
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
  }

  add(){
    this.router.navigate(['/supply/register'],{queryParams : {forStorno : this.isStorno}});
  }
}
