import { Component, OnDestroy, OnInit, ViewChild, NgZone, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseWebComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { AddCarInServiceActionProxy } from './add-car-in-service.action-proxy';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { AddCarInServiceSandbox } from './add-car-in-service.sandbox';
import { CarService_Dto, CarServiceEntryData_Dto, ClientCarParts_Dto } from 'src/app/core/app-dto/car-service.dto';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';
import { WizardComponent } from 'angular-archwizard';
import { Car_Dto } from 'src/app/core/app-dto/car.dto';
import { CarFormComponent } from 'src/app/car/components/car-form/car-form.component';
import { SaveDraftActionProxy } from '../../common/di/save-draft.action-proxy';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { OrganizationClient_Dto } from 'src/app/core/app-dto/organization-client.dto';
import { OrganizationClientFormComponent } from 'src/app/organization-client/components/organization-client-form/organization-client-form.component';
import { CarServiceDataFormComponent } from '../car-service-data-form/car-service-data-form.component';
import { FileAttachment, FileToUpload, FileUploadTarget } from 'src/app/core/app-dto/core.dto';
import { CarServiceSearchComponent } from '../car-service-search/car-service-search.component';
import { FileWrapper } from 'src/app/core/upload/components/upload-notification/upload-notification.di';
import { CarServicePhotosComponent, FileImageWrapper } from '../car-service-photos/car-service-photos.component';
import { UploadInput } from 'src/app/core/upload/components/upload-notification/upload-notification.component';
import { AddEditCalendarEventDI } from "../../../calendar/components/add-edit-calendar-event/add-edit-calendar-event.di";

@Component({
  selector: 'app-add-car-in-service',
  templateUrl: './add-car-in-service.component.html',
  styleUrls: ['./add-car-in-service.component.scss']
})
export class AddCarInServiceComponent extends BaseWebComponent implements OnInit, OnDestroy {
  public addCarInServiceAP: AddCarInServiceActionProxy;
  public saveDraftAP: SaveDraftActionProxy;

  public model: CarService_Dto;
  public files: Array<FileWrapper>;
  public imgUrls: Array<FileImageWrapper> = [];

  public initialStep: number = 0;
  public step: number = 0;
  public stepMin: number = 0;
  public stepMax: number = 6;

  public isCarDetailsValid: boolean = true;
  public isClientDetailsValid: boolean = true;
  public isEntryDataValid: boolean = true;

  public formSubmitted: boolean = false;
  public event: string = null;

  @Input("defaultCarId") defaultCarId: string;

  @ViewChild(WizardComponent, { static: true })
  public wizard: WizardComponent;

  @ViewChild(CarFormComponent, { static: false })
  private carFormComponent: CarFormComponent;

  @ViewChild(OrganizationClientFormComponent, { static: false })
  private clientFormComponent: OrganizationClientFormComponent;

  @ViewChild(CarServiceDataFormComponent, { static: false })
  private carServiceDataFormComponent: CarServiceDataFormComponent;

  @ViewChild(CarServiceSearchComponent, { static: false }) carServiceSearchComponent: CarServiceSearchComponent;

  @ViewChild(CarServicePhotosComponent, { static: false }) carServicePhotosComponent: CarServicePhotosComponent;

  public onClose: Subject<boolean>;


  constructor(
    public appState: Store<store.State>,
    public sandbox: AddCarInServiceSandbox,
    public activatedRoute: ActivatedRoute,
    private activeModal: BsModalRef,
    private router: Router
  ) {
    super(sandbox);

    this.addCarInServiceAP = new AddCarInServiceActionProxy(this, sandbox.appState);
    this.saveDraftAP = new SaveDraftActionProxy(this, sandbox.appState);
    this.model = new CarService_Dto(null);
  }

  ngOnInit() {
    this.onClose = new Subject();
    this.initialize();

    this.dataLoaded = true;
    if (this.initialStep > 0) {
      this.step = this.initialStep;
      this.wizard.goToStep(this.initialStep);
    }

  }

  ngOnDestroy() {
    this.destroy();
  }

  public uploadInput: UploadInput;
  public saveForm() {
    this.formSubmitted = true;

    this.carFormComponent.accept();
    this.clientFormComponent.accept();
    this.carServiceDataFormComponent.accept();
    this.updateValidation();

    if (this.isCarDetailsValid && this.isClientDetailsValid && this.isEntryDataValid) {
      this.addCarInServiceAP.execute(this.model, this.event, (data: CarService_Dto) => {
        console.log("uploadCompleted " + NgZone.isInAngularZone());
        if (this.files != null && this.files.length > 0) {
          var timestamp = new Date().getTime();
          var filesToUpload = new Array<FileToUpload>();

          for (var i = 0; i < this.files.length; i++) {
            this.files[i].id = undefined;
            timestamp = timestamp + 1;
            filesToUpload.push(new FileToUpload(timestamp.toString(), this.files[i].file, FileUploadTarget.InitialCarServiceEntry, data.id));
          }
          if (filesToUpload.length > 0) {
            this.sandbox.dispatchUploadInputEvent(new UploadInput(filesToUpload, FileUploadTarget.InitialCarServiceEntry));
          }
        }
        this.onClose.next(true);
        this.activeModal.hide();
        this.router.navigate(['/car-service/cars/all']);
      });

    } else {
      $(".modal").animate({ scrollTop: 0 }, "slow");
    }
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
    this.router.navigate(['/car-service/cars/all']);
  }

  updateValidation() {
    if (this.formSubmitted) {
      this.isCarDetailsValid = this.carFormComponent.isValid();
      this.isClientDetailsValid = this.clientFormComponent.isValid();
      this.isEntryDataValid = this.carServiceDataFormComponent.isValid();
      this.carFormComponent.markFormAsDirtyAndTouched();
      this.clientFormComponent.markFormAsDirtyAndTouched();
      this.carServiceDataFormComponent.markFormAsDirtyAndTouched();
    }
    if (this.step == 0)
      this.clearSearch();
  }

  updateModel() {
    this.model.car = this.carFormComponent.di.getModel();
    this.model.client = this.clientFormComponent.di.getModel();
    this.model.carServiceEntryData = this.carServiceDataFormComponent.di.getModel();
    this.files = this.carServicePhotosComponent.di.getModel();
    this.imgUrls = this.carServicePhotosComponent.getImgUrl();

    if (this.formSubmitted)
      this.carServiceDataFormComponent.markFormAsDirtyAndTouched();
    this.model = new CarService_Dto(this.model);
  }

  goToNextStep() {
    this.step++;
    this.wizard.goToNextStep();
    this.updateValidation();
    this.updateModel();
  }

  goToPreviousStep() {
    this.step--;
    this.wizard.goToPreviousStep();
    this.updateValidation();
    this.updateModel();

  }

  goToStep(index: number) {
    this.step = index;
    this.wizard.goToStep(index);
    this.updateValidation();
    this.updateModel();

  }

  carDetailsFormSaved(data: Car_Dto) {
    if (ObjectValidators.isValidObject(data))
      this.model.car = data;
  }

  clientDetailsFormSaved(data: OrganizationClient_Dto) {
    if (ObjectValidators.isValidObject(data))
      this.model.client = data;
  }

  carServiceEntryDataFormSaved(data: CarServiceEntryData_Dto) {
    if (ObjectValidators.isValidObject(data)) {
      this.model.carServiceEntryData = data;
    }
  }

  initialFilesSaved(data: Array<FileAttachment>) {
    if (ObjectValidators.isValidArray(data))
      this.model.initialFiles = data;
  }

  clientCarPartsSaved(data: Array<ClientCarParts_Dto>) {
    if (ObjectValidators.isValidArray(data))
      this.model.clientCarParts = data;
  }

  modelSelected(value: CarService_Dto) {
    this.model = new CarService_Dto(value);
  }

  eventSelected(value: string) {
    this.event = value;
  }

  clearSearch() {
    this.carServiceSearchComponent.clearSearch();
  }

  saveDraft() {
    this.model.car = this.carFormComponent.di.getModel();
    this.model.client = this.clientFormComponent.di.getModel();
    this.model.carServiceEntryData = this.carServiceDataFormComponent.di.getModel();
    this.saveDraftAP.execute(this.model, this.step);
    this.activeModal.hide();
  }
}
