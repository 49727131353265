import { PagedResult } from "../../shared/datatable/datatable.helpers";
import * as escapeStringRegexp from "escape-string-regexp";

export class ApiIntegrationHelper {

  public static toPagedResult<T>(arr: Array<T>, defaultPageSize: number): PagedResult<T> {
    let result = new PagedResult<T>();
    if (arr != null && arr !== undefined) {
      result.items = arr;
      result.totalItems = arr.length;
      result.currentPage = 1;
      result.totalPages = Math.ceil(arr.length / defaultPageSize);
    }
    return result;
  }
}


export function cleanSearchValue(search: string): string {
  if (search != null && search != undefined && search.trim() != '')
    return escapeStringRegexp(search.trim());
  else
    return "";
}