import { ObjectValidators } from '../shared/object.validators';
import { Base_Dto, BaseCRUDRules, IdName_Dto } from './misc.dto';
import { Department_Dto } from './department.dto';

export class CarWorkRules extends BaseCRUDRules {
  constructor(data: any) {
    super(data);
  }
}

export class CarWork_Dto extends Base_Dto {
  public description: string;
  public make: string;
  public model: string;
  public year: number;
  public power: number;
  public fuel: IdName_Dto[];
  public cilindricalCapacity: number;
  public department: Department_Dto;
  public duration: number;
  public value: number;
  public valueHasVAT: boolean;
  public valueWithVAT: number;
  public curreny: string;
  public isIndependentOfCarType: boolean;
  public rules: CarWorkRules;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.description = data.description;
      this.make = data.make;
      this.model = data.model;
      this.year = data.year;
      this.power = data.power;
      this.fuel = data.fuel;
      this.cilindricalCapacity = data.cilindricalCapacity;
      this.department = new Department_Dto(data.department);
      this.duration = data.duration;
      this.value = data.value;
      this.valueHasVAT = data.valueHasVAT;
      this.valueWithVAT = data.valueWithVAT;
      this.curreny = data.curreny;
      this.isIndependentOfCarType = data.isIndependentOfCarType;
      this.rules = new CarWorkRules(data.rules);
    }
  }
}


export class AppointmentCarWorks_Dto extends Base_Dto {
  public details: string;
  public carWorks : Array<CarWork_Dto>;

  constructor(appointmentCarWorks: AppointmentCarWorks_Dto) {
    super(appointmentCarWorks);

    if (ObjectValidators.isValidObject(appointmentCarWorks)) {
      this.details = appointmentCarWorks.details;
      this.carWorks = appointmentCarWorks.carWorks;
    }
  }
}


