import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NotificationsSandbox} from "./notifications.sandbox";
import {BaseWebComponent} from "../../../shared/base/base.component";
import {ResetSection} from "../../../app-store/events/base.events";
import {NotificationDI} from "./notification.di";
import {NotificationDataTableParameters} from "../../../app-store/events/notification.events";
import {ConfigService} from "../../app-config.service";
import {DeleteNotificationForUserActionProxy} from "./delete-notification-for-user.action-proxy";
import {MarkNotificationAsNotReadActionProxy} from "./mark-notification-as-not-read.action-proxy";
import {MarkNotificationAsReadActionProxy} from "./mark-notification-as-read.action-proxy";
import {GetItemsActionProxy} from "./get-items.action-proxy";
import {AppNotification} from "../../../app-dto/core.dto";
import {isValidArrayAndHasElements} from "../../../shared/helpers/common.helpers";
import {InfiniteScrollDirective} from "ngx-infinite-scroll";
import {GetUnreadNotificationsCountActionProxy} from "./get-unread-notifications-count.action-proxy";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent extends BaseWebComponent implements OnInit, OnDestroy {

  public unreadOnly: boolean;
  public params: NotificationDataTableParameters = NotificationDataTableParameters.getDefault();
  public getNoOfUnreadNotificationsAP : GetUnreadNotificationsCountActionProxy;

  constructor(public sandbox: NotificationsSandbox, public configService: ConfigService) {
    super(sandbox, ResetSection.Notifications);
    this.params.pageSize = this.configService.getDefaultPageSize();
    this.getNoOfUnreadNotificationsAP = new GetUnreadNotificationsCountActionProxy(this, sandbox.appState);
  }
  @ViewChild(InfiniteScrollDirective, {static:false}) infiniteScroll: InfiniteScrollDirective;


  ngOnInit() {
    this.getItems(true);
    this.getNoOfUnreadNotificationsAP.execute();
    this.sandbox.initialize();
  }

  ngOnDestroy() {
    this.destroy();
  }

  remove(data: NotificationDI) {
    new DeleteNotificationForUserActionProxy(this, this.sandbox.appState).execute(data.getModel());
  }

  toggleState(data: NotificationDI) {
    if (data.model.isRead)
      new MarkNotificationAsNotReadActionProxy(this, this.sandbox.appState).execute(data.getModel());
    else new MarkNotificationAsReadActionProxy(this, this.sandbox.appState).execute(data.getModel());
  }

  public isOpen: boolean = false;

  toggleNotification(e: any) {
    this.isOpen = !this.isOpen;
    if (this.isOpen == true) {
      this.infiniteScroll.ngOnDestroy();
      this.infiniteScroll.setup();
      this.isExecuting=false;
      this.getItems(true);

      $('body').attr("style", "overflow:hidden");
    }
    else{
      $('body').attr('style', 'overflow:auto');
    }
    $(".sidebar-background").toggleClass('active');
    $(".sidebar").toggleClass('active');

  }


  loadNextPage() {
    this.getItems(false);
  }

  onlyUnreadNotifications(event: any) {
    this.unreadOnly = !this.unreadOnly;
    this.params.onlyNotRead = this.unreadOnly;
    this.getItems(true);
  }

  public isExecuting: boolean = false;
  public hasMoreItems: boolean = true;


  getItems(withReset: boolean) {
    if (this.isExecuting==false && this.isOpen) {
      if (withReset) {
        this.hasMoreItems = true;
        this.params.pageNumber = 1;
      }else{
        this.params.pageNumber++;
      }

      if (this.hasMoreItems) {
        this.isExecuting = true;

        new GetItemsActionProxy(this, this.sandbox.appState).execute(this.params, (result: Array<AppNotification>) => {
          this.hasMoreItems = isValidArrayAndHasElements(result);
          this.isExecuting = false;
        }, withReset);
      }
    }
  }

}
