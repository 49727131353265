import {Base_Dto, BaseCRUDRules, BaseLocationEntity, BusinessRule, IdName_Dto} from "./misc.dto";
import {ObjectValidators} from "../shared/object.validators";
import {AppConstants} from "./app.constants";
import {StringHelpers} from "../shared/string.helpers";
import * as moment from 'moment';
import {Organization} from './organization.dto';
import {isValidArrayAndHasElements, isValidObject} from "../shared/helpers/common.helpers";


export class AppRole_Dto extends Base_Dto {
    public name: string;
    public displayAs: string;
    public description: string;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.displayAs = data.displayAs;
            this.description = data.description;
            this.name = data.name;
        }
    }

    public static isSuperAdminRole(roleName: string) {
        return roleName.replace(' ', '').toLowerCase() == AppConstants.SUPER_ADMIN_ROLE_NAME;
    }

    public static isCountryAdminRole(roleName: string) {
        return roleName.replace(' ', '').toLowerCase() == AppConstants.COUNTRY_ADMIN_ROLE_NAME;
    }
}

export class UserHistory_Dto {
    public startDate: Date;
    public endDate: Date;
    public salary: number;
    public currency: IdName_Dto;

    constructor(data: any) {
        if (ObjectValidators.isValidObject(data)) {
            if (ObjectValidators.isValidObject(data.startDate))
                this.startDate = moment.utc(data.startDate).toDate();
            if (ObjectValidators.isValidObject(data.endDate))
                this.endDate = moment.utc(data.endDate).toDate();
            this.salary = data.salary;
            this.currency = data.currency;
        }
    }
}

export class User_Dto extends Base_Dto {
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public email: string;
    public password?: string;
    public confirmPassword?: string;
    public phoneNumber: string;
    public roles: Array<AppRole_Dto>;
    public address: Address;
    public organizationId: string;
    public organizationName: string;
    public displayImageUrl: string;
    public rules: UserRules;
    public isInitialSetupDone: boolean;

    public isActive: boolean;
    public isOwner: boolean;

    public roleId: string;
    public role: AppRole_Dto;

    public createdOn: Date;
    public updatedOn: Date;

    public salary: number = 0;
    public currency: string;

    public startDate: Date;
    public history: Array<UserHistory_Dto>;
    public userMechanicLink: UserMechanicLink_Dto;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.isActive = data.isActive;
            this.isOwner = data.isOwner
            this.rules = new UserRules(data.rules);
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.email = data.email;
            this.salary = data.salary;
            this.currency = data.currency;
            this.displayImageUrl = data.displayImageUrl;
            this.phoneNumber = data.phoneNumber;
            this.organizationId = data.organizationId;
            this.organizationName = data.organizationName;
            this.isInitialSetupDone = data.isInitialSetupDone;
            if (ObjectValidators.isValidObject(data.role)) {
                this.role = new AppRole_Dto(data.role);
                this.roleId = this.role.id;
            }
            if (ObjectValidators.isValidObject(data.address)) {
                this.address = new Address(data.address);
            } else this.address = new Address();

            if (ObjectValidators.isValidObject(data.createdOn))
                this.createdOn = moment.utc(data.createdOn).toDate();
            if (ObjectValidators.isValidObject(data.updatedOn))
                this.updatedOn = moment.utc(data.updatedOn).toDate();

            if (ObjectValidators.isValidObject(data.startDate))
                this.startDate = moment.utc(data.startDate).toDate();
            if (ObjectValidators.isValidArray(data.history))
                this.history = data.history.map(r => new UserHistory_Dto(r));
            else
                this.history = [];
            if (isValidObject(data.userMechanicLink)) {
                this.userMechanicLink = new UserMechanicLink_Dto(data.userMechanicLink);
            }
        } else {
            this.address = new Address();
        }
    }


    public isLoaded() {
        if (ObjectValidators.isValidString(this.id))
            return true;
        return false;
    }

    public getFullName() {
        if (ObjectValidators.isValidString(this.middleName))
            return this.firstName + ' ' + this.middleName + ' ' + this.lastName;
        else return this.firstName + ' ' + this.lastName;
    }
}

export class UserMechanicLink_Dto {
    public id: string;
    public userId: string;
    public mechanicId: string;

    constructor(data: any) {
        if (isValidObject(data)) {
            this.id = data.id;
            this.userId = data.userId;
            this.mechanicId = data.mechanicId;
        }
    }
}


export class UserTrace_Dto extends Base_Dto {
    public firstName: string;
    public lastName: string;
    public email: string;
    public imageUrl: string;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.email = data.email;
            this.imageUrl = data.imageUrl;
        }
    }
}


export class LoggedUser_Dto extends User_Dto {
    public static identifier: string = "LoggedUser";
    public token: string;
    public organization: Organization;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.token = data.token;
            if (isValidObject(data.organization))
                this.organization = new Organization(data.organization);
        } else {
            this.token = null;
            this.organization = new Organization(null);
        }
    }

    public static save(data: LoggedUser_Dto): void {
        localStorage.setItem('auth', JSON.stringify(data));
    }

    public static remove(): void {
        localStorage.setItem('auth', null);
    }

    public static load(): LoggedUser_Dto {
        var object = JSON.parse(localStorage.getItem("auth"));
        if (object != null) {
            return new LoggedUser_Dto(object);
        }
        return null;
    }

}

export class Country extends BaseLocationEntity {
    public regions: Array<BaseLocationEntity>;
    public center: GeoCoordinate;
    public isActive: boolean;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.code = data.code;
            this.isActive = data.isActive;
            if (ObjectValidators.isValidNonEmptyArray(data.regions)) {
                this.regions = data.regions.map(r => new BaseLocationEntity(r));
            } else
                this.regions = [];
            if (ObjectValidators.isValidObject(data.center)) {
                this.center = new GeoCoordinate(data.center);
            } else
                this.center = new GeoCoordinate(null);
        }
    }
}

export class Address {
    public address1: string;
    public address2: string;
    public zipCode: string;
    public country: BaseLocationEntity;
    public countryId: string;
    public region: BaseLocationEntity;
    public regionId: string;
    public city: string;
    public geoLocation: GeoCoordinate;
    public label: string;

    constructor(data?: any) {
        if (ObjectValidators.isValidObject(data)) {
            this.address1 = data.address1;
            this.address2 = data.address2;
            this.zipCode = data.zipCode;
            if (ObjectValidators.isValidObject(data.region)) {
                this.region = new BaseLocationEntity(data.region);
            } else
                this.region = new BaseLocationEntity();
            if (ObjectValidators.isValidObject(data.country)) {
                this.country = new BaseLocationEntity(data.country);
            } else
                this.country = new BaseLocationEntity();
            this.city = data.city;
            if (ObjectValidators.isValidObject(data.geoLocation)) {
                this.geoLocation = new GeoCoordinate(data.geoLocation);
            } else
                this.geoLocation = new GeoCoordinate(null);
            this.label = StringHelpers.computeAddress(this);
        } else {
            this.address1 = '';
            this.address2 = '';
            this.city = '';
            this.zipCode = '';
            this.countryId = '';
            this.country = new BaseLocationEntity();
            this.regionId = '';
            this.region = new BaseLocationEntity();
            this.label = '';
            this.geoLocation = new GeoCoordinate(null);
        }
    }

    public isEmpty(): boolean {
        return (ObjectValidators.isEmptyString(this.address1)) &&
            (ObjectValidators.isEmptyString(this.address2)) &&
            (ObjectValidators.isEmptyString(this.zipCode)) &&
            (ObjectValidators.isValidObject(this.region) && this.region.isEmpty()) &&
            (ObjectValidators.isValidObject(this.country) && this.country.isEmpty()) &&
            (ObjectValidators.isEmptyString(this.city));

    }
}

export class GeoCoordinate {
    public latitude: number;
    public longitude: number;

    constructor(data: GeoCoordinate) {
        if (ObjectValidators.isValidObject(data)) {
            this.latitude = data.latitude;
            this.longitude = data.longitude;
        }
    }
}

export class UserRules extends BaseCRUDRules {
    public canActivate: BusinessRule;
    public canDeactivate: BusinessRule;
    public canEditHistory: BusinessRule;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.canActivate = new BusinessRule(data.canActivate);
            this.canDeactivate = new BusinessRule(data.canDeactivate);
            this.canEditHistory = new BusinessRule(data.canEditHistory);
        }
        else {
            this.canActivate = new BusinessRule();
            this.canDeactivate = new BusinessRule();
            this.canEditHistory = new BusinessRule();
        }
    }
}

export enum FileUploadTarget {
    DataImport = 0,
    UserProfile = 1,
    Static = 2,
    Organization = 3,
    Invoice = 4,
    OrganizationProfile = 5,
    OrganizationCUI = 6,
    InitialCarServiceEntry = 7,
    CarServiceEntry = 8,
    CarServiceEntryGDPR = 9,
    CarServiceEntryInitialCommand = 10,
    CarServiceEntrySHDeclaration = 11,
    CarServiceEntryPV = 12,
    CarServiceEntryEstimate = 13,
    CarServiceEntryAction = 14,
    CarServiceEntryFinalInspection = 15,
    AsyncJob = 16,
    SupplyNote = 17,
    Offer=18
}

export class FileAttachment extends Base_Dto {
    public organizationId: string;
    public relatedEntityId: string;
    public fileName: string;
    public size: number;
    public url: string;
    public uploadTaskId: string;
    public type: FileUploadTarget;
    public createdOn: Date;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.organizationId = data.organizationId;
            this.relatedEntityId = data.relatedEntityId;
            this.fileName = data.fileName;
            this.size = data.size;
            this.url = data.url;
            this.uploadTaskId = data.uploadTaskId;
            this.type = data.type;
            if (ObjectValidators.isValidObject(data.createdOn))
                this.createdOn = moment.utc(data.createdOn).toDate();
        }
    }
}

export class FileToUpload {
    public uuId: string;

    constructor(public id: string, public file: File, public purpose: FileUploadTarget, public relatedEntityId: string = "") {
    }
}

export enum AsyncJobType {
    CalendarImport,
    CarPartsExport,
    CarPartsImport
}

export enum AsyncJobStatus {
    New,
    InProgress,
    Done,
    Error
}

export class AsyncJob extends Base_Dto {
    public type: AsyncJobType;
    public status: AsyncJobStatus;
    public observations: string;
    public organizationId: string;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.organizationId = data.organizationId;
            this.observations = data.observations;
            this.status = data.status;
            this.type = data.type;
        }
    }

}

export enum AppNotificationType {
    ExportSagaStarted = 1,
    ExportSageError = 2,
    ExportSagaSuccess = 3,
    CashRegisterActivated = 4,
    CashRegisterDeactivated = 5,
    CashRegisterError = 6,
    PrintJobStatusUpdate = 7
}

export enum AppNotificationRelatedEntityType {
    SagaExport = 1,
    CashRegister = 2,
    PrintJob = 3
}

export class AppNotificationFile {
    public id: string;
    public url: string;
    public name: string;

    constructor(data: any) {
        if (isValidObject(data)) {
            this.id = data.id;
            this.url = data.url;
            this.name = data.name;
        }
    }
}

export class AppNotificationRules extends BaseCRUDRules {
    public canMarkAsRead: BusinessRule;
    public canMarkAsNotRead: BusinessRule;

    constructor(data: any) {
        super(data);
        if (isValidObject(data)) {
            this.canMarkAsNotRead = new BusinessRule(data.canMarkAsNotRead);
            this.canMarkAsRead = new BusinessRule(data.canMarkAsRead);
        } else {
            this.canMarkAsNotRead = new BusinessRule();
            this.canMarkAsRead = new BusinessRule();
        }
    }
}

export class AppNotification extends Base_Dto {
    public text: string;
    public description: string;
    public title: string;
    public type: AppNotificationType;
    public relatedEntityId: string;
    public relatedEntityType: AppNotificationRelatedEntityType;
    public files: Array<AppNotificationFile>;
    public isRead: boolean;
    public rules: AppNotificationRules;

    constructor(data: any) {
        super(data);
        if (isValidObject(data)) {
            this.text = data.text;
            this.description = data.description;
            this.title = data.title;
            this.type = data.type;
            this.relatedEntityId = data.relatedEntityId;
            this.relatedEntityType = data.relatedEntityType;
            if (isValidArrayAndHasElements(this.files)) {
                this.files = data.files.map(r => new AppNotificationFile(r));
            }
            else this.files = [];
            this.isRead = data.isRead;
            this.rules = new AppNotificationRules(data.rules);
        }
    }
}

export class StickyHeaderNotification {
    public title: string;
    public description: string;
    public hasDismissOption: boolean = false;
    public type: StickyHeaderNotificationImportance;

    constructor(data) {
        if (isValidObject(data)) {
            this.title = data.title;
            this.description = data.description;
            this.hasDismissOption = data.hasDismissOption;
            this.type = data.type;
        }
    }
}

export enum StickyHeaderNotificationImportance {
    low,
    medium,
    high
}


