import {EventWithScreenLoading, UIGeneratedEvent} from './base.events';
import {CreateReceipt_Dto} from '../../app-dto/receipt.dto';
import {ReceiptDataTableParameters} from '../../shared/datatable/datatable.helpers';

export class DoGenerateReceiptEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'DoGenerateReceiptEvent';

    constructor(public model: CreateReceipt_Dto, public invoiceId: string) {
        super();
        this.type += DoGenerateReceiptEvent.identifier;
    }
}

export class GetOrganizationReceiptsPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'GetOrganizationReceiptsPagedResultEvent';

    constructor(public parameters: ReceiptDataTableParameters, public withReset: boolean = false) {
        super();
        this.type += GetOrganizationReceiptsPagedResultEvent.identifier;
    }
}

export class GetReceiptEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'GetReceiptEvent';

    constructor(public receiptId: string) {
        super();
        this.type += GetReceiptEvent.identifier;
    }
}

export class GetOrgReceiptDownloadLink extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'GetOrgReceiptDownloadLink';

    constructor(public receiptId: string, public callback: (link: string) => void) {
        super();
        this.type += GetOrgReceiptDownloadLink.identifier;
    }
}
