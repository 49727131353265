import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesComponent } from './components/files/files.component';
import { CoreModule } from '../core/core/core.module';
import { FilesSandbox } from './components/files/files.sandbox';
import { PhotosComponent } from './components/photos/photos.component';
import { PhotosSandbox } from './components/photos/photos.sandbox';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../core/shared/shared.module';

@NgModule({
  declarations: [FilesComponent, PhotosComponent],
    imports: [
        CommonModule,
        CoreModule,
        DataTablesModule,
        SharedModule
    ],
  providers: [
  ],
  exports: [
    FilesComponent,
    PhotosComponent
  ]
})
export class DocumentsModule { }
