import { ObjectValidators } from '../shared/object.validators';
import { Base_Dto } from './misc.dto';

export class AppointmentClient_Dto extends Base_Dto {
  public name: string;
  public phoneNumber: string;
  public clientId: string;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.name = data.name;
      this.phoneNumber = data.phoneNumber;
      this.clientId = data.clientId;
    }
  }
}
