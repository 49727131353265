import {Component, Input, OnInit} from '@angular/core';
import {Offer_Dto} from '../../../core/app-dto/offer.dto';
import {getFormattedIdNameList, isValidObject} from '../../../core/shared/helpers/common.helpers';
import {BsModalService} from 'ngx-bootstrap';
import {UpdateOfferCarDataComponent} from '../update-offer-car-data/update-offer-car-data.component';
import {Car_Dto} from '../../../core/app-dto/car.dto';

@Component({
    selector: 'app-offer-details-list-car-details',
    templateUrl: './offer-details-list-car-details.component.html',
    styleUrls: ['./offer-details-list-car-details.component.scss']
})
export class OfferDetailsListCarDetailsComponent implements OnInit {

    @Input() offer: Offer_Dto;
    @Input() isReadOnlyValue: boolean = false;

    constructor(private modalService: BsModalService) {
    }

    ngOnInit() {
    }

    editCar() {
        const modalRef = this.modalService.show(UpdateOfferCarDataComponent, {backdrop: 'static', keyboard: false, class: 'modal-xl'});
        modalRef.content.dataInput = this.offer;
    }

    formatFuels(fuelsList) {
        return getFormattedIdNameList(fuelsList);
    }


}

