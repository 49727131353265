import {Component, Input, OnInit} from '@angular/core';
import {ActionsSubject, Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {isValidObject, isValidString} from "../../../shared/helpers/common.helpers";
import {GetCarServiceEntryInvoiceActionProxy} from "./get-car-service-entry-invoice.action-proxy";
import * as store from "../../../app-store/index";
import {IWebComponent} from "../../../shared/base/base.component";
import {Invoice_Dto, InvoiceType} from "../../../app-dto/invoice.dto";
import {Router} from "@angular/router";
import {GenerateInvoiceForCarServiceEntry} from "../../../app-store/events/invoices.events";
import {GenerateInvoiceForCarEntryActionProxy} from "./generate-invoice-for-car-entry.action-proxy";

@Component({
  selector: 'app-car-service-entry-invoice-button',
  templateUrl: './car-service-entry-invoice-button.component.html',
  styleUrls: ['./car-service-entry-invoice-button.component.scss']
})
export class CarServiceEntryInvoiceButtonComponent implements IWebComponent, OnInit {

  private actionSubscription = new Subscription();
  private actionTypes: Array<string> = [];
  private carServiceEntryId: string = null;
  private organizationId: string = null;
  private getInvoiceAP: GetCarServiceEntryInvoiceActionProxy = null;
  private generateInvoiceAP: GenerateInvoiceForCarEntryActionProxy = null;
  private clientId: string = null;
  public currentInvoice: Invoice_Dto = null;
  public invoiceType: InvoiceType = null;
  public label: string = "Vizualizeaza factura";
  public canGenerateInvoice: boolean = false;
  public firstTimeDataLoaded: boolean = false;

  @Input("input") set input(data: CarServiceEntryInvoiceButtonInputComponent) {
    if (isValidObject(data)) {
      this.actionTypes = data.actionTypes;
      this.carServiceEntryId = data.carServiceEntryId;
      this.organizationId = data.organizationId;
      this.invoiceType = data.invoiceType;
      this.canGenerateInvoice = data.canGenerateInvoice;
      if(this.invoiceType == InvoiceType.CarServiceEntryAdvancePayment) this.label = "Vizualizeaza factura avans";
      this.initialize();
    }
  }

  constructor(
    private actionsSubj: ActionsSubject,
    private router: Router,
    protected appState$: Store<store.State>
  ) {
    this.getInvoiceAP = new GetCarServiceEntryInvoiceActionProxy(this, this.appState$);
    this.generateInvoiceAP = new GenerateInvoiceForCarEntryActionProxy(this, this.appState$);
    this.actionSubscription = actionsSubj.subscribe(data => {
      if (this.actionTypes.find(f => f.indexOf(data.type) > -1)) {

        if (isValidString(this.carServiceEntryId))
          this.getData();
      }
    });

  }

  ngOnInit() {
  }

  getData() {
    this.getInvoiceAP.execute(this.carServiceEntryId, this.organizationId, this.invoiceType,(data: Invoice_Dto) => {
      this.currentInvoice = data;
      this.firstTimeDataLoaded = true;
    });
  }

  initialize() {
    this.getData();
  }

  destroy() {
    if (isValidObject(this.actionSubscription)) {
      this.actionSubscription.unsubscribe();
    }
  }

  goToInvoice() {
    this.router.navigate(['invoice', this.organizationId, this.currentInvoice.id, 'details']);
  }

  generateInvoice() {
    this.generateInvoiceAP.execute(this.carServiceEntryId, this.organizationId);
  }
}

export class CarServiceEntryInvoiceButtonInputComponent {
  constructor(public carServiceEntryId: string,
              public organizationId: string,
              public invoiceType: InvoiceType,
              public actionTypes: Array<string> = [],
              public canGenerateInvoice: boolean) {

  }
}
