import {Component, OnInit, AfterViewInit} from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import {
  BaseListWebComponentWithSearchAndActiveBehavior
} from 'src/app/core/shared/base/base.component';
import { ConfigService } from 'src/app/core/core/app-config.service';
import { Router } from '@angular/router';
import {  ResetSection } from 'src/app/core/app-store/events/base.events';
import { ActiveDataTableParameters, DataTableParametersOrderEntity } from 'src/app/core/shared/datatable/datatable.helpers';
import { BsModalService } from 'ngx-bootstrap';
import { GetDepartmentsActionProxy } from './get-departments.action-proxy';
import { DepartmentsListSandbox } from './departments-list.sandbox';
import { SaveDepartmentComponent } from '../save-department/save-department.component';
import { ActivateDepartmentActionProxy } from './activate-department.action-proxy';
import { DeactivateDepartmentActionProxy } from './deactivate-department.action-proxy';
import { DeleteDepartmentActionProxy } from './delete-department.action-proxy';
import { Department_Dto } from 'src/app/core/app-dto/department.dto';
import {ConfirmationDialogService} from '../../../core/core/dialog/confirmation-dialog.service';
import { SetBreadcrumbActionProxy } from '../../../core/breadcrumb/set-breadcrumb.action-proxy';
import { IdName_Dto } from '../../../core/app-dto/misc.dto';


@Component({
  selector: 'app-departments-list',
  templateUrl: './departments-list.component.html',
  styleUrls: ['./departments-list.component.scss']
})
export class DepartmentsListComponent extends BaseListWebComponentWithSearchAndActiveBehavior implements OnInit, AfterViewInit {
  public getDepartmentsAP: GetDepartmentsActionProxy;
  public activateDepartmentAP: ActivateDepartmentActionProxy;
  public deactivateDepartmentAP: DeactivateDepartmentActionProxy;
  public deleteDepartmentAP: DeleteDepartmentActionProxy;
  public columns: any = [];
  public sortColumns: Array<IdName_Dto> = [];
  public currentPage = 1;

  constructor(
    public sandbox: DepartmentsListSandbox,
    actionsSubj: ActionsSubject,
    private configService: ConfigService,
    public router: Router, public confirmationDialogService: ConfirmationDialogService,
    private modalService: BsModalService
  ) {
    super(sandbox, actionsSubj, ResetSection.Departments);
    this.getDepartmentsAP = new GetDepartmentsActionProxy(this, sandbox.appState);
    this.activateDepartmentAP = new ActivateDepartmentActionProxy(this, sandbox.appState);
    this.deactivateDepartmentAP = new DeactivateDepartmentActionProxy(this, sandbox.appState);
    this.deleteDepartmentAP = new DeleteDepartmentActionProxy(this, sandbox.appState);
    this.columns = [
      { data: 'name', name: 'Nume', width: '40%' },
      { data: 'laborPricePerHour', name: 'Pret ora manopera(cu TVA)', width: '30%' },
      { data: 'currency', name: 'Moneda', width: '30%' },
      { data: 'action', width: '50px'},
      { data: 'createdOn', width: '0'}
    ];
    this.sortColumns = this.columns.filter(c => c.name).map(c => ({id: c.data, name: c.name} as IdName_Dto));
  }

  ngAfterViewInit() {
    this.setBreadcrumbAP.execute([{text: 'Acasa', url: '/dashboard'}, {text: 'Departamente'}]);
  }

  ngOnInit() {
    this.initialize();

    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      pageLength: this.configService.getDefaultPageSize(),
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      ajax: (dataTablesParameters: ActiveDataTableParameters, callback) => {
        dataTablesParameters.active = this.isActive;
        dataTablesParameters.search.value = this.searchValue;
        this.doGetAll(new ActiveDataTableParameters(dataTablesParameters), callback, true);
      },
      columns: this.columns,
      order: [[4, 'desc']],
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
  }

  public doGetAll(dataTablesParameters: ActiveDataTableParameters, callback: any, withReset: boolean) {
    this.sandbox.callback = callback;
    this.sandbox.dataTableParameters = dataTablesParameters;

    if (withReset) {
      setTimeout(() => {this.currentPage = 1; }, 0);
      this.sandbox.dataTableParameters.pageNumber = 1;
      dataTablesParameters.pageNumber = 1;
    }
    this.getDepartmentsAP.execute(dataTablesParameters, withReset);
  }

  createDepartment() {
    const modalRef = this.modalService.show(SaveDepartmentComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = null;
  }
  updateDepartment(department: Department_Dto) {
    const modalRef = this.modalService.show(SaveDepartmentComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = department;
  }

  activateDepartment(departmentId: string) {
    this.activateDepartmentAP.execute(departmentId);
  }

  deactivateDepartment(departmentId: string) {
    this.deactivateDepartmentAP.execute(departmentId);
  }

  deleteDepartment(department: Department_Dto) {
    this.confirmationDialogService.confirm(
      "Stergere departament",
      "Urmeaza sa stergeti departamentul <b>" + department.name + "</b>. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        this.deleteDepartmentAP.execute(department.id);
      }
    });
  }

  onSortChange($event) {
    if ($event) {
      const columnId = this.columns.map((e) => e.data).indexOf($event.id);
      const orderEntity = new DataTableParametersOrderEntity({column: columnId, dir: 'asc'});
      this.sandbox.dataTableParameters.order = [orderEntity];
    }
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, true);
  }

  onPageChange(currentPage: number) {
    this.currentPage = currentPage;
    this.sandbox.dataTableParameters.pageNumber = currentPage;
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
  }
}
