import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../core/shared/shared.module';
import { DataTablesModule } from 'angular-datatables';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { ServicePlatformStatusListComponent } from './components/service-platform-status-list/service-platform-status-list.component';

import { ServicePlatformStatusListSandbox } from './components/service-platform-status-list/service-platform-status-list.sandbox';
@NgModule({
  declarations: [
    ServicePlatformStatusListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    SharedModule,
    DataTablesModule
  ],
  exports: [ServicePlatformStatusListComponent],
  providers: [
    ServicePlatformStatusListSandbox
  ],
  entryComponents: []
})
export class ServicePlatformStatusModule { }
