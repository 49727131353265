import { PagedResult } from '../../shared/datatable/datatable.helpers';
import { Action } from '@ngrx/store';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from '../events/base.events';
import { ResetStateActionProxy } from '../../shared/base/base.action-proxy';
import { Supply_Dto } from '../../app-dto/supply.dto';
import {
  ActivateSupplyEvent,
  DeactivateSupplyEvent, DeleteSupplyEvent, EditSupplyEvent,
  GetSupplyEvent, GetSuppliesEvent,
  GetSuppliesPagedResultEvent,
  RegisterSupplyEvent, GetSuppliesWithUnpaidInvoicePagedResultEvent, MarkSupplyAsNotPaidEvent, MarkSupplyAsPaidEvent
} from '../events/supply.events';
import * as moment from 'moment';
import {ObjectValidators} from '../../shared/object.validators';

export interface SupplyState {
  lastRefreshTime: number;
  supply: Supply_Dto;
  suppliesPagedResult: PagedResult<Supply_Dto>;
  supplies: Supply_Dto[];
  suppliesWithUnpaidInvoicePagedResult: PagedResult<Supply_Dto>;
}

export const INITIAL_STATE: SupplyState = Object.assign({}, {
  lastRefreshTime: 0,
  supply: null,
  suppliesPagedResult: new PagedResult<Supply_Dto>([]),
  supplies: null,
  suppliesWithUnpaidInvoicePagedResult: new PagedResult<Supply_Dto>([])
});


export function reducer(state = INITIAL_STATE, action: Action): SupplyState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {
    // Get Supply

    case Do_ResetStateForSection.identifier + ResetSection.Supply: {
      return Object.assign({}, state, { supply: null });
    }

    case Do_GenericSuccessEvent.identifier + GetSupplyEvent.identifier: {
      const supplyData = new Supply_Dto((action as Do_GenericSuccessEvent<Supply_Dto>).data);
      return Object.assign({}, state, { supply: supplyData });
    }

    case Do_GenericErrorEvent.identifier + GetSupplyEvent.identifier: {
      return Object.assign({}, state, { supply: null });
    }

    case ResetStateActionProxy.name + GetSupplyEvent.identifier: {
      return Object.assign({}, state, { supply: null });
    }

    // Get Supply

    // Get Supplies

    case Do_ResetStateForSection.identifier + ResetSection.Supplies: {
      return Object.assign({}, state, { suppliesPagedResult: new PagedResult<Supply_Dto>([]) });
    }

    case GetSuppliesPagedResultEvent.identifier: {
      if ((action as GetSuppliesPagedResultEvent).withReset) {
        state.suppliesPagedResult = new PagedResult<Supply_Dto>([]);
        return Object.assign({}, state);
      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetSuppliesPagedResultEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<PagedResult<Supply_Dto>>).data;
      state.suppliesPagedResult = new PagedResult<Supply_Dto>(data);
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetSuppliesPagedResultEvent.identifier: {
      state.suppliesPagedResult = new PagedResult<Supply_Dto>([]);
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + GetSuppliesEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<Supply_Dto[]>).data;
      state.supplies = data;
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetSuppliesEvent.identifier: {
      state.supplies = null;
      return Object.assign({}, state);
    }

    // Get Supplies

    // Get Supplies with unpaid invoice

    case Do_ResetStateForSection.identifier + ResetSection.SupplieswithUnpaidInvoice: {
      return Object.assign({}, state, { suppliesWithUnpaidInvoicePagedResult: new PagedResult<Supply_Dto>([]) });
    }

    case GetSuppliesWithUnpaidInvoicePagedResultEvent.identifier: {
      if ((action as GetSuppliesWithUnpaidInvoicePagedResultEvent).withReset) {
        state.suppliesWithUnpaidInvoicePagedResult = new PagedResult<Supply_Dto>([]);
        return Object.assign({}, state);
      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetSuppliesWithUnpaidInvoicePagedResultEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<PagedResult<Supply_Dto>>).data;
      state.suppliesWithUnpaidInvoicePagedResult = new PagedResult<Supply_Dto>(data);
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetSuppliesWithUnpaidInvoicePagedResultEvent.identifier: {
      state.suppliesWithUnpaidInvoicePagedResult = new PagedResult<Supply_Dto>([]);
      return Object.assign({}, state);
    }
    // Get Supplies with unpaid invoice

    case Do_GenericSuccessEvent.identifier + RegisterSupplyEvent.identifier: {
      state.lastRefreshTime =  moment().unix();
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + DeactivateSupplyEvent.identifier:
    case Do_GenericSuccessEvent.identifier + ActivateSupplyEvent.identifier:
    case Do_GenericSuccessEvent.identifier + MarkSupplyAsPaidEvent.identifier:
    case Do_GenericSuccessEvent.identifier + MarkSupplyAsNotPaidEvent.identifier:
    case Do_GenericSuccessEvent.identifier + EditSupplyEvent.identifier: {
      const data = new Supply_Dto((action as Do_GenericSuccessEvent<Supply_Dto>).data);
      if(ObjectValidators.isValidObject(state.supply) && state.supply.id == data.id) {
        state.supply = data;
      }
      state.lastRefreshTime =  moment().unix();
      return Object.assign({}, state);

    }

    case Do_GenericSuccessEvent.identifier + DeleteSupplyEvent.identifier: {
      state.lastRefreshTime =  moment().unix();
      return Object.assign({}, state);
    }

    default: {
      return state;
    }
  }
}

export const getTimestampCallback = (state: SupplyState) => state.lastRefreshTime;
export const getSupplyCallback = (state: SupplyState) => state.supply;
export const getSuppliesPagedResultCallback = (state: SupplyState) => state.suppliesPagedResult;
export const getSuppliesCallback = (state: SupplyState) => state.supplies;
export const getSuppliesUnpaidInvoicePagedResultCallback = (state: SupplyState) => state.suppliesWithUnpaidInvoicePagedResult;
