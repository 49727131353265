export class ObjectValidators {

    public static isEmptyString(value: string): boolean {
        if (value == undefined || value == null || value === undefined || value == '' || value == "") return true;
        return false;
    }

    public static isValidObject(value: any): boolean {
        if (value == undefined || value == null || value === undefined) return false;
        return true;
    }

    public static isValidString(value: any): boolean {
        if (value == undefined || value == null || value === undefined || value == '' || value == "") return false;
        return true;
    }

    public static isValidArray(value: any): boolean {
        if (value == undefined || value == null || value === undefined) return false;
        return Array.isArray(value);
    }

    public static isValidNonEmptyArray(data: any): boolean {
        const isArray = this.isValidArray(data);
        if (isArray) return data.length > 0;
        else return false;
    }

    public static isValidDate(value: any): boolean {
        if (value == undefined || value == null || value === undefined) return false;
        return Object.prototype.toString.call(value) === "[object Date]"
    }

    public static isValidPositiveInteger(value: number) {
        if (value == undefined || value == null || value === undefined) return false;
        return value >>> 0 === parseFloat(value.toString());
    }
}
