import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseFormComponent} from '../../../../core/shared/base/base.component';
import {Subject} from 'rxjs';
import {FileWrapper} from '../../../../core/upload/components/upload-notification/upload-notification.di';
import {
    CarServicePhotosComponent,
    FileImageWrapper
} from '../../../../car-service/components/car-service-photos/car-service-photos.component';
import {WizardComponent} from 'angular-archwizard';
import {Store} from '@ngrx/store';
import * as store from '../../../../core/app-store';
import {ActivatedRoute} from '@angular/router';
import {BsModalRef} from 'ngx-bootstrap';
import {DateTimeService} from '../../../../core/core/services/date-time.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AppUserBusinessRuleProvider} from '../../../../core/core/business-rule-provider/app-user.business-rule.provider';
import {ResetSection} from '../../../../core/app-store/events/base.events';
import {OrganizationClient_Dto} from '../../../../core/app-dto/organization-client.dto';
import {ObjectValidators} from '../../../../core/shared/object.validators';
import {FileAttachment, FileToUpload, FileUploadTarget} from '../../../../core/app-dto/core.dto';
import {UploadInput} from '../../../../core/upload/components/upload-notification/upload-notification.component';
import {SaveDraftOfferActionProxy} from '../../../common/ap/save-draft-offer.action-proxy';
import {Offer_Dto, OfferCar_Dto} from '../../../../core/app-dto/offer.dto';
import {AddOfferDi} from '../add-offer.di';
import {RegisterOfferActionProxy} from '../register-offer.action-proxy';
import {AddOfferThirdStepComponent} from '../add-offer-third-step/add-offer-third-step.component';
import {AddOfferWizardSandbox} from './add-offer-wizard.sandbox';
import {AddOfferFirstStepComponent} from '../add-offer-first-step/add-offer-first-step.component';
import {Car_Dto} from '../../../../core/app-dto/car.dto';
import {CarFormComponent} from '../../../../car/components/car-form/car-form.component';
import {AddOfferClientComponent} from '../add-offer-client-data/add-offer-client.component';

@Component({
    selector: 'app-add-offer-wizard',
    templateUrl: './add-offer-wizard.component.html',
    styleUrls: ['./add-offer-wizard.component.scss']
})
export class AddOfferWizardComponent extends BaseFormComponent implements OnInit, OnDestroy {
    public registerAP: RegisterOfferActionProxy;
    public saveDraftAP: SaveDraftOfferActionProxy;

    public onClose: Subject<boolean>;

    public offer: Offer_Dto = null;
    public title: string = '';

    public files: Array<FileWrapper>;
    public imgUrls: Array<FileImageWrapper> = [];
    public initialStep: number = 0;

    public step: number = 0;
    public stepMin: number = 0;
    public stepMax: number = 4;

    public isFirstStepValid: boolean = true;
    public isClientStepValid = true;
    public isCarStepValid = true;
    public isCarWorksStepValid = true;
    public isSummary = false;

    public model: Offer_Dto;

    public di: AddOfferDi;//we need to overwrite di with specific type for production build

    @ViewChild(WizardComponent, {static: true})
    public wizard: WizardComponent;

    @ViewChild(AddOfferFirstStepComponent, {static: false})
    private firstStepComponent: AddOfferFirstStepComponent;

    @ViewChild(CarFormComponent, {static: false})
    private carComponent: CarFormComponent;

    @ViewChild(AddOfferClientComponent, {static: false})
    private clientComponent: AddOfferClientComponent;

    @ViewChild(AddOfferThirdStepComponent, {static: false})
    private thirdStepComponent: AddOfferThirdStepComponent;

    constructor(
        public appState: Store<store.State>,
        public sandbox: AddOfferWizardSandbox,
        public activatedRoute: ActivatedRoute,
        private activeModal: BsModalRef,
        public dateTimeService: DateTimeService,
        private spinner: NgxSpinnerService,
        public rulesProvider: AppUserBusinessRuleProvider
    ) {
        super(sandbox, ResetSection.SaveServicePlatform);
        this.registerAP = new RegisterOfferActionProxy(this, sandbox.appState, spinner);
        this.saveDraftAP = new SaveDraftOfferActionProxy(this, sandbox.appState);
        this.di = new AddOfferDi(this.dateTimeService);
        this.model = new Offer_Dto(null);
    }

    ngOnInit() {
        this.di = new AddOfferDi(this.dateTimeService, this.model);
        if (ObjectValidators.isValidObject(this.offer)) {
            this.di = new AddOfferDi(this.dateTimeService, this.offer);
        }

        if (this.di.model.id) {
            this.title = 'Modifica oferta';
        } else {
            this.title = 'Adauga oferta';
        }

        this.onClose = new Subject();
        this.dataLoaded = true;

        if (this.model.isIndividual != null)
            this.doesNeedClient =  this.model.isIndividual;

        if (this.initialStep > 0) {
            this.step = this.initialStep;
            this.wizard.goToStep(this.initialStep);
        }

        this.initialize();
    }

    ngOnDestroy() {
        this.destroy();
    }

    public decline() {
        this.onClose.next(false);
        this.activeModal.hide();
    }

    clientDetailsFormSaved(data: OrganizationClient_Dto) {
        if (ObjectValidators.isValidObject(data)) {
            this.model.client = data;
        }
    }

    carDetailsFormSaved(data: Car_Dto) {
        if (ObjectValidators.isValidObject(data)) {
            this.model.car = new OfferCar_Dto(data);
        }
    }

    initialFilesSaved(data: Array<FileAttachment>) {
        // if (ObjectValidators.isValidArray(data))
        // this.model.initialFiles = data;
    }

    public doesNeedClient: boolean = true;

    offerPrimaryDataFormSaved(data: Offer_Dto) {
        if (ObjectValidators.isValidObject(data)) {
            const di = this.firstStepComponent.di;
            this.model.title = di.model.title;
            this.model.type = di.model.type;
            this.model.offerEntryData.discount = di.model.offerEntryData.discount;
            this.model.isIndividual = di.model.isIndividual;
            setTimeout(() => {
                this.doesNeedClient = this.model.isIndividual;
            }, 100);
        }
    }

    carWorksFormSaved(data: Offer_Dto) {
        if (ObjectValidators.isValidObject(data)) {
            this.model.offerEntryData = this.thirdStepComponent.di.getModel().offerEntryData;
            this.model.clientCarParts = this.thirdStepComponent.di.getModel().clientCarParts;
        }
    }

    updateModel() {
        this.model.title = this.firstStepComponent.di.model.title;
        this.model.type = this.firstStepComponent.di.model.type;
        this.model.isIndividual = this.firstStepComponent.di.model.isIndividual;

        if (ObjectValidators.isValidObject(this.clientComponent) && this.model.isIndividual) {
            this.model.client = this.clientComponent.di.getModel();
        }

        if (ObjectValidators.isValidObject(this.carComponent)) {
            this.model.car = this.carComponent.di.getModel();
        }

        this.model.offerEntryData = this.thirdStepComponent.di.getModel().offerEntryData;
        this.model.clientCarParts = this.thirdStepComponent.di.getModel().clientCarParts;
    }

    goToNextStep() {
        const currentStep = this.step;
        this.step++;
        this.wizard.goToNextStep();
        this.updateValidation();
        this.updateModel();
        $('.scroll-container').animate({scrollTop: 0}, 'slow');
    }

    goToPreviousStep() {
        this.step--;
        this.wizard.goToPreviousStep();
        this.updateValidation();
        this.updateModel();
        $('.scroll-container').animate({scrollTop: 0}, 'slow');
    }

    goToStep(index: number) {
        let currentStepIsValid = true;

        if(index == this.step)
            return;

        if(index > this.step) {
            const currentStep = this.step;
            currentStepIsValid = this.isCurrentStepValid(currentStep);
        }

        this.isSummary = false;
        this.step = index;
        this.wizard.goToStep(index);
        this.updateValidation();
        this.updateModel();
        $('.scroll-container').animate({scrollTop: 0}, 'slow');
    }

    goToSummary() {
        this.formSubmitted = true;

        this.firstStepComponent.accept();

        if (ObjectValidators.isValidObject(this.clientComponent) && this.model.isIndividual) {
            this.clientComponent.accept();
        }

        this.carComponent.accept();

        this.thirdStepComponent.accept();

        this.updateValidation();

        if (this.isFirstStepValid && this.isClientStepValid && this.isCarStepValid && this.isCarWorksStepValid) {
            this.isSummary = true;
        }
        $('.scroll-container').animate({scrollTop: 0}, 'slow');
    }

    updateValidation() {
        if (this.formSubmitted) {
            this.isFirstStepValid = this.firstStepComponent.isValid();

            if (ObjectValidators.isValidObject(this.clientComponent) && this.model.isIndividual) {
                this.isClientStepValid = this.clientComponent.isValid();
            } else {
                this.isClientStepValid = true;
            }

            if (ObjectValidators.isValidObject(this.carComponent)) {
                this.isCarStepValid = this.carComponent.isValid();
            } else {
                this.isCarStepValid = true;
            }

            this.isCarWorksStepValid = this.thirdStepComponent.isValid();
            if (ObjectValidators.isValidObject(this.carComponent)) {
                this.carComponent.markFormAsDirtyAndTouched();
            }
            if (ObjectValidators.isValidObject(this.clientComponent) && this.model.isIndividual) {
                this.clientComponent.markFormAsDirtyAndTouched();
            }
        }
    }

    isCurrentStepValid(step: number) {
        if (step == 0) {
            this.firstStepComponent.accept();
            this.isFirstStepValid = this.firstStepComponent.isValid();
            return this.isFirstStepValid;
        } else if (step == 1) {
            if (this.model.isIndividual && ObjectValidators.isValidObject(this.clientComponent)) {
                this.clientComponent.accept();
                this.isClientStepValid = this.clientComponent.isValid();
                return this.isClientStepValid;
            } else if (!this.model.isIndividual) {
                this.carComponent.accept();
                this.isCarStepValid = this.carComponent.isValid();
                return this.isCarStepValid;
            }
        } else if (this.step == 2) {
            if (this.model.isIndividual) {
                this.carComponent.accept();
                this.isCarStepValid = this.carComponent.isValid();
                return this.isCarStepValid;
            } else {
                this.thirdStepComponent.accept();
                this.isCarWorksStepValid = this.thirdStepComponent.isValid();
                return this.isCarWorksStepValid;
            }
        }
        else if (this.step == 3) {
            this.thirdStepComponent.accept();
            this.isCarWorksStepValid = this.thirdStepComponent.isValid();
            return this.isCarWorksStepValid;
        }
        return false;
    }

    saveForm() {
        if (this.isFirstStepValid && this.isClientStepValid && this.isCarStepValid && this.isCarWorksStepValid) {
            this.di.model = this.model;
            this.registerAP.execute((data: Offer_Dto) => {
                console.log('uploadCompleted ' + NgZone.isInAngularZone());
                if (this.files != null && this.files.length > 0) {
                    let timestamp = new Date().getTime();
                    const filesToUpload = new Array<FileToUpload>();

                    for (let i = 0; i < this.files.length; i++) {
                        this.files[i].id = undefined;
                        timestamp = timestamp + 1;
                        filesToUpload.push(new FileToUpload(timestamp.toString(), this.files[i].file, FileUploadTarget.Offer, data.id));
                    }
                    if (filesToUpload.length > 0) {
                        this.sandbox.dispatchUploadInputEvent(new UploadInput(filesToUpload, FileUploadTarget.Offer));
                    }
                }

                this.onClose.next(true);
                this.activeModal.hide();
                // this.router.navigate(['/car-service/all']);
            });
        } else {
            $('.modal').animate({scrollTop: 0}, 'slow');
        }
    }

    saveDraft() {
        this.model.title = this.firstStepComponent.di.model.title;
        this.model.type = this.firstStepComponent.di.model.type;
        this.model.isIndividual = this.firstStepComponent.di.model.isIndividual;

        if (ObjectValidators.isValidObject(this.clientComponent) && this.model.isIndividual) {
            this.model.client = this.clientComponent.di.getModel();
        }

        if (ObjectValidators.isValidObject(this.carComponent)) {
            this.model.car = new OfferCar_Dto(this.carComponent.di.getModel());
        }

        this.model.offerEntryData = this.thirdStepComponent.di.getModel().offerEntryData;
        this.model.clientCarParts = this.thirdStepComponent.di.getModel().clientCarParts;
        this.saveDraftAP.execute(this.model, this.step);
        this.activeModal.hide();
    }
}
