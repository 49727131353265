import {Injectable} from "@angular/core";
import {Actions, createEffect, Effect, ofType} from '@ngrx/effects';
import {Observable} from "rxjs";
import { map, switchMap} from "rxjs/operators";
import {Do_GenericErrorEvent, Do_GenericSuccessEvent, SeamlessEvent} from "../../app-store/events/base.events";
import {DoGetVersion, DoLoadStaticData} from '../../app-store/events/static-data.events';
import {StaticData} from "../../app-dto/static-data.dto";
import {ObjectValidators} from "../../shared/object.validators";
import {StaticDataApiService} from "../../api-integration/services/static-data.service";
import {Version_Dto} from '../../app-dto/version.dto';

@Injectable()
export class StaticDataEventProcessor {

  constructor(
    private actions$: Actions,
    private service: StaticDataApiService
  ) {
  }

  error$:
    Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType(DoLoadStaticData.identifier, SeamlessEvent.identifier + DoLoadStaticData.identifier),
      map((action: DoLoadStaticData) => action),
      switchMap((action: DoLoadStaticData) => {
          return this.service.u_getStaticData().pipe(
            map((res: StaticData) => {
              if (ObjectValidators.isValidObject(res))
                return new Do_GenericSuccessEvent<StaticData>(res, action);
            }, (error: string) => {
              return new Do_GenericErrorEvent<string>(error, action);
            })
          );
        }
      )
    ));

    @Effect()
    getVersion$: Observable<any> = this.actions$
        .pipe(
            ofType(DoGetVersion.identifier, SeamlessEvent.identifier + DoGetVersion.identifier),
            map((action: DoGetVersion) => action),
            switchMap((action: DoGetVersion) => {
                return new Observable((observer) => {
                    this.service.u_getVersion()
                        .subscribe((res: Version_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Version_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
}
