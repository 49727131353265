import {BaseFormDI} from 'src/app/core/shared/base/base.di';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CarPart_Dto} from '../../../core/app-dto/car-part.dto';
import {Supplier_Dto} from '../../../core/app-dto/supplier.dto';
import {min} from '../../../core/shared/form/custom-form.validator';
import {YesNoComponent, YesNoComponentInput} from '../../../core/shared/components/yes-no/yes-no.component';
import {isValidString} from '../../../core/shared/helpers/common.helpers';
import {StaticDataSelectorInput} from "../../../core/shared/components/static-data-selector/static-data-selector.component";
import {round} from "lodash";

export class SaveCarPartDi extends BaseFormDI<CarPart_Dto> {

    public acquisitionPriceVATInput: YesNoComponentInput;
    public sellingPriceVATInput: YesNoComponentInput;
    public acquisitionVatsDataInput: StaticDataSelectorInput;
    public sellingVatsDataInput: StaticDataSelectorInput;

    constructor(data: CarPart_Dto = new CarPart_Dto(null), private canManageSupplies: boolean = false) {
        super(new CarPart_Dto(data));
        this.initForm();
        if (isValidString(this.model.id) == false) {
            this.model.acquisitionPriceHasVat = true;
            this.model.sellingPriceHasVat = true;
        }
        this.acquisitionPriceVATInput = new YesNoComponentInput(this.model.acquisitionPriceHasVat, !this.model.acquisitionPriceHasVat, "Pretul include TVA?",
            this.form, "acquisitionVAT", this.canManageSupplies == true)
        this.sellingPriceVATInput = new YesNoComponentInput(this.model.sellingPriceHasVat, !this.model.sellingPriceHasVat, "Pretul include TVA?", this.form, "sellingPriceVAT")
        this.acquisitionVatsDataInput = {
            form: this.form,
            type: 'supplyVats',
            formControlName: 'acquisitionVatValue',
            isRequired: true,
            label: 'Cota TVA',
            isReadOnly: false
        };
        this.sellingVatsDataInput = {
            form: this.form,
            type: 'supplyVats',
            formControlName: 'sellingVatValue',
            isRequired: true,
            label: 'Cota TVA',
            isReadOnly: false
        };
    }

    getModel(): CarPart_Dto {
        this.model.name = this.form.value.name;
        this.model.code = this.form.value.code;
        this.model.acquisitionPrice = this.form.value.acquisitionPrice;
        this.model.acquisitionPriceHasVat = this.form.get(this.acquisitionPriceVATInput.formGroupName).get(YesNoComponent.YES_CONTROL_NAME).value || false;
        this.model.acquisitionPriceCurrency = this.form.value.acquisitionPriceCurrency.name;
        this.model.acquisitionVatValue = this.form.value.acquisitionVatValue;
        this.model.sellingVatValue = this.form.value.sellingVatValue;
        if (this.model.acquisitionPriceHasVat) {
            this.model.acquisitionPriceWithVat = this.model.acquisitionPrice;
            this.model.acquisitionPrice = round(this.model.acquisitionPriceWithVat * 100 / (100 + this.form.value.acquisitionVatValue.id), 2);
        } else {
            this.model.acquisitionPriceWithVat = round(this.model.acquisitionPrice +
                (this.model.acquisitionPrice * this.form.value.acquisitionVatValue.id / 100), 2);
        }
        this.model.sellingPrice = this.form.value.sellingPrice;
        this.model.sellingPriceHasVat = this.form.get(this.sellingPriceVATInput.formGroupName).get(YesNoComponent.YES_CONTROL_NAME).value || false;
        this.model.sellingPriceCurrency = this.form.value.sellingPriceCurrency.name;
        if (this.model.sellingPriceHasVat) {
            this.model.sellingPriceWithVat = this.model.sellingPrice;
            this.model.sellingPrice = round(this.model.sellingPriceWithVat * 100 / (100 + this.form.value.sellingVatValue.id), 2);
        } else {
            this.model.sellingPriceWithVat = round(this.model.sellingPrice +
                (this.model.sellingPrice * this.form.value.sellingVatValue.id / 100), 2);
        }
        this.model.supplier = new Supplier_Dto(this.form.value.supplier);
        this.model.isSecondHand = this.form.value.isSecondHand || false;
        this.model.quantity = this.form.value.quantity || 0;
        this.model.um = this.form.value.um;

        return this.model;
    }

    initForm() {
        let sellingPrice: number = this.model.sellingPrice;
        if (this.model.sellingPriceHasVat) sellingPrice = this.model.sellingPriceWithVat;
        let acquisitionPrice: number = this.model.acquisitionPrice;
        if (this.model.acquisitionPriceHasVat) acquisitionPrice = this.model.acquisitionPriceWithVat;
        if(!isValidString(this.model.um)) this.model.um = 'buc';
        this.form = new FormGroup({
            name: new FormControl(this.model.name, Validators.compose([Validators.required])),
            code: new FormControl(this.model.code, Validators.compose([Validators.required])),
            acquisitionPrice: new FormControl(acquisitionPrice, Validators.compose([Validators.required, min(0)])),
            sellingPrice: new FormControl(sellingPrice, Validators.compose([Validators.required, min(0)])),
            supplier: new FormControl(this.model.supplier, Validators.compose([Validators.required])),
            isSecondHand: new FormControl(this.model.isSecondHand, Validators.compose([])),
            quantity: new FormControl(this.model.quantity, Validators.compose([])),
            um: new FormControl(this.model.um, Validators.compose([]))
        });
    }

}
