import {Base_Dto, BaseCRUDRules, BusinessRule} from "./misc.dto";
import {ObjectValidators} from "../shared/object.validators";
import * as moment from "moment";
import {NumericLookupDto} from "./numeric-lookup.dto";
import {AppConstants} from "./app.constants";

export class CashRegisterRules extends BaseCRUDRules {
    public canReset: BusinessRule;
    public canDeactivate: BusinessRule;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.canReset = new BusinessRule(data.canReset);
            this.canDeactivate = new BusinessRule(data.canDeactivate);

        } else {
            this.canReset = new BusinessRule();
            this.canDeactivate = new BusinessRule();
        }
    }
}

export class CashRegister_Dto extends Base_Dto {
    public organizationId: string;
    public name: string;
    public description: string;
    public index: number;
    public directory: string;
    public filePattern: string;
    public activationCode: string;
    public status: string;
    public lastErrorMessage: string;
    public make: string;
    public model: string;
    public cashIndex: NumericLookupDto;
    public cardIndex: NumericLookupDto;
    public createdOn: Date;
    public updatedOn: Date;

    public rules: CashRegisterRules;

    constructor(data?: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.organizationId = data.organizationId;
            this.name = data.name;
            this.description = data.description;
            this.index = data.index;
            this.directory = data.directory;
            this.filePattern = data.filePattern;
            this.activationCode = data.activationCode;
            this.status = data.status;
            this.lastErrorMessage = data.lastErrorMessage;
            this.make = data.make;
            this.model = data.model;

            if (ObjectValidators.isValidObject(data.cashIndex))
                this.cashIndex = new NumericLookupDto(data.cashIndex);
            else this.cashIndex = NumericLookupDto.initWith(AppConstants.CASH_REGISTER_PARAMETER_CASH, AppConstants.CASH_REGISTER_PARAMETER_CASH_DISPLAY_AS, 1);

            if (ObjectValidators.isValidObject(data.cardIndex))
                this.cardIndex = new NumericLookupDto(data.cardIndex);
            else this.cardIndex = NumericLookupDto.initWith(AppConstants.CASH_REGISTER_PARAMETER_CARD, AppConstants.CASH_REGISTER_PARAMETER_CARD_DISPLAY_AS, 2);

            if (ObjectValidators.isValidObject(data.createdOn))
                this.createdOn = moment.utc(data.createdOn).toDate();

            if (ObjectValidators.isValidObject(data.updatedOn))
                this.updatedOn = moment.utc(data.updatedOn).toDate();

            this.rules = new CashRegisterRules(data.rules);
        }
    }
}
