import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseFormComponent } from 'src/app/core/shared/base/base.component';
import { LoginSandbox } from './login.sandbox';
import { LoginDI } from './login.di';
import { LoginActionProxy } from './login.action-proxy';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import {LogoutActionProxy} from '../logout/logout.action-proxy';
import {ActivatedRoute} from '@angular/router';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseFormComponent implements OnInit, OnDestroy {

  public loginAP: LoginActionProxy;
  public logoutAP: LogoutActionProxy;

  public errorMessage: string = '';
  private sub: any;

  constructor(
    public sandbox: LoginSandbox,
    private route: ActivatedRoute,
    private notifierService: NotifierService
  ) {
    super(sandbox, ResetSection.Login);
    this.loginAP = new LoginActionProxy(this, sandbox.appState);
    this.logoutAP = new LogoutActionProxy(this, sandbox.appState);
    this.di = new LoginDI();
  }

  ngOnInit() {
    this.logoutAP.execute(false);
    this.initialize((message: string) => {
      this.errorMessage = message;
    });
    this.sub = this.route
        .queryParams
        .subscribe(params => {
          if (params.reason === '401') {
            this.notifierService.notify('error', 'Sesiunea a expirat');
          }
        });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.destroy();
  }

  saveForm() {
    this.formSubmitted = true;
    this.loginAP.execute();
  }
}
