import { Injectable } from '@angular/core';
import * as store from '../../../app-store/index';
import { Store, select } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { Do_ResetStateForSection, ResetSection } from 'src/app/core/app-store/events/base.events';
import { BaseComponentState } from 'src/app/core/app-store/utils/base-component.state';
import { ObjectValidators } from 'src/app/core/shared/object.validators';

@Injectable()
export class GenerateActivationLinkSandbox extends BaseSandbox {
  public state$ = this.appState$.pipe(select(store.getGenerateActivationLinkComponent));

  constructor(
    protected appState$: Store<store.State>
  ) {
    super(appState$);
  }

  clearSandboxState() {
  }

  initialize(authMessage: (message: string) => {}) {
    this.clearSandboxState();
    this.appState$.dispatch(new Do_ResetStateForSection(ResetSection.GenerateActivationLink));

    this.subscriptions.push(this.state$.subscribe((state: BaseComponentState) => {
      if (ObjectValidators.isValidObject(state)) {
        authMessage(state.stateMessage);
      }
    }));
  }
}
