import {ObjectValidators} from '../shared/object.validators';
import {Base_Dto, BaseCRUDRules} from './misc.dto';
import * as moment from 'moment';
import {ServicePlatform_Dto} from './service-platform.dto';
import {Mechanic_Dto} from './mechanic.dto';
import {AppointmentClient_Dto} from './appointment-client.dto';
import {AppointmentCar_Dto} from './appointment-car.dto';
import {AppointmentCarWorks_Dto, CarWork_Dto} from './car-work.dto';
import {CalendarEventCardPosition} from './calendar-event.dto';
import {isValidObject} from "../shared/helpers/common.helpers";

export class CarAppointmentRules extends BaseCRUDRules {
  constructor(data: any) {
    super(data);
  }
}

export class CarAppointment_Dto extends Base_Dto {
  public car: AppointmentCar_Dto;
  public client: AppointmentClient_Dto;
  public carWorks: Array<CarWork_Dto>;
  public startDate: Date;
  public endDate: Date;
  public servicePlatform: ServicePlatform_Dto;
  public mechanic: Mechanic_Dto;
  public paymentInAdvance: number;
  public paymentInAdvanceCurrency: string;
  public rules: CarAppointmentRules;
  public cardPosition?: CalendarEventCardPosition;
  public details: string;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
        this.car = new AppointmentCar_Dto(data.car);
        this.client = new AppointmentClient_Dto(data.client);
        this.carWorks = data.carWorks;
        this.details = data.details;

      if (isValidObject(data.servicePlatform)) {
        this.servicePlatform = new ServicePlatform_Dto(data.servicePlatform);
      }
      if (isValidObject(data.mechanic))
        this.mechanic = new Mechanic_Dto(data.mechanic);
      this.paymentInAdvance = data.paymentInAdvance;
      this.paymentInAdvanceCurrency = data.paymentInAdvanceCurrency;

      // if (ObjectValidators.isValidArray(data.carWorks)) {
      //   this.carWorks = data.carWorks.map(r => new CarWork_Dto(r.carWorks?.carWorks));
      // } else {
      //   this.carWorks = [];
      // }

      // if (ObjectValidators.isValidArray(this.appointmentCarWorks.carWorks)) {
      //   this.carWorks = this.appointmentCarWorks.carWorks.map(r => new CarWork_Dto(r));
      // } else {
      //   this.carWorks = [];
      // }

      if (ObjectValidators.isValidObject(data.startDate))
        this.startDate = moment.utc(data.startDate).toDate();
      if (ObjectValidators.isValidObject(data.endDate))
        this.endDate = moment.utc(data.endDate).toDate();
      this.rules = new CarAppointmentRules(data.rules);
    }
  }
}
