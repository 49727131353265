import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { Workmanship_Dto } from '../../../core/app-dto/workmanship.dto';

export class CarServiceDetailsWorkmanshipsDi extends BaseFormDI<Array<Workmanship_Dto>> {
    constructor(data: Array<Workmanship_Dto> = new Array<Workmanship_Dto>()) {
        super(data);
    }
    initForm() {
    }

    getModel(): Array<Workmanship_Dto> {
        return this.model;
    }
}
