import { Component, OnInit } from '@angular/core';
import {CarService_Dto} from "../../../core/app-dto/car-service.dto";
import {AddEditCarInServiceWizardComponent} from "../add-edit-car-in-service-wizard/add-edit-car-in-service-wizard.component";
import {BsModalService} from "ngx-bootstrap";
import { SaveCarComponent } from '../../../car/components/save-car/save-car.component';

@Component({
  selector: 'app-car-service-lists-overview',
  templateUrl: './car-service-lists-overview.component.html',
  styleUrls: ['./car-service-lists-overview.component.scss']
})
export class CarServiceListsOverviewComponent implements OnInit {

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
  }

  addCarInService() {
    const initialState = {
      carInService: new CarService_Dto(null),
      title: 'Adauga Masina in Service'
    };
    const modalRef = this.modalService.show(AddEditCarInServiceWizardComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
  }

  addCar() {
    const modalRef = this.modalService.show(SaveCarComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl' });
    modalRef.content.dataInput = null;
  }
}
