import { State } from '../state';
import * as fromServicePlatform from '../stores/service-platform.store';
import { createSelector } from '@ngrx/store';

export const getServicePlatformStateCallback = (state: State) => state.servicePlatform;

export const getServicePlatformTimestamp = createSelector(getServicePlatformStateCallback, fromServicePlatform.getTimestampCallback);
export const getServicePlatform = createSelector(getServicePlatformStateCallback, fromServicePlatform.getServicePlatformCallback);
export const getServicePlatformsListing = createSelector(getServicePlatformStateCallback, fromServicePlatform.getServicePlatformsPagedResultCallback);
export const getServicePlatformsList = createSelector(getServicePlatformStateCallback, fromServicePlatform.getServicePlatformsListCallback);
