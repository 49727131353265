import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { DeleteCalendarEventEvent } from 'src/app/core/app-store/events/calendar-events';
import { NgxSpinnerService } from 'ngx-spinner';

export class DeleteCalendarEventActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
        appState: Store<store.State>,
        private spinner: NgxSpinnerService) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(id: string): any {
        this.spinner.show('calendar');
        this.appState.dispatch(new DeleteCalendarEventEvent(id, () => {
            this.spinner.hide('calendar');
        }));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

