import {BaseFormDI} from 'src/app/core/shared/base/base.di';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CarPart_Dto} from '../../../core/app-dto/car-part.dto';
import {Supplier_Dto} from '../../../core/app-dto/supplier.dto';
import {min, PhoneReg} from '../../../core/shared/form/custom-form.validator';
import {YesNoComponent, YesNoComponentInput} from '../../../core/shared/components/yes-no/yes-no.component';
import {isValidString} from '../../../core/shared/helpers/common.helpers';
import {StaticDataSelectorInput} from '../../../core/shared/components/static-data-selector/static-data-selector.component';
import {round} from 'lodash';
import {OfferDeclinedTrace_Dto} from '../../../core/app-dto/offer.dto';

export class ResponseToOfferDi extends BaseFormDI<OfferDeclinedTrace_Dto> {


    constructor(data: OfferDeclinedTrace_Dto = new OfferDeclinedTrace_Dto(null), private forImplicitClient: boolean = false) {
        super(new OfferDeclinedTrace_Dto(data));
        this.initForm();

    }

    getModel(): OfferDeclinedTrace_Dto {
        this.model.name = this.form.getRawValue().name;
        this.model.phoneNumber = this.form.getRawValue().phoneNumber;
        this.model.email = this.form.getRawValue().email;
        this.model.reason = this.form.getRawValue().reason;

        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
            name: new FormControl({value: this.model.name, disabled: this.forImplicitClient}, Validators.compose([Validators.required])),
            email: new FormControl({
                value: this.model.email,
                disabled: this.forImplicitClient
            }, Validators.compose([Validators.required, Validators.email])),
            phoneNumber: new FormControl({value: this.model.phoneNumber, disabled: this.forImplicitClient},
                Validators.compose([Validators.required, PhoneReg()])),
            reason: new FormControl({value: this.model.reason, disabled: false}, Validators.compose([])),

        });
    }

}
