import * as moment from 'moment';
import {ObjectValidators} from "./object.validators";

export class DateHelpers {
  public static dateToString(date: Date, format = 'DD/MM/YYYY') {
    if(ObjectValidators.isValidObject(date)) {
      return moment(date).format(format);
    }
    return '';
  }

  public static getTimeUntilExpiration(date: Date) {
    return moment(date).locale('ro').fromNow(true);
  }

  public static getUTCStartFixedDate(date: Date) {
    return moment.utc([date.getFullYear(), date.getMonth(), date.getDate(), 0,0,0]).toDate();
  }
  public static getUTCEndFixedDate(date: Date) {
    return moment.utc([date.getFullYear(), date.getMonth(), date.getDate(), 23,59,59]).toDate();
  }
}
