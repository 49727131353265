import {Component, OnInit, AfterViewInit, ViewChildren, QueryList, Input, Output, EventEmitter} from '@angular/core';
import {ActionsSubject} from '@ngrx/store';
import {
    BaseListWebComponent,
    BaseListWebComponentWithSearchAndActiveBehavior
} from 'src/app/core/shared/base/base.component';
import {DataTableDirective} from 'angular-datatables';
import {Subscription} from 'rxjs/Subscription';
import {ConfigService} from 'src/app/core/core/app-config.service';
import {Router} from '@angular/router';
import {SeamlessEvent, ResetSection} from 'src/app/core/app-store/events/base.events';
import {SearchEvent} from 'src/app/core/app-store';
import {ActiveDataTableParameters, DataTableParametersOrderEntity} from 'src/app/core/shared/datatable/datatable.helpers';
import {BsModalService} from 'ngx-bootstrap';
import {GetOrganizationClientsActionProxy} from './get-organization-clients.action-proxy';
import {OrganizationClientsListSandbox} from './organization-clients-list.sandbox';
import {SaveOrganizationClientComponent} from '../save-organization-client/save-organization-client.component';
import {DeleteOrganizationClientActionProxy} from './delete-organization-client.action-proxy';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {OrganizationClient_Dto} from 'src/app/core/app-dto/organization-client.dto';
import {ConfirmationDialogService} from '../../../core/core/dialog/confirmation-dialog.service';
import {SetBreadcrumbActionProxy} from '../../../core/breadcrumb/set-breadcrumb.action-proxy';
import {IdName_Dto} from '../../../core/app-dto/misc.dto';
import {OrganizationClientsListItemDi} from './organization-clients-list-item.di';
import {CarWork_Dto} from '../../../core/app-dto/car-work.dto';
import {OffertedClientContact_Dto} from '../../../core/app-dto/offer.dto';


@Component({
    selector: 'app-organization-clients-list',
    templateUrl: './organization-clients-list.component.html',
    styleUrls: ['./organization-clients-list.component.scss']
})
export class OrganizationClientsListComponent extends BaseListWebComponentWithSearchAndActiveBehavior implements OnInit, AfterViewInit {
    public getOrganizationClientsAP: GetOrganizationClientsActionProxy;
    public deleteOrganizationClientAP: DeleteOrganizationClientActionProxy;
    public columns: any = [];
    public sortColumns: Array<IdName_Dto> = [];
    public currentPage = 1;
    @Input('forOffer') forOffer: boolean = false;

    @Input('selectedOffertedClients') set selectedOffertedClients(value: Array<OffertedClientContact_Dto>) {
        this.sandbox.setOffertedClients(value);
    }

    @Output()
    itemSelected = new EventEmitter<OrganizationClient_Dto>();
    @Output()
    itemDeselected = new EventEmitter<OrganizationClient_Dto>();

    @ViewChildren(DataTableDirective) set datatableValue(datatable: QueryList<DataTableDirective>) {
        if (datatable != null) {
            datatable.first.dtInstance.then((dtInstance: DataTables.Api) => {
                this.dtApiInstance = dtInstance;
                this.sandbox.setResetTableCallback(() => {
                    dtInstance.ajax.reload();
                });
            });
        }
    }


    constructor(
        public sandbox: OrganizationClientsListSandbox,
        actionsSubj: ActionsSubject,
        private configService: ConfigService,
        public router: Router,
        public confirmationDialogService: ConfirmationDialogService,
        private modalService: BsModalService
    ) {
        super(sandbox, actionsSubj, ResetSection.OrganizationClients);
        this.getOrganizationClientsAP = new GetOrganizationClientsActionProxy(this, sandbox.appState);
        this.deleteOrganizationClientAP = new DeleteOrganizationClientActionProxy(this, sandbox.appState);

        this.columns = [{data: 'name', name: 'Nume', width: '20%'},
            {data: 'cuiCnp', name: 'CUI/CNP', width: '10%'},
            {data: 'nrRegCi', name: 'Nr Reg Com/Serie CI', width: '10%'},
            {data: 'phoneNumber', name: 'Telefon', width: '10%'},
            {data: 'email', name: 'Email', width: '10%'},
            {data: 'iban', name: 'Cont bancar', width: '15%'},
            {data: 'bank', name: 'Banca', width: '10%'},
            {data: 'address', name: 'Adresa', width: '15%'},
            {data: 'action', width: '50px'},
            {data: 'createdOn', width: '0'}];
        this.sortColumns = this.columns.filter(c => c.name).map(c => ({id: c.data, name: c.name} as IdName_Dto));
    }

    ngAfterViewInit() {
        this.setBreadcrumbAP.execute([{text: 'Acasa', url: '/dashboard'}, {text: 'Clienti'}]);
    }

    ngOnInit() {
        this.initialize();

        this.dtOptions = {
            responsive: true,
            paging: false,
            info: false,
            lengthChange: false,
            searching: false,
            pageLength: this.configService.getDefaultPageSize(),
            scrollX: false,
            serverSide: true,
            processing: false,
            ordering: false,
            autoWidth: false,
            ajax: (dataTablesParameters: ActiveDataTableParameters, callback) => {
                dataTablesParameters.active = this.isActive;
                dataTablesParameters.search.value = this.searchValue;
                this.doGetAll(new ActiveDataTableParameters(dataTablesParameters), callback, true);
            },
            columns: this.columns,
            order: [[9, 'desc']],
            dom: '<\'row mx-1\'<\'col-sm-12 col-md-6 px-3\'l><\'col-sm-12 col-md-6 px-3\'f>>' + '<\'table-responsive\'tr>' + '<\'row mx-1 align-items-center justify-content-center justify-content-md-between\'<\'col-auto mb-2 mb-sm-0\'i><\'col-auto\'p>>'
        };
    }

    public doGetAll(dataTablesParameters: ActiveDataTableParameters, callback: any, withReset: boolean) {
        this.sandbox.callback = callback;
        this.sandbox.dataTableParameters = dataTablesParameters;
        if (withReset) {
            setTimeout(() => {this.currentPage = 1; }, 0);
            this.sandbox.dataTableParameters.pageNumber = 1;
            dataTablesParameters.pageNumber = 1;
        }
        this.getOrganizationClientsAP.execute(dataTablesParameters, withReset);
    }

    createOrganizationClient() {
        const modalRef = this.modalService.show(SaveOrganizationClientComponent, {backdrop: 'static', keyboard: false, class: 'modal-xl'});
        modalRef.content.dataInput = null;
    }


    updateOrganizationClient(client: OrganizationClient_Dto) {
        const modalRef = this.modalService.show(SaveOrganizationClientComponent, {backdrop: 'static', keyboard: false, class: 'modal-xl'});
        modalRef.content.dataInput = client;
    }

    deleteOrganizationClient(organizationClient: OrganizationClient_Dto) {
        this.confirmationDialogService.confirm(
            'Stergere client',
            'Urmeaza sa stergeti clientul <b>' + organizationClient.name + '</b>. Sunteti sigur?',
            'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
            if (confirmed) {
                this.deleteOrganizationClientAP.execute(organizationClient.id);
            }
        });

    }

    search(data: any) {
        if (ObjectValidators.isValidString(data)) {
            this.modelChanged.next(data);
        }
        if (data === '') {
            this.modelChanged.next(null);
        }
        return false;
    }

    onPageChange(currentPage: number) {
        this.currentPage = currentPage;
        this.sandbox.dataTableParameters.pageNumber = currentPage;
        this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
    }

    onSortChange($event) {
        if ($event) {
            const columnId = this.columns.map((e) => e.data).indexOf($event.id);
            const orderEntity = new DataTableParametersOrderEntity({column: columnId, dir: 'asc'});
            this.sandbox.dataTableParameters.order = [orderEntity];
        }
        this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, true);
    }

    toggleSelection(item: OrganizationClientsListItemDi) {
        item.toggleSelection();
        if (item.isSelected) {
            this.itemSelected.emit(item.getModel());
        } else {
            this.itemDeselected.emit(item.getModel());
        }
    }
}
