import { FileUploadTarget, FileAttachment } from 'src/app/core/app-dto/core.dto';
import {
    UIGeneratedEvent,
    EventWithScreenLoading,
    SeamlessEvent
} from 'src/app/core/app-store/events/base.events';

export class GetDocumentsByEntityAndTypeEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetDocumentsByEntityAndTypeEvent";
    constructor(public target: Array<FileUploadTarget>, public relatedEntityId: string) {
        super();
        this.type += GetDocumentsByEntityAndTypeEvent.identifier;
    }
}

export class GetDocumentsByEntityAndTypeAnnonymusEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetDocumentsByEntityAndTypeAnnonymusEvent";
    constructor(public target: Array<FileUploadTarget>, public relatedEntityId: string) {
        super();
        this.type += GetDocumentsByEntityAndTypeAnnonymusEvent.identifier;
    }
}

export class DeleteDocumentEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeleteDocumentEvent";
    constructor(public id: string,public target: Array<FileUploadTarget>, public relatedEntityId: string) {
        super();
        this.type += DeleteDocumentEvent.identifier;
    }
}

export class DocumentUploadedEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "DocumentUploadedEvent";
    constructor(public data: FileAttachment) {
        super();
        this.type += DocumentUploadedEvent.identifier;
    }
}

export class GenerateDevizEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GenerateDevizEvent";
    constructor(public id: string) {
        super();
        this.type += GenerateDevizEvent.identifier;
    }
}

export class GenerateGDPREvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GenerateGDPREvent";
    constructor(public id: string) {
        super();
        this.type += GenerateGDPREvent.identifier;
    }
}

export class GenerateSHEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GenerateSHEvent";
    constructor(public id: string) {
        super();
        this.type += GenerateSHEvent.identifier;
    }
}

export class GeneratePVEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GeneratePVEvent";
    constructor(public id: string) {
        super();
        this.type += GeneratePVEvent.identifier;
    }
}

export class GenerateICEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GenerateICEvent";
    constructor(public id: string) {
        super();
        this.type += GenerateICEvent.identifier;
    }
}
