import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiErrorDto } from '../utils/api-error.dto';
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Draft_Dto } from '../../app-dto/draft.dto';
import { ErrorHelpers } from '../../shared/error.helpers';

class DraftApiRoutes {
    public static route_for_GET_DRAFTS() {
        return '/api/draft/all';
    }
    public static route_for_DELETE_DRAFT(draftId: string) {
        return `/api/draft/${draftId}`;
    }

    public static route_for_REGISTER_DRAFT(){
      return '/api/draft';
    }
}

@Injectable()
export class DraftApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_getDrafts(): Observable<Draft_Dto[]> {
        const url = this.configService.getAPIBaseUrl() + DraftApiRoutes.route_for_GET_DRAFTS();

        return this.http.get(url)
            .map((res: Draft_Dto[]) => {
                return res;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deleteDraft(draftId: string): Observable<Draft_Dto> {
        const url = this.configService.getAPIBaseUrl() + DraftApiRoutes.route_for_DELETE_DRAFT(draftId);

        return this.http.delete(url)
            .map((res: any) => {
                return new Draft_Dto(null);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

  u_registerDraft(carData: Draft_Dto): Observable<Draft_Dto> {
    const url = this.configService.getAPIBaseUrl() + DraftApiRoutes.route_for_REGISTER_DRAFT();

    return this.http.post(url, carData)
      .map((res: any) => {
        return new Draft_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }
}
