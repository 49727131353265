import { Store } from '@ngrx/store';
import * as store from '../../../app-store/index';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { GetServicePlatformAvailabilityEvent } from '../../../app-store/events/service-platform.events';
import {CalendarEventRelatedEntityType} from "../../../app-dto/calendar-event.dto";

export class CheckServicePlatformAvailabilityActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(startDate: Date, endDate: Date, servicePlatformId: string = null, except: string = null, relatedEntityType:CalendarEventRelatedEntityType, dataLoaded: (result: boolean) => void): any {
        this.appState.dispatch(new GetServicePlatformAvailabilityEvent(startDate, endDate, servicePlatformId, except,relatedEntityType, (result: boolean) => {
            dataLoaded(result);
        }));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

