import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import {CreateCheckoutSession, StripeConfigResponse} from "../dto/stripe.dto";
import {CreateStripeCheckoutPaymentEvent, GetStripeConfigEvent} from "../events/stripe.events";

export class CreateStripeCheckoutActionProxy extends BaseActionProxy {
  constructor(component: IWebComponent, appState: Store<store.State>) {
    super(component, appState);
  }

  canExecute(): boolean {
    return true;
  }

  execute(data:CreateCheckoutSession, callback:(data:string) => void): any {
    if (this.canExecute()) {
      this.appState.dispatch(new CreateStripeCheckoutPaymentEvent(data,callback));
    }
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }
}

