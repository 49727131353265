import { Injectable } from '@angular/core';
import * as store from '../../../app-store/index';
import { Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';

@Injectable()
export class ServifyButtonSandbox extends BaseSandbox {

    constructor(
        protected appState$: Store<store.State>
    ) {
        super(appState$);
    }

    clearSandboxState() {
    }

    initialize() {
        this.clearSandboxState();
    }
}
