import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { BaseFormComponent, BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import { AppointmentCarSandbox } from './appointment-car.sandbox';
import { AppointmentCarDi } from './appointment-car.di';
import { AppointmentCar_Dto } from '../../../core/app-dto/appointment-car.dto';
import { CarSearchComponent } from '../../../car/components/car-search/car-search.component';
import { Car_Dto } from '../../../core/app-dto/car.dto';
import { OrganizationClient_Dto } from '../../../core/app-dto/organization-client.dto';
import { CarAppointment_Dto } from '../../../core/app-dto/car-appointment.dto';
import {GetClientForCarActionProxy} from '../../../car-service/components/car-service-client/get-client-for-car.action-proxy';

@Component({
  selector: 'app-appointment-car',
  templateUrl: './appointment-car.component.html',
  styleUrls: ['./appointment-car.component.scss']
})
export class AppointmentCarComponent extends BaseFormModelComponent<Car_Dto> implements OnInit, OnDestroy {
  public getClientForCarAP: GetClientForCarActionProxy;
  public carSearch: string = '';

  @Output()
  modelSaved = new EventEmitter<AppointmentCar_Dto>();
  @Output()
  eventSelectedOutput = new EventEmitter<string>();

  @ViewChild(CarSearchComponent, { static: false })
  carSearchComponent: CarSearchComponent;

  @Input("car") set client(car: AppointmentCar_Dto) {
    this.di = new AppointmentCarDi(car);
    this.carSearch = this.di.model.registrationNumber;
  }


  constructor(public appState: Store<store.State>,
              public sandbox: AppointmentCarSandbox) {
    super(sandbox);
    this.getClientForCarAP = new GetClientForCarActionProxy(this, sandbox.appState);
  }

  public diInitialize() {
  }

  ngOnInit() {

    this.initialize((data: Car_Dto) => {
      this.di = new AppointmentCarDi(new AppointmentCar_Dto({
        registrationNumber: this.sandbox.carForClient.registrationNumber,
        bodySeries: this.sandbox.carForClient.bodySeries,
        make: this.sandbox.carForClient.make,
        model: this.sandbox.carForClient.model,
        carRelatedId: this.sandbox.carForClient.id
      }));
      this.appointmentCarSelected(data);
      if (data) {
        this.carSearch = this.sandbox.carForClient.registrationNumber;
      } else {
        this.carSearch = '';
        this.clearSearch();
      }
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();
    if (this.di.isValid()) {
      if (this.modelSaved != null) {
        this.modelSaved.emit(this.di.getModel());
      }
    }
  }

  isValid() {
    return this.di.isValid();
  }

  appointmentCarSelected(value: any) {
    const car = new Car_Dto(value);
    this.di.model.registrationNumber = car.registrationNumber;
    this.di.model.carRelatedId = car.id;
    this.di.form.controls['make'].setValue(car.make);
    this.di.form.controls['model'].setValue(car.model);
    this.di.form.controls['bodySeries'].setValue(car.bodySeries);

    if (this.modelSaved != null) {
      this.modelSaved.emit(this.di.getModel());
    }

    if (car.id) {
      if (!this.sandbox.isClientComponentTriggered) {
        this.getClientForCarAP.execute(car.id);
      }
    } else {
      this.sandbox.resetClientForCar();
    }
  }

  clearSearch() {
    if (this.carSearchComponent) {
      this.carSearchComponent.clearSelect();
    }
  }
}
