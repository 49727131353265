import {Component, OnInit} from '@angular/core';
import {isSameDay, isSameMonth} from 'date-fns';
import {Subject} from 'rxjs';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarMonthViewDay,
  CalendarView,
  CalendarWeekViewBeforeRenderEvent
} from 'angular-calendar';
import {BsModalService} from 'ngx-bootstrap';
import {AddEditCalendarEventComponent} from '../add-edit-calendar-event/add-edit-calendar-event.component';
import {CalendarEvent_Dto, CalendarEventType} from 'src/app/core/app-dto/calendar-event.dto';
import {DateTimeService} from 'src/app/core/core/services/date-time.service';
import {GetCalendarEventsActionProxy} from './get-calendar-events.action-proxy';
import {DeleteCalendarEventActionProxy} from './delete-calendar-event.action-proxy';
import {BaseWebComponent} from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import {UploadInputEvent} from '../../../core/app-store/index';
import {Store} from '@ngrx/store';
import {CalendarSandbox} from './calendar.sandbox';
import {ResetSection} from 'src/app/core/app-store/events/base.events';
import {CalendarEventDI} from './calendar.di';
import * as moment from 'moment';
import {ConfirmationDialogService} from 'src/app/core/core/dialog/confirmation-dialog.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {DeviceDetectorService} from 'ngx-device-detector';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {CustomDateFormatter} from './calendar-custom-date-formatter.provider';
import {Organization} from "../../../core/app-dto/organization.dto";
import {DateHelpers} from "../../../core/shared/date.helpers";
import {ConfirmCalendarEventActionProxy} from './confirm-calendar-event.action-proxy';
import {FileWrapper} from "../../../core/upload/components/upload-notification/upload-notification.di";
import {AsyncJob, AsyncJobType, FileToUpload, FileUploadTarget} from "../../../core/app-dto/core.dto";
import {UploadInput} from "../../../core/upload/components/upload-notification/upload-notification.component";
import {isValidArrayAndHasElements, isValidIcsFile, isValidObject} from "../../../core/shared/helpers/common.helpers";
import {NotifierService} from "angular-notifier";
import {AddImportTaskActionProxy} from "./add-import-task.action-proxy";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class CalendarComponent extends BaseWebComponent implements OnInit {
  public getCalendarEventsAP: GetCalendarEventsActionProxy;
  public deleteCalendarEventAP: DeleteCalendarEventActionProxy;
  public confirmCalendarEventAP: ConfirmCalendarEventActionProxy;
  public addImportAP: AddImportTaskActionProxy;

  public minStartHour = 6;
  public minStartMinute = 0;
  public maxEndHour = 20;
  public maxEndMinute = 0;


  locale: string = 'ro';

  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = true;

  public startDate: Date = null;
  public endDate: Date = null;
  public eventType: string = null;
  public searchValue: string = null;

  public isMobile: boolean = false;
  public isTablet: boolean = false;
  public isDesktopDevice: boolean = true;

  modelChanged: Subject<string> = new Subject<string>();

  public currentOrganization: Organization = null;

  constructor(
    public appState: Store<store.State>,
    public sandbox: CalendarSandbox,
    private modalService: BsModalService,
    public dateTimeService: DateTimeService,
    public confirmationDialogService: ConfirmationDialogService,
    private spinner: NgxSpinnerService,
    private deviceService: DeviceDetectorService,
    private notifierService: NotifierService
  ) {
    super(sandbox, ResetSection.Calendar);
    this.getCalendarEventsAP = new GetCalendarEventsActionProxy(this, sandbox.appState, this.spinner);
    this.deleteCalendarEventAP = new DeleteCalendarEventActionProxy(this, sandbox.appState, this.spinner);
    this.confirmCalendarEventAP = new ConfirmCalendarEventActionProxy(this, sandbox.appState, this.spinner);
    this.addImportAP = new AddImportTaskActionProxy(this, sandbox.appState);

    this.modelChanged.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(searchQuery => {
        this.searchValue = searchQuery;
        this.getCalendarEventsAP.execute(this.startDate, this.endDate, this.eventType, this.searchValue);
      });

    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();

    this.view = CalendarView.Day;
  }

  ngOnInit() {
    this.updateViewDate();
    this.getCalendarEventsAP.execute(this.startDate, this.endDate, this.eventType, this.searchValue);

    this.initialize((data: Array<CalendarEvent_Dto>, currentOrganization: Organization) => {

        this.currentOrganization = currentOrganization;
        if (isValidObject(this.currentOrganization.workingHoursSettings) &&
          isValidArrayAndHasElements(this.currentOrganization.workingHoursSettings.workingHours)) {
          let newMinHour = null;
          let newMinMinute = null;
          let newMaxHour = null;
          let newMaxMinute = null;
          this.currentOrganization.workingHoursSettings.workingHours.forEach((item) => {
            if (isValidArrayAndHasElements(item.hoursRange)) {
              item.hoursRange.forEach((range) => {
                  if (newMinHour == null) {
                    newMinHour = range.fromHours;
                    newMinMinute = range.fromMinutes;
                  }
                  else if (newMinHour > range.fromHours) {
                    newMinHour = range.fromHours;
                    newMinMinute = range.fromMinutes;
                  }
                  else {
                    if (newMinHour == range.fromHours && newMinMinute > range.fromMinutes)
                      newMinMinute = range.fromMinutes;
                  }

                  if (newMaxHour == null) {
                    newMaxHour = range.toHours;
                    newMaxMinute = range.toMinutes;
                  }
                  else if (newMaxHour < range.toHours) {
                    newMaxHour = range.toHours;
                    newMaxMinute = range.toMinutes;
                  } else {
                    if (newMaxHour == range.toHours && newMaxMinute < range.toMinutes)
                      newMaxMinute = range.toMinutes;
                  }
                }
              );
            }
          });
          if (newMinHour != null) this.minStartHour = newMinHour;
          if (newMinMinute != null) this.minStartMinute = newMinMinute;
          if (newMaxHour != null) this.maxEndHour = newMaxHour;
          if (newMaxMinute != null) this.maxEndMinute = newMaxMinute;
        }

        this.events = data.map(r => new CalendarEventDI(r,
          (event: CalendarEventDI) => {

            let type = event.model.type == CalendarEventType.Appointment ? "programare" : "notificare";

            let initialState = {
              event: event.getModel(),
              title: 'Editare ' + type
            }

            const modalRef = this.modalService.show(AddEditCalendarEventComponent, {
              initialState,
              backdrop: 'static',
              keyboard: false,
              class: 'modal-xl'
            });
          },
          (event: CalendarEventDI) => {
            let type = event.model.type == CalendarEventType.Appointment ? "programare" : "notificare";
            this.confirmationDialogService.confirm(
              "Stergere " + type,
              "Urmeaza sa stergeti evenimentul <b>" + event.title + "</b>. Sunteti sigur?",
              'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
              if (confirmed) {
                this.deleteCalendarEventAP.execute(event.model.id);
              }
            });
          },
          (event: CalendarEventDI) => {
            let type = event.model.type == CalendarEventType.Appointment ? "programare" : "notificare";
            this.confirmationDialogService.confirm(
              "Confirmare " + type,
              "Urmeaza sa confirmati evenimentul <b>" + event.title + "</b>. Sunteti sigur?",
              'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
              if (confirmed) {
                this.confirmCalendarEventAP.execute(event.model.id);
              }
            });
          }
        ));

        this.dataLoaded = true;
      }
    )
    ;
  }

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)
    ) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  setView(view
            :
            CalendarView
  ) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  updateViewDate() {
    switch (this.view) {
      case CalendarView.Day: {
        this.startDate = moment(this.viewDate).startOf('day').toDate();
        this.endDate = moment(this.viewDate).endOf('day').toDate();
        break;
      }
      case CalendarView.Week: {
        this.startDate = moment(this.viewDate).startOf('week').toDate();
        this.endDate = moment(this.viewDate).endOf('week').toDate();
        break;
      }
      case CalendarView.Month: {
        this.startDate = moment(this.viewDate).startOf('month').toDate();
        this.endDate = moment(this.viewDate).endOf('month').toDate();
        break;
      }
    }

    this.getCalendarEventsAP.execute(this.startDate, this.endDate, this.eventType, this.searchValue);
  }

  handleEvent(action: string, event: CalendarEventDI): void {
    let initialState = {
      event: event.getModel(),
      title: 'Editare eveniment'
    }

    const modalRef = this.modalService.show(AddEditCalendarEventComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
  }

  addNewCalendarEvent() {
    this.addCalendarEvent(new Date());
  }

  addCalendarEvent(date: Date) {
    let event = new CalendarEvent_Dto(null);
    event.startDate = date;
    event.endDate = date;
    let initialState = {
      event: event,
      title: 'Adauga programare'
    }
    const modalRef = this.modalService.show(AddEditCalendarEventComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
  }

  onEventTypeChange(data: any) {
    if (data == "null")
      this.eventType = null;
    else
      this.eventType = CalendarEventType[parseInt(data)];
    this.getCalendarEventsAP.execute(this.startDate, this.endDate, this.eventType, this.searchValue);
  }

  searchEvent(data: any) {
    if (ObjectValidators.isValidString(data))
      this.modelChanged.next(data);
    if (data === "")
      this.modelChanged.next(null);
    return false;
  }

  beforeMonthViewRender({body}: { body: CalendarMonthViewDay[] }): void {
    let daysOff: Array<string> = this.currentOrganization != null && this.currentOrganization.workingHoursSettings != null && this.currentOrganization.workingHoursSettings.daysOff != null ?
      this.currentOrganization.workingHoursSettings.daysOff.map(r => DateHelpers.dateToString(r.date)) : [];
    body.forEach((day) => {
      if (daysOff.indexOf(DateHelpers.dateToString(day.date)) > -1) {
        day.cssClass = 'cal-disabled';
      }
      else day.cssClass = 'none';
    });
  }

  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
    let daysOff: Array<string> = this.currentOrganization != null && this.currentOrganization.workingHoursSettings != null && this.currentOrganization.workingHoursSettings.daysOff != null ?
      this.currentOrganization.workingHoursSettings.daysOff.map(r => DateHelpers.dateToString(r.date)) : [];
    let workingDaysIntervals: Array<DateRange> = [];
    for (let i = 0; i < renderEvent.header.length; i++) {
      let date: Date = renderEvent.header[i].date;
      let hoursRanges = this.currentOrganization != null && this.currentOrganization.workingHoursSettings != null && this.currentOrganization.workingHoursSettings.workingHours != null ?
        this.currentOrganization.workingHoursSettings.workingHours[i].hoursRange : [];
      if (ObjectValidators.isValidNonEmptyArray(hoursRanges)) {
        hoursRanges.forEach(f => {
          let range: DateRange = new DateRange();
          range.start = new Date(date.getFullYear(), date.getMonth(), date.getDate(), f.fromHours, f.fromMinutes, 0, 0);
          range.end = new Date(date.getFullYear(), date.getMonth(), date.getDate(), f.toHours, f.toMinutes, 0, 0);
          workingDaysIntervals.push(range);
        });
      }
    }
    renderEvent.hourColumns.forEach((hourColumn) => {
      if (daysOff.indexOf(DateHelpers.dateToString(hourColumn.date)) > -1) {
        hourColumn.hours.forEach((hour) => {
          hour.segments.forEach((segment) => {
            segment.cssClass = 'cal-disabled';
          });
        });
      } else {
        hourColumn.hours.forEach((hour) => {
          hour.segments.forEach((segment) => {
            let startHourDate = segment.date;
            let endHourDate = moment(segment.date).add(30, 'm').toDate();
            let foundWorkingRange = workingDaysIntervals.find(f => {
              let result = f.start <= startHourDate && f.end >= endHourDate;
              return result;
            });
            let cssClass = 'none';
            if (foundWorkingRange == null) cssClass = 'cal-disabled';
            segment.cssClass = cssClass;
          });
        });
      }
    });
    setTimeout(() => {
      $(".cal-disabled").parent().removeClass("cal-disabled");
      $(".cal-disabled").parent().addClass("cal-disabled");
    }, 100);
  }

  beforeDayViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
    let daysOff: Array<string> = this.currentOrganization != null && this.currentOrganization.workingHoursSettings != null && this.currentOrganization.workingHoursSettings.daysOff != null ?
      this.currentOrganization.workingHoursSettings.daysOff.map(r => DateHelpers.dateToString(r.date)) : [];
    let workingDaysIntervals: Array<DateRange> = [];

    let date: Date = renderEvent.header[0].date;
    let hourDayIndex = renderEvent.header[0].day - 1;
    if (hourDayIndex < 0) hourDayIndex = 6;
    let hoursRanges = this.currentOrganization != null && this.currentOrganization.workingHoursSettings != null && this.currentOrganization.workingHoursSettings.workingHours != null ?
      this.currentOrganization.workingHoursSettings.workingHours[hourDayIndex].hoursRange : [];
    if (ObjectValidators.isValidNonEmptyArray(hoursRanges)) {
      hoursRanges.forEach(f => {
        let range: DateRange = new DateRange();
        range.start = new Date(date.getFullYear(), date.getMonth(), date.getDate(), f.fromHours, f.fromMinutes, 0, 0);
        range.end = new Date(date.getFullYear(), date.getMonth(), date.getDate(), f.toHours, f.toMinutes, 0, 0);
        workingDaysIntervals.push(range);
      });
    }
    renderEvent.hourColumns.forEach((hourColumn) => {
      if (daysOff.indexOf(DateHelpers.dateToString(hourColumn.date)) > -1) {
        hourColumn.hours.forEach((hour) => {
          hour.segments.forEach((segment) => {
            segment.cssClass = 'cal-disabled';
          });
        });
      } else {
        hourColumn.hours.forEach((hour) => {

          hour.segments.forEach((segment) => {
            let startHourDate = segment.date;
            let endHourDate = moment(segment.date).add(30, 'm').toDate();
            let foundWorkingRange = workingDaysIntervals.find(f => {
              let result = f.start <= startHourDate && f.end >= endHourDate;
              return result;
            });
            let cssClass = 'none';
            if (foundWorkingRange == null) cssClass = 'cal-disabled';
            segment.cssClass = cssClass;
          });

        });
      }
    });
    setTimeout(() => {
      $(".cal-disabled").parent().removeClass("cal-disabled");
      $(".cal-disabled").parent().addClass("cal-disabled");
    }, 100);
  }

  startImport(data: FileList) {
    let files: Array<FileWrapper>;
    let validFiles = true;
    let arrayData = Array.from(data);
    arrayData.forEach(r => {
      if (isValidIcsFile(r) == false) {
        //@Todo: add notification error
        validFiles = false;
      }
    });
    if (validFiles) {
      files = arrayData.map(r => {
        let result = new FileWrapper();
        result.file = r;
        result.id = new Date().getTime().toString();
        return result;
      });
      this.addImportAP.execute(new AsyncJob({
        type: AsyncJobType.CalendarImport
      }), (data: AsyncJob) => {
        if (isValidObject(data) == false) return;
        if (files != null && files.length > 0) {
          var timestamp = new Date().getTime();
          var filesToUpload = new Array<FileToUpload>();

          for (var i = 0; i < files.length; i++) {
            files[i].id = undefined;
            timestamp = timestamp + 1;
            filesToUpload.push(new FileToUpload(timestamp.toString(), files[i].file, FileUploadTarget.AsyncJob, data.id));
          }
          if (filesToUpload.length > 0) {
            this.appState.dispatch(new UploadInputEvent(new UploadInput(filesToUpload, FileUploadTarget.AsyncJob)));
          }
        }
      });

    } else {
      this.notifierService.notify('error', 'Tipul fisierului nu este acceptat!');
    }


  }
}

export class DateRange {
  public start: Date;
  public end: Date;
}
