import { Component, OnInit } from '@angular/core';
import { InvoiceRequestType } from '../invoice/invoice.component';
import { BaseWebComponentWithBreadcrumb } from '../../../core/shared/base/base.component';
import { InvoiceListOverviewSandbox } from './invoice-list-overview.sandbox';
import { ResetSection } from '../../../core/app-store/events/base.events';

@Component({
  selector: 'app-invoice-list-overview',
  templateUrl: './invoice-list-overview.component.html',
  styleUrls: ['./invoice-list-overview.component.scss']
})
export class InvoiceListOverviewComponent extends BaseWebComponentWithBreadcrumb implements OnInit {

  public invoiceRequestType: InvoiceRequestType = InvoiceRequestType.SuperAdmin;

  constructor( public sandbox: InvoiceListOverviewSandbox) {
    super(sandbox, ResetSection.Invoice);
  }

  ngOnInit() {
    this.setBreadcrumbAP.execute([{text: 'Acasa', url: '/dashboard'}, {text: 'Facturi'}]);
  }
}
