import { Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import { map, switchMap } from 'rxjs/operators';
import {
    GetServicePlatformsPagedResultEvent,
    GetServicePlatformEvent,
    RegisterServicePlatformEvent,
    DeleteServicePlatformEvent,
    ActivateServicePlatformEvent,
    DeactivateServicePlatformEvent,
    EditServicePlatformEvent,
    GetServicePlatformsListEvent,
    GetServicePlatformAvailabilityEvent
} from 'src/app/core/app-store/events/service-platform.events';
import {
  Do_GenericSuccessEvent,
  Do_GenericErrorEvent,
  EventWithScreenLoading,
  SeamlessEvent
} from 'src/app/core/app-store/events/base.events';
import { PagedResult } from 'src/app/core/shared/datatable/datatable.helpers';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { ServicePlatform_Dto } from 'src/app/core/app-dto/service-platform.dto';
import { ServicePlatformApiService } from 'src/app/core/api-integration/services/service-platform-api.service';


@Injectable()
export class ServicePlatformUIEventProcessor {

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private servicePlatformApiService: ServicePlatformApiService
    ) {
    }

    @Effect()
    GetServicePlatformsPagedResultEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetServicePlatformsPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetServicePlatformsPagedResultEvent.identifier),
            map((action: GetServicePlatformsPagedResultEvent) => action),
            switchMap((action: GetServicePlatformsPagedResultEvent) => {
                return new Observable((observer) => {
                    this.servicePlatformApiService.u_getFilteredServicePlatformsPaginated(action.parameters)
                        .subscribe((res: PagedResult<ServicePlatform_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<ServicePlatform_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    getServicePlatform$: Observable<any> = this.actions$
        .pipe(
            ofType(GetServicePlatformEvent.identifier, EventWithScreenLoading.identifier + GetServicePlatformEvent.identifier),
            map((action: GetServicePlatformEvent) => action),
            switchMap((action: GetServicePlatformEvent) => {
                return new Observable((observer) => {
                    this.servicePlatformApiService.u_getServicePlatform(action.id)
                        .subscribe((res: ServicePlatform_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<ServicePlatform_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );


    @Effect()
    getServicePlatformList$: Observable<any> = this.actions$
        .pipe(
            ofType(GetServicePlatformsListEvent.identifier, SeamlessEvent.identifier + GetServicePlatformsListEvent.identifier),
            map((action: GetServicePlatformsListEvent) => action),
            switchMap((action: GetServicePlatformsListEvent) => {
                return new Observable((observer) => {
                    this.servicePlatformApiService.u_getServicePlatformList()
                        .subscribe((res: Array<ServicePlatform_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Array<ServicePlatform_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doRegisterServicePlatform$: Observable<any> = this.actions$
        .pipe(
            ofType(RegisterServicePlatformEvent.identifier, EventWithScreenLoading.identifier + RegisterServicePlatformEvent.identifier),
            map((action: RegisterServicePlatformEvent) => action),
            switchMap((action: RegisterServicePlatformEvent) => {
                return new Observable((observer) => {
                    this.servicePlatformApiService.u_registerServicePlatform(action.model)
                        .subscribe((res: ServicePlatform_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<ServicePlatform_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doEditServicePlatform$: Observable<any> = this.actions$
        .pipe(
            ofType(EditServicePlatformEvent.identifier, EventWithScreenLoading.identifier + EditServicePlatformEvent.identifier),
            map((action: EditServicePlatformEvent) => action),
            switchMap((action: EditServicePlatformEvent) => {
                return new Observable((observer) => {
                    this.servicePlatformApiService.u_editServicePlatform(action.model)
                        .subscribe((res: ServicePlatform_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<ServicePlatform_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doDeleteServicePlatform$: Observable<any> = this.actions$
        .pipe(
            ofType(DeleteServicePlatformEvent.identifier, EventWithScreenLoading.identifier + DeleteServicePlatformEvent.identifier),
            map((action: DeleteServicePlatformEvent) => action),
            switchMap((action: DeleteServicePlatformEvent) => {
                return new Observable((observer) => {
                    this.servicePlatformApiService.u_deleteServicePlatform(action.id)
                        .subscribe((res: ServicePlatform_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<ServicePlatform_Dto>(res, action));
                            if(ObjectValidators.isValidObject(action.callback)) action.callback();
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doActivateServicePlatform$: Observable<any> = this.actions$
        .pipe(
            ofType(ActivateServicePlatformEvent.identifier, EventWithScreenLoading.identifier + ActivateServicePlatformEvent.identifier),
            map((action: ActivateServicePlatformEvent) => action),
            switchMap((action: ActivateServicePlatformEvent) => {
                return new Observable((observer) => {
                    this.servicePlatformApiService.u_activateServicePlatform(action.id)
                        .subscribe((res: ServicePlatform_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<ServicePlatform_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doDeactivateServicePlatform$: Observable<any> = this.actions$
        .pipe(
            ofType(DeactivateServicePlatformEvent.identifier, EventWithScreenLoading.identifier + DeactivateServicePlatformEvent.identifier),
            map((action: DeactivateServicePlatformEvent) => action),
            switchMap((action: DeactivateServicePlatformEvent) => {
                return new Observable((observer) => {
                    this.servicePlatformApiService.u_deactivateServicePlatform(action.id)
                        .subscribe((res: ServicePlatform_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<ServicePlatform_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    getServicePlatformAvailability$: Observable<any> = this.actions$
        .pipe(
            ofType(GetServicePlatformAvailabilityEvent.identifier, SeamlessEvent.identifier + GetServicePlatformAvailabilityEvent.identifier),
            map((action: GetServicePlatformAvailabilityEvent) => action),
            switchMap((action: GetServicePlatformAvailabilityEvent) => {
                return new Observable((observer) => {
                    this.servicePlatformApiService.u_getServicePlatformAvailability(action.startDate, action.endDate, action.servicePlatformId, action.except, action.relatedEntityType)
                        .subscribe((res: boolean) => {
                            if (ObjectValidators.isValidObject(res)) {
                                action.callback(res);
                                observer.next(new Do_GenericSuccessEvent<boolean>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            action.callback(false);
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
}
