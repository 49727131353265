import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared/shared.module';
import { DataTablesModule } from 'angular-datatables';
import { BsDatepickerModule, ModalModule } from 'ngx-bootstrap';
import { DepartmentModule } from '../department/department.module';

import { MechanicsListComponent } from './components/mechanics-list/mechanics-list.component';
import { MechanicDetailsComponent } from './components/mechanic-details/mechanic-details.component';
import { SaveMechanicComponent } from './components/save-mechanic/save-mechanic.component';

import { SaveMechanicSandbox } from './components/save-mechanic/save-mechanic.sandbox';
import { MechanicDetailsSandbox } from './components/mechanic-details/mechanic-details.sandbox';
import { MechanicsListSandbox } from './components/mechanics-list/mechanics-list.sandbox';
import { DeactivateMechanicComponent } from './components/deactivate-mechanic/deactivate-mechanic.component';
import { ActivateMechanicComponent } from './components/activate-mechanic/activate-mechanic.component';
import { EditMechanicHistoryComponent } from './components/edit-mechanic-history/edit-mechanic-history.component';
import { ActivateMechanicSandbox } from './components/activate-mechanic/activate-mechanic.sandbox';
import { DeactivateMechanicSandbox } from './components/deactivate-mechanic/deactivate-mechanic.sandbox';
import { EditMechanicHistorySandbox } from './components/edit-mechanic-history/edit-mechanic-history.sandbox';
import { AddUserAccountComponent } from './components/add-user-account/add-user-account.component';
import {AddUserAccountSandbox} from "./components/add-user-account/add-user-account.sandbox";
import { MechanicListWithStatusComponent } from './components/mechanic-list-with-status/mechanic-list-with-status.component';
import {MechanicListWithStatusSandbox} from "./components/mechanic-list-with-status/mechanic-list-with-status.sandbox";

@NgModule({
  declarations: [
    MechanicsListComponent,
    MechanicDetailsComponent,
    SaveMechanicComponent,
    DeactivateMechanicComponent,
    ActivateMechanicComponent,
    EditMechanicHistoryComponent,
    AddUserAccountComponent,
    MechanicListWithStatusComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    SharedModule,
    DataTablesModule,
    BsDatepickerModule,
    ModalModule.forRoot(),
    DepartmentModule
  ],
  providers: [
    MechanicsListSandbox,
    MechanicDetailsSandbox,
    SaveMechanicSandbox,
    DeactivateMechanicSandbox,
    ActivateMechanicSandbox,
    EditMechanicHistorySandbox,
    AddUserAccountSandbox,
    MechanicListWithStatusSandbox
  ],
  entryComponents: [
    SaveMechanicComponent,
    ActivateMechanicComponent,
    DeactivateMechanicComponent,
    EditMechanicHistoryComponent,
    AddUserAccountComponent
  ],
  exports :[MechanicListWithStatusComponent]
})
export class MechanicModule { }
