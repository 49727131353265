import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FileAttachment } from 'src/app/core/app-dto/core.dto';
import { FileWrapper } from 'src/app/core/upload/components/upload-notification/upload-notification.di';

export class CarServicePhotosDI extends BaseFormDI<Array<FileWrapper>> {

    constructor(data: Array<FileWrapper> = []) {
        super(data);
        this.initForm();
        
    }

    getModel(): Array<FileWrapper> {
        return this.model;
    }

    initForm() {
    }
}
