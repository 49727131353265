import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiErrorDto } from '../utils/api-error.dto';
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {ServicePlatform_Dto} from '../../app-dto/service-platform.dto';
import { ErrorHelpers } from '../../shared/error.helpers';

class ServicePlatformStatusApiRoutes {
    public static route_for_GET_SERVICE_PLATFORM_STATUSES_PAGINATED() {
        return '/api/servicePlatform/list/extended';
    }
}

@Injectable()
export class ServicePlatformStatusApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_getFilteredServicePlatformStatusesPaginated(): Observable<Array<ServicePlatform_Dto>> {
        const url = this.configService.getAPIBaseUrl() + ServicePlatformStatusApiRoutes.route_for_GET_SERVICE_PLATFORM_STATUSES_PAGINATED() ;

        return this.http.get(url)
            .map((res: Array<ServicePlatform_Dto>) => {
                return res.map(r => new  ServicePlatform_Dto(r));
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
}
