import {PagedResult} from "../../shared/datatable/datatable.helpers";
import {Action} from "@ngrx/store";
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from "../events/base.events";
import {Invoice_Dto, InvoiceDetails_Dto} from '../../app-dto/invoice.dto';
import {
  GetInvoiceEvent,
  GetInvoicesPagedResultEvent,
  GetOrganizationInvoiceEvent,
  GetOrganizationInvoicesPagedResultEvent,
  GetOrganizationSubscriptionInvoiceEvent,
  GetOrganizationSubscriptionInvoicesPagedResultEvent,
  GetUnauthorizedInvoiceEvent,
  MarkOrgInvoiceAsNotPaidEvent,
  MarkOrgInvoiceAsPaidEvent,
  MarkSubInvoiceAsNotPaidEvent,
  MarkSubInvoiceAsPaidEvent,
  RegenerateOrgInvoiceEvent,
  UpdateInvoiceDeadlineEvent
} from '../events/invoices.events';
import {isValidObject} from "../../shared/helpers/common.helpers";

export interface InvoiceState {
  lastRefreshTime: number;
  invoice: InvoiceDetails_Dto;
  invoicesPagedResult: PagedResult<Invoice_Dto>;
}

export const INITIAL_STATE: InvoiceState = Object.assign({}, {
  lastRefreshTime: 0,
  invoice: null,
  invoicesPagedResult: new PagedResult<Invoice_Dto>([]),
});


export function reducer(state = INITIAL_STATE, action: Action): InvoiceState {
  if (!action) return state;
  var actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {

    // Get Invoice

    case Do_ResetStateForSection.identifier + ResetSection.Invoice: {
      return Object.assign({}, state, {invoice: null});
    }

    case Do_GenericSuccessEvent.identifier + MarkSubInvoiceAsNotPaidEvent.identifier:
    case Do_GenericSuccessEvent.identifier + MarkSubInvoiceAsPaidEvent.identifier:
    case Do_GenericSuccessEvent.identifier + MarkOrgInvoiceAsPaidEvent.identifier:
    case Do_GenericSuccessEvent.identifier + MarkOrgInvoiceAsNotPaidEvent.identifier: {
      const invoiceData = (<Do_GenericSuccessEvent<Invoice_Dto>>action).data;
      if (isValidObject(state.invoice) && isValidObject(invoiceData) && state.invoice.invoice.id == invoiceData.id) {
        state.invoice.invoice = invoiceData;
        state.invoice = new InvoiceDetails_Dto(state.invoice);
        return Object.assign({}, state);
      }
    }

    case Do_GenericErrorEvent.identifier + GetOrganizationInvoiceEvent.identifier:
    case Do_GenericErrorEvent.identifier + GetOrganizationSubscriptionInvoiceEvent.identifier:
    case Do_GenericErrorEvent.identifier + GetInvoiceEvent.identifier:
    case Do_GenericErrorEvent.identifier + UpdateInvoiceDeadlineEvent.identifier: {
      return Object.assign({}, state, {invoice: null});
    }


    case Do_GenericSuccessEvent.identifier + GetInvoiceEvent.identifier:
    case Do_GenericSuccessEvent.identifier + GetOrganizationSubscriptionInvoiceEvent.identifier:
    case Do_GenericSuccessEvent.identifier + GetOrganizationInvoiceEvent.identifier:
    case Do_GenericSuccessEvent.identifier + UpdateInvoiceDeadlineEvent.identifier: {
      var invoiceData = (<Do_GenericSuccessEvent<InvoiceDetails_Dto>>action).data;
      return Object.assign({}, state, {invoice: invoiceData});
    }


    case Do_GenericSuccessEvent.identifier + GetUnauthorizedInvoiceEvent.identifier: {
      var invoiceData = (<Do_GenericSuccessEvent<InvoiceDetails_Dto>>action).data;
      return Object.assign({}, state, {invoice: invoiceData});
    }

    case Do_GenericErrorEvent.identifier + GetUnauthorizedInvoiceEvent.identifier: {
      return Object.assign({}, state, {invoice: null});
    }

    case Do_ResetStateForSection.identifier + ResetSection.Invoices: {
      return Object.assign({}, state, {invoicesPagedResult: new PagedResult<Invoice_Dto>([])});
    }

    case GetOrganizationSubscriptionInvoicesPagedResultEvent.identifier:
    case GetInvoicesPagedResultEvent.identifier:
    case GetOrganizationInvoicesPagedResultEvent.identifier: {
      if ((<GetOrganizationInvoicesPagedResultEvent>action).withReset) {
        state.invoicesPagedResult = new PagedResult<Invoice_Dto>([]);
        return Object.assign({}, state);
      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetOrganizationSubscriptionInvoicesPagedResultEvent.identifier:
    case Do_GenericSuccessEvent.identifier + GetInvoicesPagedResultEvent.identifier:
    case Do_GenericSuccessEvent.identifier + GetOrganizationInvoicesPagedResultEvent.identifier: {
      var data = (<Do_GenericSuccessEvent<PagedResult<Invoice_Dto>>>action).data;
      return Object.assign({}, state,{invoicesPagedResult:new PagedResult<Invoice_Dto>(data)});
    }

    case Do_GenericErrorEvent.identifier + GetOrganizationSubscriptionInvoicesPagedResultEvent.identifier:
    case Do_GenericErrorEvent.identifier + GetInvoicesPagedResultEvent.identifier:
    case Do_GenericErrorEvent.identifier + GetOrganizationInvoicesPagedResultEvent.identifier: {
      state.invoicesPagedResult = new PagedResult<Invoice_Dto>([]);
      return Object.assign({}, state);
    }

    // /Get Invoices

    default: {
      return state;
    }
  }
}

export const getTimestampCallback = (state: InvoiceState) => state.lastRefreshTime;
export const getInvoiceCallback = (state: InvoiceState) => state.invoice;
export const getInvoicesPagedResultCallback = (state: InvoiceState) => state.invoicesPagedResult;
