import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { BaseFormComponent } from 'src/app/core/shared/base/base.component';
import { CarService_Dto } from 'src/app/core/app-dto/car-service.dto';
import { CarServiceSearchSandbox } from './car-service-search.sandbox';
import { CarServiceSearchDI } from './car-service-search.di';
import { Car_Dto } from 'src/app/core/app-dto/car.dto';
import { OrganizationClient_Dto } from 'src/app/core/app-dto/organization-client.dto';
import { OrganizationClientSearchComponent } from 'src/app/organization-client/components/organization-client-search/organization-client-search.component';
import { CarSearchComponent } from 'src/app/car/components/car-search/car-search.component';
import { CalendarEvent_Dto } from 'src/app/core/app-dto/calendar-event.dto';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { EventSearchComponent } from 'src/app/calendar/components/event-search/event-search.component';

@Component({
  selector: 'app-car-service-search',
  templateUrl: './car-service-search.component.html',
  styleUrls: ['./car-service-search.component.scss']
})
export class CarServiceSearchComponent extends BaseFormComponent implements OnInit, OnDestroy {

  public clientSearch: string = '';
  public carSearch: string = '';

  @Output()
  modelSaved = new EventEmitter<CarService_Dto>();
  @Output()
  eventSelectedOutput = new EventEmitter<string>();
  @Input("carId") carId: string;

  @ViewChild(CarSearchComponent, { static: false }) carSearchComponent: CarSearchComponent;
  @ViewChild(OrganizationClientSearchComponent, { static: false }) organizationClientSearchComponent: OrganizationClientSearchComponent;
  @ViewChild(EventSearchComponent, { static: false }) eventSearchComponent: EventSearchComponent;

  constructor(
    public appState: Store<store.State>,
    public sandbox: CarServiceSearchSandbox
  ) {
    super(sandbox);
  }

  ngOnInit() {
    this.di = new CarServiceSearchDI(null);
  }

  ngOnDestroy() {
    this.destroy();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();
    if (this.di.isValid()) {
      if (this.modelSaved != null) {
        this.modelSaved.emit(this.di.getModel());
      }
    }
  }

  isValid() {
    return this.di.isValid();
  }

  carSelected(value: Car_Dto) {
    this.di.model.car = new Car_Dto(value);
    if (this.modelSaved != null) {
      this.modelSaved.emit(this.di.getModel());
    }
  }

  organizationClientSelected(value: OrganizationClient_Dto) {
    this.di.model.client = new OrganizationClient_Dto(value);
    if (this.modelSaved != null) {
      this.modelSaved.emit(this.di.getModel());
    }
  }

  eventSelected(value: CalendarEvent_Dto) {
    if (ObjectValidators.isValidObject(value)) {
      this.clientSearch = value.clientName;
      this.carSearch = value.carNumber;
      this.di.model.carServiceEntryData.clientRequests = value.description;
      this.di.model.car.registrationNumber = value.carNumber;
      this.di.model.client.name = value.clientName;
      this.di.model.client.phoneNumber = value.clientPhoneNumber;
      if (this.modelSaved != null) {
        this.modelSaved.emit(this.di.getModel());
      }
      if (this.eventSelectedOutput != null) {
        this.eventSelectedOutput.emit(value.id);
      }
    } else {
      this.clientSearch = '';
      this.carSearch = '';
      if (this.eventSelectedOutput != null) {
        this.eventSelectedOutput.emit(value.id);
      }
    }

  }

  clearSearch() {
    this.organizationClientSearchComponent.clearSelect();
    this.carSearchComponent.clearSelect();
    this.eventSearchComponent.clearSelect();
  }
}
