import {SeamlessEvent, UIGeneratedEvent} from "./base.events";
import {ReportingGroupinOption} from "../../app-dto/reporting.dto";

export class GetProfitReportingEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetProfitReportingEvent";

  constructor(public groupingOption:ReportingGroupinOption, public startDate:Date, public endDate:Date,public callback:()=>void) {
    super();
    this.type += GetProfitReportingEvent.identifier;
  }
}

export class GetProvidersReportingEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetProvidersReportingEvent";

  constructor( public startDate:Date, public endDate:Date,public providerId:string,public callback:()=>void) {
    super();
    this.type += GetProvidersReportingEvent.identifier;
  }
}
export class GetProvidersListReportingEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetProvidersListReportingEvent";

  constructor( public startDate:Date, public endDate:Date,public providerId:string,public callback:()=>void) {
    super();
    this.type += GetProvidersListReportingEvent.identifier;
  }
}
export class GetMechanicsReportingEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetMechanicsReportingEvent";

  constructor(public startDate:Date, public endDate:Date,public callback:()=>void) {
    super();
    this.type += GetMechanicsReportingEvent.identifier;
  }
}
export class GetOrdersReportingEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetOrdersReportingEvent";

  constructor(public groupingOption:ReportingGroupinOption, public startDate:Date, public endDate:Date,public callback:()=>void) {
    super();
    this.type += GetOrdersReportingEvent.identifier;
  }
}
