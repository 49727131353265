import { ObjectValidators } from "./object.validators";
import { Address, User_Dto } from "../app-dto/core.dto";
import {isValidString} from "./helpers/common.helpers";

export class StringHelpers {
  public static doGuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  public static computeUserAddress(user: User_Dto): string {
    let computedAddress = '';
    if (ObjectValidators.isValidObject(user) && ObjectValidators.isValidObject(user.address)) {
      computedAddress = StringHelpers.computeAddress(user.address);
    }
    return computedAddress;
  }

  public static computeAddress(address: Address) {
    let computedAddress = '';
    if (ObjectValidators.isValidObject(address)) {
      const addressArray: Array<string> = [];

      if (!ObjectValidators.isEmptyString(address.address1))
        addressArray.push(address.address1);

      if (!ObjectValidators.isEmptyString(address.city))
        addressArray.push(address.city);

      if (ObjectValidators.isValidObject(address.region) && !ObjectValidators.isEmptyString(address.region.name))
        addressArray.push(address.region.name);

      if (ObjectValidators.isValidObject(address.country) && !ObjectValidators.isEmptyString(address.country.name))
        addressArray.push(address.country.name);

      if (!ObjectValidators.isEmptyString(address.zipCode))
        addressArray.push(address.zipCode);

      computedAddress = addressArray.join(', ');
    }
    return computedAddress;
  }

  public static tryAppendStringSeparatedByComma(root:string, data:string){
    if(isValidString(data)){
      if(isValidString(root)) return root + ", " + data;
      return data;
    }
    return root;
  }
}
