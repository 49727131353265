import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute } from '@angular/router';
import { InvoiceRequestType } from '../invoice/invoice.component';
import { BaseWebComponentWithBreadcrumb } from '../../../core/shared/base/base.component';
import { ResetSection } from '../../../core/app-store/events/base.events';
import { InvoiceListOverviewOrganizationSandbox } from './invoice-list-overview-organization.sandbox';
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";

@Component({
  selector: 'app-invoice-list-overview-organization',
  templateUrl: './invoice-list-overview-organization.component.html',
  styleUrls: ['./invoice-list-overview-organization.component.scss']
})
export class InvoiceListOverviewOrganizationComponent extends BaseWebComponentWithBreadcrumb implements OnInit {

  public organizationId: string = null;
  public invoiceRequestType: InvoiceRequestType = InvoiceRequestType.Organization;
  public dataLoaded: boolean = false;
  public canExportForSaga:boolean=false;

  constructor(public activatedRoute: ActivatedRoute,
              private rulesProvider:AppUserBusinessRuleProvider,
              public sandbox: InvoiceListOverviewOrganizationSandbox) {
    super(sandbox, ResetSection.Invoices);
    this.canExportForSaga = this.rulesProvider.rules.canExportForSaga;
  }

  ngOnInit() {
    this.setBreadcrumbAP.execute([{text: 'Acasa', url: '/dashboard'}, {text: 'Facturi'}]);

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.organizationId !== undefined) {
        this.organizationId = params.organizationId;
        this.dataLoaded = true;
      }
    });
  }
}
