import {BaseActionProxy} from '../../shared/base/base.action-proxy';
import {IWebComponent} from '../../shared/base/base.component';
import {Store} from '@ngrx/store';
import * as store from '../../app-store';
import {Observable} from 'rxjs';
import {DoGetVersion} from '../../app-store';

export class GetVersionActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }

    execute(): any {
        this.appState.dispatch(new DoGetVersion());
    }
}
