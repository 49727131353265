import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { UploadInput } from '../../../core/upload/components/upload-notification/upload-notification.component';
import { UploadInputEvent } from '../../../core/app-store/index';

@Injectable()
export class AddEditCarInServiceWizardSandbox extends BaseSandbox {

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
    }

    public initialize(): any {
        this.clearSandboxState();
    }

    dispatchUploadInputEvent(uploadInput: UploadInput) {
        this.appState$.dispatch(new UploadInputEvent(uploadInput));
    }
}
