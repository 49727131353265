import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseFormModelComponent} from "../../../core/shared/base/base.component";
import {Subject} from "rxjs";
import {Store} from "@ngrx/store";
import * as store from "../../../core/app-store";
import {BsModalRef} from "ngx-bootstrap";
import {AddUserAccountActionProxy} from "./add-user-account.action-proxy";
import {AddUserAccountDI} from "./add-user-account.di";
import {AddUserAccountSandbox} from "./add-user-account.sandbox";
import {Mechanic_Dto} from "../../../core/app-dto/mechanic.dto";

@Component({
  selector: 'app-add-user-account',
  templateUrl: './add-user-account.component.html',
  styleUrls: ['./add-user-account.component.scss']
})
export class AddUserAccountComponent extends BaseFormModelComponent<Mechanic_Dto> implements OnInit, OnDestroy {
  public saveAP: AddUserAccountActionProxy;
  public onClose: Subject<boolean>;

  public di: AddUserAccountDI;

  constructor(public appState: Store<store.State>,
              public sandbox: AddUserAccountSandbox,
              private activeModal: BsModalRef) {
    super(sandbox, null);
    this.saveAP = new AddUserAccountActionProxy(this, sandbox.appState);
  }

  public diInitialize(data: Mechanic_Dto) {
    this.di = new AddUserAccountDI(data);
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.onClose = new Subject();

  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.formSubmitted = true;
    if (this.saveAP.canExecute()) {
      this.saveAP.execute();
      this.onClose.next(true);
      this.activeModal.hide();
    }
  }
}
