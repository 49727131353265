import {BaseActionProxy} from "../../base/base.action-proxy";
import {IWebComponent} from "../../base/base.component";
import {Store} from "@ngrx/store";
import * as store from "../../../app-store";
import {Do_GetActive_CashRegisters_Event} from "../../../app-store/events/cash-register.events";
import {Observable} from "rxjs";

export class GetActiveCashRegistersActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(): any {
        this.appState.dispatch(new Do_GetActive_CashRegisters_Event());
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}
