import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { SearchCarWorkEvent } from '../../../core/app-store/events/car-works.events';
import { CarWork_Dto } from '../../../core/app-dto/car-work.dto';

export class SearchCarWorksActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(search: string,model:string, make:string, year:number, loading: (items: Array<CarWork_Dto>) => void): any {
        this.appState.dispatch(new SearchCarWorkEvent(search,model, make, year, (items: Array<CarWork_Dto>) => {
            loading(items);
        }));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

