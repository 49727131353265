import { ObjectValidators } from '../shared/object.validators';
import {Base_Dto, BaseCRUDRules, BusinessRule, IdName_Dto} from './misc.dto';
import * as moment from 'moment';

export class CarRules extends BaseCRUDRules {
  public canActivate: BusinessRule;
  public canDeactivate: BusinessRule;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.canActivate = new BusinessRule(data.canActivate);
      this.canDeactivate = new BusinessRule(data.canDeactivate);

    } else {
      this.canActivate = new BusinessRule();
      this.canDeactivate = new BusinessRule();
    }
  }
}

export class Car_Dto extends Base_Dto {
  public registrationNumber: string;
  public bodySeries: string;
  public make: string;
  public model: string;
  public year: number;
  public power: number;
  public fuel: IdName_Dto[];
  public cilindricalCapacity: number;
  public rules: CarRules;
  public details: string;
  public itpExpirationDate: Date;
  public insuranceExpirationDate: Date;
  public createdOn: Date;
  public updatedOn: Date;

  constructor(data?: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.registrationNumber = data.registrationNumber;
      this.bodySeries = data.bodySeries;
      this.make = data.make;
      this.model = data.model;
      this.year = data.year;
      this.power = data.power;
      this.fuel = data.fuel;
      this.cilindricalCapacity = data.cilindricalCapacity;
      this.rules = new CarRules(data.rules);
      this.details = data.details;
      if (ObjectValidators.isValidObject(data.itpExpirationDate))
        this.itpExpirationDate = moment(data.itpExpirationDate).toDate();
      if (ObjectValidators.isValidObject(data.insuranceExpirationDate))
        this.insuranceExpirationDate = moment(data.insuranceExpirationDate).toDate();
      if (ObjectValidators.isValidObject(data.createdOn))
        this.createdOn = moment.utc(data.createdOn).toDate();
      if (ObjectValidators.isValidObject(data.updatedOn))
        this.updatedOn = moment.utc(data.updatedOn).toDate();
    }
  }
}
