import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {AddPaymentMethodDi} from "./add-payment-method.di";
import {AddPaymentMethodSandbox} from "./add-payment-method.sandbox";
import {BaseFormComponent} from "../../../shared/base/base.component";
import {CreateAnonymousCardSetupSession} from "../../../stripe-integration/dto/stripe.dto";
import {StripeService} from "../../../stripe-integration/services/stripe.service";
import {ObjectValidators} from "../../../shared/object.validators";
import {ResetSection} from "../../../app-store/events/base.events";
import '@stripe/stripe-js';

@Component({
    selector: 'add-payment-method',
    templateUrl: './add-payment-method.component.html',
    styleUrls: ['./add-payment-method.component.scss']
})
export class AddPaymentMethodComponent extends BaseFormComponent implements OnInit, OnDestroy {
    private mode: string;
    public title: string = '';
    public btnCaption: string = '';

    public errorMessage: string = '';

    public di: AddPaymentMethodDi;

    constructor(public sandbox: AddPaymentMethodSandbox, private route: ActivatedRoute, public stripeService: StripeService) {
        super(sandbox, ResetSection.RequestSubscription);
    }

    public diInitialize(data: CreateAnonymousCardSetupSession) {
        this.di = new AddPaymentMethodDi(data);
        this.dataLoaded = true;
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.mode = ObjectValidators.isValidString(params['mode']) ? params['mode'] : 'add';
            const name = ObjectValidators.isValidString(params['name']) ? params['name'] : '';
            const email = ObjectValidators.isValidString(params['email']) ? params['email'] : '';
            const organization = ObjectValidators.isValidString(params['organization']) ? params['organization'] : '';

            this.diInitialize(new CreateAnonymousCardSetupSession({
                name: name,
                email: email,
                organizationName: organization
            }));

            if (this.mode == 'add') {
                this.title = 'Adauga un card pentru a putea folosi in continuare ServiFy';
                this.btnCaption = 'Adauga';
            }
            else if (this.mode == 'confirm') {
                this.title = 'Actualizeaza-ti cardul pentru a putea folosi in continuare ServiFy';
                this.btnCaption = 'Actualizeaza';
            }
        });
    }

    public saveForm() {
        this.di.isValid();
    }

    ngOnDestroy() {
        this.destroy();
    }

    private canExecute(): boolean {
        this.markFormAsDirtyAndTouched();
        return this.di.isValid();
    }

    add() {
        this.formSubmitted = true;

        if (!this.canExecute())
            return;

        this.markFormAsNotDirty();

        const modelObject: CreateAnonymousCardSetupSession = <CreateAnonymousCardSetupSession> JSON.parse(JSON.stringify(this.di.getModel()));
        this.stripeService.createAmonymousCardSetupSession(modelObject).subscribe((result: string) => {
            const stripeInstance = Stripe(this.stripeService.currentConfig.publicKey,
                {
                    locale: 'ro'
                });
            stripeInstance.redirectToCheckout({
                sessionId: result,
            }).then(function (result) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
                this.notifierService.notify('error', result.error.message);
            });

            //@todo: catch error and show - no org
        });
    }
}
