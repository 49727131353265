import {BaseDI} from 'src/app/core/shared/base/base.di';
import {
    Offer_Dto,
    OfferAcceptedTrace_Dto,
    OfferDeclinedTrace_Dto,
    OfferRecipient_Dto,
    OffertedClientContact_Dto
} from '../../../core/app-dto/offer.dto';
import {isValidArrayAndHasElements, isValidString} from '../../../core/shared/helpers/common.helpers';

export class OffertedClientDi extends BaseDI<OffertedClientContact_Dto> {

    constructor(data: OffertedClientContact_Dto) {
        super(new OffertedClientContact_Dto(data));
    }


    getModel(): OffertedClientContact_Dto {
        return this.model;
    }
}
