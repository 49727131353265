import { BaseDI } from 'src/app/core/shared/base/base.di';
import { StringHelpers } from 'src/app/core/shared/string.helpers';
import { ObjectValidators } from "../../../core/shared/object.validators";
import { Invoice_Dto, InvoiceStatus } from 'src/app/core/app-dto/invoice.dto';
import { isValidObject } from 'src/app/core/shared/helpers/common.helpers';

export class InvoiceOverdueListDI extends BaseDI<Invoice_Dto> {
    public status: string = "";
    public new: boolean = false;
    public paid: boolean = false;
    public overdue: boolean = false;

    constructor(data: Invoice_Dto) {
        super(new Invoice_Dto(data));
        if (isValidObject(this.model.status))
            this.status = InvoiceStatus[this.model.status];
        switch (this.model.status) {
            case InvoiceStatus.New: {
                this.new = true;
                break;
            }
            case InvoiceStatus.Paid: {
                this.paid = true;
                break;
            }
            case InvoiceStatus.Overdue: {
                this.overdue = true;
                break;
            }
        }
    }

    getModel(): Invoice_Dto {
        return this.model;
    }
}
