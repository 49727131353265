import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { CarPart_Dto } from '../../../core/app-dto/car-part.dto';
import {isValidArrayAndHasElements} from "../../../core/shared/helpers/common.helpers";

export class CarServiceDetailsCarPartsDi extends BaseFormDI<Array<CarPart_Dto>> {
  public totalValueWithVat : number = 0;
    constructor(data: Array<CarPart_Dto> = new Array<CarPart_Dto>(null)) {
        super(data);
        if(isValidArrayAndHasElements(data))
          data.forEach(x=> this.totalValueWithVat+=x.sellingPriceWithVat * x.quantity);
    }
    initForm() {
    }

    getModel(): Array<CarPart_Dto> {
        return this.model;
    }
}
