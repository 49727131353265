import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseFormModelComponent} from '../../../core/shared/base/base.component';
import {CarPart_Dto} from '../../../core/app-dto/car-part.dto';
import {RegisterCarPartActionProxy} from '../../../car-part/components/save-car-part/register-car-part.action-proxy';
import {EditCarPartActionProxy} from '../../../car-part/components/save-car-part/edit-car-part.action-proxy';
import {GetCarPartActionProxy} from '../../../car-part/components/car-part-details/get-car-part.action-proxy';
import {GetSuppliersActionProxy} from '../../../car-part/components/save-car-part/get-suppliers.action-proxy';
import {StaticDataSelectorInput} from '../../../core/shared/components/static-data-selector/static-data-selector.component';
import {Subject} from 'rxjs';
import {SaveCarPartDi} from '../../../car-part/components/save-car-part/save-car-part.di';
import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {SaveCarPartSandbox} from '../../../car-part/components/save-car-part/save-car-part.sandbox';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {AppUserBusinessRuleProvider} from '../../../core/core/business-rule-provider/app-user.business-rule.provider';
import {ActivatedRoute} from '@angular/router';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {SaveSupplierModalComponent} from '../../../supply/components/save-supplier-modal/save-supplier-modal.component';
import {Supplier_Dto} from '../../../core/app-dto/supplier.dto';
import {Offer_Dto, OfferDeclinedTrace_Dto} from '../../../core/app-dto/offer.dto';
import {AcceptOfferActionProxy} from '../../common/ap/accept-offer.action-proxy';
import {DeclineOfferActionProxy} from '../../common/ap/decline-offer.action-proxy';
import {ResponseToOfferDi} from './response-to-offer.di';
import {isValidObject} from '../../../core/shared/helpers/common.helpers';

@Component({
    selector: 'app-response-to-offer',
    templateUrl: './response-to-offer.component.html',
    styleUrls: ['./response-to-offer.component.scss']
})
export class ResponseToOfferComponent extends BaseFormModelComponent<ResponseToOfferInput> implements OnInit, OnDestroy {
    public acceptAP: AcceptOfferActionProxy;
    public declineAP: DeclineOfferActionProxy;
    public title: string;
    public onClose: Subject<boolean>;
    public di: ResponseToOfferDi;
    public input: ResponseToOfferInput;

    constructor(public appState: Store<store.State>,
                private activeModal: BsModalRef,
                private modalService: BsModalService,
                public activatedRoute: ActivatedRoute) {
        super(null, null);
        this.acceptAP = new AcceptOfferActionProxy(this, appState);
        this.declineAP = new DeclineOfferActionProxy(this, appState);
        this.di = new ResponseToOfferDi();
    }

    public diInitialize(data: ResponseToOfferInput) {
        if (isValidObject(data) === false) {
            return;
        }
        this.input = data;
        this.title = data.isAccepting ? 'Accepta oferta' : 'Refuza oferta';
        const offerResponse = data.offer.isIndividual ? new OfferDeclinedTrace_Dto(data.offer.client) : null;
        this.di = new ResponseToOfferDi(offerResponse, data.offer.isIndividual);
        this.dataLoaded = true;

    }

    ngOnInit() {
        this.onClose = new Subject();
    }

    ngOnDestroy() {
        this.destroy();
    }

    public decline() {
        this.onClose.next(false);
        this.activeModal.hide();
    }

    public accept() {
        this.formSubmitted = true;
        if (this.di.isValid() == false && this.input.offer.isIndividual==false) {
            return;
        }
        const code = document.location.pathname.split('/')[3];
        if (this.input.isAccepting) {
            if (this.acceptAP.canExecute()) {
                this.acceptAP.execute(this.input.offer.id, code, this.di.getModel());
                this.onClose.next(true);
                this.activeModal.hide();
            }
        } else {
            if (this.declineAP.canExecute()) {
                this.declineAP.execute(this.input.offer.id, code, this.di.getModel());
                this.onClose.next(true);
                this.activeModal.hide();
            }
        }
    }


}

export class ResponseToOfferInput {
    constructor(public offer: Offer_Dto, public isAccepting: boolean) {
    }
}
