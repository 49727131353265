import {Action} from '@ngrx/store';
import {
    Do_GenericErrorEvent,
    Do_GenericSuccessEvent,
    Do_ResetStateForSection,
    EventWithScreenLoading,
    EventWithStopingScreenLoader,
    ResetSection,
    SeamlessEvent
} from '../events/base.events';
import {Supplier_Dto} from '../../app-dto/supplier.dto';
import {
    ActivateSupplierEvent,
    DeactivateSupplierEvent,
    DeleteSupplierEvent,
    GetSupplierEvent,
    GetSuppliersEvent,
    GetSuppliersPagedResultEvent,
    RegisterSupplierEvent,
    UpdateSupplierEvent
} from '../events/supplier.events';
import {PagedResult} from '../../shared/datatable/datatable.helpers';
import * as moment from 'moment';

export interface SupplierState {
    lastRefreshTime: number;
    currentSupplier: Supplier_Dto;
    suppliers: Supplier_Dto[];
    SupplierPagedResult: PagedResult<Supplier_Dto>;
}

export const INITIAL_STATE: SupplierState = Object.assign({}, {
    lastRefreshTime: 0,
    currentSupplier: null,
    suppliers: [],
    SupplierPagedResult: new PagedResult<Supplier_Dto>([])
});


export function reducer(state = INITIAL_STATE, action: Action): SupplierState {
    if (!action) { return state; }
    let actionType = action.type;

    actionType = actionType.replace(EventWithScreenLoading.identifier, '');
    actionType = actionType.replace(SeamlessEvent.identifier, '');
    actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
    switch (actionType) {

        // Get Supplier

        case Do_ResetStateForSection.identifier + ResetSection.Suppliers: {
            return Object.assign({}, state, { supplier: null });
        }

        case Do_GenericSuccessEvent.identifier + GetSupplierEvent.identifier: {
            const data = (action as Do_GenericSuccessEvent<Supplier_Dto>).data;
            state.currentSupplier = data;
            return Object.assign({}, state);
        }

        case Do_GenericErrorEvent.identifier + GetSupplierEvent.identifier: {
            state.currentSupplier = null;
            return Object.assign({}, state);
        }

        // Get Suppliers

        case Do_ResetStateForSection.identifier + ResetSection.Suppliers: {
            return Object.assign({}, state, {suppliers: []});
        }

        case Do_GenericSuccessEvent.identifier + GetSuppliersEvent.identifier: {
            const data = (action as Do_GenericSuccessEvent<Supplier_Dto[]>).data;
            state.suppliers = data;
            return Object.assign({}, state);
        }

        case Do_GenericErrorEvent.identifier + GetSuppliersEvent.identifier: {
            state.suppliers = [];
            return Object.assign({}, state);
        }

        // Get Suppliers

        case Do_ResetStateForSection.identifier + ResetSection.Suppliers: {
            return Object.assign({}, state, {SuppliersPagedResult: new PagedResult<Supplier_Dto>([])});
        }

        case Do_GenericSuccessEvent.identifier + GetSuppliersPagedResultEvent.identifier: {
            const data = (action as Do_GenericSuccessEvent<PagedResult<Supplier_Dto>>).data;
            state.SupplierPagedResult = new PagedResult<Supplier_Dto>(data);
            return Object.assign({}, state);
        }

        case Do_GenericErrorEvent.identifier + GetSuppliersPagedResultEvent.identifier: {
            state.SupplierPagedResult = new PagedResult<Supplier_Dto>([]);
            return Object.assign({}, state);
        }

        case Do_GenericSuccessEvent.identifier + RegisterSupplierEvent.identifier: {
            return Object.assign({}, state, {lastRefreshTime: moment().unix(), currentSupplier: null});
        }

        case Do_GenericSuccessEvent.identifier + UpdateSupplierEvent.identifier: {
            const data = new Supplier_Dto((action as Do_GenericSuccessEvent<Supplier_Dto>).data);
            return Object.assign({}, state, {lastRefreshTime: moment().unix(), currentSupplier: data});
        }

        case Do_GenericSuccessEvent.identifier + DeactivateSupplierEvent.identifier:
        case Do_GenericSuccessEvent.identifier + ActivateSupplierEvent.identifier: {
            const data = new Supplier_Dto((action as Do_GenericSuccessEvent<Supplier_Dto>).data);
            return Object.assign({}, state, {lastRefreshTime: moment().unix(), currentSupplier: data});
        }

        case Do_GenericSuccessEvent.identifier + DeleteSupplierEvent.identifier: {
            return Object.assign({}, state, { lastRefreshTime: moment().unix(), currentSupplier: null });
        }

        default: {
            return state;
        }
    }
}

export const getTimestampCallback = (state: SupplierState) => state.lastRefreshTime;
export const getSupplierCallback = (state: SupplierState) => state.currentSupplier;
export const getSuppliersPagedResultCallback = (state: SupplierState) => state.suppliers;
export const getSupplierSPagedResultCallbackNew = (state: SupplierState) => state.SupplierPagedResult;
