import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseFormModelComponent, BaseWebComponent} from '../../../core/shared/base/base.component';
import {Offer_Dto, OffertedClientContact_Dto, SendOfferPayload_Dto} from '../../../core/app-dto/offer.dto';
import {UpdateOfferCarDataActionProxy} from '../../common/ap/update-offer-car-data.action-proxy';
import {Subject} from 'rxjs';
import {CarFormComponent} from '../../../car/components/car-form/car-form.component';
import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {BsModalRef} from 'ngx-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {ResetSection} from '../../../core/app-store/events/base.events';
import {EditCarServiceCarDi} from '../../../car-service/components/edit-car-service-car/edit-car-service-car.di';
import {Car_Dto} from '../../../core/app-dto/car.dto';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {SendOfferActionProxy} from '../../common/ap/send-offer.action-proxy';
import {OffertedClientDi} from './offerted-client.di';
import {OrganizationClient_Dto} from '../../../core/app-dto/organization-client.dto';
import {isValidArrayAndHasElements, isValidObject} from '../../../core/shared/helpers/common.helpers';
import {OrganizationClientsListComponent} from '../../../organization-client/components/organization-clients-list/organization-clients-list.component';
import {ReceiverDi} from '../offer-details-list-senders/receiver.di';

@Component({
    selector: 'app-send-offer-to-clients',
    templateUrl: './send-offer-to-clients.component.html',
    styleUrls: ['./send-offer-to-clients.component.scss']
})
export class SendOfferToClientsComponent extends BaseWebComponent implements OnInit, OnDestroy {
    public sendOfferAP: SendOfferActionProxy;
    public onClose: Subject<boolean>;
    public offerValue: Offer_Dto;
    public selectedClients: Array<OffertedClientDi> = [];
    public displaySelectedItems: boolean = false;
    public forEmail: boolean = false;
    public forSMS: boolean = false;

    @Input('offer') set offer(offer: Offer_Dto) {
        if(isValidObject(offer)) {
            this.offerValue = offer;
            this.dataLoaded = true;
        }
    }

    @ViewChild(OrganizationClientsListComponent, {static: false})
    private clientsListComponent: OrganizationClientsListComponent;

    constructor(public appState: Store<store.State>,
                private activeModal: BsModalRef,
                public activatedRoute: ActivatedRoute) {
        super(null, ResetSection.OrganizationClients);
        this.sendOfferAP = new SendOfferActionProxy(this, appState);
    }


    ngOnInit() {
        this.onClose = new Subject();
    }

    ngOnDestroy() {
        this.destroy();
    }

    public decline() {
        this.onClose.next(false);
        this.activeModal.hide();
    }

    public accept() {
        const payload = new SendOfferPayload_Dto();
        payload.sendToEmail = this.forEmail;
        payload.sendToSMS = this.forSMS;
        payload.contacts = this.selectedClients.map(r => r.getModel());
        this.sendOfferAP.execute(this.offerValue, payload);
        this.onClose.next(true);
        this.activeModal.hide();
    }
    getSelectedOffertedClients(){
        return this.selectedClients.map(r=>r.getModel());
    }
    itemSelected(data: OrganizationClient_Dto) {
        if (isValidObject(data)) {
            const index = this.selectedClients.indexOf(
                this.selectedClients.find(f => f.model.email === data.email && f.model.phoneNumber === data.phoneNumber && f.model.name === data.name)
            );
            if (index > -1) {
                return;
            }
            this.selectedClients.push(new OffertedClientDi(new OffertedClientContact_Dto({
                name: data.name,
                email: data.email,
                phoneNumber: data.phoneNumber
            })));
        }
    }

    itemDeselected(data: OrganizationClient_Dto) {
        if (isValidArrayAndHasElements(this.selectedClients) && isValidObject(data)) {
            const index = this.selectedClients.indexOf(
                this.selectedClients.find(f => f.model.email === data.email && f.model.phoneNumber === data.phoneNumber && f.model.name === data.name)
            );
            if (index > -1) {
                this.selectedClients.splice(index, 1);
            }
        }
    }

    toggleDisplaySelectedItems() {
        this.displaySelectedItems = !this.displaySelectedItems;
    }
}
