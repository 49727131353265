import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  NgZone,
  ViewChildren,
  QueryList,
  Output,
  EventEmitter
} from '@angular/core';
import { GetFilesActionProxy } from './get-files.action-proxy';
import { BaseListWebComponent, BaseWebComponent } from 'src/app/core/shared/base/base.component';
import { FilesSandbox } from './files.sandbox';
import {FileUploadTarget, FileAttachment, FileToUpload, Address} from 'src/app/core/app-dto/core.dto';
import { FileDI } from '../di/file.di';
import { DeleteFilesActionProxy } from './delete-file.action-proxy';
import { FileWrapper } from 'src/app/core/upload/components/upload-notification/upload-notification.di';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { UploadInput } from 'src/app/core/upload/components/upload-notification/upload-notification.component';
import {isValidFile, isValidString} from 'src/app/core/shared/helpers/common.helpers';
import { NotifierService } from 'angular-notifier';
import { GenerateDevizActionProxy } from './generate-deviz.action-proxy';
import { GenerateGDPRActionProxy } from './generate-gdpr.action-proxy';
import { GenerateSHActionProxy } from './generate-sh.action-proxy';
import { GeneratePVActionProxy } from './generate-pv.action-proxy';
import { GenerateICActionProxy } from './generate-ic.action-proxy';
import {ConfirmationDialogService} from "../../../core/core/dialog/confirmation-dialog.service";
import { ActiveDataTableParameters } from '../../../core/shared/datatable/datatable.helpers';
import { ConfigService } from '../../../core/core/app-config.service';
import { DataTableDirective } from 'angular-datatables';
import {ActionsSubject} from "@ngrx/store";
import {Subscription} from "rxjs";
import {
  Do_GenericSuccessEvent,
  EventWithScreenLoading,
  EventWithStopingScreenLoader
} from "../../../core/app-store/events/base.events";
import {GenerateDevizEvent} from "../../../core/app-store/events/documents-events";
import {EditCarServiceEntryFinalInspectionEvent} from "../../../core/app-store/events/car-service.events";
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
  providers : [FilesSandbox]
})
export class FilesComponent extends BaseListWebComponent implements OnInit, OnDestroy {
  public getFilesAP: GetFilesActionProxy;
  public deleteFilesAP: DeleteFilesActionProxy;
  public generateDevizAP: GenerateDevizActionProxy;
  public generateGDPRAP: GenerateGDPRActionProxy;
  public generateSHAP: GenerateSHActionProxy;
  public generatePVAP: GeneratePVActionProxy;
  public generateICAP: GenerateICActionProxy;

  private actionSubscription = new Subscription();

  @Input() type: Array<FileUploadTarget> = null;
  @Input() primaryType: FileUploadTarget = null;
  @Input() relatedEntityId: string = '';
  @Input() isCarServiceEntryEstimate: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Output() finalInspectionCalled= new EventEmitter();

  public fileUploadTarget = FileUploadTarget;
  public files: Array<FileDI> = [];
  public columns: any = [];

  @ViewChildren(DataTableDirective) set datatableValue(datatable: QueryList<DataTableDirective>) {
    if (datatable != null && datatable.first != null) {
      datatable.first.dtInstance.then((dtInstance: DataTables.Api) => {
        this.dtApiInstance = dtInstance;
      });
    }
  }

  constructor(private configService: ConfigService,
              public sandbox: FilesSandbox,
              public confirmationDialogService: ConfirmationDialogService,
              private actionsSubj: ActionsSubject,
              public rulesProvider:AppUserBusinessRuleProvider,
              private notifierService: NotifierService) {
    super(sandbox);
    this.getFilesAP = new GetFilesActionProxy(this, sandbox.appState);
    this.generateDevizAP = new GenerateDevizActionProxy(this, sandbox.appState);
    this.generateGDPRAP = new GenerateGDPRActionProxy(this, sandbox.appState);
    this.generateSHAP = new GenerateSHActionProxy(this, sandbox.appState);
    this.generatePVAP = new GeneratePVActionProxy(this, sandbox.appState);
    this.generateICAP = new GenerateICActionProxy(this, sandbox.appState);
    this.deleteFilesAP = new DeleteFilesActionProxy(this, sandbox.appState);
    this.actionSubscription = actionsSubj.subscribe(data => {
      if (data.type == Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + EditCarServiceEntryFinalInspectionEvent.identifier) {
        if (isValidString(this.relatedEntityId))
          this.getFilesAP.execute(this.type, this.relatedEntityId);
      }
    });
  }

  ngOnInit() {
    this.getFilesAP.execute(this.type, this.relatedEntityId);

    this.initialize(this.relatedEntityId, this.type,(data: Array<FileAttachment>) => {
      this.files = data.map(r => new FileDI(r));
      this.dataLoaded = true;
    });

    this.columns = [
      { data: 'name', name: 'Nume', width: '40%' },
      { data: 'type', name: 'Tip', width: '15%' },
      { data: 'size', name: 'Marime', width: '15%'},
      { data: 'date', name: 'Data generarii', width: '20%' },
      { data: 'action', width: '10%'}
    ];

    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      pageLength: this.configService.getDefaultPageSize(),
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      columns: this.columns,
      order: [[3, 'asc']],
      ajax: () => {
      },
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
  }

  ngOnDestroy() {
    this.destroy();
  }

  generateGDPR() {
    this.generateGDPRAP.execute(this.relatedEntityId);
  }

  generateDeviz() {
    this.generateDevizAP.execute(this.relatedEntityId);
  }

  generateSH() {
    this.generateSHAP.execute(this.relatedEntityId);
  }

  generatePV() {
    this.generatePVAP.execute(this.relatedEntityId);
  }

  generateIC() {
    this.generateICAP.execute(this.relatedEntityId);
  }

  deleteFile(id: string) {
    this.confirmationDialogService.confirm(
      "Stergere fisier",
      "Urmeaza sa stergeti fisierul. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        this.deleteFilesAP.execute(id, this.type, this.relatedEntityId);
      }
    });

  }

  finalInspection() {
    if(this.finalInspectionCalled)this.finalInspectionCalled.emit();
  }

  onFileUpload(data: FileList) {
    console.log("onImageChange: " + NgZone.name);

    let validFiles = true;
    let arrayData = Array.from(data);
    arrayData.forEach(r => {
      if (isValidFile(r) == false) {
        //@Todo: add notification error
        validFiles = false;
      }
    });
    if (validFiles) {
      let files = arrayData.map(r => {
        let result = new FileWrapper();
        result.file = r;
        return result;
      });
      var timestamp = new Date().getTime();
      var filesToUpload = new Array<FileToUpload>();
      for (var i = 0; i < files.length; i++) {
        if (ObjectValidators.isValidString(files[i].id) == false) {
          timestamp = timestamp + 1;
          filesToUpload.push(new FileToUpload(timestamp.toString(), files[i].file, this.primaryType, this.relatedEntityId));
        }
      }
      if (filesToUpload.length > 0) {

        this.sandbox.dispatchUploadInputEvent(new UploadInput(filesToUpload, this.primaryType))
      } else {
        
        this.notifierService.notify('error', 'Tipul fisierului nu este acceptat!');
      }
    }
  }
}
