import {BaseActionProxy} from "../../../core/shared/base/base.action-proxy";
import {IWebComponent} from "../../../core/shared/base/base.component";
import {Store} from "@ngrx/store";
import * as store from "../../../core/app-store";
import {Offer_Dto} from "../../../core/app-dto/offer.dto";
import {UpdateOfferDataEvent} from "../../../core/app-store/events/offer.events";
import {Observable} from "rxjs";

export class UpdateOfferAttributesActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(data: Offer_Dto): any {
        this.appState.dispatch(new UpdateOfferDataEvent(data.id, data));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}
