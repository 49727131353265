import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { DeactivateSupplyEvent } from '../../../core/app-store/events/supply.events';

export class DeactivateSupplyActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent, appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(supplyId: string): any {
        if (this.canExecute()) {
            this.appState.dispatch(new DeactivateSupplyEvent(supplyId));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

