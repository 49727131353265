import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {InvoiceDetails_Dto, InvoiceMaxPermittedAmount_Dto, InvoiceType} from 'src/app/core/app-dto/invoice.dto';
import { InvoiceDI } from './invoice.di';
import {BaseWebComponentWithBreadcrumb} from 'src/app/core/shared/base/base.component';
import { GetInvoiceActionProxy } from './get-invoice.action-proxy';
import { InvoiceSandbox } from './invoice.sandbox';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { GetOrganizationInvoiceActionProxy } from './get-organization-invoice.action-proxy';
import { GetUnauthorizedInvoiceActionProxy } from './get-unauthorized-invoice.action-proxy';
import { ObjectValidators } from "../../../core/shared/object.validators";
import { MarkSubInvoiceAsNotPaidActionProxy } from "./mark-sub-invoice-as-not-paid.action-proxy";
import { MarkSubInvoiceAsPaidActionProxy } from "./mark-sub-invoice-as-paid.action-proxy";
import { MarkOrgInvoiceAsPaidActionProxy } from "./mark-org-invoice-as-paid.action-proxy";
import { MarkOrgInvoiceAsNotPaidActionProxy } from "./mark-org-invoice-as-not-paid.action-proxy";
import { RegenerateInvoiceActionProxy } from "./regenerate-invoice.action-proxy";
import { GetDownloadLinkForSubInvoiceActionProxy } from "./get-download-link-for-sub-invoice.action-proxy";
import { GetDownloadLinkForOrgInvoiceActionProxy } from "./get-download-link-for-org-invoice.action-proxy";
import { GetSubInvoiceForOrganizationActionProxy } from "./get-sub-invoice-for-organization.action-proxy";
import { BsModalService } from 'ngx-bootstrap';
import { InvoiceUpdateDeadlineComponent } from '../invoice-update-deadline/invoice-update-deadline.component';
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";
import {ReceiptComponent} from '../../../core/shared/components/receipt/receipt.component';
import {NotifierService} from 'angular-notifier';
import {InvoiceApiService} from '../../../core/api-integration/services/invoice-api.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent extends BaseWebComponentWithBreadcrumb implements OnInit, OnDestroy {

  public getInvoiceAP: GetInvoiceActionProxy;
  public getOrganizationInvoiceAP: GetOrganizationInvoiceActionProxy;
  public getOrganizationSubInvoiceAP: GetSubInvoiceForOrganizationActionProxy;
  public getUnauthorizedInvoiceAP: GetUnauthorizedInvoiceActionProxy;

  private inputData: InvoiceInput;
  public shouldDisplayBreadcrumb: boolean = false;
  public shouldDisplayOrganizationBreadcrumb: boolean = false;
  public shouldDisplayGoToAction: boolean = false;
  public shouldDisplayOrganizationSubscriptionInvoiceBreadcrumb: boolean = false;

  public di: InvoiceDI;//we need to overwrite di with specific type for production build

  @Input("input") set input(data: InvoiceInput) {
    this.inputData = data;
    if (ObjectValidators.isValidObject(data)) {
      switch (this.inputData.type) {
        case InvoiceRequestType.SuperAdmin: {
          this.shouldDisplayBreadcrumb = true;
          if (this.inputData.invoiceId != null)
            this.getInvoiceAP.execute(this.inputData.invoiceId);
          break;
        }
        case InvoiceRequestType.Organization: {
          this.shouldDisplayOrganizationBreadcrumb = true;
          this.shouldDisplayGoToAction = true;
          if (this.inputData.invoiceId != null && this.inputData.organizationId != null) {
            this.getOrganizationInvoiceAP.execute(this.inputData.organizationId, this.inputData.invoiceId);

          }
          break;
        }
        case InvoiceRequestType.SubscriptionOrganization:{
          this.shouldDisplayOrganizationSubscriptionInvoiceBreadcrumb = true;
          if (this.inputData.invoiceId != null && this.inputData.organizationId != null) {
            this.getOrganizationSubInvoiceAP.execute(this.inputData.organizationId, this.inputData.invoiceId);

          }
          break;
        }
        case InvoiceRequestType.Unauthorized: {
          if (this.inputData.invoiceId != null && this.inputData.code != null) {
            this.getUnauthorizedInvoiceAP.execute(this.inputData.invoiceId, this.inputData.code);

          }
          break;
        }
      }

    }
  }
  constructor(
    public sandbox: InvoiceSandbox,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    public rulesProvider: AppUserBusinessRuleProvider,
    public notifierService: NotifierService,
    private invoiceApiService: InvoiceApiService,
  ) {
    super(sandbox, ResetSection.Invoice);
    this.getInvoiceAP = new GetInvoiceActionProxy(this, sandbox.appState);
    this.getOrganizationInvoiceAP = new GetOrganizationInvoiceActionProxy(this, sandbox.appState);
    this.getOrganizationSubInvoiceAP = new GetSubInvoiceForOrganizationActionProxy(this, sandbox.appState);
    this.getUnauthorizedInvoiceAP = new GetUnauthorizedInvoiceActionProxy(this, sandbox.appState);
  }

  ngOnInit() {
    this.initialize((data: InvoiceDetails_Dto) => {
      this.di = new InvoiceDI(data);
      if(this.shouldDisplayBreadcrumb){
        if(this.di.model != null && this.di.model.invoice != null)
        this.setBreadcrumbAP.execute([{text: 'Acasa', url: '/dashboard'}, {text: 'Facturi', url : '/invoice/all'},
          {text : 'Factura ' + this.di.model.invoice.computedNumber}]);
      }else{
        if(this.shouldDisplayOrganizationBreadcrumb){
          if(this.di.model != null && this.di.model.invoice != null)
          this.setBreadcrumbAP.execute([{text: 'Acasa', url: '/dashboard'}, {text: 'Facturi', url : '/invoice/' + this.di.model.invoice.organizationId + '/all'},
            {text : 'Factura ' + this.di.model.invoice.computedNumber}]);
        }else{
          if(this.shouldDisplayOrganizationSubscriptionInvoiceBreadcrumb){
            if(this.di.model != null && this.di.model.invoice != null)
            this.setBreadcrumbAP.execute([{text: 'Acasa', url: '/dashboard'}, {text: 'Facturi', url : '/invoice/subscription/' + this.di.model.invoice.relatedEntityId + '/all'},
              {text : 'Factura ' + this.di.model.invoice.computedNumber}]);
          }
        }
      }
      this.dataLoaded = true;
    });
  }
  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.destroy();
  }

  markAsPaid() {
    if (this.inputData.type == InvoiceRequestType.Unauthorized || this.inputData.type == InvoiceRequestType.SubscriptionOrganization) return;
    if (this.di.model != null && this.di.model.invoice.type == InvoiceType.Subscription) {
      const ap = new MarkSubInvoiceAsPaidActionProxy(this, this.sandbox.appState);
      ap.execute(this.di.model.invoice.id);
    }
    else {
      const ap = new MarkOrgInvoiceAsPaidActionProxy(this, this.sandbox.appState);
      ap.execute(this.di.model.invoice.id, this.di.model.invoice.organizationId);
    }
  }
  markAsUnpaid() {
    if (this.inputData.type == InvoiceRequestType.Unauthorized || this.inputData.type == InvoiceRequestType.SubscriptionOrganization) return;
    if (this.di.model != null && this.di.model.invoice.type == InvoiceType.Subscription) {
      const ap = new MarkSubInvoiceAsNotPaidActionProxy(this, this.sandbox.appState);
      ap.execute(this.di.model.invoice.id);
    }
    else {
      const ap = new MarkOrgInvoiceAsNotPaidActionProxy(this, this.sandbox.appState);
      ap.execute(this.di.model.invoice.id, this.di.model.invoice.organizationId);
    }
  }
  regenerate() {
    if (this.inputData.type == InvoiceRequestType.Unauthorized || this.inputData.type == InvoiceRequestType.SubscriptionOrganization) return;
    const ap = new RegenerateInvoiceActionProxy(this, this.sandbox.appState);
    ap.execute(this.di.model.invoice.id, this.di.model.invoice.organizationId, (newId: string) => {

      this.router.navigate(['invoice/', this.di.model.invoice.organizationId, newId, 'details']);
    });
  }
  goToRelatedEntity() {
    if (this.inputData.type == InvoiceRequestType.Unauthorized || this.inputData.type == InvoiceRequestType.SubscriptionOrganization) return;
    if (this.di.model.invoice.type == InvoiceType.CarServiceEntry || this.di.model.invoice.type == InvoiceType.CarServiceEntryAdvancePayment) {
      this.router.navigate(['car-service/details', this.di.model.invoice.relatedEntityId]);
    }
  }

  download() {
    if (this.inputData.type == InvoiceRequestType.Unauthorized) return;
    if (this.di.model != null &&
        (this.di.model.invoice.type == InvoiceType.Subscription ||
            this.di.model.invoice.type == InvoiceType.SMS)
        && this.inputData.type == InvoiceRequestType.SuperAdmin) {
      const ap = new GetDownloadLinkForSubInvoiceActionProxy(this, this.sandbox.appState);
      ap.execute(this.di.model.invoice.id, (url) => {
        if (ObjectValidators.isValidString(url)) {
          window.open(url, "_self");
        }
      });
    }
    else {
      const ap = new GetDownloadLinkForOrgInvoiceActionProxy(this, this.sandbox.appState);
      ap.execute(this.di.model.invoice.id, this.di.model.invoice.organizationId, (url) => {
        if (ObjectValidators.isValidString(url)) {
          window.open(url, "_self");
        }
      });
    }
  }

  canDownload(){
    if(this.inputData.type == InvoiceRequestType.Unauthorized)return false;
    return true;
  }

  canViewActions(){
    if(this.canDownload()) return true;
    if(this.di.model.invoice.rules.canMarkAsPaid.value ||
      this.di.model.invoice.rules.canMarkAsNotPaid.value ||
      this.di.model.invoice.rules.canEditDeadline.value ||
      this.di.model.invoice.rules.canRegenerate.value ||
     this.shouldDisplayGoToAction) return true;
    return false;
  }

  editDeadline() {
    const modalRef = this.modalService.show(InvoiceUpdateDeadlineComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = this.di.model;
  }

  printReceipt() {
    if (this.di.getModel().invoice.id != null) {
      this.invoiceApiService.u_getMaxPermittedAmount(this.di.getModel().invoice.id)
          .subscribe((result: InvoiceMaxPermittedAmount_Dto) => {
        if (result.canPrintReceipt) {
          const modalRef = this.modalService.show(ReceiptComponent, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-xl'
          });
          (<ReceiptComponent> modalRef.content).setData(this.di.getModel().invoice);
          (<ReceiptComponent> modalRef.content).setValue(result.maxPermittedAmount);
        } else {
          this.notifierService.notify('error', result.message);
        }
      });
    }
  }
}

export enum InvoiceRequestType {
  SuperAdmin,
  Organization,
  Unauthorized,
  SubscriptionOrganization
}

export class InvoiceInput {
  public code: string;
  public invoiceId: string;
  public organizationId: string;
  public type: InvoiceRequestType;
}
