import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseFormModelComponent} from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import {Store} from '@ngrx/store';
import {RegisterCarPartActionProxy} from './register-car-part.action-proxy';
import {EditCarPartActionProxy} from './edit-car-part.action-proxy';
import {GetCarPartActionProxy} from '../car-part-details/get-car-part.action-proxy';
import {ActivatedRoute} from '@angular/router';
import {SaveCarPartSandbox} from './save-car-part.sandbox';
import {SaveCarPartDi} from './save-car-part.di';
import {CarPart_Dto} from '../../../core/app-dto/car-part.dto';
import {Subject} from 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {StaticDataSelectorInput} from '../../../core/shared/components/static-data-selector/static-data-selector.component';
import {GetSuppliersActionProxy} from './get-suppliers.action-proxy';
import {AddonTypes} from '../../../core/app-dto/organization.dto';
import {SaveSupplierModalComponent} from '../../../supply/components/save-supplier-modal/save-supplier-modal.component';
import {Supplier_Dto} from '../../../core/app-dto/supplier.dto';
import {AppUserBusinessRuleProvider} from '../../../core/core/business-rule-provider/app-user.business-rule.provider';
import {isValidObject, makeInputPositive} from '../../../core/shared/helpers/common.helpers';


@Component({
    selector: 'app-save-car-part',
    templateUrl: './save-car-part.component.html',
    styleUrls: ['./save-car-part.component.scss']
})
export class SaveCarPartComponent extends BaseFormModelComponent<CarPart_Dto> implements OnInit, OnDestroy {
    public registerCarPartAP: RegisterCarPartActionProxy;
    public editCarPartAP: EditCarPartActionProxy;
    public getCarPartAP: GetCarPartActionProxy;
    public getSupplierAP: GetSuppliersActionProxy;
    public title: string;
    public acquisitionPriceCurrenciesDataInput: StaticDataSelectorInput;
    public sellingPriceCurrenciesDataInput: StaticDataSelectorInput;
    public onClose: Subject<boolean>;
    public di: SaveCarPartDi;

    constructor(public appState: Store<store.State>,
                public sandbox: SaveCarPartSandbox,
                private activeModal: BsModalRef,
                private modalService: BsModalService,
                public rulesProvider: AppUserBusinessRuleProvider,
                public activatedRoute: ActivatedRoute) {
        super(sandbox, null);
        this.registerCarPartAP = new RegisterCarPartActionProxy(this, sandbox.appState);
        this.editCarPartAP = new EditCarPartActionProxy(this, sandbox.appState);
        this.getCarPartAP = new GetCarPartActionProxy(this, sandbox.appState);
        this.getSupplierAP = new GetSuppliersActionProxy(this, sandbox.appState);
        this.getSupplierAP.execute();
        this.di = new SaveCarPartDi();
    }

    public diInitialize(data: CarPart_Dto) {
        this.title = ObjectValidators.isValidObject(data) ? 'Editare piesa auto' : 'Adauga piesa auto';
        this.initializeInputsAndDi(data);
        this.sandbox.initialize();
        this.dataLoaded = true;
    }

    addSupplierPromise(name) {
        return new Promise((resolve) => {
            resolve({name: name, valid: true});
        });
    }

    ngOnInit() {
        this.onClose = new Subject();
    }

    ngOnDestroy() {
        this.destroy();
    }

    public decline() {
        this.onClose.next(false);
        this.activeModal.hide();
    }

    public accept() {
        this.formSubmitted = true;
        if (this.di.model.id) {
            if (this.editCarPartAP.canExecute()) {
                this.editCarPartAP.execute();
                this.onClose.next(true);
                this.activeModal.hide();
            }
        } else {
            if (this.registerCarPartAP.canExecute()) {
                this.registerCarPartAP.execute();
                this.onClose.next(true);
                this.activeModal.hide();
            }
        }
    }

    public canSetQuantity() {
        if (this.rulesProvider.rules == null) {
            return false;
        }
        return this.rulesProvider.rules.canManageSupplies == false;
    }

    addNewSupplier() {
        const modalRef = this.modalService.show(SaveSupplierModalComponent, {backdrop: 'static', keyboard: false, class: 'modal-xl'});
        modalRef.content.dataInput = {};
        modalRef.content.onClose.subscribe((data: Supplier_Dto) => {
            if (ObjectValidators.isValidObject(data) && data) {
                this.setSelectedSupplier(data);
                this.sandbox.items = [...this.sandbox.items, data];
                this.di.form.controls.supplier.setValue(data);
            }
        });
    }

    updateSupplier() {
        const modalRef = this.modalService.show(SaveSupplierModalComponent, {backdrop: 'static', keyboard: false, class: 'modal-xl'});
        modalRef.content.dataInput = this.di.form.controls.supplier.value;
        modalRef.content.onClose.subscribe((data: Supplier_Dto) => {
            if (ObjectValidators.isValidObject(data) && data) {
                this.setSelectedSupplier(data);
                this.sandbox.items = [...this.sandbox.items, data];
                this.di.form.controls.supplier.setValue(data);
            }
        });
    }

    setSelectedSupplier(supplier) {
        // this.computedRegComCifCnp = '';
        // this.computedAddress = '';
        // if (supplier) {
        //   this.computedAddress = StringHelpers.computeAddress(supplier.address);
        //   let delimiter = '';
        //   if (supplier.regCom) {
        //     this.computedRegComCifCnp = `${delimiter}${supplier.regCom}`;
        //     delimiter = ', ';
        //   }
        //   if (supplier.cifCnp) {
        //     this.computedRegComCifCnp += `${delimiter}${supplier.cifCnp}`;
        //   }
        // }
        // this.selectedSupplier = supplier;
    }

    carPartSelected(value: CarPart_Dto) {
        if (isValidObject(value)) {
            const newCarPart = new CarPart_Dto(value);
            if (this.di.model.id == null)
                newCarPart.id = null;
            this.initializeInputsAndDi(newCarPart);
        } else {
            this.initializeInputsAndDi(null);
        }
    }

    private initializeInputsAndDi(data: CarPart_Dto) {
        if(isValidObject(data) && data.id == "")  data.id = null;
        this.di = new SaveCarPartDi(data, this.rulesProvider.rules.canManageSupplies);
        this.acquisitionPriceCurrenciesDataInput = {
            form: this.di.form,
            type: 'currencies',
            formControlName: 'acquisitionPriceCurrency',
            isRequired: true,
            label: 'Moneda',
            isReadOnly: this.rulesProvider.rules.canManageSupplies
        };
        this.sellingPriceCurrenciesDataInput = {
            form: this.di.form,
            type: 'currencies',
            formControlName: 'sellingPriceCurrency',
            isRequired: true,
            label: 'Moneda',
            isReadOnly: false
        };
    }

    makeValuePositive(event: any) {
        makeInputPositive(event);
    }
}
