import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { ActivatedRoute } from '@angular/router';
import { Mechanic_Dto } from '../../../core/app-dto/mechanic.dto';
import { StaticDataSelectorInput } from '../../../core/shared/components/static-data-selector/static-data-selector.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AppUserBusinessRuleProvider } from "../../../core/core/business-rule-provider/app-user.business-rule.provider";
import { EditMechanicHistoryActionProxy } from './edit-mechanic-history.action-proxy';
import { EditMechanicHistorySandbox } from './edit-mechanic-history.sandbox';
import { EditMechanicHistoryDI } from './edit-mechanic-history.di';

@Component({
  selector: 'app-edit-mechanic-history',
  templateUrl: './edit-mechanic-history.component.html',
  styleUrls: ['./edit-mechanic-history.component.scss']
})
export class EditMechanicHistoryComponent extends BaseFormModelComponent<Mechanic_Dto> implements OnInit, OnDestroy {
  public editMechanicHistoryAP: EditMechanicHistoryActionProxy;
  public onClose: Subject<boolean>;
  public mechanicSpecializationTypesDataInput: StaticDataSelectorInput;
  public di: EditMechanicHistoryDI;

  constructor(
    public appState: Store<store.State>,
    public sandbox: EditMechanicHistorySandbox,
    public activeModal: BsModalRef,
    public activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    public rulesProvider: AppUserBusinessRuleProvider

  ) {
    super(sandbox, ResetSection.SaveMechanic);
    this.editMechanicHistoryAP = new EditMechanicHistoryActionProxy(this, sandbox.appState);
  }

  public diInitialize(data: Mechanic_Dto) {
    this.di = new EditMechanicHistoryDI(data);
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.onClose = new Subject();
    this.initialize();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.formSubmitted = true;
    if (this.editMechanicHistoryAP.canExecute()) {
      this.editMechanicHistoryAP.execute();
      this.onClose.next(true);
      this.activeModal.hide();
    }
  }
}
