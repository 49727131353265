import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { IdName_Dto } from 'src/app/core/app-dto/misc.dto';
import { OrganizationClient_Dto } from 'src/app/core/app-dto/organization-client.dto';

@Injectable()
export class OrganizationClientSearchSandbox extends BaseSandbox {
    public getOrganizationClientSearchList$ = this.appState$.pipe(select(store.getOrganizationClientSearchList));

    public originalOrganizationClientSearchList: Array<OrganizationClient_Dto> = [];

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    clearSandboxState() {
        this.originalOrganizationClientSearchList = [];
    }

    initialize(searchList: (data: Array<IdName_Dto>) => {}) {
        this.clearSandboxState();

        this.subscriptions.push(this.getOrganizationClientSearchList$.subscribe((data: Array<OrganizationClient_Dto>) => {
            if (ObjectValidators.isValidArray(data)) {
                this.originalOrganizationClientSearchList = data;
                searchList(data.map(r => new IdName_Dto({
                    id: r.id,
                    name: r.name
                })));
            }
        }));
    }
}
