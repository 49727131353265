import {Base_Dto, BaseCRUDRules, BusinessRule} from './misc.dto';
import {ObjectValidators} from '../shared/object.validators';
import {Address, UserTrace_Dto} from './core.dto';
import * as moment from 'moment';

export class InvoiceRules extends BaseCRUDRules {
  public canMarkAsPaid: BusinessRule;
  public canMarkAsNotPaid: BusinessRule;
  public canRegenerate: BusinessRule;
  public canPay: BusinessRule;
  public canEditDeadline: BusinessRule;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.canMarkAsPaid = new BusinessRule(data.canMarkAsPaid);
      this.canMarkAsNotPaid = new BusinessRule(data.canMarkAsNotPaid);
      this.canRegenerate = new BusinessRule(data.canRegenerate);
      this.canPay = new BusinessRule(data.canPay);
      this.canEditDeadline = new BusinessRule(data.canEditDeadline);
    } else {
      this.canMarkAsPaid = new BusinessRule();
      this.canMarkAsNotPaid = new BusinessRule();
      this.canRegenerate = new BusinessRule();
      this.canPay = new BusinessRule();
      this.canEditDeadline = new BusinessRule();
    }
  }
}

export class InvoiceOrganization_Dto {
  public name: string;
  public cui: string;
  public registrationNumber: string;
  public address: Address;
  public IBAN: string;
  public bank: string;
  public email: string;
  public logoUrl: string;
  public refType: InvoiceProviderRefType;
  public refId: string;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      this.name = data.name;
      this.cui = data.cui;
      this.registrationNumber = data.registrationNumber;
      this.IBAN = data.IBAN;
      this.bank = data.bank;
      this.email = data.email;
      this.logoUrl = data.logoUrl;
      this.refId = data.refId;
      this.refType = data.refType;

      if (ObjectValidators.isValidObject(data.address))
        this.address = new Address(data.address);
    } else {
      this.address = new Address(null);
    }
  }
}

export class Invoice_Dto extends Base_Dto {
  public number: number;
  public computedNumber: string;
  public invoiceSeries: string;
  public totalValue: number;
  public totalValueWithoutVAT: number;
  public totalVAT: number;
  public sentOn: Date;
  public provider: InvoiceOrganization_Dto;
  public client: InvoiceOrganization_Dto;
  public creator: UserTrace_Dto;
  public deadline: Date;
  public formattedDeadline: string;
  public status: InvoiceStatus;
  public currency: string;
  public rules: InvoiceRules;
  public type: InvoiceType;
  public relatedEntityId: string;
  public isOutdated: boolean;
  public organizationId: string;
  public proformaInvoiceId: string;
  public isPaymentInAdvanceInvoice: boolean;
  public isProforma: boolean;
  public paymentInAdvanceInvoiceId: boolean;
  public isExportedForSaga: boolean;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.number = data.number;
      this.proformaInvoiceId = data.proformaInvoiceId;
      this.isExportedForSaga = data.isExportedForSaga || false;
      this.computedNumber = data.computedNumber;
      this.invoiceSeries = data.invoiceSeries;
      this.totalValue = data.totalValue;
      this.totalValueWithoutVAT = data.totalValueWithoutVAT;
      this.totalVAT = data.totalVAT;
      this.status = data.status;
      this.currency = data.currency;
      this.relatedEntityId = data.relatedEntityId;
      this.isOutdated = data.isOutdated;
      this.type = data.type;
      this.organizationId = data.organizationId;
      this.isPaymentInAdvanceInvoice = data.isPaymentInAdvanceInvoice;
      this.isProforma = data.isProforma;
      this.paymentInAdvanceInvoiceId = data.paymentInAdvanceInvoiceId;

      if (ObjectValidators.isValidObject(data.sentOn))
        this.sentOn = moment.utc(data.sentOn).toDate();

      if (ObjectValidators.isValidObject(data.deadline))
        this.deadline = moment.utc(data.deadline).toDate();

      if (ObjectValidators.isValidObject(data.provider))
        this.provider = new InvoiceOrganization_Dto(data.provider);

      if (ObjectValidators.isValidObject(data.client))
        this.client = new InvoiceOrganization_Dto(data.client);

      if (ObjectValidators.isValidObject(data.creator))
        this.creator = new UserTrace_Dto(data.creator);

      if (ObjectValidators.isValidObject(data.rules))
        this.rules = new InvoiceRules(data.rules);
      else
        this.rules = new InvoiceRules(null);

    } else {
      this.rules = new InvoiceRules(null);
    }
  }
}

export class InvoiceItem_Dto extends Base_Dto {
  public description: string;
  public unitOfMeasure: string;
  public quantity: number;
  public priceForUnitOfMeasure: number;
  public valueWithoutVAT: number;
  public valueForVAT: number;
  public startingDate: Date;
  public endingDate: Date;
  public refType: InvoiceItemRefType;
  public refId: string;
  public invoiceId: string;
  public isPaymentInAdvanceItem:boolean;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.description = data.description;
      this.unitOfMeasure = data.unitOfMeasure;
      this.quantity = data.quantity;
      this.priceForUnitOfMeasure = data.priceForUnitOfMeasure;
      this.valueWithoutVAT = data.valueWithoutVAT;
      this.valueForVAT = data.valueForVAT;
      this.refType = data.refType;
      this.invoiceId = data.invoiceId;
      this.isPaymentInAdvanceItem = data.isPaymentInAdvanceItem;

      if (ObjectValidators.isValidObject(data.startingDate))
        this.startingDate = moment.utc(data.startingDate).toDate();

      if (ObjectValidators.isValidObject(data.endingDate))
        this.endingDate = moment.utc(data.endingDate).toDate();
    } else {
    }

  }
}

export class InvoiceDetails_Dto {
  public invoice: Invoice_Dto;
  public items: Array<InvoiceItem_Dto>;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      if (ObjectValidators.isValidObject(data.invoice)) {
        this.invoice = new Invoice_Dto(data.invoice);
      }

      if (ObjectValidators.isValidArray(data.items)) {
        this.items = data.items.map(r => new InvoiceItem_Dto(r));
      } else {
        this.items = [];
      }
    } else {
    }

  }
}

export class SagaExport_Dto{
  public items: Array<string>;
  public from: Date;
  public to: Date;
  public isInIntervalMode: boolean;
}

export class InvoiceMaxPermittedAmount_Dto {
  public canPrintReceipt: boolean;
  public maxPermittedAmount: number;
  public message: string;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      this.maxPermittedAmount = data.maxPermittedAmount;
      this.canPrintReceipt = data.canPrintReceipt;
      this.message = data.message;
    }
  }
}

export enum InvoiceStatus {
  New,
  Paid,
  Overdue
}

export enum InvoiceProviderRefType {
  Platform,
  Organization,
  Client
}

export enum InvoiceItemRefType {
  OrganizationSubscription
}

export enum InvoiceType {
  Subscription,
  CarServiceEntry,
  CarServiceEntryAdvancePayment,
  SMS
}
