import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EffectsModule} from "@ngrx/effects";
import {DraftUIEventProcessor} from "./event-processor/ui-event.processor";
import {DraftsSandbox} from "./components/drafts/drafts.sandbox";
import {DraftsComponent} from "./components/drafts/drafts.component";
import {SharedModule} from "../core/shared/shared.module";
import {AddOfferWizardComponent} from '../offer/components/add-offer-wizard/add-offer-controller/add-offer-wizard.component';
import {OfferModule} from '../offer/offer.module';



@NgModule({
  declarations: [
      DraftsComponent
  ],
   entryComponents: [AddOfferWizardComponent],
  imports: [
    CommonModule,
    SharedModule,
      OfferModule,
    EffectsModule.forFeature([DraftUIEventProcessor])
  ],
  providers : [DraftsSandbox],
  exports : [
      DraftsComponent
  ],

})
export class DraftModule { }
