import {EventEmitter, Injectable} from '@angular/core';
import * as signalR from "@microsoft/signalr";
import {IHttpConnectionOptions} from "@microsoft/signalr";
import {ConfigService} from "../app-config.service";
import {isValidObject} from "../../shared/helpers/common.helpers";
import {CachingUtils} from "../../shared/caching.utils";
import {AppNotification, LoggedUser_Dto} from "../../app-dto/core.dto";
import {DispatchNotificationActionProxy} from "../components/notifications/dispatch-notification.action-proxy";
import {Store} from "@ngrx/store";
import * as store from "../../app-store";

declare var window: any;

@Injectable()
export class RtcService {
  messageReceived = new EventEmitter<any>();
  private currentToken: string;
  public isConnected: boolean = false;
  private connectionIsEstablished = false;
  private hubConnection: signalR.HubConnection;

  public static instance: RtcService;


  constructor(private configService: ConfigService,
              public appState$: Store<store.State>) {
    RtcService.instance = this;
    window.addEventListener("unload", function (event) {
      RtcService.instance.clientEvent_whenUserIsClosingTheBrowser()
    });
  }

  sendMessage(message: any) {
    this.hubConnection.invoke('sendMessage', message);
  }

  public initialize() {
    const user: any = CachingUtils.loadData(LoggedUser_Dto.identifier);
    if (user.token == this.currentToken) return;
    else this.currentToken = user.token;
    this.createConnection(user);
    this.registerOnServerEvents();
    this.startConnection();
  }

  private createConnection(user: any) {
    const options: IHttpConnectionOptions = {
      accessTokenFactory: () => this.currentToken,
    };
    const wsUrl = this.configService.getAPISocketUrl() + '/hub';
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(wsUrl, options)
      .withAutomaticReconnect([5, 5, 5, 5])
      .build();
  }

  private startConnection(): void {
    this.hubConnection
      .start()
      .then(() => {
        this.connectionIsEstablished = true;
        console.log('Hub connection started');
        RtcService.instance.isConnected = true;
      })
      .catch(err => {
        console.log('Error while establishing connection, retrying...' + err);
        setTimeout(function () {
          RtcService.instance.startConnection();
        }, 5000);
      });
    this.hubConnection.onreconnecting(function () {
      console.log('[SignalR - Lifecycle:] - Client is trying to reconnect...');
      window.signalR_isTryingToReconnect = true;
      window.signalR_shouldReconnect = true;
      RtcService.instance.isConnected = false;
    });

    this.hubConnection.onreconnected(function () {
      console.log('[SignalR - Lifecycle:] - Client is reconnected.');
      window.signalR_isConnectedToHub = true;
      RtcService.instance.isConnected = true;
      ;
      window.signalR_isTryingToConnect = false;
      window.signalR_isTryingToReconnect = false;
    });

    this.hubConnection.onclose(function (error: Error) {
      window.signalR_isConnectedToHub = false;
      RtcService.instance.isConnected = false;
      console.log('[SignalR - Lifecycle:] - SignalR connection droped at: ' + new Date().toLocaleString());
      if (isValidObject(error))
        console.log("[SignalR - Lifecycle:] - Reason for disconnect is: " + error.message);

    });
  }

  private registerOnServerEvents(): void {
    this.hubConnection.on('broadcastNotification', (data: AppNotification) => {
      console.info(`SignalR - Lifecycle:] - Incoming WS notification:\n${JSON.stringify(data, null, 2)}`);
      const dto = new AppNotification(data);
      const ap = new DispatchNotificationActionProxy(null, RtcService.instance.appState$);
      ap.execute(dto);
      this.messageReceived.emit(data);
    });
  }

  public disconnect(shouldReconnect: boolean): void {
    if (this.hubConnection != null) {
      window.signalR_shouldReconnect = shouldReconnect;
      console.log('[SignalR - Lifecycle:] - Will disconnect from SignalR Hub...');
      this.hubConnection.stop().then(() => {
        console.log('[SignalR - Lifecycle:] - Signalr disconnected...');
      });
    }
  }

  clientEvent_whenUserIsClosingTheBrowser(): void {

    this.disconnect(false);
    return undefined;
  }
}
