import {Component, OnInit, AfterViewInit, ViewChildren, QueryList} from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { BaseListWebComponent } from 'src/app/core/shared/base/base.component';
import { DataTableDirective } from 'angular-datatables';
import { Subscription } from 'rxjs/Subscription';
import { ConfigService } from 'src/app/core/core/app-config.service';
import { Router } from '@angular/router';
import {
  SeamlessEvent,
  ResetSection,
  EventWithScreenLoading,
  Do_GenericSuccessEvent, EventWithStopingScreenLoader
} from 'src/app/core/app-store/events/base.events';
import { SearchEvent } from 'src/app/core/app-store';
import { ActiveDataTableParameters } from 'src/app/core/shared/datatable/datatable.helpers';
import { GetServicePlatformStatusesActionProxy } from './get-service-platform-statuses.action-proxy';
import { ServicePlatformStatusListSandbox } from './service-platform-status-list.sandbox';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  AddCarInServiceEvent,
  EditCarServiceCarEvent,
  EditCarServiceClientEvent
} from "../../../core/app-store/events/car-service.events";


@Component({
  selector: 'app-service-platform-status-list',
  templateUrl: './service-platform-status-list.component.html',
  styleUrls: ['./service-platform-status-list.component.scss']
})
export class ServicePlatformStatusListComponent extends BaseListWebComponent implements OnInit, AfterViewInit {
  public getCarServiceEntriesAP: GetServicePlatformStatusesActionProxy;
  public columns: any = [];

  constructor(public sandbox: ServicePlatformStatusListSandbox,private actionsSubj: ActionsSubject,
              private spinner: NgxSpinnerService) {
    super(sandbox, ResetSection.Cars);
    this.getCarServiceEntriesAP = new GetServicePlatformStatusesActionProxy(this, sandbox.appState, this.spinner);
    this.columns = [
      { data: 'servicePlatform', name : 'Rampa', width: '25%'},
      { data: 'status', name : 'Status', width: '15%'},
      { data: 'car', name: 'Masina', width: '40%'},
      { data: 'mechanic', name: 'Mecanic', width: '20%'}
    ];

    this.sandbox.addSubscription(actionsSubj.subscribe(data => {
      if (data.type === Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + AddCarInServiceEvent.identifier ||
        data.type === Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + EditCarServiceClientEvent.identifier ||
        data.type === Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + EditCarServiceCarEvent.identifier) {
        this.getCarServiceEntriesAP.execute();
      }
    }));
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.initialize();
    this.getCarServiceEntriesAP.execute();
    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      scrollY: '300px',
      scrollCollapse: true,
      ajax: () => {
      },
      columns: this.columns,
      order: [[1, 'asc']],
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
  }

}
