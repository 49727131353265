import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {Store} from "@ngrx/store";
import * as store from "../../../../core/app-store";
import {BaseFormModelComponent} from "../../../../core/shared/base/base.component";
import {Offer_Dto, OfferWorkEntry_Dto} from "../../../../core/app-dto/offer.dto";
import {AddOfferThirdStepDi} from "./add-offer-third-step.di";
import {Car_Dto} from "../../../../core/app-dto/car.dto";
import {OrganizationClientSearchComponent} from "../../../../organization-client/components/organization-client-search/organization-client-search.component";
import {AddOfferThirdStepSandbox} from "./add-offer-third-step.sandbox";
import {ClientCarParts_Dto} from "../../../../core/app-dto/car-service.dto";
import {ObjectValidators} from "../../../../core/shared/object.validators";
import {AddOfferCarWorksListComponent} from "../../add-offer-car-works-list/add-offer-car-works-list.component";
import {isValidObject, makeInputPositive} from '../../../../core/shared/helpers/common.helpers';
import {CarFormDI} from '../../../../car/components/car-form/car-form.di';

@Component({
    selector: 'app-offer-third-step',
    templateUrl: './add-offer-third-step.component.html',
    styleUrls: ['./add-offer-third-step.component.scss']
})
export class AddOfferThirdStepComponent extends BaseFormModelComponent<Offer_Dto> implements OnInit, OnDestroy {
    public di: AddOfferThirdStepDi;

    @Output()
    modelSaved = new EventEmitter<Offer_Dto>();

    @Output()
    eventSelectedOutput = new EventEmitter<string>();

    @Input("car") car: Car_Dto;

    private forOfferValue: boolean = false;
    @Input('forOffer') set forOffer(value: boolean) {
        this.forOfferValue = value;
        if (isValidObject(this.di)) {
            this.di = new AddOfferThirdStepDi(this.di.model, this.forOfferValue);
        }
    }

    @ViewChild(OrganizationClientSearchComponent, {static: false}) organizationClientSearchComponent: OrganizationClientSearchComponent;
    @ViewChild(AddOfferCarWorksListComponent, {static: false}) carWorksList: AddOfferCarWorksListComponent;

    constructor(public appState: Store<store.State>,
                public sandbox: AddOfferThirdStepSandbox) {
        super(sandbox);
    }

    public diInitialize(data: Offer_Dto) {
        this.di = new AddOfferThirdStepDi(data, this.forOfferValue);
        this.dataLoaded = true;
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnDestroy() {
        this.destroy();
    }

    public accept() {
        this.formSubmitted = true;
        this.markFormAsDirtyAndTouched();
        if (this.di.isValid()) {
            if (this.modelSaved != null) {
                const offer = this.di.getModel();
                offer.offerEntryData.carWorksRequests = this.carWorksList.di.getModel();
                this.modelSaved.emit(offer);
            }
        }
    }

    isValid() {
        return this.di.isValid();
    }

    offerCarWorksSelected(carWorks: Array<OfferWorkEntry_Dto>) {
        this.di.model.offerEntryData.carWorksRequests = carWorks;
        this.modelSaved.emit(this.di.getModel());
    }

    offerClientCarPartsSaved(data: Array<ClientCarParts_Dto>) {
        if (ObjectValidators.isValidArray(data))
            this.di.model.clientCarParts = data;
    }

    makeValuePositive(event: any) {
        makeInputPositive(event);
    }
}
