import {
  UIGeneratedEvent,
  EventWithScreenLoading, SeamlessEvent
} from './base.events';
import { ActiveDataTableParameters } from '../../shared/datatable/datatable.helpers';
import { Department_Dto } from '../../app-dto/department.dto';

export class GetDepartmentsPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetDepartmentsPagedResultEvent";
    constructor(public parameters: ActiveDataTableParameters, public withReset: boolean = false) {
        super();
        this.type += GetDepartmentsPagedResultEvent.identifier;
    }
}

export class GetDepartmentsEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetDepartmentsEvent";
    constructor() {
        super();
        this.type += GetDepartmentsEvent.identifier;
    }
}

export class RegisterDepartmentEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "RegisterDepartmentEvent";
    constructor(public model: Department_Dto) {
        super();
        this.type += RegisterDepartmentEvent.identifier;
    }
}

export class GetDepartmentEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetDepartmentEvent";
    constructor(public id: string) {
        super();
        this.type += GetDepartmentEvent.identifier;
    }
}


export class EditDepartmentEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "EditDepartmentEvent";
    constructor(public model: Department_Dto) {
        super();
        this.type += EditDepartmentEvent.identifier;
    }
}


export class DeleteDepartmentEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeleteDepartmentEvent";
    constructor(public id: string, public callback:Function = null) {
        super();
        this.type += DeleteDepartmentEvent.identifier;
    }
}

export class ActivateDepartmentEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "ActivateDepartmentEvent";
    constructor(public id: string) {
        super();
        this.type += ActivateDepartmentEvent.identifier;
    }
}

export class DeactivateDepartmentEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeactivateDepartmentEvent";
    constructor(public id: string) {
        super();
        this.type += DeactivateDepartmentEvent.identifier;
    }
}
