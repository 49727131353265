import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebFormComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { GetMechanicListEvent } from 'src/app/core/app-store/events/mechanic.events';
import { GetServicePlatformsListEvent } from 'src/app/core/app-store/events/service-platform.events';
import { GetDepartmentsEvent } from '../../../core/app-store/events/department.events';

export class GetDataActionProxy extends BaseActionProxy {
    constructor(component: IWebFormComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(): any {
        if (this.canExecute()) {
            this.appState.dispatch(new GetMechanicListEvent());
            this.appState.dispatch(new GetServicePlatformsListEvent());
            this.appState.dispatch(new GetDepartmentsEvent());
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

