import {
    UIGeneratedEvent,
    EventWithScreenLoading,
} from './base.events';

export class SearchEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = 'SearchEvent';
    constructor(public value: string) {
        super();
        this.type += SearchEvent.identifier;
    }
}
