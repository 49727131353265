import { State } from '../state';
import * as fromDepartment from '../stores/department.store';
import { createSelector } from '@ngrx/store';

export const getDepartmentStateCallback = (state: State) => state.department;

export const getDepartmentTimestamp = createSelector(getDepartmentStateCallback, fromDepartment.getTimestampCallback);
export const getDepartment = createSelector(getDepartmentStateCallback, fromDepartment.getDepartmentCallback);
export const getDepartmentsListing = createSelector(getDepartmentStateCallback, fromDepartment.getDepartmentsPagedResultCallback);
export const getAllDepartmentsListing = createSelector(getDepartmentStateCallback, fromDepartment.getDepartmentsCallback);

