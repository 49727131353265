import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {BaseListWebComponentWithSearchAndActiveBehavior} from "../../../core/shared/base/base.component";
import {GetCarPartsActionProxy} from "../car-parts-list/get-car-parts.action-proxy";
import {GetExcelCarPartsActionProxy} from "../car-parts-list/get-excel-car-parts-action.proxy";
import {GetSuppliersActionProxy} from "../save-car-part/get-suppliers.action-proxy";
import {GetExcelCarPartsImportTemplateActionProxy} from "../car-parts-list/get-excel-car-parts-import-template-action.proxy";
import {AddImportTaskActionProxy} from "../../../calendar/components/calendar/add-import-task.action-proxy";
import {CarPartsTableComponent} from "../../../core/shared/car-parts-table/car-parts-table.component";
import {CarPartsListSandbox} from "../car-parts-list/car-parts-list.sandbox";
import {ActionsSubject, Store} from "@ngrx/store";
import {ConfigService} from "../../../core/core/app-config.service";
import {Router} from "@angular/router";
import {BsModalService} from "ngx-bootstrap";
import * as store from "../../../core/app-store";
import {NotifierService} from "angular-notifier";
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";
import {ResetSection} from "../../../core/app-store/events/base.events";
import {
    ActiveDataTableParameters, DataTableParameters,
    DataTableParametersOrderEntity
} from "../../../core/shared/datatable/datatable.helpers";
import {DateHelpers} from "../../../core/shared/date.helpers";
import {SaveCarPartComponent} from "../save-car-part/save-car-part.component";
import {FileWrapper} from "../../../core/upload/components/upload-notification/upload-notification.di";
import {isValidExcelFile, isValidObject} from "../../../core/shared/helpers/common.helpers";
import {AsyncJob, AsyncJobType, FileToUpload, FileUploadTarget} from "../../../core/app-dto/core.dto";
import {UploadInputEvent} from "../../../core/app-store";
import {UploadInput} from "../../../core/upload/components/upload-notification/upload-notification.component";
import {GetSupplyItemsForCarPartPagedResultEvent} from "../../../core/app-store/events/car-part.events";
import {IdName_Dto} from "../../../core/app-dto/misc.dto";
import {ActivateCarPartStockAlertActionProxy} from "../../../core/shared/car-parts-table/activate-car-part-stock-alert.action-proxy";
import {DeactivateCarPartStockAlertActionProxy} from "../../../core/shared/car-parts-table/deactivate-car-part-stock-alert.action-proxy";
import {ConfirmationDialogService} from "../../../core/core/dialog/confirmation-dialog.service";
import {ActivateCarPartActionProxy} from "../../../core/shared/car-parts-table/activate-car-part.action-proxy";
import {DeactivateCarPartActionProxy} from "../../../core/shared/car-parts-table/deactivate-car-part.action-proxy";
import {DeleteCarPartActionProxy} from "../../../core/shared/car-parts-table/delete-car-part.action-proxy";
import {CarPart_Dto} from "../../../core/app-dto/car-part.dto";
import {SupplyItemsSandbox} from "./supply-items.sandbox";
import {GetSupplyItemsForCarPartActionProxy} from "./get-supply-items-for-car-part.action-proxy";
import {SupplyItemDi} from "./supply-item.di";
import {DeleteSupplyItemActionProxy} from "./delete-supply-item.action-proxy";

@Component({
    selector: 'app-supply-items',
    templateUrl: './supply-items.component.html',
    styleUrls: ['./supply-items.component.scss']
})
export class SupplyItemsComponent extends BaseListWebComponentWithSearchAndActiveBehavior implements OnInit, AfterViewInit {
    public getDataAP: GetSupplyItemsForCarPartActionProxy;
    public deleteDataAP: DeleteSupplyItemActionProxy;
    public includeUsedOnes = false;
    public columns: any = [];
    public sortColumns: Array<IdName_Dto> = [];
    public currentPage = 1;

    @Input('carPart') carPart: CarPart_Dto = null;

    constructor(public sandbox: SupplyItemsSandbox,
                actionsSubj: ActionsSubject,
                private configService: ConfigService,
                public router: Router, public confirmationDialogService: ConfirmationDialogService,
                private modalService: BsModalService,
                public rulesProvider: AppUserBusinessRuleProvider) {
        super(sandbox, actionsSubj);
        this.getDataAP = new GetSupplyItemsForCarPartActionProxy(this, sandbox.appState);
        this.deleteDataAP = new DeleteSupplyItemActionProxy(this, sandbox.appState);
        this.columns = [
            {data: 'quantity', name: 'Cantitate totala', width: '34%'},
            {data: 'availableQuantity', name: 'Cantitate disponibila', width: '33%'},
            {data: 'createdOn', name: 'Data inregistrarii', width: '33%'},
            {data: 'action', width: '50px'},
        ];
        this.sortColumns = this.columns.filter(c => c.name).map(c => ({id: c.data, name: c.name} as IdName_Dto));
    }

    ngAfterViewInit() {
    }

    ngOnInit() {
        this.initialize();
        this.dtOptions = {
            responsive: true,
            paging: false,
            info: false,
            lengthChange: false,
            searching: false,
            pageLength: this.configService.getDefaultPageSize(),
            scrollX: false,
            serverSide: true,
            processing: false,
            ordering: false,
            autoWidth: false,
            ajax: (dataTablesParameters: DataTableParameters, callback) => {
                dataTablesParameters.search.value = this.searchValue;
                this.doGetAll(new DataTableParameters(dataTablesParameters), callback, false);
            },
            columns: this.columns,
            order: [[2, 'desc']],
            dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
        };
    }

    public doGetAll(dataTablesParameters: DataTableParameters, callback: any, withReset: boolean) {
        this.sandbox.callback = callback;
        this.sandbox.dataTableParameters = dataTablesParameters;
        this.getDataAP.execute(this.carPart.id, dataTablesParameters, withReset, this.includeUsedOnes || false);
    }


    deleteSupplyItem(data: SupplyItemDi) {
        this.confirmationDialogService.confirm(
            'Stergere inregistrarea',
            "Urmeaza sa stergeti o inregistrare a piesei <b>" + data.model.code + "</b>. Sunteti sigur?",
            'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
            if (confirmed) {
                this.deleteDataAP.execute(data.model.id);
            }
        });

    }

    addSupplyItem() {
        const modalRef = this.modalService.show(SaveCarPartComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
        modalRef.content.dataInput = null;
    }

    includeUsedOnesChecked(value: boolean) {
        this.includeUsedOnes = value;
        if (this.dtApiInstance != null)
            this.dtApiInstance.ajax.reload();
    }

    updateCarPart(data: SupplyItemDi) {
        const carPart = new CarPart_Dto(this.carPart);
        carPart.id = data.model.id;
        carPart.quantity = data.model.documentedQuantity;
         const modalRef = this.modalService.show(SaveCarPartComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
         modalRef.content.dataInput = carPart;
    }

    onPageChange(currentPage: number) {
        this.sandbox.dataTableParameters.pageNumber = currentPage;
        this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
    }

    onSortChange($event) {
        if ($event) {
            const columnId = this.columns.map((e) => e.data).indexOf($event.id);
            const orderEntity = new DataTableParametersOrderEntity({column: columnId, dir: 'asc'});
            this.sandbox.dataTableParameters.order = [orderEntity];
        }
        this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
    }
}
