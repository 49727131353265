import { PagedResult } from '../../shared/datatable/datatable.helpers';
import { Action } from '@ngrx/store';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from '../events/base.events';
import { ResetStateActionProxy } from '../../shared/base/base.action-proxy';
import {
  ActivateOrganizationClientEvent,
  DeactivateOrganizationClientEvent,
  DeleteOrganizationClientEvent,
  EditOrganizationClientEvent,
  GetOrganizationClientCreditEvent,
  GetOrganizationClientEvent,
  GetOrganizationClientsPagedResultEvent,
  RegisterOrganizationClientEvent, ResetInfoFromCuiEvent,
  SearchOrganizationClientEvent
} from '../events/organization-client.events';
import * as moment from 'moment';
import {OrganizationClient_Dto} from '../../app-dto/organization-client.dto';
import {CompanyInfo_Dto} from "../../app-dto/company-info.dto";
import {GetClientForCarEvent} from '../events/car-service.events';

export interface OrganizationClientState {
  lastRefreshTime: number;
  organizationClient: OrganizationClient_Dto;
  OrganizationClientsPagedResult: PagedResult<OrganizationClient_Dto>;
  organizationClientSearchList: Array<OrganizationClient_Dto>;
  credit: number;
  companyInfo: CompanyInfo_Dto;
  clientForCar: OrganizationClient_Dto;
}

export const INITIAL_STATE: OrganizationClientState = Object.assign({}, {
  lastRefreshTime: 0,
  organizationClient: null,
  OrganizationClientsPagedResult: new PagedResult<OrganizationClient_Dto>([]),
  organizationClientSearchList: [],
  credit: 0,
  companyInfo: null,
  clientForCar: null
});


export function reducer(state = INITIAL_STATE, action: Action): OrganizationClientState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {

    // Get Client For Car

    case Do_GenericSuccessEvent.identifier + GetClientForCarEvent.identifier: {
      const clientForCarData = (action as Do_GenericSuccessEvent<OrganizationClient_Dto>).data;
      return Object.assign({}, state, { clientForCar: clientForCarData });
    }

    case Do_GenericErrorEvent.identifier + GetClientForCarEvent.identifier: {
      return Object.assign({}, state, { clientForCar: null });
    }

    case Do_ResetStateForSection.identifier + ResetSection.ClientForCar: {
      return Object.assign({}, state, { clientForCar: null });
    }
    // Get Client For Car

    // Get Organization Client

    case Do_ResetStateForSection.identifier + ResetSection.OrganizationClient: {
      return Object.assign({}, state, { organizationClient: null });
    }

    case Do_GenericSuccessEvent.identifier + GetOrganizationClientEvent.identifier: {
      const organizationClientData = new OrganizationClient_Dto((action as Do_GenericSuccessEvent<OrganizationClient_Dto>).data);
      return Object.assign({}, state, { organizationClient: organizationClientData });
    }

    case Do_GenericErrorEvent.identifier + GetOrganizationClientEvent.identifier: {
      return Object.assign({}, state, { organizationClient: null });
    }

    case ResetStateActionProxy.name + GetOrganizationClientEvent.identifier: {
      return Object.assign({}, state, { organizationClient: null });
    }

    // Get Organization Client



    // Get Organization Clients

    case Do_ResetStateForSection.identifier + ResetSection.OrganizationClients: {
      return Object.assign({}, state, { OrganizationClientsPagedResult: new PagedResult<OrganizationClient_Dto>([]) });
    }

    case GetOrganizationClientsPagedResultEvent.identifier: {
      if ((action as GetOrganizationClientsPagedResultEvent).withReset) {
        state.OrganizationClientsPagedResult = new PagedResult<OrganizationClient_Dto>([]);
        return Object.assign({}, state);
      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetOrganizationClientsPagedResultEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<PagedResult<OrganizationClient_Dto>>).data;
      state.OrganizationClientsPagedResult = new PagedResult<OrganizationClient_Dto>(data);
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetOrganizationClientsPagedResultEvent.identifier: {
      state.OrganizationClientsPagedResult = new PagedResult<OrganizationClient_Dto>([]);
      return Object.assign({}, state);
    }

    // Get Organization Client

    // Search Organization Client

    case Do_GenericSuccessEvent.identifier + SearchOrganizationClientEvent.identifier: {
      const organizationClientSearchData = (action as Do_GenericSuccessEvent<Array<OrganizationClient_Dto>>).data.map(r => new OrganizationClient_Dto(r));
      return Object.assign({}, state, { organizationClientSearchList: organizationClientSearchData });
    }

    case Do_GenericErrorEvent.identifier + SearchOrganizationClientEvent.identifier: {
      return Object.assign({}, state, { organizationClientSearchList: [] });
    }

    // Search Organization Client

    case Do_GenericSuccessEvent.identifier + RegisterOrganizationClientEvent.identifier: {
      return Object.assign({}, state, { lastRefreshTime: moment().unix(), organizationClient: null });
    }

    case Do_GenericSuccessEvent.identifier + EditOrganizationClientEvent.identifier: {
      const data = new OrganizationClient_Dto((action as Do_GenericSuccessEvent<OrganizationClient_Dto>).data);
      return Object.assign({}, state, { lastRefreshTime: moment().unix(), organizationClient: data });
    }

    case Do_GenericSuccessEvent.identifier + ActivateOrganizationClientEvent.identifier: {
      return Object.assign({}, state, { lastRefreshTime: moment().unix(), organizationClient: null });
    }

    case Do_GenericSuccessEvent.identifier + DeactivateOrganizationClientEvent.identifier: {
      return Object.assign({}, state, { lastRefreshTime: moment().unix(), organizationClient: null });
    }

    case Do_GenericSuccessEvent.identifier + DeleteOrganizationClientEvent.identifier: {
      return Object.assign({}, state, { lastRefreshTime: moment().unix(), organizationClient: null });
    }

    case Do_GenericSuccessEvent.identifier + GetOrganizationClientCreditEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<number>).data;
      return Object.assign({}, state, { credit: data });
    }

    case ResetInfoFromCuiEvent.identifier: {
      return Object.assign({}, state, {companyInfo: null});
    }

    default: {
      return state;
    }
  }
}

export const getTimestampCallback = (state: OrganizationClientState) => state.lastRefreshTime;
export const getOrganizationClientCallback = (state: OrganizationClientState) => state.organizationClient;
export const getOrganizationClientsPagedResultCallback = (state: OrganizationClientState) => state.OrganizationClientsPagedResult;
export const getOrganizationClientSearchListCallback = (state: OrganizationClientState) => state.organizationClientSearchList;
export const getOrganizationClientCreditCallback = (state: OrganizationClientState) => state.credit;
export const getOrganizationClientInfoCallback = (state: OrganizationClientState) => state.companyInfo;
export const getClientForCarCallback = (state: OrganizationClientState) => state.clientForCar;
