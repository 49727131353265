import { PagedResult } from '../../shared/datatable/datatable.helpers';
import { Action } from '@ngrx/store';
import {
    Do_GenericErrorEvent,
    Do_GenericSuccessEvent,
    Do_ResetStateForSection,
    EventWithScreenLoading,
    EventWithStopingScreenLoader,
    ResetSection,
    SeamlessEvent
} from '../events/base.events';
import { ResetStateActionProxy } from '../../shared/base/base.action-proxy';
import { Car_Dto } from '../../app-dto/car.dto';
import {
    ActivateCarEvent,
    DeactivateCarEvent, DeleteCarEvent, EditCarEvent,
    GetCarEvent,
    GetCarsPagedResultEvent,
    RegisterCarEvent
} from '../events/car.events';
import * as moment from 'moment';
import { CalendarEvent_Dto } from '../../app-dto/calendar-event.dto';
import {
    GetCalendarEventsEvent,
    RegisterCalendarEventEvent,
    EditCalendarEventEvent,
    DeleteCalendarEventEvent,
    SearchCalendarEventEvent,
    ConfirmCalendarEvent,
    RegisterCarAppointmentEvent
} from '../events/calendar-events';
import { ObjectValidators } from '../../shared/object.validators';
import { CarAppointment_Dto } from '../../app-dto/car-appointment.dto';

export interface CalendarState {
    lastRefreshTime: number;
    calendarEvents: Array<CalendarEvent_Dto>;
    calendarSearchList: Array<CalendarEvent_Dto>;
    carAppointmentEvents: Array<CalendarEvent_Dto>;
}

export const INITIAL_STATE: CalendarState = Object.assign({}, {
    lastRefreshTime: 0,
    calendarEvents: [],
    calendarSearchList: [],
    carAppointmentEvents: []
});


export function reducer(state = INITIAL_STATE, action: Action): CalendarState {
    if (!action) return state;
    let actionType = action.type;

    actionType = actionType.replace(EventWithScreenLoading.identifier, '');
    actionType = actionType.replace(SeamlessEvent.identifier, '');
    actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
    switch (actionType) {

        // Get Calendar events
        case Do_ResetStateForSection.identifier + ResetSection.Calendar: {
            return Object.assign({}, state, { calendarEvents: [] });
        }

        case Do_GenericSuccessEvent.identifier + GetCalendarEventsEvent.identifier: {
            const calendarEventsData = (action as Do_GenericSuccessEvent<Array<CalendarEvent_Dto>>).data;

            return Object.assign({}, state, { calendarEvents: calendarEventsData });
        }

        case Do_GenericErrorEvent.identifier + GetCalendarEventsEvent.identifier: {
            return Object.assign({}, state, { calendarEvents: [] });
        }

        case ResetStateActionProxy.name + GetCalendarEventsEvent.identifier: {
            return Object.assign({}, state, { calendarEvents: [] });
        }
        // Get Calendar events

        case Do_GenericSuccessEvent.identifier + RegisterCalendarEventEvent.identifier: {
            const calendarEventData = (action as Do_GenericSuccessEvent<CalendarEvent_Dto>).data;
            if (ObjectValidators.isValidArray(state.calendarEvents)) {
                state.calendarEvents.push(calendarEventData);
                state.calendarEvents = state.calendarEvents.map(r => r);
            }
            return Object.assign({}, state);
        }

        case Do_GenericSuccessEvent.identifier + EditCalendarEventEvent.identifier:
        case Do_GenericSuccessEvent.identifier + ConfirmCalendarEvent.identifier: {
            const calendarEventData = (action as Do_GenericSuccessEvent<CalendarEvent_Dto>).data;
            if (ObjectValidators.isValidArray(state.calendarEvents)) {
                let index = state.calendarEvents.findIndex(r => r.id == calendarEventData.id);
                if (index > -1) {
                    state.calendarEvents[index] = calendarEventData;
                }
                state.calendarEvents = state.calendarEvents.map(r => r);
            }
            return Object.assign({}, state);
        }

        case Do_GenericSuccessEvent.identifier + DeleteCalendarEventEvent.identifier: {
            const calendarEventIdData = (action as Do_GenericSuccessEvent<string>).data;
            if (ObjectValidators.isValidArray(state.calendarEvents)) {
                let index = state.calendarEvents.findIndex(r => r.id == calendarEventIdData);
                if (index > -1) {
                    state.calendarEvents.splice(index, 1);
                }
                state.calendarEvents = state.calendarEvents.map(r => r);
            }
            return Object.assign({}, state);
        }

        // Search Calendar

        case Do_GenericSuccessEvent.identifier + SearchCalendarEventEvent.identifier: {
            const calendarSearchData = (action as Do_GenericSuccessEvent<Array<CalendarEvent_Dto>>).data;
            return Object.assign({}, state, { calendarSearchList: calendarSearchData });
        }

        case Do_GenericErrorEvent.identifier + SearchCalendarEventEvent.identifier: {
            return Object.assign({}, state, { calendarSearchList: [] });
        }

        // Search Calendar



        // Register Car Appointment events

        case Do_GenericSuccessEvent.identifier + RegisterCarAppointmentEvent.identifier: {
            const carAppointmentEventData = (action as Do_GenericSuccessEvent<CalendarEvent_Dto>).data;
            if (ObjectValidators.isValidArray(state.carAppointmentEvents)) {
                state.carAppointmentEvents.push(carAppointmentEventData);
                state.carAppointmentEvents = state.carAppointmentEvents.map(r => r);
            }
            return Object.assign({}, state);
        }

        // end of Register Car Appointment events

        default: {
            return state;
        }
    }
}

export const getTimestampCallback = (state: CalendarState) => state.lastRefreshTime;
export const getCalendarEventsCallback = (state: CalendarState) => state.calendarEvents;
export const getCalendarSearchListCallback = (state: CalendarState) => state.calendarSearchList;
