import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared/shared.module';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectModule } from '@ng-select/ng-select';
import { WorkmanshipFormComponent } from './components/workmanship-form/workmanship-form.component';
import { WorkmanshipFormSandbox } from './components/workmanship-form/workmanship-form.sandbox';


@NgModule({
  declarations: [
    WorkmanshipFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    SharedModule,
    DataTablesModule
  ],
  providers: [
    WorkmanshipFormSandbox
  ],
  exports: [
    WorkmanshipFormComponent
  ]
})
export class WorkmanshipModule { }
