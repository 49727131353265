import { BaseDI } from 'src/app/core/shared/base/base.di';
import {ServicePlatform_Dto} from "../../../core/app-dto/service-platform.dto";
import {CarAction_Dto} from "../../../core/app-dto/car-service.dto";
import {isValidArrayAndHasElements} from "../../../core/shared/helpers/common.helpers";

export class UnassignedServicePlatformDI extends BaseDI<Array<ServicePlatform_Dto>> {
  constructor(data: Array<ServicePlatform_Dto>) {
    super(data);
  }

  public getComputedCarNames(){
    if(isValidArrayAndHasElements(this.model)){
      return this.model.map(r=>r.car != null ? r.car.registrationNumber : '').join(',');
    }
    return '';
  }

  getModel(): Array<ServicePlatform_Dto> {
    return this.model;
  }
}

