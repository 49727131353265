import {Injectable} from "@angular/core";
import {Observable, Subscriber} from "rxjs";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {LoggedUser_Dto} from "../../app-dto/core.dto";
import * as store from '../../app-store/index';
import {BaseSandbox} from "../../shared/base/base.sandbox";
import {isValidArrayAndHasElements, isValidObject} from "../../shared/helpers/common.helpers";
import {DoLoadRulesForUser} from "../../app-store/events/base.events";

@Injectable()
export class AppUserBusinessRuleProvider extends BaseSandbox {
  public currentUser$ = this.appState.select(store.getLoggedUser);
  public currentUser: LoggedUser_Dto;
  public currentRules$ = this.appState.select(store.getCurrentUserRules);
  private subscribers: Array<Subscriber<AppUserBusinessRule>> = [];
  public rules: AppUserBusinessRule;
  private previousUser: LoggedUser_Dto;

  constructor(appState$: Store<store.State>, private router: Router) {
    super(appState$);
    this.subscriptions.push(this.currentUser$.subscribe((result: LoggedUser_Dto) => {
      if (result != null) {
        this.currentUser = result;
        if (isValidObject(this.previousUser)) {
          if (this.previousUser.id != this.currentUser.id) {
            this.appState.dispatch(new DoLoadRulesForUser());
            this.previousUser = this.currentUser;
          }
        }
        else {
          this.appState.dispatch(new DoLoadRulesForUser());
          this.previousUser = this.currentUser;
        }
      } else {
        this.currentUser = null;
        this.rules = null;
        this.previousUser = null;
        this.sendRulesToSubscribers();
      }
    }));
    this.subscriptions.push(this.currentRules$.subscribe((data: AppUserBusinessRule) => {
      if (isValidObject(data))
        this.rules = new AppUserBusinessRule(data);
      else this.rules = null;
      this.sendRulesToSubscribers();
    }));
  }

  private sendRulesToSubscribers() {
    if (isValidArrayAndHasElements(this.subscribers)) {
      this.subscribers.forEach(s => s.next(this.rules));
    }
  }

  public subscribeToRules(): Observable<AppUserBusinessRule> {
    return new Observable((observer) => {
      this.subscribers.push(observer);
      if (isValidObject(this.rules)) {
        observer.next(this.rules);
      }
    });
  }

  clearSandboxState() {
  }

  initialize(param1?: any, param2?: any, param3?: any, param4?: any, param5?: any): any {
  }
}

export class AppUserBusinessRule {
  public canManageOffers:boolean;
  public canManageSubscriptions: boolean;
  public canViewProfile: boolean;
  public canManageServicePlatforms: boolean;
  public canViewServicePlatformsWithLoadingInfo: boolean;
  public canManageUsers: boolean;
  public canManageMechanics: boolean;
  public canViewMechanics: boolean;
  public canManageCarParts: boolean;
  public canManageCars: boolean;
  public canManageCalendarEvents: boolean;
  public canManageOrganizationProfile: boolean;
  public canManageOrganizationClients: boolean;
  public canManageDepartments: boolean;
  public canManageOrganizationCosts: boolean;
  public canManageOrganizationSubscriptionInvoices: boolean;
  public canViewSuperAdminLogs: boolean;
  public canManageSupplies: boolean;
  public canManageCashRegisters: boolean;

  public canViewServiceDashboard: boolean;
  public canViewSuperAdminDashboard: boolean;
  public canViewSuperAdminInvoices: boolean;
  public canViewServicePlatformInvoices: boolean;
  public canViewOrganizationInvoices: boolean;
  public canViewOrganizationProfile: boolean;
  public canViewDrafts: boolean;
  public canViewAudit: boolean;

  public canViewProfitReport: boolean;
  public canViewMechanicReport: boolean;
  public canViewOrdersReport: boolean;
  public canViewProviderReport: boolean;

  public canViewSMSBought: boolean;
  public canViewSMSSent: boolean;
  public canBuySMS: boolean;
  public canBuySMSAsSuperAdmin: boolean;
  public canViewLastOrdersValueOnDashboard: boolean;
  public canImportCalendarAppointments: boolean;
  public canManageCarServiceEntryActions: boolean;
  public canViewCarServiceEntryActions: boolean;
  public canExportForSaga: boolean;
  public canViewStatisticsOnDashboard: boolean;
  public canManageAppNotifications: boolean;
  public canUploadFilesOnCarServiceEntry: boolean;
  public canUploadPhotosOnCarServiceEntry: boolean;
  public canUseCreditLimitForOrganizationClient: boolean;
  public canNotifyOrganizationClient: boolean;
  public canUserStockAlerts:boolean;


  constructor(data: any) {
    if (isValidObject(data)) {
      this.canManageOffers = data.canManageOffers;
      // true;  // @todo - replace with actual value
      this.canManageCarServiceEntryActions = data.canManageCarServiceEntryActions;
      this.canViewStatisticsOnDashboard = data.canViewStatisticsOnDashboard;
      this.canViewCarServiceEntryActions = data.canViewCarServiceEntryActions;
      this.canViewServicePlatformsWithLoadingInfo = data.canViewServicePlatformsWithLoadingInfo;
      this.canManageSubscriptions = data.canManageSubscriptions;
      this.canViewProfile = data.canViewProfile;
      this.canManageServicePlatforms = data.canManageServicePlatforms;
      this.canManageUsers = data.canManageUsers;
      this.canManageMechanics = data.canManageMechanics;
      this.canViewMechanics = data.canViewMechanics;
      this.canManageCarParts = data.canManageCarParts;
      this.canManageCars = data.canManageCars;
      this.canManageCalendarEvents = data.canManageCalendarEvents;
      this.canManageOrganizationProfile = data.canManageOrganizationProfile;
      this.canManageOrganizationClients = data.canManageOrganizationClients;
      this.canManageDepartments = data.canManageDepartments;
      this.canManageOrganizationCosts = data.canManageOrganizationCosts;
      this.canManageOrganizationSubscriptionInvoices = data.canManageOrganizationSubscriptionInvoices;
      this.canViewSuperAdminLogs = data.canViewSuperAdminLogs;
      this.canManageSupplies = data.canManageSupplies;
      this.canManageCashRegisters = data.canManageCashRegisters;

      this.canViewServiceDashboard = data.canViewServiceDashboard;
      this.canViewSuperAdminInvoices = data.canViewSuperAdminInvoices;
      this.canViewSuperAdminDashboard = data.canViewSuperAdminDashboard;
      this.canViewServicePlatformInvoices = data.canViewServicePlatformInvoices;
      this.canViewOrganizationInvoices = data.canViewOrganizationInvoices;
      this.canViewOrganizationProfile = data.canViewOrganizationProfile;
      this.canViewDrafts = data.canViewDrafts;
      this.canViewAudit = data.canViewAudit;

      this.canViewSMSBought = data.canViewSMSBought;
      this.canViewSMSSent = data.canViewSMSSent;
      this.canBuySMS = data.canBuySMS;
      this.canBuySMSAsSuperAdmin = data.canBuySMSAsSuperAdmin;
      this.canViewLastOrdersValueOnDashboard = data.canViewLastOrdersValueOnDashboard;
      this.canImportCalendarAppointments = data.canImportCalendarAppointments;

      this.canViewProfitReport = data.canViewProfitReport;
      this.canViewMechanicReport = data.canViewMechanicReport;
      this.canViewOrdersReport = data.canViewOrdersReport;
      this.canViewProviderReport = data.canViewProviderReport;
      this.canManageAppNotifications = data.canManageAppNotifications;
      this.canUploadFilesOnCarServiceEntry = data.canUploadFilesOnCarServiceEntry;
      this.canUploadPhotosOnCarServiceEntry = data.canUploadPhotosOnCarServiceEntry;
      this.canUseCreditLimitForOrganizationClient = data.canUseCreditLimitForOrganizationClient;
      this.canNotifyOrganizationClient = data.canNotifyOrganizationClient;
      this.canUserStockAlerts = data.canUserStockAlerts;
    }
    this.canExportForSaga = data.canExportForSaga;

  }
}
