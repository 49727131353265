import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'angular-calendar';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared/shared.module';
import { CoreModule } from '../core/core/core.module';
import { BsDatepickerModule, TimepickerModule, ModalModule, TooltipModule, TooltipConfig } from 'ngx-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { CalendarComponent } from './components/calendar/calendar.component';
import { AddEditCalendarEventComponent } from './components/add-edit-calendar-event/add-edit-calendar-event.component';
import { EventSearchComponent } from './components/event-search/event-search.component';

import { AddEditCalendarEventSandbox } from './components/add-edit-calendar-event/add-edit-calendar-event.sandbox';
import { CalendarSandbox } from './components/calendar/calendar.sandbox';
import { EventSearchSandbox } from './components/event-search/event-serach.sandbox';
import { OverviewComponent } from './components/overview/overview.component';
import { RouterModule } from '@angular/router';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { CalendarServicePlatformsComponent } from './components/calendar-service-platforms/calendar-service-platforms.component';
import { CalendarServicePlatformsSandbox } from './components/calendar-service-platforms/calendar-service-platforms.sandbox';
import { EventCardComponent } from './components/event-card/event-card.component';
import { CalendarCurrentTimeComponent } from './components/calendar-current-time/calendar-current-time.component';
import { CalendarNavigationComponent } from './components/calendar-nagivation/calendar-navigation.component';
import { CalendarNavigationSandbox } from './components/calendar-nagivation/calendar-navigation.sandbox';
import { DisabledIntervalComponent } from './components/disabled-interval/disabled-interval.component';
import { ArchwizardModule } from 'angular-archwizard';
import { CarModule } from '../car/car.module';
import { CarPartModule } from '../car-part/car-part.module';
import { OrganizationClientModule } from '../organization-client/organization-client.module';
import { AddEditCalendarEventWizardComponent } from './components/add-edit-calendar-event-wizard/add-edit-calendar-event-wizard.component';
import { AddEditCalendarEventWizardSandbox } from './components/add-edit-calendar-event-wizard/add-edit-calendar-event-wizard.sandbox';
import { AppointmentClientComponent } from './components/appointment-client/appointment-client.component';
import { AppointmentClientSandbox } from './components/appointment-client/appointment-client.sandbox';
import { AppointmentCarComponent } from './components/appointment-car/appointment-car.component';
import { AppointmentCarSandbox } from './components/appointment-car/appointment-car.sandbox';
import { AppointmentCarWorksSandbox } from './components/appointment-car-works/appointment-car-works.sandbox';
import { AppointmentCarWorksComponent } from './components/appointment-car-works/appointment-car-works.component';
import { CarWorkModule } from '../car-works/car-work.module';
import { AppointmentDetailsComponent } from './components/appointment-details/appointment-details.component';
import { AppointmentDetailsSandbox } from './components/appointment-details/appointment-details.sandbox';
import { AppointmentSummaryComponent } from './components/appointment-summary/appointment-summary.component';
import { AppointmentSummarySandbox } from './components/appointment-summary/appointment-summary.sandbox';
import { OverviewSandbox } from './components/overview/overview.sandbox';


@NgModule({
  declarations: [
    CalendarComponent,
    AddEditCalendarEventComponent,
    EventSearchComponent,
    OverviewComponent,
    StatisticsComponent,
    EventCardComponent,
    CalendarServicePlatformsComponent,
    CalendarCurrentTimeComponent,
    CalendarNavigationComponent,
    DisabledIntervalComponent,
    AddEditCalendarEventWizardComponent,
    AppointmentClientComponent,
    AppointmentCarComponent,
    AppointmentCarWorksComponent,
    AppointmentDetailsComponent,
    AppointmentSummaryComponent
  ],
  imports: [
    CoreModule,
    CommonModule,
    CalendarModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    NgSelectModule,
    ArchwizardModule,
    CarModule,
    CarPartModule,
    CarWorkModule,
    OrganizationClientModule,
    TooltipModule.forRoot(),
    BsDatepickerModule,
    TimepickerModule,
    ModalModule.forRoot(),
    RouterModule
  ],
  exports: [
    CalendarComponent,
    EventSearchComponent,
    CalendarServicePlatformsComponent,
    AddEditCalendarEventWizardComponent
  ],
  entryComponents: [
    AddEditCalendarEventComponent,
    AddEditCalendarEventWizardComponent
  ],
  providers: [
    AddEditCalendarEventSandbox,
    CalendarSandbox,
    EventSearchSandbox,
    CalendarServicePlatformsSandbox,
    CalendarNavigationSandbox,
    AddEditCalendarEventWizardSandbox,
    AppointmentClientSandbox,
    AppointmentCarSandbox,
    AppointmentCarWorksSandbox,
    AppointmentDetailsSandbox,
    AppointmentSummarySandbox,
    OverviewSandbox
  ]
})
export class CustomCalendarModule { }
