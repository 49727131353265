import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { GetCalendarEventsEvent } from 'src/app/core/app-store/events/calendar-events';
import { NgxSpinnerService } from 'ngx-spinner';

export class GetCalendarEventsActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
        appState: Store<store.State>,
        private spinner: NgxSpinnerService) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(startDate: Date, endDate: Date, type: string = null, search: string = null): any {
        setTimeout(() => {
            this.spinner.show('calendar');
          }, 0);
        this.appState.dispatch(new GetCalendarEventsEvent(startDate, endDate, type, search, () => {
            this.spinner.hide('calendar');
        }));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

