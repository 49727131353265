import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { CarService_Dto } from 'src/app/core/app-dto/car-service.dto';
import { FileAttachment } from 'src/app/core/app-dto/core.dto';
import { ObjectValidators } from 'src/app/core/shared/object.validators';

export class CarServiceCompleteDI extends BaseFormDI<CarService_Dto> {
    public images: Array<FileAttachment> = [];
    public carInfo: string = '';
    public carData: string = '';

    constructor(data: CarService_Dto = new CarService_Dto(null)) {
        super(new CarService_Dto(data));

        let computeCarInfo = [];
        let computeCarData = [];

        if (ObjectValidators.isValidString(this.model.car.make))
            computeCarInfo.push(this.model.car.make);
        if (ObjectValidators.isValidString(this.model.car.model))
            computeCarInfo.push(this.model.car.model);
        if (ObjectValidators.isValidString(this.model.car.year))
            computeCarInfo.push(this.model.car.year);
        if (ObjectValidators.isValidArray(this.model.car.fuel)) {
            this.model.car.fuel.forEach(r => {
                if (ObjectValidators.isValidString(r.name))
                    computeCarInfo.push(r.name);
            });
        }
        if (ObjectValidators.isValidString(this.model.car.cilindricalCapacity))
            computeCarInfo.push(this.model.car.cilindricalCapacity + ' cm3');
        if (ObjectValidators.isValidString(this.model.car.power))
            computeCarInfo.push(this.model.car.power + ' Kw');
        if(ObjectValidators.isValidString(this.model.car.bodySeries)){
          computeCarInfo.push(this.model.car.bodySeries);
        }

        if (ObjectValidators.isValidString(this.model.carServiceEntryData.actualNoOfKm))
            computeCarData.push(this.model.carServiceEntryData.actualNoOfKm + ' km');
        if (ObjectValidators.isValidString(this.model.carServiceEntryData.fuel))
            computeCarData.push(this.model.carServiceEntryData.fuel + '% combustibil');

        this.carInfo = computeCarInfo.join(', ');
        this.carData = computeCarData.join(', ');

        this.initForm();
    }

    getModel(): CarService_Dto {
        return this.model;
    }

    initForm() {
    }
}
