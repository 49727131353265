import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import {IWebComponent, IWebFormComponent} from 'src/app/core/shared/base/base.component';
import {BaseActionProxy, BaseFormActionProxy} from 'src/app/core/shared/base/base.action-proxy';
import { GetMechanicEvent } from '../../../core/app-store/events/mechanic.events';

export class GetMechanicActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(mechanicId: string): any {
        this.appState.dispatch(new GetMechanicEvent(mechanicId));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

