import { Store } from "@ngrx/store";
import * as store from "../../../core/app-store";
import { Observable } from "rxjs";
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import {
  MarkOrgInvoiceAsNotPaidEvent,
} from 'src/app/core/app-store/events/invoices.events';

export class MarkOrgInvoiceAsNotPaidActionProxy extends BaseActionProxy {
  constructor(component: IWebComponent,
              appState: Store<store.State>) {
    super(component, appState)
  }

  canExecute(): boolean {
    return true;
  }

  execute(invoiceId: string, organizationId:string): any {

    this.appState.dispatch(new MarkOrgInvoiceAsNotPaidEvent(invoiceId,organizationId));
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }
}

