import { ObjectValidators } from '../shared/object.validators';
import { Base_Dto, BaseCRUDRules } from './misc.dto';
import * as moment from 'moment';
import { ServicePlatform_Dto } from './service-platform.dto';
import { Mechanic_Dto } from './mechanic.dto';

export class CalendarEventRules extends BaseCRUDRules {
  constructor(data: any) {
    super(data);
  }
}

export class CalendarEvent_Dto extends Base_Dto {
  public title: string;
  public description: string;
  public clientName: string;
  public clientPhoneNumber: string;
  public carNumber: string;
  public notifications: Array<CalendarEventNotification_Dto>;
  public startDate: Date;
  public endDate: Date;
  public isFullDayEvent: boolean;
  public type: CalendarEventType;
  public state: CalendarEventState;
  public relatedEntityId: string;
  public parentRelatedEntityId: string;
  public parentRelatedType: CalendarEventParentRelatedEntityType;
  public relatedEntityType: CalendarEventRelatedEntityType;
  public servicePlatform: ServicePlatform_Dto;
  public mechanic: Mechanic_Dto;
  public makeModel: string;
  public isConfirmed: boolean;
  public isFinished: boolean;
  public rules: CalendarEventRules;
  public cardPosition?: CalendarEventCardPosition;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.title = data.title;
      this.description = data.description;
      this.clientName = data.clientName;
      this.clientPhoneNumber = data.clientPhoneNumber;
      this.carNumber = data.carNumber;
      this.isFullDayEvent = data.isFullDayEvent;
      this.type = data.type;
      this.state = data.state;
      this.parentRelatedEntityId = data.parentRelatedEntityId;
      this.parentRelatedType = data.parentRelatedType;
      this.relatedEntityType = data.relatedEntityType;
      this.relatedEntityId = data.relatedEntityId;
      this.servicePlatform = data.servicePlatform;
      this.mechanic = data.mechanic;
      this.makeModel = data.makeModel;
      if (data.isConfirmed != null)
        this.isConfirmed = data.isConfirmed;
      else this.isConfirmed = false;
      if (data.isFinished != null)
        this.isFinished = data.isFinished;
      else this.isFinished = false;
      if (ObjectValidators.isValidArray(data.notifications))
        this.notifications = data.notifications.map(r => new CalendarEventNotification_Dto(r));
      else
        this.notifications = [];
      if (ObjectValidators.isValidObject(data.startDate))
        this.startDate = moment.utc(data.startDate).toDate();
      if (ObjectValidators.isValidObject(data.endDate))
        this.endDate = moment.utc(data.endDate).toDate();
      this.rules = new CalendarEventRules(data.rules);
    }
  }
}

export class CalendarEventNotification_Dto extends Base_Dto {
  public type: EventNotificationType;
  public beforeAmount: number;
  public beforeAmountType: EventNotificationAmountType;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.type = data.type;
      this.beforeAmount = data.beforeAmount;
      this.beforeAmountType = data.beforeAmountType;
    }
  }
}

export class CalendarEventCardPosition {
  public top: number;
  public left: number;
  public width: number;
  public height: number;
  public overlapping: number;
  public order: number;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      this.top = data.top;
      this.left = data.left;
      this.width = data.width;
      this.height = data.height;
      this.overlapping = data.overlapping;
      this.order = data.order;
    }
  }
}

export enum CalendarEventRelatedEntityType {
  Car,
  CarService,
  Appointment,
  CarServiceAction
}

export enum CalendarEventParentRelatedEntityType {
  CarService,
  None
}

export enum CalendarEventState {
  None,
  Appointment,
  Reception,
  Todo,
  InProgress,
  Waiting,
  Done,
  LeftTheService
}

export enum CalendarEventStateLabel {
  'None',
  'Programata',
  'Receptie',
  'Nou',
  'In lucru',
  'In asteptare',
  'Finalizare'

}

export enum CalendarEventType {
  Appointment,
  ITPNotification,
  InsuranceNotification,
  CarServiceAction,
  CustomAppointment
}

export enum EventNotificationType {
  InApp,
  Email,
  SMS,
  NA
}

export enum EventNotificationAmountType {
  Minutes,
  Hours,
  Days,
  Weeks,
  NA
}

export class CalendarStatistics {
  public dailyCount: number = 0;
  public weeklyCount: number = 0;
  public monthlyCount: number = 0;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      this.dailyCount = data.dailyCount;
      this.weeklyCount = data.weeklyCount;
      this.monthlyCount = data.monthlyCount;
    }
  }
}
