import {Injectable} from '@angular/core';
import {BaseApiService} from './base-api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiErrorDto} from '../utils/api-error.dto';
import {ConfigService} from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {ErrorHelpers} from '../../shared/error.helpers';
import {
    AddPaymentMethod, CreateAnonymousCardSetupSession, CreateCardSetupSession,
    CreateCheckoutSession,
    StripeConfigResponse,
    UpdatePaymentMethod
} from "../../stripe-integration/dto/stripe.dto";

class StripeApiServiceRoutes {
    public static route_for_CREATE_PAYMENT_SESSION() {
        return '/api/stripeIntegration/create-payment-session';
    }

    public static route_for_GET_STRIPE_CONFIG() {
        return '/api/stripeIntegration/config';
    }

    public static route_for_ADD_PAYMENT_METHOD() {
        return '/api/stripeIntegration/add-payment-method';
    }

    public static route_for_UPDATE_PAYMENT_METHOD() {
        return '/api/stripeIntegration/update-payment-method';
    }

    public static route_for_CREATE_CARD_SETUP_SESSION() {
        return '/api/StripeIntegration/create-card-setup-session';
    }

    public static route_for_CREATE_ANONYMOUS_CARD_SETUP_SESSION() {
        return '/api/StripeIntegration/create-anonymous-card-setup-session';
    }
}

@Injectable()
export class StripeApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_getStripeConfig(): Observable<StripeConfigResponse> {
        const url = this.configService.getAPIBaseUrl() + StripeApiServiceRoutes.route_for_GET_STRIPE_CONFIG();

        return this.http.get(url)
            .map((res: StripeConfigResponse) => {
                return res;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_createCheckoutSession(data: CreateCheckoutSession): Observable<string> {
        const url = this.configService.getAPIBaseUrl() + StripeApiServiceRoutes.route_for_CREATE_PAYMENT_SESSION();

        return this.http.post(url, data)
            .map((res: any) => {
                return res.data;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_addPaymentMethod(data: AddPaymentMethod): Observable<string> {
        const url = this.configService.getAPIBaseUrl() + StripeApiServiceRoutes.route_for_ADD_PAYMENT_METHOD();

        return this.http.post(url, data)
            .map((res: any) => {
                return res.data;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_updatePaymentMethod(data: UpdatePaymentMethod): Observable<string> {
        const url = this.configService.getAPIBaseUrl() + StripeApiServiceRoutes.route_for_UPDATE_PAYMENT_METHOD();

        return this.http.post(url, data)
            .map((res: any) => {
                return res.data;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_createCardSetupSession(data: CreateCardSetupSession): Observable<string> {
        const url = this.configService.getAPIBaseUrl() + StripeApiServiceRoutes.route_for_CREATE_CARD_SETUP_SESSION();

        return this.http.post(url, data)
            .map((res: any) => {
                return res.data;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_createAnonymousCardSetupSession(data: CreateAnonymousCardSetupSession): Observable<string> {
        const url = this.configService.getAPIBaseUrl() + StripeApiServiceRoutes.route_for_CREATE_ANONYMOUS_CARD_SETUP_SESSION();
        return this.http.post(url, data)
            .map((res: any) => {
                return res.data;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
}
