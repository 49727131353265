import {ObjectValidators} from '../shared/object.validators';
import {Base_Dto, BaseCRUDRules, BusinessRule} from './misc.dto';
import * as moment from 'moment';
import {Address} from './core.dto';

export class OrganizationClientRules extends BaseCRUDRules {
  public canActivate: BusinessRule;
  public canDeactivate: BusinessRule;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.canActivate = new BusinessRule(data.canActivate);
      this.canDeactivate = new BusinessRule(data.canDeactivate);

    } else {
      this.canActivate = new BusinessRule();
      this.canDeactivate = new BusinessRule();
    }
  }
}

export class OrganizationClient_Dto extends Base_Dto {
  public isLegalEntity: boolean = false;
  public name: string;
  public cuiCnp: string;
  public isUniqueId: boolean = false;
  public nrRegCi: string;
  public phoneNumber: string;
  public email: string;
  public willClientAcceptNotifications: boolean = true;
  public iban: string;
  public bank: string;
  public applyCreditLimit: boolean;
  public creditLimit: number;
  public generalDiscount: number;
  public workmanshipDiscount: number;
  public carPartDiscount: number;
  public address: Address;
  public createdOn: Date;
  public updatedOn: Date;
  public rules: OrganizationClientRules;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.isLegalEntity = data.isLegalEntity || false;
      this.name = data.name;
      this.cuiCnp = data.cuiCnp;
      this.isUniqueId = data.isUniqueId;
      this.nrRegCi = data.nrRegCi;
      this.phoneNumber = data.phoneNumber;
      this.email = data.email;
      this.willClientAcceptNotifications = data.willClientAcceptNotifications;
      this.iban = data.iban;
      this.bank = data.bank;
      this.applyCreditLimit = data.applyCreditLimit;
      this.creditLimit = data.creditLimit;
      this.generalDiscount = data.generalDiscount;
      this.carPartDiscount = data.carPartDiscount;
      this.workmanshipDiscount = data.workmanshipDiscount;
      this.address = data.address != null ? new Address(data.address) : new Address(null);
      this.rules = new OrganizationClientRules(data.rules);

      if (ObjectValidators.isValidObject(data.createdOn))
        this.createdOn = moment.utc(data.createdOn).toDate();
      if (ObjectValidators.isValidObject(data.updatedOn))
        this.updatedOn = moment.utc(data.updatedOn).toDate();
      if (ObjectValidators.isValidObject(data.address))

        this.address = new Address(data.address);
    } else {
      this.address = new Address(null);
    }

    if(this.willClientAcceptNotifications == null)
      this.willClientAcceptNotifications = true;
  }
}
