import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { ObjectValidators } from '../../shared/object.validators';

@Injectable()
export class DateTimeService {

    constructor() {
    }

    toUTC(date: Date): Date {
        if (ObjectValidators.isValidObject(date))
            return moment(date).utc().toDate();
        return date;
    }

    fromUTC(date: Date): Date {
        if (ObjectValidators.isValidObject(date))
            return moment(date).tz("Europe/Bucharest").toDate(); // todo: get user time zone.
        return date;
    }
}
