import {BaseFormActionProxy} from "../../base/base.action-proxy";
import {IWebFormComponent} from "../../base/base.component";
import {Store} from "@ngrx/store";
import * as store from "../../../app-store";
import {
    Do_Register_PrintJob_Event
} from "../../../app-store/events/cash-register.events";
import {Observable} from "rxjs";

export class RegisterPrintJobActionProxy extends BaseFormActionProxy {
    constructor(component: IWebFormComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        this.component.markFormAsDirtyAndTouched();
        return this.component.di.isValid();
    }

    execute(): any {
        if (this.canExecute()) {
            this.component.markFormAsNotDirty();
            const model = this.component.di.getModel();
            this.appState.dispatch(new Do_Register_PrintJob_Event(model));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}
