import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { validateAllFormFields } from 'src/app/core/shared/helpers/common.helpers';
import { CustomValidators } from 'ngx-custom-validators';
import { ResetPasswordSandbox } from './reset-password.sandbox';
import { BaseFormComponent } from 'src/app/core/shared/base/base.component';
import { ResetPasswordDI } from './reset-password.di';
import { ResetPasswordActionProxy } from './reset-password.action-proxy';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import {ActivatedRoute, Params} from '@angular/router';
import {ActivateSubscriptionDI} from '../activate-subscription/activate-subscription.di';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseFormComponent implements OnInit, OnDestroy {

  public loginAP: ResetPasswordActionProxy;

  public errorMessage: string = '';

  constructor(public sandbox: ResetPasswordSandbox,
              public activatedRoute: ActivatedRoute) {
    super(sandbox, ResetSection.ResetPassword);
    this.loginAP = new ResetPasswordActionProxy(this, sandbox.appState);
    this.di = new ResetPasswordDI();
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params['code'] !== undefined) {
        this.di = new ResetPasswordDI();
        this.di.model.code = decodeURI(params['code']);
      }
    });

    this.initialize((message: string) => {
      this.errorMessage = message;
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  saveForm() {
    this.formSubmitted = true;
    this.loginAP.execute();
  }
}
