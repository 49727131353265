import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import {AsyncJob} from '../../../core/app-dto/core.dto';
import {RegisterAsyncJobEvent} from '../../../core/app-store/events/asyncjob.events';

export class AddImportTaskActionProxy  extends BaseActionProxy {
  constructor(component: IWebComponent,
              appState: Store<store.State>) {
    super(component, appState);
  }

  canExecute(): boolean {
    return true;
  }

  execute(data: AsyncJob, callback: (asyncJob: AsyncJob) => void): any {
    this.appState.dispatch(new RegisterAsyncJobEvent(data, callback));
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }
}

