import {Observable} from "rxjs";
import {Store} from '@ngrx/store';
import {Injectable} from "@angular/core";
import * as store from "../../app-store";
import {Actions, createEffect, Effect, ofType} from '@ngrx/effects';
import {filter, map, mergeMap, switchMap} from "rxjs/operators";
import {Router} from "@angular/router";
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  SeamlessEvent
} from "../../app-store/events/base.events";
import {NotifierService} from "angular-notifier";
import {StripeApiService} from "../../api-integration/services/stripe-api.service";
import {
    AddPaymentMethodEvent, CreateAnonymousCardSetupEvent, CreateCardSetupEvent,
    CreateStripeCheckoutPaymentEvent,
    GetStripeConfigEvent, UpdatePaymentMethodEvent
} from "../../stripe-integration/events/stripe.events";
import {StripeConfigResponse} from "../../stripe-integration/dto/stripe.dto";

@Injectable()
export class StripeEventProcessor {
    private readonly notifier: NotifierService;

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private apiService: StripeApiService,
        private router: Router,
        private notifierService: NotifierService,
    ) {
        this.notifier = notifierService;
    }


    @Effect()
    doLoadStripeConfig$
        :
        Observable<any> = this.actions$
        .pipe(
            ofType(GetStripeConfigEvent.identifier, SeamlessEvent.identifier + GetStripeConfigEvent.identifier),
            map((action: any) => action),
            switchMap((action: GetStripeConfigEvent) => {
                    return new Observable((observer) => {
                        this.apiService.u_getStripeConfig()
                            .subscribe((response: StripeConfigResponse) => {
                                observer.next(new Do_GenericSuccessEvent<StripeConfigResponse>(response, action));
                                if (action.callback) action.callback(response);
                                observer.complete();
                            }, (error: string) => {
                              observer.next(new Do_GenericErrorEvent<string>(error, action));
                              observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doCreateCheckoutSession$
        :
        Observable<any> = this.actions$
        .pipe(
            ofType(CreateStripeCheckoutPaymentEvent.identifier, SeamlessEvent.identifier + CreateStripeCheckoutPaymentEvent.identifier),
            map((action: any) => action),
            switchMap((action: CreateStripeCheckoutPaymentEvent) => {
                    return new Observable((observer) => {
                        this.apiService.u_createCheckoutSession(action.data)
                            .subscribe((response: string) => {
                                observer.next(new Do_GenericSuccessEvent<string>(response, action));
                                if (action.callback) action.callback(response);
                                observer.complete();
                            }, (error: string) => {
                              observer.next(new Do_GenericErrorEvent<string>(error, action));
                              observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doAddPaymentMethod$
        :
        Observable<any> = this.actions$
        .pipe(
            ofType(AddPaymentMethodEvent.identifier, SeamlessEvent.identifier + AddPaymentMethodEvent.identifier),
            map((action: any) => action),
            switchMap((action: AddPaymentMethodEvent) => {
                    return new Observable((observer) => {
                        this.apiService.u_addPaymentMethod(action.data)
                            .subscribe((response: string) => {
                                observer.next(new Do_GenericSuccessEvent<string>(response, action));
                                if (action.callback) action.callback(response);
                                observer.complete();
                            }, (error: string) => {
                              observer.next(new Do_GenericErrorEvent<string>(error, action));
                              observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doUpdatePaymentMethod$
        :
        Observable<any> = this.actions$
        .pipe(
            ofType(UpdatePaymentMethodEvent.identifier, SeamlessEvent.identifier + UpdatePaymentMethodEvent.identifier),
            map((action: any) => action),
            switchMap((action: UpdatePaymentMethodEvent) => {
                    return new Observable((observer) => {
                        this.apiService.u_updatePaymentMethod(action.data)
                            .subscribe((response: string) => {
                                observer.next(new Do_GenericSuccessEvent<string>(response, action));
                                if (action.callback) action.callback(response);
                                observer.complete();
                            }, (error: string) => {
                              observer.next(new Do_GenericErrorEvent<string>(error, action));
                              observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doCreateCardSetupSession$
        :
        Observable<any> = this.actions$
        .pipe(
            ofType(CreateCardSetupEvent.identifier, SeamlessEvent.identifier + CreateCardSetupEvent.identifier),
            map((action: any) => action),
            switchMap((action: CreateCardSetupEvent) => {
                    return new Observable((observer) => {
                        this.apiService.u_createCardSetupSession(action.data)
                            .subscribe((response: string) => {
                                observer.next(new Do_GenericSuccessEvent<string>(response, action));
                                if (action.callback) action.callback(response);
                                observer.complete();
                            }, (error: string) => {
                              observer.next(new Do_GenericErrorEvent<string>(error, action));
                              observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doCreateAnonymousCardSetupSession$
        :
        Observable<any> = this.actions$
        .pipe(
            ofType(CreateAnonymousCardSetupEvent.identifier, SeamlessEvent.identifier + CreateAnonymousCardSetupEvent.identifier),
            map((action: any) => action),
            switchMap((action: CreateAnonymousCardSetupEvent) => {
                    return new Observable((observer) => {
                        this.apiService.u_createAnonymousCardSetupSession(action.data)
                            .subscribe((response: string) => {
                                observer.next(new Do_GenericSuccessEvent<string>(response, action));
                                if (action.callback) action.callback(response);
                                observer.complete();
                            }, (error: string) => {
                              observer.next(new Do_GenericErrorEvent<string>(error, action));
                              observer.complete();
                            });
                    });
                }
            )
        );
}
