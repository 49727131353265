export class Cacheable {
  public cachingKey: string;

  constructor(cachingKey:string){
    this.cachingKey = cachingKey;
  }

  public store(): void {
    localStorage.setItem(this.cachingKey, JSON.stringify(this));
  }
}

export class CachingUtils {
  public static remove(cachingKey: string): void {
    localStorage.setItem(cachingKey, null);
  }

  public static loadData(cachingKey){
    let object = JSON.parse(localStorage.getItem(cachingKey));
    return object;
  }

  public static load<T>(cachingKey: string, type: (new (data?: any) => T)): T {
    let object = JSON.parse(localStorage.getItem(cachingKey));
    if (object != null) return new type(object);
    return null;
  }

  public static save(cachingKey: string, data:any): void {
    localStorage.setItem(cachingKey, JSON.stringify(data));
  }
}
