import {ObjectValidators} from '../shared/object.validators';
import {Base_Dto, BaseCRUDRules, BusinessRule, IdName_Dto} from './misc.dto';
import * as moment from 'moment';
import {Department_Dto} from './department.dto';
import {UserMechanicLink_Dto} from "./core.dto";
import {isValidArrayAndHasElements, isValidObject} from "../shared/helpers/common.helpers";
import {CarAction_Dto, CarService_Dto} from "./car-service.dto";

export class MechanicRules extends BaseCRUDRules {
  public canActivate: BusinessRule;
  public canDeactivate: BusinessRule;
  public canViewSalary: BusinessRule;
  public canEditHistory: BusinessRule;
  public canAddAccount: BusinessRule;
  public canDeleteAccount: BusinessRule;

  constructor(data: any) {
    super(data);

    if (ObjectValidators.isValidObject(data)) {
      this.canActivate = new BusinessRule(data.canActivate);
      this.canDeactivate = new BusinessRule(data.canDeactivate);
      this.canViewSalary = new BusinessRule(data.canViewSalary);
      this.canEditHistory = new BusinessRule(data.canEditHistory);
      this.canAddAccount = new BusinessRule(data.canAddAccount);
      this.canDeleteAccount = new BusinessRule(data.canDeleteAccount);
    } else {
      this.canActivate = new BusinessRule();
      this.canDeactivate = new BusinessRule();
      this.canViewSalary = new BusinessRule();
      this.canEditHistory = new BusinessRule();
      this.canAddAccount = new BusinessRule();
      this.canDeleteAccount = new BusinessRule();
    }
  }
}

export class MechanicHistory_Dto {
  public startDate: Date;
  public endDate: Date;
  public salary: number;
  public salaryAsPercentage: number;
  public isPaidUsingPercentageValue: boolean;
  public currency: IdName_Dto;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      if (ObjectValidators.isValidObject(data.startDate))
        this.startDate = moment.utc(data.startDate).toDate();
      if (ObjectValidators.isValidObject(data.endDate))
        this.endDate = moment.utc(data.endDate).toDate();
      this.salary = data.salary;
      this.salaryAsPercentage = data.salaryAsPercentage;
      this.isPaidUsingPercentageValue = data.isPaidUsingPercentageValue;
      this.currency = data.currency;
    }
  }
}

export class Mechanic_Dto extends Base_Dto {
  public firstName: string;
  public lastName: string;
  public fullName?: string;
  public birthday: Date;
  public salary: number;
  public salaryAsPercentage: number;
  public isPaidUsingPercentageValue: boolean;
  public currency: IdName_Dto;
  public mechanicSpecializationType: IdName_Dto[];
  public departments: Array<Department_Dto>;
  public rules: MechanicRules;
  public createdOn: Date;
  public updatedOn: Date;
  public startDate: Date;
  public history: Array<MechanicHistory_Dto>;
  public userMechanicLink: UserMechanicLink_Dto;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.fullName = `${data.firstName} ${data.lastName}`;
      if (ObjectValidators.isValidObject(data.birthday))
        this.birthday = moment(data.birthday).toDate();
      this.salary = data.salary;
      this.salaryAsPercentage = data.salaryAsPercentage;
      this.isPaidUsingPercentageValue = data.isPaidUsingPercentageValue;
      this.currency = data.currency;
      this.mechanicSpecializationType = data.mechanicSpecializationType;
      this.departments = data.departments ? data.departments.map(r => new Department_Dto(r)) : [];
      this.rules = new MechanicRules(data.rules);
      if (ObjectValidators.isValidObject(data.createdOn))
        this.createdOn = moment.utc(data.createdOn).toDate();
      if (ObjectValidators.isValidObject(data.updatedOn))
        this.updatedOn = moment.utc(data.updatedOn).toDate();
      if (ObjectValidators.isValidObject(data.startDate))
        this.startDate = moment.utc(data.startDate).toDate();
      this.history = data.history ? data.history.map(r => new MechanicHistory_Dto(r)) : [];
      if (isValidObject(data.userMechanicLink)) {
        this.userMechanicLink = new UserMechanicLink_Dto(data.userMechanicLink);
      }
    }
  }
}

export class RequestAccountForMechanic_Dto {
  constructor(public email: string,
              public phoneNumber: string,
              public mechanicId: string) {

  }
}

export class MechanicWithActionsAndCar_Dto {
  public mechanic: Mechanic_Dto;
  public actions: Array<CarAction_Dto>;
  public carEntries: Array<CarService_Dto>;

  constructor(data: any) {
    if (isValidObject(data)) {
      if(isValidObject(data.mechanic)) this.mechanic = new Mechanic_Dto(data.mechanic);
      if(isValidArrayAndHasElements(data.actions)) this.actions = data.actions.map(r=> new CarAction_Dto(r));
      else this.actions = [];
      if(isValidArrayAndHasElements(data.carEntries)) this.carEntries = data.carEntries.map(r=> new CarService_Dto(r));
      else this.carEntries = [];
    }
  }
}
