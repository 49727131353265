import { Injectable } from '@angular/core';
import * as store from '../../../app-store/index';
import { Store, select } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { Do_ResetStateForSection, ResetSection } from 'src/app/core/app-store/events/base.events';
import { BaseComponentState } from 'src/app/core/app-store/utils/base-component.state';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { StaticData } from 'src/app/core/app-dto/static-data.dto';

@Injectable()
export class RequestSubscriptionSandbox extends BaseSandbox {
    public requestSubscription$ = this.appState$.pipe(select(store.getRequestSubscription));
    public staticData$ = this.appState$.pipe(select(store.getStaticData));

    constructor(
        protected appState$: Store<store.State>
    ) {
        super(appState$);
    }

    clearSandboxState() {
    }

    initialize(authMessage: (message: BaseComponentState) => {}, staticData: (data: StaticData) => {}) {
        this.clearSandboxState();
        this.appState$.dispatch(new Do_ResetStateForSection(ResetSection.RequestSubscription));

        this.subscriptions.push(this.requestSubscription$.subscribe((state: BaseComponentState) => {
            if (ObjectValidators.isValidObject(state)) {
                authMessage(state);
            }
        }));

        this.subscriptions.push(this.staticData$.subscribe((data: StaticData) => {
            if (ObjectValidators.isValidObject(data)) {
                staticData(data);
            }
        }));
    }
}
