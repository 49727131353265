import {BaseActionProxy} from '../../../core/shared/base/base.action-proxy';
import {Observable} from 'rxjs';
import {ReceiptDataTableParameters} from '../../../core/shared/datatable/datatable.helpers';
import {IWebComponent} from '../../../core/shared/base/base.component';
import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {GetOrganizationReceiptsPagedResultEvent} from '../../../core/app-store/events/receipt.events';

export class GetOrganizationReceiptsActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent, appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }

    execute(dataTablesParameters: ReceiptDataTableParameters, withReset: boolean): any {
        if (this.canExecute()) {
            this.appState.dispatch(new GetOrganizationReceiptsPagedResultEvent(dataTablesParameters, withReset));
        }
    }
}
