import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiErrorDto } from '../utils/api-error.dto';
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ActiveDataTableParameters, PagedResult } from '../../shared/datatable/datatable.helpers';
import { OrganizationClient_Dto } from '../../app-dto/organization-client.dto';
import { ErrorHelpers } from '../../shared/error.helpers';
import {CompanyInfo_Dto} from '../../app-dto/company-info.dto';
import {Organization} from "../../app-dto/organization.dto";

class OrganizationClientApiRoutes {
    public static route_for_GET_ORGANIZATION_CLIENTS_PAGINATED() {
        return '/api/organizationClient/all';
    }
    public static route_for_GET_ORGANIZATION_CLIENT(organizationClientId: string) {
        return `/api/organizationClient/${organizationClientId}`;
    }
    public static route_for_REGISTER_ORGANIZATION_CLIENT() {
        return '/api/organizationClient/';
    }
    public static route_for_EDIT_ORGANIZATION_CLIENT() {
        return '/api/organizationClient/';
    }
    public static route_for_DELETE_ORGANIZATION_CLIENT(organizationClientId: string) {
        return `/api/organizationClient/${organizationClientId}`;
    }
    public static route_for_SEARCH_ORGANIZATION_CLIENT() {
        return `/api/organizationClient/search`;
    }
    public static route_for_GET_ORGANIZATION_CLIENT_CREDIT(clientId: string) {
        return `/api/organizationClient/${clientId}/credit`;
    }

    public static route_for_GET_ORGANIZATION_INFO_FROM_CUI_TERMENE(cui: string) {
        return `/api/organization/info2?cui=${cui}`;
    }

    public static route_for_GET_ORGANIZATION_INFO_FROM_CUI_ANAF(cui: string) {
        return `/api/organization/fiscalintegration/lookup-organization-info?cui=${cui}`;
    }
}

@Injectable()
export class OrganizationClientApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_registerOrganizationClient(organizationClientData: OrganizationClient_Dto): Observable<OrganizationClient_Dto> {
        const url = this.configService.getAPIBaseUrl() + OrganizationClientApiRoutes.route_for_REGISTER_ORGANIZATION_CLIENT();

        return this.http.post(url, organizationClientData)
            .map((res: any) => {
                return new OrganizationClient_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_editOrganizationClient(organizationClientData: OrganizationClient_Dto): Observable<OrganizationClient_Dto> {
        const url = this.configService.getAPIBaseUrl() + OrganizationClientApiRoutes.route_for_EDIT_ORGANIZATION_CLIENT();

        return this.http.put(url, organizationClientData)
            .map((res: any) => {
                return new OrganizationClient_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getFilteredOrganizationClientsPaginated(data: ActiveDataTableParameters): Observable<PagedResult<OrganizationClient_Dto>> {
        const url = this.configService.getAPIBaseUrl() + OrganizationClientApiRoutes.route_for_GET_ORGANIZATION_CLIENTS_PAGINATED() + '?' + data.toUrlParameters();

        return this.http.get(url)
            .map((res: PagedResult<OrganizationClient_Dto>) => {
                return new PagedResult<OrganizationClient_Dto>(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getOrganizationClient(organizationClientId: string): Observable<OrganizationClient_Dto> {
        const url = this.configService.getAPIBaseUrl() + OrganizationClientApiRoutes.route_for_GET_ORGANIZATION_CLIENT(organizationClientId);

        return this.http.get(url)
            .map((res: any) => {
                return new OrganizationClient_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deleteOrganizationClient(organizationClientId: string): Observable<OrganizationClient_Dto> {
        const url = this.configService.getAPIBaseUrl() + OrganizationClientApiRoutes.route_for_DELETE_ORGANIZATION_CLIENT(organizationClientId);

        return this.http.delete(url)
            .map((res: any) => {
                return new OrganizationClient_Dto(null);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_searchOrganizationClients(search: string): Observable<Array<OrganizationClient_Dto>> {
        const url = this.configService.getAPIBaseUrl() + OrganizationClientApiRoutes.route_for_SEARCH_ORGANIZATION_CLIENT() + '?search=' + search;

        return this.http.get(url)
            .map((res: any) => {
                return res.map(r => new OrganizationClient_Dto(r));
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getOrganizationClientActualCredit(clientId: string): Observable<number> {
        const url = this.configService.getAPIBaseUrl() + OrganizationClientApiRoutes.route_for_GET_ORGANIZATION_CLIENT_CREDIT(clientId);

        return this.http.get(url)
            .map((res: number) => {
                return res;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getOrganizationClientInfoFromCuiTermene(cui: string): Observable<CompanyInfo_Dto> {
        const url = this.configService.getAPIBaseUrl()
            + OrganizationClientApiRoutes.route_for_GET_ORGANIZATION_INFO_FROM_CUI_TERMENE(cui);

        return this.http.get(url)
            .map((res: any) => {
                //@todo: Madalin: server answers with a different DTO; merge both into a single one.
                return res;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getOrganizationClientInfoFromCuiAnaf(cui: string): Observable<Organization> {
        const url = this.configService.getAPIBaseUrl()
            + OrganizationClientApiRoutes.route_for_GET_ORGANIZATION_INFO_FROM_CUI_ANAF(cui);

        return this.http.get(url)
            .map((res: Organization) => {
                // @todo: Madalin: server answers with a different DTO; merge both into a single one.
                return res;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

}
