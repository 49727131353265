import { Observable } from "rxjs";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Injectable } from "@angular/core";

import "rxjs-compat/add/operator/switchMap";
import { map, switchMap } from "rxjs/operators";
import { DoUploadFilesEvent, UploadInputFinishedEvent } from "../../app-store/events/upload.events";
import { Do_GenericSuccessEvent, SeamlessEvent } from "../../app-store/events/base.events";
import { FileUploadTarget } from '../../app-dto/core.dto';
import { ProfileImageChanged } from '../../app-store/events/profile.events';
import { OrganizationImageChanged, OrganizationCuiChanged } from '../../app-store/events/subscription.events';
import { DocumentUploadedEvent } from '../../app-store/events/documents-events';

@Injectable()
export class UploadUIEventProcessor {

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>) {
    }


    @Effect()
    uploadFiles$
        :
        Observable<any> = this.actions$
            .pipe(
                ofType(DoUploadFilesEvent.identifier, SeamlessEvent.identifier + DoUploadFilesEvent.identifier),
                map((action: DoUploadFilesEvent) => action),
                switchMap((action: DoUploadFilesEvent) => {
                    return new Observable((observer) => {
                        var res = action.files;
                        observer.next(new Do_GenericSuccessEvent<any>(res, action));
                        observer.complete();
                    });
                }
                )
            );

    @Effect()
    uploadInputFinishedEvent$
        :
        Observable<any> = this.actions$
            .pipe(
                ofType(UploadInputFinishedEvent.identifier, SeamlessEvent.identifier + UploadInputFinishedEvent.identifier),
                map((action: UploadInputFinishedEvent) => action),
                switchMap((action: UploadInputFinishedEvent) => {
                    return new Observable((observer) => {
                        var res = action.fileUploaded;
                        switch (res.type) {
                            case FileUploadTarget.UserProfile: {
                                observer.next(new ProfileImageChanged(res));
                                break;
                            }
                            case FileUploadTarget.OrganizationProfile: {
                                observer.next(new OrganizationImageChanged(res));
                                break;
                            }
                            case FileUploadTarget.OrganizationCUI: {
                                observer.next(new OrganizationCuiChanged(res));
                                break;
                            }
                            default: {
                                observer.next(new DocumentUploadedEvent(res));
                                break;
                            }
                        }
                        observer.complete();
                    });
                }
                )
            );
}
