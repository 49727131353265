import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {BaseFormModelComponent} from "../../../../core/shared/base/base.component";
import {OrganizationClient_Dto} from "../../../../core/app-dto/organization-client.dto";
import {OrganizationClientSearchComponent} from "../../../../organization-client/components/organization-client-search/organization-client-search.component";
import {Store} from "@ngrx/store";
import * as store from "../../../../core/app-store";
import {ResetSection} from "../../../../core/app-store/events/base.events";
import {isValidObject} from "../../../../core/shared/helpers/common.helpers";
import {Address} from "../../../../core/app-dto/core.dto";
import {AddOfferClientDi} from "./add-offer-client.di";
import {AddOfferClientSandbox} from "./add-offer-client.sandbox";
import {SyncCarForClientActionProxy} from "./sync-car-for-client.action-proxy";

@Component({
    selector: 'app-add-offer-client',
    templateUrl: './add-offer-client.component.html',
    styleUrls: ['./add-offer-client.component.scss']
})
export class AddOfferClientComponent extends BaseFormModelComponent<OrganizationClient_Dto> implements OnInit, OnDestroy {
    public getCarForClientAP: SyncCarForClientActionProxy;
    public clientSearch: string = '';
    public di: AddOfferClientDi;

    @Output() modelSaved = new EventEmitter<OrganizationClient_Dto>();

    @Output() eventSelectedOutput = new EventEmitter<string>();

    @ViewChild(OrganizationClientSearchComponent, { static: false }) organizationClientSearchComponent: OrganizationClientSearchComponent;

    constructor(public appState: Store<store.State>,
                public sandbox: AddOfferClientSandbox) {
        super(sandbox, ResetSection.CarForClient);
        this.getCarForClientAP = new SyncCarForClientActionProxy(this, sandbox.appState);
    }

    public diInitialize(data: OrganizationClient_Dto) {
        let previousId: string = null;
        if (this.di != null && this.di.model.id != null) {
            previousId = this.di.cnpCui;
        }
        this.di = new AddOfferClientDi(data);
        if (previousId != null) {
            this.di.cnpCui = previousId;
            this.di.setCuiCnp(previousId, data.isUniqueId);
        }
        if(isValidObject(data)) this.clientSearch = data.name;
        this.dataLoaded = true;
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnDestroy() {
        this.destroy();
    }

    public accept() {
        this.formSubmitted = true;
        this.markFormAsDirtyAndTouched();
        if (this.di.isValid()) {
            if (this.modelSaved != null) {
                this.modelSaved.emit(this.di.getModel());
            }
        }
    }

    isValid() {
        return this.di.isValid();
    }

    organizationClientSelected(value: any) {
        const client = new OrganizationClient_Dto(value);

        this.di = new AddOfferClientDi(client);
        setTimeout(() => {
            this.di.form.controls['isLegalEntity'].setValue(client.isLegalEntity + '');
            this.di.changeLegalForm({target:{value:client.isLegalEntity + ''}});
            if(this.di.isLegalEntity){
                if (!client.id) {
                    client.address = new Address(null);
                    client.address.country = this.sandbox.organization.address.country;
                    client.address.countryId = this.sandbox.organization.address.countryId;
                    client.address.city = this.sandbox.organization.address.city;
                    client.address.region = this.sandbox.organization.address.region;
                    client.address.regionId = this.sandbox.organization.address.regionId;
                }
            }
            this.di.form.controls['phoneNumber'].setValue(client.phoneNumber);
            this.di.form.controls['cuiCnp'].setValue(client.cuiCnp);
            this.di.form.controls['nrRegCi'].setValue(client.nrRegCi);
            this.di.form.controls['email'].setValue(client.email);
            if (this.di.isValid() && this.modelSaved != null) {
                this.modelSaved.emit(this.di.getModel());
            }
            if (client.id) {
                this.getCarForClientAP.execute(client.id);
            } else {
                this.sandbox.resetCarForClient();
            }
        }, 0);
    }

    clearSearch() {
        this.organizationClientSearchComponent.clearSelect();
    }
}
