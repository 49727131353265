import {Injectable} from "@angular/core";
import {Actions, Effect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import * as store from "../../core/app-store";
import {Observable} from "rxjs";
import {
    Do_GenericErrorEvent,
    Do_GenericSuccessEvent,
    EventWithScreenLoading, SeamlessEvent
} from "../../core/app-store/events/base.events";
import {map, switchMap} from "rxjs/operators";
import { PagedResult } from "../../core/shared/datatable/datatable.helpers";
import {ObjectValidators} from "../../core/shared/object.validators";
import {isValidObject} from "../../core/shared/helpers/common.helpers";
import {CashRegisterApiService} from "../../core/api-integration/services/cash-register-api.service";
import {InvoicePrintApiService} from "../../core/api-integration/services/invoice-print-api.service";
import {CashRegister_Dto} from "../../core/app-dto/cash-register.dto";
import {
    Do_Deactivate_CashRegister_Event, Do_Delete_All_PrintJob_Event,
    Do_Delete_CashRegister_Event, Do_Delete_PrintJob_Event,
    Do_Edit_CashRegister_Event,
    Do_GetActive_CashRegisters_Event,
    Do_GetAll_CashRegisters_AsPaginated_Event, Do_GetAll_PrintJobs_AsPaginated_Event,
    Do_GetOne_CashRegister_Event,
    Do_ResetActivation_For_CashRegister_Event,
    Do_Register_CashRegister_Event, Do_Register_PrintJob_Event
} from "../../core/app-store/events/cash-register.events";
import {InvoicePrintJob_Dto} from "../../core/app-dto/invoice-print-job.dto";

@Injectable()
export class CashRegisterUIEventProcessor {

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private cashRegisterApiService: CashRegisterApiService,
        private printJobApiService: InvoicePrintApiService
    ) {
    }

    @Effect()
    GetAllCashRegistersEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(Do_GetAll_CashRegisters_AsPaginated_Event.identifier, EventWithScreenLoading.identifier + Do_GetAll_CashRegisters_AsPaginated_Event.identifier),
            map((action: Do_GetAll_CashRegisters_AsPaginated_Event) => action),
            switchMap((action: Do_GetAll_CashRegisters_AsPaginated_Event) => {
                return new Observable((observer) => {
                    this.cashRegisterApiService.cr_getFilteredCashRegisters(action.parameters)
                        .subscribe((res: PagedResult<CashRegister_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<CashRegister_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    GetActiveCashRegistersEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(Do_GetActive_CashRegisters_Event.identifier, SeamlessEvent.identifier + Do_GetActive_CashRegisters_Event.identifier),
            map((action: Do_GetActive_CashRegisters_Event) => action),
            switchMap((action: Do_GetActive_CashRegisters_Event) => {
                return new Observable((observer) => {
                    this.cashRegisterApiService.cr_getAllActiveCashRegisters()
                        .subscribe((res: CashRegister_Dto[]) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<CashRegister_Dto[]>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    GetCashRegister$: Observable<any> = this.actions$
        .pipe(
            ofType(Do_GetOne_CashRegister_Event.identifier, EventWithScreenLoading.identifier + Do_GetOne_CashRegister_Event.identifier),
            map((action: Do_GetOne_CashRegister_Event) => action),
            switchMap((action: Do_GetOne_CashRegister_Event) => {
                return new Observable((observer) => {
                    this.cashRegisterApiService.cr_getCashRegister(action.id)
                        .subscribe((res: CashRegister_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<CashRegister_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    RegisterCashRegister$: Observable<any> = this.actions$
        .pipe(
            ofType(Do_Register_CashRegister_Event.identifier, EventWithScreenLoading.identifier + Do_Register_CashRegister_Event.identifier),
            map((action: Do_Register_CashRegister_Event) => action),
            switchMap((action: Do_Register_CashRegister_Event) => {
                    return new Observable((observer) => {
                        this.cashRegisterApiService.cr_registerCashRegister(action.model)
                            .subscribe((res: CashRegister_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<CashRegister_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    EditCashRegister$: Observable<any> = this.actions$
        .pipe(
            ofType(Do_Edit_CashRegister_Event.identifier, EventWithScreenLoading.identifier + Do_Edit_CashRegister_Event.identifier),
            map((action: Do_Edit_CashRegister_Event) => action),
            switchMap((action: Do_Edit_CashRegister_Event) => {
                    return new Observable((observer) => {
                        this.cashRegisterApiService.cr_editCashRegister(action.model)
                            .subscribe((res: CashRegister_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<CashRegister_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    DeleteCashRegister$: Observable<any> = this.actions$
        .pipe(
            ofType(Do_Delete_CashRegister_Event.identifier, EventWithScreenLoading.identifier + Do_Delete_CashRegister_Event.identifier),
            map((action: Do_Delete_CashRegister_Event) => action),
            switchMap((action: Do_Delete_CashRegister_Event) => {
                    return new Observable((observer) => {
                        this.cashRegisterApiService.cr_deleteCashRegister(action.id)
                            .subscribe((res: CashRegister_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<CashRegister_Dto>(res, action));
                                if (isValidObject(action.callback))
                                    action.callback();
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    ResetActivationForCashRegister$: Observable<any> = this.actions$
        .pipe(
            ofType(Do_ResetActivation_For_CashRegister_Event.identifier, EventWithScreenLoading.identifier + Do_ResetActivation_For_CashRegister_Event.identifier),
            map((action: Do_ResetActivation_For_CashRegister_Event) => action),
            switchMap((action: Do_ResetActivation_For_CashRegister_Event) => {
                    return new Observable((observer) => {
                        this.cashRegisterApiService.cr_resetCashRegister(action.id)
                            .subscribe((res: CashRegister_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<CashRegister_Dto>(res, action));
                                if (isValidObject(action.callback))
                                    action.callback();
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    DeactivateCashRegister$: Observable<any> = this.actions$
        .pipe(
            ofType(Do_Deactivate_CashRegister_Event.identifier, EventWithScreenLoading.identifier + Do_Deactivate_CashRegister_Event.identifier),
            map((action: Do_Deactivate_CashRegister_Event) => action),
            switchMap((action: Do_Deactivate_CashRegister_Event) => {
                    return new Observable((observer) => {
                        this.cashRegisterApiService.cr_deactivateCashRegister(action.id)
                            .subscribe((res: CashRegister_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<CashRegister_Dto>(res, action));
                                if (isValidObject(action.callback))
                                    action.callback();
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    GetFilteredInvoicePrintJobsPagedResultEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(Do_GetAll_PrintJobs_AsPaginated_Event.identifier, EventWithScreenLoading.identifier + Do_GetAll_PrintJobs_AsPaginated_Event.identifier),
            map((action: Do_GetAll_PrintJobs_AsPaginated_Event) => action),
            switchMap((action: Do_GetAll_PrintJobs_AsPaginated_Event) => {
                return new Observable((observer) => {
                    this.printJobApiService.pj_getFilteredInvoicePrintJobsAsPaginated(action.parameters)
                        .subscribe((res: PagedResult<InvoicePrintJob_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<InvoicePrintJob_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    DeleteOneInvoicePrintJob$: Observable<any> = this.actions$
        .pipe(
            ofType(Do_Delete_PrintJob_Event.identifier, EventWithScreenLoading.identifier + Do_Delete_PrintJob_Event.identifier),
            map((action: Do_Delete_PrintJob_Event) => action),
            switchMap((action: Do_Delete_PrintJob_Event) => {
                    return new Observable((observer) => {
                        this.printJobApiService.pj_deletePrintJob(action.id)
                            .subscribe((res: boolean) => {
                                observer.next(new Do_GenericSuccessEvent<boolean>(res, action));
                                if (isValidObject(action.callback))
                                    action.callback();
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    DeleteAllInvoicePrintJobs$: Observable<any> = this.actions$
        .pipe(
            ofType(Do_Delete_All_PrintJob_Event.identifier, EventWithScreenLoading.identifier + Do_Delete_All_PrintJob_Event.identifier),
            map((action: Do_Delete_All_PrintJob_Event) => action),
            switchMap((action: Do_Delete_All_PrintJob_Event) => {
                    return new Observable((observer) => {
                        this.printJobApiService.pj_deleteAllPrintJobs(action.cashRegisterId)
                            .subscribe((res: boolean) => {
                                observer.next(new Do_GenericSuccessEvent<boolean>(res, action));
                                if (isValidObject(action.callback))
                                    action.callback();
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    RegisterInvoicePrintJob$: Observable<any> = this.actions$
        .pipe(
            ofType(Do_Register_PrintJob_Event.identifier, EventWithScreenLoading.identifier + Do_Register_PrintJob_Event.identifier),
            map((action: Do_Register_PrintJob_Event) => action),
            switchMap((action: Do_Register_PrintJob_Event) => {
                    return new Observable((observer) => {
                        this.printJobApiService.pj_registerPrintJob(action.model)
                            .subscribe((res: InvoicePrintJob_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<InvoicePrintJob_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );
}
