import {BaseDI} from 'src/app/core/shared/base/base.di';
import {Offer_Dto} from '../../../core/app-dto/offer.dto';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {DateHelpers} from '../../../core/shared/date.helpers';

export class ListOfferEntryItemDi extends BaseDI<Offer_Dto> {
    public registeredOn:string = '';
    constructor(data: Offer_Dto) {
        super(new Offer_Dto(data));
        if(ObjectValidators.isValidObject(this.model.createdOn)) {
            this.registeredOn = DateHelpers.dateToString(this.model.createdOn);
        }
    }

    getModel(): Offer_Dto {
        return this.model;
    }
}
