import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CreateAnonymousCardSetupSession} from "../../../stripe-integration/dto/stripe.dto";
import {BaseFormDI} from "../../../shared/base/base.di";
import {ObjectValidators} from "../../../shared/object.validators";

export class AddPaymentMethodDi extends BaseFormDI<CreateAnonymousCardSetupSession> {
    constructor(data: CreateAnonymousCardSetupSession) {
        if (data != null) {
            super(data);
        }
        else {
            super(new CreateAnonymousCardSetupSession(null));
        }
        this.initForm();
    }

    private setFormControlValue(key:string, value: any){
        (<FormControl>this.form.controls[key])
            .setValue(value, { onlySelf: true });
    }

    initForm() {
        this.form = new FormGroup({
            name: new FormControl('', Validators.compose([Validators.required])),
            email: new FormControl('', Validators.compose([Validators.required])),
            organizationName: new FormControl('', Validators.compose([Validators.required]))
        });

        if (ObjectValidators.isValidString(this.model.name))
            this.setFormControlValue('name', this.model.name);

        if (ObjectValidators.isValidString(this.model.email))
            this.setFormControlValue('email', this.model.email);

        if (ObjectValidators.isValidString(this.model.organizationName))
            this.setFormControlValue('organizationName', this.model.organizationName);
    }

    getModel(): CreateAnonymousCardSetupSession {
        const formValue: CreateAnonymousCardSetupSession = this.form.value as CreateAnonymousCardSetupSession;
        this.model.name = formValue.name;
        this.model.email = formValue.email;
        this.model.organizationName = formValue.organizationName;
        return this.model;
    }
}
