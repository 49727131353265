import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { CarService_Dto, CarAction_Dto } from '../../../core/app-dto/car-service.dto';

@Injectable()
export class CarServiceOverviewUnauthorizedSandbox extends BaseSandbox {
    public getCarService$ = this.appState$.pipe(select(store.getCarService));
    public carServiceActions$ = this.appState$.pipe(select(store.getCarServiceActionListing));

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    clearSandboxState() {
    }

    initialize(carService: (data: CarService_Dto) => {}, carServiceActions: (data: Array<CarAction_Dto>) => {}) {
        this.clearSandboxState();

        this.subscriptions.push(this.getCarService$.subscribe((data: CarService_Dto) => {
            if (ObjectValidators.isValidObject(data)) {
                carService(data);
            }
        }));

        this.subscriptions.push(this.carServiceActions$.subscribe((data: Array<CarAction_Dto>) => {
            if (data != null) {
                carServiceActions(data);
            }
        }));
    }
}
