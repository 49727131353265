import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Mechanic_Dto } from 'src/app/core/app-dto/mechanic.dto';
import { validEndDate } from 'src/app/core/shared/form/custom-form.validator';

export class DeactivateMechanicDi extends BaseFormDI<Mechanic_Dto> {

    public endDate: Date;
    public startDate: Date;

    constructor(data: Mechanic_Dto = new Mechanic_Dto(null)) {
        super(new Mechanic_Dto(data));
        this.endDate = new Date();
        if(this.model.history.length > 0) {
            this.startDate = this.model.history[this.model.history.length - 1].startDate;
        }
        
        this.initForm();
    }

    getModel(): Mechanic_Dto {
        return this.model;
    }

    getDate(): Date {
        this.endDate = this.form.value.endDate;
        return this.endDate;
    }

    initForm() {
        this.form = new FormGroup({
            endDate: new FormControl(this.endDate, Validators.compose([Validators.required, validEndDate(this.startDate)])),
        });
    }
}
