import {Injectable} from '@angular/core';
import * as store from '../../../app-store/index';
import {select, Store} from '@ngrx/store';
import {BaseSandboxWithCurrentUserLoaded} from 'src/app/core/shared/base/base.sandbox';
import {StickyHeaderNotification} from "../../../app-dto/core.dto";
import * as coreStore from "../../../app-store/state-selectors/core.selectors";
import {isValidArrayAndHasElements, isValidObject} from "../../../shared/helpers/common.helpers";

@Injectable()
export class StickyHeaderNotificationSandbox extends BaseSandboxWithCurrentUserLoaded {
  public stickyHeaders$ = this.appState$.pipe(select(coreStore.getStickyHeaderNotifications));

  constructor(protected appState$: Store<store.State>) {
    super(appState$);
  }

  public clearSandboxState() {
  }

  public initialize(dataLoaded: (data: StickyHeaderNotification[]) => void): any {
    this.clearSandboxState();
    super.loadUser(() => {
      if (isValidObject(this.currentUser) && isValidObject(this.currentUser.organization))
        this.subscriptions.push(this.stickyHeaders$.subscribe((value: StickyHeaderNotification[]) => {
            if (dataLoaded) dataLoaded(value);
        }));
    });


  }
}
