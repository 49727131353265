import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';
import { min } from '../../../core/shared/form/custom-form.validator';

export class CarServiceCarDi extends BaseFormDI<CarService_Dto> {

    constructor(data: CarService_Dto = new CarService_Dto(null)) {
        super(new CarService_Dto(data));
        this.initForm();
    }

    getModel(): CarService_Dto {
        this.model.car.make = this.form.value.make;
        this.model.car.model = this.form.value.model;
        this.model.car.bodySeries = this.form.value.bodySeries;
        this.model.car.year = this.form.value.year;
        this.model.car.power = this.form.value.power;
        this.model.car.fuel = this.form.value.fuel;
        this.model.car.cilindricalCapacity = this.form.value.cilindricalCapacity;
        this.model.car.details = this.form.value.details;
        this.model.car.itpExpirationDate = this.form.value.itpExpirationDate;
        this.model.car.insuranceExpirationDate = this.form.value.insuranceExpirationDate;
        this.model.carServiceEntryData.actualNoOfKm = this.form.value.actualNoOfKm;
        this.model.carServiceEntryData.fuel = this.form.value.actualFuel;

        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
            make: new FormControl(this.model.car.make, Validators.compose([Validators.required])),
            model: new FormControl(this.model.car.model, Validators.compose([Validators.required])),
            bodySeries: new FormControl(this.model.car.bodySeries, Validators.compose([Validators.required])),
            year: new FormControl(this.model.car.year, Validators.compose([Validators.required])),
            power: new FormControl(this.model.car.power, Validators.compose([Validators.required])),
            cilindricalCapacity: new FormControl(this.model.car.cilindricalCapacity, Validators.compose([Validators.required, min(0)])),
            details: new FormControl(this.model.car.details, Validators.compose([])),
            itpExpirationDate: new FormControl(this.model.car.itpExpirationDate, Validators.compose([])),
            insuranceExpirationDate: new FormControl(this.model.car.insuranceExpirationDate, Validators.compose([])),
            actualNoOfKm: new FormControl(this.model.carServiceEntryData.actualNoOfKm, Validators.compose([Validators.required])),
            actualFuel: new FormControl(this.model.carServiceEntryData.fuel, Validators.compose([Validators.required])),
        });
    }
}
