import { BaseDI } from 'src/app/core/shared/base/base.di';
import {CarServiceEntryStatistics} from "../../../core/app-dto/car-service.dto";

export class CarServiceStatisticsDI extends BaseDI<CarServiceEntryStatistics> {

  public sevenDaysLabel :string = "comenzi";
  public monthLabel :string = "comenzi";

  constructor(data: CarServiceEntryStatistics = new CarServiceEntryStatistics(null)) {
    super(data);
    if(data.weeklyCount==1) this.sevenDaysLabel= "comanda";
    if(data.monthlyCount==1) this.monthLabel = "comanda";
  }

  getModel(): CarServiceEntryStatistics {
    return this.model;
  }
}
