import { Injectable } from "@angular/core";
import { BaseApiService } from "./base-api.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiErrorDto } from "../utils/api-error.dto";
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { InvoiceDataTableParameters, PagedResult } from '../../shared/datatable/datatable.helpers';
import {Invoice_Dto, InvoiceDetails_Dto, InvoiceMaxPermittedAmount_Dto, InvoiceType, SagaExport_Dto} from '../../app-dto/invoice.dto';
import { ErrorHelpers } from '../../shared/error.helpers';
import { isValidObject } from "../../shared/helpers/common.helpers";

class InvoiceApiRoutes {

  public static route_for_GET_INVOICES_PAGINATED() {
    return '/api/invoice/subscription/all/paginated';
  }

  public static route_for_GET_ORGANIZATION_INVOICES_PAGINATED(id: string) {
    return '/api/invoice/' + id + '/all/paginated';
  }

  public static route_for_GET_SUBSCRIPTION_INVOICES_FOR_ORGANIZATION_PAGINATED(id: string) {
    return '/api/invoice/' + id + '/subscription/all/paginated';
  }

  public static route_for_GET_SUBSCRIPTION_INVOICE(id: string) {
    return '/api/invoice/subscription/' + id;
  }

  public static route_for_GET_MAX_PERMITTED_AMOUNT_FOR_INVOICE(id: string) {
    return '/api/invoice/' + id + '/maxPermittedAmount';
  }

  public static route_for_GET_SUBSCRIPTION_INVOICE_FOR_ORGANIZATION(id: string, organizationId: string) {
    return '/api/invoice/' + organizationId + '/subscription/' + id;
  }

  public static route_for_GET_ORGANIZATION_INVOICE(id: string, organizationId: string) {
    return '/api/invoice/' + organizationId + '/' + id;
  }

  public static route_for_GET_UNAUTHORIZED_INVOICE(id: string, code: string) {
    return '/api/invoice/annonymus/' + id + '/' + code;
  }

  public static route_for_GET_UNAUTHORIZED_INVOICE_PAY_BUTTON(id: string, code: string) {
    return '/api/invoice/payButton/' + id + '/' + code;
  }

  public static route_for_GET_AUTHORIZED_INVOICE_PAY_BUTTON(id: string) {
    return '/api/invoice/payButton/' + id;
  }

  public static route_for_MARK_SUBSCRIPTION_INVOICE_AS_PAID(id: string) {
    return '/api/invoice/subscription/' + id + '/paid';
  }
  public static route_for_MARK_ORGANIZATION_INVOICE_AS_PAID(id: string, organizationId: string) {
    return '/api/invoice/' + organizationId + '/' + id + '/paid';
  }

  public static route_for_MARK_SUBSCRIPTION_INVOICE_AS_NOT_PAID(id: string) {
    return '/api/invoice/subscription/' + id + '/not-paid';
  }
  public static route_for_MARK_ORGANIZATION_INVOICE_AS_NOT_PAID(id: string, organizationId: string) {
    return '/api/invoice/' + organizationId + '/' + id + '/not-paid';
  }

  public static route_for_RETRIEVE_DOWNLOAD_LINK_FOR_SUBSCRIPTION_INVOICE(id: string) {
    return '/api/invoice/subscription/' + id + '/download';
  }
  public static route_for_RETRIEVE_DOWNLOAD_LINK_FOR_ORGANIZATION_INVOICE(id: string, organizationId: string) {
    return '/api/invoice/' + organizationId + '/' + id + '/download';
  }

  public static route_for_REGENERATE_ORGANIZATION_INVOICE(id: string, organizationId: string) {
    return '/api/invoice/' + organizationId + '/' + id + '/regenerate';
  }
  public static route_for_GET_INVOICE_FOR_CAR_SERVICE_ENTRY(id: string, organizationId: string, type: InvoiceType) {
    return '/api/invoice/' + organizationId + '/carentry/' + id + "?type=" + type;
  }
  public static route_for_UPDATE_INVOICES_DEADLINE() {
    return '/api/invoice/deadline';
  }
  public static route_for_START_SAGA_EXPORT() {
    return '/api/invoice/export/saga';
  }
}

@Injectable()
export class InvoiceApiService extends BaseApiService {

  constructor(private http: HttpClient, configService: ConfigService) {
    super(configService);
  }

  u_getFilteredSubscriptionInvoicesPaginated(data: InvoiceDataTableParameters): Observable<PagedResult<Invoice_Dto>> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_GET_INVOICES_PAGINATED() + "?" + data.toUrlParameters();

    return this.http.get(url)
      .map((res: any) => {
        return new PagedResult<Invoice_Dto>(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getOrganizationFilteredInvoicesPaginated(data: InvoiceDataTableParameters, organizationId: string = null): Observable<PagedResult<Invoice_Dto>> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_GET_ORGANIZATION_INVOICES_PAGINATED(organizationId) + "?" + data.toUrlParameters();

    return this.http.get(url)
      .map((res: any) => {
        return new PagedResult<Invoice_Dto>(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getOrganizationFilteredSubscriptionInvoicesPaginated(data: InvoiceDataTableParameters, organizationId: string = null): Observable<PagedResult<Invoice_Dto>> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_GET_SUBSCRIPTION_INVOICES_FOR_ORGANIZATION_PAGINATED(organizationId) + "?" + data.toUrlParameters();

    return this.http.get(url)
      .map((res: any) => {
        return new PagedResult<Invoice_Dto>(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getInvoice(invoiceId: string): Observable<InvoiceDetails_Dto> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_GET_SUBSCRIPTION_INVOICE(invoiceId);

    return this.http.get(url)
      .map((res: any) => {
        return new InvoiceDetails_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getOrganizationInvoice(invoiceId: string, organizationId: string): Observable<InvoiceDetails_Dto> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_GET_ORGANIZATION_INVOICE(invoiceId, organizationId);

    return this.http.get(url)
      .map((res: any) => {
        return new InvoiceDetails_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }
  u_getSubscriptionInvoiceForOrganization(invoiceId: string, organizationId: string): Observable<InvoiceDetails_Dto> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_GET_SUBSCRIPTION_INVOICE_FOR_ORGANIZATION(invoiceId, organizationId);

    return this.http.get(url)
      .map((res: any) => {
        return new InvoiceDetails_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getUnauthorizedInvoice(invoiceId: string, code: string): Observable<InvoiceDetails_Dto> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_GET_UNAUTHORIZED_INVOICE(invoiceId, code);

    return this.http.get(url)
      .map((res: any) => {
        return new InvoiceDetails_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getUnauthorizedPayButton(invoiceId: string, code: string): Observable<string> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_GET_UNAUTHORIZED_INVOICE_PAY_BUTTON(invoiceId, code);
    return this.http.get(url, { responseType: 'text' })
      .map((res: any) => {
        return res;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getAuthorizedPayButton(invoiceId: string): Observable<string> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_GET_AUTHORIZED_INVOICE_PAY_BUTTON(invoiceId);

    return this.http.get(url, { responseType: 'text' })
      .map((res: any) => {
        return res;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getDownloadLinkForSubscriptionInvoice(invoiceId: string): Observable<string> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_RETRIEVE_DOWNLOAD_LINK_FOR_SUBSCRIPTION_INVOICE(invoiceId);

    return this.http.get(url)
      .map((res: any) => {
        return res.data;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getDownloadLinkForOrganizationInvoice(invoiceId: string, organizationId): Observable<string> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_RETRIEVE_DOWNLOAD_LINK_FOR_ORGANIZATION_INVOICE(invoiceId, organizationId);
    return this.http.get(url)
      .map((res: any) => {
        return res.data;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_markSubscriptionInvoiceAsPaid(invoiceId: string): Observable<Invoice_Dto> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_MARK_SUBSCRIPTION_INVOICE_AS_PAID(invoiceId);

    return this.http.put(url, {})
      .map((res: any) => {
        return new Invoice_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_markSubscriptionInvoiceAsNotPaid(invoiceId: string): Observable<Invoice_Dto> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_MARK_SUBSCRIPTION_INVOICE_AS_NOT_PAID(invoiceId);

    return this.http.put(url, {})
      .map((res: any) => {
        return new Invoice_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_markOrganizationInvoiceAsPaid(invoiceId: string, organizationId: string): Observable<Invoice_Dto> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_MARK_ORGANIZATION_INVOICE_AS_PAID(invoiceId, organizationId);

    return this.http.put(url, {})
      .map((res: any) => {
        return new Invoice_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_markOrganizationInvoiceAsNotPaid(invoiceId: string, organizationId: string): Observable<Invoice_Dto> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_MARK_ORGANIZATION_INVOICE_AS_NOT_PAID(invoiceId, organizationId);

    return this.http.put(url, {})
      .map((res: any) => {
        return new Invoice_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_regenerateOrganizationInvoice(invoiceId: string, organizationId: string): Observable<Invoice_Dto> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_REGENERATE_ORGANIZATION_INVOICE(invoiceId, organizationId);

    return this.http.post(url, {})
      .map((res: any) => {
        return new Invoice_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getInvoiceForCarServiceEntry(carServiceEntryId: string, organizationId: string, invoiceType: InvoiceType): Observable<Invoice_Dto> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_GET_INVOICE_FOR_CAR_SERVICE_ENTRY(carServiceEntryId, organizationId, invoiceType);

    return this.http.get(url)
      .map((res: any) => {
        if (isValidObject(res)) {
          return new Invoice_Dto(res);
        }
        else return null;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_updateInvoiceDeadlineEvent(data: InvoiceDetails_Dto): Observable<InvoiceDetails_Dto> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_UPDATE_INVOICES_DEADLINE();

    return this.http.put(url, data.invoice)
      .map((res: any) => {
        return new InvoiceDetails_Dto(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_startSagaExport(data: SagaExport_Dto): Observable<any> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_START_SAGA_EXPORT();

    return this.http.post(url, data)
      .map((res: any) => {
        return res;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getMaxPermittedAmount(invoiceId: string): Observable<InvoiceMaxPermittedAmount_Dto> {
    const url = this.configService.getAPIBaseUrl() + InvoiceApiRoutes.route_for_GET_MAX_PERMITTED_AMOUNT_FOR_INVOICE(invoiceId);
    return this.http.get(url)
        .map((res: any) => {
          return new InvoiceMaxPermittedAmount_Dto(res);
        })
        .catch<any, any>((err: ApiErrorDto, o) => {
          return ErrorHelpers.throwError(err);
        });
  }
}
