import {Component, Input, OnInit} from '@angular/core';
import {Offer_Dto} from '../../../core/app-dto/offer.dto';
import {getFormattedIdNameList} from '../../../core/shared/helpers/common.helpers';
import {UpdateOfferClientCarPartsComponent} from '../update-offer-client-car-parts/update-offer-client-car-parts.component';
import {BsModalService} from 'ngx-bootstrap';

@Component({
  selector: 'app-offer-details-list-client-car-parts',
  templateUrl: './offer-details-list-client-car-parts.component.html',
  styleUrls: ['./offer-details-list-client-car-parts.component.scss']
})
export class OfferDetailsListClientCarPartsComponent implements OnInit {

  @Input() offer: Offer_Dto;
  @Input() isReadOnlyValue: boolean = false;

  constructor(private modalService:BsModalService) {
  }

  ngOnInit() {
  }

  edit() {
    const modalRef = this.modalService.show(UpdateOfferClientCarPartsComponent, {backdrop: 'static', keyboard: false, class: 'modal-xl'});
    modalRef.content.dataInput = this.offer;
  }

}
