import {Injectable} from "@angular/core";
import {BaseSandbox} from "../../base/base.sandbox";
import {select, Store} from "@ngrx/store";
import * as store from "../../../app-store";
import {isValidArrayAndHasElements} from "../../helpers/common.helpers";
import {CashRegister_Dto} from "../../../app-dto/cash-register.dto";

@Injectable()
export class FiscalPrintInvoiceSandbox extends BaseSandbox {
    public getAllActiveCashRegisters$ = this.appState$.pipe(select(store.getAllActiveCashRegisters));
    public cashRegisters: CashRegister_Dto[];
    public hasCashRegisters: boolean = false;

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
        this.hasCashRegisters = false;
    }

    public initialize(): any {
        this.clearSandboxState();
        this.subscriptions.push(this.getAllActiveCashRegisters$.subscribe((data: CashRegister_Dto[]) => {
            if (data != null) {
                if (isValidArrayAndHasElements(data)) {
                    this.hasCashRegisters = true;
                    this.cashRegisters = data.map(r => {
                        return new CashRegister_Dto(r);
                    });
                } else {
                    this.hasCashRegisters = false;
                }
            } else {
                this.cashRegisters = [];
            }
        }));
    }
}
