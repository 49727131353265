import { State } from '../state';
import * as fromCarPart from '../stores/car-part.store';
import { createSelector } from '@ngrx/store';
import {
    getSupplyItemForCarPartPagedResultCallback,
    getSupplyItemsTimestampCallback,
    getTotalCarPartsValueCallback
} from "../stores/car-part.store";

export const getCarPartStateCallback = (state: State) => state.carPart;

export const getCarPartTimestamp = createSelector(getCarPartStateCallback, fromCarPart.getTimestampCallback);
export const getTotalCarPartsValue = createSelector(getCarPartStateCallback, fromCarPart.getTotalCarPartsValueCallback);
export const getSupplyItemsTimestamp = createSelector(getCarPartStateCallback, fromCarPart.getSupplyItemsTimestampCallback);
export const getCarPart = createSelector(getCarPartStateCallback, fromCarPart.getCarPartCallback);
export const getCarPartAggregate = createSelector(getCarPartStateCallback, fromCarPart.getCarPartAggregateCallback);
export const getCarPartsListing = createSelector(getCarPartStateCallback, fromCarPart.getCarPartsPagedResultCallback);
export const getSupplyItemForCarPartPagedResult = createSelector(getCarPartStateCallback, fromCarPart.getSupplyItemForCarPartPagedResultCallback);
export const getCarPartSearchList = createSelector(getCarPartStateCallback, fromCarPart.getCarPartSearchListCallback);
export const getCarPartStocksListing = createSelector(getCarPartStateCallback, fromCarPart.getCarPartStocksPagedResultCallback);
export const getCarPartsImportTemplateUrl = createSelector(getCarPartStateCallback, fromCarPart.getCarPartsImportTemplateCallback);
