import {
    UIGeneratedEvent,
    EventWithScreenLoading,
    SeamlessEvent,
} from './base.events';
import { CalendarEvent_Dto } from '../../app-dto/calendar-event.dto';
import { CarAppointment_Dto } from '../../app-dto/car-appointment.dto';
import { OrganizationClient_Dto } from '../../app-dto/organization-client.dto';

export class GetCalendarEventsEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier:string = "GetCalendarEventsEvent";
    constructor(public startDate: Date, public endDate: Date, public eventType: string = null, public search: string = null, public callback: Function) {
        super();
        this.type += GetCalendarEventsEvent.identifier;
    }
}

export class RegisterCalendarEventEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier:string = "RegisterCalendarEventEvent";
    constructor(public model: CalendarEvent_Dto, public callback: Function) {
        super();
        this.type += RegisterCalendarEventEvent.identifier;
    }
}

export class EditCalendarEventEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier:string = "EditCalendarEventEvent";
    constructor(public model: CalendarEvent_Dto, public callback: Function) {
        super();
        this.type += EditCalendarEventEvent.identifier;
    }
}

export class DeleteCalendarEventEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier:string = "DeleteCalendarEventEvent";
    constructor(public id: string, public callback: Function) {
        super();
        this.type += DeleteCalendarEventEvent.identifier;
    }
}

export class SearchCalendarEventEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier:string = "SearchCalendarEventEvent";
    constructor(public startDate: Date, public endDate: Date, public search: string, public callBack: Function) {
        super();
        this.type += SearchCalendarEventEvent.identifier;
    }
}

export class GetCalendarEventStatisticsEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier:string = "GetCalendarEventStatisticsEvent";
  constructor(public callback: Function) {
    super();
    this.type += GetCalendarEventStatisticsEvent.identifier;
  }
}

export class ConfirmCalendarEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier:string = "ConfirmCalendarEvent";
    constructor(public id: string, public callback: Function) {
        super();
        this.type += ConfirmCalendarEvent.identifier;
    }
}


// Car Appointments

export class RegisterCarAppointmentEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier:string = "RegisterCarAppointmentEvent";
    constructor(public model: CarAppointment_Dto, public callback: Function) {
        super();
        this.type += RegisterCarAppointmentEvent.identifier;
    }
}

export class GetCarAppointmentEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = "GetCarAppointmentEvent";

    constructor(public id: string, public callback: (data: CarAppointment_Dto) => void) {
        super();
        this.type += GetCarAppointmentEvent.identifier;
    }
}

export class UpdateCarAppointmentEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier:string = "UpdateCarAppointmentEvent";
    constructor(public model: CarAppointment_Dto, public callback: Function) {
        super();
        this.type += UpdateCarAppointmentEvent.identifier;
    }
}

export class DeleteCarAppointmentEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeleteCarAppointmentEvent";

  constructor(public id: string, public callback: () => void) {
    super();
    this.type += DeleteCarAppointmentEvent.identifier;
  }
}
