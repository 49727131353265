import { Action } from '@ngrx/store';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from '../events/base.events';
import { ResetStateActionProxy } from '../../shared/base/base.action-proxy';
import { FileAttachment, FileUploadTarget, User_Dto } from '../../app-dto/core.dto';
import { GetProfileEvent, ProfileImageChanged, EditProfileEvent, DeleteUserImageProfileUrlEvent } from '../events/profile.events';

export interface ProfileState {
  lastRefreshTime: number;
  profile: User_Dto;
}

export const INITIAL_STATE: ProfileState = Object.assign({}, {
  lastRefreshTime: 0,
  profile: null
});


export function reducer(state = INITIAL_STATE, action: Action): ProfileState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {
    // Get Profile

    case Do_ResetStateForSection.identifier + ResetSection.Profile: {
      return Object.assign({}, state, { profile: null });
    }

    case Do_GenericSuccessEvent.identifier + GetProfileEvent.identifier: {
      const userData = (action as Do_GenericSuccessEvent<User_Dto>).data;
      return Object.assign({}, state, { profile: userData });
    }

    case Do_GenericErrorEvent.identifier + GetProfileEvent.identifier: {
      return Object.assign({}, state, { profile: null });
    }

    case ResetStateActionProxy.name + GetProfileEvent.identifier: {
      return Object.assign({}, state, { profile: null });
    }

    case ProfileImageChanged.identifier: {
      const data: FileAttachment = (action as ProfileImageChanged).data;
      
      if (state.profile != null && state.profile.id == data.relatedEntityId && data.type == FileUploadTarget.UserProfile) {
        state.profile.displayImageUrl = data.url;
        state.profile = new User_Dto(state.profile);
        return Object.assign({}, state);
      }
    }

    case Do_GenericSuccessEvent.identifier + EditProfileEvent.identifier: {
      const userData = (action as Do_GenericSuccessEvent<User_Dto>).data;
      return Object.assign({}, state, { profile: userData });
    }

    case Do_GenericSuccessEvent.identifier + DeleteUserImageProfileUrlEvent.identifier: {
      var dataUrl = (<Do_GenericSuccessEvent<string>>action).data;
      state.profile.displayImageUrl = dataUrl;
      state.profile = new User_Dto(state.profile);
      return Object.assign({}, state);
    }

    default: {
      return state;
    }
  }
}

export const getTimestampCallback = (state: ProfileState) => state.lastRefreshTime;
export const getProfileCallback = (state: ProfileState) => state.profile;
