import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseWebComponent} from "../../../core/shared/base/base.component";
import {Store} from "@ngrx/store";
import * as store from "../../../core/app-store";
import {BsModalRef} from "ngx-bootstrap";
import {Invoice_Dto, SagaExport_Dto} from "../../../core/app-dto/invoice.dto";
import {isValidArrayAndHasElements, isValidObject} from "../../../core/shared/helpers/common.helpers";
import {DoSagaExportActionProxy} from "./do-saga-export.action-proxy";
import {NotifierService} from "angular-notifier";
import {DatePickerDI} from "../../../core/shared/daterangepicker/daterangepicker.di";

@Component({
  selector: 'app-saga-export',
  templateUrl: './saga-export.component.html',
  styleUrls: ['./saga-export.component.scss']
})
export class SagaExportComponent extends BaseWebComponent implements OnInit, OnDestroy {

  public hasAlreadyExportedItems: boolean = false;
  public originalItems: Array<Invoice_Dto> = [];
  public alreadyExportedItems: Array<Invoice_Dto> = [];
  public includeAlreadyExportedItems: boolean = false;
  public isInIntervalMode: boolean = false;
  public datePickerDI: DatePickerDI = null;
  public from: Date;
  public to: Date;
  public selectedRangeAsString = null;

  private doExportAP: DoSagaExportActionProxy;

  constructor(public appState: Store<store.State>,
              private notifierService: NotifierService,
              private activeModal: BsModalRef) {
    super(null, null);
    this.doExportAP = new DoSagaExportActionProxy(this, appState);
  }

  @Input("dataInput") set dataInput(data: SagaExportInput) {
    if (isValidObject(data)) {
      if (isValidArrayAndHasElements(data.items)) this.isInIntervalMode = false;
      else this.isInIntervalMode = true;
      this.alreadyExportedItems = data.items.filter(f => f.isExportedForSaga);
      if (isValidArrayAndHasElements(this.alreadyExportedItems)) this.hasAlreadyExportedItems = true;
      this.originalItems = data.items;
      this.dataLoaded = true;
    }
  }


  ngOnInit() {
    this.datePickerDI = new DatePickerDI();
    this.to = this.datePickerDI.getDefaultEndDate();
    this.from = this.datePickerDI.getDefaultStartDate();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.activeModal.hide();
  }

  public accept() {
    const itemsToSend = this.includeAlreadyExportedItems ? this.originalItems.map(r => r.id) : this.originalItems.filter(r => r.isExportedForSaga == false).map(r => r.id);
    if (itemsToSend.length == 0 && this.isInIntervalMode === false) {
      this.notifierService.notify('error', "Nu aveti facturi selectate pentru export.");
      return;
    }
    const data = new SagaExport_Dto();
    data.items = itemsToSend;
    data.isInIntervalMode = this.isInIntervalMode;
    data.from = this.from;
    data.to = this.to;
    this.doExportAP.execute(data, () => {
      this.activeModal.hide();
    });
  }

  public toggleAlreadyExportedItemFlag() {
    this.includeAlreadyExportedItems = !this.includeAlreadyExportedItems;
  }

  cancelDaterangepickerCalled(event: any) {
    this.selectedRangeAsString = null;
    this.to = null;
    this.from = null;
    event.picker.datePicker.setStartDate(new Date());
    event.picker.datePicker.setEndDate(new Date());
  }

  applyDaterangepickerCalled(event: any) {
    this.from = event.picker.startDate.toDate();
    this.to = event.picker.endDate.toDate();
  }
}

export class SagaExportInput {
  constructor(public items: Array<Invoice_Dto>) {
  }
}
