import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {BaseFormModelComponent} from "../../../../core/shared/base/base.component";
import {Offer_Dto} from "../../../../core/app-dto/offer.dto";
import {Store} from "@ngrx/store";
import * as store from "../../../../core/app-store";
import {AddOfferFirstStepDi} from "./add-offer-first-step.di";
import {AddOfferFirstStepSandbox} from "./add-offer-first-step.sandbox";
import {IdName_Dto} from "../../../../core/app-dto/misc.dto";
import {
    makeInputPositiveAndGreaterThenOne,
    makeValidDiscount,
    makeValidDiscountAndGreaterThenOne
} from '../../../../core/shared/helpers/common.helpers';
import {ObjectValidators} from '../../../../core/shared/object.validators';
import {FormControl, Validators} from '@angular/forms';

@Component({
    selector: 'app-offer-first-step',
    templateUrl: './add-offer-first-step.component.html',
    styleUrls: ['./add-offer-first-step.component.scss']
})
export class AddOfferFirstStepComponent extends BaseFormModelComponent<Offer_Dto> implements OnInit, OnDestroy {
    public di: AddOfferFirstStepDi;

    @Input() isEdit: boolean = false;

    @Output()
    modelSaved = new EventEmitter<Offer_Dto>();

    @Output()
    eventSelectedOutput = new EventEmitter<string>();

    constructor(public appState: Store<store.State>,
                public sandbox: AddOfferFirstStepSandbox) {
        super(sandbox);
    }

    public diInitialize(data: Offer_Dto) {
        this.di = new AddOfferFirstStepDi(data);
        this.dataLoaded = true;
        if (ObjectValidators.isValidObject(data.type) && ObjectValidators.isValidString(data.type.name)) {
           this.di.needsDiscount = ObjectValidators.isValidObject(data.type) && data.type.name.toLowerCase().indexOf('discount') > -1;
        }
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnDestroy() {
        this.destroy();
    }

    public accept() {
        this.formSubmitted = true;
        this.markFormAsDirtyAndTouched();
        if (this.di.isValid()) {
            if (this.modelSaved != null) {
                this.modelSaved.emit(this.di.getModel());
            }
        }
    }

    isValid() {
        return this.di.isValid();
    }

    public onTypeChanged(selection: IdName_Dto) {
        const needsDiscount = ObjectValidators.isValidObject(this.di.getModel().type) && this.di.getModel().type.name.toLowerCase().indexOf('discount') > -1;
        if (needsDiscount) {
            this.di.form.removeControl('discount');
            this.di.form.addControl('discount', new FormControl(this.di.model.offerEntryData.discount, Validators.compose([Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')])));
        }
        this.modelSaved.emit(this.di.getModel());
    }

    public onDiscountChanged() {
        this.modelSaved.emit(this.di.getModel());
    }

    makeValuePositive(event: any) {
        makeValidDiscountAndGreaterThenOne(event);
    }
}
