import { Component, Input } from '@angular/core';
import {
  AppUserBusinessRuleProvider
} from '../../core/business-rule-provider/app-user.business-rule.provider';
import * as moment from 'moment';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent {

  @Input() organizationId: string = null;
  public currentTime: string = '';

  constructor(
      public rulesProvider: AppUserBusinessRuleProvider
  ) {
    setInterval(() => {
      this.currentTime = moment().format('HH:mm:ss');
    }, 1000);
  }
}
