import { ObjectValidators } from '../shared/object.validators';
import {Base_Dto, BaseCRUDRules, BusinessRule} from './misc.dto';
import * as moment from 'moment';
import { Address } from './core.dto';

export class SupplierRules extends BaseCRUDRules {

  constructor(data: any) {
    super(data);
  }
}


export class Supplier_Dto extends Base_Dto {
  public name: string;
  public cifCnp: string;
  public regCom: string;
  public code: string;
  public vatPayer: boolean;
  public vatAtPayment: boolean;
  public address: Address;
  public iban: string;
  public bank: string;
  public email: string;
  public contactPerson: string;
  public phone: string;
  public createdOn: Date;
  public updatedOn: Date;
  public rules: SupplierRules;
  public isActive: boolean;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.name = data.name;
      this.cifCnp = data.cifCnp;
      this.regCom = data.regCom;
      this.code = data.code;
      this.isActive = data.isActive;
      this.vatPayer = data.vatPayer;
      this.vatAtPayment = data.vatAtPayment;
      if (ObjectValidators.isValidObject(data.address)) {
        this.address = new Address(data.address);
      } else {
        this.address = new Address();
      }
      this.iban = data.iban;
      this.bank = data.bank;
      this.email = data.email;
      this.contactPerson = data.contactPerson;
      this.phone = data.phone;
      if (ObjectValidators.isValidObject(data.createdOn))
        this.createdOn = moment.utc(data.createdOn).toDate();
      if (ObjectValidators.isValidObject(data.updatedOn))
        this.updatedOn = moment.utc(data.updatedOn).toDate();
      this.rules = new SupplierRules(null);
      this.rules.canView = BusinessRule.getTrueRuleValue();
      this.rules.canUpdate = BusinessRule.getTrueRuleValue();
      this.rules.canDelete = BusinessRule.getTrueRuleValue();
    }
  }
}
