import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {BaseFormModelComponent} from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { ActivatedRoute } from '@angular/router';
import { EditCarServiceClientSandbox } from './edit-car-service-client.sandbox';
import {EditCarServiceClientDi} from './edit-car-service-client.di';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap';
import {ObjectValidators} from '../../../core/shared/object.validators';
import { EditCarServiceClientActionProxy } from './edit-car-service-client.action-proxy';
import { OrganizationClient_Dto } from '../../../core/app-dto/organization-client.dto';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';
import { OrganizationClientFormComponent } from '../../../organization-client/components/organization-client-form/organization-client-form.component';


@Component({
  selector: 'app-edit-car-service-client',
  templateUrl: './edit-car-service-client.component.html',
  styleUrls: ['./edit-car-service-client.component.scss']
})
export class EditCarServiceClientComponent extends BaseFormModelComponent<CarService_Dto> implements OnInit, OnDestroy {
  public editCarServiceClientAP: EditCarServiceClientActionProxy;
  public onClose: Subject<boolean>;

  @ViewChild(OrganizationClientFormComponent, { static: false })
  private clientFormComponent: OrganizationClientFormComponent;

  constructor(public appState: Store<store.State>,
              public sandbox: EditCarServiceClientSandbox,
              private activeModal: BsModalRef,
              public activatedRoute: ActivatedRoute) {
    super(sandbox, ResetSection.CarService);
    this.editCarServiceClientAP = new EditCarServiceClientActionProxy(this, sandbox.appState);
    this.di = new EditCarServiceClientDi();
  }

  public diInitialize(data: CarService_Dto) {
    this.di = new EditCarServiceClientDi(data);
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.formSubmitted = true;
    this.clientFormComponent.accept();
    this.clientFormComponent.markFormAsDirtyAndTouched();
  }

  clientDetailsFormSaved(data: OrganizationClient_Dto) {
    if (ObjectValidators.isValidObject(data)) {
      this.di.model.client = data;
      if (this.editCarServiceClientAP.canExecute()) {
        this.editCarServiceClientAP.execute();
        this.onClose.next(true);
        this.activeModal.hide();
      }
    }
  }
}
