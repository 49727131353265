import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseFormModelComponent} from '../../../core/shared/base/base.component';
import {Offer_Dto} from '../../../core/app-dto/offer.dto';
import {UpdateOfferClientDataActionProxy} from '../../common/ap/update-offer-client-data.action-proxy';
import {Subject} from 'rxjs';
import {OrganizationClientFormComponent} from '../../../organization-client/components/organization-client-form/organization-client-form.component';
import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {BsModalRef} from 'ngx-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {EditCarServiceClientDi} from '../../../car-service/components/edit-car-service-client/edit-car-service-client.di';
import {OrganizationClient_Dto} from '../../../core/app-dto/organization-client.dto';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {UpdateOfferClientCarPartsActionProxy} from '../../common/ap/update-offer-client-car-parts.action-proxy';
import {CarServiceClientPartsComponent} from '../../../car-service/components/car-service-client-parts/car-service-client-parts.component';
import {ClientCarParts_Dto} from '../../../core/app-dto/car-service.dto';

@Component({
    selector: 'app-update-offer-client-car-parts',
    templateUrl: './update-offer-client-car-parts.component.html',
    styleUrls: ['./update-offer-client-car-parts.component.scss']
})
export class UpdateOfferClientCarPartsComponent extends BaseFormModelComponent<Offer_Dto> implements OnInit, OnDestroy {
    public editDataAP: UpdateOfferClientCarPartsActionProxy;
    public onClose: Subject<boolean>;
    public offer: Offer_Dto;

    @ViewChild(CarServiceClientPartsComponent, {static: false})
    private formComponent: CarServiceClientPartsComponent;

    constructor(public appState: Store<store.State>,
                private activeModal: BsModalRef,
                public activatedRoute: ActivatedRoute) {
        super(null, null);
        this.editDataAP = new UpdateOfferClientCarPartsActionProxy(this, appState);
        this.di = new EditCarServiceClientDi();
    }

    public diInitialize(data: Offer_Dto) {
        this.offer = new Offer_Dto(data);
        this.dataLoaded = true;
    }

    ngOnInit() {
        this.onClose = new Subject();
    }

    ngOnDestroy() {
        this.destroy();
    }

    public decline() {
        this.onClose.next(false);
        this.activeModal.hide();
    }

    public accept() {
        this.formSubmitted = true;
        this.formComponent.emitValues();
    }

    clientCarPartsSaved(data: Array<ClientCarParts_Dto>) {
        if (ObjectValidators.isValidArray(data)) {
            this.offer.clientCarParts = data;
            data.forEach(x => x.id = null);
        } else {
            this.offer.clientCarParts = [];
        }
        this.editDataAP.execute(this.offer);
        this.onClose.next(true);
        this.activeModal.hide();
    }
}
