import {BaseFormDI} from '../../base/base.di';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CreateReceipt_Dto} from '../../../app-dto/receipt.dto';

export class ReceiptDi extends BaseFormDI<CreateReceipt_Dto> {
    constructor(data) {
        super(new CreateReceipt_Dto(data));
        this.initForm();
    }

    getModel(): CreateReceipt_Dto {
        this.model.value = this.form.value.value;
        this.model.issueDate = this.form.value.issueDate;
        this.model.representing = this.form.value.representing;
        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
            value: new FormControl(this.model.value, Validators.compose([Validators.required, Validators.min(1)])),
            issueDate: new FormControl(this.model.issueDate || new Date(), Validators.compose([Validators.required])),
            representing: new FormControl(this.model.representing, Validators.compose([Validators.required]))
        });
    }

    public issueDateChanged(data: Date) {
        this.model.issueDate = this.form.value.issueDate;
    }
}
