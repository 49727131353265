import { ObjectValidators } from '../shared/object.validators';
import { Address, LoggedUser_Dto } from './core.dto';
import { Base_Dto } from './misc.dto';

export class Login_Dto {
  constructor(public email: string, public password: string) {
  }
}

export class ForgotPassword_Dto {
  constructor(public email: string) {
  }
}

export class ResetPassword_Dto extends ForgotPassword_Dto {
  constructor(public code: string, public password: string, public confirmPassword: string) {
    super('');
  }
}

export class ChangePassword_Dto {
  constructor(public password: string, public confirmPassword: string) {
  }
}

export class RejectSubscription_Dto {
  constructor(public details: string, public updateUser: boolean) {
  }
}

export class RequestSubscription_Dto extends Base_Dto {
  public id: string;
  public serviceName: string;
  public firstName: string;
  public lastName: string;
  public cui: string;
  public registrationNumber: string;
  public email: string;
  public phoneNumber: string;
  public address: Address;
  public subscriptionId: string;
  public currency: string;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.serviceName = data.serviceName;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.cui = data.cui;
      this.registrationNumber = data.registrationNumber;
      this.email = data.email;
      this.phoneNumber = data.phoneNumber;
      this.subscriptionId = data.subscriptionId;
      this.currency = data.currency;
      if (ObjectValidators.isValidObject(data.address)) {
        this.address = new Address(data.address);
      } else this.address = new Address();
    } else {
      this.address = new Address();
    }
  }
}

export class ActivateSubscription_Dto extends ResetPassword_Dto {
  public email: string;
  constructor() {
    super('', '', '');
  }
}

export class DeactivateSubscription_Dto {
  public reason: string = '';
  constructor() {
  }
}
