import {DataTableParameters, PagedResult} from "./datatable/datatable.helpers";
import {Observable, Subscription} from "rxjs";
import {ObjectValidators} from "./object.validators";

export class AllPagesLoader{

  public static run<T>(actionCallback:(datatableParameters : DataTableParameters,...args: any[])=> Observable<PagedResult<T>>,
                       datatableParameters : DataTableParameters,...args: any[]):Observable<Array<T>>{
      datatableParameters.pageNumber = 1;
      datatableParameters.pageSize = 0;
    let result = new Observable<Array<T>>((observer)=>{
        this.doLoadData<T>(null,actionCallback, datatableParameters,observer,[],...args);
    });

    return result;
  }

  private static doLoadData<T>(currentSubscription:Subscription,actionCallback:(datatableParameters : DataTableParameters,...args: any[])=> Observable<PagedResult<T>>,
                               datatableParameters:DataTableParameters, observer:any, resultItems:Array<T>,...args: any[]){
    if(ObjectValidators.isValidObject(currentSubscription)) currentSubscription.unsubscribe();
    if(datatableParameters ==null) {
      observer.next(null);
      observer.complete();
    }

    currentSubscription = actionCallback(datatableParameters,...args).subscribe((result:PagedResult<T>)=>{
      if(result != null) {
        if (result.totalPages == result.currentPage) {
          resultItems.push.apply(resultItems, result.items);
          observer.next(resultItems);
          observer.complete();
        }
        else {
          datatableParameters.pageNumber++;
          resultItems.push.apply(resultItems, result.items);
          this.doLoadData<T>(currentSubscription, actionCallback, datatableParameters, observer, resultItems, ...args);
        }
      }
    });
  }
}
