import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { StaticDataSelectorInput } from '../../../core/shared/components/static-data-selector/static-data-selector.component';
import { WorkmanshipFormDi } from './workmanship-form.di';
import { WorkmanshipFormSandbox } from './workmanship-form.sandbox';
import { Workmanship_Dto } from '../../../core/app-dto/workmanship.dto';

@Component({
  selector: 'app-workmanship-form',
  templateUrl: './workmanship-form.component.html',
  styleUrls: ['./workmanship-form.component.scss']
})
export class WorkmanshipFormComponent extends BaseFormModelComponent<Workmanship_Dto> implements OnInit, OnDestroy {
  public workmanshipCurrencyDataInput: StaticDataSelectorInput;

  @Output()
  formSaved = new EventEmitter<Workmanship_Dto>();

  @Input('withQuantity') withQuantity: boolean;

  constructor(public appState: Store<store.State>,
              public sandbox: WorkmanshipFormSandbox
  ) {
    super(sandbox, ResetSection.CarPart);
  }

  public diInitialize(data: Workmanship_Dto) {
    this.di = new WorkmanshipFormDi(data);
    this.workmanshipCurrencyDataInput = {
      form: this.di.form,
      type: 'currencies',
      formControlName: 'currency',
      isRequired: true,
      label: 'Moneda',
      isReadOnly:false
    };
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();
    if (this.di.isValid()) {
      if (this.formSaved != null) {
        this.formSaved.emit(this.di.getModel());
      }
    }
  }

  isValid() {
    return this.di.isValid();
  }
}
