import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CarService_Dto } from 'src/app/core/app-dto/car-service.dto';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap';
import { CarServiceFinalInspectionDI } from './car-service-final-inspection.di';
import { CarServiceFinalInspectionSandbox } from './car-service-final-inspection.sandbox';
import { CarServiceSaveFinalInspectionComponent } from '../car-service-save-final-inspection/car-service-save-final-inspection.component';
import { DownloadCarServiceFinalInspectionActionProxy } from './download-car-service-final-inspection.action-proxy';
import { ObjectValidators } from 'src/app/core/shared/object.validators';

@Component({
  selector: 'app-car-service-final-inspection',
  templateUrl: './car-service-final-inspection.component.html',
  styleUrls: ['./car-service-final-inspection.component.scss']
})
export class CarServiceFinalInspectionComponent extends BaseFormModelComponent<CarService_Dto> implements OnInit, OnDestroy {

  public isReadOnlyValue: boolean;
  @Input() set isReadOnly(value: boolean) {
    this.isReadOnlyValue = value;
  }

  public di: CarServiceFinalInspectionDI;//we need to overwrite di with specific type for production build
  public downloadFinalInspectionAP: DownloadCarServiceFinalInspectionActionProxy;

  constructor(public appState: Store<store.State>,
    private modalService: BsModalService,
    public sandbox: CarServiceFinalInspectionSandbox
  ) {
    super(sandbox);
    this.downloadFinalInspectionAP = new DownloadCarServiceFinalInspectionActionProxy(this, sandbox.appState);
  }

  public diInitialize(data: CarService_Dto) {
    this.di = new CarServiceFinalInspectionDI(data);
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.sandbox.initialize();
  }

  ngOnDestroy() {
    this.destroy();
  }

  editFinalInspection() {
    const modalRef = this.modalService.show(CarServiceSaveFinalInspectionComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = this.di.model;
  }

  downloadFinalInspection() {
    this.downloadFinalInspectionAP.execute(this.di.model.id, (url: string) => {
      if (ObjectValidators.isValidString(url)) {
        window.open(url, "_self");
      }
    })
  }
}
