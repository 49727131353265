import { Injectable } from "@angular/core";
import * as authenticationStore from "./core/app-store/state-selectors/authentication.selectors";
import * as coreStore from "./core/app-store/state-selectors/core.selectors";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import * as ApplicationStore from "./core/app-store/state";
import { AddAuthenticationToState, RemoveAuthenticationFromState, LoadCurrentLoggedUser } from "./core/app-store/events/authentication.events";
import { BaseComponentState } from "./core/app-store/utils/base-component.state";
import { AppComponent } from "./app.component";
import { BaseSandbox } from "./core/shared/base/base.sandbox";
import { CachingUtils } from "./core/shared/caching.utils";
import { LoggedUser_Dto } from "./core/app-dto/core.dto";
import { NgxSpinnerService } from "ngx-spinner";
import {RtcService} from "./core/core/services/rtc.service";
import {AddStickyHeaderNotificationActionProxy} from "./core/core/components/sticky-header-notification/add-sticky-header-notification.action-proxy";

@Injectable()
export class AppSandbox extends BaseSandbox {

  public loggedUser$ = this.appState$.pipe(select(coreStore.getLoggedUser));
  public isLoading$ = this.appState$.pipe(select(coreStore.getLoadingState));
  public logout$ = this.appState$.pipe(select(authenticationStore.getLogout));

  public isLoggedIn: boolean = false;

  constructor(
    private router: Router,
    protected appState$: Store<ApplicationStore.State>,
    private spinner: NgxSpinnerService,
    private rtcService : RtcService
  ) {
    super(appState$);
  }

  private loadData() {
    const user = CachingUtils.loadData(LoggedUser_Dto.identifier);
    if (user != null) {
      const loggedUser = new LoggedUser_Dto(user);
      this.appState$.dispatch(new AddAuthenticationToState(loggedUser));
      if (loggedUser != null) {
        if (!loggedUser.isLoaded()) {
          this.router.navigate(['/auth/login']);
        } else if (loggedUser.isOwner && !loggedUser.isInitialSetupDone) {
          this.router.navigate(['/subscription/organization-profile']);
        }
      }
    } else this.appState$.dispatch(new RemoveAuthenticationFromState());
  }

  public clearSandboxState() {
  }

  public initialize(t, dataLoaded: Function): any {
    this.clearSandboxState();
    this.loadData();

    this.subscriptions.push(this.isLoading$.subscribe((value: boolean) => {
      if (value) this.spinner.show("app");
      else this.spinner.hide("app");
    }));

    this.subscriptions.push(this.loggedUser$.subscribe((user: LoggedUser_Dto) => {
      if (user != null) {
        CachingUtils.save(LoggedUser_Dto.identifier, user);
        const loggedUser = new LoggedUser_Dto(user);
        this.isLoggedIn = true;
        if (loggedUser != null) {
          if (!loggedUser.isLoaded()) {
            this.router.navigate(["/auth/login"]);
            this.rtcService.disconnect(false);
          }
          else {
            this.rtcService.initialize();
            dataLoaded(loggedUser);

          }
        }
      }else{

      }
    }));


    this.subscriptions.push(this.logout$.subscribe((state: BaseComponentState) => {
      if (state.loaded) {

        this.router.navigate(['/auth/login']);
        CachingUtils.remove(LoggedUser_Dto.identifier);
        this.isLoggedIn = false;
        this.rtcService.disconnect(false);
      }
    }));
  }

  public unsubscribe(): any {
    this.unregisterEvents();
  }

}
