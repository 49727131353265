import {CarPart_Dto, CarPartAggregate_Dto} from '../../app-dto/car-part.dto';
import {PagedResult} from '../../shared/datatable/datatable.helpers';
import {SupplyItem_Dto} from '../../app-dto/supply.dto';
import {Action} from '@ngrx/store';
import {
    Do_GenericErrorEvent,
    Do_GenericSuccessEvent,
    Do_ResetStateForSection,
    EventWithScreenLoading,
    EventWithStopingScreenLoader,
    ResetSection,
    SeamlessEvent
} from '../events/base.events';
import {
    ActivateCarPartEvent,
    ActivateCarPartStockAlertEvent,
    DeactivateCarPartEvent,
    DeactivateCarPartStockAlertEvent,
    DeleteCarPartEvent,
    DeleteSupplyItemEvent,
    EditCarPartEvent,
    GetCarPartEvent,
    GetCarPartsPagedResultEvent,
    GetCarPartStockAlertsPagedResultEvent,
    GetExcelCarPartsImportTemplateEvent,
    GetSupplyItemsForCarPartPagedResultEvent,
    GetTotalCarPartsValueEvent,
    RegisterCarPartEvent,
    SearchCarPartEvent,
    SearchCarPartForSupplyEvent

} from '../events/car-part.events';
import {ResetStateActionProxy} from '../../shared/base/base.action-proxy';
import * as moment from 'moment';
import {ObjectValidators} from '../../shared/object.validators';
import {Offer_Dto} from '../../app-dto/offer.dto';
import {
    AcceptOfferEvent,
    AddOfferServiceCarPartEvent, DeclineOfferEvent,
    DeleteOfferEvent,
    DeleteOfferServiceCarPartEvent,
    GetOfferEvent,
    GetOffersPagedResultEvent,
    GetUnauthOfferEvent,
    RegisterOfferEvent, SendOfferEvent,
    UpdateOfferCarDataEvent,
    UpdateOfferCarWorksEvent,
    UpdateOfferClientCarPartsEvent,
    UpdateOfferClientDataEvent,
    UpdateOfferDataEvent,
    UpdateOfferFinalizeEvent,
    UpdateOfferServiceCarPartEvent
} from '../events/offer.events';

export interface OfferState {
    lastRefreshTime: number;
    currentOffer: Offer_Dto;
    offersPagedResult: PagedResult<Offer_Dto>;
}

export const INITIAL_STATE: OfferState = Object.assign({}, {
    lastRefreshTime: 0,
    currentOffer: null,
    offersPagedResult: new PagedResult<Offer_Dto>([]),
});


export function reducer(state = INITIAL_STATE, action: Action): OfferState {
    if (!action) return state;
    let actionType = action.type;

    actionType = actionType.replace(EventWithScreenLoading.identifier, '');
    actionType = actionType.replace(SeamlessEvent.identifier, '');
    actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
    switch (actionType) {
        // Get Car Part

        case Do_ResetStateForSection.identifier + ResetSection.Offer: {
            return Object.assign({}, state, {
                currentOffer: null,
                offersPagedResult: new PagedResult<Offer_Dto>([])
            });
        }
        case Do_GenericSuccessEvent.identifier + AcceptOfferEvent.identifier:
        case Do_GenericSuccessEvent.identifier + DeclineOfferEvent.identifier:
        case Do_GenericSuccessEvent.identifier + SendOfferEvent.identifier:
        case Do_GenericSuccessEvent.identifier + UpdateOfferClientCarPartsEvent.identifier:
        case Do_GenericSuccessEvent.identifier + UpdateOfferFinalizeEvent.identifier:
        case Do_GenericSuccessEvent.identifier + UpdateOfferDataEvent.identifier:
        case Do_GenericSuccessEvent.identifier + UpdateOfferCarWorksEvent.identifier:
        case Do_GenericSuccessEvent.identifier + UpdateOfferClientDataEvent.identifier:
        case Do_GenericSuccessEvent.identifier + UpdateOfferServiceCarPartEvent.identifier:
        case Do_GenericSuccessEvent.identifier + AddOfferServiceCarPartEvent.identifier:
        case Do_GenericSuccessEvent.identifier + DeleteOfferServiceCarPartEvent.identifier:
        case Do_GenericSuccessEvent.identifier + UpdateOfferCarDataEvent.identifier:
        case Do_GenericSuccessEvent.identifier + GetUnauthOfferEvent.identifier:
        case Do_GenericSuccessEvent.identifier + GetOfferEvent.identifier: {
            const data = new Offer_Dto((action as Do_GenericSuccessEvent<Offer_Dto>).data);
            return Object.assign({}, state, {currentOffer: data});
        }
        case Do_GenericErrorEvent.identifier + GetUnauthOfferEvent.identifier:
        case Do_GenericErrorEvent.identifier + GetOfferEvent.identifier: {
            return Object.assign({}, state, {
                currentOffer: null
            });
        }

        case GetOffersPagedResultEvent.identifier: {
            if ((action as GetOffersPagedResultEvent).withReset) {
                state.offersPagedResult = new PagedResult<Offer_Dto>([]);
                return Object.assign({}, state);
            }
            return state;
        }

        case Do_GenericSuccessEvent.identifier + GetOffersPagedResultEvent.identifier: {
            const data = (action as Do_GenericSuccessEvent<PagedResult<Offer_Dto>>).data;
            state.offersPagedResult = new PagedResult<Offer_Dto>(data);
            return Object.assign({}, state);
        }

        case Do_GenericErrorEvent.identifier + GetOffersPagedResultEvent.identifier: {
            state.offersPagedResult = new PagedResult<Offer_Dto>([]);
            return Object.assign({}, state);
        }

        case Do_GenericSuccessEvent.identifier + AcceptOfferEvent.identifier:
        case Do_GenericSuccessEvent.identifier + DeclineOfferEvent.identifier:
        case Do_GenericSuccessEvent.identifier + SendOfferEvent.identifier:
        case Do_GenericSuccessEvent.identifier + UpdateOfferFinalizeEvent.identifier:
        case Do_GenericSuccessEvent.identifier + UpdateOfferDataEvent.identifier:
        case Do_GenericSuccessEvent.identifier + UpdateOfferCarWorksEvent.identifier:
        case Do_GenericSuccessEvent.identifier + UpdateOfferClientDataEvent.identifier:
        case Do_GenericSuccessEvent.identifier + UpdateOfferServiceCarPartEvent.identifier:
        case Do_GenericSuccessEvent.identifier + AddOfferServiceCarPartEvent.identifier:
        case Do_GenericSuccessEvent.identifier + DeleteOfferServiceCarPartEvent.identifier:
        case Do_GenericSuccessEvent.identifier + UpdateOfferCarDataEvent.identifier:
        case Do_GenericSuccessEvent.identifier + RegisterOfferEvent.identifier: {
            return Object.assign({}, state, {lastRefreshTime: moment().unix()});
        }

        //@todo : add edit actions and update current offer + last refresh time

        case Do_GenericSuccessEvent.identifier + DeleteOfferEvent.identifier: {
            state.lastRefreshTime = moment().unix();
            return Object.assign({}, state);
        }

        default: {
            return state;
        }
    }
}

export const getTimestampCallback = (state: OfferState) => state.lastRefreshTime;
export const getOffersPagedResultCallback = (state: OfferState) => state.offersPagedResult;
export const getOfferCallback = (state: OfferState) => state.currentOffer;
