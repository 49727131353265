import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BaseFormModelComponent} from 'src/app/core/shared/base/base.component';
import {CarServiceClientPartsDI} from './car-service-client-parts.di';
import {ClientCarParts_Dto} from 'src/app/core/app-dto/car-service.dto';
import * as store from '../../../core/app-store/index';
import {Store} from '@ngrx/store';
import {CarServiceClientPartsSandbox} from './car-service-client-parts.sandbox';
import {CarPart_Dto} from 'src/app/core/app-dto/car-part.dto';
import {CarPartSearchComponent} from 'src/app/car-part/components/car-part-search/car-part-search.component';
import {ConfirmationDialogService} from '../../../core/core/dialog/confirmation-dialog.service';
import {makeInputPositiveAndGreaterThenOne} from '../../../core/shared/helpers/common.helpers';

@Component({
    selector: 'app-car-service-client-parts',
    templateUrl: './car-service-client-parts.component.html',
    styleUrls: ['./car-service-client-parts.component.scss']
})
export class CarServiceClientPartsComponent extends BaseFormModelComponent<Array<ClientCarParts_Dto>> implements OnInit, OnDestroy {
    public di: CarServiceClientPartsDI;
    public isEdit: boolean = false;
    @Output()
    formSaved = new EventEmitter<Array<ClientCarParts_Dto>>();

    @ViewChild(CarPartSearchComponent, {static: false}) carPartSearchComponent: CarPartSearchComponent;

    constructor(
        public appState: Store<store.State>, public confirmationDialogService: ConfirmationDialogService,
        public sandbox: CarServiceClientPartsSandbox
    ) {
        super(sandbox);
    }

    public diInitialize(data: Array<ClientCarParts_Dto>) {
        this.di = new CarServiceClientPartsDI(data);
        this.dataLoaded = true;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.destroy();
    }

    public accept() {
        this.formSubmitted = true;
        this.markFormAsDirtyAndTouched();
        if (this.di.isValid()) {
            (<CarServiceClientPartsDI> this.di).addPart();
            this.formSubmitted = false;
            if (this.formSaved != null) {
                this.formSaved.emit(this.di.getModel());
            }
        }
    }

    public emitValues() {
        if (this.formSaved != null) {
            this.formSaved.emit(this.di.getModel());
        }
    }

    delete(id: string) {

        // this.confirmationDialogService.confirm(
        //   "Stergere piesa",
        //   "Urmeaza sa stergeti piesa. Sunteti sigur?",
        //   'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
        //   if (confirmed) {
        //     let i = (<CarServiceClientPartsDI>this.di).model.findIndex(r => r.id == id);
        //     (<CarServiceClientPartsDI>this.di).model.splice(i, 1);
        //   }
        // });
        let i = (<CarServiceClientPartsDI> this.di).model.findIndex(r => r.id == id);
        (<CarServiceClientPartsDI> this.di).model.splice(i, 1);

    }

    edit(id: string) {
        this.isEdit = true;
        let i = this.di.model.findIndex(r => r.id === id);
        this.di.form.controls.code.setValue(this.di.model[i].code);
        this.di.form.controls.code.disable();
        this.di.form.controls.name.setValue(this.di.model[i].name);
        this.di.form.controls.quantity.setValue(this.di.model[i].quantity);
    }

    cancelEdit() {
        this.isEdit = false;
        this.di.form.controls.code.enable();
        this.di.form.reset();
        this.di.form.controls.quantity.setValue(1);
    }

    saveEdit() {
        this.formSubmitted = true;
        this.isEdit = false;
        this.di.form.controls.code.enable();
        this.di.saveEdit();
    }

    isValid() {
        return this.di.isValid();
    }

    carPartSelected(value: CarPart_Dto) {
        let carPart = new ClientCarParts_Dto(value);
        (<CarServiceClientPartsDI> this.di).addSearchedPart(carPart);
        this.carPartSearchComponent.clearSelect();
    }

    makeValuePositive(event: any) {
        makeInputPositiveAndGreaterThenOne(event);
    }

    public addClientPart() {
        this.formSubmitted = true;
        this.di.addPart();
        this.carPartSearchComponent.search('');
        this.carPartSearchComponent.clearSelect();
    }
}
