import {BaseActionProxy} from "../../shared/base/base.action-proxy";
import {IWebComponent} from "../../shared/base/base.component";
import {Store} from "@ngrx/store";
import * as store from "../../app-store";
import {UpdatePaymentMethod} from "../dto/stripe.dto";
import {UpdatePaymentMethodEvent} from "../events/stripe.events";
import {Observable} from "rxjs";

export class UpdateStripePaymentMethodActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent, appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(data: UpdatePaymentMethod, callback: (data: string) => void): any {
        if (this.canExecute()) {
            this.appState.dispatch(new UpdatePaymentMethodEvent(data, callback));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}
