import {BaseFormDI} from "../../../../core/shared/base/base.di";
import {Offer_Dto} from "../../../../core/app-dto/offer.dto";
import {StaticDataSelectorInput} from "../../../../core/shared/components/static-data-selector/static-data-selector.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ObjectValidators} from "../../../../core/shared/object.validators";

export class AddOfferFirstStepDi extends BaseFormDI<Offer_Dto> {
    public needsDiscount: boolean = false;
    public offerTypesInput: StaticDataSelectorInput;

    constructor(data: Offer_Dto = new Offer_Dto(null)) {
        super(new Offer_Dto(data));

        this.initForm();

        this.offerTypesInput = {
            form: this.form,
            type: 'offerTypes',
            formControlName: 'offerType',
            isRequired: true,
            label: "Tipul ofertei",
            isReadOnly: false
        };
    }

    getModel(): Offer_Dto {
        this.model.title = this.form.value.title;

        if (ObjectValidators.isValidObject(this.form.value.offerType))
            this.model.type = this.form.value.offerType;

        if (ObjectValidators.isValidObject(this.model.type) && ObjectValidators.isValidString(this.model.type.name))
            this.needsDiscount = ObjectValidators.isValidObject(this.model.type) && this.model.type.name.toLowerCase().indexOf('discount') > -1;

        this.model.isIndividual = ObjectValidators.isValidObject(this.model.type) && this.model.type.name.toLowerCase().indexOf('individuala') > -1;
        if (this.needsDiscount)
            this.model.offerEntryData.discount = this.form.value.discount;
        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
            title: new FormControl(this.model.title, [Validators.required]),
            discount: new FormControl(this.model.offerEntryData != null ? this.model.offerEntryData.discount : 0)
        });
    }
}
