import { Action } from '@ngrx/store';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  SeamlessEvent
} from '../events/base.events';
import { Breadcrumb } from '../../app-dto/breadcrumb.dto';
import { BreadcrumbEvent } from '../events/breadcrumb.events';

export interface BreadcrumbState {
  breadcrumb: Array<Breadcrumb>;
}
export const INITIAL_STATE: BreadcrumbState = Object.assign({}, {
  breadcrumb: []
});


export function reducer(state = INITIAL_STATE, action: Action): BreadcrumbState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {
    case BreadcrumbEvent.identifier: {
      const data = (action as BreadcrumbEvent);
      state.breadcrumb = data.value;
      return Object.assign({}, state);
    }

    default: {
      return state;
    }
  }
}

export const getBreadcrumbCallback = (state: BreadcrumbState) => state.breadcrumb;
