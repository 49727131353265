import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActiveDataTableParameters } from '../../../core/shared/datatable/datatable.helpers';
import { GetSuppliesWithUnpaidInvoicePagedResultEvent } from '../../../core/app-store/events/supply.events';

export class GetSupplyUnpaidInvoiceListDashboardActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent, appState: Store<store.State>, private spinner: NgxSpinnerService) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(dataTablesParameters: ActiveDataTableParameters, withReset: boolean): any {
        if (this.canExecute()) {
            setTimeout(() => {
                this.spinner.show('car-part-stock-alert-list-dashboard');
            }, 0);
            this.appState.dispatch(new GetSuppliesWithUnpaidInvoicePagedResultEvent(() => {
                this.spinner.hide('car-part-stock-alert-list-dashboard');
            }, dataTablesParameters, withReset));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

