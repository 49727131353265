import {Observable} from 'rxjs';
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import {map, switchMap} from 'rxjs/operators';
import {
    Do_GenericSuccessEvent,
    Do_GenericErrorEvent,
    EventWithScreenLoading,
    SeamlessEvent
} from 'src/app/core/app-store/events/base.events';
import {PagedResult} from 'src/app/core/shared/datatable/datatable.helpers';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {CarPart_Dto, CarPartAggregate_Dto} from 'src/app/core/app-dto/car-part.dto';
import {
    ActivateCarPartEvent,
    ActivateCarPartStockAlertEvent,
    DeactivateCarPartEvent,
    DeactivateCarPartStockAlertEvent,
    DeleteCarPartEvent, DeleteSupplyItemEvent,
    EditCarPartEvent,
    GetCarPartEvent,
    GetCarPartsPagedResultEvent,
    GetCarPartStockAlertsPagedResultEvent,
    GetExcelCarPartsEvent,
    GetExcelCarPartsImportTemplateEvent,
    GetSupplyItemsForCarPartPagedResultEvent, GetTotalCarPartsValueEvent,
    RegisterCarPartEvent,
    SearchCarPartEvent,
    SearchCarPartForSupplyEvent
} from '../../core/app-store/events/car-part.events';
import {CarPartApiService} from '../../core/api-integration/services/car-part-api.service';
import {GetSuppliersEvent} from '../../core/app-store/events/supplier.events';
import {Supplier_Dto} from '../../core/app-dto/supplier.dto';
import {SupplierApiService} from '../../core/api-integration/services/supplier-api.service';
import {isValidObject} from '../../core/shared/helpers/common.helpers';
import {NotifierService} from 'angular-notifier';
import {SupplyItem_Dto} from "../../core/app-dto/supply.dto";


@Injectable()
export class CarPartUIEventProcessor {

    constructor(private actions$: Actions,
                private appState$: Store<store.State>,
                private carPartApiService: CarPartApiService,
                private notifierService: NotifierService,
                private supplierApiService: SupplierApiService) {
    }

    @Effect()
    DeleteSupplyItemEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(DeleteSupplyItemEvent.identifier, EventWithScreenLoading.identifier + DeleteSupplyItemEvent.identifier),
            map((action: DeleteSupplyItemEvent) => action),
            switchMap((action: DeleteSupplyItemEvent) => {
                return new Observable((observer) => {
                    this.carPartApiService.u_deleteSupplyItem(action.supplyItemId)
                        .subscribe((res: CarPart_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<CarPart_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
    @Effect()
    GetSupplyItemsForCarPartPagedResultEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetSupplyItemsForCarPartPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetSupplyItemsForCarPartPagedResultEvent.identifier),
            map((action: GetSupplyItemsForCarPartPagedResultEvent) => action),
            switchMap((action: GetSupplyItemsForCarPartPagedResultEvent) => {
                return new Observable((observer) => {
                    this.carPartApiService.u_getFilteredSupplyItemsForCarPartPaginated(action.carPartId, action.parameters, action.includeUsedOnes)
                        .subscribe((res: PagedResult<SupplyItem_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<SupplyItem_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
    @Effect()
    GetCarPartsPagedResultEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetCarPartsPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetCarPartsPagedResultEvent.identifier),
            map((action: GetCarPartsPagedResultEvent) => action),
            switchMap((action: GetCarPartsPagedResultEvent) => {
                return new Observable((observer) => {
                    this.carPartApiService.u_getFilteredCarPartsPaginated(action.parameters, action.supplierFilter)
                        .subscribe((res: PagedResult<CarPart_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<CarPart_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    GetCarPartStockAlertsPagedResultEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetCarPartStockAlertsPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetCarPartStockAlertsPagedResultEvent.identifier),
            map((action: GetCarPartStockAlertsPagedResultEvent) => action),
            switchMap((action: GetCarPartStockAlertsPagedResultEvent) => {
                return new Observable((observer) => {
                    this.carPartApiService.u_getFilteredCarPartStockAlertsPaginated(action.parameters)
                        .subscribe((res: PagedResult<CarPart_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<CarPart_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    getCarPart$: Observable<any> = this.actions$
        .pipe(
            ofType(GetCarPartEvent.identifier, EventWithScreenLoading.identifier + GetCarPartEvent.identifier),
            map((action: GetCarPartEvent) => action),
            switchMap((action: GetCarPartEvent) => {
                return new Observable((observer) => {
                    this.carPartApiService.u_getCarPart(action.id)
                        .subscribe((res: CarPartAggregate_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<CarPartAggregate_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doRegisterCarPart$: Observable<any> = this.actions$
        .pipe(
            ofType(RegisterCarPartEvent.identifier, EventWithScreenLoading.identifier + RegisterCarPartEvent.identifier),
            map((action: RegisterCarPartEvent) => action),
            switchMap((action: RegisterCarPartEvent) => {
                    return new Observable((observer) => {
                        this.carPartApiService.u_registerCarPart(action.model)
                            .subscribe((res: CarPart_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<CarPart_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doEditCarPart$: Observable<any> = this.actions$
        .pipe(
            ofType(EditCarPartEvent.identifier, EventWithScreenLoading.identifier + EditCarPartEvent.identifier),
            map((action: EditCarPartEvent) => action),
            switchMap((action: EditCarPartEvent) => {
                    return new Observable((observer) => {
                        this.carPartApiService.u_editCarPart(action.model)
                            .subscribe((res: CarPart_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<CarPart_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doDeleteCarPart$: Observable<any> = this.actions$
        .pipe(
            ofType(DeleteCarPartEvent.identifier, EventWithScreenLoading.identifier + DeleteCarPartEvent.identifier),
            map((action: DeleteCarPartEvent) => action),
            switchMap((action: DeleteCarPartEvent) => {
                    return new Observable((observer) => {
                        this.carPartApiService.u_deleteCarPart(action.id)
                            .subscribe((res: CarPart_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<CarPart_Dto>(res, action));
                                if (isValidObject(action.callback)) action.callback();
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doActivateCarPart$: Observable<any> = this.actions$
        .pipe(
            ofType(ActivateCarPartEvent.identifier, EventWithScreenLoading.identifier + ActivateCarPartEvent.identifier),
            map((action: ActivateCarPartEvent) => action),
            switchMap((action: ActivateCarPartEvent) => {
                    return new Observable((observer) => {
                        this.carPartApiService.u_activateCarPart(action.id)
                            .subscribe((res: CarPart_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<CarPart_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doDeactivateCarPart$: Observable<any> = this.actions$
        .pipe(
            ofType(DeactivateCarPartEvent.identifier, EventWithScreenLoading.identifier + DeactivateCarPartEvent.identifier),
            map((action: DeactivateCarPartEvent) => action),
            switchMap((action: DeactivateCarPartEvent) => {
                    return new Observable((observer) => {
                        this.carPartApiService.u_deactivateCarPart(action.id)
                            .subscribe((res: CarPart_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<CarPart_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doGetSuppliers$: Observable<any> = this.actions$
        .pipe(
            ofType(GetSuppliersEvent.identifier, EventWithScreenLoading.identifier + GetSuppliersEvent.identifier),
            map((action: GetSuppliersEvent) => action),
            switchMap((action: GetSuppliersEvent) => {
                    return new Observable((observer) => {
                        this.supplierApiService.u_getSuppliers()
                            .subscribe((res: Supplier_Dto[]) => {
                                observer.next(new Do_GenericSuccessEvent<Supplier_Dto[]>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );
    @Effect()
    doGetTotalValue$: Observable<any> = this.actions$
        .pipe(
            ofType(GetTotalCarPartsValueEvent.identifier, SeamlessEvent.identifier + GetTotalCarPartsValueEvent.identifier),
            map((action: GetTotalCarPartsValueEvent) => action),
            switchMap((action: GetTotalCarPartsValueEvent) => {
                    return new Observable((observer) => {
                        this.carPartApiService.u_getTotalCarPartsValue()
                            .subscribe((res: number) => {
                                observer.next(new Do_GenericSuccessEvent<number>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );


    @Effect()
    doSearchCarParts$: Observable<any> = this.actions$
        .pipe(
            ofType(SearchCarPartEvent.identifier, SeamlessEvent.identifier + SearchCarPartEvent.identifier),
            map((action: SearchCarPartEvent) => action),
            switchMap((action: SearchCarPartEvent) => {
                return new Observable((observer) => {
                    this.carPartApiService.u_searchCarParts(action.search, action.displayOnlyAvailableItems)
                        .subscribe((res: Array<CarPart_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Array<CarPart_Dto>>(res, action));
                            }
                            action.callBack();
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            action.callBack();
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doSearchCarPartsForSupply$: Observable<any> = this.actions$
        .pipe(
            ofType(SearchCarPartForSupplyEvent.identifier, SeamlessEvent.identifier + SearchCarPartForSupplyEvent.identifier),
            map((action: SearchCarPartForSupplyEvent) => action),
            switchMap((action: SearchCarPartForSupplyEvent) => {
                return new Observable((observer) => {
                    this.carPartApiService.u_searchCarPartsForSupply(action.search, action.supplierName)
                        .subscribe((res: Array<CarPart_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Array<CarPart_Dto>>(res, action));
                            }
                            action.callBack();
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            action.callBack();
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doGetExcelCarParts$: Observable<any> = this.actions$
        .pipe(
            ofType(GetExcelCarPartsEvent.identifier, EventWithScreenLoading.identifier + GetExcelCarPartsEvent.identifier),
            map((action: GetExcelCarPartsEvent) => action),
            switchMap((action: GetExcelCarPartsEvent) => {
                return new Observable((observer) => {
                    this.carPartApiService.u_getExcelCarParts(action.supplierId)
                        .subscribe((res: string) => {
                            observer.next(new Do_GenericSuccessEvent<string>(res, action));
                            observer.complete();
                            this.notifierService.notify('success', 'Cererea dumneavoastra a fost inregistrata! Vei primi un email cu link-ul pentru descarcare!');
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doGetExcelCarPartsImportTemplate$: Observable<any> = this.actions$
        .pipe(
            ofType(GetExcelCarPartsImportTemplateEvent.identifier, EventWithScreenLoading.identifier + GetExcelCarPartsImportTemplateEvent.identifier),
            map((action: GetExcelCarPartsImportTemplateEvent) => action),
            switchMap((action: GetExcelCarPartsImportTemplateEvent) => {
                return new Observable((observer) => {
                    this.carPartApiService.u_getExcelCarPartsImportTemplate()
                        .subscribe((res: string) => {
                            observer.next(new Do_GenericSuccessEvent<string>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doActivateCarPartStockAlert$: Observable<any> = this.actions$
        .pipe(
            ofType(ActivateCarPartStockAlertEvent.identifier, EventWithScreenLoading.identifier + ActivateCarPartStockAlertEvent.identifier),
            map((action: ActivateCarPartStockAlertEvent) => action),
            switchMap((action: ActivateCarPartStockAlertEvent) => {
                    return new Observable((observer) => {
                        this.carPartApiService.u_activateCarPartStockAlert(action.id)
                            .subscribe((res: CarPart_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<CarPart_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

    @Effect()
    doDeactivateCarPartStockAlert$: Observable<any> = this.actions$
        .pipe(
            ofType(DeactivateCarPartStockAlertEvent.identifier, EventWithScreenLoading.identifier + DeactivateCarPartStockAlertEvent.identifier),
            map((action: DeactivateCarPartStockAlertEvent) => action),
            switchMap((action: DeactivateCarPartStockAlertEvent) => {
                    return new Observable((observer) => {
                        this.carPartApiService.u_deactivateCarPartStockAlert(action.id)
                            .subscribe((res: CarPart_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<CarPart_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );
}
