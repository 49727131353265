import {ObjectValidators} from '../shared/object.validators';
import {Base_Dto, BaseCRUDRules, BusinessRule, IdNameNumber_Dto} from './misc.dto';
import * as moment from 'moment';
import {Supplier_Dto} from './supplier.dto';
import {Supply_Dto} from './supply.dto';
import {isValidArrayAndHasElements, isValidObject} from '../shared/helpers/common.helpers';

export class Organization_Dto extends Base_Dto {
    public name: string;

    constructor(data: any) {
        super(data);

        this.name = data.name;
        }
    }

export class SuperAdminLogs_Dto extends  Base_Dto {
    public  organizationName: string;
    public  numberOfUsers: number;
    public date: Date;
    public  numberOfInvoices: number;
    public numberOfAuditEvents: number;
    public  numberOfCarServiceEntries: number;


    constructor(data: any) {
        super(data);
        this.organizationName = data.organizationName;
        this.numberOfUsers = data.numberOfUsers;
        this.date = data.date;
        this.numberOfInvoices = data.numberOfInvoices;
        this.numberOfAuditEvents = data.numberOfAuditEvents;
        this.numberOfCarServiceEntries = data.numberOfCarServiceEntries;
    }
}





