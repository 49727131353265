import {Component, Input, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {BsModalRef} from "ngx-bootstrap";

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  public title: string;
  public message: string;
  public btnOkText: string;
  public btnCancelText: string;

  public onClose: Subject<boolean>;

  constructor(private activeModal: BsModalRef) {
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.onClose.next(true);
    this.activeModal.hide();
  }

  public dismiss() {
    this.onClose.next(false);
    this.activeModal.hide();
  }
}
