import {Injectable} from '@angular/core';
import * as store from '../../../core/app-store/index';
import {select, Store} from '@ngrx/store';
import {BaseSandbox} from 'src/app/core/shared/base/base.sandbox';
import * as coreStore from '../../../core/app-store/state-selectors/core.selectors';
import {LoggedUser_Dto} from '../../../core/app-dto/core.dto';
import {Organization} from '../../../core/app-dto/organization.dto';
import {ObjectValidators} from '../../../core/shared/object.validators';
import { ServicePlatform_Dto, ServicePlatformCalendarColumn } from '../../../core/app-dto/service-platform.dto';
import { CalendarEvent_Dto } from '../../../core/app-dto/calendar-event.dto';

@Injectable()
export class CalendarServicePlatformsSandbox extends BaseSandbox {
  public calendarEvents$ = this.appState$.pipe(select(store.getCalendarEvents));
  public loggedUser$ = this.appState$.pipe(select(coreStore.getLoggedUser));
  public getServicePlatforms$ = this.appState$.pipe(select(store.getServicePlatformsList));
  private currentOrganization: Organization = null;
  public currentItems: Array<CalendarEvent_Dto> = [];
  public servicePlatforms: Array<ServicePlatformCalendarColumn> = [];

  constructor(protected appState$: Store<store.State>) {
    super(appState$);
  }

  public clearSandboxState() {
    this.currentItems = null;
    this.currentOrganization = null;
    this.servicePlatforms = [];
  }

  public initialize(dataLoaded): any {
    this.clearSandboxState();

    this.subscriptions.push(this.calendarEvents$.subscribe((data: Array<CalendarEvent_Dto>) => {
      if (data != null) {
        this.currentItems = data;
        this.checkAndDispatchDataLoaded(dataLoaded);
      }
    }));
    this.subscriptions.push(this.loggedUser$.subscribe((user: LoggedUser_Dto) => {
      if (user != null && user.organization != null) {
        this.currentOrganization = user.organization;
        this.checkAndDispatchDataLoaded(dataLoaded);
      }
    }));

    this.subscriptions.push(this.getServicePlatforms$.subscribe((servicePlatforms: Array<ServicePlatform_Dto>) => {
      if (servicePlatforms != null && servicePlatforms.length > 0) {
        this.servicePlatforms = servicePlatforms.map(servicePlatform => {
          return {
            id: servicePlatform.id,
            title: servicePlatform.name,
            subtitle: servicePlatform.description
          } as ServicePlatformCalendarColumn;
        });
        this.checkAndDispatchDataLoaded(dataLoaded);
      }
    }));
  }

  private checkAndDispatchDataLoaded(dataLoaded : any){
    if (ObjectValidators.isValidObject(this.currentOrganization) &&
      ObjectValidators.isValidNonEmptyArray(this.servicePlatforms)){
      dataLoaded(this.currentItems, this.currentOrganization, this.servicePlatforms);
    }
  }
}
