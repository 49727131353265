import * as fromAuthentication from './stores/authentication.store';
import * as fromCore from './stores/core.store';
import * as fromStaticData from './stores/static-data.store';
import * as fromSubscription from './stores/subscription.store';
import * as fromServicePlatform from './stores/service-platform.store';
import * as fromMechanic from './stores/mechanic.store';
import * as fromProfile from './stores/profile.store';
import * as fromUser from './stores/user.store';
import * as fromUpload from './stores/upload.store';
import * as fromInvoice from './stores/invoice.store';
import * as fromReceipt from './stores/receipt.store';
import * as fromCarPart from './stores/car-part.store';
import * as fromCar from './stores/car.store';
import * as fromCalendar from './stores/calendar.store';
import * as fromSupplier from './stores/supplier.store';
import * as fromOrganizationClient from './stores/organization-client.store';
import * as fromDraft from './stores/draft.store';
import * as fromCarService from './stores/car-service.store';
import * as fromServicePlatformStatus from './stores/service-platform-status.store';
import * as fromAudit from './stores/audit.store';
import * as fromDocuments from './stores/documents.store';
import * as fromDepartment from './stores/department.store';
import * as fromReporting from './stores/reporting.store';
import * as fromOrganizationCost from './stores/organization-cost.store';
import * as fromSMS from './stores/sms.store';
import * as fromBreadcrumb from './stores/breadcrumb.store';
import * as fromCarWork from './stores/car-work.store';
import * as fromSupply from './stores/supply.store';
import * as fromCashRegister from './stores/cash-register.store';
import * as fromOffer from './stores/offer.store';
import * as fromSuperAdminLogs from './stores/super-admin-logs.store';

export interface State {
    authentication: fromAuthentication.AuthenticationState;
    core: fromCore.CoreState;
    staticData: fromStaticData.StaticDataState;
    subscription: fromSubscription.SubscriptionState;
    servicePlatform: fromServicePlatform.ServicePlatformState;
    mechanic: fromMechanic.MechanicState;
    user: fromUser.UserState;
    upload: fromUpload.UploadState;
    profile: fromProfile.ProfileState;
    invoice: fromInvoice.InvoiceState;
    receipt: fromReceipt.ReceiptState;
    carPart: fromCarPart.CarPartState;
    car: fromCar.CarState;
    calendar: fromCalendar.CalendarState;
    supplier: fromSupplier.SupplierState;
    organizationClient: fromOrganizationClient.OrganizationClientState;
    draft: fromDraft.DraftState;
    carService: fromCarService.CarServiceState;
    servicePlatformStatus: fromServicePlatformStatus.ServicePlatformStatusState;
    audit: fromAudit.AuditState;
    documents: fromDocuments.DocumentsState;
    department: fromDepartment.DepartmentState;
    reporting: fromReporting.ReportingState;
    organizationCost: fromOrganizationCost.OrganizationCostState;
    sms: fromSMS.SmsState;
    breadcrumb: fromBreadcrumb.BreadcrumbState;
    carWork: fromCarWork.CarWorkState;
    supply: fromSupply.SupplyState;
    cashRegister: fromCashRegister.CashRegisterState;
    offer: fromOffer.OfferState;
    superAdminLogs: fromSuperAdminLogs.SuperAdminLogsState;
}

export const INITIAL_STATE: State = {
    authentication: fromAuthentication.INITIAL_STATE,
    core: fromCore.INITIAL_STATE,
    staticData: fromStaticData.INITIAL_STATE,
    subscription: fromSubscription.INITIAL_STATE,
    servicePlatform: fromServicePlatform.INITIAL_STATE,
    mechanic: fromMechanic.INITIAL_STATE,
    user: fromUser.INITIAL_STATE,
    upload: fromUpload.INITIAL_STATE,
    profile: fromProfile.INITIAL_STATE,
    invoice: fromInvoice.INITIAL_STATE,
    receipt: fromReceipt.INITIAL_STATE,
    carPart: fromCarPart.INITIAL_STATE,
    car: fromCar.INITIAL_STATE,
    calendar: fromCalendar.INITIAL_STATE,
    supplier: fromSupplier.INITIAL_STATE,
    organizationClient: fromOrganizationClient.INITIAL_STATE,
    draft: fromDraft.INITIAL_STATE,
    carService: fromCarService.INITIAL_STATE,
    servicePlatformStatus: fromServicePlatformStatus.INITIAL_STATE,
    audit: fromAudit.INITIAL_STATE,
    documents: fromDocuments.INITIAL_STATE,
    department: fromDepartment.INITIAL_STATE,
    reporting: fromReporting.INITIAL_STATE,
    organizationCost: fromOrganizationCost.INITIAL_STATE,
    sms: fromSMS.INITIAL_STATE,
    breadcrumb: fromBreadcrumb.INITIAL_STATE,
    carWork: fromCarWork.INITIAL_STATE,
    supply: fromSupply.INITIAL_STATE,
    cashRegister: fromCashRegister.INITIAL_STATE,
    offer : fromOffer.INITIAL_STATE,
    superAdminLogs: fromSuperAdminLogs.INITIAL_STATE
};
