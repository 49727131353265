import {BaseFormDI} from 'src/app/core/shared/base/base.di';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Supply_Dto} from '../../../core/app-dto/supply.dto';
import {isValidString} from "../../../core/shared/helpers/common.helpers";

export class SupplyFormDi extends BaseFormDI<Supply_Dto> {
  constructor(data: Supply_Dto = new Supply_Dto(null)) {
    super(new Supply_Dto(data));
    this.initForm();
  }

  getModel(): Supply_Dto {
    this.model.supplier = this.form.getRawValue().supplier;
    this.model.invoiceNumber = this.form.value.invoiceNumber;
    this.model.supplyDate = this.form.value.supplyDate;
    this.model.currency = this.form.value.currency;
    this.model.invoiceDate = this.form.value.invoiceDate;
    this.model.deadline = this.form.value.deadline;
    this.model.totalValueWithVat = this.form.value.totalValueWithVat;
    this.model.totalVatValue = this.form.value.totalVatValue;
    this.model.observations = this.form.value.observations;
    this.model.receptionCommittee = this.form.value.receptionCommittee;
    this.model.delegate = this.form.value.delegate;
    this.model.auto = this.form.value.auto;
    this.model.internalObservations = this.form.value.internalObservations;
    this.model.supplyItems = this.form.value.supplyItems;
    this.model.isStorno = this.form.value.isStorno;

    return this.model;
  }

  initForm() {
    this.form = new FormGroup({
      supplier: new FormControl(this.model.supplier, Validators.compose([Validators.required])),
      invoiceNumber: new FormControl(this.model.invoiceNumber, Validators.compose([Validators.required])),
      supplyDate: new FormControl(this.model.supplyDate || new Date(), Validators.compose([Validators.required])),
      currencySelectedValue: new FormControl(this.model.currency, Validators.compose([Validators.required])),
      invoiceDate: new FormControl(this.model.invoiceDate || new Date(), Validators.compose([Validators.required])),
      deadline: new FormControl(this.model.deadline || new Date(), Validators.compose([Validators.required])),
      totalVatValue: new FormControl(this.model.totalVatValue, Validators.compose([Validators.required])),
      totalValueWithVat: new FormControl(this.model.totalValueWithVat, Validators.compose([Validators.required])),
      observations: new FormControl(this.model.observations, Validators.compose([])),
      receptionCommittee: new FormControl(this.model.receptionCommittee, Validators.compose([])),
      delegate: new FormControl(this.model.delegate, Validators.compose([])),
      auto: new FormControl(this.model.auto, Validators.compose([])),
      internalObservations: new FormControl(this.model.internalObservations, Validators.compose([]))
    });
  }
}
