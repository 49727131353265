import {Injectable} from '@angular/core';
import {BaseApiService} from './base-api.service';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscriber} from 'rxjs';
import {ApiErrorDto} from '../utils/api-error.dto';
import {ConfigService} from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {ActiveDataTableParameters, DataTableParameters, PagedResult} from '../../shared/datatable/datatable.helpers';
import {Mechanic_Dto, MechanicWithActionsAndCar_Dto, RequestAccountForMechanic_Dto} from '../../app-dto/mechanic.dto';
import {ErrorHelpers} from '../../shared/error.helpers';
import {ObjectValidators} from '../../shared/object.validators';
import {cleanSearchValue} from '../utils/api-integration.helper';
import {Offer_Dto, OfferAcceptedTrace_Dto, OfferDeclinedTrace_Dto, SendOfferPayload_Dto} from '../../app-dto/offer.dto';
import {DeleteCarServiceEntryActionProxy} from '../../../car-service/components/car-service-details/delete-car-service-entry.action-proxy';
import {OfferDataTableParameters} from '../../app-store/events/offer.events';
import {CarPart_Dto} from '../../app-dto/car-part.dto';
import {debug} from 'util';
import {CarService_Dto} from '../../app-dto/car-service.dto';

class OfferApiRoutes {
    public static route_for_GET_OFFERS_PAGINATED() {
        return '/api/offerentry/paginated';
    }

    public static route_for_GET_OFFER(id: string) {
        return `/api/offerentry/${id}`;
    }

    public static route_for_GET_UNAUTH_OFFER(id: string, code: string) {
        return `/api/offerentry/annonymus/${code}/${id}`;
    }

    public static route_for_REGISTER_OFFER() {
        return '/api/offerentry/';
    }

    public static route_for_DELETE_OFFER(id: string) {
        return `/api/offerentry/${id}`;
    }

    public static route_for_UPDATE_OFFER_CLIENT() {
        return '/api/offerentry/client';
    }

    public static route_for_UPDATE_OFFER_CAR() {
        return '/api/offerentry/car';
    }

    public static route_for_UPDATE_OFFER_DATA() {
        return '/api/offerentry/data';
    }

    public static route_for_UPDATE_OFFER_CAR_WORKS() {
        return '/api/offerentry/carWorks';
    }

    public static route_for_UPDATE_OFFER_CLIENT_CAR_PARTS() {
        return '/api/offerentry/clientCarParts';
    }

    public static route_for_ADD_SERVICE_CAR_PART_TO_OFFER(id: string) {
        return `/api/offerentry/${id}/serviceCarPart`;
    }

    public static route_for_UPDATE_SERVICE_CAR_PART_TO_OFFER(id: string) {
        return `/api/offerentry/${id}/serviceCarPart`;
    }

    public static route_for_DELETE_SERVICE_CAR_PART_TO_OFFER(id: string) {
        return `/api/offerentry/${id}/serviceCarPart`;
    }

    public static route_for_FINALIZE_OFFER(id: string) {
        return `/api/offerentry/${id}/finalize`;
    }

    public static route_for_SEND_OFFER(id: string) {
        return `/api/offerentry/${id}/send`;
    }

    public static route_for_ACCEPT_OFFER(id: string, code: string) {
        return `/api/offerentry/annonymus/${code}/${id}/accept`;
    }

    public static route_for_DECLINE_OFFER(id: string, code: string) {
        return `/api/offerentry/annonymus/${code}/${id}/decline`;
    }

    public static route_for_GENERATE_OFFER(id: string) {
        return `/api/offerentry/${id}/print`;
    }
}

@Injectable()
export class OfferApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_declineOffer(id: string, code: string, data: OfferDeclinedTrace_Dto): Observable<Offer_Dto> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_DECLINE_OFFER(id, code);

        return this.http.put(url, data)
            .map((res: any) => {
                return new Offer_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_acceptOffer(id: string, code: string, data: OfferAcceptedTrace_Dto): Observable<Offer_Dto> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_ACCEPT_OFFER(id, code);

        return this.http.put(url, data)
            .map((res: any) => {
                return new Offer_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_sendOffer(id: string, data: SendOfferPayload_Dto): Observable<Offer_Dto> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_SEND_OFFER(id);

        return this.http.put(url, data)
            .map((res: any) => {
                return new Offer_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_updateOfferDeleteServiceCarPart(id: string, data: CarPart_Dto): Observable<Offer_Dto> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_DELETE_SERVICE_CAR_PART_TO_OFFER(id);

        return this.http.patch(url, data)
            .map((res: any) => {
                return new Offer_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_updateOfferUpdateServiceCarPart(id: string, data: CarPart_Dto): Observable<Offer_Dto> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_UPDATE_SERVICE_CAR_PART_TO_OFFER(id);

        return this.http.put(url, data)
            .map((res: any) => {
                return new Offer_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_updateOfferAddServiceCarPart(id: string, data: CarPart_Dto): Observable<Offer_Dto> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_ADD_SERVICE_CAR_PART_TO_OFFER(id);

        return this.http.post(url, data)
            .map((res: any) => {
                return new Offer_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_updateOfferClientCarParts(data: Offer_Dto): Observable<Offer_Dto> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_UPDATE_OFFER_CLIENT_CAR_PARTS();

        return this.http.put(url, data)
            .map((res: any) => {
                return new Offer_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_finalizeOffer(data: Offer_Dto): Observable<Offer_Dto> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_FINALIZE_OFFER(data.id);

        return this.http.put(url, data)
            .map((res: any) => {
                return new Offer_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_updateOfferCarWorks(data: Offer_Dto): Observable<Offer_Dto> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_UPDATE_OFFER_CAR_WORKS();

        return this.http.put(url, data)
            .map((res: any) => {
                return new Offer_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_updateOfferData(data: Offer_Dto): Observable<Offer_Dto> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_UPDATE_OFFER_DATA();

        return this.http.put(url, data)
            .map((res: any) => {
                return new Offer_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_updateOfferCarData(data: Offer_Dto): Observable<Offer_Dto> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_UPDATE_OFFER_CAR();

        return this.http.put(url, data)
            .map((res: any) => {
                return new Offer_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_updateOfferClientData(data: Offer_Dto): Observable<Offer_Dto> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_UPDATE_OFFER_CLIENT();

        return this.http.put(url, data)
            .map((res: any) => {
                return new Offer_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_registerOffer(data: Offer_Dto): Observable<Offer_Dto> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_REGISTER_OFFER();

        return this.http.post(url, data)
            .map((res: any) => {
                return new Offer_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }


    u_getFilteredOffersPaginated(data: OfferDataTableParameters): Observable<PagedResult<Offer_Dto>> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_GET_OFFERS_PAGINATED() + '?' + data.toUrlParameters();

        return this.http.get(url)
            .map((res: PagedResult<Offer_Dto>) => {
                return new PagedResult<Offer_Dto>(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getOffer(id: string): Observable<Offer_Dto> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_GET_OFFER(id);

        return this.http.get(url)
            .map((res: any) => {
                return new Offer_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getUnauthOffer(id: string, code: string): Observable<Offer_Dto> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_GET_UNAUTH_OFFER(id, code);

        return this.http.get(url)
            .map((res: any) => {
                return new Offer_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deleteOffer(id: string): Observable<any> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_DELETE_OFFER(id);

        return this.http.delete(url)
            .map((res: any) => {
                return res;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_generateOffer(carServiceId: string): Observable<any> {
        const url = this.configService.getAPIBaseUrl() + OfferApiRoutes.route_for_GENERATE_OFFER(carServiceId);

        return this.http.get(url)
            .map((res: any) => {
                return res;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return Observable.create((observer: Subscriber<any>) => {
                    observer.error(err.error.error.message);
                    observer.complete();
                });
            });
    }
}
