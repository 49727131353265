import { Component, OnInit } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { BaseListWebComponentWithSearchAndActiveBehavior } from 'src/app/core/shared/base/base.component';
import { Router } from '@angular/router';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { GetSupplyUnpaidInvoiceListDashboardActionProxy } from './get-supply-unpaid-invoice-list-dashboard.action-proxy';
import { SupplyUnpaidInvoiceListDashboardSandbox } from './supply-unpaid-invoice-list-dashboard.sandbox';
import { BsModalService } from 'ngx-bootstrap';
import { ConfigService } from 'src/app/core/core/app-config.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActiveDataTableParameters } from '../../../core/shared/datatable/datatable.helpers';


@Component({
  selector: 'app-supply-unpaid-invoice-list-dashboard',
  templateUrl: './supply-unpaid-invoice-list-dashboard.component.html',
  styleUrls: ['./supply-unpaid-invoice-list-dashboard.component.scss']
})
export class SupplyUnpaidInvoiceListDashboardComponent extends BaseListWebComponentWithSearchAndActiveBehavior implements OnInit {
  public getsupplyUnpaidInvoiceListAP: GetSupplyUnpaidInvoiceListDashboardActionProxy;
  public searchValue: string = '';
  public currentPage = 1;

  constructor(public sandbox: SupplyUnpaidInvoiceListDashboardSandbox,
              actionsSubj: ActionsSubject,
              private configService: ConfigService,
              private modalService: BsModalService,
              public router: Router,
              private spinner: NgxSpinnerService) {
    super(sandbox, actionsSubj, ResetSection.CarService);
    this.getsupplyUnpaidInvoiceListAP = new GetSupplyUnpaidInvoiceListDashboardActionProxy(this, sandbox.appState, this.spinner);
  }

  ngOnInit() {
    this.initialize();
    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      pageLength: this.configService.getDefaultPageSize(),
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      ajax: (dataTablesParameters: ActiveDataTableParameters, callback) => {
        dataTablesParameters.active = this.isActive;
        dataTablesParameters.search.value = this.searchValue;
        this.doGetAll(new ActiveDataTableParameters(dataTablesParameters), callback, false);
      },
      columns: [
        { data: 'receptionSeries', width: '25%' },
        { data: 'invoiceNumber', width: '25%' },
        { data: 'deadline', width: '20%' },
        { data: 'supplier', width: '15%' },
        { data: 'totalValueWithVat', width: '15%' }
      ],
      order: [[1, 'asc']],
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
  }

  public doGetAll(dataTablesParameters: ActiveDataTableParameters, callback: any, withReset: boolean) {
    this.sandbox.callback = callback;
    this.sandbox.dataTableParameters = dataTablesParameters;
    this.getsupplyUnpaidInvoiceListAP.execute(dataTablesParameters, withReset);
  }

  onPageChange(currentPage: number) {
    this.sandbox.dataTableParameters.pageNumber = currentPage;
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
  }
}
