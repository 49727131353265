import {EventWithScreenLoading, UIGeneratedEvent} from './base.events';
import {AsyncJob} from '../../app-dto/core.dto';

export class RegisterAsyncJobEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = 'RegisterAsyncJobEvent';
  constructor(public model: AsyncJob, public callback: (data: AsyncJob) => void) {
    super();
    this.type += RegisterAsyncJobEvent.identifier;
  }
}
