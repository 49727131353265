import { BaseDI } from 'src/app/core/shared/base/base.di';
import {ServicePlatform_Dto} from "../../../core/app-dto/service-platform.dto";
import {Mechanic_Dto, MechanicWithActionsAndCar_Dto} from "../../../core/app-dto/mechanic.dto";
import {isValidArrayAndHasElements, isValidObject} from "../../../core/shared/helpers/common.helpers";

export class MechanicWithStatusAndActionDI extends BaseDI<MechanicWithActionsAndCar_Dto> {
  constructor(data: any) {
    super(new MechanicWithActionsAndCar_Dto(data));
  }


  getModel(): MechanicWithActionsAndCar_Dto {
    return this.model;
  }

  getActionsForCarEntryId(id:string){
    if(isValidArrayAndHasElements(this.model.actions)){
      return this.model.actions.filter(f=>f.carServiceEntryId == id);
    }
    return [];
  }
}
