import {BaseFormActionProxy} from '../../base/base.action-proxy';
import {IWebFormComponent} from '../../base/base.component';
import {Store} from '@ngrx/store';
import * as store from '../../../app-store';
import {Observable} from 'rxjs';
import {DoGenerateReceiptEvent} from '../../../app-store/events/receipt.events';

export class GenerateReceiptActionProxy extends BaseFormActionProxy {
    constructor(component: IWebFormComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        this.component.markFormAsDirtyAndTouched();
        return this.component.di.isValid();
    }

    execute(invoiceId: string): any {
        if (this.canExecute()) {
            this.component.markFormAsNotDirty();
            const model = this.component.di.getModel();
            this.appState.dispatch(new DoGenerateReceiptEvent(model, invoiceId));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return undefined;
    }
}
