import { BaseDI } from 'src/app/core/shared/base/base.di';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';
import {CarActionDiHelpers} from "../../common/di/car-action.di.helpers";

export class CarServiceDetailsDi extends BaseDI<CarService_Dto> {
    constructor(data: CarService_Dto = new CarService_Dto(null)) {
        super(data);
    }

    getModel(): CarService_Dto {
        return this.model;
    }

  getCalendarEventStatus(){
    return CarActionDiHelpers.getCalendarEventStatus(this.model.status);
  }
}
