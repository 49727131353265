import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { Mechanic_Dto } from '../../../core/app-dto/mechanic.dto';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { min, validEndDate } from 'src/app/core/shared/form/custom-form.validator';
import * as moment from 'moment';
import {YesNoComponent, YesNoComponentInput} from '../../../core/shared/components/yes-no/yes-no.component';

export class SaveMechanicDI extends BaseFormDI<Mechanic_Dto> {

  public endDate: Date = null;
  public isPaidUsingPercentageInput: YesNoComponentInput;
  constructor(data: Mechanic_Dto = new Mechanic_Dto(null)) {
    super(new Mechanic_Dto(data));
    if (this.model.startDate == null)
      this.model.startDate = new Date();
    if (this.model.history && this.model.history.length > 1) {
      this.endDate = this.model.history[this.model.history.length - 2].endDate;
    }
    this.initForm();
    this.isPaidUsingPercentageInput = new YesNoComponentInput(this.model.isPaidUsingPercentageValue, !this.model.isPaidUsingPercentageValue,
      "Salariu procentual?", this.form,"isPaidUsingPercentageValue");
  }

  getModel(): Mechanic_Dto {
    this.model.firstName = this.form.value.firstName;
    this.model.lastName = this.form.value.lastName;
    this.model.birthday = this.form.value.birthday;
    this.model.salary = this.form.value.salary;
    this.model.currency = this.form.value.currency;
    this.model.mechanicSpecializationType = this.form.value.mechanicSpecializationType;
    this.model.departments = this.form.value.departments;
    this.model.isPaidUsingPercentageValue = this.form.get(this.isPaidUsingPercentageInput.formGroupName).get(YesNoComponent.YES_CONTROL_NAME).value || false;

    if (this.form.value.startDate != null) {
      this.model.startDate = moment.utc([this.form.value.startDate.getFullYear(), this.form.value.startDate.getMonth(), this.form.value.startDate.getDate(), 0, 0, 0]).toDate();
    }

    return this.model;
  }

  initForm() {
    let salary: number = this.model.salary;
    if (this.model.isPaidUsingPercentageValue) salary = this.model.salaryAsPercentage;

    this.form = new FormGroup({
      firstName: new FormControl(this.model.firstName, Validators.compose([Validators.required])),
      lastName: new FormControl(this.model.lastName, Validators.compose([Validators.required])),
      birthday: new FormControl(this.model.birthday || new Date(), Validators.compose([Validators.required])),
      salary: new FormControl(salary, Validators.compose([Validators.required, min(0)])),
      currencySelectedValue: new FormControl(this.model.currency, Validators.compose([Validators.required])),
      departments: new FormControl(this.model.departments, Validators.compose([Validators.required])),
      startDate: new FormControl(this.model.startDate, Validators.compose([Validators.required, validEndDate(this.endDate)])),
    });
  }
}
