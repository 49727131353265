import {BaseFormDI} from 'src/app/core/shared/base/base.di';
import {Supply_Dto} from '../../../core/app-dto/supply.dto';

export class SaveSupplyDi extends BaseFormDI<Supply_Dto> {

  constructor(data: Supply_Dto = new Supply_Dto(null)) {
    super(new Supply_Dto(data));
    this.initForm();
  }

  getModel(): Supply_Dto {
    return this.model;
  }

  initForm() {
  }
}
