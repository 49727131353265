import {FileToUpload} from "../../app-dto/core.dto";
import { Action } from "@ngrx/store";
import {EventWithScreenLoading, EventWithStopingScreenLoader, SeamlessEvent} from "../events/base.events";

export interface UploadState {
  files: Array<FileToUpload>
};

export const INITIAL_STATE: UploadState = Object.assign({}, {
  files: []
});


export function reducer(state = INITIAL_STATE, action: Action): UploadState {
  if (!action) return state;
  var actionType = action.type;
  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  switch (actionType) {

    default: {
      return state;
    }
  }
}

export const getUploadItemCallback = (state: UploadState) => state.files;
