import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {Observable} from 'rxjs';
import {IWebComponent} from 'src/app/core/shared/base/base.component';
import {BaseActionProxy} from 'src/app/core/shared/base/base.action-proxy';
import {GetCarServiceEvent} from '../../../core/app-store/events/car-service.events';
import {
    AddOfferServiceCarPartEvent, DeleteOfferServiceCarPartEvent,
    GetOfferEvent,
    UpdateOfferCarDataEvent,
    UpdateOfferCarWorksEvent, UpdateOfferClientCarPartsEvent,
    UpdateOfferClientDataEvent, UpdateOfferServiceCarPartEvent
} from '../../../core/app-store/events/offer.events';
import {Offer_Dto} from '../../../core/app-dto/offer.dto';
import {CarPart_Dto} from '../../../core/app-dto/car-part.dto';

export class UpdateOfferDeleteServiceCarPartActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(offerId: string, data: CarPart_Dto): any {
        this.appState.dispatch(new DeleteOfferServiceCarPartEvent(offerId, data));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

