import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormGroup } from '@angular/forms';
import { DateTimeService } from 'src/app/core/core/services/date-time.service';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';

export class AddEditCarInServiceWizardDi extends BaseFormDI<CarService_Dto> {

  constructor(public dateTimeService: DateTimeService, data: CarService_Dto = new CarService_Dto(null)) {
    super(new CarService_Dto(data));

    this.initForm();
  }

  getModel(): CarService_Dto {
    this.model.appointmentDetails = this.model.appointmentDetails;
    return this.model;

  }

  initForm() {
    this.form = new FormGroup({
    });
  }
}
