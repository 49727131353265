import {ChangeDetectionStrategy, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActionsSubject, Store} from '@ngrx/store';
import {ActiveDataTableParameters} from '../../../core/shared/datatable/datatable.helpers';
import {BaseListWebComponent} from '../../../core/shared/base/base.component';
import {ResetSection, SeamlessEvent} from '../../../core/app-store/events/base.events';
import {ConfigService} from '../../../core/core/app-config.service';
import {Router} from '@angular/router';
import {SearchEvent} from '../../../core/app-store/events/index';
import {Subscription} from 'rxjs/Subscription';
import {DeleteDraftActionProxy} from './delete-draft.action-proxy';
import {DraftsSandbox} from './drafts.sandbox';
import {GetDraftActionProxy} from './get-draft.action-proxy';
import {DateHelpers} from '../../../core/shared/date.helpers';
import {ContinueDraftActionProxy} from "./continue-draft.action-proxy";
import {Draft_Dto} from "../../../core/app-dto/draft.dto";

@Component({
  selector: 'app-drafts',
  templateUrl: './drafts.component.html',
  styleUrls: ['./drafts.component.scss']
})
export class DraftsComponent extends BaseListWebComponent implements OnInit {
  public getDraftsAP: GetDraftActionProxy;
  public deleteDraftAP: DeleteDraftActionProxy;
  public continueDraftAP: ContinueDraftActionProxy;
  public isActive: boolean = true;
  public searchValue: string = '';
  private searchDraft = new Subscription();

  constructor(
      public sandbox: DraftsSandbox,
      private actionsSubj: ActionsSubject,
      private configService: ConfigService,
      public router: Router
  ) {
    super(sandbox, ResetSection.Drafts);
    this.getDraftsAP = new GetDraftActionProxy(this, sandbox.appState);
    this.deleteDraftAP = new DeleteDraftActionProxy(this, sandbox.appState);
    this.continueDraftAP = new ContinueDraftActionProxy(this, sandbox.appState);

    this.searchDraft = actionsSubj.subscribe(data => {
      if (data.type === SeamlessEvent.identifier + SearchEvent.identifier) {
        this.searchValue = (data as SearchEvent).value;
        this.dtApiInstance.search(this.searchValue).draw();
      }
    });
  }

  ngOnInit() {
    this.initialize(()=>{
      this.getDraftsAP.execute();
    });
    this.getDraftsAP.execute();
  }

  deleteDraft(draftId: string) {
    this.deleteDraftAP.execute(draftId);
  }

  continueDraft(draft: Draft_Dto) {
    this.continueDraftAP.execute(draft);
    this.deleteDraftAP.execute(draft.id);
  }

  dateToString(date: Date) {
    return DateHelpers.dateToString(date, 'DD/MM/YYYY, HH:mm');
  }

  getExpirationTime(date: Date) {
    return DateHelpers.getTimeUntilExpiration(date);
  }
}

