import {BaseFormDI} from 'src/app/core/shared/base/base.di';
import {Mechanic_Dto} from '../../../core/app-dto/mechanic.dto';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {validStartDate} from 'src/app/core/shared/form/custom-form.validator';
import {isValidObject} from 'src/app/core/shared/helpers/common.helpers';
import {StaticDataSelectorInput} from 'src/app/core/shared/components/static-data-selector/static-data-selector.component';
import {IdName_Dto} from 'src/app/core/app-dto/misc.dto';
import * as moment from 'moment';
import {YesNoComponentInput} from "../../../core/shared/components/yes-no/yes-no.component";

export class EditMechanicHistoryDI extends BaseFormDI<Mechanic_Dto> {

  public currenciesDataInput: Array<StaticDataSelectorInput> = [];
  public isPaidUsingPercentageInputs: Array<YesNoComponentInput> = [];

  constructor(data: Mechanic_Dto = new Mechanic_Dto(null)) {
    super(new Mechanic_Dto(data));
    this.initForm();
    if (isValidObject(this.model.history) && this.model.history.length > 0) {
      this.model.history.forEach((r, i) => {
        var startDateControl: FormControl;
        var endDateControl: FormControl;

        if (i < this.model.history.length - 1) {
          startDateControl = new FormControl(r.startDate, Validators.compose([Validators.required, validStartDate(r.endDate)]));
          endDateControl = new FormControl(r.endDate, Validators.compose([Validators.required, validStartDate(this.model.history[i + 1].startDate)]));
        } else {
          startDateControl = new FormControl(r.startDate, Validators.compose([Validators.required, validStartDate(r.endDate)]));
          endDateControl = new FormControl(r.endDate, Validators.compose([]));
        }
        let salary:number = r.salary;
        if(r.isPaidUsingPercentageValue) salary = r.salaryAsPercentage;
        (this.form.get('history') as FormArray).push(new FormGroup({
          startDate: startDateControl,
          endDate: endDateControl,
          salary: new FormControl(salary, Validators.compose([Validators.required])),
          currencySelectedValue: new FormControl(r.currency, Validators.compose([Validators.required]))
        }));
        this.currenciesDataInput.push({
          form: ((this.form.get('history') as FormArray).controls[i] as FormGroup),
          type: 'currencies',
          formControlName: 'currency',
          isRequired: true,
          label: 'Moneda',
          isReadOnly:false
        });
        this.isPaidUsingPercentageInputs.push(new YesNoComponentInput(r.isPaidUsingPercentageValue, !r.isPaidUsingPercentageValue,
          "Salariu procentual?", ((this.form.get('history') as FormArray).controls[i] as FormGroup), "isPaidUsingPercentageValue" + i.toString()));
      });
    }
  }

  getModel(): Mechanic_Dto {
    return this.model;
  }

  initForm() {
    this.form = new FormGroup({
      history: new FormArray([])
    });
  }

  updateStartDate(date: Date, i: number) {
    if (date != null) {
      this.model.history[i].startDate = moment.utc([date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0]).toDate();
    }
    this.validateGroup(i);
  }

  updateEndDate(date: Date, i: number) {
    if (date != null) {
      this.model.history[i].endDate = moment.utc([date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0]).toDate();
    }
    this.validateGroup(i);
  }

  updateSalary(salary: number, i: number) {
    this.model.history[i].salary = salary;
  }

  updateCurrency(currency: IdName_Dto, i: number) {
    this.model.history[i].currency = currency;
  }

  isPaidUsingPercentageChanged(isChecked: boolean, i: number) {
    this.model.history[i].isPaidUsingPercentageValue = isChecked;
  }

  validateGroup(i: number) {
    (this.form.get('history') as FormArray).controls[i].get('startDate').setValidators([]);
    (this.form.get('history') as FormArray).controls[i].get('endDate').setValidators([]);

    if (i == 0) {
      (this.form.get('history') as FormArray).controls[i].get('startDate').setValidators([Validators.required, validStartDate(this.model.history[i].endDate)]);
      (this.form.get('history') as FormArray).controls[i].get('endDate').setValidators([Validators.required, validStartDate(this.model.history[i + 1].startDate)]);

      (this.form.get('history') as FormArray).controls[i].get('startDate').updateValueAndValidity();
      (this.form.get('history') as FormArray).controls[i].get('endDate').updateValueAndValidity();
    } else if (i == this.model.history.length - 1) {
      (this.form.get('history') as FormArray).controls[i - 1].get('endDate').setValidators([Validators.required, validStartDate(this.model.history[i].startDate)]);

      (this.form.get('history') as FormArray).controls[i - 1].get('endDate').updateValueAndValidity();
    } else {
      (this.form.get('history') as FormArray).controls[i - 1].get('endDate').setValidators([Validators.required, validStartDate(this.model.history[i].startDate)]);
      (this.form.get('history') as FormArray).controls[i].get('startDate').setValidators([Validators.required, validStartDate(this.model.history[i].endDate)]);
      (this.form.get('history') as FormArray).controls[i].get('endDate').setValidators([Validators.required, validStartDate(this.model.history[i + 1].startDate)]);

      (this.form.get('history') as FormArray).controls[i - 1].get('endDate').updateValueAndValidity();
      (this.form.get('history') as FormArray).controls[i].get('startDate').updateValueAndValidity();
      (this.form.get('history') as FormArray).controls[i].get('endDate').updateValueAndValidity();
    }
  }
}
