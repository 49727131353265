import {Injectable} from "@angular/core";
import {BaseSandboxWithCurrentUserLoaded} from "../../../core/shared/base/base.sandbox";
import {select, Store} from "@ngrx/store";
import * as store from "../../../core/app-store";
import {Organization} from "../../../core/app-dto/organization.dto";
import {
    ActiveDataTableParameters,
    DataTableParameters,
    PagedResult
} from "../../../core/shared/datatable/datatable.helpers";
import {CarPartsListItemDi} from "../car-parts-list/car-parts-list-item.di";
import {Supplier_Dto} from "../../../core/app-dto/supplier.dto";
import {CarPart_Dto} from "../../../core/app-dto/car-part.dto";
import {ObjectValidators} from "../../../core/shared/object.validators";
import {getSupplyItemForCarPartPagedResult, getSupplyItemsTimestamp} from "../../../core/app-store";
import {SupplyItem_Dto} from "../../../core/app-dto/supply.dto";
import {SupplyItemDi} from "./supply-item.di";

@Injectable()
export class SupplyItemsSandbox extends BaseSandboxWithCurrentUserLoaded {
    public supplyItems$ = this.appState$.pipe(select(store.getSupplyItemForCarPartPagedResult));
    public timestamp$ = this.appState$.pipe(select(store.getSupplyItemsTimestamp));
    public totalNumberOfResults: number = null;
    public callback: any;
    public dataTableParameters: DataTableParameters;
    public resetTableCallback: Function;
    public lastRefreshTime: number = 0;

    public totalPages: number = 0;
    private draw: number = 1;
    public items:Array<SupplyItemDi> = [];

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
        this.draw = 1;
        this.lastRefreshTime = 0;
        this.items = [];
    }

    public initialize(): void {
        this.clearSandboxState();
        this.loadUser(()=>{

        });
        this.subscriptions.push(this.supplyItems$.subscribe((data: PagedResult<SupplyItem_Dto>) => {
            if (data != null && data.items != null) {
                this.items = data.items.map(r => new SupplyItemDi(r));
                this.totalNumberOfResults = data.totalItems;
                this.totalPages = data.totalPages;
                if (this.callback != null) {
                    this.callback({
                        draw: this.draw++,
                        recordsTotal: data.totalItems,
                        recordsFiltered: data.totalItems,
                        data: []
                    });
                }
            } else {
                this.items = [];
                this.draw = 1;
            }
        }));

        this.subscriptions.push(this.timestamp$.subscribe((result: number) => {
            if (result > this.lastRefreshTime) {
                this.lastRefreshTime = result;
                this.resetTable();
            }
        }));
    }

    public setResetTableCallback(callback: Function) {
        this.resetTableCallback = callback;
    }

    public resetTable() {
        if (this.resetTableCallback != null) {
            this.resetTableCallback();
        }
    }
}
