import {Base_Dto, BaseCRUDRules, BaseKeyNameEntity, BusinessRule, IdNameKey_Dto} from './misc.dto';
import {ObjectValidators} from '../shared/object.validators';
import {Car_Dto} from './car.dto';
import {OrganizationClient_Dto} from './organization-client.dto';
import {FileAttachment} from './core.dto';
import {CarPart_Dto, CarPartWithSupplyItemRef_Dto} from './car-part.dto';
import * as moment from 'moment';
import {Mechanic_Dto} from './mechanic.dto';
import {ServicePlatform_Dto} from './service-platform.dto';
import {isValidObject} from "../shared/helpers/common.helpers";
import {Workmanship_Dto} from './workmanship.dto';
import { Department_Dto } from './department.dto';
import { CarWork_Dto } from './car-work.dto';
import { CalendarEventState } from './calendar-event.dto';

export class CarService_Dto extends Base_Dto {
  public car: Car_Dto;
  public client: OrganizationClient_Dto;
  public carServiceEntryData: CarServiceEntryData_Dto;
  public initialFiles: Array<FileAttachment>;
  public serviceFiles: Array<FileAttachment>;
  public clientCarParts: Array<ClientCarParts_Dto>;
  public serviceCarParts: Array<CarPartWithSupplyItemRef_Dto>;
  public workManShips: Array<Workmanship_Dto>;
  public status: BaseKeyNameEntity;
  public rules: CarServiceEntryRules;
  public organizationId: string;
  public computedTotalPrice: number;
  public internalNumber: number;
  public createdOn: Date;
  public updatedOn: Date;
  public finishedDate: Date;
  public finalInspection: CarServiceFinalInspection_Dto;
  public appointmentDetails: string;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {

      if (data.appointmentDetails != null)
        this.appointmentDetails = data.appointmentDetails;

      if (ObjectValidators.isValidObject(data.car))
        this.car = new Car_Dto(data.car);
      else{
        this.car = new Car_Dto(null);
      }
      if (ObjectValidators.isValidObject(data.client))
        this.client = new OrganizationClient_Dto(data.client);
      if (ObjectValidators.isValidObject(data.carServiceEntryData))
        this.carServiceEntryData = new CarServiceEntryData_Dto(data.carServiceEntryData);
      if (ObjectValidators.isValidArray(data.initialFiles))
        this.initialFiles = data.initialFiles.map(r => new FileAttachment(r));
      if (ObjectValidators.isValidArray(data.serviceFiles))
        this.serviceFiles = data.serviceFiles.map(r => new FileAttachment(r));
      if (ObjectValidators.isValidArray(data.clientCarParts))
        this.clientCarParts = data.clientCarParts.map(r => new ClientCarParts_Dto(r));
      if (ObjectValidators.isValidArray(data.serviceCarParts))
        this.serviceCarParts = data.serviceCarParts.map(r => new CarPartWithSupplyItemRef_Dto(r));
      if (ObjectValidators.isValidArray(data.workManShips))
        this.workManShips = data.workManShips.map(r => new Workmanship_Dto(r));
      if (ObjectValidators.isValidObject(data.status))
        this.status = new BaseKeyNameEntity(data.status);
      if (ObjectValidators.isValidObject(data.rules))
        this.rules = new CarServiceEntryRules(data.rules);
      if (ObjectValidators.isValidObject(data.finalInspection))
        this.finalInspection = new CarServiceFinalInspection_Dto(data.finalInspection);
      else
        this.finalInspection = new CarServiceFinalInspection_Dto(null);
      this.organizationId = data.organizationId;
      this.computedTotalPrice = data.computedTotalPrice;
      this.internalNumber = data.internalNumber;

      if (data.carServiceEntryData.appointmentDetails != null)
        this.appointmentDetails = data.carServiceEntryData.appointmentDetails;


      if (ObjectValidators.isValidObject(data.createdOn))
        this.createdOn = moment(data.createdOn).toDate();
      if (ObjectValidators.isValidObject(data.updatedOn))
        this.updatedOn = moment(data.updatedOn).toDate();
      if (ObjectValidators.isValidObject(data.finishedDate))
        this.finishedDate = moment(data.finishedDate).toDate();
    } else {
      this.rules = new CarServiceEntryRules(null);
      this.car = new Car_Dto(null);
      this.client = new OrganizationClient_Dto(null);
      this.carServiceEntryData = new CarServiceEntryData_Dto(null);
      this.finalInspection = new CarServiceFinalInspection_Dto(null);
      this.initialFiles = [];
      this.serviceFiles = [];
      this.serviceCarParts = [];
      this.clientCarParts = [];
      this.status = null;
    }
  }
}

export class CarServiceEntryData_Dto extends Base_Dto {
  public actualNoOfKm: number;
  public fuel: number;
  public clientCarWorksRequests: Array<CarWork_Dto>;
  public serviceCarWorksRecomandations: Array<CarWork_Dto>;
  public clientRequests: string;
  public priceEstimate: string;
  public priceEstimateNumber: number;
  public priceEstimateCurrency: string;
  public deadline: string;
  public serviceRecomandations: string;
  public paymentInAdvance: number = 0;
  public paymentInAdvanceCurrency: string;
  public estimatedDeadlineType: IdNameKey_Dto;
  public deadlineNumber: number;
  public appointmentDetails: string;
  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.appointmentDetails = data.appointmentDetails;
      this.actualNoOfKm = data.actualNoOfKm;
      this.fuel = data.fuel;
      this.clientCarWorksRequests = data.clientCarWorksRequests;
      this.serviceCarWorksRecomandations = data.serviceCarWorksRecomandations;
      this.priceEstimate = data.priceEstimate;
      this.deadline = data.deadline;
      this.clientRequests = data.clientRequests;
      this.serviceRecomandations = data.serviceRecomandations;
      this.paymentInAdvance = data.paymentInAdvance;
      this.paymentInAdvanceCurrency = data.paymentInAdvanceCurrency;
      if (isValidObject(data.estimatedDeadlineType))
        this.estimatedDeadlineType = new IdNameKey_Dto(data.estimatedDeadlineType);
      this.deadlineNumber = data.deadlineNumber;
      this.priceEstimateNumber = data.priceEstimateNumber;
      this.priceEstimateCurrency = data.priceEstimateCurrency;
    }
  }
}

export class ClientCarParts_Dto extends Base_Dto {
  public name: string;
  public code: string;
  public quantity: number;
  public clientId: string;
  public um: string;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.name = data.name;
      this.code = data.code;
      this.quantity = data.quantity;
      this.clientId = data.clientId;
      this.um = data.um;
    }
  }
}

export class CarServiceEntryRules extends BaseCRUDRules {
  public canChangeInitialImages: BusinessRule;
  public canManageClientCarParts: BusinessRule;
  public canManageServiceClientParts: BusinessRule;
  public canManageDocuments: BusinessRule;
  public canManageActions: BusinessRule;
  public canManageActionsWithAllInfo: BusinessRule;
  public canAddActions: BusinessRule;
  public canFinishCar: BusinessRule;
  public finalInspectionDone: BusinessRule;
  public canGenerateInvoice:BusinessRule;
  public canViewFinancialInfo :BusinessRule;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.canChangeInitialImages = new BusinessRule(data.canChangeInitialImages);
      this.canManageClientCarParts = new BusinessRule(data.canManageClientCarParts);
      this.canManageServiceClientParts = new BusinessRule(data.canManageServiceClientParts);
      this.canManageDocuments = new BusinessRule(data.canManageDocuments);
      this.canManageActions = new BusinessRule(data.canManageActions);
      this.canManageActionsWithAllInfo = new BusinessRule(data.canManageActionsWithAllInfo);
      this.canAddActions = new BusinessRule(data.canAddActions);
      this.canFinishCar = new BusinessRule(data.canFinishCar);
      this.finalInspectionDone = new BusinessRule(data.finalInspectionDone);
      this.canGenerateInvoice = new BusinessRule(data.canGenerateInvoice);
      this.canViewFinancialInfo = new BusinessRule(data.canViewFinancialInfo);
    }
    else {
      this.canChangeInitialImages = new BusinessRule();
      this.canManageClientCarParts = new BusinessRule();
      this.canManageServiceClientParts = new BusinessRule();
      this.canManageDocuments = new BusinessRule();
      this.canManageActions = new BusinessRule();
      this.canAddActions = new BusinessRule();
      this.canFinishCar = new BusinessRule();
      this.finalInspectionDone = new BusinessRule();
      this.canGenerateInvoice = new BusinessRule();
      this.canViewFinancialInfo = new BusinessRule();
    }
  }
}

export class CarActionRules extends BaseCRUDRules {
  public canViewActualDuration : BusinessRule;
  public canManageCommonInfo : BusinessRule;
  public canViewFinancialInfo : BusinessRule;
  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.canViewActualDuration = new BusinessRule(data.canViewActualDuration);
      this.canManageCommonInfo = new BusinessRule(data.canManageCommonInfo);
      this.canViewFinancialInfo = new BusinessRule(data.canViewFinancialInfo);
    } else {
      this.canViewActualDuration = new BusinessRule();
      this.canManageCommonInfo = new BusinessRule();
      this.canViewFinancialInfo = new BusinessRule();
    }
  }
}

export class CarAction_Dto extends Base_Dto {
  public createdOn: Date;

  public carServiceEntryId: string;
  public action: string;
  public mechanic: Mechanic_Dto;
  public department: Department_Dto;
  public carWork: CarWork_Dto;
  public duration: number = 0.0;
  public actualDuration: number = 0.0;
  public status: BaseKeyNameEntity;
  public date: Date;
  public endDate: Date;
  public servicePlatform: ServicePlatform_Dto;

  public rules: CarActionRules;

  public price: number = 0.0;
  public priceHasVAT: boolean = false;
  public hasPrice: boolean = false;

  public clearPlatform: boolean = false;

  public totalValue: number;
  public totalValueWithVAT: number;
  public currency: string;
  public observation: string;
  public waitingStatusReason: string;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.carServiceEntryId = data.carServiceEntryId;
      if (ObjectValidators.isValidObject(data.createdOn))
        this.createdOn = moment(data.createdOn).toDate();
      if (ObjectValidators.isValidObject(data.endDate))
        this.endDate = moment(data.endDate).toDate();
      this.action = data.action;
      if (ObjectValidators.isValidObject(data.mechanic))
        this.mechanic = new Mechanic_Dto(data.mechanic);
      if (ObjectValidators.isValidObject(data.department))
        this.department = new Department_Dto(data.department);
      if (ObjectValidators.isValidObject(data.servicePlatform))
        this.servicePlatform = new ServicePlatform_Dto(data.servicePlatform);
      if (ObjectValidators.isValidObject(data.carWork))
        this.carWork = new CarWork_Dto(data.carWork);
      this.duration = data.duration;
      this.actualDuration = data.actualDuration;
      this.price = data.price;
      this.hasPrice = data.hasPrice;
      this.priceHasVAT = data.priceHasVAT;
      this.totalValue = data.totalValue;
      this.totalValueWithVAT = data.totalValueWithVAT;
      this.observation = data.observation;
      this.waitingStatusReason = data.waitingStatusReason;
      this.currency = data.currency;
      if (ObjectValidators.isValidObject(data.status))
        this.status = new BaseKeyNameEntity(data.status);
      if (ObjectValidators.isValidObject(data.date))
        this.date = moment(data.date).toDate();
      if (ObjectValidators.isValidObject(data.rules))
        this.rules = new CarActionRules(data.rules);
    } else {
      this.rules = new CarActionRules(null);
    }
  }
}

export class CarServiceEntryActionListChanged_Dto {
  public carServiceEntry: CarService_Dto;
  public action: CarAction_Dto;

  constructor(data: any) {
    if (isValidObject(data)) {
      this.carServiceEntry = new CarService_Dto(data.carServiceEntry);
      this.action = new CarAction_Dto(data.action);
    }
  }
}


export class CarServiceEntryStatistics {
  public weeklyCount: number = 0;
  public weeklyValue: number = 0;
  public monthlyCount: number = 0;
  public monthlyValue: number = 0;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      this.weeklyValue = data.weeklyValue;
      this.weeklyCount = data.weeklyCount;
      this.monthlyCount = data.monthlyCount;
      this.monthlyValue = data.monthlyValue;
    }
  }
}

export class CarServiceFinalInspection_Dto {
  public brakes: string;
  public hazards: string;
  public functional: string;
  public geometry: string;
  public windscreen: string;
  public roadTest: string;
  public headlights: string;
  public startUser: FinalInspectionUser_Dto;
  public endUser: FinalInspectionUser_Dto;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      this.brakes = data.brakes;
      this.hazards = data.hazards;
      this.functional = data.functional;
      this.geometry = data.geometry;
      this.windscreen = data.windscreen;
      this.roadTest = data.roadTest;
      this.headlights = data.headlights;
      if (ObjectValidators.isValidObject(data.startUser))
        this.startUser = new FinalInspectionUser_Dto(data.startUser);
      if (ObjectValidators.isValidObject(data.endUser))
        this.endUser = new FinalInspectionUser_Dto(data.endUser);
    }
  }
}

export class FinalInspectionUser_Dto {
  public id: string;
  public name: string;
  public type: FinalInspectionUserType;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      this.id = data.id;
      this.name = data.name;
      this.type = data.type;
    }
  }
}


export enum FinalInspectionUserType {
  User,
  Mechanic
}
