import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { CarService_Dto, FinalInspectionUser_Dto } from '../../../core/app-dto/car-service.dto';
import { CarServiceSaveFinalInspectionDi } from './car-service-save-final-inspection.di';
import { CarServiceSaveFinalInspectionActionProxy } from './car-service-save-final-inspection.action-proxy';
import { CarServiceSaveFinalInspectionSandbox } from './car-service-save-final-inspection.sandbox';
import { GetCarServiceUsersActionProxy } from './get-car-service-users.action-proxy';

@Component({
  selector: 'app-car-service-save-final-inspection',
  templateUrl: './car-service-save-final-inspection.component.html',
  styleUrls: ['./car-service-save-final-inspection.component.scss']
})
export class CarServiceSaveFinalInspectionComponent extends BaseFormModelComponent<CarService_Dto> implements OnInit, OnDestroy {
  public editCarServiceClientAP: CarServiceSaveFinalInspectionActionProxy;
  public getCarServiceActionsAP: GetCarServiceUsersActionProxy;

  public onClose: Subject<boolean>;
  public selectItems: Array<string>;
  public users: Array<FinalInspectionUser_Dto>;

  constructor(public appState: Store<store.State>,
    public sandbox: CarServiceSaveFinalInspectionSandbox,
    private activeModal: BsModalRef,
    public activatedRoute: ActivatedRoute) {
    super(sandbox);
    this.editCarServiceClientAP = new CarServiceSaveFinalInspectionActionProxy(this, sandbox.appState);
    this.getCarServiceActionsAP = new GetCarServiceUsersActionProxy(this, sandbox.appState);
    this.getCarServiceActionsAP.execute();
    this.di = new CarServiceSaveFinalInspectionDi();
  }

  public diInitialize(data: CarService_Dto) {
    this.selectItems = ['C – corespunzator', 'N – necorespunzator'];
    this.di = new CarServiceSaveFinalInspectionDi(data);
    this.sandbox.initialize((data: Array<FinalInspectionUser_Dto>) => {
      this.users = data;
      this.dataLoaded = true;
    });
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.formSubmitted = true;
    if (this.editCarServiceClientAP.canExecute()) {
      this.editCarServiceClientAP.execute();
      this.onClose.next(true);
      this.activeModal.hide();
    }
  }
}
