import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { ConfigService } from "../../../core/app-config.service";
import { FileAttachment, FileToUpload, FileUploadTarget, LoggedUser_Dto } from "../../../app-dto/core.dto";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CachingUtils } from "../../../shared/caching.utils";
import { ObjectValidators } from "../../../shared/object.validators";

declare var AzureStorage: any;


export interface ISaasResponse {
  container: string;
  storageUrl: string;
  saSToken: string;
  saSUrl: string;
  uuId: string;
  id: string;
  text: string;
  extension: string;
  destinationContainer: string;
  pathToDestinationContainer: string;
}

export interface Req_SaasUrl {
  fileName: string;
  size: number;
  purpose: FileUploadTarget;
  relatedEntityId: string;
}


@Injectable()
export class AzureUploaderService {

  //we will allow only one upload at a time

  private finishedOrError = false;

  private cancelFilter: any = null;

  public constructor(
    protected http: HttpClient,
    protected configService: ConfigService, ) {
  }

  cancel() {
    if (this.cancelFilter != null) {
      this.cancelFilter.cancel = true;
    }
  }

  uploadToStorage(file: FileToUpload): Observable<number> {
    this.finishedOrError = false;
    const progress$ = new Subject<number>();
    this.refreshProgress.bind(this);
    
    var sasResponse = this.getSaasUrl({
      fileName: file.file.name,
      size: file.file.size,
      purpose: file.purpose,
      relatedEntityId: file.relatedEntityId
    }).subscribe((data) => {
      if (data == null) {
        progress$.next(-1);
        return progress$.asObservable();
      }
      console.log("sasResponse: " + NgZone.isInAngularZone());
      file.uuId = data.id;

      var uploadResponse = this.uploadFile(data, file.file, progress$);
      if (uploadResponse != null) {
        this.refreshProgress(uploadResponse.speedSummary, progress$);
      }
    });
    return progress$.asObservable();
  }

  private getSaasUrl(data: Req_SaasUrl): Observable<ISaasResponse> {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', });
    this.populateAuthorizationHeaderIfExists(headers);
    let url = this.getBaseUrl() + `/api/file/start-upload-task`;

    return this.http.post(url, data, { headers: headers })
      .map(res => {
        return <ISaasResponse>res;
      })
      .catch((error: any) => {
        //this.responseHandler.onCatch(error, null);
        return new Observable((observer) => {
          observer.next();
          //observer.next(null);
        })
      });
  }

  public publishDocument(data: string): Observable<any> {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', });
    this.populateAuthorizationHeaderIfExists(headers);
    let url = this.getBaseUrl() + `/api/file/publish-upload-task/` + data;
    return this.http.get(url, { headers: headers })
      .map(res => {
        return res;
      })
      .catch((error: any) => {
        //this.responseHandler.onCatch(error, null);
        return new Observable((observer) => {
          observer.next();
          //observer.next(null);
        })
      });
  }

  protected populateAuthorizationHeaderIfExists(headers: any) {
    let user: any = CachingUtils.loadData(LoggedUser_Dto.identifier);
    if (ObjectValidators.isValidObject(user) && ObjectValidators.isValidObject(user.token)) {
      headers.append("Authorization", "Bearer " + user.token.access_token);
    }
  }

  protected getBaseUrl(): string {
    return this.configService.getAPIBaseUrl();
  }

  private uploadFile(saasResponse: ISaasResponse, file: File, progress$: Subject<number>): any {
    
    var sasToken = saasResponse.saSToken;//.substring(1);
    this.cancelFilter = {
      cancel: false,              // Set to true to cancel an in-progress upload
      loggingOn: true,            // Set to true to see info on console
      onCancelComplete: null,     // Set to a function you want called when a cancelled request is (probably?) complete,
      // i.e. when returnCounter==nextCounter. Because when you cancel an upload it can be many
      // seconds before the in-progress chunks complete.

      // Internal from here down
      nextCounter: 0,             // Increments each time next() is called. a.k.a. 'SentChunks' ?
      returnCounter: 0,           // Increments each time next()'s callback function is called. a.k.a. 'ReceivedChunks'?
      handle: function (requestOptions, next) {
        var self = this;
        if (self.cancel) {
          self.log('cancelling before chunk sent');
          return;
        }
        if (next) {
          self.nextCounter++;
          next(requestOptions, function (returnObject, finalCallback, nextPostCallback) {
            self.returnCounter++;
            if (self.cancel) {
              self.log('cancelling after chunk received');
              if (self.nextCounter == self.returnCounter && self.onCancelComplete) {
                self.onCancelComplete();
              }

              // REALLY ??? Is this the right way to stop the upload?
              return;

            }
            if (nextPostCallback) {
              nextPostCallback(returnObject);
            } else if (finalCallback) {
              finalCallback(returnObject);
            }
          });
        }
      },
      log: function (msg) {
        if (this.loggingOn) {
          console.log('cancelUploadFilter: ' + msg + ' nc: ' + this.nextCounter + ', rc: ' + this.returnCounter);
        }
      },
    };

    var url = saasResponse.storageUrl;
    if (ObjectValidators.isValidString(saasResponse.pathToDestinationContainer))
      url += '/' + saasResponse.pathToDestinationContainer;
    var fileService = AzureStorage.Blob.createBlobServiceWithSas(url, sasToken).withFilter(this.cancelFilter);
    console.log("uploadFile: " + NgZone.isInAngularZone());
    var self = this;
    
    var serviceResponse = fileService.createBlockBlobFromBrowserFile(saasResponse.destinationContainer, saasResponse.id + saasResponse.extension, file, {}, (error: any, result: any, response: any) => {
      this.finishedOrError = true;
      console.log("uploadFile serviceResponse: " + NgZone.isInAngularZone());
      if (error) {
        progress$.error('Error uploading to storage: ' + JSON.stringify(saasResponse));
      } else {
        progress$.next(100);
        progress$.complete();
      }
    });
    return {
      speedSummary: serviceResponse
    };
  }

  private refreshProgress(speedSummary: any, progress$: Subject<number>): void {
    setTimeout(() => {

      if (!this.finishedOrError) {
        var progress = speedSummary.getCompletePercent();
        if (progress != 100) {
          progress$.next(parseFloat(progress));
          this.refreshProgress(speedSummary, progress$);
        }
      }
    }, 200);
  }
}
