import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { InvoiceDetails_Dto } from 'src/app/core/app-dto/invoice.dto';
import {Router} from '@angular/router';

@Injectable()
export class InvoiceSandbox extends BaseSandbox {
    public getInvoice$ = this.appState$.pipe(select(store.getInvoice));
    public getReceiptId$ = this.appState$.pipe(select(store.getReceiptId));

    constructor(
        protected appState$: Store<store.State>,
        private router: Router
    ) {
        super(appState$);
    }

    clearSandboxState() {
    }

    initialize(subscription: (data: InvoiceDetails_Dto) => {}) {
        this.clearSandboxState();

        this.subscriptions.push(this.getInvoice$.subscribe((data: InvoiceDetails_Dto) => {
            if (ObjectValidators.isValidObject(data)) {
                subscription(data);
            }
        }));

        this.subscriptions.push(this.getReceiptId$.subscribe((data: string) => {
            if (ObjectValidators.isValidString(data)) {
                this.router.navigate(['/receipt', data, 'details']);
            }
        }));
    }
}
