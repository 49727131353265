import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { ApiErrorDto } from '../utils/api-error.dto';
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { DataTableParameters, PagedResult} from '../../shared/datatable/datatable.helpers';
import {Audit_Dto} from "../../app-dto/audit.dto";
import { ErrorHelpers } from '../../shared/error.helpers';
import {Organization_Dto} from '../../app-dto/super-admin-logs.dto';

class AuditApiRoutes {
  public static route_for_GET_AUDIT_PAGINATED() {
    return '/api/audit';
  }
}

@Injectable()
export class AuditApiService extends BaseApiService {

  constructor(private http: HttpClient, configService: ConfigService) {
    super(configService);
  }

  u_getFilteredAuditPaginated(data: DataTableParameters): Observable<PagedResult<Audit_Dto>> {
    const url = this.configService.getAPIBaseUrl() + AuditApiRoutes.route_for_GET_AUDIT_PAGINATED() + '?' + data.toUrlParameters();

    return this.http.get(url)
      .map((res: PagedResult<Audit_Dto>) => {
        return new PagedResult<Audit_Dto>(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }
}
