import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {BaseFormModelComponent} from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { ActivatedRoute } from '@angular/router';
import { EditCarServiceCarWorksSandbox } from './edit-car-service-car-works.sandbox';
import {EditCarServiceCarWorksDi} from './edit-car-service-car-works.di';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap';
import {ObjectValidators} from '../../../core/shared/object.validators';
import { EditCarServiceCarWorksActionProxy } from './edit-car-service-car-works.action-proxy';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';
import { OrganizationClientFormComponent } from '../../../organization-client/components/organization-client-form/organization-client-form.component';
import { CarWork_Dto } from '../../../core/app-dto/car-work.dto';


@Component({
  selector: 'app-edit-car-service-car-works',
  templateUrl: './edit-car-service-car-works.component.html',
  styleUrls: ['./edit-car-service-car-works.component.scss']
})
export class EditCarServiceCarWorksComponent extends BaseFormModelComponent<CarService_Dto> implements OnInit, OnDestroy {
  public editCarServiceCarWorksAP: EditCarServiceCarWorksActionProxy;
  public onClose: Subject<boolean>;

  // @ViewChild(OrganizationClientFormComponent, { static: false })
  // private clientFormComponent: OrganizationClientFormComponent;

  constructor(public appState: Store<store.State>,
              public sandbox: EditCarServiceCarWorksSandbox,
              private activeModal: BsModalRef,
              public activatedRoute: ActivatedRoute) {
    super(sandbox, ResetSection.CarService);
    this.editCarServiceCarWorksAP = new EditCarServiceCarWorksActionProxy(this, sandbox.appState);
    this.di = new EditCarServiceCarWorksDi();
  }

  public diInitialize(data: CarService_Dto) {
    this.di = new EditCarServiceCarWorksDi(data);
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.formSubmitted = true;
    if (this.editCarServiceCarWorksAP.canExecute()) {
      this.editCarServiceCarWorksAP.execute();
      this.onClose.next(true);
      this.activeModal.hide();
    }
  }

  recommendedCarWorksSelected(data: Array<CarWork_Dto>) {
    if (ObjectValidators.isValidObject(data)) {
      this.di.model.carServiceEntryData.serviceCarWorksRecomandations = data;
    }
  }
}
