import { Component, OnInit } from '@angular/core';
import {BaseWebComponent} from "../../../core/shared/base/base.component";
import {GetStatisticsActionProxy} from "../../../calendar/components/statistics/get-statistics.action-proxy";
import {StatisticsDI} from "../../../calendar/components/statistics/statistics.di";
import {Store} from "@ngrx/store";
import * as store from "../../../core/app-store";
import {NgxSpinnerService} from "ngx-spinner";
import {CalendarStatistics} from "../../../core/app-dto/calendar-event.dto";
import {GetCarServiceEntryStatisticsActionProxy} from "./get-car-service-entry-statistics.action-proxy";
import {CarServiceEntryStatistics} from "../../../core/app-dto/car-service.dto";
import {CarServiceStatisticsDI} from "./car-service-statistics.di";
import {CarServiceStatisticsSandbox} from "./car-service-statistics.sandbox";
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";

@Component({
  selector: 'app-car-service-statistics',
  templateUrl: './car-service-statistics.component.html',
  styleUrls: ['./car-service-statistics.component.scss'],
})
export class CarServiceStatisticsComponent extends BaseWebComponent implements OnInit {
  public getDataAP: GetCarServiceEntryStatisticsActionProxy;
  public data: CarServiceStatisticsDI = null;


  constructor(
    public appState: Store<store.State>,
    private spinner: NgxSpinnerService,
    public sandbox:CarServiceStatisticsSandbox,
    public rulesProvider : AppUserBusinessRuleProvider
  ) {
    super(sandbox,null);
    this.getDataAP = new GetCarServiceEntryStatisticsActionProxy(this, appState, this.spinner);
  }

  ngOnInit() {
    this.sandbox.initialize();
    this.sandbox.loadUser(()=>{});
    this.getDataAP.execute((data:CarServiceEntryStatistics)=>{
      this.data = new CarServiceStatisticsDI(data);
    });

  }
}
