import {Injectable} from '@angular/core';
import {BaseApiService} from './base-api.service';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../core/app-config.service';
import {Observable} from 'rxjs';
import {ApiErrorDto} from '../utils/api-error.dto';
import {ErrorHelpers} from '../../shared/error.helpers';
import {CreateReceipt_Dto, Receipt_Dto} from '../../app-dto/receipt.dto';
import {PagedResult, ReceiptDataTableParameters} from '../../shared/datatable/datatable.helpers';
import {FileAttachment} from '../../app-dto/core.dto';

class ReceiptPrintJobApiRoutes {
    public static route_for_POST_RECEIPT(id: string) {
        return '/api/receipt/' + id;
    }
    public static route_for_GET_ORGANIZATION_RECEIPTS_PAGINATED(id: string) {
        return '/api/receipt/' + id + '/all/paginated';
    }
    public static route_for_RETRIEVE_DOWNLOAD_LINK_FOR_ORGANIZATION_RECEIPT(id: string) {
        return '/api/receipt/' + id + '/download';
    }
}

@Injectable()
export class ReceiptPrintApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    pj_generateReceiptPrintJob(data: CreateReceipt_Dto, id: string): Observable<FileAttachment> {
        const url = this.configService.getAPIBaseUrl() + ReceiptPrintJobApiRoutes.route_for_POST_RECEIPT(id);

        return this.http.post(url, data)
            .map((res: any) => {
                return new FileAttachment(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getOrganizationFilteredReceiptsPaginated(data: ReceiptDataTableParameters, organizationId: string = null): Observable<PagedResult<Receipt_Dto>> {
        const url = this.configService.getAPIBaseUrl() + ReceiptPrintJobApiRoutes.route_for_GET_ORGANIZATION_RECEIPTS_PAGINATED(organizationId) + '?' + data.toUrlParameters();

        return this.http.get(url)
            .map((res: any) => {
                return new PagedResult<Receipt_Dto>(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getReceipt(receiptId: string): Observable<Receipt_Dto> {
        const url = this.configService.getAPIBaseUrl() + ReceiptPrintJobApiRoutes.route_for_POST_RECEIPT(receiptId);

        return this.http.get(url)
            .map((res: any) => {
                return new Receipt_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getDownloadLinkForOrganizationReceipt(receiptId: string): Observable<string> {
        const url = this.configService.getAPIBaseUrl() + ReceiptPrintJobApiRoutes.route_for_RETRIEVE_DOWNLOAD_LINK_FOR_ORGANIZATION_RECEIPT(receiptId);
        return this.http.get(url)
            .map((res: any) => {
                return res.data;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
}
