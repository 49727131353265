import { Store } from '@ngrx/store';
import * as store from '../../../app-store/index';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { GetCarServiceEvent } from '../../../app-store/events/car-service.events';
import {Invoice_Dto, InvoiceType} from "../../../app-dto/invoice.dto";
import {GetInvoiceForCarServiceEntry} from "../../../app-store/events/invoices.events";

export class GetCarServiceEntryInvoiceActionProxy extends BaseActionProxy {
  constructor(component: IWebComponent,
              appState: Store<store.State>) {
    super(component, appState);
  }

  canExecute(): boolean {
    return true;
  }

  execute(carServiceId: string, organizationId:string, invoiceType:InvoiceType, callback:(invoice:Invoice_Dto) =>void): any {
    this.appState.dispatch(new GetInvoiceForCarServiceEntry(carServiceId,organizationId, invoiceType, callback));
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }
}

