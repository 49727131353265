import {Component, Input, OnInit} from '@angular/core';
import {Offer_Dto} from '../../../core/app-dto/offer.dto';
import {UpdateOfferCarWorksComponent} from "../update-offer-car-works/update-offer-car-works.component";
import {BsModalService} from "ngx-bootstrap";

@Component({
  selector: 'app-offer-details-list-car-service-actions',
  templateUrl: './offer-details-list-car-service-actions.component.html',
  styleUrls: ['./offer-details-list-car-service-actions.component.scss']
})
export class OfferDetailsListCarServiceActionsComponent implements OnInit {


  @Input() offer: Offer_Dto;
  @Input() isReadOnlyValue: boolean = false;

  constructor(private modalService:BsModalService) {
  }

  ngOnInit() {
  }

    edit() {
        const modalRef = this.modalService.show(UpdateOfferCarWorksComponent, {backdrop: 'static', keyboard: false, class: 'modal-xl'});
        modalRef.content.dataInput = this.offer;
    }
}

