import {Base_Dto, BaseCRUDRules} from "./misc.dto";
import {isValidObject} from "../shared/helpers/common.helpers";
import {ObjectValidators} from "../shared/object.validators";
import * as moment from "moment";

export enum SMSTransactionType
{
  CarServiceInProgress,
  CarServiceReady,
  ITPReminder,
  InsuranceReminder,
  AppointmentReminder
}

export class SMSPackageType extends Base_Dto {
  public name: string;
  public description: string;
  public noOfSMS: number;
  public currency: string;
  public value: number;
  constructor(data:any){
    super(data);
    if(isValidObject(data)){
      this.name = data.name;
      this.description = data.description;
      this.noOfSMS = data.noOfSMS;
      this.currency = data.currency;
      this.value = data.value;
    }
  }
}



export class SMSBoughtTransaction extends Base_Dto {
  public organizationId: string;
  public createdOn: Date;
  public externalTransactionId: string;
  public registeredOn: Date;
  public value: number;
  public currency: string;
  public packageType: SMSPackageType;
  public isSuccess: boolean;
  public isBoughtBySuperAdmin: boolean;
  public stripeReceiptUrl : string;
  public rules: BaseCRUDRules;

  constructor(data:any){
    super(data);
    if(isValidObject(data)){
      this.organizationId = data.organizationId;
      this.stripeReceiptUrl = data.stripeReceiptUrl;
      this.externalTransactionId = data.externalTransactionId;
      this.value = data.value;
      this.currency = data.currency;
      if (ObjectValidators.isValidObject(data.registeredOn))
        this.registeredOn = moment.utc(data.registeredOn).toDate();
      if (ObjectValidators.isValidObject(data.createdOn))
        this.createdOn = moment.utc(data.createdOn).toDate();
      this.packageType = new SMSPackageType(data.packageType);
      this.isSuccess = data.isSuccess;
      this.isBoughtBySuperAdmin = data.isBoughtBySuperAdmin;
      this.rules = new BaseCRUDRules(data.rules);
    }
  }
}

export class SMSSentTransaction extends Base_Dto{
  public organizationId: string;
  public createdOn: Date;
  public type: SMSTransactionType;
  public message: string;
  public phoneNumber: string;
  public receiverName: string;
  public relatedEntityId: string;

  public rules: BaseCRUDRules;
  constructor(data:any){
    super(data);
    if(isValidObject(data)){
      this.organizationId = data.organizationId;
      this.type = data.type;
      this.message = data.message;
      this.phoneNumber = data.phoneNumber;
      if (ObjectValidators.isValidObject(data.createdOn))
        this.createdOn = moment.utc(data.createdOn).toDate();
      this.receiverName = data.receiverName;
      this.relatedEntityId = data.relatedEntityId;
      this.rules = new BaseCRUDRules(data.rules);
    }
  }
}
