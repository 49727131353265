import { BaseSandbox } from "../../../shared/base/base.sandbox";
import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import * as ApplicationStore from '../../../app-store/state';
import * as staticDataStore from '../../../app-store/state-selectors/static-data.selectors';
import * as authenticationStore from "../../../app-store/state-selectors/authentication.selectors";
import { BaseComponentState } from "../../../app-store/utils/base-component.state";
import {ConfigService} from "../../../core/app-config.service";
import {StaticData} from "../../../app-dto/static-data.dto";
import {DoAddStaticDataToState, DoLoadStaticData} from "../../../app-store/events/static-data.events";

@Injectable()
export class StaticDataSandbox extends BaseSandbox {

  public logout$ = this.appState$.select(authenticationStore.getLogout);
  public staticDataLoaded$ = this.appState$.select(staticDataStore.getStaticData);

  private version: string;
  constructor(
    protected appState$: Store<ApplicationStore.State>,
    private config: ConfigService
  ) {
    super(appState$);
  }

  private getKeyForStaticData() {
    return "static-data-" + this.version;
  }

  public clearSandboxState(){

  }

  public initialize(): void {
    this.version = this.config.get("staticDataVersion");
    var existingData = localStorage.getItem(this.getKeyForStaticData());
    if (existingData != null) {
      var appObject = JSON.parse(existingData);
      var staticData = new StaticData(appObject);
      if (staticData != null && staticData.isEmpty() == false) {

        this.appState$.dispatch(new DoAddStaticDataToState(staticData));
      } else {
        this.appState$.dispatch(new DoLoadStaticData());
      }
    } else {
      this.appState$.dispatch(new DoLoadStaticData());
    }

    this.subscriptions.push(this.staticDataLoaded$.subscribe((data: StaticData) => {
      if (data != null) {
        localStorage.setItem(this.getKeyForStaticData(), JSON.stringify(data));
      }
    }));

    this.subscriptions.push(this.logout$.subscribe((state: BaseComponentState) => {
      if (state.loaded) {
        localStorage.removeItem(this.getKeyForStaticData());
      }
    }));
  }

  public unsubscribe(): any {
    this.unregisterEvents();
  }

}
