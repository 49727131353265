import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseFormModelComponent} from '../../base/base.component';
import {Invoice_Dto} from '../../../app-dto/invoice.dto';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap';
import {ReceiptSandbox} from './receipt.sandbox';
import {ReceiptDi} from './receipt.di';
import {GenerateReceiptActionProxy} from './generate-receipt.action-proxy';
import {CreateReceipt_Dto} from '../../../app-dto/receipt.dto';
import {Validators} from '@angular/forms';

@Component({
    selector: 'app-receipt',
    templateUrl: './receipt.component.html',
    styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent extends BaseFormModelComponent<CreateReceipt_Dto> implements OnInit, OnDestroy {
    public onClose: Subject<boolean>;
    public di: ReceiptDi;
    public generateReceiptAP: GenerateReceiptActionProxy;
    public invoice: Invoice_Dto;

    constructor(
        public activeModal: BsModalRef,
        public sandbox: ReceiptSandbox
    ) {
        super(sandbox);
        this.di = new ReceiptDi(null);
        this.generateReceiptAP = new GenerateReceiptActionProxy(this, sandbox.appState);
    }

    public setData(invoice: Invoice_Dto) {
        this.di.form.get('representing').setValue('contravaloare factura ' + invoice.computedNumber + ' din ' +
            invoice.sentOn.getDate() + '/' + (invoice.sentOn.getMonth() + 1) + '/' + invoice.sentOn.getFullYear());
        this.dataLoaded = true;
        this.invoice = invoice;
    }

    public setValue(amount: number) {
        this.di.form.get('value').setValue(amount);
        this.di.form.get('value').setValidators(Validators.max(amount));
    }

    ngOnInit() {
        this.onClose = new Subject();
        this.initialize();
    }

    ngOnDestroy() {
        this.destroy();
    }

    public decline() {
        this.onClose.next(false);
        this.activeModal.hide();
    }

    public accept() {
        this.formSubmitted = true;
        if (this.generateReceiptAP.canExecute()) {
            this.generateReceiptAP.execute(this.invoice.id);
            this.activeModal.hide();
        }
    }

    diInitialize(data: CreateReceipt_Dto) {
    }
}
