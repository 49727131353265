import {Injectable} from '@angular/core';
import * as store from '../../../core/app-store/index';
import {UploadInputEvent} from '../../../core/app-store/index';
import {select, Store} from '@ngrx/store';
import {BaseSandbox} from 'src/app/core/shared/base/base.sandbox';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {FileAttachment, FileUploadTarget} from 'src/app/core/app-dto/core.dto';
import {UploadInput} from 'src/app/core/upload/components/upload-notification/upload-notification.component';
import {FileDictionary} from "../../../core/app-store/stores/documents.store";
import {isValidObject} from "../../../core/shared/helpers/common.helpers";

@Injectable()
export class FilesSandbox extends BaseSandbox {
  public getFiles$ = this.appState$.pipe(select(store.getDocumentsListEvents));

  constructor(protected appState$: Store<store.State>) {
    super(appState$);
  }

  clearSandboxState() {
  }

  initialize(relatedEntityId: string, types: Array<FileUploadTarget>, dataLoaded: (data: Array<FileAttachment>) => {}) {
    this.clearSandboxState();

    this.subscriptions.push(this.getFiles$.subscribe((data: Array<FileDictionary>) => {
      if (ObjectValidators.isValidArray(data)) {
        const stringifiedTypes = JSON.stringify(types);
        const foundItem = data.find(f => f.id == relatedEntityId && JSON.stringify(f.type) == stringifiedTypes);
        if (isValidObject(foundItem))
          dataLoaded(foundItem.documents);
        else dataLoaded([]);
      }
    }));
  }

  dispatchUploadInputEvent(uploadInput: UploadInput) {
    this.appState$.dispatch(new UploadInputEvent(uploadInput));
  }
}
