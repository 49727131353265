import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { Car_Dto } from '../../../core/app-dto/car.dto';
import {Do_ResetStateForSection, ResetSection} from '../../../core/app-store/events/base.events';

@Injectable()
export class CarServiceCarSandbox extends BaseSandbox {
    public carForClient$ = this.appState$.pipe(select(store.getCarForClient));
    public carForClient: Car_Dto = null;
    public isClientComponentTriggered: boolean = false;

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
    }

    public initialize(dataLoaded): any {
        this.clearSandboxState();
        this.subscriptions.push(this.carForClient$.subscribe((data: Car_Dto) => {
            this.carForClient = new Car_Dto(data);
            if (data != null) {
                this.isClientComponentTriggered = true;
            } else {
                this.isClientComponentTriggered = false;
            }
            dataLoaded(data);
        }));
    }

    public resetClientForCar() {
        this.appState$.dispatch(new Do_ResetStateForSection(ResetSection.ClientForCar));
    }
}
