import { BaseDI } from 'src/app/core/shared/base/base.di';
import { Supply_Dto } from '../../../core/app-dto/supply.dto';

export class SuppliesListItemDi extends BaseDI<Supply_Dto> {
    constructor(data: any) {
        super(new Supply_Dto(data));
    }

    getModel(): Supply_Dto {
        return this.model;
    }
}
