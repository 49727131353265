import * as store from '../../../app-store/index';
import {BaseSandbox} from "../../../shared/base/base.sandbox";
import {Store} from "@ngrx/store";
import {Injectable} from "@angular/core";

@Injectable()

export class AddPaymentMethodSandbox extends BaseSandbox {
    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
    }

    public initialize(): void {
        this.clearSandboxState();
    }
}
