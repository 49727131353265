import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebFormComponent } from 'src/app/core/shared/base/base.component';
import { BaseFormActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { UpdateCarServiceAddCarPartEvent } from '../../../core/app-store/events/car-service.events';
import { CarPart_Dto } from '../../../core/app-dto/car-part.dto';

export class CarSerivceDetailsAddCarPartActionProxy extends BaseFormActionProxy {
    constructor(component: IWebFormComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(serviceCarPart: CarPart_Dto, carServiceId: string): any {
        if (this.canExecute()) {
            this.appState.dispatch(new UpdateCarServiceAddCarPartEvent(serviceCarPart, carServiceId));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

