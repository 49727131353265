import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { NgxSpinnerService } from 'ngx-spinner';
import {GetMechanicsWithStatusEvent} from "../../../core/app-store/events/mechanic.events";

export class GetMechanicsWithStatusActionProxy extends BaseActionProxy {
  constructor(component: IWebComponent, appState: Store<store.State>, private spinner: NgxSpinnerService) {
    super(component, appState);
  }

  canExecute(): boolean {
    return true;
  }

  execute(): any {
    if (this.canExecute()) {
      setTimeout(() => {
        this.spinner.show('mechanicsListDashboard');
      }, 0);
      this.appState.dispatch(new GetMechanicsWithStatusEvent(() => {
        this.spinner.hide('mechanicsListDashboard');
      }));
    }
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }
}

