import { BaseDI } from 'src/app/core/shared/base/base.di';
import {StickyHeaderNotification} from "../../../app-dto/core.dto";

export class StickyHeaderNotificationDI extends BaseDI<StickyHeaderNotification> {
  constructor(data: any) {
    super(new StickyHeaderNotification(data));
  }

  getModel(): StickyHeaderNotification {
    return this.model;
  }
}
