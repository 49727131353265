import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { CarAction_Dto } from 'src/app/core/app-dto/car-service.dto';

@Injectable()
export class CarServiceActionListSandbox extends BaseSandbox {
    public carServiceActions$ = this.appState$.pipe(select(store.getCarServiceActionListing));

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
    }

    public initialize(dataLoaded): any {
        this.clearSandboxState();

        this.subscriptions.push(this.carServiceActions$.subscribe((data: Array<CarAction_Dto>) => {
            if (data != null) {
                
                dataLoaded(data);
            }
        }));
    }
}
