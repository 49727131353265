import { State } from '../state';
import * as fromCar from '../stores/car.store';
import { createSelector } from '@ngrx/store';

export const getCarStateCallback = (state: State) => state.car;

export const getCarTimestamp = createSelector(getCarStateCallback, fromCar.getTimestampCallback);
export const getCar = createSelector(getCarStateCallback, fromCar.getCarCallback);
export const getCarsListing = createSelector(getCarStateCallback, fromCar.getCarsPagedResultCallback);
export const getCarSearchList = createSelector(getCarStateCallback, fromCar.getCarSearchListCallback);
export const getCarForClient = createSelector(getCarStateCallback, fromCar.getCarForClientCallback);

