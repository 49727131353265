import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebFormComponent } from 'src/app/core/shared/base/base.component';
import { BaseFormActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { RegisterCalendarEventEvent } from 'src/app/core/app-store/events/calendar-events';
import { NgxSpinnerService } from 'ngx-spinner';

export class RegisterCalendarEventActionProxy extends BaseFormActionProxy {
    constructor(component: IWebFormComponent,
        appState: Store<store.State>,
        private spinner: NgxSpinnerService) {
        super(component, appState);
    }

    canExecute(): boolean {
        this.component.markFormAsDirtyAndTouched();
        return this.component.di.isValid();
    }

    execute(): any {
        if (this.canExecute()) {
            this.component.markFormAsNotDirty();
            const model = this.component.di.getModel();
            this.spinner.show('calendar');
            this.appState.dispatch(new RegisterCalendarEventEvent(model, () => {
                this.spinner.hide('calendar');
            }));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

