import {Injectable} from '@angular/core';
import {BaseApiService} from './base-api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiErrorDto} from '../utils/api-error.dto';
import {ConfigService} from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {ActiveDataTableParameters, DataTableParameters, PagedResult} from '../../shared/datatable/datatable.helpers';
import {CarPart_Dto, CarPartAggregate_Dto} from '../../app-dto/car-part.dto';
import {ErrorHelpers} from '../../shared/error.helpers';
import {SupplyItem_Dto} from '../../app-dto/supply.dto';
import {isValidObject} from '../../shared/helpers/common.helpers';

class CarPartApiRoutes {
    public static route_for_GET_SUPPLY_ITEMS_FOR_CAR_PART_PAGINATED(carPartId: string) {
        return `/api/carPart/${carPartId}/supply-items`;
    }

    public static route_for_GET_CAR_PARTS_PAGINATED() {
        return '/api/carPart/all';
    }

    public static route_for_GET_CAR_PART_STOCK_ALERTS_PAGINATED() {
        return '/api/carPart/all-stock-alerts';
    }

    public static route_for_GET_CAR_PART(carPartId: string) {
        return `/api/carPart/${carPartId}`;
    }

    public static route_for_REGISTER_CAR_PART() {
        return '/api/carPart/';
    }

    public static route_for_EDIT_CAR_PART() {
        return '/api/carPart/';
    }

    public static route_for_DELETE_CAR_PART(carPartId: string) {
        return `/api/carPart/${carPartId}`;
    }

    public static route_for_DELETE_CAR_PART_SUPPLY_ITEM(id: string) {
        return `/api/carPart/supply-item/${id}`;
    }

    public static route_for_ACTIVATE_CAR_PART(carPartId: string) {
        return `/api/carPart/${carPartId}/activate`;
    }

    public static route_for_DEACTIVATE_CAR_PART(carPartId: string) {
        return `/api/carPart/${carPartId}/deactivate`;
    }

    public static route_for_SEARCH_CAR_PART() {
        return `/api/carPart/search`;
    }

    public static route_for_SEARCH_CAR_FOR_SUPPLY() {
        return `/api/carPart/supply-search`;
    }

    public static route_for_GET_EXCEL_CAR_PARTS() {
        return `/api/carPart/getExcel`;
    }

    public static route_for_GET_EXCEL_CAR_PARTS_IMPORT_TEMPLATE() {
        return `/api/carPart/getExcelCarPartImportTemplate`;
    }

    public static route_for_ACTIVATE_CAR_PART_STOCK_ALERT(carPartId: string) {
        return `/api/carPart/${carPartId}/activate-stock-alert`;
    }

    public static route_for_DEACTIVATE_CAR_PART_STOCK_ALERT(carPartId: string) {
        return `/api/carPart/${carPartId}/deactivate-stock-alert`;
    }

    public static route_for_GET_TOTAL_CAR_PARTS_VALUE() {
        return `/api/carPart/total-count`;
    }
}

@Injectable()
export class CarPartApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_registerCarPart(carPartData: CarPart_Dto): Observable<CarPart_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_REGISTER_CAR_PART();

        return this.http.post(url, carPartData)
            .map((res: any) => {
                return new CarPart_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_editCarPart(carPartData: CarPart_Dto): Observable<CarPart_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_EDIT_CAR_PART();

        return this.http.put(url, carPartData)
            .map((res: any) => {
                return new CarPart_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getFilteredCarPartsPaginated(data: ActiveDataTableParameters, supplierFilter: string): Observable<PagedResult<CarPart_Dto>> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_GET_CAR_PARTS_PAGINATED()
            + '?' + data.toUrlParameters() + '&supplierFilter=' + supplierFilter;

        return this.http.get(url)
            .map((res: PagedResult<CarPart_Dto>) => {
                return new PagedResult<CarPart_Dto>(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getFilteredSupplyItemsForCarPartPaginated(carPartId: string, data: DataTableParameters, includeUsedOnes: boolean): Observable<PagedResult<SupplyItem_Dto>> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_GET_SUPPLY_ITEMS_FOR_CAR_PART_PAGINATED(carPartId)
            + '?' + data.toUrlParameters() + '&includeUsedOnes=' + includeUsedOnes;

        return this.http.get(url)
            .map((res: PagedResult<SupplyItem_Dto>) => {
                return new PagedResult<SupplyItem_Dto>(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getFilteredCarPartStockAlertsPaginated(data: ActiveDataTableParameters): Observable<PagedResult<CarPart_Dto>> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_GET_CAR_PART_STOCK_ALERTS_PAGINATED() + '?' + data.toUrlParameters();

        return this.http.get(url)
            .map((res: PagedResult<CarPart_Dto>) => {
                return new PagedResult<CarPart_Dto>(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getCarPart(carPartId: string): Observable<CarPartAggregate_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_GET_CAR_PART(carPartId);

        return this.http.get(url)
            .map((res: any) => {
                return new CarPartAggregate_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deleteCarPart(carPartId: string): Observable<CarPart_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_DELETE_CAR_PART(carPartId);

        return this.http.delete(url)
            .map((res: any) => {
                return new CarPart_Dto({id: carPartId});
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deleteSupplyItem(supplyItemId: string): Observable<CarPart_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_DELETE_CAR_PART_SUPPLY_ITEM(supplyItemId);

        return this.http.delete(url)
            .map((res: any) => {
                return new CarPart_Dto({id: supplyItemId});
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_activateCarPart(carPartId: string): Observable<CarPart_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_ACTIVATE_CAR_PART(carPartId);

        return this.http.put(url, {})
            .map((res: any) => {
                return new CarPart_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deactivateCarPart(carPartId: string): Observable<CarPart_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_DEACTIVATE_CAR_PART(carPartId);

        return this.http.put(url, {})
            .map((res: any) => {
                return new CarPart_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_searchCarParts(search: string, displayOnlyAvailableItems: boolean = true): Observable<Array<CarPart_Dto>> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_SEARCH_CAR_PART() + '?search=' + search
            + '&displayOnlyAvailableItems=' +
            (isValidObject(displayOnlyAvailableItems) ? displayOnlyAvailableItems : true).toString();

        return this.http.get(url)
            .map((res: any) => {
                return res.map(r => new CarPart_Dto(r));
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_searchCarPartsForSupply(search: string, supplierName: string): Observable<Array<CarPart_Dto>> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_SEARCH_CAR_FOR_SUPPLY() + '?search=' + search
            + '&supplierName=' + supplierName;

        return this.http.get(url)
            .map((res: any) => {
                return res.map(r => new CarPart_Dto(r));
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getExcelCarParts(supplierId: string): Observable<string> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_GET_EXCEL_CAR_PARTS() + '?supplierId=' + supplierId;

        return this.http.get(url)
            .map((res: any) => {
                return res;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getExcelCarPartsImportTemplate(): Observable<string> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_GET_EXCEL_CAR_PARTS_IMPORT_TEMPLATE();

        return this.http.get(url)
            .map((res: any) => {
                return res;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_activateCarPartStockAlert(carPartId: string): Observable<CarPart_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_ACTIVATE_CAR_PART_STOCK_ALERT(carPartId);

        return this.http.put(url, {})
            .map((res: any) => {
                return new CarPart_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deactivateCarPartStockAlert(carPartId: string): Observable<CarPart_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_DEACTIVATE_CAR_PART_STOCK_ALERT(carPartId);

        return this.http.put(url, {})
            .map((res: any) => {
                return new CarPart_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getTotalCarPartsValue(): Observable<number> {
        const url = this.configService.getAPIBaseUrl() + CarPartApiRoutes.route_for_GET_TOTAL_CAR_PARTS_VALUE();

        return this.http.get(url)
            .map((res: any) => {
                return res;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
}
