import { State } from '../state';
import * as fromMechanic from '../stores/mechanic.store';
import { createSelector } from '@ngrx/store';

export const getMechanicStateCallback = (state: State) => state.mechanic;

export const getMechanicTimestamp = createSelector(getMechanicStateCallback, fromMechanic.getTimestampCallback);
export const getMechanic = createSelector(getMechanicStateCallback, fromMechanic.getMechanicCallback);
export const getMechanicsListing = createSelector(getMechanicStateCallback, fromMechanic.getMechanicsPagedResultCallback);
export const getMechanicsList = createSelector(getMechanicStateCallback, fromMechanic.getMechanicsListCallback);
export const getMechanicsWithStatusList = createSelector(getMechanicStateCallback, fromMechanic.getMechanicsWithStatusListCallback);
