import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ServifyButtonSandbox } from './servify-button.sandbox';

@Component({
  selector: 'app-servify-button',
  templateUrl: './servify-button.component.html',
  styleUrls: ['./servify-button.component.scss']
})
export class ServifyButtonComponent implements OnInit {
  @Input() label: string;
  @Input() icon: string = '';
  @Input() type: string = '';
  @Input() disabled: boolean = false;
  @Input() buttonWidth:string = "320px";
  public buttonClass: string;
  public buttonIcon: string;


  @ViewChild('buttonElement', {static: false}) buttonElement: ElementRef;

  constructor(public sandbox: ServifyButtonSandbox) {
  }

  ngOnInit() {
    this.buttonClass = `btn btn-${this.type} mr-1 mb-1 align-items-center justify-content-between`;
    this.buttonIcon = `${this.icon} pull-right`;
  }
}
