import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OrganizationClient_Dto } from '../../../core/app-dto/organization-client.dto';
import {CnpReg, EmailRegForControl, noWhitespaceValidator, PhoneReg} from 'src/app/core/shared/form/custom-form.validator';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import {CompanyInfo_Dto} from "../../../core/app-dto/company-info.dto";
import {Address} from '../../../core/app-dto/core.dto';


export class OrganizationClientFormDI extends BaseFormDI<OrganizationClient_Dto> {
  public selectedValue: string = null;
  public cnpCui: string = null;

  constructor(data: OrganizationClient_Dto = new OrganizationClient_Dto(null)) {
    super(new OrganizationClient_Dto(data));
    if (ObjectValidators.isValidString(this.model.id))
      this.selectedValue = this.model.id;
    this.initForm();

    if (this.model.isLegalEntity) {
      this.form.get('iban').setValidators([]);
      this.form.get('bank').setValidators([]);
      this.form.get('nrRegCi').setValidators([]);
      this.form.get('cuiCnp').setValidators([Validators.required, noWhitespaceValidator]);
      this.enableCreditAndDiscounts();
      if (!this.form.value.applyCreditLimit) {
        this.form.get('creditLimit').setValue(null);
        this.form.get('creditLimit').disable();
      } else {
        this.form.get('creditLimit').enable();
      }
    } else {
      if (this.form.value.isUniqueId) {
        this.disableCuiCnp();
        this.form.get('cuiCnp').setValidators([]);
      } else {
        this.enableCuiCnp();
        this.form.get('cuiCnp').setValidators([Validators.required, noWhitespaceValidator, CnpReg()]);
      }
      this.form.get('iban').setValidators([]);
      this.form.get('nrRegCi').setValidators([]);
      this.form.get('bank').setValidators([]);
      this.resetAndDisableCreditAndDiscounts();
    }
    this.updateValueAndValidity();
    this.cnpCui = data.cuiCnp;
  }

  enableCuiCnp() {
    setTimeout( () => {
      this.form.get('cuiCnp').enable();
    }, 100);
  }

  disableCuiCnp() {
    setTimeout( () => {
      this.form.get('cuiCnp').disable();
    }, 100);
  }

  getModel(): OrganizationClient_Dto {
    this.model.isLegalEntity = this.form.value.isLegalEntity === 'true';
    this.model.name = this.form.value.name;
    this.model.cuiCnp = this.form.value.cuiCnp;
    this.model.isUniqueId = this.form.value.isUniqueId || false;
    this.model.nrRegCi = this.form.value.nrRegCi;
    this.model.phoneNumber = this.form.value.phoneNumber;
    this.model.email = this.form.value.email;
    this.model.willClientAcceptNotifications = this.form.value.willClientAcceptNotifications || false;
    this.model.iban = this.form.value.iban;
    this.model.bank = this.form.value.bank;
    this.model.applyCreditLimit = this.form.value.applyCreditLimit || false;
    this.model.creditLimit = this.form.value.creditLimit || 0;
    this.model.generalDiscount = this.form.value.generalDiscount || 0;
    this.model.workmanshipDiscount = this.form.value.workmanshipDiscount || 0;
    this.model.carPartDiscount = this.form.value.carPartDiscount || 0;
    this.model.address = this.form.value.address;

    return this.model;
  }

  changeLegalForm(e: any) {
    if (e.target.value != "false") {
      if (this.form.value.isUniqueId) {
        this.form.get('cuiCnp').setValue(null);
      }
      this.enableCuiCnp();
      this.form.get('iban').setValidators([]);
      this.form.get('bank').setValidators([]);
      this.form.get('cuiCnp').setValidators([Validators.required, noWhitespaceValidator]);
      this.form.get('nrRegCi').setValidators([]);
      this.enableCreditAndDiscounts();
      if (!this.form.value.applyCreditLimit) {
        this.form.get('creditLimit').setValue(null);
        this.form.get('creditLimit').disable();
      } else {
        this.form.get('creditLimit').enable();
      }
    } else {
      if (this.form.value.isUniqueId) {
        this.form.get('cuiCnp').setValue(this.cnpCui);
        this.disableCuiCnp();
        this.form.get('cuiCnp').setValidators([]);
      } else {
        this.enableCuiCnp();
        this.form.get('cuiCnp').setValidators([Validators.required, noWhitespaceValidator, CnpReg()]);
      }
      this.form.get('iban').setValidators([]);
      this.form.get('bank').setValidators([]);
      this.form.get('nrRegCi').setValidators([]);

      this.resetAndDisableCreditAndDiscounts();
    }
    this.updateValueAndValidity();
  }

  changeCreditLimitState(e: any) {
    if (e.target.checked) {
      this.form.get('creditLimit').enable();
    } else {
      this.form.get('creditLimit').setValue(null);
      this.form.get('creditLimit').disable();
    }
  }

  changeUniqueIdState(e: any) {
    if (e.target.checked) {
      this.form.get('cuiCnp').setValue(this.cnpCui);
      this.disableCuiCnp();
      this.form.get('cuiCnp').setValidators([]);
    } else {
      this.form.get('cuiCnp').setValue(null);
      this.enableCuiCnp();
      this.form.get('cuiCnp').setValidators([Validators.required, noWhitespaceValidator, CnpReg()]);
    }
    this.updateValueAndValidity();
  }

  initForm() {
    this.form = new FormGroup({
      isLegalEntity: new FormControl(this.model.isLegalEntity + '', Validators.compose([Validators.required])),
      name: new FormControl(this.model.name, Validators.compose([Validators.required])),
      cuiCnp: new FormControl(this.model.cuiCnp, Validators.compose([noWhitespaceValidator])),
      isUniqueId: new FormControl(this.model.isUniqueId, Validators.compose([])),
      nrRegCi: new FormControl(this.model.nrRegCi, Validators.compose([])),
      phoneNumber: new FormControl(this.model.phoneNumber, Validators.compose([Validators.required, PhoneReg()])),
      email: new FormControl(this.model.email, Validators.compose([EmailRegForControl()])),
      willClientAcceptNotifications: new FormControl(this.model.willClientAcceptNotifications, Validators.compose([])),
      iban: new FormControl(this.model.iban, Validators.compose([])),
      bank: new FormControl(this.model.bank, Validators.compose([])),
      applyCreditLimit: new FormControl(this.model.applyCreditLimit, Validators.compose([])),
      creditLimit: new FormControl(this.model.creditLimit, Validators.compose([Validators.min(0)])),
      generalDiscount: new FormControl(this.model.generalDiscount, Validators.compose([Validators.min(0), Validators.max(100)])),
      workmanshipDiscount: new FormControl(this.model.workmanshipDiscount, Validators.compose([Validators.min(0), Validators.max(100)])),
      carPartDiscount: new FormControl(this.model.carPartDiscount, Validators.compose([Validators.min(0), Validators.max(100)]))
    });
  }

  resetAndDisableCreditAndDiscounts() {
    this.form.get('applyCreditLimit').setValue(false);
    this.form.get('creditLimit').setValue(null);
    this.form.get('generalDiscount').setValue(null);
    this.form.get('workmanshipDiscount').setValue(null);
    this.form.get('carPartDiscount').setValue(null);
    this.form.get('applyCreditLimit').disable();
    this.form.get('creditLimit').disable();
    this.form.get('generalDiscount').disable();
    this.form.get('workmanshipDiscount').disable();
    this.form.get('carPartDiscount').disable();
  }

  resetAnafUsedFields() {
    this.form.get('name').setValue(null);
    this.form.get('nrRegCi').setValue(null);
    this.form.get('phoneNumber').setValue(null);
    const address = new Address(null);
    this.model.address = address;
  }

  enableCreditAndDiscounts() {
    this.form.get('applyCreditLimit').enable();
    this.form.get('creditLimit').enable();
    this.form.get('generalDiscount').enable();
    this.form.get('workmanshipDiscount').enable();
    this.form.get('carPartDiscount').enable();
  }

  updateValueAndValidity() {
    this.form.get('iban').updateValueAndValidity();
    this.form.get('nrRegCi').updateValueAndValidity();
    this.form.get('bank').updateValueAndValidity();
    this.form.get('applyCreditLimit').updateValueAndValidity();
    this.form.get('creditLimit').updateValueAndValidity();
    this.form.get('generalDiscount').updateValueAndValidity();
    this.form.get('workmanshipDiscount').updateValueAndValidity();
    this.form.get('carPartDiscount').updateValueAndValidity();
    this.form.get('isUniqueId').updateValueAndValidity();
    this.form.get('cuiCnp').updateValueAndValidity();
  }

  updateFormAfterCuiData(data: CompanyInfo_Dto) {
    this.form.get('name').setValue(data.nume);
    this.form.get('name').updateValueAndValidity();
    this.form.get('nrRegCi').setValue(data.nrInregistrareRecom);
    this.form.get('nrRegCi').updateValueAndValidity();
  }
}
