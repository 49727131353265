import {isValidObject} from '../shared/helpers/common.helpers';

export class Version_Dto {
    public name: string;
    public version: string;

    constructor(data: any) {
        if (isValidObject(data)) {
            this.name = data.name;
            this.version = data.version;
        }
    }
}
