import {BaseDI} from 'src/app/core/shared/base/base.di';
import {CarAction_Dto, CarServiceEntryData_Dto} from 'src/app/core/app-dto/car-service.dto';
import {CarActionDiHelpers} from '../../common/di/car-action.di.helpers';
import { formatNumber } from '@angular/common';

export class CarServiceActionListItemDI extends BaseDI<CarAction_Dto> {
  public computedPrice: string = '-';
  public computedPriceWithVAT: string = '-';
  public isWaitingStatus: boolean = false;

  constructor(data: CarAction_Dto) {
    super(new CarAction_Dto(data));
    if (data.mechanic != null) {
      this.computedPrice = formatNumber(data.totalValue, 'en-US' , '1.2-2') + ' ' + data.currency;
      this.computedPriceWithVAT = formatNumber(data.totalValueWithVAT, 'en-US' , '1.2-2') + ' ' + data.currency;
    }
    this.isWaitingStatus = CarActionDiHelpers.isWaitingStatus(data.status);
  }

  getModel(): CarAction_Dto {
    return this.model;
  }

  getCalendarEventStatus(){
    return CarActionDiHelpers.getCalendarEventStatus(this.model.status);
  }
}
