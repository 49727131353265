import {State} from './state';
import {ActionReducerMap} from '@ngrx/store';
import * as fromAuthentication from './stores/authentication.store';
import * as fromCore from './stores/core.store';
import * as fromStaticData from './stores/static-data.store';
import * as fromSubscription from './stores/subscription.store';
import * as fromPlatform from './stores/service-platform.store';
import * as fromMechanic from './stores/mechanic.store';
import * as fromUser from './stores/user.store';
import * as fromProfile from './stores/profile.store';
import * as fromUpload from './stores/upload.store';
import * as fromInvoice from './stores/invoice.store';
import * as fromReceipt from './stores/receipt.store';
import * as fromCarPart from './stores/car-part.store';
import * as fromCar from './stores/car.store';
import * as fromCalendar from './stores/calendar.store';
import * as fromSupplier from './stores/supplier.store';
import * as fromOrganizationClient from './stores/organization-client.store';
import * as fromDraft from './stores/draft.store';
import * as fromCarService from './stores/car-service.store';
import * as fromServicePlatformStatus from './stores/service-platform-status.store';
import * as fromAudit from './stores/audit.store';
import * as fromDocuments from './stores/documents.store';
import * as fromDepartment from './stores/department.store';
import * as fromReporting from './stores/reporting.store';
import * as fromOrganizationCost from './stores/organization-cost.store';
import * as fromSMS from './stores/sms.store';
import * as fromBreadcrumb from './stores/breadcrumb.store';
import * as fromCarWork from './stores/car-work.store';
import * as fromSupply from './stores/supply.store';
import * as fromCashRegister from './stores/cash-register.store';
import * as fromOffer from './stores/offer.store';
import * as fromSuperAdminLogs from './stores/super-admin-logs.store';

export const reducers: ActionReducerMap<State> = {
    authentication: fromAuthentication.reducer,
    core: fromCore.reducer,
    staticData: fromStaticData.reducer,
    subscription: fromSubscription.reducer,
    servicePlatform: fromPlatform.reducer,
    mechanic: fromMechanic.reducer,
    user: fromUser.reducer,
    profile: fromProfile.reducer,
    upload: fromUpload.reducer,
    invoice: fromInvoice.reducer,
    receipt: fromReceipt.reducer,
    carPart: fromCarPart.reducer,
    car: fromCar.reducer,
    calendar: fromCalendar.reducer,
    supplier: fromSupplier.reducer,
    organizationClient: fromOrganizationClient.reducer,
    draft: fromDraft.reducer,
    carService: fromCarService.reducer,
    servicePlatformStatus: fromServicePlatformStatus.reducer,
    audit: fromAudit.reducer,
    documents: fromDocuments.reducer,
    department: fromDepartment.reducer,
    reporting: fromReporting.reducer,
    organizationCost: fromOrganizationCost.reducer,
    sms: fromSMS.reducer,
    breadcrumb: fromBreadcrumb.reducer,
    carWork: fromCarWork.reducer,
    supply: fromSupply.reducer,
    cashRegister: fromCashRegister.reducer,
    offer: fromOffer.reducer,
    superAdminLogs: fromSuperAdminLogs.reducer
};
