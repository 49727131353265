import {Injectable} from '@angular/core';
import {BaseApiService} from './base-api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiErrorDto} from '../utils/api-error.dto';
import {ConfigService} from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {ErrorHelpers} from '../../shared/error.helpers';
import {DataTableParameters, PagedResult} from "../../shared/datatable/datatable.helpers";
import {
  SMSBoughtTransaction,
  SMSPackageType,
  SMSSentTransaction
} from "../../app-dto/sms.dto";
import {Car_Dto} from "../../app-dto/car.dto";
import {Organization} from "../../app-dto/organization.dto";

class SmsApiServiceRoutes {
  public static route_for_GET_SMS_SENT_PAGINATED() {
    return '/api/sms/sent/all';
  }

  public static route_for_GET_SMS_BOUGHT_PAGINATED() {
    return '/api/sms/bought/all';
  }

  public static route_for_GET_AVAILABLE_PACKAGES() {
    return '/api/sms/packages/all';
  }

  public static route_for_GET_AVAILABLE_PACKAGES_FOR_PLATFORM_ADMIN() {
    return '/api/sms/packages/all/platform-admin';
  }

  public static route_for_BUY_SMS_FOR_ORGANIZATION(organizationId: string, packageId: string) {
    return '/api/sms/packages/buy/' + organizationId + '/' + packageId;
  }
}

@Injectable()
export class SmsApiService extends BaseApiService {

  constructor(private http: HttpClient, configService: ConfigService) {
    super(configService);
  }

  u_getFilteredSentSMSPaginated(data: DataTableParameters): Observable<PagedResult<SMSSentTransaction>> {
    const url = this.configService.getAPIBaseUrl() + SmsApiServiceRoutes.route_for_GET_SMS_SENT_PAGINATED() + '?' + data.toUrlParameters();

    return this.http.get(url)
      .map((res: PagedResult<SMSSentTransaction>) => {
        return new PagedResult<SMSSentTransaction>(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }
  u_getFilteredBoughtSMSPaginated(data: DataTableParameters): Observable<PagedResult<SMSBoughtTransaction>> {
    const url = this.configService.getAPIBaseUrl() + SmsApiServiceRoutes.route_for_GET_SMS_BOUGHT_PAGINATED() + '?' + data.toUrlParameters();

    return this.http.get(url)
      .map((res: PagedResult<SMSBoughtTransaction>) => {
        return new PagedResult<SMSBoughtTransaction>(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getAvailablePackages(): Observable<Array<SMSPackageType>> {
    const url = this.configService.getAPIBaseUrl() + SmsApiServiceRoutes.route_for_GET_AVAILABLE_PACKAGES() ;

    return this.http.get(url)
      .map((res: Array<SMSPackageType>) => {
        return res.map(r=> new SMSPackageType(r));
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }
  u_getAvailablePackagesForPlatformAdmin(): Observable<Array<SMSPackageType>> {
    const url = this.configService.getAPIBaseUrl() + SmsApiServiceRoutes.route_for_GET_AVAILABLE_PACKAGES_FOR_PLATFORM_ADMIN() ;

    return this.http.get(url)
      .map((res: Array<SMSPackageType>) => {
        return res.map(r=> new SMSPackageType(r));
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }
  u_buySMSForOrganizationAsPlatformAdmin(organizationId:string, packageId:string): Observable<Organization> {
    const url = this.configService.getAPIBaseUrl() + SmsApiServiceRoutes.route_for_BUY_SMS_FOR_ORGANIZATION(organizationId, packageId);

    return this.http.post(url, {})
      .map((res: Organization) => {
        return new Organization(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }
}
