import {ObjectValidators} from '../shared/object.validators';
import {Base_Dto, BaseCRUDRules, BusinessRule} from './misc.dto';
import * as moment from 'moment';
import {Car_Dto} from './car.dto';
import {OrganizationClient_Dto} from "./organization-client.dto";
import {CarAction_Dto} from "./car-service.dto";
import {isValidArrayAndHasElements, isValidObject} from "../shared/helpers/common.helpers";

export class ServicePlatformRules extends BaseCRUDRules {
  public canActivate: BusinessRule;
  public canDeactivate: BusinessRule;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.canActivate = new BusinessRule(data.canActivate);
      this.canDeactivate = new BusinessRule(data.canDeactivate);

    } else {
      this.canActivate = new BusinessRule();
      this.canDeactivate = new BusinessRule();
    }
  }
}

export class ServicePlatform_Dto extends Base_Dto {
  public name: string;
  public description: string;
  public number: number;
  public car: Car_Dto;
  public client: OrganizationClient_Dto;
  public currentAction: CarAction_Dto;
  public rules: ServicePlatformRules;
  public createdOn: Date;
  public updatedOn: Date;
  public currentCarServiceEntryId: string;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.name = data.name;
      this.currentCarServiceEntryId = data.currentCarServiceEntryId;
      this.description = data.description;
      this.number = data.number;
      if (ObjectValidators.isValidObject(data.car))
        this.car = new Car_Dto(data.car);
      if (ObjectValidators.isValidObject(data.client))
        this.client = new OrganizationClient_Dto(data.client);
      if (ObjectValidators.isValidObject(data.currentAction))
        this.currentAction = new CarAction_Dto(data.currentAction);
      this.rules = new ServicePlatformRules(data.rules);
      if (ObjectValidators.isValidObject(data.createdOn))
        this.createdOn = moment.utc(data.createdOn).toDate();
      if (ObjectValidators.isValidObject(data.updatedOn))
        this.updatedOn = moment.utc(data.updatedOn).toDate();
    }
  }
}

export class ServicePlatformCalendarColumn {
  public id: string;
  public title: string;
  public subtitle: string;
}


