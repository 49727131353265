import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store/index';
import {Observable} from 'rxjs';
import {IWebComponent} from 'src/app/core/shared/base/base.component';
import {BaseActionProxy} from 'src/app/core/shared/base/base.action-proxy';
import {CarService_Dto} from 'src/app/core/app-dto/car-service.dto';
import {RegisterDraftEvent} from "../../../core/app-store/events/draft.events";
import {Draft_Dto, DraftType} from "../../../core/app-dto/draft.dto";
import {isValidObject} from "../../../core/shared/helpers/common.helpers";
import {StringHelpers} from "../../../core/shared/string.helpers";

export class SaveDraftActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
        appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(model: CarService_Dto, step:number): any {
        if (this.canExecute()) {
            const draftModel = new Draft_Dto(null);
            draftModel.currentPage = step.toString();
            if(isValidObject(model.client)) {
              draftModel.description = StringHelpers.tryAppendStringSeparatedByComma(draftModel.description, model.client.name);
              draftModel.description = StringHelpers.tryAppendStringSeparatedByComma(draftModel.description, model.client.phoneNumber);
              draftModel.description = StringHelpers.tryAppendStringSeparatedByComma(draftModel.description, model.client.cuiCnp);
            }
            if(isValidObject(model.car)){
              draftModel.title = StringHelpers.tryAppendStringSeparatedByComma(draftModel.title, model.car.registrationNumber);
              draftModel.title = StringHelpers.tryAppendStringSeparatedByComma(draftModel.title, model.car.make);
              draftModel.title = StringHelpers.tryAppendStringSeparatedByComma(draftModel.title, model.car.model);
            }
            draftModel.data = JSON.stringify(model);
            draftModel.type = DraftType.CarServiceEntry;
            this.appState.dispatch(new RegisterDraftEvent(draftModel));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

