import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import {
    BaseListSandboxWithActiveAndSearchBehavior,
    BaseSandboxWithCurrentUserLoaded
} from 'src/app/core/shared/base/base.sandbox';
import { ActiveDataTableParameters, DataTableParameters, PagedResult } from 'src/app/core/shared/datatable/datatable.helpers';
import { CarPart_Dto } from '../../../core/app-dto/car-part.dto';
import { Organization } from '../../../core/app-dto/organization.dto';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { Supplier_Dto } from '../../../core/app-dto/supplier.dto';
import {getTotalCarPartsValue} from "../../../core/app-store/index";

@Injectable()
export class TotalCarPartsValueSandbox extends BaseSandboxWithCurrentUserLoaded {
    public timestamp$ = this.appState$.pipe(select(store.getCarPartTimestamp));
    public getTotalValue$ = this.appState$.pipe(select(store.getTotalCarPartsValue));

    public callback: any;
    public resetCallback: Function;
    public lastRefreshTime: number = 0;


    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
        this.lastRefreshTime = 0;
    }

    public initialize(dataLoaded: (result:number)=>void): void {
        this.clearSandboxState();
        this.loadUser(()=>{});
        this.subscriptions.push(this.getTotalValue$.subscribe((data: number) => {
            dataLoaded(data);
        }));

        this.subscriptions.push(this.timestamp$.subscribe((result: number) => {
            if (result > this.lastRefreshTime) {
                this.lastRefreshTime = result;
                this.resetTable();
            }
        }));

    }

    public setResetCallback(callback: Function) {
        this.resetCallback = callback;
    }

    public resetTable() {
        if (this.resetCallback != null) {
            this.resetCallback();
        }
    }
}
