import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseWebComponentWithBreadcrumb} from '../../../core/shared/base/base.component';
import {GetCarServiceDetailsActionProxy} from '../../../car-service/components/car-service-details/get-car-service-details.action-proxy';
import {FinalizeCarServiceActionProxy} from '../../../car-service/components/car-service-details/finalize-car-service.action-proxy';
import {DeleteCarServiceEntryActionProxy} from '../../../car-service/components/car-service-details/delete-car-service-entry.action-proxy';
import {FileImageWrapper} from '../../../car-service/components/car-service-photos/car-service-photos.component';
import {FileUploadTarget} from '../../../core/app-dto/core.dto';
import {CarServiceEntryInvoiceButtonInputComponent} from '../../../core/core/components/car-service-entry-invoice-button/car-service-entry-invoice-button.component';
import {CarServiceDetailsSandbox} from '../../../car-service/components/car-service-details/car-service-details.sandbox';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap';
import {ConfirmationDialogService} from '../../../core/core/dialog/confirmation-dialog.service';
import {AppUserBusinessRuleProvider} from '../../../core/core/business-rule-provider/app-user.business-rule.provider';
import {ResetSection} from '../../../core/app-store/events/base.events';
import {CarServiceDetailsDi} from '../../../car-service/components/car-service-details/car-service-details.di';
import {CarService_Dto} from '../../../core/app-dto/car-service.dto';
import {InvoiceType} from '../../../core/app-dto/invoice.dto';
import {
    AddCarInServiceActionEvent,
    DeleteCarInServiceActionEvent,
    DeleteCarServiceCarPartEvent,
    DeleteCarServiceWorkmanshipEvent,
    EditCarServiceClientEvent,
    FinalizeCarServiceEvent,
    UpdateCarInServiceActionEvent, UpdateCarServiceAddCarPartEvent,
    UpdateCarServiceAddWorkmanshipEvent,
    UpdateCarServiceUpdateWorkmanshipEvent
} from '../../../core/app-store/events/car-service.events';
import {GenerateInvoiceForCarServiceEntry} from '../../../core/app-store/events/invoices.events';
import {DateHelpers} from '../../../core/shared/date.helpers';
import {CarServiceSaveFinalInspectionComponent} from '../../../car-service/components/car-service-save-final-inspection/car-service-save-final-inspection.component';
import {GetOfferActionProxy} from '../../common/ap/get-offer.action-proxy';
import {DeleteOfferActionProxy} from '../../common/ap/delete-offer.action-proxy';
import {OfferDetailsOverviewSandbox} from './offer-details-overview.sandbox';
import {OfferDetailsDi} from './offer-details.di';
import {Offer_Dto} from '../../../core/app-dto/offer.dto';
import {GetUnauthOfferActionProxy} from '../../common/ap/get-unauth-offer.action-proxy';
import {isValidString} from '../../../core/shared/helpers/common.helpers';
import {UpdateOfferFinalizeEvent} from '../../../core/app-store/events/offer.events';
import {FinalizeOfferActionProxy} from '../../common/ap/finalize-offer.action-proxy';
import {SendOfferToClientsComponent} from '../send-offer-to-clients/send-offer-to-clients.component';
import {ResponseToOfferComponent, ResponseToOfferInput} from '../response-to-offer/response-to-offer.component';
import {UpdateOfferCarDataComponent} from "../update-offer-car-data/update-offer-car-data.component";
import {UpdateOfferTitleComponent} from "../update-offer-title/update-offer-title.component";
import {
    GenerateInvoiceForCarEntryActionProxy
} from '../../../core/core/components/car-service-entry-invoice-button/generate-invoice-for-car-entry.action-proxy';
import {GenerateOfferActionProxy} from './generate-offer.action-proxy';

@Component({
    selector: 'app-offer-details-overview',
    templateUrl: './offer-details-overview.component.html',
    styleUrls: ['./offer-details-overview.component.scss']
})
export class OfferDetailsOverviewComponent extends BaseWebComponentWithBreadcrumb implements OnInit, OnDestroy {
    public getDataAP: GetOfferActionProxy;
    public getDataAnnonymusAP: GetUnauthOfferActionProxy;
    public deleteDataAP: DeleteOfferActionProxy;
    public finalizeOfferAP: FinalizeOfferActionProxy;
    public offerId: string = null;
    public tab: number = 1;
    public fileUploadTarget = FileUploadTarget;
    public isReadOnly = false;
    public di: OfferDetailsDi;
    private generateOfferAP: GenerateOfferActionProxy = null;

    constructor(
        public sandbox: OfferDetailsOverviewSandbox,
        public activatedRoute: ActivatedRoute,
        private modalService: BsModalService, public confirmationDialogService: ConfirmationDialogService,
        private router: Router,
        public rulesProvider: AppUserBusinessRuleProvider
    ) {
        super(sandbox, ResetSection.Offer);
        this.getDataAP = new GetOfferActionProxy(this, sandbox.appState);
        this.finalizeOfferAP = new FinalizeOfferActionProxy(this, sandbox.appState);
        this.getDataAnnonymusAP = new GetUnauthOfferActionProxy(this, sandbox.appState);
        this.deleteDataAP = new DeleteOfferActionProxy(this, sandbox.appState);
        this.di = new OfferDetailsDi(null);
        this.generateOfferAP = new GenerateOfferActionProxy(this, sandbox.appState);
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe((params: Params) => {
            if (params.id !== undefined) {
                this.offerId = params.id;
                if (isValidString(params.code) === false) {
                    this.getDataAP.execute(this.offerId);
                } else {
                    this.getDataAnnonymusAP.execute(this.offerId, params.code);
                    this.isReadOnly = true;
                }
            }
        });

        this.initialize((data: Offer_Dto) => {

            this.setBreadcrumbAP.execute([
                {text: 'Acasa', url: '/dashboard'},
                {text: 'Oferte', url: '/offer/all'},
                {text: data.title}]);

            this.di = new OfferDetailsDi(data);
            this.dataLoaded = true;
        });
    }

    ngOnDestroy() {
        this.destroy();
    }

    dateToString(date: Date) {
        return DateHelpers.dateToString(date, 'DD/MM/YYYY');
    }

    updateTab(i: number) {
        this.tab = i;
    }


    deleteOffer() {
        this.confirmationDialogService.confirm(
            'Stergere oferta',
            'Urmeaza sa stergeti oferta. Sunteti sigur?',
            'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
            if (confirmed) {
                this.deleteDataAP.execute(this.di.model.id, () => {
                    this.router.navigate(['/offer/all']);

                });
            }
        });
    }

    editGeneralInfo() {
        const modalRef = this.modalService.show(UpdateOfferTitleComponent, {backdrop: 'static', keyboard: false, class: 'modal-xl'});
        modalRef.content.dataInput = this.di.model;
    }

    finalize() {
        this.confirmationDialogService.confirm(
            'Finalizeaza oferta',
            'Urmeaza sa finalizati oferta. Sunteti sigur?',
            'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
            if (confirmed) {
                this.finalizeOfferAP.execute(this.di.model.id, this.di.model);
            }
        });
    }

    acceptOffer() {
        const modalRef = this.modalService.show(ResponseToOfferComponent, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-xl'
        });
        modalRef.content.dataInput = new ResponseToOfferInput(this.di.model, true);
    }
    declineOffer() {
        const modalRef = this.modalService.show(ResponseToOfferComponent, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-xl'
        });
        modalRef.content.dataInput = new ResponseToOfferInput(this.di.model, false);
    }

    generateOffer() {
        this.generateOfferAP.execute(this.offerId);
    }
}
