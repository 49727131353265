// @ts-ignore

import {BaseActionProxy} from "../../../core/shared/base/base.action-proxy";
import {IWebComponent} from "../../../core/shared/base/base.component";
import {Store} from "@ngrx/store";
import * as store from "../../../core/app-store";
import {Observable} from "rxjs";
import {
    GetInfoFromCuiAnafEvent,
} from "../../../core/app-store/events/organization-client.events";
import {Organization} from "../../../core/app-dto/organization.dto";

export class GetOrganizationClientFromAnafActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent, appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(cui: string, res: (result: Organization) => void): any {
        if (this.canExecute()) {
            this.appState.dispatch(new GetInfoFromCuiAnafEvent(cui, res));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}
