import { Component, HostBinding, Input, OnInit } from '@angular/core';
import {
  CalendarEventCardPosition
} from '../../../core/app-dto/calendar-event.dto';

@Component({
  selector: 'app-disabled-interval',
  templateUrl: './disabled-interval.component.html',
  styleUrls: ['./disabled-interval.component.scss']
})
export class DisabledIntervalComponent implements OnInit {

  public minHeightForDetails = 80;
  public minWidthForVertical = 80;
  @Input('cardPosition') cardPosition: CalendarEventCardPosition;

  @HostBinding('style.width.px')
  get width(): number {
    return this.cardPosition.width;
  }

  @HostBinding('style.height.px')
  get height(): number {
    return this.cardPosition.height;
  }

  @HostBinding('style.top.px')
  get top(): number {
    return this.cardPosition.top;
  }

  @HostBinding('style.left.px')
  get left(): number {
    return this.cardPosition.left + 60;
    // + this.calendarEvent.cardPosition.order *
      //  (this.calendarEvent.cardPosition.width - 4) / this.calendarEvent.cardPosition.overlapping;
  }

  constructor() {}

  ngOnInit() {
  }
}
