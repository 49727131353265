import {Base_Dto, BaseCRUDRules, BaseKeyNameEntity, BusinessRule, IdName_Dto, IdNameNumber_Dto} from './misc.dto';
import {Address} from "./core.dto";
import {ObjectValidators} from "../shared/object.validators";
import {isValidObject} from "../shared/helpers/common.helpers";
import * as moment from "moment";

export enum SubscriptionRecurreceType {
    Monthly,
    Yearly
}

export class SubscriptionProduct{
    public name: string;
    public description: string;
    public isPrimary: boolean;
    public reccurrenceType: SubscriptionRecurreceType;
    public price: number;
    public currency: string;
    public dbSubscriptionTypeId: string;

    constructor(data: any) {
        if (ObjectValidators.isValidObject(data)) {
            this.name = data.name;
            this.description = data.description;
            this.isPrimary = data.isPrimary;
            this.reccurrenceType = data.reccurrenceType;
            this.price = data.price;
            this.currency = data.currency;
            this.dbSubscriptionTypeId = data.dbSubscriptionTypeId;
        }
    }
}

export class Subscription {
    public name: string;
    public isActive: boolean;
    public isDiscounted: boolean;
    public products: Array<SubscriptionProduct> = [];
    public primaryProduct: SubscriptionProduct;
    public expiresOn: Date;
    public customerId: string;
    public subscriptionId: string;
    public subscriptionState: string;
    public paymentMethodId: string = '';
    public needPaymentMethodUpdate?: boolean;

    constructor(data: any) {
        if (ObjectValidators.isValidObject(data)) {
            this.name = data.name;
            this.isActive = data.isActive;
            this.isDiscounted = data.isDiscounted;

            if (ObjectValidators.isValidObject(data.products) && ObjectValidators.isValidNonEmptyArray(data.products)) {
                this.products = data.products.map(x => new SubscriptionProduct(x));
                this.primaryProduct = this.products.find(x => x.isPrimary);
            }

            if (ObjectValidators.isValidObject(data.expiresOn))
                this.expiresOn = moment.utc(data.expiresOn).toDate();

            if (ObjectValidators.isValidObject(data.stripeData)) {
                this.customerId = data.stripeData.customerId;
                this.subscriptionId = data.stripeData.subscriptionId;
                this.subscriptionState = data.stripeData.subscriptionState;
                this.paymentMethodId = data.stripeData.paymentMethodId;
                this.needPaymentMethodUpdate = ObjectValidators.isValidObject(data.stripeData.paymentMethodUpdateNeeded) ? data.stripeData.paymentMethodUpdateNeeded : false;
            }
            else {
                this.customerId = data.customerId;
                this.subscriptionId = data.subscriptionId;
                this.subscriptionState = data.subscriptionState;
                this.paymentMethodId = data.paymentMethodId;
                this.needPaymentMethodUpdate = data.needPaymentMethodUpdate;
            }
        }
    }
}

export class OrganizationNotificationSettingsProfile {
    public shouldNotifyClientsBeforeAppointment: boolean;
    public shouldNotifyClientsWhenCarIsInWork: boolean;
    public shouldNotifyClientsWhenCarIsFinished: boolean;
    public shouldGenerateReminderForInsurance: boolean;
    public shouldGenerateReminderForITP: boolean;

    constructor(data: any) {
        if (ObjectValidators.isValidObject(data)) {
            this.shouldNotifyClientsBeforeAppointment = data.shouldNotifyClientsBeforeAppointment || false;
            this.shouldNotifyClientsWhenCarIsInWork = data.shouldNotifyClientsWhenCarIsInWork || false;
            this.shouldNotifyClientsWhenCarIsFinished = data.shouldNotifyClientsWhenCarIsFinished || false;
            this.shouldGenerateReminderForInsurance = data.shouldGenerateReminderForInsurance || false;
            this.shouldGenerateReminderForITP = data.shouldGenerateReminderForITP || false;
        }
    }
}

export class OrganziationRules extends BaseCRUDRules {
    public canActivate: BusinessRule;
    public canDeactivate: BusinessRule;
    public canRequestDeactivation: BusinessRule;
    public canValidate: BusinessRule;
    public canDeleteLogoUrl: BusinessRule;
    public canDownloadCui: BusinessRule;
    public canDeleteCuiImage: BusinessRule;
    public canBuySMS: BusinessRule;
    public canChangeSubscriptionType: BusinessRule;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.canActivate = new BusinessRule(data.canActivate);
            this.canDeactivate = new BusinessRule(data.canDeactivate);
            this.canRequestDeactivation = new BusinessRule(data.canRequestDeactivation);
            this.canValidate = new BusinessRule(data.canValidate);
            this.canDeleteLogoUrl = new BusinessRule(data.canDeleteLogoUrl);
            this.canDownloadCui = new BusinessRule(data.canDownloadCui);
            this.canDeleteCuiImage = new BusinessRule(data.canDeleteCuiImage);
            this.canBuySMS = new BusinessRule(data.canBuySMS);
            this.canChangeSubscriptionType = new BusinessRule(data.canChangeSubscriptionType);

        }
        else {
            this.canActivate = new BusinessRule();
            this.canDeactivate = new BusinessRule();
            this.canRequestDeactivation = new BusinessRule();
            this.canDeleteLogoUrl = new BusinessRule();
            this.canValidate = new BusinessRule();
            this.canDownloadCui = new BusinessRule();
            this.canDeleteCuiImage = new BusinessRule();
            this.canBuySMS = new BusinessRule();
            this.canChangeSubscriptionType = new BusinessRule();
        }
    }
}

export class Organization extends Base_Dto {
    public name: string;
    public description: string;
    public displayImageUrl: string;
    public cuiImageUrl: string;
    public taxId: string;
    public registrationNumber: string;
    public type: BaseKeyNameEntity;
    public address: Address;
    public website: string;
    public representativePersonName: string;
    public representativePersonTitle: string;
    public representativePersonPhoneNumber: string;
    public representativePersonEmail: string;
    public isValidated: boolean;
    public isTrial: boolean;
    public requestedToBeDeactivated: boolean;
    public invoiceSeries: string;
    public invoiceFooter: string;
    public tva: number;
    public supplySeries: string;
    public receiptSeries: string;
    public subscription: Subscription;
    public addons: Addon[];
    public isArchived: boolean;
    public archivedOn: Date;
    public archivedBy: string;
    public isUsed: boolean;
    public rules: OrganziationRules;
    public notificationSettings: OrganizationNotificationSettingsProfile;
    public workingHoursSettings: OrganizationWorkingHoursProfile;
    public currency: string;
    public bankAccounts: Array<BankAccount>;

    public createdOn: Date;
    public updatedOn: Date;
    public authorizationNumber: string;
    public totalNumberOfSMSAvailable: number;
    public exportEmailReceivers: string;
    public hasVatOnCashing: boolean = false;
    public hasReverseCharge: boolean = false;

    constructor(data: any) {
        super(data);
        if (isValidObject(data)) {
            this.name = data.name;
            this.hasVatOnCashing = data.hasVatOnCashing;
            this.hasReverseCharge = data.hasReverseCharge;
            this.currency = data.currency;
            this.authorizationNumber = data.authorizationNumber;
            this.description = data.description;
            this.cuiImageUrl = data.cuiImageUrl;
            this.displayImageUrl = data.displayImageUrl;
            this.taxId = data.taxId;
            this.registrationNumber = data.registrationNumber;
            if (ObjectValidators.isValidObject(data.address))
                this.address = new Address(data.address);
            if (ObjectValidators.isValidObject(data.type))
                this.type = new BaseKeyNameEntity(data.type);
            this.website = data.website;
            this.representativePersonName = data.representativePersonName;
            this.representativePersonTitle = data.representativePersonTitle;
            this.representativePersonPhoneNumber = data.representativePersonPhoneNumber;
            this.representativePersonEmail = data.representativePersonEmail;
            this.isValidated = data.isValidated;
            this.invoiceSeries = data.invoiceSeries;
            this.invoiceFooter = data.invoiceFooter;
            this.tva = data.tva;
            this.supplySeries = data.supplySeries;
            this.receiptSeries = data.receiptSeries;
            this.isTrial = data.isTrial;
            this.requestedToBeDeactivated = data.requestedToBeDeactivated;
            if (ObjectValidators.isValidObject(data.subscription))
                this.subscription = new Subscription(data.subscription);
            if (ObjectValidators.isValidObject(data.addons))
                this.addons = data.addons;
            this.isArchived = data.isArchived;
            if (ObjectValidators.isValidObject(data.archivedOn))
                this.archivedOn = moment.utc(data.archivedOn).toDate();
            this.archivedBy = data.archivedBy;
            this.isUsed = data.isUsed;
            this.rules = new OrganziationRules(data.rules);
            this.totalNumberOfSMSAvailable = data.totalNumberOfSMSAvailable;
            this.exportEmailReceivers = data.exportEmailReceivers;
            this.notificationSettings = new OrganizationNotificationSettingsProfile(data.notificationSettings);
            this.workingHoursSettings = new OrganizationWorkingHoursProfile(data.workingHoursSettings);
            if (ObjectValidators.isValidObject(data.createdOn))
                this.createdOn = moment.utc(data.createdOn).toDate();
            if (ObjectValidators.isValidObject(data.updatedOn))
                this.updatedOn = moment.utc(data.updatedOn).toDate();
            if (ObjectValidators.isValidObject(data.bankAccounts))
                this.bankAccounts = data.bankAccounts;
        }
    }
}

export class OrganizationWorkingHoursProfile {
    public workingHours: Array<DayWorkingHourProfile>;
    public daysOff: Array<DayOff>;

    constructor(data: any) {
        if (ObjectValidators.isValidObject(data)) {
            if (ObjectValidators.isValidArray(data.workingHours))
                this.workingHours = data.workingHours.map(r => new DayWorkingHourProfile(r));
            else
                this.workingHours = [];
            if (ObjectValidators.isValidArray(data.daysOff))
                this.daysOff = data.daysOff.map(r => new DayOff(r));
            else
                this.daysOff = [];
        } else {
            this.workingHours = [];
            this.daysOff = [];
        }
    }
}

export class DayWorkingHourProfile {
    public key: number;
    public isOpened: boolean;
    public hoursRange: Array<HoursRange>;

    constructor(data: any) {
        if (ObjectValidators.isValidObject(data)) {
            this.key = data.key;
            this.isOpened = data.isOpened;
            this.hoursRange = data.hoursRange;
        }
    }
}

export class DayOff {
    public date: Date;

    constructor(data: any) {
        if (ObjectValidators.isValidObject(data)) {
            if (ObjectValidators.isValidObject(data.date))
                this.date = moment.utc(data.date).toDate();
        }
    }
}

export class HoursRange {
    public fromHours: number;
    public fromMinutes: number;
    public toHours: number;
    public toMinutes: number;

    constructor(data: any) {
        if (ObjectValidators.isValidObject(data)) {
            this.fromHours = data.fromHours;
            this.fromMinutes = data.fromMinutes;
            this.toHours = data.toHours;
            this.toMinutes = data.toMinutes;
        }
    }
}

export class OrganizationCostRules extends BaseCRUDRules {
    public canActivate: BusinessRule;
    public canDeactivate: BusinessRule;
    public canDeleteMonthly: BusinessRule;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.canActivate = new BusinessRule(data.canActivate);
            this.canDeactivate = new BusinessRule(data.canDeactivate);
            this.canDeleteMonthly = new BusinessRule(data.canDeleteMonthly);
        }
        else {
            this.canActivate = new BusinessRule();
            this.canDeactivate = new BusinessRule();
            this.canDeleteMonthly = new BusinessRule();
        }
    }
}

export class OrganizationCost extends Base_Dto {
    public organizationId: string;
    public createdOn: Date;

    public name: string;
    public description: string;
    public value: number;
    public valueHasVat: boolean;
    public valueWithVat: number;
    public vatValue: number;
    public currency: IdName_Dto;
    public isMonthly: boolean;
    public isActive: boolean;
    public startingDate: Date;
    public endingDate: Date;
    public templateId: string;
    public rules: OrganizationCostRules;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.organizationId = data.organizationId;
            this.id = data.id;
            if (ObjectValidators.isValidObject(data.createdOn))
                this.createdOn = moment.utc(data.createdOn).toDate();

            this.name = data.name;
            this.description = data.description;
            this.value = data.value;
            this.valueHasVat = data.valueHasVat;
            this.valueWithVat = data.valueWithVat;
            this.vatValue = data.vatValue;
            this.currency = data.currency;
            this.isMonthly = data.isMonthly;
            this.isActive = data.isActive;
            this.templateId = data.templateId;
            this.rules = new OrganizationCostRules(data.rules);
            if (ObjectValidators.isValidObject(data.startingDate))
                this.startingDate = moment.utc(data.startingDate).toDate();
            if (ObjectValidators.isValidObject(data.endingDate))
                this.endingDate = moment.utc(data.endingDate).toDate();
        } else {
            this.isMonthly = false;
        }
    }
}

export class BankAccount {
    public iban: string;
    public bank: string;
    public currency: string;
    public swift: string;

    constructor(data: any) {
        if (ObjectValidators.isValidObject(data)) {
            this.iban = data.iBAN;
            this.bank = data.bank;
            this.currency = data.currency;
            this.swift = data.sWIFT;
        }
    }
}

export enum AddonRecurreceType {
    Monthly
}

export enum AddonTypes {
    Stock
}

export class AddonType {
    public id: string;
    public name: string;
    public description: string;
    public type: AddonTypes;
    public reccurrenceType: AddonRecurreceType;
    public price: number;
    public currency: string;

    constructor(data: any) {
        if (ObjectValidators.isValidObject(data)) {
            this.id = data.id;
            this.name = data.name;
            this.description = data.description;
            this.type = data.type;
            this.reccurrenceType = data.reccurrenceType;
            this.price = data.price;
            this.currency = data.currency;
        }
    }
}

export class Addon {
    public addonType: AddonType;
    public expiresOn: Date;
    public isActive: boolean;
    public deactivationEnabled: boolean;

    constructor(data: any) {
        if (ObjectValidators.isValidObject(data)) {
            this.isActive = data.isActive || false;
            this.addonType = new AddonType(data.addonType);
            if (ObjectValidators.isValidObject(data.expiresOn))
                this.expiresOn = moment.utc(data.expiresOn).toDate();
        }
    }
}

export class Preorder {
    public id: string;
    public addon: Addon;
    public organization: Organization;

    constructor(data: any) {
        if (ObjectValidators.isValidObject(data)) {
            this.id = data.id;
            this.addon = new Addon(data.addon);
            this.organization = new Organization(data.organization);
        }
    }
}
