import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import {BaseSandboxWithCurrentUserLoaded} from 'src/app/core/shared/base/base.sandbox';
import { Organization } from '../../../core/app-dto/organization.dto';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { Do_ResetStateForSection, ResetSection } from '../../../core/app-store/events/base.events';
import {OrganizationClient_Dto} from '../../../core/app-dto/organization-client.dto';

@Injectable()
export class CarServiceClientSandbox extends BaseSandboxWithCurrentUserLoaded {
    public organization: Organization;
    public getOrganizationClient$ = this.appState$.pipe(select(store.getOrganizationClient));
    public clientForCar$ = this.appState$.pipe(select(store.getOrganizationClientFromCar));
    public clientForCar: OrganizationClient_Dto = null;
    public organizationClient: OrganizationClient_Dto = null;
    public isCarComponentTriggered: boolean = false;

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
    }

    public initialize(dataLoaded, userLoaded: () => void): any {
        this.clearSandboxState();
        this.subscriptions.push(this.clientForCar$.subscribe((data: OrganizationClient_Dto) => {
            this.clientForCar = new OrganizationClient_Dto(data);
            if (data != null) {
                this.isCarComponentTriggered = true;
            } else {
                this.isCarComponentTriggered = false;
            }
            dataLoaded(data);
        }));
        this.loadUser(() => {
            userLoaded();
        });
        /*this.subscriptions.push(this.getOrganizationClient$.subscribe((data: OrganizationClient_Dto) => {
            if (ObjectValidators.isValidObject(data)) {
                this.organizationClient = new OrganizationClient_Dto(data);
            }
        }));*/
    }

    public resetCarForClient() {
        this.appState$.dispatch(new Do_ResetStateForSection(ResetSection.CarForClient));
    }
}
