import { Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import { map, switchMap } from 'rxjs/operators';
import {
  Do_GenericSuccessEvent,
  Do_GenericErrorEvent,
  EventWithScreenLoading,
  SeamlessEvent
} from 'src/app/core/app-store/events/base.events';
import { PagedResult } from 'src/app/core/shared/datatable/datatable.helpers';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import {Mechanic_Dto, MechanicWithActionsAndCar_Dto} from 'src/app/core/app-dto/mechanic.dto';
import { MechanicApiService } from 'src/app/core/api-integration/services/mechanic-api.service';
import {
    ActivateMechanicEvent,
    DeactivateMechanicEvent,
    DeleteMechanicEvent,
    EditMechanicEvent,
    GetMechanicEvent,
    GetMechanicsPagedResultEvent,
    RegisterMechanicEvent,
    GetMechanicListEvent,
    AddUserAccountForMechanicEvent,
    DeleteAccountForMechanicEvent, GetMechanicsWithStatusEvent, GetMechanicAvailabilityEvent
} from '../../core/app-store/events/mechanic.events';
import {isValidObject} from "../../core/shared/helpers/common.helpers";
import { GetServicePlatformAvailabilityEvent } from '../../core/app-store/events/service-platform.events';


@Injectable()
export class MechanicUIEventProcessor {

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private mechanicApiService: MechanicApiService
    ) {
    }
  @Effect()
  getMechanicsWithStatus$: Observable<any> = this.actions$
    .pipe(
      ofType(GetMechanicsWithStatusEvent.identifier, SeamlessEvent.identifier + GetMechanicsWithStatusEvent.identifier),
      map((action: GetMechanicsWithStatusEvent) => action),
      switchMap((action: GetMechanicsWithStatusEvent) => {
        return new Observable((observer) => {
          this.mechanicApiService.u_getMechanicsWithStatusList()
            .subscribe((res: Array<MechanicWithActionsAndCar_Dto>) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<Array<MechanicWithActionsAndCar_Dto>>(res, action));
              }
              if(action.callback != null) action.callback();
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

    @Effect()
    GetMechanicsPagedResultEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetMechanicsPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetMechanicsPagedResultEvent.identifier),
            map((action: GetMechanicsPagedResultEvent) => action),
            switchMap((action: GetMechanicsPagedResultEvent) => {
                return new Observable((observer) => {
                    this.mechanicApiService.u_getFilteredMechanicsPaginated(action.parameters)
                        .subscribe((res: PagedResult<Mechanic_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<Mechanic_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    getMechanic$: Observable<any> = this.actions$
        .pipe(
            ofType(GetMechanicEvent.identifier, EventWithScreenLoading.identifier + GetMechanicEvent.identifier),
            map((action: GetMechanicEvent) => action),
            switchMap((action: GetMechanicEvent) => {
                return new Observable((observer) => {
                    this.mechanicApiService.u_getMechanic(action.id)
                        .subscribe((res: Mechanic_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Mechanic_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    getMechanicList$: Observable<any> = this.actions$
        .pipe(
            ofType(GetMechanicListEvent.identifier, SeamlessEvent.identifier + GetMechanicListEvent.identifier),
            map((action: GetMechanicListEvent) => action),
            switchMap((action: GetMechanicListEvent) => {
                return new Observable((observer) => {
                    this.mechanicApiService.u_getMechanicList()
                        .subscribe((res: Array<Mechanic_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Array<Mechanic_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    doRegisterMechanic$: Observable<any> = this.actions$
        .pipe(
            ofType(RegisterMechanicEvent.identifier, EventWithScreenLoading.identifier + RegisterMechanicEvent.identifier),
            map((action: RegisterMechanicEvent) => action),
            switchMap((action: RegisterMechanicEvent) => {
                return new Observable((observer) => {
                    this.mechanicApiService.u_registerMechanic(action.model)
                        .subscribe((res: Mechanic_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Mechanic_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doEditMechanic$: Observable<any> = this.actions$
        .pipe(
            ofType(EditMechanicEvent.identifier, EventWithScreenLoading.identifier + EditMechanicEvent.identifier),
            map((action: EditMechanicEvent) => action),
            switchMap((action: EditMechanicEvent) => {
                return new Observable((observer) => {
                    this.mechanicApiService.u_editMechanic(action.model, action.updatedHistory)
                        .subscribe((res: Mechanic_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Mechanic_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doDeleteMechanic$: Observable<any> = this.actions$
        .pipe(
            ofType(DeleteMechanicEvent.identifier, EventWithScreenLoading.identifier + DeleteMechanicEvent.identifier),
            map((action: DeleteMechanicEvent) => action),
            switchMap((action: DeleteMechanicEvent) => {
                return new Observable((observer) => {
                    this.mechanicApiService.u_deleteMechanic(action.id)
                        .subscribe((res: Mechanic_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Mechanic_Dto>(res, action));
                            if(isValidObject(action.callback)) action.callback();
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doActivateMechanic$: Observable<any> = this.actions$
        .pipe(
            ofType(ActivateMechanicEvent.identifier, EventWithScreenLoading.identifier + ActivateMechanicEvent.identifier),
            map((action: ActivateMechanicEvent) => action),
            switchMap((action: ActivateMechanicEvent) => {
                return new Observable((observer) => {
                    this.mechanicApiService.u_activateMechanic(action.id, action.startDate)
                        .subscribe((res: Mechanic_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Mechanic_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

    @Effect()
    doDeactivateMechanic$: Observable<any> = this.actions$
        .pipe(
            ofType(DeactivateMechanicEvent.identifier, EventWithScreenLoading.identifier + DeactivateMechanicEvent.identifier),
            map((action: DeactivateMechanicEvent) => action),
            switchMap((action: DeactivateMechanicEvent) => {
                return new Observable((observer) => {
                    this.mechanicApiService.u_deactivateMechanic(action.id, action.endDate)
                        .subscribe((res: Mechanic_Dto) => {
                            observer.next(new Do_GenericSuccessEvent<Mechanic_Dto>(res, action));
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }
            )
        );

  @Effect()
  doAddAccountForMechanic$: Observable<any> = this.actions$
    .pipe(
      ofType(AddUserAccountForMechanicEvent.identifier, EventWithScreenLoading.identifier + AddUserAccountForMechanicEvent.identifier),
      map((action: AddUserAccountForMechanicEvent) => action),
      switchMap((action: AddUserAccountForMechanicEvent) => {
          return new Observable((observer) => {
            this.mechanicApiService.u_registerMechanicAccount(action.model)
              .subscribe((res: Mechanic_Dto) => {
                observer.next(new Do_GenericSuccessEvent<Mechanic_Dto>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );


  @Effect()
  doRemoveAccountForMechanic$: Observable<any> = this.actions$
    .pipe(
      ofType(DeleteAccountForMechanicEvent.identifier, EventWithScreenLoading.identifier + DeleteAccountForMechanicEvent.identifier),
      map((action: DeleteAccountForMechanicEvent) => action),
      switchMap((action: DeleteAccountForMechanicEvent) => {
          return new Observable((observer) => {
            this.mechanicApiService.u_deleteAccountForMechanic(action.id)
              .subscribe((res: Mechanic_Dto) => {
                observer.next(new Do_GenericSuccessEvent<Mechanic_Dto>(res, action));
                if(action.callback) action.callback();
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

    @Effect()
    getServicePlatformAvailability$: Observable<any> = this.actions$
        .pipe(
            ofType(GetMechanicAvailabilityEvent.identifier, SeamlessEvent.identifier + GetMechanicAvailabilityEvent.identifier),
            map((action: GetMechanicAvailabilityEvent) => action),
            switchMap((action: GetMechanicAvailabilityEvent) => {
                return new Observable((observer) => {
                    this.mechanicApiService.u_getMechanicAvailability(action.startDate, action.endDate, action.mechanicId, action.except, action.relatedEntityType)
                        .subscribe((res: boolean) => {
                            if (ObjectValidators.isValidObject(res)) {
                                action.callback(res);
                                observer.next(new Do_GenericSuccessEvent<boolean>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            action.callback(false);
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
}
