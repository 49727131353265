import { Component, OnInit, OnDestroy } from '@angular/core';
import {BaseWebComponent, BaseWebComponentWithBreadcrumb} from 'src/app/core/shared/base/base.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GetSupplyActionProxy } from './get-supply.action-proxy';
import { SupplyDetailsSandbox } from './supply-details.sandbox';
import { SupplyDetailsDi } from './supply-details.di';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { Supply_Dto } from '../../../core/app-dto/supply.dto';
import { BsModalService } from 'ngx-bootstrap';
import { ActivateSupplyActionProxy } from '../supplies-list/activate-supply.action-proxy';
import { DeactivateSupplyActionProxy } from '../supplies-list/deactivate-supply.action-proxy';
import { DeleteSupplyActionProxy } from '../supplies-list/delete-supply.action-proxy';
import { ConfirmationDialogService} from '../../../core/core/dialog/confirmation-dialog.service';
import { ActiveDataTableParameters } from '../../../core/shared/datatable/datatable.helpers';
import { ConfigService } from '../../../core/core/app-config.service';
import { MarkInvoiceAsPaidActionProxy } from './mark-invoice-as-paid.action-proxy';
import { MarkInvoiceAsNotPaidActionProxy } from './mark-invoice-as-not-paid.action-proxy';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { GetSupplyNoteLinkActionProxy } from './get-supply-note-link.action-proxy';
import {Breadcrumb} from "../../../core/app-dto/breadcrumb.dto";

@Component({
  selector: 'app-supply-details',
  templateUrl: './supply-details.component.html',
  styleUrls: ['./supply-details.component.scss']
})
export class SupplyDetailsComponent extends BaseWebComponentWithBreadcrumb implements OnInit, OnDestroy {
  public getSupplyAP: GetSupplyActionProxy;
  public activateSupplyAP: ActivateSupplyActionProxy;
  public deactivateSupplyAP: DeactivateSupplyActionProxy;
  public deleteSupplyAP: DeleteSupplyActionProxy;
  public markInvoiceAsPaidAP: MarkInvoiceAsPaidActionProxy;
  public markInvoiceAsNotPaidAP: MarkInvoiceAsNotPaidActionProxy;
  public supplyId: string = null;
  public dtOptions;
  public isStorno: boolean;
  public title: string;
  public objectName: string;
  public di: SupplyDetailsDi;

  constructor(public sandbox: SupplyDetailsSandbox,
              public activatedRoute: ActivatedRoute,
              private configService: ConfigService,
              public router: Router, public confirmationDialogService: ConfirmationDialogService,
              private modalService: BsModalService) {
    super(sandbox, ResetSection.Supply);
    this.getSupplyAP = new GetSupplyActionProxy(this, sandbox.appState);
    this.activateSupplyAP = new ActivateSupplyActionProxy(this, sandbox.appState);
    this.deactivateSupplyAP = new DeactivateSupplyActionProxy(this, sandbox.appState);
    this.deleteSupplyAP = new DeleteSupplyActionProxy(this, sandbox.appState);
    this.markInvoiceAsPaidAP = new MarkInvoiceAsPaidActionProxy(this, sandbox.appState);
    this.markInvoiceAsNotPaidAP = new MarkInvoiceAsNotPaidActionProxy(this, sandbox.appState);
    this.di = new SupplyDetailsDi();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id !== undefined) {
        this.supplyId = params.id;
        this.getSupplyAP.execute(this.supplyId);
      }
    });

    this.initialize((data: Supply_Dto) => {
      this.isStorno = data.isStorno;
      this.title = this.isStorno ? 'Stornari' : 'Receptii';
      this.objectName = this.isStorno ? 'Stornare' : 'Receptie';
      this.di = new SupplyDetailsDi(data);
      this.setBreadcrumbAP.execute([ new Breadcrumb({text: 'Acasa', url: '/dashboard'}),new Breadcrumb({text: 'Receptii', url:'/supply/all?isStorno=' + this.isStorno}),
        new Breadcrumb({text:this.objectName + ' : ' + data.number})]);
      this.dataLoaded = true;
    });

    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      pageLength: this.configService.getDefaultPageSize(),
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      ajax: (dataTablesParameters: ActiveDataTableParameters, callback) => {
      },
      columns: [
        { data: 'nrCrt', width: '7%', orderable: false },
        { data: 'nameCode', width: '20%' },
        { data: 'documentedQuantity', width: '15%' },
        { data: 'receivedQuantity', width: '15%' },
        { data: 'acquisitionPriceWithoutVat', width: '10%' },
        { data: 'acquisitionPriceWithVat', width: '10%' },
        { data: 'totalVat', width: '10%' },
        { data: 'vatValue', width: '10%' },
        { data: 'createdOn', width: '0', orderable: false }
      ],
      order: [[4, 'desc']],
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
  }

  ngOnDestroy() {
    this.destroy();
  }

  deleteSupply(supplyId: string) {
    this.confirmationDialogService.confirm(
      `Stergere ${this.objectName}`,
      `Urmeaza sa stergeti aceasta ${this.objectName}. Sunteti sigur?`,
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        this.deleteSupplyAP.execute(supplyId, () => {
          if (this.isStorno) {
            this.router.navigate(['/supply/all'], { queryParams: { isStorno: this.isStorno } });
          } else {
            this.router.navigate(['/supply/all']);
          }
        });
      }
    });
  }

  markAsPaid() {
    this.markInvoiceAsPaidAP.execute(this.di.model.id);
  }

  markAsNotPaid() {
    this.markInvoiceAsNotPaidAP.execute(this.di.model.id);
  }

  downloadSupplyNote() {
    const ap = new GetSupplyNoteLinkActionProxy(this, this.sandbox.appState);
    ap.execute(this.di.model.id, (url) => {
      if (ObjectValidators.isValidString(url)) {
        window.open(url, '_self');
      }
    });
  }
}
