import { Injectable } from "@angular/core";
import { BaseApiService } from "./base-api.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { from, Observable, Subscriber } from "rxjs";
import { ApiErrorDto } from "../utils/api-error.dto";
import { Token } from "@angular/compiler";
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { CachingUtils } from '../../shared/caching.utils';
import { ActiveDataTableParameters, PagedResult } from '../../shared/datatable/datatable.helpers';
import { RequestSubscription_Dto, DeactivateSubscription_Dto } from '../../app-dto/authentication.dto';
import { Organization, Preorder } from '../../app-dto/organization.dto';
import { ErrorHelpers } from '../../shared/error.helpers';
import { CarService_Dto } from '../../app-dto/car-service.dto';

class SubscriptionApiRoutes {

  public static route_for_GET_SUBSCRIPTIONS_PAGINATED() {
    return '/api/organization/all/paginated';
  }

  public static route_for_GET_SUBSCRIPTION(id: string) {
    return '/api/organization/' + id;
  }

  public static route_for_GET_SUBSCRIPTION_PROFILE() {
    return '/api/organization/';
  }

  public static route_for_EDIT_SUBSCRIPTION() {
    return '/api/organization/';
  }
  public static route_for_CHANGE_SUBSCRIPTION_PLAN() {
    return '/api/organization/subscription-type';
  }

  public static route_for_EDIT_SUBSCRIPTION_PROFILE() {
    return '/api/organization/profile';
  }

  public static route_for_DEACTVATE_SUBSCRIPTION() {
    return '/api/organization/deactivate/';
  }

  public static route_for_VALIDATE_SUBSCRIPTION(id: string) {
    return `/api/organization/validate/${id}`;
  }

  public static route_for_GET_SUBSCRIPTION_CUI_URL(organizationId:string) {
    return `/api/organization/${organizationId}/cui`;
  }

  public static route_for_DELETE_SUBSCRIPTION_CUI_URL() {
    return `/api/organization/cui`;
  }

  public static route_for_DELETE_SUBSCRIPTION_PROFILE_IMAGE_URL() {
    return `/api/organization/profile-image`;
  }

  public static route_for_ACTVATE_ADDON(addonId: string) {
    return `/api/organization/activate-addon/${addonId}`;
  }

  public static route_for_DEACTVATE_ADDON(addonId: string) {
    return `/api/organization/deactivate-addon/${addonId}`;
  }

  public static route_for_CANCEL_DEACTVATE_ADDON(addonId: string) {
    return `/api/organization/cancel-deactivate-addon/${addonId}`;
  }

  public static route_for_REGISTER_PREORDER_ADDON(addonId: string) {
    return `/api/organization/register-preorder/${addonId}`;
  }
  public static route_for_GET_PAY_BUTTON_ADDON(preorderId: string) {
    return `/api/organization/payButton/${preorderId}`;
  }
}

@Injectable()
export class SubscriptionApiService extends BaseApiService {

  constructor(private http: HttpClient, configService: ConfigService) {
    super(configService);
  }
  u_changeSubscriptionPlan(organizationId:string, subscriptionTypeId:string): Observable<Organization> {
    const url = this.configService.getAPIBaseUrl() + SubscriptionApiRoutes.route_for_CHANGE_SUBSCRIPTION_PLAN();

    return this.http.put(url, {
      organizationId:organizationId,
      subscriptionTypeId:subscriptionTypeId
    })
      .map((res: any) => {
        return new Organization(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }
  u_getFilteredSubscriptionsPaginated(data: ActiveDataTableParameters): Observable<PagedResult<Organization>> {
    const url = this.configService.getAPIBaseUrl() + SubscriptionApiRoutes.route_for_GET_SUBSCRIPTIONS_PAGINATED() + '?' + data.toUrlParameters();

    return this.http.get(url)
      .map((res: any) => {
        return new PagedResult<Organization>(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getSubscription(data: string): Observable<Organization> {
    const url = this.configService.getAPIBaseUrl() + SubscriptionApiRoutes.route_for_GET_SUBSCRIPTION(data);

    return this.http.get(url)
      .map((res: any) => {
        return new Organization(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getSubscriptionProfile(): Observable<Organization> {
    const url = this.configService.getAPIBaseUrl() + SubscriptionApiRoutes.route_for_GET_SUBSCRIPTION_PROFILE();

    return this.http.get(url)
      .map((res: any) => {
        return new Organization(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_editSubscription(data: Organization): Observable<Organization> {
    const url = this.configService.getAPIBaseUrl() + SubscriptionApiRoutes.route_for_EDIT_SUBSCRIPTION();

    return this.http.put(url, data)
      .map((res: any) => {
        return new Organization(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_editSubscriptionProfile(data: Organization): Observable<Organization> {
    const url = this.configService.getAPIBaseUrl() + SubscriptionApiRoutes.route_for_EDIT_SUBSCRIPTION_PROFILE();

    return this.http.put(url, data)
      .map((res: any) => {
        return new Organization(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_deactivateSubscription(model: DeactivateSubscription_Dto): Observable<Organization> {
    const url = this.configService.getAPIBaseUrl() + SubscriptionApiRoutes.route_for_DEACTVATE_SUBSCRIPTION();

    return this.http.put(url, model)
      .map((res: any) => {
        return new Organization(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_validateSubscription(id: string): Observable<Organization> {
    const url = this.configService.getAPIBaseUrl() + SubscriptionApiRoutes.route_for_VALIDATE_SUBSCRIPTION(id);

    return this.http.get(url)
      .map((res: any) => {
        return new Organization(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_getCuiUrl(organizationId: string): Observable<string> {
    const url = this.configService.getAPIBaseUrl() + SubscriptionApiRoutes.route_for_GET_SUBSCRIPTION_CUI_URL(organizationId);

    return this.http.get(url)
      .map((res: any) => {
        return res.value;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_deleteCuiUrl(): Observable<string> {
    const url = this.configService.getAPIBaseUrl() + SubscriptionApiRoutes.route_for_DELETE_SUBSCRIPTION_CUI_URL();

    return this.http.delete(url)
      .map((res: any) => {
        return res.value;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_deleteProfileImageUrl(): Observable<string> {
    const url = this.configService.getAPIBaseUrl() + SubscriptionApiRoutes.route_for_DELETE_SUBSCRIPTION_PROFILE_IMAGE_URL();

    return this.http.delete(url)
      .map((res: any) => {
        return res.value;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }

  u_activateAddon(addonId: string): Observable<Organization> {
    const url = this.configService.getAPIBaseUrl() + SubscriptionApiRoutes.route_for_ACTVATE_ADDON(addonId);

    return this.http.put(url, {})
        .map((res: any) => {
          return new Organization(res);
        })
        .catch<any, any>((err: ApiErrorDto, o) => {
          return ErrorHelpers.throwError(err);
        });
  }

  u_deactivateAddon(addonId: string): Observable<Organization> {
    const url = this.configService.getAPIBaseUrl() + SubscriptionApiRoutes.route_for_DEACTVATE_ADDON(addonId);

    return this.http.put(url, addonId)
        .map((res: any) => {
          return new Organization(res);
        })
        .catch<any, any>((err: ApiErrorDto, o) => {
          return ErrorHelpers.throwError(err);
        });
  }

  u_cancelDeactivateAddon(addonId: string): Observable<Organization> {
    const url = this.configService.getAPIBaseUrl() + SubscriptionApiRoutes.route_for_CANCEL_DEACTVATE_ADDON(addonId);

    return this.http.put(url, addonId)
        .map((res: any) => {
          return new Organization(res);
        })
        .catch<any, any>((err: ApiErrorDto, o) => {
          return ErrorHelpers.throwError(err);
        });
  }

  u_registerPreorderAddon(addonId: string): Observable<any> {
    const url = this.configService.getAPIBaseUrl() + SubscriptionApiRoutes.route_for_REGISTER_PREORDER_ADDON(addonId);

    return this.http.put(url, {})
        .map((res: any) => {
          return new Preorder(res);
        })
        .catch<any, any>((err: ApiErrorDto, o) => {
          return ErrorHelpers.throwError(err);
        });
  }

  u_getPayButtonAddon(preorderId: string): Observable<any> {
    const url = this.configService.getAPIBaseUrl() + SubscriptionApiRoutes.route_for_GET_PAY_BUTTON_ADDON(preorderId);

    return this.http.get(url, { responseType: 'text' })
        .map((res: string) => {
          return res;
        })
        .catch<any, any>((err: ApiErrorDto, o) => {
          return ErrorHelpers.throwError(err);
        });
  }
}
