import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar-current-time',
  templateUrl: './calendar-current-time.component.html',
  styleUrls: ['./calendar-current-time.component.scss']
})
export class CalendarCurrentTimeComponent implements OnInit {

  public currentTime: string = '';
  public calendarLineHeight = 56;
  public calendarLinePadding = 10;
  public top = 0;
  public showCurrentTimeLine = false;

  @Input('minStartHour') minStartHour: number;
  @Input('minStartMinute') minStartMinute: number;
  @Input('maxEndHour') maxEndHour: number;
  @Input('maxEndMinute') maxEndMinute: number;
  @ViewChild('currentTimeContainer', {static: false}) currentTimeContainer: ElementRef;


  @HostBinding('style.top.px')
  get getTop(): number {
    return this.top;
  }

  constructor() {}

  ngOnInit() {
    this.setCurrentTime();
    setInterval(this.setCurrentTime.bind(this), 1000);
  }

  setCurrentTime(): void {
    const now = moment();
    if (now > moment(`${this.minStartHour}:${this.minStartMinute}`, 'HH:mm') &&
        now < moment(`${this.maxEndHour}:${this.maxEndMinute}`, 'HH:mm')) {
      this.currentTime = now.format('HH:mm');
      const topDiff = now.diff(moment(`${this.minStartHour}:${this.minStartMinute}`, 'HH:mm'));
      this.showCurrentTimeLine = true;
      this.top = topDiff / 1000 / 3600 * this.calendarLineHeight - this.calendarLinePadding;
    } else {
      this.showCurrentTimeLine = false;
    }
  }

}
