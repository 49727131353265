import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShowErrorsComponent} from './components/show-errors/show-errors.component';
import {CustomFormsModule} from 'ngx-custom-validators';
import {SearchComponent} from './components/search/search.component';
import {SearchSandbox} from './components/search/search.sandbox';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddressComponent} from './components/address/address.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {StaticDataSelectorComponent} from './components/static-data-selector/static-data-selector.component';
import {CarPartsTableComponent} from './car-parts-table/car-parts-table.component';
import {DataTablesModule} from 'angular-datatables';
import {RouterModule} from '@angular/router';

import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {MomentModule} from 'ngx-moment';
import {NgxSpinnerModule} from 'ngx-spinner';
import {YesNoComponent} from './components/yes-no/yes-no.component';
import {ImageHelpers} from './image.helpers';
import {ServifyButtonComponent} from './components/servify-button/servify-button.component';
import {ServifyButtonSandbox} from './components/servify-button/servify-button.sandbox';
import {ServifySelectSandbox} from './components/servify-sort/servify-select.sandbox';
import {ServifySelectComponent} from './components/servify-sort/servify-select.component';
import {ServifyPaginationComponent} from './components/servify-pagination/servify-pagination.component';
import {ServifyPaginationSandbox} from './components/servify-pagination/servify-pagination.sandbox';
import {EventStatusButtonComponent} from './components/event-status-button/event-status-button.component';
import {CalendarShortcutAddResourcesComponent} from './components/calendar-shortcut-add-resources/calendar-shortcut-add-resources.component';
import {ServifySearchComponent} from './components/servify-search/servify-search.component';
import {FiscalPrintInvoiceComponent} from "./components/fiscal-print-invoice/fiscal-print-invoice.component";
import {FiscalPrintInvoiceSandbox} from "./components/fiscal-print-invoice/fiscal-print-invoice.sandbox";
import {FiscalPrintButtonComponent} from "./components/fiscal-print-button/fiscal-print-button.component";
import {CarStatusLabelComponent} from "./components/car-status-label/car-status-label.component";
import {ReceiptComponent} from './components/receipt/receipt.component';
import {ReceiptSandbox} from './components/receipt/receipt.sandbox';
import {BsDatepickerModule} from 'ngx-bootstrap';

@NgModule({
  declarations: [
    ShowErrorsComponent,
    SearchComponent,
    AddressComponent,
    StaticDataSelectorComponent,
    CarPartsTableComponent,
    ConfirmDialogComponent,
    YesNoComponent,
    ServifyButtonComponent,
    ServifySelectComponent,
    ServifyPaginationComponent,
    EventStatusButtonComponent,
    ServifySearchComponent,
    CalendarShortcutAddResourcesComponent,
    FiscalPrintInvoiceComponent,
    FiscalPrintButtonComponent,
    CarStatusLabelComponent,
    ReceiptComponent
  ],
    imports: [
        CommonModule,
        CustomFormsModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        DataTablesModule,
        RouterModule,
        MomentModule,
        NgxSpinnerModule,
        BsDatepickerModule
    ],
  exports: [
    ShowErrorsComponent,
    SearchComponent,
    AddressComponent,
    StaticDataSelectorComponent,
    CarPartsTableComponent,
    MomentModule,
    NgxSpinnerModule,
    YesNoComponent,
    ServifyButtonComponent,
    ServifySelectComponent,
    ServifyPaginationComponent,
    EventStatusButtonComponent,
    CalendarShortcutAddResourcesComponent,
    FiscalPrintInvoiceComponent,
    FiscalPrintButtonComponent,
    CarStatusLabelComponent,
    ReceiptComponent
  ],
  providers: [
    SearchSandbox,
    ImageHelpers,
    ServifyButtonSandbox,
    ServifySelectSandbox,
    ServifyPaginationSandbox,
    FiscalPrintInvoiceSandbox,
    ReceiptSandbox
  ],
  entryComponents: [
    ConfirmDialogComponent,
    FiscalPrintInvoiceComponent,
    ReceiptComponent
  ]
})
export class SharedModule {
}
