import {Base_Dto, BaseCRUDRules} from './misc.dto';
import {ObjectValidators} from '../shared/object.validators';

export class ReceiptRules extends BaseCRUDRules {
    constructor(data: any) {
        super(data);
    }
}

export class Receipt_Dto extends Base_Dto {
    public number: number;
    public computedNumber: string;
    public receiptSeries: string;
    public value: number;
    public currency: string;
    public invoiceId: string;
    public organizationId: string;
    public invoiceComputedNumber: string;
    public invoiceDate: Date;
    public sentOn: Date;
    public notes: string;
    public rules: ReceiptRules;

    constructor(data?: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.number = data.number;
            this.computedNumber = data.computedNumber;
            this.receiptSeries = data.receiptSeries;
            this.value = data.value;
            this.currency = data.currency;
            this.invoiceId = data.invoiceId;
            this.organizationId = data.organizationId;
            this.invoiceComputedNumber = data.invoiceComputedNumber;
            this.invoiceDate = data.invoiceDate;
            this.sentOn = data.sentOn;
            this.notes = data.notes;
            if (ObjectValidators.isValidObject(data.rules)) {
                this.rules = new ReceiptRules(data.rules);
            } else {
                this.rules = new ReceiptRules(null);
            }
        } else {
            this.rules = new ReceiptRules(null);
        }
    }
}

export class CreateReceipt_Dto {
    public value: number;
    public issueDate: Date;
    public representing: string;

    constructor(data) {
        this.value = data;
    }
}
