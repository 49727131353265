import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { ClientCarParts_Dto } from '../../../core/app-dto/car-service.dto';

export class CarServiceDetailsClientCarPartsDi extends BaseFormDI<Array<ClientCarParts_Dto>> {
    constructor(data: Array<ClientCarParts_Dto> = new Array<ClientCarParts_Dto>(null)) {
        super(data);
    }
    initForm() {
    }

    getModel(): Array<ClientCarParts_Dto> {
        return this.model;
    }
}
