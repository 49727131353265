import {Component, OnInit, AfterViewInit, Input} from '@angular/core';
import { ConfigService } from 'src/app/core/core/app-config.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap';
import { GetCarServiceActionsActionProxy } from './get-car-service-actions.action-proxy';
import { CarServiceActionListSandbox } from './car-service-action-list.sandbox';
import { CarServiceAddActionComponent } from '../car-service-add-action/car-service-add-action.component';
import { BaseWebComponent } from 'src/app/core/shared/base/base.component';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { CarServiceActionListItemDI } from './car-service-action-list-item.di';
import { CarAction_Dto } from 'src/app/core/app-dto/car-service.dto';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { ConfirmationDialogService } from 'src/app/core/core/dialog/confirmation-dialog.service';
import { DeleteCarServiceActionsActionProxy } from './delete-car-service-action.action-proxy';
import { FileUploadTarget } from 'src/app/core/app-dto/core.dto';
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";

@Component({
  selector: 'app-car-service-action-list',
  templateUrl: './car-service-action-list.component.html',
  styleUrls: ['./car-service-action-list.component.scss']
})
export class CarServiceActionListComponent extends BaseWebComponent implements OnInit, AfterViewInit {
  public getCarServiceActionsAP: GetCarServiceActionsActionProxy;
  public deleteCarServiceActionAP: DeleteCarServiceActionsActionProxy;

  public id: string = null;
  public data: Array<CarServiceActionListItemDI> = [];
  public fileUploadTarget = FileUploadTarget;

  @Input() isReadOnly: boolean = false;
  @Input() canAddNewItems: boolean = false;
  @Input() canManageAllInfo: boolean = false;

  constructor(public sandbox: CarServiceActionListSandbox,
              private configService: ConfigService,
              public router: Router,
              private modalService: BsModalService,
              public activatedRoute: ActivatedRoute,
              public confirmationDialogService: ConfirmationDialogService,
              public rulesProvider:AppUserBusinessRuleProvider
  ) {
    super(sandbox, ResetSection.Cars);
    this.getCarServiceActionsAP = new GetCarServiceActionsActionProxy(this, sandbox.appState);
    this.deleteCarServiceActionAP = new DeleteCarServiceActionsActionProxy(this, sandbox.appState);
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id !== undefined) {
        this.id = params.id;
        this.getCarServiceActionsAP.execute(this.id);
      }
    });
    this.initialize((data: Array<CarAction_Dto>) => {
      if (ObjectValidators.isValidArray(data))
        this.data = data.map(r => new CarServiceActionListItemDI(r));
    });
  }

  addAction() {
    const initialState = {
      carServiceEntryId: this.id,
      canChangeAllInfo : this.canManageAllInfo
    }
    this.modalService.show(CarServiceAddActionComponent,
        { initialState, backdrop: 'static', keyboard: false, class: 'modal-xl' });
  }

  editAction(data: CarAction_Dto) {
    const initialState = {
      carServiceEntryId: this.id,
      canChangeAllInfo : this.canManageAllInfo,
      data
    }
    this.modalService.show(CarServiceAddActionComponent,
        { initialState, backdrop: 'static', keyboard: false, class: 'modal-xl' });
  }

  deleteAction(id: string) {
    this.confirmationDialogService.confirm(
      'Stergere lucrare.',
      'Urmeaza sa stergeti lucrare. Sunteti sigur?',
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
        if (confirmed) {
          this.deleteCarServiceActionAP.execute(id);
        }
      });
  }
}
