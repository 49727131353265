import {PagedResult} from '../../shared/datatable/datatable.helpers';
import {Action} from '@ngrx/store';
import {
    Do_GenericErrorEvent,
    Do_GenericSuccessEvent,
    Do_ResetStateForSection,
    EventWithScreenLoading,
    EventWithStopingScreenLoader,
    ResetSection,
    SeamlessEvent
} from '../events/base.events';
import {ResetStateActionProxy} from '../../shared/base/base.action-proxy';
import {CarPart_Dto, CarPartAggregate_Dto} from '../../app-dto/car-part.dto';
import {
    ActivateCarPartEvent,
    ActivateCarPartStockAlertEvent,
    DeactivateCarPartEvent,
    DeactivateCarPartStockAlertEvent,
    DeleteCarPartEvent, DeleteSupplyItemEvent,
    EditCarPartEvent,
    GetCarPartEvent,
    GetCarPartsPagedResultEvent,
    GetCarPartStockAlertsPagedResultEvent,
    GetExcelCarPartsImportTemplateEvent,
    GetSupplyItemsForCarPartPagedResultEvent, GetTotalCarPartsValueEvent,
    RegisterCarPartEvent,
    SearchCarPartEvent,
    SearchCarPartForSupplyEvent
} from '../events/car-part.events';
import * as moment from 'moment';
import {ObjectValidators} from '../../shared/object.validators';
import {SupplyItem_Dto} from "../../app-dto/supply.dto";

export interface CarPartState {
    lastRefreshTime: number;
    supplyItemslastRefreshTime: number;
    carPartAggregate: CarPartAggregate_Dto;
    carPart: CarPart_Dto;
    carPartsPagedResult: PagedResult<CarPart_Dto>;
    supplyItemsForCarPartPagedResult: PagedResult<SupplyItem_Dto>;
    carPartSearchList: Array<CarPart_Dto>;
    carPartStocksPagedResult: PagedResult<CarPart_Dto>;
    carPartsImportTemplatrUrl: string;
    totalCarPartsValue:number;
}

export const INITIAL_STATE: CarPartState = Object.assign({}, {
    lastRefreshTime: 0,
    supplyItemslastRefreshTime: 0,
    carPart: null,
    carPartAggregate: null,
    carPartsPagedResult: new PagedResult<CarPart_Dto>([]),
    supplyItemsForCarPartPagedResult: new PagedResult<SupplyItem_Dto>([]),
    carPartSearchList: [],
    carPartStocksPagedResult: new PagedResult<CarPart_Dto>([]),
    carPartsImportTemplatrUrl: '',
    totalCarPartsValue : 0
});


export function reducer(state = INITIAL_STATE, action: Action): CarPartState {
    if (!action) return state;
    let actionType = action.type;

    actionType = actionType.replace(EventWithScreenLoading.identifier, '');
    actionType = actionType.replace(SeamlessEvent.identifier, '');
    actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
    switch (actionType) {
        // Get Car Part

        case Do_ResetStateForSection.identifier + ResetSection.CarPart: {
            return Object.assign({}, state, {
                carPartAggregate: null,
                supplyItemsForCarPartPagedResult: new PagedResult<SupplyItem_Dto>([])
            });
        }

        case Do_GenericSuccessEvent.identifier + GetCarPartEvent.identifier: {
            const carPartData = new CarPartAggregate_Dto((action as Do_GenericSuccessEvent<CarPartAggregate_Dto>).data);
            return Object.assign({}, state, {carPartAggregate: carPartData});
        }

        case Do_GenericErrorEvent.identifier + GetCarPartEvent.identifier: {
            return Object.assign({}, state, {
                carPartAggregate: null,
                supplyItemsForCarPartPagedResult: new PagedResult<SupplyItem_Dto>([])
            });
        }

        case ResetStateActionProxy.name + GetCarPartEvent.identifier: {
            return Object.assign({}, state, {
                carPartAggregate: null,
                supplyItemsForCarPartPagedResult: new PagedResult<SupplyItem_Dto>([])
            });
        }

        // /Get Car Part

        // Get Car Parts

        case Do_ResetStateForSection.identifier + ResetSection.CarParts: {
            return Object.assign({}, state, {carPartsPagedResult: new PagedResult<CarPart_Dto>([])});
        }

        case GetCarPartsPagedResultEvent.identifier: {
            if ((action as GetCarPartsPagedResultEvent).withReset) {
                state.carPartsPagedResult = new PagedResult<CarPart_Dto>([]);
                return Object.assign({}, state);
            }
            return state;
        }
        case GetSupplyItemsForCarPartPagedResultEvent.identifier: {
            if ((action as GetSupplyItemsForCarPartPagedResultEvent).withReset) {
                state.supplyItemsForCarPartPagedResult = new PagedResult<SupplyItem_Dto>([]);
                return Object.assign({}, state);
            }
            return state;
        }
        case Do_GenericSuccessEvent.identifier + GetSupplyItemsForCarPartPagedResultEvent.identifier: {
            const data = (action as Do_GenericSuccessEvent<PagedResult<SupplyItem_Dto>>).data;
            state.supplyItemsForCarPartPagedResult = new PagedResult<SupplyItem_Dto>(data);
            return Object.assign({}, state);
        }

        case Do_GenericErrorEvent.identifier + GetSupplyItemsForCarPartPagedResultEvent.identifier: {
            state.supplyItemsForCarPartPagedResult = new PagedResult<SupplyItem_Dto>([]);
            return Object.assign({}, state);
        }


        case Do_GenericSuccessEvent.identifier + GetCarPartsPagedResultEvent.identifier: {
            const data = (action as Do_GenericSuccessEvent<PagedResult<CarPart_Dto>>).data;
            state.carPartsPagedResult = new PagedResult<CarPart_Dto>(data);
            return Object.assign({}, state);
        }

        case Do_GenericErrorEvent.identifier + GetCarPartsPagedResultEvent.identifier: {
            state.carPartsPagedResult = new PagedResult<CarPart_Dto>([]);
            return Object.assign({}, state);
        }

        // Get Car Parts

        // Get Car Part Stock Alerts

        case Do_ResetStateForSection.identifier + ResetSection.CarPartStockAlerts: {
            return Object.assign({}, state, {carPartStocksPagedResult: new PagedResult<CarPart_Dto>([])});
        }

        case GetCarPartStockAlertsPagedResultEvent.identifier: {
            if ((action as GetCarPartStockAlertsPagedResultEvent).withReset) {
                state.carPartStocksPagedResult = new PagedResult<CarPart_Dto>([]);
                return Object.assign({}, state);
            }
            return state;
        }

        case Do_GenericSuccessEvent.identifier + GetCarPartStockAlertsPagedResultEvent.identifier: {
            const data = (action as Do_GenericSuccessEvent<PagedResult<CarPart_Dto>>).data;
            state.carPartStocksPagedResult = new PagedResult<CarPart_Dto>(data);
            return Object.assign({}, state);
        }

        case Do_GenericErrorEvent.identifier + GetCarPartStockAlertsPagedResultEvent.identifier: {
            state.carPartStocksPagedResult = new PagedResult<CarPart_Dto>([]);
            return Object.assign({}, state);
        }

        // Get Car Part Stock Alerts

        // Search Car Part

        case Do_GenericSuccessEvent.identifier + SearchCarPartEvent.identifier: {
            const carPartSearchData = (action as Do_GenericSuccessEvent<Array<CarPart_Dto>>).data.map(r => new CarPart_Dto(r));
            return Object.assign({}, state, {carPartSearchList: carPartSearchData});
        }

        case Do_GenericErrorEvent.identifier + SearchCarPartEvent.identifier: {
            return Object.assign({}, state, {carPartSearchList: []});
        }

        case Do_GenericSuccessEvent.identifier + SearchCarPartForSupplyEvent.identifier: {
            const carPartSearchData = (action as Do_GenericSuccessEvent<Array<CarPart_Dto>>).data.map(r => new CarPart_Dto(r));
            return Object.assign({}, state, {carPartSearchList: carPartSearchData});
        }

        case Do_GenericErrorEvent.identifier + SearchCarPartForSupplyEvent.identifier: {
            return Object.assign({}, state, {carPartSearchList: []});
        }

        // Search Car Part

        case Do_GenericSuccessEvent.identifier + GetExcelCarPartsImportTemplateEvent.identifier: {
            const data = (action as Do_GenericSuccessEvent<string>).data;
            state.carPartsImportTemplatrUrl = data;
            return Object.assign({}, state);
        }

        case Do_GenericSuccessEvent.identifier + RegisterCarPartEvent.identifier: {
            return Object.assign({}, state, {lastRefreshTime: moment().unix(), supplyItemslastRefreshTime: moment().unix()});
        }
        case Do_GenericSuccessEvent.identifier + EditCarPartEvent.identifier: {
            return Object.assign({}, state, {
                lastRefreshTime: moment().unix(),
                supplyItemslastRefreshTime: moment().unix()
            });
        }

        case Do_GenericSuccessEvent.identifier + DeactivateCarPartEvent.identifier:
        case Do_GenericSuccessEvent.identifier + ActivateCarPartEvent.identifier:
        case Do_GenericSuccessEvent.identifier + DeactivateCarPartStockAlertEvent.identifier:
        case Do_GenericSuccessEvent.identifier + ActivateCarPartStockAlertEvent.identifier: {
            const data = new CarPart_Dto((action as Do_GenericSuccessEvent<CarPart_Dto>).data);
            if (ObjectValidators.isValidObject(state.carPart) && state.carPart.id == data.id) {
                state.carPart = data;
            }
            if (ObjectValidators.isValidObject(state.carPartAggregate) && state.carPartAggregate.carPart.id === data.id) {
                state.carPartAggregate = {
                    carPart: data,
                    supplies: state.carPartAggregate.supplies
                };
            }
            state.lastRefreshTime = moment().unix();
            state.supplyItemslastRefreshTime = moment().unix();
            return Object.assign({}, state);
        }

        case Do_GenericSuccessEvent.identifier + DeleteCarPartEvent.identifier: {
            state.lastRefreshTime = moment().unix();
            return Object.assign({}, state);
        }
        case Do_GenericSuccessEvent.identifier + DeleteSupplyItemEvent.identifier: {
            state.supplyItemslastRefreshTime = moment().unix();
            return Object.assign({}, state);
        }
        case Do_GenericSuccessEvent.identifier + GetTotalCarPartsValueEvent.identifier: {
            state.totalCarPartsValue = (action as Do_GenericSuccessEvent<number>).data;
            return Object.assign({}, state);
        }

        default: {
            return state;
        }
    }
}

export const getTimestampCallback = (state: CarPartState) => state.lastRefreshTime;
export const getTotalCarPartsValueCallback = (state: CarPartState) => state.totalCarPartsValue;
export const getSupplyItemsTimestampCallback = (state: CarPartState) => state.supplyItemslastRefreshTime;
export const getCarPartCallback = (state: CarPartState) => state.carPart;
export const getCarPartAggregateCallback = (state: CarPartState) => state.carPartAggregate;
export const getCarPartsPagedResultCallback = (state: CarPartState) => state.carPartsPagedResult;
export const getSupplyItemForCarPartPagedResultCallback = (state: CarPartState) => state.supplyItemsForCarPartPagedResult;
export const getCarPartSearchListCallback = (state: CarPartState) => state.carPartSearchList;
export const getCarPartStocksPagedResultCallback = (state: CarPartState) => state.carPartStocksPagedResult;
export const getCarPartsImportTemplateCallback = (state: CarPartState) => state.carPartsImportTemplatrUrl;
