import { Injectable } from '@angular/core';
import * as store from '../../../app-store/index';
import { Store, select } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ResetSection, Do_ResetStateForSection } from 'src/app/core/app-store/events/base.events';
import { BaseComponentState } from 'src/app/core/app-store/utils/base-component.state';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import {Router} from '@angular/router';

@Injectable()
export class ResetPasswordSandbox extends BaseSandbox {

    public resetPassword$ = this.appState$.pipe(select(store.getResetPassword));
    private loginRoute: string = 'auth/login';

    constructor(private router: Router,
                protected appState$: Store<store.State>) {
        super(appState$);
    }

    clearSandboxState() {
    }

    initialize(authMessage: (message: string) => {}) {
        this.clearSandboxState();
        this.appState$.dispatch(new Do_ResetStateForSection(ResetSection.ResetPassword));

        this.subscriptions.push(this.resetPassword$.subscribe((state: BaseComponentState) => {
            if (ObjectValidators.isValidObject(state)) {
                authMessage(state.stateMessage);
                if (state.loaded)
                    this.router.navigate([this.loginRoute]);
            }
        }));
    }
}
