import {BaseActionProxy} from '../../../core/shared/base/base.action-proxy';
import {IWebComponent} from '../../../core/shared/base/base.component';
import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {GenerateInvoiceForCarServiceEntry} from '../../../core/app-store/events/invoices.events';
import {Observable} from 'rxjs';
import {GenerateOffer} from '../../../core/app-store/events/offer.events';

export class GenerateOfferActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(carServiceId: string): any {
        this.appState.dispatch(new GenerateOffer(carServiceId));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}
