import { EventWithScreenLoading, SeamlessEvent, UIGeneratedEvent, } from './base.events';
import { ActiveDataTableParameters } from '../../shared/datatable/datatable.helpers';
import { OrganizationClient_Dto } from '../../app-dto/organization-client.dto';
import {Organization} from "../../app-dto/organization.dto";

export class GetOrganizationClientsPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetOrganizationClientsPagedResultEvent";

  constructor(public parameters: ActiveDataTableParameters, public withReset: boolean = false) {
    super();
    this.type += GetOrganizationClientsPagedResultEvent.identifier;
  }
}

export class RegisterOrganizationClientEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "RegisterOrganizationClientEvent";

  constructor(public model: OrganizationClient_Dto) {
    super();
    this.type += RegisterOrganizationClientEvent.identifier;
  }
}

export class GetOrganizationClientEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetOrganizationClientEvent";

  constructor(public id: string, public callback: (data: OrganizationClient_Dto) => void) {
    super();
    this.type += GetOrganizationClientEvent.identifier;
  }
}


export class EditOrganizationClientEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "EditOrganizationClientEvent";

  constructor(public model: OrganizationClient_Dto) {
    super();
    this.type += EditOrganizationClientEvent.identifier;
  }
}


export class DeleteOrganizationClientEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeleteOrganizationClientEvent";

  constructor(public id: string, public callback: Function = null) {
    super();
    this.type += DeleteOrganizationClientEvent.identifier;
  }
}

export class ActivateOrganizationClientEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "ActivateOrganizationClientEvent";

  constructor(public id: string) {
    super();
    this.type += ActivateOrganizationClientEvent.identifier;
  }
}

export class DeactivateOrganizationClientEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeactivateOrganizationClientEvent";

  constructor(public id: string) {
    super();
    this.type += DeactivateOrganizationClientEvent.identifier;
  }
}

export class SearchOrganizationClientEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "SearchOrganizationClientEvent";

  constructor(public search: string, public callBack: Function) {
    super();
    this.type += SearchOrganizationClientEvent.identifier;
  }
}

export class GetOrganizationClientCreditEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetOrganizationClientCreditEvent";

  constructor(public clientId: string) {
    super();
    this.type += GetOrganizationClientCreditEvent.identifier;
  }
}

export class GetInfoFromCuiAnafEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetInfoFromCuiAnafEvent";

  constructor(public cui: string, public callback: (result: Organization) => void) {
    super();
    this.type += GetInfoFromCuiAnafEvent.identifier;
  }
}

export class ResetInfoFromCuiEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "ResetInfoFromCuiEvent";

  constructor() {
    super();
    this.type += ResetInfoFromCuiEvent.identifier;
  }
}
