import {IdNameKey_Dto} from "../../../core/app-dto/misc.dto";
import {isValidObject} from "../../../core/shared/helpers/common.helpers";
import {CalendarEventState} from "../../../core/app-dto/calendar-event.dto";

export class CarActionDiHelpers{
  public static isWaitingStatus(status:IdNameKey_Dto) : boolean{
    if(isValidObject(status)){
      return status.key == "Waiting";
    }
    return false;
  }
  public static isInProgressStatus(status:IdNameKey_Dto) : boolean{
    if(isValidObject(status)){
      return status.key == "InProgress";
    }
    return false;
  }
  public static isReadyStatus(status:IdNameKey_Dto) : boolean{
    if(isValidObject(status)){
      return status.key == "Ready";
    }
    return false;
  }
  public static isNewStatus(status:IdNameKey_Dto) : boolean{
    if(isValidObject(status)){
      return status.key == "New";
    }
    return false;
  }

  public static getCalendarEventStatus(status:IdNameKey_Dto):CalendarEventState{
    if(isValidObject(status)==false) return CalendarEventState.Todo;
    switch (status.key)  {
      case 'New': return CalendarEventState.Reception;
      case 'Ready': return CalendarEventState.Done;
      case 'InProgress': return CalendarEventState.InProgress;
      case 'Waiting': return CalendarEventState.Waiting;

    }
  }
}
