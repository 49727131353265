import { State } from '../state';
import * as fromSupply from '../stores/supply.store';
import { createSelector } from '@ngrx/store';

export const getSupplyStateCallback = (state: State) => state.supply;

export const getSupplyTimestamp = createSelector(getSupplyStateCallback, fromSupply.getTimestampCallback);
export const getSupply = createSelector(getSupplyStateCallback, fromSupply.getSupplyCallback);
export const getSuppliesListing = createSelector(getSupplyStateCallback, fromSupply.getSuppliesPagedResultCallback);
export const getAllSuppliesListing = createSelector(getSupplyStateCallback, fromSupply.getSuppliesCallback);
export const getSuppliesUnpaidInvoiceListing = createSelector(getSupplyStateCallback, fromSupply.getSuppliesUnpaidInvoicePagedResultCallback);

