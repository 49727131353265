import { Injectable } from "@angular/core";
import { BsModalService } from "ngx-bootstrap";
import { Observable } from "rxjs";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { NotificationDialogComponent } from "./notification-dialog/notification-dialog.component";

@Injectable()
export class ConfirmationDialogService {

  constructor(private modalService: BsModalService) {
  }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm' | 'lg' = 'lg',
    centered: boolean = true): Observable<boolean> {
    const modalRef = this.modalService.show(ConfirmationDialogComponent, { backdrop: 'static', keyboard: false, class: 'modal-md' });
    modalRef.content.title = title;
    modalRef.content.message = message;
    modalRef.content.btnOkText = btnOkText;
    modalRef.content.btnCancelText = btnCancelText;
    return modalRef.content.onClose;
  }
  public info(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    dialogSize: 'sm' | 'lg' = 'lg',
    centered: boolean = true): Observable<boolean> {
    const modalRef = this.modalService.show(NotificationDialogComponent, { backdrop: 'static', keyboard: false, class: 'modal-md' });
    modalRef.content.title = title;
    modalRef.content.message = message;
    modalRef.content.btnOkText = btnOkText;

    return modalRef.content.onClose;;
  }
}
