import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { IdName_Dto } from 'src/app/core/app-dto/misc.dto';
import { Car_Dto } from 'src/app/core/app-dto/car.dto';

@Injectable()
export class CarSearchSandbox extends BaseSandbox {
    public getCarSearchList$ = this.appState$.pipe(select(store.getCarSearchList));

    public originalCarSearchList: Array<Car_Dto> = [];

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    clearSandboxState() {
        this.originalCarSearchList = [];
    }

    initialize(searchList: (data: Array<IdName_Dto>) => {}) {
        this.clearSandboxState();

        this.subscriptions.push(this.getCarSearchList$.subscribe((data: Array<Car_Dto>) => {
            if (ObjectValidators.isValidArray(data)) {
                this.originalCarSearchList = data;
                searchList(data.map(r => new IdName_Dto({
                    id: r.id,
                    name: r.registrationNumber + " " + r.make + " " + r.model
                })));
            }
        }));
    }
}
