import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { NgxSpinnerService } from 'ngx-spinner';
import { GetServicePlatformStatusesEvent } from "../../../core/app-store/events/service-platform-status.events";

export class GetServicePlatformStatusesActionProxy extends BaseActionProxy {
  constructor(component: IWebComponent, appState: Store<store.State>, private spinner: NgxSpinnerService) {
    super(component, appState);
  }

  canExecute(): boolean {
    return true;
  }

  execute(): any {
    if (this.canExecute()) {
      setTimeout(() => {
        this.spinner.show('servicePlatformListDashboard');
      }, 0);
      this.appState.dispatch(new GetServicePlatformStatusesEvent(() => {
        this.spinner.hide('servicePlatformListDashboard');
      }));
    }
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }
}

