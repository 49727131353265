import {BaseFormDI} from 'src/app/core/shared/base/base.di';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CarAppointment_Dto} from '../../../core/app-dto/car-appointment.dto';
import {DateTimeService} from '../../../core/core/services/date-time.service';
import {StaticDataSelectorInput} from '../../../core/shared/components/static-data-selector/static-data-selector.component';
import * as moment from "moment";

export class AppointmentDetailsDi extends BaseFormDI<CarAppointment_Dto> {
  endTime: Date;
  startTime: Date;
  public paymentInAdvanceCurrenciesDataInput: StaticDataSelectorInput;

  constructor(public dateTimeService: DateTimeService, data: CarAppointment_Dto = new CarAppointment_Dto(null)) {
    super(new CarAppointment_Dto(data));

    this.startTime = data.startDate;
    this.endTime = data.endDate;

    this.initForm();

    this.paymentInAdvanceCurrenciesDataInput = {
      form: this.form,
      type: 'currencies',
      formControlName: 'paymentInAdvanceCurrency',
      isRequired: true,
      label: 'Moneda',
      isReadOnly: false
    };
  }

  getModel(): CarAppointment_Dto {
    this.model.startDate = this.form.value.startDate;
    this.model.endDate = this.form.value.endDate;
    this.model.startDate.setHours(this.form.value.startTime ? this.form.value.startTime.getHours() : new Date().getHours(),
      this.form.value.startTime ? this.form.value.startTime.getMinutes() : new Date().getMinutes(), 0, 0);
    this.model.startDate = this.dateTimeService.toUTC(this.model.startDate);
    this.model.endDate.setHours(this.form.value.endTime ? this.form.value.endTime.getHours() : new Date().getHours(),
      this.form.value.endTime ? this.form.value.endTime.getMinutes() : new Date().getMinutes(), 0, 0);
    this.model.endDate = this.dateTimeService.toUTC(this.model.endDate);
    this.model.paymentInAdvance = this.form.value.paymentInAdvance || 0;
    this.model.paymentInAdvanceCurrency = this.form.value.paymentInAdvanceCurrency != null ?
      this.form.value.paymentInAdvanceCurrency.name : null;

    this.model.mechanic = this.form.value.mechanic;
    this.model.servicePlatform = this.form.value.servicePlatform;

    return this.model;
  }

  initForm() {
    if (!this.startTime) {
      this.startTime = new Date();
      this.startTime.setHours(12);
      this.startTime.setMinutes(0);
      this.startTime.setSeconds(0);
      this.startTime.setMilliseconds(0);
    }
    if (!this.endTime) {
      this.endTime = new Date();
      this.endTime.setHours(13);
      this.endTime.setMinutes(0);
      this.startTime.setSeconds(0);
      this.startTime.setMilliseconds(0);
    }
    this.form = new FormGroup({
      startDate: new FormControl(this.model.startDate || new Date(), Validators.compose([Validators.required])),
      startTime: new FormControl(this.startTime, Validators.compose([Validators.required])),
      endDate: new FormControl(this.model.endDate || new Date(), Validators.compose([Validators.required])),
      endTime: new FormControl(this.endTime, Validators.compose([Validators.required])),
      paymentInAdvance: new FormControl(this.model.paymentInAdvance, Validators.compose([Validators.min(0)])),
      mechanic: new FormControl(this.model.mechanic || null, Validators.compose([])),
      servicePlatform: new FormControl(this.model.servicePlatform || null, Validators.compose([Validators.required]))
    });
    this.selectedStartDate = new Date(this.form.value.startDate.getFullYear(), this.form.value.startDate.getMonth(), this.form.value.startDate.getDate(),this.form.value.startTime.getHours(),this.form.value.startTime.getMinutes(),0,0);
    this.selectedEndDate = new Date(this.form.value.endDate.getFullYear(), this.form.value.endDate.getMonth(), this.form.value.endDate.getDate(),this.form.value.endTime.getHours(),this.form.value.endTime.getMinutes(),0,0);
  }

  public selectedStartDate: Date;
  public selectedEndDate: Date;


  public startDateChanged(data: Date) {
    this.model.endDate = this.form.value.startDate;
    this.form.controls.endDate.setValue(this.form.value.startDate);
    if (data && this.form.value.startTime) {
      this.selectedStartDate = new Date(data.getFullYear(), data.getMonth(), data.getDate(),this.form.value.startTime.getHours(),this.form.value.startTime.getMinutes(),0,0);
    }
    else this.selectedStartDate = null;
  }

  startTimeChanged(data: Date) {
    if (data) {
      if(this.selectedStartDate) {
        this.selectedStartDate = new Date(this.selectedStartDate.getFullYear(), this.selectedStartDate.getMonth(), this.selectedStartDate.getDate(), this.form.value.startTime.getHours(), this.form.value.startTime.getMinutes(), 0, 0);
      }
    }
    else this.selectedStartDate = null;
  }
  endDateChanged(data: Date) {
    if (data && this.form.value.endTime) {
      this.selectedEndDate = new Date(data.getFullYear(), data.getMonth(), data.getDate(),this.form.value.endTime.getHours(),this.form.value.endTime.getMinutes(),0,0);
    }
    else this.selectedEndDate = null;
  }
  endTimeChanged(data: Date) {
    if (data) {
      if(this.selectedEndDate) {
        this.selectedEndDate = new Date(this.selectedEndDate.getFullYear(), this.selectedEndDate.getMonth(), this.selectedEndDate.getDate(), this.form.value.endTime.getHours(), this.form.value.endTime.getMinutes(), 0, 0);
      }
    }
    else this.selectedEndDate = null;
  }
  public busyFlag: boolean = false;
  busyStateFlagChanged(value: boolean) {
    this.busyFlag = value;
  }
}
