import { Component, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import {
  BaseListWebComponent,
  BaseListWebComponentWithSearchAndActiveBehavior
} from 'src/app/core/shared/base/base.component';
import { DataTableDirective } from 'angular-datatables';
import { Subscription } from 'rxjs/Subscription';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { SeamlessEvent, ResetSection } from 'src/app/core/app-store/events/base.events';
import { SearchEvent } from 'src/app/core/app-store';
import {
  ActiveDataTableParameters,
  CarServiceListDataTableParameters, DataTableParametersOrderEntity
} from 'src/app/core/shared/datatable/datatable.helpers';
import { GetCarServiceListActionProxy } from './get-car-service-list.action-proxy';
import { CarServiceListSandbox } from './car-service-list.sandbox';
import { AddCarInServiceComponent } from '../add-car-in-service/add-car-in-service.component';
import { BsModalService } from 'ngx-bootstrap';
import { CarServiceDetailsComponent } from '../car-service-details/car-service-details.component';
import { ConfigService } from 'src/app/core/core/app-config.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { filter } from 'rxjs/operators';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';
import { AddEditCarInServiceWizardComponent } from '../add-edit-car-in-service-wizard/add-edit-car-in-service-wizard.component';
import {IdName_Dto} from "../../../core/app-dto/misc.dto";
import {CarWork_Dto} from "../../../core/app-dto/car-work.dto";


@Component({
  selector: 'app-car-service-list',
  templateUrl: './car-service-list.component.html',
  styleUrls: ['./car-service-list.component.scss']
})
export class CarServiceListComponent extends BaseListWebComponentWithSearchAndActiveBehavior implements OnInit, AfterViewInit {
  public getCarServiceEntriesAP: GetCarServiceListActionProxy;
  public onlyCarsThatLeftTheService: boolean = false;
  public title: string = 'Masini in service';
  public columns: any = [];
  public sortColumns: Array<IdName_Dto> = [];
  public currentPage = 1;

  constructor(
    public sandbox: CarServiceListSandbox,
    actionsSubj: ActionsSubject,
    private configService: ConfigService,
    private modalService: BsModalService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) {
    super(sandbox, actionsSubj, ResetSection.CarService);
    this.getCarServiceEntriesAP = new GetCarServiceListActionProxy(this, sandbox.appState);

    this.activatedRoute.queryParams
      .subscribe((params: any) => {
        if (ObjectValidators.isValidObject(params) && ObjectValidators.isValidObject(params.new) != null) {
          if (params.new === 'true') {
            this.addCarInService();
          }
        }
      });

    this.sandbox.addSubscription(this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe((event: any) => {

        if (event.url.search('exit') > 1) {
          this.title = 'Masini iesite din service';
          this.onlyCarsThatLeftTheService = true;
        }
        this.setBreadcrumbAP.execute([
          {text: 'Acasa', url: '/dashboard'},
          {text: this.title}]);
        this.dataLoaded = true;
      }));
    this.columns = [
      { data: 'internalNumber', width: '40px', name : 'Numar intern' },
      { data: 'registrationNumber', width: '10%', name:"Numar inmatriculare" },
      { data: 'clientName', width: '30%', name:'Client' },
      { data: 'clientPhone', width: '10%', name : 'Telefon' },
      { data: 'car', width: '10%', name:'Marca/Model' },
      { data: 'actualNoOfKm', width: '100px', name :'Nr de km' },
      { data: 'status', width: '170px' },
      { data: 'updatedOn', width: '15%', name :'Data iesirii' },
      { data: 'computedTotalPrice', width: '10%', orderable: false },
      { data: 'createdOn', width: '0', orderable: false }
    ];
    this.sortColumns = this.columns.filter(c => c.name).map(c => ({id: c.data, name: c.name} as IdName_Dto));
  }

  ngAfterViewInit() {
  }

  ngOnInit() {

    this.initialize();
    if(this.onlyCarsThatLeftTheService==false){
      this.columns = this.columns.filter(c=>c.data !== 'updatedOn');
      this.sortColumns = this.columns.filter(c => c.name).map(c => ({id: c.data, name: c.name} as IdName_Dto));
    }
    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      pageLength: this.configService.getDefaultPageSize(),
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      ajax: (dataTablesParameters: CarServiceListDataTableParameters, callback) => {
        dataTablesParameters.onlyCarsThatLeftTheService = this.onlyCarsThatLeftTheService;
        dataTablesParameters.search.value = this.searchValue;
        if (this.onlyCarsThatLeftTheService) {
          dataTablesParameters.order = [new DataTableParametersOrderEntity({column: 7, dir: 'desc'})];
        }
        this.doGetAll(new CarServiceListDataTableParameters(dataTablesParameters), callback, true);
      },
      columns: this.columns,
      // dom: '<"pull-left"><"pull-right"><"bottom"><"clear">',
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
  }

  public doGetAll(dataTablesParameters: CarServiceListDataTableParameters, callback: any, withReset: boolean) {
    this.sandbox.callback = callback;
    this.sandbox.dataTableParameters = dataTablesParameters;

    if (withReset) {
      setTimeout(() => {this.currentPage = 1; }, 0);
      this.sandbox.dataTableParameters.pageNumber = 1;
      dataTablesParameters.pageNumber = 1;
    }
    this.getCarServiceEntriesAP.execute(dataTablesParameters, withReset);
  }

  addCarInService() {
    this.isActive = false;
    const initialState = {
      carInService: new CarService_Dto(null),
      title: 'Adauga Masina in Service'
    };
    const modalRef = this.modalService.show(AddEditCarInServiceWizardComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
  }

  onPageChange(currentPage: number) {
    this.currentPage = currentPage;
    this.sandbox.dataTableParameters.pageNumber = currentPage;
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
  }
  onSortChange($event) {
    if ($event) {
      const columnId = this.columns.map((e) => e.data).indexOf($event.id);
      let orderEntity;
      if (this.onlyCarsThatLeftTheService && columnId === 7) {
        orderEntity = new DataTableParametersOrderEntity({column: columnId, dir: 'desc'});
      } else {
        orderEntity = new DataTableParametersOrderEntity({column: columnId, dir: 'asc'});
      }
      this.sandbox.dataTableParameters.order = [orderEntity];
    }
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, true);
  }
  carWorksToText(data: Array<CarWork_Dto>) {
    if (!data) return '';

    return data.map(carWork => {
      return carWork.description;
    }).join(', ');
  }

}
