import {
    Component,
    EventEmitter, Input,
    OnDestroy,
    OnInit,
    Output, ViewChild
} from "@angular/core";
import {BaseFormComponent} from "../../../core/shared/base/base.component";
import {Store} from "@ngrx/store";
import * as store from "../../../core/app-store";
import {OfferWorkEntry_Dto} from "../../../core/app-dto/offer.dto";
import {AddOfferCarWorkDi} from "./add-offer-car-work.di";
import {AddOfferCarWorksListDi} from "./add-offer-car-works-list.di";
import {AddOfferCarWorksListSandbox} from "./add-offer-car-works-list.sandbox";
import {CarWorksSearchComponent} from "../../../car-works/components/car-works-search/car-works-search.component";
import {ObjectValidators} from "../../../core/shared/object.validators";
import {makeInputPositive} from '../../../core/shared/helpers/common.helpers';

@Component({
    selector: 'app-add-offer-works-list',
    templateUrl: './add-offer-car-works-list.component.html',
    styleUrls: ['./add-offer-car-works-list.component.scss']
})
export class AddOfferCarWorksListComponent extends BaseFormComponent implements OnInit, OnDestroy {

    @Output()
    modelSaved = new EventEmitter<Array<OfferWorkEntry_Dto>>();

    @Input('model') model;
    @Input('make') make;
    @Input('year') year;

    public clientSearch: '';
    public di: AddOfferCarWorksListDi;
    public addDi: AddOfferCarWorkDi;

    @Input("carWorks") set carWorks(carWorks: Array<OfferWorkEntry_Dto>) {
        this.di = new AddOfferCarWorksListDi(carWorks);
    }

    @ViewChild('carWorkSearch', {static: false}) carWorkSearchComponent: CarWorksSearchComponent;

    constructor(public appState: Store<store.State>,
                public sandbox: AddOfferCarWorksListSandbox) {
        super(sandbox);
        this.di = new AddOfferCarWorksListDi([]);
    }

    ngOnInit() {
        this.addDi = new AddOfferCarWorkDi();

    }

    ngOnDestroy() {
        this.destroy();
    }

    clearInput() {
        this.addDi = new AddOfferCarWorkDi();
        this.carWorkSearchComponent.clearSelect();
    }

    doNothing($event: any) {
    }

    addCarWork() {
        this.addCarWorkInModel();
        this.clearInput();

    }

    addCarWorkInModel(): void {
        this.formSubmitted =true;
        let description: string = '';
        const carWork = this.carWorkSearchComponent.selectedItem;
        if (carWork) {
            description = carWork.description;
        }
        else {
            const carWorkAsIdName = this.carWorkSearchComponent.selectedItemValue;
            description = carWorkAsIdName.name;
        }
        if (ObjectValidators.isValidString(description)) {
            this.markFormAsDirtyAndTouched();
            if (this.addDi.isValid()) {
                const model = this.addDi.getModel();
                model.description = description;

                this.di.model.push(model);
                this.formSubmitted = false;
                //this.modelSaved.emit(this.di.getModel());
            }
        }
    }

    deleteCarWork(item: OfferWorkEntry_Dto) {
        const index = this.di.model.indexOf(item);
        if (index > -1) {
            this.di.model.splice(index, 1);
            //this.modelSaved.emit(this.di.getModel());
        }
    }

    public accept() {
        this.formSubmitted = true;
        this.markFormAsDirtyAndTouched();
        if (this.di.isValid()) {
            if (this.modelSaved != null) {
                this.modelSaved.emit(this.di.getModel());
            }
        }
    }

    public emitValues() {
        if (this.modelSaved != null) {
            this.modelSaved.emit(this.di.getModel());
        }
    }

    makeValuePositive(event: any) {
        makeInputPositive(event);
    }
}
