import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import {MechanicWithStatusAndActionDI} from "./mechanic-with-status-and-action.di";
import {MechanicWithActionsAndCar_Dto} from "../../../core/app-dto/mechanic.dto";

@Injectable()
export class MechanicListWithStatusSandbox extends BaseSandbox {
  public dataSelector$ = this.appState$.pipe(select(store.getMechanicsWithStatusList));

  public items: Array<MechanicWithStatusAndActionDI> = [];

  constructor(protected appState$: Store<store.State>) {
    super(appState$);
  }

  public clearSandboxState() {
    this.items = [];
  }

  public initialize(): void {
    this.clearSandboxState();
    this.subscriptions.push(this.dataSelector$.subscribe((data: Array<MechanicWithActionsAndCar_Dto>) => {
      if (data != null ) {
        this.items = data.map(r => new MechanicWithStatusAndActionDI(r));
      } else {
        this.items = [];
      }
    }));
  }

}
