import { State } from '../state';
import * as fromOrganizationClient from '../stores/organization-client.store';
import { createSelector } from '@ngrx/store';

export const getOrganizationClientStateCallback = (state: State) => state.organizationClient;

export const getOrganizationClientTimestamp = createSelector(getOrganizationClientStateCallback, fromOrganizationClient.getTimestampCallback);
export const getOrganizationClient = createSelector(getOrganizationClientStateCallback, fromOrganizationClient.getOrganizationClientCallback);
export const getOrganizationClientsListing = createSelector(getOrganizationClientStateCallback, fromOrganizationClient.getOrganizationClientsPagedResultCallback);
export const getOrganizationClientSearchList = createSelector(getOrganizationClientStateCallback, fromOrganizationClient.getOrganizationClientSearchListCallback);
export const getOrganizationClientCredit = createSelector(getOrganizationClientStateCallback, fromOrganizationClient.getOrganizationClientCreditCallback);
export const getOrganizationCompanyInfo = createSelector(getOrganizationClientStateCallback, fromOrganizationClient.getOrganizationClientInfoCallback);
export const getOrganizationClientFromCar = createSelector(getOrganizationClientStateCallback, fromOrganizationClient.getClientForCarCallback);
