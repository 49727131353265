import {
    UIGeneratedEvent,
    EventWithScreenLoading,
    SeamlessEvent,
} from './base.events';
import {ActiveDataTableParameters} from '../../shared/datatable/datatable.helpers';

export class GetServicePlatformStatusesEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetServicePlatformStatusesEvent";
    constructor(public callback:Function) {
        super();
        this.type += GetServicePlatformStatusesEvent.identifier;
    }
}
