import {EventWithScreenLoading, SeamlessEvent, UIGeneratedEvent} from "./base.events";
import {AsyncJob, StickyHeaderNotification} from "../../app-dto/core.dto";


export class AddStickyHeaderNotificationEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = 'AddStickyHeaderNotificationEvent';
  constructor(public model: StickyHeaderNotification) {
    super();
    this.type += AddStickyHeaderNotificationEvent.identifier;
  }
}
export class RemoveStickyHeaderNotificationEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = 'RemoveStickyHeaderNotificationEvent';
  constructor(public model: StickyHeaderNotification) {
    super();
    this.type += RemoveStickyHeaderNotificationEvent.identifier;
  }
}
