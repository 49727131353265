import { Store } from "@ngrx/store";
import * as store from "../../../core/app-store";
import { Observable } from "rxjs";
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import {GetOrgInvoiceDownloadLink, MarkOrgInvoiceAsPaidEvent} from 'src/app/core/app-store/events/invoices.events';

export class GetDownloadLinkForOrgInvoiceActionProxy extends BaseActionProxy {
  constructor(component: IWebComponent,
              appState: Store<store.State>) {
    super(component, appState);
  }

  canExecute(): boolean {
    return true;
  }

  execute(invoiceId: string, organizationId: string, callback: (link: string) => void): any {
    this.appState.dispatch(new GetOrgInvoiceDownloadLink(invoiceId, organizationId, callback));
  }

  canExecuteAsync(): Observable<boolean> {
    return null;
  }
}

