import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IdNameKey_Dto } from "../../../app-dto/misc.dto";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { isValidArray, isValidArrayAndHasElements, isValidObject, isValidString } from '../../helpers/common.helpers';
import * as store from "../../../app-store";
import { select, Store } from "@ngrx/store";
import { StaticData } from "../../../app-dto/static-data.dto";
import * as coreStore from "../../../app-store/state-selectors/core.selectors";
import { LoggedUser_Dto } from "../../../app-dto/core.dto";
import { Subscription } from "rxjs";
import { ObjectValidators } from "../../object.validators";

@Component({
  selector: 'app-static-data-selector',
  templateUrl: './static-data-selector.component.html',
  styleUrls: ['./static-data-selector.component.scss']
})
export class StaticDataSelectorComponent implements OnInit, OnDestroy {

  public selectedValue: any = null;
  public items: Array<IdNameKey_Dto>;
  public inputValue: StaticDataSelectorInput;
  public formControl: AbstractControl;
  public bindValueModeValue: string = 'id';

  @Input('appendTo') appendTo: string = null;
  @Input('isMultiple') isMultiple: boolean = false;
  @Input('isFirstItemDefault') isFirstItemDefault: boolean = false;

  @Input('bindValueMode') set bindValueMode(data: string) {
    if (isValidString(data)) {
      this.bindValueModeValue = data;
    }
  }

  @Input("input") set input(data: StaticDataSelectorInput) {
    if (isValidObject(data)) {
      if(data.isReadOnly==undefined || data.isReadOnly==null) data.isReadOnly = false;
      this.inputValue = data;
      if (isValidObject(data.form)) {
        if (data.form.get(data.formControlName) == null) {
          if (data.isRequired) {
            data.form.addControl(data.formControlName, new FormControl(null, [Validators.required]));
            data.form.addControl(data.formControlName + 'SelectedValue', new FormControl(null, [Validators.required]));
          } else {
            data.form.addControl(data.formControlName, new FormControl(null, []));
            data.form.addControl(data.formControlName + 'SelectedValue', new FormControl(null, []));
          }
          this.formControl = data.form.get(data.formControlName + 'SelectedValue');
          if (this.inputValue.type == "currencies" || this.inputValue.isReadOnly) this.formControl.disable();
        }
        else {
          this.formControl = data.form.get(data.formControlName + 'SelectedValue');
          if (this.inputValue.type == "currencies" || this.inputValue.isReadOnly) this.formControl.disable();
        }

        this.setInitialValue();
      }
      this.processInputAndSetControlState();
    }
  }

  @Input('initialValue') set initialValue(data: any) {
    if (this.bindValueModeValue === 'name' && !isValidString(data)) {
      this.selectedValue = '19%';
    } else {
      this.selectedValue = data;
    }

    this.setInitialValue();
  }

  setInitialValue() {
    if (isValidObject(this.formControl)) {
      this.formControl.setValue(this.selectedValue);
      if ((isValidString(this.selectedValue) || isValidArray(this.selectedValue)) && isValidArrayAndHasElements(this.items)) {
        const control = this.inputValue.form.controls[this.inputValue.formControlName];
        if (this.bindValueModeValue === 'name') {
          control.setValue(this.items.find(i => i.name === this.selectedValue));
        } else {
          control.setValue(this.items.find(i => i.id === this.selectedValue));
        }
      }
      if (this.stateSubscription != null) {
        this.stateSubscription.unsubscribe();
      }
      this.stateSubscription = this.staticData$.subscribe((staticData: StaticData) => {
        if (staticData != null) {
          this.items = staticData[this.inputValue.type];
          if (this.isFirstItemDefault && !isValidString(this.selectedValue)) {
            this.selectedValue = this.items[0];
          }
          if (isValidString(this.selectedValue) || isValidArray(this.selectedValue)) {
            const control = this.inputValue.form.controls[this.inputValue.formControlName];
            const controlSelected = this.inputValue.form.controls[this.inputValue.formControlName + 'SelectedValue'];
            if (!this.isMultiple) {
              if (this.bindValueModeValue === 'name') {
                const selObject = this.items.find(i => i.name === this.selectedValue || i.name === this.selectedValue.name);
                control.setValue(selObject);
                controlSelected.setValue(selObject != null ? selObject.name : null);
              } else {
                const selObject = this.items.find(i => i.id === this.selectedValue || i.id === this.selectedValue.id);
                control.setValue(selObject);
                controlSelected.setValue(selObject != null ? selObject.id : null);
              }
            } else {
              const selectedValue = this.selectedValue;
              const reduced = this.items.reduce((filtered, option) => {
                if (selectedValue.some(value => option.id === value.id)) {
                  filtered.push(option.id);
                }
                return filtered;
              }, []);

              control.setValue(this.items.filter(i => {
                return this.selectedValue.some(value => i.id === value.id);
              }));
              controlSelected.setValue(reduced);
            }
          }
          this.processInputAndSetControlState();
        }
      });
    }
  }

  public formSubmitted: boolean = false;

  @Input('formSubmitted') set submitted(value: boolean) {
    this.formSubmitted = value;
  }

  @Output()
  valueChanged = new EventEmitter<IdNameKey_Dto>();

  public stateSubscription;
  public staticData$ = this.appState$.select(store.getStaticData);
  public currentUserSubscription: Subscription;
  public currentUser$ = this.appState$.pipe(select(coreStore.getLoggedUser));
  public currentUser: LoggedUser_Dto = null;

  public isDisabled: boolean = false;

  constructor(private appState$: Store<store.State>) {
  }

  ngOnInit() {
    this.currentUserSubscription = this.currentUser$.subscribe((state: LoggedUser_Dto) => {
      this.currentUser = state;
      this.processInputAndSetControlState();
    })
  }

  ngOnDestroy() {
    if (this.stateSubscription != null) {
      this.stateSubscription.unsubscribe();
    }
    if (this.currentUserSubscription != null) {
      this.currentUserSubscription.unsubscribe();
    }
  }

  public changedValue(value: IdNameKey_Dto): void {
    if (this.inputValue.form == null) return;
    if (ObjectValidators.isValidArray(value)) {
      const control = this.inputValue.form.controls[this.inputValue.formControlName];
      control.setValue(value);
    }
    else {
      const control = this.inputValue.form.controls[this.inputValue.formControlName];
      control.setValue(value);

      const controlSelected = this.inputValue.form.controls[this.inputValue.formControlName + 'SelectedValue'];
      if (this.bindValueModeValue === 'name') {
        controlSelected.setValue(value != null ? value.name : null);
      } else {
        controlSelected.setValue(value != null ? value.id : null);
      }
    }
    if (this.valueChanged != null) {
      this.valueChanged.emit(value);
    }
  }

  isInvalidControl(control: AbstractControl): boolean {
    if (isValidObject(control) && control.disabled == false && !control.valid && this.formSubmitted)
      return true;
    return false;
  }

  processInputAndSetControlState() {
    // setTimeout(() => {
      if (this.inputValue != null && this.inputValue.type == "roles"
        && this.items != null) {
        var foundItemIndex = this.items.findIndex(x=>x.name == "Mecanic Organizatie");
        if(foundItemIndex>=0){
          this.items.splice(foundItemIndex, 1);
        }
      }
      if (this.currentUser != null && this.currentUser.organization != null && this.inputValue != null && this.inputValue.type == "currencies"
        && this.items != null) {
        this.isDisabled = true;
        let currentCurrency = this.items.find(f => f.key == this.currentUser.organization.currency);
        this.changedValue(currentCurrency);
        if (this.inputValue.form != null) {
          const control = this.inputValue.form.controls[this.inputValue.formControlName + 'SelectedValue'];
          control.disable({ onlySelf: true, emitEvent: true });
        }
      }
      if (this.currentUser == null && this.inputValue != null && this.inputValue.type == "currencies") {
        const control = this.inputValue.form.controls[this.inputValue.formControlName + 'SelectedValue'];
        control.enable({ onlySelf: true, emitEvent: true });
      }
      if(this.currentUser == null && this.inputValue != null && this.inputValue.type == "currencies"){
        this.isDisabled = true;
        let currentCurrency = this.items.find(f => f.key == 'LEI');
        this.changedValue(currentCurrency);
        if (this.inputValue.form != null) {
          const control = this.inputValue.form.controls[this.inputValue.formControlName + 'SelectedValue'];
          control.disable({ onlySelf: true, emitEvent: true });
        }
      }
    // }, 100);
  }
}

export class StaticDataSelectorInput {
  public form: FormGroup;
  public type: string;
  public formControlName: string;
  public isRequired: boolean;
  public isReadOnly: boolean;
  public label: string;
}
