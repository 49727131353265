import {Injectable} from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandboxWithCurrentUserLoaded} from 'src/app/core/shared/base/base.sandbox';
import { Organization } from '../../../core/app-dto/organization.dto';
import { ObjectValidators } from '../../../core/shared/object.validators';

@Injectable()
export class CarServiceDetailsCarPartsSandbox extends BaseSandboxWithCurrentUserLoaded {
  public organization: Organization;

  constructor(protected appState$: Store<store.State>) {
    super(appState$);
  }

  public clearSandboxState() {
  }

  public initialize(): any {
    this.clearSandboxState();

    this.loadUser(() => {
      this.organization = this.currentUser.organization;
    });
  }
}
