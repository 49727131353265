import { Component, OnInit, OnDestroy, ViewChild, Input, ViewChildren, QueryList } from '@angular/core';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import { CarServiceDetailsClientCarPartsDi } from './car-service-details-client-car-parts.di';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { CarServiceDetailsClientCarPartsSandbox } from './car-service-details-client-car-parts.sandbox';
import { CarPart_Dto } from 'src/app/core/app-dto/car-part.dto';
import { CarPartSearchComponent } from 'src/app/car-part/components/car-part-search/car-part-search.component';
import { BsModalService } from 'ngx-bootstrap';
import { CarSerivceDetailsAddCarPartActionProxy } from './car-service-details-add-client-car-part.action-proxy';
import { CarSerivceDetailsDeleteCarPartActionProxy } from './car-service-details-delete-client-car-part.action-proxy';
import { ClientCarParts_Dto } from '../../../core/app-dto/car-service.dto';
import { CarServiceSaveClientCarPartComponent } from '../car-service-save-client-car-part/car-service-save-client-car-part.component';
import {ConfirmationDialogService} from "../../../core/core/dialog/confirmation-dialog.service";
import { DataTableDirective } from 'angular-datatables';
import { ConfigService } from '../../../core/core/app-config.service';

@Component({
  selector: 'app-car-service-details-client-car-parts',
  templateUrl: './car-service-details-client-car-parts.component.html',
  styleUrls: ['./car-service-details-client-car-parts.component.scss']
})
export class CarServiceDetailsClientCarPartsComponent extends BaseFormModelComponent<Array<ClientCarParts_Dto>> implements OnInit, OnDestroy {

  public columns: any = [];
  public dtApiInstance: DataTables.Api;
  public dtOptions = null;
  public datatable: DataTableDirective;

  @ViewChild(CarPartSearchComponent, { static: false }) carPartSearchComponent: CarPartSearchComponent;
  @Input() carServiceId: string;
  @Input() isReadOnly: boolean = false;
  @ViewChildren(DataTableDirective) set datatableValue(datatable: QueryList<DataTableDirective>) {
    if (datatable != null && datatable.first != null) {
      datatable.first.dtInstance.then((dtInstance: DataTables.Api) => {
        this.dtApiInstance = dtInstance;
      });
    }
  }

  public carSerivceDetailsAddClientCarPartAP: CarSerivceDetailsAddCarPartActionProxy;
  public carServiceDetailsDeleteClientCarPartAP: CarSerivceDetailsDeleteCarPartActionProxy;

  constructor(public appState: Store<store.State>,
              private configService: ConfigService,
              private modalService: BsModalService,
              public confirmationDialogService: ConfirmationDialogService,
              public sandbox: CarServiceDetailsClientCarPartsSandbox) {
    super(sandbox);
    this.carSerivceDetailsAddClientCarPartAP = new CarSerivceDetailsAddCarPartActionProxy(this, sandbox.appState);
    this.carServiceDetailsDeleteClientCarPartAP = new CarSerivceDetailsDeleteCarPartActionProxy(this, sandbox.appState);
  }

  public diInitialize(data: Array<ClientCarParts_Dto>) {
    this.di = new CarServiceDetailsClientCarPartsDi(data);
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.columns = [
      { data: 'code', name: 'Cod piesa', width: '15%' },
      { data: 'name', name: 'Denumire', width: '15%' },
      { data: 'quantity', name: 'Cantitate', width: '15%'},
      { data: 'um', name: 'Unitate masura ( U/M )', width: '40%'},
      { data: 'action', width: '100px'}
    ];

    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      pageLength: this.configService.getDefaultPageSize(),
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      columns: this.columns,
      order: [[4, 'asc']],
      ajax: () => {
      },
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
  }

  ngOnDestroy() {
    this.destroy();
  }

  public addClientCarPart() {
    const modalRef = this.modalService.show(
        CarServiceSaveClientCarPartComponent,
        { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = {
      carServiceId: this.carServiceId,
      carPart: null
    };
  }

  public updateClientCarPart(carPart: CarPart_Dto) {
    const modalRef = this.modalService.show(
        CarServiceSaveClientCarPartComponent,
        { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = {
      carServiceId: this.carServiceId,
      carPart : carPart
    };
  }

  delete(id: string) {
    this.confirmationDialogService.confirm(
      "Stergere piesa",
      "Urmeaza sa stergeti piesa. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        let i = (<CarServiceDetailsClientCarPartsDi>this.di).model.findIndex(r => r.id === id);
        (<CarServiceDetailsClientCarPartsDi>this.di).model.splice(i, 1);

        // replace with faster .filter function

        if (this.carServiceDetailsDeleteClientCarPartAP.canExecute()) {
          this.carServiceDetailsDeleteClientCarPartAP.execute(this.carServiceId, id);
        }
      }
    });

  }

  isValid() {
    return this.di.isValid();
  }

  carPartSelected(carPart: CarPart_Dto) {
    if (carPart) {
      carPart.quantity = 1;
      this.updateClientCarPart(carPart);
    }
    this.carPartSearchComponent.clearSelect();
  }
}
