import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseWebComponentWithBreadcrumb} from '../../../core/shared/base/base.component';
import {ReceiptDetailsSandbox} from './receipt-details.sandbox';
import {ResetSection} from '../../../core/app-store/events/base.events';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {GetReceiptActionProxy} from './get-receipt.action-proxy';
import {ReceiptListItemDi} from '../receipt-list/receipt-list-item.di';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {GetDownloadLinkDorOrgReceiptActionProxy} from './get-download-link-dor-org-receipt.action-proxy';
import {InvoiceRequestType} from '../../../invoice/components/invoice/invoice.component';

@Component({
    selector: 'app-receipt-details',
    templateUrl: './receipt-details.component.html',
    styleUrls: ['./receipt-details.component.scss']
})
export class ReceiptDetailsComponent extends BaseWebComponentWithBreadcrumb implements OnInit, OnDestroy {
    public getReceiptAP: GetReceiptActionProxy;
    public invoiceRequestType: InvoiceRequestType = InvoiceRequestType.Organization;
    constructor(
        public sandbox: ReceiptDetailsSandbox,
        public activatedRoute: ActivatedRoute,
        public router: Router
    ) {
        super(sandbox, ResetSection.Receipt);
        this.getReceiptAP = new GetReceiptActionProxy(this, sandbox.appState);
    }
    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params: Params) => {
            if (params.receiptId != null) {
                this.getReceiptAP.execute(params.receiptId);
            }
        });
        this.initialize((result: ReceiptListItemDi) => {
            this.dataLoaded = true;
            this.setBreadcrumbAP.execute([{text: 'Acasa', url: '/dashboard'}, {text: 'Chitante', url : '/receipt/all'},
                {text : 'Chitanta ' + this.sandbox.item.model.computedNumber}]);
        });
    }
    download() {
        const ap = new GetDownloadLinkDorOrgReceiptActionProxy(this, this.sandbox.appState);
        ap.execute(this.sandbox.item.model.id, (url) => {
            if (ObjectValidators.isValidString(url)) {
                window.open(url, '_self');
            }
        });
    }

    public openInvoice(invoiceId: string) {
        switch (this.invoiceRequestType) {
            case InvoiceRequestType.SuperAdmin: {
                this.router.navigate(['/invoice', invoiceId, 'details']);
                break;
            }
            case InvoiceRequestType.Organization: {
                this.router.navigate(['/invoice', this.sandbox.item.getModel().organizationId, invoiceId, 'details']);
                break;
            }
            case InvoiceRequestType.SubscriptionOrganization: {
                this.router.navigate(['/invoice/subscription', this.sandbox.item.getModel().organizationId, invoiceId, 'details']);
                break;
            }
        }
    }
}
