import {Injectable} from '@angular/core';
import * as store from '../../../core/app-store/index';
import {select, Store} from '@ngrx/store';
import {BaseSandbox} from 'src/app/core/shared/base/base.sandbox';
import {CalendarEvent_Dto} from 'src/app/core/app-dto/calendar-event.dto';
import * as coreStore from "../../../core/app-store/state-selectors/core.selectors";
import {LoggedUser_Dto} from "../../../core/app-dto/core.dto";
import {Organization} from "../../../core/app-dto/organization.dto";
import {ObjectValidators} from "../../../core/shared/object.validators";

@Injectable()
export class CalendarSandbox extends BaseSandbox {
  public calendarEvents$ = this.appState$.pipe(select(store.getCalendarEvents));
  public loggedUser$ = this.appState$.pipe(select(coreStore.getLoggedUser));
  private currentOrganization: Organization = null;
  private currentItems: Array<CalendarEvent_Dto> = null;

  constructor(protected appState$: Store<store.State>) {
    super(appState$);
  }

  public clearSandboxState() {
    this.currentItems = null;
    this.currentOrganization = null;
  }

  public initialize(dataLoaded): any {
    this.clearSandboxState();

    this.subscriptions.push(this.calendarEvents$.subscribe((data: Array<CalendarEvent_Dto>) => {
      if (data != null) {
        this.currentItems = data;
        if (ObjectValidators.isValidObject(this.currentOrganization)) {
          dataLoaded(data, this.currentOrganization);
        }
      }
    }));
    this.subscriptions.push(this.loggedUser$.subscribe((user: LoggedUser_Dto) => {
      if (user != null && user.organization != null) {
        this.currentOrganization = user.organization;
        if (ObjectValidators.isValidNonEmptyArray(this.currentItems)) {
          dataLoaded(this.currentItems, this.currentOrganization);
        }
      }
    }));
  }
}
