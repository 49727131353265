import {Injectable} from "@angular/core";
import {BaseApiService} from "./base-api.service";
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "../../core/app-config.service";
import {Observable} from "rxjs";
import {ApiErrorDto} from "../utils/api-error.dto";
import {ErrorHelpers} from "../../shared/error.helpers";
import {ActiveDataTableParameters, PagedResult} from "../../shared/datatable/datatable.helpers";
import {ApiResourceConstants} from "../utils/api-resource.constants";
import {CashRegister_Dto} from "../../app-dto/cash-register.dto";
import {ObjectValidators} from "../../shared/object.validators";

class CashRegisterApiRoutes {
    public static route_for_GET_CASH_REGISTERS() {
        return `${ApiResourceConstants.CASH_REGISTER}/all/paginated`;
    }

    public static route_for_GET_ALL_ACTIVE_CASH_REGISTERS() {
        return `${ApiResourceConstants.CASH_REGISTER}/all/active`;
    }

    public static route_for_GET_COUNT_OF_ACTIVE_CASH_REGISTERS() {
        return `${ApiResourceConstants.CASH_REGISTER}/count/active`;
    }

    public static route_for_GET_ONE_CASH_REGISTER(id: string) {
        return `${ApiResourceConstants.CASH_REGISTER}/${id}`;
    }

    public static route_for_REGISTER_ONE_CASH_REGISTER() {
        return `${ApiResourceConstants.CASH_REGISTER}`;
    }

    public static route_for_EDIT_ONE_CASH_REGISTER() {
        return `${ApiResourceConstants.CASH_REGISTER}`;
    }

    public static route_for_DELETE_ONE_CASH_REGISTER(id: string) {
        return `${ApiResourceConstants.CASH_REGISTER}/${id}`;
    }

    public static route_for_RESET_ACTIVATION_FOR_ONE_CASH_REGISTER(id: string) {
        return `${ApiResourceConstants.CASH_REGISTER}/${id}/reset`;
    }

    public static route_for_DEACTIVATE_ONE_CASH_REGISTER(id: string) {
        return `${ApiResourceConstants.CASH_REGISTER}/${id}/deactivate`;
    }

    public static route_for_GET_CASH_REGISTER_LAST_INDEX(){
        return `${ApiResourceConstants.CASH_REGISTER}/all/last-index`
    }
}

@Injectable()
export class CashRegisterApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    cr_registerCashRegister(data: CashRegister_Dto): Observable<CashRegister_Dto> {
        const url = this.configService.getAPIBaseUrl() + CashRegisterApiRoutes.route_for_REGISTER_ONE_CASH_REGISTER();

        return this.http.post(url, data)
            .map((res: any) => {
                return new CashRegister_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    cr_editCashRegister(data: CashRegister_Dto): Observable<CashRegister_Dto> {
        const url = this.configService.getAPIBaseUrl() + CashRegisterApiRoutes.route_for_EDIT_ONE_CASH_REGISTER();

        return this.http.put(url, data)
            .map((res: any) => {
                return new CashRegister_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    cr_getFilteredCashRegisters(data: ActiveDataTableParameters): Observable<PagedResult<CashRegister_Dto>> {
        const url = this.configService.getAPIBaseUrl() + CashRegisterApiRoutes.route_for_GET_CASH_REGISTERS() + '?' + data.toUrlParameters();

        return this.http.get(url)
            .map((res: PagedResult<CashRegister_Dto>) => {
                let result = new PagedResult<CashRegister_Dto>(res);
                if (ObjectValidators.isValidNonEmptyArray(res.items)) {
                    let domainModels = res.items.map(x => new CashRegister_Dto(x));
                    result.items = domainModels.map(x => x);
                }
                return result;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    cr_getAllActiveCashRegisters(): Observable<Array<CashRegister_Dto>> {
        const url = this.configService.getAPIBaseUrl() + CashRegisterApiRoutes.route_for_GET_ALL_ACTIVE_CASH_REGISTERS();

        return this.http.get(url)
            .map((res: Array<CashRegister_Dto>) => {
                if (ObjectValidators.isValidNonEmptyArray(res)) {
                    let domainModels = res.map(x => new CashRegister_Dto(x));
                    return domainModels;
                }
                return [];
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    cr_getCountOfAllActiveCashRegisters(): Observable<number> {
        const url = this.configService.getAPIBaseUrl() + CashRegisterApiRoutes.route_for_GET_COUNT_OF_ACTIVE_CASH_REGISTERS();

        return this.http.get(url)
            .map((res: number) => {
                return res;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    cr_getCashRegister(id: string): Observable<CashRegister_Dto> {
        const url = this.configService.getAPIBaseUrl() + CashRegisterApiRoutes.route_for_GET_ONE_CASH_REGISTER(id);

        return this.http.get(url)
            .map((res: any) => {
                return new CashRegister_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    cr_deleteCashRegister(id: string): Observable<CashRegister_Dto> {
        const url = this.configService.getAPIBaseUrl() + CashRegisterApiRoutes.route_for_DELETE_ONE_CASH_REGISTER(id);

        return this.http.delete(url)
            .map((res: any) => {
                return new CashRegister_Dto(null);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    cr_resetCashRegister(id: string): Observable<CashRegister_Dto> {
        const url = this.configService.getAPIBaseUrl() + CashRegisterApiRoutes.route_for_RESET_ACTIVATION_FOR_ONE_CASH_REGISTER(id);

        return this.http.put(url, {})
            .map((res: any) => {
                return new CashRegister_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    cr_deactivateCashRegister(id: string): Observable<CashRegister_Dto> {
        const url = this.configService.getAPIBaseUrl() + CashRegisterApiRoutes.route_for_DEACTIVATE_ONE_CASH_REGISTER(id);

        return this.http.put(url, {})
            .map((res: any) => {
                return new CashRegister_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    cr_getLastCashRegisterIndex(): Observable<number> {
        const url = this.configService.getAPIBaseUrl() + CashRegisterApiRoutes.route_for_GET_CASH_REGISTER_LAST_INDEX();

        return this.http.get(url)
            .map((res: any) => {
                return Number(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
}
