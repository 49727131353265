import { AbstractControl, ValidatorFn, FormControl } from "@angular/forms";
import { FormGroup } from '@angular/forms';
import { isNumeric } from "rxjs/internal/util/isNumeric";
import { ObjectValidators } from "../object.validators";
import * as moment from 'moment';
import { DateHelpers } from '../date.helpers';

export class CustomFormValidator {
  static shortName(form: AbstractControl): any {
    if (form.pristine)
      return null;

    const FIRST_LETTER_REGEXP = /^[A-Za-z_]+$/;
    const REST_OF_THE_WORD_REGEXP = /^[a-zA-Z0-9]+$/;

    let val: string = form.value;
    let rest = val.substring(1, val.length);

    form.markAsTouched();

    if (val.length == 1) {
      if (FIRST_LETTER_REGEXP.test(val.charAt(0))) {
        return null;
      }
      return {
        invalidShortName: true
      };
    } else {
      if (FIRST_LETTER_REGEXP.test(val.charAt(0)) && REST_OF_THE_WORD_REGEXP.test(rest)) {
        return null;
      }
      return {
        invalidShortName: true
      };
    }
  }
}



// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (!ObjectValidators.isValidObject(control) || !ObjectValidators.isValidObject(matchingControl)) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function HasChoices(controlName: string, targetControlName: string, choices: Array<string>) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const targetControl = formGroup.controls[targetControlName];

    if (targetControl.value == true && choices.length == 0) {
      targetControl.setErrors({ mustHaveChoices: true });
    } else {
      targetControl.setErrors(null);
    }
  };
}

export function NoSpace(controlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];

    if (control.errors && !control.errors.hasSpaces) {
      // return if another validator has already found an error on the control
      return;
    }

    if (ObjectValidators.isValidString(control.value)) {
      if (control.value.indexOf(' ') >= 0) {
        control.setErrors({ hasSpaces: true });
      } else {
        control.setErrors(null);
      }
    } else {
      return;
    }

  };
}

export function EmailReg(controlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    var emailReg = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';

    if (control.errors && !control.errors.emailReg) {
      // return if another validator has already found an error on the control
      return;
    }

    if (ObjectValidators.isValidArray(control.value) == false) {
      if (!ObjectValidators.isEmptyString(control.value)) {
        if (control.value.match(emailReg) == null) {
          control.setErrors({ emailReg: true });
        } else {
          control.setErrors(null);
        }
      } else {
        return;
      }
    }
  };
}

export function PhoneReg() {
  return (formControl: FormControl) => {
    const phoneReg = `^[+]{0,1}[(]{0,1}[0-9]{4,7}[)]{0,1}[-\s\.\/]{0,1}[0-9]{3}[-\s\.\/]{0,1}[0-9]{3}$`;
    if (ObjectValidators.isValidArray(formControl.value) === false) {
      if (!ObjectValidators.isEmptyString(formControl.value)) {
        if (formControl.value.match(phoneReg) == null) {
          return { phoneReg: true };
        }
      } else {
        return;
      }
    }
  };
}

export function EmailRegForControl() {
  return (formControl: FormControl) => {
    const emailReg = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.]{1}[a-zA-Z0-9-]+$';
    if (ObjectValidators.isValidArray(formControl.value) === false) {
      if (!ObjectValidators.isEmptyString(formControl.value)) {
        if (formControl.value.match(emailReg) == null) {
          return { emailReg: true };
        }
      } else {
        return;
      }
    }
  };
}

export function CnpReg() {
  return (formControl: FormControl) => {
    const cnpReg = `^[0-9]*$`;
    if (ObjectValidators.isValidArray(formControl.value) === false) {
      if (!ObjectValidators.isEmptyString(formControl.value)) {
        if (formControl.value.match(cnpReg) == null) {
          return { cnpReg: true };
        }
      } else {
        return;
      }
    }
  };
}

export function PasswordReg(controlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    var pwReg = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{4,15}$";

    if (!ObjectValidators.isValidObject(control)) {
      // return if another validator has already found an error on the control
      return;
    }

    if (control.errors && !control.errors.passwordReg) {
      // return if another validator has already found an error on the control
      return;
    }

    if (ObjectValidators.isValidArray(control.value) == false) {
      if (!ObjectValidators.isEmptyString(control.value)) {
        if (control.value.match(pwReg) == null) {
          control.setErrors({ passwordReg: true });
        } else {
          control.setErrors(null);
        }
      } else {
        return;
      }
    }
  };
}

export function min(min: Number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const input = control.value,
      isValid = input > min;

    if (input ==null || input == undefined)
      return null;

    if (!isValid)
      return { 'minValue': { min } };
    else
      return null;
  };
}

export function maxTextLength(max: Number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const input = control.value;
    if (!input)
      return null;

    const isValid = input.length > max;

    if (isValid)
      return { 'maxTextLength': { max } }
    else
      return null;
  };
}

export function isNumber(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const input = control.value;

    if (!input)
      return null;

    if (isNumeric(input))
      return null;
    else
      return { 'numeric': '   ' }
  };
}



export function validStartDateTime(endDate: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    return checkEndDateTime(endDate, control.value);
  };
}

export function validEndDateTime(startDate: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    return checkEndDateTime(startDate, control.value);
  };
}

export function checkStartDateTime(endDate: any, startDate: any) {
  if (startDate == null || endDate == null)
    return null;

  const message = {
    'lessThan': {
      'message': "Field must be lower than " + endDate.getHours() + ":" + endDate.getMinutes()
    }
  };
  return moment(endDate).isBefore(moment(startDate)) ? message : null;
}

export function checkEndDateTime(startDate: any, endDate: any) {
  if (startDate == null || endDate == null)
    return null;

  const message = {
    'moreThan': {
      'message': "Field must be greater than " + startDate.getHours() + ":" + startDate.getMinutes()
    }
  };
  return moment(endDate).isBefore(moment(startDate)) ? message : null;
}

export function validEndDate(startDate: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    return checkEndDate(startDate, control.value);
  };
}

export function checkEndDate(startDate: any, endDate: any) {
  if (startDate == null || endDate == null)
    return null;

  const message = {
    'moreThan': {
      'message': "Field must be greater than " + DateHelpers.dateToString(startDate)
    }
  };

  let a = moment(endDate).isBefore(moment(startDate), 'day');
  
  return a ? message : null;
}

export function validStartDate(endDate: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    return checkStartDate(endDate, control.value);
  };
}

export function checkStartDate(endDate: any, startDate: any) {
  if (startDate == null || endDate == null)
    return null;

  const message = {
    'moreThan': {
      'message': "Field must be lower than " + DateHelpers.dateToString(endDate)
    }
  };
  return moment(endDate).isBefore(moment(startDate), 'day') ? message : null;

}
export function noWhitespaceValidator(control: FormControl) {
  const isSpace = (control.value || '').match(/\s/g);
  return isSpace ? {'whitespace': true} : null;
}
