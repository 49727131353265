import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { ApiErrorDto } from '../utils/api-error.dto';
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ActiveDataTableParameters, PagedResult } from '../../shared/datatable/datatable.helpers';
import { Car_Dto } from '../../app-dto/car.dto';
import { ErrorHelpers } from '../../shared/error.helpers';
import {OrganizationClient_Dto} from '../../app-dto/organization-client.dto';

class CarApiRoutes {
    public static route_for_GET_CARS_PAGINATED() {
        return '/api/car/all';
    }
    public static route_for_GET_CAR(carId: string) {
        return `/api/car/${carId}`;
    }
    public static route_for_REGISTER_CAR() {
        return '/api/car/';
    }
    public static route_for_EDIT_CAR() {
        return '/api/car/';
    }
    public static route_for_DELETE_CAR(carId: string) {
        return `/api/car/${carId}`;
    }
    public static route_for_ACTIVATE_CAR(carId: string) {
        return `/api/car/${carId}/activate`;
    }
    public static route_for_DEACTIVATE_CAR(carId: string) {
        return `/api/car/${carId}/deactivate`;
    }
    public static route_for_SEARCH_CAR() {
        return `/api/car/search`;
    }
    public static route_for_GET_CAR_FOR_CLIENT(clientId: string) {
        return `/api/car/carForClient/${clientId}`;
    }
    public static route_for_GET_CLIENT_FOR_CAR(carId: string) {
        return `/api/car/clientForCar/${carId}`;
    }
}

@Injectable()
export class CarApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_registerCar(carData: Car_Dto): Observable<Car_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarApiRoutes.route_for_REGISTER_CAR();

        return this.http.post(url, carData)
            .map((res: any) => {
                return new Car_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_editCar(carData: Car_Dto): Observable<Car_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarApiRoutes.route_for_EDIT_CAR();

        return this.http.put(url, carData)
            .map((res: any) => {
                return new Car_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getFilteredCarsPaginated(data: ActiveDataTableParameters): Observable<PagedResult<Car_Dto>> {
        const url = this.configService.getAPIBaseUrl() + CarApiRoutes.route_for_GET_CARS_PAGINATED() + '?' + data.toUrlParameters();

        return this.http.get(url)
            .map((res: PagedResult<Car_Dto>) => {
                return new PagedResult<Car_Dto>(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getCar(carId: string): Observable<Car_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarApiRoutes.route_for_GET_CAR(carId);

        return this.http.get(url)
            .map((res: any) => {
                return new Car_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deleteCar(carId: string): Observable<Car_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarApiRoutes.route_for_DELETE_CAR(carId);

        return this.http.delete(url)
            .map((res: any) => {
                return new Car_Dto(null);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_activateCar(carId: string): Observable<Car_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarApiRoutes.route_for_ACTIVATE_CAR(carId);

        return this.http.put(url, {})
            .map((res: any) => {
                return new Car_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deactivateCar(carId: string): Observable<Car_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarApiRoutes.route_for_DEACTIVATE_CAR(carId);

        return this.http.put(url, {})
            .map((res: any) => {
                return new Car_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_searchCars(search: string): Observable<Array<Car_Dto>> {
        const url = this.configService.getAPIBaseUrl() + CarApiRoutes.route_for_SEARCH_CAR() + '?search=' + search;
        
        return this.http.get(url)
            .map((res: any) => {
                return res.map(r => new Car_Dto(r));
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getCarForClient(clientId: string): Observable<Car_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarApiRoutes.route_for_GET_CAR_FOR_CLIENT(clientId);

        return this.http.get(url)
            .map((res: any) => {
                return new Car_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getClientForCar(carId: string): Observable<OrganizationClient_Dto> {
        const url = this.configService.getAPIBaseUrl() + CarApiRoutes.route_for_GET_CLIENT_FOR_CAR(carId);

        return this.http.get(url)
            .map((res: any) => {
                return new OrganizationClient_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
}
