import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseFormComponent, BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { range, reverse } from 'lodash';
import { RegisterCarActionProxy } from './register-car.action-proxy';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { EditCarActionProxy } from './edit-car.action-proxy';
import { GetCarActionProxy } from '../car-details/get-car.action-proxy';
import { ActivatedRoute, Params } from '@angular/router';
import { SaveCarSandbox } from './save-car.sandbox';
import { Car_Dto } from '../../../core/app-dto/car.dto';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { CarFormComponent } from '../car-form/car-form.component';
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";


@Component({
  selector: 'app-save-car',
  templateUrl: './save-car.component.html',
  styleUrls: ['./save-car.component.scss']
})
export class SaveCarComponent extends BaseFormModelComponent<Car_Dto> implements OnInit, OnDestroy {
  public registerCarAP: RegisterCarActionProxy;
  public editCarAP: EditCarActionProxy;
  public getCarAP: GetCarActionProxy;
  public title: string;
  public onClose: Subject<boolean>;

  public data: Car_Dto = null;

  @ViewChild(CarFormComponent, { static: false })
  private carFormComponent: CarFormComponent;


  constructor(public appState: Store<store.State>,
    public sandbox: SaveCarSandbox,
    private activeModal: BsModalRef,
    
    public activatedRoute: ActivatedRoute) {
    super(sandbox, null);
    this.registerCarAP = new RegisterCarActionProxy(this, sandbox.appState);
    this.editCarAP = new EditCarActionProxy(this, sandbox.appState);
    this.getCarAP = new GetCarActionProxy(this, sandbox.appState);
  }

  public diInitialize(data: Car_Dto) {
    this.data = data;
    this.title = ObjectValidators.isValidObject(data) ? 'Editare masina' : 'Adauga masina';
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.carFormComponent.accept();
  }

  public formSaved(model: Car_Dto) {
    if (model.id) {
      this.editCarAP.execute(model);
    } else {
      this.registerCarAP.execute(model);
    }
    this.onClose.next(true);
    this.activeModal.hide();
  }
}
