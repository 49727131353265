import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared/shared.module';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectModule } from '@ng-select/ng-select';
import { CarWorksSearchComponent } from './components/car-works-search/car-works-search.component';
import { CarWorksSearchSandbox } from './components/car-works-search/car-works-search.sandbox';
import { ModalModule } from 'ngx-bootstrap';
import { CarWorksListSandbox } from './components/car-works-list/car-works-list.sandbox';
import { CarWorksListComponent } from './components/car-works-list/car-works-list.component';

@NgModule({
  declarations: [
    CarWorksSearchComponent,
    CarWorksListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    SharedModule,
    DataTablesModule,
    ModalModule.forRoot()
  ],
  providers: [
    CarWorksSearchSandbox,
    CarWorksListSandbox
  ],
  entryComponents: [
  ],
  exports: [
    CarWorksSearchComponent,
    CarWorksListComponent
  ]
})
export class CarWorkModule { }
