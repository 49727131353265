import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { CarPart_Dto } from '../../../core/app-dto/car-part.dto';

@Injectable()
export class CarServiceSaveClientCarPartSandbox extends BaseSandbox {
    public getCarPart$ = this.appState$.pipe(select(store.getCarPart));

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
    }

    public initialize(carPart: (data: CarPart_Dto) => {}): any {
        this.clearSandboxState();

        this.subscriptions.push(this.getCarPart$.subscribe((data: CarPart_Dto) => {
            if (ObjectValidators.isValidObject(data)) {
                carPart(data);
            }
        }));
    }
}
