import { Component, OnInit } from '@angular/core';
import {AppUserBusinessRuleProvider} from '../../../core/core/business-rule-provider/app-user.business-rule.provider';
import {ConfigService} from '../../../core/core/app-config.service';
import { BaseWebComponentWithBreadcrumb } from '../../../core/shared/base/base.component';
import { ResetSection } from '../../../core/app-store/events/base.events';
import { OverviewSandbox } from './overview.sandbox';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent extends BaseWebComponentWithBreadcrumb implements OnInit {


  constructor(public rulesProvider: AppUserBusinessRuleProvider,
              public configService: ConfigService,
              public sandbox: OverviewSandbox) {
    super(sandbox, ResetSection.Calendar);
  }

  ngOnInit() {
    this.setBreadcrumbAP.execute([
      {text: 'Acasa', url: '/dashboard'},
      {text: 'Activitate'}]);
  }
}
