import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { DataTableParameters, PagedResult} from '../../shared/datatable/datatable.helpers';
import { ErrorHelpers } from '../../shared/error.helpers';
import {Organization_Dto, SuperAdminLogs_Dto} from '../../app-dto/super-admin-logs.dto';
import {ApiErrorDto} from '../utils/api-error.dto';
import {CarPart_Dto} from '../../app-dto/car-part.dto';
import {isValidObject} from '../../shared/helpers/common.helpers';

class SuperAdminLogsApiRoutes {
    public static route_for_GET_LOGS_PAGINATED() {
        return '/api/logs/all';
    }

    public static route_for_ORGANIZATION_LOGS_SEARCH() {
        return `/api/organization/search/`;
    }
}

@Injectable()
export class SuperAdminLogsApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_getFilteredSuperAdminLogsPaginated(data: DataTableParameters, organizationFilter: string, from: Date, to: Date ): Observable<PagedResult<SuperAdminLogs_Dto>> {
        const url = this.configService.getAPIBaseUrl() + SuperAdminLogsApiRoutes.route_for_GET_LOGS_PAGINATED() + '?' + data.toUrlParameters() + '&organizationFilter=' + organizationFilter + '&from=' + from.toISOString() + '&to=' + to.toISOString();
        return this.http.get(url)
            .map((res: PagedResult<SuperAdminLogs_Dto>) => {
                return new PagedResult<SuperAdminLogs_Dto>(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

        u_searchOrganizationLogs(search: string, displayOnlyAvailableItems: boolean = true): Observable<Array<Organization_Dto>> {
            const url = this.configService.getAPIBaseUrl() + SuperAdminLogsApiRoutes.route_for_ORGANIZATION_LOGS_SEARCH()  + search;

            return this.http.get(url)
                .map((res: any) => {
                    return res.map(r => new Organization_Dto(r));
                })
                .catch<any, any>((err: ApiErrorDto, o) => {
                    return ErrorHelpers.throwError(err);
                });
        }

}

