import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import {Observable, Subscriber} from 'rxjs';
import { ApiErrorDto } from '../utils/api-error.dto';
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { FileAttachment, FileUploadTarget } from '../../app-dto/core.dto';
import { ErrorHelpers } from '../../shared/error.helpers';

class DocumentsApiRoutes {
    public static route_for_GET_DOCUMENTS() {
        return '/api/file';
    }
    public static route_for_GET_DOCUMENTS_ANNONYMUS() {
        return '/api/file/annonymus';
    }

    public static route_for_DELETE_DOCUMENT() {
        return '/api/file';
    }

    public static route_for_GENERATE_DEVIZ(id: string) {
        return `/api/carServiceEntry/documents/deviz/${id}`;
    }

    public static route_for_GENERATE_GDPR(id: string) {
        return `/api/carServiceEntry/documents/gdpr/${id}`;
    }

    public static route_for_GENERATE_SH(id: string) {
        return `/api/carServiceEntry/documents/sh/${id}`;
    }

    public static route_for_GENERATE_PV(id: string) {
        return `/api/carServiceEntry/documents/pv/${id}`;
    }

    public static route_for_GENERATE_IC(id: string) {
        return `/api/carServiceEntry/documents/initialCommand/${id}`;
    }
}

@Injectable()
export class DocumentsApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_getDocuments(types: Array<FileUploadTarget>, relatedEntityId: string): Observable<Array<FileAttachment>> {

        const url = this.configService.getAPIBaseUrl() + DocumentsApiRoutes.route_for_GET_DOCUMENTS();
        const data = {
          types: types,
          relatedEntityId: relatedEntityId
        };

        return this.http.post(url, data)
            .map((res: any) => {
                return res.map(r => new FileAttachment(r));
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getDocumentsAnnonymus(types: Array<FileUploadTarget>, relatedEntityId: string): Observable<Array<FileAttachment>> {

        var url = this.configService.getAPIBaseUrl() + DocumentsApiRoutes.route_for_GET_DOCUMENTS_ANNONYMUS();

        let data = {
            types: types,
            relatedEntityId: relatedEntityId
        };

        return this.http.post(url, data)
            .map((res: any) => {
                return res.map(r => new FileAttachment(r));
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }


    u_deleteDocument(id: string): Observable<boolean> {
        var url = this.configService.getAPIBaseUrl() + DocumentsApiRoutes.route_for_DELETE_DOCUMENT() + "?" + "id=" + id;

        return this.http.delete(url)
            .map((res: any) => {
                return true;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }


    u_generateDeviz(id: string): Observable<FileAttachment> {
        var url = this.configService.getAPIBaseUrl() + DocumentsApiRoutes.route_for_GENERATE_DEVIZ(id);

        return this.http.get(url)
            .map((res: any) => {
                return new FileAttachment(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_generateGDPR(id: string): Observable<FileAttachment> {
        var url = this.configService.getAPIBaseUrl() + DocumentsApiRoutes.route_for_GENERATE_GDPR(id);

        return this.http.get(url)
            .map((res: any) => {
                return new FileAttachment(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_generateSH(id: string): Observable<FileAttachment> {
        var url = this.configService.getAPIBaseUrl() + DocumentsApiRoutes.route_for_GENERATE_SH(id);

        return this.http.get(url)
            .map((res: any) => {
                return new FileAttachment(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_generatePV(id: string): Observable<FileAttachment> {
        var url = this.configService.getAPIBaseUrl() + DocumentsApiRoutes.route_for_GENERATE_PV(id);

        return this.http.get(url)
            .map((res: any) => {
                return new FileAttachment(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_generateIC(id: string): Observable<FileAttachment> {
        var url = this.configService.getAPIBaseUrl() + DocumentsApiRoutes.route_for_GENERATE_IC(id);

        return this.http.get(url)
            .map((res: any) => {
                return new FileAttachment(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
}
