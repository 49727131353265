import {BaseFormDI} from 'src/app/core/shared/base/base.di';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { ClientCarParts_Dto } from '../../../core/app-dto/car-service.dto';
import {isValidString} from "../../../core/shared/helpers/common.helpers";

export class ClientCarPartFormDi extends BaseFormDI<ClientCarParts_Dto> {

  constructor(data: ClientCarParts_Dto = new ClientCarParts_Dto(null)) {
    super(new ClientCarParts_Dto(data));
    this.initForm();
  }

  getModel(): ClientCarParts_Dto {
    this.model.name = this.form.value.name;
    this.model.code = this.form.value.code;
    this.model.quantity = this.form.value.quantity;
    this.model.um = this.form.value.um;

    if (this.model.quantity === 0) {
      this.model.quantity = 1;
    }
    return this.model;
  }

  initForm() {
    if(!isValidString(this.model.um)) this.model.um = 'buc';
    this.form = new FormGroup({
      name: new FormControl(this.model.name, Validators.compose([Validators.required])),
      code: new FormControl(this.model.code, Validators.compose([Validators.required])),
      quantity: new FormControl(this.model.quantity || 1, Validators.compose([Validators.required])),
      um: new FormControl(this.model.um, Validators.compose([]))
    });
  }
}
