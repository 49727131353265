import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseFormModelComponent} from '../../../core/shared/base/base.component';
import {Offer_Dto, OfferWorkEntry_Dto} from '../../../core/app-dto/offer.dto';
import {UpdateOfferClientCarPartsActionProxy} from '../../common/ap/update-offer-client-car-parts.action-proxy';
import {Subject} from 'rxjs';
import {CarServiceClientPartsComponent} from '../../../car-service/components/car-service-client-parts/car-service-client-parts.component';
import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {BsModalRef} from 'ngx-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {EditCarServiceClientDi} from '../../../car-service/components/edit-car-service-client/edit-car-service-client.di';
import {ClientCarParts_Dto} from '../../../core/app-dto/car-service.dto';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {UpdateOfferCarWorksActionProxy} from '../../common/ap/update-offer-car-works.action-proxy';
import {CarWorksListComponent} from '../../../car-works/components/car-works-list/car-works-list.component';
import {AddOfferCarWorksListComponent} from "../add-offer-car-works-list/add-offer-car-works-list.component";

@Component({
  selector: 'app-update-offer-car-works',
  templateUrl: './update-offer-car-works.component.html',
  styleUrls: ['./update-offer-car-works.component.scss']
})
export class UpdateOfferCarWorksComponent extends BaseFormModelComponent<Offer_Dto> implements OnInit, OnDestroy {
  public editDataAP: UpdateOfferCarWorksActionProxy;
  public onClose: Subject<boolean>;
  public offer: Offer_Dto;

  @ViewChild(AddOfferCarWorksListComponent, {static: false})
  private formComponent: AddOfferCarWorksListComponent;

  constructor(public appState: Store<store.State>,
              private activeModal: BsModalRef,
              public activatedRoute: ActivatedRoute) {
    super(null, null);
    this.editDataAP = new UpdateOfferCarWorksActionProxy(this, appState);
  }

  public diInitialize(data: Offer_Dto) {
    this.offer = new Offer_Dto(data);
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.formSubmitted = true;
    this.formComponent.emitValues();
  }

  carWorksSelected(data: Array<OfferWorkEntry_Dto>) {
    if (ObjectValidators.isValidArray(data)) {
      this.offer.offerEntryData.carWorksRequests = data;

    } else {
      this.offer.offerEntryData.carWorksRequests = [];
    }
    this.editDataAP.execute(this.offer);
    this.onClose.next(true);
    this.activeModal.hide();
  }
}
