import {PagedResult} from "../../shared/datatable/datatable.helpers";
import {Action} from "@ngrx/store";
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  SeamlessEvent
} from "../events/base.events";

import {SMSBoughtTransaction, SMSSentTransaction} from "../../app-dto/sms.dto";
import {GetSMSBoughtPagedResultEvent, GetSMSSentPagedResultEvent} from "../events/sms.events";

export interface SmsState {
  smsSentPagedResult: PagedResult<SMSSentTransaction>;
  smsBoughtPagedResult: PagedResult<SMSBoughtTransaction>;
}

export const INITIAL_STATE: SmsState = Object.assign({}, {
  smsSentPagedResult: new PagedResult<SMSSentTransaction>([]),
  smsBoughtPagedResult: new PagedResult<SMSBoughtTransaction>([]),
});


export function reducer(state = INITIAL_STATE, action: Action): SmsState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {

    case GetSMSSentPagedResultEvent.identifier: {
      if ((action as GetSMSSentPagedResultEvent).withReset) {
        state.smsSentPagedResult = new PagedResult<SMSSentTransaction>([]);
        return Object.assign({}, state);
      }
      return state;
    }
    case GetSMSBoughtPagedResultEvent.identifier: {
      if ((action as GetSMSBoughtPagedResultEvent).withReset) {
        state.smsBoughtPagedResult = new PagedResult<SMSBoughtTransaction>([]);
        return Object.assign({}, state);
      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetSMSSentPagedResultEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<PagedResult<SMSSentTransaction>>).data;
      state.smsSentPagedResult = new PagedResult<SMSSentTransaction>(data);
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + Do_GenericSuccessEvent.identifier: {
      state.smsSentPagedResult = new PagedResult<SMSSentTransaction>([]);
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + GetSMSBoughtPagedResultEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<PagedResult<SMSBoughtTransaction>>).data;
      state.smsBoughtPagedResult = new PagedResult<SMSBoughtTransaction>(data);
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetSMSBoughtPagedResultEvent.identifier: {
      state.smsBoughtPagedResult = new PagedResult<SMSBoughtTransaction>([]);
      return Object.assign({}, state);
    }
    default: {
      return state;
    }
  }
}

export const getSmsSentPagedResultCallback = (state: SmsState) => state.smsSentPagedResult;
export const getSmsBoughtPagedResultCallback = (state: SmsState) => state.smsBoughtPagedResult;
