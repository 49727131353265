import {Injectable} from "@angular/core";
import {NotifierService} from "angular-notifier";

@Injectable()
export class ImageHelpers{
  constructor(private notifierService : NotifierService){

  }

  public isNoOfImagesValid(newLength:number){
    if(newLength >10){
      this.notifierService.notify('warning', 'Puteti incarca maxim 10 poze!');
      return false;
    }
    return true;
  }
}
