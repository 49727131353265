import {Component, OnInit, ViewChild} from '@angular/core';
import {GetTotalCarPartsValueActionProxy} from "./get-total-car-parts-value.action-proxy";
import {GetCarPartsActionProxy} from "../car-parts-list/get-car-parts.action-proxy";
import {GetExcelCarPartsActionProxy} from "../car-parts-list/get-excel-car-parts-action.proxy";
import {GetSuppliersActionProxy} from "../save-car-part/get-suppliers.action-proxy";
import {GetExcelCarPartsImportTemplateActionProxy} from "../car-parts-list/get-excel-car-parts-import-template-action.proxy";
import {AddImportTaskActionProxy} from "../../../calendar/components/calendar/add-import-task.action-proxy";
import {CarPartsTableComponent} from "../../../core/shared/car-parts-table/car-parts-table.component";
import {CarPartsListSandbox} from "../car-parts-list/car-parts-list.sandbox";
import {ActionsSubject, Store} from "@ngrx/store";
import {ConfigService} from "../../../core/core/app-config.service";
import {Router} from "@angular/router";
import {BsModalService} from "ngx-bootstrap";
import * as store from "../../../core/app-store";
import {NotifierService} from "angular-notifier";
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";
import {ResetSection} from "../../../core/app-store/events/base.events";
import {ActiveDataTableParameters} from "../../../core/shared/datatable/datatable.helpers";
import {DateHelpers} from "../../../core/shared/date.helpers";
import {SaveCarPartComponent} from "../save-car-part/save-car-part.component";
import {FileWrapper} from "../../../core/upload/components/upload-notification/upload-notification.di";
import {isValidExcelFile, isValidObject} from "../../../core/shared/helpers/common.helpers";
import {AsyncJob, AsyncJobType, FileToUpload, FileUploadTarget} from "../../../core/app-dto/core.dto";
import {UploadInputEvent} from "../../../core/app-store";
import {UploadInput} from "../../../core/upload/components/upload-notification/upload-notification.component";
import {TotalCarPartsValueSandbox} from "./total-car-parts-value.sandbox";
import {BaseWebComponent} from "../../../core/shared/base/base.component";

@Component({
  selector: 'app-total-car-parts-value',
  templateUrl: './total-car-parts-value.component.html',
  styleUrls: ['./total-car-parts-value.component.scss']
})
export class TotalCarPartsValueComponent extends BaseWebComponent implements OnInit {
  public getDataAP : GetTotalCarPartsValueActionProxy;
  public totalValue:number = 0;


  constructor(public sandbox: TotalCarPartsValueSandbox,
              actionsSubj: ActionsSubject,
              private configService: ConfigService,
              public router: Router,
              private modalService: BsModalService,
              public appState: Store<store.State>,
              private notifierService: NotifierService,
              public rulesProvider:AppUserBusinessRuleProvider
  ) {
    super(sandbox);
    this.getDataAP = new GetTotalCarPartsValueActionProxy(this, sandbox.appState);
  }

  ngOnInit() {
    this.initialize((result:number)=>{
      this.totalValue = result;
    });
    this.sandbox.setResetCallback(()=>{
      this.getDataAP.execute();
    });
    this.getDataAP.execute();
  }
}
