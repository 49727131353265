import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ServifyPaginationSandbox } from './servify-pagination.sandbox';

@Component({
  selector: 'app-servify-pagination',
  templateUrl: './servify-pagination.component.html',
  styleUrls: ['./servify-pagination.component.scss']
})
export class ServifyPaginationComponent {
  public position;
  public servifyPaginationPosition = ServifyPaginationPosition;
  public  currentPageValue : number = 1;
  @Input("currentPage") set currentPage(value: number) {
    this.currentPageValue = value;
  }
  @Input() totalPages: number = 1;

  @Output() onChange: EventEmitter<number> = new EventEmitter<number>();

  constructor(public sandbox: ServifyPaginationSandbox) {
    this.position = ServifyPaginationPosition.first;
  }

  isPreviousDisabled() {
    return this.currentPageValue === 1;
  }

  goToPreviousPage() {
    if (!this.isPreviousDisabled()) {
      this.currentPageValue -= 1;
      this.onChange.emit(this.currentPageValue);
      this.setActiveDot();
    }
  }

  isNextDisabled() {
    return this.currentPageValue === this.totalPages;
  }

  goToNextPage() {
    if (!this.isNextDisabled()) {
      this.currentPageValue += 1;
      this.onChange.emit(this.currentPageValue);
      this.setActiveDot();
    }
  }

  setActiveDot() {
    if (this.currentPageValue === 1) {
      this.position = ServifyPaginationPosition.first;
    } else if (this.currentPageValue === this.totalPages) {
      this.position = ServifyPaginationPosition.last;
    } else {
      this.position = ServifyPaginationPosition.inbetween;
    }
  }
}

export enum ServifyPaginationPosition {
  first,
  inbetween,
  last
}
