import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute } from '@angular/router';
import { BaseWebComponent } from 'src/app/core/shared/base/base.component';
import { CarServiceOverviewUnauthorizedSandbox } from './car-service-overview-unauthorized.sandbox';
import { GetCarServiceDetailsActionProxy } from './get-car-service-details.action-proxy';
import { CarServiceOverviewUnauthorizedDI } from './car-service-overview-unauthorized.di';
import { CarService_Dto, CarAction_Dto } from 'src/app/core/app-dto/car-service.dto';
import { DateHelpers } from 'src/app/core/shared/date.helpers';
import { FileUploadTarget } from 'src/app/core/app-dto/core.dto';
import {CarServiceCompleteDI} from "../car-service-complete/car-service-complete.di";
import { CarWork_Dto } from '../../../core/app-dto/car-work.dto';

@Component({
  selector: 'app-car-service-overview-unauthorized',
  templateUrl: './car-service-overview-unauthorized.component.html',
  styleUrls: ['./car-service-overview-unauthorized.component.scss']
})
export class CarServiceOverviewUnauthorizedComponent extends BaseWebComponent implements OnInit {
  public getCarServiceAP: GetCarServiceDetailsActionProxy;

  public actionsLoaded: boolean = false;
  public actions: Array<CarAction_Dto> = [];
  public code: string = null;
  public carServiceEntryId: string = null;
  public tab: number = 1;
  public fileUploadTarget = FileUploadTarget;

  public di: CarServiceOverviewUnauthorizedDI;//we need to overwrite di with specific type for production build

  constructor(
    public activatedRoute: ActivatedRoute,
    public sandbox: CarServiceOverviewUnauthorizedSandbox,
  ) {
    super(sandbox);
    this.getCarServiceAP = new GetCarServiceDetailsActionProxy(this, sandbox.appState);
    this.di = new CarServiceOverviewUnauthorizedDI();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.code !== undefined) {
        this.code = params.code;
        this.carServiceEntryId = params.carServiceEntryId;
        this.getCarServiceAP.execute(this.code, this.carServiceEntryId);
      }
    });

    this.initialize((data: CarService_Dto) => {
      this.di = new CarServiceOverviewUnauthorizedDI(data);
      this.dataLoaded = true;
    }, (data: Array<CarAction_Dto>) => {
      this.actions = data;
      this.actionsLoaded = true;
    });
  }

  dateToString(date: Date) {
    return DateHelpers.dateToString(date, 'DD/MM/YYYY');
  }

  updateTab(i: number) {
    this.tab = i;
  }

  carWorksToText(data: Array<CarWork_Dto>) {
    if (!data) return '';

    return data.map(carWork => {
      return carWork.description;
    }).join(', ');
  }
}
