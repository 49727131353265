import { BaseDI } from 'src/app/core/shared/base/base.di';
import { Supply_Dto } from '../../../core/app-dto/supply.dto';
import { InvoiceStatus } from '../../../core/app-dto/invoice.dto';

export class SupplyDetailsDi extends BaseDI<Supply_Dto> {
    public computedStatus: string;
    public isPaid:boolean=false;
    public isNew:boolean=false;
    public isOverdue:boolean=false;
    constructor(data: Supply_Dto = new Supply_Dto(null)) {
        super(data);
        if (data.invoiceStatus === InvoiceStatus.Paid) {
            this.computedStatus = 'Platita';
            this.isPaid = true;
        } else if (data.invoiceStatus === InvoiceStatus.New) {
            this.computedStatus = 'Neplatita';
            this.isNew = true;
        } else {
            this.computedStatus = 'Depasita';
            this.isOverdue = true;
        }
    }

    getModel(): Supply_Dto {
        return this.model;
    }
}
