import {Injectable} from '@angular/core';
import * as store from '../../../app-store/index';
import {select, Store} from '@ngrx/store';
import {BaseSandbox} from 'src/app/core/shared/base/base.sandbox';
import {PagedResult} from 'src/app/core/shared/datatable/datatable.helpers';
import {NotificationDI} from "./notification.di";
import {AppNotification} from "../../../app-dto/core.dto";

@Injectable()
export class NotificationsSandbox extends BaseSandbox {
  public notifications$ = this.appState$.pipe(select(store.getAppNotifications));
  public unreadNotificationsCount$ = this.appState$.pipe(select(store.getUnreadAppNotificationsCount));
  public allNotifications: Array<NotificationDI> = [];
  public latestNotificationsLoading: boolean = false;
  public message: string = '';
  public unreadNotifications: number = 0;

  constructor(protected appState$: Store<store.State>) {
    super(appState$);
  }

  public clearSandboxState() {
    this.allNotifications = [];
  }



  public initialize() {
    this.clearSandboxState();
    this.subscriptions.push(this.unreadNotificationsCount$.subscribe((result:number)=>{
      this.unreadNotifications = result;
      if (this.unreadNotifications == 0)
        this.message = 'No unread messages';
      if (this.unreadNotifications == 1)
        this.message = 'You have 1 unread message';
      if (this.unreadNotifications > 1)
        this.message = 'You have ' + this.unreadNotifications + ' unread messages';
    }));
    this.subscriptions.push(this.notifications$.subscribe((result: PagedResult<AppNotification>) => {
      if (result != null && result.items != null && result.items.length > 0) {
        this.allNotifications = result.items.map(r => {
          return new NotificationDI(r);
        });
      } else {
        this.allNotifications = [];
      }
    }));
  }
}
