import {BaseFormDI} from 'src/app/core/shared/base/base.di';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CarAction_Dto} from 'src/app/core/app-dto/car-service.dto';
import {Mechanic_Dto} from "../../../core/app-dto/mechanic.dto";
import {IdNameKey_Dto} from "../../../core/app-dto/misc.dto";
import {ObjectValidators} from "../../../core/shared/object.validators";
import {YesNoComponent, YesNoComponentInput} from "../../../core/shared/components/yes-no/yes-no.component";
import {CarActionDiHelpers} from "../../common/di/car-action.di.helpers";
import {isValidNumber, isValidString} from "../../../core/shared/helpers/common.helpers";
import {DateTimeService} from "../../../core/core/services/date-time.service";
import * as moment from "moment";

export class CarServiceAddActionDI extends BaseFormDI<CarAction_Dto> {
  public textForWindow = 'Adauga lucrare';
  public durationOrEntirePriceLabel: string = '';
  public priceForEntireActionHasVATInput: YesNoComponentInput;
  public isWaitingStatus: boolean = false;
  public isNoPlatformChecked: boolean = false;
  public startTime: Date;
  public endTime: Date;

  constructor(data: CarAction_Dto = new CarAction_Dto(null), public dateTimeService: DateTimeService, public canChangeAllInfo: boolean = true) {
    super(new CarAction_Dto(data));
    if (isValidString(this.model.id)) this.textForWindow = 'Editare lucrare';
    this.startTime = this.model.date;
    this.endTime = this.model.endDate;
    this.initForm();
    this.priceForEntireActionHasVATInput = new YesNoComponentInput(this.model.priceHasVAT, !this.model.priceHasVAT, "Include TVA?",
      this.form, 'priceHasVAT');
  }

  getModel(): CarAction_Dto {
    const formValue = this.form.getRawValue();
    this.model.carWork = formValue.carWork;
    this.model.mechanic = formValue.mechanic;
    this.model.department = formValue.department;
    this.model.duration = formValue.duration;
    this.model.actualDuration = formValue.actualDuration;
    this.model.price = formValue.price;
    if (this.canChangeAllInfo) {
      const priceForEntireActionHasVATControl = this.form.get(this.priceForEntireActionHasVATInput.formGroupName);
      this.model.priceHasVAT = priceForEntireActionHasVATControl !=
      null ? priceForEntireActionHasVATControl.get(YesNoComponent.YES_CONTROL_NAME).value : false;
      this.model.hasPrice = formValue.priceIsForEntireAction;
    }
    this.model.status = formValue.status;

    this.model.date = formValue.date;
    this.model.date.setHours(formValue.startTime ? formValue.startTime.getHours() : new Date().getHours(),
      formValue.startTime ? formValue.startTime.getMinutes() : new Date().getMinutes(), 0, 0);
    this.model.date = this.dateTimeService.toUTC(this.model.date);

    this.model.endDate = formValue.endDate;
    if(this.model.endDate != null){
      this.model.endDate.setHours(formValue.endTime ? formValue.endTime.getHours() : new Date().getHours(),
        formValue.endTime ? formValue.endTime.getMinutes() : new Date().getMinutes(), 0, 0);
      this.model.endDate = this.dateTimeService.toUTC(this.model.endDate);
    }

    this.model.servicePlatform = formValue.servicePlatform;
    this.model.observation = formValue.observation;
    this.model.waitingStatusReason = formValue.waitingStatusReason;

    return this.model;
  }

  initForm() {
    if (!this.startTime) {
      this.startTime = new Date();
      this.startTime.setHours(12);
      this.startTime.setMinutes(0);
      this.startTime.setSeconds(0);
      this.startTime.setMilliseconds(0);
    }
    if (!this.endTime) {
      this.endTime = new Date();
      this.endTime.setHours(13);
      this.endTime.setMinutes(0);
      this.endTime.setSeconds(0);
      this.endTime.setMilliseconds(0);
    }
    if(this.model.date == null) this.model.date = new Date();
    this.selectedStartDate = new Date(this.model.date.getFullYear(), this.model.date.getMonth(), this.model.date.getDate(),
      this.startTime.getHours(),this.startTime.getMinutes(), 0, 0);

    if(this.model.endDate != null) {
      this.selectedEndDate = new Date(this.model.endDate.getFullYear(), this.model.endDate.getMonth(), this.model.endDate.getDate(),
        this.endTime.getHours(),this.endTime.getMinutes(), 0, 0);
    }
    else{
      this.selectedEndDate = this.endTime = this.model.endDate = moment(this.selectedStartDate).add(this.model.duration, "hours").toDate();

    }

    if (this.model.servicePlatform)
      this.model.clearPlatform = false;
    else
      this.model.clearPlatform = true;

    this.form = new FormGroup({
      carWork: new FormControl(this.model.carWork, Validators.compose([Validators.required])),
      mechanic: new FormControl(this.model.mechanic, Validators.compose([Validators.required])),
      department: new FormControl(this.model.department, Validators.compose([Validators.required])),
      duration: new FormControl(this.model.duration, Validators.compose([Validators.required])),
      actualDuration: new FormControl(this.model.actualDuration, Validators.compose([Validators.required])),
      price: new FormControl(this.model.price, Validators.compose([Validators.required])),
      statusSelectedValue: new FormControl(this.model.status, Validators.compose([Validators.required])),
      date: new FormControl(this.model.date || new Date(), Validators.compose([Validators.required])),
      endDate: new FormControl(this.model.endDate || new Date(), Validators.compose([Validators.required])),
      startTime: new FormControl(this.startTime, Validators.compose([Validators.required])),
      endTime: new FormControl(this.endTime, Validators.compose([Validators.required])),
      priceIsForEntireAction: new FormControl(this.model.hasPrice, Validators.compose([])),
      observation: new FormControl(this.model.observation, Validators.compose([])),
      waitingStatusReason: new FormControl(this.model.waitingStatusReason, Validators.compose([])),
      servicePlatform: new FormControl(this.model.servicePlatform, Validators.compose([Validators.required])),
      clearPlatform: new FormControl(this.model.clearPlatform, Validators.compose([])),
    });

    if (ObjectValidators.isValidObject(this.model.status)) {
      this.statusChanged(new IdNameKey_Dto({
        id: this.model.status.id,
        name: this.model.status.name,
        key: this.model.status.key
      }));
    } else {
      this.statusChanged(null);
    }
    if (this.canChangeAllInfo == false) {
      this.form.controls["mechanic"].disable({onlySelf: true, emitEvent: false});
      this.form.controls["department"].disable({onlySelf: true, emitEvent: false});
      this.form.controls["duration"].disable({onlySelf: true, emitEvent: false});
      this.form.controls["date"].disable({onlySelf: true, emitEvent: false});
      this.form.controls["startTime"].disable({onlySelf: true, emitEvent: false});
      this.form.controls["endDate"].disable({onlySelf: true, emitEvent: false});
      this.form.controls["endTime"].disable({onlySelf: true, emitEvent: false});
      this.form.controls["price"].disable({onlySelf: true, emitEvent: false});
      this.form.controls["priceIsForEntireAction"].disable({onlySelf: true, emitEvent: false});
      this.form.controls["servicePlatform"].disable({onlySelf: true, emitEvent: false});
    }
    if (this.form.controls.priceIsForEntireAction.value) {
      this.durationOrEntirePriceLabel = 'Pret manopera';
    } else {
      this.durationOrEntirePriceLabel = 'Durata(ore)';
    }
  }

  priceIsForEntireActionChanged(value: boolean) {
    if (value) {
      this.durationOrEntirePriceLabel = "Pret manopera";
      //this.form.get("priceIsWithVat").setValue(true);
      //this.form.get(this.priceForEntireActionHasVATInput.formGroupName).get(YesNoComponent.YES_CONTROL_NAME).setValue(true);
      this.priceForEntireActionHasVATInput = new YesNoComponentInput(true, false, "Include TVA?",
        this.form, "priceHasVAT");
      this.form.get("duration").setValue(0);
    }
    else {
      this.durationOrEntirePriceLabel = "Durata(ore)";
      this.form.get("price").setValue(0);
    }
    this.form.controls.priceIsForEntireAction.setValue(value);
  }

  mechanicChanged(data: Mechanic_Dto) {
    if (data == null) this.priceIsForEntireActionChanged(false);
  }

  clearServicePlatform(value) {
    this.isNoPlatformChecked = value;
    if (value) {
      this.form.get("servicePlatform").setValue(null);
      this.form.get("servicePlatform").disable({onlySelf: true, emitEvent: true});
    }
    else {
      this.form.get("servicePlatform").enable();
    }
  }

  statusChanged(data: IdNameKey_Dto) {
    if (data == null) {
      return;
    }
    if (CarActionDiHelpers.isWaitingStatus(data)) {
      this.isWaitingStatus = true;
      this.form.get("waitingStatusReason").setValidators([Validators.required]);
      this.form.get("waitingStatusReason").updateValueAndValidity();

      this.form.get("servicePlatform").setValidators([]);
      this.form.get("servicePlatform").updateValueAndValidity();
    }
    else{
      this.isWaitingStatus = false;
      this.form.get("servicePlatform").enable();
      this.form.get("waitingStatusReason").setValue(null);

      this.form.get("servicePlatform").setValidators([Validators.required]);
      this.form.get("servicePlatform").updateValueAndValidity();

      this.form.get("waitingStatusReason").setValidators([]);
      this.form.get("waitingStatusReason").updateValueAndValidity();
    }

  }

  durationChanged(hours: number) {
    // if (isValidNumber(hours)) {
    //   if (this.selectedStartDateDate) {
    //     this.selectedEndDateDate = moment(this.selectedStartDateDate).add(hours, "hours").toDate();
    //   }
    // }
  }

  public selectedStartDate: Date;
  public selectedEndDate: Date;


  public startDateChanged(data: Date) {
    this.model.endDate = this.form.value.startDate;
    this.form.controls.endDate.setValue(this.form.value.startDate);
    if (data && this.form.value.startTime) {
        this.selectedStartDate = new Date(data.getFullYear(), data.getMonth(), data.getDate(),this.form.value.startTime.getHours(),this.form.value.startTime.getMinutes(),0,0);
    }
    else this.selectedStartDate = null;
  }

  startTimeChanged(data: Date) {
    if (data) {
      if(this.selectedStartDate) {
        this.selectedStartDate = new Date(this.selectedStartDate.getFullYear(), this.selectedStartDate.getMonth(), this.selectedStartDate.getDate(), this.form.value.startTime.getHours(), this.form.value.startTime.getMinutes(), 0, 0);
      }
    }
    else this.selectedStartDate = null;
  }
  endDateChanged(data: Date) {
    if (data && this.form.value.endTime) {
      this.selectedEndDate = new Date(data.getFullYear(), data.getMonth(), data.getDate(),this.form.value.endTime.getHours(),this.form.value.endTime.getMinutes(),0,0);
    }
    else this.selectedEndDate = null;
  }
  endTimeChanged(data: Date) {
    if (data) {
      if(this.selectedEndDate) {
        this.selectedEndDate = new Date(this.selectedEndDate.getFullYear(), this.selectedEndDate.getMonth(), this.selectedEndDate.getDate(), this.form.value.endTime.getHours(), this.form.value.endTime.getMinutes(), 0, 0);
      }
    }
    else this.selectedEndDate = null;
  }
  public busyFlag: boolean = false;
  busyStateFlagChanged(value: boolean) {
    this.busyFlag = value;
  }
}
