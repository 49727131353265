import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { CarService_Dto, FinalInspectionUser_Dto } from '../../../core/app-dto/car-service.dto';

@Injectable()
export class CarServiceSaveFinalInspectionSandbox extends BaseSandbox {
    public getCarServiceUsers$ = this.appState$.pipe(select(store.getCarServiceUsersListing));

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
    }

    public initialize(carServiceUsers: (data: Array<FinalInspectionUser_Dto>) => void): any {
        this.clearSandboxState();

        this.subscriptions.push(this.getCarServiceUsers$.subscribe((data: Array<FinalInspectionUser_Dto>) => {
            if (ObjectValidators.isValidArray(data) && data.length > 0) {
                carServiceUsers(data);
            }
        }));
    }
}
