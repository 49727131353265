import {Injectable} from '@angular/core';
import {BaseSandbox} from '../../shared/base/base.sandbox';
import {select, Store} from '@ngrx/store';
import * as store from '../../app-store';
import {Version_Dto} from '../../app-dto/version.dto';
import {ObjectValidators} from '../../shared/object.validators';

@Injectable()
export class FooterSandbox extends BaseSandbox {
    public getVersion$ = this.appState$.pipe(select(store.getVersion));

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    clearSandboxState() {
    }

    initialize(dataLoaded): any {
        this.clearSandboxState();
        this.subscriptions.push(this.getVersion$.subscribe((data: Version_Dto) => {
            if (ObjectValidators.isValidObject(data)) {
                dataLoaded(data);
            }
        }));
    }
}
