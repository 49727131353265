import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { DeleteDocumentEvent } from 'src/app/core/app-store/events/documents-events';
import {FileUploadTarget} from "../../../core/app-dto/core.dto";

export class DeletePhotosActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
        appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(id: string,target: Array<FileUploadTarget>, relatedEntityId: string): any {
        this.appState.dispatch(new DeleteDocumentEvent(id, target, relatedEntityId));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

