import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import {
  BaseListWebComponentWithSearchAndActiveBehavior
} from 'src/app/core/shared/base/base.component';
import { ConfigService } from 'src/app/core/core/app-config.service';
import { Router } from '@angular/router';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { ActiveDataTableParameters, DataTableParametersOrderEntity } from 'src/app/core/shared/datatable/datatable.helpers';
import { BsModalService } from 'ngx-bootstrap';
import { GetCarsActionProxy } from './get-cars.action-proxy';
import { CarsListSandbox } from './cars-list.sandbox';
import { DateHelpers } from '../../../core/shared/date.helpers';
import { SaveCarComponent } from '../save-car/save-car.component';
import { ActivateCarActionProxy } from './activate-car.action-proxy';
import { DeactivateCarActionProxy } from './deactivate-car.action-proxy';
import { DeleteCarActionProxy } from './delete-car.action-proxy';
import { getFormattedIdNameList } from '../../../core/shared/helpers/common.helpers';
import { Car_Dto } from 'src/app/core/app-dto/car.dto';
import { ConfirmationDialogService } from '../../../core/core/dialog/confirmation-dialog.service';
import { IdName_Dto } from '../../../core/app-dto/misc.dto';
import {CarsListItemDi} from "./cars-list-item.di";
import {CarService_Dto} from "../../../core/app-dto/car-service.dto";
import {AddEditCarInServiceWizardComponent} from "../../../car-service/components/add-edit-car-in-service-wizard/add-edit-car-in-service-wizard.component";

@Component({
  selector: 'app-cars-list',
  templateUrl: './cars-list.component.html',
  styleUrls: ['./cars-list.component.scss']
})
export class CarsListComponent extends BaseListWebComponentWithSearchAndActiveBehavior implements OnInit, AfterViewInit {
  public getCarsAP: GetCarsActionProxy;
  public activateCarAP: ActivateCarActionProxy;
  public deactivateCarAP: DeactivateCarActionProxy;
  public deleteCarAP: DeleteCarActionProxy;
  public columns: any = [];
  public sortColumns: Array<IdName_Dto> = [];
  public currentPage = 1;

  constructor(public sandbox: CarsListSandbox,
              actionsSubj: ActionsSubject,
              private configService: ConfigService,
              public router: Router,
              public confirmationDialogService: ConfirmationDialogService,
              private modalService: BsModalService) {
    super(sandbox, actionsSubj, ResetSection.Cars);
    this.getCarsAP = new GetCarsActionProxy(this, sandbox.appState);
    this.activateCarAP = new ActivateCarActionProxy(this, sandbox.appState);
    this.deactivateCarAP = new DeactivateCarActionProxy(this, sandbox.appState);
    this.deleteCarAP = new DeleteCarActionProxy(this, sandbox.appState);

    this.columns = [
      { data: 'registrationNumber', name: 'Numar inmatriculare', width: '15%' },
      { data: 'bodySeries', name: 'Serie caroserie', width: '15%' },
      { data: 'make', name: 'Marca',  width: '15%' },
      { data: 'model', name: 'Model', width: '15%' },
      { data: 'year', name: 'An fabricatie', width: '10%' },
      { data: 'power', name: 'Kw', width: '10%' },
      { data: 'fuel', name: 'Combustibil', width: '10%' },
      { data: 'cilindricalCapacity', name: 'Capacitate cilindrica', width: '10%' },
      { data: 'action', width: '50px', orderable: false },
      { data: 'createdOn', width: '0', orderable: false }
    ];
    this.sortColumns = this.columns.filter(c => c.name).map(c => ({id: c.data, name: c.name} as IdName_Dto));
  }

  ngAfterViewInit() {
    this.setBreadcrumbAP.execute([{text: 'Acasa', url: '/dashboard'}, {text: 'Masini'}]);
  }

  ngOnInit() {
    this.initialize();

    this.dtOptions = {
      responsive: true,
      paging: false,
      info: false,
      lengthChange: false,
      searching: false,
      pageLength: this.configService.getDefaultPageSize(),
      scrollX: false,
      serverSide: true,
      processing: false,
      ordering: false,
      autoWidth: false,
      ajax: (dataTablesParameters: ActiveDataTableParameters, callback) => {
        dataTablesParameters.active = this.isActive;
        dataTablesParameters.search.value = this.searchValue;
        this.doGetAll(new ActiveDataTableParameters(dataTablesParameters), callback, true);
      },
      columns: this.columns,
      order: [[9, 'desc']],
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    };
  }

  public doGetAll(dataTablesParameters: ActiveDataTableParameters, callback: any, withReset: boolean) {
    this.sandbox.callback = callback;
    this.sandbox.dataTableParameters = dataTablesParameters;

    if (withReset) {
      setTimeout(() => {this.currentPage = 1; }, 0);
      this.sandbox.dataTableParameters.pageNumber = 1;
      dataTablesParameters.pageNumber = 1;
    }
    this.getCarsAP.execute(dataTablesParameters, withReset);
  }

  onSortChange($event) {
    if ($event) {
      const columnId = this.columns.map((e) => e.data).indexOf($event.id);
      const orderEntity = new DataTableParametersOrderEntity({column: columnId, dir: 'asc'});
      this.sandbox.dataTableParameters.order = [orderEntity];
    }
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, true);
  }

  dateToString(date: Date) {
    return DateHelpers.dateToString(date, 'DD/MM/YYYY');
  }

  createCar() {
    const modalRef = this.modalService.show(SaveCarComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = null;
  }

  updateCar(car: Car_Dto) {
    const modalRef = this.modalService.show(SaveCarComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = car;
  }

  activateCar(carId: string) {
    this.activateCarAP.execute(carId);
  }

  deactivateCar(carId: string) {
    this.deactivateCarAP.execute(carId);
  }

  addCarInService(data:CarsListItemDi){
    const carService = new CarService_Dto({
      car: data.getModel(),
      carServiceEntryData : {}
    });
    const initialState = {
      carService,
      title: 'Adauga masina in service'
    };
    const modalRef = this.modalService.show(AddEditCarInServiceWizardComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
    this.router.navigate(['car-service','cars', 'all']);
  }

  deleteCar(car: Car_Dto) {
    this.confirmationDialogService.confirm(
      'Stergere masina',
      "Urmeaza sa stergeti masina <b>" + car.registrationNumber + "</b>. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
        if (confirmed) {
          this.deleteCarAP.execute(car.id);
        }
      });
  }

  formatFuels(fuelsList) {
    return getFormattedIdNameList(fuelsList);
  }

  onPageChange(currentPage: number) {
    this.currentPage - currentPage;
    this.sandbox.dataTableParameters.pageNumber = currentPage;
    this.doGetAll(this.sandbox.dataTableParameters, this.sandbox.callback, false);
  }
}
