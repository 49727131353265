import {
    UIGeneratedEvent,
    EventWithScreenLoading,
    SeamlessEvent,
} from './base.events';
import {ActiveDataTableParameters, DataTableParameters} from '../../shared/datatable/datatable.helpers';




export class SearchOrganizationEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = 'SearchOrganizationEvent';

    constructor(public search: string, public callBack: Function, public displayOnlyAvailableItems: boolean = true) {
        super();
        this.type += SearchOrganizationEvent.identifier;
    }
}

export class GetSuperAdminLogsPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'GetSuperAdminLogsPagedResultEvent';

    constructor(public parameters: ActiveDataTableParameters, public withReset: boolean = false, public organizationFilter: string, public from: Date, public to: Date) {
        super();
        this.type += GetSuperAdminLogsPagedResultEvent.identifier;
    }
}

