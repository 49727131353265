import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Mechanic_Dto } from 'src/app/core/app-dto/mechanic.dto';
import { validEndDate } from 'src/app/core/shared/form/custom-form.validator';

export class ActivateMechanicDi extends BaseFormDI<Mechanic_Dto> {

    public endDate: Date;
    public startDate: Date;

    constructor(data: Mechanic_Dto = new Mechanic_Dto(null)) {
        super(new Mechanic_Dto(data));
        this.startDate = new Date();
        if(this.model.history.length > 0) {
            this.endDate = this.model.history[this.model.history.length - 1].endDate;
        }
        this.initForm();
    }

    getModel(): Mechanic_Dto {
        return this.model;
    }

    getDate(): Date {
        this.startDate = this.form.value.startDate;
        return this.startDate;
    }

    initForm() {
        this.form = new FormGroup({
            startDate: new FormControl(this.startDate, Validators.compose([Validators.required, validEndDate(this.endDate)])),
        });
    }
}
