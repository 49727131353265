import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {isValidObject} from '../../helpers/common.helpers';

@Component({
  selector: 'app-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.scss']
})
export class YesNoComponent implements OnInit, OnDestroy {
  public static YES_CONTROL_NAME: string = 'yes';
  public form: FormGroup;
  public dataInput: YesNoComponentInput;
  public formGroupName: string;
  public showForm = true;

  @Input('input') set input(data: YesNoComponentInput) {
    if (isValidObject(data) && isValidObject(data.form)) {
      this.dataInput = data;
      this.formGroupName = data.formGroupName;

      if (data.form.get(data.formGroupName) == null) {
        data.form.addControl(data.formGroupName, new FormGroup({
          yes: new FormControl({value : data.yesChecked, disabled:data.isDisabled}, Validators.compose([])),
          no: new FormControl({value : data.noChecked, disabled:data.isDisabled}, Validators.compose([])),
        }));
      } else {
        data.form.get(data.formGroupName).get('yes').setValue(data.yesChecked);
        data.form.get(data.formGroupName).get('no').setValue(data.noChecked);
      }
      this.form = data.form;
    }
  }

  public formSubmitted: boolean = false;
  @Input('formSubmitted') set submitted(value: boolean) {
    this.formSubmitted = value;
  }

  @Output()
  valueChanged = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  isInvalidControl(control: AbstractControl): boolean {
    if (!control.valid && this.formSubmitted)
      return true;
    return false;
  }

  yesChecked(newValue) {
    this.form.get(this.dataInput.formGroupName).get('no').setValue(!newValue);
    this.form.get(this.dataInput.formGroupName).get('yes').setValue(newValue);

    if (newValue == true) this.valueChanged.emit(true);
    else this.valueChanged.emit(false);
  }

  noChecked(newValue) {
    this.form.get(this.dataInput.formGroupName).get('yes').setValue(!newValue);
    this.form.get(this.dataInput.formGroupName).get('no').setValue(newValue);

    this.form.get(this.dataInput.formGroupName).updateValueAndValidity();
    if (newValue == true) this.valueChanged.emit(false);
    else this.valueChanged.emit(true);
  }
}

export class YesNoComponentInput {
  constructor(public yesChecked: boolean,
              public noChecked: boolean,
              public label: string,
              public form: FormGroup,
              public formGroupName: string,
              public isDisabled:boolean = false) {
  }
}

export class YesNoOutput {
  public yesChecked: boolean;
  public noChecked: boolean;
}
