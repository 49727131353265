import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiErrorDto } from '../utils/api-error.dto';
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { CalendarEvent_Dto, CalendarStatistics } from '../../app-dto/calendar-event.dto';
import { ObjectValidators } from '../../shared/object.validators';
import { ErrorHelpers } from '../../shared/error.helpers';
import { cleanSearchValue } from '../utils/api-integration.helper';
import { CarAppointment_Dto } from '../../app-dto/car-appointment.dto';
import {AppointmentCarWorks_Dto} from '../../app-dto/car-work.dto';

class CalendarApiRoutes {
    public static route_for_REGISTER_CALENDAR_EVENT() {
        return '/api/calendarEvent';
    }
    public static route_for_EDIT_CALENDAR_EVENT() {
        return '/api/calendarEvent';
    }
    public static route_for_DELETE_CALENDAR_EVENT(id: string) {
        return `/api/calendarEvent/${id}`;
    }
    public static route_for_GET_CALENDAR_EVENTS() {
        return `/api/calendarEvent/`;
    }
    public static route_for_GET_CALENDAR_STATISTICS() {
        return `/api/calendarEvent/statistics`;
    }
    public static route_for_CONFIRM_CALENDAR_EVENT(id: string) {
        return `/api/calendarEvent/${id}/confirm`;
    }

    public static route_for_REGISTER_CAR_APPOINTMENT_EVENT() {
        return '/api/carAppointment';
    }
    public static route_for_UPDATE_CAR_APPOINTMENT_EVENT() {
        return '/api/carAppointment';
    }
    public static route_for_GET_CAR_APPOINTMENT_EVENT(id: string) {
        return `/api/carAppointment/${id}`;
    }
  public static route_for_DELETE_CAR_APPOINTMENT_EVENT(id: string) {
    return `/api/carAppointment/${id}`;
  }
}

@Injectable()
export class CalendarApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_registerCalendarEvent(calendarEvent: CalendarEvent_Dto): Observable<CalendarEvent_Dto> {
        const url = this.configService.getAPIBaseUrl() + CalendarApiRoutes.route_for_REGISTER_CALENDAR_EVENT();
        return this.http.post(url, calendarEvent)
            .map((res: any) => {
                return new CalendarEvent_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_registerCarAppointmentEvent(calendarEvent: CarAppointment_Dto): Observable<CarAppointment_Dto> {
        const url = this.configService.getAPIBaseUrl() + CalendarApiRoutes.route_for_REGISTER_CAR_APPOINTMENT_EVENT();
        return this.http.post(url, calendarEvent)
            .map((res: any) => {
                return new CarAppointment_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_updateCarAppointmentEvent(calendarEvent: CarAppointment_Dto): Observable<CarAppointment_Dto> {
        const url = this.configService.getAPIBaseUrl() + CalendarApiRoutes.route_for_UPDATE_CAR_APPOINTMENT_EVENT();
        return this.http.put(url, calendarEvent)
            .map((res: any) => {
                return new CarAppointment_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_editCalendarEvent(calendarEvent: CalendarEvent_Dto): Observable<CalendarEvent_Dto> {
        const url = this.configService.getAPIBaseUrl() + CalendarApiRoutes.route_for_EDIT_CALENDAR_EVENT();

        return this.http.put(url, calendarEvent)
            .map((res: any) => {
                return new CalendarEvent_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deleteCalendarEvent(id: string): Observable<boolean> {
        const url = this.configService.getAPIBaseUrl() + CalendarApiRoutes.route_for_DELETE_CALENDAR_EVENT(id);

        return this.http.delete(url)
            .map((res: any) => {
                return true;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getCalendarEvents(startDate: Date, endDate: Date, type: string = null, search: string = null): Observable<Array<CalendarEvent_Dto>> {
        var url = this.configService.getAPIBaseUrl() + CalendarApiRoutes.route_for_GET_CALENDAR_EVENTS() + '?' +
            'startDate=' + startDate.toDateString() + ' ' + startDate.toLocaleTimeString() + '&' +
            'endDate=' + endDate.toDateString() + ' ' + endDate.toLocaleTimeString();
        if (ObjectValidators.isValidString(type))
            url = url + '&type=' + type;
        if (ObjectValidators.isValidString(search))
            url = url + '&search=' + cleanSearchValue(search);

        return this.http.get(url)
            .map((res: any) => {
                return res.map(r => new CalendarEvent_Dto(r));
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
    u_getCalendarEventStatistics(): Observable<CalendarStatistics> {
        var url = this.configService.getAPIBaseUrl() + CalendarApiRoutes.route_for_GET_CALENDAR_STATISTICS();
        return this.http.get(url)
            .map((res: any) => {
                return new CalendarStatistics(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_confirmCalendarEvent(id: string): Observable<CalendarEvent_Dto> {
        const url = this.configService.getAPIBaseUrl() + CalendarApiRoutes.route_for_CONFIRM_CALENDAR_EVENT(id);

        return this.http.get(url)
            .map((res: any) => {
                return new CalendarEvent_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getCarAppointmentEvent(id: string): Observable<CarAppointment_Dto> {
        const url = this.configService.getAPIBaseUrl() + CalendarApiRoutes.route_for_GET_CAR_APPOINTMENT_EVENT(id);

        return this.http.get(url)
            .map((res: any) => {
                const carAppointment = new CarAppointment_Dto(res);
                return carAppointment;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
  u_deletetCarAppointmentEvent(id: string): Observable<string> {
    const url = this.configService.getAPIBaseUrl() + CalendarApiRoutes.route_for_DELETE_CAR_APPOINTMENT_EVENT(id);

    return this.http.delete(url)
      .map((res: any) => {
        return id;
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }
}
