import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseFormModelComponent} from '../../../core/shared/base/base.component';
import {CarService_Dto} from '../../../core/app-dto/car-service.dto';
import {EditCarServiceCarActionProxy} from '../../../car-service/components/edit-car-service-car/edit-car-service-car.action-proxy';
import {Subject} from 'rxjs';
import {CarFormComponent} from '../../../car/components/car-form/car-form.component';
import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {EditCarServiceCarSandbox} from '../../../car-service/components/edit-car-service-car/edit-car-service-car.sandbox';
import {BsModalRef} from 'ngx-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {ResetSection} from '../../../core/app-store/events/base.events';
import {EditCarServiceCarDi} from '../../../car-service/components/edit-car-service-car/edit-car-service-car.di';
import {Car_Dto} from '../../../core/app-dto/car.dto';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {Offer_Dto, OfferCar_Dto} from '../../../core/app-dto/offer.dto';
import {UpdateOfferCarDataActionProxy} from '../../common/ap/update-offer-car-data.action-proxy';
import {isValidObject} from '../../../core/shared/helpers/common.helpers';

@Component({
    selector: 'app-update-offer-car-data',
    templateUrl: './update-offer-car-data.component.html',
    styleUrls: ['./update-offer-car-data.component.scss']
})
export class UpdateOfferCarDataComponent extends BaseFormModelComponent<Offer_Dto> implements OnInit, OnDestroy {
    public editDataAP: UpdateOfferCarDataActionProxy;
    public onClose: Subject<boolean>;
    public offer: Offer_Dto;
    public carDto : Car_Dto;

    @ViewChild(CarFormComponent, {static: false})
    private carFormComponent: CarFormComponent;

    constructor(public appState: Store<store.State>,
                private activeModal: BsModalRef,
                public activatedRoute: ActivatedRoute) {
        super(null, ResetSection.CarService);
        this.editDataAP = new UpdateOfferCarDataActionProxy(this, appState);
        this.di = new EditCarServiceCarDi();
    }

    public diInitialize(data: Offer_Dto) {
        this.offer = new Offer_Dto(data);
        if (isValidObject(this.offer)) {
            this.carDto= new Car_Dto(this.offer.car);
        }
        this.dataLoaded = true;
    }

    ngOnInit() {
        this.onClose = new Subject();
    }

    ngOnDestroy() {
        this.destroy();
    }

    public decline() {
        this.onClose.next(false);
        this.activeModal.hide();
    }

    public accept() {
        this.formSubmitted = true;
        this.carFormComponent.accept();
        this.carFormComponent.markFormAsDirtyAndTouched();
    }

    carDetailsFormSaved(data: Car_Dto) {
        if (ObjectValidators.isValidObject(data)) {
            this.offer.car = new OfferCar_Dto(data);
            if (this.editDataAP.canExecute()) {
                this.editDataAP.execute(this.offer);
                this.onClose.next(true);
                this.activeModal.hide();
            }
        }
    }
}
