import { State } from '../state';
import * as fromAuthentication from "../stores/authentication.store";
import {createSelector} from "@ngrx/store";

export const getAuthenticatedStateCallback = (state: State) => state.authentication;

export const getLogin = createSelector(getAuthenticatedStateCallback, fromAuthentication.getLoginCallback);
export const getForgotPassword  = createSelector(getAuthenticatedStateCallback, fromAuthentication.getForgotPasswordCallback);
export const getResetPassword  = createSelector(getAuthenticatedStateCallback, fromAuthentication.getResetPasswordCallback);
export const getLogout  = createSelector(getAuthenticatedStateCallback, fromAuthentication.getLogoutCallback);
export const getRequestSubscription  = createSelector(getAuthenticatedStateCallback, fromAuthentication.getRequestSubscriptionCallback);
export const getActivateSubscription  = createSelector(getAuthenticatedStateCallback, fromAuthentication.getActivateSubscriptionCallback);
export const getGenerateActivationLinkComponent  = createSelector(getAuthenticatedStateCallback, fromAuthentication.getGenerateActivationLinkComponentCallback);

