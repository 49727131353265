import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ServifySearchSandbox } from './servify-search.sandbox';
import { IdName_Dto } from 'src/app/core/app-dto/misc.dto';
import { Subject } from 'rxjs/Subject';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ObjectValidators } from '../../object.validators';
import { isValidObject } from "../../helpers/common.helpers";

@Component({
  selector: 'app-servify-search',
  templateUrl: './servify-search.component.html',
  styleUrls: ['./servify-search.component.scss']
})
export class ServifySearchComponent {

  public searchValue: string = null;
  public isCleared: boolean = false;
  public selectedValueId: string = null;


  @Input() items: Array<IdName_Dto>;
  @Input() isLoading: boolean = false;
  @Input() allowInput: boolean = false;
  @Input() error: boolean = false;

  @Input("initialValue") set initialValue(data: IdName_Dto) {
    if (isValidObject(data)) {

      if(data.id == undefined){
        data.id = "input";
        let i = this.items.findIndex(r => r.id == 'input');
        if (i >0) {
          this.items.splice(i,1);
        }
        this.items.push(new IdName_Dto({ id: 'input', name: data.name }));
        this.items = this.items.map(r => new IdName_Dto(r));
        this.input = data.name;
      }
      this.selectedValueId = data.id;
    }
    else {
      this.selectedValueId = null;
    }
  }

  @Output() searchCalled: EventEmitter<string> = new EventEmitter<string>();
  @Output() inputValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected = new EventEmitter<IdName_Dto>();

  public input$ = new Subject<string | null>();
  public modelChanged: Subject<string> = new Subject<string>();

  @ViewChild(NgSelectComponent, { static: false }) ngSelectComponent: NgSelectComponent;

  constructor(
    public sandbox: ServifySearchSandbox
  ) {
    this.input$.subscribe((searchQuery) => {
      this.modelChanged.next(searchQuery);
    });

    this.modelChanged.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(searchQuery => {
        this.searchValue = searchQuery;
        if (this.searchValue == null)
          this.searchValue = '';
        if (this.searchCalled != null) {
          this.searchCalled.emit(this.searchValue);
        }
      });

    this.isCleared = false;
  }

  selected(item: IdName_Dto) {
    if (this.allowInput && this.input != '') {
      let i = this.items.findIndex(r => r.id == 'input');
      if (i == -1) {
        this.items.push(new IdName_Dto({ id: 'input', name: this.input }));
        this.items = this.items.map(r => new IdName_Dto(r));
      }
      this.input = '';
    }
    if (ObjectValidators.isValidObject(item)) {
      if (this.itemSelected != null) {
        this.itemSelected.emit(item);
      }
      this.selectedValueId = item.id;
    } else {
      if (!this.isCleared) {
        if (this.itemSelected != null) {
          this.itemSelected.emit(null);
        }
      }
      this.isCleared = false;
      this.selectedValueId = null;
    }
  }

  public input: string = '';
  inputChanged(val: any) {
    this.input = val.term;
  }

  clearSelect() {
    this.isCleared = true;
    this.ngSelectComponent.handleClearClick();
  }

  onClose() {
    if (this.allowInput && this.input != '') {
      let i = this.items.findIndex(r => r.id == 'input');
      if (i == -1) {
        this.items.push(new IdName_Dto({ id: 'input', name: this.input }));
        this.items = this.items.map(r => new IdName_Dto(r));
      }
      this.selectedValueId = 'input';
      if (this.inputValue != null) {
        this.inputValue.emit(this.input);
      }
    }
  }

  onOpen() {
    if (this.allowInput && this.input != '') {
      let i = this.items.findIndex(r => r.id == 'input');
      if (i >= 0) {
        this.items.splice(i, 1);
        this.items = this.items.map(r => new IdName_Dto(r));
      }
      this.selectedValueId = null;
      this.input = '';
      if (this.inputValue != null) {
        this.inputValue.emit(this.input);
      }
    }
  }
}
