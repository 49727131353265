import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { User_Dto } from '../../../core/app-dto/core.dto';

@Injectable()
export class AddUserAccountSandbox extends BaseSandbox {

  constructor(protected appState$: Store<store.State>) {
    super(appState$);
  }

  public clearSandboxState() {
  }

  public initialize(): any {
    this.clearSandboxState();

  }
}
