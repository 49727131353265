import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientCarParts_Dto } from 'src/app/core/app-dto/car-service.dto';
import {ObjectValidators} from '../../../core/shared/object.validators';

export class ClientPartCarFormDi {
    constructor(public code: string, public name: string, public quantity: number) {
    }
}

export class CarServiceClientPartsDI extends BaseFormDI<Array<ClientCarParts_Dto>> {
    public i = 0;
    public formDi: ClientPartCarFormDi;

    constructor(data: Array<ClientCarParts_Dto> = new Array<ClientCarParts_Dto>()) {
        super(data);
        this.formDi = new ClientPartCarFormDi('', '', 1);
        this.initForm();
    }

    getModel(): Array<ClientCarParts_Dto> {
        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
            code: new FormControl(this.formDi.code, Validators.compose([Validators.required])),
            name: new FormControl(this.formDi.name, Validators.compose([Validators.required])),
            quantity: new FormControl(this.formDi.quantity, Validators.compose([Validators.required]))
        });
    }

    private markFormGroupTouched(formGroup: FormGroup) {
        if (ObjectValidators.isValidObject(formGroup)) {
            (<any>Object).values(formGroup.controls).forEach(control => {
                control.markAsTouched();

                if (control.controls) {
                    this.markFormGroupTouched(control);
                }
            });
        }
    }

    markFormAsDirtyAndTouched() {
        if (ObjectValidators.isValidObject(this.form)) {
            this.markFormGroupTouched(this.form);
            this.form.markAsTouched();
            this.form.markAsDirty();
        } else {
            if (ObjectValidators.isValidObject(this.formDi) &&
                ObjectValidators.isValidObject(this.form)) {
                this.markFormGroupTouched(this.form);
                this.form.markAsTouched();
                this.form.markAsDirty();
            }
        }
    }

    addPart() {
        console.log(this.form.valid);
        this.markFormAsDirtyAndTouched();
        if (this.form.valid) {
            let ok = 0;
            for (let j = 0; j < this.model.length; j++) {
                if (this.model[j].code === this.form.value.code) {
                    this.model[j].quantity += this.form.value.quantity;
                    ok = 1;
                }
            }
            if (ok === 0) {
                this.i++;
                this.model.push(new ClientCarParts_Dto({
                    id: this.i + '',
                    code: this.form.value.code,
                    name: this.form.value.name,
                    quantity: this.form.value.quantity,
                    um: 'buc.',
                }));
            }
            this.form.reset();
            this.form.controls.quantity.setValue(1);
        }
    }

    saveEdit() {
        this.markFormAsDirtyAndTouched();
        if (this.form.valid) {
            for (let j = 0; j < this.model.length; j++) {
                if (this.model[j].code === this.form.value.code) {
                    this.model[j].name = this.form.value.name;
                    this.model[j].quantity = this.form.value.quantity;
                }
            }
            this.form.reset();
            this.form.controls.quantity.setValue(1);
        }
    }

    addSearchedPart(carPart: ClientCarParts_Dto) {
        this.form.controls.code.setValue(carPart.code);
        this.form.controls.name.setValue(carPart.name);
        this.form.controls.quantity.setValue(1);
    }
}
