import {isValidObject} from "../../shared/helpers/common.helpers";
import {ObjectValidators} from "../../shared/object.validators";

export class CreateCheckoutSession {
    public name: string;
    public description: string;
    public amount: number;
    public currency: string;
    public quantity: number;
    public unitSize: number;
    public packageId: string;
}

export class StripeConfigResponse {
    public publicKey: string;
    public defaultCurrency: string;

    constructor(data: any) {
        if (isValidObject(data)) {
            this.publicKey = data.publicKey;
            this.defaultCurrency = data.defaultCurrency;
        }
    }
}

export class AddPaymentMethod {
    public name: string;
    public stripeToken: string;

    constructor(data?: any) {
        if (data != null && data !== undefined) {
            this.name = data.name;
            this.stripeToken = data.stripeToken;
        }
    }
}

export class UpdatePaymentMethod {
    public stripeToken: string;
}

export class CreateCardSetupSession {
    public description: string;

    constructor(){}
}

export class CreateAnonymousCardSetupSession extends CreateCardSetupSession {
    public name: string = '';
    public email: string = '';
    public organizationName: string = '';

    public constructor(data?: any) {
        super();
        if (ObjectValidators.isValidObject(data)) {
            this.name = data.name;
            this.email = data.email;
            this.organizationName = data.organizationName;
        }
        this.description = 'default payment method';
    }
}
