import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { IdName_Dto } from 'src/app/core/app-dto/misc.dto';
import { CarPart_Dto } from 'src/app/core/app-dto/car-part.dto';

@Injectable()
export class CarPartSearchSandbox extends BaseSandbox {
    public getCarPartSearchList$ = this.appState$.pipe(select(store.getCarPartSearchList));

    public originalCarPartSearchList: Array<CarPart_Dto> = [];

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    clearSandboxState() {
        this.originalCarPartSearchList = [];
    }

    initialize(searchList: (data: Array<IdName_Dto>) => {}) {
        this.clearSandboxState();

        this.subscriptions.push(this.getCarPartSearchList$.subscribe((data: Array<CarPart_Dto>) => {
            if (ObjectValidators.isValidArray(data)) {
                this.originalCarPartSearchList = data;
                searchList(data.map(r => new IdName_Dto({
                    id: r.id,
                    name: `${r.name} - ${r.code} - ${r.supplier.name} - ${r.acquisitionPriceWithVat} ${r.acquisitionPriceCurrency}`
                })));
            }
        }));
    }
}
