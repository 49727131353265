import {Component, Input, OnInit} from '@angular/core';
import {Offer_Dto} from '../../../core/app-dto/offer.dto';
import {CarPart_Dto} from '../../../core/app-dto/car-part.dto';
import {isValidArrayAndHasElements, isValidObject} from '../../../core/shared/helpers/common.helpers';
import {CarServiceSaveCarPartComponent} from '../../../car-service/components/car-service-save-car-part/car-service-save-car-part.component';
import {BsModalService} from 'ngx-bootstrap';
import {UpdateOfferSaveServiceCarPartComponent} from '../update-offer-save-service-car-part/update-offer-save-service-car-part.component';
import {UpdateOfferDeleteServiceCarPartActionProxy} from '../../common/ap/update-offer-delete-service-car-part.action-proxy';
import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {BaseWebComponent} from '../../../core/shared/base/base.component';
import {ConfirmationDialogService} from '../../../core/core/dialog/confirmation-dialog.service';

@Component({
    selector: 'app-offer-details-list-service-car-parts',
    templateUrl: './offer-details-list-service-car-parts.component.html',
    styleUrls: ['./offer-details-list-service-car-parts.component.scss']
})
export class OfferDetailsListServiceCarPartsComponent extends BaseWebComponent implements OnInit {

    private deleteAP: UpdateOfferDeleteServiceCarPartActionProxy;

    @Input() offer: Offer_Dto;
    @Input() isReadOnlyValue: boolean = false;

    constructor(private modalService: BsModalService,
                private confirmationDialogService : ConfirmationDialogService,
                appState: Store<store.State>) {
        super(null, null);
        this.deleteAP = new UpdateOfferDeleteServiceCarPartActionProxy(this, appState);
    }

    ngOnInit() {
    }

    addNewCarPart() {
        const modalRef = this.modalService.show(UpdateOfferSaveServiceCarPartComponent, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-xl'
        });
        modalRef.content.dataInput = {
            offer: this.offer,
            carPart: null
        };
    }

    delete(data: CarPart_Dto) {
        this.confirmationDialogService.confirm(
            'Stergere piesa',
            'Urmeaza sa stergeti piesa din oferta. Sunteti sigur?',
            'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
            if (confirmed) {
                this.deleteAP.execute(this.offer.id, data);
            }
        });
    }

    updateCarPart(data: CarPart_Dto) {
        const modalRef = this.modalService.show(UpdateOfferSaveServiceCarPartComponent, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-xl'
        });
        modalRef.content.dataInput = {
            offer: this.offer,
            carPart: data
        };
    }

    getTotalValueWithVat() {
        if (isValidObject(this.offer)) {
            if (isValidArrayAndHasElements(this.offer.serviceCarParts)) {
                return this.offer.serviceCarParts.reduce((a, b) => a + b.sellingPriceWithVat * b.quantity, 0);
            }
        }
        return 0;
    }

    getCurrency() {
        if (isValidObject(this.offer)) {
            if (isValidArrayAndHasElements(this.offer.serviceCarParts)) {
                return this.offer.serviceCarParts[0].sellingPriceCurrency;
            }
        }
        return '';
    }
}
