import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import * as store from '../../core/app-store';
import {Observable} from 'rxjs';
import {Do_GenericErrorEvent, Do_GenericSuccessEvent, EventWithScreenLoading, SeamlessEvent} from '../../core/app-store/events/base.events';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {ObjectValidators} from '../../core/shared/object.validators';
import {
    AcceptOfferEvent,
    AddOfferServiceCarPartEvent, DeclineOfferEvent,
    DeleteOfferEvent,
    DeleteOfferServiceCarPartEvent, GenerateOffer,
    GetOfferEvent,
    GetOffersPagedResultEvent,
    GetUnauthOfferEvent,
    RegisterOfferEvent, SendOfferEvent,
    UpdateOfferCarDataEvent,
    UpdateOfferCarWorksEvent, UpdateOfferClientCarPartsEvent,
    UpdateOfferClientDataEvent,
    UpdateOfferDataEvent, UpdateOfferFinalizeEvent,
    UpdateOfferServiceCarPartEvent
} from '../../core/app-store/events/offer.events';
import {OfferApiService} from '../../core/api-integration/services/offer-api.service';
import {Offer_Dto} from '../../core/app-dto/offer.dto';
import {PagedResult} from '../../core/shared/datatable/datatable.helpers';

import {ConfirmationDialogService} from '../../core/core/dialog/confirmation-dialog.service';
import {ContinueDraftOfferEntryEvent} from "../../core/app-store/events/draft.events";
import {isValidString} from "../../core/shared/helpers/common.helpers";
import {AddOfferWizardComponent} from "../components/add-offer-wizard/add-offer-controller/add-offer-wizard.component";
import {BsModalService} from "ngx-bootstrap";

@Injectable()
export class OfferUIEventProcessor {

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private apiService: OfferApiService,
        private confirmationDialogService:ConfirmationDialogService,
        private modalService: BsModalService,
        private offerApiService: OfferApiService
    ) {
    }

    @Effect()
    getOffersPagedResult$: Observable<any> = this.actions$
        .pipe(
            ofType(GetOffersPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetOffersPagedResultEvent.identifier),
            map((action: GetOffersPagedResultEvent) => action),
            switchMap((action: GetOffersPagedResultEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_getFilteredOffersPaginated(action.parameters)
                        .subscribe((res: PagedResult<Offer_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<Offer_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    getCurrentOffer$: Observable<any> = this.actions$
        .pipe(
            ofType(GetOfferEvent.identifier, EventWithScreenLoading.identifier + GetOfferEvent.identifier),
            map((action: GetOfferEvent) => action),
            switchMap((action: GetOfferEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_getOffer(action.id)
                        .subscribe((res: Offer_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Offer_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
    @Effect()
    getUnauthCurrentOffer$: Observable<any> = this.actions$
        .pipe(
            ofType(GetUnauthOfferEvent.identifier, EventWithScreenLoading.identifier + GetUnauthOfferEvent.identifier),
            map((action: GetUnauthOfferEvent) => action),
            switchMap((action: GetUnauthOfferEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_getUnauthOffer(action.id, action.code)
                        .subscribe((res: Offer_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Offer_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    registerOffer$: Observable<any> = this.actions$
        .pipe(
            ofType(RegisterOfferEvent.identifier, EventWithScreenLoading.identifier + RegisterOfferEvent.identifier),
            map((action: RegisterOfferEvent) => action),
            switchMap((action: RegisterOfferEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_registerOffer(action.model)
                        .subscribe((res: Offer_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Offer_Dto>(res, action));
                                if(action.callback)
                                    action.callback(res);
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    deleteOffer$: Observable<any> = this.actions$
        .pipe(
            ofType(DeleteOfferEvent.identifier, EventWithScreenLoading.identifier + DeleteOfferEvent.identifier),
            map((action: DeleteOfferEvent) => action),
            switchMap((action: DeleteOfferEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_deleteOffer(action.id)
                        .subscribe((res: any) => {
                            observer.next(new Do_GenericSuccessEvent<string>(action.id, action));
                            if (ObjectValidators.isValidObject(action.deleteSuccessCallback())) {
                                action.deleteSuccessCallback();
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    addServiceCarPartToOffer: Observable<any> = this.actions$
        .pipe(
            ofType(AddOfferServiceCarPartEvent.identifier, EventWithScreenLoading.identifier + AddOfferServiceCarPartEvent.identifier),
            map((action: AddOfferServiceCarPartEvent) => action),
            switchMap((action: AddOfferServiceCarPartEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_updateOfferAddServiceCarPart(action.id, action.data)
                        .subscribe((res: Offer_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Offer_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    @Effect()
    updateServiceCarPartToOffer: Observable<any> = this.actions$
        .pipe(
            ofType(UpdateOfferServiceCarPartEvent.identifier, EventWithScreenLoading.identifier + UpdateOfferServiceCarPartEvent.identifier),
            map((action: UpdateOfferServiceCarPartEvent) => action),
            switchMap((action: UpdateOfferServiceCarPartEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_updateOfferUpdateServiceCarPart(action.id, action.data)
                        .subscribe((res: Offer_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Offer_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
    @Effect()
    deleteServiceCarPartToOffer: Observable<any> = this.actions$
        .pipe(
            ofType(DeleteOfferServiceCarPartEvent.identifier, EventWithScreenLoading.identifier + DeleteOfferServiceCarPartEvent.identifier),
            map((action: DeleteOfferServiceCarPartEvent) => action),
            switchMap((action: DeleteOfferServiceCarPartEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_updateOfferDeleteServiceCarPart(action.id, action.data)
                        .subscribe((res: Offer_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Offer_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
    @Effect()
    updateOfferClientData: Observable<any> = this.actions$
        .pipe(
            ofType(UpdateOfferClientDataEvent.identifier, EventWithScreenLoading.identifier + UpdateOfferClientDataEvent.identifier),
            map((action: UpdateOfferClientDataEvent) => action),
            switchMap((action: UpdateOfferClientDataEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_updateOfferClientData(action.data)
                        .subscribe((res: Offer_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Offer_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
    @Effect()
    updateOfferData: Observable<any> = this.actions$
        .pipe(
            ofType(UpdateOfferDataEvent.identifier, EventWithScreenLoading.identifier + UpdateOfferDataEvent.identifier),
            map((action: UpdateOfferDataEvent) => action),
            switchMap((action: UpdateOfferDataEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_updateOfferData(action.data)
                        .subscribe((res: Offer_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Offer_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
    @Effect()
    updateOfferCarData: Observable<any> = this.actions$
        .pipe(
            ofType(UpdateOfferCarDataEvent.identifier, EventWithScreenLoading.identifier + UpdateOfferCarDataEvent.identifier),
            map((action: UpdateOfferCarDataEvent) => action),
            switchMap((action: UpdateOfferCarDataEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_updateOfferCarData(action.data)
                        .subscribe((res: Offer_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Offer_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
    @Effect()
    updateOfferCarWorks: Observable<any> = this.actions$
        .pipe(
            ofType(UpdateOfferCarWorksEvent.identifier, EventWithScreenLoading.identifier + UpdateOfferCarWorksEvent.identifier),
            map((action: UpdateOfferCarWorksEvent) => action),
            switchMap((action: UpdateOfferCarWorksEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_updateOfferCarWorks(action.data)
                        .subscribe((res: Offer_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Offer_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
    @Effect()
    updateOfferFinalize: Observable<any> = this.actions$
        .pipe(
            ofType(UpdateOfferFinalizeEvent.identifier, EventWithScreenLoading.identifier + UpdateOfferFinalizeEvent.identifier),
            map((action: UpdateOfferFinalizeEvent) => action),
            switchMap((action: UpdateOfferFinalizeEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_finalizeOffer(action.data)
                        .subscribe((res: Offer_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Offer_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
    @Effect()
    updateOfferClientCarParts: Observable<any> = this.actions$
        .pipe(
            ofType(UpdateOfferClientCarPartsEvent.identifier, EventWithScreenLoading.identifier + UpdateOfferClientCarPartsEvent.identifier),
            map((action: UpdateOfferClientCarPartsEvent) => action),
            switchMap((action: UpdateOfferClientCarPartsEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_updateOfferClientCarParts(action.data)
                        .subscribe((res: Offer_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Offer_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
    @Effect()
    sendOffer: Observable<any> = this.actions$
        .pipe(
            ofType(SendOfferEvent.identifier, EventWithScreenLoading.identifier + SendOfferEvent.identifier),
            map((action: SendOfferEvent) => action),
            switchMap((action: SendOfferEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_sendOffer(action.id, action.data)
                        .subscribe((res: Offer_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<Offer_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
    @Effect()
    acceptOffer: Observable<any> = this.actions$
        .pipe(
            ofType(AcceptOfferEvent.identifier, EventWithScreenLoading.identifier + AcceptOfferEvent.identifier),
            map((action: AcceptOfferEvent) => action),
            switchMap((action: AcceptOfferEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_acceptOffer(action.id, action.code, action.data)
                        .subscribe((res: Offer_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                this.confirmationDialogService.info('Oferta acceptata', 'Va multumim pentru ca ati acceptat oferta noastra. Colegii nostri va vor contacta in scurt timp')
                                observer.next(new Do_GenericSuccessEvent<Offer_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
    @Effect()
    declinetOffer: Observable<any> = this.actions$
        .pipe(
            ofType(DeclineOfferEvent.identifier, EventWithScreenLoading.identifier + DeclineOfferEvent.identifier),
            map((action: DeclineOfferEvent) => action),
            switchMap((action: DeclineOfferEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_declineOffer(action.id, action.code, action.data)
                        .subscribe((res: Offer_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                this.confirmationDialogService.info('Oferta refuzata', 'Va multumim pentru timpul acordat. Daca va razganditi puteti accepta oricand oferta folosind acelasi link.')
                                observer.next(new Do_GenericSuccessEvent<Offer_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            }));

    @Effect()
    doContinueDraftOffer$: Observable<any> = this.actions$
        .pipe(
            ofType(ContinueDraftOfferEntryEvent.identifier, SeamlessEvent.identifier + ContinueDraftOfferEntryEvent.identifier),
            map((action: ContinueDraftOfferEntryEvent) => action),
            switchMap((action: ContinueDraftOfferEntryEvent) => {
                    return new Observable((observer) => {
                        const step = isValidString(action.model.currentPage) ? parseInt(action.model.currentPage) : 1;
                        const initialState = { model: new Offer_Dto(JSON.parse(action.model.data)), initialStep: step };
                        const modalRef = this.modalService.show(AddOfferWizardComponent, { initialState: initialState, backdrop: 'static', keyboard: false, class: 'modal-xl' });
                        observer.complete();
                    });
                }
            )
        );

    @Effect()
    generateOffer$: Observable<any> = this.actions$
        .pipe(
            ofType(GenerateOffer.identifier, EventWithScreenLoading.identifier + GenerateOffer.identifier),
            map((action: GenerateOffer) => action),
            mergeMap((action: GenerateOffer) => {
                    return new Observable((observer) => {
                        this.offerApiService.u_generateOffer(action.offerEntry)
                            .subscribe((res: any) => {
                                if (ObjectValidators.isValidObject(res)) {
                                    observer.next(new Do_GenericSuccessEvent<any>(res, action));
                                    if (ObjectValidators.isValidString(res.url)) {
                                        window.open(res.url, '_self');
                                    }
                                }
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );
}
