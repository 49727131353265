import {BaseFormDI} from 'src/app/core/shared/base/base.di';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CarServiceEntryData_Dto} from 'src/app/core/app-dto/car-service.dto';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {StaticDataSelectorInput} from "../../../core/shared/components/static-data-selector/static-data-selector.component";
import {isValidNumber, isValidObject, isValidString} from "../../../core/shared/helpers/common.helpers";

export class CarServiceDataFormDI extends BaseFormDI<CarServiceEntryData_Dto> {

  public paymentInAdvanceCurrenciesDataInput: StaticDataSelectorInput;
  public estimatedCostCurrenciesDataInput: StaticDataSelectorInput;
  public estimatedDeadlineTypesInput: StaticDataSelectorInput;
  public shouldUseEstimatedDeadlineTypeSelection: boolean = false;


  constructor(data: CarServiceEntryData_Dto = new CarServiceEntryData_Dto(null)) {
    super(new CarServiceEntryData_Dto(data));

    if (!ObjectValidators.isValidObject(this.model.fuel))
      this.model.fuel = 50;

    this.initForm();
    this.paymentInAdvanceCurrenciesDataInput = {
      form: this.form,
      type: 'currencies',
      formControlName: 'paymentInAdvanceCurrency',
      isRequired: true,
      label: 'Moneda',
      isReadOnly:false
    };
    this.estimatedCostCurrenciesDataInput = {
      form: this.form,
      type: 'currencies',
      formControlName: 'priceEstimateCurrency',
      isRequired: true,
      label: 'Moneda',
      isReadOnly:false
    };
    this.estimatedDeadlineTypesInput = {
      form: this.form,
      type: 'estimatedDeadlineTypes',
      formControlName: 'estimatedDeadlineType',
      isRequired: true,
      label: null,
      isReadOnly:false
    };
    if (isValidString(this.model.deadline) == false || isValidObject(this.model.estimatedDeadlineType)) {
      this.shouldUseEstimatedDeadlineTypeSelection = true;
      this.form.addControl("deadlineNumber", new FormControl(this.model.deadlineNumber, [Validators.required]));
      this.form.addControl("priceEstimateNumber", new FormControl(this.model.priceEstimateNumber, [Validators.required]));
    } else {
      this.form.addControl("deadline", new FormControl(this.model.deadline, [Validators.required]));
      this.form.addControl("priceEstimate", new FormControl(this.model.priceEstimate, [Validators.required]));
    }

  }

  getModel(): CarServiceEntryData_Dto {
    this.model.actualNoOfKm = this.form.value.actualNoOfKm;
    this.model.clientRequests = this.form.value.clientRequests;
    this.model.fuel = this.form.value.fuel;
    this.model.serviceRecomandations = this.form.value.serviceRecomandations;
    if (this.shouldUseEstimatedDeadlineTypeSelection == false)
      this.model.deadline = this.form.value.deadline;
    else {
      this.model.deadlineNumber = this.form.value.deadlineNumber;
      this.model.estimatedDeadlineType = this.form.value.estimatedDeadlineType != null ? this.form.value.estimatedDeadlineType : null;
      if (isValidNumber(this.model.deadlineNumber))
        this.model.deadline = this.model.deadlineNumber + ' ' + (this.model.estimatedDeadlineType != null ? this.model.estimatedDeadlineType.name : '');
    }
    if (this.shouldUseEstimatedDeadlineTypeSelection == false)
      this.model.priceEstimate = this.form.value.priceEstimate;
    else {
      this.model.priceEstimateNumber = this.form.value.priceEstimateNumber;
      this.model.priceEstimateCurrency = this.form.value.priceEstimateCurrency != null ? this.form.value.priceEstimateCurrency.name : null;
      if (isValidNumber(this.model.priceEstimateNumber))
        this.model.priceEstimate = this.model.priceEstimateNumber + ' ' + this.model.priceEstimateCurrency;
    }
    this.model.paymentInAdvance = this.form.value.paymentInAdvance | 0;
    this.model.paymentInAdvanceCurrency = this.form.value.paymentInAdvanceCurrency != null ? this.form.value.paymentInAdvanceCurrency.name : null;

    return this.model;
  }

  initForm() {
    this.form = new FormGroup({
      actualNoOfKm: new FormControl(this.model.actualNoOfKm, Validators.compose([Validators.required])),
      fuel: new FormControl(this.model.fuel, Validators.compose([Validators.required])),
      clientRequests: new FormControl(this.model.clientRequests, Validators.compose([])),
      serviceRecomandations: new FormControl(this.model.serviceRecomandations, Validators.compose([])),
      paymentInAdvance: new FormControl(this.model.paymentInAdvance, Validators.compose([Validators.min(0)]))
    });
  }
}
