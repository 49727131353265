import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { MarkSupplyAsNotPaidEvent } from '../../../core/app-store/events/supply.events';

export class MarkInvoiceAsNotPaidActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(supplyId: string): any {
        this.appState.dispatch(new MarkSupplyAsNotPaidEvent(supplyId));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

