import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {CalendarDateFormatter, CalendarView} from 'angular-calendar';
import {BsModalService} from 'ngx-bootstrap';
import {CalendarEvent_Dto, CalendarEventCardPosition, CalendarEventType} from 'src/app/core/app-dto/calendar-event.dto';
import {DateTimeService} from 'src/app/core/core/services/date-time.service';
import {BaseWebComponent} from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import {ActionsSubject, Store} from '@ngrx/store';
import {CalendarServicePlatformsSandbox} from './calendar-service-platforms.sandbox';
import {
  Do_GenericSuccessEvent,
  EventWithStopingScreenLoader,
  ResetSection
} from 'src/app/core/app-store/events/base.events';
import * as moment from 'moment';
import {Moment} from 'moment';
import {ConfirmationDialogService} from 'src/app/core/core/dialog/confirmation-dialog.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {DeviceDetectorService} from 'ngx-device-detector';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {CustomDateFormatter} from './calendar-custom-date-formatter.provider';
import {DayWorkingHourProfile, HoursRange, Organization} from '../../../core/app-dto/organization.dto';
import {isValidArrayAndHasElements, isValidObject} from '../../../core/shared/helpers/common.helpers';
import {NotifierService} from 'angular-notifier';
import {GetCalendarEventsActionProxy} from '../calendar/get-calendar-events.action-proxy';
import {max, range, toLower} from 'lodash';
import {CalendarNavigation, CalendarViewType} from '../calendar-nagivation/calendar-navigation.component';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {AddEditCalendarEventWizardComponent} from '../add-edit-calendar-event-wizard/add-edit-calendar-event-wizard.component';
import {GetServicePlatformsActionProxy} from './get-service-platforms.action-proxy';
import {ServicePlatform_Dto} from '../../../core/app-dto/service-platform.dto';
import {CarAppointment_Dto} from '../../../core/app-dto/car-appointment.dto';
import {AddEditCalendarEventComponent} from '../add-edit-calendar-event/add-edit-calendar-event.component';
import {Router} from "@angular/router";
import {AddCarInServiceEvent} from "../../../core/app-store/events/car-service.events";
import {
  DeleteCarAppointmentEvent,
  RegisterCarAppointmentEvent,
  UpdateCarAppointmentEvent
} from '../../../core/app-store/events/calendar-events';

@Component({
  selector: 'app-calendar-service-platforms',
  templateUrl: './calendar-service-platforms.component.html',
  styleUrls: ['./calendar-service-platforms.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class CalendarServicePlatformsComponent extends BaseWebComponent implements OnInit, AfterViewInit {
  public getCalendarEventsAP: GetCalendarEventsActionProxy;
  public getServicePlatformsAP: GetServicePlatformsActionProxy;
  // public deleteCalendarEventAP: DeleteCalendarEventActionProxy;
  // public confirmCalendarEventAP: ConfirmCalendarEventActionProxy;
  // public addImportAP: AddImportTaskActionProxy;

  public minStartHour = 6;
  public minStartMinute = 0;
  public maxEndHour = 20;
  public maxEndMinute = 0;
  public dayColumnMinWidth = 260;
  public weekColumnMinWidth = 205;
  public columnWidth = 260;
  public calendarHeaderPadding = 60;
  public calendarLineHeight = 56;
  public defaultServicePlatformCalendarColumns = 0;

  locale: string = 'ro';

  view: CalendarView = CalendarView.Day;
  CalendarView = CalendarView;
  viewDate: Moment = moment();
  public calendarNavigation: CalendarNavigation = {
    currentDate: moment(),
    view: CalendarView.Day,
    viewType: CalendarViewType.Horizontal
  };
  private calendarNavigationOldState: CalendarNavigation;

  refresh: Subject<any> = new Subject();
  events: Array<CalendarEvent_Dto> = [];
  closedHoursIntervals: Array<CalendarEventCardPosition> = [];

  activeDayIsOpen: boolean = true;

  public startDate: Date = null;
  public endDate: Date = null;
  public eventType: string = null;
  public searchValue: string = null;

  public isMobile: boolean = false;
  public isTablet: boolean = false;
  public isDesktopDevice: boolean = true;

  modelChanged: Subject<string> = new Subject<string>();

  public currentOrganization: Organization = null;
  public columns: any = [];
  private firstColumnPadding = 60;
  public hours: any = [];
  public currentTime: string = '';
  private eventsChangedSubscription: Subscription

  @ViewChild('servicePlatformCalendar', {static: false}) servicePlatformCalendar: ElementRef;
  @ViewChild('customCalendarHeader', {static: false}) customCalendarHeader: ElementRef;
  @ViewChild('currentTimeContainer', {static: false}) currentTimeContainer: ElementRef;
  @ViewChild('calendarContainer', {static: false}) calendarContainer: ElementRef;

  constructor(public appState: Store<store.State>,
              public sandbox: CalendarServicePlatformsSandbox,
              private modalService: BsModalService,
              public dateTimeService: DateTimeService,
              public confirmationDialogService: ConfirmationDialogService,
              private spinner: NgxSpinnerService,
              private deviceService: DeviceDetectorService,
              private notifierService: NotifierService,
              private router: Router,
              private actionsSubj: ActionsSubject) {
    super(sandbox, ResetSection.Calendar);
    this.getCalendarEventsAP = new GetCalendarEventsActionProxy(this, sandbox.appState, this.spinner);
    this.getServicePlatformsAP = new GetServicePlatformsActionProxy(this, sandbox.appState);
    // this.deleteCalendarEventAP = new DeleteCalendarEventActionProxy(this, sandbox.appState, this.spinner);
    // this.confirmCalendarEventAP = new ConfirmCalendarEventActionProxy(this, sandbox.appState, this.spinner);
    // this.addImportAP = new AddImportTaskActionProxy(this, sandbox.appState);

    actionsSubj.subscribe(data => {
      if (data.type === Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + RegisterCarAppointmentEvent.identifier ||
        data.type === Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + DeleteCarAppointmentEvent.identifier ||
        data.type === Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + UpdateCarAppointmentEvent.identifier) {
        this.getCalendarEventsAP.execute(this.startDate, this.endDate, this.eventType, this.searchValue);
      }
    });

    this.modelChanged.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(searchQuery => {
        this.searchValue = toLower(searchQuery);
        this.setEventsPosition();
      });

    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();

    this.view = CalendarView.Day;

    this.eventsChangedSubscription = actionsSubj.subscribe(data => {
      if (data.type == Do_GenericSuccessEvent.identifier + EventWithStopingScreenLoader.identifier + AddCarInServiceEvent.identifier) {
        this.getCalendarEventsAP.execute(this.startDate, this.endDate, this.eventType, this.searchValue);
      }
    });
  }

  ngOnDestroy() {
    if(isValidObject(this.eventsChangedSubscription)){
      this.eventsChangedSubscription.unsubscribe();
    }
    this.destroy();
  }

  ngAfterViewInit() {
    this.dataLoaded = false;
    setTimeout(() => {
      this.setHeaderWidth();
    });
  }

  ngOnInit() {
    this.columns = [];
    this.getServicePlatformsAP.execute();
    this.updateViewDate(this.calendarNavigation);
    this.getCalendarEventsAP.execute(this.startDate, this.endDate, this.eventType, this.searchValue);
    this.initialize((data: Array<CarAppointment_Dto>, currentOrganization: Organization, servicePlatforms: Array<ServicePlatform_Dto>) => {
      if (servicePlatforms.length > this.defaultServicePlatformCalendarColumns && this.columns.length == this.defaultServicePlatformCalendarColumns) {
        setTimeout(() => {
          this.updateViewDate(this.calendarNavigation);
        });
      }
      this.currentOrganization = currentOrganization;
      if (isValidObject(this.currentOrganization.workingHoursSettings) &&
        isValidArrayAndHasElements(this.currentOrganization.workingHoursSettings.workingHours)) {
        let newMinHour = null;
        let newMinMinute = null;
        let newMaxHour = null;
        let newMaxMinute = null;
        this.closedHoursIntervals = [];
        // TODO get min/max hour only for current day
        this.currentOrganization.workingHoursSettings.workingHours.forEach((item) => {
          if (isValidArrayAndHasElements(item.hoursRange)) {
            item.hoursRange.forEach((range) => {
                if (newMinHour == null) {
                  newMinHour = range.fromHours;
                  newMinMinute = range.fromMinutes;
                }
                else if (newMinHour > range.fromHours) {
                  newMinHour = range.fromHours;
                  newMinMinute = range.fromMinutes;
                }
                else {
                  if (newMinHour == range.fromHours && newMinMinute > range.fromMinutes)
                    newMinMinute = range.fromMinutes;
                }

                if (newMaxHour == null) {
                  newMaxHour = range.toHours;
                  newMaxMinute = range.toMinutes;
                }
                else if (newMaxHour < range.toHours) {
                  newMaxHour = range.toHours;
                  newMaxMinute = range.toMinutes;
                } else {
                  if (newMaxHour == range.toHours && newMaxMinute < range.toMinutes)
                    newMaxMinute = range.toMinutes;
                }
              }
            );
          }
        });
        if (newMinHour != null) this.minStartHour = newMinHour;
        if (newMinMinute != null) this.minStartMinute = newMinMinute;
        if (newMaxHour != null) this.maxEndHour = newMaxHour;
        if (newMaxMinute != null) this.maxEndMinute = newMaxMinute;

        this.currentOrganization.workingHoursSettings.workingHours.forEach((item) => {
          if (this.calendarNavigation.view === CalendarView.Day) {
            let currentDayIndex = moment({...this.calendarNavigation.currentDate}).day();
            if(currentDayIndex ==0) currentDayIndex = 6;
            else currentDayIndex -=1;
            if (currentDayIndex === item.key) {
              this.extractClosedInterval(item);
            }
          } else if (this.calendarNavigation.view === CalendarView.Week) {
            this.extractClosedInterval(item);
          }
        });
      }

      this.hours = range(this.minStartHour, this.maxEndHour).map(hour => {
        return moment({hour}).format('HH:mm');
      });

      this.setEventsPosition();
    });
  }

  getDisabledIntervalPosition(hoursRange: HoursRange, itemKey) {
    const start = moment().set('hour', hoursRange.fromHours).set('minute', hoursRange.fromMinutes);
    const end = moment().set('hour', hoursRange.toHours).set('minute', hoursRange.toMinutes);
    const topDiff = start.diff(moment().set('hour', this.minStartHour).set('minute', this.minStartMinute));
    const top = topDiff / 1000 / 3600 * this.calendarLineHeight;
    const heightDiff = end.diff(start);
    const height = heightDiff / 1000 / 3600 * this.calendarLineHeight;

    return {
      top,
      left: this.calendarNavigation.view === CalendarView.Day ? 0 : this.columnWidth * itemKey,
      width: this.calendarNavigation.view === CalendarView.Day ? this.columnWidth * this.columns.length : this.columnWidth,
      height
    };
  }

  extractClosedInterval(item: DayWorkingHourProfile): void {
    if (item.isOpened === false) {
      this.closedHoursIntervals.push(new CalendarEventCardPosition(this.getDisabledIntervalPosition({
        fromHours: this.minStartHour,
        fromMinutes: this.minStartMinute,
        toHours: this.maxEndHour,
        toMinutes: this.maxEndMinute
      }, item.key)));
    } else {
      if (this.minStartHour !== item.hoursRange[0].fromHours || this.minStartMinute !== item.hoursRange[0].fromMinutes) {
        this.closedHoursIntervals.push(new CalendarEventCardPosition(this.getDisabledIntervalPosition({
          fromHours: this.minStartHour,
          fromMinutes: this.minStartMinute,
          toHours: item.hoursRange[0].fromHours,
          toMinutes: item.hoursRange[0].fromMinutes,
        }, item.key)));
      }
      if (this.maxEndHour !== item.hoursRange[item.hoursRange.length - 1].toHours ||
        this.maxEndMinute !== item.hoursRange[item.hoursRange.length - 1].toMinutes) {
        this.closedHoursIntervals.push(new CalendarEventCardPosition(this.getDisabledIntervalPosition({
          fromHours: item.hoursRange[item.hoursRange.length - 1].toHours,
          fromMinutes: item.hoursRange[item.hoursRange.length - 1].toMinutes,
          toHours: this.maxEndHour,
          toMinutes: this.maxEndMinute
        }, item.key)));
      }
      if (item.hoursRange.length > 1) {
        for (let i = 1; i <= item.hoursRange.length - 1; i++) {
          this.closedHoursIntervals.push(new CalendarEventCardPosition(this.getDisabledIntervalPosition({
            fromHours: item.hoursRange[i - 1].toHours,
            fromMinutes: item.hoursRange[i - 1].toMinutes,
            toHours: item.hoursRange[i].fromHours,
            toMinutes: item.hoursRange[i].fromMinutes,
          }, item.key)));
        }
      }
    }
  }

  search(data) {
    if (ObjectValidators.isValidString(data)) {
      this.modelChanged.next(data);
    }
    if (data === '') {
      this.modelChanged.next(null);
    }
  }

  setHeaderWidth(): void {
    if (this.columns.length == 0) return;
    this.dataLoaded = false;
    const containerWidth = this.servicePlatformCalendar.nativeElement.offsetWidth;
    this.columnWidth = this.calendarNavigation.view === CalendarView.Day ?
      max([(containerWidth - this.firstColumnPadding) / this.columns.length, this.dayColumnMinWidth])
      : max([(containerWidth - this.firstColumnPadding) / this.columns.length, this.weekColumnMinWidth]);

    this.customCalendarHeader.nativeElement.style.width = `${this.columnWidth * this.columns.length + this.calendarHeaderPadding}px`;
    this.calendarContainer.nativeElement.style.width = `${this.columnWidth * this.columns.length + this.calendarHeaderPadding}px`;

    for (let i = 0; i < this.customCalendarHeader.nativeElement.children.length; i++) {
      this.customCalendarHeader.nativeElement.children[i].style.width = `${this.columnWidth}px`;
    }

    this.dataLoaded = true;
  }

  getEventsFromEventBasedOnWorkingHours(event: CalendarEvent_Dto): Array<CalendarEvent_Dto> {
    const daysDiff = moment(event.endDate).diff(moment(event.startDate), 'days');
    if (daysDiff == 0) return [event];
    let result = new Array<CalendarEvent_Dto>();

    if (this.currentOrganization.workingHoursSettings == null) return [event];
    if (this.currentOrganization.workingHoursSettings.workingHours == null) return [event];

    for (let i = 0; i <= daysDiff; i++) {

      const currentDayStartTime = moment(event.startDate).add(i, "days");

      const dayIndexForWorkingHours = currentDayStartTime.day() == 0 ? 6 : currentDayStartTime.day() - 1;
      let workingHoursProfile = this.currentOrganization.workingHoursSettings.workingHours.find(x => x.key == dayIndexForWorkingHours);
      let minStartHour, minStartMinute, maxEndHour, maxEndMinute;
      if (workingHoursProfile != null && isValidArrayAndHasElements(workingHoursProfile.hoursRange)) {
        workingHoursProfile.hoursRange.forEach((range) => {
          let item = Object.assign({}, event);
          let currentDayStartTimeAsDate = currentDayStartTime.toDate();
          let rangeStartDate = new Date(currentDayStartTimeAsDate.getFullYear(), currentDayStartTimeAsDate.getMonth(), currentDayStartTimeAsDate.getDate(), range.fromHours, range.fromMinutes, 0, 0);
          let rangeEndDate = new Date(currentDayStartTimeAsDate.getFullYear(), currentDayStartTimeAsDate.getMonth(), currentDayStartTimeAsDate.getDate(), range.toHours, range.toMinutes, 0, 0);
          if(rangeStartDate > event.endDate) return;

          if (i != 0) {
              item.startDate = rangeStartDate;
          }
          if (i != daysDiff) {
            item.endDate = rangeEndDate;
          } else {
            item.endDate = rangeEndDate < event.endDate ? rangeEndDate : event.endDate;
          }
          result.push(item);
        });

      }
    }
    return result;
  }

  getProcessedEventsForMultiDays(events: Array<CalendarEvent_Dto>): Array<CalendarEvent_Dto> {
    if (!isValidArrayAndHasElements(events)) return [];
    if (this.currentOrganization.workingHoursSettings == null) return events;
    let result = new Array<CalendarEvent_Dto>();

    events.forEach((event) => {
      let eventsGenerated = this.getEventsFromEventBasedOnWorkingHours(event);
      result.push(...eventsGenerated);
    });
    return result;
  }

  setEventsPosition() {
    if (this.columns.length == 0) return;
    this.events = [];
    let filteredEvents = this.sandbox.currentItems.filter(event => {
      if (event.servicePlatform == null) return false;
      if (!this.searchValue) return true;
      return Object.values(event).some(item => item != null && item != undefined ? toLower(item.toString()).includes(this.searchValue) : false);
    });
    filteredEvents = this.getProcessedEventsForMultiDays(filteredEvents);
    if (this.calendarNavigation.view === CalendarView.Day) {
      filteredEvents = filteredEvents.filter(f => f.startDate.getFullYear() == this.calendarNavigation.currentDate.year() &&
        f.startDate.getDay() == this.calendarNavigation.currentDate.day() &&
        f.startDate.getMonth() == this.calendarNavigation.currentDate.month());
    }
    filteredEvents.sort((a, b) => {
      return (b.endDate.getTime() - b.startDate.getTime()) - (a.endDate.getTime() - a.startDate.getTime());
    }).forEach((event: CalendarEvent_Dto) => {
      let columnIndex = this.columns.findIndex(column => this.getPredicateForView(column, event));
      const currentDayStartTime = this.calendarNavigation.view === CalendarView.Day ?
        moment(this.calendarNavigation.currentDate).set('hour', this.minStartHour).set('minute', this.minStartMinute)
        : moment(event.startDate).set('hour', this.minStartHour).set('minute', this.minStartMinute);
      const currentDayEndTime = this.calendarNavigation.view === CalendarView.Day ?
        moment(this.calendarNavigation.currentDate).set('hour', this.maxEndHour).set('minute', this.maxEndMinute)
        : moment(event.endDate).set('hour', this.maxEndHour).set('minute', this.maxEndMinute);
      if (columnIndex === -1) columnIndex = 0;
      const topDiff = event.startDate < currentDayStartTime.toDate() ? 0 : moment(event.startDate).diff(currentDayStartTime);
      const top = topDiff / 1000 / 3600 * this.calendarLineHeight;

      // let adjustedStartDate = currentDayStartTime > moment(event.startDate) ? currentDayStartTime : moment(event.startDate);
      // let eventDurationAsHours = moment.duration(moment(event.endDate).diff(moment(event.startDate))).asHours();
      // let getEventDurationInHoursForCurrentDa


      const endDateHeightDiff = currentDayEndTime < moment(event.endDate) ? currentDayEndTime : moment(event.endDate);
      const startDateHeightDiff = currentDayStartTime > moment(event.startDate) ? currentDayStartTime : moment(event.startDate);

      const heightDiff = moment(endDateHeightDiff).diff(moment(startDateHeightDiff));
      const height = heightDiff / 1000 / 3600 * this.calendarLineHeight;

      // if (!event.cardPosition || !event.cardPosition.overlapping) {
      const findOverlappingEvents = filteredEvents.filter(ev => {
        let isSameServicePlatform = false;
        if (!event.servicePlatform && !ev.servicePlatform) {
          isSameServicePlatform = true;
        }
        if (event.servicePlatform && ev.servicePlatform) {
          isSameServicePlatform = event.servicePlatform.id === ev.servicePlatform.id;
        }

        return isSameServicePlatform && (!(moment(event.endDate).unix() <= moment(ev.startDate).unix()) &&
          !(moment(ev.endDate).unix() <= moment(event.startDate).unix()));
      });

      if (!event.cardPosition) {
        event.cardPosition = {
          overlapping: findOverlappingEvents.length > 0 ? findOverlappingEvents.length : 1
        } as CalendarEventCardPosition;
      } else {
        event.cardPosition.overlapping = findOverlappingEvents.length > 0 ? findOverlappingEvents.length : 1
      }

      findOverlappingEvents.map((ev, index) => {
        if (!ev.cardPosition) {
          ev.cardPosition = {
            // overlapping: findOverlappingEvents.length,
            order: index
          } as CalendarEventCardPosition;
        } else {
          // ev.cardPosition.overlapping = findOverlappingEvents.length;
          ev.cardPosition.order = index;
        }

        return ev;
      });
      // }

      event.cardPosition.top = top < 0 ? 0 : top;
      event.cardPosition.left = this.columnWidth * columnIndex;
      event.cardPosition.width = this.columnWidth;
      event.cardPosition.height = height;
      event.cardPosition.order = event.cardPosition.order ? event.cardPosition.order : 0;

      this.events.push(event);
    });
  }

  getPredicateForView(column, e): boolean {
    if (this.calendarNavigation.view === CalendarView.Week) {
      return column.id === (moment(e.startDate).weekday() - 1);
    } else if (this.calendarNavigation.view === CalendarView.Day) {
      if (!e.servicePlatform) return false;

      return column.id === e.servicePlatform.id;
    }

    return false;
  }

  updateViewDate($event: any) {
    this.calendarNavigation = $event;
    switch (this.calendarNavigation.view) {
      case CalendarView.Day: {
        this.startDate = moment(this.calendarNavigation.currentDate).startOf('day').toDate();
        this.endDate = moment(this.calendarNavigation.currentDate).endOf('day').toDate();
        break;
      }
      case CalendarView.Week: {
        this.startDate = moment(this.calendarNavigation.currentDate).startOf('week').toDate();
        this.endDate = moment(this.calendarNavigation.currentDate).endOf('week').toDate();
        break;
      }
      case CalendarView.Month: {
        this.startDate = moment(this.calendarNavigation.currentDate).startOf('month').toDate();
        this.endDate = moment(this.calendarNavigation.currentDate).endOf('month').toDate();
        break;
      }
    }

    switch (this.calendarNavigation.view) {
      case CalendarView.Week:

        if (this.columns.length === 0 || !this.calendarNavigationOldState || this.calendarNavigationOldState.view !== this.calendarNavigation.view
          || (this.calendarNavigation.currentDate < moment(this.calendarNavigation.currentDate).startOf('week') ||
            this.calendarNavigation.currentDate > moment(this.calendarNavigation.currentDate).endOf('week'))) {
          this.columns = this.currentOrganization.workingHoursSettings.workingHours.map(day => {
            return {
              id: day.key,
              title: moment({...this.calendarNavigation.currentDate}).day(day.key + 1).locale('ro').format('dddd'),
              subtitle: moment({...this.calendarNavigation.currentDate}).locale('ro').startOf('week').add(day.key, 'days').format('DD MMMM YYYY')
            };
          });

          setTimeout(() => {
            this.setHeaderWidth();
          });
        }
        break;
      case CalendarView.Day:
        if (this.columns.length === this.defaultServicePlatformCalendarColumns || !this.calendarNavigationOldState ||
          this.calendarNavigationOldState.view !== this.calendarNavigation.view) {
          this.columns = this.sandbox.servicePlatforms;
          setTimeout(() => {
            this.setHeaderWidth();
          });
        }
        break;
    }

    this.calendarNavigationOldState = {...this.calendarNavigation};
    this.getCalendarEventsAP.execute(this.startDate, this.endDate, this.eventType, this.searchValue);
  }

  addCalendarEvent(date: Date = new Date()) {
    const event = new CarAppointment_Dto(null);
    event.startDate = date;
    event.endDate = date;
    const initialState = {
      event,
      title: 'Adauga programare'
    };
    const modalRef = this.modalService.show(AddEditCalendarEventWizardComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });

    modalRef.content.onClose.subscribe((dataHasChanged: boolean) => {
      if (dataHasChanged) {
        this.getCalendarEventsAP.execute(this.startDate, this.endDate, this.eventType, this.searchValue);
      }
    });
  }

  openEventSummary(event: CalendarEvent_Dto) {
    if (event.type == CalendarEventType.CustomAppointment) {
      let initialState = {
        event: event,
        title: 'Editare eveniment'
      }

      const modalRef = this.modalService.show(AddEditCalendarEventComponent, {
        initialState,
        backdrop: 'static',
        keyboard: false,
        class: 'modal-xl'
      });
    } else if (event.type == CalendarEventType.Appointment) {
      const initialState = {
        event,
        title: 'Modifica programare'
      };
      const modalRef = this.modalService.show(AddEditCalendarEventWizardComponent, {
        initialState,
        backdrop: 'static',
        keyboard: false,
        class: 'modal-xl'
      });

      modalRef.content.onClose.subscribe((dataHasChanged: boolean) => {
        if (dataHasChanged) {
          this.getCalendarEventsAP.execute(this.startDate, this.endDate, this.eventType, this.searchValue);
        }
      });
    }

    else {
      this.router.navigate(['/car-service/details/', event.parentRelatedEntityId]);
    }
  }
}
