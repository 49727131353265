import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseFormComponent, BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { range, reverse } from 'lodash';
import { RegisterDepartmentActionProxy } from './register-department.action-proxy';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { EditDepartmentActionProxy } from './edit-department.action-proxy';
import { GetDepartmentActionProxy } from '../department-details/get-department.action-proxy';
import { ActivatedRoute, Params } from '@angular/router';
import { SaveDepartmentSandbox } from './save-department.sandbox';
import { Department_Dto } from '../../../core/app-dto/department.dto';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { DepartmentFormComponent } from '../department-form/department-form.component';


@Component({
  selector: 'app-save-department',
  templateUrl: './save-department.component.html',
  styleUrls: ['./save-department.component.scss']
})
export class SaveDepartmentComponent extends BaseFormModelComponent<Department_Dto> implements OnInit, OnDestroy {
  public registerDepartmentAP: RegisterDepartmentActionProxy;
  public editDepartmentAP: EditDepartmentActionProxy;
  public getDepartmentAP: GetDepartmentActionProxy;
  public title: string;
  public onClose: Subject<boolean>;

  public data: Department_Dto = null;

  @ViewChild(DepartmentFormComponent, { static: false })
  private departmentFormComponent: DepartmentFormComponent;


  constructor(public appState: Store<store.State>,
              public sandbox: SaveDepartmentSandbox,
              private activeModal: BsModalRef,
              public activatedRoute: ActivatedRoute) {
    super(sandbox, null);
    this.registerDepartmentAP = new RegisterDepartmentActionProxy(this, sandbox.appState);
    this.editDepartmentAP = new EditDepartmentActionProxy(this, sandbox.appState);
    this.getDepartmentAP = new GetDepartmentActionProxy(this, sandbox.appState);
  }

  public diInitialize(data: Department_Dto) {
    this.data = data;
    this.title = ObjectValidators.isValidObject(data) ? 'Editare departament' : 'Adauga departament';
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.departmentFormComponent.accept();
  }

  public formSaved(model: Department_Dto) {
    if (model.id) {
      this.editDepartmentAP.execute(model);
    } else {
      this.registerDepartmentAP.execute(model);
    }
    this.onClose.next(true);
    this.activeModal.hide();
  }
}
