import {ObjectValidators} from '../shared/object.validators';
import {Base_Dto, BaseCRUDRules, BusinessRule, IdName_Dto, IdNameNumber_Dto} from './misc.dto';
import * as moment from 'moment';
import {isValidObject} from '../shared/helpers/common.helpers';
import {Supplier_Dto} from './supplier.dto';
import {InvoiceStatus} from './app.enums';

export class SupplyRules extends BaseCRUDRules {
    public canActivate: BusinessRule;
    public canDeactivate: BusinessRule;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.canActivate = new BusinessRule(data.canActivate);
            this.canDeactivate = new BusinessRule(data.canDeactivate);

        } else {
            this.canActivate = new BusinessRule();
            this.canDeactivate = new BusinessRule();
        }
    }
}

export class SupplyItemRules extends BaseCRUDRules {
    public canActivate: BusinessRule;
    public canDeactivate: BusinessRule;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.canActivate = new BusinessRule(data.canActivate);
            this.canDeactivate = new BusinessRule(data.canDeactivate);

        } else {
            this.canActivate = new BusinessRule();
            this.canDeactivate = new BusinessRule();
        }
    }
}

export class Supply_Dto extends Base_Dto {
    public number: string;
    public supplier: Supplier_Dto;
    public invoiceNumber: string;
    public supplyDate: Date;
    public currency: IdName_Dto;
    public receptionSeries: string;
    public invoiceDate: Date;
    public deadline: Date;
    public invoiceStatus: InvoiceStatus;
    public totalValueWithVat: number;
    public totalVatValue: number;
    public totalWithVat: number;
    public totalWithoutVat: number;
    public totalVat: number;
    public observations: string;
    public receptionCommittee: string;
    public delegate: string;
    public auto: string;
    public internalObservations: string;
    public supplyItems: SupplyItem_Dto[];
    public isStorno: boolean;
    public rules: SupplyRules;
    public createdOn: Date;
    public updatedOn: Date;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.number = data.number;
            this.supplier = data.supplier;
            this.invoiceNumber = data.invoiceNumber;
            if (ObjectValidators.isValidObject(data.supplyDate))
                this.supplyDate = moment.utc(data.supplyDate).toDate();
            if (isValidObject(data.currency)) {
                this.currency = new IdName_Dto(data.currency);
            }
            this.receptionSeries = data.receptionSeries;
            if (ObjectValidators.isValidObject(data.invoiceDate))
                this.invoiceDate = moment.utc(data.invoiceDate).toDate();
            if (ObjectValidators.isValidObject(data.deadline))
                this.deadline = moment.utc(data.deadline).toDate();
            this.invoiceStatus = data.invoiceStatus;
            this.totalValueWithVat = data.totalValueWithVat;
            this.totalVatValue = data.totalVatValue;
            this.totalWithVat = data.totalWithVat;
            this.totalWithoutVat = data.totalWithoutVat;
            this.totalVat = data.totalVat;
            this.observations = data.observations;
            this.receptionCommittee = data.receptionCommittee;
            this.delegate = data.delegate;
            this.auto = data.auto;
            this.internalObservations = data.internalObservations;
            this.supplyItems = data.supplyItems || [];
            this.isStorno = data.isStorno;
            this.rules = new SupplyRules(data.rules);
            if (ObjectValidators.isValidObject(data.createdOn))
                this.createdOn = moment.utc(data.createdOn).toDate();
            if (ObjectValidators.isValidObject(data.updatedOn))
                this.updatedOn = moment.utc(data.updatedOn).toDate();
        }
    }
}

export class SupplyItem_Dto extends Base_Dto {
    public name: string;
    public code: string;
    public um: string;
    public documentedQuantity: number;
    public receivedQuantity: number;
    public acquisitionPriceWithoutVat: number;
    public acquisitionPriceWithVat: number;
    public acquisitionPriceHasVat: boolean;
    public acquisitionPriceCurrency: string;
    public vatValue: IdNameNumber_Dto;
    public totalVat: number;
    public observations: string;
    public rules: SupplyItemRules;
    public createdOn: Date;
    public updatedOn: Date;
    public availableQuantity: number;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.acquisitionPriceCurrency = data.acquisitionPriceCurrency;
            this.name = data.name;
            this.code = data.code;
            this.um = data.um;
            this.availableQuantity = data.availableQuantity;
            this.documentedQuantity = data.documentedQuantity;
            this.receivedQuantity = data.receivedQuantity;
            this.acquisitionPriceWithoutVat = data.acquisitionPriceWithoutVat;
            this.acquisitionPriceWithVat = data.acquisitionPriceWithVat;
            this.acquisitionPriceHasVat = data.acquisitionPriceHasVat;
            this.vatValue = data.vatValue;
            this.totalVat = data.totalVat;
            this.observations = data.observations;
            this.rules = new SupplyRules(data.rules);
            if (ObjectValidators.isValidObject(data.createdOn))
                this.createdOn = moment.utc(data.createdOn).toDate();
            if (ObjectValidators.isValidObject(data.updatedOn))
                this.updatedOn = moment.utc(data.updatedOn).toDate();
        }
    }
}
