import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadNotificationComponent } from './components/upload-notification/upload-notification.component';
import { UploadNotificationSandbox } from './components/upload-notification/upload-notification.sandbox';
import { AzureUploaderService } from './components/upload-notification/azure-uploader.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [AzureUploaderService],
  declarations: [UploadNotificationComponent],
  exports: [UploadNotificationComponent]
})
export class UploadModule { }
