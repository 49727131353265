import {Injectable} from '@angular/core';
import {BaseApiService} from './base-api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiErrorDto} from '../utils/api-error.dto';
import {ConfigService} from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {ActiveDataTableParameters, PagedResult} from '../../shared/datatable/datatable.helpers';
import {ServicePlatform_Dto} from '../../app-dto/service-platform.dto';
import {ErrorHelpers} from '../../shared/error.helpers';
import {CalendarEventRelatedEntityType} from '../../app-dto/calendar-event.dto';
import {ObjectValidators} from '../../shared/object.validators';
import {cleanSearchValue} from '../utils/api-integration.helper';

class PlatformApiRoutes {
    public static route_for_GET_SERVICE_PLATFORMS_PAGINATED() {
        return '/api/ServicePlatform/all';
    }
    public static route_for_GET_SERVICE_PLATFORM(servicePlatformId: string) {
        return `/api/ServicePlatform/${servicePlatformId}`;
    }
    public static route_for_REGISTER_SERVICE_PLATFORM() {
        return '/api/ServicePlatform/';
    }
    public static route_for_EDIT_SERVICE_PLATFORM() {
        return '/api/ServicePlatform/';
    }
    public static route_for_DELETE_SERVICE_PLATFORM(servicePlatformId: string) {
        return `/api/ServicePlatform/${servicePlatformId}`;
    }
    public static route_for_ACTIVATE_SERVICE_PLATFORM(servicePlatformId: string) {
        return `/api/ServicePlatform/${servicePlatformId}/activate`;
    }
    public static route_for_DEACTIVATE_SERVICE_PLATFORM(servicePlatformId: string) {
        return `/api/ServicePlatform/${servicePlatformId}/deactivate`;
    }
    public static route_for_GET_SERVICE_PLATFORM_LIST() {
        return `/api/ServicePlatform/list`;
    }
    public static route_for_GET_SERVICE_PLATFORM_AVAILABILITY() {
        return `/api/ServicePlatform/availability`;
    }
}

@Injectable()
export class ServicePlatformApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_registerServicePlatform(servicePlatformData: ServicePlatform_Dto): Observable<ServicePlatform_Dto> {
        const url = this.configService.getAPIBaseUrl() + PlatformApiRoutes.route_for_REGISTER_SERVICE_PLATFORM();

        return this.http.post(url, servicePlatformData)
            .map((res: any) => {
                return new ServicePlatform_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_editServicePlatform(servicePlatformData: ServicePlatform_Dto): Observable<ServicePlatform_Dto> {
        const url = this.configService.getAPIBaseUrl() + PlatformApiRoutes.route_for_EDIT_SERVICE_PLATFORM();

        return this.http.put(url, servicePlatformData)
            .map((res: any) => {
                return new ServicePlatform_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getFilteredServicePlatformsPaginated(data: ActiveDataTableParameters): Observable<PagedResult<ServicePlatform_Dto>> {
        const url = this.configService.getAPIBaseUrl() + PlatformApiRoutes.route_for_GET_SERVICE_PLATFORMS_PAGINATED() + '?' + data.toUrlParameters();

        return this.http.get(url)
            .map((res: PagedResult<ServicePlatform_Dto>) => {
                return new PagedResult<ServicePlatform_Dto>(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getServicePlatform(servicePlatformId: string): Observable<ServicePlatform_Dto> {
        const url = this.configService.getAPIBaseUrl() + PlatformApiRoutes.route_for_GET_SERVICE_PLATFORM(servicePlatformId);

        return this.http.get(url)
            .map((res: any) => {
                return new ServicePlatform_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getServicePlatformList(): Observable<Array<ServicePlatform_Dto>> {
        const url = this.configService.getAPIBaseUrl() + PlatformApiRoutes.route_for_GET_SERVICE_PLATFORM_LIST();

        return this.http.get(url)
            .map((res: any) => {
                return res.map(r => new ServicePlatform_Dto(r));
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deleteServicePlatform(servicePlatformId: string): Observable<ServicePlatform_Dto> {
        const url = this.configService.getAPIBaseUrl() + PlatformApiRoutes.route_for_DELETE_SERVICE_PLATFORM(servicePlatformId);

        return this.http.delete(url)
            .map((res: any) => {
                return new ServicePlatform_Dto(null);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_activateServicePlatform(servicePlatformId: string): Observable<ServicePlatform_Dto> {
        const url = this.configService.getAPIBaseUrl() + PlatformApiRoutes.route_for_ACTIVATE_SERVICE_PLATFORM(servicePlatformId);

        return this.http.patch(url, {})
            .map((res: any) => {
                return new ServicePlatform_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deactivateServicePlatform(servicePlatformId: string): Observable<ServicePlatform_Dto> {
        const url = this.configService.getAPIBaseUrl() + PlatformApiRoutes.route_for_DEACTIVATE_SERVICE_PLATFORM(servicePlatformId);

        return this.http.patch(url, {})
            .map((res: any) => {
                return new ServicePlatform_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getServicePlatformAvailability(startDate: Date, endDate: Date, servicePlatformId: string = null, except: string = null,relatedEntityType:CalendarEventRelatedEntityType = CalendarEventRelatedEntityType.Appointment): Observable<boolean> {
        var url = this.configService.getAPIBaseUrl() + PlatformApiRoutes.route_for_GET_SERVICE_PLATFORM_AVAILABILITY() + '?' +
            'startDate=' + startDate.toISOString() + '&' +
            'endDate=' + endDate.toISOString() ;
        if (ObjectValidators.isValidString(servicePlatformId))
            url = url + '&servicePlatformId=' + servicePlatformId;
        if (ObjectValidators.isValidString(except)) {
          url = url + '&except=' + cleanSearchValue(except) + '&relatedEntityType=' + relatedEntityType;
        }
        return this.http.get(url)
            .map((res: any) => {
                return res;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
}
