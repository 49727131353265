import {ObjectValidators} from "../shared/object.validators";

export class ReportingSet<T> {
  public data: Array<T>;

  constructor(data: Array<T>) {
    this.data = data;
  }
}

export class ReportingItem {
  public key: string;
  public value: number;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      this.key = data.key;
      this.value = data.value;
    }
  }
}

export class ReportingMechanicItem extends ReportingItem {
  public salary: number;
  public profit: number;
  public valueWithVAT: number;

  constructor(data: any) {
    super(data);
    this.profit = data.profit;
    this.salary = data.salary;
    this.valueWithVAT = data.valueWithVAT;
  }
}

export class ReportingProviderItem {
  public key: string;
  public totalCost: number;
  public totalIncome: number;
  public profitValue: number;

  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      this.key = data.key;
      this.totalCost = data.totalCost;
      this.totalIncome = data.totalIncome;
      this.profitValue = data.profitValue;
    }
  }
}

export class ReportingListProfitItem {
  public key: string;
  public profit: number;
  public carPartsEarnings: number;
  public workmanShipEarnings: number;
  public carPartsCost: number;
  public mechanicsSalary: number;
  public usersSalary: number;
  public organizationCostSalary: number;
  public overallCost: number;
  public providersProfit: number;


  constructor(data: any) {
    if (ObjectValidators.isValidObject(data)) {
      this.key = data.key;
      this.profit = data.profit;
      this.carPartsEarnings = data.carPartsEarnings;
      this.workmanShipEarnings = data.workmanShipEarnings;
      this.carPartsCost = data.carPartsCost;
      this.mechanicsSalary = data.mechanicsSalary;
      this.usersSalary = data.usersSalary;
      this.organizationCostSalary = data.organizationCostSalary;
      this.overallCost = data.overallCost;
      this.providersProfit = data.providersProfit;
    }
  }
}

export enum ReportingGroupinOption {
  Day,
  Month
}
