import {Base_Dto} from "./misc.dto";
import {ObjectValidators} from "../shared/object.validators";
import * as moment from "moment";

export class InvoicePartialCharge_Dto {
    public amount: number;
    public type: number;
    public description: string;

    constructor(data?: any) {
        if (ObjectValidators.isValidObject(data)) {
            if (data.value)
                this.amount = data.value;
            else this.amount = data.amount;
            this.type = data.type;
            this.description = data.description;
        }
    }
}

export class InvoicePrintJob_Dto extends Base_Dto {
    public organizationId: string;
    public invoiceId: string;
    public cashRegisterId: string;
    public cashRegisterName: string;
    public status: string;
    public charges: Array<InvoicePartialCharge_Dto> = [];
    public freeText: string;
    public outputFileName: string;
    public createdOn: Date;
    public updatedOn: Date;

    constructor(data?: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.organizationId = data.organizationId;
            this.invoiceId = data.invoiceId;
            this.cashRegisterId = data.cashRegisterId;
            this.cashRegisterName = data.cashRegisterName;
            this.status = data.status;
            this.freeText = data.freeText;
            this.outputFileName = data.outputFileName;

            if (ObjectValidators.isValidObject(data.createdOn))
                this.createdOn = moment.utc(data.createdOn).toDate();

            if (ObjectValidators.isValidObject(data.updatedOn))
                this.updatedOn = moment.utc(data.updatedOn).toDate();

            if (ObjectValidators.isValidNonEmptyArray(data.charges))
                this.charges = data.charges.map(x => new InvoicePartialCharge_Dto(x));
        }
    }
}
