import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CarService_Dto } from 'src/app/core/app-dto/car-service.dto';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { CarServiceCompleteSandbox } from './car-service-complete.sandbox';
import { CarServiceCompleteDI } from './car-service-complete.di';
import { FileImageWrapper } from '../car-service-photos/car-service-photos.component';
import { BsModalService } from 'ngx-bootstrap';
import { EditCarServiceClientComponent } from '../edit-car-service-client/edit-car-service-client.component';
import { EditCarServiceCarComponent } from '../edit-car-service-car/edit-car-service-car.component';
import { EditCarServiceDataComponent } from '../edit-car-service-data/edit-car-service-data.component';
import { EditCarServiceCarWorksComponent } from '../edit-car-service-car-works/edit-car-service-car-works.component';
import {getFormattedIdNameList} from "../../../core/shared/helpers/common.helpers";
import {IdName_Dto} from "../../../core/app-dto/misc.dto";
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";

@Component({
  selector: 'app-car-service-complete',
  templateUrl: './car-service-complete.component.html',
  styleUrls: ['./car-service-complete.component.scss']
})
export class CarServiceCompleteComponent extends BaseFormModelComponent<CarService_Dto> implements OnInit, OnDestroy {

  @Input() imgUrls: Array<FileImageWrapper> = [];

  public isReadOnlyValue:boolean;
  @Input() set isReadOnly(value:boolean){
   this.isReadOnlyValue = value;
  }

  public di: CarServiceCompleteDI;//we need to overwrite di with specific type for production build


  constructor(public appState: Store<store.State>,
              private modalService: BsModalService,
              public sandbox: CarServiceCompleteSandbox,
              public rulesProvider:AppUserBusinessRuleProvider
  ) {
    super(sandbox);
  }

  public diInitialize(data: CarService_Dto) {
    this.di = new CarServiceCompleteDI(data);
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.sandbox.initialize();
  }

  ngOnDestroy() {
    this.destroy();
  }

  editClient() {
    const modalRef = this.modalService.show(EditCarServiceClientComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = this.di.model;

  }

  editCar() {
    const modalRef = this.modalService.show(EditCarServiceCarComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = this.di.model;
  }

  editCarService() {
    const modalRef = this.modalService.show(EditCarServiceDataComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = this.di.model;
  }
  editserviceCarWorksRecomandations() {
    const modalRef = this.modalService.show(EditCarServiceCarWorksComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = this.di.model;
  }

  formatFuels(fuelsList) {
    return getFormattedIdNameList(fuelsList);
  }
}
