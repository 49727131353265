import {ObjectValidators} from '../shared/object.validators';
import {Base_Dto, BaseCRUDRules, BusinessRule, IdNameNumber_Dto} from './misc.dto';
import * as moment from 'moment';
import {Supplier_Dto} from './supplier.dto';
import {Supply_Dto} from './supply.dto';
import {isValidArrayAndHasElements, isValidObject} from "../shared/helpers/common.helpers";

export class CarPartRules extends BaseCRUDRules {
    public canActivate: BusinessRule;
    public canDeactivate: BusinessRule;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.canActivate = new BusinessRule(data.canActivate);
            this.canDeactivate = new BusinessRule(data.canDeactivate);

        } else {
            this.canActivate = new BusinessRule();
            this.canDeactivate = new BusinessRule();
        }
    }
}

export class CarPart_Dto extends Base_Dto {
    public name: string;
    public nameCode: string;
    public code: string;
    public acquisitionPrice: number;
    public acquisitionPriceHasVat: boolean;
    public acquisitionPriceWithVat?: number;
    public acquisitionPriceCurrency: string;
    public sellingPrice: number;
    public sellingPriceHasVat: boolean;
    public sellingPriceWithVat?: number;
    public sellingPriceCurrency: string;
    public supplier: Supplier_Dto;
    public isSecondHand: boolean;
    public isFromSupply: boolean;
    public rules: CarPartRules;
    public quantity?: number;
    public um: string;
    public availableQuantity?: number;
    public hasStockAlert: boolean;
    public createdOn: Date;
    public updatedOn: Date;
    public acquisitionVatValue: IdNameNumber_Dto;
    public sellingVatValue: IdNameNumber_Dto;
    public isEdit: boolean;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            if (isValidObject(data.acquisitionVatValue)) {
                this.acquisitionVatValue = new IdNameNumber_Dto(data.acquisitionVatValue);
            }
            if (isValidObject(data.sellingVatValue)) {
                this.sellingVatValue = new IdNameNumber_Dto(data.sellingVatValue);
            }
            this.name = data.name;
            this.nameCode = `${data.name} - ${data.code} - ${data.acquisitionPriceWithVat} ${data.acquisitionPriceCurrency}`;
            this.code = data.code;
            this.acquisitionPrice = data.acquisitionPrice;
            this.acquisitionPriceHasVat = data.acquisitionPriceHasVat || false;
            this.acquisitionPriceWithVat = data.acquisitionPriceWithVat;
            this.acquisitionPriceCurrency = data.acquisitionPriceCurrency;
            this.sellingPrice = data.sellingPrice;
            this.sellingPriceHasVat = data.sellingPriceHasVat || false;
            this.sellingPriceWithVat = data.sellingPriceWithVat;
            this.sellingPriceCurrency = data.sellingPriceCurrency;
            this.supplier = data.supplier;
            this.isSecondHand = data.isSecondHand || false;
            this.isFromSupply = data.isFromSupply || false;
            this.hasStockAlert = data.hasStockAlert || false;
            this.quantity = data.quantity;
            this.um = data.um;
            this.availableQuantity = data.availableQuantity;
            this.rules = new CarPartRules(data.rules);
            if (ObjectValidators.isValidObject(data.createdOn))
                this.createdOn = moment.utc(data.createdOn).toDate();
            if (ObjectValidators.isValidObject(data.updatedOn))
                this.updatedOn = moment.utc(data.updatedOn).toDate();
            this.isEdit = false;
        }
    }

}

export class CarPartWithSupplyItemRef_Dto extends CarPart_Dto {
    public supplyItems: Array<CarPartSupplyItem>;

    constructor(data: any) {
        super(data);
        if (isValidObject(data)) {
            if (isValidArrayAndHasElements(data.supplyItems)) {
                this.supplyItems = data.supplyItems.map(r => new CarPartSupplyItem(r));
            } else this.supplyItems = [];
        }
    }
}

export class CarPartSupplyItem {
    public supplyItemId: string;
    public quantity: number;

    constructor(data: any) {
        if (isValidObject(data)) {
            this.supplyItemId = data.supplyItemId;
            this.quantity = data.quantity;
        }
    }
}


export class CarServiceCarPart_Dto {
    public carServiceId: string;
    public carPart: CarPart_Dto;

    constructor(data: any) {
        if (ObjectValidators.isValidObject(data)) {
            this.carServiceId = data.carServiceId;
            this.carPart = new CarPart_Dto(data.carPart);
        }
    }
}

export class CarPartAggregate_Dto {
    public carPart: CarPart_Dto;
    public supplies: Supply_Dto[];

    constructor(data: any) {
        if (ObjectValidators.isValidObject(data)) {
            this.carPart = new CarPart_Dto(data.carPart);
            this.supplies = data.supplies;
        }
    }
}


