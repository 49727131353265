import { BaseDI } from 'src/app/core/shared/base/base.di';
import { Car_Dto } from '../../../core/app-dto/car.dto';

export class CarsListItemDi extends BaseDI<Car_Dto> {
    constructor(data: any) {
        super(new Car_Dto(data));
    }

    getModel(): Car_Dto {
        return this.model;
    }
}
