import { BaseDI } from 'src/app/core/shared/base/base.di';
import { Car_Dto } from '../../../core/app-dto/car.dto';

export class CarDetailsDi extends BaseDI<Car_Dto> {
    constructor(data: Car_Dto = new Car_Dto(null)) {
        super(data);
    }

    getModel(): Car_Dto {
        return this.model;
    }
}
