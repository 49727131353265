import {Component, Input, OnInit} from '@angular/core';
import {Offer_Dto, OffertedClientContact_Dto, SendOfferPayload_Dto} from '../../../core/app-dto/offer.dto';
import {CarPart_Dto} from '../../../core/app-dto/car-part.dto';
import {isValidArrayAndHasElements, isValidObject} from '../../../core/shared/helpers/common.helpers';
import {ReceiverDi} from './receiver.di';
import {AppointmentClient_Dto} from '../../../core/app-dto/appointment-client.dto';
import {AppointmentClientDi} from '../../../calendar/components/appointment-client/appointment-client.di';
import {UpdateOfferSaveServiceCarPartComponent} from '../update-offer-save-service-car-part/update-offer-save-service-car-part.component';
import {BsModalService} from 'ngx-bootstrap';
import {SendOfferToClientsComponent} from '../send-offer-to-clients/send-offer-to-clients.component';
import {SendOfferActionProxy} from '../../common/ap/send-offer.action-proxy';
import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {BaseWebComponent} from '../../../core/shared/base/base.component';

@Component({
    selector: 'app-offer-details-list-senders',
    templateUrl: './offer-details-list-senders.component.html',
    styleUrls: ['./offer-details-list-senders.component.scss']
})
export class OfferDetailsListSendersComponent extends BaseWebComponent implements OnInit {
    public sendOfferAP: SendOfferActionProxy;
    public items: Array<ReceiverDi> = [];
    public offerValue: Offer_Dto;
    public forEmail: boolean = false;
    public forSMS: boolean = false;

    @Input('offer') set offer(offer: Offer_Dto) {
        this.offerValue = offer;
        if (isValidObject(offer) && isValidArrayAndHasElements(offer.sentList)) {
            this.items = offer.sentList.map(r => new ReceiverDi(r, offer.acceptedList, offer.declinedList));
        } else {
            this.items = [];
        }
    }

    @Input() isReadOnlyValue: boolean = false;

    constructor(private modalService: BsModalService, appState: Store<store.State>) {
        super(null, null);
        this.sendOfferAP = new SendOfferActionProxy(this, appState);
    }

    ngOnInit() {
    }

    canSend() {
        return (
            (this.offerValue.isIndividual && !isValidArrayAndHasElements(this.offerValue.sentList)) || this.offerValue.isIndividual == false)
            && this.offerValue.rules.canSendOffer.value;
    }

    sendOffer() {
        if (this.offerValue.isIndividual === false) {
            const modalRef = this.modalService.show(SendOfferToClientsComponent, {
                backdrop: 'static',
                keyboard: false,
                class: 'modal-xl'
            });
            modalRef.content.offer = this.offerValue;
        } else {
            if (isValidArrayAndHasElements(this.offerValue.sentList)) {
                return;
            }
            const payload = new SendOfferPayload_Dto();
            payload.sendToEmail = this.forEmail;
            payload.sendToSMS = this.forSMS;
            const client = new OffertedClientContact_Dto(this.offerValue.client);
            payload.contacts = [client];
            this.sendOfferAP.execute(this.offerValue, payload);

        }
    }
}
