import { Observable } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import { map, switchMap } from 'rxjs/operators';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  EventWithScreenLoading,
  SeamlessEvent
} from 'src/app/core/app-store/events/base.events';
import { PagedResult } from 'src/app/core/shared/datatable/datatable.helpers';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import {
  AddCarInServiceActionEvent,
  AddCarInServiceEvent,
  DeleteCarInServiceActionEvent,
  DeleteCarServiceCarPartEvent,
  DeleteCarServiceClientCarPartEvent,
  DeleteCarServiceEvent,
  DeleteCarServiceWorkmanshipEvent,
  EditCarServiceCarEvent,
  EditCarServiceClientEvent,
  EditCarServiceEntryDataEvent,
  FinalizeCarServiceEvent,
  GetCarInServiceActionListEvent,
  GetCarServiceEntriesEvent,
  GetCarServiceEntriesPagedResultEvent,
  GetCarServiceEvent,
  UpdateCarInServiceActionEvent,
  UpdateCarServiceAddCarPartEvent,
  UpdateCarServiceAddClientPartEvent,
  UpdateCarServiceAddWorkmanshipEvent,
  UpdateCarServiceUpdateCarPartEvent,
  UpdateCarServiceUpdateClientPartEvent,
  UpdateCarServiceUpdateWorkmanshipEvent,
  GetCarServiceUnatuhorizedEvent,
  GetCarInServiceActionListUnauthorizedEvent,
  GetCarServiceEntryStatisticsEvent,
  EditCarServiceEntryFinalInspectionEvent,
  GetCarServiceEntryUsersEvent,
  DownloadCarServiceEntryFinalInspectionEvent,
  EditCarServiceCarWorksEvent, DeleteCarPartFromCarService
} from '../../core/app-store/events/car-service.events';
import { CarServiceApiService } from '../../core/api-integration/services/car-service-api.service';
import {
  CarAction_Dto,
  CarService_Dto,
  CarServiceEntryActionListChanged_Dto,
  CarServiceEntryStatistics,
  FinalInspectionUser_Dto
} from '../../core/app-dto/car-service.dto';
import { ContinueDraftCarServiceEntryEvent } from "../../core/app-store/events/draft.events";
import { AddCarInServiceComponent } from "../components/add-car-in-service/add-car-in-service.component";
import { BsModalService } from "ngx-bootstrap";
import { isValidString } from "../../core/shared/helpers/common.helpers";
import {AddEditCarInServiceWizardComponent} from "../components/add-edit-car-in-service-wizard/add-edit-car-in-service-wizard.component";

@Injectable()
export class CarServiceUIEventProcessor {

  constructor(
    private actions$: Actions,
    private appState$: Store<store.State>,
    private carServiceApiService: CarServiceApiService,
    private modalService: BsModalService
  ) {
  }

  @Effect()
  GetCarServiceEntriesPagedResultEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetCarServiceEntriesPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetCarServiceEntriesPagedResultEvent.identifier),
      map((action: GetCarServiceEntriesPagedResultEvent) => action),
      switchMap((action: GetCarServiceEntriesPagedResultEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_getFilteredCarServiceEntriesPaginated(action.parameters)
            .subscribe((res: PagedResult<CarService_Dto>) => {
              action.callback();
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<PagedResult<CarService_Dto>>(res, action));
              }
              observer.complete();
            }, (error: string) => {
              action.callback();
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  GetCarServiceEntriesEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetCarServiceEntriesEvent.identifier, SeamlessEvent.identifier + GetCarServiceEntriesEvent.identifier),
      map((action: GetCarServiceEntriesEvent) => action),
      switchMap((action: GetCarServiceEntriesEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_getCarServiceEntries()
            .subscribe((res: Array<CarService_Dto>) => {
              action.callback();
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<Array<CarService_Dto>>(res, action));
              }
              observer.complete();
            }, (error: string) => {
              action.callback();
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  doAddCarInServiceEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(AddCarInServiceEvent.identifier, EventWithScreenLoading.identifier + AddCarInServiceEvent.identifier),
      map((action: AddCarInServiceEvent) => action),
      switchMap((action: AddCarInServiceEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_addCarServiceEntry(action.model, action.appointmentId)
            .subscribe((res: CarService_Dto) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              }
              action.callback(res);
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  doGetCarServiceEntryEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetCarServiceEvent.identifier, EventWithScreenLoading.identifier + GetCarServiceEvent.identifier),
      map((action: GetCarServiceEvent) => action),
      switchMap((action: GetCarServiceEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_getCarServiceEntry(action.id)
            .subscribe((res: CarService_Dto) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              }
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  doGetCarServiceEntryUnauthorizedEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetCarServiceUnatuhorizedEvent.identifier, EventWithScreenLoading.identifier + GetCarServiceUnatuhorizedEvent.identifier),
      map((action: GetCarServiceUnatuhorizedEvent) => action),
      switchMap((action: GetCarServiceUnatuhorizedEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_getCarServiceEntryUnauthorized(action.code, action.carServiceEntryId)
            .subscribe((res: CarService_Dto) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              }
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  doEditCarServiceClient$: Observable<any> = this.actions$
    .pipe(
      ofType(EditCarServiceClientEvent.identifier, EventWithScreenLoading.identifier + EditCarServiceClientEvent.identifier),
      map((action: EditCarServiceClientEvent) => action),
      switchMap((action: EditCarServiceClientEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_editCarServiceClient(action.model)
            .subscribe((res: CarService_Dto) => {
              observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      }
      )
    );

    @Effect()
    doEditCarServiceCarWorks$: Observable<any> = this.actions$
        .pipe(
            ofType(EditCarServiceCarWorksEvent.identifier, EventWithScreenLoading.identifier + EditCarServiceCarWorksEvent.identifier),
            map((action: EditCarServiceCarWorksEvent) => action),
            switchMap((action: EditCarServiceCarWorksEvent) => {
                    return new Observable((observer) => {
                        this.carServiceApiService.u_editCarServiceCarWorks(action.model)
                            .subscribe((res: CarService_Dto) => {
                                observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
                                observer.complete();
                            }, (error: string) => {
                                observer.next(new Do_GenericErrorEvent<string>(error, action));
                                observer.complete();
                            });
                    });
                }
            )
        );

  @Effect()
  doEditCarServiceCar$: Observable<any> = this.actions$
    .pipe(
      ofType(EditCarServiceCarEvent.identifier, EventWithScreenLoading.identifier + EditCarServiceCarEvent.identifier),
      map((action: EditCarServiceCarEvent) => action),
      switchMap((action: EditCarServiceCarEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_editCarServiceCar(action.model)
            .subscribe((res: CarService_Dto) => {
              observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      }
      )
    );

  @Effect()
  doEditCarServiceEntryData$: Observable<any> = this.actions$
    .pipe(
      ofType(EditCarServiceEntryDataEvent.identifier, EventWithScreenLoading.identifier + EditCarServiceEntryDataEvent.identifier),
      map((action: EditCarServiceEntryDataEvent) => action),
      switchMap((action: EditCarServiceEntryDataEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_editCarServiceEntryData(action.model)
            .subscribe((res: CarService_Dto) => {
              observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      }
      )
    );

  @Effect()
  doEditCarServiceEntryFinalInspection$: Observable<any> = this.actions$
    .pipe(
      ofType(EditCarServiceEntryFinalInspectionEvent.identifier, EventWithScreenLoading.identifier + EditCarServiceEntryFinalInspectionEvent.identifier),
      map((action: EditCarServiceEntryFinalInspectionEvent) => action),
      switchMap((action: EditCarServiceEntryFinalInspectionEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_editCarServiceEntryFinalInspection(action.model)
            .subscribe((res: CarService_Dto) => {
              observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      }
      )
    );

  @Effect()
  doUpdateCarServiceAddClientPart$: Observable<any> = this.actions$
    .pipe(
      ofType(UpdateCarServiceAddClientPartEvent.identifier, EventWithScreenLoading.identifier + UpdateCarServiceAddClientPartEvent.identifier),
      map((action: UpdateCarServiceAddClientPartEvent) => action),
      switchMap((action: UpdateCarServiceAddClientPartEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_updateCarServiceAddClientPart(action.model, action.id)
            .subscribe((res: CarService_Dto) => {
              observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      }
      )
    );

  @Effect()
  doUpdateCarServiceUpdateClientPart$: Observable<any> = this.actions$
    .pipe(
      ofType(UpdateCarServiceUpdateClientPartEvent.identifier, EventWithScreenLoading.identifier + UpdateCarServiceUpdateClientPartEvent.identifier),
      map((action: UpdateCarServiceUpdateClientPartEvent) => action),
      switchMap((action: UpdateCarServiceUpdateClientPartEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_updateCarServiceUpdateClientPart(action.model, action.id)
            .subscribe((res: CarService_Dto) => {
              observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      }
      )
    );

  @Effect()
  doUpdateCarServiceAddCarPart$: Observable<any> = this.actions$
    .pipe(
      ofType(UpdateCarServiceAddCarPartEvent.identifier, EventWithScreenLoading.identifier + UpdateCarServiceAddCarPartEvent.identifier),
      map((action: UpdateCarServiceAddCarPartEvent) => action),
      switchMap((action: UpdateCarServiceAddCarPartEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_updateCarServiceAddCarPart(action.model, action.id)
            .subscribe((res: CarService_Dto) => {
              observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      }
      )
    );

  @Effect()
  doUpdateCarServiceUpdateCarPart$: Observable<any> = this.actions$
    .pipe(
      ofType(UpdateCarServiceUpdateCarPartEvent.identifier, EventWithScreenLoading.identifier + UpdateCarServiceUpdateCarPartEvent.identifier),
      map((action: UpdateCarServiceUpdateCarPartEvent) => action),
      switchMap((action: UpdateCarServiceUpdateCarPartEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_updateCarServiceUpdateCarPart(action.model, action.id)
            .subscribe((res: CarService_Dto) => {
              observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      }
      )
    );

  @Effect()
  doUpdateCarServiceUpdateWorkmanship$: Observable<any> = this.actions$
    .pipe(
      ofType(UpdateCarServiceUpdateWorkmanshipEvent.identifier, EventWithScreenLoading.identifier + UpdateCarServiceUpdateWorkmanshipEvent.identifier),
      map((action: UpdateCarServiceUpdateWorkmanshipEvent) => action),
      switchMap((action: UpdateCarServiceUpdateWorkmanshipEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_updateCarServiceUpdateWorkmanship(action.model, action.id)
            .subscribe((res: CarService_Dto) => {
              observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      }
      )
    );

  @Effect()
  doUpdateCarServiceAddWorkmanship$: Observable<any> = this.actions$
    .pipe(
      ofType(UpdateCarServiceAddWorkmanshipEvent.identifier, EventWithScreenLoading.identifier + UpdateCarServiceAddWorkmanshipEvent.identifier),
      map((action: UpdateCarServiceAddWorkmanshipEvent) => action),
      switchMap((action: UpdateCarServiceAddWorkmanshipEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_updateCarServiceAddWorkmanship(action.model, action.id)
            .subscribe((res: CarService_Dto) => {
              observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      }
      )
    );

  @Effect()
  doAddCarInServiceActionEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(AddCarInServiceActionEvent.identifier, EventWithScreenLoading.identifier + AddCarInServiceActionEvent.identifier),
      map((action: AddCarInServiceActionEvent) => action),
      switchMap((action: AddCarInServiceActionEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_addCarServiceEntryAction(action.model)
            .subscribe((res: CarServiceEntryActionListChanged_Dto) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<CarServiceEntryActionListChanged_Dto>(res, action));
              }
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  doUpdateCarInServiceActionEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(UpdateCarInServiceActionEvent.identifier, EventWithScreenLoading.identifier + UpdateCarInServiceActionEvent.identifier),
      map((action: UpdateCarInServiceActionEvent) => action),
      switchMap((action: UpdateCarInServiceActionEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_updateCarServiceEntryAction(action.model)
            .subscribe((res: CarServiceEntryActionListChanged_Dto) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<CarServiceEntryActionListChanged_Dto>(res, action));
              }
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  doDeleteCarInServiceActionEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(DeleteCarInServiceActionEvent.identifier, EventWithScreenLoading.identifier + DeleteCarInServiceActionEvent.identifier),
      map((action: DeleteCarInServiceActionEvent) => action),
      switchMap((action: DeleteCarInServiceActionEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_deleteCarServiceEntryAction(action.id)
            .subscribe((res: CarServiceEntryActionListChanged_Dto) => {
              observer.next(new Do_GenericSuccessEvent<CarServiceEntryActionListChanged_Dto>(res, action));
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  doGetCarInServiceActionListEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetCarInServiceActionListEvent.identifier, EventWithScreenLoading.identifier + GetCarInServiceActionListEvent.identifier),
      map((action: GetCarInServiceActionListEvent) => action),
      switchMap((action: GetCarInServiceActionListEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_getCarServiceEntryActionList(action.id)
            .subscribe((res: Array<CarAction_Dto>) => {
              if (ObjectValidators.isValidObject(res)) {

                observer.next(new Do_GenericSuccessEvent<Array<CarAction_Dto>>(res, action));
              }
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  doGetCarInServiceActionListUnauthorizedEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetCarInServiceActionListUnauthorizedEvent.identifier, EventWithScreenLoading.identifier + GetCarInServiceActionListUnauthorizedEvent.identifier),
      map((action: GetCarInServiceActionListUnauthorizedEvent) => action),
      switchMap((action: GetCarInServiceActionListUnauthorizedEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_getCarServiceEntryActionUnauthorizedList(action.code, action.carServiceEntryId)
            .subscribe((res: Array<CarAction_Dto>) => {
              if (ObjectValidators.isValidObject(res)) {

                observer.next(new Do_GenericSuccessEvent<Array<CarAction_Dto>>(res, action));
              }
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );


  @Effect()
  doUpdateCarServiceDeleteServiceCarPart$: Observable<any> = this.actions$
    .pipe(
      ofType(DeleteCarServiceCarPartEvent.identifier, EventWithScreenLoading.identifier + DeleteCarServiceCarPartEvent.identifier),
      map((action: DeleteCarServiceCarPartEvent) => action),
      switchMap((action: DeleteCarServiceCarPartEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_updateCarServiceDeleteServiceCarPart(action.data)
            .subscribe((res: DeleteCarPartFromCarService) => {
              observer.next(new Do_GenericSuccessEvent<DeleteCarPartFromCarService>(res, action));
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      }
      )
    );

  @Effect()
  doUpdateCarServiceDeleteClientCarPart$: Observable<any> = this.actions$
    .pipe(
      ofType(DeleteCarServiceClientCarPartEvent.identifier, EventWithScreenLoading.identifier + DeleteCarServiceClientCarPartEvent.identifier),
      map((action: DeleteCarServiceClientCarPartEvent) => action),
      switchMap((action: DeleteCarServiceClientCarPartEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_updateCarServiceDeleteClientCarPart(action.carServiceId, action.carPartId)
            .subscribe((res: CarService_Dto) => {
              observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      }
      )
    );

  @Effect()
  doUpdateCarServiceDeleteWorkmanship$: Observable<any> = this.actions$
    .pipe(
      ofType(DeleteCarServiceWorkmanshipEvent.identifier, EventWithScreenLoading.identifier + DeleteCarServiceWorkmanshipEvent.identifier),
      map((action: DeleteCarServiceWorkmanshipEvent) => action),
      switchMap((action: DeleteCarServiceWorkmanshipEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_updateCarServiceDeleteWorkmanship(action.carServiceId, action.workmanshipId)
            .subscribe((res: CarService_Dto) => {
              observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      }
      )
    );

  @Effect()
  doContinueDraft$: Observable<any> = this.actions$
    .pipe(
      ofType(ContinueDraftCarServiceEntryEvent.identifier, SeamlessEvent.identifier + ContinueDraftCarServiceEntryEvent.identifier),
      map((action: ContinueDraftCarServiceEntryEvent) => action),
      switchMap((action: ContinueDraftCarServiceEntryEvent) => {
        return new Observable((observer) => {
          const step = isValidString(action.model.currentPage) ? parseInt(action.model.currentPage) : 1;
          const initialState = { model: new CarService_Dto(JSON.parse(action.model.data)), initialStep: step };
          const modalRef = this.modalService.show(AddEditCarInServiceWizardComponent, { initialState: initialState, backdrop: 'static', keyboard: false, class: 'modal-xl' });
          observer.complete();

        });
      }
      )
    );

  @Effect()
  DeleteCarServiceEntryEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(DeleteCarServiceEvent.identifier, EventWithScreenLoading.identifier + DeleteCarServiceEvent.identifier),
      map((action: DeleteCarServiceEvent) => action),
      switchMap((action: DeleteCarServiceEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_deleteCarServiceEntry(action.id)
            .subscribe((res: CarService_Dto) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              }
              if (ObjectValidators.isValidObject(action.callback)) action.callback();
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  FinalizeCarServiceEntryEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(FinalizeCarServiceEvent.identifier, EventWithScreenLoading.identifier + FinalizeCarServiceEvent.identifier),
      map((action: FinalizeCarServiceEvent) => action),
      switchMap((action: FinalizeCarServiceEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_finalizeCarServiceEntry(action.id)
            .subscribe((res: CarService_Dto) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
              }
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  GetStatistics$: Observable<any> = this.actions$
    .pipe(
      ofType(GetCarServiceEntryStatisticsEvent.identifier, SeamlessEvent.identifier + GetCarServiceEntryStatisticsEvent.identifier),
      map((action: GetCarServiceEntryStatisticsEvent) => action),
      switchMap((action: GetCarServiceEntryStatisticsEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_getStatistics()
            .subscribe((res: CarServiceEntryStatistics) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<CarServiceEntryStatistics>(res, action));
              }
              if (action.callback) action.callback(res);
              observer.complete();
            }, (error: string) => {
              action.callback();
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  GetCarServiceEntryUsers$: Observable<any> = this.actions$
    .pipe(
      ofType(GetCarServiceEntryUsersEvent.identifier, EventWithScreenLoading.identifier + GetCarServiceEntryUsersEvent.identifier),
      map((action: GetCarServiceEntryUsersEvent) => action),
      switchMap((action: GetCarServiceEntryUsersEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_getCarServiceEntryUsersEvent()
            .subscribe((res: Array<FinalInspectionUser_Dto>) => {
              if (ObjectValidators.isValidArray(res)) {
                observer.next(new Do_GenericSuccessEvent<Array<FinalInspectionUser_Dto>>(res, action));
              }
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  DownloadCarServiceEntryFinalInspection$: Observable<any> = this.actions$
    .pipe(
      ofType(DownloadCarServiceEntryFinalInspectionEvent.identifier, EventWithScreenLoading.identifier + DownloadCarServiceEntryFinalInspectionEvent.identifier),
      map((action: DownloadCarServiceEntryFinalInspectionEvent) => action),
      switchMap((action: DownloadCarServiceEntryFinalInspectionEvent) => {
        return new Observable((observer) => {
          this.carServiceApiService.u_downloadCarServiceEntryFinalInspectionEvent(action.id)
            .subscribe((res: string) => {
              if (ObjectValidators.isValidString(res)) {
                action.callback(res);
              }
              observer.next(new Do_GenericSuccessEvent<string>(res, action));
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );
}
