import {Injectable} from '@angular/core';
import {BaseSandbox} from '../../../core/shared/base/base.sandbox';
import {select, Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {PagedResult, ReceiptDataTableParameters} from '../../../core/shared/datatable/datatable.helpers';
import {ReceiptListItemDi} from './receipt-list-item.di';
import {Receipt_Dto} from '../../../core/app-dto/receipt.dto';

@Injectable()
export class ReceiptListSandbox extends BaseSandbox {
    public receipts$ = this.appState$.pipe(select(store.getReceiptsListing));
    public timestamp$ = this.appState$.pipe(select(store.getReceiptTimestamp));
    public totalPages: number = 0;
    public items: Array<ReceiptListItemDi> = [];
    public dataTableParameters: ReceiptDataTableParameters;
    public callback: any;
    private draw: number = 1;
    public lastRefreshTime: number = 0;
    public resetTableCallback: Function;
    public totalNumberOfResults: number = null;
    public organizationId: string = null;

    constructor(
        protected appState$: Store<store.State>
    ) {
        super(appState$);
    }
    clearSandboxState() {
        this.items = [];
        this.draw = 1;
        this.lastRefreshTime = 0;
    }

    public initialize(itemsLoaded: (result: Array<ReceiptListItemDi>) => void): any {
        this.clearSandboxState();
        this.subscriptions.push(this.receipts$.subscribe((data: PagedResult<Receipt_Dto>) => {
            if (data != null && data.items != null) {
                if (data.items.length > 0) {
                    this.organizationId = data.items[0].organizationId;
                }
                this.items = data.items.map(r => new ReceiptListItemDi(r));
                this.totalNumberOfResults = data.totalItems;
                this.totalPages = data.totalPages;
                if (this.callback != null) {
                    this.callback({
                        draw: this.draw++,
                        recordsTotal: data.totalItems,
                        recordsFiltered: data.totalItems,
                        data: []
                    });

                }
            } else {
                this.items = [];
                this.draw = 1;
            }
            if (itemsLoaded != null) { itemsLoaded(this.items); }
        }));

        this.subscriptions.push(this.timestamp$.subscribe((result: number) => {
            if (result > this.lastRefreshTime) {
                this.lastRefreshTime = result;
                this.resetTable();
            }
        }));
    }

    public setResetTableCallback(callback: Function) {
        this.resetTableCallback = callback;
    }

    public resetTable() {
        if (this.resetTableCallback != null) { this.resetTableCallback(); }
    }
}
