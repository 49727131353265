import {EventWithScreenLoading, UIGeneratedEvent,} from './base.events';
import {ActiveDataTableParameters} from '../../shared/datatable/datatable.helpers';
import {User_Dto} from '../../app-dto/core.dto';

export class GetUsersPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetUsersPagedResultEvent";

  constructor(public parameters: ActiveDataTableParameters, public withReset: boolean = false) {
    super();
    this.type += GetUsersPagedResultEvent.identifier;
  }
}

export class GetUserEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetUserEvent";

  constructor(public id: string) {
    super();
    this.type += GetUserEvent.identifier;
  }
}

export class RegisterUserEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "RegisterUserEvent";

  constructor(public model: User_Dto) {
    super();
    this.type += RegisterUserEvent.identifier;
  }
}


export class EditUserEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "EditUserEvent";

  constructor(public model: User_Dto, public updatedHistory: boolean) {
    super();
    this.type += EditUserEvent.identifier;
  }
}


export class DeleteUserEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeleteUserEvent";

  constructor(public id: string, public callback: Function = null) {
    super();
    this.type += DeleteUserEvent.identifier;
  }
}

export class ActivateUserEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "ActivateUserEvent";

  constructor(public id: string, public startDate: Date) {
    super();
    this.type += ActivateUserEvent.identifier;
  }
}

export class DeactivateUserEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DeactivateUserEvent";

  constructor(public id: string, public endDate: Date) {
    super();
    this.type += DeactivateUserEvent.identifier;
  }
}

export class SetInitialSetupEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "SetInitialSetupEvent";

  constructor(public id: string, public value: boolean) {
    super();
    this.type += SetInitialSetupEvent.identifier;
  }
}
