import {BaseFormDI} from 'src/app/core/shared/base/base.di';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { Workmanship_Dto } from '../../../core/app-dto/workmanship.dto';

export class WorkmanshipFormDi extends BaseFormDI<Workmanship_Dto> {

  constructor(data: Workmanship_Dto = new Workmanship_Dto(null)) {
    super(new Workmanship_Dto(data));
    this.initForm();
  }

  getModel(): Workmanship_Dto {
    this.model.description = this.form.value.description;
    this.model.currency = this.form.value.currencySelectedValue;
    this.model.quantity = this.form.value.quantity;
    this.model.pricePerUnit = this.form.value.pricePerUnit;
    this.model.totalValue = this.form.value.pricePerUnit * this.form.value.quantity;
    this.model.totalValueHasVAT = this.form.value.totalValueHasVAT || false;

    return this.model;
  }

  initForm() {
    this.form = new FormGroup({
      description: new FormControl(this.model.description, Validators.compose([Validators.required])),
      quantity: new FormControl(this.model.quantity, Validators.compose([Validators.required])),
      pricePerUnit: new FormControl(this.model.pricePerUnit, Validators.compose([Validators.required])),
      currencySelectedValue: new FormControl(this.model.currency, Validators.compose([Validators.required])),
      totalValueHasVAT: new FormControl(this.model.totalValueHasVAT, Validators.compose([]))
    });
  }
}
