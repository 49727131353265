import { PagedResult } from "../../shared/datatable/datatable.helpers";
import { Action } from "@ngrx/store";
import {
  LoginEvent,
  AddAuthenticationToState,
  RemoveAuthenticationFromState,
  LogoutEvent,
  ForgotPasswordEvent,
  ResetPasswordEvent,
  LoadCurrentLoggedUser,
  RequestSubscriptionEvent,
  ActivateSubscriptionEvent, GenerateActivationLinkEvent,
} from "../events/authentication.events";
import {
  BASE_EXECUTING_STATE, BASE_FAILED_STATE,
  BASE_INITIAL_STATE,
  BASE_SUCCEEDED_STATE,
  BaseComponentState
} from "../utils/base-component.state";
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent, Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader, ResetSection,
  SeamlessEvent
} from "../events/base.events";
import {GenerateActivationLinkComponent} from "../../auth/components/generate-activation-link/generate-activation-link.component";

export interface AuthenticationState {
  loginState: BaseComponentState;
  forgotPasswordState: BaseComponentState,
  resetPasswordState: BaseComponentState,
  logoutState: BaseComponentState,
  requestSubscriptionState: BaseComponentState,
  activateSubscriptionState: BaseComponentState,
  generateActivationLinkState: BaseComponentState,
};

export const INITIAL_STATE: AuthenticationState = Object.assign({}, {
  loginState: BASE_INITIAL_STATE,
  forgotPasswordState: BASE_INITIAL_STATE,
  resetPasswordState: BASE_INITIAL_STATE,
  logoutState: BASE_INITIAL_STATE,
  requestSubscriptionState: BASE_INITIAL_STATE,
  activateSubscriptionState: BASE_INITIAL_STATE,
  generateActivationLinkState : BASE_INITIAL_STATE
});


export function reducer(state = INITIAL_STATE, action: Action): AuthenticationState {
  if (!action) return state;
  var actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {

    case Do_ResetStateForSection.identifier + ResetSection.Login: {
      state.loginState = BASE_INITIAL_STATE;
      return Object.assign({}, state);
    }
    case Do_ResetStateForSection.identifier + ResetSection.ResetPassword: {
      state.resetPasswordState = BASE_INITIAL_STATE;
      return Object.assign({}, state);
    }
    case Do_ResetStateForSection.identifier + ResetSection.ForgotPassword: {
      state.forgotPasswordState = BASE_INITIAL_STATE;
      return Object.assign({}, state);
    }
    case Do_ResetStateForSection.identifier + ResetSection.RequestSubscription: {
      state.requestSubscriptionState = BASE_INITIAL_STATE;
      return Object.assign({}, state);
    }
    case Do_ResetStateForSection.identifier + ResetSection.ActivateSubscription: {
      state.activateSubscriptionState = BASE_INITIAL_STATE;
      return Object.assign({}, state);
    }

    // Login

    case LoginEvent.identifier: {
      return Object.assign({}, state, { loginState: BASE_EXECUTING_STATE, logoutState: BASE_INITIAL_STATE });
    }

    case Do_GenericSuccessEvent.identifier + LoginEvent.identifier: {
      state.loginState = BASE_SUCCEEDED_STATE;
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + LoginEvent.identifier: {
      var newState = BASE_FAILED_STATE;
      newState.stateMessage = (<Do_GenericErrorEvent<string>>action).data;
      return Object.assign({}, state, { loginState: newState });
    }




    // Forgot Password

    case Do_GenericSuccessEvent.identifier + ForgotPasswordEvent.identifier: {
      state.forgotPasswordState = BASE_SUCCEEDED_STATE;
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + ForgotPasswordEvent.identifier: {
      var newState = BASE_FAILED_STATE;
      newState.stateMessage = (<Do_GenericErrorEvent<string>>action).data;
      return Object.assign({}, state, { forgotPasswordState: newState });
    }

    // /Forgot Password


    // Reset Password

    case Do_GenericSuccessEvent.identifier + ResetPasswordEvent.identifier: {
      state.resetPasswordState = BASE_SUCCEEDED_STATE;
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + ResetPasswordEvent.identifier: {
      var newState = BASE_FAILED_STATE;
      newState.stateMessage = (<Do_GenericErrorEvent<string>>action).data;
      return Object.assign({}, state, { resetPasswordState: newState });
    }

    // /Reset Password


    // Logout

    case Do_GenericSuccessEvent.identifier + LogoutEvent.identifier: {
      return Object.assign({}, state, {
        loginState: BASE_INITIAL_STATE,
        logoutState: BASE_SUCCEEDED_STATE
      });
    }

    // /Logout


    // Request Subscription

    case Do_GenericSuccessEvent.identifier + RequestSubscriptionEvent.identifier: {
      state.requestSubscriptionState = BASE_SUCCEEDED_STATE;
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + RequestSubscriptionEvent.identifier: {
      var newState = BASE_FAILED_STATE;
      newState.stateMessage = (<Do_GenericErrorEvent<string>>action).data;
      return Object.assign({}, state, { requestSubscriptionState: newState });
    }

    // /Requeste Subscription


    // Activate Subscription

    case Do_GenericSuccessEvent.identifier + ActivateSubscriptionEvent.identifier: {
      state.activateSubscriptionState = BASE_SUCCEEDED_STATE;
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + ActivateSubscriptionEvent.identifier: {
      var newState = BASE_FAILED_STATE;
      newState.stateMessage = (<Do_GenericErrorEvent<string>>action).data;
      return Object.assign({}, state, { activateSubscriptionState: newState });
    }

    // /Activate Subscription

          //Regenerate Activation Link

    // Activate Subscription

    case Do_GenericSuccessEvent.identifier + GenerateActivationLinkEvent.identifier: {
      state.generateActivationLinkState = BASE_SUCCEEDED_STATE;
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GenerateActivationLinkEvent.identifier: {
      var newState = BASE_FAILED_STATE;
      newState.stateMessage = (<Do_GenericErrorEvent<string>>action).data;
      return Object.assign({}, state, { generateActivationLinkState: newState });
    }


    // Regeneraate Activation Link
    default: {
      return state;
    }
  }
}

export const getLoginCallback = (state: AuthenticationState) => state.loginState;
export const getForgotPasswordCallback = (state: AuthenticationState) => state.forgotPasswordState;
export const getResetPasswordCallback = (state: AuthenticationState) => state.resetPasswordState;
export const getLogoutCallback = (state: AuthenticationState) => state.logoutState;
export const getRequestSubscriptionCallback = (state: AuthenticationState) => state.requestSubscriptionState;
export const getActivateSubscriptionCallback = (state: AuthenticationState) => state.activateSubscriptionState;
export const getGenerateActivationLinkComponentCallback = (state: AuthenticationState) => state.generateActivationLinkState;

