import {
    UIGeneratedEvent,
    EventWithScreenLoading,
    SeamlessEvent,
} from './base.events';
import {ActiveDataTableParameters, DataTableParameters} from '../../shared/datatable/datatable.helpers';
import {CarPart_Dto} from '../../app-dto/car-part.dto';

export class GetCarPartsPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'GetCarPartsPagedResultEvent';

    constructor(public parameters: ActiveDataTableParameters, public withReset: boolean = false, public supplierFilter: string) {
        super();
        this.type += GetCarPartsPagedResultEvent.identifier;
    }
}

export class GetCarPartStockAlertsPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'GetCarPartStockAlertsPagedResultEvent';

    constructor(public callback: Function, public parameters: ActiveDataTableParameters, public withReset: boolean = false) {
        super();
        this.type += GetCarPartStockAlertsPagedResultEvent.identifier;
    }
}

export class RegisterCarPartEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'RegisterCarPartEvent';

    constructor(public model: CarPart_Dto) {
        super();
        this.type += RegisterCarPartEvent.identifier;
    }
}

export class GetCarPartEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'GetCarPartEvent';

    constructor(public id: string) {
        super();
        this.type += GetCarPartEvent.identifier;
    }
}


export class EditCarPartEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'EditCarPartEvent';

    constructor(public model: CarPart_Dto) {
        super();
        this.type += EditCarPartEvent.identifier;
    }
}


export class DeleteCarPartEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'DeleteCarPartEvent';

    constructor(public id: string, public callback: Function = null) {
        super();
        this.type += DeleteCarPartEvent.identifier;
    }
}

export class ActivateCarPartEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'ActivateCarPartEvent';

    constructor(public id: string) {
        super();
        this.type += ActivateCarPartEvent.identifier;
    }
}

export class DeactivateCarPartEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'DeactivateCarPartEvent';

    constructor(public id: string) {
        super();
        this.type += DeactivateCarPartEvent.identifier;
    }
}

export class SearchCarPartEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = 'SearchCarPartEvent';

    constructor(public search: string, public callBack: Function, public displayOnlyAvailableItems: boolean = true) {
        super();
        this.type += SearchCarPartEvent.identifier;
    }
}

export class SearchCarPartForSupplyEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = 'SearchCarPartForSupplyEvent';

    constructor(public search: string, public supplierName: string, public callBack: Function) {
        super();
        this.type += SearchCarPartForSupplyEvent.identifier;
    }
}

export class GetExcelCarPartsEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'GetExcelCarPartsEvent';

    constructor(public supplierId: string) {
        super();
        this.type += GetExcelCarPartsEvent.identifier;
    }
}

export class GetExcelCarPartsImportTemplateEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'GetExcelCarPartsImportTemplateEvent';

    constructor() {
        super();
        this.type += GetExcelCarPartsImportTemplateEvent.identifier;
    }
}

export class ActivateCarPartStockAlertEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'ActivateCarPartStockAlertEvent';

    constructor(public id: string) {
        super();
        this.type += ActivateCarPartStockAlertEvent.identifier;
    }
}

export class DeactivateCarPartStockAlertEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'DeactivateCarPartStockAlertEvent';

    constructor(public id: string) {
        super();
        this.type += DeactivateCarPartStockAlertEvent.identifier;
    }
}

export class GetSupplyItemsForCarPartPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'GetSupplyItemsForCarPartPagedResultEvent';

    constructor(public carPartId: string, public parameters: DataTableParameters, public withReset: boolean = false, public includeUsedOnes: boolean) {
        super();
        this.type += GetSupplyItemsForCarPartPagedResultEvent.identifier;
    }
}

export class DeleteSupplyItemEvent extends EventWithScreenLoading implements UIGeneratedEvent {
    public static identifier: string = 'DeleteSupplyItemEvent';

    constructor(public supplyItemId: string) {
        super();
        this.type += DeleteSupplyItemEvent.identifier;
    }
}

export class GetTotalCarPartsValueEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = 'GetTotalCarPartsValueEvent';

    constructor() {
        super();
        this.type += GetTotalCarPartsValueEvent.identifier;
    }
}
