import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { ClientCarPartFormDi } from './client-car-part-form.di';
import { ClientCarPartFormSandbox } from './client-car-part-form.sandbox';
import { ClientCarParts_Dto } from '../../../core/app-dto/car-service.dto';
import {makeInputPositive} from '../../../core/shared/helpers/common.helpers';

@Component({
  selector: 'app-client-car-part-form',
  templateUrl: './client-car-part-form.component.html',
  styleUrls: ['./client-car-part-form.component.scss']
})
export class ClientCarPartFormComponent extends BaseFormModelComponent<ClientCarParts_Dto> implements OnInit, OnDestroy {

  @Output()
  formSaved = new EventEmitter<ClientCarParts_Dto>();

  @Input('withQuantity') withQuantity: boolean;

  constructor(public appState: Store<store.State>,
              public sandbox: ClientCarPartFormSandbox
  ) {
    super(sandbox, ResetSection.CarPart);
  }

  public diInitialize(data: ClientCarParts_Dto) {
    this.di = new ClientCarPartFormDi(data);
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();
    if (this.di.isValid()) {
      if (this.formSaved != null) {
        this.formSaved.emit(this.di.getModel());
      }
    }
  }

  isValid() {
    return this.di.isValid();
  }

  makeValuePositive(event: any) {
    makeInputPositive(event);
  }
}
