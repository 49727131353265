import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppUserBusinessRuleProvider} from "./business-rule-provider/app-user.business-rule.provider";
import {RulesGuard} from "./route-guards/rules.guard";
import {AuthGuard} from "./route-guards/auth.guard";
import {NotificationDialogComponent} from './dialog/notification-dialog/notification-dialog.component';
import {ConfirmationDialogComponent} from "./dialog/confirmation-dialog/confirmation-dialog.component";
import {ConfirmationDialogService} from "./dialog/confirmation-dialog.service";
import {NotFoundComponent} from './components/not-found/not-found.component';
import {UnauthorizedComponent} from './components/unauthorized/unauthorized.component';
import {SharedModule} from "../shared/shared.module";
import {RouterModule} from '@angular/router';
import {AuthModule} from "../auth/auth.module";
import {InvoiceModule} from 'src/app/invoice/invoice.module';
import {DateTimeService} from './services/date-time.service';
import {BsDatepickerModule, ModalModule, TimepickerModule} from 'ngx-bootstrap';
import {YesNoPipe} from '../pipes/yes-no.pipe';
import {CarServiceEntryInvoiceButtonComponent} from "./components/car-service-entry-invoice-button/car-service-entry-invoice-button.component";
import {CheckBusyIntervalComponent} from './components/check-busy-interval/check-busy-interval.component';
import {CheckBusyIntervalSandbox} from "./components/check-busy-interval/check-busy-interval.sandbox";
import {CheckMechanicPlatformBusyComponent} from './components/check-mechanic-platform-busy/check-mechanic-platform-busy.component';
import {CheckMechanicPlatformBusySandbox} from "./components/check-mechanic-platform-busy/check-mechanic-platform-busy.sandbox";
import {PaymentSuccessfulComponent} from "./components/payment-successful/payment-successful.component";
import {PaymentFailedComponent} from "./components/payment-failed/payment-failed.component";
import {StripeIntegrationModule} from "../stripe-integration/stripe-integration.module";
import {AddPaymentMethodComponent} from "./components/add-payment-method/add-payment-method.component";
import {AddPaymentMethodSandbox} from "./components/add-payment-method/add-payment-method.sandbox";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {StickyHeaderNotificationComponent} from './components/sticky-header-notification/sticky-header-notification.component';
import {StickyHeaderNotificationSandbox} from "./components/sticky-header-notification/sticky-header-notification.sandbox";
import {RtcService} from "./services/rtc.service";
import { NotificationsComponent } from './components/notifications/notifications.component';
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {NotificationsSandbox} from "./components/notifications/notifications.sandbox";

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    NotificationDialogComponent,
    NotFoundComponent,
    UnauthorizedComponent,
    YesNoPipe,
    CarServiceEntryInvoiceButtonComponent,
    CheckBusyIntervalComponent,
    CheckMechanicPlatformBusyComponent,
    PaymentFailedComponent,
    PaymentSuccessfulComponent,
    AddPaymentMethodComponent,
    StickyHeaderNotificationComponent,
    NotificationsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    AuthModule,
    InvoiceModule,
    ModalModule,
    BsDatepickerModule,
    TimepickerModule,
    StripeIntegrationModule,
    ReactiveFormsModule,
    FormsModule,
    InfiniteScrollModule
  ],
  entryComponents: [
    NotificationDialogComponent,
    ConfirmationDialogComponent,
    AddPaymentMethodComponent
  ],
  exports: [
    NotificationDialogComponent,
    ConfirmationDialogComponent,
    YesNoPipe,
    CarServiceEntryInvoiceButtonComponent,
    CheckBusyIntervalComponent,
    CheckMechanicPlatformBusyComponent,
    AddPaymentMethodComponent,
    StickyHeaderNotificationComponent,
    NotificationsComponent
  ],
  providers: [
    AppUserBusinessRuleProvider,
    RulesGuard,
    AuthGuard,
    ConfirmationDialogService,
    DateTimeService,
    CheckBusyIntervalSandbox,
    AddPaymentMethodSandbox,
    CheckMechanicPlatformBusySandbox,
    StickyHeaderNotificationSandbox,
    RtcService,
    NotificationsSandbox
    ]
})
export class CoreModule {
}
