import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseFormModelComponent} from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import {Store} from '@ngrx/store';
import {ResetSection} from 'src/app/core/app-store/events/base.events';
import {StaticDataSelectorInput} from '../../../core/shared/components/static-data-selector/static-data-selector.component';
import {CarPartFormDi} from './car-part-form.di';
import {CarPartFormSandbox} from './car-part-form.sandbox';
import {CarPart_Dto} from '../../../core/app-dto/car-part.dto';
import {SaveSupplierModalComponent} from '../../../supply/components/save-supplier-modal/save-supplier-modal.component';
import {Supplier_Dto} from '../../../core/app-dto/supplier.dto';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {BsModalService} from 'ngx-bootstrap';
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";
import {isValidObject, makeInputPositive} from '../../../core/shared/helpers/common.helpers';

@Component({
  selector: 'app-car-part-form',
  templateUrl: './car-part-form.component.html',
  styleUrls: ['./car-part-form.component.scss']
})
export class CarPartFormComponent extends BaseFormModelComponent<CarPart_Dto> implements OnInit, OnDestroy {
  public acquisitionPriceCurrenciesDataInput: StaticDataSelectorInput;
  public sellingPriceCurrenciesDataInput: StaticDataSelectorInput;
  public acquisitionVatsDataInput: StaticDataSelectorInput;
  public sellingVatsDataInput: StaticDataSelectorInput;
  public di: CarPartFormDi;
  @Output()
  formSaved = new EventEmitter<CarPart_Dto>();

  @Input('withQuantity') withQuantity: boolean;
  @Input('withQuantityValidation') withQuantityValidation: boolean = true;
  private editableCarPartValue:boolean = false;
  @Input('editableCarPart') set editableCarPart(value:boolean){
    this.editableCarPartValue =value;
    if(isValidObject(this.di) && isValidObject(this.di.model)){
      this.dataLoaded=false;
      setTimeout(()=>{
        this.diInitialize(this.di.model);
      },0);

    }
  }

  constructor(public appState: Store<store.State>,
              private modalService: BsModalService,
              public rulesProvider: AppUserBusinessRuleProvider,
              public sandbox: CarPartFormSandbox) {
    super(sandbox, ResetSection.CarPart);
  }

  public diInitialize(data: CarPart_Dto) {
    this.di = new CarPartFormDi(data, this.editableCarPartValue, this.withQuantityValidation);
    this.acquisitionPriceCurrenciesDataInput = {
      form: this.di.form,
      type: 'currencies',
      formControlName: 'acquisitionPriceCurrency',
      isRequired: true,
      label: 'Moneda',
      isReadOnly: true
    };
    this.sellingPriceCurrenciesDataInput = {
      form: this.di.form,
      type: 'currencies',
      formControlName: 'sellingPriceCurrency',
      isRequired: true,
      label: 'Moneda',
      isReadOnly: true
    };
    this.acquisitionVatsDataInput = {
      form: this.di.form,
      type: 'supplyVats',
      formControlName: 'acquisitionVatValue',
      isRequired: true,
      label: 'Cota TVA',
      isReadOnly: this.editableCarPartValue == false
    };
    this.sellingVatsDataInput = {
      form: this.di.form,
      type: 'supplyVats',
      formControlName: 'sellingVatValue',
      isRequired: true,
      label: 'Cota TVA',
      isReadOnly: false
    };
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();
    if (this.di.isValid()) {
      if (this.formSaved != null) {
        this.formSaved.emit(this.di.getModel());
      }
    }
  }

  isValid() {
    return this.di.isValid();
  }

  addSupplierPromise(name) {
    return new Promise((resolve) => {
      resolve({name: name, valid: true});
    });
  }

  addNewSupplier() {
    const modalRef = this.modalService.show(SaveSupplierModalComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-xl'
    });
    modalRef.content.dataInput = {};
    modalRef.content.onClose.subscribe((data: Supplier_Dto) => {
      if (ObjectValidators.isValidObject(data) && data) {
        this.setSelectedSupplier(data);
        this.sandbox.items = [...this.sandbox.items, data];
        this.di.form.controls.supplier.setValue(data);
      }
    });
  }

  setSelectedSupplier(supplier) {
    // this.computedRegComCifCnp = '';
    // this.computedAddress = '';
    // if (supplier) {
    //   this.computedAddress = StringHelpers.computeAddress(supplier.address);
    //   let delimiter = '';
    //   if (supplier.regCom) {
    //     this.computedRegComCifCnp = `${delimiter}${supplier.regCom}`;
    //     delimiter = ', ';
    //   }
    //   if (supplier.cifCnp) {
    //     this.computedRegComCifCnp += `${delimiter}${supplier.cifCnp}`;
    //   }
    // }
    // this.selectedSupplier = supplier;
  }

  makeValuePositive(event: any) {
    makeInputPositive(event);
  }
}
