import {SeamlessEvent, UIGeneratedEvent} from "./base.events";
import {StaticData} from "../../app-dto/static-data.dto";

export class DoAddStaticDataToState extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "DoAddStaticDataToState";

  constructor(public staticData: StaticData) {
    super();
    this.type += DoAddStaticDataToState.identifier;
  }
}

export class DoLoadStaticData extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "DoLoadStaticData";

  constructor() {
    super();
    this.type += DoLoadStaticData.identifier;
  }
}

export class DoRemoveStaticDataFromState extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "DoRemoveStaticDataFromState";

  constructor() {
    super();
    this.type += DoRemoveStaticDataFromState.identifier;
  }
}

export class DoGetVersion extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "DoGetVersion";

  constructor() {
    super();
    this.type += DoGetVersion.identifier;
  }
}
