import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {SearchOrganizationClientActionProxy} from './search-organization-client.action-proxy';
import {OrganizationClientSearchSandbox} from './organization-client-search.sandbox';
import {BaseWebComponent} from 'src/app/core/shared/base/base.component';
import {IdName_Dto} from 'src/app/core/app-dto/misc.dto';
import {OrganizationClient_Dto} from 'src/app/core/app-dto/organization-client.dto';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {SearchComponent} from 'src/app/core/shared/components/search/search.component';
import {isValidArrayAndHasElements, isValidObject, isValidString} from "../../../core/shared/helpers/common.helpers";
import * as escapeStringRegexp from "escape-string-regexp";
import {GetOrganizationClientActionProxy} from '../organization-client-details/get-organization-client.action-proxy';

@Component({
    selector: 'app-organization-client-search',
    templateUrl: './organization-client-search.component.html',
    styleUrls: ['./organization-client-search.component.scss']
})
export class OrganizationClientSearchComponent extends BaseWebComponent implements OnInit, OnDestroy {
    public searchOrganizationClientAP: SearchOrganizationClientActionProxy;
    public getOrganizationClientAP: GetOrganizationClientActionProxy;

    public items: Array<IdName_Dto> = [];
    public isLoading: boolean = false;

    public selectedItemValue: IdName_Dto = null;

    @Output() itemSelected = new EventEmitter<OrganizationClient_Dto>();
    @Output() inputValue = new EventEmitter<string>();

    @ViewChild(SearchComponent, {static: false}) searchComponent: SearchComponent;

    @Input() error: boolean = false;
    @Input() allowInput: boolean = false;
    @Input() willFetchClient: boolean = true;

    @Input('search') set searchValue(value: string) {
        if (ObjectValidators.isValidString(value)) {
            this.search(value);
            this.selectedItemValue = new IdName_Dto({id: undefined, name: value});
        }
    }

    @Input('clientId') set carIdValue(value: string) {
        if (ObjectValidators.isValidString(value) && this.willFetchClient) {
            this.getOrganizationClientAP.execute(value, (data: OrganizationClient_Dto) => {
                this.itemSelected.emit(data);
                this.selectedItemValue = new IdName_Dto({id: data.id, name: data.name});
            });
        }
    }

    @Input('companyAnaf') set companyAnaf(value: OrganizationClient_Dto) {
        if (ObjectValidators.isValidObject(value)) {
            if (ObjectValidators.isValidString(value.name)) {
                this.selectedItemValue = new IdName_Dto({id: '', name: value.name});
                this.searchOrganizationClientAP.execute(value.name, () => {
                    const data = this.sandbox.originalOrganizationClientSearchList.find(r => escapeStringRegexp(r.name.trim()) === escapeStringRegexp(value.name.trim()));
                    if (ObjectValidators.isValidObject(data)) {
                        this.itemSelected.emit(data);
                    } else {
                        this.itemSelected.emit(value);
                    }
                });
            }
        }
    }

    constructor(
        public sandbox: OrganizationClientSearchSandbox
    ) {
        super(sandbox);
        this.searchOrganizationClientAP = new SearchOrganizationClientActionProxy(this, sandbox.appState);
        this.getOrganizationClientAP = new GetOrganizationClientActionProxy(this, sandbox.appState);
    }

    ngOnInit() {
        this.items = [];
        if (!isValidObject(this.selectedItemValue))
            this.search('');
        this.initialize((data: Array<IdName_Dto>) => {
            this.items = data;

            if (ObjectValidators.isValidObject(this.selectedItemValue)) {
                if (ObjectValidators.isValidArray(this.items)) {
                    const found = this.items.find(x => x.name == this.selectedItemValue.name);
                    if (!found) {
                        this.items.push(this.selectedItemValue);
                    } else {
                        this.selectedItemValue.id = found.id;
                    }
                } else {
                    this.items = [this.selectedItemValue];
                }
            }
            this.dataLoaded = true;
        });
    }

    ngOnDestroy() {
        this.destroy();
    }

    public search(value: string) {
        this.isLoading = true;
        if (isValidString(value)) {
            value = escapeStringRegexp(value.trim());
        }
        this.searchOrganizationClientAP.execute(value, () => {
            this.isLoading = false;
        });
    }

    public selected(value: IdName_Dto) {
        if (ObjectValidators.isValidObject(value)) {
            let data = this.sandbox.originalOrganizationClientSearchList.find(r => r.id == value.id);
            if (this.itemSelected != null) {
                if (isValidObject(data) == false) data = new OrganizationClient_Dto({name: value.name});
                this.itemSelected.emit(data);
            }
        } else {
            if (this.itemSelected != null) {
                this.itemSelected.emit(null);
                this.search('');
            }
        }
    }

    clearSelect() {
        this.searchComponent.clearSelect();
    }

    inputChanged(value: string) {
        if (this.inputValue != null) {
            this.inputValue.emit(value);
        }
    }
}
