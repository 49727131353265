import {SeamlessEvent, UIGeneratedEvent} from "./base.events";
import {FileAttachment, FileToUpload} from "../../app-dto/core.dto";
import {UploadInput} from '../../upload/components/upload-notification/upload-notification.component';

export class DoUploadFilesEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "DoUploadFilesEvent";

  constructor(public files: Array<FileToUpload>) {
    super();
    this.type += DoUploadFilesEvent.identifier;
  }
}

export class NoInternetConnection extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "NoInternetConnection";

  constructor(public payload: any = null) {
    super();
    this.type += NoInternetConnection.identifier;
  }
}

export class DoPublishDocument extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "DoPublishDocument";

  constructor(public uuId: string, public fileId: string) {
    super();
    this.type += DoPublishDocument.identifier;
  }
}

export class DoCancelUploadingFile extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "DoCancelUploadingFile";

  constructor(public file: FileToUpload) {
    super();
    this.type += DoCancelUploadingFile.identifier;
  }
}

export class DoCancelUploadingTaskFile extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "DoCancelUploadingTaskFile";

  constructor(public file: FileToUpload) {
    super();
    this.type += DoCancelUploadingTaskFile.identifier;
  }
}

export class UploadInputEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "UploadInputEvent";

  constructor(public uploadInput: UploadInput) {
    super();
    this.type += UploadInputEvent.identifier;
  }
}

export class UploadInputFinishedEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "UploadInputFinishedEvent";

  constructor(public fileUploaded: FileAttachment) {
    super();

    this.type += UploadInputFinishedEvent.identifier;
  }
}
