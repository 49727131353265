import {Action} from '@ngrx/store';

export interface DataGeneratedEvent extends Action {
  //defines actions that are generated from model proxies
}

export interface UIGeneratedEvent extends Action {
  //defines actions initiated by user interactions
}

export class EventWithScreenLoading implements Action {
  public static identifier: string = "EventWithScreenLoading";
  type = EventWithScreenLoading.identifier;
}

export class EventWithStopingScreenLoader implements Action {
  public static identifier: string = "EventWithStopingScreenLoader";
  type = EventWithStopingScreenLoader.identifier;
}

export class SeamlessEvent implements Action {
  public static identifier: string = "SeamlessEvent";
  type = SeamlessEvent.identifier;
}

export class DoLoadRulesForUser extends SeamlessEvent {
  public static identifier: string = "DoLoadRulesForUser";
  type = DoLoadRulesForUser.identifier;
}

export class DoClearRulesForUser extends SeamlessEvent {
  public static identifier: string = "DoClearRulesForUser";
  type = DoClearRulesForUser.identifier;

  constructor(public payload: any = null) {
    super();
  }
}

export class Do_GenericSuccessEvent<T> implements Action {
  public static identifier: string = "Do_GenericSuccessEvent";
  type = Do_GenericSuccessEvent.identifier;
  public data: T;

  constructor(data: T, action: Action) {
    this.data = data;
    if (action.type.indexOf(EventWithScreenLoading.identifier) > -1) {
      this.type += EventWithStopingScreenLoader.identifier;
      this.type += action.type.replace(EventWithScreenLoading.identifier, '');
    } else {
      if (action.type.indexOf(SeamlessEvent.identifier) > -1) {
        this.type += action.type.replace(SeamlessEvent.identifier, '');
      }
      else this.type += action.type;
    }
  }
}

export class Do_GenericErrorEvent<T> implements Action {
  public static identifier: string = "Do_GenericErrorEvent";
  type = Do_GenericErrorEvent.identifier;
  public data: T

  constructor(data: T, action: Action) {
    this.data = data;
    if (action.type.indexOf(EventWithScreenLoading.identifier) > -1) {
      this.type += EventWithStopingScreenLoader.identifier;
      this.type += action.type.replace(EventWithScreenLoading.identifier, '');
    } else {
      if (action.type.indexOf(SeamlessEvent.identifier) > 1) {
        this.type += action.type.replace(SeamlessEvent.identifier, '');
      }
      else this.type += action.type;
    }
  }
}


export enum ResetSection {
  Login,
  ForgotPassword,
  ResetPassword,
  Dashboard,
  RequestSubscription,
  ActivateSubscription,
  GenerateActivationLink,
  RequestedSubscriptions,
  SubscriptionDetails,
  SubscriptionAddons,
  SubscriptionSettings,
  Subscriptions,
  EditSubscription,
  RegisterSubscription,
  ServicePlatformDetails,
  ServicePlatforms,
  SaveServicePlatform,
  Mechanics,
  MechanicDetails,
  SaveMechanic,
  Users,
  UserDetails,
  SaveUser,
  Profile,
  Invoice,
  Invoices,
  CarPart,
  CarParts,
  CarPartStockAlerts,
  Car,
  Cars,
  Calendar,
  Suppliers,
  OrganizationClient,
  OrganizationClients,
  Draft,
  Drafts,
  CarService,
  CarServices,
  ServicePlatformStatuses,
  ServiceDetails,
  Audit,
  Department,
  Departments,
  Reporting,
  OrganizationCosts,
  OrganizationCost,
  Sms,
  CarWork,
  Supply,
  Supplies,
  SupplieswithUnpaidInvoice,
  CarForClient,
  CashRegisters,
  CashRegisterDetails,
  SaveCashRegister,
  SavePrintJob,
  Notifications,
  Offer,
  SuperAdminLogs,
  ClientForCar,
  Receipt
}

export class Do_ResetStateForSection extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "Do_ResetStateForSection";

  constructor(public section: ResetSection) {
    super();
    this.type += Do_ResetStateForSection.identifier;
    this.type += section;
  }
}
