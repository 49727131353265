import {BaseDI, BaseFormDI} from 'src/app/core/shared/base/base.di';
import { ResetPassword_Dto } from 'src/app/core/app-dto/authentication.dto';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "ngx-custom-validators";

export class ResetPasswordDI extends BaseFormDI<ResetPassword_Dto> {

    constructor(data: ResetPassword_Dto = new ResetPassword_Dto('', '', '')) {
        super(data);
        this.initForm();
    }

    getModel(): ResetPassword_Dto {
      this.model.password = this.form.value.password;
        return this.model;
    }

  initForm() {
    let password = new FormControl('', Validators.compose([Validators.required]));
    let confirmPassword = new FormControl('', Validators.compose([Validators.required]));

    this.form = new FormGroup({
      password: password,
      confirmPassword: confirmPassword
    });
  }
}
