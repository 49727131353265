import {AzureUploaderService} from "./azure-uploader.service";
import {FileAttachment, FileToUpload} from "../../../app-dto/core.dto";
import {NgZone} from "@angular/core";

export class DIUploadItem {
  public hasError: boolean = false;
  public isUploading: boolean = false;

  public progressValue: number = 0;
  public fileUploaded: FileAttachment = null;

  constructor(public file: FileToUpload, private service: AzureUploaderService, private completeCallback: Function, private ngZone:NgZone) {
    //this.initializeUpload();
  }

  public initializeUpload() {
    var self = this;
    self.isUploading = true;
    console.log("initializeUpload: " + NgZone.isInAngularZone());
    this.service.uploadToStorage(this.file).subscribe((progress) => {
      console.log("uploadToStorage: " + NgZone.isInAngularZone());
      this.ngZone.run(()=>{
        console.log("uploadToStorage: IN " + NgZone.isInAngularZone());
        if (progress == -1) {
          //something went wrong
          this.hasError = true;
          this.completeCallback(this);
        } else {
          this.progressValue = progress;
          if (progress == 100) {
            var subscription = this.service.publishDocument(this.file.uuId).subscribe((result: FileAttachment) => {
              this.fileUploaded = result;
              self.isUploading = false;
              this.completeCallback(this);
              if (subscription != null) subscription.unsubscribe();
            });
          }
        }
      });
    });
  }

  cancelItem() {
    if (this.progressValue != 100) {
      this.service.cancel();
    }
  }

}
export class FileWrapper {
  public id: string;
  public file: File;
}
