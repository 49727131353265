import { Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import { map, switchMap } from 'rxjs/operators';
import { Do_GenericSuccessEvent, Do_GenericErrorEvent, EventWithScreenLoading, SeamlessEvent } from 'src/app/core/app-store/events/base.events';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { CarPart_Dto, CarPartAggregate_Dto } from 'src/app/core/app-dto/car-part.dto';
import {
    GetCarPartEvent,
    SearchCarPartEvent
} from '../../core/app-store/events/car-part.events';
import { CarPartApiService } from '../../core/api-integration/services/car-part-api.service';


@Injectable()
export class WorkmanshipUIEventProcessor {

    constructor(private actions$: Actions,
                private appState$: Store<store.State>,
                private carPartApiService: CarPartApiService) {
    }

    @Effect()
    getCarPart$: Observable<any> = this.actions$
        .pipe(
            ofType(GetCarPartEvent.identifier, EventWithScreenLoading.identifier + GetCarPartEvent.identifier),
            map((action: GetCarPartEvent) => action),
            switchMap((action: GetCarPartEvent) => {
                return new Observable((observer) => {
                    this.carPartApiService.u_getCarPart(action.id)
                        .subscribe((res: CarPartAggregate_Dto) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<CarPartAggregate_Dto>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );

    // @Effect()
    // doSearchWorkmanships$: Observable<any> = this.actions$
    //     .pipe(
    //         ofType(SearchCarPartEvent.identifier, SeamlessEvent.identifier + SearchCarPartEvent.identifier),
    //         map((action: SearchCarPartEvent) => action),
    //         switchMap((action: SearchCarPartEvent) => {
    //             return new Observable((observer) => {
    //                 this.carPartApiService.u_searchCarParts(action.search)
    //                     .subscribe((res: Array<CarPart_Dto>) => {
    //                         if (ObjectValidators.isValidObject(res)) {
    //                             observer.next(new Do_GenericSuccessEvent<Array<CarPart_Dto>>(res, action));
    //                         }
    //                         action.callBack();
    //                         observer.complete();
    //                     }, (error: string) => {
    //                         observer.next(new Do_GenericErrorEvent<string>(error, action));
    //                         action.callBack();
    //                         observer.complete();
    //                     });
    //             });
    //         })
    //     );
}
