import { PagedResult } from '../../shared/datatable/datatable.helpers';
import { Action } from '@ngrx/store';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader,
  ResetSection,
  SeamlessEvent
} from '../events/base.events';
import { ResetStateActionProxy } from '../../shared/base/base.action-proxy';
import {Mechanic_Dto, MechanicWithActionsAndCar_Dto} from '../../app-dto/mechanic.dto';
import {
  ActivateMechanicEvent, DeactivateMechanicEvent,
  DeleteMechanicEvent, EditMechanicEvent,
  GetMechanicEvent,
  GetMechanicsPagedResultEvent,
  RegisterMechanicEvent,
  GetMechanicListEvent, AddUserAccountForMechanicEvent, DeleteAccountForMechanicEvent, GetMechanicsWithStatusEvent
} from '../events/mechanic.events';
import * as moment from 'moment';
import {ServicePlatform_Dto} from "../../app-dto/service-platform.dto";
import {ObjectValidators} from "../../shared/object.validators";

export interface MechanicState {
  lastRefreshTime: number;
  mechanic: Mechanic_Dto;
  mechanicsPagedResult: PagedResult<Mechanic_Dto>;
  mechanicsList: Array<Mechanic_Dto>;
  mechanicsWithStatusList : Array<MechanicWithActionsAndCar_Dto>
}

export const INITIAL_STATE: MechanicState = Object.assign({}, {
  lastRefreshTime: 0,
  mechanic: null,
  mechanicsPagedResult: new PagedResult<Mechanic_Dto>([]),
  mechanicsList: [],
  mechanicsWithStatusList : []
});


export function reducer(state = INITIAL_STATE, action: Action): MechanicState {
  if (!action) return state;
  let actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {
    // Get Mechanic

    case Do_ResetStateForSection.identifier + ResetSection.MechanicDetails: {
      return Object.assign({}, state, { mechanic: null });
    }

    case Do_GenericSuccessEvent.identifier + GetMechanicEvent.identifier: {
      const mechanicData = new Mechanic_Dto((action as Do_GenericSuccessEvent<Mechanic_Dto>).data);
      return Object.assign({}, state, { mechanic: mechanicData });
    }

    case Do_GenericErrorEvent.identifier + GetMechanicEvent.identifier: {
      return Object.assign({}, state, { mechanic: null });
    }

    case ResetStateActionProxy.name + GetMechanicEvent.identifier: {
      return Object.assign({}, state, { mechanic: null });
    }

    // /Get Mechanic

    case Do_GenericSuccessEvent.identifier + RegisterMechanicEvent.identifier: {
      return Object.assign({}, state, { lastRefreshTime: moment().unix() });
    }

    case Do_GenericSuccessEvent.identifier + DeleteMechanicEvent.identifier: {
      return Object.assign({}, state, { lastRefreshTime: moment().unix() });
    }

    // Get Mechanics

    case Do_ResetStateForSection.identifier + ResetSection.Mechanics: {
      return Object.assign({}, state, { mechanicsPagedResult: new PagedResult<Mechanic_Dto>([]) });
    }

    case GetMechanicsPagedResultEvent.identifier: {
      if ((action as GetMechanicsPagedResultEvent).withReset) {
        state.mechanicsPagedResult = new PagedResult<Mechanic_Dto>([]);
        return Object.assign({}, state);
      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetMechanicsPagedResultEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<PagedResult<Mechanic_Dto>>).data;
      state.mechanicsPagedResult = new PagedResult<Mechanic_Dto>(data);
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetMechanicsPagedResultEvent.identifier: {
      state.mechanicsPagedResult = new PagedResult<Mechanic_Dto>([]);
      return Object.assign({}, state);
    }
    // /Get Mechanics

    case Do_GenericSuccessEvent.identifier + GetMechanicsWithStatusEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<Array<MechanicWithActionsAndCar_Dto>>).data;
      state.mechanicsWithStatusList = data.map(r => new MechanicWithActionsAndCar_Dto(r));
      return Object.assign({}, state);
    }

    // Get Mechanics List
    case Do_GenericSuccessEvent.identifier + GetMechanicListEvent.identifier: {
      const data = (action as Do_GenericSuccessEvent<Array<Mechanic_Dto>>).data;
      state.mechanicsList = data.map(r => new Mechanic_Dto(r));
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetMechanicListEvent.identifier: {
      state.mechanicsList = [];
      return Object.assign({}, state);
    }
    // /Get Mechanics List
    case Do_GenericSuccessEvent.identifier + AddUserAccountForMechanicEvent.identifier:
    case Do_GenericSuccessEvent.identifier + DeleteAccountForMechanicEvent.identifier:
    case Do_GenericSuccessEvent.identifier + ActivateMechanicEvent.identifier:
    case Do_GenericSuccessEvent.identifier + DeactivateMechanicEvent.identifier:
    case Do_GenericSuccessEvent.identifier + EditMechanicEvent.identifier: {
      const data = new Mechanic_Dto((action as Do_GenericSuccessEvent<Mechanic_Dto>).data);
      if(ObjectValidators.isValidObject(state.mechanic) && state.mechanic.id == data.id) {
        state.mechanic = data;
      }
      state.lastRefreshTime =  moment().unix();
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + AddUserAccountForMechanicEvent.identifier:
    case Do_GenericSuccessEvent.identifier + DeleteAccountForMechanicEvent.identifier:
    case Do_GenericSuccessEvent.identifier + DeleteMechanicEvent.identifier: {
      return Object.assign({}, state, { lastRefreshTime: moment().unix() });
    }

    default: {
      return state;
    }
  }
}

export const getTimestampCallback = (state: MechanicState) => state.lastRefreshTime;
export const getMechanicCallback = (state: MechanicState) => state.mechanic;
export const getMechanicsPagedResultCallback = (state: MechanicState) => state.mechanicsPagedResult;
export const getMechanicsListCallback = (state: MechanicState) => state.mechanicsList;
export const getMechanicsWithStatusListCallback = (state: MechanicState) => state.mechanicsWithStatusList;
