import { State } from '../state';
import * as fromCore from '../stores/core.store';
import {createSelector} from "@ngrx/store";

export const getCoreState = (state: State) => state.core;
export const getLoadingState = createSelector(getCoreState, fromCore.getLoadingCallback);
export const getLoggedUser = createSelector(getCoreState, fromCore.getLoggedUserCallback);
export const getCurrentUserRules = createSelector(getCoreState, fromCore.getCurrentUserRulesCallback);
export const getStickyHeaderNotifications = createSelector(getCoreState, fromCore.getStickyHeaderNotificationsCallback);
export const getAppNotifications = createSelector(getCoreState, fromCore.getNotificationsCallback);
export const getUnreadAppNotificationsCount = createSelector(getCoreState, fromCore.getUnreadNotificationsCountCallback);
export const getVersion = createSelector(getCoreState, fromCore.getVersionCallback);
