import {BaseDI, BaseSelectWrapperDI} from 'src/app/core/shared/base/base.di';
import { OrganizationClient_Dto } from '../../../core/app-dto/organization-client.dto';

export class OrganizationClientsListItemDi extends BaseSelectWrapperDI<OrganizationClient_Dto> {
    constructor(data: any) {
        super(new OrganizationClient_Dto(data));
    }

    getModel(): OrganizationClient_Dto {
        return this.model;
    }
}
