import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs";
import {BsModalRef} from "ngx-bootstrap";

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {

  public title: string;
  public message: string;
  public btnOkText: string;

  public onClose: Subject<boolean>;

  constructor(private activeModal: BsModalRef) {
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  public accept() {
    this.onClose.next(true);
    this.activeModal.hide();
  }

  public dismiss() {
    this.onClose.next(true);
    this.activeModal.hide();
  }
}
