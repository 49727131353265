import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {BaseFormComponent} from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import {Store} from '@ngrx/store';
import {ResetSection} from 'src/app/core/app-store/events/base.events';
import {ActivatedRoute} from '@angular/router';
import {BsModalRef} from 'ngx-bootstrap';
import {DateTimeService} from 'src/app/core/core/services/date-time.service';
import {IdName_Dto} from 'src/app/core/app-dto/misc.dto';
import {NgxSpinnerService} from 'ngx-spinner';
import {WizardComponent} from 'angular-archwizard';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {EditCarInServiceActionProxy} from './edit-car-in-service.action-proxy';
import {AddEditCarInServiceWizardDi} from './add-edit-car-in-service-wizard.di';
import {AddEditCarInServiceWizardSandbox} from './add-edit-car-in-service-wizard.sandbox';
import {CarServiceCarComponent} from '../car-service-car/car-service-car.component';
import {CarServiceClientComponent} from '../car-service-client/car-service-client.component';
import {OrganizationClient_Dto} from '../../../core/app-dto/organization-client.dto';
import {CarService_Dto} from '../../../core/app-dto/car-service.dto';
import {RegisterCarInServiceActionProxy} from './register-car-in-service.action-proxy';
import {CarServicePhotosComponent, FileImageWrapper} from '../car-service-photos/car-service-photos.component';
import {FileAttachment, FileToUpload, FileUploadTarget} from '../../../core/app-dto/core.dto';
import {UploadInput} from '../../../core/upload/components/upload-notification/upload-notification.component';
import {FileWrapper} from '../../../core/upload/components/upload-notification/upload-notification.di';
import {CarServiceCarWorksComponent} from '../car-service-car-works/car-service-car-works.component';
import {SaveDraftActionProxy} from "../../common/di/save-draft.action-proxy";
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";
import {ResetStateActionProxy} from '../../../core/shared/base/base.action-proxy';


@Component({
  selector: 'app-add-edit-car-in-service-wizard',
  templateUrl: './add-edit-car-in-service-wizard.component.html',
  styleUrls: ['./add-edit-car-in-service-wizard.component.scss']
})
export class AddEditCarInServiceWizardComponent extends BaseFormComponent implements OnInit, OnDestroy {
  public registerCarInServiceAP: RegisterCarInServiceActionProxy;
  public editCalendarEventAP: EditCarInServiceActionProxy;
  public saveDraftAP: SaveDraftActionProxy;

  public onClose: Subject<boolean>;

  public carService: CarService_Dto = null;
  public appointmentId: string;
  public title: string = '';
  public calendarEventTypes: Array<IdName_Dto> = [];
  public files: Array<FileWrapper>;
  public imgUrls: Array<FileImageWrapper> = [];
  public initialStep: number = 0;
  public step: number = 0;
  public stepMin: number = 0;
  public stepMax: number = 3;
  public isClientStepValid = true;
  public isCarStepValid = true;
  public isCarWorksStepValid = true;
  public isAppointmentDetailsStepValid = true;
  public isSummary = false;
  public isFromAppointment = false;

  public model: CarService_Dto;

  public di: AddEditCarInServiceWizardDi;//we need to overwrite di with specific type for production build

  @ViewChild(WizardComponent, {static: true})
  public wizard: WizardComponent;

  @ViewChild(CarServiceCarComponent, {static: false})
  private carServiceCarComponent: CarServiceCarComponent;

  @ViewChild(CarServiceClientComponent, {static: false})
  private carServiceClientComponent: CarServiceClientComponent;
  @ViewChild(CarServiceCarWorksComponent, {static: false})
  private carServiceCarWorksComponent: CarServiceCarWorksComponent;

  @ViewChild(CarServicePhotosComponent, {static: false}) carServicePhotosComponent: CarServicePhotosComponent;

  constructor(
    public appState: Store<store.State>,
    public sandbox: AddEditCarInServiceWizardSandbox,
    public activatedRoute: ActivatedRoute,
    private activeModal: BsModalRef,
    public dateTimeService: DateTimeService,
    private spinner: NgxSpinnerService,
    public rulesProvider: AppUserBusinessRuleProvider
  ) {
    super(sandbox, ResetSection.SaveServicePlatform);
    this.registerCarInServiceAP = new RegisterCarInServiceActionProxy(this, sandbox.appState, spinner);
    this.editCalendarEventAP = new EditCarInServiceActionProxy(this, sandbox.appState, spinner);
    this.saveDraftAP = new SaveDraftActionProxy(this, sandbox.appState);
    this.di = new AddEditCarInServiceWizardDi(this.dateTimeService);
    this.model = new CarService_Dto(null);
  }

  ngOnInit() {
    this.di = new AddEditCarInServiceWizardDi(this.dateTimeService, this.model);
    if (this.carService)
      this.di = new AddEditCarInServiceWizardDi(this.dateTimeService, this.carService);

    if (this.di.model.id) {
      this.title = 'Modifica masina in service';
    } else {
      this.title = 'Adauga masina in service';
    }

    this.onClose = new Subject();
    this.dataLoaded = true;

    if (this.initialStep > 0) {
      this.step = this.initialStep;
      this.wizard.goToStep(this.initialStep);
    }

    this.initialize();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    const resetClientForCarAP = new ResetStateActionProxy(this.sandbox.appState, ResetSection.ClientForCar);
    resetClientForCarAP.execute();
    this.onClose.next(false);
    this.activeModal.hide();
  }

  clientDetailsFormSaved(data: OrganizationClient_Dto) {
    if (ObjectValidators.isValidObject(data))
      this.model.client = data;
  }

  carDetailsFormSaved(data: CarService_Dto) {
    if (ObjectValidators.isValidObject(data)) {
      this.model.car = data.car;
      this.model.carServiceEntryData.actualNoOfKm = data.carServiceEntryData != null ? data.carServiceEntryData.actualNoOfKm : 0;
      this.model.carServiceEntryData.fuel = data.carServiceEntryData != null ? data.carServiceEntryData.fuel : 0;
      this.model.initialFiles = data.initialFiles;
      this.model.appointmentDetails = data.appointmentDetails;
    }
  }

  initialFilesSaved(data: Array<FileAttachment>) {
    // if (ObjectValidators.isValidArray(data))
    // this.model.initialFiles = data;
  }

  carWorksFormSaved(data: CarService_Dto) {
    if (ObjectValidators.isValidObject(data)) {
      let actualNoOfKm = this.model.carServiceEntryData.actualNoOfKm;
      let oldFuel = this.model.carServiceEntryData.fuel;
      this.model.carServiceEntryData = this.carServiceCarWorksComponent.di.getModel().carServiceEntryData;
      this.model.carServiceEntryData.actualNoOfKm = actualNoOfKm;
      this.model.carServiceEntryData.fuel = oldFuel;
      this.model.clientCarParts = this.carServiceCarWorksComponent.di.getModel().clientCarParts;
      this.model.appointmentDetails = data.appointmentDetails;
    }
  }

  updateModel() {
    this.model.client = this.carServiceClientComponent.di.getModel();
    this.model.car = this.carServiceCarComponent.di.getModel();
    const actualNoOfKm = this.model.carServiceEntryData.actualNoOfKm;
    const oldFuel = this.model.carServiceEntryData.fuel;
    this.model.carServiceEntryData = this.carServiceCarWorksComponent.di.getModel().carServiceEntryData;
    this.model.carServiceEntryData.actualNoOfKm = actualNoOfKm;
    this.model.carServiceEntryData.fuel = oldFuel;
    this.model.clientCarParts = this.carServiceCarWorksComponent.di.getModel().clientCarParts;
    this.files = this.carServicePhotosComponent != null ? this.carServicePhotosComponent.di.getModel() : [];
    this.imgUrls = this.carServicePhotosComponent != null ? this.carServicePhotosComponent.getImgUrl() : [];
    this.model.appointmentDetails = this.model.appointmentDetails;
  }

  goToNextStep() {
    this.step++;
    this.wizard.goToNextStep();
    this.updateValidation();
    this.updateModel();
    $('.scroll-container').animate({scrollTop: 0}, 'slow');
  }

  goToPreviousStep() {
    this.step--;
    this.wizard.goToPreviousStep();
    this.updateValidation();
    this.updateModel();
    $('.scroll-container').animate({scrollTop: 0}, 'slow');
  }

  goToStep(index: number) {
    this.isSummary = false;
    this.step = index;
    this.wizard.goToStep(index);
    this.updateValidation();
    this.updateModel();
    $('.scroll-container').animate({scrollTop: 0}, 'slow');
  }

  goToSummary() {
    this.formSubmitted = true;

    this.model.appointmentDetails = this.model.appointmentDetails;
    this.carServiceClientComponent.accept();
    this.carServiceCarComponent.accept();
    this.carServiceCarWorksComponent.accept();
    this.updateValidation();
    if (this.isClientStepValid && this.isCarStepValid && this.isCarWorksStepValid) {
      this.isSummary = true;
    }
    $('.scroll-container').animate({scrollTop: 0}, 'slow');
  }

  updateValidation() {
    if (this.formSubmitted) {
      this.isClientStepValid = this.carServiceClientComponent.isValid();
      this.isCarStepValid = this.carServiceCarComponent.isValid();
      this.isCarWorksStepValid = this.carServiceCarWorksComponent.isValid();
      this.carServiceCarComponent.markFormAsDirtyAndTouched();
      this.carServiceClientComponent.markFormAsDirtyAndTouched();
    }
  }

  saveForm() {
    if (this.isClientStepValid && this.isCarStepValid && this.isCarWorksStepValid) {
      this.di.model = this.model;
      this.registerCarInServiceAP.execute(this.appointmentId, (data: CarService_Dto) => {
        console.log("uploadCompleted " + NgZone.isInAngularZone());
        if (this.files != null && this.files.length > 0) {
          var timestamp = new Date().getTime();
          var filesToUpload = new Array<FileToUpload>();

          for (var i = 0; i < this.files.length; i++) {
            this.files[i].id = undefined;
            timestamp = timestamp + 1;
            filesToUpload.push(new FileToUpload(timestamp.toString(), this.files[i].file, FileUploadTarget.InitialCarServiceEntry, data.id));
          }
          if (filesToUpload.length > 0) {
            this.sandbox.dispatchUploadInputEvent(new UploadInput(filesToUpload, FileUploadTarget.InitialCarServiceEntry));
          }
        }

        this.onClose.next(true);
        this.activeModal.hide();
        // this.router.navigate(['/car-service/all']);
      });
    } else {
      $('.modal').animate({scrollTop: 0}, 'slow');
    }
  }

  saveDraft() {
    this.model.client = this.carServiceClientComponent.di.getModel();
    this.model.car = this.carServiceCarComponent.di.getModel().car;
    const actualNoOfKm = this.carServiceCarComponent.di.getModel().carServiceEntryData != null ? this.carServiceCarComponent.di.getModel().carServiceEntryData.actualNoOfKm : 0;
    const oldFuel = this.carServiceCarComponent.di.getModel().carServiceEntryData != null ? this.carServiceCarComponent.di.getModel().carServiceEntryData.fuel : 0;
    this.model.carServiceEntryData = this.carServiceCarWorksComponent.di.getModel().carServiceEntryData;
    this.model.carServiceEntryData.actualNoOfKm = actualNoOfKm;
    this.model.carServiceEntryData.fuel = oldFuel;
    this.model.clientCarParts = this.carServiceCarWorksComponent.di.getModel().clientCarParts;
    this.saveDraftAP.execute(this.model, this.step);
    const resetClientForCarAP = new ResetStateActionProxy(this.sandbox.appState, ResetSection.ClientForCar);
    resetClientForCarAP.execute();
    this.activeModal.hide();
  }
}
