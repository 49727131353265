import { BaseDI } from 'src/app/core/shared/base/base.di';
import { CarPart_Dto } from '../../../core/app-dto/car-part.dto';

export class CarPartsListItemDi extends BaseDI<CarPart_Dto> {
    constructor(data: any) {
        super(new CarPart_Dto(data));
    }

    getModel(): CarPart_Dto {
        return this.model;
    }
}
