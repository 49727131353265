import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import {
  BaseListSandboxWithActiveAndSearchBehavior,
  BaseSandboxWithCurrentUserLoaded
} from 'src/app/core/shared/base/base.sandbox';
import { ActiveDataTableParameters, DataTableParameters, PagedResult } from 'src/app/core/shared/datatable/datatable.helpers';
import { CarPartsListItemDi } from './car-parts-list-item.di';
import { CarPart_Dto } from '../../../core/app-dto/car-part.dto';
import { Organization } from '../../../core/app-dto/organization.dto';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { Supplier_Dto } from '../../../core/app-dto/supplier.dto';

@Injectable()
export class CarPartsListSandbox extends BaseSandboxWithCurrentUserLoaded {
    public carParts$ = this.appState$.pipe(select(store.getCarPartsListing));
    public timestamp$ = this.appState$.pipe(select(store.getCarPartTimestamp));
    public getSuppliers$ = this.appState$.pipe(select(store.getSuppliersListing));
    public getCarPartsImportTemplateUrl$ = this.appState$.pipe(select(store.getCarPartsImportTemplateUrl));

    public organization: Organization;
    public totalNumberOfResults: number = null;
    public callback: any;
    public dataTableParameters: ActiveDataTableParameters;
    public resetTableCallback: Function;
    public lastRefreshTime: number = 0;

    public totalPages: number = 0;
    public items: Array<CarPartsListItemDi> = [];
    private draw: number = 1;

    public suppliers: Supplier_Dto[];
    public carPartsImportTemplateUrl: string = '';

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
        this.items = [];
        this.draw = 1;
        this.lastRefreshTime = 0;
    }

    public initialize(): void {
        this.clearSandboxState();
        this.loadUser(()=>{
          this.organization = this.currentUser.organization;
        });
        this.subscriptions.push(this.carParts$.subscribe((data: PagedResult<CarPart_Dto>) => {
            if (data != null && data.items != null) {
                this.items = data.items.map(r => new CarPartsListItemDi(r));
                this.totalNumberOfResults = data.totalItems;
                this.totalPages = data.totalPages;
                if (this.callback != null) {
                    this.callback({
                        draw: this.draw++,
                        recordsTotal: data.totalItems,
                        recordsFiltered: data.totalItems,
                        data: []
                    });
                }
            } else {
                this.items = [];
                this.draw = 1;
            }
        }));

        this.subscriptions.push(this.timestamp$.subscribe((result: number) => {
            if (result > this.lastRefreshTime) {
                this.lastRefreshTime = result;
                this.resetTable();
            }
        }));


        this.subscriptions.push(this.getSuppliers$.subscribe((data: Supplier_Dto[]) => {
            if (data != null) {
                this.suppliers = data;
            } else {
                this.suppliers = [];
            }
        }));

        this.subscriptions.push(this.getCarPartsImportTemplateUrl$.subscribe((urlResponse: any) => {
            if (ObjectValidators.isValidObject(urlResponse)) {
                this.carPartsImportTemplateUrl = urlResponse.data;
            }
        }));
    }

    public setResetTableCallback(callback: Function) {
        this.resetTableCallback = callback;
    }

    public resetTable() {
        if (this.resetTableCallback != null) {
            this.resetTableCallback();
        }
    }
}
