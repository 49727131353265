import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseFormComponent} from 'src/app/core/shared/base/base.component';
import {RequestSubscriptionActionProxy} from './request-subscription.action-proxy';
import {RequestSubscriptionSandbox} from './request-subscription.sandbox';
import {RequestSubscriptionDI} from './request-subscription.di';
import {StaticData} from 'src/app/core/app-dto/static-data.dto';
import {ResetSection} from 'src/app/core/app-store/events/base.events';
import {BaseLocationEntity, IdName_Dto} from '../../../app-dto/misc.dto';
import {StaticDataSelectorInput} from "../../../shared/components/static-data-selector/static-data-selector.component";
import {ActivatedRoute} from "@angular/router";
import {isValidArrayAndHasElements, isValidObject, isValidString} from "../../../shared/helpers/common.helpers";
import {Organization} from '../../../app-dto/organization.dto';
import {Address} from '../../../app-dto/core.dto';
import {
  GetOrganizationClientFromAnafActionProxy
} from '../../../../organization-client/components/organization-client-form/get-organization-client-from-anaf.action-proxy';
import {Store} from '@ngrx/store';
import * as store from '../../../app-store';

@Component({
  selector: 'app-request-subscription',
  templateUrl: './request-subscription.component.html',
  styleUrls: ['./request-subscription.component.scss']
})
export class RequestSubscriptionComponent extends BaseFormComponent implements OnInit, OnDestroy {
  public getOrganizationClientFromAnafAP: GetOrganizationClientFromAnafActionProxy;
  public requestSubscriptionAP: RequestSubscriptionActionProxy;
  public currenciesDataInput: StaticDataSelectorInput;
  private queryStringPlanName: string = '';

  public errorMessage: string = '';

  public subscriptionTypes: Array<IdName_Dto>;
  public di: RequestSubscriptionDI;//we need to overwrite di with specific type for production build
  constructor(private route: ActivatedRoute,
              public sandbox: RequestSubscriptionSandbox,
              public appState: Store<store.State>
  ) {
    super(sandbox, ResetSection.RequestSubscription);
    this.requestSubscriptionAP = new RequestSubscriptionActionProxy(this, sandbox.appState);
    this.di = new RequestSubscriptionDI();
    this.currenciesDataInput = {
      form: this.di.form,
      type: 'currencies',
      formControlName: 'currency',
      isRequired: true,
      label: 'Moneda',
      isReadOnly: false
    };
    this.getOrganizationClientFromAnafAP = new GetOrganizationClientFromAnafActionProxy(this, appState);
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
          if (params.plan != null) {
            this.queryStringPlanName = params.plan;
            this.setPlanFromQueryStringValue();
          }
        }
      );
    this.initialize(
      (message: string) => {
        this.errorMessage = message;
      },
      (data: StaticData) => {
        this.subscriptionTypes = data.subscriptionTypes;
        this.setPlanFromQueryStringValue();
      }
    );
  }

  private setPlanFromQueryStringValue() {
    if (isValidString(this.queryStringPlanName) && isValidArrayAndHasElements(this.subscriptionTypes)) {
      const foundSubscriptionType = this.subscriptionTypes.find(f => f.name.replace(/\s/g, '').toLowerCase() == this.queryStringPlanName.toLowerCase());
      if (isValidObject(foundSubscriptionType))
        this.di.subscriptionId = foundSubscriptionType.id;
    }
  }

  ngOnDestroy() {
    this.destroy();
  }

  saveForm() {
    this.formSubmitted = true;
    this.requestSubscriptionAP.execute();
  }

  public companyInfoLookupAnaf() {
    const inputCui: string = this.di.getModel().cui;
    if (isValidString(inputCui)) {
      this.getOrganizationClientFromAnafAP.execute(inputCui, (result: Organization) => {
        this.whenAnafCompanyFound(result);
      });
    }
  }

  public whenAnafCompanyFound(data: Organization) {
    if (isValidObject(data)) {
      this.di.resetAnafUsedFields();
      this.di.form.get('serviceName').setValue(data.name);
      this.di.form.get('registrationNumber').setValue(data.registrationNumber);
      this.di.form.get('phoneNumber').setValue(data.representativePersonPhoneNumber);
      const address = new Address(null);
      if (isValidString(data.address.address1)) {
        address.address1 = data.address.address1;
      }
      if (isValidString(data.address.city)) {
        address.city = data.address.city;
      }
      if (isValidString(data.address.region.id)) {
        address.region = new BaseLocationEntity(data.address.region);
      }
      if (isValidString(data.address.country.id)) {
        address.country = new BaseLocationEntity(data.address.country);
      }
      this.di.model.address = address;
    }
  }
}
