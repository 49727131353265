import { EventWithScreenLoading, SeamlessEvent, UIGeneratedEvent } from "./base.events";
import { InvoiceDataTableParameters } from '../../shared/datatable/datatable.helpers';
import {Invoice_Dto, InvoiceType, InvoiceDetails_Dto, SagaExport_Dto} from "../../app-dto/invoice.dto";

export class GetInvoicesPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetInvoicesPagedResultEvent";

  constructor(public parameters: InvoiceDataTableParameters, public withReset: boolean = false) {
    super();
    this.type += GetInvoicesPagedResultEvent.identifier;
  }
}

export class GetOrganizationInvoicesPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetOrganizationInvoicesPagedResultEvent";

  constructor(public parameters: InvoiceDataTableParameters, public withReset: boolean = false, public organizationId: string = null) {
    super();
    this.type += GetOrganizationInvoicesPagedResultEvent.identifier;
  }
}

export class GetOrganizationSubscriptionInvoicesPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetOrganizationSubscriptionInvoicesPagedResultEvent";

  constructor(public parameters: InvoiceDataTableParameters, public withReset: boolean = false, public organizationId: string = null) {
    super();
    this.type += GetOrganizationSubscriptionInvoicesPagedResultEvent.identifier;
  }
}

export class GetInvoiceEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetInvoiceEvent";

  constructor(public invoiceId: string) {
    super();
    this.type += GetInvoiceEvent.identifier;
  }
}

export class GetOrganizationInvoiceEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetOrganizationInvoiceEvent";

  constructor(public organizationId: string, public invoiceId: string) {
    super();
    this.type += GetOrganizationInvoiceEvent.identifier;
  }
}

export class GetOrganizationSubscriptionInvoiceEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetOrganizationSubscriptionInvoiceEvent";

  constructor(public organizationId: string, public invoiceId: string) {
    super();
    this.type += GetOrganizationSubscriptionInvoiceEvent.identifier;
  }
}

export class GetUnauthorizedInvoiceEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetUnauthorizedInvoiceEvent";

  constructor(public invoiceId: string, public code: string) {
    super();
    this.type += GetUnauthorizedInvoiceEvent.identifier;
  }
}

export class GetPayButtonAuthEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetPayButtonAuthEvent";

  constructor(public invoiceId: string, public callback: (result: string) => void) {
    super();
    this.type += GetPayButtonAuthEvent.identifier;
  }
}

export class GetPayButtonUnAuthEvent extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetPayButtonUnAuthEvent";

  constructor(public invoiceId: string, public code: string, public callback: (result: string) => void) {
    super();
    this.type += GetPayButtonUnAuthEvent.identifier;
  }
}

export class MarkOrgInvoiceAsPaidEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "MarkOrgInvoiceAsPaidEvent";

  constructor(public invoiceId: string, public organizationId: string) {
    super();
    this.type += MarkOrgInvoiceAsPaidEvent.identifier;
  }
}

export class MarkOrgInvoiceAsNotPaidEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "MarkOrgInvoiceAsNotPaidEvent";

  constructor(public invoiceId: string, public organizationId: string) {
    super();
    this.type += MarkOrgInvoiceAsNotPaidEvent.identifier;
  }
}

export class MarkSubInvoiceAsPaidEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "MarkSubInvoiceAsPaidEvent";

  constructor(public invoiceId: string) {
    super();
    this.type += MarkSubInvoiceAsPaidEvent.identifier;
  }
}

export class MarkSubInvoiceAsNotPaidEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "MarkSubInvoiceAsNotPaidEvent";

  constructor(public invoiceId: string) {
    super();
    this.type += MarkSubInvoiceAsNotPaidEvent.identifier;
  }
}

export class RegenerateOrgInvoiceEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "RegenerateOrgInvoiceEvent";

  constructor(public invoiceId: string, public organizationId: string, public callback: (newId: string) => void) {
    super();
    this.type += RegenerateOrgInvoiceEvent.identifier;
  }
}

export class GetOrgInvoiceDownloadLink extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetOrgInvoiceDownloadLink";

  constructor(public invoiceId: string, public organizationId: string, public callback: (link: string) => void) {
    super();
    this.type += GetOrgInvoiceDownloadLink.identifier;
  }
}

export class GetSubInvoiceDownloadLink extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GetSubInvoiceDownloadLink";

  constructor(public invoiceId: string, public callback: (link: string) => void) {
    super();
    this.type += GetSubInvoiceDownloadLink.identifier;
  }
}

export class GetInvoiceForCarServiceEntry extends SeamlessEvent implements UIGeneratedEvent {
  public static identifier: string = "GetInvoiceForCarServiceEntry";

  constructor(public carServiceEntry: string, public organizationId: string, public invoiceType: InvoiceType, public callback: (data: Invoice_Dto) => void) {
    super();
    this.type += GetInvoiceForCarServiceEntry.identifier;
  }
}

export class UpdateInvoiceDeadlineEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "UpdateInvoiceDeadlineEvent";

  constructor(public invoice: InvoiceDetails_Dto, public callback:(data:InvoiceDetails_Dto)=>void) {
    super();
    this.type += UpdateInvoiceDeadlineEvent.identifier;
  }
}

export class GenerateInvoiceForCarServiceEntry extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "GenerateInvoiceForCarServiceEntry";

  constructor(public carServiceEntry: string, public organizationId: string) {
    super();
    this.type += GenerateInvoiceForCarServiceEntry.identifier;
  }
}
export class DoSagaExportForInvoicesEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier: string = "DoSagaExportForInvoicesEvent";

  constructor(public data: SagaExport_Dto,  public callback:()=>void) {
    super();
    this.type += DoSagaExportForInvoicesEvent.identifier;
  }
}

