import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiErrorDto } from '../utils/api-error.dto';
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {Supplier_Dto} from '../../app-dto/supplier.dto';
import { ErrorHelpers } from '../../shared/error.helpers';
import {ActiveDataTableParameters, PagedResult} from "../../shared/datatable/datatable.helpers";

class SupplierApiRoutes {
    public static route_for_GET_SUPPLIERS() {
        return '/api/supplier/all';
    }

    public static route_for_GET_SUPPLIER(supplierID: string) {
        return `/api/supplier/${supplierID}`;
    }

    public static route_for_GET_SUPPLIERS_PAGED() {
        return '/api/supplier/';
    }

    public static route_for_REGISTER_SUPPLIER() {
        return '/api/supplier/';
    }

    public static route_for_UPDATE_SUPPLIER() {
        return '/api/supplier/';
    }

    public static route_for_ACTIVATE_SUPPLIER(supplierID: string) {
        return `/api/supplier/${supplierID}/activate/`;
    }

    public static route_for_DEACTIVATE_SUPPLIER(supplierID: string) {
        return `/api/supplier/${supplierID}/deactivate/`;
    }

    public static route_for_DELETE_SUPPLIER(supplierID: string) {
        return `/api/supplier/${supplierID}`;
    }
}

@Injectable()
export class SupplierApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_getSuppliers(): Observable<Supplier_Dto[]> {
        const url = this.configService.getAPIBaseUrl() + SupplierApiRoutes.route_for_GET_SUPPLIERS();

        return this.http.get(url)
            .map((res: Supplier_Dto[]) => {
                return res;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getSuppliersPaged(data: ActiveDataTableParameters): Observable<PagedResult<Supplier_Dto>> {
        const url = this.configService.getAPIBaseUrl() + SupplierApiRoutes.route_for_GET_SUPPLIERS_PAGED() + '?' + data.toUrlParameters();

        return this.http.get(url)
            .map((res: PagedResult<Supplier_Dto>) => {
                const x = new PagedResult(res);
                return new PagedResult<Supplier_Dto>(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getSupplier(supplierID: string): Observable<Supplier_Dto> {
        const url = this.configService.getAPIBaseUrl() + SupplierApiRoutes.route_for_GET_SUPPLIER(supplierID);

        return this.http.get(url)
            .map((res: any) => {
                return new Supplier_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_registerSupplier(supplierData: Supplier_Dto): Observable<Supplier_Dto> {
        const url = this.configService.getAPIBaseUrl() + SupplierApiRoutes.route_for_REGISTER_SUPPLIER();

        return this.http.post(url, supplierData)
            .map((res: any) => {
                return new Supplier_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_updateSupplier(supplierData: Supplier_Dto): Observable<Supplier_Dto> {
        const url = this.configService.getAPIBaseUrl() + SupplierApiRoutes.route_for_UPDATE_SUPPLIER();

        return this.http.put(url, supplierData)
            .map((res: any) => {
                return new Supplier_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deleteSupplier(supplierId: string): Observable<Supplier_Dto> {
        const url = this.configService.getAPIBaseUrl() + SupplierApiRoutes.route_for_DELETE_SUPPLIER(supplierId);

        return this.http.delete(url)
            .map((res: any) => {
                return new Supplier_Dto(null);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_activateSupplier(supplierID: string): Observable<Supplier_Dto> {
        const url = this.configService.getAPIBaseUrl() + SupplierApiRoutes.route_for_ACTIVATE_SUPPLIER(supplierID);

        return this.http.put(url, {})
            .map((res: any) => {
                return new Supplier_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deactivateSupplier(supplierID: string): Observable<Supplier_Dto> {
        const url = this.configService.getAPIBaseUrl() + SupplierApiRoutes.route_for_DEACTIVATE_SUPPLIER(supplierID);

        return this.http.put(url, {})
            .map((res: any) => {
                return new Supplier_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
}
