import {BaseFormDI} from "../../../core/shared/base/base.di";
import {DateTimeService} from "../../../core/core/services/date-time.service";
import {FormGroup} from "@angular/forms";
import {Offer_Dto} from "../../../core/app-dto/offer.dto";

export class AddOfferDi extends BaseFormDI<Offer_Dto> {
    constructor(public dateTimeService: DateTimeService, data: Offer_Dto = new Offer_Dto(null)) {
        super(new Offer_Dto(data));

        this.initForm();
    }

    getModel(): Offer_Dto {
        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
        });
    }
}
