import { PagedResult } from "../../shared/datatable/datatable.helpers";
import { Action } from "@ngrx/store";
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent, Do_ResetStateForSection,
  EventWithScreenLoading,
  EventWithStopingScreenLoader, ResetSection,
  SeamlessEvent
} from "../events/base.events";
import { RequestSubscription_Dto } from '../../app-dto/authentication.dto';
import {
  GetSubscriptionsPagedResultEvent,
  GetSubscriptionEvent,
  GetSubscriptionProfileEvent,
  EditSubscriptionEvent,
  EditSubscriptionProfileEvent,
  DeactivateSubscriptionEvent,
  ValidateSubscriptionEvent,
  OrganizationImageChanged,
  OrganizationCuiChanged,
  DeleteOrganizationImageProfileUrlEvent,
  DeleteOrganizationCuiUrlEvent,
  ActivateAddonEvent, DeactivateAddonEvent, CancelDeactivateAddonEvent
} from '../events/subscription.events';
import { ResetStateActionProxy } from '../../shared/base/base.action-proxy';
import { Organization } from "../../app-dto/organization.dto";
import { FileAttachment, FileUploadTarget } from '../../app-dto/core.dto';
import { Subscription } from 'rxjs';
import {BuySMSForOrganizationAsPlatformAdminEvent} from "../events/sms.events";

export interface SubscriptionState {
  lastRefreshTime: number;
  subscription: Organization;
  subscriptionsPagedResult: PagedResult<Organization>;
};

export const INITIAL_STATE: SubscriptionState = Object.assign({}, {
  lastRefreshTime: 0,
  subscription: null,
  subscriptionsPagedResult: new PagedResult<Organization>([]),
});


export function reducer(state = INITIAL_STATE, action: Action): SubscriptionState {
  if (!action) return state;
  var actionType = action.type;

  actionType = actionType.replace(EventWithScreenLoading.identifier, '');
  actionType = actionType.replace(SeamlessEvent.identifier, '');
  actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
  switch (actionType) {
    // Get Subscription

    case Do_ResetStateForSection.identifier + ResetSection.RegisterSubscription: {
      return Object.assign({}, state, { subscription: null });
    }

    case Do_GenericSuccessEvent.identifier + GetSubscriptionEvent.identifier: {
      const subscriptionData = (<Do_GenericSuccessEvent<Organization>>action).data;
      
      return Object.assign({}, state, { subscription: subscriptionData });
    }

    case Do_GenericErrorEvent.identifier + GetSubscriptionEvent.identifier: {
      return Object.assign({}, state, { subscription: null });
    }

    case ResetStateActionProxy.name + GetSubscriptionEvent.identifier: {
      return Object.assign({}, state, { subscription: null });
    }

    // /Get Subscription

    // Get Subscription Profile

    case Do_GenericSuccessEvent.identifier + GetSubscriptionProfileEvent.identifier: {
      const subscriptionData = (<Do_GenericSuccessEvent<Organization>>action).data;
      
      return Object.assign({}, state, { subscription: subscriptionData });
    }

    case Do_GenericErrorEvent.identifier + GetSubscriptionProfileEvent.identifier: {
      return Object.assign({}, state, { subscription: null });
    }

    // /Get Subscription Profile

    // Update Subscription Profile

    case Do_GenericSuccessEvent.identifier + EditSubscriptionEvent.identifier:
    case Do_GenericSuccessEvent.identifier + BuySMSForOrganizationAsPlatformAdminEvent.identifier:
    case Do_GenericSuccessEvent.identifier + EditSubscriptionProfileEvent.identifier: {
      const subscriptionData = (<Do_GenericSuccessEvent<Organization>>action).data;
      return Object.assign({}, state, { subscription: subscriptionData });
    }

    // /Update Subscription Profile



    // Deactivate Subscription

    case Do_GenericSuccessEvent.identifier + DeactivateSubscriptionEvent.identifier: {
      const subscriptionData = (<Do_GenericSuccessEvent<Organization>>action).data;
      return Object.assign({}, state, { subscription: subscriptionData });
    }

    // /Deactivate Subscription


    // Validate Subscription

    case Do_GenericSuccessEvent.identifier + ValidateSubscriptionEvent.identifier: {
      const subscriptionData = (<Do_GenericSuccessEvent<Organization>>action).data;
      return Object.assign({}, state, { subscription: subscriptionData });
    }

    // /Validate Subscription



    // Get Subscriptions

    case Do_ResetStateForSection.identifier + ResetSection.Subscriptions: {
      return Object.assign({}, state, { subscriptionsPagedResult: new PagedResult<Organization>([]) });
    }

    case GetSubscriptionsPagedResultEvent.identifier: {
      if ((<GetSubscriptionsPagedResultEvent>action).withReset) {
        state.subscriptionsPagedResult = new PagedResult<Organization>([]);
        return Object.assign({}, state);
      }
      return state;
    }

    case Do_GenericSuccessEvent.identifier + GetSubscriptionsPagedResultEvent.identifier: {
      var data = (<Do_GenericSuccessEvent<PagedResult<Organization>>>action).data;
      state.subscriptionsPagedResult = new PagedResult<Organization>(data);
      return Object.assign({}, state);
    }

    case Do_GenericErrorEvent.identifier + GetSubscriptionsPagedResultEvent.identifier: {
      state.subscriptionsPagedResult = new PagedResult<Organization>([])
      return Object.assign({}, state);
    }

    // /Get Subscriptions

    case Do_GenericSuccessEvent.identifier + DeleteOrganizationImageProfileUrlEvent.identifier: {
      var dataUrl = (<Do_GenericSuccessEvent<string>>action).data;
      state.subscription.displayImageUrl = dataUrl;
      state.subscription = new Organization(state.subscription);
      return Object.assign({}, state);
    }

    case Do_GenericSuccessEvent.identifier + DeleteOrganizationCuiUrlEvent.identifier: {
      state.subscription.cuiImageUrl = null;
      state.subscription = new Organization(state.subscription);
      return Object.assign({}, state);
    }

    case OrganizationImageChanged.identifier: {
      const data: FileAttachment = (action as OrganizationImageChanged).data;
      
      if (state.subscription != null && state.subscription.id == data.organizationId && data.type == FileUploadTarget.OrganizationProfile) {
        state.subscription.displayImageUrl = data.url;
        state.subscription = new Organization(state.subscription);
        return Object.assign({}, state);
      }

    }

    case OrganizationCuiChanged.identifier: {
      const data: FileAttachment = (action as OrganizationCuiChanged).data;
      
      if (state.subscription != null && state.subscription.id == data.organizationId && data.type == FileUploadTarget.OrganizationCUI) {
        state.subscription.cuiImageUrl = data.url;
        state.subscription = new Organization(state.subscription);
        return Object.assign({}, state);
      }
    }

      // Activate/deactivate Addon for Organization

    case Do_GenericSuccessEvent.identifier + ActivateAddonEvent.identifier: {
      const subscriptionData = (<Do_GenericSuccessEvent<Organization>> action).data;
      return Object.assign({}, state, { subscription: subscriptionData });
    }

    case Do_GenericSuccessEvent.identifier + DeactivateAddonEvent.identifier: {
      const subscriptionData = (<Do_GenericSuccessEvent<Organization>> action).data;
      return Object.assign({}, state, { subscription: subscriptionData });
    }

    case Do_GenericSuccessEvent.identifier + CancelDeactivateAddonEvent.identifier: {
      const subscriptionData = (<Do_GenericSuccessEvent<Organization>> action).data;
      return Object.assign({}, state, { subscription: subscriptionData });
    }

      // Activate/deactivate Addon for Organization

    default: {
      return state;
    }
  }
}

export const getTimestampCallback = (state: SubscriptionState) => state.lastRefreshTime;
export const getSubscriptionCallback = (state: SubscriptionState) => state.subscription;
export const getSubscriptionsPagedResultCallback = (state: SubscriptionState) => state.subscriptionsPagedResult;
