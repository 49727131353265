import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { Department_Dto } from 'src/app/core/app-dto/department.dto';

@Injectable()
export class DepartmentDetailsSandbox extends BaseSandbox {
    public getDepartment$ = this.appState$.pipe(select(store.getDepartment));

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    clearSandboxState() {
    }

    initialize(department: (data: Department_Dto) => {}) {
        this.clearSandboxState();

        this.subscriptions.push(this.getDepartment$.subscribe((data: Department_Dto) => {
            if (ObjectValidators.isValidObject(data)) {
              
                department(data);
            }
        }));
    }
}
