import { ObjectValidators } from '../shared/object.validators';
import {Base_Dto, BaseCRUDRules, BusinessRule, IdName_Dto} from './misc.dto';
import * as moment from 'moment';

export class DraftRules extends BaseCRUDRules {
  public canActivate: BusinessRule;
  public canDeactivate: BusinessRule;

  constructor(data: any) {
    super(data);
    if (ObjectValidators.isValidObject(data)) {
      this.canActivate = new BusinessRule(data.canActivate);
      this.canDeactivate = new BusinessRule(data.canDeactivate);

    } else {
      this.canActivate = new BusinessRule();
      this.canDeactivate = new BusinessRule();
    }
  }
}

export enum DraftType {
    CarServiceEntry = 1,
    OfferEntry = 2
}

export class Draft_Dto extends Base_Dto {
    public title: string;
    public description: string;
    public currentPage: string;
    public data: string;
    public rules: DraftRules;
    public expirationDate: Date;
    public createdOn: Date;
    public updatedOn: Date;
    public type: DraftType;

    constructor(data: any) {
        super(data);
        if (ObjectValidators.isValidObject(data)) {
            this.title = data.title;
            this.description = data.description;
            this.currentPage = data.currentPage;
            this.data = data.data;
            this.rules = new DraftRules(data.rules);
            if (ObjectValidators.isValidObject(data.expirationDate))
                this.expirationDate = moment.utc(data.expirationDate).toDate();
            if (ObjectValidators.isValidObject(data.createdOn))
                this.createdOn = moment.utc(data.createdOn).toDate();
            if (ObjectValidators.isValidObject(data.updatedOn))
                this.updatedOn = moment.utc(data.updatedOn).toDate();
            this.type = <DraftType>data.type;
        }
    }
}
