import { BaseDI } from 'src/app/core/shared/base/base.di';
import { CarService_Dto } from '../../../core/app-dto/car-service.dto';

export class CarServiceListDashboardItemDi extends BaseDI<CarService_Dto> {
    constructor(data: any) {
        super(new CarService_Dto(data));
    }

    getModel(): CarService_Dto {
        return this.model;
    }
}
