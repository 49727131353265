import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { RegisterCarServiceClientCarPartActionProxy } from './register-car-service-client-car-part.action-proxy';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { EditCarServiceClientCarPartActionProxy } from './edit-car-service-client-car-part.action-proxy';
import { ActivatedRoute } from '@angular/router';
import { CarServiceSaveClientCarPartSandbox } from './car-service-save-client-car-part.sandbox';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { CarPart_Dto, CarServiceCarPart_Dto } from '../../../core/app-dto/car-part.dto';
import { GetCarPartActionProxy } from '../../../car-part/components/car-part-details/get-car-part.action-proxy';
import { GetSuppliersActionProxy } from '../../../car-part/components/save-car-part/get-suppliers.action-proxy';
import { ClientCarPartFormComponent } from '../../../car-part/components/client-car-part-form/client-car-part-form.component';
import { ClientCarParts_Dto } from '../../../core/app-dto/car-service.dto';
import {isValidObject} from "../../../core/shared/helpers/common.helpers";


@Component({
  selector: 'app-car-service-save-client-car-part',
  templateUrl: './car-service-save-client-car-part.component.html',
  styleUrls: ['./car-service-save-client-car-part.component.scss']
})
export class CarServiceSaveClientCarPartComponent extends BaseFormModelComponent<CarServiceCarPart_Dto> implements OnInit, OnDestroy {
  public registerCarServiceClientCarPartAP: RegisterCarServiceClientCarPartActionProxy;
  public editCarServiceClientCarPartAP: EditCarServiceClientCarPartActionProxy;
  public getCarAP: GetCarPartActionProxy;
  public getSupplierAP: GetSuppliersActionProxy;
  public title: string;
  public onClose: Subject<boolean>;

  public data: CarPart_Dto = null;
  public carServiceId: string;
  private isEdit:boolean = false;

  @ViewChild(ClientCarPartFormComponent, { static: false })
  private clientCarPartFormComponent: ClientCarPartFormComponent;


  constructor(public appState: Store<store.State>,
              public sandbox: CarServiceSaveClientCarPartSandbox,
              private activeModal: BsModalRef,
              public activatedRoute: ActivatedRoute) {
    super(sandbox, ResetSection.CarPart);
    this.registerCarServiceClientCarPartAP = new RegisterCarServiceClientCarPartActionProxy(this, sandbox.appState);
    this.editCarServiceClientCarPartAP = new EditCarServiceClientCarPartActionProxy(this, sandbox.appState);
    this.getCarAP = new GetCarPartActionProxy(this, sandbox.appState);
    this.getSupplierAP = new GetSuppliersActionProxy(this, sandbox.appState);
    this.getSupplierAP.execute();
  }

  public diInitialize(data: CarServiceCarPart_Dto) {
    this.data = data.carPart;
    this.carServiceId = data.carServiceId;
    this.title = ObjectValidators.isValidObject(this.data) ? 'Editare piesa client' : 'Adauga piesa client';
    this.isEdit = ObjectValidators.isValidObject(this.data);
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.clientCarPartFormComponent.accept();
  }

  public formSaved(model: ClientCarParts_Dto) {
    if (this.isEdit) {
      this.editCarServiceClientCarPartAP.execute(model, this.carServiceId);
    } else {
      this.registerCarServiceClientCarPartAP.execute(model, this.carServiceId);
    }
    this.onClose.next(true);
    this.activeModal.hide();
  }

  carPartSelected(value: CarPart_Dto) {
    if (isValidObject(value))
      this.data = new CarPart_Dto(value);
    else this.data = null;
  }
}
