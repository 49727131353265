import {
    UIGeneratedEvent,
    SeamlessEvent,
} from './base.events';
import { Breadcrumb } from '../../app-dto/breadcrumb.dto';

export class BreadcrumbEvent extends SeamlessEvent implements UIGeneratedEvent {
    public static identifier: string = 'BreadcrumbEvent';
    constructor(public value: Breadcrumb[]) {
        super();
        this.type += BreadcrumbEvent.identifier;
    }
}
