import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { BaseFormComponent } from 'src/app/core/shared/base/base.component';
import { AppointmentSummarySandbox } from './appointment-summary.sandbox';
import { AppointmentSummaryDi } from './appointment-summary.di';
import { AppointmentClient_Dto } from '../../../core/app-dto/appointment-client.dto';
import { CarAppointment_Dto } from '../../../core/app-dto/car-appointment.dto';

@Component({
  selector: 'app-appointment-summary',
  templateUrl: './appointment-summary.component.html',
  styleUrls: ['./appointment-summary.component.scss']
})
export class AppointmentSummaryComponent extends BaseFormComponent implements OnInit, OnDestroy {

  @Output()
  modelSaved = new EventEmitter<AppointmentClient_Dto>();

  @Input('carAppointment') carAppointment: CarAppointment_Dto;

  constructor(public appState: Store<store.State>,
              public sandbox: AppointmentSummarySandbox) {
    super(sandbox);
  }

  ngOnInit() {
    this.di = new AppointmentSummaryDi(null);
  }

  ngOnDestroy() {
    this.destroy();
  }
}
