import { Store } from "@ngrx/store";
import * as store from "../../../core/app-store";
import { Observable } from "rxjs";
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { GetInvoiceEvent } from 'src/app/core/app-store/events/invoices.events';

export class GetInvoiceActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(invoiceId: string): any {
        this.appState.dispatch(new GetInvoiceEvent(invoiceId));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

