import {Injectable} from "@angular/core";
import {BaseApiService} from "./base-api.service";
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "../../core/app-config.service";
import {Observable} from "rxjs";
import {ApiErrorDto} from "../utils/api-error.dto";
import {ErrorHelpers} from "../../shared/error.helpers";
import {AsyncJob} from '../../app-dto/core.dto';

class AsyncJobApiRoutes {
  public static route_for_REGISTER_JOB() {
    return '/api/asyncjob/';
  }

}

@Injectable()
export class AsyncJobApiSerivce extends BaseApiService {

  constructor(private http: HttpClient, configService: ConfigService) {
    super(configService);
  }

  u_registerJob(data: AsyncJob): Observable<AsyncJob> {
    const url = this.configService.getAPIBaseUrl() + AsyncJobApiRoutes.route_for_REGISTER_JOB();

    return this.http.post(url, data)
      .map((res: any) => {
        return new AsyncJob(res);
      })
      .catch<any, any>((err: ApiErrorDto, o) => {
        return ErrorHelpers.throwError(err);
      });
  }
}
