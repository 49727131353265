import {PagedResult} from '../../shared/datatable/datatable.helpers';
import {Receipt_Dto} from '../../app-dto/receipt.dto';
import {Action} from '@ngrx/store';
import {
    Do_GenericErrorEvent,
    Do_GenericSuccessEvent,
    Do_ResetStateForSection,
    EventWithScreenLoading,
    EventWithStopingScreenLoader,
    ResetSection,
    SeamlessEvent
} from '../events/base.events';
import {DoGenerateReceiptEvent, GetOrganizationReceiptsPagedResultEvent, GetReceiptEvent} from '../events/receipt.events';
import {FileAttachment} from '../../app-dto/core.dto';

export interface ReceiptState {
    lastRefreshTime: number;
    receipt: Receipt_Dto;
    receiptsPagedResult: PagedResult<Receipt_Dto>;
    receiptId: string;
}

export const INITIAL_STATE: ReceiptState = Object.assign({}, {
    lastRefreshTime: 0,
    receipt: null,
    receiptsPagedResult: new PagedResult<Receipt_Dto>([]),
    receiptId: null
});

export function reducer(state = INITIAL_STATE, action: Action): ReceiptState {
    if (!action) { return state; }
    var actionType = action.type;

    actionType = actionType.replace(EventWithScreenLoading.identifier, '');
    actionType = actionType.replace(SeamlessEvent.identifier, '');
    actionType = actionType.replace(EventWithStopingScreenLoader.identifier, '');
    switch (actionType) {

        // Get Receipt

        case Do_ResetStateForSection.identifier + ResetSection.Receipt: {
            return Object.assign({}, state, {receipt: null, receiptId : null});
        }
        case GetOrganizationReceiptsPagedResultEvent.identifier: {
            if ((<GetOrganizationReceiptsPagedResultEvent> action).withReset) {
                state.receiptsPagedResult = new PagedResult<Receipt_Dto>([]);
                return Object.assign({}, state);
            }
            return state;
        }
        case Do_GenericSuccessEvent.identifier + GetOrganizationReceiptsPagedResultEvent.identifier: {
            const data = (<Do_GenericSuccessEvent<PagedResult<Receipt_Dto>>> action).data;
            return Object.assign({}, state, {receiptsPagedResult: new PagedResult<Receipt_Dto>(data)});
        }
        case Do_GenericSuccessEvent.identifier + GetReceiptEvent.identifier: {
            const data = (<Do_GenericSuccessEvent<Receipt_Dto>> action).data;
            return Object.assign({}, state, {receipt: new Receipt_Dto(data)});
        }
        case Do_GenericSuccessEvent.identifier + DoGenerateReceiptEvent.identifier: {
            const data = (<Do_GenericSuccessEvent<FileAttachment>> action).data;
            return Object.assign({}, state, {receiptId: data.relatedEntityId});
        }
        case Do_GenericErrorEvent.identifier + GetOrganizationReceiptsPagedResultEvent.identifier: {
            state.receiptsPagedResult = new PagedResult<Receipt_Dto>([]);
            return Object.assign({}, state);
        }
        case Do_GenericErrorEvent.identifier + DoGenerateReceiptEvent.identifier: {
            state.receiptId = null;
            return Object.assign({}, state);
        }
        case Do_GenericErrorEvent.identifier + GetReceiptEvent.identifier: {
            state.receipt = new Receipt_Dto([]);
            return Object.assign({}, state);
        }
        default: {
            return state;
        }
    }
}

export const getTimestampCallback = (state: ReceiptState) => state.lastRefreshTime;
export const getReceiptCallback = (state: ReceiptState) => state.receipt;
export const getReceiptsPagedResultCallback = (state: ReceiptState) => state.receiptsPagedResult;
export const getReceiptIdCallback = (state: ReceiptState) => state.receiptId;
