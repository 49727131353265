import {Component, OnDestroy, OnInit, Output, EventEmitter, Input, ViewChild} from '@angular/core';
import {BaseFormModelComponent} from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import {Store} from '@ngrx/store';
import {range, reverse} from 'lodash';
import {Car_Dto} from '../../../core/app-dto/car.dto';
import * as moment from 'moment';
import {CarFormDI} from './car-form.di';
import {CarFormSandbox} from './car-form.sandbox';
import {isValidObject, makeInputPositive} from '../../../core/shared/helpers/common.helpers';
import {CarSearchComponent} from '../car-search/car-search.component';

@Component({
    selector: 'app-car-form',
    templateUrl: './car-form.component.html',
    styleUrls: ['./car-form.component.scss']
})
export class CarFormComponent extends BaseFormModelComponent<Car_Dto> implements OnInit, OnDestroy {

    public years: number[];
    public carSearch: string = '';
    public di: CarFormDI;
    public defaultSearchValue: string;

    @Output()
    formSaved = new EventEmitter<Car_Dto>();

    @Input('carServiceEntry') carServiceEntry: boolean = false;

    private forGeneralOfferValue: boolean = false;
    @Input('forGeneralOffer') set forGeneralOffer(value: boolean) {
        this.forGeneralOfferValue = value;
        if (isValidObject(this.di)) {
            this.di = new CarFormDI(this.di.model,  this.forGeneralOfferValue, this.forOfferValue);
        }
    }
    private forOfferValue: boolean = false;
    @Input('forOffer') set forOffer(value: boolean) {
        this.forOfferValue = value;
        if (isValidObject(this.di)) {
            this.di = new CarFormDI(this.di.model,  this.forGeneralOfferValue, this.forOfferValue);
        }
    }
    private forOfferEditValue: boolean = false;
    @Input('forOfferEdit') set forOfferEdit(value: boolean) {
        this.forOfferEditValue = value;
        if (isValidObject(this.di)) {
            this.di = new CarFormDI(this.di.model,  this.forGeneralOfferValue, this.forOfferValue, this.forOfferEditValue);
        }
    }

    @ViewChild(CarSearchComponent, {static: false}) carSearchComponent: CarSearchComponent;

    constructor(
        public appState: Store<store.State>,
        public sandbox: CarFormSandbox
    ) {
        super(sandbox, null);
    }

    public diInitialize(data: Car_Dto) {
        this.di = new CarFormDI(data, this.forGeneralOfferValue, this.forOfferValue);
        this.years = reverse(range(1980, moment().year() + 1));

        this.carSearch = this.di.model.registrationNumber;

        this.dataLoaded = true;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.destroy();
    }

    public accept() {
        this.formSubmitted = true;
        this.markFormAsDirtyAndTouched();
        if (this.di.isValid()) {
            if (this.formSaved != null) {
                this.formSaved.emit(this.di.getModel());
            }
        }
    }

    isValid() {
        return this.di.isValid();
    }

    public carError: boolean = false;

    carSelected(value: Car_Dto) {
        if (value != null) {
            this.di = new CarFormDI(value);
            this.carError = false;
        } else {
            this.di.form.get('registrationNumber').setValue('');
            this.carError = true;
        }
        this.di.form.get('registrationNumber').updateValueAndValidity();
    }

    inputChanged(value: string) {
        this.di.form.get('registrationNumber').setValue(value);
        this.di.form.get('registrationNumber').updateValueAndValidity();
        if (value != '') {
            this.carError = false;
        } else {
            this.carError = true;
        }
    }

    makeValuePositive(event: any) {
        makeInputPositive(event);
    }

    carServiceCarSelected(value: any) {
        const car = new Car_Dto(value);
        this.di.model = car;
        this.di.form.controls['bodySeries'].setValue(car.bodySeries);
        this.di.form.controls['make'].setValue(car.make);
        this.di.form.controls['model'].setValue(car.model);
        this.di.form.controls['year'].setValue(car.year);
        this.di.form.controls['power'].setValue(car.power);
        if (this.di.form.controls['fuel']) {
            this.di.form.controls['fuel'].setValue(car.fuel);
        }
        this.di.form.controls['cilindricalCapacity'].setValue(car.cilindricalCapacity);

        if (this.formSaved != null) {
            this.formSaved.emit(this.di.getModel());
        }
    }

    clearSearch() {
        if (this.carSearchComponent) {
            this.carSearchComponent.clearSelect();
        }
    }
}
