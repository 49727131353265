import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { GetCarServiceEntriesEvent } from 'src/app/core/app-store/events/car-service.events';
import { NgxSpinnerService } from 'ngx-spinner';

export class GetCarServiceListDashboardActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent, appState: Store<store.State>, private spinner: NgxSpinnerService) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(): any {
        if (this.canExecute()) {
            setTimeout(() => {
                this.spinner.show('car-service-list-dashboard');
            }, 0);
            this.appState.dispatch(new GetCarServiceEntriesEvent(() => {
                this.spinner.hide('car-service-list-dashboard');
            }));
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

