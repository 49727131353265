import { BaseFormDI } from 'src/app/core/shared/base/base.di';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {CnpReg, EmailRegForControl, noWhitespaceValidator, PhoneReg} from '../../../core/shared/form/custom-form.validator';
import { OrganizationClient_Dto } from '../../../core/app-dto/organization-client.dto';
import {Address} from "../../../core/app-dto/core.dto";

export class CarServiceClientDi extends BaseFormDI<OrganizationClient_Dto> {

    public isLegalEntity = false;
    public cnpCui: string = null;

    constructor(data: OrganizationClient_Dto = new OrganizationClient_Dto(null)) {
        super(new OrganizationClient_Dto(data));
        this.initForm();

        if (this.model.isLegalEntity) {
            this.form.get('cuiCnp').setValidators([Validators.required, noWhitespaceValidator]);
        } else {
            if (this.form.value.isUniqueId) {
                this.form.get('cuiCnp').disable({onlySelf: true});
                this.form.get('cuiCnp').setValidators([]);
            } else {
                this.form.get('cuiCnp').enable();
                this.form.get('cuiCnp').setValidators([Validators.required, noWhitespaceValidator, CnpReg()]);
            }
            this.form.get('cuiCnp').updateValueAndValidity();
        }
        this.form.get('isUniqueId').updateValueAndValidity();
        this.cnpCui = data.cuiCnp;
    }

    getModel(): OrganizationClient_Dto {
        this.model.isLegalEntity = this.form.value.isLegalEntity === 'true';
        this.model.cuiCnp = this.form.getRawValue().cuiCnp;
        this.model.isUniqueId = this.form.value.isUniqueId || false;
        this.model.phoneNumber = this.form.value.phoneNumber;
        this.model.email = this.form.value.email;
        this.model.willClientAcceptNotifications = this.form.value.willClientAcceptNotifications || false;

        if(this.model.isLegalEntity) {
          this.model.address = new Address(this.form.value.address);
          this.model.iban = this.form.value.iban;
          this.model.bank = this.form.value.bank;
          this.model.nrRegCi = this.form.value.nrRegCi;
          this.model.bank = this.form.value.bank;
        } else {
          this.model.address = null;
          this.model.iban = null;
          this.model.bank = null;
          this.model.nrRegCi = null;
          this.model.bank = null;
        }
        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
            isLegalEntity: new FormControl(this.model.isLegalEntity + '', Validators.compose([Validators.required])),
            cuiCnp: new FormControl(this.model.cuiCnp, Validators.compose([noWhitespaceValidator])),
            isUniqueId: new FormControl(this.model.isUniqueId, Validators.compose([])),
            nrRegCi: new FormControl(this.model.nrRegCi, Validators.compose([])),
            phoneNumber: new FormControl(this.model.phoneNumber, Validators.compose([Validators.required, PhoneReg()])),
            email: new FormControl(this.model.email, Validators.compose([EmailRegForControl()])),
            iban: new FormControl(this.model.iban, Validators.compose([])),
            bank: new FormControl(this.model.iban, Validators.compose([])),
            willClientAcceptNotifications: new FormControl(this.model.willClientAcceptNotifications, Validators.compose([])),
        });
    }

    public changeLegalForm(e: any) {
        if (e.target.value != "false") {
            if (this.form.value.isUniqueId) {
                this.form.get('cuiCnp').setValue(null);
            }
            this.form.get('iban').setValidators([]);
            this.form.get('nrRegCi').setValidators([]);
            this.form.get('cuiCnp').enable();
            this.form.get('cuiCnp').setValidators([Validators.required, noWhitespaceValidator]);
            this.isLegalEntity = true;
        } else {
            if (this.form.value.isUniqueId) {
                this.form.get('cuiCnp').setValue(this.cnpCui);
                this.form.get('cuiCnp').disable({onlySelf: true});
                this.form.get('cuiCnp').setValidators([]);
            } else {
                this.form.get('cuiCnp').enable();
                this.form.get('cuiCnp').setValidators([Validators.required, noWhitespaceValidator, CnpReg()]);
            }
            this.form.get('nrRegCi').setValidators([]);
            this.form.get('iban').setValidators([]);
            this.isLegalEntity = false;
        }
        this.form.get('iban').updateValueAndValidity();
        this.form.get('nrRegCi').updateValueAndValidity();
        this.form.get('cuiCnp').updateValueAndValidity();
        this.form.get('isUniqueId').updateValueAndValidity();
    }

    resetAnafUsedFields() {
        this.model.id = null;
        this.form.get('nrRegCi').setValue(null);
        this.form.get('phoneNumber').setValue(null);
        const address = new Address(null);
        this.model.address = address;
    }

    changeUniqueIdState(e: any) {
        if (e.target.checked) {
            this.form.get('cuiCnp').setValue(this.cnpCui);
            this.form.get('cuiCnp').disable({onlySelf: true});
            this.form.get('cuiCnp').setValidators([]);
        } else {
            this.form.get('cuiCnp').setValue(null);
            this.form.get('cuiCnp').enable();
            this.form.get('cuiCnp').setValidators([Validators.required, noWhitespaceValidator, CnpReg()]);
        }
        this.form.get('cuiCnp').updateValueAndValidity();
        this.form.get('isUniqueId').updateValueAndValidity();
    }
}
