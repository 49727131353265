import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiErrorDto } from '../utils/api-error.dto';
import { ConfigService } from '../../core/app-config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ActiveDataTableParameters, PagedResult } from '../../shared/datatable/datatable.helpers';
import { Supply_Dto } from '../../app-dto/supply.dto';
import { ErrorHelpers } from '../../shared/error.helpers';
import {Supplier_Dto} from "../../app-dto/supplier.dto";

class SupplyApiRoutes {
    public static route_for_GET_SUPPLIES_PAGINATED() {
        return '/api/supply/paginated';
    }
    public static route_for_GET_SUPPLIES_WITH_UNPAID_INVOICE_PAGINATED() {
        return '/api/supply/all-unpaid-invoice';
    }
    public static route_for_GET_SUPPLY(supplyId: string) {
        return `/api/supply/${supplyId}`;
    }
    public static route_for_REGISTER_SUPPLY() {
        return '/api/supply/';
    }
    public static route_for_EDIT_SUPPLY() {
        return '/api/supply/';
    }
    public static route_for_DELETE_SUPPLY(supplyId: string) {
        return `/api/supply/${supplyId}`;
    }
    public static route_for_ACTIVATE_SUPPLY(supplyId: string) {
        return `/api/supply/${supplyId}/activate`;
    }
    public static route_for_DEACTIVATE_SUPPLY(supplyId: string) {
        return `/api/supply/${supplyId}/deactivate`;
    }
    public static route_for_GET_SUPPLIES() {
        return `/api/supply/search`;
    }
    public static route_for_GET_SUPPLY_NOTE_LINK(supplyId: string) {
        return `/api/supply/supply-note/${supplyId}`;
    }
    public static route_for_MARK_SUPPLY_AS_PAID(supplyId: string) {
        return `/api/supply/${supplyId}/paid`;
    }
    public static route_for_MARK_SUPPLY_AS_NOT_PAID(supplyId: string) {
        return `/api/supply/${supplyId}/not-paid`;
    }
}

@Injectable()
export class SupplyApiService extends BaseApiService {

    constructor(private http: HttpClient, configService: ConfigService) {
        super(configService);
    }

    u_registerSupply(supplyData: Supply_Dto): Observable<Supply_Dto> {
        const url = this.configService.getAPIBaseUrl() + SupplyApiRoutes.route_for_REGISTER_SUPPLY();

        return this.http.post(url, supplyData)
            .map((res: any) => {
                return new Supply_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_editSupply(supplyData: Supply_Dto): Observable<Supply_Dto> {
        const url = this.configService.getAPIBaseUrl() + SupplyApiRoutes.route_for_EDIT_SUPPLY();

        return this.http.put(url, supplyData)
            .map((res: any) => {
                return new Supply_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getFilteredSuppliesPaginated(data: ActiveDataTableParameters, isStorno): Observable<PagedResult<Supply_Dto>> {
        const url = this.configService.getAPIBaseUrl() + SupplyApiRoutes.route_for_GET_SUPPLIES_PAGINATED() +
            '?' + data.toUrlParameters() + '&isStorno=' + isStorno;

        return this.http.get(url)
            .map((res: PagedResult<Supply_Dto>) => {
                return new PagedResult<Supply_Dto>(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getFilteredSuppliesWithUnpaidInvoicePaginated(data: ActiveDataTableParameters): Observable<PagedResult<Supply_Dto>> {
        const url = this.configService.getAPIBaseUrl() + SupplyApiRoutes.route_for_GET_SUPPLIES_WITH_UNPAID_INVOICE_PAGINATED() + '?' + data.toUrlParameters();

        return this.http.get(url)
            .map((res: PagedResult<Supply_Dto>) => {
                return new PagedResult<Supply_Dto>(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getSupply(supplyId: string): Observable<Supply_Dto> {
        const url = this.configService.getAPIBaseUrl() + SupplyApiRoutes.route_for_GET_SUPPLY(supplyId);

        return this.http.get(url)
            .map((res: any) => {
                return new Supply_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getSupplies(isStorno: boolean, supplierId: string): Observable<Supply_Dto[]> {
        const url = this.configService.getAPIBaseUrl() + SupplyApiRoutes.route_for_GET_SUPPLIES() +
            `?isStorno=${isStorno}&supplierId=${supplierId}`;

        return this.http.get(url)
            .map((res: Supply_Dto[]) => {
                return res;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deleteSupply(supplyId: string): Observable<Supply_Dto> {
        const url = this.configService.getAPIBaseUrl() + SupplyApiRoutes.route_for_DELETE_SUPPLY(supplyId);

        return this.http.delete(url)
            .map((res: any) => {
                return new Supply_Dto(null);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_activateSupply(supplyId: string): Observable<Supply_Dto> {
        const url = this.configService.getAPIBaseUrl() + SupplyApiRoutes.route_for_ACTIVATE_SUPPLY(supplyId);

        return this.http.put(url, {})
            .map((res: any) => {
                return new Supply_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_deactivateSupply(supplyId: string): Observable<Supply_Dto> {
        const url = this.configService.getAPIBaseUrl() + SupplyApiRoutes.route_for_DEACTIVATE_SUPPLY(supplyId);

        return this.http.put(url, {})
            .map((res: any) => {
                return new Supply_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_getSupplyNoteLink(supplyId: string): Observable<string> {
        const url = this.configService.getAPIBaseUrl() + SupplyApiRoutes.route_for_GET_SUPPLY_NOTE_LINK(supplyId);

        return this.http.get(url)
            .map((res: any) => {
                return res.data;
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_markSupplyAsPaid(supplyId: string): Observable<Supply_Dto> {
        const url = this.configService.getAPIBaseUrl() + SupplyApiRoutes.route_for_MARK_SUPPLY_AS_PAID(supplyId);

        return this.http.put(url, {})
            .map((res: any) => {
                return new Supply_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }

    u_markSupplyAsNotPaid(supplyId: string): Observable<Supply_Dto> {
        const url = this.configService.getAPIBaseUrl() + SupplyApiRoutes.route_for_MARK_SUPPLY_AS_NOT_PAID(supplyId);

        return this.http.put(url, {})
            .map((res: any) => {
                return new Supply_Dto(res);
            })
            .catch<any, any>((err: ApiErrorDto, o) => {
                return ErrorHelpers.throwError(err);
            });
    }
}
