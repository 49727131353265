import {BaseDI} from 'src/app/core/shared/base/base.di';
import {Draft_Dto, DraftType} from '../../../core/app-dto/draft.dto';

export class DraftsItemDi extends BaseDI<Draft_Dto> {
    public computedType: string = '';

    constructor(data: any) {
        super(new Draft_Dto(data));
        if (this.model.type == DraftType.CarServiceEntry)
            this.computedType = 'Intrare in service';
        else if (this.model.type == DraftType.OfferEntry)
            this.computedType = 'Oferta';
    }

    getModel(): Draft_Dto {
        return this.model;
    }
}
