import {Injectable} from "@angular/core";
import {Actions, Effect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import * as store from "../../core/app-store";
import {Observable} from "rxjs";
import {
    Do_GenericErrorEvent,
    Do_GenericSuccessEvent,
    EventWithScreenLoading
} from "../../core/app-store/events/base.events";
import {map, switchMap} from "rxjs/operators";
import {PagedResult} from "../../core/shared/datatable/datatable.helpers";
import {ObjectValidators} from "../../core/shared/object.validators";
import {SuperAdminLogsApiService} from '../../core/api-integration/services/super-admin-logs.api.service';
import {GetSuperAdminLogsPagedResultEvent} from '../../core/app-store/events/super-admin-logs.events';
import {Organization_Dto, SuperAdminLogs_Dto} from '../../core/app-dto/super-admin-logs.dto';

@Injectable()
export class SuperAdminLogsUIEventProcessor {

    constructor(
        private actions$: Actions,
        private appState$: Store<store.State>,
        private apiService: SuperAdminLogsApiService
    ) {
    }

    @Effect()
    GetSuperAdminLogsPagedResultEvent$: Observable<any> = this.actions$
        .pipe(
            ofType(GetSuperAdminLogsPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetSuperAdminLogsPagedResultEvent.identifier),
            map((action: GetSuperAdminLogsPagedResultEvent) => action),
            switchMap((action: GetSuperAdminLogsPagedResultEvent) => {
                return new Observable((observer) => {
                    this.apiService.u_getFilteredSuperAdminLogsPaginated(action.parameters, action.organizationFilter, action.from, action.to)
                        .subscribe((res: PagedResult<SuperAdminLogs_Dto>) => {
                            if (ObjectValidators.isValidObject(res)) {
                                observer.next(new Do_GenericSuccessEvent<PagedResult<SuperAdminLogs_Dto>>(res, action));
                            }
                            observer.complete();
                        }, (error: string) => {
                            observer.next(new Do_GenericErrorEvent<string>(error, action));
                            observer.complete();
                        });
                });
            })
        );
}
