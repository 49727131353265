import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { BaseWebComponent } from 'src/app/core/shared/base/base.component';
import { IdName_Dto } from 'src/app/core/app-dto/misc.dto';
import { Car_Dto } from 'src/app/core/app-dto/car.dto';
import { SearchComponent } from 'src/app/core/shared/components/search/search.component';
import { ObjectValidators } from 'src/app/core/shared/object.validators';
import { SearchEventActionProxy } from './search-event.action-proxy';
import { EventSearchSandbox } from './event-serach.sandbox';
import { CalendarEvent_Dto } from 'src/app/core/app-dto/calendar-event.dto';
import * as moment from 'moment';
import {isValidString} from "../../../core/shared/helpers/common.helpers";
import * as escapeStringRegexp from "escape-string-regexp";

@Component({
  selector: 'app-event-search',
  templateUrl: './event-search.component.html',
  styleUrls: ['./event-search.component.scss']
})
export class EventSearchComponent extends BaseWebComponent implements OnInit, OnDestroy {
  public searchCalendarAP: SearchEventActionProxy;

  public startDate: Date;
  public endDate: Date;

  public items: Array<IdName_Dto> = [];
  public isLoading: boolean = false;

  @Output() itemSelected = new EventEmitter<CalendarEvent_Dto>();

  @ViewChild(SearchComponent, { static: false }) searchComponent: SearchComponent;

  constructor(
    public sandbox: EventSearchSandbox
  ) {
    super(sandbox);
    this.searchCalendarAP = new SearchEventActionProxy(this, sandbox.appState);
    let date = new Date();
    this.startDate = moment(date).startOf('day').toDate();
    this.endDate = moment(date).endOf('day').toDate();
  }

  ngOnInit() {
    this.items = [];
    this.search('');
    this.initialize((data: Array<IdName_Dto>) => {
      this.items = data;
      this.dataLoaded = true;
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  public search(value: string) {
    this.isLoading = true;
    if(isValidString(value)){
      value = escapeStringRegexp(value.trim())
    }
    this.searchCalendarAP.execute(this.startDate, this.endDate, value, () => {
      this.isLoading = false;
    });
  }

  public selected(value: IdName_Dto) {
    if (ObjectValidators.isValidObject(value)) {
      let data = this.sandbox.originalCalendarSearchList.find(r => r.id == value.id);
      if (this.itemSelected != null) {
        this.itemSelected.emit(data);
      }
    } else {
      if (this.itemSelected != null) {
        this.itemSelected.emit(null);
      }
    }
  }

  clearSelect() {
    this.searchComponent.clearSelect();
  }
}
