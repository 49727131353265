import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { BaseWebComponent, BaseWebComponentWithBreadcrumb } from 'src/app/core/shared/base/base.component';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {GetCarServiceDetailsActionProxy} from './get-car-service-details.action-proxy';
import {CarServiceDetailsSandbox} from './car-service-details.sandbox';
import {CarServiceDetailsDi} from './car-service-details.di';
import {ResetSection} from 'src/app/core/app-store/events/base.events';
import {CarService_Dto} from '../../../core/app-dto/car-service.dto';
import {DateHelpers} from '../../../core/shared/date.helpers';
import {BsModalService} from 'ngx-bootstrap';
import {FileImageWrapper} from '../car-service-photos/car-service-photos.component';
import {FileUploadTarget} from 'src/app/core/app-dto/core.dto';
import {FinalizeCarServiceActionProxy} from "./finalize-car-service.action-proxy";
import {DeleteCarServiceEntryActionProxy} from "./delete-car-service-entry.action-proxy";
import {CarServiceEntryInvoiceButtonInputComponent} from "../../../core/core/components/car-service-entry-invoice-button/car-service-entry-invoice-button.component";
import {
  AddCarInServiceActionEvent,
  DeleteCarInServiceActionEvent,
  DeleteCarServiceCarPartEvent,
  DeleteCarServiceWorkmanshipEvent,
  EditCarServiceClientEvent,
  FinalizeCarServiceEvent,
  UpdateCarInServiceActionEvent,
  UpdateCarServiceAddCarPartEvent,
  UpdateCarServiceAddWorkmanshipEvent,
  UpdateCarServiceUpdateWorkmanshipEvent
} from "../../../core/app-store/events/car-service.events";
import {ConfirmationDialogService} from "../../../core/core/dialog/confirmation-dialog.service";
import {InvoiceType} from "../../../core/app-dto/invoice.dto";
import {GenerateInvoiceForCarServiceEntry} from "../../../core/app-store/events/invoices.events";
import { CarAppointment_Dto } from '../../../core/app-dto/car-appointment.dto';
import { AddEditCalendarEventWizardComponent } from '../../../calendar/components/add-edit-calendar-event-wizard/add-edit-calendar-event-wizard.component';
import { AddEditCarInServiceWizardComponent } from '../add-edit-car-in-service-wizard/add-edit-car-in-service-wizard.component';
import {CarServiceSaveFinalInspectionComponent} from "../car-service-save-final-inspection/car-service-save-final-inspection.component";
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";

@Component({
  selector: 'app-car-service-details',
  templateUrl: './car-service-details.component.html',
  styleUrls: ['./car-service-details.component.scss']
})
export class CarServiceDetailsComponent extends BaseWebComponentWithBreadcrumb implements OnInit, OnDestroy {
  public getCarServiceAP: GetCarServiceDetailsActionProxy;
  public finalizeCarServiceEntryAP: FinalizeCarServiceActionProxy;
  public deleteCarServiceEntryAP: DeleteCarServiceEntryActionProxy;
  public carId: string = null;
  public imgUrls: Array<FileImageWrapper> = [];
  public tab: number = 1;
  public fileUploadTarget = FileUploadTarget;
  public invoiceInput: CarServiceEntryInvoiceButtonInputComponent = null;
  public paymentInAdvanceInvoiceInput: CarServiceEntryInvoiceButtonInputComponent = null;
  public onlyCarsThatLeftTheService: boolean = false;

  constructor(
    public sandbox: CarServiceDetailsSandbox,
    public activatedRoute: ActivatedRoute,
    private modalService: BsModalService, public confirmationDialogService: ConfirmationDialogService,
    private router: Router,
    public rulesProvider:AppUserBusinessRuleProvider
  ) {
    super(sandbox, ResetSection.Car);
    this.getCarServiceAP = new GetCarServiceDetailsActionProxy(this, sandbox.appState);
    this.finalizeCarServiceEntryAP = new FinalizeCarServiceActionProxy(this, sandbox.appState);
    this.deleteCarServiceEntryAP = new DeleteCarServiceEntryActionProxy(this, sandbox.appState);
    this.di = new CarServiceDetailsDi();

  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id !== undefined) {
        this.carId = params.id;
        this.getCarServiceAP.execute(this.carId);
      }
    });

    this.initialize((data: CarService_Dto) => {
      if (data.status.name === 'Iesita din service') {
        this.setBreadcrumbAP.execute([
          {text: 'Acasa', url: '/dashboard'},
          {text: 'Masini iesite din service', url: '/car-service/cars/exit'},
          {text: data.car.registrationNumber}]);
      } else {
        this.setBreadcrumbAP.execute([
          {text: 'Acasa', url: '/dashboard'},
          {text: 'Masini in service', url: '/car-service/cars/all'},
          {text: data.car.registrationNumber}]);
      }

      this.di = new CarServiceDetailsDi(data);
      this.invoiceInput = new CarServiceEntryInvoiceButtonInputComponent(data.id, data.organizationId, InvoiceType.CarServiceEntry,
        [FinalizeCarServiceEvent.identifier, AddCarInServiceActionEvent.identifier, UpdateCarInServiceActionEvent.identifier,
          UpdateCarServiceAddWorkmanshipEvent.identifier, UpdateCarServiceUpdateWorkmanshipEvent.identifier,
          DeleteCarServiceWorkmanshipEvent.identifier, DeleteCarInServiceActionEvent.identifier,
          EditCarServiceClientEvent.identifier, DeleteCarServiceCarPartEvent.identifier, UpdateCarServiceAddCarPartEvent.identifier,
          GenerateInvoiceForCarServiceEntry.identifier
        ], data.rules.canGenerateInvoice.value);
      this.paymentInAdvanceInvoiceInput = new CarServiceEntryInvoiceButtonInputComponent(data.id, data.organizationId, InvoiceType.CarServiceEntryAdvancePayment,
        [FinalizeCarServiceEvent.identifier, AddCarInServiceActionEvent.identifier, UpdateCarInServiceActionEvent.identifier,
          UpdateCarServiceAddWorkmanshipEvent.identifier, UpdateCarServiceUpdateWorkmanshipEvent.identifier,
          DeleteCarServiceWorkmanshipEvent.identifier, DeleteCarInServiceActionEvent.identifier,
          EditCarServiceClientEvent.identifier, DeleteCarServiceCarPartEvent.identifier, UpdateCarServiceAddCarPartEvent.identifier,
          GenerateInvoiceForCarServiceEntry.identifier
        ], false);
      this.dataLoaded = true;
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  dateToString(date: Date) {
    return DateHelpers.dateToString(date, 'DD/MM/YYYY');
  }

  updateTab(i: number) {
    this.tab = i;
  }

  finalizeCarServiceEntry() {
    if ((<CarService_Dto>this.di.getModel()).rules.finalInspectionDone.value == false) {
      this.confirmationDialogService.confirm(
        "Inspectia finala",
        "Inspectia finala a masinii nu a fost efectuata. Doriti sa continuati?",
        'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
        if (confirmed) {
          this.finalizeCarServiceEntryAP.execute(this.di.model.id);
        }
      });
    } else
      this.finalizeCarServiceEntryAP.execute(this.di.model.id);
  }

  deleteCarServiceEntry() {
    this.confirmationDialogService.confirm(
      "Stergere intrare",
      "Urmeaza sa stergeti intrarea in service. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        this.deleteCarServiceEntryAP.execute(this.di.model.id, () => {
          if (this.di.model.rules.canFinishCar.value)
            this.router.navigate(['/car-service/cars/all']);
          else
            this.router.navigate(['/car-service/cars/exit']);
        });
      }
    });
  }

  finalInspection(){
    const modalRef = this.modalService.show(CarServiceSaveFinalInspectionComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = this.di.model;
  }
}
