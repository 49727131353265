import { Injectable } from '@angular/core';
import * as store from '../../../core/app-store/index';
import { select, Store } from '@ngrx/store';
import { BaseSandbox } from 'src/app/core/shared/base/base.sandbox';
import { DraftsItemDi } from './drafts-item.di';
import {Draft_Dto} from '../../../core/app-dto/draft.dto';

@Injectable()
export class DraftsSandbox extends BaseSandbox {
    public drafts$ = this.appState$.pipe(select(store.getDraftsListing));
    public timestamp$ = this.appState$.pipe(select(store.getDraftTimestamp));
    public items: Array<DraftsItemDi> = [];
    private lastRefreshTime : number = 0;

    constructor(protected appState$: Store<store.State>) {
        super(appState$);
    }

    public clearSandboxState() {
    }

    public initialize( resetCallback:()=>void): void {
        this.clearSandboxState();
        this.subscriptions.push(this.drafts$.subscribe((data: Draft_Dto[]) => {
            if (data != null) {
                this.items = data.map(r => new DraftsItemDi(r));
            }
        }));

      this.subscriptions.push(this.timestamp$.subscribe((result: number) => {
        if (result > this.lastRefreshTime) {
          this.lastRefreshTime = result;
          resetCallback();
        }

      }));
    }
}
