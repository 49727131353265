import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseFormModelComponent } from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import { Store } from '@ngrx/store';
import { RegisterOrganizationClientActionProxy } from './register-organization-client.action-proxy';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { EditOrganizationClientActionProxy } from './edit-organization-client.action-proxy';
import { GetOrganizationClientActionProxy } from '../organization-client-details/get-organization-client.action-proxy';
import { ActivatedRoute } from '@angular/router';
import { SaveOrganizationClientSandbox } from './save-organization-client.sandbox';
import { OrganizationClient_Dto } from '../../../core/app-dto/organization-client.dto';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { ObjectValidators } from '../../../core/shared/object.validators';
import { OrganizationClientFormComponent } from '../organization-client-form/organization-client-form.component';
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";


@Component({
  selector: 'app-save-organization-client',
  templateUrl: './save-organization-client.component.html',
  styleUrls: ['./save-organization-client.component.scss']
})
export class SaveOrganizationClientComponent extends BaseFormModelComponent<OrganizationClient_Dto> implements OnInit, OnDestroy {
  public registerOrganizationClientAP: RegisterOrganizationClientActionProxy;
  public editOrganizationClientAP: EditOrganizationClientActionProxy;
  public getOrganizationClientAP: GetOrganizationClientActionProxy;
  public title: string;
  public onClose: Subject<boolean>;

  public data: OrganizationClient_Dto = null;

  @ViewChild(OrganizationClientFormComponent, { static: false })
  private organizationClientFormComponent: OrganizationClientFormComponent;

  constructor(public appState: Store<store.State>,
    public sandbox: SaveOrganizationClientSandbox,
    private activeModal: BsModalRef,
    public rulesProvider:AppUserBusinessRuleProvider,
    public activatedRoute: ActivatedRoute) {
    super(sandbox, ResetSection.OrganizationClient);
    this.registerOrganizationClientAP = new RegisterOrganizationClientActionProxy(this, sandbox.appState);
    this.editOrganizationClientAP = new EditOrganizationClientActionProxy(this, sandbox.appState);
    this.getOrganizationClientAP = new GetOrganizationClientActionProxy(this, sandbox.appState);
  }

  public diInitialize(data: OrganizationClient_Dto) {
    this.data = data;
    this.title = ObjectValidators.isValidObject(data) ? 'Editare client' : 'Adauga client';
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.organizationClientFormComponent.accept();
    this.formSubmitted = true;

  }

  public formSaved(model: OrganizationClient_Dto) {
    if (model.id) {
      this.editOrganizationClientAP.execute(model);
    } else {
      this.registerOrganizationClientAP.execute(model);
    }

    this.onClose.next(true);
    this.activeModal.hide();
  }
}
