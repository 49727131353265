import {Component, OnInit} from '@angular/core';
import {AppUserBusinessRuleProvider} from 'src/app/core/core/business-rule-provider/app-user.business-rule.provider';
import {ConfigService} from 'src/app/core/core/app-config.service';
import {BaseWebComponentWithBreadcrumb} from '../../../core/shared/base/base.component';
import {ResetSection} from '../../../core/app-store/events/base.events';
import {DashboardSandbox} from './dashboard.sandbox';
import {InvoiceRequestType} from '../../../invoice/components/invoice/invoice.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseWebComponentWithBreadcrumb implements OnInit {
    public isServicePlatformWidgetSelected: boolean = true;
    public invoiceType: InvoiceRequestType = InvoiceRequestType.Organization;
    public organizationId: string = null;

    constructor(public rulesProvider: AppUserBusinessRuleProvider,
                public configService: ConfigService,
                public sandbox: DashboardSandbox) {
        super(sandbox, ResetSection.Dashboard);
    }

    ngOnInit() {
        this.sandbox.initialize(() => {
            this.organizationId = this.sandbox.currentUser.organization.id;
            this.dataLoaded = true;
        });
        this.setBreadcrumbAP.execute([
            {text: 'Acasa'}]);
    }

    servicePlatformSelected() {
        if (this.isServicePlatformWidgetSelected == false) {
            this.isServicePlatformWidgetSelected = true;
        }
    }

    mechanicsSelected() {
        if (this.isServicePlatformWidgetSelected == true) {
            this.isServicePlatformWidgetSelected = false;
        }
    }
}
