import { Observable } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as store from '../../core/app-store';
import 'rxjs-compat/add/operator/switchMap';
import { map, switchMap } from 'rxjs/operators';
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  EventWithScreenLoading,
  SeamlessEvent
} from 'src/app/core/app-store/events/base.events';
import { PagedResult } from 'src/app/core/shared/datatable/datatable.helpers';
import { ObjectValidators } from 'src/app/core/shared/object.validators';

import {SmsApiService} from "../../core/api-integration/services/sms-api.service";
import {
  BuySMSForOrganizationAsPlatformAdminEvent,
  GetAvailablePackagesForSMSEvent, GetAvailablePackagesForSMSForPlatformAdminEvent,
  GetSMSBoughtPagedResultEvent,
  GetSMSSentPagedResultEvent
} from "../../core/app-store/events/sms.events";
import {
  SMSBoughtTransaction,
  SMSPackageType,
  SMSSentTransaction
} from "../../core/app-dto/sms.dto";
import {GetAvailableOptionsForSuperadminActionProxy} from "../components/buy-sms-as-platform-admin/get-available-options-for-superadmin.action-proxy";
import {Organization} from "../../core/app-dto/organization.dto";

@Injectable()
export class SmsUIEventProcessor {

  constructor(
    private actions$: Actions,
    private appState$: Store<store.State>,
    private apiService: SmsApiService,
  ) {
  }

  @Effect()
  GetSmsSentPagedResultEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetSMSSentPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetSMSSentPagedResultEvent.identifier),
      map((action: GetSMSSentPagedResultEvent) => action),
      switchMap((action: GetSMSSentPagedResultEvent) => {
        return new Observable((observer) => {
          this.apiService.u_getFilteredSentSMSPaginated(action.parameters)
            .subscribe((res: PagedResult<SMSSentTransaction>) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<PagedResult<SMSSentTransaction>>(res, action));
              }
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );
  @Effect()
  GetSmsBoughtPagedResultEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetSMSBoughtPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetSMSBoughtPagedResultEvent.identifier),
      map((action: GetSMSBoughtPagedResultEvent) => action),
      switchMap((action: GetSMSBoughtPagedResultEvent) => {
        return new Observable((observer) => {
          this.apiService.u_getFilteredBoughtSMSPaginated(action.parameters)
            .subscribe((res: PagedResult<SMSBoughtTransaction>) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<PagedResult<SMSBoughtTransaction>>(res, action));
              }
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  GetGetAvailableSMSPackagesEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetAvailablePackagesForSMSEvent.identifier, SeamlessEvent.identifier + GetAvailablePackagesForSMSEvent.identifier),
      map((action: GetAvailablePackagesForSMSEvent) => action),
      switchMap((action: GetAvailablePackagesForSMSEvent) => {
        return new Observable((observer) => {
          this.apiService.u_getAvailablePackages()
            .subscribe((res: Array<SMSPackageType>) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<Array<SMSPackageType>>(res, action));
              }
              if(action.callback) action.callback(res);
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  GetAvailableSMSPackagesForPlatformAdminEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetAvailablePackagesForSMSForPlatformAdminEvent.identifier, SeamlessEvent.identifier + GetAvailablePackagesForSMSForPlatformAdminEvent.identifier),
      map((action: GetAvailablePackagesForSMSForPlatformAdminEvent) => action),
      switchMap((action: GetAvailablePackagesForSMSForPlatformAdminEvent) => {
        return new Observable((observer) => {
          this.apiService.u_getAvailablePackagesForPlatformAdmin()
            .subscribe((res: Array<SMSPackageType>) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<Array<SMSPackageType>>(res, action));
              }
              if(action.callback) action.callback(res);
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

  @Effect()
  ChooseSMSPackagesForOrgAsPlatformAdminEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(BuySMSForOrganizationAsPlatformAdminEvent.identifier, EventWithScreenLoading.identifier + BuySMSForOrganizationAsPlatformAdminEvent.identifier),
      map((action: BuySMSForOrganizationAsPlatformAdminEvent) => action),
      switchMap((action: BuySMSForOrganizationAsPlatformAdminEvent) => {
        return new Observable((observer) => {
          this.apiService.u_buySMSForOrganizationAsPlatformAdmin(action.organizationId, action.packageId)
            .subscribe((res: Organization) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<Organization>(res, action));
              }
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );

}
