import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  BaseFormComponent,
  BaseWebComponent,
  BaseWebComponentWithBreadcrumb
} from 'src/app/core/shared/base/base.component';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { GetDepartmentActionProxy } from './get-department.action-proxy';
import { DepartmentDetailsSandbox } from './department-details.sandbox';
import { DepartmentDetailsDi } from './department-details.di';
import { ResetSection } from 'src/app/core/app-store/events/base.events';
import { Department_Dto } from '../../../core/app-dto/department.dto';
import { SaveDepartmentComponent } from '../save-department/save-department.component';
import { BsModalService } from 'ngx-bootstrap';
import { ActivateDepartmentActionProxy } from '../department-list/activate-department.action-proxy';
import { DeactivateDepartmentActionProxy } from '../department-list/deactivate-department.action-proxy';
import { DeleteDepartmentActionProxy } from '../department-list/delete-department.action-proxy';
import {ConfirmationDialogService} from "../../../core/core/dialog/confirmation-dialog.service";
import {Breadcrumb} from "../../../core/app-dto/breadcrumb.dto";

@Component({
  selector: 'app-department-details',
  templateUrl: './department-details.component.html',
  styleUrls: ['./department-details.component.scss']
})
export class DepartmentDetailsComponent extends BaseWebComponentWithBreadcrumb implements OnInit, OnDestroy {
  public getDepartmentAP: GetDepartmentActionProxy;
  public activateDepartmentAP: ActivateDepartmentActionProxy;
  public deactivateDepartmentAP: DeactivateDepartmentActionProxy;
  public deleteDepartmentAP: DeleteDepartmentActionProxy;
  public departmentId: string = null;

  constructor(
    public sandbox: DepartmentDetailsSandbox,
    public activatedRoute: ActivatedRoute,
    public router:Router, public confirmationDialogService: ConfirmationDialogService,
    private modalService: BsModalService
  ) {
    super(sandbox, ResetSection.Department);
    this.getDepartmentAP = new GetDepartmentActionProxy(this, sandbox.appState);
    this.activateDepartmentAP = new ActivateDepartmentActionProxy(this, sandbox.appState);
    this.deactivateDepartmentAP = new DeactivateDepartmentActionProxy(this, sandbox.appState);
    this.deleteDepartmentAP = new DeleteDepartmentActionProxy(this, sandbox.appState);
    this.di = new DepartmentDetailsDi();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id !== undefined) {
        this.departmentId = params.id;
        this.getDepartmentAP.execute(this.departmentId);
      }
    });

    this.initialize((data: Department_Dto) => {
      this.di = new DepartmentDetailsDi(data);
      this.setBreadcrumbAP.execute([ new Breadcrumb({text: 'Acasa', url: '/dashboard'}),new Breadcrumb({text: 'Departamente', url:'/department/all'}),
        new Breadcrumb({text:data.name})]);
      this.dataLoaded = true;
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  updateDepartment() {
    const modalRef = this.modalService.show(SaveDepartmentComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
    modalRef.content.dataInput = this.di.model;
  }

  activateDepartment(departmentId: string) {
    this.activateDepartmentAP.execute(departmentId);
  }

  deactivateDepartment(departmentId: string) {
    this.deactivateDepartmentAP.execute(departmentId);
  }

  deleteDepartment(departmentId: string) {
    this.confirmationDialogService.confirm(
      "Stergere departament",
      "Urmeaza sa stergeti departamentul. Sunteti sigur?",
      'Continua', 'Cancel', 'sm', true).subscribe((confirmed) => {
      if (confirmed) {
        this.deleteDepartmentAP.execute(departmentId, ()=>{
          this.router.navigate(['/department/all']);
        });
      }
    });

  }
}
