import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Address, Country} from "../../../app-dto/core.dto";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {StaticData} from "../../../app-dto/static-data.dto";
import {BaseLocationEntity} from "../../../app-dto/misc.dto";
import * as store from '../../../app-store';
import {isValidArrayAndHasElements, isValidObject} from "../../helpers/common.helpers";

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy {

  public countries: Array<BaseLocationEntity> = [];
  public selectedCountry: string = null;
  public regions: Array<BaseLocationEntity> = [];
  public selectedRegion: string = null;

  private originalCountries: Array<Country>;

  private stateSubscription;

  public addressForm: FormGroup;

  @Input('isOneColumnDisplay') isOneColumnDisplay = false;

  @Input('group') set group(data: FormGroup) {
    this.addressForm = data;
    if (isValidObject(this.addressForm)) {
      if (this.addressForm.get("address") == null) {
        this.addressForm.addControl("address", new FormGroup({
          city: new FormControl('', Validators.compose([])),
          country: new FormControl('', Validators.compose([Validators.required])),
          selectedCountry: new FormControl('', Validators.compose([Validators.required])),
          region: new FormControl('', Validators.compose([Validators.required])),
          selectedRegion: new FormControl('', Validators.compose([Validators.required])),
          address1: new FormControl('', Validators.compose([])),
          zipCode: new FormControl('', Validators.compose([])),
        }));
        if (this.originalCountries != null) {
          let romaniaDefaultCountry = null;
          if (isValidArrayAndHasElements(this.countries))
            romaniaDefaultCountry = this.countries.find(f => f.key === 'Romania');
          this.tryToSetNewAddress(null,  romaniaDefaultCountry != null ? romaniaDefaultCountry.id : null, null, null);
        }
      }
    }
  }

  public formSubmitted: boolean = false;

  @Input('formSubmitted') set submitted(value: boolean) {
    this.formSubmitted = value;
  }

  private initialData: Address;
  public addressModel: Address;

  @Input('address') set address(address: Address) {

    if (address != undefined && address != null) {
      this.initialData = new Address(address);
      this.addressModel = address;
      if (this.originalCountries != null) {
        this.tryToSetNewAddress(address, address.country != null && address.country.id != undefined ? address.country.id : this.originalCountries[0].id,
          address.region != null ? address.region.id : null, address.region != null && address.region.id == null ? address.region.code : null);
      }
    } else {
      if (this.originalCountries != null) {
        let romaniaDefaultCountry = null;
        if (isValidArrayAndHasElements(this.countries))
          romaniaDefaultCountry = this.countries.find(f => f.key === 'Romania');
        this.tryToSetNewAddress(address,  romaniaDefaultCountry != null ? romaniaDefaultCountry.id : null, null, null);
      }
    }
  }

  @Output()
  addressChanged = new EventEmitter<Address>();

  public staticData$ = this.appState$.select(store.getStaticData);

  constructor(private appState$: Store<store.State>, private cdr: ChangeDetectorRef) {
    this.addressModel = new Address(null);
    this.stateSubscription = this.staticData$.subscribe((staticData: StaticData) => {
      if (staticData != null) {
        this.originalCountries = staticData.countries;
        if (staticData.countries.length > 0) {
          this.countries = staticData.countries.map(c => new BaseLocationEntity(c));
          this.countries.sort((a, b) => a.name < b.name ? -1 : 1);
          if (this.initialData != null) {
            this.tryToSetNewAddress(this.initialData, this.initialData.country != null && this.initialData.country.id != undefined ? this.initialData.country.id : this.originalCountries[0].id,
              this.initialData.region != null ? this.initialData.region.id : null, this.initialData.region != null && this.initialData.region.id == null ? this.initialData.region.code : null);
          } else {
            let romaniaDefaultCountry = null;
            if (isValidArrayAndHasElements(this.countries))
              romaniaDefaultCountry = this.countries.find(f => f.key === 'Romania');
            this.tryToSetNewAddress(null, romaniaDefaultCountry != null ? romaniaDefaultCountry.id : null, null, null);
          }
        }

      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.stateSubscription != null) {
      this.stateSubscription.unsubscribe();
      if (this.addressForm != null) {
        this.addressForm.removeControl('address');
      }
    }
  }

  public tryGetCountryIdByName(name: string) {
    if (this.originalCountries != null && this.originalCountries != undefined && this.originalCountries.length > 0) {
      var existingCountry = this.originalCountries.find(d => d.name == name);
      return existingCountry.id;
    }
    return null;
  }

  public tryGetRegionIdByName(name: string) {
    if (this.originalCountries != null && this.originalCountries != undefined && this.originalCountries.length > 0) {
      for (var i = 0; i < this.originalCountries.length; i++) {
        var existingRegion = this.originalCountries[i].regions.find(d => name.indexOf(d.name) > 0);
        if (existingRegion != null) {
          return existingRegion.id;
        }
      }
    }
    return null;
  }

  public tryToSetNewAddress(address: Address, countryId: string, regionId: string, customRegion: string) {
    this.cdr.detach();
    var newAddress = new Address(address);
    var arrayOfRegions: Array<BaseLocationEntity> = [];
    newAddress.country = null;
    newAddress.region = null;
    if (countryId != null) {
      if (this.originalCountries != null && this.originalCountries != undefined && this.originalCountries.length > 0) {
        var existingCountry = this.originalCountries.find(d => d.id == countryId);
        if (existingCountry != null) {
          newAddress.country = new BaseLocationEntity(existingCountry);

          if (newAddress.country.key != "Romania") {
            arrayOfRegions = null;
            if (regionId == null && customRegion != null)
              newAddress.region = new BaseLocationEntity({
                id: null,
                key: customRegion,
                name: customRegion,
                description: '',
                code: customRegion
              });
            else
              newAddress.region = new BaseLocationEntity(null);
          } else {
            arrayOfRegions = existingCountry.regions;
            if (regionId != null) {
              var existingRegion = arrayOfRegions.find(d => regionId == d.id);
              if (existingRegion != null) {
                newAddress.region = new BaseLocationEntity(existingRegion);
              }
            }
          }
        }
      }
    }
    this.addressModel.address1 = newAddress.address1;
    this.addressModel.address2 = newAddress.address2;
    this.addressModel.city = newAddress.city;
    this.addressModel.country = newAddress.country;
    this.addressModel.region = newAddress.region;
    this.addressModel.zipCode = newAddress.zipCode;
    this.selectedCountry = newAddress.country != null ? newAddress.country.id : null;
    this.selectedRegion = newAddress.region != null ? newAddress.region.id != null ? newAddress.region.id : newAddress.region.code : null;
    this.regions = arrayOfRegions;


    this.cdr.reattach();
    this.setAddressValue();
    if (this.addressChanged != null) {
      this.addressChanged.emit(newAddress);
    }
  }

  private setAddressValue() {
    if (isValidObject(this.addressForm) && isValidObject(this.addressForm.controls.address)) {
      (<FormGroup>this.addressForm.controls.address).controls.city.setValue(this.addressModel.city);
      (<FormGroup>this.addressForm.controls.address).controls.address1.setValue(this.addressModel.address1);
      (<FormGroup>this.addressForm.controls.address).controls.country.setValue(this.addressModel.country);
      (<FormGroup>this.addressForm.controls.address).controls.region.setValue(this.addressModel.region);
      (<FormGroup>this.addressForm.controls.address).controls.zipCode.setValue(this.addressModel.zipCode);
      (<FormGroup>this.addressForm.controls.address).controls.selectedCountry.setValue(this.addressModel.country != null ? this.addressModel.country.id : null);
      (<FormGroup>this.addressForm.controls.address).controls.selectedRegion.setValue(this.addressModel.region != null ? this.addressModel.region.id != null ? this.addressModel.region.id : this.addressModel.region.code : null);

    }
  }

  public changedCountry(value: BaseLocationEntity): void {
    if (isValidObject(value) == false) this.tryToSetNewAddress(this.addressModel, null, null, null);
    else {
      //if (this.addressModel != null && this.addressModel.country != null && this.addressModel.country.id != value.id) {
      this.tryToSetNewAddress(this.addressModel, value.id, null, null);
      //}
    }
  }

  public changedRegion(value: BaseLocationEntity): void {
    if (isValidObject(value) == false) this.tryToSetNewAddress(this.addressModel, this.addressModel.country != null ? this.addressModel.country.id : null, null, null);
    else {
      //if (this.selectedCountry != value.id) {
      this.tryToSetNewAddress(this.addressModel, this.addressModel.country != null ? this.addressModel.country.id : null, value.id, null);
      //}
    }
  }

  isInvalidControl(control: AbstractControl): boolean {
    if (!control.valid && this.formSubmitted)
      return true;
    return false;
  }

  customRegionChanged(e: any) {
    this.addressModel.region = new BaseLocationEntity({
      id: null,
      key: e.target.value,
      name: e.target.value,
      description: '',
      code: e.target.value
    });
    if (isValidObject(this.addressForm) && isValidObject(this.addressForm.controls.address)) {
      (<FormGroup>this.addressForm.controls.address).controls.region.setValue(this.addressModel.region);
    }
    this.outputAddress();
  }

  outputAddress() {
    this.addressChanged.emit(this.addressModel);
  }
}

