import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {BaseFormComponent} from "../../../../core/shared/base/base.component";
import {FileImageWrapper} from "../../../../car-service/components/car-service-photos/car-service-photos.component";
import {Store} from "@ngrx/store";
import * as store from "../../../../core/app-store";
import {BsModalService} from "ngx-bootstrap";
import {Offer_Dto} from "../../../../core/app-dto/offer.dto";
import {AddOfferSummarySandbox} from "./add-offer-summary.sandbox";
import {AddOfferSummaryDi} from "./add-offer-summary.di";
import {UpdateOfferCarDataComponent} from "../../update-offer-car-data/update-offer-car-data.component";
import {UpdateOfferClientDataComponent} from "../../update-offer-client-data/update-offer-client-data.component";
import {getFormattedIdNameList} from "../../../../core/shared/helpers/common.helpers";

@Component({
    selector: 'app-add-offer-summary',
    templateUrl: './add-offer-summary.component.html',
    styleUrls: ['./add-offer-summary.component.scss']
})
export class AddOfferSummaryComponent extends BaseFormComponent implements OnInit, OnDestroy {

    @Output()
    modelSaved = new EventEmitter<Offer_Dto>();

    @Input('offer') offer: Offer_Dto;
    @Input('urls') urls: Array<FileImageWrapper>;

    public isReadOnlyValue:boolean;
    @Input() set isReadOnly(value:boolean){
        this.isReadOnlyValue = value;
    }

    constructor(public appState: Store<store.State>,
                private modalService: BsModalService,
                public sandbox: AddOfferSummarySandbox) {
        super(sandbox);
    }

    ngOnInit() {
        this.di = new AddOfferSummaryDi(null);
    }

    ngOnDestroy() {
        this.destroy();
    }

    editClient() {
        const modalRef = this.modalService.show(UpdateOfferClientDataComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
        modalRef.content.dataInput = this.di.model;
    }

    editCar() {
        const modalRef = this.modalService.show(UpdateOfferCarDataComponent, { backdrop: 'static', keyboard: false, class: 'modal-xl' });
        modalRef.content.dataInput = this.di.model;
    }

    formatFuels(fuelsList) {
        return getFormattedIdNameList(fuelsList);
    }
}
