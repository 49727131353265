import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import * as store from '../../../core/app-store/index';
import {Store} from '@ngrx/store';
import {BaseFormModelComponent} from 'src/app/core/shared/base/base.component';
import {CarServiceCarSandbox} from './car-service-car.sandbox';
import {CarServiceCarDi} from './car-service-car.di';
import {OrganizationClientSearchComponent} from 'src/app/organization-client/components/organization-client-search/organization-client-search.component';
import {range, reverse} from 'lodash';
import * as moment from 'moment';
import {StaticDataSelectorInput} from '../../../core/shared/components/static-data-selector/static-data-selector.component';
import {Options} from 'ng5-slider';
import {Car_Dto} from '../../../core/app-dto/car.dto';
import {CarService_Dto} from '../../../core/app-dto/car-service.dto';
import {FileAttachment, FileUploadTarget} from '../../../core/app-dto/core.dto';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {FileImageWrapper} from '../car-service-photos/car-service-photos.component';
import {isValidObject, makeInputPositive, makeInputPositiveInteger} from '../../../core/shared/helpers/common.helpers';
import { CarSearchComponent } from '../../../car/components/car-search/car-search.component';
import {isNullOrUndefined} from 'util';
import {GetClientForCarActionProxy} from '../car-service-client/get-client-for-car.action-proxy';

@Component({
  selector: 'app-car-service-car',
  templateUrl: './car-service-car.component.html',
  styleUrls: ['./car-service-car.component.scss']
})
export class CarServiceCarComponent extends BaseFormModelComponent<CarService_Dto> implements OnInit, OnDestroy {

  public carSearch: string = '';
  public years: number[];
  public filesUrls: Array<FileImageWrapper> = [];
  public fuelTypesDataInput: StaticDataSelectorInput;
  public fileUploadTarget = FileUploadTarget;
  options: Options = {
    floor: 0,
    ceil: 100
  };
  public getClientForCarAP: GetClientForCarActionProxy;

  @Output()
  modelSaved = new EventEmitter<CarService_Dto>();
  @Output()
  eventSelectedOutput = new EventEmitter<string>();

  private isFromAppointmentValue: boolean = false;
  @Input('isFromAppointment') set isFromAppointment(value: boolean) {
    this.isFromAppointmentValue = value;
  }

  @ViewChild(CarSearchComponent, {static: false}) carSearchComponent: CarSearchComponent;

  constructor(public appState: Store<store.State>,
              public sandbox: CarServiceCarSandbox) {
    super(sandbox);
    this.getClientForCarAP = new GetClientForCarActionProxy(this, sandbox.appState);
  }

  public diInitialize(data: CarService_Dto) {
    this.di = new CarServiceCarDi(data);
    if (isValidObject(data) && isValidObject(data.car)) {
      this.carSearch = data.car.registrationNumber;
    }
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.years = reverse(range(1980, moment().year() + 1));
    this.fuelTypesDataInput = {
      form: this.di.form,
      type: 'fuelTypes',
      formControlName: 'fuel',
      isRequired: true,
      label: 'Combustibil',
      isReadOnly: false
    };
    this.initialize((data: Car_Dto) => {
      if (data) {
        this.carSearch = this.sandbox.carForClient.registrationNumber;
        this.di.model.car = this.sandbox.carForClient;
      } else {
        if (this.di != null && this.di.getModel().car != null) {
          const initialCarService: CarService_Dto = this.di.getModel();
          if (initialCarService.carServiceEntryData != null) {
            this.di.form.controls['actualNoOfKm'].setValue(initialCarService.carServiceEntryData.actualNoOfKm);
            this.di.form.controls['actualFuel'].setValue(initialCarService.carServiceEntryData.fuel);
          }
          const initialCar = this.di.getModel().car;

          this.carServiceCarSelected(initialCar);
          this.carSearch = initialCar.registrationNumber;
        } else {
          this.carServiceCarSelected(data);
          this.carSearch = '';
          this.clearSearch();
        }
      }
    });
    this.sandbox.resetClientForCar();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public accept() {
    this.formSubmitted = true;
    this.markFormAsDirtyAndTouched();
    if (this.di.isValid()) {
      if (this.modelSaved != null) {
        this.modelSaved.emit(this.di.getModel());
      }
    }
  }

  isValid() {
    return this.di.isValid();
  }

  carServiceCarSelected(value: any) {
    const car = new Car_Dto(value);
    this.di.model.car = car;
    this.di.form.controls['bodySeries'].setValue(car.bodySeries);
    this.di.form.controls['make'].setValue(car.make);
    this.di.form.controls['model'].setValue(car.model);
    this.di.form.controls['year'].setValue(car.year);
    this.di.form.controls['power'].setValue(car.power);
    if (this.di.form.controls['fuel']) {
      this.di.form.controls['fuel'].setValue(car.fuel);
    }
    this.di.form.controls['cilindricalCapacity'].setValue(car.cilindricalCapacity);
    this.di.form.controls['insuranceExpirationDate'].setValue(car.insuranceExpirationDate);
    this.di.form.controls['itpExpirationDate'].setValue(car.itpExpirationDate);

    if (this.modelSaved != null) {
      this.modelSaved.emit(this.di.getModel());
    }

    if (!this.isFromAppointmentValue) {
      if (car.id) {
        if (!this.sandbox.isClientComponentTriggered) {
          this.getClientForCarAP.execute(car.id);
        }
      } else {
        this.sandbox.resetClientForCar();
      }
    }
  }

  clearSearch() {
    if (this.carSearchComponent) {
      this.carSearchComponent.clearSelect();
    }
  }

  initialFilesSaved(data: Array<FileAttachment>) {
    if (ObjectValidators.isValidArray(data)) {
      this.di.model.initialFiles = data;
      this.modelSaved.emit(this.di.getModel());
    }
  }

  initialUrlsSaved(data: Array<FileImageWrapper>) {
    this.filesUrls = data;
  }

  public getInitialUrls(): Array<FileImageWrapper> {
    return this.filesUrls;
  }

  makeValuePositive(event: any) {
    makeInputPositiveInteger(event);
  }
}
