import {Observable} from "rxjs";
import {Store} from '@ngrx/store';
import {Injectable} from "@angular/core";
import * as store from "../../app-store";
import {Actions, createEffect, Effect, ofType} from '@ngrx/effects';
import {filter, map, mergeMap, switchMap} from "rxjs/operators";
import {Router} from "@angular/router";
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  DoLoadRulesForUser,
  EventWithScreenLoading,
  SeamlessEvent
} from "../../app-store/events/base.events";
import {NotifierService} from "angular-notifier";
import {AuthApiService} from "../../api-integration/services/auth-api.service";
import {AppUserBusinessRule} from "../business-rule-provider/app-user.business-rule.provider";
import {GenerateInvoiceForCarServiceEntry, GetInvoiceForCarServiceEntry} from "../../app-store/events/invoices.events";
import {Invoice_Dto} from "../../app-dto/invoice.dto";
import {ObjectValidators} from "../../shared/object.validators";
import {isValidObject} from "../../shared/helpers/common.helpers";
import {InvoiceApiService} from "../../api-integration/services/invoice-api.service";
import {RegisterAsyncJobEvent} from "../../app-store/events/asyncjob.events";
import {AsyncJobApiSerivce} from "../../api-integration/services/async-job-api.serivce";
import {AppNotification, AsyncJob} from "../../app-dto/core.dto";
import {CarServiceApiService} from "../../api-integration/services/car-service-api.service";
import {CarService_Dto} from "../../app-dto/car-service.dto";
import {GetOrganizationClientCreditEvent} from '../../app-store/events/organization-client.events';
import {
  DeleteAppNotificationEvent,
  GetNoOfUnreadNotificationsEvent,
  GetNotificationsPagedResultEvent,
  MarkAppNotificationAsNotReadEvent,
  MarkAppNotificationAsReadEvent
} from "../../app-store/events/notification.events";
import {AppNotificationService} from "../../api-integration/services/app-notification.service";
import {PagedResult} from "../../shared/datatable/datatable.helpers";

@Injectable()
export class CoreDataEventProcessor {
  private readonly notifier: NotifierService;

  constructor(
    private actions$: Actions,
    private appState$: Store<store.State>,
    private authApiService: AuthApiService,
    private invoiceApiService: InvoiceApiService,
    private carServiceEntryApiService: CarServiceApiService,
    private router: Router,
    private notifierService: NotifierService,
    private asyncJobService: AsyncJobApiSerivce,
    private notificationService: AppNotificationService
  ) {
    this.notifier = notifierService;
  }

  error$:
    Observable<any> = createEffect(() => this.actions$
    .pipe(
      filter(action => action.type.indexOf(Do_GenericErrorEvent.identifier) != -1),
      switchMap((action: Do_GenericErrorEvent<string>) => {
          this.notifier.notify('error', action.data);
          return new Observable((observer) => {
            observer.complete();
          });
        }
      )
    ));

  @Effect()
  doLoadRules$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(DoLoadRulesForUser.identifier, SeamlessEvent.identifier + DoLoadRulesForUser.identifier),
      map((action: any) => action),
      switchMap((action: any) => {
          return new Observable((observer) => {
            this.authApiService.u_getRules()
              .subscribe((response: AppUserBusinessRule) => {
                observer.next(new Do_GenericSuccessEvent<AppUserBusinessRule>(response, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  getInvoiceForCarServiceEntry$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GetInvoiceForCarServiceEntry.identifier, SeamlessEvent.identifier + GetInvoiceForCarServiceEntry.identifier),
      map((action: GetInvoiceForCarServiceEntry) => action),
      mergeMap((action: GetInvoiceForCarServiceEntry) => {
          return new Observable((observer) => {
            this.invoiceApiService.u_getInvoiceForCarServiceEntry(action.carServiceEntry, action.organizationId, action.invoiceType)
              .subscribe((res: Invoice_Dto) => {
                if (ObjectValidators.isValidObject(res)) {
                  observer.next(new Do_GenericSuccessEvent<Invoice_Dto>(res, action));
                }
                if (isValidObject(action.callback)) action.callback(res);
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  generateInvoiceForCarServiceEntry$
    :
    Observable<any> = this.actions$
    .pipe(
      ofType(GenerateInvoiceForCarServiceEntry.identifier, EventWithScreenLoading.identifier + GenerateInvoiceForCarServiceEntry.identifier),
      map((action: GenerateInvoiceForCarServiceEntry) => action),
      mergeMap((action: GenerateInvoiceForCarServiceEntry) => {
          return new Observable((observer) => {
            this.carServiceEntryApiService.u_generateCarServiceEntryInvoice(action.carServiceEntry)
              .subscribe((res: CarService_Dto) => {
                if (ObjectValidators.isValidObject(res)) {
                  observer.next(new Do_GenericSuccessEvent<CarService_Dto>(res, action));
                  this.appState$.dispatch(new GetOrganizationClientCreditEvent(res.client.id));
                }
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  doRegisterAsyncJobEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(RegisterAsyncJobEvent.identifier, EventWithScreenLoading.identifier + RegisterAsyncJobEvent.identifier),
      map((action: RegisterAsyncJobEvent) => action),
      switchMap((action: RegisterAsyncJobEvent) => {
          return new Observable((observer) => {
            this.asyncJobService.u_registerJob(action.model)
              .subscribe((res: AsyncJob) => {
                action.callback(res);
                observer.next(new Do_GenericSuccessEvent<AsyncJob>(res, action));
                observer.complete();
              }, (error: string) => {
                action.callback(null);
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );

  @Effect()
  doGetNotificationsPaginatedEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetNotificationsPagedResultEvent.identifier, SeamlessEvent.identifier + GetNotificationsPagedResultEvent.identifier),
      map((action: GetNotificationsPagedResultEvent) => action),
      switchMap((action: GetNotificationsPagedResultEvent) => {
          return new Observable((observer) => {
            this.notificationService.u_getFilteredNotificationPaginated(action.parameters)
              .subscribe((res: PagedResult<AppNotification>) => {
                if (action.callback) {
                  action.callback(res.items);
                }
                observer.next(new Do_GenericSuccessEvent<PagedResult<AppNotification>>(res, action));
                observer.complete();
              }, (error: string) => {
                action.callback(null);
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );
  @Effect()
  doMarkNotificationAsReadEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(MarkAppNotificationAsReadEvent.identifier, SeamlessEvent.identifier + MarkAppNotificationAsReadEvent.identifier),
      map((action: MarkAppNotificationAsReadEvent) => action),
      switchMap((action: MarkAppNotificationAsReadEvent) => {
          return new Observable((observer) => {
            this.notificationService.u_markNotificationAsRead(action.data.id)
              .subscribe((res: AppNotification) => {
                observer.next(new Do_GenericSuccessEvent<AppNotification>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );
  @Effect()
  doMarkNotificationAsNotReadEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(MarkAppNotificationAsNotReadEvent.identifier, SeamlessEvent.identifier + MarkAppNotificationAsNotReadEvent.identifier),
      map((action: MarkAppNotificationAsNotReadEvent) => action),
      switchMap((action: MarkAppNotificationAsNotReadEvent) => {
          return new Observable((observer) => {
            this.notificationService.u_markNotificationAsNotRead(action.data.id)
              .subscribe((res: AppNotification) => {
                observer.next(new Do_GenericSuccessEvent<AppNotification>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );
  @Effect()
  doDeleteNotificationEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(DeleteAppNotificationEvent.identifier, SeamlessEvent.identifier + DeleteAppNotificationEvent.identifier),
      map((action: DeleteAppNotificationEvent) => action),
      switchMap((action: DeleteAppNotificationEvent) => {
          return new Observable((observer) => {
            this.notificationService.u_deleteNotification(action.data.id)
              .subscribe((res: string) => {
                observer.next(new Do_GenericSuccessEvent<string>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );
  @Effect()
  doRetrieveUnreadNotificationsCountEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetNoOfUnreadNotificationsEvent.identifier, SeamlessEvent.identifier + GetNoOfUnreadNotificationsEvent.identifier,
      ),
      map((action: GetNoOfUnreadNotificationsEvent) => action),
      switchMap((action: GetNoOfUnreadNotificationsEvent) => {
          return new Observable((observer) => {
            this.notificationService.u_getNoOfUnreadNotifications()
              .subscribe((res: number) => {
                observer.next(new Do_GenericSuccessEvent<number>(res, action));
                observer.complete();
              }, (error: string) => {
                observer.next(new Do_GenericErrorEvent<string>(error, action));
                observer.complete();
              });
          });
        }
      )
    );
}
