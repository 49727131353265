import {Injectable} from '@angular/core';
import {BaseSandbox} from '../../../core/shared/base/base.sandbox';
import {select, Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {Receipt_Dto} from '../../../core/app-dto/receipt.dto';
import {ReceiptListItemDi} from '../receipt-list/receipt-list-item.di';

@Injectable()
export class ReceiptDetailsSandbox extends BaseSandbox {
    public getReceipt$ = this.appState$.pipe(select(store.getReceipt));
    public item: ReceiptListItemDi;

    constructor(
        protected appState$: Store<store.State>
    ) {
        super(appState$);
    }
    clearSandboxState() {
    }

    initialize(subscription: (data: Receipt_Dto) => {}) {
        this.clearSandboxState();
        this.subscriptions.push(this.getReceipt$.subscribe((data: Receipt_Dto) => {
            if (ObjectValidators.isValidObject(data)) {
                this.item = new ReceiptListItemDi(data);
                subscription(data);
            }
        }));
    }
}
