import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {Observable} from 'rxjs';
import {IWebComponent} from 'src/app/core/shared/base/base.component';
import {BaseActionProxy} from 'src/app/core/shared/base/base.action-proxy';
import {SearchCarPartEvent} from 'src/app/core/app-store/events/car-part.events';

export class SearchCarPartActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(search: string, loading: Function, displayOnlyAvailableItems: boolean = true): any {
        this.appState.dispatch(new SearchCarPartEvent(search, () => {
            loading();
        }, displayOnlyAvailableItems));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

