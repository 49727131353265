import {Injectable} from '@angular/core';
import * as store from '../../../core/app-store/index';
import {select, Store} from '@ngrx/store';
import {BaseSandbox} from 'src/app/core/shared/base/base.sandbox';
import {Mechanic_Dto} from 'src/app/core/app-dto/mechanic.dto';
import {ServicePlatform_Dto} from 'src/app/core/app-dto/service-platform.dto';
import {ObjectValidators} from 'src/app/core/shared/object.validators';
import {Department_Dto} from '../../../core/app-dto/department.dto';
import {CarService_Dto} from "../../../core/app-dto/car-service.dto";

@Injectable()
export class CarServiceAddActionSandbox extends BaseSandbox {
  public getMechanicsList$ = this.appState$.pipe(select(store.getMechanicsList));
  public getDepartmentsList$ = this.appState$.pipe(select(store.getAllDepartmentsListing));
  public getServicePlarformsList$ = this.appState$.pipe(select(store.getServicePlatformsList));
  public getCarService$ = this.appState$.pipe(select(store.getCarService));

  public mechanicsList: Array<Mechanic_Dto> = [];
  public departmentsList: Array<Department_Dto> = [];
  public servicePlatformsList: Array<ServicePlatform_Dto> = [];

  constructor(protected appState$: Store<store.State>) {
    super(appState$);
  }

  public clearSandboxState() {
  }

  public initialize(currentCarServiceLoaded: (result: CarService_Dto) => void): any {
    this.clearSandboxState();

    this.subscriptions.push(this.getMechanicsList$.subscribe((data: Array<Mechanic_Dto>) => {
      if (ObjectValidators.isValidArray(data)) {
        this.mechanicsList = data;
      }
    }));

    this.subscriptions.push(this.getDepartmentsList$.subscribe((data: Array<Department_Dto>) => {
      if (ObjectValidators.isValidArray(data)) {
        this.departmentsList = data;
      }
    }));

    this.subscriptions.push(this.getServicePlarformsList$.subscribe((data: Array<ServicePlatform_Dto>) => {
      if (ObjectValidators.isValidArray(data)) {
        this.servicePlatformsList = data;
      }
    }));
    this.subscriptions.push(this.getCarService$.subscribe((data: CarService_Dto) => {
      if (ObjectValidators.isValidObject(data)) {
        currentCarServiceLoaded(data);
      }
    }));
  }
}
