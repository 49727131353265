import { BaseDI } from 'src/app/core/shared/base/base.di';
import { Mechanic_Dto } from 'src/app/core/app-dto/mechanic.dto';
import {
  getFormattedIdNameList,
  isValidArrayAndHasElements,
  isValidObject
} from "../../../core/shared/helpers/common.helpers";
import { DateHelpers } from "../../../core/shared/date.helpers";

export class MechanicDetailsDi extends BaseDI<Mechanic_Dto> {

  public computedSpecialization: string = '';
  public computedBirthday: string = '';
  public computedCreatedOn: string = '';
  public startDate: Date = null;
  public endDate: Date = null;
  public computedSalary = '';
  public computedCurrency = '';
  public departmentList = '';
  public hasUserProfile:boolean = false;

  constructor(data: Mechanic_Dto = new Mechanic_Dto(null)) {
    super(data);
    this.computeMechanicSpecializationsString();
    if(isValidObject(data.userMechanicLink)) this.hasUserProfile = true;
    this.computedBirthday = DateHelpers.dateToString(data.birthday, 'DD/MM/YYYY');
    this.computedCreatedOn = DateHelpers.dateToString(data.createdOn, 'DD/MM/YYYY');
    this.departmentList = this.model.departments ? this.model.departments.map(d => d.name).join(', ') : '';
    if (this.model.history != null && this.model.history.length > 0) {
      this.startDate = this.model.history[this.model.history.length - 1].startDate;
      this.endDate = this.model.history[this.model.history.length - 1].endDate;
      const lastItem = this.model.history[this.model.history.length - 1];
      this.computedSalary = lastItem.isPaidUsingPercentageValue ? lastItem.salaryAsPercentage != null ? lastItem.salaryAsPercentage.toString() :'-' :
        lastItem.salary != null ? lastItem.salary.toString() : '-';
      this.computedCurrency = lastItem.isPaidUsingPercentageValue ? "%" :
        lastItem.currency != null ? lastItem.currency.name : '';
    }
    else{
      this.computedSalary = this.model.isPaidUsingPercentageValue ? this.model.salaryAsPercentage != null ? this.model.salaryAsPercentage.toString() : "-" :
        this.model.salary != null ? this.model.salary.toString() : '-';
      this.computedCurrency = this.model.isPaidUsingPercentageValue ? "%" : this.model.currency != null ?this.model.currency.name : '';
    }
  }

  getModel(): Mechanic_Dto {
    return this.model;
  }


  computeMechanicSpecializationsString() {
    if (isValidArrayAndHasElements(this.model.mechanicSpecializationType)) {
      this.computedSpecialization = getFormattedIdNameList(this.model.mechanicSpecializationType);
    }
    else this.computedSpecialization = '';
  }

}
