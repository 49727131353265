import {NgModule} from '@angular/core';
import {ReceiptListComponent} from './components/receipt-list/receipt-list.component';
import {ReceiptListSandbox} from './components/receipt-list/receipt-list.sandbox';
import {SharedModule} from '../core/shared/shared.module';
import {DataTablesModule} from 'angular-datatables';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {
    ReceiptListOverviewOrganizationComponent
} from './components/receipt-list-overview-organization/receipt-list-overview-organization.component';
import {
    ReceiptListOverviewOrganizationSandbox
} from './components/receipt-list-overview-organization/receipt-list-overview-organization.sandbox';
import {ReceiptDetailsComponent} from './components/receipt-details/receipt-details.component';
import {ReceiptDetailsSandbox} from './components/receipt-details/receipt-details.sandbox';

@NgModule({
    declarations: [
        ReceiptListComponent,
        ReceiptListOverviewOrganizationComponent,
        ReceiptDetailsComponent
    ],
    imports: [
        SharedModule,
        DataTablesModule,
        CommonModule,
        RouterModule
    ],
    providers: [
        ReceiptListSandbox,
        ReceiptListOverviewOrganizationSandbox,
        ReceiptDetailsSandbox
    ],
    entryComponents: [],
    exports: [
        ReceiptListComponent
    ]
})
export class ReceiptModule {}
