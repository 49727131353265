import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {Observable} from 'rxjs';
import {IWebComponent} from 'src/app/core/shared/base/base.component';
import {BaseActionProxy} from 'src/app/core/shared/base/base.action-proxy';
import {ActiveDataTableParameters, DataTableParameters} from 'src/app/core/shared/datatable/datatable.helpers';
import {
    GetSupplyItemsForCarPartPagedResultEvent, GetTotalCarPartsValueEvent
} from 'src/app/core/app-store/events/car-part.events';

export class GetTotalCarPartsValueActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent, appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(): any {
        if (this.canExecute()) {
            this.appState.dispatch(new GetTotalCarPartsValueEvent());
        }
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

