import {Component, OnInit} from '@angular/core';
import {BaseWebComponentWithBreadcrumb} from '../../../core/shared/base/base.component';
import {InvoiceRequestType} from '../../../invoice/components/invoice/invoice.component';
import {ReceiptListOverviewOrganizationSandbox} from './receipt-list-overview-organization.sandbox';
import {ActivatedRoute, Params} from '@angular/router';
import {ResetSection} from '../../../core/app-store/events/base.events';

@Component({
    selector: 'app-receipt-list-overview-organization',
    templateUrl: './receipt-list-overview-organization.component.html',
    styleUrls: ['./receipt-list-overview-organization.component.scss']
})
export class ReceiptListOverviewOrganizationComponent extends BaseWebComponentWithBreadcrumb implements OnInit {
    public invoiceRequestType: InvoiceRequestType = InvoiceRequestType.Organization;

    constructor(
        public sandbox: ReceiptListOverviewOrganizationSandbox
    ) {
        super(sandbox, ResetSection.Receipt);
    }
    ngOnInit(): void {
        this.setBreadcrumbAP.execute([{text: 'Acasa', url: '/dashboard'}, {text: 'Chitante'}]);
    }
}
