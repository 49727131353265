import {Store} from '@ngrx/store';
import * as store from '../../../core/app-store';
import {Observable} from 'rxjs';
import {IWebComponent} from 'src/app/core/shared/base/base.component';
import {BaseActionProxy} from 'src/app/core/shared/base/base.action-proxy';
import {GetCarServiceEvent} from '../../../core/app-store/events/car-service.events';
import {
    AcceptOfferEvent,
    AddOfferServiceCarPartEvent,
    GetOfferEvent,
    UpdateOfferCarDataEvent,
    UpdateOfferCarWorksEvent, UpdateOfferClientCarPartsEvent,
    UpdateOfferClientDataEvent, UpdateOfferFinalizeEvent
} from '../../../core/app-store/events/offer.events';
import {Offer_Dto, OfferAcceptedTrace_Dto} from '../../../core/app-dto/offer.dto';
import {CarPart_Dto} from '../../../core/app-dto/car-part.dto';

export class AcceptOfferActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
                appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(offerId: string, code: string, data: OfferAcceptedTrace_Dto): any {
        this.appState.dispatch(new AcceptOfferEvent(offerId, code, data));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

