import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseFormModelComponent} from 'src/app/core/shared/base/base.component';
import * as store from '../../../core/app-store/index';
import {Store} from '@ngrx/store';
import {RegisterCarServiceCarPartActionProxy} from './register-car-service-car-part.action-proxy';
import {ResetSection} from 'src/app/core/app-store/events/base.events';
import {EditCarServiceCarPartActionProxy} from './edit-car-service-car-part.action-proxy';
import {ActivatedRoute} from '@angular/router';
import {CarServiceSaveCarPartSandbox} from './car-service-save-car-part.sandbox';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap';
import {ObjectValidators} from '../../../core/shared/object.validators';
import {CarPartFormComponent} from '../../../car-part/components/car-part-form/car-part-form.component';
import {CarPart_Dto, CarServiceCarPart_Dto} from '../../../core/app-dto/car-part.dto';
import {GetCarPartActionProxy} from '../../../car-part/components/car-part-details/get-car-part.action-proxy';
import {GetSuppliersActionProxy} from '../../../car-part/components/save-car-part/get-suppliers.action-proxy';
import {CarPartSearchComponent} from "../../../car-part/components/car-part-search/car-part-search.component";
import {isValidArrayAndHasElements, isValidObject} from "../../../core/shared/helpers/common.helpers";
import {AppUserBusinessRuleProvider} from "../../../core/core/business-rule-provider/app-user.business-rule.provider";
import {NotifierService} from "angular-notifier";


@Component({
  selector: 'app-car-service-save-car-part',
  templateUrl: './car-service-save-car-part.component.html',
  styleUrls: ['./car-service-save-car-part.component.scss']
})
export class CarServiceSaveCarPartComponent extends BaseFormModelComponent<CarServiceCarPart_Dto> implements OnInit, OnDestroy {
  public registerCarServiceCarPartAP: RegisterCarServiceCarPartActionProxy;
  public editCarServiceCarPartAP: EditCarServiceCarPartActionProxy;
  public getCarAP: GetCarPartActionProxy;
  public getSupplierAP: GetSuppliersActionProxy;
  public title: string;
  public onClose: Subject<boolean>;

  public data: CarPart_Dto = null;
  public isAddginNewItem: boolean = false;
  public carServiceId: string;

  @Input("allItems") allItems:Array<CarPart_Dto>;

  @ViewChild(CarPartFormComponent, {static: false})
  private carPartFormComponent: CarPartFormComponent;

  @ViewChild(CarPartSearchComponent, {static: false}) carPartSearchComponent: CarPartSearchComponent;


  constructor(public appState: Store<store.State>,
              public sandbox: CarServiceSaveCarPartSandbox,
              private activeModal: BsModalRef,
              private notifierService: NotifierService,
              public rulesProvider: AppUserBusinessRuleProvider,
              public activatedRoute: ActivatedRoute) {
    super(sandbox, ResetSection.CarPart);
    this.registerCarServiceCarPartAP = new RegisterCarServiceCarPartActionProxy(this, sandbox.appState);
    this.editCarServiceCarPartAP = new EditCarServiceCarPartActionProxy(this, sandbox.appState);
    this.getCarAP = new GetCarPartActionProxy(this, sandbox.appState);
    this.getSupplierAP = new GetSuppliersActionProxy(this, sandbox.appState);
    this.getSupplierAP.execute();
  }

  public diInitialize(data: CarServiceCarPart_Dto) {
    this.data = data.carPart;
    this.carServiceId = data.carServiceId;
    if(isValidObject(this.data)){
      this.title = 'Editare piesa service';
    }
    else{
      this.isAddginNewItem = true;
      this.title = 'Adauga piesa service';
    }
    this.dataLoaded = true;
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public decline() {
    this.onClose.next(false);
    this.activeModal.hide();
  }

  public accept() {
    this.carPartFormComponent.accept();
  }

  public formSaved(model: CarPart_Dto) {
    if(this.rulesProvider.rules.canManageSupplies==false) {
      if (model.id) {
        model.isEdit = !this.isAddginNewItem;
        this.editCarServiceCarPartAP.execute(model, this.carServiceId);
      } else {
        this.registerCarServiceCarPartAP.execute(model, this.carServiceId);
      }
    }else{
      if(this.isAddginNewItem){
        if(isValidArrayAndHasElements(this.allItems)){
          const existingItem = this.allItems.find(f=>{
            if(f.supplier.id === model.supplier.id && f.code === model.code
            && f.acquisitionPriceWithVat === model.acquisitionPriceWithVat) return true;
            return false;
          });
          if(isValidObject(existingItem)){
            this.notifierService.notify('error', `Piesa avand codul ${model.code} achizitionata de la ${model.supplier.name} si avand pretul de achizitie ${model.acquisitionPriceWithVat} este deja adaugata la masina curenta. Va rugam sa editati detaliile.`);
            return;
          }
        }
        this.registerCarServiceCarPartAP.execute(model, this.carServiceId);
      }else{
        model.isEdit = !this.isAddginNewItem;
        this.editCarServiceCarPartAP.execute(model, this.carServiceId);
      }
    }
    this.onClose.next(true);
    this.activeModal.hide();
  }

  carPartSelected(value: CarPart_Dto) {
    if (isValidObject(value))
      this.data = new CarPart_Dto(value);
    else this.data = null;
  }
}
