import {Component, Input, OnInit} from '@angular/core';
import {isValidObject} from '../../../core/shared/helpers/common.helpers';

@Component({
    selector: 'app-offer-status-label',
    templateUrl: './offer-status-label.component.html',
    styleUrls: ['./offer-status-label.component.scss']
})
export class OfferStatusLabelComponent implements OnInit {
    public statusLabel: string = '';
    public backgroundColor: string = '#00616B';

    @Input('status') set status(value: OfferStatusKey) {
        if (isValidObject(value) == false) {
            this.backgroundColor = '#00306C';
            this.statusLabel = 'Noua';
        } else {
            switch (value) {
                case OfferStatusKey.New:
                    this.backgroundColor = '#00306C';
                    this.statusLabel = 'Noua';
                    break;
                case OfferStatusKey.Draft:
                    this.backgroundColor = '#00616B';
                    this.statusLabel = 'Generata';
                    break;
                case OfferStatusKey.Finalized:
                    this.backgroundColor = '#007567';
                    this.statusLabel = 'Finalizata';
                    break;
            }
        }
    }


    constructor() {
    }

    ngOnInit() {


    }
}

export enum OfferStatusKey {
    Draft = 'Draft',
    New = 'New',
    Finalized = 'Finalized',
    ALL = 'All'
}

