import { Store } from '@ngrx/store';
import * as store from '../../../core/app-store';
import { Observable } from 'rxjs';
import { IWebComponent } from 'src/app/core/shared/base/base.component';
import { BaseActionProxy } from 'src/app/core/shared/base/base.action-proxy';
import { SearchCalendarEventEvent } from 'src/app/core/app-store/events/calendar-events';

export class SearchEventActionProxy extends BaseActionProxy {
    constructor(component: IWebComponent,
        appState: Store<store.State>) {
        super(component, appState);
    }

    canExecute(): boolean {
        return true;
    }

    execute(startDate: Date, endDate: Date, search: string, loading: Function): any {
        this.appState.dispatch(new SearchCalendarEventEvent(startDate, endDate, search, () => {
            loading();
        }));
    }

    canExecuteAsync(): Observable<boolean> {
        return null;
    }
}

