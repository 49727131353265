import {Injectable} from "@angular/core";
import {Actions, Effect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import * as store from "../../core/app-store";
import {Observable} from "rxjs";
import {GetCarsPagedResultEvent} from "../../core/app-store/events/car.events";
import {
  Do_GenericErrorEvent,
  Do_GenericSuccessEvent,
  EventWithScreenLoading
} from "../../core/app-store/events/base.events";
import {map, switchMap} from "rxjs/operators";
import {PagedResult} from "../../core/shared/datatable/datatable.helpers";
import {Car_Dto} from "../../core/app-dto/car.dto";
import {ObjectValidators} from "../../core/shared/object.validators";
import {AuditApiService} from "../../core/api-integration/services/audit-api.service";
import {GetAuditPagedResultEvent} from "../../core/app-store/events/audit.events";
import {Audit_Dto} from "../../core/app-dto/audit.dto";

@Injectable()
export class AuditUIEventProcessor {

  constructor(
    private actions$: Actions,
    private appState$: Store<store.State>,
    private apiService: AuditApiService
  ) {
  }

  @Effect()
  GetAuditPagedResultEvent$: Observable<any> = this.actions$
    .pipe(
      ofType(GetAuditPagedResultEvent.identifier, EventWithScreenLoading.identifier + GetAuditPagedResultEvent.identifier),
      map((action: GetAuditPagedResultEvent) => action),
      switchMap((action: GetAuditPagedResultEvent) => {
        return new Observable((observer) => {
          this.apiService.u_getFilteredAuditPaginated(action.parameters)
            .subscribe((res: PagedResult<Audit_Dto>) => {
              if (ObjectValidators.isValidObject(res)) {
                observer.next(new Do_GenericSuccessEvent<PagedResult<Audit_Dto>>(res, action));
              }
              observer.complete();
            }, (error: string) => {
              observer.next(new Do_GenericErrorEvent<string>(error, action));
              observer.complete();
            });
        });
      })
    );
}
