import {EventWithScreenLoading, UIGeneratedEvent} from "./base.events";
import { DataTableParameters} from "../../shared/datatable/datatable.helpers";

export class GetAuditPagedResultEvent extends EventWithScreenLoading implements UIGeneratedEvent {
  public static identifier:string = "GetAuditPagedResultEvent";
  constructor(public parameters: DataTableParameters, public withReset: boolean = false) {
    super();
    this.type += GetAuditPagedResultEvent.identifier;
  }
}
