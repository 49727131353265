import {BaseFormDI} from "../../../../core/shared/base/base.di";
import {OrganizationClient_Dto} from "../../../../core/app-dto/organization-client.dto";
import {Address} from "../../../../core/app-dto/core.dto";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CnpReg, EmailRegForControl, noWhitespaceValidator, PhoneReg} from '../../../../core/shared/form/custom-form.validator';

export class AddOfferClientDi extends BaseFormDI<OrganizationClient_Dto> {

    public isLegalEntity = false;
    public cnpCui: string = null;

    constructor(data: OrganizationClient_Dto = new OrganizationClient_Dto(null)) {
        super(new OrganizationClient_Dto(data));
        this.initForm();
        if (this.model.isLegalEntity) {
            this.form.get('cuiCnp').setValidators([Validators.required, noWhitespaceValidator]);
        } else {
            if (this.form.value.isUniqueId) {
                this.form.get('cuiCnp').disable();
                this.form.get('cuiCnp').setValidators([]);
            } else {
                this.form.get('cuiCnp').enable();
                this.form.get('cuiCnp').setValidators([Validators.required, noWhitespaceValidator, CnpReg()]);
            }
        }
        this.form.get('cuiCnp').updateValueAndValidity();
        this.form.get('isUniqueId').updateValueAndValidity();
        this.cnpCui = data.cuiCnp;
    }

    getModel(): OrganizationClient_Dto {
        this.model.isLegalEntity = this.form.value.isLegalEntity === 'true';
        this.model.cuiCnp = this.form.getRawValue().cuiCnp;
        this.model.isUniqueId = this.form.value.isUniqueId || false;
        this.model.phoneNumber = this.form.value.phoneNumber;
        this.model.email = this.form.value.email;

        if (this.model.isLegalEntity) {
            this.model.address = new Address(this.form.value.address);
            this.model.nrRegCi = this.form.value.nrRegCi;
        } else {
            this.model.address = null;
            this.model.nrRegCi = null;
        }
        return this.model;
    }

    initForm() {
        this.form = new FormGroup({
            isLegalEntity: new FormControl(this.model.isLegalEntity + '', Validators.compose([Validators.required])),
            cuiCnp: new FormControl(this.model.cuiCnp, Validators.compose([noWhitespaceValidator])),
            isUniqueId: new FormControl(this.model.isUniqueId, Validators.compose([])),
            nrRegCi: new FormControl(this.model.nrRegCi, Validators.compose([])),
            phoneNumber: new FormControl(this.model.phoneNumber, Validators.compose([Validators.required, PhoneReg()])),
            email: new FormControl(this.model.email, Validators.compose([EmailRegForControl()])),
        });
    }

    public changeLegalForm(e: any) {
        if (e.target.value != "false") {
            if (this.form.value.isUniqueId) {
                this.form.get('cuiCnp').setValue(null);
            }
            this.form.get('cuiCnp').enable();
            this.form.get('cuiCnp').setValidators([Validators.required, noWhitespaceValidator]);
            this.isLegalEntity = true;
        } else {
            this.setCuiCnp(this.cnpCui, this.form.value.isUniqueId);
            this.isLegalEntity = false;
        }
        this.form.get('cuiCnp').updateValueAndValidity();
        this.form.get('isUniqueId').updateValueAndValidity();
    }

    changeUniqueIdState(e: any) {
        if (e.target.checked) {
            this.form.get('cuiCnp').setValue(this.cnpCui);
            this.form.get('cuiCnp').disable();
            this.form.get('cuiCnp').setValidators([]);
        } else {
            this.form.get('cuiCnp').setValue(null);
            this.form.get('cuiCnp').enable();
            this.form.get('cuiCnp').setValidators([Validators.required, noWhitespaceValidator, CnpReg()]);
        }
        this.form.get('cuiCnp').updateValueAndValidity();
        this.form.get('isUniqueId').updateValueAndValidity();
    }

    setCuiCnp(cnp: string, isUniqueId: boolean) {
        if (isUniqueId) {
            this.form.get('cuiCnp').setValue(cnp);
            this.form.get('cuiCnp').disable();
            this.form.get('cuiCnp').setValidators([]);
        } else {
            this.form.get('cuiCnp').enable();
            this.form.get('cuiCnp').setValidators([Validators.required, noWhitespaceValidator, CnpReg()]);
        }
    }
}
