import { Component, OnInit } from '@angular/core';
import { BaseWebComponent } from 'src/app/core/shared/base/base.component';
import { LogoutActionProxy } from './logout.action-proxy';
import { LogoutSandbox } from './logout.sandbox';
import {Router} from "@angular/router";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent extends BaseWebComponent implements OnInit {
  public doLogoutAP: LogoutActionProxy;

  constructor(public sandbox: LogoutSandbox, public router:Router) {
    super(sandbox);
    this.doLogoutAP = new LogoutActionProxy(this, sandbox.appState);
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy() {
    this.destroy();
  }
}
