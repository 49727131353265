import {BaseFormDI} from 'src/app/core/shared/base/base.di';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Department_Dto} from '../../../core/app-dto/department.dto';
import {ObjectValidators} from "../../../core/shared/object.validators";
import { min } from 'src/app/core/shared/form/custom-form.validator';
import {YesNoComponent, YesNoComponentInput} from "../../../core/shared/components/yes-no/yes-no.component";

export class DepartmentFormDi extends BaseFormDI<Department_Dto> {
  public priceVATInput: YesNoComponentInput;
  constructor(data: Department_Dto = new Department_Dto(null)) {
    super(new Department_Dto(data));
    this.initForm();
    this.priceVATInput = new YesNoComponentInput(this.model.priceHasVAT, !this.model.priceHasVAT, "Pretul include TVA?",
      this.form,"priceHasVAT");
  }

  getModel(): Department_Dto {
    this.model.name = this.form.value.name;
    this.model.laborPricePerHour = this.form.value.laborPricePerHour;
    this.model.currency = this.form.value.currency;
    this.model.priceHasVAT = this.form.get(this.priceVATInput.formGroupName).get(YesNoComponent.YES_CONTROL_NAME).value || false;;
    return this.model;
  }

  initForm() {

    let priceValue :number = this.model.laborPricePerHour;
    if(this.model.priceHasVAT==false) priceValue = this.model.priceWithoutVAT;
    this.form = new FormGroup({
      name: new FormControl(this.model.name, Validators.compose([Validators.required])),
      laborPricePerHour: new FormControl(priceValue, Validators.compose([Validators.required, min(0)])),
      currencySelectedValue: new FormControl(this.model.currency, Validators.compose([Validators.required])),
    });
  }
}
